var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "订单信息",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _vm.orderDatalist
                    ? _c(
                        "div",
                        { staticClass: "description" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("用户信息"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            用户昵称：" +
                                  _vm._s(_vm.orderDatalist.user.nickname) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            收货人：" +
                                  _vm._s(_vm.orderDatalist.address_name) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            联系电话：" +
                                  _vm._s(_vm.orderDatalist.address_phone) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            收货地址：" +
                                  _vm._s(_vm.orderDatalist.address_info) +
                                  "\n          "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c("div", { staticClass: "title" }, [
                            _vm._v("收货信息"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "acea-row" }, [
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            订单编号：" +
                                  _vm._s(_vm.orderDatalist.order_id) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("\n            订单状态：\n            "),
                              _vm.orderDatalist.order_status === 0
                                ? _c("span", [_vm._v("未确认")])
                                : _vm.orderDatalist.refund_status == 1
                                ? _c("span", [_vm._v("申请售后中")])
                                : _vm.orderDatalist.refund_status == 2
                                ? _c("span", [_vm._v("买家待发货")])
                                : _vm.orderDatalist.refund_status == 3
                                ? _c("span", [_vm._v("卖家待收货")])
                                : _vm.orderDatalist.order_status == 1
                                ? _c("span", [_vm._v("未支付")])
                                : _vm.orderDatalist.order_status == 2
                                ? _c("span", [_vm._v("待发货")])
                                : _vm.orderDatalist.order_status == 3
                                ? _c("span", [_vm._v("待收货")])
                                : _vm.orderDatalist.order_status == 4
                                ? _c("span", [_vm._v("待评价")])
                                : _vm.orderDatalist.order_status == 5
                                ? _c("span", [_vm._v("已完成")])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            拍品保证金：￥" +
                                  _vm._s(
                                    _vm.orderDatalist.auction.bond_price / 100
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            拍品落槌价：￥" +
                                  _vm._s(
                                    _vm.orderDatalist.auction.now_price / 100
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            交付邮费：￥" +
                                  _vm._s(
                                    _vm.orderDatalist.auction.postage_price /
                                      100
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            实际支付：￥" +
                                  _vm._s(
                                    (_vm.orderDatalist.auction.bond_price +
                                      _vm.orderDatalist.pay_price) /
                                      100
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            创建时间：" +
                                  _vm._s(_vm.orderDatalist.create_time) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            支付方式：" +
                                  _vm._s(_vm.orderDatalist.pay_type) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "description-term" }, [
                              _vm._v(
                                "\n            商家备注：" +
                                  _vm._s(_vm.orderDatalist.remark) +
                                  "\n          "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.orderDatalist.delivery_no &&
                          _vm.orderDatalist.delivery_name
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("物流信息"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "\n              快递公司：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_name
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "\n              快递单号：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_no
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: { click: _vm.openLogistics },
                                        },
                                        [_vm._v("物流查询")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.orderDatalist.delivery_type === "2"
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("配送信息"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "\n              送货人姓名：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_name
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [
                                      _vm._v(
                                        "\n              送货人电话：" +
                                          _vm._s(
                                            _vm.orderDatalist.delivery_id
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.orderDatalist.mark
                            ? [
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("用户备注"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "acea-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "description-term" },
                                    [_vm._v(_vm._s(_vm.orderDatalist.mark))]
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流查询",
            visible: _vm.dialogLogistics,
            width: "350px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLogistics = $event
            },
          },
        },
        [
          _vm.orderDatalist
            ? _c("logistics-from", {
                attrs: {
                  "order-datalist": _vm.orderDatalist,
                  result: _vm.result,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }