"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._updateType = exports._updateScence = exports._updatePaper = exports._updateModel = exports._updateFrame = exports._getTypeList = exports._getTypeDetail = exports._getScenceList = exports._getScenceDetail = exports._getPaperList = exports._getPaperDetail = exports._getModelList = exports._getModelDetail = exports._getFrameList = exports._getFrameDetail = exports._deleteType = exports._deleteScence = exports._deletePaper = exports._deleteModel = exports._deleteFrame = exports._createType = exports._createScence = exports._createPaper = exports._createModel = exports._createFrame = exports._ChangeTypeSort = exports._ChangeScenceSort = exports._ChangePaperSort = void 0;
var _request = _interopRequireDefault(require("./request"));
/* ------------- 模版 --------------- */
// 获取模版列表
var _getModelList = exports._getModelList = function _getModelList(state) {
  return _request.default.get("frame/multiTemplate/list", state);
};

// 获取模版详情
var _getModelDetail = exports._getModelDetail = function _getModelDetail(state) {
  return _request.default.get("frame/multiTemplate/detail/".concat(state.template_id), state);
};

// 创建模版
var _createModel = exports._createModel = function _createModel(state) {
  return _request.default.post("frame/multiTemplate/create", state);
};

// 更新模版
var _updateModel = exports._updateModel = function _updateModel(state) {
  return _request.default.post("frame/multiTemplate/update/".concat(state.template_id), state);
};

// 删除模版
var _deleteModel = exports._deleteModel = function _deleteModel(state) {
  return _request.default.post("frame/multiTemplate/delete/".concat(state.template_id), state);
};

/* ------------- 框类型 --------------- */
// 获取类型列表
var _getTypeList = exports._getTypeList = function _getTypeList(state) {
  return _request.default.get("frame/category/list", state);
};

// 获取类型详情
var _getTypeDetail = exports._getTypeDetail = function _getTypeDetail(state) {
  return _request.default.get("frame/category/detail/".concat(state.template_id), state);
};

// 创建类型
var _createType = exports._createType = function _createType(state) {
  return _request.default.post("frame/category/create", state);
};

// 更新类型
var _updateType = exports._updateType = function _updateType(state) {
  return _request.default.post("frame/category/update/".concat(state.template_id), state);
};

// 删除类型
var _deleteType = exports._deleteType = function _deleteType(state) {
  return _request.default.post("frame/category/delete/".concat(state.id), state);
};

// 修改类型排序
var _ChangeTypeSort = exports._ChangeTypeSort = function _ChangeTypeSort(state) {
  return _request.default.post("frame/category/switchSort/".concat(state.id), state);
};

/* ------------- 框卡纸 --------------- */
// 获取框卡纸列表
var _getPaperList = exports._getPaperList = function _getPaperList(state) {
  return _request.default.get("frame/paper/list", state);
};

// 获取框卡纸详情
var _getPaperDetail = exports._getPaperDetail = function _getPaperDetail(state) {
  return _request.default.get("frame/paper/detail/".concat(state.template_id), state);
};

// 创建框卡纸
var _createPaper = exports._createPaper = function _createPaper(state) {
  return _request.default.post("frame/paper/create", state);
};

// 更新框卡纸
var _updatePaper = exports._updatePaper = function _updatePaper(state) {
  return _request.default.post("frame/paper/update/".concat(state.template_id), state);
};

// 删除框卡纸
var _deletePaper = exports._deletePaper = function _deletePaper(state) {
  return _request.default.post("frame/paper/delete/".concat(state.id), state);
};

// 修改框卡纸排序
var _ChangePaperSort = exports._ChangePaperSort = function _ChangePaperSort(state) {
  return _request.default.post("frame/paper/switchSort/".concat(state.id), state);
};

/* ------------- 场景 --------------- */
// 获取场景列表
var _getScenceList = exports._getScenceList = function _getScenceList(state) {
  return _request.default.get("frame/scene/list", state);
};

// 获取场景详情
var _getScenceDetail = exports._getScenceDetail = function _getScenceDetail(state) {
  return _request.default.get("frame/scene/detail/".concat(state.template_id), state);
};

// 创建框场景
var _createScence = exports._createScence = function _createScence(state) {
  return _request.default.post("frame/scene/create", state);
};

// 更新场景
var _updateScence = exports._updateScence = function _updateScence(state) {
  return _request.default.post("frame/scene/update/".concat(state.template_id), state);
};

// 删除场景
var _deleteScence = exports._deleteScence = function _deleteScence(state) {
  return _request.default.post("frame/scene/delete/".concat(state.id), state);
};

// 修改场景排序
var _ChangeScenceSort = exports._ChangeScenceSort = function _ChangeScenceSort(state) {
  return _request.default.post("frame/scene/switchSort/".concat(state.id), state);
};

/* ---------- 画框 ------------------ */
// 获取模版列表
var _getFrameList = exports._getFrameList = function _getFrameList(state) {
  return _request.default.get("frame/template/list", state);
};

// 获取模版详情
var _getFrameDetail = exports._getFrameDetail = function _getFrameDetail(state) {
  return _request.default.get("frame/template/detail", state);
};

// 创建模版
var _createFrame = exports._createFrame = function _createFrame(state) {
  return _request.default.post("frame/template/create", state);
};

// 更新模版
var _updateFrame = exports._updateFrame = function _updateFrame(state) {
  return _request.default.post("frame/template/update/".concat(state.id), state);
};

// 删除模版
var _deleteFrame = exports._deleteFrame = function _deleteFrame(state) {
  return _request.default.post("frame/template/delete/".concat(state.template_id), state);
};