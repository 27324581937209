var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
        display: "flex",
        justifyContent: _vm.facade.align,
      },
    },
    [
      _c(
        "section",
        {
          style: {
            backgroundColor: _vm.facade.pageBackground,
            borderWidth:
              _vm.facade.borderTop +
              "px " +
              _vm.facade.borderRight +
              "px " +
              _vm.facade.borderBottom +
              "px " +
              _vm.facade.borderLeft +
              "px",
            borderRadius:
              _vm.facade.pageRadiusTopLeft +
              "px " +
              _vm.facade.pageRadiusTopRight +
              "px " +
              _vm.facade.pageRadiusBottomRight +
              "px " +
              _vm.facade.pageRadiusBottomLeft +
              "px",
            borderStyle: _vm.facade.borderStyle,
            borderColor: _vm.facade.borderColor,
            color: _vm.facade.pageColor,
            fontSize: _vm.facade.fontSize + "px",
            fontWeight: "" + _vm.facade.fontWeight,
            flex: _vm.facade.widthType == 1 ? 1 : "unset",
            display: "flex",
            alignItems: _vm.facade.align,
            flexDirection: "column",
            lineHeight: _vm.facade.lineHeight + "px",
            letterSpacing: _vm.facade.letterSpacing + "px",
          },
        },
        [
          _vm.facade.hasFold == 2
            ? [
                _c(
                  "section",
                  {
                    style: {
                      margin: "-" + _vm.facade.borderTop + "px 0 0",
                      transform: "translate3d(1px, 0px, 0px)",
                      display: "flex",
                      justifyContent: "flex-end",
                    },
                  },
                  [
                    _c("section", {
                      style: {
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                        borderWidth: "11px 0px 0px 11px",
                        borderColor:
                          "rgb(255, 255, 255) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) " +
                          _vm.facade.borderColor,
                      },
                    }),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.content.data
            ? _c("section", {
                style: {
                  padding:
                    _vm.facade.pagePaddingTop +
                    "px " +
                    _vm.facade.pagePaddingRight +
                    "px " +
                    _vm.facade.pagePaddingBottom +
                    "px " +
                    _vm.facade.pagePaddingLeft +
                    "px",
                },
                domProps: { innerHTML: _vm._s(_vm.content.data) },
              })
            : _c(
                "section",
                {
                  style: {
                    padding:
                      _vm.facade.pagePaddingTop +
                      "px " +
                      _vm.facade.pagePaddingRight +
                      "px " +
                      _vm.facade.pagePaddingBottom +
                      "px " +
                      _vm.facade.pagePaddingLeft +
                      "px",
                  },
                },
                [_vm._v("请先设置文字内容")]
              ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }