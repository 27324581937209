var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c(
        "el-form",
        {
          attrs: { size: "small", "label-width": "0px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("el-form-item", { attrs: { label: " " } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("span", { staticClass: "fWeight500" }, [
                  _vm._v("用户手机号："),
                ]),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    staticClass: "w200",
                    attrs: { placeholder: "输入用户手机号" },
                    model: {
                      value: _vm.tableFromIssue.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "phone", $$v)
                      },
                      expression: "tableFromIssue.phone",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: {
                        click: function ($event) {
                          return _vm.pageChangeIssue(1)
                        },
                      },
                      slot: "append",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "fWeight500",
                    staticStyle: { "margin-left": "40px" },
                  },
                  [_vm._v("奖励类型：")]
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "selWidth",
                    attrs: { placeholder: "请选择状态" },
                    on: { change: _vm.getIssueList },
                    model: {
                      value: _vm.tableFromIssue.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "type", $$v)
                      },
                      expression: "tableFromIssue.type",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "全部", value: "" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 1, label: "积分" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 2, label: "数字藏品" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 3, label: "数字盲盒" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 4, label: "优惠券" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 5, label: "商品" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.Loading,
              expression: "Loading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            data: _vm.issueData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "用户信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.user
                      ? [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetails(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(scope.row.user.real_name) +
                                      "\n              " +
                                      _vm._s(scope.row.user.phone)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "达到条件" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v("连续签到" + _vm._s(scope.row.rule.day) + "天"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 1
                      ? _c("span", [_vm._v("积分")])
                      : scope.row.type == 2
                      ? _c("span", [_vm._v("数字藏品")])
                      : scope.row.type == 3
                      ? _c("span", [_vm._v("数字盲盒")])
                      : scope.row.type == 4
                      ? _c("span", [_vm._v("优惠券")])
                      : scope.row.type == 5
                      ? _c("span", [_vm._v("商品")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 2 ||
                    scope.row.type == 3 ||
                    scope.row.type == 4 ||
                    scope.row.type == 5
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                scope.row.prize_info.name ||
                                  scope.row.prize_info.store_name
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "领取时间", prop: "add_time" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            staticClass: "mt20",
            staticStyle: { "text-align": "right" },
            attrs: {
              background: "",
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.tableFromIssue.limit,
              "current-page": _vm.tableFromIssue.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.issueData.total,
            },
            on: {
              "size-change": _vm.handleSizeChangeIssue,
              "current-change": _vm.pageChangeIssue,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }