"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _searchAlbum = _interopRequireDefault(require("@/components/searchDataView/searchAlbum"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    searchAlbum: _searchAlbum.default
  },
  props: {
    venueId: {
      type: String,
      default: ""
    },
    value: {
      type: [String],
      default: ""
    }
  },
  data: function data() {
    return {
      goSearch: false,
      changeAlbum: {}
    };
  },
  computed: {
    select: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    showAlbumListAction: function showAlbumListAction() {
      this.goSearch = true;
    },
    changeAlbumOk: function changeAlbumOk(data) {
      if (data && data[0]) {
        this.changeAlbum = data[0];
      }
      console.log(this.changeAlbum);
      this.select = "".concat(this.changeAlbum.id);
      this.goSearch = false;
    }
  }
};