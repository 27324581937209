var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("div", { staticClass: "common-page-title" }, [
        _c("img", {
          staticClass: "back",
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024529/image/1716954194860207587.png!120a",
            alt: "返回",
          },
          on: { click: _vm.backAction },
        }),
        _vm._v("在线征集"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "征集标题", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入标题" },
                    model: {
                      value: _vm.workDetail.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "title", $$v)
                      },
                      expression: "workDetail.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "征集海报", prop: "image" } },
                [
                  _c("div", { staticClass: "flex align-items-c" }, [
                    _vm.workDetail.image
                      ? _c("div", { staticClass: "img-list" }, [
                          _c("img", {
                            attrs: { src: _vm.workDetail.image + "!120a" },
                            on: {
                              click: function ($event) {
                                return _vm.showImageAction(_vm.workDetail.image)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del",
                              on: {
                                click: function ($event) {
                                  return _vm.delImgAction("no")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c mr10",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg(1)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml10 color-gray ft12" }, [
                      _vm._v(
                        "图片格式支持：JPG、JPEG、PNG、GIF；文件大小≤20M；图片比例建议1:1"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "征集开始时间", prop: "start_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "date",
                      "value-format": "timestamp",
                      placeholder: "请选择征集开始时间",
                      align: "right",
                    },
                    model: {
                      value: _vm.workDetail.start_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "start_time", $$v)
                      },
                      expression: "workDetail.start_time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "征集结束时间", prop: "end_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "date",
                      "value-format": "timestamp",
                      placeholder: "请选择征集结束时间",
                      align: "right",
                    },
                    model: {
                      value: _vm.workDetail.end_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "end_time", $$v)
                      },
                      expression: "workDetail.end_time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "征集详情" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.workDetail.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "content", $$v)
                      },
                      expression: "workDetail.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showWork = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imagesKey + "!m640" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }