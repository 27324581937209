"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.fill");
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CustomerTask',
  data: function data() {
    return {
      improveInformation: false,
      improveInformationPoint: 1,
      improveInformationImg: 'https://artimg2.artart.cn/merchant/23/202389/image/169157976779424530.png',
      bingPhone: false,
      bingPhonePoint: 1,
      bingPhoneImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579767494877622.png',
      collectionXcx: false,
      collectionXcxPoint: 1,
      collectionXcxImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579767073579170.png',
      buyGoods: false,
      buyGoodsPrice: 1,
      buyGoodsPoint: 1,
      buyGoodsImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579842331455075.png',
      order: false,
      orderCount: 1,
      orderPoint: 1,
      orderImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579841995953809.png',
      vote: false,
      voteCount: 5,
      votePoint: 10,
      voteImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579841530798437.png',
      checkIn: false,
      checkInPoint: 1,
      checkInImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579913564402749.png',
      upImg: '',
      title: '任务中心',
      firstDay: false,
      firstDayPoint: 300,
      firstDayImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579913206294400.png',
      newPeople: false,
      newPeoplePoint: 500,
      newPeopleImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579912657541503.png',
      newPeopleCon: false,
      newPeopleConPoint: 1,
      newPeopleImgCon: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579912657541503.png',
      watchLive: false,
      watchLiveImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579912657541503.png',
      watchLivePoint: 1,
      shareLive: false,
      shareLivePoint: 1,
      shareLiveImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579912657541503.png',
      question: false,
      questionImg: 'https://artimg2.artart.cn/merchant/23/202389/image/1691579912657541503.png',
      questionPoint: 1,
      firstDayEvaluate: false,
      firstDayEvaluatePoint: 20,
      firstDayEvaluateImg: 'https://img.yunzongbu.cn/node/2019/111/1572616039579482415.png'
    };
  },
  created: function created() {
    this.load();
  },
  mounted: function mounted() {
    // this.setRing();
  },
  methods: {
    load: function load() {
      var _this = this;
      (0, _member.growthValueGetConfigApi)().then(function (res) {
        if (res.data.config_data) {
          _this.title = res.data.page_title_name;
          var m = res.data.config_data;
          if (m.complete_user_info) {
            _this.improveInformationPoint = m.complete_user_info.give_growth_value;
            _this.improveInformation = m.complete_user_info.status === 1;
            // this.improveInformationImg = m.pic;
          }
          if (m.bind_phone) {
            _this.bingPhonePoint = m.bind_phone.give_growth_value;
            _this.bingPhone = m.bind_phone.status === 1;
            // this.bingPhoneImg = m.pic;
            // } else if (m.ent === 'mic_app_fav') {
            //   this.collectionXcxPoint = m.pdcode;
            //   this.collectionXcx = true;
            //   this.collectionXcxImg = m.pic;
          }
          if (m.buy_product_amount) {
            _this.buyGoodsPrice = m.buy_product_amount.buy_amount || 1;
            _this.buyGoodsPoint = m.buy_product_amount.give_growth_value;
            _this.buyGoods = m.buy_product_amount.status === 1;
            // this.buyGoodsImg = m.pic;
          }
          if (m.buy_product_count) {
            _this.orderCount = m.buy_product_count.buy_count || 1;
            _this.orderPoint = m.buy_product_count.give_growth_value;
            _this.order = m.buy_product_count.status === 1;
            // this.orderImg = m.pic;
          }
          if (m.user_sign) {
            _this.checkInPoint = m.user_sign.give_growth_value || 1;
            _this.checkIn = m.user_sign.status === 1;
            // this.checkInImg = m.pic;
          }
          if (m.user_register) {
            _this.firstDayPoint = m.user_register.give_growth_value || 1;
            _this.firstDay = m.user_register.status === 1;
          }
          if (m.invite_new_user) {
            _this.newPeoplePoint = m.invite_new_user.give_growth_value || 1;
            _this.newPeople = m.invite_new_user.status === 1;
          }
          if (m.invite_new_user_consume) {
            _this.newPeopleConPoint = m.invite_new_user_consume.give_growth_value || 1;
            _this.newPeopleCon = m.invite_new_user_consume.status === 1;
          }
          if (m.watch_live_room) {
            _this.watchLivePoint = m.watch_live_room.give_growth_value || 1;
            _this.watchLive = m.watch_live_room.status === 1;
          }
          if (m.share_live_room) {
            _this.shareLivePoint = m.share_live_room.give_growth_value || 1;
            _this.shareLive = m.share_live_room.status === 1;
          }
          if (m.identification_question_answer_success) {
            _this.questionPoint = m.identification_question_answer_success.give_growth_value || 1;
            _this.question = m.identification_question_answer_success.status === 1;
          }
        } else {
          _this.improveInformation = true;
          _this.bingPhone = true;
          _this.buyGoods = true;
          _this.order = true;
          _this.checkIn = true;
          _this.firstDay = true;
          _this.newPeople = true;
        }
        if (res.data.top_image) {
          _this.upImg = res.data.top_image;
        }
      });
    },
    setRing: function setRing() {
      var c = document.getElementById('myCanvas');
      var ctx = c.getContext('2d');
      ctx.beginPath();
      ctx.arc(98, 70, 55, Math.PI * 1.1, Math.PI * 2.2, false);
      ctx.closePath();
      ctx.fillStyle = '#eee';
      ctx.fill();
      ctx.beginPath();
      ctx.arc(98, 70, 55, Math.PI * 0.8, Math.PI * 1.8, false);
      ctx.closePath();
      ctx.fillStyle = '#FA3D3D';
      ctx.fill();
      ctx.beginPath();
      ctx.arc(98, 70, 50, Math.PI * 0.8, Math.PI * 2.2, false);
      ctx.closePath();
      ctx.fillStyle = '#f8f8f8';
      ctx.fill();
    },
    clearAction: function clearAction() {
      this.improveInformation = false;
      this.improveInformationPoint = 1;
      this.bingPhone = false;
      this.bingPhonePoint = 1;
      this.collectionXcx = false;
      this.collectionXcxPoint = 1;
      this.buyGoods = false;
      this.buyGoodsPrice = 1;
      this.buyGoodsPoint = 1;
      this.order = false;
      this.orderCount = 1;
      this.orderPoint = 1;
      this.checkIn = false;
      this.checkInPoint = 1;
    },
    saveAction: function saveAction() {
      var _this2 = this;
      if (!this.title) {
        this.$message.error('请输入页面标题');
        return false;
      }
      var d = {
        complete_user_info: {
          status: 0,
          give_growth_value: this.improveInformationPoint
        },
        bind_phone: {
          status: 0,
          give_growth_value: this.bingPhonePoint
        },
        buy_product_amount: {
          status: 0,
          buy_amount: this.buyGoodsPrice,
          give_growth_value: this.buyGoodsPoint
        },
        buy_product_count: {
          status: 0,
          buy_count: this.orderCount,
          give_growth_value: this.orderPoint
        },
        user_sign: {
          status: 0,
          give_growth_value: this.checkInPoint
        },
        user_register: {
          status: 0,
          give_growth_value: this.firstDayPoint
        },
        invite_new_user: {
          status: 0,
          give_growth_value: this.newPeoplePoint
        },
        invite_new_user_consume: {
          status: 0,
          give_growth_value: this.newPeopleConPoint
        },
        watch_live_room: {
          status: 0,
          give_growth_value: this.watchLivePoint
        },
        share_live_room: {
          status: 0,
          give_growth_value: this.shareLivePoint
        },
        identification_question_answer_success: {
          status: 0,
          give_growth_value: this.questionPoint
        }
      };
      if (this.improveInformation || this.bingPhone || this.buyGoods || this.order || this.checkIn || this.collectionXcx || this.vote || this.firstDay || this.firstDayEvaluate || this.newPeople) {
        if (this.improveInformation) {
          if (!this.improveInformationPoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.complete_user_info.status = 1;
        }
        if (this.bingPhone) {
          if (!this.bingPhonePoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.bind_phone.status = 1;
        }
        // if (this.collectionXcx) {
        //   if (!this.collectionXcxPoint) {
        //     this.$message.error('请设置任务所得积分数');
        //     return false;
        //   }
        //   d = d.concat([{
        //     ent: 'mic_app_fav',
        //     status: 1,
        //     type: 1,
        //     pdcode: this.collectionXcxPoint,
        //     num: 1000000,
        //     pic: this.collectionXcxImg
        //   }]);
        // }
        if (this.buyGoods) {
          if (!this.buyGoodsPrice || !this.buyGoodsPoint) {
            this.$message.error('请设置任务条件以及所得积分数');
            return false;
          }
          d.buy_product_amount.status = 1;
        }
        if (this.order) {
          if (!this.orderCount || !this.orderPoint) {
            this.$message.error('请设置任务条件以及所得积分数');
            return false;
          }
          d.buy_product_count.status = 1;
        }
        // if (this.vote) {
        //   if (!this.voteCount || !this.votePoint) {
        //     this.$message.error('请设置任务条件以及所得积分数');
        //     return false;
        //   }
        //   d = d.concat([{
        //     ent: 'vote_count',
        //     status: 1,
        //     type: 1,
        //     cate_count: this.voteCount,
        //     pdcode: this.votePoint,
        //     num: 1000000,
        //     pic: this.voteImg
        //   }]);
        // }
        if (this.checkIn) {
          if (!this.checkInPoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.user_sign.status = 1;
        }
        if (this.newPeople) {
          if (!this.newPeoplePoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.invite_new_user.status = 1;
        }
        if (this.newPeopleCon) {
          if (!this.newPeopleConPoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.invite_new_user_consume.status = 1;
        }
        if (this.watchLive) {
          if (!this.watchLivePoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.watch_live_room.status = 1;
        }
        if (this.shareLive) {
          if (!this.shareLivePoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.share_live_room.status = 1;
        }
        if (this.question) {
          if (!this.questionPoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.identification_question_answer_success.status = 1;
        }
        if (this.firstDay) {
          if (!this.firstDayPoint) {
            this.$message.error('请设置任务所得积分数');
            return false;
          }
          d.user_register.status = 1;
        }
        // if (this.firstDayEvaluate) {
        //   if (!this.firstDayEvaluatePoint) {
        //     this.$message.error('请设置任务所得积分数');
        //     return false;
        //   }
        //   d = d.concat([{
        //     ent: 'first_evaluate',
        //     status: 1,
        //     type: 1,
        //     pdcode: this.firstDayEvaluatePoint,
        //     num: 1000000,
        //     pic: this.firstDayEvaluateImg
        //   }]);
        // }
        console.log(d);
        var loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        (0, _member.growthValueSetConfigApi)({
          page_title_name: this.title || '',
          config_data: d,
          top_image: this.upImg ? this.upImg : ''
        }).then(function () {
          loading.close();
          _this2.$message.success('操作成功');
        }).catch(function (msg) {
          loading.close();
        });
      } else {
        this.$message.error('最少选择一个任务');
        return false;
      }
      return false;
    },
    delUpImgAction: function delUpImgAction() {
      this.upImg = '';
    },
    uploadImg: function uploadImg(res, file, i) {
      if (i === 'upImg') {
        this.upImg = res.url;
      } else {
        this[i] = res.url;
      }
    },
    changeImg: function changeImg(key) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        _this3[key] = img[0];
      }, 1, '1');
    },
    showImageAction: function showImageAction(key) {
      this.imagesViewKey = key;
      this.showImage = true;
    },
    delImgAction: function delImgAction(key) {
      this[key] = '';
      this.$refs['ruleForm'].validateField(key);
    }
  }
};