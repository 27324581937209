"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _authenticate = require("@/api/authenticate");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
var _components = require("echarts/components");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/component/legendScroll");
require("echarts/lib/chart/pie");
echarts.use([_components.GridComponent]);
var SelectType = {
  "1": "Expert",
  "3": "PraisalReport",
  "4": "PraisalCategory",
  "5": "PraisalSetting",
  "2": "Appraisal",
  "6": "AppraisalComplaint"
};
var _default = exports.default = {
  name: "praisalReport",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      activeMenu: '3',
      chartDate: "",
      status: 0,
      uid: "",
      date: "",
      stime: "",
      etime: "",
      startDateOption: {
        disabledDate: this.setStartDate
      },
      endDateOption: {
        disabledDate: this.setEndDate
      },
      searchLoading: false,
      searchKey: "",
      announcerList: [],
      selectType: 1,
      isStudy: 1,
      alldata: {},
      loading: false,
      estime: "",
      eetime: "",
      token: localStorage.getItem("token"),
      resultData: [],
      total: 0,
      queryParams: {
        page: 1,
        limit: 10,
        select_type: 1
      },
      colorArr: ["red", "orange", "blue"],
      hasCategory: false,
      suid: ''
    };
  },
  mounted: function mounted() {
    this.getAllData();
    this.load();
    this.searchPost("");
    this.loadTableData();
    this.loadCategoryData();
  },
  watch: {
    status: {
      handler: function handler(nval) {
        var _this = this;
        this.$nextTick(function () {
          if (nval == 0) {
            !_this.orderEchart && (_this.orderEchart = echarts.init(document.getElementById("order")));
          }
          if (nval != 1) {
            !_this.moneyEchart && (_this.moneyEchart = echarts.init(document.getElementById("money")));
          }
          if (nval == 1) {
            !_this.peopleMoneyEchart && (_this.peopleMoneyEchart = echarts.init(document.getElementById("peopleMoney")));
          }
          if (nval == 0 && _this.hasCategory) {
            !_this.category && (_this.category = echarts.init(document.getElementById("category")));
          }
        });
      },
      immediate: true
    }
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      var _this2 = this;
      this.activeMenu = index;
      this.$router.push({
        name: SelectType[index]
      });
      setTimeout(function () {
        _this2.activeMenu = '3';
      });
    },
    formSubmit: function formSubmit(e) {
      if (this.cz) {
        this.cz = false;
        return false;
      }
      e.preventDefault();
      return false;
    },
    searchPost: function searchPost(data) {
      var _this3 = this;
      var params = {
        uname: data,
        page: 1,
        limit: 20
      };
      this.searchLoading = true;
      (0, _authenticate._getExpertData)(params).then(function (res) {
        if (res.status === 200) {
          _this3.announcerList = res.data.list;
        }
        _this3.searchLoading = false;
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeType: function changeType(type) {
      this.selectType = type;
      this.load();
      this.loadCategoryData();
    },
    getAllData: function getAllData() {
      var _this4 = this;
      (0, _authenticate._getQuestionData)().then(function (res) {
        if (res.status === 200) {
          _this4.alldata = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    loadPeopleData: function loadPeopleData() {
      var _this5 = this;
      var params = {};
      if (this.stime) {
        params.stime = new Date(this.stime).getTime() / 1000;
      }
      if (this.etime) {
        params.etime = new Date(this.etime).getTime() / 1000;
      }
      if (this.suid) {
        params.expert_uid = this.suid;
      }
      (0, _authenticate._getExpertTable)(params).then(function (res) {
        if (res.status === 200) {
          _this5.resultData = res.data.list;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    selectPeopleOrder: function selectPeopleOrder() {
      this.queryParams.page = 1;
      this.loadPeopleData();
    },
    loadTableData: function loadTableData() {
      var _this6 = this;
      var params = this.queryParams;
      (0, _authenticate._getQuestionTable)(params).then(function (res) {
        if (res.status === 200) {
          _this6.total = res.data.count;
          _this6.resultData = res.data.list;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    loadStudyTableData: function loadStudyTableData() {
      var _this7 = this;
      var params = this.queryParams;
      params.limit = this.queryParams.limit;
      (0, _authenticate._getQuestionStudyTable)(params).then(function (res) {
        if (res.status === 200) {
          _this7.total = res.data.count;
          _this7.resultData = res.data.list;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    loadCategoryData: function loadCategoryData() {
      var _this8 = this;
      var params = {
        select_type: this.selectType
      };
      (0, _authenticate._getQuestionCategory)(params).then(function (res) {
        if (res.status === 200) {
          if (!res.data.length) {
            _this8.hasCategory = false;
            return;
          }
          _this8.hasCategory = true;
          var legendData = [];
          var selected = false;
          var seriesData = [];
          res.data.forEach(function (obj) {
            legendData.push(obj.category_name);
            seriesData.push({
              name: obj.category_name,
              value: obj.total_order
            });
          });
          var options = {
            title: {
              text: "鉴定门类收益报表"
            },
            tooltip: {
              trigger: "item",
              formatter: function formatter(param) {
                return "".concat(param.seriesName, ":").concat(param.name, "</br> \u8BA2\u5355\u6570\u91CF\uFF1A").concat(param.data.value, "<br> \u91D1\u989D\uFF1A").concat(_this8.formatMoney(res.data[param.dataIndex].total_pay_money / 100, 2));
              }
            },
            legend: {
              type: "scroll",
              orient: "vertical",
              right: 10,
              top: 20,
              bottom: 20,
              data: legendData,
              selected: selected
            },
            series: [{
              name: "类别",
              type: "pie",
              radius: "55%",
              center: ["40%", "50%"],
              data: seriesData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            }]
          };
          !_this8.category && (_this8.category = echarts.init(document.getElementById("category")));
          _this8.category.setOption(options);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeTableType: function changeTableType(type) {
      this.queryParams.select_type = type;
      this.queryParams.page = 1;
      if (this.status === 2) {
        this.loadStudyTableData();
      } else {
        this.loadTableData();
      }
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.limit = size;
      this.queryParams.page = 1;
      this.loadTableData();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: newPage
        }
      });
      this.loadTableData();
    },
    onChangeDate: function onChangeDate(e) {
      this.date = e;
    },
    getExportFileList: function getExportFileList(type) {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    },
    exportAction: function exportAction(type) {
      var _this9 = this;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      params.limit = this.queryParams.limit;
      if (this.stime) {
        params.stime = new Date(this.stime).getTime() / 1000;
      }
      if (this.etime) {
        params.etime = new Date(this.etime).getTime() / 1000;
      }
      if (this.suid && type == 'identificationExpertReport') {
        params.expert_uid = this.suid;
      }
      // 导出
      (0, _system.exportexcel)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
        export_type: type
      })).then(function (res) {
        var h = _this9.$createElement;
        _this9.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this9.$msgbox.close();
                _this9.getExportFileList(type);
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    load: function load() {
      var _this10 = this;
      var params = {
        select_type: this.selectType,
        is_study_order: this.isStudy
      };
      //   if (this.chartDate) {
      //     params.day = this.$formatData(this.chartDate, 1);
      //   } else {
      //     params.day = this.$formatData(new Date(), 1);
      //   }
      if (this.isStudy !== 2) {
        params.expert_uid = this.uid;
      }
      if (this.date && this.date.length) {
        params.date = this.date.join("-");
      } else {
        params.date = "";
      }
      (0, _authenticate._getQuestionOrder)(params).then(function (res) {
        if (res.status === 200) {
          _this10.orderEchart = echarts.init(document.getElementById("order"));
          _this10.moneyEchart = echarts.init(document.getElementById("money"));
          if (res.data.length) {
            var datearr = [];
            var orderarr = [];
            var overorderarr = [];
            var returnorderarr = [];
            var moneyarr = [];
            var overmoenyarr = [];
            var returnmkoneyarr = [];
            var peopleMoneyArr = [];
            res.data.forEach(function (obj) {
              datearr.push(obj.times);
              orderarr.push(obj.total_order);
              overorderarr.push(obj.total_over_order);
              returnorderarr.push(obj.total_refund_order);
              moneyarr.push(obj.total_pay_money);
              peopleMoneyArr.push(obj.expert_reward);
              if (_this10.isStudy !== 2) {
                overmoenyarr.push(obj.total_over_pay_money);
                returnmkoneyarr.push(obj.total_refund_pay_money);
              }
            });
            var option = {
              xAxis: {
                type: "category",
                data: datearr,
                name: "日期"
              },
              color: _this10.colorArr,
              title: {
                text: "订单"
              },
              tooltip: {
                trigger: "axis"
              },
              legend: {
                data: [{
                  name: "总订单数量"
                }, {
                  name: "订单完成数量"
                }, {
                  name: "退款数量"
                }]
              },
              yAxis: {
                type: "value",
                name: "个数"
              },
              series: [{
                data: orderarr,
                type: "line",
                name: "总订单数量",
                stack: "总量"
                // label: {
                //   normal: {
                //     show: true,
                //     position: 'top'
                //   }
                // },
              }, {
                data: overorderarr,
                type: "line",
                name: "订单完成数量"
              }, {
                data: returnorderarr,
                type: "line",
                name: "退款数量"
              }]
            };
            var option1 = {
              xAxis: {
                type: "category",
                data: datearr,
                name: "日期"
              },
              title: {
                text: "金额"
              },
              color: _this10.colorArr,
              legend: {
                data: [{
                  name: _this10.isStudy === 2 ? "偷学收益" : "总订单金额"
                }, {
                  name: _this10.isStudy === 2 ? "偷学" : "订单完成金额"
                }, {
                  name: _this10.isStudy === 2 ? "偷学" : "退款金额"
                }]
              },
              tooltip: {
                trigger: "axis"
              },
              yAxis: {
                type: "value",
                name: "金额"
              },
              series: [{
                data: moneyarr,
                type: "line",
                // label: {
                //   normal: {
                //     show: true,
                //     position: 'top'
                //   }
                // },
                name: "总订单金额"
              }, {
                data: overmoenyarr,
                type: "line",
                name: "订单完成金额"
              }, {
                data: returnmkoneyarr,
                type: "line",
                name: "退款金额"
              }]
            };
            var option2 = {
              xAxis: {
                type: "category",
                data: datearr,
                name: "日期"
              },
              color: _this10.colorArr,
              title: {
                text: "专家收益金额趋势"
              },
              tooltip: {
                trigger: "axis"
              },
              legend: {
                data: [{
                  name: "专家总订单金额"
                }, {
                  name: "已完成订单金额"
                }, {
                  name: "订单退款金额"
                }]
              },
              yAxis: {
                type: "value",
                name: "金额"
              },
              series: [{
                data: moneyarr,
                type: "line",
                name: "专家总订单金额",
                stack: "总量"
                // label: {
                //   normal: {
                //     show: true,
                //     position: 'top'
                //   }
                // },
              }, {
                data: peopleMoneyArr,
                type: "line",
                name: "已完成订单金额"
              }, {
                data: returnmkoneyarr,
                type: "line",
                name: "订单退款金额"
              }]
            };
            _this10.orderEchart.setOption(option);
            !_this10.moneyEchart && (_this10.moneyEchart = echarts.init(document.getElementById("money")));
            _this10.moneyEchart.setOption(option1);
            !_this10.peopleMoneyEchart && (_this10.peopleMoneyEchart = echarts.init(document.getElementById("peopleMoney")));
            _this10.peopleMoneyEchart.setOption(option2);
          } else {
            var _option = {
              xAxis: {
                type: "category",
                data: [""],
                name: "日期"
              },
              yAxis: {
                type: "value",
                name: "个数"
              },
              series: [{
                data: [""],
                type: "line"
              }]
            };
            var _option2 = {
              xAxis: {
                type: "category",
                data: [""],
                name: "日期"
              },
              yAxis: {
                type: "value",
                name: "金额"
              },
              series: [{
                data: [""],
                type: "line"
              }]
            };
            _this10.orderEchart.setOption(_option);
            _this10.moneyEchart.setOption(_option2);
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeStateEvent: function changeStateEvent(status) {
      this.status = status;
      if (status === 2) {
        this.isStudy = 2;
        this.queryParams.page = 1;
        this.loadStudyTableData();
      } else {
        //  2为偷学  1为未偷学
        this.isStudy = 1;
        if (status === 1) {
          this.queryParams.page = 1;
          this.loadPeopleData();
        } else {
          this.queryParams.page = 1;
          this.loadTableData();
        }
      }
      this.load();
    },
    formatMoney: function formatMoney(money, decimal) {
      var decimal1 = decimal > 0 && decimal <= 20 ? decimal : 2;
      var money2 = money;
      var money1 = "".concat(parseFloat("".concat(money2).replace(/[^\d-]/g, "")).toFixed(decimal1));
      var d = money1.split(".")[0].split("").reverse();
      //   const y = `.${money1.split('.')[1]}`;
      var q = "";
      for (var i = 0; i < d.length; i += 1) {
        q += d[i] + ((i + 1) % 3 === 0 && i + 1 !== d.length ? "," : "");
      }
      return "".concat(q.split("").reverse().join(""));
    }
  }
};