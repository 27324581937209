"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _questionnaire = require("@/api/questionnaire");
var _request = _interopRequireDefault(require("@/api/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "askList",
  data: function data() {
    return {
      list: [],
      refuseFlag: false,
      row: {},
      remark: "",
      beginTime: "",
      lastTime: "",
      changeSize: 10,
      startDateOption: {
        disabledDate: this.setStartDate
      },
      endDateOption: {
        disabledDate: this.setEndDate
      },
      total: 0,
      currentPage: 1,
      queryParams: {
        page: 1,
        size: 20
      },
      status: 0,
      title: "",
      showFlag: false,
      showDetail: {},
      miniFlag: false,
      miniurl: ""
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var params = {
        page: this.queryParams.page,
        limit: this.queryParams.size
      };
      if (this.title) {
        params.title = this.title;
      }
      (0, _questionnaire._gerQuestionnaireList)(params).then(function (res) {
        if (res.status === 200) {
          _this.list = res.data.list;
          _this.total = res.data.count;
          _this.currentPage = res.data.current_page;
        }
      }).catch(function (err) {
        _this.$message.error(err.message);
      });
    },
    getSearchList: function getSearchList() {
      this.queryParams.page = 1;
      this.getList();
    },
    goAddAsk: function goAddAsk() {
      this.$router.push({
        path: "./createAsk"
      });
    },
    editAsk: function editAsk(row) {
      this.$router.push({
        path: "./createAsk",
        query: {
          id: row.naire_id
        }
      });
    },
    deleteAsk: function deleteAsk(row) {
      var _this2 = this;
      var params = {
        naireId: row.naire_id
      };
      (0, _questionnaire._deleteQuestionnaire)(params).then(function (res) {
        console.log(res);
        if (res.status === 200) {
          _this2.$message.success("删除成功");
          _this2.getList();
        } else {
          _this2.$message.error(res.message);
        }
      }).catch(function (err) {
        _this2.$message.error(err.message);
      });
    },
    onChangeStatus: function onChangeStatus(row) {
      var _this3 = this;
      var params = {
        naireId: row.naire_id,
        status: 1
      };
      (0, _questionnaire._changeStatus)(params).then(function (res) {
        if (res.status === 200) {
          _this3.$message.success("操作成功");
          _this3.getList();
        } else {
          _this3.$message.error(res.message);
        }
      }).catch(function (err) {
        _this3.$message.error(err.message);
      });
    },
    goGetAsk: function goGetAsk(row) {
      if (row.answer_count > 0) {
        this.$router.push({
          path: "./getAsk",
          query: {
            id: row.naire_id
          }
        });
      } else {
        this.$message.error("回收数量为0,不可查看");
      }
    },
    showImgAction: function showImgAction(m, index) {
      var _this4 = this;
      var action = process.env.VUE_APP_BASE_API + "/api/sharePoster";
      var mer_id = this.$store.getters.mer_id;
      switch (index) {
        case 1:
          action += "?return_format=2&poster_type=questionnaire&qrcode_type=1&Independent_qrcode=1&id=" + m.naire_id + '&path=' + encodeURIComponent('/pages/otherPage/otherPage?link=' + encodeURIComponent('/pages/questionnaire/questionnaire?id=' + m.naire_id)) + "&mer_id=" + mer_id;
          break;
        case 2:
          action += "?return_format=2&poster_type=questionnaire&qrcode_type=2&Independent_qrcode=1&id=" + m.naire_id + "&mer_id=" + mer_id;
          break;
        case 3:
          action += "?return_format=2&poster_type=auction&qrcode_type=1&Independent_qrcode=0&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
        case 4:
          action += "?return_format=2&poster_type=auction&qrcode_type=2&Independent_qrcode=0&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
        default:
          action += "?return_format=2&poster_type=auction&qrcode_type=1&Independent_qrcode=1&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;

      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        if (index == 1) {
          _this4.miniFlag = true;
          _this4.miniurl = res.data.base_image;
        } else {
          _this4.showFlag = true;
          _this4.showDetail = {
            h5_url: "/pages/questionnaire/questionnaire?id=".concat(m.naire_id),
            qr_code: res.data.base_image
          };
        }
      }).catch(function (err) {
        _this4.miniFlag = false;
        _this4.showFlag = false;
        loading.close();
      });
    },
    selectStatus: function selectStatus(name) {
      var status = 0;
      if (name === "1") {
        status = 2;
      } else if (name === "2") {
        status = 1;
      } else if (name === "3") {
        status = -1;
      }
      this.status = status;
      this.queryParams.page = 1;
      this.getList();
    },
    setEndDate: function setEndDate(date) {
      // 设置结束时间规则 大于开始时间
      if (this.beginTime) {
        return date && date.valueOf() < Date.parse(this.beginTime).valueOf();
      }
      return "";
    },
    setStartDate: function setStartDate(date) {
      // 设置开始时间规则 小于结束时间
      if (this.lastTime) {
        return date && date.valueOf() > Date.parse(this.lastTime).valueOf();
      }
      return "";
    },
    changeStartDate: function changeStartDate(data) {
      // 筛选条件选择时间
      this.beginTime = data;
    },
    changeEndDate: function changeEndDate(data) {
      // 筛选条件选择时间
      this.lastTime = data;
    },
    changePageSize: function changePageSize(pageSize) {
      this.queryParams.size = pageSize;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: 1,
          size: pageSize
        }
      });
      this.getList();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: newPage,
          size: this.queryParams.size
        }
      });
      this.getList();
    }
  },
  created: function created() {
    this.getList();
  }
};