var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "战机名称：", prop: "aircraft_name" } },
                [
                  _c("el-input", {
                    staticClass: "selwidth",
                    attrs: { placeholder: "请输入战机名称" },
                    model: {
                      value: _vm.formValidate.aircraft_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "aircraft_name", $$v)
                      },
                      expression: "formValidate.aircraft_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "战机图片：",
                    prop: "aircraft_img",
                    required: "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      attrs: { title: "正方形图片" },
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "aircraft_img")
                        },
                      },
                    },
                    [
                      _vm.formValidate.aircraft_img
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.aircraft_img + "!120a",
                              },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("战机图片")]),
                          ]),
                    ]
                  ),
                  _vm._v("\n                比例1:1,png透明图片\n            "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "星球名称", prop: "planet_name" } },
                [
                  _c("el-input", {
                    staticClass: "selwidth",
                    attrs: { placeholder: "请输入星球名称" },
                    model: {
                      value: _vm.formValidate.planet_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "planet_name", $$v)
                      },
                      expression: "formValidate.planet_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "星球图片：",
                    prop: "planet_img",
                    required: "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      attrs: { title: "正方形图片" },
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "planet_img")
                        },
                      },
                    },
                    [
                      _vm.formValidate.planet_img
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.formValidate.planet_img + "!120a",
                              },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("星球图片")]),
                          ]),
                    ]
                  ),
                  _vm._v("\n                比例1:1,png透明图片\n            "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "耐久度", prop: "durable" } },
                [
                  _c("el-input-number", {
                    staticClass: "selwidth",
                    attrs: {
                      min: 1,
                      controls: true,
                      placeholder: "请输入耐久度",
                    },
                    model: {
                      value: _vm.formValidate.durable,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "durable", $$v)
                      },
                      expression: "formValidate.durable",
                    },
                  }),
                  _vm._v(
                    "\n                耐久度只能输入数字,即每架战机的可可采矿次数\n            "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "成功率", prop: "success_rate" } },
                [
                  _c("el-input-number", {
                    staticClass: "selwidth",
                    attrs: {
                      size: "normal",
                      min: 1,
                      max: 100,
                      step: 1,
                      controls: true,
                      "controls-position": "both",
                    },
                    model: {
                      value: _vm.formValidate.success_rate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "success_rate", $$v)
                      },
                      expression: "formValidate.success_rate",
                    },
                  }),
                  _vm._v(" %\n               \n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "挖矿时长", prop: "duration" } },
                [
                  _c("el-input-number", {
                    staticClass: "selwidth",
                    attrs: {
                      min: 1,
                      placeholder: "请输入挖矿时长",
                      controls: false,
                    },
                    model: {
                      value: _vm.formValidate.duration,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "duration", $$v)
                      },
                      expression: "formValidate.duration",
                    },
                  }),
                  _vm._v("  分钟\n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产出", required: "" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.output, border: "", stripe: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "名称", "min-width": "140" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.name
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                            staticStyle: {
                                              "flex-shrink": "0",
                                              "margin-right": "6px",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image + "!120a",
                                                "preview-src-list": [
                                                  scope.row.aircraft_img,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(" " + _vm._s(scope.row.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "output",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("更换")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "output",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "库存" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(scope.row.stock || "") +
                                    "\n                       "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产出数量" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 1,
                                    step: 1,
                                    controls: true,
                                    "controls-position": "both",
                                  },
                                  model: {
                                    value: scope.row.num,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "num", $$v)
                                    },
                                    expression: "scope.row.num",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产出概率" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    disabled: scope.$index == 0,
                                    step: 1,
                                    controls: true,
                                    "controls-position": "both",
                                    max: 100,
                                  },
                                  on: { change: _vm.changerate },
                                  model: {
                                    value: scope.row.rate,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "rate", $$v)
                                    },
                                    expression: "scope.row.rate",
                                  },
                                }),
                                _vm._v(
                                  "\n                            %\n                        "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.$index > 0
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "default",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.dellist(
                                                "output",
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.addlist("output")
                            },
                          },
                        },
                        [_vm._v("+添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._v("\n                        全部产出 "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": "1",
                              "inactive-value": "2",
                            },
                            model: {
                              value: _vm.outtype,
                              callback: function ($$v) {
                                _vm.outtype = $$v
                              },
                              expression: "outtype",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "兑换条件", required: "" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.condition, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "名称", "min-width": "140" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.name
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                            staticStyle: {
                                              "flex-shrink": "0",
                                              "margin-right": "6px",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image + "!120a",
                                                "preview-src-list": [
                                                  scope.row.aircraft_img,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(" " + _vm._s(scope.row.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "condition",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("更换")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "condition",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "需要数量" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 1,
                                    step: 1,
                                    controls: true,
                                    "controls-position": "both",
                                  },
                                  model: {
                                    value: scope.row.neednum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "neednum", $$v)
                                    },
                                    expression: "scope.row.neednum",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return scope.$index > 0
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "default",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.dellist(
                                                "condition",
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.addlist("condition")
                            },
                          },
                        },
                        [_vm._v("+添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._v("\n                        需满足全部条件 "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": "1",
                              "inactive-value": "2",
                            },
                            model: {
                              value: _vm.conditiontype,
                              callback: function ($$v) {
                                _vm.conditiontype = $$v
                              },
                              expression: "conditiontype",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "补充耐久度" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.durability, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { label: "名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.name
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                            staticStyle: {
                                              "flex-shrink": "0",
                                              "margin-right": "6px",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.image + "!120a",
                                                "preview-src-list": [
                                                  scope.row.aircraft_img,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(" " + _vm._s(scope.row.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "durability",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("更换")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  "durability",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "需要数量" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: {
                                    min: 1,
                                    step: 1,
                                    controls: true,
                                    "controls-position": "both",
                                  },
                                  model: {
                                    value: scope.row.neednum,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "neednum", $$v)
                                    },
                                    expression: "scope.row.neednum",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "default" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.dellist(
                                          "durability",
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.addlist("durability")
                            },
                          },
                        },
                        [_vm._v("+添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._v("\n                        需满足全部条件 "),
                          _c("el-switch", {
                            attrs: {
                              "active-value": "1",
                              "inactive-value": "2",
                            },
                            model: {
                              value: _vm.durabilitytype,
                              callback: function ($$v) {
                                _vm.durabilitytype = $$v
                              },
                              expression: "durabilitytype",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("\n                   提交\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }