var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        backgroundColor: _vm.facade.pageBackground,
        padding:
          _vm.facade.pagePaddingTop +
          "px " +
          _vm.facade.pagePaddingRight +
          "px " +
          _vm.facade.pagePaddingBottom +
          "px " +
          _vm.facade.pagePaddingLeft +
          "px",
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
        borderWidth:
          _vm.facade.borderTop +
          "px " +
          _vm.facade.borderRight +
          "px " +
          _vm.facade.borderBottom +
          "px " +
          _vm.facade.borderLeft +
          "px",
        borderStyle: "solid",
        borderColor: _vm.facade.borderColor,
        overflowY: "auto",
        fontFamily: "serif",
      },
    },
    [
      _c(
        "section",
        {
          staticStyle: {
            "text-align": "center",
            "font-size": "32px",
            "font-weight": "900",
            "letter-spacing": "5px",
          },
        },
        [_vm._v(_vm._s(_vm.content.title))]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticStyle: {
            "border-top": ".5px solid #f1f1f1",
            "margin-top": "20px",
          },
        },
        [
          _c(
            "section",
            {
              staticStyle: {
                "margin-top": "30px",
                "text-align": "center",
                "letter-spacing": "4px",
                "font-size": "18px",
                "font-weight": "600",
              },
            },
            [_vm._v("征集门类")]
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticStyle: {
                "margin-top": "8px",
                "font-size": "13px",
                display: "flex",
                "justify-content": "center",
                "flex-wrap": "wrap",
              },
            },
            _vm._l(_vm.content.mer_collection_category, function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticStyle: { padding: "3px 7px", display: "block" },
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticStyle: {
                "margin-top": "30px",
                "text-align": "center",
                "letter-spacing": "4px",
                "font-size": "18px",
                "font-weight": "600",
              },
            },
            [_vm._v("征集邮箱")]
          ),
          _vm._v(" "),
          _vm.content.mer_collection_email_data &&
          _vm.content.mer_collection_email_data.length > 0
            ? _c(
                "section",
                {
                  staticStyle: {
                    "margin-top": "14px",
                    "text-align": "center",
                    "font-size": "13px",
                    display: "flex",
                    "justify-content": "center",
                    "flex-wrap": "wrap",
                    width: "100%",
                  },
                },
                _vm._l(
                  _vm.content.mer_collection_email_data,
                  function (item, index) {
                    return _c(
                      "section",
                      {
                        key: index,
                        staticStyle: { width: "50%", "margin-bottom": "7px" },
                      },
                      [
                        (index + 1) % 2 == 1 &&
                        index ==
                          _vm.content.mer_collection_email_data.length - 1
                          ? _c(
                              "section",
                              {
                                staticStyle: {
                                  display: "block",
                                  width: "100%",
                                  "text-align": "center",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.email) + " " + _vm._s(item.name)
                                ),
                              ]
                            )
                          : (index + 1) % 2 == 0
                          ? _c(
                              "section",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "start",
                                  "align-items": "center",
                                  width: "100%",
                                },
                              },
                              [
                                _c("section", {
                                  staticStyle: {
                                    display: "inline-block",
                                    height: "10px",
                                    "border-left": "1.5px solid #f1f1f1",
                                    "margin-right": "7px",
                                    "margin-top": "2px",
                                  },
                                }),
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.email) +
                                    " " +
                                    _vm._s(item.name)
                                ),
                              ]
                            )
                          : (index + 1) % 2 == 1
                          ? _c(
                              "section",
                              {
                                staticStyle: {
                                  display: "flex",
                                  width: "100%",
                                  "justify-content": "end",
                                  "padding-right": "9px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.email) +
                                    " " +
                                    _vm._s(item.name) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticStyle: {
            "border-top": ".5px solid #f1f1f1",
            "margin-top": "30px",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "section",
            {
              staticStyle: {
                "text-align": "center",
                "font-size": "14px",
                "margin-top": "4px",
              },
            },
            [_vm._v(_vm._s(_vm.content.company_address))]
          ),
          _vm._v(" "),
          _c("section", { staticStyle: { display: "flex" } }, [
            _c("section", { staticStyle: { flex: "1" } }, [
              _c(
                "section",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "text-align": "center",
                    "letter-spacing": "4px",
                    "font-size": "16px",
                    "font-weight": "600",
                  },
                },
                [_vm._v("联系电话")]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  staticStyle: {
                    "text-align": "center",
                    "font-size": "14px",
                    "margin-top": "4px",
                  },
                },
                [_vm._v(_vm._s(_vm.content.contact_phone))]
              ),
            ]),
            _vm._v(" "),
            _c("section", { staticStyle: { flex: "1" } }, [
              _c(
                "section",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    "text-align": "center",
                    "letter-spacing": "4px",
                    "font-size": "16px",
                    "font-weight": "600",
                  },
                },
                [_vm._v("官网")]
              ),
              _vm._v(" "),
              _c(
                "section",
                {
                  staticStyle: {
                    "text-align": "center",
                    "font-size": "14px",
                    "margin-top": "4px",
                  },
                },
                [_vm._v(_vm._s(_vm.content.mer_pc_website))]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticStyle: {
            "border-top": ".5px solid #f1f1f1",
            "margin-top": "20px",
          },
        },
        [
          _vm.content.mer_media_make_logo_data &&
          _vm.content.mer_media_make_logo_data.length > 0
            ? _c(
                "section",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "flex-wrap": "wrap",
                    margin: "30px 10px 0",
                    "font-size": "12px",
                  },
                },
                _vm._l(
                  _vm.content.mer_media_make_logo_data,
                  function (item, index) {
                    return _c(
                      "section",
                      {
                        key: index,
                        staticStyle: {
                          width: "25%",
                          "margin-bottom": "20px",
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "center",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "40px", height: "40px" },
                          attrs: { src: item.imgUrl },
                        }),
                        _vm._v(" "),
                        _c("section", { staticStyle: { margin: "10px 0" } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("section", [_vm._v(_vm._s(item.account))]),
                      ]
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.content.mer_qrcode_data && _vm.content.mer_qrcode_data.length > 0
            ? _c(
                "section",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "font-size": "10px",
                    "margin-top": "20px",
                  },
                },
                _vm._l(_vm.content.mer_qrcode_data, function (item, index) {
                  return _c(
                    "section",
                    {
                      key: index,
                      staticStyle: {
                        "text-align": "center",
                        "margin-right": "20px",
                      },
                      style:
                        _vm.content.mer_qrcode_data.length - 1 == index
                          ? "margin-right: 0;"
                          : "",
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "60px", height: "60px" },
                        attrs: { src: item.imgUrl + "!120a" },
                      }),
                      _vm._v(" "),
                      _c("section", [_vm._v(_vm._s(item.name))]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticStyle: {
          "margin-top": "30px",
          "text-align": "center",
          "font-size": "18px",
          "font-weight": "600",
        },
      },
      [
        _c("span", [_vm._v("地")]),
        _c("span", { staticStyle: { "padding-left": "20px" } }, [_vm._v("址")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }