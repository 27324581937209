"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    colour: _colour.default
  },
  data: function data() {
    return {
      background_color: "#2e3b58",
      background_image: "",
      main_color: "#ffffff",
      word_color: "#ffffff",
      gap: "3",
      transition: "0",
      showvideo: false,
      selectall: [],
      showmodel: "1",
      second: "3",
      origin: "1",
      main_image: "",
      edition_url: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)("setting_led_config").then(function (res) {
      if (res.data.led_data) {
        var leddata = JSON.parse(res.data.led_data);
        _this.background_color = leddata.background_color;
        _this.main_color = leddata.main_color;
        _this.word_color = leddata.word_color;
        _this.gap = leddata.gap;
        _this.transition = leddata.transition;
        _this.second = leddata.second;
        _this.showvideo = leddata.showvideo;
        _this.selectall = leddata.selectall;
        _this.showmodel = leddata.showmodel;
        _this.origin = leddata.origin;
        _this.background_image = leddata.background_image || "";
        _this.main_image = leddata.main_image || "";
      }
    });
  },
  methods: {
    save: function save() {
      var _this2 = this;
      var configjson = {
        background_color: this.background_color,
        main_color: this.main_color,
        word_color: this.word_color,
        gap: this.gap,
        transition: this.transition,
        showvideo: this.showvideo,
        selectall: this.selectall,
        showmodel: this.showmodel,
        second: this.second,
        origin: this.origin,
        background_image: this.background_image,
        main_image: this.main_image,
        edition_url: this.edition_url
      };
      (0, _system.configClassKeysSave)("setting_led_config", {
        led_data: JSON.stringify(configjson)
      }).then(function () {
        _this2.$message.success("保存成功");
      }).catch(function () {});
    },
    refresh: function refresh() {
      this.background_color = "#2e3b58";
      this.main_color = "#ffffff";
      this.word_color = "#ffffff";
      this.gap = "3";
      this.transition = "0";
      this.showvideo = false;
      this.selectall = [];
      this.showmodel = "1";
      this.second = "3";
      this.origin = "1";
      this.background_image = "";
      this.main_image = "";
    },
    delImg: function delImg(key) {
      this[key] = "";
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, key, key1) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        if (key1) {
          _this3[key] = img[0];
        } else {
          _this3[key] = img[0];
        }
      }, tit);
    }
  }
};