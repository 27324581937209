"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _llpay = _interopRequireDefault(require("./llpay.js"));
var _freight = require("@/api/freight");
var _lianlianpay = require("@/api/lianlianpay");
var _SendVerifyCode = _interopRequireDefault(require("@/utils/SendVerifyCode"));
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getCityApi} from "@/api/system";
var _default = exports.default = {
  name: "llpay",
  mixins: [_SendVerifyCode.default],
  data: function data() {
    return {
      currentTab: 0,
      opentype: "",
      cityList: [],
      fullscreenLoading: false,
      ruleValidate: {
        type: [{
          required: true,
          trigger: "change",
          message: "请选择开户类型"
        }],
        mobile: [{
          required: true,
          trigger: "blur",
          message: "请输入手机号"
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: "请输入正确的手机号"
        }],
        code: [{
          required: true,
          trigger: "blur",
          message: "请输入验证码"
        }, {
          pattern: /^\d{6}$/,
          message: "请输入6位数字验证码"
        }],
        "basicInfo.real_name": [{
          required: true,
          trigger: "blur",
          message: "请输入真实姓名"
        }
        // {
        //   pattern: /^[\u4e00-\u9fa5]{2,4}$/,
        //   message: "请输入正确的真实姓名",
        // },
        ],
        "basicInfo.idcard": [{
          required: true,
          trigger: "blur",
          message: "请输入身份证号"
        }, {
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          message: "请输入正确的身份证号"
        }],
        "basicInfo.org_code": [{
          required: true,
          trigger: "blur",
          message: "请输入证件号码"
        }],
        "basicInfo.org_exp": [{
          required: true,
          trigger: "change",
          message: "请选择到期时间"
        }],
        "basicInfo.expiration_date": [{
          required: true,
          trigger: "change",
          message: "请选择到期时间"
        }],
        "basicInfo.business_licene_photo": [{
          required: true,
          trigger: "change",
          message: "请选择图片"
        }],
        "basicInfo.org_address": [{
          required: true,
          trigger: "blur",
          message: "请输入企业地址"
        }],
        "basicInfo.org_email": [{
          required: true,
          trigger: "blur",
          message: "请输入注册邮箱"
        }],
        "linkedAcctInfo.bank_card_id": [{
          required: true,
          trigger: "blur",
          message: "请输入银行卡号"
        }],
        "linkedAcctInfo.bank_card_phone": [{
          required: true,
          trigger: "blur",
          message: "请输入预留手机号"
        }],
        "linkedAcctInfo.linked_accttype": [{
          required: true,
          trigger: "change",
          message: "请选择类型"
        }],
        "linkedAcctInfo.linked_acctno": [{
          required: true,
          trigger: "blur",
          message: "请输入银行卡号"
        }],
        "linkedAcctInfo.linked_bankcode": [{
          required: true,
          trigger: "change",
          message: "请选择银行编码"
        }],
        "linkedAcctInfo.linked_brbankname": [{
          required: true,
          trigger: "blur",
          message: "请输入对公账户开户行名"
        }],
        "linkedAcctInfo.linked_brbankno": [{
          required: true,
          trigger: "blur",
          message: "请输入对公账户开户行号"
        }],
        "linkedAcctInfo.linked_acctname": [{
          required: true,
          trigger: "blur",
          message: "请输入对公账户名"
        }],
        "linkedAcctInfo.linked_phone": [{
          required: true,
          trigger: "blur",
          message: "请输入预留手机号"
        }],
        "legalreptInfo.legalrept_name": [{
          required: true,
          trigger: "blur",
          message: "请输入法人姓名"
        }],
        "legalreptInfo.legalrept_phone": [{
          required: true,
          trigger: "blur",
          message: "请输入法人手机号"
        }],
        "legalreptInfo.legalrept_idcard": [{
          required: true,
          trigger: "blur",
          message: "请输入法人证件号"
        }],
        "legalreptInfo.idcard_face": [{
          required: true,
          trigger: "change",
          message: "请选择图片"
        }],
        "legalreptInfo.idcard_back": [{
          required: true,
          trigger: "change",
          message: "请选择图片"
        }],
        "legalreptInfo.expiration_date": [{
          required: true,
          trigger: "change",
          message: "请选择时间"
        }],
        "contactsInfo.contacts_name": [{
          required: true,
          trigger: "blur",
          message: "请输入联系人姓名"
        }],
        "contactsInfo.contacts_phone": [{
          required: true,
          trigger: "blur",
          message: "请输入联系人电话"
        }]
      },
      formValidate: {
        type: 2,
        cardtype: 1,
        exptype: 1,
        mobile: "",
        code: "",
        basicInfo: {
          phone: "",
          real_name: "",
          idcard: "",
          expiration_date: "",
          business_licene_photo: ""
        },
        linkedAcctInfo: {
          bank_card_id: "",
          bank_card_phone: ""
        },
        legalreptInfo: {
          idcard_face: "",
          idcard_back: "",
          legalrept_id_type: "ID_CARD" // 身份证类型 
        },
        contactsInfo: {},
        basicAcctInfo: {}
      },
      loading: false,
      vcode: "",
      lldisabled: false,
      statusdisabled: false,
      codeList: [],
      bankcodeList: [],
      citycode: '',
      bank_code: '',
      brabank_name: '',
      props: {
        children: 'children',
        label: 'name',
        value: 'area_code',
        multiple: false
      },
      status: 'WAIT_COMMIT'
    };
  },
  created: function created() {
    var _this = this;
    (0, _llpay.default)().then(function (res) {
      console.log("加载完成");
      (0, _lianlianpay.getPasswordRandomFactor)().then(function (res) {
        console.log(res);
        _this.getrandomKey(res.data);
      });
    });
    (0, _lianlianpay.getOpenAccountInfo)().then(function (res) {
      if (res.data && res.data.open_status) {
        _this.formValidate.type = res.data.account_type;
        _this.lldisabled = true;
        _this.formValidate.mobile = res.data.basic_info.phone;
        _this.formValidate.basicInfo = res.data.basic_info;
        _this.formValidate.linkedAcctInfo = res.data.linked_acct_info;
        _this.formValidate.legalreptInfo = res.data.legalrept_info;
        _this.formValidate.legalreptInfo.legalrept_id_type = res.data.legalrept_info.legalrept_id_type || 'ID_CARD';
        if (res.data.legalrept_info && res.data.legalrept_info.expiration_date) {
          if (/9999/.test(res.data.legalrept_info.expiration_date)) {
            _this.formValidate.cardtype = 2;
            _this.formValidate.legalreptInfo.expiration_date = '';
          } else {
            _this.formValidate.cardtype = 1;
          }
        }
        if (res.data.basic_info.org_exp) {
          if (/9999/.test(res.data.basic_info.org_exp)) {
            _this.formValidate.exptype = 2;
            _this.formValidate.basicInfo.org_exp = '';
          } else {
            _this.formValidate.exptype = 1;
          }
        }
        _this.formValidate.contactsInfo = res.data.contacts_info;
        _this.formValidate.basicAcctInfo = res.data.basic_acct_info;
        _this.status = res.data.open_status;
        _this.statusdisabled = _this.status == 'NORMAL' ? true : false;
        _this.checkcodebank();
      }
    });
    this.getBankCode();
    this.getCityList();
    this.getBankCodeList();
    // getEnterpriseOpenAccount({
    //     "basicInfo": {
    //       "phone": "15811419068",
    //       "org_exp": "20350507",
    //       "org_code": "91110108339856780X",
    //       "real_name": "北京艺天下文化科技有限公司",
    //       "org_email": "649943953@qq.com",
    //       "org_address": "北京市昌平区立业路13号1幢1至3层101-2064",
    //       "business_licene_photo_doc_id": "202205270014660122",
    //       "random_key": "b77e128a-baa7-4f91-9c08-be01ff09e46f",
    //       "password": "HAR6/0RekvlOXYw34E4JdHviaF6fypbbIi8SvWXakynCYjn/XCPSPvUCN2JpSufzA2cYahzjJhuEEWIswa0OIkNQgLeUgeN7zV78mzT1vPWPfzflBzA/Wf7LmIiygXSiQoANohOTGSF03IvPyfSKm3rk9u4Bw487STgX2RrtuqbmRaPYIc8Kt2u7NL6MXkwfCLga6RyzP8/jMDqjVsSi3hwPqDSnyxzfek/O1Y0R8FzXkdnNy7GJY/kqOmEWp19OQwHissYQ3x1F2l/cNbx/ulEyNpgWvqEVJssr4x1duig=",
    //       "business_licene_photo": "https://saas.cdn.yunzongbu.cn/merchant/11603/2022527/image/1653618900304153233.png"
    //   },
    //   "linkedAcctInfo": {
    //       "linked_accttype": "ENTERPRISE_BANK_ACCT",
    //       "linked_acctno": "110917011610106",
    //       "linked_brbankno": "308100005703",
    //       "linked_brbankname": "招商银行",
    //       "linked_bankcode": "03080000",
    //       "linked_acctname": "北京艺天下文化科技有限公司",
    //       "linked_phone": "15811419068"
    //   },
    //   "legalreptInfo": {
    //       "legalrept_name": "张晓亮",
    //       "legalrept_phone": "15811419068",
    //       "idcard_face": "https://saas.cdn.yunzongbu.cn/merchant/11603/2022527/image/1653618906257392209.jpg",
    //       "idcard_face_doc_id": "202205270014670127",
    //       "idcard_back": "https://saas.cdn.yunzongbu.cn/merchant/11603/2022527/image/1653618905802139956.jpg",
    //       "idcard_back_doc_id": "202206090015180361",
    //       "expiration_date": "20400824",
    //       "legalrept_idcard": "220122198410286517"
    //   },
    //   "contactsInfo": {
    //       "contacts_name": "宁晓琪",
    //       "contacts_phone": "15701572256"
    //   },
    //   "basicAcctInfo": {
    //       "linked_bankcode": "03080000",
    //       "linked_acctno": "110917011610106"
    //   }
    // }).then()
  },
  methods: {
    remoteMethod: function remoteMethod(data) {
      var _this2 = this;
      console.log(data);
      if (this.bank_code && this.brabank_name && this.citycode && data) {
        (0, _lianlianpay.getBankCodeList)({
          bank_code: this.bank_code,
          city_code: this.citycode[1],
          brabank_name: data
        }).then(function (res) {
          console.log(res);
          _this2.bankcodeList = res.data.card_list;
        });
      }
    },
    changebind: function changebind() {
      this.$router.push("".concat(_settings.roterPre, "/accounts/llAccount/llchangebind"));
    },
    getBankCode: function getBankCode() {
      var _this3 = this;
      (0, _lianlianpay.getBankCode)().then(function (res) {
        console.log(res);
        _this3.codeList = res.data;
        _this3.checkcodebank();
      });
    },
    checkcodebank: function checkcodebank() {
      var _this4 = this;
      if (this.codeList.length > 0 && this.formValidate.linkedAcctInfo.linked_bankcode) {
        this.codeList.forEach(function (item) {
          if (item.bank_code == _this4.formValidate.linkedAcctInfo.linked_bankcode) {
            _this4.bank_code = item.bank_code;
            _this4.brabank_name = item.bank_name;
            if (_this4.changebankflg) {
              _this4.formValidate.linkedAcctInfo.linked_brbankname = item.bank_name;
              _this4.changebankflg = false;
            }
            _this4.getBankCodeList();
          }
        });
      }
    },
    getBankCodeList: function getBankCodeList() {
      var _this5 = this;
      if (this.bank_code && this.brabank_name && this.citycode) {
        (0, _lianlianpay.getBankCodeList)({
          bank_code: this.bank_code,
          city_code: this.citycode[1],
          brabank_name: this.brabank_name
        }).then(function (res) {
          console.log(res);
          _this5.bankcodeList = res.data.card_list;
        });
      }
    },
    checkbrbankno: function checkbrbankno() {
      if (this.bankcodeList.length <= 0) {
        this.$message.error('请先选择银行编号和地址');
      }
    },
    changebankcode: function changebankcode(val) {
      console.log(val);
      this.changebankflg = true;
      this.checkcodebank();
    },
    changecitycode: function changecitycode(val) {
      console.log(val);
      this.getBankCodeList();
    },
    getrandomKey: function getrandomKey(data) {
      // 初始化密码控件
      function initPassword() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        // eslint-disable-next-line
        var passowrd = new showKeyBord({
          png: "//mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/img",
          // 图片路径，用这个就行了，也可以下载到本地
          click_objId: "#keyboardBtn2",
          // 触发显示键盘的Dom节点Id
          regExp1: "[\\S\\s]",
          // 输入过程限制的正则，不匹配正则的字符不让输入
          regExp2: "^[\\S\\s]{6,12}$",
          // 输入完成后的正则，与getValid()对应，判断输入值是否匹配该正则
          Input_texId: "#LOGPASS2",
          // 接收内容的Dom节点Id
          parent_objId: "#ipt_pwd2",
          // 将键盘插入到何处的Dom节点Id
          placeholderId: "#LOGPASS2_SHOW",
          // span标签的id，该span用于模拟Input_texId的placeholder
          len: "12",
          // 表示最大允许输入多少个字符[可以不传递]
          keyBordLeft: "",
          keyBordTop: "",
          chaosMode: 0,
          // 混乱模式,0:无混乱;1:打开时乱一次;2:每输入一个字符乱一次,默认值0
          clickStatus: 1,
          // 按键状态,0:鼠标划过、悬浮时按键无变化;1:鼠标划过、悬浮时按键的颜色变化,默认值0
          titlefont: "安全支付输入",
          backfont: "退 格",
          capsfont: "切换大/小写",
          surefont: "确定",
          isen: false,
          callBack: function callBack() {},
          // 密码校验成功回调
          errorCallBack: function errorCallBack() {},
          // 密码校验失败回调
          rsaPublicKey: options.rsaPublicKey || "",
          // RSA加密必须参数，国密加密可不要
          sm2KeyHex: options.sm2KeyHex || "",
          // 国密加密必须参数，RSA加密可不要
          license: options.license || "" // 必须参数
        });
        passowrd.initOptions();
        console.log("init");
        return passowrd;
      }
      // 随机因子，必须包含sm2KeyHex、license、randomKey，这里只是示例，正常使用应该由服务端调起连连随机因子申请接口获取
      var options = {
        license: data.license,
        sm2KeyHex: data.sm2_key_hex,
        randomKey: data.random_key,
        rsaPublicKey: data.rsa_public_content
      };
      this.randomKey = data.random_key;
      this.randomValue = data.random_value;
      this.passwordInstance = initPassword(options);
      $("#get-password").click(function () {
        // 获取国密密文
        passwordInstance.setRandKey(options.randomKey); // 先设置随机key
        var password = passwordInstance.getOutputSM(); // 国密加密密码
        var rsaPassword = passwordInstance.getOutput(); // RSA加密密码
        // $('#password').text(rsaPassword);
        console.log("国密密码是", password);
        console.log("RSA密码是", rsaPassword);
        // // 清空密码框
        // passwordInstance.clearPWD()

        // 其他API请参考文档
      });
    },
    getCode: function getCode() {
      if (!this.formValidate.mobile) {
        return this.$message.error("请输入手机号码");
      }
      if (!/^1(3|4|5|7|8|9|6)\d{9}$/i.test(this.formValidate.mobile)) {
        return this.$message.error("请输入正确的手机号码");
      }
      (0, _lianlianpay.getBindMobileCode)({
        phone: this.formValidate.mobile
      }).then(function (res) {});
      this.sendCode();
    },
    checkimg: function checkimg(url) {
      (0, _lianlianpay.checkuploadFile)({
        image: url
      }).then(function (res) {
        console.log(res);
      });
    },
    // 列表
    getCityList: function getCityList() {
      var _this6 = this;
      (0, _freight.cityList)().then(function (res) {
        _this6.cityList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    handleSubmitUp: function handleSubmitUp() {
      if (this.currentTab-- < 0) this.currentTab = 0;
    },
    handleSubmitNest: function handleSubmitNest(name) {
      var _this7 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          if (_this7.currentTab == 0) {
            (0, _lianlianpay.checkBindMobileCode)({
              phone: _this7.formValidate.mobile,
              verify_code: _this7.formValidate.code
            }).then(function (res) {
              _this7.formValidate.basicInfo.phone = _this7.formValidate.mobile;
              (0, _lianlianpay.getPersonalOpenAccount)(_this7.formValidate).then(function (res) {
                _this7.currentTab++;
                console.log(res);
                _this7.currentJson = res.data;
              });
            });
          } else if (_this7.currentTab == 1) {
            if (_this7.formValidate.type == "1") {
              _this7.passwordInstance.setRandKey(_this7.randomValue); // 先设置随机key
              (0, _lianlianpay.checkPersonalOpenAccount)({
                token: _this7.currentJson.token,
                verify_code: _this7.vcode,
                order_no: _this7.currentJson.txn_seqno,
                random_key: _this7.randomKey,
                password: _this7.passwordInstance.getOutputSM()
              });
            }
          }
        } else {
          _this7.$message.warning("请填写完整信息！");
        }
      });
    },
    // 提交
    handleSubmit: function handleSubmit(name) {
      var _this8 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this8.fullscreenLoading = true;
          console.log(_this8.currentJson);
          _this8.passwordInstance.setRandKey(_this8.randomValue); // 先设置随机key
          if (_this8.formValidate.type == 1) {
            (0, _lianlianpay.checkPersonalOpenAccount)({
              token: _this8.currentJson.token,
              verify_code: _this8.vcode,
              order_no: _this8.currentJson.txn_seqno,
              random_key: _this8.randomKey,
              password: _this8.passwordInstance.getOutputSM()
            }).then(function (res) {
              _this8.fullscreenLoading = false;
              _this8.$message.success("提交成功");
            }).catch(function () {
              _this8.fullscreenLoading = false;
            });
          } else if (_this8.formValidate.type == 2) {
            var json = JSON.parse(JSON.stringify(_this8.formValidate));
            json.basicInfo.phone = _this8.formValidate.mobile;
            json.basicInfo.random_key = _this8.randomKey;
            json.basicInfo.password = _this8.passwordInstance.getOutputSM();
            if (json.cardtype == 2) {
              json.legalreptInfo.expiration_date = '99991231';
            }
            if (json.exptype == 2) {
              json.basicInfo.org_exp = '99991231';
            }
            json.basicAcctInfo.linked_bankcode = _this8.formValidate.linkedAcctInfo.linked_bankcode;
            json.basicAcctInfo.linked_acctno = _this8.formValidate.linkedAcctInfo.linked_acctno;
            (0, _lianlianpay.checkBindMobileCode)({
              phone: _this8.formValidate.mobile,
              verify_code: _this8.formValidate.code
            }).then(function (res) {
              (0, _lianlianpay.getEnterpriseOpenAccount)(json).then(function (res) {
                _this8.fullscreenLoading = false;
                _this8.$message.success("提交成功");
              }).catch(function (res) {
                _this8.fullscreenLoading = false;
                // this.$message.error(res.message);
              });
            }).catch(function () {
              _this8.fullscreenLoading = false;
              (0, _lianlianpay.getEnterpriseOpenAccount)(json).then(function (res) {
                _this8.fullscreenLoading = false;
              }).catch(function (res) {
                _this8.fullscreenLoading = false;
                // this.$message.error(res.message);
              });
            });
          }
        } else {}
      });
    },
    changeImg: function changeImg(type) {
      var _this9 = this;
      this.$modalUpload(function (img) {
        (0, _lianlianpay.checkuploadFile)({
          image: img[0]
        }).then(function (res) {
          if (type == 'basicInfo.business_licene_photo') {
            _this9.formValidate.basicInfo.business_licene_photo = img[0];
            _this9.formValidate.basicInfo.business_licene_photo_doc_id = res.data.doc_id;
          } else if (type == 'legalreptInfo.idcard_face') {
            _this9.formValidate.legalreptInfo.idcard_face = img[0];
            _this9.formValidate.legalreptInfo.idcard_face_doc_id = res.data.doc_id;
          } else if (type == 'legalreptInfo.idcard_back') {
            _this9.formValidate.legalreptInfo.idcard_back = img[0];
            _this9.formValidate.legalreptInfo.idcard_back_doc_id = res.data.doc_id;
          }
        });
      }, 1, "1");
    }
  }
};