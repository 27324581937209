"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _settings = require("@/settings");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _marketing = require("@/api/marketing");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "activerecords",
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
    // retaildetail
  },
  data: function data() {
    return {
      moren: require("@/assets/images/f.png"),
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        username: "",
        type: 1,
        date: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: ""
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      loading: false,
      searchLoading: false,
      showdetail: false,
      sendflag: false,
      sendJson: {
        delivery_name: "",
        delivery_no: ""
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    showsend: function showsend(row) {
      this.row = row;
      this.sendflag = true;
      this.sendJson = {
        delivery_name: "",
        delivery_no: ""
      };
    },
    cursorSend: function cursorSend() {
      var _this = this;
      var json = JSON.parse(JSON.stringify(this.sendJson));
      json.record_id = this.row.id;
      (0, _marketing.drawActiveSend)(json).then(function () {
        _this.$message.success("操作成功");
        _this.sendflag = false;
        _this.getList();
      });
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.join_data = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.join_data = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _marketing.furnaceRecordApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    retaildetailrefash: function retaildetailrefash() {
      console.log("刷新");
      this.getList();
    },
    // 导出
    exportRecord: function exportRecord() {
      var _this3 = this;
      this.tableFrom.export_type = "salesman";
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        /*this.$message.success(res.message);
          this.$refs.exportList.exportFileList();*/
        var h = _this3.$createElement;
        _this3.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this3.$msgbox.close();
                _this3.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 导出列表
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList("salesman");
    }
  }
};