var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading || _vm.lock,
          expression: "pageLoading || lock",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "header-breadcrumb",
        { attrs: { name: "排序", "has-back": true, "is-fixed": true } },
        [
          _c("template", { slot: "leftHander" }, [
            _c("span", { staticClass: "header-txt" }, [
              _vm._v(
                "（此功能，仅适用于“不印刷图录”的专场，排序后，图录号将根据顺序重新定义。）"
              ),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("el-card", [
        _c(
          "div",
          { staticClass: "album-change flex align-items-c" },
          [
            _c(
              "el-select",
              {
                staticClass: "w300",
                model: {
                  value: _vm.changeAlbum,
                  callback: function ($$v) {
                    _vm.changeAlbum = $$v
                  },
                  expression: "changeAlbum",
                },
              },
              _vm._l(_vm.albumList, function (m, i) {
                return _c("el-option", {
                  key: i,
                  attrs: { label: m.album_name, value: i },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "start-lot" },
              [
                _c("span", { staticClass: "ml20" }, [_vm._v("起始图录号：")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: {
                    width: "120px",
                    "font-size": "14px",
                    "font-weight": "500",
                  },
                  attrs: { controls: false },
                  model: {
                    value: _vm.startLot,
                    callback: function ($$v) {
                      _vm.startLot = $$v
                    },
                    expression: "startLot",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.pageLoading
          ? _c("div", { staticClass: "auction-con" }, [
              _vm.albumList[_vm.changeAlbum]
                ? _c(
                    "div",
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "auction-list",
                            attrs: {
                              list: _vm.albumList[_vm.changeAlbum].auctionList,
                              "set-data": _vm.setData,
                            },
                          },
                          "draggable",
                          _vm.$attrs,
                          false
                        ),
                        _vm._l(
                          _vm.albumList[_vm.changeAlbum].auctionList,
                          function (m, i) {
                            return _c(
                              "div",
                              { key: "auction" + i, staticClass: "item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "img" },
                                  [
                                    _c("el-image", {
                                      directives: [
                                        { name: "lazy", rawName: "v-lazy" },
                                      ],
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                        display: "block",
                                      },
                                      attrs: {
                                        src:
                                          m.image +
                                          "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_200/quality,q_90/format,webp",
                                        fit: "contain",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "name" }, [
                                  _c("span", [
                                    _vm._v(
                                      "LOT " +
                                        _vm._s(_vm.startSort + i + _vm.startLot)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "& 顺序 " + _vm._s(_vm.startSort + i + 1)
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "formFixedBox" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: { type: "primary" },
                on: { click: _vm.saveAction },
              },
              [_vm._v("保存排序")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }