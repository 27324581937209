var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout", staticStyle: { background: "#f7f7f7" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            {
              staticStyle: {
                position: "fixed",
                left: "0",
                top: "0",
                right: "0",
              },
              attrs: { height: "64px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-align-center",
                  staticStyle: {
                    width: "1345px",
                    margin: "0 auto",
                    height: "64px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "page-back item-align-center",
                      on: { click: _vm.backAction },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/b8b0d796b5afef0149e76265443f1cbd.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("返回")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-shu" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-name item-align-center" }, [
                    _c("div", { staticClass: "l" }, [_vm._v("基础页面")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/6390304e322470c6b1a238158de1ccf4.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "r" }, [
                      _vm._v(_vm._s(_vm.pageInfo.name || "")),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "page-btn item-align-center" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "large" },
                          on: { click: _vm.handlePageSave },
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: { click: _vm.handleCancelAction },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.isEdit ? "primary" : "info",
                            size: "large",
                          },
                          on: { click: _vm.sendAction },
                        },
                        [_vm._v("发布")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: { click: _vm.previewAction },
                        },
                        [_vm._v("预览")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-main",
            {
              staticStyle: {
                "padding-top": "64px",
                "box-sizing": "border-box",
                width: "1345px",
                margin: "0 auto",
              },
            },
            [
              _c(
                "div",
                { staticClass: "layout-tool" },
                [
                  _c(
                    "el-scrollbar",
                    { style: _vm.scrollHeight },
                    [
                      _c(
                        "el-collapse",
                        {
                          attrs: {
                            value: [
                              "basics",
                              "advanced",
                              "sales",
                              "other",
                              "marketing",
                            ],
                          },
                        },
                        _vm._l(_vm.panels, function (mounted, index) {
                          return _c(
                            "el-collapse-item",
                            { key: index, attrs: { name: index } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("i", {
                                  staticClass: "le-icon le-icon-arrow",
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(mounted.title))]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "fm-collapse-tool" },
                                [
                                  _vm._l(
                                    mounted.children,
                                    function (item, index) {
                                      return [
                                        item.name == "task" &&
                                        _vm.$manifest("task", "status")
                                          ? _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "widget",
                                                attrs: {
                                                  id:
                                                    item.title == "视频"
                                                      ? "guide001"
                                                      : "",
                                                  draggable: true,
                                                  "data-type": "module",
                                                },
                                                on: {
                                                  dragstart: function ($event) {
                                                    return _vm.dragStart(item)
                                                  },
                                                  dragend: _vm.dragEnd,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "widget-icon",
                                                  attrs: { src: item.icon },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ]
                                            )
                                          : _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass: "widget",
                                                attrs: {
                                                  id:
                                                    item.title == "视频"
                                                      ? "guide001"
                                                      : "",
                                                  draggable: true,
                                                  "data-type": "module",
                                                },
                                                on: {
                                                  dragstart: function ($event) {
                                                    return _vm.dragStart(item)
                                                  },
                                                  dragend: _vm.dragEnd,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "widget-icon",
                                                  attrs: { src: item.icon },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ]
                                            ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "clears" }),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            2
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-body" },
                [
                  _c(
                    "el-scrollbar",
                    {
                      ref: "scrollbar",
                      style: _vm.scrollHeight,
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handlePackage($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "layout-phone",
                          style: {
                            minHeight:
                              !_vm.pageInfo.open_header ||
                              _vm.pageInfo.headerSetting.header_air
                                ? "700px"
                                : "765px",
                            backgroundColor: _vm.pageInfo.background,
                            backgroundImage:
                              "url(" + _vm.pageInfo.bg_image + ")",
                            backgroundRepeat:
                              _vm.pageInfo.bg_image_mode === 3
                                ? "repeat-y"
                                : "no-repeat",
                            backgroundPosition:
                              _vm.pageInfo.bg_image_mode == 3 ||
                              _vm.pageInfo.bg_image_mode == 4
                                ? "center top"
                                : "center",
                            backgroundSize:
                              _vm.pageInfo.bg_image_mode == 2
                                ? "cover"
                                : "375px",
                          },
                        },
                        [
                          _vm.pageInfo.headerSetting
                            ? _c("div", {
                                staticClass: "layout-phone-header pos-absolute",
                                style: {
                                  backgroundImage:
                                    "url(" +
                                    (_vm.pageInfo.headerSetting.header_color ===
                                    "white"
                                      ? "https://saas.cdn.yunzongbu.cn/image/20211026/9823014575b33347581d939efbd91aee.png"
                                      : "https://saas.cdn.yunzongbu.cn/image/20211026/a3459168515274a30097453656ad97b1.png") +
                                    ")",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticStyle: { height: "88px" } }),
                          _vm._v(" "),
                          _vm.pageInfo.userInfoSetting
                            ? _c(
                                "div",
                                {
                                  staticClass: "mine-top flex align-items-c",
                                  class: {
                                    col:
                                      _vm.pageInfo.userInfoSetting.areaAlign ==
                                      2,
                                  },
                                  style: {
                                    marginTop:
                                      _vm.pageInfo.userInfoSetting.top_padding /
                                        2 +
                                      "px",
                                    padding:
                                      "0 " +
                                      _vm.pageInfo.userInfoSetting
                                        .left_padding /
                                        2 +
                                      "px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mine-avatar",
                                      style: {
                                        width:
                                          _vm.pageInfo.userInfoSetting
                                            .avatar_width /
                                            2 +
                                          "px",
                                        height:
                                          _vm.pageInfo.userInfoSetting
                                            .avatar_height /
                                            2 +
                                          "px",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211209/e52b0a0217bd7777aad33b30d0a85dc0.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mine-uname flex-one",
                                      style: {
                                        marginLeft:
                                          (_vm.pageInfo.userInfoSetting
                                            .areaAlign == 2
                                            ? 0
                                            : _vm.pageInfo.userInfoSetting
                                                .uname_margin_left / 2) + "px",
                                        marginTop:
                                          (_vm.pageInfo.userInfoSetting
                                            .areaAlign != 2
                                            ? 0
                                            : _vm.pageInfo.userInfoSetting
                                                .uname_margin_left / 2) + "px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "uname",
                                          style: {
                                            fontSize:
                                              _vm.pageInfo.userInfoSetting
                                                .uname_size /
                                                2 +
                                              "px",
                                            color:
                                              _vm.pageInfo.userInfoSetting
                                                .uname_color,
                                            fontWeight:
                                              _vm.pageInfo.userInfoSetting
                                                .uname_style,
                                          },
                                        },
                                        [_vm._v("艺天下")]
                                      ),
                                      _vm._v(" "),
                                      _vm.pageInfo.userInfoSetting.open_level
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "progress",
                                              style: {
                                                marginTop:
                                                  _vm.pageInfo.userInfoSetting
                                                    .progress_margin_top /
                                                    2 +
                                                  "px",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "bg",
                                                style: {
                                                  background:
                                                    _vm.pageInfo.userInfoSetting
                                                      .progress_color,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "p",
                                                style: {
                                                  background:
                                                    _vm.pageInfo.userInfoSetting
                                                      .progress_color,
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.pageInfo.userInfoSetting.open_level
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "progress-txt",
                                              style: {
                                                marginTop:
                                                  _vm.pageInfo.userInfoSetting
                                                    .progress_txt_margin_top /
                                                    2 +
                                                  "px",
                                                fontSize:
                                                  _vm.pageInfo.userInfoSetting
                                                    .progress_txt_size /
                                                    2 +
                                                  "px",
                                                color:
                                                  _vm.pageInfo.userInfoSetting
                                                    .progress_txt_color,
                                                fontWeight:
                                                  _vm.pageInfo.userInfoSetting
                                                    .progress_txt_style,
                                              },
                                            },
                                            [_vm._v("成长值 388/1500 升级LV3")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.pageInfo.userInfoSetting.areaAlign != 2
                                    ? _c(
                                        "div",
                                        { staticClass: "user-setting" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.pageInfo.userInfoSetting
                                                .settingImg,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "layout-phone-body",
                              attrs: { id: "guide002" },
                            },
                            [_c("router-view", { ref: "childView" })],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.is_lock
                ? _c(
                    "div",
                    {
                      staticClass: "layout-attribute display-flex",
                      attrs: { id: "guide004" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _vm.index >= 0
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _vm.modules[_vm.attribute]
                                    ? _c(_vm.modules[_vm.attribute].attribute, {
                                        tag: "component",
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.index == -1
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _c("pageAttribute", {
                                    model: {
                                      value: _vm.pageInfo,
                                      callback: function ($$v) {
                                        _vm.pageInfo = $$v
                                      },
                                      expression: "pageInfo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.index == -2
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _c("titleAttribute", {
                                    model: {
                                      value: _vm.pageInfo,
                                      callback: function ($$v) {
                                        _vm.pageInfo = $$v
                                      },
                                      expression: "pageInfo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.index == -3
                            ? _c(
                                "el-scrollbar",
                                { style: _vm.scrollHeight },
                                [
                                  _c("footerAttribute", {
                                    model: {
                                      value: _vm.pageInfo,
                                      callback: function ($$v) {
                                        _vm.pageInfo = $$v
                                      },
                                      expression: "pageInfo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: _vm.showImgType == 1 ? "查看二维码" : "预览二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }