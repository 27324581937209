"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkBindMobileCode = checkBindMobileCode;
exports.checkEnterpriseOpenAccount = checkEnterpriseOpenAccount;
exports.checkPersonalOpenAccount = checkPersonalOpenAccount;
exports.checkuploadFile = checkuploadFile;
exports.getBankCode = getBankCode;
exports.getBankCodeList = getBankCodeList;
exports.getBindMobileCode = getBindMobileCode;
exports.getChangeBindApply = getChangeBindApply;
exports.getChangeBindVerify = getChangeBindVerify;
exports.getEnterpriseOpenAccount = getEnterpriseOpenAccount;
exports.getFindPassword = getFindPassword;
exports.getFindPasswordApply = getFindPasswordApply;
exports.getLianLianAccountInfo = getLianLianAccountInfo;
exports.getLianLianMerchantInfo = getLianLianMerchantInfo;
exports.getLianLianWithdraw = getLianLianWithdraw;
exports.getOpenAccountInfo = getOpenAccountInfo;
exports.getPasswordRandomFactor = getPasswordRandomFactor;
exports.getPersonalOpenAccount = getPersonalOpenAccount;
exports.getWithdrawApply = getWithdrawApply;
exports.getWithdrawAudit = getWithdrawAudit;
exports.getWithdrawSmsVerify = getWithdrawSmsVerify;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 连连支付 - 获取密码随机因子
 */
function getPasswordRandomFactor(data) {
  return _request.default.get("llpay/getRandom", data);
}

/**
 * @description 连连支付 - 获取银行编码
 */
function getBankCode(data) {
  return _request.default.get("llpay/getBankCodeList", data);
}

/**
 * @description 连连支付 - 获取大额行号
 */
function getBankCodeList(data) {
  return _request.default.get("llpay/getCnapscode", data);
}

/**
 * @description 连连支付 - 上传文件
 */
function checkuploadFile(data) {
  return _request.default.post("llpay/documentUpload", data);
}

/**
 * @description 连连支付 - 绑定手机验证码申请
 */
function getBindMobileCode(data) {
  return _request.default.post("llpay/regphoneVerifyCodeApply", data);
}

/**
 * @description 连连支付 - 绑定手机验证码验证
 */
function checkBindMobileCode(data) {
  return _request.default.post("llpay/regphoneVerifyCodeVerify", data);
}

/**
 * @description 连连支付 - 个人开户申请
 */
function getPersonalOpenAccount(data) {
  return _request.default.post("llpay/openacctApplyIndividual", data);
}

/**
 * @description 连连支付 - 个人开户验证
 */
function checkPersonalOpenAccount(data) {
  return _request.default.post("llpay/openacctVerifyIndividual", data);
}

/**
 * @description 连连支付 - 企业开户申请
 */
function getEnterpriseOpenAccount(data) {
  return _request.default.post("llpay/openacctApplyEnterprise", data);
}

/**
 * @description 连连支付 - 企业开户验证
 */
function checkEnterpriseOpenAccount(data) {
  return _request.default.post("llpay/openacctVerifyEnterprise", data);
}

/**
 * @description 连连支付 - 获取连连账户信息
 */
function getLianLianAccountInfo(data) {
  return _request.default.get("llpay/getAcctinfo", data);
}

/**
 * @description 连连支付 - 获取提交信息
 */
function getOpenAccountInfo(data) {
  return _request.default.get("llpay/getOpenAccountInfo", data);
}

/**
 * @description 连连支付 - 获取连连商户信息
 */
function getLianLianMerchantInfo(data) {
  return _request.default.get("llpay/getAcctinfo", data);
}

/**
 * @description 连连支付 - 提现申请
 */
function getWithdrawApply(data) {
  return _request.default.post("llpay/extract", data);
}
/**
 * @description 连连支付 - 提现短信验证
 */
function getWithdrawSmsVerify(data) {
  return _request.default.post("llpay/validationSms", data);
}
/**
 * @description 连连支付 - 找回密码申请
 */
function getFindPasswordApply(data) {
  return _request.default.post("llpay/findPasswordApply", data);
}
/**
 * @description 连连支付 - 找回密码
 */
function getFindPassword(data) {
  return _request.default.post("llpay/findPasswordVerify", data);
}
/**
 * @description 连连支付 - 企业用户更换绑定账号申请
 */
function getChangeBindApply(data) {
  return _request.default.post("llpay/enterpriseChangecardApply", data);
}
/**
 * @description 连连支付 - 企业用户更换绑定账号验证
 */
function getChangeBindVerify(data) {
  return _request.default.post("llpay/enterpriseChangecardVerify", data);
}
/**
 * @description 连连支付 - 连连用户提现列表
 */
function getLianLianWithdraw(data) {
  return _request.default.get("llpay/mer/userExtractList", data);
}
/**
 * @description 连连支付 - 审核提现
 */
function getWithdrawAudit(data) {
  return _request.default.post("llpay/mer/userExtractCheck", data);
}