var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            title: " ",
            width: "430px",
            top: "5vh",
            "custom-class": "dialog-hide-bg",
            "show-close": false,
            "close-on-click-modal": false,
            "before-close": _vm.closeAction,
          },
        },
        [
          _c("div", { staticClass: "iphone-bg" }, [
            _c(
              "div",
              {
                staticClass: "close-icon",
                on: { click: _vm.clickCloseAction },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024820/image/1724159132276659587.png!120a",
                    alt: "",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "iphone-content" }, [
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "22px",
                      "line-height": "1.4",
                      "margin-bottom": "7px",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.title))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "top-bottom-info" }, [
                  _c("div", [
                    _c("span", { staticClass: "tag" }, [_vm._v("原创")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "tip" }, [
                      _vm._v(_vm._s(_vm.mer_name)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "tip", staticStyle: { color: "#576b95" } },
                      [_vm._v(_vm._s(_vm.mer_name))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { staticClass: "tip" }, [
                      _vm._v(
                        _vm._s(_vm.parseTime(new Date(), "{y}-{m}-{d} {h}:{i}"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "tip" }, [_vm._v("北京")]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("section", { ref: "is_copy_el", attrs: { id: "is_copy" } }, [
                _c(
                  "section",
                  { style: _vm.pageStyle },
                  _vm._l(_vm.pages, function (item, index) {
                    return _c(
                      "section",
                      {
                        key: index + 1,
                        style:
                          "margin-bottom: 0;font-family: " +
                          (_vm.pageInfo.fontFamily || "PingFangTC-Light") +
                          "," +
                          (_vm.pageInfo.fontFamily_ch || "PingFangTC-Light") +
                          ",PingFangTC-Light",
                      },
                      [
                        item.name === "title"
                          ? [
                              _c("Title", {
                                attrs: {
                                  facade: item.facade,
                                  content: item.content,
                                  index: index,
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "title_weChat"
                          ? [
                              _c("TitleWeChat", {
                                attrs: {
                                  facade: item.facade,
                                  content: item.content,
                                  index: index,
                                  "is-p": "1",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "rubik_weChat"
                          ? _c("Rubik", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "blank"
                          ? _c("Blank", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "textView"
                          ? _c("textView", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "auction_weChat"
                          ? _c("AuctionWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "mini-appid": _vm.miniAppid,
                                "mini-name": _vm.miniName,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "auction_album_weChat"
                          ? _c("AuctionAlbumWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "mini-appid": _vm.miniAppid,
                                "mini-name": _vm.miniName,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "banner_weChat"
                          ? _c("BannerWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "mini-appid": _vm.miniAppid,
                                "mini-name": _vm.miniName,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "contact_information"
                          ? _c("ContactInformation", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "is-preview": "1",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "logo_weChat"
                          ? _c("logoWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "mini-appid": _vm.miniAppid,
                                "mini-name": _vm.miniName,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "contract_weChat"
                          ? _c("contractWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "mini-appid": _vm.miniAppid,
                                "mini-name": _vm.miniName,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "position_weChat"
                          ? _c("positionWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "is-preview": "1",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "auction_notice_weChat"
                          ? _c("auctionNoticeWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "is-preview": "1",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "annual_solicitation_weChat"
                          ? _c("annualSolicitationWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                                "is-preview": "1",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "img_weChat"
                          ? _c("ImgWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                "mini-appid": _vm.miniAppid,
                                "mini-name": _vm.miniName,
                                index: index,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "text_weChat"
                          ? _c("TextWeChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.name === "weChatVideo_weChat"
                          ? _c("WeChatVideo_weChat", {
                              attrs: {
                                facade: item.facade,
                                content: item.content,
                                index: index,
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btns mt10 item-flex-center" },
            [
              _vm.type == "outer"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      staticStyle: { width: "30%" },
                      attrs: { type: "default" },
                      on: { click: _vm.editAction },
                    },
                    [_vm._v("修改")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "btn flex-one",
                  attrs: { type: "primary" },
                  on: { click: _vm.syncsCaogao },
                },
                [_vm._v("同步到公众号草稿箱")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showModelFlag, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showModelFlag = $event
            },
            close: function ($event) {
              _vm.showModelFlag = false
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("div", [_vm._v("将从“ArtAI账户”扣除")]),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "20px" } }, [
              _vm._v(_vm._s(_vm.price) + "元"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex align-items-c",
              staticStyle: { "margin-top": "40px" },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "w300",
                  attrs: { type: "primary" },
                  on: { click: _vm.modelSubmit },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "w300",
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      _vm.showModelFlag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }