var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "mobile",
      staticClass: "package",
      attrs: { id: "min-page", draggable: false, "data-index": -2 },
      on: {
        dragover: [
          function ($event) {
            return _vm.allowDrop($event)
          },
          function ($event) {
            $event.preventDefault()
          },
        ],
        drop: _vm.drop,
      },
    },
    [
      _vm._l(_vm.pages, function (item, index) {
        return _c(
          "div",
          { key: index + 1, attrs: { role: "widgetgroup" } },
          [
            item.name === "albumDetail"
              ? _c("AlbumDetail", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auctionAlbum"
              ? _c("AuctionAlbum", {
                  attrs: {
                    facade: _vm.pageInfo,
                    content: item.content,
                    index: index,
                    no_control: "2",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auction"
              ? _c("Auction", {
                  attrs: {
                    facade: _vm.pageInfoNew(index),
                    content: item.content,
                    index: index,
                    no_control: "2",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auctionDetail"
              ? _c("AuctionDetail", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "product"
              ? _c("Product", {
                  attrs: {
                    facade: _vm.pageInfoNew(index),
                    content: item.content,
                    index: index,
                    no_control: "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "productDetail"
              ? _c("ProductDetail", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "nftCollection"
              ? _c("NftCollection", {
                  attrs: {
                    facade: _vm.pageInfoNew(index),
                    content: item.content,
                    index: index,
                    no_control: "2",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "nftDetail"
              ? _c("NftDetail", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "blindPage"
              ? _c("Blind", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "composePage"
              ? _c("Compose", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "1",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "blindDetail"
              ? _c("BlindDetail", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "2",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "brand"
              ? _c("Brand", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "2",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "ticket"
              ? _c("Ticket", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                    no_control: "2",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "package-empty", attrs: { "data-index": -2 } },
        [
          _vm.dragActive(-2, "down")
            ? _c("Covermask", { attrs: { "data-index": -2 } })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }