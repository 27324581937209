"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _frame = require("@/api/frame");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var activeIndexObj = {
  "1": "frameList",
  "2": "frameType",
  "3": "framePaper",
  "4": "frameScence",
  "5": "frameFrame"
};
var _default = exports.default = {
  name: "frameFrame",
  data: function data() {
    return {
      lists: [],
      queryParams: {
        page: 1,
        limit: 20
      },
      categoryId: "",
      categorys: [],
      total: 0,
      frameflag: false,
      typeStatus: "0",
      formList: {
        bgcolor: "",
        category: "",
        category_id: 0,
        down_height: 0,
        down_img: "",
        down_nei_shadow_height: 0,
        down_shadow_height: 0,
        id: "",
        img_url: "",
        is_del: 0,
        is_scroll: 0,
        left_down_height: 0,
        left_down_img: "",
        left_down_width: 0,
        left_img: "",
        left_shadow_width: 0,
        left_up_height: 0,
        left_up_img: "",
        left_up_width: 0,
        left_width: 0,
        right_down_height: 0,
        right_down_img: "",
        right_down_width: 0,
        right_img: "",
        right_nei_shadow_width: 0,
        right_shadow_width: 0,
        right_up_height: 0,
        right_up_img: "",
        right_up_width: 0,
        right_width: 0,
        round_border: 0,
        round_params: "",
        type: 0,
        up_height: 0,
        up_img: "",
        up_shadow_height: 0,
        category_type: 0
      }
    };
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      this.$router.push({
        name: activeIndexObj[index]
      });
    },
    changeType: function changeType(type) {
      this.queryParams.page = 1;
      this.load();
    },
    changeTypeStatus: function changeTypeStatus(value) {
      this.typeStatus = value;
      this.load();
    },
    edit: function edit(row, type) {
      this.work = row;
      this.price = "";
      if (type === 1) {
        this.saleflag = true;
        return;
      }
      this.handle(type);
    },
    addFrame: function addFrame() {
      this.frameflag = true;
      this.formList = {
        bgcolor: "",
        category: "",
        category_id: this.categoryId,
        down_height: "",
        down_img: "",
        down_nei_shadow_height: "",
        down_shadow_height: "",
        id: "",
        img_url: "",
        is_scroll: "",
        left_down_height: "",
        left_down_img: "",
        left_down_width: "",
        left_img: "",
        left_shadow_width: 0,
        left_up_height: 0,
        left_up_img: "",
        left_up_width: 0,
        left_width: 0,
        right_down_height: 0,
        right_down_img: "",
        right_down_width: 0,
        right_img: "",
        right_nei_shadow_width: 0,
        right_shadow_width: 0,
        right_up_height: 0,
        right_up_img: "",
        right_up_width: 0,
        right_width: 0,
        round_border: 0,
        round_params: "",
        type: 0,
        up_height: 0,
        up_img: "",
        up_shadow_height: 0,
        category_type: 0
      };
    },
    save: function save() {
      var _this = this;
      var json = this.formList;
      if (json.id) {
        (0, _frame._updateFrame)(json).then(function (res) {
          if (res.status === 200) {
            _this.$message.success("编辑成功");
          } else {
            _this.$message.error(res.message);
          }
          _this.frameflag = false;
          _this.queryParams.page = 1;
          _this.load();
        }).catch(function (err) {
          console.log(err);
          _this.$message.error(err.message);
        });
      } else {
        (0, _frame._createFrame)(json).then(function (res) {
          if (res.status === 200) {
            _this.$message.success("添加成功");
          } else {
            _this.$message.error(res.message);
          }
          _this.frameflag = false;
          _this.queryParams.page = 1;
          _this.load();
        }).catch(function (err) {
          console.log(err);
          _this.$message.error(err.message);
        });
      }
    },
    handle: function handle(row) {
      this.frameflag = true;
      this.formList = JSON.parse(JSON.stringify(row));
    },
    deleteFrame: function deleteFrame(row) {
      var _this2 = this;
      (0, _frame._deleteFrame)({
        template_id: row.id,
        type: 1
      }).then(function (res) {
        if (res.status === 200) {
          _this2.$message.success("删除成功");
          _this2.queryParams.page = 1;
          _this2.load();
        } else {
          _this2.$message.error(res.message);
        }
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err.message);
      });
    },
    getCategory: function getCategory() {
      var _this3 = this;
      var params = {};
      params.type = 1;
      (0, _frame._getTypeList)(params).then(function (res) {
        if (res.status === 200) {
          _this3.categorys = res.data.list;
          if (res.data.list.length) {
            _this3.categoryId = res.data.list[0].id;
            _this3.load();
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    load: function load() {
      var _this4 = this;
      var params = this.queryParams;
      params.category_id = this.categoryId;
      params.category_type = this.typeStatus == 0 ? "" : this.typeStatus;
      this.resultData = [];
      this.loading = true;
      (0, _frame._getFrameList)(params).then(function (res) {
        if (res.status === 200) {
          _this4.total = res.data.count;
          _this4.lists = res.data.list.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              category_name: _this4.categorys.find(function (i) {
                return i.id == item.category_id;
              }).category_name
            });
          });
        }
        _this4.loading = false;
      }).catch(function (err) {
        _this4.loading = false;
        console.log(err);
      });
    },
    onUpload: function onUpload(type) {
      var _this5 = this;
      this.$modalUpload(function (img) {
        if (img.length > 1) return _this5.$message.error("只能选择一张");
        _this5.formList[type] = img[0];
      });
    },
    handleRemove: function handleRemove(type) {
      this.formList[type] = "";
    },
    changePageSize: function changePageSize(pageSize) {
      this.queryParams.limit = pageSize;
      this.queryParams.page = 1;
      this.$router.push({
        path: this.$route.path,
        query: {
          type: this.queryParams.topictype,
          page: 1,
          limit: pageSize
        }
      });
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          type: this.queryParams.topictype,
          page: newPage,
          limit: this.queryParams.limit
        }
      });
      this.load();
    },
    change: function change() {}
  },
  watch: {},
  created: function created() {
    this.getCategory();
  }
};