var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("div", { staticClass: "top-menu flex" }, [
        _c("div", { staticClass: "flex-one" }, [
          _vm.$store.getters.mer_logo
            ? _c("img", {
                staticClass: "ml20",
                staticStyle: { height: "40px", "margin-top": "5px" },
                attrs: { src: _vm.$store.getters.mer_logo },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "pre flex align-items-c",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.changeaddwork($event)
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022111/image/1667302451971420841.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("添加")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "pre flex align-items-c",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.savepublic($event)
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022111/image/1667302451971420841.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("保存")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "pre flex align-items-c",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.exitAction($event)
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022111/image/1667302451280271950.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("退出")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showwork,
              expression: "showwork",
            },
          ],
          staticClass: "left-menu flex",
        },
        [
          _c("div", { staticClass: "l" }, [
            _c(
              "div",
              {
                staticClass: "item item-flex-center",
                class: _vm.changeType == 1 ? "curr" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeEditTypeAction(1)
                  },
                },
              },
              [
                _vm.changeType != 1
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537912087375137.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537909015398609.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c("div", [_vm._v("藏品")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item item-flex-center",
                class: _vm.changeType == 2 ? "curr" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeEditTypeAction(2)
                  },
                },
              },
              [
                _vm.changeType != 2
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537912355800675.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537908572896244.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c("div", [_vm._v("作品")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item item-flex-center",
                class: _vm.changeType == 3 ? "curr" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeEditTypeAction(3)
                  },
                },
              },
              [
                _vm.changeType != 3
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537911824158420.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537909300858271.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c("div", [_vm._v("商品")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item item-flex-center",
                class: _vm.changeType == 4 ? "curr" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeEditTypeAction(4)
                  },
                },
              },
              [
                _vm.changeType != 4
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537911567737564.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537909543669670.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c("div", [_vm._v("拍品")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item item-flex-center",
                class: _vm.changeType == 5 ? "curr" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeEditTypeAction(5)
                  },
                },
              },
              [
                _vm.changeType != 5
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537911060456561.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537910214620339.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c("div", [_vm._v("素材")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item item-flex-center",
                class: _vm.changeType == 6 ? "curr" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeEditTypeAction(6)
                  },
                },
              },
              [
                _vm.changeType != 6
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537910801458654.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537910550290588.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c("div", [_vm._v("直播")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item item-flex-center",
                class: _vm.changeType == 7 ? "curr" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeEditTypeAction(7)
                  },
                },
              },
              [
                _vm.changeType != 7
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/168353818285238128.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683538183234601982.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c("div", [_vm._v("文字")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item item-flex-center",
                class: _vm.changeType == 0 ? "curr" : "",
                on: {
                  click: function ($event) {
                    return _vm.changeEditTypeAction(0)
                  },
                },
              },
              [
                _vm.changeType != 0
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537911299106987.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202358/image/1683537909882997315.png",
                        alt: "",
                      },
                    }),
                _vm._v(" "),
                _c("div", [_vm._v("详情")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "r" },
            [
              _vm.changeType == 0
                ? [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "search flex align-items-c ml10 mr10 mt20",
                        staticStyle: { height: "auto" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "flex-one",
                          attrs: { placeholder: "请输入空间名称" },
                          model: {
                            value: _vm.space_name,
                            callback: function ($$v) {
                              _vm.space_name = $$v
                            },
                            expression: "space_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "search flex align-items-c ml10 mr10 mt20",
                        staticStyle: { height: "auto" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "flex-one",
                          attrs: {
                            type: "textarea",
                            resize: "none",
                            rows: 5,
                            placeholder: "请输入空间描述",
                          },
                          model: {
                            value: _vm.space_intro,
                            callback: function ($$v) {
                              _vm.space_intro = $$v
                            },
                            expression: "space_intro",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "search flex align-items-c ml10 mr10 mt20",
                        staticStyle: { height: "auto" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogVisible = true
                              },
                            },
                          },
                          [_vm._v("修改空间介绍")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.changeType == 7
                ? [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "search flex align-items-c ml10 mr10 mt20",
                        staticStyle: { height: "auto" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "flex-one",
                          attrs: {
                            type: "textarea",
                            resize: "none",
                            rows: 5,
                            placeholder: "请输入文字",
                          },
                          model: {
                            value: _vm.text,
                            callback: function ($$v) {
                              _vm.text = $$v
                            },
                            expression: "text",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "search flex align-items-c ml10 mr10 mt10",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "flex-one",
                            attrs: { type: "primary" },
                          },
                          [_vm._v("确认添加")]
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c t ml10 mr10" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-one" },
                          [
                            _vm.changeType == 1 ? [_vm._v("藏品")] : _vm._e(),
                            _vm._v(" "),
                            _vm.changeType == 2 ? [_vm._v("作品")] : _vm._e(),
                            _vm._v(" "),
                            _vm.changeType == 3 ? [_vm._v("商品")] : _vm._e(),
                            _vm._v(" "),
                            _vm.changeType == 4 ? [_vm._v("拍品")] : _vm._e(),
                            _vm._v(" "),
                            _vm.changeType == 5 ? [_vm._v("素材")] : _vm._e(),
                            _vm._v(" "),
                            _vm.changeType == 6 ? [_vm._v("直播")] : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tr flex align-items-c",
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.refreshAction },
                          },
                          [
                            _c("i", { staticClass: "el-icon-refresh-left" }),
                            _vm._v(" "),
                            _c("span", [_vm._v("刷新")]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "search flex align-items-c ml10 mr10" },
                      [
                        _vm.changeType == 1 || _vm.changeType == 2
                          ? [
                              _c(
                                "el-select",
                                {
                                  staticClass: "flex-one",
                                  attrs: { clearable: "", placeholder: "类型" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getWorkList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.file_type,
                                    callback: function ($$v) {
                                      _vm.file_type = $$v
                                    },
                                    expression: "file_type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "图片", value: 1 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "3D", value: 2 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "视频", value: 3 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "音频", value: 4 },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticClass: "flex-one ml10 mr10",
                                  attrs: { clearable: "", placeholder: "标签" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getWorkList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.label_id,
                                    callback: function ($$v) {
                                      _vm.label_id = $$v
                                    },
                                    expression: "label_id",
                                  },
                                },
                                _vm._l(_vm.tagList, function (m, i) {
                                  return _c("el-option", {
                                    key: "tag" + i,
                                    attrs: {
                                      label: m.label_name,
                                      value: m.label_id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.changeType == 3 || _vm.changeType == 4
                          ? [
                              _c("el-cascader", {
                                staticClass: "filter-item flex-one mr10",
                                attrs: {
                                  options: _vm.merCateList,
                                  placeholder: "分类",
                                  props: _vm.props,
                                  filterable: "",
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getWorkList(1)
                                  },
                                },
                                model: {
                                  value: _vm.mer_cate_id,
                                  callback: function ($$v) {
                                    _vm.mer_cate_id = $$v
                                  },
                                  expression: "mer_cate_id",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.changeType == 5
                          ? [
                              _c(
                                "el-select",
                                {
                                  staticClass: "flex-one mr10",
                                  attrs: { clearable: "", placeholder: "分类" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getWorkList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.attachment_category_id,
                                    callback: function ($$v) {
                                      _vm.attachment_category_id = $$v
                                    },
                                    expression: "attachment_category_id",
                                  },
                                },
                                _vm._l(_vm.treeData2, function (m, i) {
                                  return _c("el-option", {
                                    key: "treeData2" + i,
                                    attrs: {
                                      label: m.attachment_category_name,
                                      value: m.attachment_category_id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.changeType == 3
                          ? [
                              _c("el-input", {
                                staticClass: "w100 mr10",
                                attrs: { placeholder: "商品名称" },
                                model: {
                                  value: _vm.keyword,
                                  callback: function ($$v) {
                                    _vm.keyword = $$v
                                  },
                                  expression: "keyword",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.changeType == 4
                          ? [
                              _c("el-input", {
                                staticClass: "w100 mr10",
                                attrs: { placeholder: "拍卖会/专场/拍品" },
                                model: {
                                  value: _vm.keyword,
                                  callback: function ($$v) {
                                    _vm.keyword = $$v
                                  },
                                  expression: "keyword",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.changeType == 5
                          ? [
                              _c("el-input", {
                                staticClass: "w100 mr10",
                                attrs: { placeholder: "图片名称" },
                                model: {
                                  value: _vm.keyword,
                                  callback: function ($$v) {
                                    _vm.keyword = $$v
                                  },
                                  expression: "keyword",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.changeType == 6
                          ? [
                              _c("el-input", {
                                staticClass: "flex-one mr10",
                                attrs: { placeholder: "直播间名称" },
                                model: {
                                  value: _vm.keyword,
                                  callback: function ($$v) {
                                    _vm.keyword = $$v
                                  },
                                  expression: "keyword",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.getWorkList(1)
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "work-list flex" }, [
                      _c(
                        "div",
                        { staticClass: "work-l" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "add",
                              on: { click: _vm.addAction },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "add-t",
                              on: { click: _vm.addAction },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.tableData.data[0], function (m, i) {
                            return _c(
                              "div",
                              {
                                key: "l" + i,
                                staticClass: "work",
                                class: {
                                  curr:
                                    _vm.changeWorkIds.indexOf(m.product_id) !=
                                    -1,
                                },
                                attrs: { draggable: "true" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeWorkAction(m)
                                  },
                                  dragstart: function ($event) {
                                    return _vm.imgdragstart($event, m)
                                  },
                                  dragend: _vm.imgdragend,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: m.product.image + "!K200",
                                    alt: "",
                                    draggable: "true",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "work-name" }, [
                                  _vm._v(_vm._s(m.product.store_name)),
                                ]),
                                _vm._v(" "),
                                _vm.placeWorksIds.indexOf(m.product_id) != -1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "is-use item-flex-center",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-check",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "work-r" },
                        _vm._l(_vm.tableData.data[1], function (m, i) {
                          return _c(
                            "div",
                            {
                              key: "r" + i,
                              staticClass: "work",
                              class: {
                                curr:
                                  _vm.changeWorkIds.indexOf(m.product_id) != -1,
                              },
                              attrs: { draggable: "true" },
                              on: {
                                dragstart: function ($event) {
                                  return _vm.imgdragstart($event, m)
                                },
                                dragend: _vm.imgdragend,
                                click: function ($event) {
                                  return _vm.changeWorkAction(m)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: m.product.image + "!K200",
                                  alt: "",
                                  draggable: "true",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "work-name" }, [
                                _vm._v(_vm._s(m.product.store_name)),
                              ]),
                              _vm._v(" "),
                              _vm.placeWorksIds.indexOf(m.product_id) != -1
                                ? _c(
                                    "div",
                                    { staticClass: "is-use item-flex-center" },
                                    [_c("i", { staticClass: "el-icon-check" })]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "item-flex-center",
                        staticStyle: { "padding-bottom": "10px" },
                      },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                            "page-size": Number(_vm.queryParams.limit),
                            "current-page": Number(_vm.queryParams.page),
                            layout: "total, sizes, jumper",
                            total: _vm.tableData.total,
                          },
                          on: {
                            "size-change": _vm.setSizeChange,
                            "current-change": _vm.getWorkList,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.changeWorkIds.length > 0 && _vm.showwork
        ? _c(
            "div",
            { staticClass: "quick-deployment" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.quickDeployment },
                },
                [_vm._v("一键布展")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showworkflag
        ? _c("div", { staticClass: "pop-detail nochange" }, [
            _c("div", { staticClass: "ePZzdO" }, [
              _c("div", { staticClass: "emgskr" }, [
                _c("div", { staticClass: "cTdkra" }, [
                  _c(
                    "h2",
                    [
                      _vm.workdetail.work_type == 1
                        ? [_vm._v("藏品")]
                        : _vm.workdetail.work_type == 2
                        ? [_vm._v("作品")]
                        : _vm.workdetail.work_type == 3
                        ? [_vm._v("商品")]
                        : _vm.workdetail.work_type == 4
                        ? [_vm._v("拍品")]
                        : _vm.workdetail.work_type == 5
                        ? [_vm._v("素材")]
                        : _vm.workdetail.work_type == 6
                        ? [_vm._v("直播")]
                        : [_vm._v("作品")],
                      _vm._v(" "),
                      [_vm._v("信息")],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "iCYNuA mobile",
                      on: {
                        click: function ($event) {
                          _vm.showworkflag = false
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            fill: "none",
                            width: "14",
                            height: "14",
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 18 18",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M17.198.804a.698.698 0 0 1 0 .992l-15.4 15.4a.701.701 0 0 1-.991-.992l15.4-15.4a.7.7 0 0 1 .991 0Z",
                              fill: "#000",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M.807.804a.7.7 0 0 0 0 .992l15.4 15.4a.701.701 0 0 0 .991-.992L1.798.804a.7.7 0 0 0-.99 0Z",
                              fill: "#000",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "eSTBjN",
                  staticStyle: { "margin-top": "22px" },
                }),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "dnWPLx" }),
              _vm._v(" "),
              _c("h3", [_vm._v("图片信息")]),
              _vm._v(" "),
              _c("div", { staticClass: "fvzWAN" }, [
                _c("div", { staticClass: "preview-img-wrapper" }, [
                  _c("img", {
                    staticClass: "hOjzxy preview-img",
                    attrs: {
                      draggable: "true",
                      src: _vm.workdetail.product.image + "!120a",
                      loading: "lazy",
                      "data-index": "0",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "metadata" }, [
                  _c("div", { staticClass: "header-actions" }, [
                    _c(
                      "svg",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "16",
                          height: "16",
                          fill: "none",
                          viewBox: "0 0 16 16",
                        },
                        on: { click: _vm.remove },
                      },
                      [
                        _c("path", {
                          attrs: {
                            stroke: "#000",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "1.5",
                            d: "M5.334 4V2.667a1.333 1.333 0 011.333-1.334h2.667a1.333 1.333 0 011.333 1.334V4m2 0v9.333a1.334 1.334 0 01-1.333 1.334H4.667a1.334 1.334 0 01-1.333-1.334V4h9.333zM6.666 7.333v4M9.334 7.333v4",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("h3", { staticClass: "artwork-name" }, [
                      _vm._v(_vm._s(_vm.workdetail.product.store_name)),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", {
                  staticClass: "eSTBjN",
                  staticStyle: { "margin-bottom": "12px" },
                }),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "cMaYOi",
                    attrs: { width: "100%", height: "40px" },
                  },
                  [
                    _c("input", {
                      staticClass: "eetplR",
                      attrs: {
                        color: "#777980",
                        placeholder: "Untitled",
                        "font-size": "14px",
                        disabled: "",
                      },
                      domProps: { value: _vm.workdetail.product.store_name },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", {
                  staticClass: "eSTBjN",
                  staticStyle: { "margin-bottom": "12px" },
                }),
                _vm._v(" "),
                _c("h3", [_vm._v("调整尺寸")]),
                _vm._v(" "),
                _c("div", { staticClass: "hkcDzt", attrs: { mode: "light" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.slidervalue,
                        expression: "slidervalue",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.slidervalue },
                    on: {
                      change: _vm.changeslide,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.slidervalue = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.slidervalue,
                        expression: "slidervalue",
                      },
                    ],
                    staticClass: "slider",
                    style:
                      "background-image: linear-gradient(\n                90deg,\n                rgb(61, 63, 70) " +
                      (_vm.slidervalue / 200) * 100 +
                      "%,\n                rgb(223, 223, 228) " +
                      (_vm.slidervalue / 200) * 100 +
                      "%\n              );\n              box-shadow: rgba(34, 34, 43, 0.11) 0px 2px 4px,\n                rgba(34, 35, 41, 0.05) 0px 1px 1px;",
                    attrs: { type: "range", min: "1", max: "200" },
                    domProps: { value: _vm.slidervalue },
                    on: {
                      input: _vm.changeslide,
                      __r: function ($event) {
                        _vm.slidervalue = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "transform" }, [
                  _c("label", { attrs: { for: "checkbox" } }, [
                    _c("input", {
                      attrs: { id: "checkbox", type: "checkbox" },
                      domProps: { checked: _vm.controlsIsUsed },
                      on: { change: _vm.changecontrolsIsUsed },
                    }),
                    _vm._v("\n            是否启用3D编辑\n          "),
                  ]),
                ]),
                _vm._v(" "),
                _c("h3", [_vm._v("按E切换到旋转，R切换回位移")]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "right-show-3d",
        attrs: { id: "container" },
        on: {
          touchmove: _vm.touchmove,
          mousemove: _vm.touchmove,
          touchstart: _vm.touchstart,
          mousedown: _vm.touchstart,
          mouseup: _vm.touchend,
          mouseout: _vm.touchend,
          touchend: _vm.touchend,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改空间介绍",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("Tinymce", {
            ref: "editor",
            attrs: { height: 500 },
            model: {
              value: _vm.space_desc,
              callback: function ($$v) {
                _vm.space_desc = $$v
              },
              expression: "space_desc",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-items-c t ml10 mr10" }, [
      _c("div", { staticClass: "flex-one" }, [_vm._v("空间详情")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-items-c t ml10 mr10" }, [
      _c("div", { staticClass: "flex-one" }, [_vm._v("文字")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bvtzSZ" }, [_c("h3", [_vm._v("标题")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }