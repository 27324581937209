"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _element = _interopRequireDefault(require("./element"));
/*
 * @Author: qinuoyun
 * @Date:   2020-08-31 15:42:11
 * @Last Modified by:   qinuoyun
 * @Last Modified time: 2021-02-11 22:12:16
 */
var _default = exports.default = {
  element: _element.default
};