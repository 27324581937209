var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { "background-color": _vm.facade.backgroundColor } },
    _vm._l(_vm.defaultData, function (m, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "auction-meeting",
          class: { mt0: i === 0 },
          style: { marginTop: _vm.facade.meetingMarginTop / 2 + "px" },
        },
        [
          _c(
            "div",
            {
              style: {
                padding:
                  _vm.facade.meetingPaddingTop / 2 +
                  "px " +
                  _vm.facade.meetingPaddingRight / 2 +
                  "px " +
                  _vm.facade.meetingPaddingBottom / 2 +
                  "px " +
                  _vm.facade.meetingPaddingLeft / 2 +
                  "px",
              },
            },
            [
              _c("div", { staticClass: "title item-align-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "l flex-one",
                    style: {
                      color: _vm.facade.meetingNameColor,
                      fontSize: _vm.facade.meetingNameFontSize / 2 + "px",
                      "font-weight": _vm.facade.meetingNameFontWeight,
                    },
                  },
                  [
                    _vm._v(
                      "第三届大众艺术品拍卖会书画第三届大众艺术品拍卖会书画"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "r ml10",
                    style: {
                      color: _vm.facade.meetingAlbumCountColor,
                      fontSize: _vm.facade.meetingAlbumCountFontSize / 2 + "px",
                      "font-weight": _vm.facade.meetingAlbumCountFontWeight,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        style: { color: _vm.facade.meetingAlbumNowCountColor },
                      },
                      [_vm._v("2")]
                    ),
                    _vm._v("个专场"),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex",
                  style: {
                    color: _vm.facade.meetingInfoColor,
                    fontSize: _vm.facade.meetingInfoFontSize / 2 + "px",
                    "font-weight": _vm.facade.meetingInfoFontWeight,
                    "margin-top": _vm.facade.meetingInfoMarginTop / 2 + "px",
                  },
                },
                [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _vm.facade.isset
                    ? _c(
                        "div",
                        {
                          staticClass: "flex-one relative",
                          staticStyle: { bottom: "2px" },
                        },
                        [
                          _vm.content.style == 1
                            ? _c("pointer", {
                                attrs: {
                                  color: _vm.facade.indicatorColor,
                                  type: _vm.facade.indicatorStyle,
                                  align: "right",
                                  vAlign: "bottom",
                                  margin: 0,
                                  rightFixed: "1",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.facade.isset
            ? _c("album", {
                attrs: {
                  facade: _vm.facade,
                  content: _vm.content,
                  "is-meeting": "1",
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", [_vm._v("北京国际饭店")]),
      _vm._v(" "),
      _c("span", [_vm._v("3月27日 19点开拍")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }