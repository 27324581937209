"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      recordData: {}
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    this.loadStreamInfo();
    console.log(this.venue_name);
  },
  methods: {
    changeTab: function changeTab(data) {
      if (data !== this.$route.path) {
        this.$router.push({
          path: data
        });
      }
    },
    loadStreamInfo: function loadStreamInfo() {
      var _this = this;
      (0, _accounts.SMSInfo)().then(function (res) {
        _this.recordData = res.data;
      });
    }
  }
};