"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _user = require("@/api/user.js");
var _warehouse = require("@/api/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    detailjson: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      merData: {},
      showFiled: [],
      attrItem: ['190', '198', '203', '204', '209', '210', '211', '212', '213', '214', '215', '232', '233', '234', '235', '236', '237', '238', '239', '240', '241']
    };
  },
  filters: {
    nameFirter: function nameFirter(str) {
      var arr = str.split('|');
      if (arr.length > 1) return arr[0];else return str;
    },
    englis: function englis(str) {
      var arr = str.split('|');
      if (arr.length > 1) return arr[1];else return ' ';
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_transfers_warehouse_print'
    }).then(function (res) {
      _this.showFiled = res.data.erp_v2_transfers_warehouse_print.show_list;
    });
  },
  methods: {}
};