"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.function.name");
var _uploadPDF = _interopRequireDefault(require("../uploadPDF"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Distribution",
  components: {
    uploadPDF: _uploadPDF.default
  },
  data: function data() {
    return {
      selectData1: {},
      selectData2: {},
      classifyData1: [{
        name: "店铺首页",
        child: [],
        link: "/"
      }, {
        name: "列表页",
        link: "",
        child: [{
          name: "征集活动列表",
          child: [],
          link: "/pages/auctionCollect/index"
        }, {
          name: "商城分类列表",
          child: [],
          link: "/pages/category/index"
        }, {
          name: "商城列表",
          child: [{
            key: "cate_f_id",
            value: "",
            placeholder: "一级分类ID"
          }, {
            key: "cate_id",
            value: "",
            placeholder: "二级分类ID"
          }],
          link: "/pages/productList/index?"
        }, {
          name: "艺术盲盒列表",
          child: [{
            key: "albumid",
            value: "",
            placeholder: "系列id"
          }],
          link: "/pages/product/blind?"
        }, {
          name: "邀请函列表",
          child: [],
          link: "/pages/marketing/invitation"
        },
        /*  {
          name: "鉴定问题列表",
          child: [],
          link: "/sub-pages/appraisal-online/questions-list/questions-list",
        }, */
        {
          name: "鉴定专家列表",
          child: [{
            key: "title",
            value: "",
            placeholder: "分类ID"
          }],
          link: "/pages/appraisal-online/expert-list/expert-list?"
        }]
      }, {
        name: "详情页",
        child: [{
          name: "自定义页面详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入自定义页面ID",
            required: true
          }],
          link: "/pages/home/home?"
        }, {
          name: "拍卖会详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入拍卖会ID",
            required: true
          }],
          link: "/pages/auctionMeeting/index?"
        }, {
          name: "专场详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入专场ID",
            required: true
          }],
          link: "/pages/albumDetail/index?"
        }, {
          name: "拍品详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入拍品ID",
            required: true
          }],
          link: "/pages/auctionDetail/index?"
        }, {
          name: "商品详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入商品ID",
            required: true
          }],
          link: "/pages/productDetail/index?"
        }, {
          name: "会员卡详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入会员卡ID",
            required: true
          }],
          link: "/pages/member/detail?"
        }, {
          name: "文章详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入文章ID",
            required: true
          }],
          link: "/pages/word/index?"
        }, {
          name: "数字藏品详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入藏品ID",
            required: true
          }],
          link: "/pages/nftCollection/index?"
        }, {
          name: "合成详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入合成ID",
            required: true
          }],
          link: "/pages/marketing/compose/nft_compose?"
        }, {
          name: "征集活动详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入征集活动ID",
            required: true
          }],
          link: "/pages/auctionCollect/detail?"
        }, {
          name: "助力活动详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入助力活动ID",
            required: true
          }],
          link: "/pages/marketing/helpActivity/helpActivity?"
        }, {
          name: "NFT盲盒详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入NFT盲盒ID",
            required: true
          }],
          link: "/pages/nftCollection/nftbox?"
        }, {
          name: "艺术盲盒详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入艺术盲盒ID",
            required: true
          }],
          link: "/pages/product/blinddetail?"
        }, {
          name: "3D空间详情",
          child: [{
            key: "id",
            value: "",
            placeholder: "输入3D空间ID",
            required: true
          }],
          link: "/pages/threepreview?"
        }],
        link: ""
      }, {
        name: "购物车",
        child: [],
        link: "/pages/shoppingCart/index"
      }, {
        name: "寄售",
        child: [],
        link: "/nft_market/home"
      }, {
        name: "我的藏品",
        child: [],
        link: "/users/my_nft"
      }, {
        name: "个人中心",
        child: [],
        link: "/pages/users/index"
      }, {
        name: "其他",
        child: [{
          name: "自定义链接",
          child: [{
            key: "link",
            value: "",
            placeholder: "输入跳转地址"
          }],
          link: ""
        }, {
          name: "公众号链接",
          child: [{
            key: "official_account",
            value: "",
            placeholder: "输入跳转地址"
          }],
          link: ""
        }, {
          name: "领取优惠券",
          child: [],
          link: "getCoupons"
        }, {
          name: "电话",
          child: [{
            key: "phone",
            value: "",
            placeholder: "输入电话",
            required: true
          }],
          link: "phone?"
        }, {
          name: "客服",
          child: [],
          link: "contact",
          link_h5: "contact"
        }, {
          name: "打开PDF文件",
          child: [{
            key: "url",
            value: "",
            placeholder: "输入电话",
            uploadPdf: true
          }],
          link: "pdf?",
          link_h5: ""
        }, {
          name: "问卷调查",
          child: [{
            key: "id",
            value: "",
            placeholder: "问卷ID"
          }],
          link: "/pages/questionnaire/questionnaire?"
        }],
        link: ""
      }],
      classifyData2: [],
      classifyData3: [],
      showSet: false,
      ossClient: null,
      statusText: false,
      authProgress: 0,
      fileUrl: ""
    };
  },
  methods: {
    changeshow: function changeshow(data) {
      var _this = this;
      if (data.name || data.name1) {
        for (var key in this.classifyData1) {
          var val = this.classifyData1[key];
          if (val.name === data.name) {
            this.selectData1 = val;
            this.classifyData2 = val.child;
          }
        }
        if (data.name1) {
          var _loop = function _loop() {
            var val = _this.classifyData2[_key];
            if (val && val.name === data.name1) {
              _this.selectData2 = val;
              _this.classifyData3 = val.child;
              if (data.name1 === "自定义链接") {
                _this.classifyData3[0].value = data.link;
              } else if (data.name1 === "公众号链接") {
                _this.classifyData3[0].value = data.link;
              } else if (_this.classifyData3.length > 0) {
                var query = data.link.split("?")[1];
                var qList = query.split("&");
                _this.classifyData3.forEach(function (m) {
                  qList.forEach(function (cc) {
                    var hasIndex = cc.indexOf("".concat(m.key, "="));
                    console.log(cc, m, hasIndex);
                    if (hasIndex !== -1) {
                      var param = cc.split("=");
                      m.value = param[1];
                    }
                  });
                });
              }
            }
          };
          for (var _key in this.classifyData2) {
            _loop();
          }
        }
      } else {
        this.selectData1 = {};
        this.selectData2 = {};
        this.classifyData2 = [];
        this.classifyData3 = [];
      }
      this.showSet = true;
    },
    handleChange1: function handleChange1(item) {
      this.classifyData2 = item.child;
      this.classifyData3 = [];
    },
    handleChange2: function handleChange2(item) {
      item.child.forEach(function (m) {
        if (m.value) {
          m.value = "";
        }
      });
      this.classifyData3 = item.child;
    },
    uploadPDF: function uploadPDF(url) {
      console.log(this.classifyData3, url);
      this.classifyData3[0].value = url;
    },
    submitAction: function submitAction() {
      var data = {
        link: "",
        name: "",
        name1: ""
      };
      if (!this.selectData1.name) {
        this.showSet = false;
        // 关闭
      } else {
        data.link = this.selectData1.link;
        data.name = this.selectData1.name;
        if (this.selectData1.child.length > 0) {
          if (!this.selectData2.name) {
            this.$message.error("请选择具体跳转链接");
            return false;
            // 关闭
          }
          data.link += this.selectData2.link;
          data.name1 = this.selectData2.name;
          if (this.selectData2.child.length > 0) {
            if (data.link) {
              var d = [];
              for (var key in this.classifyData3) {
                var m = this.classifyData3[key];
                if (m.required && !m.value) {
                  this.$message.error("请输入具体跳转内容ID");
                  return false;
                }
                d.push("".concat(m.key, "=").concat(m.value));
              }
              data.link += d.length > 0 ? d.join("&") : "";
            } else {
              data.link = this.classifyData3[0].value;
            }
          }
        }
      }
      this.$emit("selectMessage", {
        data: data
      });
      this.showSet = false;
    }
  }
};