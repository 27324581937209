"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _product = require("@/api/product");
var _vuex = require("vuex");
var _live = require("@/api/live");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _nftAlbum = _interopRequireDefault(require("@/components/customChildren/nftAlbum"));
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default,
    Nft: _nft.default,
    NftAlbum: _nftAlbum.default,
    Product: _goods.default
  },
  data: function data() {
    var _this = this;
    var validateUpload2 = function validateUpload2(rule, value, callback) {
      if (!_this.ruleForm.cate_id) {
        callback(new Error('请选择文章分类'));
      } else {
        callback();
      }
    };
    return {
      album_id: '',
      id: '',
      status: 1,
      sleOptions: {
        title: '',
        category_id: ''
      },
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      ruleForm: {
        image_show_style: 1,
        cover_image: '',
        live_screen_direction: 1,
        live_stream_type: 'LVB',
        live_title: '',
        live_type: 'news',
        is_open_gift: 1,
        is_open_chat: 1,
        is_open_like: 1,
        message: '',
        is_watch_video_playback: 1,
        cate_id: '',
        open_rule: 0,
        qrcode_url: ''
      },
      treeData: [],
      rules: {
        live_title: {
          required: true,
          message: '请输入直播标题',
          trigger: 'blur'
        },
        cate_id: [{
          required: true,
          validator: validateUpload2,
          trigger: 'change'
        }]
      },
      startTime: {
        year: undefined,
        month: undefined,
        date: undefined,
        hour: undefined,
        minute: undefined
      },
      orderArr: [{
        type: 'nft',
        data: []
      }, {
        type: 'nft_album',
        data: []
      }, {
        type: 'product',
        data: []
      }, {
        type: 'snapshot',
        data: []
      }],
      changeType: '',
      changeIndex: 0,
      searchLoading: false,
      snapshotList: [],
      peopleNum: 0,
      liveType: ''
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })),
  mounted: function mounted() {
    this.live_id = this.$route.query.id || '';
    this.getList();
    if (this.live_id) {
      this.loadLiveDetail();
    } else {
      this.liveType = 'news';
      var nowDate = new Date();
      this.setTime('startTime', nowDate);
    }
  },
  methods: {
    // 所有分类
    getList: function getList() {
      var _this2 = this;
      (0, _live.liveListApi)().then(function (res) {
        _this2.treeData = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 分类点击
    handleSelClick: function handleSelClick(node) {
      console.log(node);
      this.ruleForm.cate_id = node.category_id;
      this.sleOptions = {
        title: node.title,
        category_id: node.category_id
      };
    },
    loadLiveDetail: function loadLiveDetail() {
      var _this3 = this;
      (0, _live.liveDetailApi)(this.live_id).then(function (res) {
        var data = res.data;
        if (data.cate_id) {
          _this3.sleOptions.title = data.category.title;
          _this3.sleOptions.category_id = data.cate_id;
        }
        if (data.extend_data.watch_rule && data.extend_data.watch_rule.length) {
          data.extend_data.watch_rule.forEach(function (m) {
            if (m.type === 'snapshot') {
              _this3.orderArr[3].data = m.data;
              _this3.loadSnapshot(m.data.join(','));
              _this3.changeSnapshotAction(m.data);
            } else {
              var index = 0;
              if (m.type === 'nft') {
                index = 0;
              } else if (m.type === 'product') {
                index = 2;
              } else if (m.type === 'nft_album') {
                index = 1;
              }
              _this3.orderArr[index].data = m.data.map(function (m) {
                return {
                  id: m
                };
              });
            }
          });
        }
        _this3.liveType = data.live_type;
        _this3.ruleForm = {
          image_show_style: data.image_show_style ? data.image_show_style : 1,
          cover_image: data.cover_image,
          live_screen_direction: data.extend_data.live_screen_direction,
          live_stream_type: data.live_stream_type,
          live_title: data.live_title,
          live_type: data.live_type,
          is_open_gift: data.extend_data.is_open_gift,
          is_open_chat: data.extend_data.is_open_chat,
          is_open_like: data.extend_data.is_open_like,
          is_watch_video_playback: data.extend_data.is_watch_video_playback,
          virtual_num: data.extend_data.virtual_num,
          cate_id: data.cate_id,
          message: data.message,
          qrcode_url: data.extend_data.qrcode_url || '',
          open_rule: data.extend_data.watch_rule && data.extend_data.watch_rule.length ? 1 : 0
        };
        _this3.setTime('startTime', data.start_time * 1000);
        _this3.$refs['editor'].setContent(data.message || '');
      }).catch(function (e) {
        console.log(e);
        _this3.$router.back();
      });
    },
    changeImg: function changeImg(key) {
      var _this4 = this;
      this.$modalUpload(function (img) {
        if (key === 'qrcode_url') {
          _this4.ruleForm.qrcode_url = img[0];
        } else {
          _this4.ruleForm.cover_image = img[0];
        }
      }, 1, '1');
    },
    delImgAction: function delImgAction() {
      this.ruleForm.qrcode_url = '';
    },
    saveAction: function saveAction() {
      var _this5 = this;
      var data = JSON.parse(JSON.stringify(this.ruleForm));
      if (!data.cover_image) {
        this.$message.error('请上传直播封面图');
        return false;
      }
      if (!data.live_title) {
        this.$message.error('请输入直播标题');
        return false;
      }

      // 开播时间处理
      var startTime = this.getTime('startTime');
      if (typeof startTime === 'string') {
        this.$message.error("".concat(startTime, "\u5F00\u64AD\u65F6\u95F4"));
        return false;
      }
      data.start_time = startTime / 1000;
      var watch_rule = [];
      if (data.open_rule) {
        this.orderArr.forEach(function (m) {
          if (m.data.length) {
            var newM = JSON.parse(JSON.stringify(m));
            if (m.type !== 'snapshot') {
              newM.data = newM.data.map(function (item) {
                return item.id;
              });
            }
            watch_rule.push(newM);
          }
        });
        if (watch_rule.length === 0) {
          this.$message.error('请设置观看权限');
          return false;
        }
        data.watch_rule = JSON.stringify(watch_rule);
      }
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var action = _live.createLiveApi;
      if (this.live_id) {
        action = _live.editLiveApi;
      }
      action(this.live_id, data).then(function () {
        loading.close();
        _this5.$message.success('操作成功');
        _this5.$router.back();
      }).catch(function (msg) {
        loading.close();
        _this5.$message.error(msg);
      });
    },
    getTime: function getTime(key, noHour) {
      if (!this[key].year || !this[key].month || !this[key].date) {
        return '请完整设置';
      }
      var d = new Date("".concat(this[key].year, "-").concat(this[key].month, "-").concat(this[key].date));
      if (!noHour) {
        d.setHours(this[key].hour || 0);
        d.setMinutes(this[key].minute || 0);
        d.setSeconds(0);
      } else {
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
      }
      return Date.parse(d);
    },
    setTime: function setTime(key, date, noHour) {
      var d = new Date(date);
      console.log(d);
      this[key].year = d.getFullYear();
      this[key].month = d.getMonth() + 1;
      this[key].date = d.getDate();
      if (!noHour) {
        this[key].hour = d.getHours();
        this[key].minute = d.getMinutes();
      }
    },
    backAction: function backAction() {
      this.$router.back();
    },
    handleAdditem: function handleAdditem() {
      this.orderArr = this.orderArr.concat([{
        type: 'nft',
        data: []
      }]);
    },
    editAction: function editAction(i) {
      if (this.orderArr[i]) {
        this.orderArr[i].data = [];
      }
    },
    showChangeAction: function showChangeAction(view, i) {
      this.changeType = view;
      this.changeIndex = i;
      this.$refs[view].changeshow();
    },
    selectMessage: function selectMessage(data) {
      if (this.orderArr[this.changeIndex]) {
        this.orderArr[this.changeIndex].data = data.data;
      }
      // const changeIds = this.orderArr.map(m => m.id);
      // let changeList = [];
      // for (const i in data.data) {
      //   const m = data.data[i];
      //   const index = changeIds.indexOf(m.id);
      //   if (index === -1) {
      //     m.have_count = 1;
      //     m.type = this.changeType;
      //     changeList = changeList.concat([m]);
      //   }
      // }
      // if (changeList.length) {
      //   this.orderArr = this.orderArr.concat(changeList);
      // }
    },
    loadSnapshot: function loadSnapshot(ids) {
      var _this6 = this;
      this.searchLoading = true;
      var params = {
        page: 1,
        limit: 10,
        snapshot_ids: ids
      };
      (0, _product.snapshotListApi)(params).then(function (res) {
        _this6.snapshotList = res.data.list;
        _this6.searchLoading = false;
      });
    },
    remoteMethod: function remoteMethod(query) {
      var _this7 = this;
      if (query !== '') {
        this.searchLoading = true;
        var params = {
          page: 1,
          limit: 10,
          title: query
        };
        (0, _product.snapshotListApi)(params).then(function (res) {
          _this7.snapshotList = res.data.list;
          _this7.searchLoading = false;
        });
      } else {
        this.snapshotList = [];
      }
    },
    changeSnapshotAction: function changeSnapshotAction(data) {
      var _this8 = this;
      console.log(data);
      if (data.length) {
        (0, _product.snapshotUserListApi)({
          snapshot_id: data.join(',')
        }).then(function (res) {
          _this8.peopleNum = res.data.count;
        });
      } else {
        this.peopleNum = 0;
      }
      // snapshotUserListApi()
    },
    resetAction: function resetAction(i) {
      if (this.orderArr[i].data.length) {
        this.orderArr[i].data = [];
        if (i === 3) {
          this.peopleNum = 0;
        }
      }
    }
  }
};