var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "name"
        ? [
            _c(
              "control",
              { attrs: { title: "专场名称", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "拍卖会颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.meeting_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "meeting_color", $$v)
                              },
                              expression: "facade.meeting_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "拍卖会字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.meeting_fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "meeting_fontSize", $$v)
                              },
                              expression: "facade.meeting_fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "拍卖会行高" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.meeting_lineHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "meeting_lineHeight", $$v)
                              },
                              expression: "facade.meeting_lineHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "拍卖会风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.meeting_fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "meeting_fontWeight",
                                    $$v
                                  )
                                },
                                expression: "facade.meeting_fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "专场颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "专场字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "专场行高" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.lineHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "lineHeight", $$v)
                              },
                              expression: "facade.lineHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "专场风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "control",
              { attrs: { title: "专场状态", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "状态样式" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "item-align-center",
                            staticStyle: { height: "30px" },
                            model: {
                              value: _vm.facade.status_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "status_type", $$v)
                              },
                              expression: "facade.status_type",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                staticClass: "item-align-center",
                                attrs: { label: 1 },
                              },
                              [
                                _c("div", { staticClass: "status-type1" }, [
                                  _vm._v("预展中"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                staticClass: "item-align-center",
                                attrs: { label: 2 },
                              },
                              [
                                _c("div", { staticClass: "status-type2" }, [
                                  _vm._v("预展中"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                staticClass: "item-align-center",
                                attrs: { label: 3 },
                              },
                              [
                                _c("div", { staticClass: "status-type3" }, [
                                  _vm._v("预展中"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "时间风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.time_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "time_type", $$v)
                              },
                              expression: "facade.time_type",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { label: 1 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "time1 flex align-items-c ft12",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#000",
                                              "font-weight": "500",
                                              "margin-right": "5px",
                                            },
                                          },
                                          [_vm._v("结拍")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bg" }, [
                                          _vm._v("1"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("天")]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bg" }, [
                                          _vm._v("12"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("时")]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bg" }, [
                                          _vm._v("23"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("分")]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "bg" }, [
                                          _vm._v("20"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v("秒")]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: { "margin-top": "3px" },
                                    attrs: { label: 2 },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "time2 flex align-items-c ft12",
                                        style: { color: _vm.facade.color },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#000",
                                              "font-weight": "500",
                                              "margin-right": "5px",
                                            },
                                          },
                                          [_vm._v("结拍")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item" }, [
                                          _vm._v("1"),
                                          _c("span", [_vm._v("天")]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item" }, [
                                          _vm._v("12"),
                                          _c("span", [_vm._v("时")]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item" }, [
                                          _vm._v("23"),
                                          _c("span", [_vm._v("分")]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "item" }, [
                                          _vm._v("20"),
                                          _c("span", [_vm._v("秒")]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "开拍提醒" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "时间颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.timeColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "timeColor", $$v)
                            },
                            expression: "facade.timeColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.facade.time_type === 1
                      ? [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "突出背景" },
                            },
                            [
                              _c("colour", {
                                attrs: { colour: "#000000" },
                                model: {
                                  value: _vm.facade.bgBtnColor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "bgBtnColor", $$v)
                                  },
                                  expression: "facade.bgBtnColor",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "突出文字" },
                            },
                            [
                              _c("colour", {
                                attrs: { colour: "#000000" },
                                model: {
                                  value: _vm.facade.bgColor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "bgColor", $$v)
                                  },
                                  expression: "facade.bgColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm.facade.time_type === 2
                      ? [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mt20",
                              attrs: { label: "突出时间" },
                            },
                            [
                              _c("colour", {
                                attrs: { colour: "#000000" },
                                model: {
                                  value: _vm.facade.activeColor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "activeColor", $$v)
                                  },
                                  expression: "facade.activeColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "detail"
        ? [
            _c(
              "control",
              { attrs: { title: "专场详情", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "突出颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.activeColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "activeColor", $$v)
                            },
                            expression: "facade.activeColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "突出风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.activeFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "activeFontWeight", $$v)
                              },
                              expression: "facade.activeFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 50, max: 300 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图片圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.img_radius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "img_radius", $$v)
                            },
                            expression: "facade.img_radius",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "control",
              { attrs: { title: "介绍文字", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 20, max: 200 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-align-center mt20" },
                      [
                        _c(
                          "div",
                          { staticClass: "fontW400 ft14 color-black" },
                          [_vm._v("介绍折叠")]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "flex-one",
                          staticStyle: { "font-size": "12px", color: "#999" },
                        }),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#009406",
                            "inactive-color": "#cccccc",
                          },
                          model: {
                            value: _vm.facade.fold,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fold", $$v)
                            },
                            expression: "facade.fold",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品列表", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "默认模板" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.listShow,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "listShow", $$v)
                              },
                              expression: "facade.listShow",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("单列"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("双列"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("列表"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "即将结拍排序" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.endOrderType,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "endOrderType", $$v)
                              },
                              expression: "facade.endOrderType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "end" } }, [
                              _vm._v("按结束时间"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "order_num" } }, [
                              _vm._v("按LOT号"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ft14" }, [
                  _vm._v("请在左侧拍品组件里设置"),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }