var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "calc(100vh - 230px)" } },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%", color: "#000" },
          attrs: {
            data: _vm.tableData.data,
            border: "",
            height: "100%",
            "highlight-current-row": "",
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "tableEmpty" }, [
              _c("img", {
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
            ]),
          ]),
          _vm._v(" "),
          _c("ytx-table-column", {
            attrs: {
              label: "头像",
              width: "50",
              align: "center",
              prop: "",
              "search-query": _vm.searchQuery,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: {
                        width: "25px",
                        height: "25px",
                        "margin-top": "6px",
                      },
                      attrs: {
                        src:
                          scope.row.user.avatar +
                          (scope.row.user.avatar.indexOf(
                            "https://saas.cdn.yunzongbu.cn"
                          ) === -1
                            ? ""
                            : "!120a"),
                        alt: "",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ytx-table-column", {
            attrs: {
              label: "客户",
              width: "300",
              prop: "",
              "search-key": "keywords",
              "search-query": _vm.searchQuery,
            },
            on: { changeVal: _vm.searchAction },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "color-lan",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openUserInfoAction(scope.row.user.uid)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.user.real_name || scope.row.user.nickname
                          )
                        ),
                        scope.row.user.real_name
                          ? _c("span", [
                              _vm._v(
                                "(" + _vm._s(scope.row.user.nickname) + ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    scope.row.user.phone
                      ? _c("span", [_vm._v(_vm._s(scope.row.user.phone))])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.user.mark
                      ? _c("span", [_vm._v(_vm._s(scope.row.user.mark))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ytx-table-column", {
            attrs: {
              label: "可用余额",
              prop: "",
              "search-query": _vm.searchQuery,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "num" }, [
                      _vm._v(
                        _vm._s(_vm.formatPriceNumber(scope.row.balance, 2))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ytx-table-column", {
            attrs: {
              label: "冻结余额",
              prop: "",
              "search-query": _vm.searchQuery,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "num color-lan cur",
                        on: {
                          click: function ($event) {
                            return _vm.openUserAction(2, scope.row.user.uid)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(scope.row.frozenPrice, 2)
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ytx-table-column", {
            attrs: {
              label: "历史充值",
              prop: "",
              "search-query": _vm.searchQuery,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "num color-lan cur",
                        on: {
                          click: function ($event) {
                            return _vm.openUserAction(3, scope.row.user.uid)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(scope.row.rechargePrice, 2)
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ytx-table-column", {
            attrs: {
              label: "历史退款",
              prop: "",
              "search-query": _vm.searchQuery,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "num color-lan cur",
                        on: {
                          click: function ($event) {
                            return _vm.openUserAction(4, scope.row.user.uid)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              scope.row.totalExtractPrice,
                              2
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ytx-table-column", {
            attrs: {
              label: "历史消费",
              prop: "",
              "search-query": _vm.searchQuery,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "num color-lan cur",
                        on: {
                          click: function ($event) {
                            return _vm.openUserAction(5, scope.row.user.uid)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              scope.row.totalPayOrderPrice,
                              2
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("ytx-table-column", {
            attrs: {
              label: "对账状态",
              prop: "",
              "search-query": _vm.searchQuery,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.reconciliation_status == 0
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "danger" } },
                          [_vm._v("错误")]
                        )
                      : scope.row.reconciliation_status == 1
                      ? _c("el-tag", { attrs: { size: "small" } }, [
                          _vm._v("正常"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }