var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "content-box",
        style: {
          padding: "0 " + _vm.facade.page_padding / 2 + "px",
          background: _vm.facade.background_color,
        },
      },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "one-list",
            class: { mt0: index === 0 },
            style: {
              marginTop: _vm.facade.item_bottom_padding / 2 + "px",
              borderBottomRightRadius:
                _vm.facade.content_bottom_radius / 2 + "px",
              borderBottomLeftRadius:
                _vm.facade.content_bottom_radius / 2 + "px",
              borderTopRightRadius: _vm.facade.content_top_radius / 2 + "px",
              borderTopLeftRadius: _vm.facade.content_top_radius / 2 + "px",
              background: _vm.facade.status_background,
              padding:
                _vm.facade.content_padding_top / 2 +
                "px " +
                _vm.facade.content_padding_left / 2 +
                "px",
            },
          },
          [
            _c("div", { staticClass: "question-title" }, [
              _vm._v(_vm._s(item.name)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "question-time" }, [
              _vm._v(_vm._s(item.time)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "question-image" },
              [
                _vm._l(item.imgs, function (img, i) {
                  return i < 3
                    ? _c("img", {
                        key: i,
                        style: {
                          borderRadius: _vm.facade.img_radius / 2 + "px",
                        },
                        attrs: { src: img + "!120a", alt: "" },
                      })
                    : _vm._e()
                }),
                _vm._v(" "),
                item.imgs.length > 3
                  ? _c("div", { staticClass: "question-more" }, [
                      _c("img", {
                        attrs: { src: item.imgs[0] + "!120a", alt: "" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "more-c" }, [
                        _vm._v("+" + _vm._s(item.imgs.length - 3)),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-box" }, [
              _c("div", { staticClass: "bottom-left" }, [
                _c("div", [
                  _vm._v(_vm._s(item.tag) + " " + _vm._s(item.price)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sign" }, [_vm._v(_vm._s(item.sign))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom-right" }, [
                _c("img", {
                  attrs: {
                    src: "https://img.yunzongbu.cn/node/2019/93/156751789182135491.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(item.click_num))]),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }