"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      showdetail: null,
      uid: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        user_keyword: '',
        date: '',
        status: '',
        end_date: '',
        membership_card_keyword: '',
        is_pay: '',
        is_again: ''
      },
      timeVal: [],
      expiration_timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      showImage: false,
      idData: {},
      showQY: false,
      qyList: [],
      changeList: [],
      showRefundMoney: false,
      refundMoneyData: {},
      showExtension: false,
      extensionData: {}
    };
  },
  created: function created() {
    if (this.$route.query.name) {
      this.tableFrom.user_keyword = this.$route.query.name || '';
    }
    if (this.$route.query.end) {
      this.tableFrom.end_date = this.$route.query.end || '';
    }
    if (this.$route.query.status) {
      this.tableFrom.status = this.$route.query.status || '';
    }
    this.getList();
  },
  methods: {
    memberSwitchStatusAction: function memberSwitchStatusAction(item) {
      var _this = this;
      this.$confirm("\u786E\u8BA4".concat(item.status === 1 ? '禁用此会员' : '启用此会员', "?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _member.memberSwitchStatusApi)({
          id: item.user_membership_card_id,
          status: item.status === 1 ? 3 : 1
        }).then(function () {
          _this.$message.success('操作成功');
          _this.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    confirmExtensionAction: function confirmExtensionAction() {
      var _this2 = this;
      if (this.extensionData.extend_type === 2 && !this.extensionData.exTime) {
        this.$message.error('请选择延期至几月几日');
        return false;
      }
      if (this.extensionData.extend_type === 1 && !this.extensionData.exDay) {
        this.$message.error('请输入延期天数');
        return false;
      }
      this.$confirm("\u786E\u8BA4\u5EF6\u671F?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          id: _this2.extensionData.id,
          extend_type: _this2.extensionData.extend_type
        };
        if (data.extend_type === 2) {
          data.end_time = _this2.extensionData.exTime / 1000;
        } else if (data.extend_type === 1) {
          data.end_time = _this2.extensionData.exDay;
        }
        (0, _member.memberExtendEndTimeApi)(data).then(function () {
          _this2.showExtension = false;
          _this2.$message.success('操作成功');
          _this2.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showExtensionAction: function showExtensionAction(data) {
      this.extensionData = {
        extend_type: 2,
        exDay: undefined,
        exTime: '',
        id: data.user_membership_card_id
      };
      this.showExtension = true;
    },
    showAllExtensionAction: function showAllExtensionAction() {
      if (this.changeList.length <= 0) {
        this.$message.error('请先选择会员');
        return false;
      }
      this.extensionData = {
        extend_type: 2,
        exDay: undefined,
        exTime: '',
        id: this.changeList.map(function (m) {
          return m.user_membership_card_id;
        }).join(',')
      };
      this.showExtension = true;
    },
    refundMoneyAction: function refundMoneyAction(data) {
      data.refund = undefined;
      this.refundMoneyData = data;
      this.showRefundMoney = true;
    },
    confirmRefundAction: function confirmRefundAction() {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u9000\u6B3E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _member.memberCardRefundApi)({
          order_id: _this3.refundMoneyData.order_id,
          refund_price: _this3.refundMoneyData.refund
        }).then(function () {
          _this3.showRefundMoney = false;
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    handleSelectionChange: function handleSelectionChange(e) {
      this.changeList = e;
    },
    // 选择时间
    selectChange: function selectChange(key, tab) {
      this.timeVal = [];
      this.tableFrom[key] = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 具体日期
    onchangeTime1: function onchangeTime1(e) {
      this.expiration_timeVal = e;
      this.tableFrom.end_date = e ? this.expiration_timeVal.join('-') : '';
      this.getList(1);
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _member.memberOrderListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.changeList = [];
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this5 = this;
      var data = (0, _objectSpread2.default)({
        export_type: 'userMembershipCardOrder'
      }, this.tableFrom);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('userMembershipCardOrder');
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    showImageAction: function showImageAction(data) {
      this.idData = data;
      this.showImage = true;
    },
    showQYAction: function showQYAction(item) {
      item.forEach(function (m) {
        var txt = '';
        switch (m.right_type) {
          case 0:
            txt = '自定义权益';
            break;
          case 1:
            txt = '场馆签到';
            break;
          case 2:
            txt = '赠送商品';
            break;
          case 3:
            txt = '赠送门票';
            break;
          case 4:
            txt = '赠送积分';
            break;
          case 5:
            txt = '赠送余额';
            break;
          case 6:
            txt = '赠送优惠券';
            break;
        }
        m.txt = txt;
      });
      this.qyList = item;
      this.showQY = true;
    }
  }
};