"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    dataWidth: {
      type: [String, Number],
      default: ''
    },
    dataHeight: {
      type: [String, Number],
      default: ''
    },
    dataNickname: {
      type: [String],
      default: ''
    },
    dataUrl: {
      type: [String],
      default: ''
    }
  }
};