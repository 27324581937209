var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          attrs: {
            trigger: "hover",
            placement: "left-start",
            "popper-class": "btn-pop-view",
            transition: "",
            width: "auto",
            "close-delay": 0,
          },
        },
        [
          _c(
            "div",
            { staticClass: "pop-btn" },
            [_vm._t("default"), _vm._v(" "), _vm._t("pop_btn")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              staticStyle: { cursor: "pointer", height: "37px" },
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _vm.$slots.pop_icon
                ? _vm._t("pop_icon")
                : _c("img", {
                    staticStyle: {
                      width: "14px",
                      height: "14px",
                      display: "block",
                    },
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/202463/image/1717384677536711692.png!120a",
                    },
                  }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }