"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var productRouter = {
  path: "".concat(_settings.roterPre, "/product"),
  name: 'product',
  component: _layout.default,
  meta: {
    icon: 'dashboard',
    title: '商品管理'
  },
  alwaysShow: true,
  redirect: 'noRedirect',
  children: [{
    path: 'classify',
    name: 'ProductClassify',
    meta: {
      title: '商品分类',
      noCache: true,
      permissionKey: '/product/classify'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/productClassify'));
      });
    }
  }, {
    path: 'productStore',
    name: 'productStore',
    meta: {
      title: '仓库管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/productStore/productStore.vue'));
      });
    }
  }, {
    path: 'attr',
    name: "ProductAttr",
    meta: {
      title: '商品规格',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/productAttr'));
      });
    }
  }, {
    path: 'list',
    name: "ProductList",
    meta: {
      title: '商品列表',
      noCache: true,
      permissionKey: '/product/list'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/productList'));
      });
    }
  }, {
    path: 'list/addProduct/:id?/:edit?',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/addProduct/index'));
      });
    },
    name: 'AddProduct',
    meta: {
      title: '商品添加',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/product/list")
    },
    hidden: true
  }, {
    path: 'reviews',
    name: 'ProductReviews',
    meta: {
      title: '商品评论'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/Reviews/index'));
      });
    }
  }, {
    path: 'attr',
    name: 'ProductAttr',
    meta: {
      title: '属性管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/attr/index'));
      });
    },
    children: [{
      path: 'attrList',
      name: 'AttrList',
      meta: {
        title: '属性管理',
        noCache: true,
        permissionKey: '/product/attr/attrList'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/attr/attrList'));
        });
      }
    }, {
      path: 'brandClassify',
      name: 'BrandClassify',
      meta: {
        title: '品牌分类',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/band/bandClassify'));
        });
      }
    }]
  }, {
    path: 'store',
    name: 'ProductStoreIndex',
    meta: {
      title: '仓库管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/productStore/index'));
      });
    },
    children: [{
      path: 'productStore',
      name: 'productStore',
      meta: {
        title: '仓库管理',
        noCache: true,
        permissionKey: '/product/store/productStore'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/productStore/productStore.vue'));
        });
      }
    }, {
      path: 'shelflist',
      name: 'productShelflist',
      meta: {
        title: '货架列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/product/store/productStore")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/basic/shelfList'));
        });
      }
    }]
  }, {
    path: 'band',
    name: 'ProductBand',
    meta: {
      title: '品牌管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/band/index'));
      });
    },
    children: [{
      path: 'brandList',
      name: 'BrandList',
      meta: {
        title: '品牌列表',
        noCache: true,
        permissionKey: '/product/band/brandList'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/band/bandList'));
        });
      }
    }, {
      path: 'brandClassify',
      name: 'BrandClassify',
      meta: {
        title: '品牌分类',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/product/band/brandList")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/band/bandClassify'));
        });
      }
    }]
  }, {
    path: 'blind',
    name: 'ProductBlind',
    meta: {
      title: '商品盲盒',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBox/index'));
      });
    },
    children: [{
      path: 'blindList',
      name: 'blindList',
      meta: {
        title: '盲盒列表',
        noCache: true,
        permissionKey: '/product/blind/blindList'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBox/blindBoxList'));
        });
      }
    }, {
      path: 'blindClassify',
      name: 'blindClassify',
      meta: {
        title: '盲盒分类',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/product/blind/blindList"),
        permissionKey: '/product/blind/blindClassify'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBox/blindBoxClassify'));
        });
      }
    }, {
      path: 'blindOrder',
      name: 'blindOrder',
      meta: {
        title: '盲盒订单',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/product/blind/blindList"),
        permissionKey: '/product/blind/blindOrder'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBox/blindOrder'));
        });
      }
    }, {
      path: 'blindAlbum',
      name: 'blindAlbum',
      meta: {
        title: '盲盒专辑',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/product/blind/blindList"),
        permissionKey: '/product/blind/blindAlbum'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBox/blindBoxAlbum'));
        });
      }
    }]
  }, {
    path: 'blindNftList',
    name: 'blindNftList',
    meta: {
      title: '盲盒NFT列表',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBoxNft/list'));
      });
    }
  }, {
    path: 'blindNft',
    name: 'blindNft',
    meta: {
      title: 'NFT盲盒',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBoxNft/index'));
      });
    },
    children: [{
      path: 'blindNftList',
      name: 'blindNftChildList',
      meta: {
        title: 'NFT盲盒列表',
        noCache: true,
        permissionKey: '/product/blindNft/blindNftList'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBoxNft/list'));
        });
      }
    }, {
      path: 'giveblind',
      name: 'giveblind',
      meta: {
        title: '赠与记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/product/blindNft/blindNftList"),
        permissionKey: '/product/blindNft/giveblind'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/blindBoxNft/giveblind'));
        });
      }
    }]
  }, {
    path: 'addBlindBox',
    name: 'AddBlindBox',
    meta: {
      title: '添加盲盒',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/product/blind/blindList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/addBlindBox'));
      });
    }
  }, {
    path: 'addBlindBoxNft',
    name: 'AddBlindBoxNft',
    meta: {
      title: '添加盲盒',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/product/blind/blindNftList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/addBlindBoxNft'));
      });
    }
  }, {
    path: 'oldfirstbuy',
    name: 'ProductFirstBuy',
    meta: {
      title: '优先购列表',
      noCache: true,
      permissionKey: '/product/firstbuy'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/firstBuy/list'));
      });
    }
  }, {
    path: 'oldaddFirstBuy',
    name: 'AddFirstBuy',
    meta: {
      title: '添加优先购',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/product/firstbuy"),
      permissionKey: '/product/firstbuy'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/firstBuy/add'));
      });
    }
  }, {
    path: 'firstbuy',
    name: 'ProductFirstBuy',
    meta: {
      title: '优先购列表',
      noCache: true,
      permissionKey: '/product/firstbuy'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/firstBuyNew/list'));
      });
    }
  }, {
    path: 'addFirstBuy',
    name: 'AddFirstBuy',
    meta: {
      title: '添加优先购',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/product/firstbuy"),
      permissionKey: '/product/firstbuy'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/firstBuyNew/add'));
      });
    }
  }, {
    path: 'quickPhoto',
    name: 'ProductQuickPhoto',
    meta: {
      title: '快照列表',
      noCache: true,
      permissionKey: '/product/quickPhoto'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/quickPhoto/list'));
      });
    }
  }, {
    path: 'addQuickPhoto',
    name: 'AddQuickPhoto',
    meta: {
      title: '添加快照',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/product/quickPhoto"),
      permissionKey: '/product/quickPhoto'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/quickPhoto/add'));
      });
    }
  }, {
    path: 'airdrop',
    name: 'ProductAirdrop',
    meta: {
      title: '空投列表',
      noCache: true,
      permissionKey: '/product/airdrop'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/airdrop/list'));
      });
    }
  }, {
    path: 'addAirdrop',
    name: 'AddAirdrop',
    meta: {
      title: '添加空投',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/product/airdrop"),
      permissionKey: '/product/airdrop'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/airdrop/add'));
      });
    }
  }, {
    path: 'discountList',
    name: 'DiscountList',
    meta: {
      title: '折扣活动',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/discount/list'));
      });
    }
  }, {
    path: 'discountCreate',
    name: 'DiscountCreate',
    meta: {
      title: '创建折扣活动',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/discount/create'));
      });
    }
  }]
};
var _default = exports.default = productRouter;