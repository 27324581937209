"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "FileList",
  data: function data() {
    return {
      fileVisible: true,
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      }
    };
  },
  methods: {
    exportFileList: function exportFileList() {
      var _this = this;
      this.loading = true;
      (0, _order.exportFileLstApi)().then(function (res) {
        _this.fileVisible = true;
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    // 下载
    downLoad: function downLoad(id) {
      (0, _order.downloadFileApi)().then(function (_ref) {
        var message = _ref.message;
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};