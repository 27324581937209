var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "会员管理" } }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c("div", { staticClass: "top-view" }, [
            _c(
              "div",
              { staticStyle: { padding: "0 20px" } },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo default-tabs mt10",
                    attrs: {
                      "default-active": _vm.routerUrl,
                      mode: "horizontal",
                    },
                    on: { select: _vm.changeTab },
                  },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/pageManagement/memberData/memberList",
                        },
                      },
                      [_vm._v("会员管理")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/pageManagement/memberData/memberCardList",
                        },
                      },
                      [_vm._v("会员卡")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/pageManagement/memberData/buyRecord",
                        },
                      },
                      [_vm._v("用户会员卡")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/pageManagement/memberData/buyOrder",
                        },
                      },
                      [_vm._v("购买记录")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/pageManagement/memberData/levelList",
                        },
                      },
                      [_vm._v("等级管理")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/pageManagement/memberData/benefitsList",
                        },
                      },
                      [_vm._v("权益管理")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/pageManagement/memberData/venueSign",
                        },
                      },
                      [_vm._v("场馆签到")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/pageManagement/memberData/levelTask",
                        },
                      },
                      [_vm._v("升级任务")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index:
                            "/merchant/pageManagement/memberData/memberData",
                        },
                      },
                      [_vm._v("统计报表")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }