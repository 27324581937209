var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        backgroundColor: _vm.facade.pageBackground,
        padding:
          _vm.facade.pagePaddingTop +
          "px " +
          _vm.facade.pagePaddingRight +
          "px " +
          _vm.facade.pagePaddingBottom +
          "px " +
          _vm.facade.pagePaddingLeft +
          "px",
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
        borderRadius:
          _vm.facade.pageRadiusTopLeft +
          "px " +
          _vm.facade.pageRadiusTopRight +
          "px " +
          _vm.facade.pageRadiusBottomRight +
          "px " +
          _vm.facade.pageRadiusBottomLeft +
          "px",
        color: _vm.facade.pageColor,
        fontSize: _vm.facade.fontSize + "px",
        fontWeight: _vm.facade.fontWeight,
      },
    },
    [
      _c("section", { style: "text-align: " + _vm.facade.align + ";" }, [
        _vm.content.address_title
          ? _c(
              "section",
              {
                style:
                  "font-size: " +
                  _vm.facade.titleFontSize +
                  "px;color: " +
                  _vm.facade.titleColor +
                  ";font-weight: " +
                  _vm.facade.titleFontWeight +
                  ";",
              },
              [_vm._v(_vm._s(_vm.content.address_title))]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "js_poi_entry wx_poi_link",
            style:
              "font-size: " +
              _vm.facade.fontSize +
              "px;color: " +
              _vm.facade.pageColor +
              ";font-weight: " +
              _vm.facade.fontWeight +
              ";",
            attrs: {
              "data-name": _vm.content.address_item.title,
              "data-address": _vm.content.address_item.address,
              "data-poiid": _vm.content.address_item.id,
              "data-type": "1",
              href: "",
              "data-districtid": "",
              "data-longitude": _vm.content.address_item.location.lng,
              "data-latitude": _vm.content.address_item.location.lat,
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.content.address_name
                  ? _vm.content.address_name
                  : "请设置位置"
              )
            ),
          ]
        ),
        _vm._v(" "),
        _vm.content.address
          ? _c(
              "section",
              {
                style:
                  "font-size: " +
                  _vm.facade.addressFontSize +
                  "px;color: " +
                  _vm.facade.addressColor +
                  ";font-weight: " +
                  _vm.facade.addressFontWeight +
                  ";",
              },
              [_vm._v(_vm._s(_vm.content.address))]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }