var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "flex-one" }, [
              _c(
                "div",
                { staticClass: "remove-control form-view ml20" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm ",
                      attrs: {
                        disabled: _vm.status !== 1,
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c("el-form-item", { attrs: { label: "邀请函封面：" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "upload-view flex align-items-c justify-c shu",
                            on: { click: _vm.changeImg },
                          },
                          [
                            _vm.ruleForm.image
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    src: _vm.ruleForm.image + "!m640",
                                    fit: "cover",
                                  },
                                })
                              : _c("i", { staticClass: "el-icon-plus" }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("图片宽1080×1920，格式PNG，JPG，不大于2M"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邀请函标题：", prop: "name" } },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "输入邀请函标题" },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "发放数量：", prop: "issued_number" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入发放数量" },
                            model: {
                              value: _vm.ruleForm.issued_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "issued_number", $$v)
                              },
                              expression: "ruleForm.issued_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "有效期：", prop: "times" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "选择开始日期",
                              "end-placeholder": "选择结束日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.ruleForm.times,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "times", $$v)
                              },
                              expression: "ruleForm.times",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动地址：", prop: "address" } },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入活动地址" },
                            model: {
                              value: _vm.ruleForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "address", $$v)
                              },
                              expression: "ruleForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否开启领取：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.is_receive,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "is_receive", $$v)
                                },
                                expression: "ruleForm.is_receive",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不开启"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("开启"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("Tinymce", {
            ref: "editor",
            attrs: { height: 400 },
            model: {
              value: _vm.ruleForm.content,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "content", $$v)
              },
              expression: "ruleForm.content",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "common-btns",
              class: { left0: _vm.device === "mobile" },
              style: { left: _vm.sidebar.opened ? "230px" : "74px" },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveAction("ruleForm")
                    },
                  },
                },
                [_vm._v("确认提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }