"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _frame = require("@/api/frame");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var activeIndexObj = {
  "1": "frameList",
  "2": "frameType",
  "3": "framePaper",
  "4": "frameScence",
  "5": "frameFrame"
};
var _default = exports.default = {
  name: "frameScence",
  data: function data() {
    return {
      changeSize: 10,
      lists: [],
      queryParams: {
        page: 1,
        limit: 20
      },
      categorys: [],
      total: 0,
      currentPage: 1,
      categoryId: "",
      scenceFlag: false,
      model1: false,
      formList: {
        image_url: "",
        category_id: ""
      }
    };
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      this.$router.push({
        name: activeIndexObj[index]
      });
    },
    changeType: function changeType(type) {
      this.queryParams.page = 1;
      this.load();
    },
    onClose: function onClose() {
      this.model1 = false;
      this.formList.image_url = "";
      this.formList.category_id = "";
    },
    onUpload: function onUpload(type) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (img.length > 1) return _this.$message.error("只能选择一张");
        _this.formList.image_url = img[0];
      });
    },
    handleRemove: function handleRemove(file) {
      this.formList.image_url = "";
    },
    save: function save() {
      var _this2 = this;
      var json = this.formList;
      (0, _frame._createScence)(json).then(function (res) {
        if (res.status === 200) {
          _this2.$message.success("添加成功");
        } else {
          _this2.$message.error(res.message);
        }
        _this2.model1 = false;
        _this2.queryParams.page = 1;
        _this2.load();
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(res.message);
      });
    },
    edit: function edit(row, type) {
      this.work = row;
      this.price = "";
      if (type === 1) {
        this.saleflag = true;
        return;
      }
      this.handle(type);
    },
    delscence: function delscence(row) {
      var _this3 = this;
      (0, _frame._deleteScence)({
        id: row.id
      }).then(function (res) {
        if (res.status === 200) {
          _this3.$message.success("删除成功");
          _this3.queryParams.page = 1;
          _this3.load();
        } else {
          _this3.$message.error(res.message);
        }
      }).catch(function (err) {
        _this3.$message.error(err.message);
      });
    },
    handle: function handle(type) {
      var _this4 = this;
      if (type === 1 && !this.price) {
        this.$Message.error("请输入价格");
        return false;
      }
      var json = {
        goods_price: this.price,
        work_id: this.work.work_id,
        state: type,
        goods_photos: this.work.works_image
      };
      return this.$http.post("frame_works_pre_order_confirm_price", json).then(function (res) {
        if (res.code === 200) {
          _this4.$Message.success("操作成功");
          _this4.load();
        }
        _this4.saleflag = false;
      });
    },
    getCategory: function getCategory() {
      var _this5 = this;
      var params = {};
      params.type = 2;
      (0, _frame._getTypeList)(params).then(function (res) {
        if (res.status === 200) {
          _this5.categorys = res.data.list;
          if (res.data.list.length) {
            _this5.categoryId = res.data.list[0].id;
            _this5.load();
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    load: function load() {
      var _this6 = this;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      this.lists = [];
      this.loading = true;
      params.category_id = this.categoryId;
      (0, _frame._getScenceList)(params).then(function (res) {
        if (res.status === 200) {
          _this6.total = res.data.count;
          _this6.lists = res.data.list.map(function (item) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              category_name: _this6.categorys.find(function (i) {
                return i.id == item.category_id;
              }).category_name
            });
          });
        }
        _this6.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this6.loading = false;
      });
    },
    changePageSize: function changePageSize(pageSize) {
      this.queryParams.limit = pageSize;
      this.queryParams.page = 1;
      /*  this.$router.push({
        path: this.$route.path,
        query: { type: this.queryParams.topictype, page: 1, limit: pageSize }
      }); */
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      /* const newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          type: this.queryParams.topictype,
          page: newPage,
          limit: this.queryParams.limit
        }
      }); */
      this.load();
    },
    changeSort: function changeSort(row) {
      var _this7 = this;
      var value = row.sort;
      if (isNaN(value)) {
        this.$message.warning("请输入数字");
        this.load();
        return;
      }
      (0, _frame._ChangeScenceSort)({
        id: row.id,
        category_id: row.category_id,
        sort: value
      }).then(function (res) {
        if (res.status === 200) {
          _this7.$message.success("保存成功");
          _this7.queryParams.page = 1;
          _this7.load();
        } else {
          _this7.$message.error(res.message);
        }
      }).catch(function (err) {
        console.log(err);
        _this7.$message.error(err.message);
      });
    }
  },
  watch: {
    $route: function $route(to, form) {
      if (to.name === "DataList" && form.name === "DataList") {
        if (to.query.type !== form.query.type) {
          console.log("watch");
        }
      }
    }
  },
  created: function created() {
    this.getCategory();
  }
};