"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.string.link");
var _textEdit = _interopRequireDefault(require("./textEdit.vue"));
var _guidance = require("@/api/guidance");
//
var _default = exports.default = {
  components: {
    textEdit: _textEdit.default
  },
  props: ["editDetail", "open3dEl"],
  data: function data() {
    return {
      id: this.$route.query.id,
      list: [],
      control: "",
      modifyDetail: {},
      spaceId: "",
      // 传送门选中传送的空间id
      spaceStartPointId: "",
      // 传送门选中传送的出生点id
      searchSpaceLoading: false,
      spaceList: [],
      pointList: [],
      jumpType: "portal",
      jumpLink: ""
    };
  },
  computed: {
    process: function process() {
      if (this.modifyDetail.scale) {
        return this.modifyDetail.scale / 200 * 100;
      }
      return 0;
    }
  },
  created: function created() {
    this.loadInit();
  },
  watch: {
    editDetail: {
      handler: function handler(val) {
        this.loadInit();
      },
      immediate: true
    }
  },
  methods: {
    loadInit: function loadInit() {
      var _this = this;
      this.modifyDetail = JSON.parse(JSON.stringify(this.editDetail));
      setTimeout(function () {
        if (_this.modifyDetail.extends && _this.modifyDetail.extends.operate) {
          _this.jumpType = _this.modifyDetail.extends.operate;
          if (_this.jumpType != "portal") {
            _this.jumpLink = _this.modifyDetail.extends.link;
          }
        }
        if (_this.modifyDetail.editType == "exhibitionData" && _this.modifyDetail.info && (_this.modifyDetail.info.exhibitionType == "portal" || _this.modifyDetail.extends.operate)) {
          if (_this.modifyDetail.extends && _this.modifyDetail.extends.spaceId) {
            _this.searchSpaceAction("", _this.modifyDetail.extends.spaceId);
            _this.spaceId = _this.modifyDetail.extends.spaceId;
            _this.spaceStartPointId = _this.modifyDetail.extends.spaceStartPointId;
            _this.getPointListAction(1);
          }
        }
      }, 50);
    },
    changeControls: function changeControls(type) {
      if (this.control == type) {
        this.control = "";
        if (this.open3dEl) {
          this.open3dEl.changeControls(false);
        }
        return false;
      }
      this.control = type;
      if (this.open3dEl) {
        this.open3dEl.changeControls(true, type);
      }
      // emits("textDraw", {
      //   method: "changeControls",
      //   data: type,
      //   editType: true,
      // });
    },
    editSizeAction: function editSizeAction(e) {
      if (this.open3dEl) {
        this.open3dEl.changeCurrentModelSize(e.target.value);
      }
      this.modifyDetail.scale = e.target.value;
    },
    delAction: function delAction() {
      if (this.open3dEl) {
        this.open3dEl.delCurrentModel();
      }
      (0, _guidance._deleteGuidanceProduct)({
        space_id: this.$route.query.id,
        link_id: this.modifyDetail.info.id,
        type: this.modifyDetail.info.extends.type
      });
    },
    searchSpaceAction: function searchSpaceAction(name, id) {
      var _this2 = this;
      // 搜索空间方法
      if (this.searchSpaceLoading) return false;
      this.searchSpaceLoading = true;
      getSpaceList({
        pageNum: 1,
        pageSize: 10,
        name: name,
        id: id
      }).then(function (res) {
        _this2.spaceList = res.rows;
        _this2.searchSpaceLoading = false;
      }).catch(function (_ref) {
        var message = _ref.message;
        return _this2.$message.error(message);
      });
    },
    inpAction: function inpAction(val) {
      if (this.open3dEl) {
        this.open3dEl.changeLockMove(val);
      }
    },
    changeSpaceIdAction: function changeSpaceIdAction() {
      this.inpAction(false);
      this.getPointListAction();
    },
    getPointListAction: function getPointListAction(hasId) {
      if (!this.spaceId) return false;
      this.pointList = [];
      if (!hasId) this.spaceStartPointId = [];
    },
    confirmAction: function confirmAction() {
      var _this3 = this;
      if (this.modifyDetail && this.modifyDetail.info && this.modifyDetail.info.exhibitionType == "portal" || this.jumpType == "portal") {
        if (!this.spaceId) {
          this.$emit("closeAction");
          return false;
        }
        var pointData = "";
        if (this.spaceStartPointId && this.pointList && this.pointList.length) {
          var pointItem = this.pointList.find(function (val) {
            return val.id == _this3.spaceStartPointId;
          });
          if (pointItem && pointItem.coordinate) {
            pointData = pointItem.coordinate;
          }
        }
        if (this.modifyDetail && this.modifyDetail.info && this.modifyDetail.info.exhibitionType == "portal") {
          if (this.open3dEl) {
            this.open3dEl.editModelExtendsInfo({
              spaceId: this.spaceId,
              spaceStartPointId: this.spaceStartPointId,
              coordinate: pointData
            });
          }
          // emits("textDraw", {
          //   method: "editModelExtendsInfo",
          //   data: {
          //     spaceId: this.spaceId,
          //     spaceStartPointId: this.spaceStartPointId,
          //     coordinate: pointData,
          //   },
          // });
        } else {
          if (this.open3dEl) {
            this.open3dEl.editModelExtendsInfo({
              operate: "portal",
              spaceId: this.spaceId,
              spaceStartPointId: this.spaceStartPointId,
              coordinate: pointData
            });
          }
        }
      } else {
        if (this.open3dEl) {
          this.open3dEl.editModelExtendsInfo({
            operate: this.jumpType,
            link: this.jumpLink
          });
        }
      }
      this.$emit("closeAction");
    },
    editOperateAction: function editOperateAction(type) {
      if (this.jumpType != type) {
        this.jumpType = type;
        this.jumpLink = "";
      }
    }
  }
};