var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 180px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_id
                },
                data: _vm.tableData.data,
                height: "100%",
                size: "mini",
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.create_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盘点人",
                  "min-width": "90",
                  prop: "stockTakeAdmin.real_name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "计划完成时间",
                  "min-width": "120",
                  prop: "end_time",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.end_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "完成时间",
                  "min-width": "120",
                  prop: "stock_take_etime",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.stock_take_etime)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "仓库",
                  "min-width": "100",
                  prop: "warehouse.name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.warehouse.name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "数据库数量",
                  "min-width": "100",
                  prop: "db_product_number",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盘点实际数量",
                  "min-width": "120",
                  prop: "stock_take_product_number",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数量差异", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock_take_product_number >=
                        scope.row.db_product_number
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.stock_take_product_number -
                                      scope.row.db_product_number
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.db_product_number -
                                      scope.row.stock_take_product_number
                                  ) +
                                  "\n        "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘赢", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock_take_status == 2
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.stock_take_product_number -
                                      scope.row.db_product_number
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘亏", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock_take_status == 2
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.db_product_number -
                                      scope.row.stock_take_product_number
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "制单人",
                  "min-width": "90",
                  prop: "creator.real_name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘点状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock_take_status == 0
                          ? _c("div", [_vm._v("待盘点")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.stock_take_status == 1
                          ? _c("div", [_vm._v("正在盘点")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.stock_take_status == 2
                          ? _c("div", [_vm._v("已完成")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘点进度", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.db_product_number > 0
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    (
                                      (scope.row.stock_take_product_number /
                                        scope.row.db_product_number) *
                                      100
                                    ).toFixed(2)
                                  ) +
                                  "%\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.create_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盘点人",
                  "min-width": "90",
                  prop: "stockTakeAdmin.real_name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "计划完成时间",
                  "min-width": "120",
                  prop: "end_time",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.end_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "完成时间",
                  "min-width": "120",
                  prop: "stock_take_etime",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.stock_take_etime)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "仓库",
                  "min-width": "100",
                  prop: "warehouse.name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.warehouse.name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "数据库数量",
                  "min-width": "100",
                  prop: "db_product_number",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "盘点实际数量",
                  "min-width": "120",
                  prop: "stock_take_product_number",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数量差异", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock_take_product_number >=
                        scope.row.db_product_number
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.stock_take_product_number -
                                      scope.row.db_product_number
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.db_product_number -
                                      scope.row.stock_take_product_number
                                  ) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘赢", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock_take_status == 2
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.stock_take_product_number -
                                      scope.row.db_product_number
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘亏", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock_take_status == 2
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.db_product_number -
                                      scope.row.stock_take_product_number
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "制单人",
                  "min-width": "90",
                  prop: "creator.real_name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘点状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock_take_status == 0
                          ? _c("div", [_vm._v("待盘点")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.stock_take_status == 1
                          ? _c("div", [_vm._v("正在盘点")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.stock_take_status == 2
                          ? _c("div", [_vm._v("已完成")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "盘点进度", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.db_product_number > 0
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    (
                                      (scope.row.stock_take_product_number /
                                        scope.row.db_product_number) *
                                      100
                                    ).toFixed(2)
                                  ) +
                                  "%\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "",
                  "min-width": "36",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "merchantErpStockTakeRecordLst",
                                    expression:
                                      "'merchantErpStockTakeRecordLst'",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.checkrecord(scope.row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "order-jilu",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("盘点记录\n            "),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.stock_take_status == 0 ||
                            scope.row.stock_take_status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "merchantErpStockTakeTaskStart",
                                        expression:
                                          "'merchantErpStockTakeTaskStart'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkstart(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "kufang-kaishipandian",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("开始盘点\n            "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value:
                                      "merchantErpStockTakeTaskUploadCredentials",
                                    expression:
                                      "'merchantErpStockTakeTaskUploadCredentials'",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changecertflag(scope.row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "order-shangchaunpingzheng",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("上传凭证\n            "),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !scope.row.stock_take_end_time
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "merchantErpStockTakeTaskEnd",
                                        expression:
                                          "'merchantErpStockTakeTaskEnd'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.successcheck(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "kufang-wanchengpandian",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("完成盘点任务\n            "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "merchantErpStockTakeTaskDelete",
                                    expression:
                                      "'merchantErpStockTakeTaskDelete'",
                                  },
                                ],
                                staticStyle: { color: "red" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delcheck(scope.row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "commondelete",
                                    color: "red",
                                  },
                                }),
                                _vm._v("删除\n            "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block paginaBox" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total,  prev, pager, next, sizes",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showcreate, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showcreate = $event
            },
            close: function ($event) {
              _vm.showcreate = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "80px",
                inline: false,
                size: "normal",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "盘点人" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择业务负责人",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.stock_take_admin_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "stock_take_admin_id", $$v)
                        },
                        expression: "formValidate.stock_take_admin_id",
                      },
                    },
                    _vm._l(_vm.adminarr, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "盘点仓库" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择库房",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "warehouse_id", $$v)
                        },
                        expression: "formValidate.warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeArr, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "完成时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      size: "small",
                      type: "datetime",
                      placement: "bottom-end",
                      placeholder: "自定义时间",
                    },
                    on: { change: _vm.changeTime },
                    model: {
                      value: _vm.formValidate.end_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "end_time", $$v)
                      },
                      expression: "formValidate.end_time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("立即创建")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showcreate = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "盘点货品", visible: _vm.checkflag, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.checkflag = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择货品" },
              model: {
                value: _vm.checkgoods.product_id,
                callback: function ($$v) {
                  _vm.$set(_vm.checkgoods, "product_id", $$v)
                },
                expression: "checkgoods.product_id",
              },
            },
            _vm._l(_vm.goodsList, function (item, index) {
              return _c("el-option", {
                key: "goods_" + index,
                attrs: { label: item.store_name, value: item.product_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.cursorcheck } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "上传凭证", visible: _vm.showcert, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showcert = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c("el-form-item", { attrs: { label: "凭证", prop: "image" } }, [
                _c(
                  "div",
                  { staticClass: "acea-row" },
                  [
                    _vm._l(_vm.certs, function (item) {
                      return _vm.certs.length
                        ? _c("div", { key: item, staticClass: "pictrue" }, [
                            _c("img", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-right": "3px",
                              },
                              attrs: { src: item },
                            }),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "uploadCont",
                        attrs: { title: "750*750px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("2")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.cursorcert } }, [
                    _vm._v("确认"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }