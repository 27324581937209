"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _review = require("@/api/review");
var _settings = require("@/settings");
var _product = require("@/api/product");
var _contract = require("@/api/contract");
var _setting = require("@/api/setting");
var _warehouse = require("@/api/warehouse");
var _user = require("@/api/user.js");
var _pushwareOrder = _interopRequireDefault(require("@/components/printOrder/pushwareOrder"));
var _transfersOrder = _interopRequireDefault(require("@/components/printOrder/transfersOrder"));
var _outwareOrder = _interopRequireDefault(require("@/components/printOrder/outwareOrder"));
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Review",
  components: {
    pushwareOrder: _pushwareOrder.default,
    transfersOrder: _transfersOrder.default,
    outwareOrder: _outwareOrder.default
  },
  data: function data() {
    return {
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        username: "",
        type: "",
        date: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: ""
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: "",
        mark: ""
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      showreview: false,
      mask: "",
      statusOptionsList: [{
        label: "全部",
        value: ""
      }, {
        label: "待审批",
        value: "0"
      }, {
        label: "已审批",
        value: "1"
      }, {
        label: "已作废",
        value: "-1"
      }],
      searchQuery: {},
      merCateList: [],
      propsMer: {
        emitPath: false,
        multiple: true,
        expandTrigger: "hover",
        value: "store_category_id",
        label: "cate_name"
      },
      showpreview: false,
      html_content: "",
      userList: [],
      showdetail: false,
      detailjson: {},
      merData: {},
      typeName: '',
      moduleVisible: false,
      moduleForm: {
        marketMake: {}
      }
    };
  },
  created: function created() {
    // console.log(this.$route.query);
    if (this.$route.query.type != '') {
      this.searchQuery.type = this.$route.query.type;
      this.tableFrom.type = this.$route.query.type;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getCategorySelect();
    this.getList(1);
    (0, _setting.adminListApi)().then(function (res) {
      // console.log(res);
      _this.userList = res.data.list.map(function (item) {
        return {
          label: item.real_name,
          value: item.merchant_admin_id
        };
      });
    });
  },
  methods: {
    getdetail: function getdetail(row) {
      var _this2 = this;
      if (row.type == 'erp_v2_contract') {
        (0, _contract.contractV2DetailApi)(row.link_item.id).then(function (res) {
          if (res.data.content) {
            _this2.html_content = res.data.content.html_content.replace("width: 0px; height: 0px; overflow: hidden;", "");
            _this2.showpreview = true;
          }
        });
      } else if (row.type == 'market_media_make') {
        (0, _aiMark.marketMediaDetailApi)(row.link_item.id).then(function (res) {
          _this2.moduleForm = res.data;
          _this2.moduleVisible = true;
        });
      } else {
        this.typeName = row.type;
        (0, _warehouse.getwarehouseOrderDetailApiV2)(row.link_item.id).then(function (res) {
          if (res.status == 200) {
            _this2.detailjson = res.data;
            _this2.showdetail = true;
          }
        });
      }
      // if (row.type == 'erp_v2_enter_warehoue') {
      // }
      // if (row.type == 'erp_v2_out_warehouse') {
      // }
      // if (row.type == 'erp_v2_transfers_order') {
      // }
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    revieworder: function revieworder(row) {
      this.handlerow = row;
      this.showreview = true;
      this.mask = "";
    },
    handlereview: function handlereview(state) {
      var _this3 = this;
      if (state == -1 && !this.mask) {
        this.$message.error("请填写拒绝备注");
        return;
      }
      (0, _review.review)(this.handlerow.audit_id, {
        status: state,
        remark: this.mask,
        type: this.handlerow.type,
        link_id: this.handlerow.link_id
      }).then(function (res) {
        _this3.$message.success("操作成功");
        _this3.getList();
        _this3.showreview = false;
      }).catch(function (err) {
        _this3.$message.error(err.message);
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList("");
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList("");
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _review.reviewslist)(this.tableFrom).then(function (res) {
        _this4.orderChartType = res.data.stat;
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    changeTab: function changeTab(data) {
      if (data && data !== this.$route.path) {
        this.$router.push({
          path: data
        });
      }
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this5 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this5.merCateList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    }
  }
};