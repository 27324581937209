var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "合同相关配置", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "div",
            { staticClass: "top-view", staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: {
                    "default-active": _vm.$route.path,
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/contract/contractConfig/contractlist",
                      },
                    },
                    [_vm._v("合同模版")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/contract/contractConfig/contractSetting",
                      },
                    },
                    [_vm._v("标的清单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/contract/contractConfig/principal",
                      },
                    },
                    [_vm._v("委托方收费")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/contract/contractConfig/contractno",
                      },
                    },
                    [_vm._v("纸质合同编号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/contract/contractConfig/reconcCon",
                      },
                    },
                    [_vm._v("对账配置")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/contract/contractConfig/ruleConfig",
                      },
                    },
                    [_vm._v("规则配置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key, ref: "" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }