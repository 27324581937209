var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "padding20 bg-white borderRadius6" }, [
        _c(
          "div",
          { staticClass: "remove-control form-view " },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm ",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "120px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "会员卡名称：", prop: "name" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入会员卡名称" },
                          model: {
                            value: _vm.ruleForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "name", $$v)
                            },
                            expression: "ruleForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "会员卡图片：", prop: "image" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      [
                        _vm.ruleForm.image
                          ? [
                              _c("div", { staticClass: "img-list mr10" }, [
                                _c("img", {
                                  attrs: { src: _vm.ruleForm.image + "!120a" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImageAction("image")
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delImgAction("image")
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-close" })]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c mr10",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg("image")
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("图片宽630×420，格式PNG，JPG，不大于2M"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "会员卡号开头：",
                      prop: "card_number_prefix",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入会员卡号开头" },
                          model: {
                            value: _vm.ruleForm.card_number_prefix,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "card_number_prefix", $$v)
                            },
                            expression: "ruleForm.card_number_prefix",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "会员卡类型：", prop: "card_type" } },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.card_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "card_type", $$v)
                                },
                                expression: "ruleForm.card_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("单人卡"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("主副卡"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.card_type == 1
                        ? _c(
                            "div",
                            { staticClass: "ml20" },
                            [
                              _c("span", [_vm._v("主副卡是否可以解绑：")]),
                              _vm._v(" "),
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.is_can_unbind,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "is_can_unbind",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.is_can_unbind",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("不可解绑"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("可以解绑"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "会员卡价格：",
                      prop: "card_number_prefix",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入会员卡价格" },
                          model: {
                            value: _vm.ruleForm.price,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "price", _vm._n($$v))
                            },
                            expression: "ruleForm.price",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "续费价格：" } }, [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("el-input", {
                        staticClass: "w300",
                        attrs: { placeholder: "请输入续费价格" },
                        model: {
                          value: _vm.ruleForm.again_price,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "again_price", _vm._n($$v))
                          },
                          expression: "ruleForm.again_price",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "已到期续费价格：" } }, [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("el-input", {
                        staticClass: "w300",
                        attrs: { placeholder: "请输入续费价格" },
                        model: {
                          value: _vm.ruleForm.expired_again_price,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "expired_again_price",
                              _vm._n($$v)
                            )
                          },
                          expression: "ruleForm.expired_again_price",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.isAccountPayType == "338349"
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-top": "30px" },
                        attrs: { label: "入账账户：" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.ruleForm.pay_account_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "pay_account_id",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.pay_account_id",
                                },
                              },
                              _vm._l(_vm.paymentAccountList, function (item) {
                                return _c("el-option", {
                                  key: item.pay_account_id,
                                  attrs: {
                                    label: item.name,
                                    value: item.pay_account_id,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: { effect: "dark", placement: "top" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm._v(
                                      "\n                如果不选择会进入系统配置的默认账户\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { cursor: "pointer" } },
                                  [
                                    _vm._v(
                                      "\n                （？）\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "库存数量：", prop: "stock" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入库存数量" },
                          model: {
                            value: _vm.ruleForm.stock,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "stock", _vm._n($$v))
                            },
                            expression: "ruleForm.stock",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "关联权益：" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleAdditem },
                      },
                      [_vm._v("添加权益")]
                    ),
                    _vm._v(" "),
                    _vm.benefitsArr.length > 0
                      ? _c(
                          "el-table",
                          {
                            staticClass: "table",
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.benefitsArr, size: "mini" },
                          },
                          [
                            _vm.ruleForm.right_type != 6
                              ? _c("el-table-column", {
                                  attrs: { label: "权益图标", width: "80" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("img", {
                                              staticStyle: {
                                                width: "40px",
                                                height: "40px",
                                                display: "block",
                                              },
                                              attrs: {
                                                src: scope.row.image,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1643538639
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "名称", prop: "name" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "权益介绍" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.showDesc) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3154319727
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "操作", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delProductAction(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2318904109
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "有效期：",
                      required: "",
                      prop: "expire_type",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.expire_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "expire_type", $$v)
                          },
                          expression: "ruleForm.expire_type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("按时间"),
                        ]),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          staticStyle: { width: "370px" },
                          attrs: {
                            "value-format": "timestamp",
                            size: "small",
                            type: "datetimerange",
                            placement: "bottom-end",
                            "ange-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            disabled: _vm.ruleForm.expire_type == 1,
                          },
                          model: {
                            value: _vm.ruleForm.times,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "times", $$v)
                            },
                            expression: "ruleForm.times",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { staticClass: "ml20", attrs: { label: 1 } },
                          [
                            _vm._v("领取后"),
                            _c("el-input-number", {
                              staticClass: "w100 ml5 mr5",
                              attrs: {
                                controls: false,
                                disabled: _vm.ruleForm.expire_type == 0,
                                placeholder: "天数",
                              },
                              model: {
                                value: _vm.ruleForm.expire_days,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "expire_days", $$v)
                                },
                                expression: "ruleForm.expire_days",
                              },
                            }),
                            _vm._v("天内有效"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "购买条件：", required: "" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.is_need_verify,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "is_need_verify", $$v)
                          },
                          expression: "ruleForm.is_need_verify",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("不限"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("需实名"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "开卡条件：", required: "" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.use_condition_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "use_condition_type", $$v)
                          },
                          expression: "ruleForm.use_condition_type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("不限"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("证件"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w300 ml20",
                      attrs: {
                        disabled: _vm.ruleForm.use_condition_type != 1,
                        placeholder: "输入证件名称 例：身份证、学生证、教师证",
                      },
                      model: {
                        value: _vm.ruleForm.use_condition,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "use_condition", $$v)
                        },
                        expression: "ruleForm.use_condition",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "赠送成长值：", prop: "give_growth_value" },
                  },
                  [
                    _c("el-input", {
                      staticClass: "w200",
                      attrs: { placeholder: "成长值" },
                      model: {
                        value: _vm.ruleForm.give_growth_value,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "give_growth_value", $$v)
                        },
                        expression: "ruleForm.give_growth_value",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml10 color-gray" }, [
                      _vm._v(
                        "赠送会员等级所需的成长值。例：本会员卡可享受v2会员权益，v2需要2000成长值，则输入框填写2000"
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "会员卡状态：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "status", $$v)
                          },
                          expression: "ruleForm.status",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("上架"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("下架"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "是否显示：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.is_show,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "is_show", $$v)
                          },
                          expression: "ruleForm.is_show",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("显示"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("不显示"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "使用须知：" } },
                  [
                    _c("Tinymce", {
                      ref: "editor",
                      attrs: { height: 400 },
                      model: {
                        value: _vm.ruleForm.use_notice,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "use_notice", $$v)
                        },
                        expression: "ruleForm.use_notice",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "详情介绍：" } },
                  [
                    _c("Tinymce", {
                      ref: "editor1",
                      attrs: { height: 400 },
                      model: {
                        value: _vm.ruleForm.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "content", $$v)
                        },
                        expression: "ruleForm.content",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: " " } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.saveAction("ruleForm")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.id ? "确认修改" : "确认创建"))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("Benefits", {
        ref: "benefits",
        attrs: { "current-data": _vm.benefitsArr },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.ruleForm[_vm.imagesViewKey] },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }