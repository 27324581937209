"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _ticket = require("@/api/ticket");
var _index = require("@/utils/index");
var _request = _interopRequireDefault(require("@/api/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      showSetTimes: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 10
      },
      parseTime: _index.parseTime,
      showSetTimesData: {
        show: false,
        index: null,
        exclude: [],
        dates: [],
        times: [{
          time_range: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 0, 0)],
          count: ''
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    init: function init(ticket_id, spec_id) {
      this.tableData = {
        data: [],
        total: 0
      };
      this.tableFrom = {
        page: 1,
        limit: 10,
        spec_id: spec_id,
        ticket_id: ticket_id
      };
      this.showSetTimes = true;
      this.getList();
    },
    createdAction: function createdAction() {
      this.$router.push('./detail');
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _ticket.specDateList)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    editAction: function editAction(id) {
      this.$router.push('./detail?id=' + id);
    },
    delAction: function delAction(id) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u65F6\u95F4?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _ticket.specDelDateApi)({
          date_id: id
        }).then(function () {
          _this2.$message.success('操作成功');
          _this2.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    addTimesAction: function addTimesAction() {
      this.showSetTimesData = {
        show: true,
        index: null,
        exclude: [],
        dates: [],
        times: [{
          time_range: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 0, 0)],
          count: ''
        }]
      };
    },
    editTimesAction: function editTimesAction(m) {
      var time = [];
      m.time.forEach(function (item) {
        console.log(item);
        var st = item.start_time.split(':');
        var se = item.end_time.split(':');
        var sTime = new Date(2016, 9, 10, st[0], st[1], st[2]);
        var eTime = new Date(2016, 9, 10, se[0], se[1], se[2]);
        time.push({
          time_range: [sTime, eTime],
          count: item.max_num,
          time_id: item.time_id
        });
      });
      this.showSetTimesData = {
        show: true,
        edit: true,
        date_id: m.date_id,
        dates: [m.date, m.date],
        times: time
      };
    },
    setTimesAction: function setTimesAction() {
      var _this3 = this;
      if (!this.showSetTimesData.dates.length) {
        this.$message.error('请先选择日期');
        return false;
      }
      var times = [];
      var dt = [];
      this.showSetTimesData.times.forEach(function (m, i) {
        if (m.time_range[0] < m.time_range[1] && m.count) {
          times.push({
            start_time: (0, _index.parseTime)(m.time_range[0], '{h}:{i}:{s}'),
            end_time: (0, _index.parseTime)(m.time_range[1], '{h}:{i}:{s}'),
            max_num: m.count,
            time_id: m.time_id ? m.time_id : ''
          });
          dt.push({
            st: Date.parse(m.time_range[0]),
            et: Date.parse(m.time_range[1])
          });
        }
      });
      if (!times.length) {
        this.$message.error('请设置时间段，以及库存数量');
        return false;
      }
      dt.sort(function (x, y) {
        return x['st'] - y['st'];
      });
      var error = '';
      dt.forEach(function (m, i) {
        if (i > 0) {
          if (m['st'] < dt[i - 1]['et']) {
            error = '存在重复的时间段，请检查';
          }
        }
      });
      if (error) {
        this.$message.error(error);
        return false;
      }
      var data = {
        ticket_id: this.tableFrom.ticket_id,
        spec_id: this.tableFrom.spec_id,
        date: {
          start_date: (0, _index.parseTime)(this.showSetTimesData.dates[0], '{y}-{m}-{d}'),
          end_date: (0, _index.parseTime)(this.showSetTimesData.dates[1], '{y}-{m}-{d}'),
          time: times,
          exclude: this.showSetTimesData.exclude
        }
      };
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var action = _ticket.specDateAddApi;
      if (this.showSetTimesData.edit) {
        action = _ticket.specSetInfoDateApi;
        data = {
          date_id: this.showSetTimesData.date_id,
          time: times
        };
      }
      action(data).then(function () {
        loading.close();
        _this3.getList();
        _this3.showSetTimesData = {
          show: false,
          index: null,
          exclude: [],
          dates: [],
          times: [{
            time_range: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 0, 0)],
            count: ''
          }]
        };
        _this3.$message.success('操作成功');
      }).catch(function (msg) {
        loading.close();
      });
    },
    addTimeAction: function addTimeAction() {
      this.showSetTimesData.times.push({
        time_range: [new Date(2016, 9, 10, 0, 0, 0), new Date(2016, 9, 10, 23, 0, 0)],
        count: ''
      });
    },
    removeTimeAction: function removeTimeAction(i) {
      this.showSetTimesData.times.splice(i, 1);
    }
  }
};