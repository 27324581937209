"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _user = require("@/api/user.js");
var _warehouse = require("@/api/warehouse");
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    detailjson: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      merData: {},
      showFiled: [],
      result: [],
      isNo: true,
      attrItem: ['190', '198', '203', '204', '209', '210', '211', '212', '213', '214', '215', '232', '233', '234', '235', '236', '237', '238', '239', '240', '241']
    };
  },
  filters: {
    nameFirter: function nameFirter(str) {
      var arr = str.split('|');
      if (arr.length > 1) return arr[0];else return str;
    },
    englis: function englis(str) {
      var arr = str.split('|');
      if (arr.length > 1) return arr[1];else return ' ';
    }
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this2.merData = res.data;
    });
    (0, _warehouse.getFieldSetAPI)({
      types: 'erp_v2_enter_warehouse_print'
    }).then(function (res) {
      _this2.showFiled = res.data.erp_v2_enter_warehouse_print.show_list;
      console.log(_this2.showFiled);
    });
    setTimeout(function () {
      // 赋值后马上更新
      _this2.isNo = true;
      _this2.compendHeight();
    }, 1000);
  },
  methods: {
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    },
    compendHeight: function compendHeight() {
      var num = 0;
      var heightAll = 0;
      var arr = [];
      var dom = document.getElementsByClassName('calcul');
      for (var i = 0; i < dom.length; i++) {
        heightAll += Number(window.getComputedStyle(dom[i]).height.replace('px', ''));
        // console.log(heightAll);
        if (num == 0 && heightAll > 1200) {
          arr.push(i);
          num += 1;
        } else if (num > 0 && heightAll > 1250 * num + 1200) {
          arr.push(i);
          num += 1;
        }
        // else if (num == 0 && heightAll+){

        // }
      }
      ;
      this.result = this.sliceArrayIntoChunks(this.detailjson.warehouseOrderProduct, arr);
      this.isNo = false;
      console.log(this.result);
    },
    sliceArrayIntoChunks: function sliceArrayIntoChunks(array, indexes) {
      var arr = [];
      var len = array.length;
      var ind = 0;
      indexes.forEach(function (item, index) {
        arr.push(array.slice(ind, item));
        ind = item;
      });
      arr.push(array.slice(indexes[indexes.length - 1], len));
      return arr;
    },
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this3 = this;
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(img);
        if (tit === '1' && !num) {
          _this3.$set(_this.detailjson, 'enterImageList', [].concat((0, _toConsumableArray2.default)(_this.detailjson.enterImageList), (0, _toConsumableArray2.default)(img)));
          (0, _warehouse.confirmpushOrderApiV2)({
            id: _this.detailjson.id,
            enter_image: _this.detailjson.enterImageList.join(',')
          }).then(function (res) {
            // console.log(res);
            _this.$message.success('修改成功');
          });
        }
        _this3.$forceUpdate();
      }, 9);
    }
  }
};