"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  data: function data() {
    return {
      disabled: false,
      text: "获取验证码"
    };
  },
  methods: {
    sendCode: function sendCode() {
      var _this = this;
      if (this.disabled) return;
      this.disabled = true;
      var n = 60;
      this.text = "剩余 " + n + "s";
      var run = setInterval(function () {
        n = n - 1;
        if (n < 0) {
          clearInterval(run);
        }
        _this.text = "剩余 " + n + "s";
        if (_this.text < "剩余 " + 0 + "s") {
          _this.disabled = false;
          _this.text = "重新获取";
        }
      }, 1000);
    }
  }
};