"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _system = require("@/api/system");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tab: '0',
      loadEnd: false,
      widthSizeSwitch: false,
      auction_style_background_color: '#03163F',
      auction_style_font_color: '#999999',
      title_style_background_color: '#000E2C',
      image_background_color: '#2E3B58',
      title_style_font_color: '#fff',
      title_style_height: '140',
      title_font_size_venue: '30',
      title_font_size_befor: '20',
      title_font_size_company_name: '25',
      title_font_size_time: '20',
      defaultList: [],
      compImg: '',
      compName: '',
      compWidth: 40,
      compHeight: 40,
      compImg1: '',
      compName1: '',
      compWidth1: 40,
      compHeight1: 40,
      auction_style_height: 55,
      auction_font_size_lot: '50',
      auction_font_size_name: '40',
      auction_font_size_intro: '30',
      price_style_height: 40,
      price_style_background_color: '#03163F',
      price_style_font_color: '#ffffff',
      price_font_size_currency: '45',
      price_font_size_money: '45',
      changeAbbreviation: [0, 1, 2, 3],
      abbreviationList: [{
        abb: 'RMB',
        rate: '1',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/ad3de5551956b18207abe64453fdf09d.png',
        unit: '￥'
      }, {
        abb: 'HKD',
        rate: '0.92',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/9ebf714a5674d9ff405ff80f8af169bd.png',
        unit: 'HK$'
      }, {
        abb: 'EUR',
        rate: '0.8512',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/a6e02634898b50c6bd068c6e102b71ad.png',
        unit: '€'
      }, {
        abb: 'USD',
        rate: '0.1480',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/9655b9f1034ec51430bed987fc92e15f.png',
        unit: '$'
      }, {
        abb: 'GBP',
        rate: '0.1122',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/6337aa00d13cec7d6b55f52070cfeecd.png',
        unit: '￡'
      }, {
        abb: 'JPY',
        rate: '17.0025',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/9d269dd3347a43da69399fd0bb454860.png',
        unit: '￥'
      }],
      windowSize: 1,
      windowSizeList: [{
        height: 768,
        name: '1024*768',
        screen_size_type: 1,
        width: 1024
      }, {
        height: 960,
        name: '1280*960',
        screen_size_type: 2,
        width: 1280
      }, {
        height: 768,
        name: '1366*768',
        screen_size_type: 3,
        width: 1366
      }, {
        height: 900,
        name: '1440*900',
        screen_size_type: 4,
        width: 1440
      }, {
        height: 1080,
        name: '1920*1080',
        screen_size_type: 5,
        width: 1920
      }],
      screen_auto_switch: true,
      image_style_height: 75,
      image_style_width: 60,
      auction_style_width: 47,
      price_style_width: 55,
      title_style_background_color_next: '#0088FF',
      title_style_background_color_over: '#FF4444',
      title_style_background_color_auction_num: '#fff',
      title_style_shadow_color: '#1F3B77',
      img_shadow: '#00BCFF',
      img_bg: '#fff',
      img_width: 690,
      img_height: 690,
      auction_style_name: '#ffffff',
      auction_style_desc: '#999999',
      auction_style_price: '#999999',
      show_start_price: 1,
      auction_font_size_price: '36',
      price_style_font_color_unit: '#999999',
      auction_font_size_lot_horizontal: '36',
      auction_font_size_name_horizontal: '36',
      auction_font_size_intro_horizontal: '30',
      auction_font_size_price_horizontal: '30',
      price_font_size_currency_horizontal: '36',
      price_font_size_money_horizontal: '36'
    };
  },
  computed: {
    fix: function fix() {
      return this.windowSizeList[this.windowSize].width / 1920;
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)('screen_projection').then(function (res) {
      if (res.data.data) {
        _this.setData(JSON.parse(res.data.data));
      }
      if (res.data.data_two) {
        _this.setData(JSON.parse(res.data.data_two));
      }
      if (res.data.data_three) {
        _this.setData(JSON.parse(res.data.data_three));
      }
      if (res.data.data_four) {
        _this.setData(JSON.parse(res.data.data_four));
      }
      if (res.data.data_five) {
        _this.setData(JSON.parse(res.data.data_five));
      }
      _this.loadEnd = true;
      // this.ruleData = res.data;
    }).catch(function () {});
  },
  methods: {
    setData: function setData(data) {
      for (var key in data) {
        var m = data[key];
        this[key] = m;
      }
    },
    changeTab: function changeTab(val) {
      if (this.tab !== val) {
        this.tab = val;
      }
    },
    saveAction: function saveAction() {
      var _this2 = this;
      var data = {
        widthSizeSwitch: this.widthSizeSwitch,
        auction_style_background_color: this.auction_style_background_color,
        auction_style_font_color: this.auction_style_font_color,
        title_style_background_color: this.title_style_background_color,
        image_background_color: this.image_background_color,
        title_style_font_color: this.title_style_font_color,
        title_style_height: this.title_style_height,
        title_font_size_venue: this.title_font_size_venue,
        title_font_size_befor: this.title_font_size_befor,
        title_font_size_company_name: this.title_font_size_company_name,
        title_font_size_time: this.title_font_size_time
      };
      var data2 = {
        defaultList: this.defaultList,
        compImg: this.compImg,
        compName: this.compName,
        compWidth: this.compWidth,
        compHeight: this.compHeight,
        compImg1: this.compImg1,
        compName1: this.compName1,
        compWidth1: this.compWidth1,
        compHeight1: this.compHeight1,
        auction_style_height: this.auction_style_height,
        auction_font_size_lot: this.auction_font_size_lot,
        auction_font_size_name: this.auction_font_size_name,
        auction_font_size_intro: this.auction_font_size_intro,
        price_style_height: this.price_style_height,
        price_style_background_color: this.price_style_background_color,
        price_style_font_color: this.price_style_font_color,
        price_font_size_currency: this.price_font_size_currency,
        price_font_size_money: this.price_font_size_money
      };
      var data3 = {
        changeAbbreviation: this.changeAbbreviation,
        abbreviationList: this.abbreviationList,
        windowSize: this.windowSize
      };
      var data4 = {
        img_shadow: this.img_shadow,
        img_bg: this.img_bg,
        img_width: this.img_width,
        img_height: this.img_height,
        auction_style_name: this.auction_style_name,
        auction_style_desc: this.auction_style_desc,
        auction_style_price: this.auction_style_price,
        show_start_price: this.show_start_price,
        auction_font_size_price: this.auction_font_size_price,
        price_style_font_color_unit: this.price_style_font_color_unit,
        auction_font_size_lot_horizontal: this.auction_font_size_lot_horizontal,
        auction_font_size_name_horizontal: this.auction_font_size_name_horizontal,
        auction_font_size_intro_horizontal: this.auction_font_size_intro_horizontal,
        auction_font_size_price_horizontal: this.auction_font_size_price_horizontal,
        price_font_size_currency_horizontal: this.price_font_size_currency_horizontal,
        price_font_size_money_horizontal: this.price_font_size_money_horizontal
      };
      var data5 = {
        windowSizeList: this.windowSizeList,
        screen_auto_switch: this.screen_auto_switch,
        image_style_height: this.image_style_height,
        image_style_width: this.image_style_width,
        auction_style_width: this.auction_style_width,
        price_style_width: this.price_style_width,
        title_style_background_color_next: this.title_style_background_color_next,
        title_style_background_color_over: this.title_style_background_color_over,
        title_style_background_color_auction_num: this.title_style_background_color_auction_num,
        title_style_shadow_color: this.title_style_shadow_color
      };
      (0, _system.configClassKeysSave)('screen_projection', {
        data: JSON.stringify(data),
        data_two: JSON.stringify(data2),
        data_three: JSON.stringify(data3),
        data_four: JSON.stringify(data4),
        data_five: JSON.stringify(data5)
      }).then(function () {
        _this2.$message.success('保存成功');
      }).catch(function () {});
    },
    moneyAdd: function moneyAdd(money, rate, decimal) {
      var decimal1 = decimal > 0 && decimal <= 20 ? decimal : 2;
      var money2 = money;
      money2 = (0, _index.formatPrice)((0, _index.formatPrice)(money) * (0, _index.formatPrice)((0, _index.formatPrice)(rate))) / 10000000000;
      // const money1 = `${parseFloat((`${money2}`).replace(/[^\d-]/g, '')).toFixed(decimal1)}`;
      var money1 = "".concat(money2);
      var d = money1.split('.')[0].split('').reverse();
      var y = money1.split('.')[1] ? ".".concat(money1.split('.')[1]) : '';
      var q = '';
      for (var i = 0; i < d.length; i += 1) {
        q += d[i] + ((i + 1) % 3 === 0 && i + 1 !== d.length ? ',' : '');
      }
      var w = '';
      if (decimal1 > 0 && y) {
        w = y.substr(0, decimal1 + 1);
      }
      return "".concat(q.split('').reverse().join('')).concat(w);
    },
    uploadIconAction: function uploadIconAction(key) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        _this3[key] = img[0];
      }, 1);
    },
    delImg: function delImg(key) {
      this[key] = '';
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};