"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
var _guidance = require("@/api/guidance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["exhibitionType", "materialList", "guidanceList", "isShow"],
  data: function data() {
    return {
      modelType: 'work',
      list: [],
      searchName: '',
      cloneList: []
    };
  },
  watch: {
    exhibitionType: function exhibitionType(val) {
      this.init();
    },
    isShow: function isShow() {
      var _this = this;
      if (this.exhibitionType == 'exhibition') {
        this.modelType = 'work';
      } else {
        this.modelType = 'material_img';
      }
      this.list.forEach(function (m) {
        return m.active = false;
      });
      this.cloneList = this.list.filter(function (item) {
        return item.extends.type == _this.modelType;
      });
      this.cloneList.forEach(function (iem) {
        return iem.active = false;
      });
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    changeModelType: function changeModelType(num) {
      this.modelType = num;
      this.list.forEach(function (m) {
        return m.active = false;
      });
      this.cloneList = this.list.filter(function (item) {
        return item.extends.type == num;
      });
    },
    goSearchAction: function goSearchAction() {
      var _this2 = this;
      this.cloneList = this.list.filter(function (item) {
        if (item.name.includes(_this2.searchName)) return true;else return false;
      });
    },
    selectWork: function selectWork(item) {
      this.cloneList.forEach(function (iem) {
        return iem.active = false;
      });
      item.active = true;
      this.$emit("getAddWorkInfo", item);
    },
    init: function init() {
      var _this3 = this;
      this.list.forEach(function (iem) {
        return iem.active = false;
      });
      if (this.exhibitionType == 'exhibition') {
        this.modelType = 'work';
      } else {
        this.modelType = 'material_img';
      }
      if (this.exhibitionType == "material") {
        this.materialList.forEach(function (m) {
          return m.active = false;
        });
        this.list = JSON.parse(JSON.stringify(this.materialList));
      } else if (this.exhibitionType == "exhibition") {
        this.guidanceList.forEach(function (m) {
          return m.active = false;
        });
        this.list = JSON.parse(JSON.stringify(this.guidanceList));
      }
      this.cloneList = this.list.filter(function (item) {
        return item.extends.type == _this3.modelType;
      });
      this.cloneList.forEach(function (iem) {
        return iem.active = false;
      });
    }
  }
};