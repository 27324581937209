"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _answerTemplate = _interopRequireDefault(require("../../components/answer-template"));
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _authenticate = require("@/api/authenticate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectType = {
  "1": "Expert",
  "3": "PraisalReport",
  "4": "PraisalCategory",
  "5": "PraisalSetting",
  "6": "AppraisalComplaint",
  "2": "Appraisal"
};
var _default = exports.default = {
  name: "Appraisal",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      date: "",
      activeMenu: "1",
      showGoAnswer: {
        question_id: "",
        show: false,
        category_id: "",
        categoryIndex: 0,
        answer_text: "",
        collection_status: 1,
        category_level_id: 1,
        expertIsAuction: 0,
        user_is_auction: 0
      },
      is_auction: "",
      userIsAuction: false,
      expertIsAuction: false,
      showSeparate: false,
      questionId: "",
      separateUid: "",
      expertList: [],
      loading1: false,
      uname: "",
      uid: "",
      studyTotal: 0,
      studyParams: {
        page: 1,
        limit: 10
      },
      qid: "",
      showStudy: false,
      studyList: [],
      showAnswer: false,
      answerList: [],
      showImg: false,
      img: [],
      categoryList: [],
      showVideo: false,
      video: "",
      complaintReasons: "",
      category: 0,
      loading: false,
      total: 0,
      showDetail: false,
      questionDetail: {},
      cursorResault: "",
      queryParams: {
        limit: 10,
        status: "0",
        page: Number(this.$route.query.page || 1)
      },
      stime: "",
      etime: "",
      expertUname: "",
      exportUid: "",
      announcerList: [],
      showCategory: false,
      showCategoryId: 0,
      searchLoading: false,
      resultData: []
    };
  },
  created: function created() {
    var _this = this;
    (0, _authenticate._getCartgorySetList)().then(function (res) {
      _this.categoryList = res.data.list;
    }).catch(function (err) {
      console.log(err);
    });
    this.load();
  },
  methods: {
    onChangeDate: function onChangeDate(e) {
      this.date = e;
      console.log(e);
    },
    onBigImg: function onBigImg(row) {
      console.log(row);
      this.img = row.img;
      this.showImg = true;
    },
    onLookHuida: function onLookHuida(row) {
      this.answerList = row.answer_list;
      this.showAnswer = true;
    },
    onLookZhuida: function onLookZhuida(row) {
      this.answerList = row.answer_list;
      this.showAnswer = true;
    },
    onLookTouxue: function onLookTouxue(row) {
      this.showStudy = true;
      this.qid = row.id;
      this.loadStudyAction();
    },
    onHuidawenti: function onHuidawenti(row) {
      console.log(row.category.id, this.categoryList);
      var cIndex = this.categoryList.map(function (m) {
        return m.id;
      }).indexOf(row.category.id);
      this.showGoAnswer = {
        question_id: row.question_asked_id ? row.question_asked_id : row.id,
        show: true,
        category_id: this.categoryList[cIndex === -1 ? 0 : cIndex].id,
        categoryIndex: cIndex === -1 ? 0 : cIndex,
        answer_text: "",
        collection_status: 1,
        category_level_id: "",
        expertIsAuction: 0,
        user_is_auction: row.user_is_auction
      };
    },
    onFendan: function onFendan(row) {
      console.log(row);
      this.qid = row.id;
      this.showSeparate = true;
      this.remoteMethod1(row);
    },
    onJiandingxiangqingi: function onJiandingxiangqingi(row) {
      this.qid = row.id;
      this.showDetail = true;
      this.getDetail();
    },
    onXiugaifenlei: function onXiugaifenlei(row) {
      this.qid = row.id;
      this.showCategoryId = row.categoory_id;
      this.showCategory = true;
    },
    onDelete: function onDelete(row) {
      var _this2 = this;
      (0, _authenticate._deleteQuestion)(row.id).then(function (res) {
        if (res.status === 200) {
          _this2.$message.success("删除成功");
          _this2.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    onTuikuan: function onTuikuan(row) {
      var _this3 = this;
      var state = {
        order_id: row.order_id,
        question_id: row.id
      };
      (0, _authenticate._orderRefund)(state).then(function (res) {
        if (res.status === 200) {
          _this3.$message.success("退款成功");
          _this3.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    onShowTiwen: function onShowTiwen(row) {
      this.setHide(row.id, 0);
    },
    onHideTiwen: function onHideTiwen(row) {
      this.setHide(row.id, 1);
    },
    onLookImg: function onLookImg(row) {
      if (row.answer_img_list && row.answer_img_list.length) {
        this.img = row.answer_img_list;
      } else {
        this.img = row.image_url;
      }
      this.showImg = true;
    },
    onLookvideo: function onLookvideo(row) {
      this.video = row.answer_video || row.video_url;
      this.showVideo = true;
    },
    onSelectTit: function onSelectTit(index) {
      var _this4 = this;
      this.activeMenu = index;
      this.$router.push({
        name: selectType[index]
      });
      setTimeout(function () {
        _this4.activeMenu = '1';
      });
    },
    setHide: function setHide(id, val) {
      var _this5 = this;
      var state = {
        id: id,
        is_hide: val
      };
      (0, _authenticate._changeHide)(state).then(function (res) {
        if (res.status === 200) {
          _this5.load();
          _this5.$message.success("操作成功");
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    saveAnswerAction: function saveAnswerAction() {
      var _this6 = this;
      if (!this.showGoAnswer.answer_text) {
        this.$message.error("请输入回答");
        return false;
      }
      if (this.showGoAnswer.expertIsAuction) {
        if (!this.showGoAnswer.category_level_id) {
          this.$message.error("请选择推荐等级");
          return false;
        }
      }
      this.showGoAnswer.expert_is_auction = Number(this.showGoAnswer.expertIsAuction);
      (0, _authenticate._createAnswer)(this.showGoAnswer).then(function (res) {
        if (res.status === 200) {
          _this6.showGoAnswer.show = false;
          _this6.$message.success("回答成功");
          _this6.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
      return false;
    },
    changeCategoryAction: function changeCategoryAction(val) {
      this.showGoAnswer.category_id = this.categoryList[val].id;
    },
    remoteMethod1: function remoteMethod1(data) {
      var _this7 = this;
      console.log(data);
      this.loading1 = true;
      var params = {
        page: 1,
        limit: 10,
        status: 1
      };
      if (data.category) {
        params.category = data.category.id;
      }
      (0, _authenticate._getExpertList)(params).then(function (res) {
        if (res.status === 200) {
          _this7.expertList = res.data.list;
        }
        _this7.loading1 = false;
      }).catch(function (err) {
        _this7.loading1 = false;
        console.log(err);
      });
    },
    separateAction: function separateAction() {
      var _this8 = this;
      if (!this.separateUid) {
        this.$message.error("请选择鉴定师");
        return;
      }
      var params = {
        question_id: this.qid,
        expert_uid: this.separateUid
      };
      (0, _authenticate._distributionExpert)(params).then(function (res) {
        if (res.status === 200) {
          _this8.$message.success("修改成功");
          _this8.showSeparate = false;
          _this8.load();
        }
        _this8.separateUid = "";
      }).catch(function (err) {
        console.log(err);
        _this8.separateUid = "";
      });
    },
    exportAction: function exportAction() {
      var _this9 = this;
      this.queryParams.category = this.category;
      this.queryParams.uname = this.uname;
      this.queryParams.uid = this.uid;
      this.queryParams.question_id = this.questionId;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      delete params.status;
      if (params.category == 0) {
        params.category = "";
      }
      params.collection_status = this.cursorResault;
      if (this.date && this.date.length) {
        params.date = this.date.join("-");
      } else {
        params.date = "";
      }
      params.expert_uname = this.expertUname;
      params.expert_uid = this.exportUid;
      // 导出
      (0, _system.exportexcel)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
        export_type: "identificationOrder"
      })).then(function (res) {
        var h = _this9.$createElement;
        _this9.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this9.$msgbox.close();
                _this9.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      var type = "identificationOrder";
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    },
    searchPost: function searchPost(data) {
      var _this10 = this;
      var params = {
        uname: data,
        page: 1,
        limit: 20
      };
      this.searchLoading = true;
      (0, _authenticate._getSelectExpert)(params).then(function (res) {
        if (res.status === 200) {
          _this10.announcerList = res.data.list;
        }
        _this10.searchLoading = false;
      }).catch(function (err) {
        console.log(err);
        _this10.searchLoading = false;
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.limit = size;
      this.queryParams.page = 1;
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: newPage
        }
      });
      this.load();
    },
    changeCategory: function changeCategory() {
      var _this11 = this;
      if (!this.showCategoryId) {
        this.$message.error("请选择分类");
        return;
      }
      var params = {
        question_id: this.qid,
        category_id: this.showCategoryId
      };
      (0, _authenticate._changeQuestionCategory)(params).then(function (res) {
        if (res.status === 200) {
          _this11.$message.success("修改成功");
          _this11.showCategory = false;
          _this11.load();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    load: function load() {
      var _this12 = this;
      this.loading = false;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      delete params.status;
      if (params.category == 0) {
        params.category = "";
      }
      params.collection_status = this.cursorResault;
      if (this.date && this.date.length) {
        params.date = this.date.join("-");
      } else {
        params.date = "";
      }
      params.expert_uname = this.expertUname;
      params.expert_uid = this.exportUid;
      (0, _authenticate._getQuestionList)(params).then(function (res) {
        if (res.status === 200) {
          res.data.list.forEach(function (m) {
            var newM = m;
            newM.img = m.image_url ? m.image_url.split(",") : [];
            newM.video = m.video_url ? m.video_url.split(",") : [];
            newM.audio = m.answer_radio ? m.answer_radio.split(",") : [];
          });
          _this12.total = res.data.count;
          _this12.resultData = res.data.list;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    searchAction: function searchAction() {
      this.queryParams.category = this.category;
      this.queryParams.uname = this.uname;
      this.queryParams.uid = this.uid;
      this.queryParams.question_id = this.questionId;
      // this.queryParams.is_auction = this.is_auction;
      // this.queryParams.expert_is_auction = Number(this.expertIsAuction);
      // this.queryParams.user_is_auction = Number(this.userIsAuction);
      this.changePage(1);
    },
    changeStateEvent: function changeStateEvent(name) {
      this.queryParams.question_status = name;
      this.queryParams.status = name;
      this.queryParams.page = 1;
      this.category = "";
      this.uname = "";
      this.uid = "";
      this.questionId = "";
      this.is_auction = "";
      // this.expertIsAuction = false;
      // this.userIsAuction = false;
      this.searchAction();
    },
    loadStudyAction: function loadStudyAction() {
      var _this13 = this;
      var params = this.studyParams;
      params.question_id = this.qid;
      (0, _authenticate._getStudyOrderList)(params).then(function (res) {
        if (res.status === 200) {
          _this13.studyList = res.data.list;
          _this13.studyTotal = res.data.count;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getDetail: function getDetail() {
      var _this14 = this;
      var params = {
        question_id: this.qid
      };
      (0, _authenticate._getQuestionDetail)(this.qid).then(function (res) {
        if (res.status === 200) {
          console.log('xxxxxxx', res);
          var data = res.data;
          if (res.data.image_url) {
            data.image_url_list = res.data.image_url.split(",");
          }
          if (res.data.radio_url) {
            data.answer_radio_list = res.data.radio_url.split(",");
          }
          _this14.questionDetail = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    changeStudyPage: function changeStudyPage(page) {
      this.studyParams.page = page;
      this.loadStudyAction();
    }
  }
};