"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _pointer = _interopRequireDefault(require("@/components/customPage/pointer.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    pointer: _pointer.default
  },
  props: {
    facade: {
      type: [Object, Array]
    },
    content: {
      type: [Object, Array]
    }
  },
  data: function data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    margin: function margin() {
      if (this.facade.chamfer_style == 1) {
        return 0;
      } else {
        return 10;
      }
    }
  }
};