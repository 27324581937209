"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
var _marketing = require("@/api/marketing");
var _vuex = require("vuex");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    selectProduct: _goods.default,
    Nft: _nft.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      ruleForm: {
        title: "",
        banner_image: "",
        start_time: "",
        end_time: "",
        content: ""
      },
      rules: {
        title: {
          required: true,
          message: "请输入活动名称",
          trigger: "blur"
        },
        banner_image: {
          required: true,
          message: "请选择图片",
          trigger: "change"
        }
      },
      assist_date: "",
      playarr: [{
        title: "",
        synthetic_info: "",
        limit_synthetic_number: "",
        total_synthetic_number: "",
        systheticMaterialData: [],
        product: {},
        isneedall: 0,
        synthetic_nft_count: 1
      }],
      id: ""
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })),
  created: function created() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.loadDetail();
      this.getPlayList();
    }
  },
  methods: {
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex == 3 && row.isneedall) {
        if (rowIndex == 0) {
          return {
            rowspan: row.itemlength || 1,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
      // return {
      //   rowspan: row.rowspan,
      //   colspan: row.colspan,
      // };
    },
    editNeedAll: function editNeedAll(index) {
      var _this2 = this;
      // this.playarr[index].isneedall = this.playarr[index].isneedall == 1 ? 0 : 1;
      if (this.playarr[index].systheticMaterialData && this.playarr[index].systheticMaterialData.length > 0) {
        this.playarr[index].systheticMaterialData.forEach(function (item) {
          item.isneedall = _this2.playarr[index].isneedall;
        });
        this.playarr[index].systheticMaterialData[0].itemlength = this.playarr[index].systheticMaterialData.length;
      }
      this.editPlay(this.playarr[index]);
      // console.log(this.playarr[index].isneedall);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.assist_date = e;
      console.log(this.moment(e[0]).format("YYYY-MM-DD HH:mm:ss"));
      this.ruleForm.start_time = e ? this.moment(e[0]).format("YYYY-MM-DD HH:mm:ss") : "";
      this.ruleForm.end_time = e ? this.moment(e[1]).format("YYYY-MM-DD HH:mm:ss") : "";
    },
    loadDetail: function loadDetail() {
      var _this3 = this;
      (0, _marketing.synthesisDetailApi)(this.id).then(function (res) {
        var info = res.data;
        _this3.assist_date = [info.start_time, info.end_time];
        _this3.ruleForm = {
          title: info.title,
          banner_image: info.banner_image,
          start_time: info.start_time,
          end_time: info.end_time,
          content: info.content
        };
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this3.$router.back();
      });
    },
    deleteplay: function deleteplay(id) {
      var _this4 = this;
      (0, _marketing.synthesisDeletePlayApi)(id).then(function (res) {
        _this4.getPlayList();
      });
    },
    getPlayList: function getPlayList() {
      var _this5 = this;
      (0, _marketing.synthesisPlayListApi)({
        synthetic_album_id: this.id
      }).then(function (res) {
        if (res.data.list.length == 0) {
          _this5.playarr = [{
            title: "",
            synthetic_info: "",
            limit_synthetic_number: "",
            total_synthetic_number: "",
            systheticMaterialData: [],
            product: {},
            isneedall: 0,
            synthetic_nft_count: 1
          }];
          return;
        }
        res.data.list.forEach(function (item) {
          if (item.synthetic_need_number > 0) {
            item.isneedall = 1;
          } else {
            item.isneedall = 0;
          }
          item.systheticMaterialData = item.syntheticMaterial.map(function (m) {
            return {
              id: m.id,
              synthetic_id: m.synthetic_id,
              product_id: m.product_id,
              need_number: m.need_number,
              is_destroy: m.is_destroy,
              image: m.product.image,
              store_name: m.product.store_name,
              isneedall: item.isneedall,
              itemlength: item.syntheticMaterial.length
            };
          });
        });
        _this5.playarr = [];
        _this5.$nextTick(function () {
          _this5.playarr = res.data.list;
        });
      });
    },
    backAction: function backAction() {
      this.$router.back();
    },
    handleAdditem: function handleAdditem(view, i, item) {
      this.changeIndex = i;
      this.changePlayitem = item;
      this.changeTtype = "";
      this.$refs[view].changeshow();
    },
    selectreaultProduct: function selectreaultProduct(i) {
      this.changeIndex = i;
      this.$refs.product_nft.changeshow();
      this.changeTtype = "result";
    },
    selectMessage: function selectMessage(data) {
      if (data.data && data.data[0]) {
        var newdata = JSON.parse(JSON.stringify(data.data[0]));
        newdata.store_name = newdata.name;
        newdata.product_id = newdata.id;
        newdata.is_destroy = 1;
        newdata.need_number = 1;
        if (this.changeTtype == "result") {
          this.playarr[this.changeIndex].product = newdata;
        } else {
          var idsarr = this.playarr[this.changeIndex].systheticMaterialData.map(function (item) {
            return item.product_id;
          });
          if (idsarr.indexOf(newdata.id) > -1) {
            this.$message.error("该商品已添加");
            return;
          }
          newdata.isneedall = this.playarr[this.changeIndex].isneedall;
          this.playarr[this.changeIndex].systheticMaterialData.push(newdata);
          if (this.playarr[this.changeIndex].systheticMaterialData && this.playarr[this.changeIndex].systheticMaterialData.length > 0) {
            this.playarr[this.changeIndex].systheticMaterialData[0].itemlength = this.playarr[this.changeIndex].systheticMaterialData.length;
          }
        }
        if (this.changePlayitem) {
          this.editPlay(this.changePlayitem);
        }
      }
    },
    cancelSelect: function cancelSelect(index, row, item) {
      var _this6 = this;
      if (!item.synthetic_id) {
        item.systheticMaterialData.splice(index, 1);
      } else {
        (0, _marketing.synthesisDeleteGoodsApi)(item.synthetic_id, row.product_id).then(function (res) {
          item.systheticMaterialData.splice(index, 1);
        }).catch(function (_ref3) {
          var message = _ref3.message;
          _this6.$message.error(message);
        });
      }
    },
    showSetAction: function showSetAction(type) {
      this.setType = type;
      this.showSet = true;
    },
    setOkAction: function setOkAction() {},
    savePlay: function savePlay(type) {
      var _this7 = this;
      var json = this.playarr[this.playarr.length - 1];
      if (!json.title) {
        this.$message.error("请输入玩法名称");
        return;
      }
      if (!json.synthetic_info) {
        this.$message.error("请输入玩法描述");
        return;
      }
      // if (!json.limit_synthetic_number) {
      //   this.$message.error("请输入限制合成数量");
      //   return;
      // }
      // if (!json.total_synthetic_number) {
      //   this.$message.error("请输入总合成数量");
      //   return;
      // }
      if (!json.product.product_id) {
        this.$message.error("请选择合成商品");
        return;
      }
      if (json.systheticMaterialData.length == 0) {
        this.$message.error("请选择合成材料");
        return;
      }
      var action = json.synthetic_id ? _marketing.synthesisEditPlayApi : _marketing.synthesisAddPlayApi;
      json.synthetic_album_id = this.id;
      json.product_id = json.product.product_id;
      if (json.isneedall == 1) {
        if (json.systheticMaterialData && json.systheticMaterialData.length > 0) {
          json.synthetic_need_number = json.systheticMaterialData[0].need_number;
        }
      } else {
        json.synthetic_need_number = 0;
      }
      action(json.synthetic_id ? json.synthetic_id : json, json).then(function (res) {
        _this7.playarr[_this7.playarr.length - 1].synthetic_id = res.message;
        _this7.playarr.push({
          title: "",
          synthetic_info: "",
          limit_synthetic_number: "",
          total_synthetic_number: "",
          systheticMaterialData: [],
          product: {},
          isneedall: 0,
          synthetic_nft_count: 1
        });
      });
    },
    editPlay: function editPlay(item) {
      if (item.synthetic_id) {
        var json = item;
        json.synthetic_album_id = this.id;
        if (json.isneedall == 1) {
          if (json.systheticMaterialData && json.systheticMaterialData.length > 0) {
            json.synthetic_need_number = json.systheticMaterialData[0].need_number;
          }
        } else {
          json.synthetic_need_number = 0;
        }
        (0, _marketing.synthesisEditPlayApi)(item.synthetic_id, json).then(function (res) {
          // this.$message.success('编辑成功');
        });
      }
    },
    editPlayGoods: function editPlayGoods(row, item) {
      var json = row;
      if (json.synthetic_id) {
        (0, _marketing.synthesisUpdateGoodsApi)(json.product_id, json).then(function (res) {
          // this.$message.success(res.message);
          // this.getselectList();
        });
      }
    },
    saveAction: function saveAction(formName) {
      var _this8 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var auction = _marketing.synthesisCreateApi;
          if (_this8.id) {
            auction = _marketing.synthesisEditApi;
            var json = _this8.playarr[_this8.playarr.length - 1];
            if (!json.synthetic_id && json.title) {
              _this8.savePlay();
            }
          }
          auction(_this8.id ? _this8.id : (0, _objectSpread2.default)({}, _this8.ruleForm), (0, _objectSpread2.default)({}, _this8.ruleForm)).then(function (res) {
            // this.$message.success(res.message);
            // this.$router.back();
            if (_this8.id) {
              _this8.$message.success('保存成功');
            }
            if (res.status == 200 && res.data.synthetic_album_id) {
              _this8.id = res.data.synthetic_album_id;
            }
          });
        } else {
          _this8.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          _this.ruleForm.banner_image = img[0];
        }
      }, tit);
    }
  }
};