"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _product = require("@/api/product");
var _cms = require("@/api/cms");
var _live = require("@/api/live");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      showSelectCategory: false,
      selectData1: [],
      classifyData1: [{
        name: '拍品分类',
        type: 'auctionClass',
        desc: '拍品'
      }, {
        name: '文章分类',
        type: 'articleClass',
        desc: '文章'
      }, {
        name: '商品分类',
        type: 'productClass',
        desc: '商品'
      }, {
        name: '作品分类',
        type: 'worksClass',
        desc: '作品'
      }, {
        name: '藏品分类',
        type: 'nftClass',
        desc: '藏品'
      }, {
        name: '直播分类',
        type: 'liveClass',
        desc: '直播'
      }, {
        name: '作者分类',
        type: 'brandClass',
        desc: '作者'
      }],
      classList1: [],
      classList2: [],
      classList3: [],
      selectCate1: [],
      selectCate2: [],
      selectCate3: [],
      changeCate: []
    };
  },
  watch: {
    flag: function flag(val) {
      this.showSelectCategory = val;
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  mounted: function mounted() {
    this.showSelectCategory = this.flag;
  },
  methods: {
    changeshow: function changeshow(data) {
      // if (data) {
      //   const d = JSON.parse(JSON.stringify(data));
      //   this.selectData1 = d.selectData1;
      //   this.changeCate = d.changeCate;
      //   this.classList1 = d.classList1;
      //   this.classList2 = d.classList2;
      //   this.classList3 = d.classList3;
      //   this.selectCate1 = d.selectCate1;
      //   this.selectCate2 = d.selectCate2;
      //   this.selectCate3 = d.selectCate3;
      //   this.changeCate = d.changeCate;
      // } else {
      //   this.selectData1 = [];
      //   this.changeCate = [];
      //   this.classList1 = [];
      //   this.classList2 = [];
      //   this.classList3 = [];
      //   this.selectCate1 = [];
      //   this.selectCate2 = [];
      //   this.selectCate3 = [];
      //   this.changeCate = [];
      // }
      this.selectData1 = [];
      this.changeCate = [];
      this.classList1 = [];
      this.classList2 = [];
      this.classList3 = [];
      this.selectCate1 = [];
      this.selectCate2 = [];
      this.selectCate3 = [];
      this.changeCate = [];
      this.showSelectCategory = true;
    },
    /**
     * 处理分类选择
     * @param  {[type]} val [description]
     * @return {[type]}     [description]
     */
    handleChange1: function handleChange1(value) {
      var _this = this;
      var action;
      switch (value.type) {
        case 'auctionClass':
          action = _product.storeCategoryListApi;
          break;
        case 'articleClass':
          action = _cms.articleListApi;
          break;
        case 'liveClass':
          action = _live.liveListApi;
          break;
        case 'brandClass':
          action = _product.brandCategoryListApi;
          break;
        default:
          action = _product.storeCategoryListApi;
          break;
      }
      action().then(function (res) {
        if (value.type === 'articleClass') {
          res.data.forEach(function (m) {
            m.cate_name = m.title;
            m.store_category_id = m.article_category_id;
          });
        } else if (value.type === 'liveClass') {
          res.data.forEach(function (m) {
            m.cate_name = m.title;
            m.store_category_id = m.category_id;
          });
        } else if (value.type === 'brandClass') {
          res.data.forEach(function (m) {
            m.store_category_id = m.store_brand_category_id;
          });
        }
        var data = res.data;
        _this.changeCate = [];
        _this.classList1 = data;
      });
      // this.$http.get(url, { params }).then(res => {
      //   if (res.code === 200) {
      //     let data = res.data;
      //     if (value.type === 'activeClass') {
      //       data = res.data.map(m => {
      //         return {
      //           cate_name: m.name,
      //           child: [],
      //           id: m.store_category_id
      //         };
      //       });
      //     }
      //     this.changeCate = [];
      //     this.classList1 = data;
      //   }
      // });
    },
    handleChange2: function handleChange2(item) {
      if (item.children && item.children.length > 0) {
        this.classList2 = item.children;
        this.selectCate2 = [];
        this.classList3 = [];
        this.selectCate3 = [];
      } else {
        var index = -1;
        for (var key in this.changeCate) {
          var m = this.changeCate[key];
          if (m.store_category_id == item.store_category_id) {
            index = key;
            break;
          }
        }
        if (index === -1) {
          this.changeCate.push(item);
        }
        this.classList2 = [];
        this.selectCate2 = [];
        this.classList3 = [];
        this.selectCate3 = [];
      }
    },
    handleChange3: function handleChange3(item) {
      if (item.children && item.children.length > 0) {
        this.classList3 = item.children;
      } else {
        var index = -1;
        for (var key in this.changeCate) {
          var m = this.changeCate[key];
          if (m.store_category_id == item.store_category_id) {
            index = key;
            break;
          }
        }
        console.log(index);
        if (index === -1) {
          this.changeCate.push(item);
        }
        this.classList3 = [];
        this.selectCate3 = [];
      }
    },
    handleChange4: function handleChange4(item) {
      var index = -1;
      for (var key in this.changeCate) {
        var m = this.changeCate[key];
        if (m.store_category_id == item.store_category_id) {
          index = key;
          break;
        }
      }
      if (index === -1) {
        this.changeCate.push(item);
      }
    },
    cursorSelect: function cursorSelect() {
      console.log(this.selectData1.type, this.selectData1.desc, this.changeCate);
      this.$emit('selectMessage', {
        type: this.selectData1.type,
        typeTxt: this.selectData1.desc,
        data: this.changeCate
      });
      this.showSelectCategory = false;
    },
    closeAction: function closeAction() {
      this.showSelectCategory = false;
    }
  }
};