var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "500px" } },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "500px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-bottom": "20px" },
              attrs: {
                "label-width": "100px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题栏背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.tabBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "tabBackground", $$v)
                      },
                      expression: "facade.tabBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.tabColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "tabColor", $$v)
                      },
                      expression: "facade.tabColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.tabFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "tabFontSize", $$v)
                      },
                      expression: "facade.tabFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.tabFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "tabFontWeight", $$v)
                        },
                        expression: "facade.tabFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "选中文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.currColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "currColor", $$v)
                      },
                      expression: "facade.currColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "选中字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.currFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "currFontSize", $$v)
                      },
                      expression: "facade.currFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "选中文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.currFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "currFontWeight", $$v)
                        },
                        expression: "facade.currFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "选中项横线" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.currBgColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "currBgColor", $$v)
                      },
                      expression: "facade.currBgColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "选中项横线宽" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.currBgWidth,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "currBgWidth", $$v)
                      },
                      expression: "facade.currBgWidth",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "选中项横线高" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 20 },
                    model: {
                      value: _vm.facade.currBgHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "currBgHeight", $$v)
                      },
                      expression: "facade.currBgHeight",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "横线距底部" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.currBgBottom,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "currBgBottom", $$v)
                      },
                      expression: "facade.currBgBottom",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "订单标签展示" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.facade.order_tab,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "order_tab", $$v)
                        },
                        expression: "facade.order_tab",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 0 } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: 1 } }, [
                        _vm._v("待付款"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: 2 } }, [
                        _vm._v("待发货"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: 3 } }, [
                        _vm._v("待收货"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: 5 } }, [
                        _vm._v("已完成"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: 6 } }, [
                        _vm._v("售后"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: 7 } }, [
                        _vm._v("已关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }