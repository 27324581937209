var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(6, "用户服务协议")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("用户服务协议")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [_vm._v("用户注册页面")]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(4, "隐私政策")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("隐私政策")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [
          _vm._v("用户注册页面、用户实名认证页面"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(3, "用户协议")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("用户协议")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [_vm._v("用户实名认证页面")]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(1, "参拍须知")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("参拍须知")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [_vm._v("拍卖专场页面")]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(5, "即刻拍参拍须知")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("即刻拍参拍须知")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [_vm._v("即刻拍专用")]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(2, "交易服务隐私协议")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("交易服务隐私协议")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [_vm._v("用户出价时勾选")]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(12, "委托发货协议")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("委托发货协议")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [
          _vm._v("委托方签署拍品退回委托发货协议"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(10, "鉴定规则")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("鉴定规则")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [_vm._v("鉴定介绍页使用")]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(9, "用户言行规范协议")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("用户言行规范协议")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [
          _vm._v("用户上传鉴定需求页面"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(7, "数字藏品购买须知")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("数字藏品购买须知")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [_vm._v("购买数字藏品页面")]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(8, "盲盒玩法")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("盲盒玩法")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [_vm._v("购买数字盲盒页面")]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item flex align-items-c",
        on: {
          click: function ($event) {
            return _vm.setRuleAction(13, "现场纸质竞买协议")
          },
        },
      },
      [
        _c("div", { staticClass: "l flex-one" }, [_vm._v("现场纸质竞买协议")]),
        _vm._v(" "),
        _c("div", { staticClass: "l flex-one" }, [
          _vm._v("现场号牌打印竞买协议页面"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [_vm._v("编辑")]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item flex align-items-c" }, [
      _c(
        "div",
        { staticClass: "l flex-one", staticStyle: { "text-align": "center" } },
        [_vm._v("协议名称")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "l flex-one", staticStyle: { "text-align": "center" } },
        [_vm._v("用途")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "r" }, [_vm._v("操作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }