"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: [{
        name: '商品列表页',
        id: 315
      }],
      listLoading: false,
      queryParams: {
        page: 1,
        limit: 20,
        type: 'diy_page'
      },
      total: 0,
      showImg: false,
      showImgUrl: ''
    };
  },
  mounted: function mounted() {
    this.load();
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  methods: {
    load: function load() {
      var _this = this;
      this.listLoading = true;
      (0, _system.diypageList)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.list = response.data.list;
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    createdDiy: function createdDiy() {
      this.$router.push({
        path: '/merchant/diy/page'
      });
    },
    editDiy: function editDiy(id) {
      this.$router.push({
        path: '/merchant/diy/page',
        query: {
          id: id
        }
      });
    },
    copyAction: function copyAction(id) {
      var _this2 = this;
      (0, _system.copyDiypageApi)(id).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.load();
      }).catch(function () {});
    },
    delDiy: function delDiy(id) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u9875\u9762?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _system.delDiypageDetailApi)(id).then(function () {
          _this3.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    sendAction: function sendAction(id) {
      var _this4 = this;
      this.$modalYouSure("\u70B9\u51FB\u786E\u8BA4\u540E\uFF0C\u5C0F\u7A0B\u5E8F\u9875\u9762\u4F1A\u5373\u65F6\u5E94\u7528\u81F3\u8BE5\u65B0\u6A21\u677F\u98CE\u683C\uFF0C\u8BF7\u786E\u8BA4\uFF01").then(function () {
        (0, _system.releasePageApi)({
          id: id
        }).then(function () {
          _this4.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    createQRCode: function createQRCode(id, key) {
      var _this5 = this;
      if (key === 2 || key === 4) {
        this.copyText = "/pages/home/home?id=".concat(id);
        var input = document.createElement('input'); // 直接构建input
        input.value = this.copyText; // 设置内容
        console.log(input.value);
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand('Copy'); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.$message.success('复制成功');
      } else if (key === 1 || key === 3) {
        var action = _system.createQRCodeMiniApi;
        if (key === 3) {
          action = _system.createQRCodeApi;
        }
        this.showImg = true;
        action({
          path: "/pages/home/home?id=".concat(id),
          type: 1
        }).then(function (res) {
          _this5.showImgUrl = res.data.base_image;
        });
      }
    },
    setHomeAction: function setHomeAction(id, type) {
      var _this6 = this;
      this.$confirm("\u786E\u8BA4\u8BBE\u7F6E\u4E3A\u9996\u9875?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var action = _system.diypageSetDefaultApi;
        if (type) {
          action = _system.diypageSetTTDefaultApi;
        }
        action(id).then(function (res) {
          _this6.$message.success('操作成功');
          _this6.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    cancelHomeAction: function cancelHomeAction(id) {
      var _this7 = this;
      this.$confirm("\u53D6\u6D88\u6296\u97F3\u9996\u9875?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _system.diypageCancelTTDefaultApi)(id).then(function (res) {
          _this7.$message.success('操作成功');
          _this7.load();
        }).catch(function () {});
      }).catch(function () {});
    }
  }
};