var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tit-color add-tit" },
    _vm._l(_vm.titleList, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "tit-item flex",
          on: {
            click: function ($event) {
              return _vm.$emit("changeAction", index)
            },
          },
        },
        [
          _c("span", { class: _vm.active === index ? "active-tit" : "" }, [
            _vm._v(_vm._s(item)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }