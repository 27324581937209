var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "辅助空白", is_tab: false } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.facade,
            "label-width": "90px",
            size: "mini",
            "label-position": "left",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "分割高度" } },
            [
              _c("slider", {
                attrs: { min: 1, max: 300 },
                model: {
                  value: _vm.facade.height,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "height", $$v)
                  },
                  expression: "facade.height",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "背景颜色" } },
            [
              _c("colour", {
                attrs: { colour: "#FFFFFF" },
                model: {
                  value: _vm.facade.background_color,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "background_color", $$v)
                  },
                  expression: "facade.background_color",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h30" }),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "带辅助线" } },
            [
              _c(
                "div",
                { staticClass: "item-align-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      staticStyle: { "font-size": "12px", color: "#999" },
                    },
                    [_vm._v("中间带分隔线")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#009406",
                      "inactive-color": "#cccccc",
                    },
                    model: {
                      value: _vm.content.has_line,
                      callback: function ($$v) {
                        _vm.$set(_vm.content, "has_line", $$v)
                      },
                      expression: "content.has_line",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.content.has_line
            ? _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "线长度" } },
                [
                  _c("slider", {
                    attrs: { min: 1, max: 375 },
                    model: {
                      value: _vm.facade.line_width,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "line_width", $$v)
                      },
                      expression: "facade.line_width",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.content.has_line
            ? _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "线高度度" } },
                [
                  _c("slider", {
                    attrs: { min: 1, max: _vm.facade.height },
                    model: {
                      value: _vm.facade.line_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "line_height", $$v)
                      },
                      expression: "facade.line_height",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.content.has_line
            ? _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "线颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.line_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "line_color", $$v)
                      },
                      expression: "facade.line_color",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }