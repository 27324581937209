"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default
  },
  data: function data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: "content",
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: "facade",
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    }
  },
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    // if (!this.facade.isSet) {
    this.$store.dispatch("user/getEnterInfo").then(function (res) {
      _this.content.mer_collection_category = res.config.mer_collection_category.split('、');
      _this.content.mer_collection_email_data = res.config.mer_collection_email_data;
      _this.content.company_address = res.config.company_address;
      _this.content.contact_phone = res.config.contact_phone;
      _this.content.mer_pc_website = 'www.' + res.config.mer_pc_website;
      _this.content.mer_media_make_logo_data = res.config.mer_media_make_logo_data;
      _this.content.mer_qrcode_data = res.config.mer_qrcode_data.filter(function (item) {
        return item.imgUrl;
      });
      console.log('1---', _this.content);
      // this.facade.isSet = true;
    });
    // }
  },
  methods: {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {}
};