"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _auction = require("@/api/auction");
var _AfterNoticePrint = _interopRequireDefault(require("@/components/order/AfterNoticePrint"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    AfterNoticePrint: _AfterNoticePrint.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formValidate: {
        venue_id: "",
        album_id: ""
      },
      rules: {
        venue_id: [{
          required: true,
          message: "请选择拍卖会",
          trigger: "change"
        }],
        album_id: [{
          required: true,
          message: "请选择专场",
          trigger: "change"
        }]
      },
      venceList: [],
      albumArr: [],
      adminarr: [],
      show: true,
      albumdetail: {},
      reportList: [],
      venueInfo: {},
      reportInfo: null,
      waitReportList: [],
      merData: {},
      albumList: [],
      showList: [],
      tworeportarrList: [],
      showtworeport: false,
      showPrintAblum: false,
      showtitleflag: false,
      borderArr: [],
      albumPrintList: [],
      bordeAblumrArr: [],
      titBodyHeight: 0,
      printVisible: false,
      isPag: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (Number(this.$route.query.vid)) {
      this.formValidate.venue_id = Number(this.$route.query.vid);
      this.getstatic();
    }
    (0, _auction.meetingList)({
      page: 1,
      limit: 20
    }).then(function (res) {
      _this.venceList = res.data.list;
      if (res.data.list.length) {
        if (!Number(_this.$route.query.vid)) {
          _this.formValidate.venue_id = res.data.list[0].venue_id;
          // this.getalbum();
          // this.formValidate.venue_id = 466;
          // this.getstatic();
        }
      }
    });
  },
  methods: {
    getAuction: function getAuction(entrust_uid) {
      var _this2 = this;
      (0, _auction.entrustAuction)({
        venue_id: this.formValidate.venue_id,
        entrust_uid: entrust_uid || ""
      }).then(function (res) {
        console.log(res);
        _this2.$refs["printAfterView"].loadData({
          venueInfo: res.data.venueInfo,
          auctionList: res.data.entrustAuctionList[0].auctionList,
          entrustUser: res.data.entrustAuctionList[0].entrustUser
        });
      });
    },
    onBatch: function onBatch() {
      var _this3 = this;
      (0, _auction.exportEntrustAuctionDealPdfApi)({
        venue_id: this.formValidate.venue_id
      }).then(function (res) {
        var h = _this3.$createElement;
        _this3.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this3.$msgbox.close();
                _this3.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('entrustAuctionDealPdf');
    },
    getstatic: function getstatic() {
      var _this4 = this;
      (0, _auction.entrustAuctionStatistics)({
        venue_id: this.formValidate.venue_id,
        type: 'entrustAuctionDealPdf'
      }).then(function (res) {
        console.log(res);
        _this4.reportList = res.data.list;
      });
    },
    changevenue: function changevenue(e) {
      this.getstatic();
    }
  }
};