var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "merchantDepartmentCreate",
                    expression: "'merchantDepartmentCreate'",
                  },
                ],
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("添加部门")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            data: _vm.tableData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "department_id", label: "ID", "min-width": "60" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "部门名称", "min-width": "150" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "leader_names",
              label: "部门负责人",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "staff_names",
              label: "部门成员",
              "min-width": "800",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "100", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantDepartmentUpdate",
                            expression: "'merchantDepartmentUpdate'",
                          },
                        ],
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(
                              scope.row.department_id,
                              scope.row
                            )
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantDepartmentDel",
                            expression: "'merchantDepartmentDel'",
                          },
                        ],
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(
                              scope.row.department_id,
                              scope.$index
                            )
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block paginaBox" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-top": "-6vh" },
          attrs: {
            title: "部门设置",
            visible: _vm.showruleProp,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showruleProp = $event
            },
          },
        },
        [
          _c("div", { staticClass: "mt20 flex align-items-c" }, [
            _c("div", { staticClass: "w100" }, [_vm._v("部门名称：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c("el-input", {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请输入部门名称" },
                  model: {
                    value: _vm.rulename,
                    callback: function ($$v) {
                      _vm.rulename = $$v
                    },
                    expression: "rulename",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt20 flex align-items-c" }, [
            _c("div", { staticClass: "w100" }, [_vm._v("部门负责人：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      multiple: "",
                      filterable: "",
                      placeholder: "请输入名称选择",
                      "popper-class": "high-select",
                    },
                    model: {
                      value: _vm.personInCharge,
                      callback: function ($$v) {
                        _vm.personInCharge = $$v
                      },
                      expression: "personInCharge",
                    },
                  },
                  _vm._l(_vm.peopleList, function (m, i) {
                    return _c("el-option", {
                      key: i,
                      attrs: { value: m.merchant_admin_id, label: m.real_name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt20 flex align-items-c" }, [
            _c("div", { staticClass: "w100" }, [_vm._v("部门成员：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      multiple: "",
                      filterable: "",
                      placeholder: "请输入名称选择",
                      "popper-class": "high-select",
                    },
                    model: {
                      value: _vm.staffList,
                      callback: function ($$v) {
                        _vm.staffList = $$v
                      },
                      expression: "staffList",
                    },
                  },
                  _vm._l(_vm.peopleList, function (m, i) {
                    return _c("el-option", {
                      key: "staff" + i,
                      attrs: { value: m.merchant_admin_id, label: m.real_name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 mb20" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "300px", "margin-top": "20px" },
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.saverule },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }