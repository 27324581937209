"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var contractRouter = {
  path: "".concat(_settings.roterPre, "/contract"),
  name: 'contract',
  meta: {
    icon: '',
    title: '合同'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'manage',
    name: 'contractmanage',
    meta: {
      title: '合同管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/index'));
      });
    },
    children: [{
      path: 'clist',
      name: 'clist',
      meta: {
        title: '合同列表',
        noCache: true,
        permissionKey: '/contract/manage/clist'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/contract/contactList'));
        });
      }
    }, {
      path: 'letterfinnance',
      name: 'letterfinnance',
      meta: {
        title: '委托方对账',
        noCache: false,
        activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist"),
        permissionKey: '/contract/manage/letterfinnance'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/contract/letterfinnance'));
        });
      }
    }, {
      path: 'returninvorder',
      name: 'returninvorder',
      meta: {
        title: '委托标的退回单',
        noCache: false,
        activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist"),
        permissionKey: '/contract/manage/returninvorder'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/contract/returninvorder'));
        });
      }
    }]
  }, {
    path: 'contractConfig',
    name: 'contractConfig',
    meta: {
      title: '合同配置',
      noCache: true
    },
    redirect: "".concat(_settings.roterPre, "/contract/contractConfig/contractSetting"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/allchild'));
      });
    },
    children: [{
      path: 'contractSetting',
      name: 'contractSetting',
      meta: {
        title: '标的清单',
        noCache: false,
        activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist"),
        permissionKey: '/contract/contractConfig/contractSetting'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/contract/contractSetting'));
        });
      }
    }, {
      path: 'reconcCon',
      name: 'reconcCon',
      meta: {
        title: '对账配置',
        noCache: false,
        activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist"),
        permissionKey: '/contract/contractConfig/reconcCon'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/contract/reconcCon'));
        });
      }
    }, {
      path: 'ruleConfig',
      name: 'lable',
      meta: {
        title: '规则配置',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist"),
        permissionKey: '/contract/contractConfig/ruleConfig'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/basic/ruleConfig'));
        });
      }
    }, {
      path: 'principal',
      name: 'principal',
      meta: {
        title: '委托方收费',
        noCache: false,
        activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist"),
        permissionKey: '/contract/contractConfig/principal'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/contract/principal'));
        });
      }
    }, {
      path: 'contractlist',
      name: 'contractlist',
      meta: {
        title: '合同模板',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist"),
        permissionKey: '/contract/contractConfig/contractlist'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/contract/list'));
        });
      }
    }, {
      path: 'contractno',
      name: 'contractno',
      meta: {
        title: '纸质合同编号',
        noCache: false,
        activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist"),
        permissionKey: '/contract/contractConfig/contractno'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/contract/concactNo'));
        });
      }
    }]
  }, {
    path: 'contractlist',
    name: 'contractlist',
    meta: {
      title: '合同模板',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/list'));
      });
    }
  }, {
    path: 'contracttwo',
    name: 'contracttwo',
    meta: {
      title: '合同',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/contractStepTwo'));
      });
    }
  }, {
    path: 'contractdetail/:id?',
    name: 'contractdetail',
    meta: {
      title: '主题模板',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/contract/manage/clist")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/detail'));
      });
    }
  }, {
    path: 'clist',
    name: 'clist',
    meta: {
      title: '合同列表',
      noCache: true
    },
    redirect: "".concat(_settings.roterPre, "/contract/manage/clist"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/contactList'));
      });
    }
  }, {
    path: 'cdetail/:id?',
    name: 'cdetail',
    meta: {
      title: '合同详情',
      noCache: false,
      activeMenu: "".concat(_settings.roterPre, "/contract/clist")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/contractDetail'));
      });
    }
  }, {
    path: 'appendix/:id?',
    name: 'appendix',
    meta: {
      title: '附件模板',
      noCache: false,
      activeMenu: "".concat(_settings.roterPre, "/contract/contractlist")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/appendixtemp'));
      });
    }
  }, {
    path: 'letterfinnance',
    name: 'letterfinnance',
    meta: {
      title: '委托方对账',
      noCache: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/letterfinnance'));
      });
    }
  }, {
    path: 'returninvorder',
    name: 'returninvorder',
    meta: {
      title: '委托标的退回单',
      noCache: false
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/returninvorder'));
      });
    }
  }, {
    path: 'returntooutorder',
    name: 'returntooutorder',
    meta: {
      title: '委托标的退回单',
      noCache: false,
      activeMenu: "".concat(_settings.roterPre, "/contract/clist")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contract/returntooutOrder'));
      });
    }
  }]
};
var _default = exports.default = contractRouter;