"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _index = require("@/utils/index");
var _open3d = require("@/api/open3d");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      status: '1',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        status: ''
      },
      setData: {
        show: false,
        isOpen: false,
        loading: false,
        id: '',
        name: '',
        exhibition_cover: '',
        exhibition_image: '',
        sort: 1,
        max_num: null
      },
      hasOpen: null
    };
  },
  mounted: function mounted() {
    this.isAuth();
    this.getList(1);
  },
  methods: {
    isAuth: function isAuth() {
      var is3dAuth = localStorage.getItem('is3dAuth');
      if (is3dAuth) {
        this.hasOpen = true;
        return false;
      } else {
        this.hasOpen = true;
        if (this.hasOpen) {
          localStorage.setItem('is3dAuth', '1');
        }
      }
      // isOpen3dApi().then(res => {
      //   this.hasOpen = !!res.data;
      //   if (this.hasOpen) {
      //     localStorage.setItem('is3dAuth', '1');
      //   }
      // });
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      (0, _open3d.templateListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    closeAction: function closeAction(id, open) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4".concat(open ? '启用' : '关闭', "\u6B64\u6A21\u578B?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _open3d.templateSwitchApi)({
          mer_temp_id: id
        }).then(function () {
          _this2.$message.success('操作成功');
          _this2.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    openAction: function openAction(m) {
      if (!m.is_set) {
        this.showSetAction(m.mer_temp_id, true);
        return false;
      }
      this.closeAction(m.mer_temp_id, 1);
    },
    changeImg: function changeImg(key) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        _this3.setData[key] = img[0];
      }, 1, '1');
    },
    showSetAction: function showSetAction(id, isOpen) {
      var _this4 = this;
      (0, _open3d.templateInfoApi)({
        mer_temp_id: id
      }).then(function (res) {
        _this4.setData = {
          show: true,
          isOpen: !!isOpen,
          loading: false,
          id: res.data.mer_temp_id,
          name: res.data.name,
          exhibition_cover: res.data.exhibition_cover,
          exhibition_image: res.data.exhibition_image,
          sort: res.data.sort,
          max_num: res.data.max_num
        };
      });
    },
    saveAction: function saveAction() {
      var _this5 = this;
      if (!this.setData.name) {
        this.$message.error('请输入名称');
        return false;
      }
      if (!this.setData.exhibition_cover) {
        this.$message.error('请上传封面图横图');
        return false;
      }
      if (!this.setData.exhibition_image) {
        this.$message.error('请上传封面图竖图');
        return false;
      }
      if (!this.setData.max_num) {
        this.$message.error('请输入最大数量');
        return false;
      }
      this.$confirm("\u786E\u8BA4\u4FEE\u6539\u6B64\u6A21\u578B?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this5.setData.loading) return false;
        _this5.setData.loading = true;
        (0, _open3d.templateSetInfoApi)({
          mer_temp_id: _this5.setData.id,
          name: _this5.setData.name,
          exhibition_cover: _this5.setData.exhibition_cover,
          exhibition_image: _this5.setData.exhibition_image,
          price: '',
          // 暂时不写
          validity_period: '',
          // 暂时不写
          sort: _this5.setData.sort,
          max_num: _this5.setData.max_num
        }).then(function () {
          if (_this5.setData.isOpen) {
            (0, _open3d.templateSwitchApi)({
              mer_temp_id: _this5.setData.id
            }).then(function () {
              _this5.setData.loading = false;
              _this5.setData.show = false;
              _this5.$message.success('操作成功');
              _this5.getList();
            }).catch(function (message) {});
          } else {
            _this5.setData.loading = false;
            _this5.setData.show = false;
            _this5.$message.success('操作成功');
            _this5.getList();
          }
        }).catch(function (message) {
          _this5.setData.loading = false;
        });
      }).catch(function () {});
    }
  }
};