"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.array.find-index");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _aiMark = require("@/api/aiMark");
var _contract = require("@/api/contract");
var _videoDetails = _interopRequireDefault(require("@/views/aiMarketing/module/videoDetails"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    videoDetails: _videoDetails.default
  },
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      resultList: [],
      columnList: [],
      listLoading: true,
      listPropsLoading: true,
      searchQuery: {},
      venue_id: '',
      venueList: [],
      videoMakeList: [],
      planVisible: false,
      planForm: {},
      typeList: [{
        label: '人工',
        value: 1
      }],
      groupList: [],
      channelsList: [],
      taskList: [],
      ruleValidate: {
        market_make_id: [{
          required: true,
          message: "请选择计划内容",
          trigger: "blur"
        }],
        promotion_type: [{
          required: true,
          message: "请选择推送方式",
          trigger: "blur"
        }]
      },
      rowkeys: [],
      rowList: [],
      moduleVisible: false,
      moduleForm: {
        marketMake: {}
      },
      channelType: {},
      channelVisible: false,
      channelForm: {}
    }, "channelType", {}), "channList", []), "tableFrom", {
      page: 1,
      limit: 20
    }), "total", 0);
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler: function handler(newQuery, oldQuery) {
        this.venue_id = Number(newQuery.venue_id);
        this.getList();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    // let venueObj = localStorage.getItem("venueObj");
    // this.venue_id = venueObj.venue_id;
    // this.getList();
    (0, _aiMark.getChannelsTypeApi)().then(function (res) {
      var obj = {};
      for (var k in res.data) {
        var arr = [];
        for (var key in res.data[k]) {
          arr.push({
            label: res.data[k][key],
            value: key
          });
        }
        obj[k] = arr;
      }
      // console.log(obj);
      _this.channelType = obj;
    });
    this.groupInit();
  },
  methods: {
    onOpenDetails: function onOpenDetails(row) {
      this.$refs.videoViewRef.onOpenInit(row.market_make_id, "".concat(row.released_plan_date, " ").concat(row.released_plan_time));
    },
    formatCount: function formatCount(row) {
      var released_count = 0;
      var count = 0;
      this.columnList.forEach(function (item) {
        if (row[item]) count += Number(row[item]);
        if (row["".concat(item, "rele")]) released_count += Number(row["".concat(item, "rele")]);
      });
      return "".concat(released_count, "/").concat(count);
    },
    onUpd: function onUpd(row) {
      this.channelForm = {
        id: row.id,
        channels_id: row.channels_id,
        channels: row.channels,
        released_plan_time: row.released_time
      };
      this.getChannList();
      this.channelVisible = true;
    },
    onUpdSubmit: function onUpdSubmit() {
      var _this2 = this;
      (0, _aiMark.promoUpdateApi)(this.channelForm.id, this.channelForm).then(function (res) {
        _this2.channelVisible = false;
        _this2.$message.success('操作成功');
        _this2.rowkeys = [];
        _this2.rowList = [];
        _this2.getList();
      });
    },
    getChannList: function getChannList() {
      var _this3 = this;
      (0, _aiMark.groupListApi)({
        channels: this.channelForm.channels
      }).then(function (res) {
        // console.log(res);
        _this3.channList = res.data.list;
      });
    },
    onDelePlan: function onDelePlan(row) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u4EFB\u52A1?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _aiMark.promoDeleApi)({
          ids: row.id
        }).then(function (res) {
          _this4.$message.success('操作成功');
          _this4.rowkeys = [];
          _this4.rowList = [];
          _this4.getList();
        });
      }).catch(function () {});
    },
    onCellClick: function onCellClick(row, column, cell, event) {
      if (!column.label || column.label.indexOf('-') == -1) return false;
      this.rowkeys = [row.channels];
      this.rowList = [];
      this.tableFrom = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.tableFrom), {}, {
        page: 1,
        channels: row.channels,
        released_plan_date: column.label,
        venue_id: this.venue_id
      });
      this.propsNextTable();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.propsNextTable();
    },
    propsNextTable: function propsNextTable() {
      var _this5 = this;
      this.listPropsLoading = true;
      (0, _aiMark.promotionListApi)(this.tableFrom).then(function (res) {
        _this5.rowList = res.data.list;
        _this5.total = res.data.count;
        _this5.listPropsLoading = false;
        _this5.$nextTick(function () {
          if (_this5.$refs.promoTable) _this5.$refs.promoTable.doLayout();
        });
      }).catch(function (err) {
        return _this5.listPropsLoading = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.page = 1;
      this.tableFrom.limit = val;
      this.propsNextTable();
    },
    onOpenMore: function onOpenMore(row) {
      var _this6 = this;
      (0, _aiMark.marketMediaDetailApi)(row.market_make_id).then(function (res) {
        _this6.moduleForm = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.data), {}, {
          released_plan_date: row.released_plan_date,
          released_plan_time: row.released_plan_time
        });
        _this6.moduleVisible = true;
      });
    },
    onDownload: function onDownload() {
      var _this7 = this;
      fetch(this.moduleForm.extend_data.video_info.url).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        var name;
        if (_this7.moduleForm.make_type == 1) name = "".concat(_this7.moduleForm.make_title, "-\u62CD\u5356\u4F1A\u89C6\u9891");
        if (_this7.moduleForm.make_type == 2) name = "".concat(_this7.moduleForm.make_title, "-\u4E13\u573A\u89C6\u9891");
        if (_this7.moduleForm.make_type == 3 || _this7.moduleForm.make_type == 4) name = "".concat(_this7.moduleForm.make_title, "-\u62CD\u54C1\u89C6\u9891");
        var a = document.createElement("a");
        var objectUrl = window.URL.createObjectURL(blob);
        a.download = "".concat(name, ".mp4");
        a.href = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      });
    },
    onSubmit: function onSubmit() {
      var _this8 = this;
      this.$refs['formValidate'].validate(function (valid) {
        if (valid) {
          if (_this8.taskList.length <= 0) {
            _this8.$message.error('请选择账号');
            return false;
          } else {
            for (var index = 0; index < _this8.taskList.length; index++) {
              var element = _this8.taskList[index];
              element.channels_id = element.id;
              if (!element.released_plan_time) {
                _this8.$message.error('请选择发布时间');
                return false;
              }
            }
          }
          var model = {
            venue_id: _this8.venue_id,
            market_make_id: _this8.planForm.market_make_id,
            promotion_type: _this8.planForm.promotion_type,
            task_data: _this8.taskList
          };
          (0, _aiMark.batchCreateApi)(model).then(function (res) {
            _this8.$message.success('操作成功');
            _this8.getList();
            _this8.planVisible = false;
          });
        }
      });
    },
    onVenueChange: function onVenueChange() {
      this.getList();
    },
    onDele: function onDele(row) {
      var index = this.channelsList.findIndex(function (item) {
        return item.account_id == row.account_id;
      });
      this.planForm.chanIds.splice(index, 1);
      this.taskList.splice(index, 1);
    },
    onChannChange: function onChannChange() {
      var _this9 = this;
      var arr = [];
      this.planForm.chanIds.forEach(function (item) {
        var row = _this9.channelsList.find(function (ele) {
          return ele.account_id == item;
        });
        arr.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
          released_plan_time: ''
        }));
      });
      this.taskList = arr;
    },
    onTypeChange: function onTypeChange() {
      this.makeList();
    },
    makeList: function makeList() {
      var _this10 = this;
      (0, _aiMark.venueMediaListApi)({
        page: 1,
        limit: 20,
        venue_id: this.venue_id,
        make_type: this.planForm.make_type ? this.planForm.make_type : 1,
        make_tag: this.planForm.make_tag
      }).then(function (res) {
        // console.log(res);
        _this10.videoMakeList = res.data.list;
      });
    },
    groupInit: function groupInit() {
      var _this11 = this;
      (0, _aiMark.groupListApi)({
        channels: this.planForm.channels
      }).then(function (res) {
        _this11.channelsList = res.data.list;
      });
    },
    onOpen: function onOpen() {
      this.makeList();
      this.planVisible = true;
    },
    getList: function getList() {
      var _this12 = this;
      this.listLoading = true;
      // 
      (0, _aiMark.promotionStatisticsListApi)({
        venue_id: this.venue_id
      }).then(function (res) {
        // console.log(res);
        _this12.columnList = res.data.releasedPlanDate;
        var arr = [];
        res.data.list.forEach(function (item) {
          var obj = {};
          var index = arr.findIndex(function (ele) {
            return ele.channels == item.channels;
          });
          if (index != -1) {
            arr[index][item.released_plan_date] = item.count;
            arr[index]["".concat(item.released_plan_date, "rele")] = item.released_count;
          } else {
            obj.channels = item.channels;
            obj.channelsText = item.channelsText;
            obj.venue_id = item.venue_id;
            obj[item.released_plan_date] = item.count;
            obj["".concat(item.released_plan_date, "rele")] = item.released_count;
            obj.isNo = Number(item.released_count) < item.count;
            arr.push(obj);
          }
        });
        _this12.resultList = arr;
        _this12.listLoading = false;
      });
    }
  }
};