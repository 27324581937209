var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "nav display-flex item-align-center",
        class: { "justify-center": _vm.facade.nav_style === 2 },
        style: {
          backgroundColor: _vm.facade.background_color,
          paddingLeft: _vm.facade.page_padding / 2 + "px",
          height: (_vm.facade.nav_height || 96) / 2 + "px",
        },
      },
      _vm._l(_vm.content.data, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "item",
            class: { ml0: index === 0 },
            style: {
              marginLeft: _vm.facade.item_padding / 2 + "px",
              padding: "0 " + _vm.facade.item_in_padding / 2 + "px",
              paddingTop: _vm.facade.text_margin_top / 2 + "px",
              fontSize: _vm.facade.text_size / 2 + "px",
              background: _vm.activeBackground(index),
              fontWeight: _vm.facade.text_style,
              color: _vm.facade.text_color,
              height: "100%",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "img item-flex-center",
                style:
                  "overflow:hidden;margin-bottom: " +
                  _vm.facade.img_margin_bottom / 2 +
                  "px;height: " +
                  _vm.facade.img_height / 2 +
                  "px;border-radius: " +
                  _vm.facade.imgTopLeftRadius / 2 +
                  "px " +
                  _vm.facade.imgTopRightRadius / 2 +
                  "px " +
                  _vm.facade.imgBottomLeftRadius / 2 +
                  "px " +
                  _vm.facade.imgBottomRightRadius / 2 +
                  "px;",
              },
              [
                item.currUrl && _vm.content.active === index
                  ? _c("img", {
                      style:
                        "width: " +
                        _vm.facade.img_width / 2 +
                        "px;height: " +
                        _vm.facade.img_height / 2 +
                        "px;display: block;",
                      attrs: { src: item.currUrl + "!m640" },
                    })
                  : item.url
                  ? _c("img", {
                      style:
                        "width: " +
                        _vm.facade.img_width / 2 +
                        "px;height: " +
                        _vm.facade.img_height / 2 +
                        "px;display: block;",
                      attrs: { src: item.url + "!m640" },
                    })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            item.title
              ? _c("div", {
                  staticClass: "txt",
                  style: {
                    color: _vm.activeColor(index),
                    fontSize: _vm.activeFontSize(index) / 2 + "px",
                    fontWeight: _vm.activeFontWeight(index),
                    lineHeight: _vm.facade.lineHeight / 2 + "px",
                    textAlign: "center",
                  },
                  domProps: {
                    innerHTML: _vm._s(item.title.replace("__", "<br />")),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.content.active === index && item.title
              ? _c("div", {
                  staticClass: "bg",
                  style: { background: _vm.facade.text_active_color },
                })
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content",
        style: { marginTop: (_vm.facade.content_margin || 20) / 2 + "px" },
      },
      [
        _vm.showContent && _vm.showContent.dataType
          ? _c(
              "div",
              [
                _vm.showContent.name === "auction"
                  ? _c("Auction", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "product"
                  ? _c("Product", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "auctionAlbum"
                  ? _c("AuctionAlbum", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "auctionMeeting"
                  ? _c("AuctionMeeting", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "courseOnline"
                  ? _c("CourseOnline", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "courseOffline"
                  ? _c("CourseOffline", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "memberCard"
                  ? _c("MemberCard", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "appraisalExpert"
                  ? _c("AppraisalExpert", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "works"
                  ? _c("Works", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "appraisalQuestion"
                  ? _c("AppraisalQuestion", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "artiest"
                  ? _c("Artiest", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "bbs"
                  ? _c("Bbs", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "brand"
                  ? _c("Brand", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "exhibit"
                  ? _c("Exhibit", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "live"
                  ? _c("Live", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "ticket"
                  ? _c("Ticket", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "word"
                  ? _c("Word", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "work"
                  ? _c("Work", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "coupon"
                  ? _c("Coupon", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "category"
                  ? _c("Category", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "nftCollection"
                  ? _c("NftCollection", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "nftBlindBox"
                  ? _c("Nftblind", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "blindBox"
                  ? _c("BlindBox", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "templateAuctionList"
                  ? _c("templateAuctionList", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "templateAlbumList"
                  ? _c("templateAlbumList", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showContent.name === "templateMeetingList"
                  ? _c("templateMeetingList", {
                      attrs: {
                        facade: _vm.showContent.facade,
                        content: _vm.showContent.content,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }