"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    types: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  data: function data() {
    return {
      showconfig: false,
      showList: [],
      hideList: []
    };
  },
  methods: {
    setData: function setData(dataTransfer) {
      dataTransfer.setData('Text', '');
    },
    changeheaderconfig: function changeheaderconfig() {
      var _this = this;
      this.showconfig = true;
      (0, _system.getCustomHeadder)({
        types: this.types
      }).then(function (res) {
        res.data.show_list.forEach(function (item) {
          item.select = true;
        });
        res.data.hide_list.forEach(function (item) {
          item.select = false;
        });
        _this.showList = res.data.show_list;
        _this.hideList = res.data.hide_list;
      });
    },
    saveheadervonfig: function saveheadervonfig() {
      (0, _system.setCustomHeader)({
        types: this.types,
        show_data: this.showList,
        hide_data: this.hideList
      }).then(function (res) {
        window.location.reload();
      });
    },
    changeselected: function changeselected(index, type) {
      var showList = JSON.parse(JSON.stringify(this.showList));
      var hideList = JSON.parse(JSON.stringify(this.hideList));
      var row = null;
      if (type == 'show') {
        row = showList[index];
        row.select = false;
        showList.splice(index, 1);
        hideList.push(row);
      } else {
        row = hideList[index];
        row.select = true;
        hideList.splice(index, 1);
        showList.push(row);
      }
      this.showList = showList;
      this.hideList = hideList;
    }
  }
};