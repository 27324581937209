var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container goodsBox" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "货品" },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#3480F4 !important",
                      "margin-right": "20px",
                    },
                    attrs: { type: "text", icon: "el-icon-picture-outline" },
                    on: {
                      click: function ($event) {
                        return _vm.goroute("1")
                      },
                    },
                  },
                  [_vm._v("素材库")]
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { color: "#3480f4", cursor: "pointer" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                            alt: "",
                          },
                        }),
                        _vm._v("\n          配置\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("2")
                                },
                              },
                            },
                            [_vm._v("分类管理")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("3")
                                },
                              },
                            },
                            [_vm._v("属性管理")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("4")
                                },
                              },
                            },
                            [_vm._v("标签管理")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("5")
                                },
                              },
                            },
                            [_vm._v("品牌管理")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goroute("6")
                                },
                              },
                            },
                            [_vm._v("货品规格")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card goods-list-custom" }, [
        _c("div", [
          _c("div", { staticClass: "top-view" }, [
            _c(
              "div",
              {
                staticStyle: {
                  padding: "0 20px",
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo default-tabs mt10",
                    attrs: {
                      "default-active": _vm.meanuname,
                      mode: "horizontal",
                    },
                    on: { select: _vm.changeTab },
                  },
                  [
                    _c("el-menu-item", { attrs: { index: "0" } }, [
                      _vm._v("货品列表"),
                    ]),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "1" } }, [
                      _vm._v("回收站"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantErpV2ProductCreate",
                            expression: "'merchantErpV2ProductCreate'",
                          },
                        ],
                        staticStyle: { height: "32px" },
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.changeaddgoods },
                      },
                      [_vm._v("新增")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantSystemExcelImport:erp_v2_product",
                            expression:
                              "'merchantSystemExcelImport:erp_v2_product'",
                          },
                        ],
                        attrs: { type: "primary", placement: "bottom" },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [_vm._v("导入")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.batchUploadAuction },
                                  },
                                  [_vm._v("上传文件")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.downloadTemplate },
                                  },
                                  [_vm._v("下载模板")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value:
                              "merchantErpV2ProductDownloadAuctionImportTemplate",
                            expression:
                              "'merchantErpV2ProductDownloadAuctionImportTemplate'",
                          },
                        ],
                        attrs: { type: "primary", placement: "top" },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [_vm._v("导出")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.exportOrder },
                                  },
                                  [_vm._v("货品表格")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.downloadAction },
                                  },
                                  [_vm._v("上拍表格")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.getExportFileList },
                                  },
                                  [_vm._v("导出记录")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", icon: "el-icon-setting" },
                        on: { click: _vm.changeheaderconfig },
                      },
                      [_vm._v("字段配置")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { overflow: "hidden" } },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                ref: "userRef",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  height: _vm.tableHeight,
                  size: "mini",
                  border: "",
                },
                on: {
                  "header-dragend": _vm.changeColumnWidth,
                  "cell-click": _vm.cellClick,
                },
                nativeOn: {
                  paste: function ($event) {
                    return _vm.pasteExcel($event)
                  },
                },
              },
              [
                _c("template", { slot: "empty" }, [
                  _c("div", { staticClass: "tableEmpty" }, [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "36", resizable: false, align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                staticClass: "tabPop",
                                attrs: {
                                  placement: "top-start",
                                  width: "100",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "spBlock onHand",
                                      class: { check: _vm.chkName === "dan" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onHandle(
                                            "dan",
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选中本页")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "spBlock onHand",
                                      class: { check: _vm.chkName === "duo" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onHandle("duo")
                                        },
                                      },
                                    },
                                    [_vm._v("选中全部")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", {
                                  attrs: {
                                    slot: "reference",
                                    value:
                                      (_vm.chkName === "dan" &&
                                        _vm.checkedPage.indexOf(
                                          _vm.tableFrom.page
                                        ) > -1) ||
                                      _vm.chkName === "duo",
                                  },
                                  on: { change: _vm.changeType },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.id
                            ? [
                                !_vm.showfilterLine ||
                                (_vm.showfilterLine && scope.$index > 0)
                                  ? [
                                      _c("el-checkbox", {
                                        attrs: {
                                          value:
                                            _vm.checkedIds.indexOf(
                                              scope.row.id
                                            ) > -1 ||
                                            (_vm.chkName === "duo" &&
                                              _vm.noChecked.indexOf(
                                                scope.row.id
                                              ) === -1),
                                        },
                                        on: {
                                          change: function (v) {
                                            return _vm.changeOne(v, scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    type: "index",
                    width: "50",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _vm._l(_vm.headerList, function (item, index) {
                  return [
                    item.field == "audit_expert_name"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              "search-key": "audit_expert_name",
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    item.field == "auditItem.audit_status"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-key": "audit_status",
                              "search-type": "radio",
                              options: [
                                { label: "待审核", value: "0" },
                                { label: "通过", value: "1" },
                                { label: "未通过", value: "-1" },
                              ],
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.auditItem
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            scope.row.auditItem.audit_status ==
                                            0
                                              ? _c("span", [_vm._v("待审核")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.auditItem.audit_status ==
                                            1
                                              ? _c("span", [_vm._v("通过")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.auditItem.audit_status ==
                                            -1
                                              ? _c("span", [_vm._v("未通过")])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    item.field == "product_warehouse_time"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              "search-key": "product_warehouse_date",
                              "search-type": "date",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.product_warehouse_time
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row
                                                    .product_warehouse_time
                                                ) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "image"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              "search-query": _vm.searchQuery,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.image
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "demo-image__preview nowrap",
                                              },
                                              [
                                                scope.row.image.split(",")
                                                  .length > 1
                                                  ? [
                                                      _c(
                                                        "el-badge",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            value:
                                                              scope.row.image.split(
                                                                ","
                                                              ).length,
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "20px",
                                                              height: "20px",
                                                              "margin-right":
                                                                "5px",
                                                              display:
                                                                "inline-block!important",
                                                            },
                                                            attrs: {
                                                              src:
                                                                scope.row.image.split(
                                                                  ","
                                                                )[0] + "!120a",
                                                              "preview-src-list":
                                                                scope.row.image.split(
                                                                  ","
                                                                ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _c("el-image", {
                                                      staticStyle: {
                                                        width: "20px",
                                                        height: "20px",
                                                        "margin-right": "5px",
                                                        display:
                                                          "inline-block!important",
                                                      },
                                                      attrs: {
                                                        src:
                                                          scope.row.image.split(
                                                            ","
                                                          )[0] + "!120a",
                                                        "preview-src-list":
                                                          scope.row.image.split(
                                                            ","
                                                          ),
                                                      },
                                                    }),
                                              ],
                                              2
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "video_link"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              "search-query": _vm.searchQuery,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.video_link
                                        ? _c("video", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              src: scope.row.video_link,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "product_no"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "start_product_no",
                              "search-other-key": "end_product_no",
                              "search-type": "inputPacker",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "salesmanAdmin.real_name"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              "search-key": "salesman_admin_keyword",
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.contract
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.contract &&
                                                    scope.row.contract
                                                      .salesmanAdmin
                                                    ? scope.row.contract
                                                        .salesmanAdmin.real_name
                                                    : ""
                                                ) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "label"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "label_ids",
                              "search-type": "radio",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                              selectFilter: "",
                              options: _vm.labelarrOne,
                            },
                            on: {
                              changeVal: _vm.searchAction,
                              selectFilterChange: _vm.onSelectFilterChange,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm.inputname ==
                                        item.field + scope.row.id
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "selected",
                                                      rawName: "v-selected",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "edit-textareabox",
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        id: "select",
                                                        placeholder: "请选择",
                                                        multiple: "",
                                                        "value-key": "label_id",
                                                        "automatic-dropdown": true,
                                                      },
                                                      on: {
                                                        "visible-change":
                                                          function ($event) {
                                                            return _vm.selectblur(
                                                              $event,
                                                              scope.$index,
                                                              item.field,
                                                              scope.row.id,
                                                              scope.row
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .label_id_arr,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "label_id_arr",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.label_id_arr",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.labelarr,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.label_id,
                                                          attrs: {
                                                            label:
                                                              item.label_name,
                                                            value:
                                                              item.label_id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _c(
                                              "div",
                                              { staticClass: "nowrap" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(scope.row.newlabel) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "enter_warehouse_type"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "enter_warehouse_type",
                              "search-type": "radio",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                              options: [
                                { label: "委托拍卖", value: "entrust" },
                                { label: "采购入库", value: "procurement" },
                                { label: "生产入库", value: "production" },
                                { label: "调拨入库", value: "transfers" },
                                { label: "退货入库", value: "refund" },
                                { label: "其他入库", value: "other" },
                              ],
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              scope.row.enterWarehouseTypeText
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "extend_data.refund_status"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "refund_status",
                              "search-type": "radio",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                              options: [
                                { label: "已退回", value: 1 },
                                { label: "未退回", value: -1 },
                              ],
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.extend_data
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            scope.row.extend_data
                                              .refund_status == 1
                                              ? _c("span", [_vm._v("已退回")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.extend_data
                                              .refund_status == -1
                                              ? _c("span", [_vm._v("未退回")])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "warehouse"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "warehouse_id",
                              "search-type": "radio",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                              options: _vm.storeList,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          scope.row.warehouse &&
                                          scope.row.warehouse.name
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.warehouse.name
                                                  )
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    item.field == "product_name"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.product_name",
                              prop: "",
                              "search-key": "product_name",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row[item.field]) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "auction_name"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "auction_name",
                              index: index,
                              label: item.name,
                              width: item.width,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.auction_name",
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "lot"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "start_lot",
                              "search-other-key": "end_lot",
                              "search-type": "inputPacker",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  staticStyle: {
                                                    resize: "none",
                                                  },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "create_time"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "date",
                              "search-query": _vm.searchQuery,
                              "search-type": "date",
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row[item.field]) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "createAdmin"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "create_admin_keyword",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          scope.row.createAdmin
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.createAdmin
                                                      .real_name
                                                  )
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "retain_price"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "start_retain_price",
                              "search-other-key": "end_retain_price",
                              "search-type": "inputPacker",
                              index: index,
                              label: item.name,
                              width: item.width,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.retain_price",
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row[item.field]) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "history_pm_number"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "start_history_pm_number",
                              "search-other-key": "end_history_pm_number",
                              "search-type": "inputPacker",
                              index: index,
                              label: item.name,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.history_pm_number",
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row[item.field]) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "entrustUser"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.entrustUser",
                              prop: "",
                              "search-key": "entrust_keyword",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          scope.row.entrustUser
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.entrustUser
                                                      .nickname
                                                  ) + "\n                    "
                                                ),
                                                scope.row.entrustUser.real_name
                                                  ? [
                                                      _vm._v(
                                                        "\n                      (" +
                                                          _vm._s(
                                                            scope.row
                                                              .entrustUser
                                                              .real_name
                                                          ) +
                                                          ")\n                    "
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "contract"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.contract",
                              "search-key": "contract_keyword",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.contract
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.contract.contract_no
                                                ) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "content"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "content",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "remark"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "remark",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.type == "attribute"
                      ? [
                          item.name == "尺寸"
                            ? _c("ytx-table-column", {
                                key: "table" + index,
                                attrs: {
                                  "search-key": item.field,
                                  index: index,
                                  label: item.name,
                                  width: item.width,
                                  prop: "",
                                  type: item.field,
                                  "search-query": _vm.attributejson,
                                  "show-overflow-tooltip": "",
                                },
                                on: { changeVal: _vm.searchattrAction },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", {
                                            staticClass: "nowrap cur",
                                            staticStyle: { height: "100%" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                scope.row.attrjson[item.field]
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickSize(
                                                  scope.row.attrjson[
                                                    item.field
                                                  ],
                                                  scope.$index,
                                                  scope.row,
                                                  item.field
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _c("ytx-table-column", {
                                key: "table" + index,
                                attrs: {
                                  "search-key": item.field,
                                  index: index,
                                  label: item.name,
                                  width: item.width,
                                  prop: "",
                                  type: item.field,
                                  "search-query": _vm.attributejson,
                                },
                                on: { changeVal: _vm.searchattrAction },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm.inputname ==
                                            item.field + scope.row.id
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "fo",
                                                          rawName: "v-fo",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "edit-textareabox",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "edit-textareabox-span",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            scope.row.attrjson[
                                                              item.field
                                                            ] || "&nbsp;"
                                                          ),
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              scope.row
                                                                .attrjson[
                                                                item.field
                                                              ],
                                                            expression:
                                                              "scope.row.attrjson[item.field]",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "edit-textarea",
                                                        style: {
                                                          resize: "none",
                                                        },
                                                        attrs: {
                                                          id: "",
                                                          resize: "none",
                                                        },
                                                        domProps: {
                                                          value:
                                                            scope.row.attrjson[
                                                              item.field
                                                            ],
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                scope.row
                                                                  .attrjson,
                                                                item.field,
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.checkinput(
                                                                scope.$index,
                                                                "attribute",
                                                                scope.row.id,
                                                                scope.row,
                                                                item.field
                                                              )
                                                            },
                                                          ],
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.saveoneflied(
                                                              scope.$index,
                                                              "attribute",
                                                              scope.row.id,
                                                              scope.row,
                                                              item.field
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ])
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "nowrap",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.click(
                                                          scope.$index,
                                                          "attribute",
                                                          scope.row.id,
                                                          scope.row,
                                                          item.field
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          scope.row.attrjson[
                                                            item.field
                                                          ] || " "
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                        ]
                      : item.field == "currentPmAlbum"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              "search-type": "cascader",
                              "select-prop": _vm.optionsAuction,
                              options: _vm.erpAuctionList,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.currentPmAlbum",
                              prop: "",
                              "search-key": "current_pm_album_id",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          scope.row.currentPmAlbum
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.currentPmAlbum
                                                      .album_name
                                                  ) +
                                                    " -\n                    " +
                                                    _vm._s(
                                                      scope.row.currentPmAlbum
                                                        .venue.title
                                                    )
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "category"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              "search-type": "cascader",
                              "select-prop": _vm.propsMer,
                              options: _vm.merCateList,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "cate_id",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm.inputname ==
                                        item.field + scope.row.id
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "edit-textareabox",
                                                },
                                                [
                                                  _c("el-cascader", {
                                                    ref:
                                                      "category" + scope.row.id,
                                                    refInFor: true,
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      options: _vm.merCateList,
                                                      props: _vm.props,
                                                      filterable: "",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.saveoneflied(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.cate_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "cate_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.cate_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "nowrap",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.click(
                                                      scope.$index,
                                                      item.field
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      scope.row.newcate || " "
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : _vm._e(),
                  ]
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  key: "20",
                  attrs: {
                    label: "",
                    width: "36",
                    fixed: "right",
                    align: "center",
                    "search-clear": true,
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.clearSearchquery },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "TableRightBtn",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: "merchantErpV2ProductUpdate",
                                      expression:
                                        "'merchantErpV2ProductUpdate'",
                                    },
                                  ],
                                  staticStyle: { margin: "0 1px" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editgoods(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "commonedit",
                                      "class-name": "blue",
                                    },
                                  }),
                                  _vm._v("编辑"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: "merchantErpV2ProductDetail",
                                      expression:
                                        "'merchantErpV2ProductDetail'",
                                    },
                                  ],
                                  staticStyle: { margin: "0 1px" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getdetail(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "commonlook",
                                      "class-name": "blue",
                                    },
                                  }),
                                  _vm._v("查看详情"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              scope.row.pm_number <= 0 && !scope.row.is_del
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "merchantErpV2ProductBatchDelete",
                                          expression:
                                            "'merchantErpV2ProductBatchDelete'",
                                        },
                                      ],
                                      staticStyle: {
                                        margin: "0 1px",
                                        color: "red",
                                      },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "icon-class": "commondelete",
                                          color: "red",
                                        },
                                      }),
                                      _vm._v("删除"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c goodslist-page-box" },
          [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.allCheck
                      ? "已选择  " + _vm.tableData.total + "  条"
                      : "已选择  " + _vm.checkedIds.length + "  条"
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _vm.tableFrom.is_del == 0
              ? _c(
                  "div",
                  {
                    staticClass: "flex-one flex",
                    staticStyle: { "margin-left": "20px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantErpV2ProductBatchDelete",
                            expression: "'merchantErpV2ProductBatchDelete'",
                          },
                        ],
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          size: "small",
                          type: "primary",
                          disabled: !(
                            _vm.checkedIds.length > 0 || _vm.allCheck
                          ),
                          icon: "el-icon-delete",
                        },
                        on: { click: _vm.handleDeleteAll },
                      },
                      [_vm._v("批量删除")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantErpV2ProductBatchUpdateField",
                            expression:
                              "'merchantErpV2ProductBatchUpdateField'",
                          },
                        ],
                        attrs: { width: "250", trigger: "click" },
                        model: {
                          value: _vm.popVis,
                          callback: function ($$v) {
                            _vm.popVis = $$v
                          },
                          expression: "popVis",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.bactchJson.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bactchJson, "type", $$v)
                                  },
                                  expression: "bactchJson.type",
                                },
                              },
                              [_vm._v("批量增加")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2" },
                                model: {
                                  value: _vm.bactchJson.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.bactchJson, "type", $$v)
                                  },
                                  expression: "bactchJson.type",
                                },
                              },
                              [_vm._v("批量替换")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "15px" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.bactchJson.content,
                                callback: function ($$v) {
                                  _vm.$set(_vm.bactchJson, "content", $$v)
                                },
                                expression: "bactchJson.content",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "15px" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "15px" } },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "product_no" },
                                    model: {
                                      value: _vm.bactchJson.field,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.bactchJson, "field", $$v)
                                      },
                                      expression: "bactchJson.field",
                                    },
                                  },
                                  [_vm._v("编号")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "15px" } },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "auction_name" },
                                    model: {
                                      value: _vm.bactchJson.field,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.bactchJson, "field", $$v)
                                      },
                                      expression: "bactchJson.field",
                                    },
                                  },
                                  [_vm._v("拍品名称")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.attributeList, function (am, ai) {
                              return _c(
                                "div",
                                {
                                  key: "filed" + ai,
                                  staticStyle: { "margin-top": "15px" },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "attribute" + am.field },
                                      model: {
                                        value: _vm.bactchJson.field,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.bactchJson, "field", $$v)
                                        },
                                        expression: "bactchJson.field",
                                      },
                                    },
                                    [_vm._v(_vm._s(am.name))]
                                  ),
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "15px" } },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "content" },
                                    model: {
                                      value: _vm.bactchJson.field,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.bactchJson, "field", $$v)
                                      },
                                      expression: "bactchJson.field",
                                    },
                                  },
                                  [_vm._v("货品详情")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-top": "20px",
                            },
                            attrs: { type: "primary" },
                            on: { click: _vm.batchedit },
                          },
                          [_vm._v("确认")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled: !(
                                    _vm.checkedIds.length > 0 || _vm.allCheck
                                  ),
                                  icon: "el-icon-edit-outline",
                                },
                              },
                              [_vm._v("\n              批量修改")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "8px" },
                        attrs: {
                          size: "small",
                          type: "primary",
                          disabled: !(
                            _vm.checkedIds.length > 0 || _vm.allCheck
                          ),
                          icon: "el-icon-document-copy",
                        },
                        on: { click: _vm.uptoauction },
                      },
                      [_vm._v("批量上拍")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown",
                      { attrs: { type: "primary", placement: "top" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "cur",
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary" },
                          },
                          [_vm._v("审核清单")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.onSeeDetails },
                                  },
                                  [_vm._v("查看清单")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: {
                                      size: "small",
                                      type: "text",
                                      disabled: !(
                                        _vm.checkedIds.length > 0 ||
                                        _vm.allCheck
                                      ),
                                    },
                                    on: { click: _vm.onAddInven },
                                  },
                                  [_vm._v("加入清单")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "flex-one",
                    staticStyle: { "margin-left": "10px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-folder-checked",
                        },
                        on: { click: _vm.restoreAllApi },
                      },
                      [_vm._v("批量恢复")]
                    ),
                  ],
                  1
                ),
            _vm._v(" "),
            _c("el-pagination", {
              staticStyle: { "margin-right": "10px" },
              attrs: {
                "page-sizes": [10, 20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, prev, pager, next, sizes ",
                total: _vm.tableData.total,
                background: "",
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showBatchUpload
        ? _c("batchUploadTemplate", {
            ref: "batchTemplate",
            attrs: { source: "goods" },
            on: { uploadOk: _vm.getList, uploadClose: _vm.closeBatchAction },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-drawer",
            {
              attrs: {
                title: "",
                visible: _vm.showdetail,
                direction: "rtl",
                size: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showdetail = $event
                },
              },
            },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "drawer-title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "drawer-title-list",
                      class: { active: _vm.drawtype == "detail" },
                      on: {
                        click: function ($event) {
                          return _vm.changedrawtype("detail")
                        },
                      },
                    },
                    [_vm._v("\n            货品详情\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "drawer-title-list",
                      class: { active: _vm.drawtype == "log" },
                      on: {
                        click: function ($event) {
                          return _vm.changedrawtype("log")
                        },
                      },
                    },
                    [_vm._v("\n            日志记录\n          ")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "drawer-title-bg" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "drawer-body" },
                [
                  _vm.drawtype == "detail"
                    ? [
                        _c("div", { staticClass: "drawer-name" }, [
                          _vm._v(_vm._s(_vm.productdetail.product_name)),
                        ]),
                        _vm._v(" "),
                        _vm.productdetail.imagearr
                          ? _c(
                              "div",
                              { staticClass: "drawer-image-box" },
                              [
                                _vm._l(
                                  _vm.productdetail.imagearr,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "image" + index,
                                        staticClass: "drawer-image",
                                      },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: item + "!120a",
                                            "preview-src-list": [item],
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _vm.productdetail.video_link
                                  ? _c("div", { staticClass: "drawer-image" }, [
                                      _c("video", {
                                        attrs: {
                                          src: _vm.productdetail.video_link,
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "drawer-text-box" }, [
                          _c("div", { staticClass: "drawer-text" }, [
                            _vm._v("\n              货品ID："),
                            _c("span", { staticClass: "color-334" }, [
                              _vm._v(_vm._s(_vm.productdetail.id)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.productdetail.cate_parent
                            ? _c(
                                "div",
                                { staticClass: "drawer-text" },
                                [
                                  _vm._v("\n              货品分类："),
                                  _vm._l(
                                    _vm.productdetail.cate_parent,
                                    function (item, index) {
                                      return _c(
                                        "span",
                                        {
                                          key: "cateparent" + index,
                                          staticClass: "color-334",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.cate_name) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "drawer-text" }, [
                            _vm._v("\n              作者/品牌："),
                            _vm.productdetail.brand
                              ? _c("span", { staticClass: "color-334" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.productdetail.brand.brand_name || ""
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "drawer-text" }, [
                            _vm._v("\n              创建时间："),
                            _c("span", { staticClass: "color-334" }, [
                              _vm._v(_vm._s(_vm.productdetail.create_time)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "drawer-text" }, [
                            _vm._v("\n              录入人员："),
                            _vm.productdetail.createAdmin
                              ? _c("span", { staticClass: "color-334" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.productdetail.createAdmin.real_name ||
                                        ""
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "drawer-text" }, [
                            _vm._v("\n              货品属性："),
                            _vm.productdetail.attribute
                              ? _c(
                                  "span",
                                  { staticClass: "color-334" },
                                  _vm._l(
                                    _vm.productdetail.attribute,
                                    function (m, i) {
                                      return _c("span", { key: "attr_" + i }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(m + "/" || "") +
                                            "\n              "
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "drawer-text" }, [
                            _vm._v("\n              货品标签："),
                            _vm.productdetail.label &&
                            _vm.productdetail.label.length
                              ? _c(
                                  "span",
                                  { staticClass: "color-334" },
                                  _vm._l(
                                    _vm.productdetail.label,
                                    function (item, index) {
                                      return _c(
                                        "span",
                                        { key: "label_" + index },
                                        [
                                          _vm._v(
                                            "#" +
                                              _vm._s(item) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("货品介绍"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "drawer-list-right" }, [
                            _vm.productdetail.content
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.productdetail.content
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.drawtype != "log"
                    ? _c("div", { staticClass: "drawer-body-title" }, [
                        _vm._v("时间轴"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.drawtype != "log"
                    ? _c(
                        "div",
                        { staticClass: "timeline-box" },
                        _vm._l(_vm.timelog, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "timelog_" + index,
                              staticClass: "timeline",
                            },
                            [
                              _c("div", { staticClass: "timeline-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20220119/677fae73c3e1c270cae37b6f76d23f74.png",
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "timeline-content",
                                  class: index % 2 == 0 ? "left" : "right",
                                },
                                [
                                  index % 2 == 0
                                    ? _c(
                                        "div",
                                        { staticClass: "timeline-oneline" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.create_time) + " "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "timeline-title" },
                                            [_vm._v(_vm._s(item.typeText))]
                                          ),
                                          _vm._v(" "),
                                          item.category == "pm"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "timeline-title",
                                                },
                                                [
                                                  _vm._v(
                                                    "：" +
                                                      _vm._s(
                                                        item.linkItem.album
                                                          .album_name
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "timeline-oneline" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "timeline-title" },
                                            [
                                              _vm._v(
                                                _vm._s(item.typeText) + " "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          item.category == "pm"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "timeline-title",
                                                },
                                                [
                                                  _vm._v(
                                                    "：" +
                                                      _vm._s(
                                                        item.linkItem.album
                                                          .album_name
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.create_time) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                  _vm._v(" "),
                                  index % 2 == 0
                                    ? _c(
                                        "div",
                                        { staticClass: "timeline-twoline" },
                                        [
                                          item.category == "push_warehouse"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewtype(
                                                        "push_warehouse",
                                                        item.link_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "入库单 " +
                                                      _vm._s(
                                                        item.linkItem
                                                          ? item.linkItem
                                                              .order_no
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            : item.category == "out_warehouse"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewtype(
                                                        "out_warehouse",
                                                        item.link_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "出库单 " +
                                                      _vm._s(
                                                        item.linkItem
                                                          ? item.linkItem
                                                              .order_no
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            : item.category == "entrust"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewtype(
                                                        "entrust",
                                                        item.link_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "委托单 " +
                                                      _vm._s(
                                                        item.linkItem
                                                          ? item.linkItem.no
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            : item.category == "contract"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewtype(
                                                        "contract",
                                                        item.link_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "合同 " +
                                                      _vm._s(
                                                        item.linkItem
                                                          ? item.linkItem.no
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.linkItem &&
                                          item.linkItem.credentials
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewimg(
                                                        item.linkItem
                                                          .credentials
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看凭证")]
                                              )
                                            : _vm._e(),
                                          _vm._v("\n                操作人："),
                                          item.createAdmin
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.createAdmin.real_name
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "timeline-twoline" },
                                        [
                                          _vm._v("\n                操作人："),
                                          item.createAdmin
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.createAdmin.real_name
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.category == "push_warehouse"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewtype(
                                                        "push_warehouse",
                                                        item.link_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "入库单 " +
                                                      _vm._s(
                                                        item.linkItem
                                                          ? item.linkItem
                                                              .order_no
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            : item.category == "out_warehouse"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewtype(
                                                        "out_warehouse",
                                                        item.link_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "出库单 " +
                                                      _vm._s(
                                                        item.linkItem
                                                          ? item.linkItem
                                                              .order_no
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            : item.category == "entrust"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewtype(
                                                        "entrust",
                                                        item.link_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "委托单 " +
                                                      _vm._s(
                                                        item.linkItem
                                                          ? item.linkItem.no
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            : item.category == "contract"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewtype(
                                                        "contract",
                                                        item.link_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "合同 " +
                                                      _vm._s(
                                                        item.linkItem
                                                          ? item.linkItem.no
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          item.linkItem &&
                                          item.linkItem.credentials
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "timeline-handle",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.prieviewimg(
                                                        item.linkItem
                                                          .credentials
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看凭证")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.drawtype == "log"
                    ? [
                        _vm.catelog.pm
                          ? _c("div", { staticClass: "drawer-list" }, [
                              _c("div", { staticClass: "drawer-list-left" }, [
                                _vm._v("上拍记录"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "drawer-list-right" },
                                _vm._l(_vm.catelog.pm, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "pm_" + index,
                                      staticClass: "drawer-l-r-list",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.create_time)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "drawer-l-r-split" },
                                        [_vm._v("/")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item.linkItem.venue.title)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "drawer-l-r-split" },
                                        [_vm._v("/")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(item.linkItem.album.album_name)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "drawer-l-r-split" },
                                        [_vm._v("/")]
                                      ),
                                      _vm._v(" "),
                                      item.linkItem.auction.auction_status == -1
                                        ? _c("span", [_vm._v("已违约")])
                                        : item.linkItem.auction
                                            .auction_status == 1
                                        ? _c("span", [_vm._v("进行中")])
                                        : item.linkItem.auction
                                            .auction_status == 2
                                        ? _c("span", [_vm._v("预展中")])
                                        : item.linkItem.auction
                                            .auction_status == 3
                                        ? _c("span", [_vm._v("拍卖结束")])
                                        : item.linkItem.auction
                                            .auction_status == 4
                                        ? _c("span", [_vm._v("流拍")])
                                        : item.linkItem.auction
                                            .auction_status == 5
                                        ? _c("span", [_vm._v("成交待付款")])
                                        : item.linkItem.auction
                                            .auction_status == 6
                                        ? _c("span", [_vm._v("成交已付款")])
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.catelog.product
                          ? _c("div", { staticClass: "drawer-list" }, [
                              _c("div", { staticClass: "drawer-list-left" }, [
                                _vm._v("货品档案"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "drawer-list-right" },
                                _vm._l(
                                  _vm.catelog.product,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "push_" + index,
                                        staticClass: "drawer-l-r-list",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.createAdmin.real_name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.create_time)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.typeText) + " "),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.catelog.entrust
                          ? _c("div", { staticClass: "drawer-list" }, [
                              _c("div", { staticClass: "drawer-list-left" }, [
                                _vm._v("委托单"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "drawer-list-right" },
                                _vm._l(
                                  _vm.catelog.entrust,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "push_" + index,
                                        staticClass: "drawer-l-r-list",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.creator.real_name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.create_time)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.typeText) + " "),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        item.linkItem && item.linkItem.no
                                          ? _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.prieviewtype(
                                                      "entrust",
                                                      item.link_id
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(item.linkItem.no))]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.catelog.contract
                          ? _c("div", { staticClass: "drawer-list" }, [
                              _c("div", { staticClass: "drawer-list-left" }, [
                                _vm._v("合同"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "drawer-list-right" },
                                _vm._l(
                                  _vm.catelog.contract,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "push_" + index,
                                        staticClass: "drawer-l-r-list",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.createAdmin.real_name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.create_time)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.typeText) + " "),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        item.linkItem && item.linkItem.no
                                          ? _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.prieviewtype(
                                                      "contract",
                                                      item.link_id
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(item.linkItem.no))]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.catelog.enter_warehouse
                          ? _c("div", { staticClass: "drawer-list" }, [
                              _c("div", { staticClass: "drawer-list-left" }, [
                                _vm._v("入库单"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "drawer-list-right" },
                                _vm._l(
                                  _vm.catelog.enter_warehouse,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "push_" + index,
                                        staticClass: "drawer-l-r-list",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.createAdmin.real_name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.create_time)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.typeText) + " "),
                                        ]),
                                        _vm._v(" "),
                                        item.linkItem && item.linkItem.order_no
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "drawer-l-r-handle",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.prieviewtype(
                                                      "push_warehouse",
                                                      item.link_id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.linkItem.order_no)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        item.linkItem &&
                                        item.linkItem.pushWarehouse
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.linkItem.pushWarehouse
                                                    .name
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.catelog.push_shelf
                          ? _c("div", { staticClass: "drawer-list" }, [
                              _c("div", { staticClass: "drawer-list-left" }, [
                                _vm._v("入位"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "drawer-list-right" },
                                _vm._l(
                                  _vm.catelog.push_shelf,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "push_" + index,
                                        staticClass: "drawer-l-r-list",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.creator.real_name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.create_time)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.typeText) + " "),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        item.linkItem.credentials
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "drawer-l-r-handle",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.prieviewimg(
                                                      item.linkItem.credentials
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("查看凭证")]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.catelog.stock_take
                          ? _c("div", { staticClass: "drawer-list" }, [
                              _c("div", { staticClass: "drawer-list-left" }, [
                                _vm._v("盘点记录"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "drawer-list-right" },
                                _vm._l(
                                  _vm.catelog.stock_take,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "stock_" + index,
                                        staticClass: "drawer-l-r-list",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.creator.real_name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.create_time)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.linkItem.abnormal_status == 1
                                                ? "盘点异常"
                                                : "盘点正常"
                                            ) + "\n                "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        item.linkItem.credentials
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "drawer-l-r-handle",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.prieviewimg(
                                                      item.linkItem.credentials
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("查看存证")]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.catelog.out_warehouse
                          ? _c("div", { staticClass: "drawer-list" }, [
                              _c("div", { staticClass: "drawer-list-left" }, [
                                _vm._v("出库单"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "drawer-list-right" },
                                _vm._l(
                                  _vm.catelog.out_warehouse,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "out_" + index,
                                        staticClass: "drawer-l-r-list",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.creator.real_name)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.create_time)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.typeText) + " "),
                                        ]),
                                        _vm._v(" "),
                                        item.linkItem && item.linkItem.order_no
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "drawer-l-r-handle",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.prieviewtype(
                                                      "out_warehouse",
                                                      item.link_id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.linkItem.order_no)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "drawer-l-r-split" },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        item.linkItem.outWarehouse
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.linkItem.outWarehouse
                                                    .name
                                                ) + "\n                "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-drawer",
            {
              attrs: {
                title: _vm.title || "添加货品",
                visible: _vm.showgoodsadd,
                direction: "rtl",
                size: "1000px",
                "before-close": _vm.beforeclose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showgoodsadd = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.fullscreenLoading,
                      expression: "fullscreenLoading",
                    },
                  ],
                  ref: "formValidate",
                  staticClass: "formValidate mt20",
                  attrs: {
                    rules: _vm.ruleValidate,
                    model: _vm.formValidate,
                    "label-width": "120px",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "货品名称：",
                                prop: "product_name",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: { placeholder: "请输入货品名称" },
                                model: {
                                  value: _vm.formValidate.product_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "product_name",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.product_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "拍品名称：",
                                prop: "auction_name",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: { placeholder: "请输入拍品名称" },
                                model: {
                                  value: _vm.formValidate.auction_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "auction_name",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.auction_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "货品编号：",
                                prop: "product_no",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: { placeholder: "请输入货品编号" },
                                model: {
                                  value: _vm.formValidate.product_no,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "product_no",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.product_no",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "货品分类：", prop: "cate_id" } },
                            [
                              _c("el-cascader", {
                                staticClass: "selWidth",
                                attrs: {
                                  options: _vm.merCateList,
                                  props: _vm.props,
                                  filterable: "",
                                  clearable: "",
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.formValidate.cate_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "cate_id", $$v)
                                  },
                                  expression: "formValidate.cate_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "属性", required: "" } },
                            _vm._l(_vm.attrList, function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: "attrr" + index,
                                  style: index > 0 ? "margin-top:20px" : "",
                                  attrs: { label: item.attr_name },
                                },
                                [
                                  item.attr_type == 1
                                    ? [
                                        _c("el-input", {
                                          attrs: { placeholder: "请输入" },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                      ]
                                    : item.attr_type == 2
                                    ? [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          },
                                          _vm._l(
                                            item.attrValue,
                                            function (m, i) {
                                              return _c(
                                                "el-radio",
                                                {
                                                  key: "attrv" + i,
                                                  attrs: {
                                                    label: m.attr_value_id,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(m.attr_value_name)
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          },
                                          _vm._l(
                                            item.attrValue,
                                            function (m, i) {
                                              return _c(
                                                "el-checkbox",
                                                {
                                                  key: "mattr" + i,
                                                  attrs: {
                                                    label: m.attr_value_id,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(m.attr_value_name)
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                ],
                                2
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "货品标签：", prop: "label" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    multiple: "",
                                  },
                                  model: {
                                    value: _vm.formValidate.label,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "label", $$v)
                                    },
                                    expression: "formValidate.label",
                                  },
                                },
                                _vm._l(_vm.labelarr, function (item) {
                                  return _c("el-option", {
                                    key: item.label_id,
                                    attrs: {
                                      label: item.label_name,
                                      value: item.label_id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.title == "编辑货品"
                        ? _c(
                            "el-col",
                            _vm._b({}, "el-col", _vm.grid2, false),
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "保留价：",
                                    prop: "retain_price",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      disabled: "",
                                      placeholder: "请输入货品保留价",
                                    },
                                    model: {
                                      value: _vm.formValidate.retain_price,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "retain_price",
                                          $$v
                                        )
                                      },
                                      expression: "formValidate.retain_price",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "wrap",
                              attrs: {
                                label: "拍品图片\n(正式图)",
                                prop: "auction_image",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _vm._l(
                                    _vm.formValidate.auction_image,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "pictrue",
                                          attrs: { draggable: "false" },
                                          on: {
                                            dragstart: function ($event) {
                                              return _vm.handleDragStart(
                                                $event,
                                                item
                                              )
                                            },
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDragOver(
                                                $event,
                                                item
                                              )
                                            },
                                            dragenter: function ($event) {
                                              return _vm.handleDragEnter(
                                                $event,
                                                item
                                              )
                                            },
                                            dragend: function ($event) {
                                              return _vm.handleDragEnd(
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", { attrs: { src: item } }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-error btndel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemove(index)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "uploadCont",
                                      attrs: { title: "750*750px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("3")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "wrap",
                              attrs: { label: "货品图片\n(合同图)" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _vm._l(
                                    _vm.formValidate.slider_image,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "pictrue",
                                          attrs: { draggable: "false" },
                                          on: {
                                            dragstart: function ($event) {
                                              return _vm.handleDragStart(
                                                $event,
                                                item
                                              )
                                            },
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDragOver(
                                                $event,
                                                item
                                              )
                                            },
                                            dragenter: function ($event) {
                                              return _vm.handleDragEnter(
                                                $event,
                                                item
                                              )
                                            },
                                            dragend: function ($event) {
                                              return _vm.handleDragEnd(
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", { attrs: { src: item } }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-error btndel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemove(index)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.formValidate.slider_image.length < 10
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "uploadCont",
                                          attrs: { title: "750*750px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "upLoadPicBox",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.modalPicTap("2")
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "upLoad" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-camera cameraIconfont",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "主图视频：",
                                prop: "video_link",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "50%" },
                                attrs: { placeholder: "请输入视频链接" },
                                model: {
                                  value: _vm.videoLink,
                                  callback: function ($$v) {
                                    _vm.videoLink = $$v
                                  },
                                  expression: "videoLink",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "uploadVideo",
                                  attrs: {
                                    type: "primary",
                                    icon: "ios-cloud-upload-outline",
                                  },
                                  on: { click: _vm.zh_uploadFile_change },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.videoLink ? "确认添加" : "上传视频"
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.upload.videoIng
                                ? _c("el-progress", {
                                    attrs: {
                                      percentage: _vm.progress,
                                      "text-inside": true,
                                      "stroke-width": 26,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.formValidate.video_link
                                ? _c(
                                    "div",
                                    { staticClass: "iview-video-style mt20" },
                                    [
                                      _c(
                                        "video",
                                        {
                                          staticStyle: {
                                            width: "200px",
                                            "border-radius": "10px",
                                          },
                                          attrs: {
                                            src: _vm.formValidate.video_link,
                                            controls: "controls",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  您的浏览器不支持 video 标签。\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "mark" }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-delete iconv",
                                        on: { click: _vm.delVideo },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 23 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "货品详情：" } },
                            [
                              _c("el-input", {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  placeholder: "请输入货品详情",
                                  size: "normal",
                                  rows: "8",
                                  type: "textarea",
                                },
                                model: {
                                  value: _vm.formValidate.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "content", $$v)
                                  },
                                  expression: "formValidate.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "submission",
                          staticStyle: { width: "200px" },
                          attrs: {
                            loading: _vm.loading,
                            type: "primary",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleSubmit("formValidate")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-drawer",
            {
              attrs: {
                visible: _vm.showconfig,
                direction: "rtl",
                size: "450px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showconfig = $event
                },
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.saveheadervonfig },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [
                    _vm._l(_vm.showList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "show_" + index,
                          staticClass: "draw-list margin-t-16",
                          attrs: { draggable: "false" },
                        },
                        [
                          _c("i", {
                            staticClass: "sign",
                            class: { "el-icon-top": index > 0 },
                            on: {
                              click: function ($event) {
                                return _vm.changesort(index)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("el-checkbox", {
                            staticClass: "checkbox",
                            on: {
                              change: function ($event) {
                                return _vm.changeselected(index, "show")
                              },
                            },
                            model: {
                              value: item.select,
                              callback: function ($$v) {
                                _vm.$set(item, "select", $$v)
                              },
                              expression: "item.select",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.hideList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "hide_" + index,
                          staticClass: "draw-list margin-t-16",
                          attrs: { draggable: "false" },
                        },
                        [
                          _c("el-checkbox", {
                            staticClass: "checkbox",
                            on: {
                              change: function ($event) {
                                return _vm.changeselected(index, "hide")
                              },
                            },
                            model: {
                              value: item.select,
                              callback: function ($$v) {
                                _vm.$set(item, "select", $$v)
                              },
                              expression: "item.select",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.urlshow
            ? _c("el-image-viewer", {
                staticStyle: { "z-index": "9999" },
                attrs: {
                  "on-close": function () {
                    _vm.urlshow = false
                  },
                  "url-list": _vm.urlshowList,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "",
                visible: _vm.showpreviewcontract,
                width: "1200px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showpreviewcontract = $event
                },
              },
            },
            [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.html_content) } }),
              _vm._v(" "),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.html_attachment_content) },
              }),
              _vm._v(" "),
              _vm._l(_vm.attachment, function (item, index) {
                return _c(
                  "div",
                  { key: "attachment" + index },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadf(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.attachment_name))]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "委托标的清单",
                visible: _vm.inventoryDetailFlag,
                width: "1200px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.inventoryDetailFlag = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _vm.inventoryDetailAll.status == -1
                    ? _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { color: "red" },
                              attrs: { span: 12, offset: 0 },
                            },
                            [
                              _vm._v(
                                "审核失败: " +
                                  _vm._s(_vm.inventoryDetailAll.remark)
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v("委托方："),
                        _vm.inventoryDetailAll.userEntrust
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.inventoryDetailAll.userEntrust
                                      .entrust_contact_company
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "单据时间：" +
                            _vm._s(_vm.inventoryDetailAll.create_time)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v("入库时间："),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "单据编号：" + _vm._s(_vm.inventoryDetailAll.order_no)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.inventoryDetailAll.storeCategory,
                    function (pitem, index) {
                      return [
                        _c(
                          "el-table",
                          {
                            key: "cate" + index,
                            staticStyle: { margin: "20px 0" },
                            attrs: { size: "mini", data: pitem.orderProduct },
                          },
                          [
                            _c("template", { slot: "empty" }, [
                              _c("div", { staticClass: "tableEmpty" }, [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "tableDiv" }, [
                                  _vm._v("暂无数据"),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              { attrs: { label: "分类", width: "80" } },
                              [_c("div", [_vm._v(_vm._s(pitem.cate_name))])]
                            ),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "图片", width: "50" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "36px",
                                                height: "36px",
                                              },
                                              attrs: {
                                                src: scope.row.product.image,
                                                "preview-src-list": [
                                                  scope.row.product.image,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "名称",
                                prop: "product.product_name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "保留价", prop: "retain_price" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.retain_price) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "作者", prop: "product.brand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.product.brand
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.product.brand
                                                      .brand_name
                                                  ) +
                                                  "\n                "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "属性",
                                  prop: "order_pay_amount",
                                  align: "center",
                                },
                              },
                              _vm._l(pitem.attribute, function (item, index) {
                                return _c("el-table-column", {
                                  key: "ps_" + index,
                                  attrs: { label: item.attr_name },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._l(
                                              scope.row.product.attrInfo,
                                              function (m, i) {
                                                return [
                                                  m.attr_id == item.attr_id
                                                    ? _c(
                                                        "div",
                                                        {
                                                          key:
                                                            "ps_" + index + i,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                m.attr_value_name
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              }
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              { attrs: { label: "成交收费", align: "center" } },
                              _vm._l(
                                _vm.inventoryConfig.price.sold_price,
                                function (item, index) {
                                  return _c("el-table-column", {
                                    key: "s_" + index,
                                    attrs: { label: item.name },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.price_data[0][
                                                      index
                                                    ].number
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              {
                                attrs: { label: "未成交收费", align: "center" },
                              },
                              _vm._l(
                                _vm.inventoryConfig.price.unsold_price,
                                function (item, index) {
                                  return _c("el-table-column", {
                                    key: "s_" + index,
                                    attrs: { label: item.name },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.price_data[1][
                                                      index
                                                    ].number
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-table-column",
                              { attrs: { label: "其他费用", align: "center" } },
                              _vm._l(
                                _vm.inventoryConfig.price.other_price,
                                function (item, index) {
                                  return _c("el-table-column", {
                                    key: "s_" + index,
                                    attrs: { label: item.name },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.price_data[2][
                                                      index
                                                    ].number
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _vm.inventoryDetailAll.creator
                        ? _c("el-col", { attrs: { span: 6 } }, [
                            _vm._v(
                              "制单人：" +
                                _vm._s(_vm.inventoryDetailAll.creator.real_name)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.inventoryDetailAll.auditAdmin
                        ? _c("el-col", { attrs: { span: 6 } }, [
                            _vm._v(
                              "审批人：" +
                                _vm._s(
                                  _vm.inventoryDetailAll.auditAdmin.real_name
                                )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.inventoryDetailAll.manageAdmin
                        ? _c("el-col", { attrs: { span: 6 } }, [
                            _vm._v(
                              "业务负责人：" +
                                _vm._s(
                                  _vm.inventoryDetailAll.manageAdmin.real_name
                                )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.inventoryDetailAll.warehouse
                        ? _c("el-col", { attrs: { span: 6 } }, [
                            _vm._v(
                              "仓库：" +
                                _vm._s(_vm.inventoryDetailAll.warehouse.name)
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { title: "", visible: _vm.showinto, width: "1000px" },
              on: {
                "update:visible": function ($event) {
                  _vm.showinto = $event
                },
              },
            },
            [
              _c(
                "div",
                { attrs: { id: "printorder" } },
                [
                  _c("div", { staticClass: "detail-box" }, [
                    _c("div", { staticClass: "detail-left" }, [
                      _vm._v("货品入库单"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-right" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "data:image/png;base64," +
                            _vm.detailjson.bar_code_image,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-code" }, [
                        _vm._v(_vm._s(_vm.detailjson.bar_code)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-list" }, [
                    _vm.detailjson.pushWarehouse
                      ? _c("div", [
                          _vm._v(
                            "\n            仓库：" +
                              _vm._s(_vm.detailjson.pushWarehouse.name) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("入库时间：" + _vm._s(_vm.detailjson.create_time)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                      _vm._v("单据编号：" + _vm._s(_vm.detailjson.order_no)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-list" }, [
                    _c("div", [
                      _vm._v("入库类型：" + _vm._s(_vm.detailjson.typeText)),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v("运输方式：")]),
                    _vm._v(" "),
                    _c("div", [_vm._v("送货人：")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ptd",
                      staticStyle: {
                        "margin-top": "20px",
                        "font-weight": "bold",
                        "border-bottom": "1px solid #eeeeee",
                        "padding-bottom": "10px",
                      },
                    },
                    [
                      _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v("图片"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "15%" } }, [
                        _vm._v("拍品名称"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v("作者"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v("分类"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "13%" } }, [
                        _vm._v("标签"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v("货架"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "20%" } }, [
                        _vm._v("属性"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.detailjson.orderProduct, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "order" + index,
                        staticClass: "ptd",
                        staticStyle: {
                          "margin-top": "20px",
                          "border-bottom": "1px solid #eeeeee",
                          "padding-bottom": "10px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { width: "10%" } },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: item.productItem.image + "!120a",
                                "preview-src-list": [item.productItem.image],
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "15%" } }, [
                          _vm._v(_vm._s(item.productItem.product_name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "10%" } }, [
                          item.productItem.brand
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(item.productItem.brand.brand_name)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "10%" } }, [
                          item.productItem.cate_parent
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      item.productItem.cate_parent[0].cate_name
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "13%" } }, [
                          item.productItem &&
                          item.productItem.label &&
                          item.productItem.label.length
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.productItem.label.join(",")) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "10%" } }, [
                          item.shelf
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.shelf.shelf_no) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "20%" } },
                          _vm._l(item.productItem.attrInfo, function (m, i) {
                            return _c("span", { key: "oattr_" + i }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(m.attr_name) +
                                  ":" +
                                  _vm._s(m.attr_value_name) +
                                  "\n            "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "detail-list",
                      staticStyle: { "margin-top": "20px" },
                    },
                    [
                      _vm.detailjson.creator
                        ? _c("div", [
                            _vm._v(
                              "\n            制单人：" +
                                _vm._s(_vm.detailjson.creator.real_name) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.detailjson.manageAdmin
                        ? _c("div", [
                            _vm._v(
                              "\n            业务负责人：" +
                                _vm._s(_vm.detailjson.manageAdmin.real_name) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [_vm._v("入库员签字：")]),
                    ]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "default" },
                      on: { click: _vm.bindPrintorder },
                    },
                    [_vm._v("打印")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { title: "", visible: _vm.showout, width: "1000px" },
              on: {
                "update:visible": function ($event) {
                  _vm.showout = $event
                },
              },
            },
            [
              _c(
                "div",
                { attrs: { id: "printorderout" } },
                [
                  _c("div", { staticClass: "detail-box" }, [
                    _c("div", { staticClass: "detail-left" }, [
                      _vm._v("货品出库单"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-right" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "data:image/png;base64," +
                            _vm.detailjson.bar_code_image,
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "detail-code" }, [
                        _vm._v(_vm._s(_vm.detailjson.bar_code)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-list" }, [
                    _vm.detailjson.pushWarehouse
                      ? _c("div", [
                          _vm._v(
                            "\n            仓库：" +
                              _vm._s(_vm.detailjson.pushWarehouse.name) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("出库时间：" + _vm._s(_vm.detailjson.create_time)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                      _vm._v("单据编号：" + _vm._s(_vm.detailjson.order_no)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-list" }, [
                    _c("div", [
                      _vm._v("出库类型：" + _vm._s(_vm.detailjson.typeText)),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v("运输方式：")]),
                    _vm._v(" "),
                    _c("div", [_vm._v("送货人：")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ptd",
                      staticStyle: {
                        "margin-top": "20px",
                        "font-weight": "bold",
                        "border-bottom": "1px solid #eeeeee",
                        "padding-bottom": "10px",
                      },
                    },
                    [
                      _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v("图片"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "15%" } }, [
                        _vm._v("拍品名称"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v("作者"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v("分类"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "13%" } }, [
                        _vm._v("标签"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v("货架"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { width: "20%" } }, [
                        _vm._v("属性"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.detailjson.orderProduct, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "ptd" + index,
                        staticClass: "ptd",
                        staticStyle: {
                          "margin-top": "20px",
                          "border-bottom": "1px solid #eeeeee",
                          "padding-bottom": "10px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { width: "10%" } },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: item.productItem.image,
                                "preview-src-list": [item.productItem.image],
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "15%" } }, [
                          _vm._v(_vm._s(item.productItem.product_name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "10%" } }, [
                          item.productItem.brand
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(item.productItem.brand.brand_name)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "10%" } }, [
                          item.productItem.cate_parent
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      item.productItem.cate_parent[0].cate_name
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "13%" } }, [
                          item.productItem &&
                          item.productItem.label &&
                          item.productItem.label.length
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.productItem.label.join(",")) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { width: "10%" } }, [
                          item.shelf
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.shelf.shelf_no) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "20%" } },
                          _vm._l(item.productItem.attrInfo, function (m, i) {
                            return _c("span", { key: "oattr_" + i }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(m.attr_name) +
                                  ":" +
                                  _vm._s(m.attr_value_name) +
                                  "\n            "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "detail-list",
                      staticStyle: { "margin-top": "20px" },
                    },
                    [
                      _vm.detailjson.creator
                        ? _c("div", [
                            _vm._v(
                              "\n            制单人：" +
                                _vm._s(_vm.detailjson.creator.real_name) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.detailjson.manageAdmin
                        ? _c("div", [
                            _vm._v(
                              "\n            业务负责人：" +
                                _vm._s(_vm.detailjson.manageAdmin.real_name) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [_vm._v("出库员签字：")]),
                    ]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.bindPrintorder("out")
                        },
                      },
                    },
                    [_vm._v("打印")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SetColumn", {
        ref: "setColumnEL",
        attrs: { types: "erp_v2_product_list" },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showVisible,
            width: "750px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _vm._l(_vm.formArr, function (item, ind) {
                return _c(
                  "div",
                  { key: ind, staticClass: "formLine" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { label: "输入:" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.long,
                                  callback: function ($$v) {
                                    _vm.$set(item, "long", $$v)
                                  },
                                  expression: "item.long",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("长(L)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.width,
                                  callback: function ($$v) {
                                    _vm.$set(item, "width", $$v)
                                  },
                                  expression: "item.width",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("宽(W)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.height,
                                  callback: function ($$v) {
                                    _vm.$set(item, "height", $$v)
                                  },
                                  expression: "item.height",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("高(H)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "185px" },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.diam,
                                  callback: function ($$v) {
                                    _vm.$set(item, "diam", $$v)
                                  },
                                  expression: "item.diam",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("直径(D)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { label: "展示:" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("el-input", {
                              staticClass: "flex-one sortCol",
                              staticStyle: { "margin-right": "5px" },
                              model: {
                                value: item.sizeStr,
                                callback: function ($$v) {
                                  _vm.$set(item, "sizeStr", $$v)
                                },
                                expression: "item.sizeStr",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex align-items-c" },
                              [
                                !item.height && !item.diam
                                  ? _c("div", [_vm._v("平尺小数点:")])
                                  : _vm._e(),
                                _vm._v(" "),
                                !item.height && !item.diam
                                  ? _c(
                                      "el-select",
                                      {
                                        staticClass: "sortCol",
                                        staticStyle: {
                                          height: "36px",
                                          width: "80px",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onChangeSize(item)
                                          },
                                        },
                                        model: {
                                          value: item.toNum,
                                          callback: function ($$v) {
                                            _vm.$set(item, "toNum", $$v)
                                          },
                                          expression: "item.toNum",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: 0, label: "0位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 1, label: "1位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 2, label: "2位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 3, label: "3位" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", [_vm._v("数量:")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  staticStyle: {
                                    height: "36px",
                                    width: "40px",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSize(item)
                                    },
                                  },
                                  model: {
                                    value: item.vice,
                                    callback: function ($$v) {
                                      _vm.$set(item, "vice", $$v)
                                    },
                                    expression: "item.vice",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-right": "5px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        !item.height && !item.diam ? "幅" : "件"
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("el-switch", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSize(item)
                                    },
                                  },
                                  model: {
                                    value: item.showVice,
                                    callback: function ($$v) {
                                      _vm.$set(item, "showVice", $$v)
                                    },
                                    expression: "item.showVice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("继续增加")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex justify-e" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }