"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _guide = _interopRequireDefault(require("./guide.json"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      is_close: true,
      pagesData: [],
      step: 0,
      ctx: '',
      position: {
        top: '223px',
        left: '205px',
        width: '87px',
        height: '92px',
        src: 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide001.png'
      },
      masks: [{
        id: 'guide001',
        index: -9,
        top: '223px',
        left: '205px',
        width: '87px',
        height: '92px',
        src: 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide001.png'
      }, {
        id: 'guide002',
        index: -9,
        top: '223px',
        left: '205px',
        width: '0',
        height: '0',
        src: 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide002.png',
        offsetX: -87,
        offsetY: 0
      }, {
        id: 'guide003',
        index: 1,
        top: '223px',
        left: '205px',
        width: '60px',
        height: '52px',
        src: 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide003.png',
        offsetX: -13,
        offsetY: -10
      }, {
        id: 'guide004',
        index: -2,
        top: '223px',
        left: '205px',
        width: '370px',
        height: '210px',
        src: 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide004.png',
        offsetX: 13,
        offsetY: 13
      }, {
        id: 'guide003',
        index: 1,
        top: '223px',
        left: '205px',
        width: '48px',
        height: '35px',
        src: 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide005.png',
        offsetX: -5,
        offsetY: 35
      }, {
        id: 'guide003',
        index: 1,
        top: '223px',
        left: '205px',
        width: '48px',
        height: '80px',
        src: 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide006.png',
        offsetX: -5,
        offsetY: 72
      }, {
        id: 'guide004',
        index: -2,
        top: '223px',
        left: '205px',
        width: '370px',
        height: '210px',
        src: 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide007.png',
        offsetX: 13,
        offsetY: 13
      }]
    };
  },
  inject: ['self'],
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    width: function width() {
      return document.body.offsetWidth;
    },
    height: function height() {
      return document.body.offsetHeight;
    },
    guideNext: function guideNext() {
      if (this.step < 6) {
        return 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide-next.png';
      } else {
        return 'http://qmxq.oss-cn-hangzhou.aliyuncs.com/guide/guide-end.png';
      }
    }
  },
  /**
   * 页面加载执行
   * offsetLeft
   * offsetTop
   * offsetWidth
   * offsetHeight
   * @return {[type]} [description]
   */
  mounted: function mounted() {
    var is_guide = window.localStorage.getItem('is_guide');
    if (!is_guide) {
      this.onSetPage();
    }
  },
  methods: {
    onSetPage: function onSetPage() {
      var _this = this;
      this.is_close = false;
      var el = document.getElementById('guide002');
      el.children[0].style.display = 'none';
      setTimeout(function () {
        _this.pagesData = JSON.parse(JSON.stringify(_this.$store.state.finish.pages));
        _this.$nextTick(function () {
          _this.$store.commit('finish/setPagesData', _guide.default);
        });
      }, 1000);
      this.setPosition();
    },
    toStep: function toStep() {
      var _this2 = this;
      if (this.step == 1) {
        var el = document.getElementById('guide002');
        el.children[0].style.display = 'block';
      }
      if (this.step < 6) {
        this.step++;
        this.setPosition();
      } else {
        setTimeout(function () {
          _this2.$nextTick(function () {
            _this2.$store.commit('finish/setPagesData', _this2.pagesData);
          });
        }, 300);
        this.is_close = true;
        window.localStorage.setItem('is_guide', 1000);
      }
    },
    toStop: function toStop() {
      var _this3 = this;
      setTimeout(function () {
        _this3.$nextTick(function () {
          _this3.$store.commit('finish/setPagesData', _this3.pagesData);
          document.getElementById('min-page').style.display = 'block';
        });
      }, 300);
      this.is_close = true;
      window.localStorage.setItem('is_guide', 1000);
    },
    setPosition: function setPosition(argument) {
      var _this4 = this;
      var mask = this.masks[this.step];
      var id = mask.id;
      var index = mask.index;
      if (index !== -9) {
        this.$store.commit('finish/setIndex', index);
      }
      this.$nextTick(function () {
        var el = document.getElementById(id);
        var position = el.getBoundingClientRect();
        _this4.position.top = position.y + mask.offsetY + 'px';
        _this4.position.left = position.x + mask.offsetX + 'px';
      });
    }
  }
};