var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", "label-width": "0px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: " " } },
                    [
                      _c("span", { staticClass: "fWeight500 ml10" }, [
                        _vm._v("时间："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.tableFrom.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "date", $$v)
                            },
                            expression: "tableFrom.date",
                          },
                        },
                        _vm._l(_vm.fromList.fromTxt, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text))]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: {
                          width: "250px",
                          position: "relative",
                          top: "2px",
                        },
                        attrs: {
                          "value-format": "yyyy/MM/dd",
                          format: "yyyy/MM/dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "自定义时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c("span", { staticClass: "fWeight500 ml10" }, [
                        _vm._v("名称："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200",
                          attrs: { placeholder: "作品名称", clearable: "" },
                          model: {
                            value: _vm.tableFrom.store_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "store_name", $$v)
                            },
                            expression: "tableFrom.store_name",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "fWeight500 ml20" }, [
                        _vm._v("卖家："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200",
                          attrs: {
                            placeholder: "卖家手机号/昵称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tableFrom.seller_keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "seller_keyword", $$v)
                            },
                            expression: "tableFrom.seller_keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "fWeight500 ml20" }, [
                        _vm._v("买家："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200",
                          attrs: {
                            placeholder: "买家手机号/昵称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tableFrom.buyer_keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "buyer_keyword", $$v)
                            },
                            expression: "tableFrom.buyer_keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.exportOrder },
                        },
                        [_vm._v("导出")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: { click: _vm.getExportFileList },
                        },
                        [_vm._v("导出记录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "order-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "作品信息", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "work-img" }, [
                        _c("img", {
                          attrs: { src: row.image + "!120a", alt: "" },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "user-info flex-one ml10",
                          staticStyle: { overflow: "hidden" },
                        },
                        [
                          _c("div", { staticClass: "fWeight500" }, [
                            _vm._v(_vm._s(row.product.store_name)),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "类型", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _vm._v(
                        _vm._s(
                          row.product.product_type == 8 ? "数字藏品" : "盲盒"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "作品编号", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _vm._v(_vm._s(row.issue_number ? row.issue_number : "")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "链类型", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _vm._v(_vm._s(row.l_type)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "卖家", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row.sellUser)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(row.sellUser ? row.sellUser.nickname : "")
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _vm._v(_vm._s(row.sellUser ? row.sellUser.phone : "")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "买家", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row.buyUser)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(row.buyUser ? row.buyUser.nickname : "")
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _vm._v(_vm._s(row.buyUser ? row.buyUser.phone : "")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "交易价格", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.price))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.merId == 339490 || _vm.merId == 23
            ? [
                _c("el-table-column", {
                  attrs: { label: "直推佣金", "min-width": "80" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  disabled: !!!scope.row.one_brokerage_remark,
                                  content:
                                    scope.row.one_brokerage_remark || "无",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", { attrs: { type: "text" } }, [
                                  _vm._v(_vm._s(scope.row.one_brokerage)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1209251567
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "推荐人", "min-width": "120" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.buySpreadUser
                            ? [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.row.buySpreadUser.nickname)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.buySpreadUser.phone)),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "手续费", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.fee))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付渠道", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(_vm._s(row.storeOrder.pay_type_name))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "交易时间", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.storeOrder.pay_time))])]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c("el-pagination", {
            staticStyle: { "text-align": "right" },
            attrs: {
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("NftSaleTmp", { ref: "nftSaleTmpEL" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }