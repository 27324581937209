"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _modules = _interopRequireDefault(require("./modules"));
var _attribute = _interopRequireDefault(require("../decoration/modules/footer/attribute"));
var _contract = require("@/api/contract");
var _auction = require("@/api/auction");
var _product = require("@/api/product");
var _cms = require("@/api/cms");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    TemplatePage: _modules.default.templatePage.element,
    TemplateList: _modules.default.templateList.element,
    Title: _modules.default.templateTitle.element,
    TemplateFooter: _modules.default.templateFooter.element,
    TemplateImg: _modules.default.templateImg.element,
    TemplateTab: _modules.default.templateTab.element,
    TemplateButton: _modules.default.templateButton.element,
    TemplateTag: _modules.default.templateTag.element,
    TemplateCoupon: _modules.default.templateCoupon.element,
    footerAttribute: _attribute.default
  },
  data: function data() {
    return {
      modules: _modules.default,
      queryParams: {
        page: 1,
        limit: 20,
        type: 'template_package'
      },
      list: [{
        name: '微信小程序首页',
        type: 'templateHomePage',
        page: '/pages/home/home',
        link: '/merchant/diy/page?type=templateHomePage',
        id: '',
        is_release: null,
        isOpen: 1
      }, {
        name: '抖音小程序首页',
        type: 'templateHomePageTiktok',
        page: '/pages/home/home',
        link: '/merchant/diy/page?type=templateHomePageTiktok',
        id: '',
        is_release: null,
        isOpen: 0
      }, {
        name: 'APP首页',
        type: 'templateHomePageApp',
        page: '/pages/home/home',
        link: '/merchant/diy/page?type=templateHomePageApp',
        id: '',
        is_release: null,
        isOpen: 0
      }, {
        name: '专场列表页',
        type: 'templateAlbumList',
        page: '',
        link: '/merchant/template/page?type=templateAlbumList',
        id: '',
        is_release: null
      }, {
        name: '专场详情页',
        type: 'templateAlbumDetail',
        page: '/pages/albumDetail/index?id=',
        link: '/merchant/template/page?type=templateAlbumDetail',
        id: '',
        is_release: null
      }, {
        name: '拍品列表页',
        type: 'templateAuctionList',
        page: '',
        link: '/merchant/template/page?type=templateAuctionList',
        id: '',
        is_release: null
      }, {
        name: '拍品详情页',
        type: 'templateAuctionDetail',
        page: '/pages/auctionDetail/index?id=',
        link: '/merchant/template/page?type=templateAuctionDetail',
        id: '',
        is_release: null
      }, {
        name: '商品列表页',
        type: 'templateProductList',
        page: '',
        link: '/merchant/template/page?type=templateProductList',
        id: '',
        is_release: null
      }, {
        name: '商品详情页',
        type: 'templateProductDetail',
        page: '/pages/productDetail/index?id=',
        link: '/merchant/template/page?type=templateProductDetail',
        id: '',
        is_release: null
      }, {
        name: '文章详情页',
        type: 'templateWordDetail',
        page: '/pages/wordDetail/index?id=',
        link: '/merchant/template/page?type=templateWordDetail',
        id: '',
        is_release: null
      }, {
        name: '个人中心',
        type: 'templatePersonalCenter',
        page: '',
        link: '/merchant/mine/page',
        id: '',
        is_release: null
      }, {
        name: '登录页',
        type: 'templateLogin',
        page: '',
        link: '/merchant/template/page?type=templateLogin',
        id: '',
        is_release: null
      }],
      listLoading: true,
      total: 0,
      showImg: false,
      showImgType: 1,
      showImgUrl: '',
      showSetting: false,
      key: '',
      moduleName: '',
      title: '',
      templateColor: {
        page: {
          pageBackground: '#ffffff',
          pageGradientBackground: '',
          pageGradientDirection: 'to bottom',
          pageBackgroundUrl: '',
          pagePosition: 'fixed',
          color: '#000000',
          subColor: '#999999',
          specialColor: '#FF4C4C',
          priceColor: '#FF4C4C',
          pageStyle: 'black',
          fontWeight: 400
        },
        list: {
          listPageBackground: '#ffffff',
          listGradientBackground: '',
          listGradientDirection: 'to bottom',
          listPageMargin: 32,
          listMainColor: '#000000',
          listSubColor: '#999999',
          listStatusColor: '#FF4C4C',
          listShadowColor: '#cccccc',
          listShadowSize: 20,
          listItemMargin: 32,
          listTopLeftRadius: 12,
          listTopRightRadius: 12,
          listBottomLeftRadius: 12,
          listBottomRightRadius: 12,
          listTopBorder: 0,
          listRightBorder: 0,
          listBottomBorder: 0,
          listLeftBorder: 0,
          listBorderColor: '#ccc',
          moreColor: '#999999'
        },
        title: {
          header_background: '#CD0000',
          header_color: 'white',
          header_text_size: 32,
          fontWeight: 500,
          slide_style_no_change: true,
          slide_header_background: '#CD0000',
          slide_header_color: 'white',
          slide_header_text_size: 32,
          slide_fontWeight: 500
        },
        img: {
          imgTopLeftRadius: 12,
          imgTopRightRadius: 12,
          imgBottomLeftRadius: 12,
          imgBottomRightRadius: 12
        },
        tab: {
          tabBackground: '',
          tabColor: '#999999',
          tabFontSize: 32,
          tabFontWeight: 400,
          currColor: '#000000',
          currFontSize: 32,
          currFontWeight: 500,
          currBgColor: '#000000',
          currBgWidth: 60,
          currBgHeight: 4,
          currBgBottom: 4
        },
        tag: {
          background: '#F4F5F9',
          gradientBackground: '',
          gradientDirection: 'to bottom',
          backgroundImage: '',
          topLeftRadius: 4,
          topRightRadius: 4,
          bottomLeftRadius: 4,
          bottomRightRadius: 4,
          statusTopLeftRadius: 4,
          statusTopRightRadius: 4,
          statusBottomLeftRadius: 4,
          statusBottomRightRadius: 4,
          textColor: '#363636',
          specialColor: '#FF4C4C',
          preBackground: '#FF4C4C',
          preGradientBackground: '',
          preGradientDirection: 'to bottom',
          preBackgroundImage: '',
          preColor: '#ffffff',
          ingBackground: '#6BD78E',
          ingGradientBackground: '',
          ingGradientDirection: 'to bottom',
          ingBackgroundImage: '',
          ingColor: '#ffffff',
          endBackground: '#B8B8B8',
          endGradientBackground: '',
          endGradientDirection: 'to bottom',
          endBackgroundImage: '',
          endColor: '#ffffff'
        },
        button: {
          style: 1,
          frontBgColor: '',
          frontBorderColor: '#dd0000',
          frontColor: '#dd0000',
          frontFontWeight: 500,
          frontFontSize: 32,
          frontTopLeftRadius: 8,
          frontTopRightRadius: 8,
          frontBottomLeftRadius: 8,
          frontBottomRightRadius: 8,
          afterBgColor: '#dd0000',
          afterBorderColor: '#dd0000',
          afterColor: '#FFFFFF',
          afterFontWeight: 500,
          afterFontSize: 32,
          afterTopLeftRadius: 8,
          afterTopRightRadius: 8,
          afterBottomLeftRadius: 8,
          afterBottomRightRadius: 8,
          frontBgImg: '',
          afterBgImg: ''
        },
        coupon: {
          background: '#ffffff',
          radius: 16,
          title_color: 'black',
          sub_title_color: '#000000',
          gradient_start: '#FF6C6C',
          gradient_end: '#E33838',
          price_font_size: 48,
          price_font_weight: 500,
          proce_font_color: '#ffffff',
          symbol_font_size: 24,
          content_border_color: '#eeeeee',
          content_background: '#ffffff',
          content_margin: 8,
          name_font_size: 28,
          name_font_weight: 500,
          name_color: '#666666',
          status1_color: '#999999',
          status2_color: '#F76262',
          desc_font_size: 24,
          desc_font_weight: 500,
          desc_color: '#FB7575',
          message_font_size: 24,
          message_font_weight: 400,
          message_color: '#999999',
          more_color: '#0088FF',
          time_font_size: 20,
          time_font_weight: 400,
          time_color: '#999999'
        },
        footer: {
          footerSetting: {
            no_change: true,
            footer_type: 1,
            footer_background: '#FFFFFF',
            footer_text_color: '#000000',
            footer_active_color: '#000000',
            footer_text_size: 18,
            footer_margin: 40,
            use_type: 2,
            content: [{
              url: '',
              url_after: '',
              title: '导航文字',
              link: {
                link: '',
                name: '',
                name1: ''
              }
            }, {
              url: '',
              url_after: '',
              title: '导航文字',
              link: {
                link: '',
                name: '',
                name1: ''
              }
            }, {
              url: '',
              url_after: '',
              title: '导航文字',
              link: {
                link: '',
                name: '',
                name1: ''
              }
            }, {
              url: '',
              url_after: '',
              title: '导航文字',
              link: {
                link: '',
                name: '',
                name1: ''
              }
            }, {
              url: '',
              url_after: '',
              title: '导航文字',
              link: {
                link: '',
                name: '',
                name1: ''
              }
            }]
          }
        }
      },
      settingData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.diypageDetail)({
      type: 'templateColor'
    }).then(function (res) {
      if (res.data.config_data && res.data.config_data) {
        if (res.data.config_data && res.data.config_data.tab && !res.data.config_data.tab.order_tab) res.data.config_data.tab.order_tab = [0, 1, 2, 3, 5];
        _this.templateColor = res.data.config_data;
      }
    });
    this.load();
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  methods: {
    showSettingAction: function showSettingAction(key) {
      switch (key) {
        case 'page':
          this.title = '通用风格设置';
          this.moduleName = 'templatePage';
          break;
        case 'list':
          this.moduleName = 'templateList';
          this.title = '列表风格设置';
          break;
        case 'title':
          this.moduleName = 'templateTitle';
          this.title = '标题栏风格设置';
          break;
        case 'footer':
          this.moduleName = 'templateFooter';
          this.title = '底部导航风格设置';
          break;
        case 'img':
          this.moduleName = 'templateImg';
          this.title = '图片圆角设置';
          break;
        case 'tab':
          this.moduleName = 'templateTab';
          this.title = 'TAB栏风格设置';
          break;
        case 'button':
          this.moduleName = 'templateButton';
          this.title = '按钮风格设置';
          break;
        case 'tag':
          this.moduleName = 'templateTag';
          this.title = '标签风格设置';
          break;
        case 'coupon':
          this.moduleName = 'templateCoupon';
          this.title = '优惠券风格设置';
          break;
      }
      this.key = key;
      this.settingData = JSON.parse(JSON.stringify(this.templateColor[key]));
      this.showSetting = true;
    },
    saveAction: function saveAction(key) {
      var _this2 = this;
      this.templateColor[key] = Object.assign({}, this.settingData);
      var data = {
        name: this.title,
        content: JSON.stringify([]),
        config_data: JSON.stringify(this.templateColor),
        type: 'templateColor'
      };
      (0, _system.diypageCreate)('', data).then(function (res) {
        _this2.$message.success('保存成功');
      }).catch(function (message) {});
      this.handleClose();
    },
    handleClose: function handleClose() {
      this.showSetting = false;
    },
    load: function load() {
      var _this3 = this;
      this.listLoading = true;
      (0, _system.diypageList)(this.queryParams).then(function (response) {
        _this3.total = response.data.count;
        var listTypes = response.data.list.map(function (m) {
          return m.type;
        });
        _this3.list.forEach(function (m) {
          var index = listTypes.indexOf(m.type);
          if (index !== -1) {
            var item = response.data.list[index];
            console.log(item);
            m.id = item.id;
            m.is_release = item.is_release;
          }
        });
        // this.list = response.data.list;
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    createdDiy: function createdDiy() {
      this.$router.push({
        path: '/merchant/diy/page'
      });
    },
    editDiy: function editDiy(link) {
      if (link) this.$router.push({
        path: link
      });
    },
    copyAction: function copyAction(id) {
      var _this4 = this;
      (0, _system.copyDiypageApi)(id).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.load();
      }).catch(function () {});
    },
    delDiy: function delDiy(id) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u9875\u9762?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _system.delDiypageDetailApi)(id).then(function () {
          _this5.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    createQRCode: function createQRCode(id, key) {
      var _this6 = this;
      if (key === 2 || key === 4) {
        this.copyText = "/pages/home/home?id=".concat(id);
        var input = document.createElement('input'); // 直接构建input
        input.value = this.copyText; // 设置内容
        console.log(input.value);
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand('Copy'); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.$message.success('复制成功');
      } else if (key === 1 || key === 3) {
        var action = _system.createQRCodeMiniApi;
        if (key === 3) {
          action = _system.createQRCodeApi;
        }
        this.showImg = true;
        this.showImgType = 1;
        action({
          path: "/pages/home/home?id=".concat(id || ''),
          type: 1
        }).then(function (res) {
          _this6.showImgUrl = res.data.base_image;
        });
      }
    },
    setHomeAction: function setHomeAction(id, type) {
      var _this7 = this;
      this.$confirm("\u786E\u8BA4\u8BBE\u7F6E\u4E3A\u9996\u9875?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var action = _system.diypageSetDefaultApi;
        if (type) {
          action = _system.diypageSetTTDefaultApi;
        }
        action(id).then(function (res) {
          _this7.$message.success('操作成功');
          _this7.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    cancelHomeAction: function cancelHomeAction(id) {
      var _this8 = this;
      this.$confirm("\u53D6\u6D88\u6296\u97F3\u9996\u9875?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _system.diypageCancelTTDefaultApi)(id).then(function (res) {
          _this8.$message.success('操作成功');
          _this8.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    sendAction: function sendAction(type) {
      var _this9 = this;
      this.$modalYouSure("\u70B9\u51FB\u786E\u8BA4\u540E\uFF0C\u5C0F\u7A0B\u5E8F\u9875\u9762\u4F1A\u5373\u65F6\u5E94\u7528\u81F3\u8BE5\u65B0\u6A21\u677F\u98CE\u683C\uFF0C\u8BF7\u786E\u8BA4\uFF01").then(function () {
        (0, _system.releasePageApi)({
          type: type
        }).then(function () {
          _this9.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    previewAction: function previewAction(type) {
      var _this10 = this;
      var path = '/pages/home/home?is_preview=1&id=';
      this.showImgUrl = '';
      if (type === 'templateAlbumList') {
        (0, _contract.getLastAuctionApi)({
          page: 1,
          limit: 1
        }).then(function (res) {
          if (res.data.list && res.data.list[0]) {
            path = '/pages/auctionMeeting/index?is_preview=1&id=' + res.data.list[0].venue_id;
            _this10.previewCode(path);
          }
        });
      } else if (type === 'templateAlbumDetail' || type === 'templateAuctionList') {
        (0, _auction.albumSearchListApi)({
          page: 1,
          limit: 1
        }).then(function (res) {
          if (res.data.list && res.data.list[0]) {
            path = '/pages/albumDetail/index?is_preview=1&id=' + res.data.list[0].album_id;
            _this10.previewCode(path);
          }
        });
      } else if (type === 'templateAuctionDetail') {
        (0, _auction.auctionSearchListApi)({
          page: 1,
          limit: 1
        }).then(function (res) {
          if (res.data.list && res.data.list[0]) {
            path = '/pages/auctionDetail/index?is_preview=1&id=' + res.data.list[0].product_id;
            _this10.previewCode(path);
          }
        });
      } else if (type === 'templateProductDetail') {
        (0, _product.productSearchListApi)({
          page: 1,
          limit: 1
        }).then(function (res) {
          if (res.data.list && res.data.list[0]) {
            path = '/pages/productDetail/index?is_preview=1&id=' + res.data.list[0].article_id;
            _this10.previewCode(path);
          }
        });
      } else if (type === 'templateWordDetail') {
        (0, _cms.articleSearchListApi)({
          page: 1,
          limit: 1
        }).then(function (res) {
          if (res.data.list && res.data.list[0]) {
            path = '/pages/word/index?is_preview=1&id=' + res.data.list[0].product_id;
            _this10.previewCode(path);
          }
        });
      } else if (type === 'templatePersonalCenter') {
        path = '/pages/users/index?is_preview=1';
        this.previewCode(path);
      } else {
        this.previewCode(path);
      }
    },
    previewCode: function previewCode(path) {
      var _this11 = this;
      this.showImgType = 2;
      this.showImg = true;
      (0, _system.createQRCodeMiniApi)({
        path: path,
        type: 1
      }).then(function (res) {
        _this11.showImgUrl = res.data.base_image;
      });
    }
  }
};