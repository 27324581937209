"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _components = require("./components");
var _user = require("@/api/user");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import RightPanel from '@/components/rightPanel'
var _default = exports.default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    // RightPanel,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView
  },
  mixins: [_ResizeHandler.default],
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    menuType: function menuType(state) {
      return state.user.menuType;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation
      };
    }
  }),
  watch: {
    menuType: function menuType(val, oldVal) {
      if (!oldVal && val && this.sidebar && this.sidebar.opened) {
        this.$store.dispatch('app/toggleSideBar', false);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.sidebar && this.sidebar.opened && this.menuType) {
      this.$store.dispatch('app/toggleSideBar', false);
    }
    (0, _user.getBaseInfo)().then(function (res) {
      console.log(res);
      _this.$store.dispatch('user/set_merchant_id', res.data.mer_id);
      _this.$store.dispatch('user/set_uname', res.data.real_name);
      _this.$store.dispatch('user/set_merchant_avtar', res.data.mer_avatar);
      _this.$store.dispatch('user/set_merchant_logo', res.data.mer_logo);
      _this.$store.dispatch('user/set_merchant_name', res.data.mer_name);
      _this.$store.dispatch('user/set_fee_type', res.data.feeTypeInfo);
      _this.label = res.data;
    }).catch(function (_ref) {
      var message = _ref.message;
    });
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};