var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("header-breadcrumb", { attrs: { name: "分销员" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { padding: "20px" } },
        [
          _c(
            "div",
            {
              staticClass: "container",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", inline: "", "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.order_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "order_type", $$v)
                            },
                            expression: "tableFrom.order_type",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部 "),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "3" } }, [
                            _vm._v("数字藏品"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "4" } }, [
                            _vm._v("数字盲盒"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "6" } }, [
                            _vm._v("寄售藏品"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "7" } }, [
                            _vm._v("寄售盲盒"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("拍品"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("商城"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "10" } }, [
                            _vm._v("门票"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "width100",
                      staticStyle: { display: "block" },
                      attrs: { label: "时间选择：" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mr20",
                          attrs: { type: "button", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.selectChange(_vm.tableFrom.date)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "date", $$v)
                            },
                            expression: "tableFrom.date",
                          },
                        },
                        _vm._l(_vm.fromList.fromTxt, function (item, i) {
                          return _c(
                            "el-radio-button",
                            { key: i, attrs: { label: item.val } },
                            [_vm._v(_vm._s(item.text))]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "value-format": "yyyy/MM/dd",
                          format: "yyyy/MM/dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "自定义时间",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "分销员：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.tableFrom.keywords,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keywords", $$v)
                            },
                            expression: "tableFrom.keywords",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-popover",
                {
                  attrs: { placement: "right", width: "500", trigger: "click" },
                },
                [
                  _c("div", { staticClass: "mt20" }, [
                    _vm._v("团队成员总数" + _vm._s(_vm.dtotal.teamUserCount)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    { attrs: { data: _vm.dtotal.saleData } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "150",
                          property: "order_type",
                          label: "类型",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          width: "100",
                          property: "pay_count",
                          label: "订单数量",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          width: "300",
                          property: "pay_price",
                          label: "销售额",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [_vm._v("查看分销统计")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.changeadduser },
                },
                [_vm._v("添加分销员")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-top",
                  },
                  on: { click: _vm.exportRecord },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出已生成列表")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "salesman_id", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.user.avatar
                                  ? scope.row.user.avatar
                                  : _vm.moren,
                                "preview-src-list": [
                                  scope.row.user.avatar || _vm.moren,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户信息", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#6699dd",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row.user)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.user.real_name || row.user.nickname)
                            ),
                            row.user.real_name
                              ? _c("span", [
                                  _vm._v("(" + _vm._s(row.user.nickname) + ")"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        row.user && row.user.phone
                          ? _c("div", [_vm._v(_vm._s(row.user.phone))])
                          : _vm._e(),
                        _vm._v(" "),
                        row.user && row.user.mark
                          ? _c("div", [_vm._v(_vm._s(row.user.mark))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "teamUserCount",
                  label: "团队成员",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showteamDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.teamUserCount))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "佣金金额", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.brokerage) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "待入账总额", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.brokerage_wait) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "待结算金额", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.brokerage_over) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "已结算金额", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#6699dd",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showBrokerageSettl(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.brokerage_settle) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "佣金百分比", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extend_data &&
                        scope.row.extend_data.brokerage_percent
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.extend_data.brokerage_percent
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "下单优惠百分比", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extend_data &&
                        scope.row.extend_data.order_discount_percent
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.extend_data.order_discount_percent
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "pay_count",
                  label: "订单数",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#6699dd",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showOrder(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.pay_count) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "pay_price",
                  label: "销售额",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.status == 1 ? "已解除" : "正常"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "join_time",
                  label: "加入时间",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "80",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.revieworder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            编辑\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeretailstatus(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            解除分销\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.retailstatus(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            启用\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.delretail(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.edit ? "编辑分销员" : "添加分销员",
            visible: _vm.showadd,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "绑定员工：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selwidth",
                      attrs: {
                        placeholder: "请选择分配员工",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.userid,
                        callback: function ($$v) {
                          _vm.userid = $$v
                        },
                        expression: "userid",
                      },
                    },
                    _vm._l(_vm.adminarr, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.edit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择用户：", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择用户",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.searchUserAction,
                            loading: _vm.searchLoading,
                          },
                          model: {
                            value: _vm.adduid,
                            callback: function ($$v) {
                              _vm.adduid = $$v
                            },
                            expression: "adduid",
                          },
                        },
                        _vm._l(_vm.userList, function (m, i) {
                          return _c(
                            "el-option",
                            {
                              key: "user" + i,
                              attrs: { value: m.uid, label: m.nickname },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                      "border-radius": "50%",
                                      "margin-right": "12px",
                                    },
                                    attrs: { src: m.avatar, alt: "" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "line-height": "18px",
                                      },
                                    },
                                    [
                                      _c("div", [_vm._v(_vm._s(m.nickname))]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v(_vm._s(m.phone))]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.edit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addrestatus,
                            callback: function ($$v) {
                              _vm.addrestatus = $$v
                            },
                            expression: "addrestatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("保留原团队分销员"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("全新分销员"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "拍卖分成比例" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.auction_brokerage_percent,
                              callback: function ($$v) {
                                _vm.auction_brokerage_percent = $$v
                              },
                              expression: "auction_brokerage_percent",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "inline-block",
                              margin: "0 6px",
                            },
                          },
                          [_vm._v("\n              ％\n            ")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v(
                            "\n              通过分销销售金额的分成比例，写0为不分\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm._l(
                    _vm.auction_brokerage_category_rule,
                    function (domain, index) {
                      return _c(
                        "el-form-item",
                        { key: index },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择分类" },
                              on: { change: _vm.changeCate },
                              model: {
                                value: domain.cate_id,
                                callback: function ($$v) {
                                  _vm.$set(domain, "cate_id", $$v)
                                },
                                expression: "domain.cate_id",
                              },
                            },
                            _vm._l(_vm.categoryList, function (item) {
                              return _c("el-option", {
                                key: item.store_category_id,
                                attrs: {
                                  label: item.cate_name,
                                  value: item.store_category_id,
                                  disabled: item.disabled,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticStyle: { width: "100px" },
                            attrs: { controls: false, precision: 0, max: 100 },
                            model: {
                              value: domain.brokerage_percent,
                              callback: function ($$v) {
                                _vm.$set(domain, "brokerage_percent", $$v)
                              },
                              expression: "domain.brokerage_percent",
                            },
                          }),
                          _vm._v("%\n          "),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteRule(
                                    index,
                                    "auction_brokerage_category_rule"
                                  )
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-circle-plus-outline",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addRule(
                                "auction_brokerage_category_rule"
                              )
                            },
                          },
                        },
                        [_vm._v("添加规则")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商城分成比例" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            model: {
                              value: _vm.product_brokerage_percent,
                              callback: function ($$v) {
                                _vm.product_brokerage_percent = $$v
                              },
                              expression: "product_brokerage_percent",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 16 } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "inline-block",
                              margin: "0 6px",
                            },
                          },
                          [_vm._v("\n              ％\n            ")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v(
                            "\n              通过分销销售金额的分成比例，写0为不分\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm._l(
                    _vm.product_brokerage_category_rule,
                    function (domain, index) {
                      return _c(
                        "el-form-item",
                        { key: index },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择分类" },
                              on: { change: _vm.changeCate },
                              model: {
                                value: domain.cate_id,
                                callback: function ($$v) {
                                  _vm.$set(domain, "cate_id", $$v)
                                },
                                expression: "domain.cate_id",
                              },
                            },
                            _vm._l(_vm.categoryList, function (item) {
                              return _c("el-option", {
                                key: item.store_category_id,
                                attrs: {
                                  label: item.cate_name,
                                  value: item.store_category_id,
                                  disabled: item.disabled,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticStyle: { width: "100px" },
                            attrs: { controls: false, precision: 0, max: 100 },
                            model: {
                              value: domain.brokerage_percent,
                              callback: function ($$v) {
                                _vm.$set(domain, "brokerage_percent", $$v)
                              },
                              expression: "domain.brokerage_percent",
                            },
                          }),
                          _vm._v("%\n          "),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteRule(
                                    index,
                                    "product_brokerage_category_rule"
                                  )
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-circle-plus-outline",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addRule(
                                "product_brokerage_category_rule"
                              )
                            },
                          },
                        },
                        [_vm._v("添加规则")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "门票分成比例" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-right": "6px" },
                      attrs: { span: 8 },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "0" },
                        model: {
                          value: _vm.ticket_brokerage_percent,
                          callback: function ($$v) {
                            _vm.ticket_brokerage_percent = $$v
                          },
                          expression: "ticket_brokerage_percent",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#999" } }, [
                    _vm._v("通过分销销售金额的分成比例，写0为不分"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下单优惠" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-right": "6px" },
                      attrs: { span: 8 },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "0" },
                        model: {
                          value: _vm.order_discount_percent,
                          callback: function ($$v) {
                            _vm.order_discount_percent = $$v
                          },
                          expression: "order_discount_percent",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#999" } }, [
                    _vm._v("分享后用户下单时用户享受的优惠比例"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              !_vm.edit
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleadd },
                    },
                    [_vm._v("添加")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleEdit },
                    },
                    [
                      _c("svg-icon", { attrs: { "icon-class": "commonedit" } }),
                      _vm._v("编辑"),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "启用分销员",
            visible: _vm.changestatusflag,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changestatusflag = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.restatus,
                callback: function ($$v) {
                  _vm.restatus = $$v
                },
                expression: "restatus",
              },
            },
            [
              _c("el-radio", { attrs: { label: "0" } }, [
                _vm._v("保留原团队分销员"),
              ]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("全新分销员")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.changestatusflag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlechangestatus },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showretaildetail
        ? [
            _c("retaildetail", {
              attrs: {
                showretaildetail: _vm.showretaildetail,
                row: _vm.row,
                uid: _vm.uid,
              },
              on: {
                refash: _vm.retaildetailrefash,
                close: _vm.retaildetailclose,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单列表",
            visible: _vm.showOrderflag,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showOrderflag = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mb10" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.exportOrderList },
                },
                [_vm._v("\n        导出\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.openOrderExport } },
                [_vm._v("\n        查看导出列表\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.orderTableData.data, border: "", stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.extend_data
                                  ? scope.row.extend_data.image
                                  : "",
                                "preview-src-list": [
                                  scope.row.extend_data.image,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "名称",
                  prop: "extend_data.title",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "购买数量",
                  prop: "extend_data.buy_count",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "订单号",
                  prop: "extend_data.order_no",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "订单金额",
                  prop: "pay_price",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "分佣比例",
                  prop: "brokerage_percent",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "分佣金额",
                  prop: "brokerage",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "已分佣金额",
                  prop: "brokerage_over",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "退分佣金额",
                  prop: "brokerage_refund",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "下单时间",
                  prop: "create_time",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "订单类型",
                  prop: "order_type_text",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "购买人",
                  prop: "user.nickname",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [6, 20, 40, 60, 80],
                  "page-size": _vm.orderTableFrom.limit,
                  "current-page": _vm.orderTableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.orderTableData.total,
                },
                on: {
                  "size-change": _vm.handleOrderSizeChange,
                  "current-change": _vm.pageOrderChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "结算记录",
            visible: _vm.brokerageSettlVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.brokerageSettlVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px", inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "el-select-loadmore",
                          rawName: "v-el-select-loadmore",
                          value: _vm.loadmoreDistributorList,
                          expression: "loadmoreDistributorList",
                        },
                      ],
                      attrs: {
                        placeholder: "请选择用户",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.getDistributor,
                        clearable: "",
                        loading: _vm.searchLoading,
                        "value-key": "user.nickname",
                      },
                      on: { change: _vm.salesmanChange },
                      model: {
                        value:
                          _vm.settlementRecordDialogData.settlementRecordFilter
                            .salesman_id,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.settlementRecordDialogData
                              .settlementRecordFilter,
                            "salesman_id",
                            $$v
                          )
                        },
                        expression:
                          "settlementRecordDialogData.settlementRecordFilter.salesman_id",
                      },
                    },
                    _vm._l(
                      _vm.settlementRecordDialogData.distributorList,
                      function (m, i) {
                        return _c(
                          "el-option",
                          {
                            key: "user" + i,
                            attrs: {
                              value: m.salesman_id,
                              label: m.user.nickname,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px",
                                    "border-radius": "50%",
                                    "margin-right": "12px",
                                  },
                                  attrs: { src: m.user.avatar, alt: "" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      "line-height": "18px",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(m.user.nickname)),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v(_vm._s(m.user.phone))]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      "value-format": "yyyy/MM/dd",
                      format: "yyyy/MM/dd",
                      size: "small",
                      type: "daterange",
                      placement: "bottom-end",
                      placeholder: "自定义时间",
                    },
                    on: { change: _vm.salesmanChange },
                    model: {
                      value:
                        _vm.settlementRecordDialogData.settlementRecordFilter
                          .timeVal,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.settlementRecordDialogData.settlementRecordFilter,
                          "timeVal",
                          $$v
                        )
                      },
                      expression:
                        "settlementRecordDialogData.settlementRecordFilter.timeVal",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { on: { click: _vm.exportSettlementRecord } },
                    [_vm._v("\n          导出\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.openExportList },
                    },
                    [_vm._v("\n          查看导出列表\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openInitiateSettlementDialog },
                    },
                    [_vm._v("\n          发起结算\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                height: "400",
                data: _vm.settlementRecordDialogData.settlementRecordTable,
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "settle_price", label: "结算金额" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "remark", label: "结算备注" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "avatar", label: "结算凭证" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: row.image },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "结算对象" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              row.user &&
                                " " + row.user.nickname + " " + row.user.phone
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "create_time", label: "结算时间" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [6, 10, 20, 30],
              "page-size": _vm.settlementRecordDialogData.tabelParams.limit,
              "current-page": _vm.settlementRecordDialogData.tabelParams.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.settlementRecordDialogData.tabelParams.total,
            },
            on: {
              "size-change": _vm.handleTableSizeChange,
              "current-change": _vm.tablePageChange,
            },
          }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "30%",
                title: "结算",
                visible:
                  _vm.settlementRecordDialogData.initiateSettlementVisible,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(
                    _vm.settlementRecordDialogData,
                    "initiateSettlementVisible",
                    $event
                  )
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "80px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算对象：" } },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "el-select-loadmore",
                              rawName: "v-el-select-loadmore",
                              value: _vm.loadmoreDistributorList,
                              expression: "loadmoreDistributorList",
                            },
                          ],
                          attrs: {
                            placeholder: "请选择用户",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.getDistributor,
                            clearable: "",
                            loading: _vm.searchLoading,
                            "value-key": "user.nickname",
                          },
                          model: {
                            value:
                              _vm.settlementRecordDialogData.settleForm
                                .salesman_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.settlementRecordDialogData.settleForm,
                                "salesman_id",
                                $$v
                              )
                            },
                            expression:
                              "settlementRecordDialogData.settleForm.salesman_id",
                          },
                        },
                        _vm._l(
                          _vm.settlementRecordDialogData.distributorList,
                          function (m, i) {
                            return _c(
                              "el-option",
                              {
                                key: "user" + i,
                                attrs: {
                                  value: m.salesman_id,
                                  label: m.user.nickname,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "40px",
                                        height: "40px",
                                        "border-radius": "50%",
                                        "margin-right": "12px",
                                      },
                                      attrs: { src: m.user.avatar, alt: "" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "line-height": "18px",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(m.user.nickname)),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(_vm._s(m.user.phone)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算金额：" } },
                    [
                      _c("el-input-number", {
                        model: {
                          value:
                            _vm.settlementRecordDialogData.settleForm
                              .settle_price,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.settlementRecordDialogData.settleForm,
                              "settle_price",
                              $$v
                            )
                          },
                          expression:
                            "settlementRecordDialogData.settleForm.settle_price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算备注：", inline: true } },
                    [
                      _c("el-input", {
                        attrs: { rows: 3, type: "textarea" },
                        model: {
                          value:
                            _vm.settlementRecordDialogData.settleForm.remark,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.settlementRecordDialogData.settleForm,
                              "remark",
                              $$v
                            )
                          },
                          expression:
                            "settlementRecordDialogData.settleForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "结算凭证：" } }, [
                    _c("div", { staticClass: "flex" }, [
                      _vm.settlementRecordDialogData.settleForm.image
                        ? _c(
                            "div",
                            {
                              staticClass: "upload-view item-flex-center mr10",
                            },
                            [
                              _c("img", {
                                staticClass: "pic",
                                attrs: {
                                  src:
                                    _vm.settlementRecordDialogData.settleForm
                                      .image + "!m640",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-view item-flex-center",
                          on: { click: _vm.changeImg },
                        },
                        [
                          _c("img", {
                            staticClass: "up",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20220301/2016064f133d38a91c22de563f552c16.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirmSettle },
                        },
                        [_vm._v("确认结算")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.settlementRecordDialogData.initiateSettlementVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("fileList", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }