var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "open3dPropleftEL", staticClass: "open3d-propleft" },
    [
      _c(
        "div",
        { staticClass: "open3d-propleft-header" },
        [
          _vm.exhibitionType.indexOf("material_") == 0
            ? [
                _c(
                  "div",
                  {
                    staticClass: "category-item",
                    class: { active: _vm.exhibitionType == "material_img" },
                    on: {
                      click: function ($event) {
                        return _vm.editExhibitionTypeAction("material_img")
                      },
                    },
                  },
                  [_vm._v("\n        图片\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "category-item",
                    class: { active: _vm.exhibitionType == "material_text" },
                    on: {
                      click: function ($event) {
                        return _vm.editExhibitionTypeAction("material_text")
                      },
                    },
                  },
                  [_vm._v("\n        文字\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "category-item",
                    class: { active: _vm.exhibitionType == "material_video" },
                    on: {
                      click: function ($event) {
                        return _vm.editExhibitionTypeAction("material_video")
                      },
                    },
                  },
                  [_vm._v("\n        视频\n      ")]
                ),
              ]
            : [
                _c(
                  "div",
                  {
                    staticClass: "category-item",
                    class: {
                      active: _vm.exhibitionType == "exhibition",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.editExhibitionTypeAction("exhibition")
                      },
                    },
                  },
                  [_vm._v("\n        作品\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "category-item",
                    class: {
                      active: _vm.exhibitionType == "auction",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.editExhibitionTypeAction("auction")
                      },
                    },
                  },
                  [_vm._v("\n        拍品\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "category-item",
                    class: {
                      active: _vm.exhibitionType == "product",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.editExhibitionTypeAction("product")
                      },
                    },
                  },
                  [_vm._v("\n        商品\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "category-item",
                    class: {
                      active: _vm.exhibitionType == "live",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.editExhibitionTypeAction("live")
                      },
                    },
                  },
                  [_vm._v("\n        直播\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "category-item",
                    class: { active: _vm.exhibitionType == "nft" },
                    on: {
                      click: function ($event) {
                        return _vm.editExhibitionTypeAction("nft")
                      },
                    },
                  },
                  [_vm._v("\n        道具\n      ")]
                ),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.exhibitionType == "material_text"
        ? [
            _c("textEdit", {
              attrs: { open3dEl: _vm.open3dEl },
              on: {
                inpFocus: function ($event) {
                  return _vm.inpAction(true)
                },
                inpBlur: function ($event) {
                  return _vm.inpAction(false)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "open3d-font-title open3d-common-title-margin" },
              [_vm._v("方位")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "open3d-control-box" }, [
              _c(
                "div",
                {
                  staticClass: "open3d-control-button",
                  class: { active: _vm.control == "translate" },
                  on: {
                    click: function ($event) {
                      return _vm.changeControls("translate")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "open3d-control-button-img",
                    attrs: {
                      src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805589854242506.png",
                      alt: "",
                    },
                  }),
                  _vm._v("\n        位移\n        "),
                  _c("img", {
                    staticClass: "open3d-control-button-icon",
                    attrs: {
                      src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202382/image/1690963842526319665.png",
                      alt: "",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "open3d-control-button",
                  class: { active: _vm.control == "rotate" },
                  on: {
                    click: function ($event) {
                      return _vm.changeControls("rotate")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "open3d-control-button-img",
                    attrs: {
                      src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805590061206575.png",
                      alt: "",
                    },
                  }),
                  _vm._v("\n        旋转\n        "),
                  _c("img", {
                    staticClass: "open3d-control-button-icon",
                    attrs: {
                      src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202382/image/1690963842526319665.png",
                      alt: "",
                    },
                  }),
                ]
              ),
            ]),
          ]
        : [
            _c("div", { staticClass: "open3d-propleft-nft-search" }, [
              _vm.exhibitionType == "auction" || _vm.exhibitionType == "product"
                ? _c(
                    "div",
                    { staticClass: "open3d-select-box" },
                    [
                      _c("el-cascader", {
                        staticClass: "filter-item flex-one",
                        attrs: {
                          options: _vm.merCateList,
                          placeholder: "分类",
                          props: _vm.props,
                          filterable: "",
                          clearable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.mer_cate_id,
                          callback: function ($$v) {
                            _vm.mer_cate_id = $$v
                          },
                          expression: "mer_cate_id",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.exhibitionType.indexOf("material_") == 0
                ? _c(
                    "div",
                    { staticClass: "open3d-select-box" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "flex-one",
                          attrs: { clearable: "", placeholder: "分类" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.attachment_category_id,
                            callback: function ($$v) {
                              _vm.attachment_category_id = $$v
                            },
                            expression: "attachment_category_id",
                          },
                        },
                        _vm._l(_vm.treeData2, function (m, i) {
                          return _c("el-option", {
                            key: "treeData2" + i,
                            attrs: {
                              label: m.attachment_category_name,
                              value: m.attachment_category_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "open3d-propleft-search-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.keyword,
                      expression: "keyword",
                    },
                  ],
                  staticClass: "open3d-propleft-input",
                  attrs: { type: "text", placeholder: "搜索" },
                  domProps: { value: _vm.keyword },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.goSearchAction($event)
                    },
                    focus: function ($event) {
                      return _vm.inpAction(true)
                    },
                    blur: function ($event) {
                      return _vm.inpAction(false)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.keyword = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "open3d-propleft-search-icon open3dtype-search",
                  attrs: {
                    src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805584592429232.png",
                    alt: "",
                  },
                  on: { click: _vm.goSearchAction },
                }),
              ]),
            ]),
            _vm._v(" "),
            _vm.exhibitionType == "exhibition"
              ? _c(
                  "div",
                  { staticClass: "open3d-tag-box" },
                  _vm._l(_vm.labelList, function (m, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "open3d-tag-text",
                        class: { active: _vm.label_id == m.label_id },
                        on: {
                          click: function ($event) {
                            return _vm.changeTagAction(m.label_id)
                          },
                        },
                      },
                      [_vm._v("\n        " + _vm._s(m.label_name) + "\n      ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "open3d-goods-box",
                on: {
                  "&scroll": function ($event) {
                    return _vm.listScrollAction($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "open3d-goods-box-content" },
                  [
                    _vm._l(_vm.list, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "open3d-goods open3d-work-list",
                          class: {
                            active: item.active,
                            open3d: _vm.open3dHasDataIds.indexOf(item.id) != -1,
                          },
                          on: {
                            dragenter: _vm.kong,
                            dragover: _vm.kong,
                            dragend: function ($event) {
                              return _vm.dragendAction($event, index)
                            },
                            click: function ($event) {
                              return _vm.changeAction(index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "open3d-goods-image-box" }, [
                            _c("img", {
                              staticClass: "open3d-goods-image",
                              attrs: { src: item.coverImage, alt: "" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "open3d-goods-pop-sign" },
                              [
                                item.type == 3
                                  ? _c("img", {
                                      attrs: {
                                        src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805584820754784.png",
                                        alt: "",
                                      },
                                    })
                                  : item.type == 2 || item.type == 5
                                  ? _c("img", {
                                      attrs: {
                                        src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805585081616402.png",
                                        alt: "",
                                      },
                                    })
                                  : item.type == 4
                                  ? _c("img", {
                                      attrs: {
                                        src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805585312452731.png",
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "open3d-name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.isLoading
                      ? _c("div", { staticClass: "loading" }, [
                          _vm._v("加载中..."),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hasMore && _vm.list.length == 0
                      ? _c("div", { staticClass: "loading" }, [
                          _vm._v("暂无数据"),
                        ])
                      : !_vm.hasMore
                      ? _c("div", { staticClass: "loading" }, [
                          _vm._v("没有更多了..."),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _vm.exhibitionType.indexOf("material_") == -1
              ? _c("div", { staticClass: "open3d-propleft-bottom-sign" }, [
                  _c(
                    "div",
                    {
                      staticClass: "open3d-propleft-bottom-checkbox",
                      on: { click: _vm.checkAllAction },
                    },
                    [
                      _c("img", {
                        staticClass: "open3d-propleft-bottom-uncheck",
                        attrs: {
                          src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/169080558551290681.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _vm.checkAll
                        ? _c("img", {
                            staticClass: "open3d-propleft-bottom-checked",
                            attrs: {
                              src: "https://open3d.cdn.yunzongbu.cn/open3d/1686290660280262657/image/202387/1691412627816324801.png",
                              alt: "",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("全选")]),
                  _vm._v(" "),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("已选"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "open3d-propleft-bottom-selected-num" },
                    [_vm._v(_vm._s(_vm.changePlaceholder))]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("件/")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "open3d-propleft-bottom-can-num" },
                    [_vm._v(_vm._s(_vm.placeholderCount))]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("件")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "open3d-propleft-bottom-sign-text" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.exhibitionType.indexOf("material_") == 0
                      ? "*可将内容拖拽至空间内装修"
                      : "默认展位占满后可拖拽更多到空间"
                  ) +
                  "\n    "
              ),
            ]),
          ],
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "open3d-propleft-bottom-button-box" },
        [
          _vm.exhibitionType == "material_text"
            ? [
                _c(
                  "div",
                  {
                    staticClass: "open3d-propleft-bottom-button-cursor",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("closeAction")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "open3d-propleft-bottom-button-img",
                      attrs: {
                        src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805589127488113.png",
                        alt: "",
                      },
                    }),
                    _vm._v("\n        确认\n      "),
                  ]
                ),
              ]
            : _vm.exhibitionType.indexOf("material_") == -1
            ? [
                _c(
                  "div",
                  {
                    staticClass: "open3d-propleft-bottom-button-cursor",
                    on: { click: _vm.quickAction },
                  },
                  [
                    _c("img", {
                      staticClass: "open3d-propleft-bottom-button-img",
                      attrs: {
                        src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805589127488113.png",
                        alt: "",
                      },
                    }),
                    _vm._v("\n        一键布展\n      "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "open3d-propleft-bottom-button-cancel",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("closeAction")
                      },
                    },
                  },
                  [_vm._v("\n        关闭\n      ")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.exhibitionType.indexOf("material_") == -1 ||
      _vm.exhibitionType == "material_text"
        ? _c("div", { staticStyle: { height: "22px" } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }