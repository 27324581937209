var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _vm.collectMoney.show
        ? _c(
            "div",
            { attrs: { id: "printorder" } },
            [
              _c("div", { staticClass: "detail-box" }, [
                _c("div", { staticClass: "detail-left" }, [
                  _c("div", { staticClass: "detail-left-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.merData.company_name) +
                        " | 委托方拍后告知书\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "detail-left-desc",
                      staticStyle: {
                        "font-size": "14px",
                        "font-family": "'printEN'",
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.merData.company_english_name) +
                          "  Notification letter from the client after the auction\n        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "secTit" }, [
                _vm._v(
                  "您好！感谢您对我公司的大力支持，希望您能一如既往地支持我公司，谢谢！"
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    "Thanks for your support to our company, we beg your understanding and also hope you can support us as usual!"
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.collectMoney.data.venueInfo
                ? _c(
                    "div",
                    {
                      staticClass: "detail-left-title",
                      staticStyle: { "margin-top": "40px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.collectMoney.data.venueInfo.title) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "justify-content": "start" },
                },
                [
                  _c("div", [
                    _vm._v("委托人姓名 SELLER'S NAME "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.collectMoney.data.entrustUser.real_name)
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "30px",
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.collectMoney.data.entrustUser.originPhone)
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.isNo
                ? _c(
                    "div",
                    { staticClass: "ptd-box" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ptd",
                          staticStyle: { "font-weight": "bold" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [_vm._v("合同号")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("KT NO.")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _c("span", [_vm._v("图录号")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("LOT NO.")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [_vm._v("拍品名称")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("DESCRIPTION")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [_vm._v("保留价")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("SALES STATUS")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [_vm._v("实得价")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("NET PROCEEDS")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [_vm._v("落槌价")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("HAMMER PRICE")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [_vm._v("成交状态")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("SALES STATUS")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [_vm._v("专场名称")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("SESSION NAME")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.collectMoney.data.auctionList,
                        function (m, i) {
                          return _c(
                            "div",
                            { key: "order" + i, staticClass: "ptd calcul" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      m.erpProduct && m.erpProduct.contract
                                        ? m.erpProduct.contract.contract_no
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "1" },
                                },
                                [_vm._v(_vm._s(m.auction.lot))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [_vm._v(_vm._s(m.auction.product.store_name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        m.erpProduct.retain_price,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        m.erpProduct.take_home_price,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        m.auction.now_price,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      m.auction.auction_status > 4
                                        ? "成交"
                                        : "流拍"
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [_vm._v(_vm._s(m.auction.album.album_name))]
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ptd" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: {
                              flex: "2",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c("span", [_vm._v("合计")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("TOTAL")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: {
                              flex: "1",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.collectMoney.data.auctionList.length) +
                                "件"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "ptd-body",
                          staticStyle: { flex: "2" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(
                                  _vm.collectMoney.blTotal,
                                  2
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(
                                  _vm.collectMoney.sdTotal,
                                  2
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(
                                  _vm.collectMoney.lcTotal,
                                  2
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "ptd-body",
                          staticStyle: { flex: "1" },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "ptd-body",
                          staticStyle: { flex: "2" },
                        }),
                      ]),
                    ],
                    2
                  )
                : [
                    _vm._l(_vm.result, function (item, ind) {
                      return _c(
                        "div",
                        {
                          key: ind,
                          staticClass: "ptd-box",
                          style:
                            ind < _vm.result.length - 1
                              ? "page-break-after: always;"
                              : "",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd",
                              staticStyle: { "font-weight": "bold" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _c("span", [_vm._v("合同号")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("KT NO.")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { flex: "1" },
                                },
                                [
                                  _c("span", [_vm._v("图录号")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("LOT NO.")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _c("span", [_vm._v("拍品名称")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("DESCRIPTION")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _c("span", [_vm._v("保留价")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("SALES STATUS")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _c("span", [_vm._v("实得价")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("NET PROCEEDS")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _c("span", [_vm._v("落槌价")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("HAMMER PRICE")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _c("span", [_vm._v("成交状态")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("SALES STATUS")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-title",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _c("span", [_vm._v("专场名称")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                      },
                                    },
                                    [_vm._v("SESSION NAME")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(item, function (m, i) {
                            return _c(
                              "div",
                              { key: "order" + i, staticClass: "ptd" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        m.erpProduct && m.erpProduct.contract
                                          ? m.erpProduct.contract.contract_no
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "1" },
                                  },
                                  [_vm._v(_vm._s(m.auction.lot))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [_vm._v(_vm._s(m.auction.product.store_name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          m.erpProduct.retain_price,
                                          2
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          m.erpProduct.take_home_price,
                                          2
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          m.auction.now_price,
                                          2
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        m.auction.auction_status > 4
                                          ? "成交"
                                          : "流拍"
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "2" },
                                  },
                                  [_vm._v(_vm._s(m.auction.album.album_name))]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd ptdFoot" }, [
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: {
                            flex: "2",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("span", [_vm._v("合计")]),
                          _c(
                            "span",
                            { staticStyle: { "font-family": "'printEN'" } },
                            [_vm._v("TOTAL")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: {
                            flex: "1",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.collectMoney.data.auctionList.length) +
                              "件"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-body",
                        staticStyle: { flex: "2" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(_vm.collectMoney.blTotal, 2)
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(_vm.collectMoney.sdTotal, 2)
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(_vm.collectMoney.lcTotal, 2)
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-body",
                        staticStyle: { flex: "2" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-body",
                        staticStyle: { flex: "2" },
                      }),
                    ]),
                  ],
              _vm._v(" "),
              _c("div", {
                staticStyle: {
                  width: "100%",
                  "border-top": "2px solid #000",
                  "margin-top": "60px",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ptd-bottom", attrs: { id: "ptd-bottom" } },
                [
                  _c("div", { staticClass: "ptd-bottom-left" }, [
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        "\n            公司地址：" +
                          _vm._s(_vm.merData.config.company_address) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        "\n            Company Address: " +
                          _vm._s(
                            _vm.merData.config.company_english_address || ""
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        "公司电话/TEL： " +
                          _vm._s(_vm.merData.config.contact_phone)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-bottom-right" },
                    _vm._l(
                      _vm.merData.config.mer_qrcode_data,
                      function (item, index) {
                        return item.imgUrl
                          ? _c(
                              "div",
                              {
                                key: "rcimg" + index,
                                staticClass: "ptd-bottom-img-list",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    crossorigin: "anonymous",
                                    src: item.imgUrl + "!120a",
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "ptd-bottom-img-list-title" },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    0
                  ),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }