"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
//
var _default = exports.default = {
  props: ["open3dEl", "pointData"],
  data: function data() {
    return {
      pointList: [],
      id: this.$route.query.id,
      showAdd: false,
      showStartPoint: false,
      nowPoint: {},
      toolboxEL: null
    };
  },
  created: function created() {
    this.getPointAction();
  },
  methods: {
    getPointAction: function getPointAction() {
      console.log(this.pointData);
      this.pointList = this.pointData;
      // getPointListApi({ pageNum: 1, pageSize: 100, spaceId: this.id }).then((res) => {
      //   res.rows.forEach(m => {
      //     m.coordinate = JSON.parse(m.coordinate);
      //   });
      //   this.pointList.value = res.rows;
      // });
    },
    addPointAction: function addPointAction() {
      if (this.open3dEl) {
        var m = this.open3dEl.getStartPoint();
        var i = Number(this.pointList.map(function (m) {
          return m.nameIndex;
        }).reverse()[0] || 0) + 1;
        var data = {
          coordinate: m,
          name: "\u51FA\u751F\u70B9".concat(i),
          nameIndex: i,
          hasDefault: false
        };
        // createPointApi({
        //   name: data.name,
        //   nameIndex: data.nameIndex,
        //   coordinate: JSON.stringify(data.coordinate),
        //   spaceId: id,
        // }).then(() => {
        //   getPointAction();
        //   showAdd.value = false;
        // }).catch(({message}) => this.$message.error(message));
        this.pointList.push(data);
        if (this.pointList.length == 1) {
          this.open3dEl.changeStartPoint(JSON.parse(JSON.stringify(data.coordinate)));
          this.pointList[0].hasDefault = true;
        }
        this.$emit('savePoint', this.pointList);
        this.showAdd = false;
      }
    },
    setDefaultAction: function setDefaultAction(i) {
      var _this = this;
      this.pointList.forEach(function (item, index) {
        item.hasDefault = false;
        if (i == index) {
          _this.open3dEl.changeStartPoint(JSON.parse(JSON.stringify(item.coordinate)));
        }
      });
      this.pointList[i].hasDefault = true;
      this.$emit('savePoint', this.pointList);
    },
    delAction: function delAction(i) {
      this.pointList.splice(i, 1);
      this.$emit('savePoint', this.pointList);
    },
    showAddPointAction: function showAddPointAction() {
      if (this.open3dEl) {
        this.nowPoint = this.open3dEl.getStartPoint();
        this.showAdd = true;
      }
    },
    dragendAction: function dragendAction(e) {
      // 拖拽结束事件

      var mouseX = e.clientX;
      var mouseY = e.clientY;
      var parentRect = this.$refs.toolboxEL.getBoundingClientRect(); // 获取添加展品弹出层的边界信息

      if (mouseX < parentRect.left || mouseX > parentRect.right || mouseY < parentRect.top || mouseY > parentRect.bottom) {
        // 判断拖拽到3d场景中
        this.open3dEl.drawExhibition({
          name: "传送门",
          coverImage: "https://saas.cdn.yunzongbu.cn/merchant/333425/20231116/image/1700118197181840335.png",
          url: "https://open3d.cdn.yunzongbu.cn/open3d/1703574407954382848/image/20231121/1700560645681666287.glb",
          type: 4,
          exhibitionType: "portal"
        }, e);
      }
    }
  }
};