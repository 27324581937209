var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "页面基本配置", is_tab: false } },
    [
      _vm.facade && _vm.facade.show
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "背景颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#f7f7f7" },
                      model: {
                        value: _vm.facade.background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "background", $$v)
                        },
                        expression: "facade.background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("背景图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.bg_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.bg_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "背景图设置" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.facade.bg_image_mode,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bg_image_mode", $$v)
                          },
                          expression: "facade.bg_image_mode",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("宽度固定上下居中"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("满屏上下左右截取"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("宽度固定上下平铺循环展示"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("宽度固定高度自适应顶部展示"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "页面边距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.page_margin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "page_margin", $$v)
                        },
                        expression: "facade.page_margin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("通用内容设置"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "左右距离" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.marginLeft,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "marginLeft", $$v)
                        },
                        expression: "facade.marginLeft",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "内间距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.item_padding,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_padding", $$v)
                        },
                        expression: "facade.item_padding",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "内容圆角" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.borderRadius,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "borderRadius", $$v)
                        },
                        expression: "facade.borderRadius",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "背景颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.item_background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_background", $$v)
                        },
                        expression: "facade.item_background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "边框颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#FFFFFF" },
                      model: {
                        value: _vm.facade.border,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "border", $$v)
                        },
                        expression: "facade.border",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题颜色" } },
                  [
                    _c("colour", {
                      attrs: { colour: "#000000" },
                      model: {
                        value: _vm.facade.titleColor,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "titleColor", $$v)
                        },
                        expression: "facade.titleColor",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题字号" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.titleFontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "titleFontSize", $$v)
                        },
                        expression: "facade.titleFontSize",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }