"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ueditorFrom = _interopRequireDefault(require("@/components/ueditorFrom"));
var _vueUeditorWrap = _interopRequireDefault(require("vue-ueditor-wrap"));
var _contract = require("@/api/contract");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'appendixtemp',
  components: {
    ueditorFrom: _ueditorFrom.default,
    VueUeditorWrap: _vueUeditorWrap.default
  },
  data: function data() {
    return {
      content: ''
    };
  },
  mounted: function mounted() {
    this.id = this.$route.params.id;
    this.getdetail();
  },
  methods: {
    getdetail: function getdetail() {
      var _this = this;
      (0, _contract.getmodalDetail)(this.id).then(function (res) {
        if (res.status == 200) {
          if (res.data.content.attachment_content) {
            _this.content = res.data.content.attachment_content;
          }
        }
      });
    },
    setdetail: function setdetail() {
      (0, _contract.setappendix)(this.id, {
        content: this.content
      }).then(function (res) {
        console.log(res);
      });
    },
    close: function close() {
      this.$router.back();
    }
  }
};