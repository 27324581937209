"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user.js");
var _index = require("@/utils/index");
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      collectMoney: {
        show: false,
        data: ''
      },
      fee_type: '',
      feeTypeInfo: {
        mer_fee_type: ''
      },
      merData: {}
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
  },
  methods: {
    switchLang: function switchLang(filed) {
      // if (this.feeTypeObj.mer_fee_type == 'HKD') {
      return _zhTW.default.TW[filed];
      // }else return langConfig.CN[filed]
    },
    loadData: function loadData(row) {
      console.log(row);
      this.collectMoney = {
        data: row,
        show: true
      };
    },
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      (0, _printJs.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page {margin:0 10mm}"
      });
    }
  }
};