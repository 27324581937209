var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "数字员工" },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#3480F4 !important",
                      "margin-right": "20px",
                    },
                    attrs: { type: "text", icon: "el-icon-picture-outline" },
                    on: {
                      click: function ($event) {
                        return _vm.onGOUrl("/merchant/systemForm/picture", 1)
                      },
                    },
                  },
                  [_vm._v("素材库")]
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { color: "#3480F4", cursor: "pointer" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                            alt: "",
                          },
                        }),
                        _vm._v("\n          配置\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/pageManagement/auction/staffConfig",
                                    1
                                  )
                                },
                              },
                            },
                            [_vm._v("任务规则")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "div",
            {
              staticClass: "top-view flex",
              staticStyle: { padding: "0 20px" },
            },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: {
                    "default-active": _vm.routerUrl,
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/pageManagement/auction/marketMatrix/materialProduction",
                      },
                    },
                    [_vm._v("生成素材")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/pageManagement/auction/marketMatrix/promotionPlan",
                      },
                    },
                    [_vm._v("发布计划")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/pageManagement/auction/marketMatrix/taskList",
                      },
                    },
                    [_vm._v("任务列表")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/pageManagement/auction/marketMatrix/channel",
                      },
                    },
                    [_vm._v("资源列表")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w300 mt10",
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    placeholder: "请选择拍卖会",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.onSearchVenue,
                  },
                  on: { change: _vm.onVenueChange },
                  model: {
                    value: _vm.venue_id,
                    callback: function ($$v) {
                      _vm.venue_id = $$v
                    },
                    expression: "venue_id",
                  },
                },
                _vm._l(_vm.venueList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.title, value: item.venue_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }