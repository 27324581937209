"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _questionnaire = require("@/api/questionnaire");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "examineOrder",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      list: [],
      show: false,
      questionnaireInfoList: [],
      questionnaireSelectList: [],
      question_select: [],
      question_select_id: "",
      showFlag: false,
      row: {},
      remark: "",
      date: "",
      changeSize: 10,
      startDateOption: {
        disabledDate: this.setStartDate
      },
      endDateOption: {
        disabledDate: this.setEndDate
      },
      total: 0,
      queryParams: {
        page: 1,
        size: 20,
        naire_id: this.$route.query.id
      },
      naire_id: this.$route.query.id,
      status: 0,
      uname: "",
      askDetail: {},
      title: ""
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;
      console.log(this.queryParams.question_select);
      var params = {
        page: this.queryParams.page,
        size: this.queryParams.size,
        naire_id: this.queryParams.naire_id
      };
      if (this.date && this.date.length) {
        params.date = this.date.join("-");
      }
      if (this.uname) {
        params.user_name = this.uname;
      }
      if (this.queryParams.question_select && this.queryParams.question_select.length) {
        params.question_select = JSON.parse(JSON.stringify(this.queryParams.question_select));
        params.question_select.forEach(function (item) {
          if ((0, _validate.isArray)(item.option_keyword)) {
            item.option_keyword = item.option_keyword.join(",");
          }
        });
        params.question_select = JSON.stringify(params.question_select);
      }
      (0, _questionnaire._gerQuestionnaireInfoList)(params).then(function (res) {
        if (res.status === 200) {
          _this.list = res.data.list;
          _this.total = res.data.count;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getDetail: function getDetail() {
      var _this2 = this;
      var params = {
        naireId: this.queryParams.naire_id
      };
      (0, _questionnaire._gerQuestionnaireInfo)(params).then(function (res) {
        if (res.status === 200) {
          _this2.questionnaireInfoList = res.data.question;
        }
      }).catch(function (err) {
        _this2.$message.error(err.message + "");
      });
    },
    replay: function replay(type) {
      this.queryParams.page = 1;
      if (type == 1) {
        this.questionnaireSelectList = [];
        this.question_select = [];
        this.queryParams.question_select = [];
        this.question_select_id = "";
        this.date = "";
      } else {
        this.show = false;
        this.queryParams.question_select = this.question_select.filter(function (item) {
          if ((0, _validate.isArray)(item.option_keyword)) return item.option_keyword.length;else return item.option_keyword;
        });
      }
      this.getList();
    },
    addOrDelRow: function addOrDelRow(row, index) {
      this.questionnaireSelectList.splice(index, 1);
      this.question_select.splice(index, 1);
      if (!this.questionnaireSelectList.length) {
        this.question_select_id = "";
      }
    },
    handleSelectionChange: function handleSelectionChange(selection) {
      console.log(selection);
      this.selectedArr = selection;
      //   const arr = this.selectedArr.map(item => item.id);
    },
    showDetail: function showDetail(row) {
      var _this3 = this;
      var params = {
        naireId: this.$route.query.id,
        recycleId: row.recycle_id,
        mer_id: localStorage.getItem("mer_id")
      };
      (0, _questionnaire._gerQuestionnaireInfoDetail)(params).then(function (res) {
        if (res.status === 200) {
          _this3.showFlag = true;
          res.data.recycleAnswerList.forEach(function (obj) {
            var newobj = obj;
            if (newobj.optionData && newobj.optionData.length) {
              for (var i = 0; i < newobj.optionData.length; i += 1) {
                for (var j = 0; j < newobj.option_id.length; j += 1) {
                  if (Number(newobj.optionData[i].option_id) === Number(newobj.option_id[j])) {
                    newobj.optionData[i].isSelect = true;
                  }
                }
              }
            }
          });
          _this3.askDetail = res.data;
          _this3.title = res.data.title;
        }
      });
    },
    delAsk: function delAsk() {
      var _this4 = this;
      if (this.selectedArr && this.selectedArr.length) {
        var arr = this.selectedArr.map(function (item) {
          return item.recycle_id;
        });
        (0, _questionnaire._deleteQuestionnaireInfoDetail)({
          recycle_id: arr.join(",")
        }).then(function (res) {
          if (res.status === 200) {
            _this4.$message.success("删除成功");
            _this4.getList();
          }
        });
      } else {
        this.$message.error("请选择需要删除的回收数据");
      }
    },
    showRefuse: function showRefuse(row) {
      this.row = row;
      this.refuseFlag = true;
    },
    selectStatus: function selectStatus(name) {
      var status = 0;
      if (name === "1") {
        status = 2;
      } else if (name === "2") {
        status = 1;
      } else if (name === "3") {
        status = -1;
      }
      this.status = status;
      this.queryParams.page = 1;
      this.getList();
    },
    onChangeDate: function onChangeDate(e) {
      this.date = e;
    },
    changePageSize: function changePageSize(pageSize) {
      this.queryParams.size = pageSize;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: 1,
          size: pageSize
        }
      });
      this.getList();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      var newPage = page;
      this.$router.push({
        path: this.$route.path,
        query: {
          page: newPage,
          size: this.queryParams.size
        }
      });
      this.getList();
    },
    exportAction: function exportAction() {
      var _this5 = this;
      this.queryParams.category = this.category;
      this.queryParams.uname = this.uname;
      this.queryParams.uid = this.uid;
      this.queryParams.question_id = this.questionId;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      delete params.status;
      if (params.category == 0) {
        params.category = "";
      }
      if (params.question_select && params.question_select.length) {
        params.question_select.forEach(function (item) {
          if ((0, _validate.isArray)(item.option_keyword)) {
            item.option_keyword = item.option_keyword.join(",");
          }
        });
        params.question_select = JSON.stringify(params.question_select);
      }
      params.collection_status = this.cursorResault;
      if (this.date && this.date.length) {
        params.date = this.date.join("-");
      } else {
        params.date = "";
      }
      params.expert_uname = this.expertUname;
      params.expert_uid = this.exportUid;
      // 导出
      (0, _system.exportexcel)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
        export_type: "questionnaireRecycle"
      })).then(function (res) {
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      var type = "questionnaireRecycle";
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    }
  },
  watch: {
    question_select_id: {
      handler: function handler(nval) {
        var obj = this.questionnaireSelectList.find(function (item) {
          return item.question_id == nval;
        });
        if (nval && !obj) {
          var row = this.questionnaireInfoList.find(function (item) {
            return item.question_id == nval;
          });
          this.questionnaireSelectList.push(row);
          var _obj = {
            question_id: row.question_id,
            question_type: row.question_type
          };
          if (row.question_type != "2") _obj.option_keyword = "";else _obj.option_keyword = [];
          this.question_select.push(_obj);
        }
      }
    }
  },
  created: function created() {
    this.getList();
    this.getDetail();
  }
};