import { render, staticRenderFns } from "./basicsMain.vue?vue&type=template&id=6f327ff3&scoped=true"
import script from "./basicsMain.vue?vue&type=script&scoped=true&lang=js"
export * from "./basicsMain.vue?vue&type=script&scoped=true&lang=js"
import style0 from "./basicsMain.vue?vue&type=style&index=0&id=6f327ff3&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f327ff3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f327ff3')) {
      api.createRecord('6f327ff3', component.options)
    } else {
      api.reload('6f327ff3', component.options)
    }
    module.hot.accept("./basicsMain.vue?vue&type=template&id=6f327ff3&scoped=true", function () {
      api.rerender('6f327ff3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/decoration/basicsMain.vue"
export default component.exports