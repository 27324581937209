"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _settings = require("@/settings");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "airdropList",
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      props: {
        value: "store_brand_category_id",
        label: "cate_name",
        children: "children",
        emitPath: false
      },
      isChecked: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        type: ''
      },
      imgList: [],
      brandCategory: [],
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      parseTime: _index.parseTime,
      userflag: false,
      newlistLoading: true,
      newtableData: {
        data: [],
        total: 0
      },
      newtableFrom: {
        page: 1,
        limit: 20,
        status: 1
      }
    };
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import";
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 下载
    downLoad: function downLoad() {
      window.open(_settingMer.default.https + "/excel/templateDownload?type=import_store_brand&token=" + (0, _auth.getToken)());
    },
    // 上传成功
    handleSuccess: function handleSuccess(response) {
      if (response.status === 200) {
        this.$message.success("上传成功");
        this.getList();
      } else {
        this.$message.error(response.message);
      }
      this.$refs.uploadImg.clearFiles();
    },
    showuser: function showuser(id) {
      this.sid = id;
      this.userflag = true;
      this.getuserList(1);
    },
    getuserList: function getuserList(num) {
      var _this = this;
      this.newlistLoading = true;
      this.newtableFrom.page = num || this.tableFrom.page;
      this.newtableFrom.task_id = this.sid;
      (0, _product.airdropUserListApi)(this.newtableFrom).then(function (res) {
        _this.newtableData.data = res.data.list;
        _this.newtableData.total = res.data.count;
        _this.newlistLoading = false;
      }).catch(function (res) {
        _this.newlistLoading = false;
        // this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      var tableJson = JSON.parse(JSON.stringify(this.tableFrom));
      if (tableJson.type == 0) {
        tableJson.type = '';
      }
      (0, _product.airdropListApi)(tableJson).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    newpageChange: function newpageChange(page) {
      this.newtableFrom.page = page;
      this.getuserList();
    },
    newhandleSizeChange: function newhandleSizeChange(val) {
      this.newtableFrom.limit = val;
      this.getuserList();
    },
    // 添加
    onAdd: function onAdd() {
      this.$router.push({
        path: _settings.roterPre + "/product/addAirdrop"
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      this.$router.push({
        path: _settings.roterPre + "/product/addAirdrop",
        query: {
          id: id
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _product.airdropDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success('删除成功');
          _this3.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this4 = this;
      brandStatusApi(row.brand_id, row.is_show).then(function (_ref3) {
        var message = _ref3.message;
        _this4.$message.success(message);
        _this4.getList();
      }).catch(function (_ref4) {
        var message = _ref4.message;
      });
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this5 = this;
      this.tableFrom.export_type = "store_brand";
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("store_brand");
    }
  }
};