var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.only ? "width: 100%;height: 70px;" : "height: auto;" },
    [
      _c("img", {
        staticStyle: { width: "100%", height: "100%", "object-fit": "cover" },
        style: {
          "border-radius":
            _vm.facade.imgTopLeftRadius / 2 +
            "px " +
            _vm.facade.imgTopRightRadius / 2 +
            "px " +
            _vm.facade.imgBottomRightRadius / 2 +
            "px " +
            _vm.facade.imgBottomLeftRadius / 2 +
            "px",
        },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }