var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c("div", {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            }),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData.data,
                  size: "small",
                  "row-key": "purchase_id",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "purchase_id",
                    label: "ID",
                    "min-width": "60",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "status", label: "类别", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row.type_text))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "快照名单", "min-width": "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.snapshotData
                            ? _vm._l(
                                scope.row.snapshotData,
                                function (item, index) {
                                  return _c("div", { key: index }, [
                                    _vm._v(
                                      "\n                 " +
                                        _vm._s(item.title) +
                                        "\n              "
                                    ),
                                  ])
                                }
                              )
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "商品名单", "min-width": "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.productData
                            ? _vm._l(
                                scope.row.productData,
                                function (item, index) {
                                  return _c("div", { key: index }, [
                                    _vm._v(
                                      "\n                 " +
                                        _vm._s(item.title) +
                                        "\n              "
                                    ),
                                  ])
                                }
                              )
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "ahead_time",
                    label: "提前时间（分钟）",
                    "min-width": "100",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "100", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return undefined
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [20, 40, 60, 80],
                    "page-size": _vm.tableFrom.limit,
                    "current-page": _vm.tableFrom.page,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.pageChange,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("file-list", { ref: "exportList" }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }