"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _ueditorFrom = _interopRequireDefault(require("@/components/ueditorFrom"));
var _vueUeditorWrap = _interopRequireDefault(require("vue-ueditor-wrap"));
var _contract = require("@/api/contract");
var _preview = _interopRequireDefault(require("./preview.vue"));
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "contractDetail",
  components: {
    ueditorFrom: _ueditorFrom.default,
    VueUeditorWrap: _vueUeditorWrap.default,
    preview: _preview.default
  },
  data: function data() {
    return {
      formatDate: _index.parseTime,
      nowdate: +new Date(),
      ownJson: [],
      otherJson: [],
      content: "",
      drawer: false,
      titleDisponsejson: {
        direc: 2,
        place: 1,
        linenum: 3
      },
      signDisponsejson: {
        direc: 2,
        place: 2
      },
      signatureFlag: false,
      expiry_date_switch: false,
      entrust_order_switch: false,
      showpreview: false,
      ownsigndata: [{
        name: '公司名称',
        value: '拍卖人',
        select: true,
        type: 'diabled',
        typename: 'company'
      }, {
        name: '联系人',
        value: '拍卖人授权经办人',
        select: true,
        type: 'nomal',
        typename: 'contact'
      }, {
        name: '声明文字',
        value: '',
        select: false,
        type: 'textarea',
        typename: 'sign'
      }],
      othersigndata: [{
        name: '公司名称',
        value: '委托人',
        select: true,
        type: 'diabled',
        typename: 'company'
      }, {
        name: '联系人',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'contact'
      }, {
        name: '声明文字',
        value: '委托人声明：本人已理解各项款项',
        select: true,
        type: 'textarea',
        typename: 'sign'
      }],
      owndata: [{
        name: '公司名称',
        value: '拍卖人',
        select: true,
        type: 'diabled',
        typename: 'company'
      }, {
        name: '地址',
        value: '',
        select: true,
        type: 'nomal',
        typename: 'address'
      }, {
        name: '网址',
        value: '邮编',
        select: true,
        type: 'nomal',
        typename: 'siteaddress'
      }, {
        name: '座机',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'telphone'
      }, {
        name: '传真',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'fax'
      }, {
        name: '邮箱',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'email'
      }, {
        name: '联系人',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'contact'
      }, {
        name: '手机号',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'phone'
      }, {
        name: '开户名称',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'account_name'
      }, {
        name: '开户行',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'account_bank'
      }, {
        name: '账号',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'account'
      }, {
        name: '国籍',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'nationality'
      }, {
        name: '证件类型',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'cardtype'
      }, {
        name: '证件号码',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'cardno'
      }],
      otherdata: [{
        name: '公司名称',
        value: '委托方',
        select: true,
        type: 'diabled',
        typename: 'company'
      }, {
        name: '联系人',
        value: '委托方联系人',
        select: true,
        type: 'nomal',
        typename: 'contact'
      }, {
        name: '国籍',
        value: '',
        select: true,
        type: 'nomal',
        typename: 'nationality'
      }, {
        name: '证件类型',
        value: '身份证',
        select: true,
        type: 'nomal',
        typename: 'cardtype'
      }, {
        name: '证件号码',
        value: '',
        select: true,
        type: 'nomal',
        typename: 'cardno'
      }, {
        name: '手机号',
        value: '手机',
        select: true,
        type: 'nomal',
        typename: 'phone'
      }, {
        name: '地址',
        value: '通信地址',
        select: true,
        type: 'nomal',
        typename: 'address'
      }, {
        name: '座机',
        value: '固定电话',
        select: true,
        type: 'nomal',
        typename: 'telphone'
      }, {
        name: '传真',
        value: '',
        select: true,
        type: 'nomal',
        typename: 'fax'
      }, {
        name: '邮箱',
        value: 'E-mail',
        select: true,
        type: 'nomal',
        typename: 'email'
      }, {
        name: '开户行',
        value: '',
        select: true,
        type: 'nomal',
        typename: 'account_bank'
      }, {
        name: '开户名称',
        value: '',
        select: true,
        type: 'nomal',
        typename: 'account_name'
      }, {
        name: '账号',
        value: '银行账号',
        select: true,
        type: 'nomal',
        typename: 'account'
      }, {
        name: '网址',
        value: '',
        select: false,
        type: 'nomal',
        typename: 'siteaddress'
      }]
    };
  },
  created: function created() {
    this.id = this.$route.params.id || '';
    this.getdetail();
  },
  methods: {
    close: function close() {
      this.$router.back();
    },
    getdetail: function getdetail() {
      var _this = this;
      (0, _contract.getmodalDetail)(this.id).then(function (res) {
        var data = res.data;
        _this.expiry_date_switch = !!data.expiry_date_switch || false;
        _this.entrust_order_switch = !!data.entrust_order_switch || false;
        if (data.content) {
          _this.content = data.content.content;
        }
        if (data.title_fields_data) {
          var titledata = JSON.parse(data.title_fields_data);
          _this.titleDisponsejson = titledata.config;
          _this.owndata = titledata.owndata;
          _this.otherdata = titledata.otherdata;
        }
        if (data.signature_area_fields_data) {
          var signdata = JSON.parse(data.signature_area_fields_data);
          _this.signDisponsejson = signdata.config;
          _this.ownsigndata = signdata.ownsigndata;
          _this.othersigndata = signdata.othersigndata;
        }
      });
    },
    editDetail: function editDetail() {
      var _this2 = this;
      var json = {
        title_fields_data: JSON.stringify({
          config: this.titleDisponsejson,
          owndata: this.owndata,
          otherdata: this.otherdata
        }),
        expiry_date_switch: Number(this.expiry_date_switch),
        entrust_order_switch: Number(this.entrust_order_switch),
        signature_area_fields_data: JSON.stringify({
          config: this.signDisponsejson,
          ownsigndata: this.ownsigndata,
          othersigndata: this.othersigndata
        }),
        content: this.content
      };
      (0, _contract.editmodalDetail)(this.id, json).then(function (res) {
        _this2.$message.success('保存成功');
        _this2.close();
      });
    },
    changedraw: function changedraw() {
      this.drawer = true;
    },
    changeselect: function changeselect(index, item, type) {
      // if(item.type == 'diabled') {
      //   return;
      // }
      // if(type == 'own'){
      //   this.owndata[index].select = !this.otherdata[index].select;
      // }else if(type == 'other'){
      //   this.otherdata[index].select = !this.otherdata[index].select;
      // }
      this.$forceUpdate();
    },
    changesort: function changesort(index, type) {
      if (index == 0) {
        return;
      }
      if (type == 'own') {
        var data = JSON.parse(JSON.stringify(this.owndata));
        var jsondata = JSON.parse(JSON.stringify(data[index - 1]));
        var currentdata = JSON.parse(JSON.stringify(data[index]));
        // this.owndata[index] = jsondata;
        // this.owndata[index-1] = currentdata;
        data[index] = jsondata;
        data[index - 1] = currentdata;
        this.owndata = data;
      } else if (type == 'other') {
        var _data = JSON.parse(JSON.stringify(this.otherdata));
        var _jsondata = JSON.parse(JSON.stringify(_data[index - 1]));
        var _currentdata = JSON.parse(JSON.stringify(_data[index]));
        _data[index] = _jsondata;
        _data[index - 1] = _currentdata;
        this.otherdata = _data;
        // this.otherdata[index] = jsondata;
        // this.otherdata[index-1] = currentdata;
      }
      this.$forceUpdate();
    },
    placechange: function placechange() {
      console.log(this.titleDisponsejson);
      if (this.titleDisponsejson.place == 2) {
        if (this.titleDisponsejson.linenum == 3) {
          this.titleDisponsejson.linenum = 2;
        }
      }
    }
  }
};