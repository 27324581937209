var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "common-page-title" }, [
        _c("img", {
          staticClass: "back",
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024529/image/1716954194860207587.png!120a",
            alt: "返回",
          },
          on: { click: _vm.backAction },
        }),
        _vm._v("统计"),
      ]),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c("div", { staticClass: "album-name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c("div", { staticClass: "top-data flex" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(_vm._s(_vm.albumStatistical.deal_price_format)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("总成交额(元)")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(_vm._s(_vm.albumStatistical.deal_rate) + "%"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("成交率")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(_vm._s(_vm.albumStatistical.deal_user_num)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("买家数量")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(_vm._s(_vm.albumStatistical.online_bid_num)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("网络买家出价次数")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(_vm._s(_vm.albumStatistical.offline_bid_num)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("现场买家出价次数")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ranking" }, [
            _c("div", { staticClass: "l" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title flex align-items-c",
                      on: {
                        click: function ($event) {
                          return _vm.showMoreAction(1, "拍品围观排行")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "flex-one lt" }, [
                        _vm._v("拍品围观排行"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", size: "mini" } },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("查看更多"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dataTemplate", {
                    attrs: { val: _vm.clickList, type: "1", page: 1 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title flex align-items-c",
                      on: {
                        click: function ($event) {
                          return _vm.showMoreAction(4, "买家成交额排行")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "flex-one lt" }, [
                        _vm._v("买家成交额排行"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", size: "mini" } },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("查看更多"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dataTemplate", {
                    attrs: { val: _vm.ideaPriceRanking, type: "4", page: 1 },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title flex align-items-c",
                      on: {
                        click: function ($event) {
                          return _vm.showMoreAction(2, "拍品出价次数排行")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "flex-one lt" }, [
                        _vm._v("拍品出价次数排行"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", size: "mini" } },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("查看更多"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dataTemplate", {
                    attrs: { val: _vm.bidNumList, type: "2", page: 1 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title flex align-items-c",
                      on: {
                        click: function ($event) {
                          return _vm.showMoreAction(5, "买家成交数量排行")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "flex-one lt" }, [
                        _vm._v("买家成交数量排行"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", size: "mini" } },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("查看更多"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dataTemplate", {
                    attrs: { val: _vm.ideaNumRanking, type: "5", page: 1 },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title flex align-items-c",
                      on: {
                        click: function ($event) {
                          return _vm.showMoreAction(3, "拍品成交价排行")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "flex-one lt" }, [
                        _vm._v("拍品成交价排行"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", size: "mini" } },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("查看更多"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dataTemplate", {
                    attrs: { val: _vm.priceList, type: "3", page: 1 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title flex align-items-c",
                      on: {
                        click: function ($event) {
                          return _vm.showMoreAction(6, "买家出价次数排行")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "flex-one lt" }, [
                        _vm._v("买家出价次数排行"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", size: "mini" } },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("查看更多"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dataTemplate", {
                    attrs: { val: _vm.bidNumRanking, type: "6", page: 1 },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("dataDrawerTemplate", { ref: "dataDrawerTemplateEL" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }