var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _vm.collectMoney.show
        ? _c(
            "div",
            {
              staticStyle: { padding: "0 20px 0 10px" },
              attrs: { id: "printorder" },
            },
            [
              _c("div", { staticStyle: { height: "150px" } }, [
                _c("div", { staticClass: "detail-box" }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-bottom": "30px" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex", width: "100%" } },
                    [
                      _c("div", { staticStyle: { width: "100px" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            flex: "1",
                            "font-weight": "500",
                            "margin-left": "5px",
                            "white-space": "pre-wrap",
                          },
                        },
                        [_vm._v(_vm._s(_vm.collectMoney.data.venue_info.title))]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _vm.collectMoney.data.userVerifyInfo
                    ? _c(
                        "div",
                        { staticStyle: { display: "flex", width: "34%" } },
                        [
                          _c("div", { staticStyle: { width: "100px" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                flex: "1",
                                "font-weight": "500",
                                "margin-left": "5px",
                                "white-space": "pre-wrap",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.userVerifyInfo.real_name
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm.collectMoney.data.userInfo
                    ? _c(
                        "div",
                        { staticStyle: { display: "flex", width: "34%" } },
                        [
                          _c("div", { staticStyle: { width: "100px" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                flex: "1",
                                "font-weight": "500",
                                "margin-left": "5px",
                                "white-space": "pre-wrap",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.collectMoney.data.userInfo.nickname)
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        { staticStyle: { display: "flex", width: "34%" } },
                        [
                          _c("div", { staticStyle: { width: "100px" } }),
                          _vm._v(" "),
                          _c("span", {
                            staticStyle: {
                              flex: "1",
                              "font-weight": "500",
                              "margin-left": "5px",
                              "white-space": "pre-wrap",
                            },
                          }),
                        ]
                      ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticStyle: { width: "125px" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          flex: "1",
                          "font-weight": "500",
                          "margin-left": "5px",
                          "white-space": "pre-wrap",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.collectMoney.data.numberPlate
                              ? _vm.collectMoney.data.numberPlate.number
                              : ""
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticStyle: { width: "95px" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          flex: "1",
                          "font-weight": "500",
                          "margin-left": "5px",
                          "white-space": "pre-wrap",
                        },
                      },
                      [_vm._v(_vm._s(_vm.collectMoney.data.newtime))]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "padding-right": "20px" } }, [
                _c(
                  "div",
                  { staticClass: "ptd-box" },
                  [
                    _c("div", { staticClass: "ptd" }, [
                      _c("div", {
                        staticClass: "ptd-title",
                        staticStyle: { flex: "1" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-title",
                        staticStyle: { flex: "2" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-title",
                        staticStyle: { flex: "2" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-title",
                        staticStyle: { flex: "2" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-title",
                        staticStyle: { flex: "2" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-title",
                        staticStyle: { flex: "2" },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.collectMoney.data.orderList, function (m, i) {
                      return _c(
                        "div",
                        { key: "order" + i, staticClass: "ptd" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { width: "90px" },
                            },
                            [
                              _vm._v(
                                _vm._s(m.auction_info ? m.auction_info.lot : "")
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "ptd-body",
                            staticStyle: { width: "100px" },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { width: "220px" },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    m.auction_info
                                      ? m.auction_info.store_name
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { width: "150px" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  m.product_price
                                    ? _vm.formatPriceNumber(m.product_price, 2)
                                    : ""
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { width: "120px" },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    m.product_price
                                      ? _vm.formatPriceNumber(
                                          m.commission_price,
                                          2
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              m.commission_rate && _vm.settlementRatio != "1"
                                ? _c("div", [
                                    _vm._v(
                                      " / " + _vm._s(m.commission_rate) + "%"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { width: "120px" },
                            },
                            [
                              m.total_postage && m.delivery_type == 1
                                ? _c("div", [
                                    _vm._v(
                                      "运费：" +
                                        _vm._s(
                                          m.product_price
                                            ? _vm.formatPriceNumber(
                                                m.total_postage,
                                                2
                                              )
                                            : ""
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              m.admin_discount_price
                                ? _c("div", [
                                    _vm._v(
                                      "优惠：-" +
                                        _vm._s(
                                          m.product_price
                                            ? _vm.formatPriceNumber(
                                                m.admin_discount_price,
                                                2
                                              )
                                            : ""
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { width: "150px" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  m.product_price
                                    ? _vm.formatPriceNumber(m.payable_price, 2)
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd" }, [
                      _c("div", {
                        staticClass: "ptd-body",
                        staticStyle: { width: "90px" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-body",
                        staticStyle: { width: "70px" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "ptd-body",
                        staticStyle: { width: "250px" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: { width: "150px" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.formatPriceNumber(_vm.total_price, 2))
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_commission_price,
                                2
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _vm.collectMoney.data.total_postage
                            ? _c("div", [
                                _vm._v(
                                  "运费：" +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data.total_postage,
                                        2
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.collectMoney.data.total_admin_discount_price
                            ? _c("div", [
                                _vm._v(
                                  "优惠：-" +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .total_admin_discount_price,
                                        2
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: { width: "150px" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_payable_price,
                                2
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd" }, [
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: {
                            width: "230px",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("span", { staticStyle: { "text-align": "left" } }),
                          _vm._v(" "),
                          _c("span", {
                            staticStyle: {
                              "font-family": "'printEN'",
                              "text-align": "left",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: {
                            flex: "1",
                            "font-weight": "bold",
                            "justify-content": "start",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.collectMoney.data.total_pay_price == 0
                                ? ""
                                : _vm.collectMoney.data.total_pay_price_rmb
                            )
                          ),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "13px" },
                },
                [
                  _c("div", {
                    staticStyle: { width: "38%", "padding-left": "120px" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "padding-left": "110px" } }),
                  _vm._v(" "),
                  _c("div"),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }