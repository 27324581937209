"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _settings = _interopRequireWildcard(require("@/settings"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var title = _settings.default.title;
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    },
    menuType: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      title: title,
      logo: _jsCookie.default.get('MerInfo') ? JSON.parse(_jsCookie.default.get('MerInfo')).menu_logo : '',
      slogo: _jsCookie.default.get('MerInfo') ? JSON.parse(_jsCookie.default.get('MerInfo')).menu_slogo : ''
    };
  }
};