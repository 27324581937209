var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "tn-comp-inst",
      staticStyle: { display: "flex", "justify-content": "center" },
    },
    [
      _c(
        "section",
        {
          staticClass: "tn-ground tn-comp-mode-flow",
          style: _vm.dataWidth == 1920 ? "width: 383px" : "width: 250px",
        },
        [
          _c("section", { staticClass: "wxw_wechannel_card_horizontal" }, [
            _c(
              "section",
              {
                staticClass:
                  "wxw_wechannel_card appmsg_card_context appmsg_card_channel js_wechannel_card",
              },
              [
                _c("section", { staticClass: "wxw_wechannel_card_inner" }, [
                  _c("section", { staticClass: "wxw_wechannel_card_bd" }, [
                    _c(
                      "section",
                      {
                        staticClass: "wxw_wechannel_video_context",
                        style:
                          "background-image: url(" +
                          _vm.dataUrl +
                          "); height: " +
                          (_vm.dataWidth == 1920 ? 215 : 332) +
                          "px;",
                      },
                      [
                        _c("i", {
                          staticClass:
                            "weui-play-btn_primary wx_video_play_icon",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      staticClass:
                        "wxw_wechannel_card_ft weui-flex js_wechannel_video_card_nickname",
                      staticStyle: { cursor: "default" },
                    },
                    [
                      _c(
                        "section",
                        { staticClass: "wxw_wechannel_profile weui-flex" },
                        [
                          _c("i", { staticClass: "wxw_wechannel_logo" }),
                          _vm._v(" "),
                          _c(
                            "section",
                            { staticClass: "wxw_wechannel_nickname" },
                            [_vm._v(_vm._s(_vm.dataNickname || "视频号名称"))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }