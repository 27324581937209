var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "information" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.merData,
            rules: _vm.rules,
            "label-width": "0px",
            size: "normal",
          },
        },
        [
          _vm.merModel
            ? _c(
                "div",
                {
                  staticClass: "business-msg",
                  staticStyle: { "padding-bottom": "54px" },
                },
                [
                  _c("div", { staticClass: "moLable" }, [_vm._v("企业LOGO")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex moCon" },
                    [
                      _c("div", { staticClass: "moConItem" }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "750*750px" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("1")
                              },
                            },
                          },
                          [
                            _vm.merData.mer_logo
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.merData.mer_logo },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "imgtt" }, [_vm._v("LOGO")]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _vm._v("130*65px"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "moConItem" }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "750*750px" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("2")
                              },
                            },
                          },
                          [
                            _vm.merData.mer_avatar
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.merData.mer_avatar },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "imgtt" }, [
                          _vm._v("商户头像"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "moConItem" }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            attrs: { title: "750*750px" },
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap("5")
                              },
                            },
                          },
                          [
                            _vm.merData.mer_print_logo
                              ? _c("div", { staticClass: "pictrue" }, [
                                  _c("img", {
                                    attrs: { src: _vm.merData.mer_print_logo },
                                  }),
                                ])
                              : _c("div", { staticClass: "upLoad" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-camera cameraIconfont",
                                  }),
                                ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "imgtt" }, [
                          _vm._v("单据打印"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.merData.mer_logo_data, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "moConItem" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicImg(
                                      "mer_logo_data",
                                      item.key
                                    )
                                  },
                                },
                              },
                              [
                                item.imgUrl
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: { src: item.imgUrl },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  staticStyle: { width: "60px" },
                                  model: {
                                    value: item.name,
                                    callback: function ($$v) {
                                      _vm.$set(item, "name", $$v)
                                    },
                                    expression: "item.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "moConItem" }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalHand("mer_logo_data")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pictrue flex justify-c align-items-c",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "imgtt" }, [
                          _vm._v("生成海报使用 "),
                        ]),
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _vm._v("400*200px"),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "moLable mt20" }, [
                    _vm._v("官方拍卖平台(二维码)"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex moCon" },
                    [
                      _vm._l(
                        _vm.merData.mer_qrcode_data,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "moConItem" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "upLoadPicBox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.modalPicImg(
                                        "mer_qrcode_data",
                                        item.key
                                      )
                                    },
                                  },
                                },
                                [
                                  item.imgUrl
                                    ? _c("div", { staticClass: "pictrue" }, [
                                        _c("img", {
                                          attrs: { src: item.imgUrl },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              [
                                "APP",
                                "小程序",
                                "微信客服",
                                "抖音二维码",
                              ].includes(item.name)
                                ? _c("div", { staticClass: "imgtt" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("el-input", {
                                        staticClass: "sortCol",
                                        staticStyle: { width: "60px" },
                                        model: {
                                          value: item.name,
                                          callback: function ($$v) {
                                            _vm.$set(item, "name", $$v)
                                          },
                                          expression: "item.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "moConItem" }, [
                        _c(
                          "div",
                          {
                            staticClass: "upLoadPicBox",
                            on: {
                              click: function ($event) {
                                return _vm.modalHand("mer_qrcode_data")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "upLoad" }, [
                              _c("i", {
                                staticClass: "el-icon-camera cameraIconfont",
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "imgtt" }, [_vm._v("自定义")]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "moLable mt20" },
                    [
                      _vm._v("自媒体矩阵号 \n      "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.showVisible = true
                            },
                          },
                        },
                        [_vm._v("选择模版")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex moCon" },
                    _vm._l(
                      _vm.merData.mer_media_make_logo_data,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "moConItem" },
                          [
                            _c("div", { staticClass: "upLoadPicBox" }, [
                              _c("div", { staticClass: "upLoad" }, [
                                _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: item.imgUrl },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "10px" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  staticStyle: { width: "60px" },
                                  attrs: { placeholder: "账号名称" },
                                  model: {
                                    value: item.account,
                                    callback: function ($$v) {
                                      _vm.$set(item, "account", $$v)
                                    },
                                    expression: "item.account",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "moLable",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [_vm._v("基本信息")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "moTHead" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("字段")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-one" }, [_vm._v("内容")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [_vm._v("用途描述")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("商户名称")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "",
                              size: "normal",
                              prop: "mer_name",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入商户名称" },
                              model: {
                                value: _vm.merData.mer_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.merData, "mer_name", $$v)
                                },
                                expression: "merData.mer_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("企业全称")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "",
                              size: "normal",
                              prop: "company_name",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入企业名称" },
                              model: {
                                value: _vm.merData.company_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.merData, "company_name", $$v)
                                },
                                expression: "merData.company_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("企业英文名称"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "",
                              size: "normal",
                              prop: "company_english_name",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入企业英文名称" },
                              model: {
                                value: _vm.merData.company_english_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.merData,
                                    "company_english_name",
                                    $$v
                                  )
                                },
                                expression: "merData.company_english_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("品牌名称")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入品牌名称" },
                          model: {
                            value: _vm.merData.mer_brand_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "mer_brand_name", $$v)
                            },
                            expression: "merData.mer_brand_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("品牌英文")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入品牌英文" },
                          model: {
                            value: _vm.merData.mer_brand_english_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.merData,
                                "mer_brand_english_name",
                                $$v
                              )
                            },
                            expression: "merData.mer_brand_english_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("客服电话")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入客服电话" },
                          model: {
                            value: _vm.merData.contact_phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "contact_phone", $$v)
                            },
                            expression: "merData.contact_phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("传真")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入传真" },
                          model: {
                            value: _vm.merData.mer_fax,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "mer_fax", $$v)
                            },
                            expression: "merData.mer_fax",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("开户名称")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入账户名称" },
                          model: {
                            value: _vm.merData.bank_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "bank_name", $$v)
                            },
                            expression: "merData.bank_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同 "),
                      _c("br"),
                      _vm._v(" 用户充值收款 "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("开户行")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入开户行" },
                          model: {
                            value: _vm.merData.bank,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "bank", $$v)
                            },
                            expression: "merData.bank",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同 "),
                      _c("br"),
                      _vm._v(" 用户充值收款"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("银行帐号")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入银行账号" },
                          model: {
                            value: _vm.merData.bank_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "bank_number", $$v)
                            },
                            expression: "merData.bank_number",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同 "),
                      _c("br"),
                      _vm._v(" 用户充值收款"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("详细地址")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "enter-button": "查找位置",
                            placeholder:
                              "请输入地址（地址中请包含城市名称，否则会影响搜索精度）",
                          },
                          model: {
                            value: _vm.merData.company_address,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "company_address", $$v)
                            },
                            expression: "merData.company_address",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("详细地址英文"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder:
                              "请输入英文地址（地址中请包含城市名称，否则会影响搜索精度）",
                          },
                          model: {
                            value: _vm.merData.company_english_address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.merData,
                                "company_english_address",
                                $$v
                              )
                            },
                            expression: "merData.company_english_address",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("邮编")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入邮编" },
                          model: {
                            value: _vm.merData.mer_post_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "mer_post_code", $$v)
                            },
                            expression: "merData.mer_post_code",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("网址")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入网址" },
                            model: {
                              value: _vm.merData.mer_pc_website,
                              callback: function ($$v) {
                                _vm.$set(_vm.merData, "mer_pc_website", $$v)
                              },
                              expression: "merData.mer_pc_website",
                            },
                          },
                          [
                            _c("template", { slot: "prepend" }, [
                              _vm._v("https://www."),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("企微客服链接"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请输入企微客服链接" },
                          model: {
                            value: _vm.merData.customer_service_url,
                            callback: function ($$v) {
                              _vm.$set(_vm.merData, "customer_service_url", $$v)
                            },
                            expression: "merData.customer_service_url",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("企业简介")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-bottom": "0" },
                            attrs: {
                              label: "",
                              size: "normal",
                              prop: "mer_info",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                placeholder: "文字简介，200字以内",
                              },
                              model: {
                                value: _vm.merData.mer_info,
                                callback: function ($$v) {
                                  _vm.$set(_vm.merData, "mer_info", $$v)
                                },
                                expression: "merData.mer_info",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }, [
                      _vm._v("打印单据\\签合同"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "moLable",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [_vm._v("征集信息")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("征集类别")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "textarea",
                            placeholder:
                              "用“、”号隔开，例如：古代书画、邮品钱币、玉石翡翠",
                          },
                          model: {
                            value: _vm.merData.mer_collection_category,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.merData,
                                "mer_collection_category",
                                $$v
                              )
                            },
                            expression: "merData.mer_collection_category",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "moBody" }, [
                    _c("div", { staticClass: "minw" }, [_vm._v("征集邮箱")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      _vm._l(
                        _vm.merData.mer_collection_email_data,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "flex align-items-c mb10",
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "55%" },
                                attrs: { placeholder: "邮箱" },
                                model: {
                                  value: item.email,
                                  callback: function ($$v) {
                                    _vm.$set(item, "email", $$v)
                                  },
                                  expression: "item.email",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: { flex: "1", padding: "0 7px" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "名称" },
                                    model: {
                                      value: item.name,
                                      callback: function ($$v) {
                                        _vm.$set(item, "name", $$v)
                                      },
                                      expression: "item.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              index === 0
                                ? _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-plus",
                                      size: "mini",
                                      circle: "",
                                    },
                                    on: { click: _vm.onAdd },
                                  })
                                : _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-minus",
                                      size: "mini",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDele(index)
                                      },
                                    },
                                  }),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "minw" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "formFixedBox" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.submitLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "自媒体模版",
            visible: _vm.showVisible,
            width: "550px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "60vh", overflow: "auto" } },
            _vm._l(_vm.temData, function (item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  [
                    _vm._v(_vm._s(item.name) + " "),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-left": "20px",
                          "font-size": "12px",
                        },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onAssig(index)
                          },
                        },
                      },
                      [_vm._v("选择它")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(item.imgList, function (ele, i) {
                    return _c("img", {
                      key: i,
                      staticStyle: {
                        width: "50px",
                        height: "50px",
                        "margin-right": "10px",
                      },
                      attrs: { src: ele.url, alt: "" },
                    })
                  }),
                  0
                ),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }