"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ticket = require("@/api/ticket");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      process: '',
      notice: ''
    };
  },
  mounted: function mounted() {
    this.getInfo();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      (0, _ticket.ticketConfInfoApi)().then(function (res) {
        if (res.data.process) {
          _this.process = res.data.process;
          _this.$refs['editor'].setContent(res.data.process || '');
        }
        if (res.data.notice) {
          _this.notice = res.data.notice;
          _this.$refs['editor1'].setContent(res.data.notice || '');
        }
      });
    },
    saveAction: function saveAction() {
      var _this2 = this;
      (0, _ticket.ticketConfCreateApi)({
        process: this.process,
        notice: this.notice
      }).then(function () {
        _this2.$message.success('操作成功');
      });
    }
  }
};