var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("header-breadcrumb", { attrs: { name: "快照名单" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { padding: "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("创建快照")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "text" },
              on: { click: _vm.getExportFileList },
            },
            [_vm._v("导出记录")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%margin-top: 20px" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "small",
                "row-key": "box_id",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "snapshot_id", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "快照名称", prop: "title", "min-width": "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "perform_time",
                  label: "拍照时间",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.parseTime(scope.row.perform_time)) +
                              "\n            "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "人数", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showuser(scope.row.snapshot_id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(scope.row.userCount) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拍照条件（持有数）", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.snapshotCondition,
                        function (item, index) {
                          return _c("div", [
                            item.product
                              ? _c("span", [
                                  _vm._v(_vm._s(item.product.store_name)),
                                ])
                              : _vm._e(),
                            _vm._v(
                              " （" +
                                _vm._s(item.have_count) +
                                "）\n            "
                            ),
                          ])
                        }
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.perform_status == 0
                          ? _c("div", [
                              _vm._v(
                                "\n                 待拍照 \n              "
                              ),
                            ])
                          : scope.row.perform_status == 1
                          ? _c("div", [
                              _vm._v(
                                "\n                已拍照\n              "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.perform_status == 0
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEdit(scope.row.snapshot_id)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { "icon-class": "commonedit" },
                                  }),
                                  _vm._v("编辑"),
                                ],
                                1
                              ),
                            ]
                          : scope.row.userCount > 0
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportOrder(
                                        scope.row.snapshot_id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("导出名单")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.snapshot_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("file-list", { ref: "exportList" }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看快照用户",
            visible: _vm.userflag,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userflag = $event
            },
            close: function ($event) {},
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-list" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.newlistLoading,
                      expression: "newlistLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.newtableData.data,
                    size: "small",
                    "row-key": "box_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", "min-width": "60" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "phone",
                      label: "手机号",
                      "min-width": "100",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.newtableFrom.limit,
                  "current-page": _vm.newtableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.newtableData.total,
                },
                on: {
                  "size-change": _vm.newhandleSizeChange,
                  "current-change": _vm.newpageChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }