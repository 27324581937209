var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasOpen
    ? _c(
        "div",
        [
          _c(
            "div",
            {},
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.createdAction },
                },
                [_vm._v("创建空间")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              staticStyle: { width: "100%", color: "#000" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                key: "1",
                attrs: { label: "空间图片", width: "80", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "num" },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: scope.row.space_image + "!120a",
                                  fix: "cover",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1649146785
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "2",
                attrs: { label: "空间ID", "min-width": "80", align: "left" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "num" }, [
                            _vm._v(_vm._s(scope.row.id)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3212878732
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "3",
                attrs: { label: "空间名称", "min-width": "120", align: "left" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showDetailAction(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.space_name))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3477483116
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "4",
                attrs: {
                  label: "作品数量",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.product_count) + "个"),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3075351655
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "5",
                attrs: {
                  label: "关联同步拍",
                  "min-width": "120",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.album_id ? "已关联" : "")),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2178687044
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "13",
                attrs: {
                  label: "创建时间",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3407778047
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.editAction(scope.row.id)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonedit" },
                              }),
                              _vm._v("编辑"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.delAction(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.addAlbumAction(scope.row)
                                },
                              },
                            },
                            [_vm._v("同步拍")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4059660722
                ),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showChangeAlbum,
                title: "线下同步拍",
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showChangeAlbum = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "change flex align-items-c color-hong" },
                [
                  _vm._v(
                    "选择关联同步拍专场后，场景里除直播外，其他作品、商品、拍品不展示"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "change flex align-items-c mt20" },
                [
                  _c("div", [_vm._v("选择专场：")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择同步拍",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.albumId,
                        callback: function ($$v) {
                          _vm.albumId = $$v
                        },
                        expression: "albumId",
                      },
                    },
                    _vm._l(_vm.albumList, function (m, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { value: m.album_id, label: m.album_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showChangeAlbum = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.btnLoading },
                      on: { click: _vm.changeAlbumAuction },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm.hasOpen === false
    ? _c("div", { staticClass: "all-height item-flex-center" }, [
        _c("img", {
          staticStyle: { height: "150px", margin: "200px 0" },
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20220512/431d21ff8b2f0e5089c57caa9f749a59.png",
          },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }