"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _data_template = _interopRequireDefault(require("./data_template"));
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    dataTemplate: _data_template.default
  },
  data: function data() {
    return {
      title: '',
      type: 1,
      showData: false,
      listLoading: false,
      list: [],
      total: 0,
      queryParams: {
        page: 1,
        limit: 20
      },
      action: null
    };
  },
  methods: {
    init: function init(type, title, key, val) {
      this.title = title;
      this.type = Number(type);
      this.total = 0;
      this.list = [];
      this.showData = true;
      this.action = '';
      this.queryParams = {
        page: 1,
        limit: 20
      };
      this.queryParams[key] = val;
      if (this.type <= 3) {
        this.action = _auction.auctionList;
        if (this.type === 1) {
          this.queryParams['order'] = 'click_desc';
        } else if (this.type === 2) {
          this.queryParams['order'] = 'bid_num_desc';
        } else if (this.type === 3) {
          this.queryParams['order'] = 'price_decs';
        }
      } else if (this.type === 4) {
        this.action = _auction.userIdeaPriceRankingApi;
      } else if (this.type === 5) {
        this.action = _auction.userIdeaNumRankingApi;
      } else if (this.type === 6) {
        this.action = _auction.userBidNumRankingApi;
      }
      this.pageChange(1);
    },
    load: function load() {
      var _this = this;
      this.listLoading = true;
      this.action(this.queryParams).then(function (res) {
        _this.total = res.data.count;
        _this.list = res.data.list;
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    }
  }
};