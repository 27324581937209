"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._updateQuestionnaire = exports._gerTitleList = exports._gerQuestionnaireList = exports._gerQuestionnaireInfoList = exports._gerQuestionnaireInfoDetail = exports._gerQuestionnaireInfo = exports._deleteQuestionnaireInfoDetail = exports._deleteQuestionnaire = exports._createQuestionnaire = exports._changeStatus = void 0;
var _request = _interopRequireDefault(require("./request"));
// 获取问卷列表
var _gerQuestionnaireList = exports._gerQuestionnaireList = function _gerQuestionnaireList(state) {
  return _request.default.get("/activity/questionnaire/list", state);
};

//获取问卷标题列表
var _gerTitleList = exports._gerTitleList = function _gerTitleList(state) {
  return _request.default.get("/activity/questionnaire/titleList", state);
};

// 获取问卷详情
var _gerQuestionnaireInfo = exports._gerQuestionnaireInfo = function _gerQuestionnaireInfo(state) {
  return _request.default.get("/activity/questionnaire/detail/".concat(state.naireId), state);
};

// 创建问卷
var _createQuestionnaire = exports._createQuestionnaire = function _createQuestionnaire(state) {
  return _request.default.post("/activity/questionnaire/create", state);
};

// 更新问卷
var _updateQuestionnaire = exports._updateQuestionnaire = function _updateQuestionnaire(state) {
  return _request.default.post("/activity/questionnaire/update/".concat(state.naireId), state);
};

// 删除问卷详情
var _deleteQuestionnaire = exports._deleteQuestionnaire = function _deleteQuestionnaire(state) {
  return _request.default.post("/activity/questionnaire/delete/".concat(state.naireId), state);
};

// 获取回收站列表
var _gerQuestionnaireInfoList = exports._gerQuestionnaireInfoList = function _gerQuestionnaireInfoList(state) {
  return _request.default.get("/activity/questionnaire/recycle/list", state);
};

// 获取回收站详情
var _gerQuestionnaireInfoDetail = exports._gerQuestionnaireInfoDetail = function _gerQuestionnaireInfoDetail(state) {
  return _request.default.get("/activity/questionnaire/recycle/detail/".concat(state.recycleId), state);
};

// 删除回收站
var _deleteQuestionnaireInfoDetail = exports._deleteQuestionnaireInfoDetail = function _deleteQuestionnaireInfoDetail(state) {
  return _request.default.post("/activity/questionnaire/recycle/delete", state);
};

//修改问卷状态
var _changeStatus = exports._changeStatus = function _changeStatus(state) {
  return _request.default.post("/activity/questionnaire/switchStatus/".concat(state.naireId), state);
};