var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "mt20" },
      [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("创建兑换")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData.data,
              size: "small",
              "row-key": "box_id",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "aircraft_product_id",
                label: "ID",
                "min-width": "60",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "140", label: "名称" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "demo-image__preview",
                              staticStyle: {
                                "flex-shrink": "0",
                                "margin-right": "6px",
                              },
                            },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: scope.row.product_image + "!120a",
                                  "preview-src-list": [scope.row.product_image],
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(" " + _vm._s(scope.row.product_name)),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "类型", prop: "durable", "min-width": "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.type == 2
                        ? _c("div", [_vm._v("实物商品")])
                        : scope.row.type == 1
                        ? _c("div", [_vm._v("数字藏品")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "兑换条件", "min-width": "140" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(scope.row.exchange_conditions_names.coin_num) +
                          "保卫积分\n                    "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "已兑换",
                "min-width": "100",
                prop: "exchange_num",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "add_time",
                label: "创建时间",
                "min-width": "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(scope.row.create_time) +
                          "\n                "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "100", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(
                                scope.row.aircraft_product_id,
                                scope.$index
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        删除\n                    "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("file-list", { ref: "exportList" }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.showImg,
              "close-on-click-modal": false,
              center: "",
              title: "查看二维码",
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showImg = $event
              },
            },
          },
          [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.showImgUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }