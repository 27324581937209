"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _auction = require("@/api/auction");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        page: 1,
        limit: 10,
        user_name_phone_keyword: '',
        date: '',
        title: '',
        status: ''
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      detailData: {
        show: false,
        data: {}
      },
      editStatusData: {
        show: false,
        status: 1,
        remark: '',
        id: ''
      },
      uid: '',
      showdetail: false,
      fileVisible: false
    };
  },
  created: function created() {
    this.queryParams.collect_id = this.$route.query.id || '';
    this.getList(1);
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      (0, _auction.collectAuctionApi)(this.queryParams).then(function (res) {
        res.data.list.forEach(function (m) {
          m.imgList = m.slider_image.split(',');
        });
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    backAction: function backAction() {
      this.$router.back();
    },
    changeStatusAction: function changeStatusAction() {
      this.getList(1);
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.queryParams.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.queryParams.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    showDetailAction: function showDetailAction(item) {
      this.detailData = {
        show: true,
        data: item
      };
    },
    editStatusAction: function editStatusAction(item, type) {
      var _this2 = this;
      if (type === 1) {
        this.$confirm("\u786E\u8BA4\u901A\u8FC7\u5BA1\u6838\u672C\u62CD\u54C1?", '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _auction.collectAuctionEditStatusApi)(item.record_id, {
            status: 1
          }).then(function () {
            _this2.$message.success('操作成功');
            _this2.getList();
          }).catch(function (message) {});
        }).catch(function () {});
      } else {
        this.editStatusData = {
          show: true,
          id: item.record_id,
          status: type,
          remark: ''
        };
      }
    },
    saveEditStatusAction: function saveEditStatusAction() {
      var _this3 = this;
      if (!this.editStatusData.remark) {
        this.$message.error('请填写拒绝理由');
        return false;
      }
      this.$confirm("\u786E\u8BA4\u62D2\u7EDD\u672C\u62CD\u54C1?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.collectAuctionEditStatusApi)(_this3.editStatusData.id, {
          status: -1,
          remark: _this3.editStatusData.remark
        }).then(function () {
          _this3.editStatusData.show = false;
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 详情
    onDetails: function onDetails(row) {
      if (row.uid === 8888) {
        this.editOrderAction(row);
        return false;
      }
      this.row = row;
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    exportOrder: function exportOrder() {
      var _this4 = this;
      var data = (0, _objectSpread2.default)({
        export_type: 'auctionCollect'
      }, this.queryParams);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this4.$createElement;
        _this4.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this4.$msgbox.close();
                _this4.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      var type = 'auctionCollect';
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    }
  }
};