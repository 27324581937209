var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coupon", style: "background: " + _vm.facade.background },
    [
      _c(
        "div",
        {
          staticClass: "coupon-item flex",
          style: _vm.only ? "padding: 0" : "",
        },
        [
          _c(
            "div",
            {
              staticClass: "l",
              style: {
                background:
                  "linear-gradient(135deg, " +
                  _vm.facade.gradient_start +
                  " 0%, " +
                  _vm.facade.gradient_start +
                  " 100%)",
                fontSize: _vm.facade.symbol_font_size / 2 + "px",
                color: _vm.facade.proce_font_color,
                borderRadius:
                  _vm.facade.radius / 2 +
                  "px 0px 0px " +
                  _vm.facade.radius / 2 +
                  "px",
              },
            },
            [
              _vm._v("￥"),
              _c(
                "span",
                {
                  style: {
                    fontSize: _vm.facade.price_font_size / 2 + "px",
                    fontWeight: _vm.facade.price_font_weight,
                  },
                },
                [_vm._v("300")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "yuan",
                style: "background: " + _vm.facade.background,
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "r",
              style: {
                border: "1px solid " + _vm.facade.content_border_color,
                background: _vm.facade.content_background,
                borderRadius:
                  "0 " +
                  _vm.facade.radius / 2 +
                  "px " +
                  _vm.facade.radius / 2 +
                  "px 0",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "r-top flex align-items-c",
                  style: "paddingTop:" + _vm.facade.content_margin / 2 + "px",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "name flex-one line1",
                      style: {
                        fontSize: _vm.facade.name_font_size / 2 + "px",
                        fontWeight: _vm.facade.name_font_weight,
                        color: _vm.facade.name_color,
                      },
                    },
                    [_vm._v("新春置宝全场活动优选新春置宝全场活动优选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "status",
                      style: { color: _vm.facade.status2_color },
                    },
                    [_vm._v("立即领取")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r-price",
                  style: {
                    fontSize: _vm.facade.desc_font_size / 2 + "px",
                    fontWeight: _vm.facade.desc_font_weight,
                    color: _vm.facade.desc_color,
                    paddingTop: _vm.facade.content_margin / 2 + "px",
                  },
                },
                [_vm._v("立减300")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r-center flex align-items-c",
                  style: {
                    fontSize: _vm.facade.message_font_size / 2 + "px",
                    fontWeight: _vm.facade.message_font_weight,
                    color: _vm.facade.message_color,
                    paddingTop: _vm.facade.content_margin / 2 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "name flex-one line1" }, [
                    _vm._v("新春置宝全场活动优选新春置宝全场活动优选"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ml5",
                      style: { color: _vm.facade.more_color },
                    },
                    [_vm._v("查看详细")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r-time",
                  style: {
                    fontSize: _vm.facade.time_font_size / 2 + "px",
                    fontWeight: _vm.facade.time_font_weight,
                    color: _vm.facade.time_color,
                    paddingTop: _vm.facade.content_margin / 2 + "px",
                  },
                },
                [_vm._v("领取后30天内有效")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.only
        ? [
            _c("div", { staticClass: "coupon-item flex" }, [
              _c(
                "div",
                {
                  staticClass: "l",
                  style: {
                    background:
                      "linear-gradient(135deg, " +
                      _vm.facade.gradient_start +
                      " 0%, " +
                      _vm.facade.gradient_start +
                      " 100%)",
                    fontSize: _vm.facade.symbol_font_size / 2 + "px",
                    color: _vm.facade.proce_font_color,
                    borderRadius:
                      _vm.facade.radius / 2 +
                      "px 0px 0px " +
                      _vm.facade.radius / 2 +
                      "px",
                  },
                },
                [
                  _vm._v("￥"),
                  _c(
                    "span",
                    {
                      style: {
                        fontSize: _vm.facade.price_font_size / 2 + "px",
                        fontWeight: _vm.facade.price_font_weight,
                      },
                    },
                    [_vm._v("300")]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "yuan",
                    style: "background: " + _vm.facade.background,
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r",
                  style: {
                    border: "1px solid " + _vm.facade.content_border_color,
                    background: _vm.facade.content_background,
                    borderRadius:
                      "0 " +
                      _vm.facade.radius / 2 +
                      "px " +
                      _vm.facade.radius / 2 +
                      "px 0",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "r-top flex align-items-c",
                      style:
                        "paddingTop:" + _vm.facade.content_margin / 2 + "px",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "name flex-one line1",
                          style: {
                            fontSize: _vm.facade.name_font_size / 2 + "px",
                            fontWeight: _vm.facade.name_font_weight,
                            color: _vm.facade.name_color,
                          },
                        },
                        [_vm._v("新春置宝全场活动优选新春置宝全场活动优选")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "status",
                          style: { color: _vm.facade.status2_color },
                        },
                        [_vm._v("立即领取")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "r-price",
                      style: {
                        fontSize: _vm.facade.desc_font_size / 2 + "px",
                        fontWeight: _vm.facade.desc_font_weight,
                        color: _vm.facade.desc_color,
                        paddingTop: _vm.facade.content_margin / 2 + "px",
                      },
                    },
                    [_vm._v("满1988减300")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "r-center flex align-items-c",
                      style: {
                        fontSize: _vm.facade.message_font_size / 2 + "px",
                        fontWeight: _vm.facade.message_font_weight,
                        color: _vm.facade.message_color,
                        paddingTop: _vm.facade.content_margin / 2 + "px",
                      },
                    },
                    [
                      _c("div", { staticClass: "name flex-one line1" }, [
                        _vm._v("新春置宝全场活动优选新春置宝全场活动优选"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ml5",
                          style: { color: _vm.facade.more_color },
                        },
                        [_vm._v("查看详细")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "r-time",
                      style: {
                        fontSize: _vm.facade.time_font_size / 2 + "px",
                        fontWeight: _vm.facade.time_font_weight,
                        color: _vm.facade.time_color,
                        paddingTop: _vm.facade.content_margin / 2 + "px",
                      },
                    },
                    [_vm._v("2022.01.12-2022.02.12")]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "coupon-item flex" }, [
              _c(
                "div",
                {
                  staticClass: "l",
                  style: {
                    background:
                      "linear-gradient(135deg, " +
                      _vm.facade.gradient_start +
                      " 0%, " +
                      _vm.facade.gradient_start +
                      " 100%)",
                    fontSize: _vm.facade.symbol_font_size / 2 + "px",
                    color: _vm.facade.proce_font_color,
                    borderRadius:
                      _vm.facade.radius / 2 +
                      "px 0px 0px " +
                      _vm.facade.radius / 2 +
                      "px",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      style: {
                        fontSize: _vm.facade.price_font_size / 2 + "px",
                        fontWeight: _vm.facade.price_font_weight,
                      },
                    },
                    [_vm._v("9折")]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "yuan",
                    style: "background: " + _vm.facade.background,
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r",
                  style: {
                    border: "1px solid " + _vm.facade.content_border_color,
                    background: _vm.facade.content_background,
                    borderRadius:
                      "0 " +
                      _vm.facade.radius / 2 +
                      "px " +
                      _vm.facade.radius / 2 +
                      "px 0",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "r-top flex align-items-c",
                      style:
                        "paddingTop:" + _vm.facade.content_margin / 2 + "px",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "name flex-one line1",
                          style: {
                            fontSize: _vm.facade.name_font_size / 2 + "px",
                            fontWeight: _vm.facade.name_font_weight,
                            color: _vm.facade.name_color,
                          },
                        },
                        [_vm._v("新春置宝全场活动优选新春置宝全场活动优选")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "status",
                          style: { color: _vm.facade.status1_color },
                        },
                        [_vm._v("已领取")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "r-price",
                      style: {
                        fontSize: _vm.facade.desc_font_size / 2 + "px",
                        fontWeight: _vm.facade.desc_font_weight,
                        color: _vm.facade.desc_color,
                        paddingTop: _vm.facade.content_margin / 2 + "px",
                      },
                    },
                    [_vm._v("9折优惠")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "r-center flex align-items-c",
                      style: {
                        fontSize: _vm.facade.message_font_size / 2 + "px",
                        fontWeight: _vm.facade.message_font_weight,
                        color: _vm.facade.message_color,
                        paddingTop: _vm.facade.content_margin / 2 + "px",
                      },
                    },
                    [
                      _c("div", { staticClass: "name flex-one line1" }, [
                        _vm._v("全部商品可用"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ml5",
                          style: { color: _vm.facade.more_color },
                        },
                        [_vm._v("查看详细")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "r-time",
                      style: {
                        fontSize: _vm.facade.time_font_size / 2 + "px",
                        fontWeight: _vm.facade.time_font_weight,
                        color: _vm.facade.time_color,
                        paddingTop: _vm.facade.content_margin / 2 + "px",
                      },
                    },
                    [_vm._v("2022.01.12-2022.02.12")]
                  ),
                ]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }