var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "item flex align-items-c h50" }, [
      _c("div", { staticClass: "flex-one" }, [
        _vm._v("委托方收费费率，业务员创建合同时不可更改"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c("el-switch", {
            attrs: { "active-value": 1, "inactive-value": 0 },
            model: {
              value:
                _vm.stateForm.erp_contract_create_entrust_price_update_switch,
              callback: function ($$v) {
                _vm.$set(
                  _vm.stateForm,
                  "erp_contract_create_entrust_price_update_switch",
                  $$v
                )
              },
              expression:
                "stateForm.erp_contract_create_entrust_price_update_switch",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item flex align-items-c h50" }, [
      _c("div", { staticClass: "flex-one" }, [
        _vm._v("启用合同标识，标记合同为特殊合同"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c("el-switch", {
            attrs: { "active-value": 1, "inactive-value": 0 },
            model: {
              value: _vm.stateForm.erp_contract_create_special_switch,
              callback: function ($$v) {
                _vm.$set(
                  _vm.stateForm,
                  "erp_contract_create_special_switch",
                  $$v
                )
              },
              expression: "stateForm.erp_contract_create_special_switch",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "formFixedBox" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }