var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "div",
              {
                staticClass: "banner-img",
                style: {
                  marginTop: _vm.facade.marginTop / 2 + "px",
                  background: _vm.facade.background,
                  width: _vm.facade.view_width / 2 + "px",
                  height: _vm.facade.view_height / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img overflow-hidden",
                    style: {
                      width: _vm.facade.img_width / 2 + "px",
                      height: _vm.facade.img_height / 2 + "px",
                      borderRadius:
                        _vm.facade.imgTopLeftRadius / 2 +
                        "px " +
                        _vm.facade.imgTopRightRadius / 2 +
                        "px " +
                        _vm.facade.imgBottomRightRadius / 2 +
                        "px " +
                        _vm.facade.imgBottomLeftRadius / 2 +
                        "px",
                    },
                  },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        display: "block",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                        fit: _vm.facade.img_style === 2 ? "contain" : "cover",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pointer", {
                  attrs: {
                    color: _vm.facade.indicator_color,
                    uncolor: _vm.facade.indicator_uncolor,
                    type: _vm.facade.indicator_style,
                    align: _vm.facade.indicator_align,
                    "v-align": _vm.facade.indicator_vertical_align,
                    margin: 0,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "div",
              {
                staticClass: "flex align-items-c justify-b",
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c("div", { staticClass: "time1 flex align-items-c" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tag-item flex align-items-c",
                      style: {
                        background: "#F4F5F9",
                        "border-radius": "4px",
                        color: "#363636",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "status",
                          style: {
                            background: "#FF4C4C",
                            "border-radius": "4px",
                            color: "#ffffff",
                          },
                        },
                        [_vm._v("预展中")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _vm._v("距开拍 20分20秒"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c(
                    "div",
                    {
                      staticClass: "r-btn flex align-items-c",
                      style: {
                        color: _vm.facade.color,
                        fontWeight: _vm.facade.fontWeight,
                        "font-size": _vm.facade.fontSize / 2 + "px",
                      },
                    },
                    [
                      _vm.facade.remindIcon
                        ? _c("img", {
                            style:
                              "margin-right: " +
                              _vm.facade.remindMargin / 2 +
                              "px",
                            attrs: { src: _vm.facade.remindIcon },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { position: "relative", bottom: "-1px" },
                        },
                        [_vm._v("提醒")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "r-btn flex align-items-c ml10",
                      style: {
                        color: _vm.facade.color,
                        fontWeight: _vm.facade.fontWeight,
                        "font-size": _vm.facade.fontSize / 2 + "px",
                      },
                    },
                    [
                      _c("img", {
                        style:
                          "margin-right: " + _vm.facade.remindMargin / 2 + "px",
                        attrs: {
                          src:
                            _vm.facade.followIcon ||
                            "https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727159036163748090.png",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { position: "relative", bottom: "-1px" },
                        },
                        [_vm._v("收藏")]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        紫泥菁英2024春拍——紫砂古器与近现代臻品\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "div",
                  {
                    class: { line1: _vm.facade.fold },
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        国内众多的博物馆（院）和实力雄厚的财团，通过拍卖国内众多的博物馆（院）和实力雄厚的财团，通过拍卖\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "price"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px " +
                    _vm.facade.marginBottom / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c("div", { staticClass: "flex align-items-c justify-b" }, [
                  _c(
                    "div",
                    {
                      staticClass: "flex-one",
                      style: {
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        color: _vm.facade.color,
                        fontWeight: _vm.facade.fontWeight,
                      },
                    },
                    [
                      _c("span", [_vm._v("当前价")]),
                      _c("span", { staticClass: "ml5" }, [_vm._v("RMB")]),
                      _c(
                        "span",
                        {
                          staticClass: "price-family ml5 mr5",
                          style: {
                            fontSize: _vm.facade.priceFontSize / 2 + "px",
                            color: _vm.facade.priceColor,
                            fontWeight: _vm.facade.priceFontWeight,
                          },
                        },
                        [_vm._v("90,000")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex align-items-c",
                      style: {
                        fontSize: _vm.facade.aroundFontSize / 2 + "px",
                        color: _vm.facade.aroundColor,
                      },
                    },
                    [
                      _c("div", { staticClass: "l flex align-items-c" }, [
                        _vm.facade.aroundPrevImg
                          ? _c("img", {
                              staticStyle: {
                                width: "16px",
                                height: "16px",
                                display: "block",
                              },
                              attrs: { src: _vm.facade.aroundPrevImg + "!300" },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "c flex-one item-flex-center",
                          class: {
                            mr20: _vm.facade.aroundNextImg,
                            ml20: _vm.facade.aroundPrevImg,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              style: {
                                color: _vm.facade.aroundCurrColor,
                                fontWeight: 500,
                              },
                            },
                            [_vm._v("4")]
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v("/283件")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "r flex align-items-c" }, [
                        _vm.facade.aroundNextImg
                          ? _c("img", {
                              staticStyle: {
                                width: "16px",
                                height: "16px",
                                display: "block",
                              },
                              attrs: { src: _vm.facade.aroundNextImg + "!300" },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c justify-s",
                    style: {
                      "margin-top":
                        _vm.facade.referencePriceMarginTop / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          fontSize: _vm.facade.referenceFontSize / 2 + "px",
                          color: _vm.facade.referenceColor,
                          fontWeight: _vm.facade.referenceFontWeight,
                        },
                      },
                      [
                        _c("span", [_vm._v("估价：")]),
                        _c(
                          "span",
                          {
                            staticClass: "price-family",
                            style: {
                              fontSize:
                                _vm.facade.referencePriceFontSize / 2 + "px",
                              color: _vm.facade.referencePriceColor,
                              fontWeight: _vm.facade.referencePriceFontWeight,
                            },
                          },
                          [_vm._v("RMB 120,000")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c justify-s",
                    style: {
                      "margin-top": _vm.facade.proxyBidMarginTop / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          fontSize: _vm.facade.proxyBidFontSize / 2 + "px",
                          color: _vm.facade.proxyBidColor,
                          fontWeight: _vm.facade.proxyBidFontWeight,
                        },
                      },
                      [
                        _c("span", [_vm._v("我的委托")]),
                        _c(
                          "span",
                          {
                            staticClass: "price-family ml5 mr5",
                            style: {
                              fontSize:
                                _vm.facade.proxyBidPriceFontSize / 2 + "px",
                              color: _vm.facade.proxyBidPriceColor,
                              fontWeight: _vm.facade.proxyBidPriceFontWeight,
                            },
                          },
                          [_vm._v("120,000")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.facade.proxyBidIcon
                      ? _c("div", [
                          _c("img", {
                            style: {
                              width: _vm.facade.proxyBidFontSize / 2 + "px",
                              height: _vm.facade.proxyBidFontSize / 2 + "px",
                            },
                            attrs: { src: _vm.facade.proxyBidIcon + "!120a" },
                          }),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "div",
              {
                style: {
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    style: {
                      height: _vm.facade.titleHeight / 2 + "px",
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      color: _vm.facade.titleColor,
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [_c("div", { staticClass: "flex-one" }, [_vm._v("拍品详情")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: { "margin-bottom": "17px" },
                    style: {
                      fontSize: _vm.facade.attrFontSize / 2 + "px",
                      color: _vm.facade.attrColor,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("拍卖会")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("北京银座第90期线上拍卖会之清朝家具")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                            },
                          },
                          [
                            _c("div", [_vm._v("8个专场")]),
                            _vm._v(" "),
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("拍卖场次")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("紫泥菁英2024春拍")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                            },
                          },
                          [
                            _c("div", [_vm._v("30件拍品")]),
                            _vm._v(" "),
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("预展时间")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("2024年5月9日至5月12日")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("预展地点")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [
                            _vm._v(
                              "北京银座在线微信小程序、抖音小程序、苹果APP、安卓APP"
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("竞价阶梯")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("查看详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                              "min-height": "21px",
                            },
                          },
                          [
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("延时周期")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("最后30秒出价延时5分钟")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("佣金")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("10%-15%")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                              "min-height": "21px",
                            },
                          },
                          [
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("保证金")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("RMB 1000 或 1:5")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                              "min-height": "21px",
                            },
                          },
                          [
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("运费")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("包邮")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("3D展览")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("沉浸式看展")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r flex align-items-c",
                            style: {
                              color: _vm.facade.attrShowColor,
                              fontWeight: _vm.facade.attrShowFontWeight,
                              "min-height": "21px",
                            },
                          },
                          [
                            _vm.facade.showDetailIcon
                              ? _c("img", {
                                  style: {
                                    width: _vm.facade.attrFontSize / 2 + "px",
                                    height: _vm.facade.attrFontSize / 2 + "px",
                                  },
                                  attrs: {
                                    src: _vm.facade.showDetailIcon + "!120a",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("拍卖时间")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("2024年5月9日 10:00")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("款识")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("花好月圆，二零零二年，成喜作于京华")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("钦印")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("王氏翰墨、成喜")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("说明")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("附此件作品与作者合影照片")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("附赠")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("附银座拍卖证书")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-s",
                        style: {
                          padding:
                            _vm.facade.attrPaddingTop / 2 +
                            "px " +
                            _vm.facade.attrPaddingRight / 2 +
                            "px " +
                            _vm.facade.attrPaddingBottom / 2 +
                            "px " +
                            _vm.facade.attrPaddingLeft / 2 +
                            "px",
                          "border-bottom":
                            "1px solid " + _vm.facade.borderColor,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("尺寸")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              color: _vm.facade.attrOtherColor,
                              fontWeight: _vm.facade.attrOtherFontWeight,
                            },
                          },
                          [_vm._v("68.5*68.2cm")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    style: {
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      lineHeight: _vm.facade.lineHeight / 2 + "px",
                    },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "徐秀棠（b.1937）：中国工艺美术大师，中国美术家协会会员，中国陶瓷美术协会理事。作品在全国评比中获奖，被国内外博物馆收藏。著有《紫砂陶刻艺术初论》、《中国紫砂》等书。宜兴长乐弘陶艺有限公司是1993年成立的公认紫砂名牌企业，中国工艺美术大师徐秀棠任长乐弘陶艺艺术总监，培养了一批有创作实力的设计人才及传统工艺制作人员。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(" ")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    style: {
                      paddingBottom:
                        (_vm.facade.hotMarginBottom || 10) / 2 + "px",
                      justifyContent: _vm.facade.hotAlign,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        style: {
                          fontSize: _vm.facade.hotFontSize / 2 + "px",
                          color: _vm.facade.hotColor,
                          fontWeight: _vm.facade.hotFontWeight,
                        },
                      },
                      [
                        _vm.facade.hotIcon
                          ? _c("img", {
                              style: {
                                width: (_vm.facade.hotFontSize + 4) / 2 + "px",
                                height: (_vm.facade.hotFontSize + 4) / 2 + "px",
                              },
                              attrs: { src: _vm.facade.hotIcon + "!120a" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "1px" } }, [
                          _vm._v("6521"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "bid_record"
        ? [
            _c(
              "div",
              {
                style: {
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    style: {
                      height: _vm.facade.titleHeight / 2 + "px",
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      color: _vm.facade.titleColor,
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [
                    _c("div", { staticClass: "flex-one" }, [
                      _vm._v("竞拍记录（12）"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "bid-record" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item flex align-items-c",
                      style:
                        "border-bottom:1px solid " + _vm.facade.borderColor,
                    },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "c flex-one" }, [
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style: {
                              fontSize: _vm.facade.fontSize / 2 + "px",
                              color: _vm.facade.color,
                              fontWeight: _vm.facade.fontWeight,
                            },
                          },
                          [_vm._v("1088号")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "time color-gray ft12",
                            style: {
                              fontSize: _vm.facade.timeFontSize / 2 + "px",
                              color: _vm.facade.timeColor,
                              fontWeight: _vm.facade.timeFontWeight,
                            },
                          },
                          [_vm._v("202-02-03 13:12:13")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price ft12 ml10",
                          style: { color: _vm.facade.priceColor },
                        },
                        [
                          _vm._v("￥"),
                          _c(
                            "span",
                            {
                              style: {
                                fontSize: _vm.facade.priceFontSize / 2 + "px",
                                fontWeight: _vm.facade.priceFontWeight,
                              },
                            },
                            [_vm._v("2522")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.facade.leadIcon
                        ? _c("div", { staticClass: "r" }, [
                            _c("img", {
                              attrs: { src: _vm.facade.leadIcon, alt: "" },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item flex align-items-c",
                      style:
                        "border-bottom:1px solid " + _vm.facade.borderColor,
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("div", { staticClass: "c flex-one" }, [
                        _c(
                          "div",
                          {
                            staticClass: "name line1",
                            style: {
                              fontSize: _vm.facade.fontSize / 2 + "px",
                              color: _vm.facade.color,
                              fontWeight: _vm.facade.fontWeight,
                            },
                          },
                          [_vm._v("比基尼小熊")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "time color-gray ft12",
                            style: {
                              fontSize: _vm.facade.timeFontSize / 2 + "px",
                              color: _vm.facade.timeColor,
                              fontWeight: _vm.facade.timeFontWeight,
                            },
                          },
                          [_vm._v("202-02-03 13:12:00")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "price ft12 ml10",
                          style: { color: _vm.facade.priceColor },
                        },
                        [
                          _vm._v("￥"),
                          _c(
                            "span",
                            {
                              style: {
                                fontSize: _vm.facade.priceFontSize / 2 + "px",
                                fontWeight: _vm.facade.priceFontWeight,
                              },
                            },
                            [_vm._v("1522")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.facade.outIcon
                        ? _c("div", { staticClass: "r" }, [
                            _c("img", {
                              attrs: { src: _vm.facade.outIcon, alt: "" },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "show flex align-items-c justify-c ft12" },
                    [
                      _c(
                        "div",
                        {
                          style: {
                            fontSize: _vm.facade.moreFontSize / 2 + "px",
                            color: _vm.facade.moreColor,
                            fontWeight: _vm.facade.moreFontWeight,
                          },
                        },
                        [_vm._v("查看全部出价")]
                      ),
                      _vm._v(" "),
                      _vm.facade.moreIcon
                        ? _c("img", {
                            staticStyle: {
                              "{ width":
                                "(facade.moreFontSize / 2) + 'px', height: (facade.moreFontSize / 2) + 'px' }",
                            },
                            attrs: { src: _vm.facade.moreIcon + "!120a" },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "div",
              {
                style: {
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    style: {
                      padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                      height: _vm.facade.titleHeight / 2 + "px",
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      color: _vm.facade.titleColor,
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [_vm._v("相关拍品")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "list",
                    style: {
                      margin: "0 -" + _vm.facade.left_margin / 2 + "px",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _vm.listFacade
                      ? _c("auctionList", {
                          attrs: {
                            facade: Object.assign({}, _vm.listFacade, {
                              pageMargin: _vm.pageInfo.page_margin,
                            }),
                            content: { type: "list", style: 2 },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "l" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20210617/a26bbd8f9f78c7f5ce40aee0e00d7a28.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "l" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: {
          src: "https://img.yunzongbu.cn/wechat_head_avatar/359dbd6c473a055aff3b217cb203769461384009d1e00132.jpg",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }