var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "数字员工配置", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c("el-card", [
        _c(
          "div",
          { staticClass: "top-view plr20" },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo default-tabs mt10",
                attrs: { "default-active": _vm.routerUrl, mode: "horizontal" },
                on: { select: _vm.changeTab },
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("任务规则"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "2" } }, [
                  _vm._v("IP配置"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt20" }, [
          _c("div", { staticClass: "item flex align-items-c h50" }, [
            _c("div", { staticClass: "flex-one" }, [
              _vm._v("拍品“结拍快讯”生成条件"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("div", [_vm._v("本场成交价前")]),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "50px" },
                  attrs: { resize: "none" },
                  model: {
                    value: _vm.ruleData.marketMediaAuctionDealTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "marketMediaAuctionDealTop", $$v)
                    },
                    expression: "ruleData.marketMediaAuctionDealTop",
                  },
                }),
                _vm._v(" "),
                _c("div", [_vm._v("件")]),
                _vm._v(" "),
                _c("div", { staticClass: "ml20" }, [_vm._v("本场出价次数前")]),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "50px" },
                  attrs: { resize: "none" },
                  model: {
                    value: _vm.ruleData.marketMediaAuctionBidNumTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "marketMediaAuctionBidNumTop", $$v)
                    },
                    expression: "ruleData.marketMediaAuctionBidNumTop",
                  },
                }),
                _vm._v(" "),
                _c("div", [_vm._v("件")]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item flex align-items-c h50" }, [
            _c("div", { staticClass: "flex-one" }, [
              _vm._v("“结拍快讯”视频名称"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("el-input", {
                  staticStyle: { width: "110px" },
                  attrs: { resize: "none" },
                  model: {
                    value: _vm.ruleData.marketMediaMakeTag2Name,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "marketMediaMakeTag2Name", $$v)
                    },
                    expression: "ruleData.marketMediaMakeTag2Name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item flex align-items-c h50" }, [
            _c("div", { staticClass: "flex-one" }, [
              _vm._v("拍品生成默认解读"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "110px" },
                    model: {
                      value: _vm.ruleData.marketMediaGenerateVideoVoiceId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleData,
                          "marketMediaGenerateVideoVoiceId",
                          $$v
                        )
                      },
                      expression: "ruleData.marketMediaGenerateVideoVoiceId",
                    },
                  },
                  _vm._l(_vm.voiceList, function (m, i) {
                    return _c("el-option", {
                      key: "user" + i,
                      attrs: { value: m.id, label: m.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formFixedBox" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }