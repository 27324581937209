"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _questionnaire = require("@/api/questionnaire");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      title: "",
      intro: "",
      questionList: [],
      id: this.$route.query.id
    };
  },
  methods: {
    getDetail: function getDetail() {
      var _this = this;
      var params = {
        naireId: this.id
      };
      (0, _questionnaire._gerQuestionnaireInfo)(params).then(function (res) {
        if (res.status === 200) {
          var data = res.data;
          _this.title = data.title;
          data.question.forEach(function (item) {
            item.optionList = item.questionOptions;
            delete item.questionOptions;
          });
          _this.questionList = data.question;
          console.log(_this.questionList);
        }
      }).catch(function (err) {
        _this.$message.error(err.message + "");
      });
    },
    back: function back() {
      this.$router.replace("/merchant/questionnaire/askList");
    },
    createQuestion: function createQuestion() {
      var json = {
        content: "",
        description: "",
        question_type: "1",
        is_require: 1,
        optionList: [{
          content: "",
          is_input_allowed: 0
        }, {
          content: "",
          is_input_allowed: 0
        }]
      };
      this.questionList.push(json);
    },
    addOption: function addOption(index) {
      var json = {
        content: "",
        is_input_allowed: 0
      };
      this.questionList[index].optionList.push(json);
    },
    delAwardsAction: function delAwardsAction(index, i) {
      if (this.questionList[index].optionList.length == 1) return;
      this.questionList[index].optionList.splice(i, 1);
    },
    upQuestion: function upQuestion(index) {
      var startindex = index - 1;
      var endindex = index;
      this.questionList[startindex] = this.questionList.splice(endindex, 1, this.questionList[startindex])[0];
    },
    upAwardsAction: function upAwardsAction(index, i) {
      var arr = this.questionList[index].optionList;
      var startindex = i - 1;
      var endindex = i;
      arr[startindex] = arr.splice(endindex, 1, arr[startindex])[0];
    },
    saveAsk: function saveAsk() {
      var _this2 = this;
      if (!this.title) {
        this.$message.error("请输入标题");
        return;
      }
      if (this.id) {
        this.questionList.forEach(function (item) {
          var newitem = item;
          if (item.optionList && item.optionList.length) {
            item.optionList.forEach(function (m) {
              var newm = m;
              if (!m.option_id) {
                newm.option_id = 0;
              }
            });
          }
          if (!newitem.question_id) {
            newitem.question_id = 0;
          }
          newitem.optionJson = item.optionList;
        });
      } else {
        this.questionList.forEach(function (item) {
          var optionJson = [];
          var newitem = item;
          if (item.optionList && item.optionList.length) {
            item.optionList.forEach(function (m) {
              optionJson.push({
                content: m.content,
                is_input_allowed: m.is_input_allowed
              });
            });
          }
          newitem.optionJson = optionJson;
        });
      }
      var questionList = JSON.parse(JSON.stringify(this.questionList));
      for (var i = 0; i < questionList.length; i += 1) {
        delete questionList[i].optionList;
      }
      var json = {
        title: this.title,
        intro: "",
        questionJson: JSON.stringify(questionList)
      };
      if (this.id) {
        json.naireId = this.id;
        (0, _questionnaire._updateQuestionnaire)(json).then(function (res) {
          if (res.status === 200) {
            _this2.$message.success("编辑成功");
            setTimeout(function () {
              _this2.back();
            }, 500);
          }
        }).catch(function (err) {
          /*  this.$message.error(err.message); */
        });
      } else {
        (0, _questionnaire._createQuestionnaire)(json).then(function (res) {
          if (res.status === 200) {
            _this2.$message.success("创建成功");
            setTimeout(function () {
              _this2.back();
            }, 500);
          }
        }).catch(function (err) {
          /*  this.$message.error(err.message); */
        });
      }
    },
    idEditAllowed: function idEditAllowed(index, i) {
      console.log(index, i);
      var list = this.questionList[index].optionList;
      if (list && list.length > 0) {
        list.forEach(function (m, mI) {
          if (mI !== i) {
            m.is_input_allowed = false;
          }
        });
      }
    }
  },
  created: function created() {
    if (this.id) {
      this.getDetail();
    } else {
      this.questionList = [{
        content: "",
        description: "",
        question_type: "1",
        is_require: 1,
        optionList: [{
          content: "",
          is_input_allowed: 0
        }, {
          content: "",
          is_input_allowed: 0
        }]
      }];
    }
  }
};