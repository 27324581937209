var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        background: _vm.facade.backgroundColor,
        padding: (_vm.isComponent ? 30 : 0) + "px 0",
        overflow: "hidden",
      },
    },
    [
      _vm.content.style === 1
        ? _vm._l(2, function (item) {
            return _c(
              "div",
              {
                key: "album1" + item,
                staticClass: "auction-meeting",
                style: {
                  overflow: "visible",
                  "margin-left": _vm.facade.pageMargin / 2 + "px",
                  "padding-bottom":
                    (!_vm.isMeeting || (_vm.isMeeting && item == 1) ? 30 : 0) +
                    "px",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  _vm._l(item == 3 ? 1 : 2, function (m) {
                    return _c(
                      "div",
                      {
                        key: m,
                        staticClass: "album flex relative",
                        class: { m0: m == 1 },
                        style: {
                          "margin-left": _vm.facade.itemMargin / 2 + "px",
                          width:
                            375 -
                            (_vm.facade.pageMargin +
                              _vm.facade.itemMargin / 2 +
                              32) +
                            "px",
                          "border-top-left-radius":
                            _vm.facade.itemTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.itemTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.itemBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.itemBottomRightRadius / 2 + "px",
                          background: _vm.facade.itemGradientBackground
                            ? "linear-gradient(" +
                              _vm.facade.itemGradientDirection +
                              ", " +
                              _vm.facade.itemBackground +
                              ", " +
                              _vm.facade.itemGradientBackground +
                              ")"
                            : _vm.facade.itemBackground,
                          "box-shadow": _vm.facade.itemShadow
                            ? _vm.facade.itemShadowX / 2 +
                              "px " +
                              _vm.facade.itemShadowY / 2 +
                              "px " +
                              _vm.facade.itemShadowSize / 2 +
                              "px " +
                              _vm.facade.itemShadow
                            : "",
                          overflow: "hidden",
                        },
                      },
                      [
                        item == 1
                          ? _c(
                              "div",
                              {
                                style: {
                                  position: "absolute",
                                  top: _vm.facade.statusMarginTop / 2 + "px",
                                  left: _vm.facade.statusMarginLeft / 2 + "px",
                                  "border-top-left-radius":
                                    _vm.facade.statusTopLeftRadius / 2 + "px",
                                  "border-top-right-radius":
                                    _vm.facade.statusTopRightRadius / 2 + "px",
                                  "border-bottom-left-radius":
                                    _vm.facade.statusBottomLeftRadius / 2 +
                                    "px",
                                  "border-bottom-right-radius":
                                    _vm.facade.statusBottomRightRadius / 2 +
                                    "px",
                                  "font-weight": _vm.facade.statusFontWeight,
                                  "font-size":
                                    _vm.facade.statusFontSize / 2 + "px",
                                  background:
                                    _vm.facade.waitingBackgoundColor +
                                    " url(" +
                                    _vm.facade.waitingBackgoundUrl +
                                    ") no-repeat 10px center / 12px 12px",
                                  color: _vm.facade.waitingColor,
                                  padding:
                                    _vm.facade.statusPaddingTop / 2 +
                                    "px " +
                                    _vm.facade.statusPaddingRight / 2 +
                                    "px " +
                                    _vm.facade.statusPaddingBottom / 2 +
                                    "px " +
                                    (_vm.facade.waitingBackgoundUrl
                                      ? (_vm.facade.statusPaddingRight + 32) / 2
                                      : _vm.facade.statusPaddingRight / 2) +
                                    "px",
                                  "z-index": 1,
                                },
                              },
                              [_vm._v("同步拍·预展中")]
                            )
                          : item == 2
                          ? _c(
                              "div",
                              {
                                style: {
                                  position: "absolute",
                                  top: _vm.facade.statusMarginTop / 2 + "px",
                                  left: _vm.facade.statusMarginLeft / 2 + "px",
                                  "border-top-left-radius":
                                    _vm.facade.statusTopLeftRadius / 2 + "px",
                                  "border-top-right-radius":
                                    _vm.facade.statusTopRightRadius / 2 + "px",
                                  "border-bottom-left-radius":
                                    _vm.facade.statusBottomLeftRadius / 2 +
                                    "px",
                                  "border-bottom-right-radius":
                                    _vm.facade.statusBottomRightRadius / 2 +
                                    "px",
                                  "font-weight": _vm.facade.statusFontWeight,
                                  "font-size":
                                    _vm.facade.statusFontSize / 2 + "px",
                                  background:
                                    _vm.facade.ingBackgoundColor +
                                    " url(" +
                                    _vm.facade.ingBackgoundUrl +
                                    ") no-repeat 10px center / 12px 12px",
                                  color: _vm.facade.ingColor,
                                  padding:
                                    _vm.facade.statusPaddingTop / 2 +
                                    "px " +
                                    _vm.facade.statusPaddingRight / 2 +
                                    "px " +
                                    _vm.facade.statusPaddingBottom / 2 +
                                    "px " +
                                    (_vm.facade.ingBackgoundUrl
                                      ? (_vm.facade.statusPaddingRight + 32) / 2
                                      : _vm.facade.statusPaddingRight / 2) +
                                    "px",
                                  "z-index": 1,
                                },
                              },
                              [_vm._v("同步拍·拍卖中")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item == 2
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "album-line flex flex-direction",
                                  staticStyle: { height: "200px" },
                                  style: {
                                    width:
                                      375 -
                                      (_vm.facade.show
                                        ? _vm.facade.pageMargin
                                        : _vm.facade.itemMargin) -
                                      (item == 3 ? 0 : 32) +
                                      "px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c",
                                      style: {
                                        position: "absolute",
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        height: _vm.facade.horHeight / 2 + "px",
                                        background: _vm.facade
                                          .horNameGradientBackground
                                          ? "linear-gradient(" +
                                            _vm.facade
                                              .horNameGradientDirection +
                                            ", " +
                                            _vm.facade.horNameBackground +
                                            ", " +
                                            _vm.facade
                                              .horNameGradientBackground +
                                            ")"
                                          : _vm.facade.horNameBackground,
                                        padding: "0 10px",
                                        "z-index": 1,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex-one line1",
                                          style: {
                                            color: _vm.facade.horNameColor,
                                            "font-weight":
                                              _vm.facade.horNameFontWeight,
                                            "font-size":
                                              _vm.facade.horNameFontSize / 2 +
                                              "px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "第三届大众艺术品拍卖会书画专场传承经典拍卖"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            color: _vm.facade.horTimeColor,
                                            "font-weight":
                                              _vm.facade.horTimeFontWeight,
                                            "font-size":
                                              _vm.facade.horTimeFontSize / 2 +
                                              "px",
                                          },
                                        },
                                        [_vm._v("3月27日9点开拍")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "img flex-one" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "no-img item-flex-center",
                                        style: {
                                          background: _vm.facade.imgBackground,
                                          overflow: "hidden",
                                          "border-top-left-radius":
                                            _vm.facade.imgTopLeftRadius / 2 +
                                            "px",
                                          "border-top-right-radius":
                                            _vm.facade.imgTopRightRadius / 2 +
                                            "px",
                                          "border-bottom-left-radius":
                                            _vm.facade.imgBottomLeftRadius / 2 +
                                            "px",
                                          "border-bottom-right-radius":
                                            _vm.facade.imgBottomRightRadius /
                                              2 +
                                            "px",
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                            display: "block",
                                          },
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                            fit:
                                              _vm.facade.imgStyle == 3
                                                ? "cover"
                                                : "contain",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "div",
                                {
                                  staticClass: "img",
                                  staticStyle: {
                                    width: "120px",
                                    height: "160px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "no-img item-flex-center",
                                      style: {
                                        background: _vm.facade.imgBackground,
                                        overflow: "hidden",
                                        "border-top-left-radius":
                                          _vm.facade.imgTopLeftRadius / 2 +
                                          "px",
                                        "border-top-right-radius":
                                          _vm.facade.imgTopRightRadius / 2 +
                                          "px",
                                        "border-bottom-left-radius":
                                          _vm.facade.imgBottomLeftRadius / 2 +
                                          "px",
                                        "border-bottom-right-radius":
                                          _vm.facade.imgBottomRightRadius / 2 +
                                          "px",
                                      },
                                    },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          display: "block",
                                        },
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                          fit:
                                            _vm.facade.imgStyle == 3
                                              ? "cover"
                                              : "contain",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-one",
                                  style: {
                                    overflow: "hidden",
                                    margin:
                                      _vm.facade.descMarginTop / 2 +
                                      "px " +
                                      _vm.facade.descMarginRight / 2 +
                                      "px " +
                                      _vm.facade.descMarginBottom / 2 +
                                      "px " +
                                      _vm.facade.descMarginLeft / 2 +
                                      "px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "name line2",
                                      style: {
                                        color: _vm.facade.nameColor,
                                        "font-weight":
                                          _vm.facade.nameFontWeight,
                                        "font-size":
                                          _vm.facade.nameFontSize / 2 + "px",
                                        "margin-top":
                                          _vm.facade.nameMarginTop / 2 + "px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "第三届大众艺术品拍卖会书画专场传承经典画作拍卖"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "name",
                                      style: {
                                        color: _vm.facade.albumInfoColor,
                                        "font-weight":
                                          _vm.facade.albumInfoFontWeight,
                                        "font-size":
                                          _vm.facade.albumInfoFontSize / 2 +
                                          "px",
                                        "margin-top":
                                          _vm.facade.albumInfoMarginTop / 2 +
                                          "px",
                                      },
                                    },
                                    [
                                      _c("div", [_vm._v("300件拍品")]),
                                      _c("div", [_vm._v("LOT001-325")]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "name",
                                      style: {
                                        color: _vm.facade.albumTimeColor,
                                        "font-weight":
                                          _vm.facade.albumTimeFontWeight,
                                        "font-size":
                                          _vm.facade.albumTimeFontSize / 2 +
                                          "px",
                                        "margin-top":
                                          _vm.facade.albumTimeMarginTop / 2 +
                                          "px",
                                      },
                                    },
                                    [_vm._v("3月27日 19点开拍")]
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                !_vm.isMeeting
                  ? _c("pointer", {
                      attrs: {
                        color: _vm.facade.indicatorColor,
                        type: _vm.facade.indicatorStyle,
                        align: _vm.facade.indicatorAlign,
                        margin: -5,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          })
        : _vm.content.style === 2
        ? [
            _c(
              "div",
              {
                staticClass: "auction-meeting",
                style: {
                  padding: "0 " + _vm.facade.pageMargin / 2 + "px",
                  overflow: "visible",
                  "padding-bottom": (_vm.content.style === 1 ? 30 : 0) + "px",
                },
              },
              [
                _vm._l(2, function (m, i) {
                  return _c(
                    "div",
                    { key: m, staticClass: "flex align-items-c" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "album flex",
                          class: i == 0 ? "m0" : "",
                          style: {
                            width: "100%",
                            "margin-top": _vm.facade.itemMargin / 2 + "px",
                            "border-top-left-radius":
                              _vm.facade.itemTopLeftRadius / 2 + "px",
                            "border-top-right-radius":
                              _vm.facade.itemTopRightRadius / 2 + "px",
                            "border-bottom-left-radius":
                              _vm.facade.itemBottomLeftRadius / 2 + "px",
                            "border-bottom-right-radius":
                              _vm.facade.itemBottomRightRadius / 2 + "px",
                            background: _vm.facade.itemGradientBackground
                              ? "linear-gradient(" +
                                _vm.facade.itemGradientDirection +
                                ", " +
                                _vm.facade.itemBackground +
                                ", " +
                                _vm.facade.itemGradientBackground +
                                ")"
                              : _vm.facade.itemBackground,
                            "box-shadow": _vm.facade.itemShadow
                              ? _vm.facade.itemShadowX / 2 +
                                "px " +
                                (_vm.facade.itemShadowY || 0) / 2 +
                                "px " +
                                _vm.facade.itemShadowSize / 2 +
                                "px " +
                                _vm.facade.itemShadow
                              : "",
                            overflow: "hidden",
                          },
                        },
                        [
                          m == 1
                            ? _c(
                                "div",
                                {
                                  style: {
                                    position: "absolute",
                                    top: _vm.facade.statusMarginTop / 2 + "px",
                                    left:
                                      _vm.facade.statusMarginLeft / 2 + "px",
                                    "border-top-left-radius":
                                      _vm.facade.statusTopLeftRadius / 2 + "px",
                                    "border-top-right-radius":
                                      _vm.facade.statusTopRightRadius / 2 +
                                      "px",
                                    "border-bottom-left-radius":
                                      _vm.facade.statusBottomLeftRadius / 2 +
                                      "px",
                                    "border-bottom-right-radius":
                                      _vm.facade.statusBottomRightRadius / 2 +
                                      "px",
                                    "font-weight": _vm.facade.statusFontWeight,
                                    "font-size":
                                      _vm.facade.statusFontSize / 2 + "px",
                                    background:
                                      _vm.facade.waitingBackgoundColor +
                                      " url(" +
                                      _vm.facade.waitingBackgoundUrl +
                                      ") no-repeat 10px center / 12px 12px",
                                    color: _vm.facade.ingColor,
                                    padding:
                                      _vm.facade.statusPaddingTop / 2 +
                                      "px " +
                                      _vm.facade.statusPaddingRight / 2 +
                                      "px " +
                                      _vm.facade.statusPaddingBottom / 2 +
                                      "px " +
                                      (_vm.facade.waitingBackgoundUrl
                                        ? (_vm.facade.statusPaddingRight + 32) /
                                          2
                                        : _vm.facade.statusPaddingRight / 2) +
                                      "px",
                                    "z-index": 1,
                                  },
                                },
                                [_vm._v("同步拍·预展中")]
                              )
                            : m == 2
                            ? _c(
                                "div",
                                {
                                  style: {
                                    position: "absolute",
                                    top: _vm.facade.statusMarginTop / 2 + "px",
                                    left:
                                      _vm.facade.statusMarginLeft / 2 + "px",
                                    "border-top-left-radius":
                                      _vm.facade.statusTopLeftRadius / 2 + "px",
                                    "border-top-right-radius":
                                      _vm.facade.statusTopRightRadius / 2 +
                                      "px",
                                    "border-bottom-left-radius":
                                      _vm.facade.statusBottomLeftRadius / 2 +
                                      "px",
                                    "border-bottom-right-radius":
                                      _vm.facade.statusBottomRightRadius / 2 +
                                      "px",
                                    "font-weight": _vm.facade.statusFontWeight,
                                    "font-size":
                                      _vm.facade.statusFontSize / 2 + "px",
                                    background:
                                      _vm.facade.ingBackgoundColor +
                                      " url(" +
                                      _vm.facade.ingBackgoundUrl +
                                      ") no-repeat 10px center / 12px 12px",
                                    color: _vm.facade.liveingColor,
                                    padding:
                                      _vm.facade.statusPaddingTop / 2 +
                                      "px " +
                                      _vm.facade.statusPaddingRight / 2 +
                                      "px " +
                                      _vm.facade.statusPaddingBottom / 2 +
                                      "px " +
                                      (_vm.facade.ingBackgoundUrl
                                        ? (_vm.facade.statusPaddingRight + 32) /
                                          2
                                        : _vm.facade.statusPaddingRight / 2) +
                                      "px",
                                    "z-index": 1,
                                  },
                                },
                                [_vm._v("同步拍·拍卖中")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img",
                                staticStyle: {
                                  width: "135px",
                                  height: "200px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "no-img item-flex-center",
                                    style: {
                                      background: _vm.facade.imgBackground,
                                      overflow: "hidden",
                                      "border-top-left-radius":
                                        _vm.facade.imgTopLeftRadius / 2 + "px",
                                      "border-top-right-radius":
                                        _vm.facade.imgTopRightRadius / 2 + "px",
                                      "border-bottom-left-radius":
                                        _vm.facade.imgBottomLeftRadius / 2 +
                                        "px",
                                      "border-bottom-right-radius":
                                        _vm.facade.imgBottomRightRadius / 2 +
                                        "px",
                                    },
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        display: "block",
                                      },
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                        fit:
                                          _vm.facade.imgStyle == 3
                                            ? "cover"
                                            : "contain",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex-one",
                                style: {
                                  overflow: "hidden",
                                  padding:
                                    _vm.facade.descMarginTop / 2 +
                                    "px " +
                                    _vm.facade.descMarginRight / 2 +
                                    "px " +
                                    _vm.facade.descMarginBottom / 2 +
                                    "px " +
                                    _vm.facade.descMarginLeft / 2 +
                                    "px",
                                  position: "relative",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "line2",
                                    style: {
                                      color: _vm.facade.nameColor,
                                      "font-weight": _vm.facade.nameFontWeight,
                                      "font-size":
                                        _vm.facade.nameFontSize / 2 + "px",
                                      "margin-top":
                                        _vm.facade.nameMarginTop / 2 + "px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "第三届大众艺术品拍卖会书画专场传承经典画作拍卖"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    style: {
                                      color: _vm.facade.albumInfoColor,
                                      "font-weight":
                                        _vm.facade.albumInfoFontWeight,
                                      "font-size":
                                        _vm.facade.albumInfoFontSize / 2 + "px",
                                      "margin-top":
                                        _vm.facade.albumInfoMarginTop / 2 +
                                        "px",
                                    },
                                  },
                                  [_vm._v("300件拍品（LOT001-325）")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    style: {
                                      color: _vm.facade.albumTypeColor,
                                      "font-weight":
                                        _vm.facade.albumTypeFontWeight,
                                      "font-size":
                                        _vm.facade.albumTypeFontSize / 2 + "px",
                                      "margin-top":
                                        _vm.facade.albumTypeMarginTop / 2 +
                                        "px",
                                    },
                                  },
                                  [_vm._v("北京国际饭店")]
                                ),
                                _vm._v(" "),
                                m == 1
                                  ? _c(
                                      "div",
                                      {
                                        style: {
                                          color: _vm.facade.albumTypeColor,
                                          "font-weight":
                                            _vm.facade.albumTypeFontWeight,
                                          "font-size":
                                            _vm.facade.albumTypeFontSize / 2 +
                                            "px",
                                          "margin-top":
                                            _vm.facade.albumTypeMarginTop / 2 +
                                            "px",
                                        },
                                      },
                                      [_vm._v("预展：3月27日 - 28日")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                m == 1
                                  ? _c(
                                      "div",
                                      {
                                        style: {
                                          color: _vm.facade.albumTypeColor,
                                          "font-weight":
                                            _vm.facade.albumTypeFontWeight,
                                          "font-size":
                                            _vm.facade.albumTypeFontSize / 2 +
                                            "px",
                                          "margin-top":
                                            _vm.facade.albumTypeMarginTop / 2 +
                                            "px",
                                        },
                                      },
                                      [_vm._v("开拍：3月29日 10:30")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                m == 1
                                  ? _c(
                                      "div",
                                      {
                                        style: {
                                          color: _vm.facade.albumTypeColor,
                                          "font-weight":
                                            _vm.facade.albumTypeFontWeight,
                                          "font-size":
                                            _vm.facade.albumTypeFontSize / 2 +
                                            "px",
                                          "margin-top":
                                            _vm.facade.albumTypeMarginTop / 2 +
                                            "px",
                                        },
                                      },
                                      [_vm._v("围观：52562人")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                m == 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c",
                                        style: {
                                          color: _vm.facade.nowAuctionColor,
                                          "font-weight":
                                            _vm.facade.nowAuctionFontWeight,
                                          "font-size":
                                            _vm.facade.nowAuctionFontSize / 2 +
                                            "px",
                                          "margin-top":
                                            _vm.facade.nowAuctionMarginTop / 2 +
                                            "px",
                                          position: "absolute",
                                          left:
                                            _vm.facade.descMarginLeft / 2 +
                                            "px",
                                          bottom:
                                            _vm.facade.nowAuctionMarginBottom /
                                              2 +
                                            "px",
                                        },
                                      },
                                      [
                                        _vm.facade.nowAuctionIcon
                                          ? _c("img", {
                                              staticStyle: {
                                                width: "16px",
                                                height: "16px",
                                                "margin-right": "4px",
                                                display: "block",
                                              },
                                              attrs: {
                                                src: _vm.facade.nowAuctionIcon,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v("当前第"),
                                          _c(
                                            "span",
                                            {
                                              style: {
                                                color:
                                                  _vm.facade
                                                    .nowAuctionCurrColor,
                                              },
                                            },
                                            [_vm._v("2")]
                                          ),
                                          _vm._v("/200件"),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                        ],
                        2
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm._l(2, function (m, i) {
                  return _c(
                    "div",
                    {
                      key: "a" + i,
                      staticClass: "album flex",
                      style: {
                        width: "100%",
                        "margin-top": _vm.facade.itemMargin / 2 + "px",
                        "border-top-left-radius":
                          _vm.facade.itemTopLeftRadius / 2 + "px",
                        "border-top-right-radius":
                          _vm.facade.itemTopRightRadius / 2 + "px",
                        "border-bottom-left-radius":
                          _vm.facade.itemBottomLeftRadius / 2 + "px",
                        "border-bottom-right-radius":
                          _vm.facade.itemBottomRightRadius / 2 + "px",
                        background: _vm.facade.itemGradientBackground
                          ? "linear-gradient(" +
                            _vm.facade.itemGradientDirection +
                            ", " +
                            _vm.facade.itemBackground +
                            ", " +
                            _vm.facade.itemGradientBackground +
                            ")"
                          : _vm.facade.itemBackground,
                        "box-shadow": _vm.facade.itemShadow
                          ? _vm.facade.itemShadowX / 2 +
                            "px " +
                            _vm.facade.itemShadowY / 2 +
                            "px " +
                            _vm.facade.itemShadowSize / 2 +
                            "px " +
                            _vm.facade.itemShadow
                          : "",
                        overflow: "hidden",
                        position: "relative",
                      },
                    },
                    [
                      m == 1
                        ? _c(
                            "div",
                            {
                              style: {
                                position: "absolute",
                                top: _vm.facade.statusMarginTop / 2 + "px",
                                left: _vm.facade.statusMarginLeft / 2 + "px",
                                "border-top-left-radius":
                                  _vm.facade.statusTopLeftRadius / 2 + "px",
                                "border-top-right-radius":
                                  _vm.facade.statusTopRightRadius / 2 + "px",
                                "border-bottom-left-radius":
                                  _vm.facade.statusBottomLeftRadius / 2 + "px",
                                "border-bottom-right-radius":
                                  _vm.facade.statusBottomRightRadius / 2 + "px",
                                "font-weight": _vm.facade.statusFontWeight,
                                "font-size":
                                  _vm.facade.statusFontSize / 2 + "px",
                                background:
                                  _vm.facade.ingBackgoundColor +
                                  " url(" +
                                  _vm.facade.ingBackgoundUrl +
                                  ") no-repeat 10px center / 12px 12px",
                                color: _vm.facade.ingColor,
                                padding:
                                  _vm.facade.statusPaddingTop / 2 +
                                  "px " +
                                  _vm.facade.statusPaddingRight / 2 +
                                  "px " +
                                  _vm.facade.statusPaddingBottom / 2 +
                                  "px " +
                                  (_vm.facade.ingBackgoundUrl
                                    ? (_vm.facade.statusPaddingRight + 32) / 2
                                    : _vm.facade.statusPaddingRight / 2) +
                                  "px",
                                "z-index": 1,
                              },
                            },
                            [_vm._v("进行中")]
                          )
                        : m == 2
                        ? _c(
                            "div",
                            {
                              style: {
                                position: "absolute",
                                top: _vm.facade.statusMarginTop / 2 + "px",
                                left: _vm.facade.statusMarginLeft / 2 + "px",
                                "border-top-left-radius":
                                  _vm.facade.statusTopLeftRadius / 2 + "px",
                                "border-top-right-radius":
                                  _vm.facade.statusTopRightRadius / 2 + "px",
                                "border-bottom-left-radius":
                                  _vm.facade.statusBottomLeftRadius / 2 + "px",
                                "border-bottom-right-radius":
                                  _vm.facade.statusBottomRightRadius / 2 + "px",
                                "font-weight": _vm.facade.statusFontWeight,
                                "font-size":
                                  _vm.facade.statusFontSize / 2 + "px",
                                background:
                                  _vm.facade.endBackgoundColor +
                                  " url(" +
                                  _vm.facade.endBackgoundUrl +
                                  ") no-repeat 10px center / 12px 12px",
                                color: _vm.facade.endColor,
                                padding:
                                  _vm.facade.statusPaddingTop / 2 +
                                  "px " +
                                  _vm.facade.statusPaddingRight / 2 +
                                  "px " +
                                  _vm.facade.statusPaddingBottom / 2 +
                                  "px " +
                                  (_vm.facade.endBackgoundUrl
                                    ? (_vm.facade.statusPaddingRight + 32) / 2
                                    : _vm.facade.statusPaddingRight / 2) +
                                  "px",
                                "z-index": 1,
                              },
                            },
                            [_vm._v("已结束")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "c-info flex-one" }, [
                        _c("div", { staticClass: "c-bottom-img" }, [
                          _c(
                            "div",
                            {
                              staticClass: "img",
                              staticStyle: { width: "100%", height: "200px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "no-img item-flex-center",
                                  style: {
                                    background: _vm.facade.imgBackground,
                                    overflow: "hidden",
                                    "border-top-left-radius":
                                      _vm.facade.imgTopLeftRadius / 2 + "px",
                                    "border-top-right-radius":
                                      _vm.facade.imgTopRightRadius / 2 + "px",
                                    "border-bottom-left-radius":
                                      _vm.facade.imgBottomLeftRadius / 2 + "px",
                                    "border-bottom-right-radius":
                                      _vm.facade.imgBottomRightRadius / 2 +
                                      "px",
                                  },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                      display: "block",
                                    },
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                      fit:
                                        _vm.facade.imgStyle == 3
                                          ? "cover"
                                          : "contain",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex-one",
                            style: {
                              overflow: "hidden",
                              margin:
                                _vm.facade.hDescMarginTop / 2 +
                                "px " +
                                _vm.facade.hDescMarginRight / 2 +
                                "px " +
                                _vm.facade.hDescMarginBottom / 2 +
                                "px " +
                                _vm.facade.hDescMarginLeft / 2 +
                                "px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "line2",
                                style: {
                                  color: _vm.facade.nameColor,
                                  "font-weight": _vm.facade.nameFontWeight,
                                  "font-size":
                                    _vm.facade.nameFontSize / 2 + "px",
                                  "margin-top":
                                    _vm.facade.nameMarginTop / 2 + "px",
                                },
                              },
                              [
                                _vm._v(
                                  "第三届大众艺术品拍卖会书画专场传承经典画作拍卖"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.facade.albumInfoColor,
                                  "font-weight": _vm.facade.albumInfoFontWeight,
                                  "font-size":
                                    _vm.facade.albumInfoFontSize / 2 + "px",
                                  "margin-top":
                                    _vm.facade.albumInfoMarginTop / 2 + "px",
                                },
                              },
                              [
                                _c("span", [_vm._v("300件拍品（LOT001-325）")]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v("围观：52562人")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            m == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "flex justify-b align-items-c",
                                    style: {
                                      "margin-top":
                                        _vm.facade.nowAuctionMarginTop / 2 +
                                        "px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        style: {
                                          color: _vm.facade.nowAuctionColor,
                                          "font-weight":
                                            _vm.facade.nowAuctionFontWeight,
                                          "font-size": "12px",
                                        },
                                      },
                                      [_vm._v("拍卖进度")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex align-items-c",
                                        style: {
                                          color: _vm.facade.nowAuctionColor,
                                          "font-weight":
                                            _vm.facade.nowAuctionFontWeight,
                                          "font-size":
                                            _vm.facade.nowAuctionFontSize / 2 +
                                            "px",
                                        },
                                      },
                                      [
                                        _vm.facade.nowAuctionIcon
                                          ? _c("img", {
                                              staticStyle: {
                                                width: "16px",
                                                height: "16px",
                                                "margin-right": "4px",
                                                display: "block",
                                              },
                                              attrs: {
                                                src: _vm.facade.nowAuctionIcon,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v("当前第"),
                                          _c(
                                            "span",
                                            {
                                              style: {
                                                color:
                                                  _vm.facade
                                                    .nowAuctionCurrColor,
                                              },
                                            },
                                            [_vm._v("2")]
                                          ),
                                          _vm._v("/200件"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            m == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "flex justify-b align-items-c",
                                    style: {
                                      "margin-top":
                                        _vm.facade.albumTypeMarginTop / 2 +
                                        "px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        style: {
                                          color: _vm.facade.albumTypeColor,
                                          "font-weight":
                                            _vm.facade.albumTypeFontWeight,
                                          "font-size":
                                            _vm.facade.albumTypeFontSize / 2 +
                                            "px",
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v("北京国际饭店")]),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml10" }, [
                                          _vm._v("3月27日9点30分结束"),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml10" }, [
                                          _vm._v("成交280件"),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.style === 3
        ? [
            _c(
              "div",
              {
                staticClass: "auction-meeting",
                style: {
                  overflow: "visible",
                  "margin-left": _vm.facade.pageMargin / 2 + "px",
                },
              },
              _vm._l(_vm.facade.line || 1, function (item) {
                return _c(
                  "div",
                  {
                    key: "line" + item,
                    staticClass: "flex align-items-c",
                    style:
                      item != 1
                        ? "margin-top: " + _vm.facade.albumLineMargin / 2 + "px"
                        : "",
                  },
                  _vm._l(5, function (m) {
                    return _c(
                      "div",
                      {
                        key: m,
                        staticClass: "album flex relative",
                        class: { m0: m == 1 },
                        style: {
                          "margin-left": _vm.facade.albumMargin / 2 + "px",
                          width: _vm.facade.imgWidth / 2 + "px",
                          height: _vm.facade.imgHeight / 2 + "px",
                          "border-top-left-radius":
                            _vm.facade.simplicityTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.simplicityTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.simplicityBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.simplicityBottomRightRadius / 2 + "px",
                          // 'box-shadow': facade.itemShadow ? `${facade.itemShadowX / 2}px ${facade.itemShadowY / 2}px ${facade.itemShadowSize / 2}px ${facade.itemShadow}` : '',
                          overflow: "hidden",
                        },
                      },
                      [
                        m == 1
                          ? _c(
                              "div",
                              {
                                style: {
                                  position: "absolute",
                                  top: _vm.facade.statusMarginTop / 2 + "px",
                                  left: _vm.facade.statusMarginLeft / 2 + "px",
                                  "border-top-left-radius":
                                    _vm.facade.statusTopLeftRadius / 2 + "px",
                                  "border-top-right-radius":
                                    _vm.facade.statusTopRightRadius / 2 + "px",
                                  "border-bottom-left-radius":
                                    _vm.facade.statusBottomLeftRadius / 2 +
                                    "px",
                                  "border-bottom-right-radius":
                                    _vm.facade.statusBottomRightRadius / 2 +
                                    "px",
                                  "font-weight": _vm.facade.statusFontWeight,
                                  "font-size":
                                    _vm.facade.statusFontSize / 2 + "px",
                                  background:
                                    _vm.facade.waitingBackgoundColor +
                                    " url(" +
                                    _vm.facade.waitingBackgoundUrl +
                                    ") no-repeat 10px center / 12px 12px",
                                  color: _vm.facade.waitingColor,
                                  padding:
                                    _vm.facade.statusPaddingTop / 2 +
                                    "px " +
                                    _vm.facade.statusPaddingRight / 2 +
                                    "px " +
                                    _vm.facade.statusPaddingBottom / 2 +
                                    "px " +
                                    (_vm.facade.waitingBackgoundUrl
                                      ? (_vm.facade.statusPaddingRight + 32) / 2
                                      : _vm.facade.statusPaddingRight / 2) +
                                    "px",
                                  "z-index": 1,
                                },
                              },
                              [_vm._v("同步拍·预展中")]
                            )
                          : m == 2
                          ? _c(
                              "div",
                              {
                                style: {
                                  position: "absolute",
                                  top: _vm.facade.statusMarginTop / 2 + "px",
                                  left: _vm.facade.statusMarginLeft / 2 + "px",
                                  "border-top-left-radius":
                                    _vm.facade.statusTopLeftRadius / 2 + "px",
                                  "border-top-right-radius":
                                    _vm.facade.statusTopRightRadius / 2 + "px",
                                  "border-bottom-left-radius":
                                    _vm.facade.statusBottomLeftRadius / 2 +
                                    "px",
                                  "border-bottom-right-radius":
                                    _vm.facade.statusBottomRightRadius / 2 +
                                    "px",
                                  "font-weight": _vm.facade.statusFontWeight,
                                  "font-size":
                                    _vm.facade.statusFontSize / 2 + "px",
                                  background:
                                    _vm.facade.ingBackgoundColor +
                                    " url(" +
                                    _vm.facade.ingBackgoundUrl +
                                    ") no-repeat 10px center / 12px 12px",
                                  color: _vm.facade.ingColor,
                                  padding:
                                    _vm.facade.statusPaddingTop / 2 +
                                    "px " +
                                    _vm.facade.statusPaddingRight / 2 +
                                    "px " +
                                    _vm.facade.statusPaddingBottom / 2 +
                                    "px " +
                                    (_vm.facade.ingBackgoundUrl
                                      ? (_vm.facade.statusPaddingRight + 32) / 2
                                      : _vm.facade.statusPaddingRight / 2) +
                                    "px",
                                  "z-index": 1,
                                },
                              },
                              [_vm._v("同步拍·拍卖中")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "album-line flex flex-direction",
                            staticStyle: { height: "200px" },
                            style: {
                              width: _vm.facade.imgWidth / 2 + "px",
                              height: _vm.facade.imgHeight / 2 + "px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                style: {
                                  position: "absolute",
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  background:
                                    "linear-gradient(to bottom, " +
                                    _vm.simplicityNameBackground(0) +
                                    ", " +
                                    _vm.simplicityNameBackground(0.5) +
                                    ")",
                                  "z-index": 1,
                                  padding:
                                    _vm.facade.simplicityNamePaddingTop / 2 +
                                    "px " +
                                    _vm.facade.simplicityNamePaddingRight / 2 +
                                    "px " +
                                    _vm.facade.simplicityNamePaddingBottom / 2 +
                                    "px " +
                                    _vm.facade.simplicityNamePaddingLeft / 2 +
                                    "px",
                                  display: "flex",
                                  "flex-direction": "column",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      color: _vm.facade.simplicityTimeColor,
                                      "font-weight":
                                        _vm.facade.simplicityTimeFontWeight,
                                      "font-size":
                                        _vm.facade.simplicityTimeFontSize / 2 +
                                        "px",
                                    },
                                  },
                                  [_vm._v("3月27日9点开拍")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    style: {
                                      color: _vm.facade.simplicityNameColor,
                                      "font-weight":
                                        _vm.facade.simplicityNameFontWeight,
                                      "font-size":
                                        _vm.facade.simplicityNameFontSize / 2 +
                                        "px",
                                      marginTop:
                                        _vm.facade.simplicityNameMarginTop / 2 +
                                        "px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "第三届大众艺术品拍卖会书画专场传承经典拍卖"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "img flex-one" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "no-img item-flex-center",
                                  style: {
                                    background: _vm.facade.simplicityBackground,
                                    overflow: "hidden",
                                    width: _vm.facade.imgWidth / 2 + "px",
                                    height: _vm.facade.imgHeight / 2 + "px",
                                  },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                      display: "block",
                                    },
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                      fit:
                                        _vm.facade.imgStyle == 3
                                          ? "cover"
                                          : "contain",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }