"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      menuKey: this.$route.path
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  created: function created() {
    if (['/merchant/marketing/sms_task/sms/message', '/merchant/marketing/sms_task/sms/sms_sign', '/merchant/marketing/sms_task/sms/sms_template'].includes(this.$route.path)) this.menuKey = '1';
  },
  methods: {
    changeTab: function changeTab(data) {
      var _this = this;
      if (data !== this.menuKey) {
        if (data == '1') this.$router.replace({
          path: '/merchant/marketing/sms_task/sms/message'
        });else this.$router.replace({
          path: data
        });
        this.menuKey = data;
        setTimeout(function () {
          if (['/merchant/marketing/sms_task/sms/message', '/merchant/marketing/sms_task/sms/sms_sign', '/merchant/marketing/sms_task/sms/sms_template'].includes(_this.$route.path)) _this.menuKey = '1';else _this.menuKey = _this.$route.path;
        });
      }
    }
  }
};