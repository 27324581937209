"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: [{
        name: '拍卖专场组件配置',
        id: 315,
        link: '',
        editLink: '/merchant/basics/page?type=album_component'
      }, {
        name: '拍卖专场详情页',
        id: 315,
        link: '/pages/auction/album/index?id=',
        editLink: '/merchant/basics/page?type=album_detail_page'
      }, {
        name: '拍卖拍品组件配置',
        id: 315,
        link: '',
        editLink: '/merchant/basics/page?type=auction_component'
      }, {
        name: '拍卖拍品详情页',
        id: 315,
        link: '/pages/auction/index?id=',
        editLink: '/merchant/basics/page?type=auction_detail_page'
      }, {
        name: '商城商品组件配置',
        id: 315,
        link: '',
        editLink: '/merchant/basics/page?type=product_component'
      }, {
        name: '商城商品详情配置',
        id: 315,
        link: '/pages/productDetail/index?id=',
        editLink: '/merchant/basics/page?type=product_detail_page'
      }, {
        name: 'NFT组件配置',
        id: 315,
        link: '',
        editLink: '/merchant/basics/page?type=nft_component'
      }, {
        name: 'NFT详情配置',
        id: 315,
        link: '/pages/nftCollection/index?id=',
        editLink: '/merchant/basics/page?type=nft_detail_page'
      }, {
        name: '合成活动配置',
        id: 315,
        link: '/pages/marketing/compose/compose?id=',
        editLink: '/merchant/basics/page?type=compose_page'
      }, {
        name: '个人中心页',
        id: 315,
        link: '/pages/users/index',
        editLink: '/merchant/mine/page'
      }, {
        name: '抖音个人中心页',
        id: 315,
        link: '/pages/users/index',
        editLink: '/merchant/mine/page?personal_center_tiktok=1'
      }, {
        name: '盲盒首页配置',
        id: 315,
        link: '/pages/product/blind',
        editLink: '/merchant/basics/page?type=blind_page'
      }],
      listLoading: false,
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0
    };
  },
  mounted: function mounted() {
    // this.load();
  },
  methods: {
    load: function load() {
      var _this = this;
      this.listLoading = true;
      (0, _system.diypageList)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.list = response.data.list;
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    createdDiy: function createdDiy() {
      this.$router.push({
        path: '/merchant/diy/page'
      });
    },
    editDiy: function editDiy(link) {
      this.$router.push({
        path: link
      });
    }
  }
};