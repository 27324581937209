var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.editLoading,
          expression: "editLoading",
        },
      ],
      staticClass: "divBox",
    },
    [
      _c(
        "el-button",
        {
          staticClass: "mr20",
          attrs: { size: "mini", icon: "el-icon-back" },
          on: { click: _vm.close },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        "font-size": "18px",
                      },
                      attrs: { span: 12, offset: 0 },
                    },
                    [_vm._v(_vm._s(_vm.id ? "修改" : "创建") + "委托标的清单")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "委托方", prop: "entrust_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                remote: "",
                                clearable: "",
                                disabled: !!_vm.adminid,
                                "remote-method": _vm.searchUserAction,
                              },
                              model: {
                                value: _vm.formValidate.entrust_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "entrust_id", $$v)
                                },
                                expression: "formValidate.entrust_id",
                              },
                            },
                            _vm._l(_vm.letterArr, function (item) {
                              return _c("el-option", {
                                key: item.entrust_id,
                                attrs: {
                                  label:
                                    item.entrust_contact_company ||
                                    item.entrust_contact_name,
                                  value: item.entrust_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "业务负责人",
                            prop: "manage_admin_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择业务负责人",
                                clearable: "",
                                filterable: "",
                                disabled: !!_vm.serveid,
                              },
                              model: {
                                value: _vm.formValidate.manage_admin_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "manage_admin_id",
                                    $$v
                                  )
                                },
                                expression: "formValidate.manage_admin_id",
                              },
                            },
                            _vm._l(_vm.adminarr, function (item) {
                              return _c("el-option", {
                                key: item.merchant_admin_id,
                                attrs: {
                                  label: item.real_name,
                                  value: item.merchant_admin_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 0 } },
                    [
                      _c("el-form-item", { attrs: { label: "单据编号" } }, [
                        _vm._v(_vm._s(_vm.orderno)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6, offset: 0 } },
                    [
                      _c("el-form-item", { attrs: { label: "制单人" } }, [
                        _vm._v(_vm._s(_vm.mannagename)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: "请选择货品分类",
                          },
                          on: {
                            change: _vm.handleselectCate,
                            "remove-tag": _vm.remotetag,
                          },
                          model: {
                            value: _vm.cate,
                            callback: function ($$v) {
                              _vm.cate = $$v
                            },
                            expression: "cate",
                          },
                        },
                        _vm._l(_vm.merCateList, function (item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: {
                                value: item.store_category_id,
                                label: item.cate_name,
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(item.cate_name))])]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.selecrCatearr, function (tag, index) {
                        return _c(
                          "el-tag",
                          {
                            key: "scate_" + index,
                            staticStyle: { "margin-left": "20px" },
                            attrs: { closable: "", size: "small" },
                            on: {
                              close: function ($event) {
                                return _vm.handleClose(tag)
                              },
                            },
                          },
                          [_vm._v(_vm._s(tag.label))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.selecrCatearr, function (citem, cindex) {
                return [
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-top": "30px" },
                      attrs: {
                        data: citem.goodsArr,
                        size: "small",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c("div", { staticClass: "tableEmpty" }, [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tableDiv" }, [
                            _vm._v("暂无数据"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "分类", prop: "sort" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(citem.label) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "图片" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.product_id > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "demo-image__preview" },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "36px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              src: scope.row.image,
                                              "preview-src-list": [
                                                scope.row.image,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          attrs: { title: "750*750px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap(
                                                "1",
                                                cindex,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          scope.row.image
                                            ? _c(
                                                "div",
                                                { staticClass: "pictrue" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: scope.row.image,
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "upLoad" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-camera cameraIconfont",
                                                  }),
                                                ]
                                              ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "拍品名称", prop: "store_name" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.product_id > 0
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.store_name) +
                                            "\n              "
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: scope.row.store_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "store_name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.store_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "保留价" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    model: {
                                      value: scope.row.retain_price,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "retain_price", $$v)
                                      },
                                      expression: "scope.row.retain_price",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "作者" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.product_id > 0
                                    ? _c("div", [
                                        scope.row.brand
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.brand.brand_name
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: scope.row.brand_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "brand_name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.brand_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "属性",
                            prop: "order_pay_amount",
                            align: "center",
                          },
                        },
                        _vm._l(citem.attrList, function (item, index) {
                          return _c("el-table-column", {
                            key: "ps_" + index,
                            attrs: { label: item.attr_name },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.product_id
                                        ? _vm._l(
                                            scope.row.attrInfo,
                                            function (m, i) {
                                              return m.attr_id == item.attr_id
                                                ? _c(
                                                    "span",
                                                    {
                                                      key: "attr_" + cindex + i,
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            m.attr_value_name
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          )
                                        : _vm._l(
                                            scope.row.attrInfo,
                                            function (m, i) {
                                              return m.attr_id == item.attr_id
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: "attr_" + cindex + i,
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            m.attr_value_name,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              m,
                                                              "attr_value_name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "m.attr_value_name",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm.inventoryConfig.switch.sold_price_switch
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "成交收费", align: "center" } },
                            _vm._l(
                              _vm.inventoryConfig.price.sold_price,
                              function (item, index) {
                                return item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: { label: item.name },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value:
                                                      scope.row.success[index]
                                                        .number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row.success[
                                                          index
                                                        ],
                                                        "number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.success[index].number",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e()
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.inventoryConfig.switch.unsold_price_switch
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "未成交收费", align: "center" } },
                            _vm._l(
                              _vm.inventoryConfig.price.unsold_price,
                              function (item, index) {
                                return item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: { label: item.name },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value:
                                                      scope.row.fail[index]
                                                        .number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row.fail[index],
                                                        "number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.fail[index].number",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e()
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.inventoryConfig.switch.other_price_switch
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "其他费用", align: "center" } },
                            _vm._l(
                              _vm.inventoryConfig.price.other_price,
                              function (item, index) {
                                return item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: { label: item.name },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value:
                                                      scope.row.other[index]
                                                        .number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row.other[index],
                                                        "number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.other[index].number",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e()
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delGoods(
                                                scope.$index,
                                                scope.row,
                                                cindex
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class": "commondelete",
                                            },
                                          }),
                                          _vm._v("删除\n                "),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.createActiveGoods(citem, cindex)
                                },
                              },
                            },
                            [_vm._v("\n              新增一条\n            ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12, offset: 0 } }),
                    ],
                    1
                  ),
                ]
              }),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "20px", width: "400px" },
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("formValidate")
                            },
                          },
                        },
                        [_vm._v("\n            保存\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "30px" } }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-tabs",
            { attrs: { value: "goods" } },
            [
              _c("el-tab-pane", {
                attrs: { label: "货品档案", name: "goods" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { inline: "", size: "small", "label-width": "110px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "货品搜索：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: {
                        placeholder: "请输入货品名称，关键字，产品编号",
                      },
                      model: {
                        value: _vm.tableFrom.keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "keyword", $$v)
                        },
                        expression: "tableFrom.keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "货品ID：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入货品ID" },
                      model: {
                        value: _vm.tableFrom.product_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "product_id", $$v)
                        },
                        expression: "tableFrom.product_id",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "货品ID",
                  prop: "product_id",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货品图", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "货品名称",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "brand", label: "作者/品牌", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.brand
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.brand.brand_name) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分类", "min-width": "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.storeCategory
                          ? [
                              _c("span", { staticClass: "mr10" }, [
                                _vm._v(
                                  _vm._s(scope.row.storeCategory.cate_name)
                                ),
                              ]),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "属性", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          _vm._l(scope.row.attrInfo, function (m, i) {
                            return _c("span", { key: "attr_" + i }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(m.attr_name) +
                                  ":" +
                                  _vm._s(m.attr_value_name) +
                                  "\n            "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "录入",
                  "min-width": "90",
                  prop: "creator.real_name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.storeCategory
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saleGoods(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.goodsIdArr.findIndex(function (
                                            item
                                          ) {
                                            return (
                                              item.product_id ===
                                              scope.row.product_id
                                            )
                                          }) > -1
                                            ? "已选择货品"
                                            : "选择货品"
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }