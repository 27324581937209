var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c("div", { staticClass: "padding20 bg-white borderRadius6" }, [
        _c(
          "div",
          { staticClass: "remove-control form-view " },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm ",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "活动名称：", prop: "title" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入活动名称" },
                          model: {
                            value: _vm.ruleForm.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "title", $$v)
                            },
                            expression: "ruleForm.title",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "奖品设置：" } }, [
                  _c(
                    "div",
                    { staticClass: "tab" },
                    [
                      _c("div", { staticClass: "tr flex align-items-c" }, [
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "10%" } },
                          [_vm._v("奖品图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "10%" } },
                          [_vm._v("奖品类型")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "15%" } },
                          [_vm._v("选择奖品")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "10%" } },
                          [_vm._v("按每*人助力")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "th",
                            staticStyle: { width: "35%", "text-align": "left" },
                          },
                          [_vm._v("助力设置")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "10%" } },
                          [_vm._v("奖品总数")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "th", staticStyle: { width: "10%" } },
                          [_vm._v("操作")]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.prize_data, function (m, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "tr flex align-items-c" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: { width: "10%" },
                              },
                              [
                                m.prize_image
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-image flex align-items-c justify-c",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeImg(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: m.prize_image + "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-image flex align-items-c justify-c",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeImg(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture",
                                        }),
                                      ]
                                    ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: { width: "10%" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    on: {
                                      change: function ($event) {
                                        return _vm.editTypeAction(i)
                                      },
                                    },
                                    model: {
                                      value: m.prize_type,
                                      callback: function ($$v) {
                                        _vm.$set(m, "prize_type", $$v)
                                      },
                                      expression: "m.prize_type",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        value: "product",
                                        label: "商品",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        value: "product_nft",
                                        label: "数字藏品",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        value: "nft_blind_box",
                                        label: "数字盲盒",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        value: "coupon",
                                        label: "优惠券",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: { width: "15%" },
                              },
                              [
                                m.prize_relation_name
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "line-height": "18px" },
                                      },
                                      [_vm._v(_vm._s(m.prize_relation_name))]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdditem(
                                              m.prize_type,
                                              i
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("选择")]
                                    ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: { width: "10%" },
                              },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#cccccc",
                                    "inactive-value": 1,
                                    "active-value": 2,
                                    disabled: !!m.prize_id,
                                  },
                                  model: {
                                    value: m.receive_type,
                                    callback: function ($$v) {
                                      _vm.$set(m, "receive_type", $$v)
                                    },
                                    expression: "m.receive_type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td flex align-items-c",
                                staticStyle: { width: "35%" },
                              },
                              [
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c("span", { staticClass: "mr5" }, [
                                      _vm._v(
                                        _vm._s(
                                          m.receive_type == 2 ? "每" : "需"
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input-number", {
                                      staticStyle: { width: "50px" },
                                      attrs: { controls: false },
                                      model: {
                                        value: m.help_count,
                                        callback: function ($$v) {
                                          _vm.$set(m, "help_count", $$v)
                                        },
                                        expression: "m.help_count",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml5" }, [
                                      _vm._v("人助力"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                m.receive_type == 2
                                  ? _c(
                                      "div",
                                      [
                                        _c("span", { staticClass: "ml5 mr5" }, [
                                          _vm._v("可领1个，最多"),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-input-number", {
                                          staticStyle: { width: "50px" },
                                          attrs: { controls: false },
                                          model: {
                                            value: m.max_receive_num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                m,
                                                "max_receive_num",
                                                $$v
                                              )
                                            },
                                            expression: "m.max_receive_num",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml5" }, [
                                          _vm._v("个"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: { width: "10%" },
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: { controls: false },
                                  model: {
                                    value: m.prize_num,
                                    callback: function ($$v) {
                                      _vm.$set(m, "prize_num", $$v)
                                    },
                                    expression: "m.prize_num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "td",
                                staticStyle: { width: "10%" },
                              },
                              [
                                !m.prize_id
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delPrizeAction(i)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tr flex align-items-c" }, [
                        _c(
                          "div",
                          {
                            staticClass: "td",
                            staticStyle: {
                              width: "100%",
                              "text-align": "left",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.addPrizeAction },
                              },
                              [_vm._v("新增奖品")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "other-page flex" }, [
          _c("div", { staticClass: "l-poster" }, [
            _c("div", { staticClass: "t" }, [_vm._v("用户分享海报示意图")]),
            _vm._v(" "),
            _c("div", { staticClass: "desc" }, [
              _vm._v("设计海报时，请将图中4个绿色虚线部分留白"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "img" }, [
              _vm.ruleForm.poster_img
                ? _c("img", {
                    attrs: { src: _vm.ruleForm.poster_img + "!m640", alt: "" },
                  })
                : _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20220516/10ca65c3a289e6402d2f5c17683aa878.png!m640",
                      alt: "",
                    },
                  }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn item-flex-center" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.uploadPosterAction },
                  },
                  [_vm._v("上传海报")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "r-set" }, [
            _c("div", { staticClass: "t" }, [_vm._v("用户分享海报示意图")]),
            _vm._v(" "),
            _c("div", { staticClass: "desc" }, [
              _vm._v("点击下方文字，设置用户参与活动时的互动文案"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "img" },
              [
                _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20220730/951b5ccddbd0210b5c3f2c7470897d9b.png!m640",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "txt1",
                    on: {
                      click: function ($event) {
                        return _vm.showSetAction("keyword_reply_message")
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.ruleForm.keyword_reply_message
                          ? _vm.ruleForm.keyword_reply_message
                          : "报名成功，快去分享你的专属海报，10人参与活动即可免费领取"
                      ) + "\n            "
                    ),
                    _c("div", { staticClass: "bai" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "txt2",
                    on: {
                      click: function ($event) {
                        return _vm.showSetAction("keyword_reply_tweet")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.ruleForm.keyword_reply_tweet.title
                            ? _vm.ruleForm.keyword_reply_tweet.title
                            : "【免费公开】价值50万的文玩艺术品电商创业方案框架"
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d flex" }, [
                      _c("div", { staticClass: "d-c" }, [
                        _vm._v(
                          _vm._s(
                            _vm.ruleForm.keyword_reply_tweet.description
                              ? _vm.ruleForm.keyword_reply_tweet.description
                              : "创业福利，拥有它你能少走弯路。"
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-r" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.ruleForm.keyword_reply_tweet.image
                              ? _vm.ruleForm.keyword_reply_tweet.image
                              : "https://saas.cdn.yunzongbu.cn/image/20220516/001bb4e89e21d3840f12cec273af7c5a.png" +
                                "!120a",
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "bai" }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "r-img" }, [
                  _vm.ruleForm.poster_img
                    ? _c("img", {
                        attrs: {
                          src: _vm.ruleForm.poster_img + "!m640",
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20220516/10ca65c3a289e6402d2f5c17683aa878.png!m640",
                          alt: "",
                        },
                      }),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "inp",
                  attrs: { placeholder: "输入触发活动关键词" },
                  model: {
                    value: _vm.ruleForm.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "keyword", $$v)
                    },
                    expression: "ruleForm.keyword",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "cz cz1" }, [
                  _vm._v("报名成功提示文字"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cz cz2" },
                  [
                    _vm._v("报名成功推送内容\n            "),
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#eeeeee",
                        "active-value": 1,
                        "inactive-value": 0,
                      },
                      model: {
                        value: _vm.ruleForm.keyword_reply_tweet.is_open,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm.keyword_reply_tweet,
                            "is_open",
                            $$v
                          )
                        },
                        expression: "ruleForm.keyword_reply_tweet.is_open",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "cz cz5" }, [
                  _vm._v("触发活动关键词"),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "common-btns",
          class: { left0: _vm.device === "mobile" },
          style: { left: _vm.sidebar.opened ? "230px" : "74px" },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAction("ruleForm")
                },
              },
            },
            [_vm._v("确认提交")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectProduct", {
        ref: "product",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "nft_blind_box",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Productblind", {
        ref: "blind_box",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSet,
            title: "设置文案",
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSet = $event
            },
          },
        },
        [
          _vm.setType == "keyword_reply_tweet" ||
          _vm.setType == "scan_reply_tweet"
            ? [
                _c("div", { staticClass: "set-message flex align-items-c" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("标题：")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "r flex-one" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入标题" },
                        model: {
                          value: _vm.gzhContent[_vm.setType].title,
                          callback: function ($$v) {
                            _vm.$set(_vm.gzhContent[_vm.setType], "title", $$v)
                          },
                          expression: "gzhContent[setType].title",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "set-message flex align-items-c" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("描述：")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "r flex-one" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入描述" },
                        model: {
                          value: _vm.gzhContent[_vm.setType].description,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.gzhContent[_vm.setType],
                              "description",
                              $$v
                            )
                          },
                          expression: "gzhContent[setType].description",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "set-message flex align-items-c" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("图片：")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "r flex-one" }, [
                    _vm.gzhContent[_vm.setType].image
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c",
                            on: { click: _vm.changeDescImg },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.gzhContent[_vm.setType].image + "!120a",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c",
                            on: { click: _vm.changeDescImg },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "set-message flex align-items-c" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("链接：")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "r flex-one" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入链接" },
                        model: {
                          value: _vm.gzhContent[_vm.setType].url,
                          callback: function ($$v) {
                            _vm.$set(_vm.gzhContent[_vm.setType], "url", $$v)
                          },
                          expression: "gzhContent[setType].url",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : [
                _c("div", { staticClass: "set-message flex align-items-c" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("文字：")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "r flex-one" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入文字" },
                        model: {
                          value: _vm.gzhContent[_vm.setType],
                          callback: function ($$v) {
                            _vm.$set(_vm.gzhContent, _vm.setType, $$v)
                          },
                          expression: "gzhContent[setType]",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr20",
                  attrs: { type: "primary" },
                  on: { click: _vm.setOkAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSet = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }