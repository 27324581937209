"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.typed.uint8-array");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _guide = _interopRequireDefault(require("@/components/guide/guide"));
var _panels_weChat = _interopRequireDefault(require("../../libs/panels_weChat.js"));
var _color_configuration_weChat = _interopRequireDefault(require("../../libs/color_configuration_weChat.js"));
var _configuration_weChat = _interopRequireDefault(require("../../libs/configuration_weChat.js"));
var _system = require("@/api/system");
var _modules = _interopRequireDefault(require("@/views/decoration/modules"));
var _preview = _interopRequireDefault(require("@/views/decoration/preview.vue"));
var _attribute = _interopRequireDefault(require("@/views/decoration/modules/page_weChat/attribute"));
var _auction = require("@/api/auction");
var _aiMark = require("@/api/aiMark");
var _user = require("@/api/user");
var _vuex = require("vuex");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)('setting'),
  mapGetters = _createNamespacedHelp.mapGetters;
var _default = exports.default = {
  name: 'CustomPage',
  components: {
    guide: _guide.default,
    pageAttribute: _attribute.default,
    preview: _preview.default
  },
  data: function data() {
    return {
      panels: _panels_weChat.default,
      configuration: _configuration_weChat.default,
      color_configuration: _color_configuration_weChat.default,
      modules: _modules.default,
      is_totop: false,
      fullHeight: document.documentElement.clientHeight,
      qrcode: {
        wechat: {}
      },
      avatar: '',
      headerHover: false,
      footerHover: false,
      previewData: [],
      showPreview: false,
      venue_id: '',
      makeTagOption: [],
      showModelFlag: false,
      modeltype: '',
      modelprice: '',
      temid: this.$route.query.temid || '',
      type: this.$route.query.type || '',
      marketid: this.$route.query.marketid || '',
      venueEndMessage: '',
      venueHighQualityAlbumMessage: '',
      venueHighQualityProductMessage: '',
      createType: '0',
      templatename: ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, mapGetters({
    getStore: 'getStore',
    version: 'getVersion'
  })), {}, {
    scrollHeight: function scrollHeight() {
      return {
        height: parseInt(this.fullHeight) - 65 + 'px'
      };
    },
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set(value) {
        this.$store.commit('finish/setPagesData', value);
      }
    },
    index: function index() {
      return this.$store.state.finish.index;
    },
    attribute: function attribute() {
      return this.$store.getters.attribute;
    },
    is_attribute: function is_attribute() {
      return this.$store.state.finish.pages.length > 0;
    },
    is_lock: function is_lock() {
      return this.$store.state.finish.is_lock;
    },
    footerBackground: function footerBackground() {
      if (this.pageInfo.hide_footer) {
        return 'none';
      } else {
        return this.pageInfo.footerSetting.footer_background;
      }
    }
  }),
  created: function created() {
    var _this2 = this;
    console.log(_configuration_weChat.default);
    (0, _user.getBaseInfo)().then(function (res) {
      if (res.data.merchantAdmin && res.data.merchantAdmin.is_system) {
        _this2.type = 'manage';
      }
    });
    this.avatar = localStorage.getItem('avatar');
    this.venue_id = this.$route.query.vid || '';
    (0, _aiMark.getMediaTypeApi)().then(function (res) {
      var arr = [];
      for (var key in res.data.make_tag) {
        arr.push({
          label: res.data.make_tag[key],
          value: key
        });
      }
      _this2.makeTagOption = arr;
    });
    (0, _auction.tweetTemplateDetailApi)({
      template_id: this.temid
    }).then(function (res) {
      console.log(res);
      if (res.data.template_price) {
        console.log('到了这里吗');
        _this2.modelprice = res.data.template_price;
      }
    });
  },
  methods: {
    handleTplOpen: function handleTplOpen() {
      var _this3 = this;
      return new Promise(function (resolve) {
        _this3.$confirm('选择新模板后，当前页面内容将被替换且不被保存， 请确认是否需要手动保存。', '提示', {
          confirmButtonText: '继续选择模板',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(function () {
          resolve(true);
        }).catch(function () {
          resolve(false);
        });
      });
    },
    /**
     * 处理返回顶部
     * @return {[type]} [description]
     */
    handleTotop: function handleTotop() {
      this.scrollbar.$el.firstChild.scrollTop = 0;
    },
    /**
     * 处理返回顶部
     * @return {[type]} [description]
     */
    handleGuide: function handleGuide() {
      window.localStorage.setItem('is_guide', 0);
      this.$refs.guide.is_close = false;
      this.$refs.guide.onSetPage();
    },
    /**
     * 处理名称
     * @param  {[type]} value [description]
     * @return {[type]}       [description]
     */
    getPageTitle: function getPageTitle(value) {
      if (value && Number(value.length) === 13) {
        return '默认微页面' + this.pageInfo.id;
      } else {
        return value || '默认微页面';
      }
    },
    /**
     * [handlePageName description]
     * @return {[type]} [description]
     */
    handlePageName: function handlePageName() {
      this.$store.commit('finish/setIndex', -2);
    },
    /**
     * 设置组件排序
     * @return {[type]} [description]
     */
    handlePackage: function handlePackage() {
      this.$store.commit('finish/setIndex', -1);
    },
    handleFooterPage: function handleFooterPage() {
      this.$store.commit('finish/setIndex', -3);
    },
    /**
     * 执行页面保存操作
     * @return {[type]} [description]
     */
    handlePageSave: function handlePageSave(e, callback) {
      var _this4 = this;
      var loading = this.$loading({
        lock: true,
        text: '保存中……',
        spinner: 'el-icon-loading'
      });
      if (this.pageInfo.title === '默认微页') {
        return;
      }
      var pageContent = this.$store.state.finish.pages;
      var contact_information_content = '';
      var position = '';
      var recommend_articles = '';
      var contact_image = '';
      pageContent.forEach(function (item) {
        if (item.name === 'contact_information') {
          contact_information_content = JSON.stringify(item.content);
        }
        if (item.name === 'position_weChat') {
          position = JSON.stringify(item.content);
        }
        if (item.name === 'img_weChat' && item.content.type === '2') {
          recommend_articles = JSON.stringify(item.content);
        }
        if (item.name === 'img_weChat' && item.content.type === '3') {
          contact_image = JSON.stringify(item.content);
        }
      });
      var data = {};
      if (contact_information_content) {
        data.contactInformation = contact_information_content;
      }
      if (position) {
        data.position = position;
      }
      if (recommend_articles) {
        data.recommend_articles = recommend_articles;
      }
      if (contact_image) {
        data.contact_image = contact_image;
      }
      if (contact_information_content || position || recommend_articles || contact_image) {
        (0, _system.configClassKeysSave)('weChatTemplateContactInformation', data);
      }
      if (this.type == 'create' || this.type == 'manage' || this.type == 'edit') {
        var content = this.$store.state.finish.pages;
        var auctionarr = [];
        content.forEach(function (item) {
          if (item.name == 'auction_weChat') {
            if (item.content && item.content.data && item.content.data.length > 0) {
              item.content.data.forEach(function (it) {
                auctionarr.push(it.id);
              });
            }
          }
        });
        (0, _auction.saveTweetApi)(this.$route.query.marketid, {
          tweet_content: content,
          config_data: {
            background: this.pageInfo.background,
            headerSetting: this.pageInfo.headerSetting,
            bg_image: this.pageInfo.commonData.bg_image,
            bg_image_mode: this.pageInfo.commonData.bg_image_mode,
            title: this.pageInfo.commonData.title,
            image: this.pageInfo.commonData.coverImg,
            digest: this.pageInfo.commonData.digest,
            author: this.pageInfo.commonData.author,
            collection: this.pageInfo.commonData.collection
          },
          auction_ids: auctionarr,
          template_id: this.temid
        }).then(function (res) {
          loading.close();
          _this4.$message({
            title: '成功',
            message: '保存成功',
            type: 'success',
            duration: 1000
          });
          var imgTextData = sessionStorage.getItem('imgTextData');
          if (imgTextData) {
            sessionStorage.setItem('imgTextData', '');
          }
          if (callback) {
            callback();
          } else {
            if (_this4.$route.query.type == 'create') {
              var params = (0, _utils.getQueryObject)();
              for (var key in _this4.queryParams) {
                var m = _this4.queryParams[key];
                params[key] = m;
              }
              params.type = 'edit';
              _this4.$router.replace({
                query: params
              });
            } else {
              // this.$router.back();
            }
          }
        });
        return;
      }
      this.actionPageSave().then(function () {
        loading.close();
        _this4.$message({
          title: '成功',
          message: '保存成功',
          type: 'success',
          duration: 1000
        });
        // this.$router.back();
      }).catch(function (error) {
        loading.close();
        console.error('错误信息', error);
      });
    },
    /**
     * 处理数据保存
     * @return {[type]} [description]
     */
    actionPageSave: function actionPageSave() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var content = _this.$store.state.finish.pages;
        var data = {
          name: _this.pageInfo.name,
          venue_id: _this.venue_id,
          content: JSON.stringify(content),
          config_data: JSON.stringify({
            background: _this.pageInfo.background,
            headerSetting: _this.pageInfo.headerSetting,
            bg_image: _this.pageInfo.bg_image,
            bg_image_mode: _this.pageInfo.bg_image_mode
          }),
          type: 'personal_center'
        };
        var action = _auction.tweetCreateApi;
        if (_this.pageInfo.id) {
          action = _auction.tweetUpdateApi;
        }
        action(_this.pageInfo.id, data).then(function (res) {
          _this.pageInfo.id = res.data.id;
          resolve(true);
        }).catch(function (message) {
          return reject(message);
        });
      });
    },
    downloadFile: function downloadFile(content, fileName) {
      // 下载base64图片
      var base64ToBlob = function base64ToBlob(code) {
        var parts = code.split(';base64,');
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {
          type: contentType
        });
      };
      var aLink = document.createElement('a');
      var blob = base64ToBlob(content); // new Blob([content]);
      var evt = document.createEvent('HTMLEvents');
      evt.initEvent('click', true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    },
    /**
     * 设置为首页
     * 确认设置该微页面为首页？
     * @return {[type]} [description]
     */
    handlePageSetting: function handlePageSetting() {
      var _this5 = this;
      if (!this.pageInfo.commonData.coverImg) {
        this.$message.error('请先上传封面图');
        return false;
      }
      if (!this.pageInfo.commonData.title) {
        this.$message.error('请先输入标题');
        return false;
      }
      if (!this.$store.state.finish.pages || this.$store.state.finish.pages.length <= 0) {
        this.$message.error('请先设置内容');
        return false;
      }
      this.previewData = this.$store.state.finish.pages;
      this.handlePageSave('', function () {
        _this5.showPreview = true;
      });
    },
    closeAction: function closeAction() {
      this.showPreview = false;
    },
    backAction: function backAction() {
      this.$router.back();
    },
    /**
     * 拖拽拿起事件
     * sort 排序  drag 拖拽
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    dragStart: function dragStart($event) {
      this.$store.commit('finish/setComs', $event);
    },
    /**
     * 拖拽放下事件
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    dragEnd: function dragEnd($event) {
      this.$store.commit('finish/setComs', '');
      $event.preventDefault();
    },
    mouseOver: function mouseOver(key) {
      this[key] = true;
    },
    mouseleave: function mouseleave(key) {
      this[key] = false;
    },
    handleCancelAction: function handleCancelAction() {
      this.$refs.childView.id = this.pageInfo.id;
      console.log(this.pageInfo.id);
      this.$refs.childView.loadPageInfo();
    },
    handlePageModelSave: function handlePageModelSave() {
      if (!this.pageInfo.commonData.coverImg) {
        this.$message.error('请先上传封面图');
        return false;
      }
      if (!this.pageInfo.commonData.title) {
        this.$message.error('请先输入标题');
        return false;
      }
      if (!this.$store.state.finish.pages || this.$store.state.finish.pages.length <= 0) {
        this.$message.error('请先设置内容');
        return false;
      }
      this.$store.state.finish.pages.forEach(function (m) {
        if (m.name === 'auction_weChat') {
          var d = JSON.parse(JSON.stringify(m.facade));
          delete d.animate_color;
          delete d.auction_spacing_color;
          delete d.item_background;
          delete d.border_color;
          delete d.border_shawdow_color;
          delete d.lot_color;
          delete d.title_color;
          delete d.desc_color;
          delete d.price_color;
          delete d.content_color;
          console.log(m.content.attrlist);
          console.log(JSON.stringify(m.content.attrlist));
          console.log(JSON.stringify(d));
        }
      });
      if (this.temid) {
        this.createType = '1';
      }
      this.templatename = this.pageInfo.commonData.title;
      this.showModelFlag = true;
    },
    modelSubmit: function modelSubmit() {
      var _this6 = this;
      var json = {};
      if (!this.modeltype) {
        this.$message.error('请输入内容类型');
        return;
      }
      if (!this.modelprice) {
        this.$message.error('请输入模板价格');
        return;
      }
      var _this = this;
      var content = _this.$store.state.finish.pages;
      json.title = this.templatename;
      json.image = this.pageInfo.commonData.coverImg;
      json.content = content;
      json.config_data = {
        background: _this.pageInfo.background,
        headerSetting: _this.pageInfo.headerSetting,
        bg_image: _this.pageInfo.commonData.bg_image,
        fontFamily: _this.pageInfo.commonData.fontFamily,
        fontFamily_ch: _this.pageInfo.commonData.fontFamily_ch,
        bg_image_mode: _this.pageInfo.commonData.bg_image_mode,
        pageBackground: _this.pageInfo.commonData.pageBackground
      };
      json.make_tag = this.modeltype;
      json.template_price = this.modelprice;
      console.log(json);
      var fun = _auction.createTweetTemplateApi;
      if (this.temid) {
        if (this.createType == 1) {
          json.template_id = this.temid;
          fun = _auction.editTweetTemplateApi;
        }
      }
      // editTweetTemplateApi
      fun(json).then(function (res) {
        _this6.$message({
          title: '成功',
          message: '保存成功',
          type: 'success',
          duration: 1000
        });
        _this6.$router.back();
      });
    },
    editTextAction: function editTextAction(e, index) {
      var _this7 = this;
      if (['venue', 'album', 'auction'].includes(e)) {
        (0, _aiMark.generateMarketTweetTextIntro)(this.$route.query.marketid, {
          type: e
        }).then(function (res) {
          console.log(res);
          _this7.pages[index].content.data = "<p style=\"white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box;\">".concat(res.data.intro, "</p>");
        });
      } else if (!this[e]) {
        // let d = {};
        (0, _aiMark.getVenuelntroDataApi)({
          venue_id: this.$route.query.venue_id,
          album_id: this.$route.query.album_id,
          type: e
        }).then(function (res) {
          if (e === 'venueEndMessage') {
            _this7.venueEndMessage = {
              messageList: res.data.venueEndMessage.venueEndMessage,
              messageData: res.data.venueEndMessage.venueData
            };
          } else if (e === 'venueHighQualityAlbumMessage') {
            _this7.venueHighQualityAlbumMessage = {
              messageList: res.data.venueHighQualityAlbumMessage.albumEndMessage,
              messageData: res.data.venueHighQualityAlbumMessage.albumData
            };
          } else if (e === 'venueHighQualityProductMessage') {
            _this7.venueHighQualityProductMessage = {
              messageList: res.data.venueHighQualityProductMessage.venueHighQualityProductMessage,
              messageData: res.data.venueHighQualityProductMessage.venueHighQualityProductData
            };
          }
          // this.$set(this, e, d);
          _this7.setMessage(e, index);
        });
      } else {
        this.setMessage(e, index);
      }
    },
    setMessage: function setMessage(e, index) {
      var _this8 = this;
      if (e && this[e]) {
        var html = '';
        this[e].messageList.forEach(function (m, i) {
          var text = m;
          for (var key in _this8[e].messageData) {
            var val = _this8[e].messageData[key];
            var h = "<strong>".concat(val, "</strong>");
            if (key.indexOf('DealPrice') !== -1 || key.indexOf('DealRatio') !== -1) {
              h = "<em>".concat(h, "</em>");
              if (_this8.pages[index].facade.temp.indexOf('specialColor') !== -1) h = "<span style=\"color: ".concat(_this8.pages[index].facade.specialColor, "\">").concat(h, "</span>");
            }
            var reKey = "{".concat(key, "}");
            text = text.replace(new RegExp(reKey, 'g'), h);
          }
          if (i !== 0) {
            html += '<p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box;">&nbsp;</p>';
          }
          html += "<p style=\"white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box;\">".concat(text, "</p>");
          // console.log(`<p style="white-space: normal; margin: 0px; padding: 0px; box-sizing: border-box;">${text}</p>`);
        });
        this.pages[index].content.data = html;
      }
    },
    setThemeAction: function setThemeAction(key) {
      this.setColorConfig(_color_configuration_weChat.default[key]);
    },
    setColor: function setColor(pBackground, themeData) {
      var _this9 = this;
      if (this.pageInfo.commonData.pageBackground) {
        this.pageInfo.commonData.pageBackground = pBackground;
      }
      this.pages.forEach(function (m, i) {
        var facade = m.facade;
        var content = m.content;
        if (m.name === 'blank') {
          if (facade.background_color) {
            facade.background_color = themeData.borderColor;
          } else if (content.has_line && facade.line_color) {
            facade.line_color = themeData.borderColor;
          }
        } else if (facade.temp) {
          facade.temp.forEach(function (item) {
            if (item === 'pageColor_border') facade['pageColor'] = themeData['borderColor'];else if (item === 'pageBackground_page') facade['pageBackground'] = _this9.pageInfo.commonData.pageBackground && pBackground ? pBackground : '#fff';else if (item === 'borderColor_decorative') facade['borderColor'] = themeData['borderColor_decorative'];else if (item === 'contact_pageBackground') facade['pageBackground'] = themeData['contact_pageBackground'];else if (item === 'contact_lineBackgound') facade['lineBackgound'] = themeData['contact_lineBackgound'];else if (item === 'contact_titleColor') facade['titleColor'] = themeData['contact_titleColor'];else if (item === 'contact_pageColor') facade['pageColor'] = themeData['contact_pageColor'];else if (item === 'pageColor_color') facade['pageColor'] = themeData['color'];else if (themeData[item]) facade[item] = themeData[item];
          });
        }
        if (m.name === 'text_weChat' && content.type && facade.temp.indexOf('specialColor') !== -1) {
          _this9.editTextAction(content.type, i);
        }
      });
    },
    setConfiguration: function setConfiguration(i) {
      var pages = JSON.parse(JSON.stringify(this.pages));
      pages.forEach(function (m, mI) {
        if (m.name === 'auction_weChat') {
          m.facade.personalizedDecoration = i;
          m.facade.border_padding_top = 0;
          m.facade.border_padding_bottom = 0;
          m.facade.border_padding_left = 25;
          m.facade.border_padding_right = 25;
          // if (this.configuration[i].val > 2 && this.configuration[i].list_bg_img) m.facade.list_bg_img = this.configuration[i].list_bg_img;
          // else m.facade.list_bg_img = '';
          // this.$set(this.pages[mI].facade, ''list_bg_img);
          // m.facade = Object.assign({}, m.facade, this.configuration[i][m.name].facade);
          // m.content = Object.assign({}, m.content, this.configuration[i][m.name].content);
          // this.setThemeAction(1);
        }
      });
      this.pages = JSON.parse(JSON.stringify(pages));
    },
    generateColors: function generateColors() {
      var _this10 = this;
      var i = null;
      this.pages.forEach(function (m, mI) {
        if (m.name === 'auction_weChat') {
          i = mI;
        }
      });
      if (i !== null) {
        if (this.pages[i].content.data && this.pages[i].content.data.length) {
          var loadingInstance = this.$loading({
            lock: true,
            //lock的修改符--默认是false
            text: "加载中",
            //显示在加载图标下方的加载文案
            spinner: "el-icon-loading",
            //自定义加载图标类名
            background: "rgba(0, 0, 0, 0.75)",
            //遮罩层颜色
            target: document.querySelector("#loading_dom") //loading覆盖的dom元素节点 默认插入body标签
          });
          (0, _aiMark.generateMarketTweetAuctionColorMatchingApi)(this.marketid, {
            auction_ids: this.pages[i].content.data.map(function (m) {
              return m.id;
            }).join(',')
          }).then(function (res) {
            var color = res.data.color;
            // this.facade.lot_color = color.main_text;
            // this.facade.title_color = color.main_text;
            // this.facade.desc_color = color.decorative_text;
            // this.facade.content_color = color.sub_text;
            // this.facade.price_color = color.highlight_text;
            // this.facade.item_background = color.background;
            // this.facade.border_color = color.decorative_text;
            // this.facade.border_shawdow_color = hexToRgb(color.decorative_text, 0.2);

            _this10.setColorConfig(color);
            loadingInstance.close();
          }).catch(function () {});
        } else {
          this.$message.error('请先选择拍品');
        }
      }
    },
    setColorConfig: function setColorConfig(color) {
      var pBackground = '';
      var themeData = {
        pageBackground: color.background,
        // 区域背景颜色
        pageColor: color.main_text,
        // 区域文字颜色
        borderColor: color.sub_text,
        // 边框颜色
        borderColor_decorative: color.decorative_text,
        // 边框颜色 - 装饰色
        specialColor: color.highlight_text,
        // 文字区域 特殊颜色

        textBackground: '',
        // 专场文字区域背景色
        titleColor: color.main_text,
        // 标题文字颜色
        preTimeTextColor: color.main_text,
        // 预展时间标签颜色
        preTimeColor: color.main_text,
        // 预展时间颜色
        timeTextColor: color.main_text,
        // 开拍时间标签颜色
        timeColor: color.main_text,
        // 开拍时间颜色
        liveTimeTextColor: color.main_text,
        // 直播时间标签颜色
        liveTimeColor: color.main_text,
        // 直播时间颜色
        venueNameBackground: color.sub_text,
        // 拍卖会名称背景色
        venueNameColor: '#ffffff',
        // 拍卖会名称文字颜色
        addressTagColor: color.main_text,
        // 地址标签
        addressBriefColor: color.main_text,
        // 地址简写
        addressNameColor: color.main_text,
        // 地址详细

        animate_color: color.sub_text,
        // 动画提示文字颜色
        auction_spacing_color: color.decorative_text,
        // 拍品间距线颜色
        item_background: color.background,
        // 拍品区域背景色
        content_background: color.background,
        // 拍品文字区域背景色
        border_color: color.decorative_text,
        // 边框颜色
        border_shawdow_color: (0, _utils.hexToRgb)(color.decorative_text, 0.2),
        // 边框颜色
        lot_color: color.main_text,
        // lot文字颜色
        title_color: color.main_text,
        // 标题文字颜色
        desc_color: color.main_text,
        // 其他文字颜色
        price_color: color.highlight_text,
        // 价格文字颜色
        content_color: color.main_text,
        // 价格文字颜色
        attr_color: color.main_text,
        // 属性名颜色
        attr_value_color: color.main_text,
        // 属性值颜色

        contact_pageBackground: color.background,
        // 联系我们背景颜色
        contact_lineBackgound: color.decorative_text,
        // 联系我们分割线颜色
        contact_titleColor: color.main_text,
        // 联系我们标题颜色
        contact_pageColor: color.main_text,
        // 联系我们文字颜色

        color: color.main_text // 图文组件文字颜色
      };
      this.setColor(pBackground, themeData);
    }
  }
};