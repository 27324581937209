"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    colour: {
      type: [String, Number]
    },
    noAlpha: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      predefineColors: ['#FFFFFF', '#000000', '#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585']
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    select: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    /**
     * 重置颜色
     * @return {[type]}       [description]
     */
    onReset: function onReset() {
      this.select = this.colour;
    },
    onClear: function onClear() {
      this.select = '';
    },
    changeColorAction: function changeColorAction(val) {
      this.$emit('changeColor', val);
    }
  }
};