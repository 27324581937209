var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tickMain main" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select w300 mt10 mr20",
                  attrs: { placeholder: "输入门票名称" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.ticket_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "ticket_name", $$v)
                    },
                    expression: "tableFrom.ticket_name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-date-picker", {
                staticClass: "mr20 w200 mt10",
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "选择统计开始日期",
                  "end-placeholder": "选择统计结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.searchDateAction },
                model: {
                  value: _vm.searchTimes,
                  callback: function ($$v) {
                    _vm.searchTimes = $$v
                  },
                  expression: "searchTimes",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mt10",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出已生成列表")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%", color: "#000" },
          attrs: {
            border: "",
            data: _vm.tableData.data,
            "highlight-current-row": "",
            "span-method": _vm.objectSpanMethod,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "门票ID", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "line1" }, [
                      _vm._v(_vm._s(scope.row.ticket_id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "门票名称", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "line1" }, [
                      _vm._v(_vm._s(scope.row.ticket_name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "统计", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v("总销量：" + _vm._s(row.statistics.total_sales)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "销售额：" + _vm._s(row.statistics.total_sales_price)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v("赠送数：" + _vm._s(row.statistics.gift_num)),
                    ]),
                    _vm._v(" "),
                    Number(row.statistics.total_refund_num)
                      ? _c("div", { staticClass: "flex" }, [
                          _vm._v(
                            "退款" +
                              _vm._s(row.statistics.total_refund_num) +
                              "张，共" +
                              _vm._s(
                                Number(row.statistics.total_refund_price)
                              ) +
                              "元"
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "票种", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(_vm._s(row.info.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "销量", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "销量：" +
                          _vm._s(row.sum_sales) +
                          "  金额：" +
                          _vm._s(row.sum_sales_price)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "渠道销量", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "小程序：" +
                          _vm._s(row.sales[1]) +
                          "  金额：" +
                          _vm._s(row.salesPrice[1])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "兑换码：" +
                          _vm._s(row.sales[2]) +
                          "  金额：" +
                          _vm._s(row.salesPrice[2])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "空投：" +
                          _vm._s(row.sales[3]) +
                          "  金额：" +
                          _vm._s(row.salesPrice[3])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "携程：" +
                          _vm._s(row.sales[4]) +
                          "  金额：" +
                          _vm._s(row.salesPrice[4])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "美团：" +
                          _vm._s(row.sales[5]) +
                          "  金额：" +
                          _vm._s(row.salesPrice[5])
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "未核销", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "小程序：" +
                          _vm._s(row.noCheck[1]) +
                          "  金额：" +
                          _vm._s(row.noCheckPrice[1])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "兑换码：" +
                          _vm._s(row.noCheck[2]) +
                          "  金额：" +
                          _vm._s(row.noCheckPrice[2])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "空投：" +
                          _vm._s(row.noCheck[3]) +
                          "  金额：" +
                          _vm._s(row.noCheckPrice[3])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "携程：" +
                          _vm._s(row.noCheck[4]) +
                          "  金额：" +
                          _vm._s(row.noCheckPrice[4])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "美团：" +
                          _vm._s(row.noCheck[5]) +
                          "  金额：" +
                          _vm._s(row.noCheckPrice[5])
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "已核销", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "小程序：" +
                          _vm._s(row.check[1]) +
                          "  金额：" +
                          _vm._s(row.checkPrice[1])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "兑换码：" +
                          _vm._s(row.check[2]) +
                          "  金额：" +
                          _vm._s(row.checkPrice[2])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "空投：" +
                          _vm._s(row.check[3]) +
                          "  金额：" +
                          _vm._s(row.checkPrice[3])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "携程：" +
                          _vm._s(row.check[4]) +
                          "  金额：" +
                          _vm._s(row.checkPrice[4])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "美团：" +
                          _vm._s(row.check[5]) +
                          "  金额：" +
                          _vm._s(row.checkPrice[5])
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }