var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "divBox wallet-common",
      staticStyle: { "min-height": "100vh" },
    },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
                inline: false,
                size: "normal",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户", size: "normal", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        placeholder: "请选择用户",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.form.uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "uid", $$v)
                        },
                        expression: "form.uid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  "border-radius": "50%",
                                  "margin-right": "12px",
                                },
                                attrs: { src: m.avatar, alt: "" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "line-height": "18px",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(m.nickname) +
                                        "(" +
                                        _vm._s(m.real_name) +
                                        ")"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(m.phone))]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转账金额", required: "" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.form.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "amount", $$v)
                      },
                      expression: "form.amount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("立即转账")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }