var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("header-breadcrumb", {
        attrs: { name: "链接", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mt20 flex align-items-c",
          staticStyle: { padding: "0 20px" },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "mt10 ft12",
              attrs: {
                data: _vm.albumList,
                "element-loading-text": "Loading",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "专场名称",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.album_name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "小程序站外链接",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.xcxLink
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ft12",
                                    attrs: {
                                      type: "text",
                                      loading: _vm.createSchemeBtnLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.createScheme(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("点击生成")]
                                ),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(_vm._s(scope.row.xcxLink))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "小程序站内链接",
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.album_type === 1
                          ? _c("div", [
                              _vm._v(
                                "/pages/albumDetail/index?id=" +
                                  _vm._s(scope.row.album_id)
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "/pages/albumDetail/index?id=" +
                                  _vm._s(scope.row.album_id)
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "H5链接地址",
                  "min-width": "300",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.album_type === 1
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.domain_url) +
                                  "/pages/albumDetail/index?id=" +
                                  _vm._s(scope.row.album_id)
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                _vm._s(_vm.domain_url) +
                                  "/pages/albumDetail/index?id=" +
                                  _vm._s(scope.row.album_id)
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "小程序码", width: "75" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "ft12",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showImgAction(1, scope.row)
                              },
                            },
                          },
                          [_vm._v("点击生成")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "H5二维码", width: "75" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "ft12",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showImgAction(2, scope.row)
                              },
                            },
                          },
                          [_vm._v("点击生成")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "小程序海报", width: "85" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "ft12",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showImgAction(3, scope.row)
                              },
                            },
                          },
                          [_vm._v("点击生成")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "H5海报", width: "75" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "ft12",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showImgAction(4, scope.row)
                              },
                            },
                          },
                          [_vm._v("点击生成")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.imgData.title,
            visible: _vm.imgData.show,
            "before-close": _vm.beforeCloseImg,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.imgData, "show", $event)
            },
          },
        },
        [
          _vm.imgData.imgUrl
            ? _c(
                "div",
                { staticClass: "flex align-items-c justify-c img-show" },
                [_c("img", { attrs: { src: _vm.imgData.imgUrl, alt: "" } })]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showPreview
        ? [
            _c("preview", {
              attrs: { pages: _vm.previewData },
              on: { closeAction: _vm.closeAction },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }