var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "title"
        ? [
            _c(
              "control",
              { attrs: { title: "标题区域", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "100px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "标题文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.titleColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "titleColor", $$v)
                              },
                              expression: "facade.titleColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "标题文字字号" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.titleFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "titleFontSize", $$v)
                              },
                              expression: "facade.titleFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "标题文字风格" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.titleFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "titleFontWeight", $$v)
                                },
                                expression: "facade.titleFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("常规"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "author"
        ? [
            _c(
              "control",
              { attrs: { title: "作者区域", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "100px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "标题文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.titleColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "titleColor", $$v)
                              },
                              expression: "facade.titleColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "标题文字字号" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.titleFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "titleFontSize", $$v)
                              },
                              expression: "facade.titleFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "标题文字风格" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.titleFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "titleFontWeight", $$v)
                                },
                                expression: "facade.titleFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("常规"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "浏览数量颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.hotColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hotColor", $$v)
                              },
                              expression: "facade.hotColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "浏览数量字号" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.hotFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hotFontSize", $$v)
                              },
                              expression: "facade.hotFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "浏览数量风格" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.hotFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "hotFontWeight", $$v)
                                },
                                expression: "facade.hotFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("常规"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "content"
        ? [
            _c(
              "control",
              { attrs: { title: "内容区域", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "100px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "内容行高" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.lineHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "lineHeight", $$v)
                              },
                              expression: "facade.lineHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "内容文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "内容文字字号" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "内容文字风格" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("常规"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "发布时间距离" },
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "div",
                              { staticClass: "radius-list t" },
                              [
                                _c("el-input-number", {
                                  staticClass: "w50",
                                  attrs: { controls: false },
                                  model: {
                                    value: _vm.facade.timePaddingTop,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.facade,
                                        "timePaddingTop",
                                        $$v
                                      )
                                    },
                                    expression: "facade.timePaddingTop",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "radius-list b" },
                              [
                                _c("el-input-number", {
                                  staticClass: "w50",
                                  attrs: { controls: false },
                                  model: {
                                    value: _vm.facade.timePaddingBottom,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.facade,
                                        "timePaddingBottom",
                                        $$v
                                      )
                                    },
                                    expression: "facade.timePaddingBottom",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "radius-list l" },
                              [
                                _c("el-input-number", {
                                  staticClass: "w50",
                                  attrs: { controls: false },
                                  model: {
                                    value: _vm.facade.timePaddingLeft,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.facade,
                                        "timePaddingLeft",
                                        $$v
                                      )
                                    },
                                    expression: "facade.timePaddingLeft",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "radius-list r" },
                              [
                                _c("el-input-number", {
                                  staticClass: "w50",
                                  attrs: { controls: false },
                                  model: {
                                    value: _vm.facade.timePaddingRight,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.facade,
                                        "timePaddingRight",
                                        $$v
                                      )
                                    },
                                    expression: "facade.timePaddingRight",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "发布时间位置" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.timeAlign,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "timeAlign", $$v)
                                },
                                expression: "facade.timeAlign",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "left" } }, [
                                _vm._v("左"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "center" } }, [
                                _vm._v("中"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "right" } }, [
                                _vm._v("右"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "发布时间颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.timeColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "timeColor", $$v)
                              },
                              expression: "facade.timeColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "发布时间字号" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.timeFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "timeFontSize", $$v)
                              },
                              expression: "facade.timeFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "发布时间风格" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "le-radio-group",
                              model: {
                                value: _vm.facade.timeFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "timeFontWeight", $$v)
                                },
                                expression: "facade.timeFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("常规"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }