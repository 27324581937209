var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-form",
        { attrs: { inline: "" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "状态：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "状态", clearable: "" },
                  on: { change: _vm.getList },
                  model: {
                    value: _vm.tableFrom.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "status", $$v)
                    },
                    expression: "tableFrom.status",
                  },
                },
                _vm._l(_vm.optionsData, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "关键字：" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "selWidth",
                  attrs: { placeholder: "请输入关键字" },
                  model: {
                    value: _vm.tableFrom.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "keyword", $$v)
                    },
                    expression: "tableFrom.keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.getList },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { size: "small", type: "primary" }, on: { click: _vm.onAdd } },
        [_vm._v("添加客服")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "service_id", label: "ID", "min-width": "60" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "user.nickname",
              label: "微信用户名称",
              "min-width": "130",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "客服头像", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticClass: "tabImage",
                          attrs: {
                            src: scope.row.avatar,
                            "preview-src-list": [scope.row.avatar],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "客服名称", "min-width": "130" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "是否显示", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-text": "显示",
                        "inactive-text": "隐藏",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onchangeIsShow(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.status,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "添加时间",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "150", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.goList(
                              scope.row.service_id,
                              scope.$index
                            )
                          },
                        },
                      },
                      [_vm._v("聊天记录")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(scope.row.service_id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onDel(scope.row.service_id, scope.$index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "聊天记录",
            width: _vm.isChat ? "600px" : "800px",
            visible: _vm.dialogTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _vm.isChat
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingChat,
                      expression: "loadingChat",
                    },
                  ],
                  key: "isIndex",
                  attrs: { data: _vm.tableChatData.data },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      property: "user.nickname",
                      label: "用户名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "用户头像", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.user.avatar
                                ? _c("img", {
                                    staticClass: "tabImage",
                                    attrs: { src: scope.row.user.avatar },
                                  })
                                : _c("img", {
                                    staticClass: "tabImage",
                                    attrs: {
                                      src: require("../../../assets/images/f.png"),
                                    },
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3196233401
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.check(
                                        scope.row.uid,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("查看对话")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      252509333
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isChat
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.goBack },
                },
                [_vm._v("返回聊天记录")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isChat
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingChat,
                      expression: "loadingChat",
                    },
                  ],
                  key: "isIndexs",
                  attrs: { data: _vm.tableServiceData.data },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "发送人", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.send_type === 0
                                      ? scope.row.user.nickname
                                      : scope.row.service.nickname
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1916843481
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发送人头像", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.user.avatar
                                ? _c("img", {
                                    staticClass: "tabImage",
                                    attrs: { src: scope.row.user.avatar },
                                  })
                                : _c("img", {
                                    staticClass: "tabImage",
                                    attrs: {
                                      src: require("../../../assets/images/f.png"),
                                    },
                                  }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3196233401
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发送消息", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.msn_type === 2
                                ? _c("span", { staticClass: "tabImage" }, [
                                    _vm._v("[" + _vm._s(scope.row.msn) + "]"),
                                  ])
                                : scope.row.msn_type === 3
                                ? _c("img", {
                                    staticClass: "tabImage",
                                    attrs: { src: scope.row.msn },
                                  })
                                : _c("span", [_vm._v(_vm._s(scope.row.msn))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3176559001
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "发送时间",
                      "min-width": "100",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.isChat
                    ? _vm.tableChatData.total
                    : _vm.tableServiceData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeChat,
                  "current-change": _vm.pageChangeChat,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }