"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _LineChart = _interopRequireDefault(require("../dashboard/admin/components/LineChart"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    LineChart: _LineChart.default
  },
  data: function data() {
    return {
      stateForm: {},
      searchQuery: {},
      date: 'today',
      lineChartData: {
        expectedData: [100, 120, 161, 134, 105, 160, 165],
        actualData: [120, 82, 91, 154, 162, 140, 145],
        payer: [100, 120, 98, 130, 150, 140, 180]
      },
      time3: "lately7"
    };
  },
  mounted: function mounted() {
    var params = JSON.parse(this.$route.query.resData);
    params.accountData.forEach(function (item) {
      item.tabData = [item.extend_data.account_info];
    });
    this.stateForm = params;
    // this.$nextTick(() => {
    //   this.$refs.lineChart.getOrderData({ date: this.time3 });
    // })
  },
  methods: {}
};