"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _request = _interopRequireDefault(require("@/api/request"));
var _product = require("@/api/product");
var _settings = require("@/settings");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
var _batchUploadTemplate = _interopRequireDefault(require("@/components/batchUploadTemplate.vue"));
var _index = require("@/utils/index");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import taoBao from './taoBao';
var _default = exports.default = {
  name: "ProductList",
  components: {
    batchUploadTemplate: _batchUploadTemplate.default
  },
  data: function data() {
    return {
      paymentAccountList: [],
      props: {
        emitPath: false
      },
      roterPre: _settings.roterPre,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        mer_cate_id: "",
        cate_id: "",
        keyword: "",
        type: "1",
        is_gift_bag: ""
        /*         business_type: "" */
      },
      categoryList: [],
      // 平台
      merCateList: [],
      // 商户分类筛选
      modals: false,
      storeList: [],
      showSku: false,
      skuList: [],
      showImg: false,
      // 查看图片 控制器
      imgUrl: "",
      // 放大查看的图片
      showBatchUpload: false
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    },
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  created: function created() {
    /*   this.getPaymentAccountByBusiness() */
    var query = JSON.parse(JSON.stringify(this.$route.query));
    if (query.page) {
      this.tableFrom.page = Number(query.page);
      this.tableFrom.keyword = query.keyword || "";
      this.tableFrom.is_gift_bag = query.is_gift_bag || "";
      this.tableFrom.type = query.type || "";
      this.tableFrom.mer_cate_id = query.mer_cate_id || "";
      this.tableFrom.cate_id = query.cate_id || "";
      this.tableFrom.limit = Number(query.limit || "20");
      /*    this.tableFrom.business_type = query.business_type || ""; */
    } else if (query.keyword) {
      this.tableFrom.keyword = query.keyword;
    }
  },
  mounted: function mounted() {
    this.getLstFilterApi();
    this.getCategorySelect();
    // this.getCategoryList();
    this.getList();
    this.getStoreList();
  },
  methods: {
    onGOUrl: function onGOUrl(url, type) {
      this.$router.push({
        path: url
      });
    },
    handleSelectionChange: function handleSelectionChange(data) {
      this.changeOrder = data;
    },
    getPaymentAccountByBusiness: function getPaymentAccountByBusiness() {
      var _this = this;
      (0, _system._getPaymentAccountByBusiness)().then(function (res) {
        _this.paymentAccountList = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    editSortAction: function editSortAction(val, id) {
      var _this2 = this;
      (0, _product.productSortApi)(id, {
        sort: val
      }).then(function () {
        _this2.$message.success("修改排序成功");
        // this.load();
      }).catch(function () {});
    },
    getSuccess: function getSuccess() {
      this.getLstFilterApi();
      this.getList(1);
    },
    batchUploadAuction: function batchUploadAuction() {
      var _this3 = this;
      // 批量上传
      this.showBatchUpload = true;
      this.$nextTick(function () {
        _this3.$refs["batchTemplate"].init(1);
      });
    },
    closeBatchAction: function closeBatchAction() {
      this.showBatchUpload = false;
    },
    getStoreList: function getStoreList(num) {
      var _this4 = this;
      (0, _product.getStoreList)({
        page: 1
      }).then(function (res) {
        _this4.storeList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 添加淘宝商品成功
    onClose: function onClose() {
      this.modals = false;
    },
    // 复制淘宝
    onCopy: function onCopy() {
      this.$refs.taoBao.modals = true;
      this.$refs.taoBao.soure_link = "";
      this.$refs.taoBao.formValidate = {};
      this.$refs.taoBao.isData = false;
    },
    handleRestore: function handleRestore(id) {
      var _this5 = this;
      this.$modalSure("恢复商品").then(function () {
        (0, _product.restoreApi)(id).then(function (res) {
          _this5.$message.success(res.message);
          _this5.getLstFilterApi();
          _this5.getList("");
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this6 = this;
      (0, _product.categorySelectTreeApi)().then(function (res) {
        _this6.merCateList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 平台分类；
    getCategoryList: function getCategoryList() {
      var _this7 = this;
      (0, _product.categoryListApi)().then(function (res) {
        _this7.categoryList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 列表表头；
    getLstFilterApi: function getLstFilterApi() {
      var _this8 = this;
      (0, _product.lstFilterApi)().then(function (res) {
        _this8.headeNum = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      for (var key in this.tableFrom) {
        var m = this.tableFrom[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    // 列表
    getList: function getList(num) {
      var _this9 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.setTypeAction();
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this9.tableData.data = res.data.list;
        _this9.tableData.total = res.data.count;
        _this9.listLoading = false;
      }).catch(function (res) {
        _this9.listLoading = false;
        // this.$message.error(res.message);
      });
      this.getLstFilterApi();
    },
    load: function load() {
      this.listLoading = true;
      this.getList("");
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this10 = this;
      this.$modalSure(this.tableFrom.type !== "5" ? "加入回收站" : "").then(function () {
        _this10.tableFrom.type === "5" ? (0, _product.destoryApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this10.$message.success(message);
          _this10.getList("");
          _this10.getLstFilterApi();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        }) : (0, _product.productDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this10.$message.success(message);
          _this10.getList("");
          _this10.getLstFilterApi();
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      });
    },
    // 删除
    batchDelete: function batchDelete() {
      var _this11 = this;
      if (this.changeOrder.length <= 0) return false;
      this.$modalSure(this.tableFrom.type !== "5" ? "确认批量加入回收站？" : "确认批量删除？").then(function () {
        _this11.tableFrom.type === "5" ? (0, _product.batchDestoryApi)({
          id: _this11.changeOrder.map(function (m) {
            return m.product_id;
          }).join(',')
        }).then(function (_ref5) {
          var message = _ref5.message;
          _this11.$message.success(message);
          _this11.getList(1);
          _this11.getLstFilterApi();
        }).catch(function (_ref6) {
          var message = _ref6.message;
        }) : (0, _product.batchProductDeleteApi)({
          id: _this11.changeOrder.map(function (m) {
            return m.product_id;
          }).join(',')
        }).then(function (_ref7) {
          var message = _ref7.message;
          _this11.$message.success(message);
          _this11.getList(1);
          _this11.getLstFilterApi();
        }).catch(function (_ref8) {
          var message = _ref8.message;
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this12 = this;
      (0, _product.statusApi)({
        id: row.product_id,
        status: row.mer_status
      }).then(function (_ref9) {
        var message = _ref9.message;
        _this12.$message.success(message);
        _this12.getList("");
        _this12.getLstFilterApi();
      }).catch(function (_ref10) {
        var message = _ref10.message;
      });
    },
    batchStatusAuction: function batchStatusAuction(status) {
      var _this13 = this;
      if (this.changeOrder.length <= 0) return false;
      this.$modalSure("\u786E\u8BA4\u6279\u91CF".concat(status ? "上架" : "下架", "\uFF1F")).then(function () {
        (0, _product.statusApi)({
          id: _this13.changeOrder.map(function (m) {
            return m.product_id;
          }).join(','),
          status: status
        }).then(function (_ref11) {
          var message = _ref11.message;
          _this13.$message.success(message);
          _this13.getList(1);
          _this13.getLstFilterApi();
        }).catch(function (_ref12) {
          var message = _ref12.message;
        });
      });
    },
    showSkuAction: function showSkuAction(sku) {
      this.skuList = sku;
      this.showSku = true;
    },
    copyXcxLink: function copyXcxLink(m, event) {
      var url = "/pages/productDetail/index?id=" + m.product_id;
      var input = document.createElement("input"); // 直接构建input
      input.value = url; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    copyH5Link: function copyH5Link(m, event) {
      var url = "/pages/productDetail/index?id=" + m.product_id;
      var url1 = "";
      if (this.domain) {
        url1 = "".concat(this.domain).concat(url);
      } else {
        url1 = "".concat(this.domain_url).concat(url);
      }
      var input = document.createElement("input"); // 直接构建input
      input.value = url1; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    showImgAction: function showImgAction(val, m) {
      var _this14 = this;
      var action = process.env.VUE_APP_BASE_API + "/api/sharePoster";
      var mer_id = this.$store.getters.mer_id;
      switch (val) {
        case 1:
          action += "?return_format=2&poster_type=product&qrcode_type=1&Independent_qrcode=1&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
        case 2:
          action += "?return_format=2&poster_type=product&qrcode_type=2&Independent_qrcode=1&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
        case 3:
          action += "?return_format=2&poster_type=product&qrcode_type=1&Independent_qrcode=0&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
        case 4:
          action += "?return_format=2&poster_type=product&qrcode_type=2&Independent_qrcode=0&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
        default:
          action += "?return_format=2&poster_type=product&qrcode_type=1&Independent_qrcode=1&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;
      this.showImg = true;
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this14.imgUrl = res.data.base_image;
      });
    },
    beforeCloseImg: function beforeCloseImg(done) {
      this.imgUrl = "";
      done();
    },
    downloadAction: function downloadAction() {
      window.open(_settingMer.default.https + "/excel/templateDownload?type=import_goods&token=" + (0, _auth.getToken)());
    }
  }
};