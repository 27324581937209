"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order");
var _orderDetail = _interopRequireDefault(require("./orderDetail"));
var _refundDetail = _interopRequireDefault(require("./refundDetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = _interopRequireDefault(require("@/components/cards/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    detailsFrom: _orderDetail.default,
    cardsData: _index.default,
    fileList: _fileList.default,
    refundFrom: _refundDetail.default
  },
  data: function data() {
    return {
      orderId: 0,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        order_sn: this.$route.query.order_sn ? this.$route.query.order_sn : "",
        order_type: "-1",
        keywords: "",
        status: "",
        date: "",
        page: 1,
        limit: 20,
        type: "1",
        username: "",
        order_id: this.$route.query.order_id ? this.$route.query.order_id : "",
        activity_type: ""
      },
      activityList: [{
        value: 0,
        label: "普通订单"
      }, {
        value: 1,
        label: "秒杀订单"
      }, {
        value: 2,
        label: "预售订单"
      }, {
        value: 3,
        label: "助力订单"
      }, {
        value: 4,
        label: "拼团订单"
      }],
      orderChartType: {},
      timeVal: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      ids: "",
      tableFromLog: {
        page: 1,
        limit: 10
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      LogLoading: false,
      dialogVisible: false,
      fileVisible: false,
      editVisible: false,
      cardLists: [],
      orderDatalist: null,
      headeNum: [],
      editId: "",
      formValidate: {
        total_price: "",
        pay_postage: "",
        pay_price: "",
        coupon_price: ""
      }
    };
  },
  mounted: function mounted() {
    if (this.$route.query.hasOwnProperty("order_sn")) {
      this.tableFrom.order_sn = this.$route.query.order_sn;
    } else {
      this.tableFrom.order_sn = "";
    }
    this.headerList();
    this.getList(1);
  },
  methods: {
    // 复制
    copy: function copy(item) {
      console.log(item);
      this.copyText = "".concat(item.address_name, "\uFF0C").concat(item.address_phone, "\uFF0C").concat(item.address_info);
      var input = document.createElement("input"); // 直接构建input
      input.value = this.copyText; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("操作成功");
    },
    // 订单筛选
    orderFilter: function orderFilter(item) {
      var status = false;
      // item.orderProduct.forEach((el) => {
      //     if (el.refund_num < el.product_num) {
      //         status = true;
      //     }
      // });
      return status;
    },
    // 退款详情页
    onRefundDetail: function onRefundDetail(sn) {
      this.$router.push({
        path: "refund",
        query: {
          sn: sn
        }
      });
    },
    // 表格某一行添加特定的样式
    addTdClass: function addTdClass(val) {
      if (val.row.status > 0 && val.row.paid == 1) {
        for (var i = 0; i < val.row.orderProduct.length; i++) {
          if (val.row.orderProduct[i].refund_num >= 0 && val.row.orderProduct[i].refund_num < val.row.orderProduct[i].product_num) {
            return "row-bg";
          }
        }
      } else {
        return " ";
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.dialogVisible = true;
      this.$refs.orderDetail.onOrderDetails(id);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this = this;
      (0, _order.exportAuctionOrderApi)(this.tableFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this.$createElement;
        _this.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this.$msgbox.close();
                _this.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("auction_order");
    },
    // 订单核销
    orderCancellation: function orderCancellation() {
      var _this2 = this;
      var that = this;
      this.$prompt("", "提示", {
        confirmButtonText: "立即核销",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputPlaceholder: "请输入核销码",
        inputErrorMessage: "请输入核销码"
      }).then(function (_ref) {
        var value = _ref.value;
        that.handleCancellation(value);
      }).catch(function () {
        _this2.$message({
          type: "info",
          message: "取消输入"
        });
      });
    },
    // 去核销
    handleCancellation: function handleCancellation(code) {
      var _this3 = this;
      this.$confirm("确定核销此订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _order.orderCancellationApi)(code).then(function (res) {
          _this3.$message.success(res.message);
          _this3.getList("");
        }).catch(function (res) {
          // this.$message.error(res.message);
          _this3.LogLoading = false;
        });
      }).catch(function () {
        _this3.$message({
          type: "info",
          message: "已取消核销"
        });
      });
    },
    // 订单记录
    // onOrderLog(id) {
    //   this.dialogVisible = true;
    //   this.LogLoading = true;
    //   orderLogApi(id, this.tableFromLog)
    //     .then((res) => {
    //       this.tableDataLog.data = res.data.list;
    //       this.tableDataLog.total = res.data.count;
    //       this.LogLoading = false;
    //     })
    //     .catch((res) => {
    //       // this.$message.error(res.message);
    //       this.LogLoading = false;
    //     });
    // },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList("");
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList("");
    },
    // 打印订单
    printOrder: function printOrder(id) {
      var _this4 = this;
      (0, _order.orderPrintApi)(id).then(function (res) {
        _this4.$message.success(res.message);
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this5 = this;
      if (row.is_del === 1) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)(row.order_id).then(function (_ref2) {
            var message = _ref2.message;
            _this5.$message.success(message);
            _this5.tableData.data.splice(idx, 1);
          }).catch(function (_ref3) {
            var message = _ref3.message;
          });
        });
      } else {
        this.$confirm("您选择的的订单存在用户未删除的订单，无法删除用户未删除的订单！", "提示", {
          confirmButtonText: "确定",
          type: "error"
        });
      }
    },
    // 备注
    onOrderMark: function onOrderMark(id) {
      var _this6 = this;
      this.$modalForm((0, _order.orderRemarkApi)(id)).then(function () {
        return _this6.getList("");
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.id);
      });
      this.ids = data.join(",");
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 编辑
    edit: function edit(id) {
      var _this7 = this;
      // this.$modalForm(orderUpdateApi(id)).then(() => this.getList(''));
      this.editId = id;
      this.editVisible = true;
      (0, _order.orderDetailApi)(id).then(function (res) {
        _this7.formValidate = {
          pay_postage: res.data.pay_postage,
          total_price: res.data.total_price,
          coupon_price: res.data.coupon_price,
          pay_price: (Number(res.data.total_price) + Number(res.data.pay_postage) - Number(res.data.coupon_price)).toFixed(2)
        };
        _this7.loading = false;
      }).catch(function (_ref4) {
        var message = _ref4.message;
        _this7.loading = false;
      });
    },
    editConfirm: function editConfirm() {
      var _this8 = this;
      (0, _order.orderUpdateApi)(this.editId, this.formValidate).then(function (res) {
        _this8.editVisible = false;
        _this8.$message.success(res.message);
        _this8.getList("");
      }).catch(function (_ref5) {
        var message = _ref5.message;
      });
    },
    changePrice: function changePrice() {
      this.formValidate.pay_price = (this.formValidate.total_price + this.formValidate.pay_postage - this.formValidate.coupon_price).toFixed(2);
    },
    // 发货
    send: function send(id) {
      var _this9 = this;
      this.$modalForm((0, _order.auctionOrderDeliveryApi)(id)).then(function () {
        return _this9.getList("");
      });
    },
    // 列表
    getList: function getList(num) {
      var _this10 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _order.auctionOrderListApi)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (m) {
          var newM = m;
          newM.auction.image = newM.auction.auction_images.split(",")[0];
          newM.auction.imageList = newM.auction.auction_images.split(",");
        });
        _this10.tableData.data = res.data.list;
        _this10.tableData.total = res.data.count;
        _this10.cardLists = res.data.stat;
        _this10.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this10.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    headerList: function headerList() {
      var _this11 = this;
      (0, _order.auctionOrderStatusNum)().then(function (res) {
        _this11.orderChartType = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 订单记录
    refundDetail: function refundDetail(id) {
      this.orderId = id;
      this.$refs.refundDetail.dialogVisible = true;
      this.$refs.refundDetail.onOrderDetails(id);
    }
  }
};