var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "videoConBox",
      style: { width: _vm.Width == 800 ? "530px" : "300px" },
    },
    [
      _c("div", {
        style: { height: _vm.Width == 800 ? "340px" : "530px" },
        attrs: { id: "player-wrapper" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }