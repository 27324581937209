"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _vuex = require("vuex");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      album_id: '',
      id: '',
      status: 1,
      sleOptions: {
        title: '',
        category_id: ''
      },
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      ruleForm: {
        image: '',
        name: '',
        issued_number: '',
        address: '',
        times: [],
        content: '',
        is_receive: 0
      },
      treeData: [],
      rules: {
        name: {
          required: true,
          message: '请输入邀请函标题',
          trigger: 'blur'
        },
        issued_number: {
          required: true,
          message: '请输入发放数量',
          trigger: 'blur'
        },
        address: {
          required: true,
          message: '请输入活动地址',
          trigger: 'blur'
        },
        times: {
          required: true,
          message: '请选择有效期',
          trigger: 'change'
        }
      },
      orderArr: [{
        type: 'nft',
        data: []
      }, {
        type: 'nft_album',
        data: []
      }, {
        type: 'product',
        data: []
      }, {
        type: 'snapshot',
        data: []
      }],
      changeType: '',
      changeIndex: 0,
      searchLoading: false,
      snapshotList: [],
      peopleNum: 0,
      liveType: ''
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })),
  mounted: function mounted() {
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.loadLiveDetail();
    }
  },
  methods: {
    loadLiveDetail: function loadLiveDetail() {
      var _this = this;
      (0, _marketing.inviteLetterDetailApi)(this.id).then(function (res) {
        var data = res.data;
        _this.ruleForm = {
          image: data.image,
          name: data.name,
          issued_number: data.issued_number,
          address: data.address,
          is_receive: data.is_receive || 0,
          content: data.content,
          times: [data.start_time, data.end_time]
        };
        _this.$refs['editor'].setContent(data.content || '');
      }).catch(function (e) {
        console.log(e);
        _this.$router.back();
      });
    },
    changeImg: function changeImg(key) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        if (key === 'qrcode_url') {
          _this2.ruleForm.qrcode_url = img[0];
        } else {
          _this2.ruleForm.image = img[0];
        }
      }, 1, '1');
    },
    saveAction: function saveAction() {
      var _this3 = this;
      var data = JSON.parse(JSON.stringify(this.ruleForm));
      if (!data.image) {
        this.$message.error('请上传邀请函封面图');
        return false;
      }
      if (!data.name) {
        this.$message.error('请输入邀请函标题');
        return false;
      }
      if (!data.issued_number) {
        this.$message.error('请输入发放数量');
        return false;
      }
      if (isNaN(data.issued_number)) {
        this.$message.error('发放数量必须为数字');
        return false;
      }
      if (!data.times.length) {
        this.$message.error('请选择有效期');
        return false;
      }
      if (!data.address) {
        this.$message.error('请输入活动地址');
        return false;
      }
      data.start_time = data.times[0];
      data.end_time = data.times[1];
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var action = _marketing.inviteLetterCreateApi;
      if (this.id) {
        action = _marketing.inviteLetterEditApi;
      }
      action(this.id, data).then(function () {
        loading.close();
        _this3.$message.success('操作成功');
        _this3.$router.back();
      }).catch(function (msg) {
        loading.close();
        _this3.$message.error(msg);
      });
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};