"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _accounts = require("@/api/accounts");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _nft = require("@/api/nft");
var _system = require("@/api/system");
var _user = require("@/api/user");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UserDetails",
  components: {
    fileList: _fileList.default
  },
  props: {
    uid: {
      type: Number,
      default: null
    },
    showdetail: {
      type: Boolean,
      default: false
    },
    showretaildetail: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      showview: false,
      moren: require("@/assets/images/f.png"),
      loading: false,
      columns: [],
      Visible: false,
      list: [{
        val: "0",
        label: "消费记录"
      }, {
        val: "3",
        label: "持有优惠券"
      }],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      },
      orderTableData: {
        data: [],
        total: 0
      },
      orderTableFrom: {
        page: 1,
        limit: 10
      },
      psInfo: null,
      type: "0",
      drawtype: "nft",
      userdetail: {},
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      nftListLoading: false,
      userjson: {},
      showusercard: false,
      showImg: false,
      imgSrc: "",
      showadd: false,
      adduid: "",
      userList: [],
      searchLoading: false,
      row: {},
      showOrderflag: false,
      timeVal: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      dtotal: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.uid) {
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.tableFrom.salesman_uid = this.uid;
      // this.tableFrom.type = 'order';
      (0, _marketing.distributorTeamListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
        _this.showview = true;
        loading.close();
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
      this.getdistributorStatistics();
      //   this.getNft();
    }
  },
  methods: {
    delrow: function delrow(row) {
      var _this2 = this;
      this.$confirm("确定删除该团队成员吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _marketing.distributorDeleteTeamApi)({
          uid: row.uid
        }).then(function (res) {
          _this2.$message({
            type: "success",
            message: "删除成功"
          });
          _this2.getList();
        });
      });
    },
    handleadd: function handleadd() {
      var _this3 = this;
      var params = {
        uid: this.adduid,
        salesman_uid: this.uid
      };
      (0, _marketing.distributorAddTeamApi)(params).then(function (res) {
        _this3.showadd = false;
        _this3.getList(1);
        _this3.$emit("refash");
      });
    },
    getList: function getList(page) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = page || this.tableFrom.page;
      (0, _marketing.distributorTeamListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    changeadduser: function changeadduser() {
      this.showadd = true;
      this.adduid = "";
      //this.searchUserAction();
    },
    searchUserAction: function searchUserAction(data) {
      var _this5 = this;
      var params = {
        keyword: data,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this5.userList = res.data.list;
      });
    },
    changedrawtype: function changedrawtype(type) {
      this.drawtype = type;
    },
    showvimage: function showvimage(item) {
      this.userjson = item;
      this.showusercard = true;
    },
    changeshowdetail: function changeshowdetail() {
      this.$emit("close");
    },
    changeType: function changeType(key) {
      this.type = key;
      this.tableFrom.page = 1;
      this.getInfo(key);
    },
    pageChange: function pageChange(page) {
      var _this6 = this;
      this.tableFrom.page = page;
      (0, _marketing.distributorTeamListApi)(this.tableFrom).then(function (res) {
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
      // this.getInfo(this.type)
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this7 = this;
      this.tableFrom.limit = val;
      (0, _marketing.distributorTeamListApi)(this.tableFrom).then(function (res) {
        _this7.tableData.data = res.data.list;
        _this7.tableData.total = res.data.count;
        _this7.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
      // this.getInfo(this.type)
    },
    showOrder: function showOrder(row) {
      this.showOrderflag = true;
      this.orderTableFrom.salesman_uid = this.uid;
      this.orderTableFrom.uid = row.uid;
      this.getOrderList(1);
    },
    getOrderList: function getOrderList(page) {
      var _this8 = this;
      this.orderTableFrom.page = page || this.orderTableFrom.page;
      (0, _marketing.distributorOrderListApi)(this.orderTableFrom).then(function (res) {
        _this8.orderTableData.data = res.data.list;
        _this8.orderTableData.total = res.data.count;
        _this8.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this8.listLoading = false;
      });
    },
    pageOrderChange: function pageOrderChange(page) {
      var _this9 = this;
      this.orderTableFrom.page = page;
      (0, _marketing.distributorOrderListApi)(this.orderTableFrom).then(function (res) {
        _this9.orderTableData.data = res.data.list;
        _this9.orderTableData.total = res.data.count;
        _this9.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this9.listLoading = false;
      });
    },
    handleOrderSizeChange: function handleOrderSizeChange(val) {
      var _this10 = this;
      this.orderTableFrom.limit = val;
      (0, _marketing.distributorOrderListApi)(this.orderTableFrom).then(function (res) {
        _this10.orderTableData.data = res.data.list;
        _this10.orderTableData.total = res.data.count;
        _this10.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this10.listLoading = false;
      });
    },
    getHeader: function getHeader() {
      this.psInfo = this.row;
    },
    showImgAction: function showImgAction(img) {
      this.imgSrc = img;
      this.showImg = true;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this11 = this;
      var data = JSON.parse(JSON.stringify(this.tableFrom));
      data.export_type = "salesmanTeamUser";
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this11.$createElement;
        _this11.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this11.$msgbox.close();
                _this11.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("salesmanTeamUser");
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
      this.getdistributorStatistics();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.join_data = e ? this.timeVal.join("-") : "";
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
      this.getdistributorStatistics();
    },
    getdistributorStatistics: function getdistributorStatistics() {
      var _this12 = this;
      var data = {
        date: this.tableFrom.date,
        salesman_uid: this.uid
      };
      (0, _marketing.distributorStatisticsApi)(data).then(function (res) {
        _this12.dtotal = res.data;
      });
    }
  }
};