"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _auction = require("@/api/auction");
var _basic = require("@/api/basic");
var _marketing = require("@/api/marketing");
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    selectView: _goods.default
  },
  data: function data() {
    return {
      loading: false,
      btnLoading: false,
      id: '',
      changeGoods: {},
      workDetail: {
        product_name: '',
        image: '',
        label_id: [],
        arrival_date: '',
        factory_price: '',
        spec: '',
        related_product_id: ''
      },
      rules: {
        product_name: [{
          required: true,
          message: '请输入产品名称',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请选择封面图',
          trigger: 'change'
        }],
        arrival_date: [{
          required: true,
          message: '请选择征集开始时间',
          trigger: 'change'
        }],
        factory_price: [{
          required: true,
          message: '请输入出厂价格',
          trigger: 'blur'
        }],
        spec: [{
          required: true,
          message: '请输入产品规格信息',
          trigger: 'blur'
        }]
      },
      showImage: false,
      imagesKey: '',
      tagList: []
    };
  },
  created: function created() {
    var _this = this;
    this.id = this.$route.query.id || '';
    (0, _basic.labelLstApi)({
      page: 1,
      limit: 200
    }).then(function (res) {
      _this.tagList = res.data.list;
      if (_this.id) {
        _this.loadDetail();
      }
    });
  },
  methods: {
    loadDetail: function loadDetail() {
      var _this2 = this;
      (0, _marketing.wineDetailApi)(this.id).then(function (res) {
        var d = res.data;
        _this2.workDetail = {
          product_name: d.product_name,
          image: d.image,
          label_id: d.label_id ? d.label_id.map(function (m) {
            return Number(m);
          }) : [],
          arrival_date: d.arrival_date,
          factory_price: d.factory_price,
          spec: d.spec
        };

        // this.changeGoods = d.

        _this2.$refs['editor'].setContent(d.content);
      });
    },
    delImgAction: function delImgAction(i) {
      this.workDetail.image = '';
    },
    showImageAction: function showImageAction(img) {
      this.imagesKey = img;
      this.showImage = true;
    },
    changeImg: function changeImg(key) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        _this3.workDetail.image = img[0];
        _this3.$refs.ruleForm.validateField(['image'], function (Error) {
          if (!Error) {
            // 验证通过
            console.log('通过了');
          } else {
            console.log('验证不通过');
          }
        });
      }, '1', '1');
    },
    setData: function setData(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/ordernumable/issues/1012
      dataTransfer.setData('Text', '');
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = Object.assign({}, _this4.workDetail);
          if (_this4.changeGoods.id) {
            data.related_product_id = _this4.changeGoods.id;
          }
          var loading = _this4.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _marketing.wineCreateApi;
          if (_this4.id) {
            action = _marketing.wineUpdateApi;
          } else {
            data.is_show = 0;
          }
          action(_this4.id, data).then(function () {
            loading.close();
            _this4.$message.success('操作成功');
            _this4.$router.back();
          }).catch(function (msg) {
            loading.close();
            _this4.$message.error(msg);
          });
        } else {
          return false;
        }
      });
    },
    handleAdditem: function handleAdditem() {
      this.$refs.selectView.changeshow();
    },
    handleDelitem: function handleDelitem() {
      this.changeGoods = {};
    },
    selectMessage: function selectMessage(data) {
      this.changeGoods = Object.assign({}, data.data[0]);
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};