"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _auction = require("@/api/auction");
var _system = require("@/api/system");
var _request = _interopRequireDefault(require("@/api/request"));
var _index = require("@/utils/index");
var _preview = _interopRequireDefault(require("@/views/decoration/preview.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    preview: _preview.default
  },
  data: function data() {
    return {
      showPreview: false,
      previewData: [],
      listNew: [],
      listLoading: true,
      queryParams: {
        page: 1,
        limit: 10,
        status: ''
      },
      total: 0,
      venue_id: '',
      albumData: {},
      albumList: [],
      imgData: {
        show: false,
        title: '',
        imgUrl: ''
      },
      type: this.$route.query.t || '3',
      pushNumData: {},
      createSchemeBtnLoading: false,
      btnLoading: false
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  mounted: function mounted() {
    this.venue_id = this.$route.query.vid || '';
    this.queryParams.venue_id = this.$route.query.vid || '';
    this.loadAlbum();
    this.loadPushArticle();
  },
  methods: {
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      params.t = this.type;
      this.$router.replace({
        query: params
      });
      console.log(params);
    },
    loadAlbum: function loadAlbum() {
      var _this = this;
      (0, _auction.albumlist)({
        page: 1,
        limit: 100,
        venue_id: this.venue_id
      }).then(function (res) {
        _this.albumList = res.data.list;
      });
    },
    loadPushArticle: function loadPushArticle() {
      var _this2 = this;
      (0, _auction.tweetListApi)(this.queryParams).then(function (response) {
        _this2.listNew = response.data.list;
      });
    },
    showImgAction: function showImgAction(val, m) {
      var _this3 = this;
      var title = '查看';
      var action = process.env.VUE_APP_BASE_API + '/api/sharePoster';
      var mer_id = this.$store.getters.mer_id;
      switch (val) {
        case 1:
          title += '小程序码';
          action += '?return_format=2&poster_type=album&qrcode_type=1&Independent_qrcode=1&id=' + m.album_id + '&width=1280&mer_id=' + mer_id;
          break;
        case 2:
          title += 'H5二维码';
          action += '?return_format=2&poster_type=album&qrcode_type=2&Independent_qrcode=1&id=' + m.album_id + '&mer_id=' + mer_id;
          break;
        case 3:
          title += '小程序海报';
          action += '?return_format=2&poster_type=album&qrcode_type=1&Independent_qrcode=0&id=' + m.album_id + '&mer_id=' + mer_id;
          break;
        case 4:
          title += 'H5海报';
          action += '?return_format=2&poster_type=album&qrcode_type=2&Independent_qrcode=0&id=' + m.album_id + '&mer_id=' + mer_id;
          break;
        default:
          title += '小程序码';
          action += '?return_format=2&poster_type=album&qrcode_type=1&Independent_qrcode=1&id=' + m.album_id + '&width=1280&mer_id=' + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;
      this.imgData.title = title;
      this.imgData.show = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this3.imgData.imgUrl = res.data.base_image;
      });
    },
    createScheme: function createScheme(m, i) {
      var _this4 = this;
      this.createSchemeBtnLoading = true;
      var url = '/pages/albumDetail/index?id=' + m.album_id;
      (0, _system.createScheme)({
        path: url
      }).then(function (res) {
        _this4.albumList[i].xcxLink = res.data.openlink;
        _this4.createSchemeBtnLoading = false;
      }).catch(function () {
        _this4.createSchemeBtnLoading = false;
      });
    },
    beforeCloseImg: function beforeCloseImg(done) {
      this.imgData.imgUrl = '';
      done();
    },
    addAction: function addAction() {
      this.$router.push({
        path: '/merchant/weChat/page',
        query: {
          vid: this.venue_id
        }
      });
    },
    editAction: function editAction(item) {
      this.$router.push({
        path: '/merchant/weChat/page',
        query: {
          vid: this.venue_id,
          id: item.id
        }
      });
    },
    delAction: function delAction(id) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u63A8\u6587?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.tweetDelApi)(id).then(function () {
          _this5.$message.success('删除成功');
          _this5.loadPushArticle();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showPreviewAction: function showPreviewAction(item) {
      var _this6 = this;
      this.showPreview = true;
      (0, _auction.tweetInfoApi)(item.id).then(function (res) {
        _this6.previewData = res.data.content;
      }).catch(function (message) {
        _this6.showPreview = false;
      });
    },
    closeAction: function closeAction() {
      this.showPreview = false;
    }
  }
};