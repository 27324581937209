"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  inject: ['uploader'],
  props: {
    type: String,
    action: {
      type: Function,
      default: function _default() {}
    },
    name: {
      type: String,
      default: 'file'
    },
    multiple: Boolean,
    accept: String,
    onProgress: Function,
    onSuccess: Function,
    onError: Function,
    beforeUpload: Function,
    onPreview: {
      type: Function,
      default: function _default() {}
    },
    onRemove: {
      type: Function,
      default: function _default() {}
    },
    fileList: Array,
    autoUpload: Boolean,
    listType: String,
    disabled: Boolean,
    limit: Number,
    onExceed: Function
  },
  data: function data() {
    return {
      mouseover: false,
      reqs: {}
    };
  },
  methods: {
    /**
     * 判断是否是图片
     * @param  {[type]}  str [description]
     * @return {Boolean}     [description]
     */
    isImage: function isImage(str) {
      return str.indexOf('image') !== -1;
    },
    /**
     * 出发变动
     * @param  {[type]} ev [description]
     * @return {[type]}    [description]
     */
    handleChange: function handleChange(ev) {
      var files = ev.target.files;
      if (!files) return;
      this.uploadFiles(files);
    },
    /**
     * 获取上传文件列表
     * @param  {[type]} files [description]
     * @return {[type]}       [description]
     */
    uploadFiles: function uploadFiles(files) {
      var _this = this;
      var postFiles = Array.prototype.slice.call(files);
      if (!this.multiple) {
        postFiles = postFiles.slice(0, 1);
      }
      if (postFiles.length === 0) {
        return;
      }
      postFiles.forEach(function (rawFile) {
        if (_this.autoUpload) _this.upload(rawFile);
      });
    },
    /**
     * 执行上传
     * @param  {[type]} rawFile [description]
     * @return {[type]}         [description]
     */
    upload: function upload(rawFile) {
      var _this2 = this;
      this.$refs.input.value = null;
      //判断是否存在前置钩子
      if (!this.beforeUpload) {
        return this.post(rawFile);
      }
      var before = this.beforeUpload(rawFile);
      //异步处理前置钩子，完成后再执行上传方法
      if (before && before.then) {
        before.then(function (processedFile) {
          var fileType = Object.prototype.toString.call(processedFile);
          //判断数据类型
          if (fileType === '[object File]' || fileType === '[object Blob]') {
            if (fileType === '[object Blob]') {
              processedFile = new File([processedFile], rawFile.name, {
                type: rawFile.type
              });
            }
            for (var p in rawFile) {
              if (rawFile.hasOwnProperty(p)) {
                processedFile[p] = rawFile[p];
              }
            }
            _this2.post(processedFile);
          } else {
            _this2.post(rawFile);
          }
        }, function () {
          _this2.onRemove(null, rawFile);
        });
      } else if (before !== false) {
        this.post(rawFile);
      } else {
        this.onRemove(null, rawFile);
      }
    },
    /**
     * 处理处理执行
     * @param  {[type]} file [description]
     * @return {[type]}      [description]
     */
    abort: function abort(file) {
      var reqs = this.reqs;
      if (file) {
        var uid = file;
        if (file.uid) uid = file.uid;
        if (reqs[uid]) {
          reqs[uid].abort();
        }
      } else {
        Object.keys(reqs).forEach(function (uid) {
          if (reqs[uid]) reqs[uid].abort();
          delete reqs[uid];
        });
      }
    },
    /**
     * 执行点击事件
     * @return {[type]} [description]
     */
    handleClick: function handleClick() {
      if (!this.disabled) {
        this.$refs.input.value = null;
        this.$refs.input.click();
      }
    },
    /**
     * 键盘点击
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    handleKeydown: function handleKeydown(e) {
      if (e.target !== e.currentTarget) return;
      if (e.keyCode === 13 || e.keyCode === 32) {
        this.handleClick();
      }
    },
    /**
     * 数据提交接口
     * @param  {[type]} rawFile [description]
     * @return {[type]}         [description]
     */
    post: function post(rawFile) {
      var _this3 = this;
      var uid = rawFile.uid;
      var req = this.action(rawFile, this.uploader.groupID);
      this.reqs[uid] = req;
      if (req && req.then) {
        req.then(function (res) {
          _this3.onSuccess(res, rawFile);
          delete _this3.reqs[uid];
        }).catch(function (err) {
          console.error('error错误', err);
          if (_this3.onError) {
            _this3.onError(err, rawFile);
          }
          delete _this3.reqs[uid];
        });
      }
    }
  },
  /**
   * 编译DOM结构
   * @param  {[type]} h [description]
   * @return {[type]}   [description]
   */
  render: function render() {
    var h = arguments[0];
    var handleClick = this.handleClick,
      drag = this.drag,
      name = this.name,
      handleChange = this.handleChange,
      multiple = this.multiple,
      accept = this.accept,
      listType = this.listType,
      uploadFiles = this.uploadFiles,
      disabled = this.disabled,
      handleKeydown = this.handleKeydown;
    //设置上传样式
    var data = {
      class: {
        'el-upload': true
      },
      on: {
        click: handleClick,
        keydown: handleKeydown
      }
    };
    //设置样式处理
    data.class["el-upload--".concat(listType)] = true;
    return h("div", (0, _babelHelperVueJsxMergeProps.default)([{}, data, {
      "attrs": {
        "tabindex": "0"
      }
    }]), [this.$slots.default, h("input", {
      "class": "el-upload__input",
      "attrs": {
        "type": "file",
        "name": name,
        "multiple": multiple,
        "accept": accept
      },
      "ref": "input",
      "on": {
        "change": handleChange
      }
    })]);
  }
};