"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = installDirective;
require("core-js/modules/es6.regexp.split");
var _store = _interopRequireDefault(require("../store"));
function installDirective(Vue) {
  Vue.directive('permission', {
    inserted: function inserted(el, binding) {
      if (binding.value) {
        if (binding.arg === 'disabled' && binding.value.indexOf('>') !== -1) {
          var permission = binding.value.split('>');
          if (!_store.default.getters.userPression[permission[0]] || !_store.default.getters.userPression[permission[0]].properties || !_store.default.getters.userPression[permission[0]].properties.display_fields || _store.default.getters.userPression[permission[0]].properties.display_fields.indexOf(permission[1]) === -1) {
            el.setAttribute('class', el.className + ' directive-disabled has-bg');
          }
        } else if (!_store.default.getters.userPression[binding.value]) {
          if (binding.arg === 'disabled') {
            el.setAttribute('class', el.className + ' directive-disabled');
            // el.setAttribute('disabled', 'true');
          } else {
            el.remove();
          }
        }
      }
    },
    update: function update(el, binding) {
      if (binding.value) {
        if (binding.arg === 'disabled' && binding.value.indexOf('>') !== -1) {
          var permission = binding.value.split('>');
          if (!_store.default.getters.userPression[permission[0]] || !_store.default.getters.userPression[permission[0]].properties || !_store.default.getters.userPression[permission[0]].properties.display_fields || _store.default.getters.userPression[permission[0]].properties.display_fields.indexOf(permission[1]) === -1) {
            el.setAttribute('class', el.className + ' directive-disabled has-bg');
          }
        } else if (!_store.default.getters.userPression[binding.value]) {
          if (binding.arg === 'disabled') {
            el.setAttribute('class', el.className + ' directive-disabled');
            // el.setAttribute('disabled', 'true');
          } else {
            el.remove();
          }
        }
      }
    }
  });
}