"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _index = require("@/utils/index");
var _work = require("@/api/work");
var _request = _interopRequireDefault(require("@/api/request"));
var _product = require("@/api/product");
var _ticket = require("@/api/ticket");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { TcPlayer } from '@/assets/js/TcPlayer-module-2.4.1';
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      categoryList: [],
      categoryValue: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        category_id: "",
        file_type: "",
        title: ""
      },
      showFlag: false,
      showDetail: {},
      miniFlag: false,
      miniurl: ""
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  mounted: function mounted() {
    var _this = this;
    var queryData = JSON.parse(JSON.stringify(this.$route.query));
    if (Number(queryData.page)) {
      this.tableFrom = queryData;
      this.tableFrom.page = Number(this.tableFrom.page);
      this.tableFrom.limit = Number(this.tableFrom.limit);
    }
    this.getList();
    (0, _product.storeCategoryListApi)().then(function (res) {
      _this.categoryList = res.data;
    }).catch(function (err) {
      console.log(err);
    });
  },
  methods: {
    handleChange: function handleChange(val) {
      this.tableFrom.category_id = val.length ? val[val.length - 1] : "";
      this.getList(1);
    },
    createdAction: function createdAction() {
      this.$router.push({
        path: "./detail"
      });
    },
    editAction: function editAction(id) {
      this.$router.push({
        path: "./detail",
        query: {
          id: id
        }
      });
    },
    delAction: function delAction(id) {
      var _this2 = this;
      // delLiveApi
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u4F5C\u54C1?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _work.workDeleteApi)(id).then(function () {
          _this2.$message.success("操作成功");
          _this2.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      (0, _work.workListApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    changeSort: function changeSort(row) {
      var _this4 = this;
      var value = row.sort;
      if (isNaN(value)) {
        this.$message.error("请输入数字");
        this.getList();
        return;
      }
      (0, _work._changeWorkSort)({
        id: row.works_id,
        sort: value
      }).then(function (res) {
        if (res.status === 200) {
          _this4.$message.success("保存成功");
          _this4.tableFrom.page = 1;
          _this4.getList();
        } else {
          _this4.$message.err(res.message);
        }
      }).catch(function (err) {
        console.log(err);
        _this4.$message.err(err.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    copyXcxLink: function copyXcxLink(m, event) {
      var url = "/pages/works/detail?id=" + m.works_id;
      var u = "/pages/otherPage/otherPage?link=".concat(encodeURIComponent(url));
      var input = document.createElement("input"); // 直接构建input
      input.value = u; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    copyH5Link: function copyH5Link(m, event) {
      var url = "/pages/works/detail?id=" + m.works_id;
      var url1 = "";
      if (this.domain) {
        url1 = "".concat(this.domain).concat(url);
      } else {
        url1 = "".concat(this.domain_url).concat(url);
      }
      var input = document.createElement("input"); // 直接构建input
      input.value = url1; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    showImgAction: function showImgAction(m, index) {
      var _this5 = this;
      var action = process.env.VUE_APP_BASE_API + "/api/sharePoster";
      var mer_id = this.$store.getters.mer_id;
      var url = "/pages/works/detail?id=".concat(m.works_id);
      switch (index) {
        case 1:
          action += "?return_format=2&poster_type=works&qrcode_type=1&Independent_qrcode=1&id=" + m.works_id + '&path=' + encodeURIComponent('/pages/otherPage/otherPage?link=' + encodeURIComponent(url)) + "&mer_id=" + mer_id;
          break;
        case 2:
          action += "?return_format=2&poster_type=works&qrcode_type=2&Independent_qrcode=1&id=" + m.works_id + '&path=' + url + "&mer_id=" + mer_id;
          break;
        case 3:
          action += "?return_format=2&poster_type=auction&qrcode_type=1&Independent_qrcode=0&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
        case 4:
          action += "?return_format=2&poster_type=auction&qrcode_type=2&Independent_qrcode=0&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
        default:
          action += "?return_format=2&poster_type=auction&qrcode_type=1&Independent_qrcode=1&id=" + m.product_id + "&mer_id=" + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;

      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        if (index == 1) {
          _this5.miniFlag = true;
          _this5.miniurl = res.data.base_image;
        } else {
          _this5.showFlag = true;
          _this5.showDetail = {
            h5_url: "/pages/works/detail?id=".concat(m.works_id),
            qr_code: res.data.base_image
          };
        }
      }).catch(function (err) {
        _this5.miniFlag = false;
        _this5.showFlag = false;
        loading.close();
      });
    }
  }
};