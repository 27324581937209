"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _nftSaleTmp = _interopRequireDefault(require("@/components/nftSaleTmp"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    NftSaleTmp: _nftSaleTmp.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      orderChartType: {},
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        type: 1,
        store_name: '',
        status: '',
        page: 1,
        limit: 10
      }
    };
  },
  created: function created() {
    this.getList(1);
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  methods: {
    changeStatusAction: function changeStatusAction() {
      this.loadStatus = false;
      this.getList(1);
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.consignListApi)(this.tableFrom).then(function (res) {
        res.data.data.forEach(function (m) {
          if (!m.conf) m.conf = [];else m.conf = JSON.parse(m.conf);
        });
        _this.tableData.data = res.data.data;
        _this.tableData.total = res.data.total;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    copyAction: function copyAction(val) {
      var input = document.createElement('input'); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    editTimeAction: function editTimeAction(index) {
      this.editRule(index);
    },
    editValAction: function editValAction(index) {
      this.editRule(index);
    },
    addRuleAction: function addRuleAction(index) {
      var d = Date.parse(new Date());
      this.tableData.data[index].conf = this.tableData.data[index].conf.concat([{
        start_time: (0, _index.parseTime)(d, '{y}-{m}-{d} {h}:{i}'),
        max_price: '',
        min_price: ''
      }]);
      this.editRule(index);
    },
    delRuleAction: function delRuleAction(index, i) {
      if (this.tableData.data[index].conf.length <= 1 && this.tableData.data[index].status === 1) {
        this.$message.error('开启寄售状态不能清空限价设置');
        return false;
      }
      this.tableData.data[index].conf.splice(i, 1);
      this.editRule(index);
    },
    editRule: function editRule(index) {
      var _this2 = this;
      (0, _marketing.consignConfEditApi)({
        series_id: this.tableData.data[index].product_collection_id,
        type: 1,
        conf: JSON.stringify(this.tableData.data[index].conf)
      }).then(function () {
        _this2.$message.success('修改成功');
      }).catch(function () {});
    },
    closeAction: function closeAction(index) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5173\u95ED\u5BC4\u552E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.consignStatusEditApi)({
          series_id: _this3.tableData.data[index].product_collection_id,
          conf: JSON.stringify(_this3.tableData.data[index].conf),
          type: 1,
          status: 2
        }).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    openAction: function openAction(index) {
      var _this4 = this;
      if (this.tableData.data[index].conf.length <= 0) {
        this.$message.error('请先设置限价规则');
        return false;
      }
      this.$confirm("\u786E\u8BA4\u5F00\u542F\u5BC4\u552E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.consignStatusEditApi)({
          series_id: _this4.tableData.data[index].product_collection_id,
          conf: JSON.stringify(_this4.tableData.data[index].conf),
          type: 1,
          status: 1
        }).then(function () {
          _this4.$message.success('操作成功');
          _this4.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showNftSaleAction: function showNftSaleAction(m, i) {
      this.showNftIndex = i;
      this.$refs.nftSaleTmpEL.loadInit(m.product_collection_id, 1);
    },
    remoreOkAction: function remoreOkAction() {
      this.tableData.data[this.showNftIndex].consign_num -= 1;
    },
    downAction: function downAction(m) {
      var _this5 = this;
      if (!m.id) {
        this.$message.error('暂无可下架的藏品');
        return false;
      }
      this.$confirm("\u786E\u8BA4\u4E0B\u67B6\u8BE5\u85CF\u54C1\u7684\u6240\u6709\u5BC4\u552E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.batchDisableApi)({
          id: m.id
        }).then(function () {
          _this5.$message.success('操作成功');
          _this5.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    editServiceRateAction: function editServiceRateAction(val, index) {
      var _this6 = this;
      if (val > 100) {
        this.$message.error('比例最大设置100%，已自动变更为100%');
        val = 100;
      }
      (0, _marketing.saveServiceRateApi)({
        series_id: this.tableData.data[index].product_collection_id,
        service_charge_rate: val || 0,
        type: 1
      }).then(function (res) {
        if (!val) {
          _this6.tableData.data[index].service_charge_rate = 0;
        } else if (val === 100) {
          _this6.tableData.data[index].service_charge_rate = 100;
        }
        _this6.$message.success('设置成功');
      }).catch(function () {});
    },
    editBrokerageAction: function editBrokerageAction(val, index) {
      var _this7 = this;
      if (val > 100) {
        this.$message.error('比例最大设置100%，已自动变更为100%');
        val = 100;
      }
      (0, _marketing.saveServiceRateApi)({
        series_id: this.tableData.data[index].product_collection_id,
        one_brokerage_percent: val || 0,
        type: 1
      }).then(function (res) {
        if (!val) {
          _this7.tableData.data[index].one_brokerage_percent = 0;
        } else if (val === 100) {
          _this7.tableData.data[index].one_brokerage_percent = 100;
        }
        _this7.$message.success('设置成功');
      }).catch(function () {});
    }
  }
};