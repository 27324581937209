var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "article-manager", staticStyle: { "padding-top": "10px" } },
    [
      _vm._l(_vm.currencyList, function (item, index) {
        return _c(
          "div",
          { key: index, staticStyle: { "margin-bottom": "20px" } },
          [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { "true-label": 1, "false-label": 0 },
                    model: {
                      value: item.is_choice,
                      callback: function ($$v) {
                        _vm.$set(item, "is_choice", $$v)
                      },
                      expression: "item.is_choice",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "14px",
                        height: "14px",
                        "margin-right": "3px",
                        "vertical-align": "middle",
                      },
                      attrs: { src: _vm.consfigOption.icon, alt: "" },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.consfigOption.type) +
                        _vm._s(_vm.consfigOption.name) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    class: { colFont: item.isno },
                    staticStyle: { width: "200px", margin: "0 6px" },
                    on: {
                      input: function ($event) {
                        return _vm.onInput(item)
                      },
                    },
                    model: {
                      value: item.rate,
                      callback: function ($$v) {
                        _vm.$set(item, "rate", $$v)
                      },
                      expression: "item.rate",
                    },
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("1" + _vm._s(_vm.consfigOption.unit_name) + "="),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("img", {
                  staticStyle: {
                    width: "20px",
                    height: "20px",
                    "margin-right": "6px",
                  },
                  attrs: { src: item.icon, alt: "" },
                }),
                _vm._v(" "),
                _c("div", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v(_vm._s(item.name) + " " + _vm._s(item.type)),
                ]),
              ],
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
        [_vm._v("保存")]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
      _c("div", [_vm._v("系统按当日汇率，每天更新一次。")]),
      _vm._v(" "),
      _c("div", [
        _vm._v("支持自定义修改，修改后的(蓝色标记)系统将不再动态更新汇率"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }