"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts/core"));
var _charts = require("echarts/charts");
var _renderers = require("echarts/renderers");
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _dashboard = require("@/api/dashboard");
//
//
//
//
//
//
//

// import echarts from "echarts";

// 注册必须的组件
echarts.use([_charts.PieChart, _renderers.CanvasRenderer]);
require('echarts/theme/macarons'); // echarts theme
var _default = exports.default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    amount: {
      type: Boolean,
      default: true
    },
    date: {
      type: String,
      default: 'lately7'
    }
  },
  data: function data() {
    return {
      chart: null,
      newData: '',
      oldData: '',
      Comment: []
    };
  },
  watch: {
    amount: {
      deep: true,
      handler: function handler(val) {
        this.amount = val;
        this.getTurnoverRatio();
      }
    },
    date: {
      deep: true,
      handler: function handler(val) {
        this.date = val;
        this.getTurnoverRatio();
      }
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {});
    // this.getTurnoverRatio();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    getTurnoverRatio: function getTurnoverRatio() {
      var that = this;
      (0, _dashboard.customerRatioApi)({
        date: this.date
      }).then(function (res) {
        if (res.status === 200) {
          that.orderCustomer = res.data;
          that.newData = that.amount ? res.data.newTotalPrice : res.data.newUser;
          that.oldData = that.amount ? res.data.oldTotalPrice : res.data.oldUser;
          that.chart = echarts.init(that.$el, 'macarons');
          that.chart.setOption({
            title: {
              text: '成交金额占比',
              left: 'center',
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'bold',
                color: '#262626'
              }
            },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              // orient: "vertical",
              bottom: 0,
              left: 'center',
              data: ['新成交客户', '老客户']
            },
            series: [{
              name: '成交比',
              type: 'pie',
              // roseType: "radius",
              radius: '55%',
              center: ['50%', '50%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [{
                value: that.newData,
                name: '新成交客户',
                itemStyle: {
                  color: '#39a1ff'
                }
              }, {
                value: that.oldData,
                name: '老客户',
                itemStyle: {
                  color: '#4ecb73'
                }
              }],
              animationEasing: 'cubicInOut',
              animationDuration: 2600
            }]
          });
        }
      }).catch(function (res) {
        that.$message.error(res.message);
      });
    }
  }
};