"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _freight = require("@/api/freight");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ShippingTemplates',
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: '开启',
        0: '关闭'
      };
      return statusMap[status];
    },
    typeFilter: function typeFilter(status) {
      var statusMap = {
        0: '按件数',
        1: '按重量',
        2: '按体积'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      tableFrom: {
        page: 1,
        limit: 20,
        name: ''
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      componentKey: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    add: function add() {
      var _this = this;
      this.$modalTemplates(0, function () {
        _this.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _freight.templateListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this = this;
      this.$modalTemplates(id, function () {
        _this.getList();
      }, this.componentKey += 1);
      // this.$refs.addTemplates.getCityList()
      // this.$refs.addTemplates.getInfo(id)
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _freight.templateDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success(message);
          _this3.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this3.$message.error(message);
        });
      });
    }
  }
};