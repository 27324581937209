"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _llpay = _interopRequireDefault(require("./llpay.js"));
var _lianlianpay = require("@/api/lianlianpay");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    llpay: _llpay.default
  },
  data: function data() {
    return {
      currentTab: 0,
      form: {
        verify_code: ""
      },
      ruleValidate: {
        verify_code: [{
          required: true,
          message: "请输入短信验证码",
          trigger: "blur"
        }]
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _llpay.default)().then(function (res) {
      console.log("加载完成");
      (0, _lianlianpay.getPasswordRandomFactor)().then(function (res) {
        console.log(res);
        _this.getrandomKey(res.data);
      });
    });
  },
  methods: {
    getrandomKey: function getrandomKey(data) {
      // 初始化密码控件
      function initPassword() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        // eslint-disable-next-line
        var passowrd = new showKeyBord({
          png: "//mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/img",
          // 图片路径，用这个就行了，也可以下载到本地
          click_objId: "#keyboardBtn2",
          // 触发显示键盘的Dom节点Id
          regExp1: "[\\S\\s]",
          // 输入过程限制的正则，不匹配正则的字符不让输入
          regExp2: "^[\\S\\s]{6,12}$",
          // 输入完成后的正则，与getValid()对应，判断输入值是否匹配该正则
          Input_texId: "#LOGPASS2",
          // 接收内容的Dom节点Id
          parent_objId: "#ipt_pwd2",
          // 将键盘插入到何处的Dom节点Id
          placeholderId: "#LOGPASS2_SHOW",
          // span标签的id，该span用于模拟Input_texId的placeholder
          len: "12",
          // 表示最大允许输入多少个字符[可以不传递]
          keyBordLeft: "",
          keyBordTop: "",
          chaosMode: 0,
          // 混乱模式,0:无混乱;1:打开时乱一次;2:每输入一个字符乱一次,默认值0
          clickStatus: 1,
          // 按键状态,0:鼠标划过、悬浮时按键无变化;1:鼠标划过、悬浮时按键的颜色变化,默认值0
          titlefont: "安全支付输入",
          backfont: "退 格",
          capsfont: "切换大/小写",
          surefont: "确定",
          isen: false,
          callBack: function callBack() {},
          // 密码校验成功回调
          errorCallBack: function errorCallBack() {},
          // 密码校验失败回调
          rsaPublicKey: options.rsaPublicKey || "",
          // RSA加密必须参数，国密加密可不要
          sm2KeyHex: options.sm2KeyHex || "",
          // 国密加密必须参数，RSA加密可不要
          license: options.license || "" // 必须参数
        });
        passowrd.initOptions();
        console.log("init");
        return passowrd;
      }
      // 随机因子，必须包含sm2KeyHex、license、randomKey，这里只是示例，正常使用应该由服务端调起连连随机因子申请接口获取
      var options = {
        license: data.license,
        sm2KeyHex: data.sm2_key_hex,
        randomKey: data.random_key,
        rsaPublicKey: data.rsa_public_content
      };
      this.randomKey = data.random_key;
      this.randomValue = data.random_value;
      this.passwordInstance = initPassword(options);
      //   $("#get-password").click(function () {
      //     // 获取国密密文
      //     passwordInstance.setRandKey(options.randomKey); // 先设置随机key
      //     const password = passwordInstance.getOutputSM(); // 国密加密密码
      //     const rsaPassword = passwordInstance.getOutput(); // RSA加密密码
      //     // $('#password').text(rsaPassword);
      //     console.log("国密密码是", password);
      //     console.log("RSA密码是", rsaPassword);
      //     // // 清空密码框
      //     // passwordInstance.clearPWD()

      //     // 其他API请参考文档
      //   });
    },
    applya: function applya() {
      var _this2 = this;
      (0, _lianlianpay.getFindPasswordApply)().then(function (res) {
        if (res.data.ret_code == "0000") {
          _this2.currentTab++;
          console.log(res);
          _this2.currentJson = res.data;
        } else {
          _this2.$message.error(res.data.ret_msg);
        }
      });
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.passwordInstance.setRandKey(_this3.randomValue); // 先设置随机key
          (0, _lianlianpay.getFindPassword)({
            token: _this3.currentJson.token,
            verify_code: _this3.form.verify_code,
            random_key: _this3.randomKey,
            password: _this3.passwordInstance.getOutputSM()
          }).then(function (res) {
            console.log(res);
            if (res.data.ret_code == "0000") {
              _this3.$message({
                message: "提交成功",
                type: "success"
              });
              _this3.$router.back();
            } else {
              _this3.$message.error(res.data.ret_msg);
            }
          });
        } else {
          _this3.$message({
            message: "请填写完整",
            type: "warning"
          });
        }
      });
    }
  }
};