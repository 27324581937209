"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var cmsRouter = {
  path: "".concat(_settings.roterPre, "/open3d"),
  name: 'open3d',
  meta: {
    icon: 'dashboard',
    title: '内容'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'template/list',
    name: 'template_list',
    meta: {
      title: '模型管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/open3d/template'));
      });
    }
  }, {
    path: 'template/market',
    name: 'template_market',
    meta: {
      title: '模型市场',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/open3d/template_market'));
      });
    }
  }, {
    path: 'order_merchant',
    name: 'order_merchant',
    meta: {
      title: '商户订单管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/open3d/order_merchant'));
      });
    }
  }]
};
var _default = exports.default = cmsRouter;