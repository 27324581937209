"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _accounts = require("@/api/accounts");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var stime = 0;
var etime = 0;
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      status: '1',
      formatPriceNumber: _index.formatPriceNumber,
      info: null,
      query: {
        reconciliation_end_day: ''
      },
      tableData: {
        show: false,
        data: [],
        total: 0
      },
      listLoading: false,
      queryParams: {
        page: 1,
        limit: 10
      },
      queryParamsN: {
        page: 1,
        limit: 10
      },
      tableDataN: {
        data: [],
        total: 0
      },
      list: [],
      stime: 0,
      etime: 0,
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < stime || time.getTime() >= etime;
        }
      },
      queryTime: {}
    };
  },
  created: function created() {
    this.loadInfo();
  },
  methods: {
    getTime: function getTime(key) {
      return this[key];
    },
    editDateAction: function editDateAction() {
      this.query.reconciliation_end_day = this.info.reconciliation_end_day;
      this.loadInfo();
    },
    confirmReconciliationAction: function confirmReconciliationAction() {
      var _this = this;
      this.$confirm("\u786E\u8BA4\u8BE5\u671F\u8D26\u5355\u786E\u8BA4\u65E0\u8BEF?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _accounts.confirmReconciliationApi)(_this.query).then(function () {
          _this.$message.success('对账成功');
          _this.query = {};
          _this.loadInfo();
        }).catch(function (msg) {
          console.log(msg);
        });
      }).catch(function () {});
    },
    loadInfo: function loadInfo() {
      var _this2 = this;
      (0, _accounts.getCurrentReconciliationInfoApi)(this.query).then(function (res) {
        _this2.info = res.data;
        var st = new Date(res.data.reconciliation_start_day);
        st.setHours('0', '0', '0');
        var et = new Date(res.server_time * 1000);
        et.setHours('0', '0', '0');
        stime = st.getTime();
        etime = et.getTime();
        _this2.queryTime = {
          start_time: res.data.reconciliation_start_day,
          end_time: res.data.reconciliation_end_day
        };
        _this2.loadPayOrderRecord();
      }).catch(function () {});
      (0, _accounts.walletReconciliationListApi)({
        page: 1,
        limit: 1
      }).then(function (res) {
        _this2.tableData.total = res.data.count;
      });
    },
    loadPayOrderRecord: function loadPayOrderRecord() {
      var _this3 = this;
      this.listLoading = true;
      (0, _accounts.getReconciliationPayOrderRecordApi)(this.queryTime).then(function (res) {
        _this3.list = res.data;
        _this3.listLoading = false;
      });
    },
    showRecordAction: function showRecordAction() {
      this.tableData.show = true;
      this.queryParams.page = 1;
      this.loadReconciliation();
    },
    loadReconciliation: function loadReconciliation() {
      var _this4 = this;
      (0, _accounts.walletReconciliationListApi)(this.queryParams).then(function (res) {
        _this4.tableData.total = res.data.count;
        _this4.tableData.data = res.data.list;
      });
    },
    // 导出
    exportOrder: function exportOrder(id) {
      var _this5 = this;
      var data = (0, _objectSpread2.default)({
        export_type: 'wallet_reconciliation_record'
      }, this.queryTime);
      if (id) {
        data = {
          export_type: 'wallet_reconciliation_record',
          reconciliation_id: id
        };
      }
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList('wallet_reconciliation_record');
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList(type) {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    }
  }
};