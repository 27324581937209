var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "digital-collection mt20" },
    [
      _c(
        "div",
        { staticClass: "flex align-items-c" },
        [
          _c("span", { staticClass: "ft14 fWeight500" }, [
            _vm._v("状态选择："),
          ]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              attrs: { type: "button" },
              on: {
                change: function ($event) {
                  return _vm.getList(1)
                },
              },
              model: {
                value: _vm.queryParams.status,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "status", $$v)
                },
                expression: "queryParams.status",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "" } }, [_vm._v("全部")]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v("上链成功"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v("转赠中"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "3" } }, [
                _vm._v("已销毁"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "4" } }, [
                _vm._v("已回收"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt20" },
        [
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { placeholder: "作品名称" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "编号 例：A01#88" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.issue_number,
                callback: function ($$v) {
                  _vm.issue_number = $$v
                },
                expression: "issue_number",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作品系列", clearable: "" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.album_id,
                callback: function ($$v) {
                  _vm.album_id = $$v
                },
                expression: "album_id",
              },
            },
            _vm._l(_vm.albumList, function (m, i) {
              return _c("el-option", {
                key: i,
                attrs: { value: m.nft_album_id, label: m.album_name },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "当前归属" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.curr,
                callback: function ($$v) {
                  _vm.curr = $$v
                },
                expression: "curr",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportOrder },
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.getExportFileList } },
            [_vm._v("导出已生成列表")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table no-height",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { width: "50" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return _vm.queryParams.status == 1
                          ? [
                              _c(
                                "el-popover",
                                {
                                  staticClass: "tabPop",
                                  attrs: {
                                    placement: "top-start",
                                    width: "100",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "spBlock onHand",
                                        class: { check: _vm.chkName === "dan" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onHandle(
                                              "dan",
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("选中本页")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-checkbox", {
                                    attrs: {
                                      slot: "reference",
                                      value:
                                        (_vm.chkName === "dan" &&
                                          _vm.checkedPage.indexOf(
                                            _vm.queryParams.page
                                          ) > -1) ||
                                        _vm.chkName === "duo",
                                    },
                                    on: { change: _vm.changeType },
                                    slot: "reference",
                                  }),
                                ],
                                1
                              ),
                            ]
                          : undefined
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm.queryParams.status == 1
                          ? [
                              _c("el-checkbox", {
                                attrs: {
                                  value:
                                    _vm.checkedIds.indexOf(
                                      scope.row.nft_data_id
                                    ) > -1 ||
                                    (_vm.chkName === "duo" &&
                                      _vm.noChecked.indexOf(
                                        scope.row.nft_data_id
                                      ) === -1),
                                },
                                on: {
                                  change: function (v) {
                                    return _vm.changeOne(v, scope.row)
                                  },
                                },
                              }),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品信息" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c("div", { staticClass: "work-img" }, [
                            _c("img", {
                              attrs: {
                                src: row.product.image + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "user-info flex-one ml10",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c("div", { staticClass: "fWeight500" }, [
                                _vm._v(_vm._s(row.product.store_name)),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _vm._v(_vm._s(row.issue_number)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "首购用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.buyUser
                          ? _c("div", { staticClass: "flex align-items-c" }, [
                              _vm._v(_vm._s(row.buyUser.nickname)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "交易时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.create_time))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "当前归属" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.ownUser
                          ? _c("div", { staticClass: "flex align-items-c" }, [
                              _vm._v(_vm._s(row.ownUser.nickname)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _vm._v(
                            _vm._s(row.status_text) +
                              " " +
                              _vm._s(row.is_sell ? "寄售中" : "")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        !row.is_sell && row.status == 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.batchRecoveryAction(
                                          row.nft_data_id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("回收")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.batchDestroyAction(
                                          row.nft_data_id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("销毁")]
                                ),
                              ],
                              1
                            )
                          : !row.is_sell && row.status == 4
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.putInAction(row)
                                      },
                                    },
                                  },
                                  [_vm._v("重新投放")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-b orderFoot" },
            [
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.batchRecoveryAction()
                        },
                      },
                    },
                    [_vm._v("批量回收")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.batchDestroyAction()
                        },
                      },
                    },
                    [_vm._v("批量销毁")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.czData.type == 1 ? "回收藏品" : "销毁藏品",
            visible: _vm.czData.show,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.czData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "请输入操作备注" },
                model: {
                  value: _vm.czData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.czData, "remark", $$v)
                  },
                  expression: "czData.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.czAction } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.czData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "重新投放",
            visible: _vm.putInData.show,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.putInData, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c fWeight500 mt20" }, [
            _c("span", { staticClass: "w100" }, [_vm._v("作品：")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-one" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.putInData.title) +
                  " | " +
                  _vm._s(_vm.putInData.code) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("接收用户：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w300",
                  attrs: {
                    placeholder: "真实姓名/手机号/昵称搜索",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.searchUserAction,
                    loading: _vm.searchLoading,
                  },
                  model: {
                    value: _vm.putInData.uid,
                    callback: function ($$v) {
                      _vm.$set(_vm.putInData, "uid", $$v)
                    },
                    expression: "putInData.uid",
                  },
                },
                _vm._l(_vm.userList, function (m, i) {
                  return _c(
                    "el-option",
                    {
                      key: "user" + i,
                      attrs: { value: m.uid, label: m.nickname },
                    },
                    [
                      _c("div", { staticClass: "avatar flex align-items-c" }, [
                        _c("img", { attrs: { src: m.avatar, alt: "" } }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "line1 flex-one",
                            staticStyle: { "line-height": "18px" },
                          },
                          [
                            _c("div", [
                              m.real_name
                                ? _c("span", [
                                    _vm._v(_vm._s(m.real_name) + " | "),
                                  ])
                                : _vm._e(),
                              _vm._v(_vm._s(m.nickname)),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(m.phone))]),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-btn mt20" },
            [
              _c("el-button", { on: { click: _vm.clearPutInDataAction } }, [
                _vm._v("\n        取消\n      "),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.putInData.btnLoading },
                  on: { click: _vm.putInDataConfirmAction },
                },
                [_vm._v("\n        确认\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }