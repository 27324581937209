var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: _vm.name, hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c("el-card", [
        _c("div", { staticClass: "img-text-top-list" }, [
          _c("div", { staticClass: "img-text-title" }, [_vm._v("内容")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "img-text-content",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.showImgTextAction },
            },
            [
              _vm._v(
                "\n        图片 " +
                  _vm._s(_vm.selectImage.length) +
                  " 张\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "img-text-content" },
            [
              _c("span", { staticClass: "img-text-content-span" }, [
                _vm._v("字段"),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择字段",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.selectfield,
                    callback: function ($$v) {
                      _vm.selectfield = $$v
                    },
                    expression: "selectfield",
                  },
                },
                _vm._l(_vm.field, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "img-text-content",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  _vm.showPropLogo = true
                },
              },
            },
            [
              _vm._v(
                "\n        Logo " +
                  _vm._s(_vm.getimageName(this.logo)) +
                  "\n      "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "img-text-top-list" }, [
          _c("div", { staticClass: "img-text-title" }, [_vm._v("风格")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "img-text-content" },
            [
              _c("span", { staticClass: "img-text-content-span" }, [
                _vm._v("比例"),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  ref: "dialSele",
                  staticStyle: { width: "110px" },
                  on: { change: _vm.visSeleChange },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "随机", value: "" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "横版", value: "16:9" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "竖版", value: "9:16" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "正版", value: "1:1" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "img-text-content" },
            [
              _c("span", { staticClass: "img-text-content-span" }, [
                _vm._v("字体"),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: {
                    "value-key": "",
                    placeholder: "请选择字体",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.selectfont,
                    callback: function ($$v) {
                      _vm.selectfont = $$v
                    },
                    expression: "selectfont",
                  },
                },
                _vm._l(_vm.videoFontList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.url },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "img-text-content" },
            [
              _c("span", { staticClass: "img-text-content-span" }, [
                _vm._v("色系"),
              ]),
              _vm._v(" "),
              _c("colour", {
                attrs: { colour: "#FFFFFF" },
                model: {
                  value: _vm.color,
                  callback: function ($$v) {
                    _vm.color = $$v
                  },
                  expression: "color",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.type == "album"
          ? _c("div", { staticClass: "img-text-top-list" }, [
              _c("div", { staticClass: "img-text-title" }, [_vm._v("数量")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "img-text-content" },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.count,
                        callback: function ($$v) {
                          _vm.count = $$v
                        },
                        expression: "count",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "1张", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "2张", value: 2 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "3张", value: 3 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "4张", value: 4 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "5张", value: 5 } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.btnloading,
                      },
                      on: { click: _vm.againImage },
                    },
                    [_vm._v("再来" + _vm._s(_vm.count) + "张")]
                  ),
                ],
                1
              ),
            ])
          : _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      loading: _vm.btnloading,
                    },
                    on: { click: _vm.againImage },
                  },
                  [_vm._v("再来5张")]
                ),
              ],
              1
            ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "image-text-poster-box" },
          _vm._l(_vm.imgarr, function (item, index) {
            return _c(
              "div",
              { key: "imgarr" + index, staticClass: "image-text-poster-list" },
              [
                _c("div", { staticClass: "image-text-poster-item" }, [
                  _c("img", { attrs: { src: item.url + "!m640", alt: "" } }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "image-text-poster-bottom" },
                  [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.width) +
                          "*" +
                          _vm._s(item.height) +
                          "/" +
                          _vm._s(item.ratio) +
                          "/" +
                          _vm._s(item.size) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown",
                      { attrs: { size: "small" } },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _c("i", {
                            staticClass: "el-icon-more el-icon--right",
                            staticStyle: { color: "#69d", cursor: "pointer" },
                            attrs: { color: "#69d" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.downimage(item)
                                  },
                                },
                              },
                              [_vm._v("下载")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.goedit(item)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "top-view flex", staticStyle: { padding: "0" } },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo default-tabs mt10",
                attrs: { "default-active": _vm.routerUrl, mode: "horizontal" },
                on: { select: _vm.changeTab },
              },
              [
                _c(
                  "el-menu-item",
                  {
                    attrs: {
                      index:
                        "/merchant/pageManagement/auction/marketMatrix/materialProduction",
                    },
                  },
                  [_vm._v("朋友圈文案")]
                ),
                _vm._v(" "),
                _c(
                  "el-menu-item",
                  {
                    attrs: {
                      index:
                        "/merchant/pageManagement/auction/marketMatrix/promotionPlan",
                    },
                  },
                  [_vm._v("视频号文案")]
                ),
                _vm._v(" "),
                _c(
                  "el-menu-item",
                  {
                    attrs: {
                      index:
                        "/merchant/pageManagement/auction/marketMatrix/taskList",
                    },
                  },
                  [_vm._v("抖音文案")]
                ),
                _vm._v(" "),
                _c(
                  "el-menu-item",
                  {
                    attrs: {
                      index:
                        "/merchant/pageManagement/auction/marketMatrix/channel",
                    },
                  },
                  [_vm._v("小红书文案")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { padding: "0" } }, [
          _c("div", { staticClass: "text-title" }, [_vm._v("文案标题")]),
          _vm._v(" "),
          _c("div", { staticClass: "text-desc" }, [_vm._v("文案内容")]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择拍品图片",
            visible: _vm.showAuctionFlag,
            top: "5vh",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAuctionFlag = $event
            },
          },
        },
        [
          _c("div", { staticClass: "mt10" }, [
            _c(
              "div",
              { staticStyle: { "font-size": "16px", "font-weight": "500" } },
              [_vm._v("拍品图片")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "change-img flex flex-wrap mt10" },
              _vm._l(_vm.auctionImageArr, function (m, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "img",
                    class: { curr: _vm.selectImage.indexOf(m) !== -1 },
                    on: {
                      click: function ($event) {
                        return _vm.setSliderImg(m)
                      },
                    },
                  },
                  [_c("img", { attrs: { src: m + "!m640", alt: "" } })]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt20 item-flex-center",
              staticStyle: {
                "padding-top": "20px",
                "border-top": "1px solid #eee",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showAuctionFlag = false
                    },
                  },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择logo",
            visible: _vm.showPropLogo,
            top: "5vh",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPropLogo = $event
            },
          },
        },
        [
          _c("div", { staticClass: "mt10" }, [
            _c(
              "div",
              { staticStyle: { "font-size": "16px", "font-weight": "500" } },
              [_vm._v("LOGO")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "change-img flex flex-wrap mt10" },
              [
                _vm.merlogo
                  ? _c(
                      "div",
                      {
                        staticClass: "img",
                        class: { curr: _vm.logo == _vm.merlogo },
                        on: {
                          click: function ($event) {
                            return _vm.setSelectLogo(_vm.merlogo)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.merlogo + "!m640", alt: "" },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "img",
                    class: { curr: _vm.logo == _vm.meravatar },
                    on: {
                      click: function ($event) {
                        return _vm.setSelectLogo(_vm.meravatar)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.meravatar + "!m640", alt: "" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.merlogodata, function (m, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "img",
                      class: { curr: _vm.logo == m.imgUrl },
                      on: {
                        click: function ($event) {
                          return _vm.setSelectLogo(m.imgUrl)
                        },
                      },
                    },
                    [_c("img", { attrs: { src: m.imgUrl + "!m640", alt: "" } })]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt20 item-flex-center",
              staticStyle: {
                "padding-top": "20px",
                "border-top": "1px solid #eee",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showAuctionFlag = false
                    },
                  },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }