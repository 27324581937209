"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Info',
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      id: '',
      loading: false,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      }
    };
  },
  computed: {},
  methods: {
    // 列表
    getList: function getList(id) {
      var _this = this;
      this.id = id;
      this.listLoading = true;
      (0, _product.assistDetailApi)(this.id, this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList(this.id);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(this.id);
    }
  }
};