var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      !_vm.only
        ? _c("div", { staticClass: "fWeight500 mb10" }, [_vm._v("初始状态")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "layout-phone-header",
          style: {
            backgroundColor: _vm.facade.header_background || "",
            backgroundImage:
              "url(" +
              (_vm.facade.header_color === "white"
                ? "https://saas.cdn.yunzongbu.cn/image/20211026/9823014575b33347581d939efbd91aee.png"
                : "https://saas.cdn.yunzongbu.cn/image/20211026/a3459168515274a30097453656ad97b1.png") +
              ")",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "layout-phone-title item-align-center justify-center",
              style: { "z-index": 200, color: _vm.facade.header_color },
            },
            [
              _c("div", { staticClass: "back" }, [
                _vm.facade.header_color === "white"
                  ? _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211029/ccfd6697386671c2bd471fb840c7fa22.png",
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png",
                        alt: "",
                      },
                    }),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  style: {
                    fontSize: _vm.facade.header_text_size / 2 + "px",
                    fontWeight: _vm.facade.fontWeight,
                  },
                },
                [_vm._v("标题栏")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.only
        ? [
            _c("div", { staticClass: "fWeight500 mb10 mt20" }, [
              _vm._v("向上滑动后"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "layout-phone-header",
                style: {
                  backgroundColor: _vm.facade.slide_style_no_change
                    ? _vm.facade.header_background
                    : _vm.facade.slide_header_background,
                  backgroundImage:
                    "url(" +
                    ((_vm.facade.slide_style_no_change
                      ? _vm.facade.header_color
                      : _vm.facade.slide_header_color) === "white"
                      ? "https://saas.cdn.yunzongbu.cn/image/20211026/9823014575b33347581d939efbd91aee.png"
                      : "https://saas.cdn.yunzongbu.cn/image/20211026/a3459168515274a30097453656ad97b1.png") +
                    ")",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "layout-phone-title item-align-center justify-center",
                    style: {
                      "z-index": 200,
                      color: _vm.facade.slide_style_no_change
                        ? _vm.facade.header_color
                        : _vm.facade.slide_header_color,
                    },
                  },
                  [
                    _c("div", { staticClass: "back" }, [
                      (_vm.facade.slide_style_no_change
                        ? _vm.facade.header_color
                        : _vm.facade.slide_header_color) === "white"
                        ? _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211029/ccfd6697386671c2bd471fb840c7fa22.png",
                              alt: "",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png",
                              alt: "",
                            },
                          }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        style: {
                          fontSize:
                            (_vm.facade.slide_style_no_change
                              ? _vm.facade.header_text_size
                              : _vm.facade.slide_header_text_size) /
                              2 +
                            "px",
                          fontWeight: _vm.facade.slide_style_no_change
                            ? _vm.facade.fontWeight
                            : _vm.facade.slide_fontWeight,
                        },
                      },
                      [_vm._v("标题栏")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }