var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "160px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                },
                [
                  _vm.formValidate.type == 1
                    ? [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "银行卡号",
                                  prop: "linkedAcctInfo.bank_card_id",
                                  required: "",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    placeholder: "请输入银行卡号",
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.linkedAcctInfo
                                        .bank_card_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.linkedAcctInfo,
                                        "bank_card_id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.linkedAcctInfo.bank_card_id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "预留手机号",
                                  prop: "linkedAcctInfo.bank_card_phone",
                                  required: "",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    placeholder: "请输入预留手机号",
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.linkedAcctInfo
                                        .bank_card_phone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.linkedAcctInfo,
                                        "bank_card_phone",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.linkedAcctInfo.bank_card_phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm.formValidate.type == 2
                    ? [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "18px",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v("开户信息")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "账户类型",
                                  prop: "linkedAcctInfo.linked_accttype",
                                  required: "",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "400px" },
                                    attrs: {
                                      placeholder: "请选择账户类型",
                                      disabled: _vm.statusdisabled,
                                    },
                                    model: {
                                      value:
                                        _vm.formValidate.linkedAcctInfo
                                          .linked_accttype,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate.linkedAcctInfo,
                                          "linked_accttype",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formValidate.linkedAcctInfo.linked_accttype",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "企业对公户",
                                        value: "ENTERPRISE_BANK_ACCT",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "银行卡号",
                                  prop: "linkedAcctInfo.linked_acctno",
                                  required: "",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    placeholder: "请输入银行卡号",
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.linkedAcctInfo
                                        .linked_acctno,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.linkedAcctInfo,
                                        "linked_acctno",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.linkedAcctInfo.linked_acctno",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "银行编码",
                                  prop: "linkedAcctInfo.linked_bankcode",
                                  required: "",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "400px" },
                                    attrs: {
                                      placeholder: "请选择银行编码",
                                      filterable: "",
                                      disabled: _vm.statusdisabled,
                                    },
                                    on: { change: _vm.changebankcode },
                                    model: {
                                      value:
                                        _vm.formValidate.linkedAcctInfo
                                          .linked_bankcode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate.linkedAcctInfo,
                                          "linked_bankcode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formValidate.linkedAcctInfo.linked_bankcode",
                                    },
                                  },
                                  _vm._l(_vm.codeList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label:
                                          item.bank_name + "-" + item.bank_code,
                                        value: item.bank_code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "对公账户开户行名",
                                  prop: "linkedAcctInfo.linked_brbankname",
                                  required: "",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    placeholder: "请输入对公账户开户行名",
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.linkedAcctInfo
                                        .linked_brbankname,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.linkedAcctInfo,
                                        "linked_brbankname",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.linkedAcctInfo.linked_brbankname",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "对公账户开户行地址",
                                  required: "",
                                },
                              },
                              [
                                _c("el-cascader", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    placeholder: "请选择开户行地址",
                                    options: _vm.cityList,
                                    props: _vm.props,
                                    "collapse-tags": "",
                                    clearable: "",
                                    disabled: _vm.statusdisabled,
                                  },
                                  on: { change: _vm.changecitycode },
                                  model: {
                                    value: _vm.citycode,
                                    callback: function ($$v) {
                                      _vm.citycode = $$v
                                    },
                                    expression: "citycode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "对公账户开户行号",
                                  prop: "linkedAcctInfo.linked_brbankno",
                                  required: "",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "400px" },
                                    attrs: {
                                      placeholder: "请选择对公账户开户行号",
                                      filterable: "",
                                      disabled: _vm.statusdisabled,
                                    },
                                    on: { focus: _vm.checkbrbankno },
                                    model: {
                                      value:
                                        _vm.formValidate.linkedAcctInfo
                                          .linked_brbankno,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate.linkedAcctInfo,
                                          "linked_brbankno",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formValidate.linkedAcctInfo.linked_brbankno",
                                    },
                                  },
                                  _vm._l(_vm.bankcodeList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label:
                                          item.brabank_name +
                                          "-" +
                                          item.cnaps_code,
                                        value: item.cnaps_code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "对公账户名",
                                  prop: "linkedAcctInfo.linked_acctname",
                                  required: "",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    placeholder: "请输入对公账户名",
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.linkedAcctInfo
                                        .linked_acctname,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.linkedAcctInfo,
                                        "linked_acctname",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.linkedAcctInfo.linked_acctname",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "预留手机号",
                                  prop: "linkedAcctInfo.linked_phone",
                                  required: "",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    placeholder: "请输入预留手机号",
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.linkedAcctInfo
                                        .linked_phone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.linkedAcctInfo,
                                        "linked_phone",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.linkedAcctInfo.linked_phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "密码(6-12位)", required: "" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { width: "200px", height: "36px" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ipt_pwd",
                                  attrs: { id: "ipt_pwd2" },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { id: "LOGPASS2_SHOW" } },
                                    [_vm._v("请输入密码")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    staticClass: "default input_search",
                                    attrs: { id: "LOGPASS2" },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "ipt_pwd_rjp default",
                                    attrs: { id: "keyboardBtn2" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 1,
                          expression: "currentTab === 1",
                        },
                      ],
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "签约验证码", required: "" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "请输入验证码" },
                            model: {
                              value: _vm.vcode,
                              callback: function ($$v) {
                                _vm.vcode = $$v
                              },
                              expression: "vcode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }