var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c("div", { staticClass: "flex mt20" }, [
      _c("div", { staticClass: "mt10 w100" }, [_vm._v("购票须知：")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-one" },
        [
          _c("Tinymce", {
            ref: "editor",
            attrs: { height: 400 },
            model: {
              value: _vm.process,
              callback: function ($$v) {
                _vm.process = $$v
              },
              expression: "process",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex mt20" }, [
      _c("div", { staticClass: "mt10 w100" }, [_vm._v("购票流程：")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-one" },
        [
          _c("Tinymce", {
            ref: "editor1",
            attrs: { height: 400 },
            model: {
              value: _vm.notice,
              callback: function ($$v) {
                _vm.notice = $$v
              },
              expression: "notice",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt20 ", staticStyle: { "margin-left": "100px" } },
      [
        _c(
          "el-button",
          {
            staticClass: "btn",
            attrs: { type: "primary" },
            on: { click: _vm.saveAction },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }