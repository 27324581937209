"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      setData: {
        show: false,
        title: '',
        type: '',
        valType: '',
        content_l: '',
        content_r: '',
        val: '',
        content_l1: '',
        content_r1: '',
        val1: ''
      },
      list: [{
        name: '启用积分',
        desc: '启用后，用户将按以下规则获得和使用积分',
        time: '',
        type: 'switch',
        val: 2
      }, {
        name: '积分名称自定义',
        desc: '积分在前台显示成“{s}”',
        time: '',
        type: 'integral_name',
        val: '积分'
      }, {
        name: '积分有效期',
        desc: '积分有效期{s}天',
        time: '',
        type: 'validity_period',
        valType: 1,
        val: 0
      }, {
        name: '积分锁定期',
        desc: '商城订单：获得积分后{s}天后可解锁',
        time: '',
        type: 'lock_period|0',
        val: 0
      }, {
        name: '积分锁定期',
        desc: '数字藏品订单：获得积分后{s}天后可解锁',
        time: '',
        type: 'lock_period|8',
        val: 0
      }, {
        name: '积分锁定期',
        desc: '数字盲盒订单：获得积分后{s}天后可解锁',
        time: '',
        type: 'lock_period|9',
        val: 0
      }, {
        name: '积分锁定期',
        desc: '实物盲盒订单：获得积分后{s}天后可解锁',
        time: '',
        type: 'lock_period|10',
        val: 0
      }, {
        name: '积分锁定期',
        desc: '门票订单：获得积分后{s}天后可解锁',
        time: '',
        type: 'lock_period|11',
        val: 0
      }, {
        name: '积分抵现比例',
        desc: '商城订单抵扣：100积分=1元',
        time: '',
        type: 'use_range|0',
        val: 100
      }, {
        name: '积分抵现比例',
        desc: '数字藏品抵扣：100积分=1元',
        time: '',
        type: 'use_range|8',
        val: 100
      }, {
        name: '积分抵现比例',
        desc: '数字盲盒抵扣：100积分=1元',
        time: '',
        type: 'use_range|9',
        val: 100
      }, {
        name: '积分抵现比例',
        desc: '实物盲盒抵扣：100积分=1元',
        time: '',
        type: 'use_range|10',
        val: 100
      }, {
        name: '积分抵现比例',
        desc: '门票抵扣：100积分=1元',
        time: '',
        type: 'use_range|11',
        val: 100
      }, {
        name: '积分获得比例',
        desc: '商城订单：1元 获得 {s}积分',
        time: '',
        type: 'get_ratio|0',
        val: 0,
        status: 1
      }, {
        name: '积分获得比例',
        desc: '数字藏品消费：1元 获得 {s}积分',
        time: '',
        type: 'get_ratio|8',
        val: 0,
        status: 1
      }, {
        name: '积分获得比例',
        desc: '数字盲盒消费：1元 获得 {s}积分',
        time: '',
        type: 'get_ratio|9',
        val: 0,
        status: 1
      }, {
        name: '积分获得比例',
        desc: '实物盲盒消费：1元 获得 {s}积分',
        time: '',
        type: 'get_ratio|10',
        val: 0,
        status: 1
      }, {
        name: '积分获得比例',
        desc: '门票消费：1元 获得 {s}积分',
        time: '',
        type: 'get_ratio|11',
        val: 0,
        status: 1
      }],
      listLoading: true,
      ruleList: [{
        name: '邀请注册',
        desc: '每邀请1人，获得{s}积分，每日上限{v}积分',
        time: '',
        type: 'invite_register',
        usable: 1,
        val: 0,
        maxVal: 0
      }, {
        name: '邀请消费',
        desc: '邀请的用户，每消费1元，获得{s}积分，每日上限{v}积分',
        time: '',
        type: 'invite_consumption',
        usable: 1,
        val: 0,
        maxVal: 0
      }, {
        name: '每日签到',
        desc: '每日签到获得{s}积分',
        time: '',
        usable: 0,
        val: 0
      }, {
        name: '社区发帖',
        desc: '每发1个新帖，获得{s}积分，每日上限{v}积分',
        time: '',
        usable: 0,
        val: 0,
        maxVal: 0
      }, {
        name: '社区评论',
        desc: '每次评论，获得{s}积分，每日上限{v}积分',
        time: '',
        usable: 0,
        val: 0,
        maxVal: 0
      }, {
        name: '发布购买评价',
        desc: '发布1条购买评价，获得{s}积分，每日上限{v}积分',
        time: '',
        usable: 0,
        val: 0,
        maxVal: 0
      }, {
        name: '关注服务号',
        desc: '关注服务号，获得{s}积分，限发1次',
        time: '',
        type: 'focus_on_gzh',
        usable: 1,
        val: 0
      }, {
        name: '下载APP',
        desc: '下载APP，获得{s}积分，限发1次',
        time: '',
        type: 'download_app',
        usable: 1,
        val: 0
      }, {
        name: '新注册用户',
        desc: '新用户注册赠送{s}积分，限发1次',
        time: '',
        type: 'new_user',
        usable: 1,
        val: 0
      }]
    };
  },
  mounted: function mounted() {
    this.load();
  },
  methods: {
    load: function load() {
      var _this = this;
      (0, _marketing.integralConfGetInfoApi)(this.queryParams).then(function (res) {
        // this.list = response.data.data;
        if (res.data) {
          var list = _this.list;
          var ruleList = _this.ruleList;
          var data = res.data;
          if (data.switch) {
            list[0].val = data.switch.status;
            list[0].time = data.switch.last_time;
          }
          if (data.integral_name) {
            list[1].val = data.integral_name.name;
            list[1].time = data.integral_name.last_time;
          }
          if (data.validity_period) {
            list[2].val = data.validity_period.day || '';
            list[2].valType = data.validity_period.type;
            list[2].time = data.validity_period.last_time;
          }
          if (data.lock_period) {
            for (var key in data.lock_period) {
              var m = data.lock_period[key];
              switch (Number(key)) {
                case 0:
                  list[3].val = m.day;
                  list[3].time = m.last_time;
                  break;
                case 8:
                  list[4].val = m.day;
                  list[4].time = m.last_time;
                  break;
                case 9:
                  list[5].val = m.day;
                  list[5].time = m.last_time;
                  break;
                case 10:
                  list[6].val = m.day;
                  list[6].time = m.last_time;
                  break;
                case 11:
                  list[7].val = m.day;
                  list[7].time = m.last_time;
                  break;
              }
            }
          }
          if (data.use_range) {
            for (var _key in data.use_range) {
              var _m = data.use_range[_key];
              switch (Number(_key)) {
                case 0:
                  list[8].time = _m.last_time;
                  list[8].status = _m.status;
                  break;
                case 8:
                  list[9].status = _m.status;
                  list[9].time = _m.last_time;
                  break;
                case 9:
                  list[10].status = _m.status;
                  list[10].time = _m.last_time;
                  break;
                case 10:
                  list[11].status = _m.status;
                  list[11].time = _m.last_time;
                  break;
                case 11:
                  list[12].status = _m.status;
                  list[12].time = _m.last_time;
                  break;
              }
            }
          }
          if (data.get_ratio) {
            for (var _key2 in data.get_ratio) {
              var _m2 = data.get_ratio[_key2];
              switch (Number(_key2)) {
                case 0:
                  list[13].val = _m2.ratio;
                  list[13].time = _m2.last_time;
                  list[13].status = _m2.status;
                  break;
                case 8:
                  list[14].val = _m2.ratio;
                  list[14].time = _m2.last_time;
                  list[14].status = _m2.status;
                  break;
                case 9:
                  list[15].val = _m2.ratio;
                  list[15].time = _m2.last_time;
                  list[15].status = _m2.status;
                  break;
                case 10:
                  list[16].val = _m2.ratio;
                  list[16].time = _m2.last_time;
                  list[16].status = _m2.status;
                  break;
                case 11:
                  list[17].val = _m2.ratio;
                  list[17].time = _m2.last_time;
                  list[17].status = _m2.status;
                  break;
              }
            }
          }
          if (data.rule) {
            if (data.rule.invite_register) {
              ruleList[0].val = data.rule.invite_register.get_integral;
              ruleList[0].maxVal = data.rule.invite_register.max;
              ruleList[0].time = data.rule.invite_register.last_time;
            }
            if (data.rule.invite_consumption) {
              ruleList[1].val = data.rule.invite_consumption.get_integral;
              ruleList[1].maxVal = data.rule.invite_consumption.max;
              ruleList[1].time = data.rule.invite_consumption.last_time;
            }
            if (data.rule.focus_on_gzh) {
              ruleList[6].val = data.rule.focus_on_gzh.get_integral;
              ruleList[6].time = data.rule.focus_on_gzh.last_time;
            }
            if (data.rule.download_app) {
              ruleList[7].val = data.rule.download_app.get_integral;
              ruleList[7].time = data.rule.download_app.last_time;
            }
            if (data.rule.new_user) {
              ruleList[8].val = data.rule.new_user.get_integral;
              ruleList[8].time = data.rule.new_user.last_time;
            }
          }
        }
        _this.listLoading = false;
      }).catch(function (message) {
        _this.listLoading = false;
      });
    },
    settingAction: function settingAction(item) {
      var data = {
        type: item.type,
        show: true,
        title: '',
        valType: '',
        content_l: '',
        content_r: '',
        val: '',
        content_l1: '',
        content_r1: '',
        val1: ''
      };
      if (item.type === 'integral_name') {
        data.title = '自定义积分名称';
        data.content_l = '积分在前台显示成';
        data.val = item.val;
      } else if (item.type === 'validity_period') {
        data.title = '积分有效期';
        data.valType = item.valType;
        data.content_l = '积分';
        data.val = item.val;
        data.content_r = '天后过期';
      } else if (item.type.indexOf('lock_period|') === 0) {
        var contentArr = item.desc.split('{s}');
        data.title = '积分锁定期设置';
        data.content_l = contentArr[0];
        data.val = item.val;
        data.content_r = contentArr[1];
      } else if (item.type.indexOf('get_ratio|') === 0) {
        var _contentArr = item.desc.split('{s}');
        data.title = '积分获得比例设置';
        data.content_l = _contentArr[0];
        data.val = item.val;
        data.content_r = _contentArr[1];
        data.status = item.status || 1;
      } else if (item.type.indexOf('use_range|') === 0) {
        data.title = '积分抵现设置';
        data.status = item.status || 1;
      } else if (item.type === 'invite_register') {
        data.title = '邀请注册获得积分规则';
        data.content_l = '每邀请1人可得';
        data.val = item.val;
        data.content_r = '积分';
        data.content_l1 = '每日上限';
        data.val1 = item.maxVal;
        data.content_r1 = '积分';
      } else if (item.type === 'invite_consumption') {
        data.title = '邀请消费获得积分规则';
        data.content_l = '邀请的用户，每消费1元可得';
        data.val = item.val;
        data.content_r = '积分';
        data.content_l1 = '每日上限';
        data.val1 = item.maxVal;
        data.content_r1 = '积分';
      } else if (item.type === 'focus_on_gzh') {
        data.title = '关注服务号获得积分规则';
        data.content_l = '关注服务号可得';
        data.val = item.val;
        data.content_r = '积分';
      } else if (item.type === 'download_app') {
        data.title = '下载APP获得积分规则';
        data.content_l = '下载APP可得';
        data.val = item.val;
        data.content_r = '积分';
      } else if (item.type === 'new_user') {
        data.title = '注册新用户获得积分规则';
        data.content_l = '注册用户可得';
        data.val = item.val;
        data.content_r = '积分';
      }
      if (data.content_l || data.content_r || data.title) {
        this.setData = data;
      }
    },
    saveAction: function saveAction() {
      var _this2 = this;
      var d = JSON.parse(JSON.stringify(this.setData));
      var data = {};
      if (this.lock) return false;
      this.lock = true;
      if (d.type === 'integral_name') {
        data.type = d.type;
        data[d.type] = {
          name: d.val || '积分'
        };
      } else if (d.type === 'validity_period') {
        data.type = d.type;
        data[d.type] = {
          type: d.valType,
          day: d.val || 0
        };
      } else if (d.type.indexOf('lock_period|') === 0) {
        var index = d.type.split('|')[1];
        if (index) {
          data.type = 'lock_period';
          data['lock_period'] = {};
          data['lock_period'][index] = {
            product_type: index,
            day: d.val
          };
        }
      } else if (d.type.indexOf('use_range|') === 0) {
        var _index = d.type.split('|')[1];
        if (_index) {
          data.type = 'use_range';
          data['use_range'] = {};
          data['use_range'][_index] = {
            product_type: _index,
            status: d.status
          };
        }
      } else if (d.type.indexOf('get_ratio|') === 0) {
        var _index2 = d.type.split('|')[1];
        if (_index2) {
          data.type = 'get_ratio';
          data['get_ratio'] = {};
          data['get_ratio'][_index2] = {
            product_type: _index2,
            ratio: d.val,
            status: d.status
          };
        }
      } else if (d.type === 'invite_register') {
        data.type = 'rule';
        data['rule'] = {
          invite_register: {
            get_integral: d.val || 0,
            max: d.val1 || 0
          }
        };
      } else if (d.type === 'invite_consumption') {
        data.type = 'rule';
        data['rule'] = {
          invite_consumption: {
            get_integral: d.val || 0,
            max: d.val1 || 0
          }
        };
      } else if (d.type === 'focus_on_gzh') {
        data.type = 'rule';
        data['rule'] = {
          focus_on_gzh: {
            get_integral: d.val || 0
          }
        };
      } else if (d.type === 'download_app') {
        data.type = 'rule';
        data['rule'] = {
          download_app: {
            get_integral: d.val || 0
          }
        };
      } else if (d.type === 'new_user') {
        data.type = 'rule';
        data['rule'] = {
          new_user: {
            get_integral: d.val || 0
          }
        };
      }
      (0, _marketing.integralConfSaveApi)(data).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.lock = false;
        _this2.setData.show = false;
        _this2.load();
      }).catch(function () {
        _this2.lock = false;
      });
    },
    editSwitchAction: function editSwitchAction(val) {
      var _this3 = this;
      (0, _marketing.integralConfSaveApi)({
        type: 'switch',
        switch: {
          status: val
        }
      }).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.load();
      }).catch(function () {});
    },
    switchAction: function switchAction(row) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4".concat(Number(row.val) === 1 ? '关闭' : '开启', "\u79EF\u5206\u529F\u80FD?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.integralConfSaveApi)({
          type: 'switch',
          switch: {
            status: Number(row.val) === 1 ? 2 : 1
          }
        }).then(function (res) {
          _this4.$message.success('操作成功');
          _this4.load();
        }).catch(function () {});
      }).catch(function () {});
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex === 0) {
        if (rowIndex === 3 || rowIndex === 8 || rowIndex === 13) {
          return {
            rowspan: 5,
            colspan: 1
          };
        } else if (rowIndex !== 0 && rowIndex !== 1 && rowIndex !== 2 && rowIndex !== 8) {
          return {
            rowspan: 0,
            colspan: 0
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      }
    }
  }
};