"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _getMap = _interopRequireDefault(require("./getMap"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    value: {
      type: [String],
      default: ''
    }
  },
  data: function data() {
    return {
      positions: [],
      state: '',
      timeout: null,
      loading: false,
      keyword: {},
      map: null,
      marker: null,
      editMap: false
    };
  },
  computed: {
    select: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted: function mounted() {
    // eslint-disable-next-line no-undef
    //
  },
  methods: {
    goEditMap: function goEditMap() {
      var _this = this;
      if (this.map) {
        this.map.destroy();
        this.map = null;
      }
      this.editMap = true;
      var lat = '';
      var lng = '';
      if (this.$route && this.$route.query && this.$route.query.field) {
        lat = this.$route.query.lat || 39.908802;
        lng = this.$route.query.lng || 116.397502;
        this.loadMap(lat, lng);
      } else {
        var long_lat = '116.397502,39.908802';
        if (this.select) {
          long_lat = this.select;
        }
        var ll = long_lat.split(',');
        lat = ll[1];
        lng = ll[0];
        try {
          // eslint-disable-next-line no-undef
          if (TMap) {
            this.loadMap(lat, lng);
          }
        } catch (e) {
          (0, _getMap.default)().then(function () {
            _this.loadMap(lat, lng);
          });
        }
      }
      //   this.editMap = true
      //   let lat = ''
      //   let lng = ''

      //   let long_lat = '116.397502,39.908802'
      //   if (this.select) {
      //     long_lat = this.select
      //   }
      //   const ll = long_lat.split(',')
      //   lat = ll[1]
      //   lng = ll[0]
      //   try {
      //     // eslint-disable-next-line no-undef
      //     if (TMap) {
      //       this.loadMap(lat, lng)
      //     }
      //   } catch (e) {
      //       MP().then(() => {
      //         this.loadMap(lat, lng)
      //       })
      //   }
      //   console.log()
      //   if (document.getElementById('map_area')) {
      // this.loadMap(lat, lng)
      //   this.loadMap()
      //   } else {

      //   }
    },
    loadMap: function loadMap(lat, lng) {
      // eslint-disable-next-line no-undef
      var center = new TMap.LatLng(lat, lng);
      // 初始化地图
      // eslint-disable-next-line no-undef
      this.map = new TMap.Map('container', {
        rotation: 20,
        // 设置地图旋转角度
        pitch: 0,
        // 设置俯仰角度（0~45）
        zoom: 16,
        // 设置地图缩放级别
        center: center // 设置地图中心点坐标
      });
      // eslint-disable-next-line no-undef
      this.marker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          // 点标记样式
          // eslint-disable-next-line no-undef
          marker: new TMap.MarkerStyle({
            width: 20,
            // 样式宽
            height: 30,
            // 样式高
            anchor: {
              x: 10,
              y: 30
            },
            // 描点位置
            src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png' // 标记路径
          })
        },
        geometries: [
        // 点标记数据数组
        {
          // 标记位置(经度，纬度，高度)
          // eslint-disable-next-line no-undef
          position: new TMap.LatLng(lat, lng, 134)
        }]
      });
    },
    remoteMethod: function remoteMethod(data) {
      var _this2 = this;
      this.loading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        var KEY = 'O6TBZ-UKH3O-N4DWD-SXBW5-Q6GHJ-ETFUO'; // 这里是你自己的开发者秘钥
        var url = 'https://apis.map.qq.com/ws/place/v1/suggestion';
        _this2.$jsonp(url, {
          key: KEY,
          keyword: data,
          output: 'jsonp'
        }).then(function (res) {
          console.log(res);
          if (res.data && res.data.length > 0) {
            _this2.positions = res.data;
          } else {
            _this2.positions = [];
          }
          _this2.loading = false;
        }).catch(function (err) {
          _this2.loading = false;
          console.log(err);
        });
      }, 500);
    },
    changeArea: function changeArea(index) {
      var data = this.positions[index];
      // eslint-disable-next-line no-undef
      this.map.setCenter(new TMap.LatLng(data.location.lat, data.location.lng));
      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }
      // eslint-disable-next-line no-undef
      this.marker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          // 点标记样式
          // eslint-disable-next-line no-undef
          marker: new TMap.MarkerStyle({
            width: 20,
            // 样式宽
            height: 30,
            // 样式高
            anchor: {
              x: 10,
              y: 30
            },
            // 描点位置
            src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png' // 标记路径
          })
        },
        geometries: [
        // 点标记数据数组
        {
          // 标记位置(经度，纬度，高度)
          // eslint-disable-next-line no-undef
          position: new TMap.LatLng(data.location.lat, data.location.lng, 134)
        }]
      });
      // this.marker.setCenter
    },
    confirmArea: function confirmArea() {
      var item = this.positions[this.keyword];
      if (item && item.location && item.location.lat) {
        var address_long_lat = "".concat(item.location.lng, ",").concat(item.location.lat);
        this.$message.success('选择地图位置成功');
        if (this.$route && this.$route.query && this.$route.query.field) {
          this.editMap = false;
          this.map.destroy();
          this.map = null;
          /* eslint-disable */
          form_create_helper.set(this.$route.query.field, address_long_lat);
          form_create_helper.close(this.$route.query.field);
        } else {
          this.select = address_long_lat;
          this.$emit('getAddressData', item);
          this.editMap = false;
          this.map.destroy();
          this.map = null;
        }
      } else {
        if (this.$route && this.$route.query && this.$route.query.field) {
          this.select = '';
          this.editMap = false;
          this.map.destroy();
          this.map = null;
          /* eslint-disable */
          form_create_helper.set(this.$route.query.field, '');
          form_create_helper.close(this.$route.query.field);
        } else {
          this.select = '';
          this.editMap = false;
          this.map.destroy();
          this.map = null;
        }
      }
    }
  }
};