"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _guidance = require("@/api/guidance");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    active: {
      type: Number,
      default: 0
    },
    titleList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      modelType: 2,
      list: [],
      searchName: '',
      cloneList: []
    };
  }
};