"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var stationRouter = {
  path: "".concat(_settings.roterPre, "/station"),
  name: 'station',
  meta: {
    icon: '',
    title: '站内消息'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'notice/:id?',
    name: 'stationNotice',
    meta: {
      title: '站内消息'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/station/notice/index'));
      });
    }
  }]
};
var _default = exports.default = stationRouter;