var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "status", $$v)
                    },
                    expression: "tableFrom.status",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("\n          全部\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "20" } }, [
                    _vm._v("\n          已核销\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "10" } }, [
                    _vm._v("\n          未核销\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "30" } }, [
                    _vm._v("\n          已过期\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "-10" } }, [
                    _vm._v("\n          已退款\n        "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "ml20",
                  attrs: { size: "small" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.get_channel,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "get_channel", $$v)
                    },
                    expression: "tableFrom.get_channel",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("\n          全部\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("\n          购买\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("\n          赠送\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "3" } }, [
                    _vm._v("\n          空投\n        "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select w200 mt20 mr20",
                  attrs: { placeholder: "输入用户名" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.user_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "user_name", $$v)
                    },
                    expression: "tableFrom.user_name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "input-with-select mr20 w200 mt20",
                  attrs: { placeholder: "输入手机号" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "phone", $$v)
                    },
                    expression: "tableFrom.phone",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "input-with-select mr20 w200 mt20",
                  attrs: { placeholder: "输入门票名称" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.ticket_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "ticket_name", $$v)
                    },
                    expression: "tableFrom.ticket_name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "input-with-select mr20 w200 mt20",
                  attrs: { placeholder: "输入卡券ID号" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.card_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "card_id", $$v)
                    },
                    expression: "tableFrom.card_id",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "mr20 w200 mt20",
                  attrs: { clearable: "", placeholder: "是否需要实名" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.is_verified,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "is_verified", $$v)
                    },
                    expression: "tableFrom.is_verified",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 1, label: "需要实名" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 0, label: "不需要实名" } }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-date-picker", {
                staticClass: "mr20 w200 mt20",
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder":
                    _vm.tableFrom.status == 20
                      ? "选择核销开始日期"
                      : "选择购买开始日期",
                  "end-placeholder":
                    _vm.tableFrom.status == 20
                      ? "选择核销结束日期"
                      : "选择购买结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                on: { change: _vm.searchDateAction },
                model: {
                  value: _vm.searchTimes,
                  callback: function ($$v) {
                    _vm.searchTimes = $$v
                  },
                  expression: "searchTimes",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "mr20 w200 mt20",
                  attrs: {
                    placeholder: "核销员",
                    clearable: "",
                    filterable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.checker_uid,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "checker_uid", $$v)
                    },
                    expression: "tableFrom.checker_uid",
                  },
                },
                _vm._l(_vm.checkerList, function (m, i) {
                  return _c(
                    "el-option",
                    {
                      key: "user" + i,
                      attrs: { value: m.uid, label: m.user.nickname },
                    },
                    [
                      _c("div", { staticClass: "avatar flex align-items-c" }, [
                        _c("img", { attrs: { src: m.user.avatar, alt: "" } }),
                        _vm._v(" "),
                        _c("div", { staticClass: "user-r flex-one" }, [
                          _c(
                            "div",
                            { staticClass: "line1" },
                            [
                              m.user.real_name
                                ? [_vm._v(_vm._s(m.user.real_name) + " | ")]
                                : _vm._e(),
                              _vm._v(
                                _vm._s(m.user.nickname) + "\n              "
                              ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(m.user.phone))]),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "mr20 w200 mt20",
                  attrs: { clearable: "", placeholder: "核销设备" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.check_channel,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "check_channel", $$v)
                    },
                    expression: "tableFrom.check_channel",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 1, label: "小程序" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 2, label: "闸机" } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { value: 3, lablel: "后台手动核销" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "mr20 w200 mt20",
                  attrs: { placeholder: "请选择门票类型" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.ticket_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "ticket_type", $$v)
                    },
                    expression: "tableFrom.ticket_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: 0 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "门票", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "预约活动", value: 2 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mt20",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mt20",
                  attrs: { type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出已生成列表")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%", color: "#000" },
          attrs: {
            border: "",
            data: _vm.tableData.data,
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "门票ID", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "line1" }, [
                      _vm._v(_vm._s(scope.row.ticket_id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "门票名称", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.product.store_name,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "line1" }, [
                          _vm._v(_vm._s(scope.row.product.store_name)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户信息", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "line1" },
                      [
                        _vm._v("\n          用户昵称："),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(row.uid)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.user ? row.user.nickname : ""))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    row.verified_info &&
                    row.verified_info.data &&
                    row.verified_info.data.length == 1
                      ? [
                          _c("div", { staticClass: "line1" }, [
                            _vm._v(
                              "\n            真实姓名：" +
                                _vm._s(row.verified_info.data[0].name) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line1" }, [
                            _vm._v(
                              "\n            手机号码：" +
                                _vm._s(row.verified_info.data[0].mobile) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "line1" }, [
                            _vm._v(_vm._s(row.verified_info.data[0].idcard)),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    row.verified_info &&
                    row.verified_info.data &&
                    row.verified_info.data.length > 1
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.showVerifiedAction(
                                    row.verified_info
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "共" +
                                  _vm._s(row.verified_info.data.length) +
                                  "个实名信息"
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "票种", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: scope.row.spec.name,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "line1" }, [
                          _vm._v(_vm._s(scope.row.spec.name)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "购买张数",
              "min-width": "100",
              prop: "order_count",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "卡券ID", "min-width": "200", prop: "code" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "来源", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.channel == 1 && !row.source_uid
                      ? [_c("div", [_vm._v("购买 ￥" + _vm._s(row.price))])]
                      : row.channel == 1
                      ? [
                          _c(
                            "div",
                            [
                              _c("span", [_vm._v("赠送")]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetails(row.source_uid)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      row.sourceUser
                                        ? row.sourceUser.nickname
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      : row.channel == 2
                      ? [_c("div", [_vm._v("兑换码")])]
                      : row.channel == 3
                      ? [_c("div", [_vm._v("空投")])]
                      : row.channel == 4
                      ? [_c("div", [_vm._v("携程")])]
                      : row.channel == 5
                      ? [_c("div", [_vm._v("美团")])]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "时间", "min-width": "180", prop: "add_time" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "有效截止时间", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.end_time))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "使用时段", "min-width": "180", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.date_id && scope.row.date
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.date.date) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.time_id && scope.row.time
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.time.start_time) +
                              "至" +
                              _vm._s(scope.row.time.end_time) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "核销数量", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.use_count) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "核销时间", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.use_time.indexOf("1970-01-01") == -1
                      ? _c("div", { staticClass: "flex" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.use_time) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "核销员", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.checker
                      ? _c("div", { staticClass: "flex" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                scope.row.checker.real_name ||
                                  scope.row.checker.nickname
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "核销设备", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.check_channel_text
                      ? _c("div", { staticClass: "flex" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.check_channel_text) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "退款", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.refund_num
                      ? _c("div", { staticClass: "flex" }, [
                          _vm._v(
                            "\n          退款" +
                              _vm._s(scope.row.refund_num) +
                              "张\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    Number(scope.row.refund_price)
                      ? _c("div", { staticClass: "flex" }, [
                          _vm._v(
                            "\n          共计" +
                              _vm._s(Number(scope.row.refund_price)) +
                              "元\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "退款时间", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.refund_time
                      ? _c("div", { staticClass: "flex" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.parseTime(scope.row.refund_time)) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", width: "140", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 10
                      ? _c("div", [
                          _c("div", [
                            _vm._v(
                              "剩余核销：" + _vm._s(scope.row.surplus_num)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("共可核销：" + _vm._s(scope.row.use_num)),
                          ]),
                        ])
                      : scope.row.status == 20
                      ? _c("div", [_vm._v("已核销")])
                      : scope.row.status == 30
                      ? _c("div", [_vm._v("已过期")])
                      : scope.row.status == 40
                      ? _c("div", [
                          _vm._v("\n          已失效"),
                          scope.row.surplus_num != scope.row.use_num
                            ? _c("span", [
                                _vm._v(
                                  "（" +
                                    _vm._s(scope.row.surplus_num) +
                                    "/" +
                                    _vm._s(scope.row.use_num) +
                                    "）"
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status != 20 && scope.row.status != 50
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.finishAction(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("手动核销")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.hasRefund
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.refundMoneyAction(scope.row)
                              },
                            },
                          },
                          [_vm._v("退款")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "实名认证信息",
            visible: _vm.showVerifiedInfo.show,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showVerifiedInfo, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap" },
            _vm._l(_vm.showVerifiedInfo.data, function (m, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "verified mb20",
                  staticStyle: { width: "220px" },
                },
                [
                  _c("div", { staticClass: "line1" }, [
                    _vm._v("真实姓名：" + _vm._s(m.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "line1" }, [
                    _vm._v("手机号码：" + _vm._s(m.mobile)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "line1" }, [
                    _vm._v(_vm._s(m.idcard)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退款",
            visible: _vm.showRefundMoney,
            width: "450px",
            center: "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRefundMoney = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("span", [_vm._v("选择退款数量：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "请选择退款数量" },
                  model: {
                    value: _vm.refundMoneyData.num,
                    callback: function ($$v) {
                      _vm.$set(_vm.refundMoneyData, "num", $$v)
                    },
                    expression: "refundMoneyData.num",
                  },
                },
                _vm._l(_vm.refundMoneyData.refundCount, function (m) {
                  return _c("el-option", {
                    key: m,
                    attrs: { label: m, value: m },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c("span", [_vm._v("输入退款金额：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "请输入退款金额" },
                model: {
                  value: _vm.refundMoneyData.refund,
                  callback: function ($$v) {
                    _vm.$set(_vm.refundMoneyData, "refund", $$v)
                  },
                  expression: "refundMoneyData.refund",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml5" }, [
                _vm._v(
                  "可退" + _vm._s(_vm.refundMoneyData.refundMaxPrice) + "元"
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showRefundMoney = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmRefundAction },
                },
                [_vm._v("确认退款")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }