var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "鉴定管理" } }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("图文")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("专家")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [
                _vm._v("数据报表"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [
                _vm._v("分类设置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [
                _vm._v("基础配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "6" } }, [_vm._v("投诉")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("div", { staticClass: "status item-align-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.type == 0 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(0)
                      },
                    },
                  },
                  [_vm._v("\n          全部\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.type == 1 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(1)
                      },
                    },
                  },
                  [_vm._v("\n          待处理\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.type == 2 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(2)
                      },
                    },
                  },
                  [_vm._v("\n          已处理\n        ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "default-box mt10" }, [
                _c(
                  "div",
                  { staticClass: "default-setting" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "投诉理由",
                          placement: "top",
                          disabled: "disabled",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: {
                              placeholder: "投诉理由",
                              filterable: "filterable",
                            },
                            model: {
                              value: _vm.complaintReasons,
                              callback: function ($$v) {
                                _vm.complaintReasons = $$v
                              },
                              expression: "complaintReasons",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "", label: "全部" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "回答错误", label: "回答错误" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                value: "色情、暴力、违法",
                                label: "色情、暴力、违法",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "语言攻击", label: "语言攻击" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "政治敏感", label: "政治敏感" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "迷信", label: "迷信" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "广告", label: "广告" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                value: "侵权（冒充他人、侵犯名誉等）",
                                label: "侵权（冒充他人、侵犯名誉等）",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "侵权", label: "侵权" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ml10",
                        attrs: { type: "primary" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.searchAction($event)
                          },
                        },
                      },
                      [_vm._v("筛选")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticClass: "mt40",
                  attrs: {
                    "v-loading": _vm.loading,
                    border: "",
                    data: _vm.resultData,
                    size: "small",
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120",
                      align: "",
                      prop: "",
                      label: "投诉信息",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(_vm._s("信息标识：" + row.content.id)),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  "信息类型：" +
                                    (row.type === 1 ? "问题" : "答案")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                _vm._s("投诉理由：" + row.complaint_content)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "140",
                      align: "",
                      prop: "",
                      label: "问题/答案 文字",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.content.text
                              ? _c("div", [_vm._v(_vm._s(row.content.text))])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "140",
                      align: "",
                      prop: "",
                      label: "问题/答案 图片",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.content.img && row.content.img.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onLookImgs(row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "commonlook" },
                                        }),
                                        _vm._v("点击查看图片"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "140",
                      align: "",
                      prop: "",
                      label: "问题/答案 视频",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.content.video
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onLookVideo(row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "commonlook" },
                                        }),
                                        _vm._v("点击查看视频"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "220",
                      align: "",
                      prop: "",
                      label: "答案 语音",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.content.audio
                              ? _c("div", [
                                  _c("audio", {
                                    staticClass: "w200",
                                    attrs: {
                                      src: row.content.audio,
                                      controls: "",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "120",
                      align: "",
                      prop: "",
                      label: "投诉时间",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.create_time))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "",
                      prop: "",
                      label: "投诉状态",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.has_resolved === 0
                              ? _c("div", [_vm._v("未处理")])
                              : row.is_blocked === 0
                              ? _c("div", [_vm._v("投诉不符")])
                              : row.is_blocked === 1
                              ? _c("div", [_vm._v("内容已屏蔽")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      align: "center",
                      prop: "",
                      label: "操作",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.has_resolved === 0
                              ? _c(
                                  "div",
                                  [
                                    _c("el-popconfirm", {
                                      attrs: {
                                        title: "确定下架此条被投诉内容吗？",
                                      },
                                      on: {
                                        onConfirm: function ($event) {
                                          return _vm.onChuli(row, 1)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "reference",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "10px",
                                                    },
                                                    attrs: { size: "mini" },
                                                  },
                                                  [_vm._v("下架内容")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-popconfirm", {
                                      attrs: { title: "确定此条投诉不处理？" },
                                      on: {
                                        onConfirm: function ($event) {
                                          return _vm.onChuli(row, 0)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "reference",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "el-button",
                                                  { attrs: { size: "mini" } },
                                                  [_vm._v("投诉不符")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "paginaBox flex" },
                [
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      total: _vm.total,
                      background: "",
                      "page-size": _vm.queryParams.limit,
                      "show-sizer": "show-sizer",
                      "show-total": "show-total",
                      "show-elevator": "show-elevator",
                      "current-page": _vm.queryParams.page,
                    },
                    on: {
                      "size-change": _vm.changePageSize,
                      "current-change": _vm.changePage,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showVideo,
                    title: "查看视频",
                    "footer-hide": "footer-hide",
                    width: "800",
                    align: "center",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showVideo = $event
                    },
                  },
                },
                [
                  _c("div", [
                    _vm.video
                      ? _c("video", {
                          staticStyle: { width: "750px", height: "421px" },
                          attrs: { src: _vm.video, controls: true },
                        })
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showImg,
                    title: "查看视频",
                    "footer-hide": "footer-hide",
                    width: "800",
                    align: "center",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showImg = $event
                    },
                  },
                },
                [
                  _vm.img && _vm.img.length > 0
                    ? _c(
                        "div",
                        { staticClass: "h400" },
                        _vm._l(_vm.img, function (m, i) {
                          return m
                            ? _c(
                                "a",
                                {
                                  key: "img" + i,
                                  attrs: { href: m, target: "_blank" },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "750px" },
                                    attrs: { src: m, controls: true },
                                  }),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }