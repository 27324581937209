var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", inline: "", "label-width": "100px" },
                },
                [
                  _c("span", { staticClass: "seachTiele" }, [
                    _vm._v("时间选择："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "mr20",
                      attrs: { type: "button", size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.selectChange(_vm.tableFrom.date)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "date", $$v)
                        },
                        expression: "tableFrom.date",
                      },
                    },
                    _vm._l(_vm.fromList.fromTxt, function (item, i) {
                      return _c(
                        "el-radio-button",
                        { key: i, attrs: { label: item.val } },
                        [_vm._v(_vm._s(item.text))]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      "value-format": "yyyy/MM/dd",
                      format: "yyyy/MM/dd",
                      size: "small",
                      type: "daterange",
                      placement: "bottom-end",
                      placeholder: "自定义时间",
                    },
                    on: { change: _vm.onchangeTime },
                    model: {
                      value: _vm.timeVal,
                      callback: function ($$v) {
                        _vm.timeVal = $$v
                      },
                      expression: "timeVal",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "按轮次：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.tableFrom.rounds_num,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "rounds_num", $$v)
                            },
                            expression: "tableFrom.rounds_num",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "box_id",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "rounds_num",
                  label: "轮次",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "进攻对象", prop: "attack_target_name" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "issue_gold_coin_num",
                  label: "发放保卫积分",
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "default" },
                            on: {
                              click: function ($event) {
                                return _vm.showpreview(scope.row, 1)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.issue_gold_coin_num))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "失败人数", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "default" },
                            on: {
                              click: function ($event) {
                                return _vm.showpreview(scope.row, 2)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.fail_num))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "add_time",
                  label: "获得时间",
                  "min-width": "140",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("file-list", { ref: "exportList" }),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showImg,
                "close-on-click-modal": false,
                center: "",
                title: "查看二维码",
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showImg = $event
                },
              },
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.showImgUrl, alt: "" },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "挖矿成绩",
                visible: _vm.resultflag,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.resultflag = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.resultData.data, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "aircraft_id",
                      label: "挖矿成绩",
                      "min-width": "140",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                            " +
                                _vm._s(scope.row.title) +
                                " "
                            ),
                            scope.row.type == 1
                              ? _c("span", [
                                  _vm._v(
                                    "(藏品：" +
                                      _vm._s(scope.row.product.store_name) +
                                      "*" +
                                      _vm._s(scope.row.num) +
                                      ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "add_time",
                      label: "获得时间",
                      "min-width": "140",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.resultFrom.limit,
                      "current-page": _vm.resultFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.resultData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeResultChange,
                      "current-change": _vm.pageResultChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }