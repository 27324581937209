var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "拍卖会" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("风格模板选择"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center",
                  staticStyle: { "margin-top": "15px" },
                },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c("div", { key: index, staticClass: "change-type" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        class: { curr: _vm.is_active(item) },
                      },
                      [
                        _c("label", [
                          _c("img", {
                            staticStyle: { width: "62px" },
                            attrs: { src: item.src },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.content.style,
                                expression: "content.style",
                              },
                            ],
                            staticClass: "choose-input",
                            attrs: { type: "radio", name: "choose" },
                            domProps: {
                              value: item.value,
                              checked: _vm._q(_vm.content.style, item.value),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.content,
                                  "style",
                                  item.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "name color-gray align-c ft12" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("数据选择"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.content.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "type", $$v)
                        },
                        expression: "content.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("系统自动展示"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("手动选择展示"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.type === 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("数据标签"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 1
                ? _c(
                    "div",
                    { staticClass: "item-align-center mt10" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.content.label,
                            callback: function ($$v) {
                              _vm.$set(_vm.content, "label", $$v)
                            },
                            expression: "content.label",
                          },
                        },
                        [
                          _c("el-checkbox", { attrs: { label: "day" } }, [
                            _vm._v("日拍"),
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "month" } }, [
                            _vm._v("月拍"),
                          ]),
                          _vm._v(" "),
                          _c("el-checkbox", { attrs: { label: "proxy" } }, [
                            _vm._v("代拍"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("系统自动展示条件"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 1
                ? _c(
                    "div",
                    { staticClass: "item-align-center mt10" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.content.system_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.content, "system_type", $$v)
                            },
                            expression: "content.system_type",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("展示预展+进行中+已结束拍卖会"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("展示预展+进行中的拍卖会"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("展示预展中的拍卖会"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 4 } }, [
                                _vm._v("展示进行中的拍卖会"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 5 } }, [
                                _vm._v("展示已结束的拍卖会"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 1 && !_vm.loadMore
                ? _c(
                    "div",
                    { staticClass: "item-align-center mt20" },
                    [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("展示数据")]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: { min: 1, max: 50, label: "数量" },
                        model: {
                          value: _vm.content.system_count,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "system_count", $$v)
                          },
                          expression: "content.system_count",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 2
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("手动选择拍卖会"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.data &&
              _vm.content.data.length > 0 &&
              _vm.content.type === 2
                ? _c(
                    "div",
                    { staticClass: "pt10" },
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          { attrs: { list: _vm.content.data } },
                          "draggable",
                          _vm.$attrs,
                          false
                        ),
                        _vm._l(_vm.content.data, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "kuang1 item-align-center mt10",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item-align-center flex-one" },
                                [
                                  _c("div", { staticClass: "ft14" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              index === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "go-down-img ml10",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goDown(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              index > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "go-up-img ml10",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goUp(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "del-img",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 2
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("img", {
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "margin-right": "5px",
                        "margin-top": "2px",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fontW500 ft16",
                        on: { click: _vm.handleAdditem },
                      },
                      [_vm._v("选择拍卖会")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "100px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.backgroundColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "backgroundColor", $$v)
                      },
                      expression: "facade.backgroundColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍卖会间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.meetingMarginTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "meetingMarginTop", $$v)
                      },
                      expression: "facade.meetingMarginTop",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍卖会标题" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.meetingPaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "meetingPaddingTop", $$v)
                            },
                            expression: "facade.meetingPaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.meetingPaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "meetingPaddingBottom", $$v)
                            },
                            expression: "facade.meetingPaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.meetingPaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "meetingPaddingLeft", $$v)
                            },
                            expression: "facade.meetingPaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.meetingPaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "meetingPaddingRight", $$v)
                            },
                            expression: "facade.meetingPaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ft14 fWeight500 color-black" }, [
                _vm._v("拍卖会名称"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.meetingNameColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "meetingNameColor", $$v)
                      },
                      expression: "facade.meetingNameColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.meetingNameFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "meetingNameFontSize", $$v)
                      },
                      expression: "facade.meetingNameFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.meetingNameFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "meetingNameFontWeight", $$v)
                        },
                        expression: "facade.meetingNameFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ft14 fWeight500 color-black" }, [
                _vm._v("专场数量"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.meetingAlbumCountColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "meetingAlbumCountColor", $$v)
                      },
                      expression: "facade.meetingAlbumCountColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "高亮数字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.meetingAlbumNowCountColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "meetingAlbumNowCountColor", $$v)
                      },
                      expression: "facade.meetingAlbumNowCountColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.meetingAlbumCountFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "meetingAlbumCountFontSize", $$v)
                      },
                      expression: "facade.meetingAlbumCountFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.meetingAlbumCountFontWeight,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.facade,
                            "meetingAlbumCountFontWeight",
                            $$v
                          )
                        },
                        expression: "facade.meetingAlbumCountFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ft14 fWeight500 color-black" }, [
                _vm._v("拍卖会时间地点"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "距离" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.meetingInfoMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "meetingInfoMarginTop", $$v)
                            },
                            expression: "facade.meetingInfoMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.meetingInfoColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "meetingInfoColor", $$v)
                      },
                      expression: "facade.meetingInfoColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.meetingInfoFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "meetingInfoFontSize", $$v)
                      },
                      expression: "facade.meetingInfoFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.meetingInfoFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "meetingInfoFontWeight", $$v)
                        },
                        expression: "facade.meetingInfoFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { currentData: _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }