var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-view mt20" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.createdAction } },
            [_vm._v("创建藏品")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "sku_name", label: "藏品名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "sku_id", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.sku_id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "赠送积分数量", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.exchange_integral_num)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                staticClass: "mt20",
                staticStyle: { "text-align": "right" },
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "560px",
            "close-on-click-modal": false,
            visible: _vm.setData.show,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setData, "show", $event)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("创建积分藏品"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt10" }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("span", { staticStyle: { width: "120px" } }, [
                  _vm._v("sku_id"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "flex-one",
                  attrs: { placeholder: "单行输入", size: "small" },
                  model: {
                    value: _vm.setData.sku_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.setData, "sku_id", $$v)
                    },
                    expression: "setData.sku_id",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex align-items-c mt10" },
              [
                _c("span", { staticStyle: { width: "120px" } }, [
                  _vm._v("名称"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "flex-one",
                  attrs: { placeholder: "单行输入", size: "small" },
                  model: {
                    value: _vm.setData.sku_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.setData, "sku_name", $$v)
                    },
                    expression: "setData.sku_name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex align-items-c mt10" },
              [
                _c("span", { staticStyle: { width: "120px" } }, [
                  _vm._v("赠送积分数量"),
                ]),
                _vm._v(" "),
                _c("el-input-number", {
                  attrs: { label: "label" },
                  model: {
                    value: _vm.setData.exchange_integral_num,
                    callback: function ($$v) {
                      _vm.$set(_vm.setData, "exchange_integral_num", $$v)
                    },
                    expression: "setData.exchange_integral_num",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }