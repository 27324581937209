var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "系列名称", prop: "album_name" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入系列名称" },
                    model: {
                      value: _vm.workDetail.album_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "album_name", $$v)
                      },
                      expression: "workDetail.album_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "封面图", prop: "album_image" } },
                [
                  _c("div", { staticClass: "flex align-items-c" }, [
                    _vm.workDetail.album_image
                      ? _c("div", { staticClass: "img-list" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.workDetail.album_image + "!120a",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showImageAction(
                                  _vm.workDetail.album_image
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del",
                              on: {
                                click: function ($event) {
                                  return _vm.delImgAction("no")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c mr10",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg(1)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml10 color-gray ft12" }, [
                      _vm._v(
                        "图片格式支持：JPG、JPEG、PNG、GIF；文件大小≤20M；图片比例建议1:1"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择标签" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: { multiple: "", placeholder: "请选择标签" },
                      model: {
                        value: _vm.workDetail.label_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "label_id", $$v)
                        },
                        expression: "workDetail.label_id",
                      },
                    },
                    _vm._l(_vm.tagList, function (m, i) {
                      return _c("el-option", {
                        key: "tag" + i,
                        attrs: { label: m.label_name, value: m.label_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "起售时间", prop: "start_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "datetime",
                      "value-format": "timestamp",
                      placeholder: "请选择发售时间",
                      align: "right",
                    },
                    model: {
                      value: _vm.workDetail.start_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "start_time", $$v)
                      },
                      expression: "workDetail.start_time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "限购数量" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { type: "number", placeholder: "数量不超过20000份" },
                    model: {
                      value: _vm.workDetail.buy_limit_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "buy_limit_num", _vm._n($$v))
                      },
                      expression: "workDetail.buy_limit_num",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情介绍" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.workDetail.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "message", $$v)
                      },
                      expression: "workDetail.message",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showWork = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imagesKey + "!m640" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }