var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "control",
              { attrs: { title: "NFT头图", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "边框宽度" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderWidth", $$v)
                              },
                              expression: "facade.borderWidth",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("图片静态展示")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex-one fontW400 color-gray ft12",
                              staticStyle: { margin: "0 12px" },
                            },
                            [_vm._v("开启后，展示图片不在3D晃动")]
                          ),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.facade.imgStatic,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "imgStatic", $$v)
                              },
                              expression: "facade.imgStatic",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "name"
        ? [
            _c(
              "control",
              { attrs: { title: "NFT名称", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 500 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.facade.showStock
                                    ? "剩余 " + _vm.item.product.stock
                                    : "抢购中"
                                ) + "\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "fontW400 ft14 color-black" },
                          [_vm._v("藏品详情")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "分享位置" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.share_area,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "share_area", $$v)
                                },
                                expression: "facade.share_area",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("名称上方"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("右上角"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("分享图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "share_icon")
                              },
                            },
                          },
                          [
                            _vm.facade.share_icon
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("share_icon")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.share_icon
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.share_icon + "!300",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "fontW400 ft14 color-black" },
                          [_vm._v("我的藏品详情")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("放大展示图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "show_icon")
                              },
                            },
                          },
                          [
                            _vm.facade.show_icon
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("show_icon")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.show_icon
                              ? _c("img", {
                                  attrs: { src: _vm.facade.show_icon + "!300" },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "tag"
        ? [
            _c(
              "control",
              { attrs: { title: "NFT标签", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "标签字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.btn_font_size,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "btn_font_size", $$v)
                              },
                              expression: "facade.btn_font_size",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "标签圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.btn_radius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "btn_radius", $$v)
                              },
                              expression: "facade.btn_radius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "主标签背景" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.main_btn_bg_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "main_btn_bg_color", $$v)
                              },
                              expression: "facade.main_btn_bg_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "主标签文字" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.main_btn_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "main_btn_color", $$v)
                              },
                              expression: "facade.main_btn_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "副标签背景" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.sub_btn_bg_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "sub_btn_bg_color", $$v)
                              },
                              expression: "facade.sub_btn_bg_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "副标签文字" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.sub_btn_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "sub_btn_color", $$v)
                              },
                              expression: "facade.sub_btn_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "库存颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.stock_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "stock_color", $$v)
                              },
                              expression: "facade.stock_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "库存字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.stock_font_size,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "stock_font_size", $$v)
                              },
                              expression: "facade.stock_font_size",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "提示颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.lock_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "lock_color", $$v)
                              },
                              expression: "facade.lock_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "提示线颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.lock_line_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "lock_line_color", $$v)
                              },
                              expression: "facade.lock_line_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "提示字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.lock_font_size,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "lock_font_size", $$v)
                              },
                              expression: "facade.lock_font_size",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "control",
              { attrs: { title: "作品信息", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "隐藏" } },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: _vm.facade.hideInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hideInfo", $$v)
                            },
                            expression: "facade.hideInfo",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: { src: _vm.facade.title_image + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "副文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#999999" },
                          model: {
                            value: _vm.facade.otherColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "otherColor", $$v)
                            },
                            expression: "facade.otherColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 40, max: 200 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("寄售详情相关"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginDetailTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginDetailTop", $$v)
                            },
                            expression: "facade.marginDetailTop",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "coupon"
        ? [
            _c(
              "control",
              { attrs: { title: "优惠券", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black mr10" },
                          [_vm._v("优惠券图标")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("couponImg")
                              },
                            },
                          },
                          [
                            _vm.facade.couponImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("couponImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.couponImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.couponImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "领取颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#999999" },
                            model: {
                              value: _vm.facade.receiveColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "receiveColor", $$v)
                              },
                              expression: "facade.receiveColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black mr10" },
                          [_vm._v("领取图标")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("receiveImg")
                              },
                            },
                          },
                          [
                            _vm.facade.receiveImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("receiveImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.receiveImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.receiveImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "control",
              { attrs: { title: "商品详情", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: { src: _vm.facade.title_image + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 30, max: 200 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h3" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("我的藏品详情"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("区块链图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "blockchain_image")
                            },
                          },
                        },
                        [
                          _vm.facade.blockchain_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("blockchain_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.blockchain_image
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.blockchain_image + "!300",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.blockchain_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "blockchain_color", $$v)
                            },
                            expression: "facade.blockchain_color",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "notice"
        ? [
            _c(
              "control",
              { attrs: { title: "购买须知", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: { src: _vm.facade.title_image + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 30, max: 200 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "playinfo"
        ? [
            _c(
              "control",
              { attrs: { title: "购买须知", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "80px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.facade.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title", $$v)
                            },
                            expression: "facade.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("标题图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "title_image")
                            },
                          },
                        },
                        [
                          _vm.facade.title_image
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("title_image")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.title_image
                            ? _c("img", {
                                attrs: { src: _vm.facade.title_image + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 30, max: 200 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "playimage"
        ? [
            _c(
              "control",
              { attrs: { title: "头图", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "playtag"
        ? [
            _c(
              "control",
              { attrs: { title: "合成玩法标签", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "右边距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginRight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginRight", $$v)
                              },
                              expression: "facade.marginRight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "标签圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderRadius", $$v)
                              },
                              expression: "facade.borderRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字大小" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 32 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.bgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "bgcolor", $$v)
                              },
                              expression: "facade.bgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "选中文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.selectColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "selectColor", $$v)
                              },
                              expression: "facade.selectColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "选中背景颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.selectBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "selectBgcolor", $$v)
                              },
                              expression: "facade.selectBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "边框颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.borderColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderColor", $$v)
                              },
                              expression: "facade.borderColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "composemeta"
        ? [
            _c(
              "control",
              { attrs: { title: "合成材料", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "标题颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.metaColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "metaColor", $$v)
                              },
                              expression: "facade.metaColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "当前拥有颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.haveColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "haveColor", $$v)
                              },
                              expression: "facade.haveColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "需要颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.needColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "needColor", $$v)
                              },
                              expression: "facade.needColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "列表圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderRadius", $$v)
                              },
                              expression: "facade.borderRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("材料链接图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "addImage")
                              },
                            },
                          },
                          [
                            _vm.facade.addImage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("addImage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.addImage
                              ? _c("img", {
                                  attrs: { src: _vm.facade.addImage + "!300" },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("结果指向图")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "resultImage")
                              },
                            },
                          },
                          [
                            _vm.facade.resultImage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("resultImage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.resultImage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.resultImage + "!300",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "合成圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.resultRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "resultRadius", $$v)
                              },
                              expression: "facade.resultRadius",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "nftCollectionList"
        ? [
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "日期颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.facade.month_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "month_color", $$v)
                    },
                    expression: "facade.month_color",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "日期字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value: _vm.facade.month_font_size,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "month_font_size", $$v)
                    },
                    expression: "facade.month_font_size",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "日期风格" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.facade.month_font_weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "month_font_weight", $$v)
                      },
                      expression: "facade.month_font_weight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "时间颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.facade.time_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "time_color", $$v)
                    },
                    expression: "facade.time_color",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "时间字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value: _vm.facade.time_font_size,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "time_font_size", $$v)
                    },
                    expression: "facade.time_font_size",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "时间风格" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.facade.time_font_weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "time_font_weight", $$v)
                      },
                      expression: "facade.time_font_weight",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "圆点颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.facade.dian_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "dian_color", $$v)
                    },
                    expression: "facade.dian_color",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "分割线颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.facade.xian_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "xian_color", $$v)
                    },
                    expression: "facade.xian_color",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }