"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ladderData: {
        btnLoading: false,
        show: false,
        intervalName: '',
        id: ''
      },
      intervalRule: [{
        min: 0,
        max: 0,
        type: 2,
        price: null
      }],
      resultData: []
    };
  },
  mounted: function mounted() {
    this.load();
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  methods: {
    load: function load() {
      var _this = this;
      (0, _system.bidRangeList)().then(function (res) {
        var r = [];
        res.data.forEach(function (m) {
          if (m.id !== 2) {
            r = r.concat([{
              id: m.id,
              title: m.title,
              content: m.content
            }]);
          }
        });
        _this.resultData = r;
      }).catch(function () {});
    },
    addRuleAction: function addRuleAction() {
      this.intervalRule = [{
        min: 0,
        max: 0,
        type: 2,
        price: null
      }];
      this.ladderData = {
        btnLoading: false,
        show: true,
        intervalName: '',
        id: ''
      };
    },
    editRuleAction: function editRuleAction(i) {
      if (this.resultData[i]) {
        var intervalRule = [];
        this.resultData[i].content.forEach(function (m) {
          intervalRule = intervalRule.concat([{
            min: m.min,
            max: m.max !== '∞' ? m.max : '∞',
            type: m.type,
            price: m.price
          }]);
        });
        this.intervalRule = intervalRule;
        this.ladderData = {
          show: true,
          intervalName: this.resultData[i].title,
          id: this.resultData[i].id
        };
      }
    },
    splitAction: function splitAction(i) {
      var intervalRule = JSON.parse(JSON.stringify(this.intervalRule));
      intervalRule[i].max = null;
      intervalRule.splice(i + 1, 0, {
        min: null,
        max: null,
        type: 2,
        price: null
      });
      this.intervalRule = intervalRule;
    },
    deleteAction: function deleteAction(i) {
      this.intervalRule.splice(i, 1);
    },
    delRuleAction: function delRuleAction(id) {
      var _this2 = this;
      this.$confirm('确认删除此阶梯？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '不删除',
        type: 'warning'
      }).then(function () {
        (0, _system.bidRangeDel)(id).then(function () {
          _this2.$message.success('删除成功');
          _this2.load();
        });
      });
    },
    saveLadderAction: function saveLadderAction() {
      var _this3 = this;
      this.ladderData.btnLoading = true;
      if (!this.ladderData.intervalName) {
        this.$message.error('请填写竞价阶梯标题');
        this.ladderData.btnLoading = false;
        return false;
      }
      var intervalRule = [];
      if (this.intervalRule.length === 1) {
        if (this.intervalRule[0].type === 1 && !this.intervalRule[0].price) {
          this.$message.error('请填写加价幅度');
          this.ladderData.btnLoading = false;
          return false;
        }
        intervalRule = [{
          min: 0,
          max: '∞',
          price: this.intervalRule[0].type === 1 ? this.intervalRule[0].price : 0,
          type: this.intervalRule[0].type
        }];
      } else {
        var error = '';
        this.intervalRule.forEach(function (m, i) {
          if (i !== _this3.intervalRule.length - 1 && !m.max) {
            error = '请设置正确的价格区间';
          }
          if (i === 0 && m.max <= 0) {
            error = '请设置正确的价格区间';
          } else if (i > 0 && m.max < _this3.intervalRule[i - 1].max && i !== _this3.intervalRule.length - 1) {
            error = '请设置正确的价格区间';
          }
          if (m.type === 1 && !m.price) {
            error = '请设置正确的加价幅度';
          }
          intervalRule = intervalRule.concat([{
            min: i > 0 ? _this3.intervalRule[i - 1].max : 0,
            max: i === _this3.intervalRule.length - 1 ? '∞' : m.max,
            price: m.type === 1 ? m.price : 0,
            type: m.type
          }]);
        });
        if (error) {
          this.$message.error(error);
          this.ladderData.btnLoading = false;
          return false;
        }
      }
      var data = {
        title: this.ladderData.intervalName,
        content: intervalRule
      };
      var action = _system.bidRangeCreate;
      if (this.ladderData.id) {
        action = _system.bidRangeEdit;
      }
      action(this.ladderData.id, data).then(function () {
        _this3.load();
        _this3.ladderData = {
          btnLoading: false,
          show: false,
          intervalName: '',
          id: ''
        };
      }).catch(function () {});
      // let url = '/auction_add_range';
      // if (this.ladderData.id) {
      //   url = '/auction_edit_range';
      //   data.id = this.ladderData.id;
      // }
      // this.$http.post(url, data).then((res) => {
      //   if (res.code === 200) {
      //     this.load();
      //     this.ladderData = {
      //       show: false,
      //       intervalName: '',
      //       id: ''
      //     };
      //   }
      // });
      return false;
    }
  }
};