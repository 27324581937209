"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _product = require("@/api/product");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "discountList",
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      listLoading: true,
      receiveType: 0,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      },
      showGoodsFlag: false,
      showGoodsTableData: {
        data: [],
        total: 0
      },
      showGoodsTableForm: {
        page: 1,
        limit: 10
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    showGoods: function showGoods(rows) {
      this.showGoodsTableForm.product_ids = rows.product.join(',');
      this.showGoodsTableForm.page = 1;
      this.showGoodsFlag = true;
      this.loadFlagGoods();
    },
    loadFlagGoods: function loadFlagGoods() {
      var _this = this;
      (0, _product.productLstApi)(this.showGoodsTableForm).then(function (res) {
        _this.showGoodsTableData.data = res.data.list;
        _this.showGoodsTableData.total = res.data.count;
      });
    },
    pageNewChange: function pageNewChange(page) {
      this.showGoodsTableForm.page = page;
      this.loadFlagGoods("");
    },
    handleSizeNewChange: function handleSizeNewChange(val) {
      this.showGoodsTableForm.limit = val;
      this.loadFlagGoods("");
    },
    editAction: function editAction(id) {
      this.$router.push({
        path: "".concat(_settings.roterPre) + "/product/discountCreate",
        query: {
          id: id
        }
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === "2") {
        // this.$router.push('/merchant/user/group');
        return false;
      } else if (tab.name === "3") {
        // this.$router.push('/merchant/user/label');
        return false;
      }
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u6D3B\u52A8\u5417?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _product.discountDelApi)({
          id: id
        }).then(function (_ref) {
          var message = _ref.message;
          _this2.$message.success(message);
          _this2.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this2.$message.error(message);
        });
      }).catch(function () {});
    },
    stopAction: function stopAction(id) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5173\u95ED\u8BE5\u6D3B\u52A8?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _product.discountStopApi)({
          id: id
        }).then(function (_ref3) {
          var message = _ref3.message;
          _this3.$message.success(message);
          _this3.getList();
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this3.$message.error(message);
        });
      }).catch(function () {});
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _product.discountListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    goLinkAction: function goLinkAction(type, m) {
      var link = "/merchant/marketing/coupon/user";
      if (type === "use") {
        link = "/merchant/marketing/coupon/use";
      }
      link += "?name=" + m.name;
      this.$router.push(link);
    }
  }
};