"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es7.object.values");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
var _marketing = require("@/api/marketing");
var _vuex = require("vuex");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var platarr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ'];
var _default = exports.default = {
  components: {
    selectProduct: _goods.default,
    Nft: _nft.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      ruleForm: {
        title: "",
        banner_image: "",
        start_time: "",
        end_time: "",
        content: ""
      },
      rules: {
        title: {
          required: true,
          message: "请输入活动名称",
          trigger: "blur"
        },
        banner_image: {
          required: true,
          message: "请选择图片",
          trigger: "change"
        }
      },
      assist_date: "",
      playarr: [{
        title: "",
        synthetic_info: "",
        limit_synthetic_number: "",
        total_synthetic_number: "",
        syntheticMaterialData: [],
        synthetic_material_condition: 0,
        //0 满足所有条件才可合成;1 满足任意条件即可合成
        synthetic_rule: [],
        // 合成规则
        synthetic_result_condition: 0,
        // 0 全部合成;1 随机合成一项
        syntheticProductData: [],
        //{type: 1: 藏品 2：盲盒, link_id: 关联产品id， single_synthetic_number: 单次合成数量, total_synthetic_number: 可合成最大数量}
        ruleList: [],
        is_show_remaining_synthetic_number: 0
      }],
      id: "",
      addruleflag: false,
      addrulenum: '',
      addruleList: [],
      playslist: []
    };
  },
  computed: {},
  created: function created() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.loadDetail();
      this.getPlayList();
    }
  },
  methods: {
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex == 4) {
        if (row.produnum > 1) {
          return {
            rowspan: row.produnum || 1,
            colspan: 1
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      }
      // return {
      //   rowspan: row.rowspan,
      //   colspan: row.colspan,
      // };
    },
    delreault: function delreault(index, playindex) {
      if (this.playarr[playindex].syntheticProductData && this.playarr[playindex].syntheticProductData.length > 0) {
        this.playarr[playindex].syntheticProductData.splice(index, 1);
      }
    },
    addresult: function addresult(index) {
      this.addruleList = [];
      this.addrulenum = '';
      this.addruleflag = true;
      this.ruleindex = index;
      var resultarr = [];
      if (this.playarr[index].ruleList && this.playarr[index].ruleList.length > 0) {
        this.playarr[index].ruleList.forEach(function (item) {
          resultarr = resultarr.concat(item.productidArr);
        });
      }
      this.playslist = this.playarr[index].syntheticMaterialData.filter(function (item) {
        return resultarr.indexOf(item.product_id) == -1;
      });
    },
    saverule: function saverule() {
      var _this2 = this;
      console.log(this.addruleList);
      if (this.addruleList.length < 2) {
        this.$message.error('选择数量不能小于2');
        return;
      }
      if (!this.addrulenum) {
        this.$message.error('请输入总量');
        return;
      }
      var newjson = JSON.parse(JSON.stringify(this.playarr[this.ruleindex].syntheticMaterialData));
      var newarr = newjson.filter(function (item) {
        return _this2.addruleList.indexOf(item.product_id) > -1;
      });
      var namearr = newarr.map(function (item) {
        return item.serial_number;
      });
      var chatatStr = newarr[0].sortable;
      var checkproductId = newarr[0].product_id;
      this.playarr[this.ruleindex].ruleList.push({
        sortable: chatatStr,
        productidArr: this.addruleList,
        name: namearr.join('+'),
        result: this.addrulenum
      });
      newjson.forEach(function (item, index) {
        if (_this2.addruleList.indexOf(item.product_id) > -1) {
          item.sortable = chatatStr;
          item.need_number = _this2.addrulenum;
        }
        if (checkproductId == item.product_id) {
          item.produnum = newarr.length;
        }
      });
      // newjson.sort((a,b) => {
      //     return a.sortable < b.sortable
      // })
      console.log(newjson);
      this.playarr[this.ruleindex].syntheticMaterialData = newjson.sort(function (a, b) {
        return a.sortable.charCodeAt() - b.sortable.charCodeAt();
      });
      this.addruleflag = false;
    },
    deleterule: function deleterule(index, ins) {
      console.log(index, ins);
      var newarr = JSON.parse(JSON.stringify(this.playarr[index]));
      var productidArr = newarr.ruleList[ins].productidArr;
      newarr.ruleList.splice(ins, 1);
      newarr.syntheticMaterialData.forEach(function (item) {
        if (productidArr.indexOf(item.product_id) > -1) {
          item.sortable = item.serial_number;
          item.need_number = '';
          item.produnum = 1;
        }
      });
      newarr.syntheticMaterialData.sort(function (a, b) {
        return a.sortable.charCodeAt() - b.sortable.charCodeAt();
      });
      this.playarr[index].ruleList = newarr.ruleList;
      this.playarr[index].syntheticMaterialData = newarr.syntheticMaterialData;
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.assist_date = e;
      console.log(this.moment(e[0]).format("YYYY-MM-DD HH:mm:ss"));
      this.ruleForm.start_time = e ? this.moment(e[0]).format("YYYY-MM-DD HH:mm:ss") : "";
      this.ruleForm.end_time = e ? this.moment(e[1]).format("YYYY-MM-DD HH:mm:ss") : "";
    },
    loadDetail: function loadDetail() {
      var _this3 = this;
      (0, _marketing.synthesisDetailApi)(this.id).then(function (res) {
        var info = res.data;
        _this3.assist_date = [info.start_time, info.end_time];
        _this3.ruleForm = {
          title: info.title,
          banner_image: info.banner_image,
          start_time: info.start_time,
          end_time: info.end_time,
          content: info.content
        };
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this3.$router.back();
      });
    },
    deleteplay: function deleteplay(id) {
      var _this4 = this;
      (0, _marketing.synthesisDeletePlayNewApi)(id).then(function (res) {
        _this4.getPlayList();
      });
    },
    getPlayList: function getPlayList() {
      var _this5 = this;
      (0, _marketing.synthesisPlayListNewApi)({
        synthetic_album_id: this.id
      }).then(function (res) {
        if (res.data.list.length == 0) {
          _this5.playarr = [{
            title: "",
            synthetic_info: "",
            limit_synthetic_number: "",
            total_synthetic_number: "",
            syntheticMaterialData: [],
            synthetic_material_condition: 0,
            synthetic_rule: [],
            synthetic_result_condition: 0,
            syntheticProductData: [],
            ruleList: [],
            is_show_remaining_synthetic_number: 0
          }];
          return;
        }
        //   res.data.list.forEach((item) => {
        //     item.syntheticMaterialData = item.syntheticMaterial.map((m) => {
        //       return {
        //         id: m.id,
        //         synthetic_id: m.synthetic_id,
        //         product_id: m.product_id,
        //         is_destroy: m.is_destroy,
        //         image: m.product.image,
        //         store_name: m.product.store_name,
        //       };
        //     });
        //   });
        var newpplayarr = [];
        res.data.list.forEach(function (item) {
          var json = {
            title: item.title,
            synthetic_info: item.synthetic_info,
            synthetic_material_condition: item.extend_data.synthetic_material_condition,
            synthetic_result_condition: item.extend_data.synthetic_result_condition,
            is_show_remaining_synthetic_number: item.extend_data.is_show_remaining_synthetic_number || 0
          };
          json.synthetic_id = item.synthetic_id;
          json.synthetic_album_id = item.synthetic_album_id;
          item.syntheticProduct.forEach(function (it) {
            it.image = it.product.image;
            it.store_name = it.product.store_name;
          });
          json.syntheticProductData = item.syntheticProduct;
          var rulelist = [];
          var sarr = [];
          item.synthetic_rule.forEach(function (it, ii) {
            it.productArr = [];
            it.rule.forEach(function (t, i) {
              t.image = t.product.image;
              t.store_name = t.product.store_name;
              t.id = t.product_id;
              if (i == 0) {
                t.produnum = it.rule.length;
              } else {
                t.produnum = 1;
              }
              t.sortable = it.rule[0].serial_number;
              t.need_number = it.total;
              it.productArr.push(t.product_id);
            });
            sarr = sarr.concat(it.rule);
            if (it.rule.length > 1) {
              var namearr = it.rule.map(function (ie) {
                return ie.serial_number;
              });
              rulelist.push({
                sortable: it.rule[0].serial_number,
                productidArr: it.productArr,
                name: namearr.join('+'),
                result: it.total
              });
            }
          });
          json.syntheticMaterialData = sarr;
          json.ruleList = rulelist;
          newpplayarr.push(json);
        });
        console.log(newpplayarr);
        _this5.playarr = [];
        _this5.$nextTick(function () {
          _this5.playarr = newpplayarr;
        });
      });
    },
    backAction: function backAction() {
      this.$router.back();
    },
    handleAdditem: function handleAdditem(view, i, item) {
      this.changeIndex = i;
      this.changePlayitem = item;
      this.changeTtype = "";
      this.$refs[view].changeshow();
    },
    selectreaultProduct: function selectreaultProduct(i) {
      this.changeIndex = i;
      this.$refs.product_nft.changeshow();
      this.changeTtype = "result";
    },
    selectMessage: function selectMessage(data) {
      if (data.data && data.data[0]) {
        var newdata = JSON.parse(JSON.stringify(data.data[0]));
        newdata.store_name = newdata.name;
        newdata.product_id = newdata.id;
        if (this.changeTtype == "result") {
          var ridsarr = this.playarr[this.changeIndex].syntheticProductData.map(function (item) {
            return item.product_id;
          });
          if (ridsarr.indexOf(newdata.id) > -1) {
            this.$message.error("该商品已添加");
            return;
          }
          newdata.link_id = newdata.id;
          newdata.type = 1;
          newdata.single_synthetic_number = '';
          newdata.can_synthetic_number = '';
          newdata.total_synthetic_number = '';
          this.playarr[this.changeIndex].syntheticProductData.push(newdata);
        } else {
          var idsarr = this.playarr[this.changeIndex].syntheticMaterialData.map(function (item) {
            return item.product_id;
          });
          if (idsarr.indexOf(newdata.id) > -1) {
            this.$message.error("该商品已添加");
            return;
          }
          newdata.is_destroy = 1;
          newdata.serial_number = platarr[this.playarr[this.changeIndex].syntheticMaterialData.length];
          newdata.sortable = newdata.serial_number;
          newdata.produnum = 1;
          this.playarr[this.changeIndex].syntheticMaterialData.push(newdata);
        }
        //   if(this.changePlayitem){
        //     this.editPlay(this.changePlayitem);
        //   }
      }
    },
    cancelSelect: function cancelSelect(index, row, item) {
      if (item.ruleList.length) {
        this.$message.error('请先删除规则');
        return;
      }
      item.syntheticMaterialData.splice(index, 1);
      item.syntheticMaterialData.forEach(function (it, index) {
        it.serial_number = platarr[index];
        it.sortable = it.serial_number;
        item.sortlength = 1;
      });
    },
    setOkAction: function setOkAction() {},
    savePlay: function savePlay(type) {
      var _this6 = this;
      var json = JSON.parse(JSON.stringify(this.playarr[this.playarr.length - 1]));
      if (!json.title) {
        this.$message.error("请输入玩法名称");
        return;
      }
      if (!json.synthetic_info) {
        this.$message.error("请输入玩法描述");
        return;
      }
      if (json.syntheticMaterialData.length == 0) {
        this.$message.error("请选择合成材料");
        return;
      }
      if (!json.syntheticProductData || !json.syntheticProductData.length) {
        this.$message.error("请选择合成结果");
        return;
      }
      var pflag = false;
      json.syntheticProductData.forEach(function (item) {
        if (!item.single_synthetic_number || !item.total_synthetic_number) {
          pflag = true;
        }
      });
      if (pflag) {
        this.$message.error("请完善合成结果信息");
        return;
      }
      var rulejson = {};
      json.ruleList.forEach(function (item) {
        rulejson[item.serial_number] = item;
      });
      var syntheticJson = {};
      json.syntheticMaterialData.forEach(function (item) {
        if (syntheticJson[item.sortable]) {
          syntheticJson[item.sortable].rule.push({
            product_id: item.product_id,
            serial_number: item.serial_number,
            is_destroy: item.is_destroy
          });
          syntheticJson[item.sortable].type = 1;
        } else {
          syntheticJson[item.sortable] = {
            type: 2,
            total: item.need_number || 1,
            rule: [{
              product_id: item.product_id,
              serial_number: item.serial_number,
              is_destroy: item.is_destroy
            }]
          };
        }
      });
      json.synthetic_rule = Object.values(syntheticJson);
      // json.synthetic_rule = [{
      //     type: 1,
      //     total: 5,
      //     rule: [{
      //         product_id: '1520',
      //         serial_number: 'A',
      //         is_destroy: 1
      //     },{
      //         product_id: '1517',
      //         serial_number: 'B',
      //         is_destroy: 1
      //     },{
      //             product_id: '1512',
      //             serial_number: 'C',
      //             is_destroy: 1
      //         }]
      // }];
      console.log(json);
      var action = json.synthetic_id ? _marketing.synthesisEditPlayNewApi : _marketing.synthesisAddPlayNewApi;
      json.synthetic_album_id = this.id;
      action(json.synthetic_id ? json.synthetic_id : json, json).then(function (res) {
        _this6.playarr[_this6.playarr.length - 1].synthetic_id = res.message;
        _this6.playarr.push({
          title: "",
          synthetic_info: "",
          limit_synthetic_number: "",
          total_synthetic_number: "",
          syntheticMaterialData: [],
          synthetic_material_condition: 0,
          synthetic_rule: [],
          synthetic_result_condition: 0,
          syntheticProductData: [],
          ruleList: [],
          is_show_remaining_synthetic_number: 0
        });
      });
    },
    newsaveplay: function newsaveplay(i, index) {
      var _this7 = this;
      var json = JSON.parse(JSON.stringify(this.playarr[index]));
      if (!json.title) {
        this.$message.error("请输入玩法名称");
        return;
      }
      if (!json.synthetic_info) {
        this.$message.error("请输入玩法描述");
        return;
      }
      if (json.syntheticMaterialData.length == 0) {
        this.$message.error("请选择合成材料");
        return;
      }
      if (!json.syntheticProductData || !json.syntheticProductData.length) {
        this.$message.error("请选择合成结果");
        return;
      }
      var pflag = false;
      json.syntheticProductData.forEach(function (item) {
        if (!item.single_synthetic_number || !item.total_synthetic_number) {
          pflag = true;
        }
      });
      if (pflag) {
        this.$message.error("请完善合成结果信息");
        return;
      }
      var rulejson = {};
      json.ruleList.forEach(function (item) {
        rulejson[item.serial_number] = item;
      });
      var syntheticJson = {};
      json.syntheticMaterialData.forEach(function (item) {
        if (syntheticJson[item.sortable]) {
          syntheticJson[item.sortable].rule.push({
            product_id: item.product_id,
            serial_number: item.serial_number,
            is_destroy: item.is_destroy
          });
          syntheticJson[item.sortable].type = 1;
        } else {
          syntheticJson[item.sortable] = {
            type: 2,
            total: item.need_number || 1,
            rule: [{
              product_id: item.product_id,
              serial_number: item.serial_number,
              is_destroy: item.is_destroy
            }]
          };
        }
      });
      json.synthetic_rule = Object.values(syntheticJson);
      // json.synthetic_rule = [{
      //     type: 1,
      //     total: 5,
      //     rule: [{
      //         product_id: '1520',
      //         serial_number: 'A',
      //         is_destroy: 1
      //     },{
      //         product_id: '1517',
      //         serial_number: 'B',
      //         is_destroy: 1
      //     },{
      //             product_id: '1512',
      //             serial_number: 'C',
      //             is_destroy: 1
      //         }]
      // }];
      console.log(json);
      var action = json.synthetic_id ? _marketing.synthesisEditPlayNewApi : _marketing.synthesisAddPlayNewApi;
      json.synthetic_album_id = this.id;
      action(json.synthetic_id ? json.synthetic_id : json, json).then(function (res) {
        _this7.$message.success(json.title + '玩法  保存成功');
      });
    },
    editPlay: function editPlay(item, $event, row) {
      if (item.ruleList.length) {
        item.ruleList.forEach(function (item) {
          if (item.sortable == row.sortable) {
            item.result = $event;
          }
        });
      }
    },
    saveAction: function saveAction(formName) {
      var _this8 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var auction = _marketing.synthesisCreateApi;
          if (_this8.id) {
            auction = _marketing.synthesisEditApi;
            // let json = this.playarr[this.playarr.length - 1];
            // if (!json.synthetic_id && json.title) {
            //   this.savePlay();
            // }
            _this8.playarr.forEach(function (item, index) {
              _this8.newsaveplay(0, index);
            });
          }
          auction(_this8.id ? _this8.id : (0, _objectSpread2.default)({}, _this8.ruleForm), (0, _objectSpread2.default)({}, _this8.ruleForm)).then(function (res) {
            // this.$message.success(res.message);
            // this.$router.back();
            if (_this8.id) {
              _this8.$message.success('保存成功');
            }
            if (res.status == 200 && res.data.synthetic_album_id) {
              _this8.id = res.data.synthetic_album_id;
            }
          });
        } else {
          _this8.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          _this.ruleForm.banner_image = img[0];
        }
      }, tit);
    }
  }
};