"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 使 el-tree 支持下拉加载更多
var elSelectLoadmore = {
  inserted: function inserted(el, binding, vnode) {
    var SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      var condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (condition) {
        binding.value();
      }
    });
  }
};
var install = function install(Vue) {
  Vue.directive('el-select-loadmore', elSelectLoadmore);
};
var _default = exports.default = install;