"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _setting = require("@/api/setting");
var _webSocket = _interopRequireDefault(require("@/utils/webSocket"));
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tab: '0',
      id: this.$route.query.id || '',
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      loadEnd: false,
      widthSizeSwitch: false,
      auction_style_background_color: '#03163F',
      auction_style_font_color: '#999999',
      title_style_background_color: '#000E2C',
      image_background_color: '#2E3B58',
      title_style_font_color: '#fff',
      title_style_height: '140',
      title_font_size_venue: '30',
      title_font_size_befor: '20',
      title_font_size_company_name: '25',
      title_font_size_time: '20',
      defaultList: [],
      compImg: '',
      compName: '',
      compWidth: 40,
      compHeight: 40,
      compImg1: '',
      compName1: '',
      compWidth1: 40,
      compHeight1: 40,
      auction_style_height: 55,
      auction_font_size_lot: '50',
      auction_font_size_name: '40',
      auction_font_size_intro: '30',
      price_style_height: 40,
      price_style_background_color: '#03163F',
      price_style_font_color: '#ffffff',
      price_font_size_currency: '45',
      price_font_size_money: '45',
      changeAbbreviation: [0, 1, 2, 3],
      abbreviationList: [{
        abb: 'RMB',
        rate: '1',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/ad3de5551956b18207abe64453fdf09d.png',
        unit: '￥'
      }, {
        abb: 'HKD',
        rate: '0.92',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/9ebf714a5674d9ff405ff80f8af169bd.png',
        unit: 'HK$'
      }, {
        abb: 'EUR',
        rate: '0.8512',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/a6e02634898b50c6bd068c6e102b71ad.png',
        unit: '€'
      }, {
        abb: 'USD',
        rate: '0.1480',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/9655b9f1034ec51430bed987fc92e15f.png',
        unit: '$'
      }, {
        abb: 'GBP',
        rate: '0.1122',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/6337aa00d13cec7d6b55f52070cfeecd.png',
        unit: '￡'
      }, {
        abb: 'JPY',
        rate: '17.0025',
        icon: 'https://saas.cdn.yunzongbu.cn/image/20210726/9d269dd3347a43da69399fd0bb454860.png',
        unit: '￥'
      }],
      defaultFeeType: {},
      windowSize: 1,
      windowSizeList: [{
        height: 768,
        name: '1024*768',
        screen_size_type: 1,
        width: 1024
      }, {
        height: 960,
        name: '1280*960',
        screen_size_type: 2,
        width: 1280
      }, {
        height: 768,
        name: '1366*768',
        screen_size_type: 3,
        width: 1366
      }, {
        height: 900,
        name: '1440*900',
        screen_size_type: 4,
        width: 1440
      }, {
        height: 1080,
        name: '1920*1080',
        screen_size_type: 5,
        width: 1920
      }],
      screen_auto_switch: true,
      image_style_height: 75,
      image_style_width: 60,
      auction_style_width: 47,
      price_style_width: 55,
      title_style_background_color_next: '#0088FF',
      title_style_background_color_over: '#FF4444',
      title_style_background_color_auction_num: '#fff',
      title_style_shadow_color: '#1F3B77',
      img_shadow: '#00BCFF',
      img_bg: '#fff',
      img_width: 690,
      img_height: 690,
      auction_style_name: '#ffffff',
      auction_style_desc: '#999999',
      auction_style_price: '#999999',
      show_start_price: 1,
      auction_font_size_price: '36',
      price_style_font_color_unit: '#999999',
      auction_font_size_lot_horizontal: '36',
      auction_font_size_name_horizontal: '36',
      auction_font_size_intro_horizontal: '30',
      auction_font_size_price_horizontal: '30',
      price_font_size_currency_horizontal: '36',
      price_font_size_money_horizontal: '36',
      w: 0,
      h: 0,
      fix: 1,
      shu: Number(this.$route.query.screen || 0),
      albumData: {},
      auctionData: {},
      lastEndAuctionInfo: {},
      sdata: {
        socketServe: _webSocket.default.Instance
      },
      client_id: '',
      end: false,
      albumEndData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)('screen_projection').then(function (res) {
      if (res.data.data) {
        _this.setData(JSON.parse(res.data.data));
      }
      if (res.data.data_two) {
        _this.setData(JSON.parse(res.data.data_two));
      }
      if (res.data.data_three) {
        _this.setData(JSON.parse(res.data.data_three));
      }
      if (res.data.data_four) {
        _this.setData(JSON.parse(res.data.data_four));
      }
      if (res.data.data_five) {
        _this.setData(JSON.parse(res.data.data_five));
      }
      if (!_this.widthSizeSwitch) {
        _this.h = _this.windowSizeList[_this.windowSize].height;
        _this.w = _this.windowSizeList[_this.windowSize].width;
        _this.fix = _this.w / 1920;
      } else {
        _this.h = document.documentElement.clientHeight || document.body.clientHeight;
        _this.w = document.documentElement.clientWidth || document.body.clientWidth;
        _this.fix = _this.w / 1920;
        window.addEventListener('resize', function () {
          _this.h = document.documentElement.clientHeight || document.body.clientHeight;
          _this.w = document.documentElement.clientWidth || document.body.clientWidth;
          _this.fix = _this.w / 1920;
        });
      }
      _this.loadEnd = true;
      // this.ruleData = res.data;
    }).catch(function () {});
    (0, _setting.getExchangeRateConfigListApi)('0').then(function (res) {
      _this.abbreviationList = res.data.exchangeRateData;
    });
    if (!_webSocket.default.Instance.ws) {
      _webSocket.default.Instance.connect();
      this.sdata.socketServe = _webSocket.default.Instance;
      this.sdata.socketServe.registerCallBack('getMessage', function (data) {
        _this.sockedMes(data);
      });
      this.sdata.socketServe.registerCallBack('openSocket', function () {
        // sendData({
        //   type: "join_auction_room",
        //   auction_id: id.value,
        // });
      });
    } else if (this.client_id) {
      this.joinRoomAction();
      // sendData({
      //   type: "join_auction_room",
      //   auction_id: id.value,
      // });
    }
  },
  methods: {
    sockedMes: function sockedMes(d) {
      var _this2 = this;
      if (d.type === 'init') {
        // 初始化
        this.client_id = d.client_id;
        this.joinRoomAction();
      } else if (d.type === 'auction_start') {
        // 确认起拍价
        this.auctionData = d.data.auction_info;
      } else if (d.type === 'auction_bid') {
        // 出价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          if (d.data.bid_record.is_confirm !== 0) {
            this.auctionData.now_price = d.data.now_price;
          }
        }
      } else if (d.type === 'auction_bid_proxy') {
        // 出价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          this.auctionData.now_price = d.data.now_price;
        }
      } else if (d.type === 'auction_bid_del') {
        // 删除最高价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          this.auctionData.now_price = d.data.now_price;
          // this.settingPrice(this.auctionData.now_price);
        }
      } else if (d.type === 'auction_end' || d.type === 'auction_revoke') {
        if (d.data.next_auction) {
          var val = JSON.parse(JSON.stringify(this.auctionData));
          this.lastEndAuctionInfo = {
            lot: val.lot,
            userInfo: null,
            now_price: ''
          };
          if (d.data.auction_info && d.data.type === 1) {
            this.lastEndAuctionInfo.now_price = val.now_price;
          }
          this.auctionData = d.data.next_auction;
        } else {
          (0, _auction.albumAuctionStatistical)({
            album_id: this.id
          }).then(function (res) {
            _this2.albumEndData = res.data;
            _this2.albumEndData.end_time = res.server_time;
            _this2.end = true;
          });
        }
      } else if (d.type === 'auction_remake') {
        this.auctionData = d.data.auction_info;
      } else if (d.type === 'auction_bid_confirm') {
        this.auctionData.now_price = d.data.now_price;
      } else if (d.type === 'album_switch' && d.data && d.data.next_album_info) {
        window.location.href = '/merchant/screen_projection?id=' + d.data.next_album_info.album_id;
      }
    },
    joinRoomAction: function joinRoomAction() {
      var _this3 = this;
      (0, _auction.joinRoom)({
        album_id: this.id,
        client_id: this.client_id
      }).then(function (res) {
        _this3.albumData = res.data.album_info;
        _this3.auctionData = res.data.now_auction;
        _this3.lastEndAuctionInfo = res.data.lastEndAuctionInfo;
      }).catch(function () {});
    },
    isLoadImgAction: function isLoadImgAction(e, id, isIndex) {
      if (!e.path) return;
      var imgEL = e.path[0];
      if (isIndex) {
        if (this.screen_auto_switch) {
          if (imgEL.width - 100 > imgEL.height) {
            this.shu = 1;
          } else {
            this.shu = 0;
          }
        }
      } else {
        var ids = this.showImg.map(function (m) {
          return m.id;
        });
        var i = ids.indexOf(id);
        if (i !== -1) {
          if (this.screen_auto_switch) {
            if (imgEL.width - 100 > imgEL.height) {
              this.showImg[i].shu = 1;
            } else {
              this.showImg[i].shu = 0;
            }
          } else {
            this.showImg[i].shu = this.shu;
          }
        }
      }
    },
    setData: function setData(data) {
      for (var key in data) {
        if (key !== 'changeAbbreviation' && key !== 'abbreviationList') {
          var m = data[key];
          this[key] = m;
        }
      }
    },
    moneyAdd: function moneyAdd(money, rate, decimal) {
      var decimal1 = decimal > 0 && decimal <= 20 ? decimal : 2;
      var money2 = money;
      if (this.albumData && this.albumData.feeTypeInfo && this.albumData.feeTypeInfo.type !== this.albumData.feeTypeInfo.mer_fee_type) {
        money2 = money2 / this.albumData.feeTypeInfo.rate;
      }
      money2 = money2 * rate;
      if (!decimal) {
        money2 = money2.toFixed(0);
      }
      // const money1 = `${parseFloat((`${money2}`).replace(/[^\d-]/g, '')).toFixed(decimal1)}`;
      var money1 = "".concat(money2);
      var d = money1.split('.')[0].split('').reverse();
      var y = money1.split('.')[1] ? ".".concat(money1.split('.')[1]) : '';
      var q = '';
      for (var i = 0; i < d.length; i += 1) {
        q += d[i] + ((i + 1) % 3 === 0 && i + 1 !== d.length ? ',' : '');
      }
      var w = '';
      if (decimal1 > 0 && y) {
        w = y.substr(0, decimal1 + 1);
      }
      return "".concat(q.split('').reverse().join('')).concat(w === '.00' ? '' : w);
    },
    backAction: function backAction() {
      this.$router.back();
    },
    loadImgAction: function loadImgAction(setImg) {
      var _this4 = this;
      var params = {
        album_id: this.id
      };
      this.$http.get('get_album_auction_all_photos', {
        params: params
      }).then(function (res) {
        if (res.code === 200) {
          res.data.forEach(function (m) {
            var newM = m;
            newM.shu = _this4.shu;
          });
          _this4.allImgList = res.data;
          if (!setImg) {
            _this4.showImgAction();
          }
        }
      });
    },
    showImgAction: function showImgAction() {
      if (this.auction && this.auctionData.id) {
        var allImgIdsList = this.allImgList.map(function (m) {
          return m.id;
        });
        var index = allImgIdsList.indexOf(this.auctionData.id);
        if (index !== -1) {
          this.showImg = this.allImgList.slice(index, index + 5 > this.allImgList.length ? this.allImgList.length : index + 5);
        } else {
          this.showImg = this.allImgList.slice(0, 5);
        }
      }
    }
  }
};