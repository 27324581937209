var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "digital-collection mt20" },
    [
      _c(
        "div",
        { staticClass: "tab flex align-items-c" },
        [
          _c(
            "el-input",
            {
              staticClass: "w200",
              attrs: { placeholder: "链上标识/作品名称" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作品系列", clearable: "" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.album_id,
                callback: function ($$v) {
                  _vm.album_id = $$v
                },
                expression: "album_id",
              },
            },
            _vm._l(_vm.albumList, function (m, i) {
              return _c("el-option", {
                key: i,
                attrs: { value: m.nft_album_id, label: m.album_name },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "文件类型", clearable: "" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.file_type,
                callback: function ($$v) {
                  _vm.file_type = $$v
                },
                expression: "file_type",
              },
            },
            [
              _c("el-option", { attrs: { value: "1", label: "图片" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "2", label: "3D" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "3", label: "视频" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "4", label: "音频" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-cascader", {
            staticClass: "w200 ml10",
            attrs: {
              options: _vm.categoryList,
              props: {
                expandTrigger: "hover",
                value: "store_category_id",
                label: "cate_name",
              },
            },
            on: { change: _vm.goSearch },
            model: {
              value: _vm.category,
              callback: function ($$v) {
                _vm.category = $$v
              },
              expression: "category",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作品状态", clearable: "" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-option", { attrs: { value: 0, label: "未发行" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: 1, label: "已发行" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.resetAction },
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addAction },
            },
            [_vm._v("新增作品")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tab flex align-items-c mt10" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table no-height",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(
                                      _vm.queryParams.page
                                    ) > -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.product_id) >
                                -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.product_id) ===
                                  -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品ID", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.product_id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品信息", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c("div", { staticClass: "work-img" }, [
                            _c("img", {
                              attrs: {
                                src: row.product.image + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "user-info flex-one ml10",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c("div", { staticClass: "fWeight500" }, [
                                _vm._v(_vm._s(row.product.store_name)),
                              ]),
                              _vm._v(" "),
                              row.original_hash
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex align-items-c color-gray",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyAction(
                                            row.original_hash
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          "" +
                                            row.original_hash[0] +
                                            row.original_hash[1] +
                                            row.original_hash[2] +
                                            "****" +
                                            row.original_hash[
                                              row.original_hash.length - 3
                                            ] +
                                            row.original_hash[
                                              row.original_hash.length - 2
                                            ] +
                                            row.original_hash[
                                              row.original_hash.length - 1
                                            ]
                                        ) + "\n                "
                                      ),
                                      _c("img", {
                                        staticStyle: {
                                          width: "12px",
                                          height: "12px",
                                          display: "block",
                                          "margin-left": "5px",
                                        },
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20220427/5e669c3233581b96b82ae894c05c2549.png",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品价格", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.formatPriceNumber(row.product.price, 2))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "现有持仓", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.userOwnNum))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发行/预留/库存", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.issuer_num) +
                              "/" +
                              _vm._s(
                                row.reserved_number ? row.reserved_number : "0"
                              ) +
                              "/" +
                              _vm._s(row.product.stock)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "文件类型", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.file_type == 1
                          ? _c("div", [_vm._v("图片")])
                          : row.file_type == 2
                          ? _c("div", [_vm._v("3D")])
                          : row.file_type == 3
                          ? _c("div", [_vm._v("视频")])
                          : row.file_type == 4
                          ? _c("div", [_vm._v("音频")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 0
                          ? _c("div", { staticClass: "color-lv" }, [
                              _vm._v("未发行"),
                            ])
                          : row.status == 1
                          ? _c("div", [_vm._v("已发行")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "推荐权值", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "60px" },
                          attrs: { min: 0, controls: false },
                          on: {
                            change: function (val) {
                              return _vm.editSortAction(val, scope.$index)
                            },
                          },
                          model: {
                            value: scope.row.order_num,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "order_num", $$v)
                            },
                            expression: "scope.row.order_num",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建成员", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.admin
                          ? _c("div", [_vm._v(_vm._s(row.admin.real_name))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [_vm._v("创建：" + _vm._s(row.create_time))]),
                        _vm._v(" "),
                        _c("div", [_vm._v("销售：" + _vm._s(row.start_time))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editAction(
                                  row.product_id,
                                  row.album_id
                                )
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "commonedit" },
                            }),
                            _vm._v("编辑"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.previewAction(row.product_id)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "commonlook" },
                                }),
                                _vm._v("预览"),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.issueAction(row.product_id)
                                  },
                                },
                              },
                              [_vm._v("发行")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status != 0 && row.is_addIssue
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.increaseQuantity(row.product_id)
                                  },
                                },
                              },
                              [_vm._v("增发")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.status
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onchangeIsShow(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    row.product.mer_status ? "下架" : "上架"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-b orderFoot" },
            [
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: { click: _vm.batchUp },
                    },
                    [_vm._v("批量上架")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: { click: _vm.batchDown },
                    },
                    [_vm._v("批量下架")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: { click: _vm.batchIssueAction },
                    },
                    [_vm._v("批量发行")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: { click: _vm.batchDel },
                    },
                    [_vm._v("批量删除")]
                  ),
                  _vm._v(" "),
                  _vm.checkedIds.length > 0 || _vm.allCheck
                    ? _c("el-alert", {
                        staticClass: "w200",
                        staticStyle: { height: "32px" },
                        attrs: {
                          title: _vm.allCheck
                            ? "已选择  " + _vm.tableData.total + "  项"
                            : "已选择  " + _vm.checkedIds.length + "  项",
                          type: "info",
                          "show-icon": "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  "page-size": Number(_vm.queryParams.limit),
                  "current-page": Number(_vm.queryParams.page),
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showIncreaseQuantity.show,
            "close-on-click-modal": false,
            center: "",
            title: "藏品增发",
            width: "350px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showIncreaseQuantity, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-flex-center" },
            [
              _c("el-input-number", {
                attrs: { placeholder: "请输入增发数量", controls: false },
                model: {
                  value: _vm.showIncreaseQuantity.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.showIncreaseQuantity, "num", $$v)
                  },
                  expression: "showIncreaseQuantity.num",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showIncreaseQuantity.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmIncreaseAction },
                },
                [_vm._v("确认增发")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }