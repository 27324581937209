var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "鉴定管理" } }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("图文")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("专家")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [
                _vm._v("数据报表"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [
                _vm._v("分类设置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [
                _vm._v("基础配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "6" } }, [_vm._v("投诉")]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "status item-align-center" }, [
            _c(
              "div",
              {
                staticClass: "status-list",
                class: { curr: _vm.queryParams.status == 0 },
                on: {
                  click: function ($event) {
                    return _vm.changeStateEvent(0)
                  },
                },
              },
              [_vm._v("\n        全部\n      ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "status-list",
                class: { curr: _vm.queryParams.status == 1 },
                on: {
                  click: function ($event) {
                    return _vm.changeStateEvent(1)
                  },
                },
              },
              [_vm._v("\n        已通过\n      ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "status-list",
                class: { curr: _vm.queryParams.status == 2 },
                on: {
                  click: function ($event) {
                    return _vm.changeStateEvent(2)
                  },
                },
              },
              [_vm._v("\n        待审核\n      ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "status-list",
                class: { curr: _vm.queryParams.status == 3 },
                on: {
                  click: function ($event) {
                    return _vm.changeStateEvent(3)
                  },
                },
              },
              [_vm._v("\n        已拒绝\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "default-box mt10" }, [
            _c(
              "div",
              { staticClass: "default-setting" },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "行家名称",
                      placement: "top",
                      disabled: "disabled",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "行家名称",
                        clearable: "clearable",
                      },
                      on: { "on-enter": _vm.searchAction },
                      model: {
                        value: _vm.username,
                        callback: function ($$v) {
                          _vm.username = $$v
                        },
                        expression: "username",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "ml10",
                    attrs: {
                      content: "鉴定分类",
                      placement: "top",
                      disabled: "disabled",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: "鉴定分类",
                          filterable: "filterable",
                        },
                        model: {
                          value: _vm.category,
                          callback: function ($$v) {
                            _vm.category = $$v
                          },
                          expression: "category",
                        },
                      },
                      [
                        _c("el-option", { attrs: { value: 0, label: "全部" } }),
                        _vm._v(" "),
                        _vm._l(_vm.categoryList, function (m) {
                          return _c("el-option", {
                            key: m.id,
                            attrs: { value: m.id, label: m.category_name },
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-date-picker", {
                  staticStyle: { width: "250px" },
                  attrs: {
                    "value-format": "yyyy/MM/dd",
                    format: "yyyy/MM/dd",
                    size: "small",
                    type: "daterange",
                    placement: "bottom-end",
                    placeholder: "选择时间",
                  },
                  on: { change: _vm.onChangeDate },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml10 color-green",
                    attrs: { type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.searchAction($event)
                      },
                    },
                  },
                  [_vm._v("筛选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml10 color-green",
                    attrs: { type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.addAction("")
                      },
                    },
                  },
                  [_vm._v("添加专家")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "mt40 no-height",
              attrs: { border: "", data: _vm.resultData, size: "small" },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "专家", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "37px",
                                height: "37px",
                                "border-radius": "50%",
                                "margin-right": "8px",
                                "margin-bottom": "8px",
                                "flex-shrink": "0",
                              },
                              attrs: { src: scope.row.avatar },
                            }),
                            _vm._v(" "),
                            _c("div"),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID", prop: "uid", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户信息", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "cur",
                            staticStyle: { color: "#409eff" },
                            on: {
                              click: function ($event) {
                                return _vm.onInfo(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.nickname) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", prop: "phone", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "申请时间", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.human_create_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "擅长领域/门类", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "领域：" + _vm._s(scope.row.categoryList.join(","))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图文收费", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.answer_amount / 100 || 0) + "元/次"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "满意度", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "提问者：" +
                              _vm._s(scope.row.satisfaction_ratio || 0) +
                              "%"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "超时率：" +
                              _vm._s(scope.row.timeout_ratio || 0) +
                              "%"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "鉴定次数", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "已鉴定：" +
                              _vm._s(scope.row.answer_num || 0) +
                              "次"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "总收入：" +
                              _vm._s(scope.row.expert_total_money || 0 / 100) +
                              "元"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n            账户余额：" +
                              _vm._s(
                                scope.row.expert_account_balance || 0 / 100
                              ) +
                              "元\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "退款次数", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "后台退款：" +
                              _vm._s(scope.row.admin_refund_count || 0) +
                              "次"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "超时退款：" +
                              _vm._s(scope.row.timeout_refund_count || 0) +
                              "次"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "退款金额：" +
                              _vm._s(scope.row.refund_monry || 0 / 100) +
                              "元"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "行家状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.is_cancelled === 1
                          ? _c("div", [_vm._v("身份取消")])
                          : scope.row.review_status === 1
                          ? _c("div", [_vm._v("正常")])
                          : scope.row.review_status === 0
                          ? _c("div", [_vm._v("待审核")])
                          : _c("div", [
                              _c("div", [_vm._v("已拒绝")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "拒绝理由：" + _vm._s(scope.row.failed_reason)
                                ),
                              ]),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "排序", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !scope.row.isInput,
                                expression: "!scope.row.isInput",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.onSort(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.sort))]
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.isInput,
                              expression: "scope.row.isInput",
                            },
                          ],
                          ref: "inputRef" + scope.row.id,
                          on: {
                            blur: function ($event) {
                              return _vm.sortExpert(scope.row)
                            },
                          },
                          model: {
                            value: _vm.sortNum,
                            callback: function ($$v) {
                              _vm.sortNum = $$v
                            },
                            expression: "sortNum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.review_status === 0
                          ? _c(
                              "div",
                              { staticClass: "flex-c mb-5" },
                              [
                                _c("el-popconfirm", {
                                  attrs: { title: "确定通过审核吗？" },
                                  on: {
                                    onConfirm: function ($event) {
                                      return _vm.confirmShenhe(scope.row)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "reference",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "mr-5",
                                                attrs: {
                                                  type: "success",
                                                  size: "mini",
                                                },
                                              },
                                              [_vm._v("通过审核")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelShenhe(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("拒绝")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.review_status === 1 &&
                        scope.row.is_cancelled === 0
                          ? _c(
                              "div",
                              { staticClass: "mb-5" },
                              [
                                _c("el-popconfirm", {
                                  attrs: { title: "确定取消行家身份吗？" },
                                  on: {
                                    onConfirm: function ($event) {
                                      return _vm.onShenfen(scope.row, 1)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "reference",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "danger",
                                                  size: "mini",
                                                },
                                              },
                                              [_vm._v("取消行家身份")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : scope.row.review_status === 1 &&
                            scope.row.is_cancelled === 1
                          ? _c(
                              "div",
                              { staticClass: "mb-5" },
                              [
                                _c("el-popconfirm", {
                                  attrs: { title: "确定恢复行家身份吗？" },
                                  on: {
                                    onConfirm: function ($event) {
                                      return _vm.onShenfen(scope.row, 2)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "reference",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "success",
                                                  size: "mini",
                                                },
                                              },
                                              [_vm._v("恢复行家身份")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.review_status === 1 &&
                        scope.row.is_zhiding === 0
                          ? _c(
                              "div",
                              { staticClass: "mb-5" },
                              [
                                _c("el-popconfirm", {
                                  attrs: { title: "确定置顶吗？" },
                                  on: {
                                    onConfirm: function ($event) {
                                      return _vm.onZhiding(scope.row, 1)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "reference",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-button",
                                              { attrs: { size: "mini" } },
                                              [_vm._v("置顶")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : scope.row.review_status === 1 &&
                            scope.row.is_zhiding === 1
                          ? _c(
                              "div",
                              { staticClass: "mb-5" },
                              [
                                _c("el-popconfirm", {
                                  attrs: { title: "确定取消置顶吗？" },
                                  on: {
                                    onConfirm: function ($event) {
                                      return _vm.onZhiding(scope.row, 2)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "reference",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-button",
                                              { attrs: { size: "mini" } },
                                              [_vm._v("取消置顶")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-c" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr-5",
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addAction(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c("el-popconfirm", {
                              attrs: { title: "确定删除吗？" },
                              on: {
                                onConfirm: function ($event) {
                                  return _vm.onDelete(scope.row)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              size: "mini",
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  total: _vm.total,
                  background: "",
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                },
                on: {
                  "size-change": _vm.changePageSize,
                  "current-change": _vm.changePage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showIntro, title: "查看简介", width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.showIntro = $event
            },
          },
        },
        [
          _vm.introMes
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.introMes) } })
            : _c("div", [_vm._v("暂无简介")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }