var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "素材库", hasBack: true, isFixed: true },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [_c("upload-from", { attrs: { ssif: true, changeType: 3 } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }