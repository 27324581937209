"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.specDateAddApi = specDateAddApi;
exports.specDateList = specDateList;
exports.specDelDateApi = specDelDateApi;
exports.specSetInfoDateApi = specSetInfoDateApi;
exports.ticketCheckerCreateApi = ticketCheckerCreateApi;
exports.ticketCheckerDelApi = ticketCheckerDelApi;
exports.ticketCheckerListApi = ticketCheckerListApi;
exports.ticketConfCreateApi = ticketConfCreateApi;
exports.ticketConfInfoApi = ticketConfInfoApi;
exports.ticketCreateApi = ticketCreateApi;
exports.ticketDeleteApi = ticketDeleteApi;
exports.ticketDetailApi = ticketDetailApi;
exports.ticketEditApi = ticketEditApi;
exports.ticketEditSortApi = ticketEditSortApi;
exports.ticketListApi = ticketListApi;
exports.ticketPreRefundApi = ticketPreRefundApi;
exports.ticketRefundApi = ticketRefundApi;
exports.ticketReserveDelApi = ticketReserveDelApi;
exports.ticketReserveListApi = ticketReserveListApi;
exports.ticketSellListApi = ticketSellListApi;
exports.ticketSellUseApi = ticketSellUseApi;
exports.ticketShareApi = ticketShareApi;
exports.ticketShowApi = ticketShowApi;
exports.ticketSpecStockApi = ticketSpecStockApi;
exports.ticketStatisticsApi = ticketStatisticsApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 门票列表
 */
function ticketListApi(data) {
  return _request.default.post('/ticket/ticket/getList', data);
}

/**
 * @description 门票 修改排序
 */
function ticketEditSortApi(data) {
  return _request.default.post('ticket/ticket/editSort', data);
}

/**
 * @description 删除门票
 */
function ticketDeleteApi(data) {
  return _request.default.post('/ticket/ticket/delete', data);
}

/**
 * @description 上下架
 */
function ticketShowApi(data) {
  return _request.default.post('ticket/ticket/editShow', data);
}

/**
 * @description 海报分享
 */
function ticketShareApi(data) {
  return _request.default.get('ticket/share', data);
}

/**
 * @description 门票详情
 */
function ticketDetailApi(data) {
  return _request.default.post('/ticket/ticket/getInfo', data);
}

/**
 * @description 创建门票
 */
function ticketCreateApi(data) {
  return _request.default.post('/ticket/ticket/create', data);
}

/**
 * @description 修改门票
 */
function ticketEditApi(data) {
  return _request.default.post('/ticket/ticket/setInfo', data);
}

/**
 * @description 核销员列表
 */
function ticketCheckerListApi(data) {
  return _request.default.post('/ticket/checker/getList', data);
}

/**
 * @description 创建核销员
 */
function ticketCheckerCreateApi(data) {
  return _request.default.post('/ticket/checker/create', data);
}

/**
 * @description 创建核销员
 */
function ticketCheckerDelApi(data) {
  return _request.default.post('/ticket/checker/delete', data);
}

/**
 * @description 核销记录
 */
function ticketSellListApi(data) {
  return _request.default.post('/ticket/sell/getList', data);
}

/**
 * @description 手动核销
 */
function ticketSellUseApi(data) {
  return _request.default.post('/ticket/sell/use', data);
}

/**
 * @description 团队预约
 */
function ticketReserveListApi(data) {
  return _request.default.post('/ticket/reserve/getList', data);
}

/**
 * @description 删除团队预约
 */
function ticketReserveDelApi(data) {
  return _request.default.post('/ticket/reserve/delete', data);
}

/**
 * @description 规则配置
 */
function ticketConfInfoApi(data) {
  return _request.default.post('/ticket/conf/getInfo', data);
}

/**
 * @description 添加规则配置
 */
function ticketConfCreateApi(data) {
  return _request.default.post('/ticket/conf/create', data);
}

/**
 * @description 数据统计
 */
function ticketStatisticsApi(data) {
  return _request.default.post('/ticket/statistics/getList', data);
}
/**
 * @description 可退款数量
 */
function ticketPreRefundApi(data) {
  return _request.default.post('ticket/ticket/preRefund', data);
}

/**
 * @description 退款
 */
function ticketRefundApi(data) {
  return _request.default.post('ticket/ticket/refund', data);
}

/**
 * @description 操作规格库存
 */
function ticketSpecStockApi(data) {
  return _request.default.post('ticket/spec/stock', data);
}

/**
 * @description 规格时间段
 */
function specDateList(data) {
  return _request.default.post('ticket/spec/dateList', data);
}

/**
 * @description 新增规格时间段
 */
function specDateAddApi(data) {
  return _request.default.post('ticket/spec/addDate', data);
}

/**
 * @description 修改规格时间段
 */
function specSetInfoDateApi(data) {
  return _request.default.post('ticket/spec/setInfoDate', data);
}

/**
 * @description 删除规格时间段
 */
function specDelDateApi(data) {
  return _request.default.post('ticket/spec/delDate', data);
}