var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", inline: "", "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "status", $$v)
                            },
                            expression: "tableFrom.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部 "),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("未发货"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("已发货"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "用户：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.tableFrom.user_keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "user_keyword", $$v)
                            },
                            expression: "tableFrom.user_keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "数字藏品：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.tableFrom.product_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "product_name", $$v)
                            },
                            expression: "tableFrom.product_name",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "table",
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            "tooltip-effect": "dark",
            data: _vm.tableData.data,
            size: "mini",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "55" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#6699dd", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(row.user)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.user.real_name || row.user.nickname)
                        ),
                        row.user.real_name
                          ? _c("span", [
                              _vm._v("(" + _vm._s(row.user.nickname) + ")"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.lottery
                      ? _c("span", [_vm._v(_vm._s(scope.row.lottery.name))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "抽奖花费", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.spend == 0
                            ? "免费"
                            : scope.row.spend + "积分"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "中奖类型", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 1
                      ? _c("div", [_vm._v("谢谢参与")])
                      : scope.row.type == 2
                      ? _c("div", [_vm._v("数字藏品")])
                      : scope.row.type == 3
                      ? _c("div", [_vm._v("数字盲盒")])
                      : scope.row.type == 4
                      ? _c("div", [_vm._v("积分")])
                      : scope.row.type == 5
                      ? _c("div", [_vm._v("优惠券")])
                      : scope.row.type == 6
                      ? _c("div", [_vm._v("商品")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "中奖" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.prizeProduct
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(scope.row.prizeProduct.prize_name) +
                              "\n      "
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "收货信息", "min-width": "180" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.address_info
                      ? [
                          _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.address_info.real_name) +
                                "\n          " +
                                _vm._s(scope.row.address_info.user_phone) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(scope.row.address_info.user_address)),
                          ]),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货信息", "min-width": "140" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.type == 6
                      ? [
                          _c("div", [
                            _vm._v(
                              "快递公司：" + _vm._s(scope.row.delivery_name)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "快递单号：" + _vm._s(scope.row.delivery_no)
                            ),
                          ]),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "参与时间", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.add_time) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "80",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 6
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showsend(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  scope.row.status == 2 ? "修改发货" : "发货"
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "发货", visible: _vm.sendflag },
          on: {
            "update:visible": function ($event) {
              _vm.sendflag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入快递公司" },
                    model: {
                      value: _vm.sendJson.delivery_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendJson, "delivery_name", $$v)
                      },
                      expression: "sendJson.delivery_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入快递公司" },
                    model: {
                      value: _vm.sendJson.delivery_no,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendJson, "delivery_no", $$v)
                      },
                      expression: "sendJson.delivery_no",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.sendflag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.cursorSend } },
                [_vm._v("确认发货")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }