"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _aiMark = require("@/api/aiMark");
var _auction = require("@/api/auction");
var _user = require("@/api/user.js");
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    colour: _colour.default
  },
  data: function data() {
    return {
      name: "",
      routerUrl: "",
      color: "",
      videoFontList: [],
      radio: "",
      logo: "",
      selectfont: "",
      imgarr: [],
      field: [],
      selectfield: [],
      type: "",
      auctionImageArr: [],
      selectImage: [],
      showAuctionFlag: false,
      count: 5,
      showPropLogo: false,
      merlogodata: [],
      merlogo: "",
      meravatar: "",
      btnloading: false,
      posterToken: '',
      posterUser: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.name = this.$route.query.title || "";
    this.$store.dispatch("user/getFontList").then(function (res) {
      _this.videoFontList = res;
    });
    if (this.$route.query.auction_id) {
      (0, _auction.auctionDetail)(this.$route.query.auction_id).then(function (res) {
        _this.auctionImageArr = res.data.product.slider_image;
      });
    }
    var marketId = this.$route.query.marketid || "";
    this.marketId = marketId;
    this.getImageTextDetail(function (res) {
      if (!(res.data && res.data.extend_data && res.data.extend_data.generatePosterData && res.data.extend_data.generatePosterData.length)) {
        _this.getImageText();
      }
      if (res.data && res.data.extend_data && res.data.extend_data.generatePosterCallback) {
        if (res.data.extend_data.generatePosterCallback.status) {
          _this.btnloading = true;
          _this.checkTweenStatus();
        }
      }
    });
    // let edittype = this.$route.query.type || "";
    // if (edittype == "edit") {
    //   let generatePosterData = localStorage.getItem("generatePosterData");
    //   this.imgarr = JSON.parse(generatePosterData);
    // } else {
    //   this.getImageText();
    // }

    this.getInfo();
    var type = "album";
    if (this.$route.query.auction_id) {
      type = "auction";
    }
    this.type = type;
    (0, _aiMark.getMarketTweetTextField)({
      type: type
    }).then(function (res) {
      var field = [];
      for (var i in res.data.field) {
        field.push({
          value: i,
          name: res.data.field[i]
        });
      }
      _this.field = field;
    });
    (0, _aiMark.getMarketTweetImgLoginStatus)().then(function (res) {
      _this.posterToken = res.data.token;
      _this.posterUser = res.data.user;
    });
    // window.addEventListener('message',this.message)
  },
  beforeDestroy: function beforeDestroy() {
    if (this.imgsetTimeout) {
      clearTimeout(this.imgsetTimeout);
      this.imgsetTimeout = null;
    }
    // window.removeEventListener('message', this.message);
  },
  methods: {
    changeTab: function changeTab() {},
    visSeleChange: function visSeleChange() {},
    message: function message(event) {
      console.log(event);
    },
    againImage: function againImage() {
      this.getImageText();
    },
    getInfo: function getInfo() {
      var _this2 = this;
      (0, _user.getBaseInfo)().then(function (res) {
        _this2.logo = res.data.mer_logo;
        _this2.merlogo = res.data.mer_logo;
        _this2.meravatar = res.data.mer_avatar;
        _this2.merlogodata = res.data.config.mer_logo_data.length == 0 ? [] : res.data.config.mer_logo_data;
      });
    },
    showImgTextAction: function showImgTextAction() {
      var _this3 = this;
      if (this.type == "album") {
        this.$modalUpload(function (img) {
          console.log(img);
          _this3.selectImage = img;
          // const index = this.merData[filed].findIndex(item => item.key == res);
          // this.$set(this.merData[filed][index],'imgUrl',img[0]);
        }, 9);
      } else {
        this.showAuctionFlag = true;
      }
    },
    setSliderImg: function setSliderImg(url) {
      var index = this.selectImage.indexOf(url);
      if (index === -1) {
        console.log("到这里了");
        this.selectImage.push(url);
        console.log(this.selectImage);
      } else {
        this.selectImage.splice(index, 1);
      }
    },
    setSelectLogo: function setSelectLogo(url) {
      this.logo = url;
    },
    getImageText: function getImageText() {
      var _this4 = this;
      var marketid = this.$route.query.marketid;
      //   let loading = this.$loading({
      //     lock: true,
      //     text: "正在生成图片，请稍后",
      //     spinner: "el-icon-loading",
      //     background: "rgba(0, 0, 0, 0.7)",
      //   });
      this.btnloading = true;
      (0, _aiMark.generateMarketTweetImg)({
        market_make_id: marketid,
        count: this.count,
        imageUrls: this.selectImage,
        ratio: this.radio,
        logoUrl: this.logo,
        fontUrl: this.selectfont,
        color: this.color,
        fieldArray: this.selectfield
      }).then(function (res) {
        if (res.data.data) {
          _this4.imgarr = _this4.imgarr.concat(res.data.data);
        }
        _this4.checkTweenStatus();
        //   loading.close();
      }).catch(function (msg) {
        //   loading.close();
        _this4.btnloading = false;
      });
    },
    downimage: function downimage(item) {
      //   window.open(item.url);
      // const link = document.createElement('a');
      // link.href = item.url;
      // link.download = 'poster.jpg';
      // link.click();

      fetch(item.url)
      // 获取 blob 对象
      .then(function (res) {
        return res.blob();
      }).then(function (blob) {
        // 创建a标签
        var link = document.createElement("a");
        // 设置a标签为不可见
        link.style.display = "none";
        // 将a标签添加到body
        document.body.appendChild(link);
        // 生成Blob URL并设置给a标签的href属性
        var url = window.URL.createObjectURL(blob);
        link.href = url;
        // 设置a标签的download
        link.download = "poster.png";
        // 模拟点击事件进行下载
        link.click();
        //下载完成后清理URL对象和a标签
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      });
    },
    goedit: function goedit(item) {
      var mer_id = localStorage.getItem('mer_id');
      var url = encodeURIComponent(location.href);
      window.open("https://poster.yunzongbu.cn/?type=edit&id=" + item.id + "&callbackurl=" + url + "&marketId=" + this.marketId + "&token=" + this.posterToken + "&merid=" + mer_id);
    },
    getimageName: function getimageName(item) {
      var url = item.split("?")[0];
      var file = url.split("/");
      return file[file.length - 1];
    },
    getImageTextDetail: function getImageTextDetail(callback) {
      var _this5 = this;
      (0, _aiMark.generateMarketTweetImgDetail)(this.marketId).then(function (res) {
        if (res.data && res.data.extend_data && res.data.extend_data.generatePosterData && res.data.extend_data.generatePosterData.length) {
          _this5.imgarr = res.data.extend_data.generatePosterData;
        }
        if (callback) {
          callback(res);
        }
      });
    },
    checkTweenStatus: function checkTweenStatus() {
      var _this6 = this;
      (0, _aiMark.getMarketTweetImgStatus)({
        market_make_id: this.marketId
      }).then(function (res) {
        _this6.btnloading = res.data.status;
        if (_this6.imgsetTimeout) {
          clearTimeout(_this6.imgsetTimeout);
          _this6.imgsetTimeout = null;
        }
        if (res.data.status) {
          _this6.imgsetTimeout = setTimeout(_this6.checkTweenStatus, 3000);
        } else {
          _this6.getImageTextDetail();
        }
      });
    }
  }
};