var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("div", { staticClass: "padding20 bg-white borderRadius6" }, [
        _c("div", { staticClass: "other-page flex" }, [
          _c("div", { staticClass: "l-poster" }, [
            _c("div", { staticClass: "t" }, [_vm._v("用户分享海报示意图")]),
            _vm._v(" "),
            _c("div", { staticClass: "desc" }, [
              _vm._v("海报尺寸750 * 1172   二维码 160*160，距底距右40"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "img" }, [
              _vm.ruleForm.poster_image
                ? _c("img", {
                    attrs: {
                      src: _vm.ruleForm.poster_image + "!m640",
                      alt: "",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2022813/image/166039535689787128.png!m640",
                      alt: "",
                    },
                  }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn item-flex-center" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.uploadPosterAction },
                  },
                  [_vm._v("上传海报")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }