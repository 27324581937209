"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _order = require("@/api/order");
var _nft = require("@/api/nft");
var _product = require("@/api/product");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index4 = require("@/utils/index");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      parseTime: _index4.parseTime,
      formatPriceNumber: _index4.formatPriceNumber,
      queryParams: {
        page: 1,
        limit: 10
      },
      keyword: '',
      file_type: '',
      category: [],
      status: '',
      business_type: '',
      categoryList: [],
      showImg: false,
      showImgUrl: '',
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      album_id: '',
      albumList: [],
      showIncreaseQuantity: {
        show: false,
        id: '',
        num: undefined
      }
    };
  },
  created: function created() {
    var _this = this;
    var query = this.$route.query;
    this.album_id = query.album_id ? Number(query.album_id) : '';
    if (query.page || query.album_id) {
      if (!query.page) query.page = 1;
      if (!query.limit) query.limit = 10;
      this.queryParams = JSON.parse(JSON.stringify(query));
    }
    (0, _product.storeCategoryListApi)().then(function (res) {
      _this.categoryList = res.data;
    });
    (0, _nft.nftCollectionAlbumListApi)({
      page: 1,
      limit: 50
    }).then(function (res) {
      _this.albumList = res.data.list;
    });
    this.getList(this.queryParams.page);
  },
  methods: {
    // 选择
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.page);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.queryParams.page);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this2 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.product_id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this2.checkedIds.indexOf(id);
          if (index === -1) {
            _this2.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this2.checkedIds.indexOf(id);
          if (index > -1) {
            _this2.checkedIds.splice(index, 1);
          }
        });
      }
    },
    // 分开选择
    changeOne: function changeOne(v, collection) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(collection.product_id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(collection.product_id);
          if (_index === -1) this.checkedIds.push(collection.product_id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(collection.product_id);
          if (_index2 === -1) this.noChecked.push(collection.product_id);
        } else {
          var _index3 = this.checkedIds.indexOf(collection.product_id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    batchUp: function batchUp() {
      var _this3 = this;
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      (0, _product.batchstatusApi)({
        id: this.checkedIds.join(','),
        status: 1
      }).then(function (_ref) {
        var message = _ref.message;
        _this3.$message.success(message);
        _this3.checkedIds = [];
        _this3.getList('');
      });
    },
    batchDown: function batchDown() {
      var _this4 = this;
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      (0, _product.batchstatusApi)({
        id: this.checkedIds.join(','),
        status: 0
      }).then(function (_ref2) {
        var message = _ref2.message;
        _this4.$message.success(message);
        _this4.checkedIds = [];
        _this4.getList('');
      });
    },
    batchDel: function batchDel() {
      var _this5 = this;
      // this.$message.error('暂未开通');
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.$confirm('确认删除作品？', '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      }).then(function () {
        (0, _product.batchDeleteApi)({
          id: _this5.checkedIds.join(',')
        }).then(function (_ref3) {
          var message = _ref3.message;
          _this5.$message.success('删除成功');
          _this5.checkedIds = [];
          _this5.getList('');
        });
      }).catch(function (_ref4) {
        var message = _ref4.message;
      });
      // this.$modalForm(

      // ).then(() => {
      //   this.getList('');
      //   //   .then(({ message }) => {
      //   //   this.$message.success('删除成功');
      //   //   this.checkedIds = [];
      //   //   this.getList('');
      //   // })
      // });
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index4.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      this.setTypeAction();
      (0, _nft.nftCollectionListApi)(this.queryParams).then(function (res) {
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
    },
    goSearch: function goSearch() {
      this.queryParams.keyword = this.keyword;
      this.queryParams.album_id = this.album_id;
      this.queryParams.file_type = this.file_type;
      if (this.category.length > 0) {
        this.queryParams.cate_id = this.category[this.category.length - 1];
      } else {
        this.queryParams.cate_id = '';
      }
      this.queryParams.status = this.status;
      this.getList(1);
    },
    resetAction: function resetAction() {
      this.keyword = '';
      this.file_type = '';
      this.status = '';
      this.category = [];
      this.goSearch();
    },
    editSortAction: function editSortAction(val, index) {
      var _this7 = this;
      // 修改排序
      var item = this.tableData.data[index];
      (0, _nft.editOrderNumApi)(item.product_id, {
        order_num: val
      }).then(function () {
        _this7.$message.success('操作成功');
      }).catch(function () {});
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this8 = this;
      (0, _order.exportOrderApi)(this.queryParams).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this8.$createElement;
        _this8.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this8.$msgbox.close();
                _this8.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('auction_order');
    },
    addAction: function addAction() {
      this.$router.push('./detail');
    },
    editAction: function editAction(id, aId) {
      this.$router.push('./detail?id=' + id + '&aid=' + aId);
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this9 = this;
      (0, _product.batchstatusApi)({
        id: row.product_id,
        status: Number(!row.product.mer_status)
      }).then(function (_ref5) {
        var message = _ref5.message;
        _this9.$message.success(message);
        _this9.checkedIds = [];
        _this9.getList('');
      });
    },
    issueAction: function issueAction(id) {
      var _this10 = this;
      this.$confirm('确认发行此作品？', '提示', {
        confirmButtonText: '发行',
        type: 'warning'
      }).then(function () {
        (0, _nft.nftCollectionIssueApi)(id).then(function () {
          _this10.$message.success('操作成功');
          _this10.getList();
        });
      }).catch(function (_ref6) {
        var message = _ref6.message;
      });
    },
    increaseQuantity: function increaseQuantity(id) {
      this.showIncreaseQuantity = {
        show: true,
        id: id,
        num: undefined
      };
    },
    confirmIncreaseAction: function confirmIncreaseAction() {
      var _this11 = this;
      if (!this.showIncreaseQuantity.num || isNaN(this.showIncreaseQuantity.num)) {
        this.$message.error('请输入正确的增发数量');
        return false;
      }
      this.$confirm('确认增发' + this.showIncreaseQuantity.num + '件藏品？', '提示', {
        confirmButtonText: '确认',
        type: 'warning'
      }).then(function () {
        (0, _nft.nftAddIssueNum)({
          product_id: _this11.showIncreaseQuantity.id,
          num: _this11.showIncreaseQuantity.num
        }).then(function () {
          _this11.$message.success('操作成功');
          _this11.showIncreaseQuantity.show = false;
          _this11.getList();
        });
      }).catch(function (_ref7) {
        var message = _ref7.message;
      });
    },
    batchIssueAction: function batchIssueAction() {
      var _this12 = this;
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.$confirm('确认批量发行作品？', '提示', {
        confirmButtonText: '发行',
        type: 'warning'
      }).then(function () {
        (0, _nft.nftCollectionBatchIssueApi)({
          id: _this12.checkedIds.join(',')
        }).then(function () {
          _this12.checkedIds = [];
          _this12.$message.success('操作成功');
          _this12.getList();
        });
      }).catch(function (_ref8) {
        var message = _ref8.message;
      });
    },
    copyAction: function copyAction(val) {
      var input = document.createElement('input'); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    previewAction: function previewAction(id, key) {
      var _this13 = this;
      this.showImg = true;
      (0, _system.createQRCodeApi)({
        path: "/pages/nftCollection/index?id=".concat(id, "&noBuy=1"),
        type: 1
      }).then(function (res) {
        _this13.showImgUrl = res.data.base_image;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.limit = val;
      this.getList();
    }
  }
};