var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guidance flex", attrs: { id: "guidance" } },
    [
      _c(
        "div",
        { staticClass: "list-guidance border-radius" },
        [
          _c("titleActive", {
            attrs: {
              active: _vm.listTitleActive,
              titleList: ["导览路径", "导览数字人"],
            },
            on: {
              changeAction: function (data) {
                return (_vm.listTitleActive = data)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "list-listOrBtn con-color" }, [
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.listTitleActive == 0,
                    expression: "listTitleActive == 0",
                  },
                ],
                staticClass: "items",
                staticStyle: { "min-height": "530px" },
                attrs: { id: "draggableBox" },
              },
              [
                _c("div", { staticClass: "tour-guide" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item flex relative",
                      class: {
                        "active-border":
                          _vm.query.type == "tourIntroductionInfo",
                      },
                      staticStyle: { margin: "16px 16px 0 16px" },
                    },
                    [
                      _c("div", { staticClass: "left-icon list-icon" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "center" }, [
                        _vm.curMemberAIPicture
                          ? _c("img", {
                              staticClass: "img",
                              attrs: { src: _vm.curMemberAIPicture, alt: "" },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "right" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "content-text flex" }, [
                          _c("span", { staticStyle: { color: "#fff" } }, [
                            _vm._v("讲解："),
                          ]),
                          _vm._v(" "),
                          _vm.tourIntroductionInfo &&
                          _vm.tourIntroductionInfo.timeLength
                            ? _c(
                                "div",
                                {
                                  staticClass: "w-120 flex",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onPlayText(
                                        _vm.tourIntroductionInfo.isPlay ? 0 : 1,
                                        _vm.tourIntroductionInfo,
                                        1
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "time-play flex" }, [
                                    _c("img", {
                                      staticClass: "icon-16 ml-4 mr-6",
                                      attrs: {
                                        src: require("@/assets/image/guidance/wifi.png"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", {}, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tourIntroductionInfo.timeLength ||
                                            "输入讲解内容"
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex flex-play",
                                      attrs: { flex: "" },
                                    },
                                    [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.tourIntroductionInfo.isPlay,
                                            expression:
                                              "!tourIntroductionInfo.isPlay",
                                          },
                                        ],
                                        staticClass: "icon-16",
                                        attrs: {
                                          src: require("@/assets/image/guidance/play.png"),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.tourIntroductionInfo.isPlay,
                                            expression:
                                              "tourIntroductionInfo.isPlay",
                                          },
                                        ],
                                        staticClass: "icon-16",
                                        attrs: {
                                          src: require("@/assets/image/guidance/playing.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "span",
                                {
                                  staticClass: "no-content cur",
                                  on: { click: _vm.editInfoAction },
                                },
                                [_vm._v("输入导览介绍")]
                              ),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "text icon-18 cur",
                            attrs: {
                              src: require("@/assets/image/guidance/edit.png"),
                            },
                            on: { click: _vm.editInfoAction },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.loadEnd
                  ? [
                      _c("div", { staticClass: "items-tit" }, [
                        _vm._v(
                          "\n            共" +
                            _vm._s(_vm.cloneGuidanceList.length) +
                            "个导览点，总导览时长" +
                            _vm._s(_vm.totalTime) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.cloneGuidanceList.length,
                              expression: "!cloneGuidanceList.length",
                            },
                          ],
                          staticClass: "item flex relative mr-16",
                        },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("div", { staticClass: "center select" }, [
                            _c(
                              "span",
                              {
                                staticClass: "blue",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelectWork("", 0)
                                  },
                                },
                              },
                              [_vm._v("选择展品")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "blue",
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelectWork("", 1)
                                  },
                                },
                              },
                              [_vm._v("选择素材")]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-scrollbar",
                        {
                          ref: "scrollbarRef",
                          style:
                            "height: " +
                            (_vm.cloneGuidanceList.length ? "480px" : "384px") +
                            ";overflow-x:hidden;",
                          attrs: {
                            height: _vm.cloneGuidanceList.length
                              ? "480px"
                              : "384px",
                          },
                        },
                        [
                          _c(
                            "draggable",
                            {
                              attrs: {
                                handle: ".handle",
                                animation: "200",
                                "item-key": "id",
                              },
                              on: {
                                start: _vm.draggadbleStart,
                                end: _vm.draggableEnd,
                              },
                              model: {
                                value: _vm.cloneGuidanceList,
                                callback: function ($$v) {
                                  _vm.cloneGuidanceList = $$v
                                },
                                expression: "cloneGuidanceList",
                              },
                            },
                            _vm._l(
                              _vm.cloneGuidanceList,
                              function (element, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "handle-li",
                                      staticStyle: { padding: "0 16px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item flex relative",
                                          class: {
                                            "active-border":
                                              !element.link_id ||
                                              element.editIng,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "left-icon list-icon",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "handle",
                                                attrs: {
                                                  src: require("@/assets/image/guidance/list.png"),
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          element.linkItem &&
                                          element.linkItem.image
                                            ? _c(
                                                "div",
                                                { staticClass: "center" },
                                                [
                                                  _c("img", {
                                                    staticClass: "img",
                                                    attrs: {
                                                      src:
                                                        element.linkItem.image +
                                                        "!m640",
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "center border",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "icon-24",
                                                    attrs: {
                                                      src: require("@/assets/image/guidance/video.png"),
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "right" }, [
                                            _c(
                                              "div",
                                              { staticClass: "tit-btn flex" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "tit" },
                                                  [
                                                    element.linkItem
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fs-12",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                element.linkItem
                                                                  .name
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "content-text flex",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#fff",
                                                    },
                                                  },
                                                  [_vm._v("讲解：")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          element.timeLength,
                                                        expression:
                                                          "element.timeLength",
                                                      },
                                                    ],
                                                    staticClass: "w-120 flex",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onPlayText(
                                                          element.isPlay
                                                            ? 0
                                                            : 1,
                                                          element
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "time-play flex",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "icon-16 ml-4 mr-6",
                                                          attrs: {
                                                            src: require("@/assets/image/guidance/wifi.png"),
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {}, [
                                                          _vm._v(
                                                            _vm._s(
                                                              element.timeLength ||
                                                                "输入讲解内容"
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              element.explain_content_url,
                                                            expression:
                                                              "element.explain_content_url",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "flex flex-play",
                                                        attrs: { flex: "" },
                                                      },
                                                      [
                                                        _c("img", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                !element.isPlay,
                                                              expression:
                                                                "!element.isPlay",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "icon-16",
                                                          attrs: {
                                                            src: require("@/assets/image/guidance/play.png"),
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("img", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                element.isPlay,
                                                              expression:
                                                                "element.isPlay",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "icon-16",
                                                          attrs: {
                                                            src: require("@/assets/image/guidance/playing.png"),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                !element.timeLength
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "no-content cur",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editDesc(
                                                              element
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("输入讲解内容")]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticClass:
                                                    "text icon-18 cur",
                                                  attrs: {
                                                    src: require("@/assets/image/guidance/edit.png"),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editDesc(
                                                        element
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "tags" }, [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(index + 1) +
                                                "\n                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "change-btns" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "box-item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "插入导览点",
                                                    placement: "right",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "icon-18 cur",
                                                    attrs: {
                                                      src: require("@/assets/image/guidance/add.png"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addMaterial(
                                                          element
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "box-item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "删除",
                                                    placement: "right",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "icon-18 cur",
                                                    attrs: {
                                                      src: require("@/assets/image/guidance/del.png"),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.detMaterial(
                                                          element
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: element.isEdit,
                                              expression: "element.isEdit",
                                            },
                                          ],
                                          staticClass: "item flex relative",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "left-icon list-icon",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/image/guidance/list.png"),
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "center select" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "blue",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onSelectWork(
                                                        element,
                                                        0
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择展品")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "blue",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onSelectWork(
                                                        element,
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("选择素材")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.listTitleActive == 1 && !_vm.isSelectNumMerber,
                    expression: "listTitleActive == 1 && !isSelectNumMerber",
                  },
                ],
                staticClass: "voice-ul pd-16",
              },
              [
                _c(
                  "el-scrollbar",
                  { attrs: { height: "502px" } },
                  _vm._l(_vm.merberAiList, function (item) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass: "voice-item flex",
                        class: { "active-border": item.editIng },
                      },
                      [
                        _c("div", { staticClass: "item-left flex" }, [
                          _c("img", {
                            staticClass: "img",
                            attrs: { src: item.image, alt: "" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-name flex" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "set-name",
                                on: {
                                  click: function ($event) {
                                    return _vm.setMember(item)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "text line1" }, [
                                  _vm._v(
                                    "设置声音：" +
                                      _vm._s(
                                        _vm.curMerberInfo.digital_man_voice_name
                                          ? _vm.curMerberInfo
                                              .digital_man_voice_name
                                          : "请选择"
                                      )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "text icon-16",
                                  attrs: {
                                    src: require("@/assets/image/guidance/edit.png"),
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item-right" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.curMerberInfo &&
                                    _vm.curMerberInfo.digital_man_id &&
                                    item.id == _vm.curMerberInfo.digital_man_id,
                                  expression:
                                    "\n                  curMerberInfo && curMerberInfo.digital_man_id && item.id == curMerberInfo.digital_man_id\n                ",
                                },
                              ],
                              staticClass: "use-in",
                            },
                            [_vm._v("使用中")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.curMerberInfo ||
                                    !_vm.curMerberInfo.digital_man_id ||
                                    item.id != _vm.curMerberInfo.digital_man_id,
                                  expression:
                                    "\n                  !curMerberInfo || !curMerberInfo.digital_man_id ||\n                  item.id != curMerberInfo.digital_man_id\n                ",
                                },
                              ],
                              staticClass: "use-ta",
                              on: {
                                click: function ($event) {
                                  return _vm.useMember(1, item)
                                },
                              },
                            },
                            [_vm._v("使用TA")]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.listTitleActive == 1 && _vm.isSelectNumMerber,
                    expression: "listTitleActive == 1 && isSelectNumMerber",
                  },
                ],
                staticClass: "voice-ul pd-16",
              },
              [
                _c(
                  "el-scrollbar",
                  { attrs: { height: "502px" } },
                  _vm._l(_vm.merberToneList, function (item) {
                    return _c(
                      "li",
                      { key: item.id, staticClass: "voice-item flex" },
                      [
                        _c("div", { staticClass: "item-left flex" }, [
                          _c("div", { staticClass: "pdl-10" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item-right" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    item.id ==
                                    _vm.merberData.digital_man_voice_id,
                                  expression:
                                    "item.id == merberData.digital_man_voice_id",
                                },
                              ],
                              staticClass: "use-in",
                            },
                            [_vm._v("使用中")]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    item.id !=
                                    _vm.merberData.digital_man_voice_id,
                                  expression:
                                    "item.id != merberData.digital_man_voice_id",
                                },
                              ],
                              staticClass: "use-ta",
                              on: {
                                click: function ($event) {
                                  return _vm.useMember(2, item)
                                },
                              },
                            },
                            [_vm._v("使用TA")]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.listTitleActive == 0
              ? _c(
                  "div",
                  { staticClass: "list-btns" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onSave(_vm.listTitleActive)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-16 save-icon",
                          attrs: {
                            src: require("@/assets/image/guidance/confirm.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("保存")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "box-item",
                        attrs: {
                          effect: "dark",
                          content:
                            _vm.listTitleActive == 0
                              ? "点击清空所有导览路线"
                              : "点击返回",
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn clear",
                            on: {
                              click: function ($event) {
                                return _vm.clearGuidanceList(
                                  _vm.listTitleActive
                                )
                              },
                            },
                          },
                          [_vm._v("清空")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSelectWork,
              expression: "isSelectWork",
            },
          ],
          staticClass: "set-works",
        },
        [
          _c("titleActive", {
            attrs: {
              active: _vm.selectWorkActive,
              titleList: ["选择展品", "选择素材"],
            },
            on: {
              changeAction: function (data) {
                return (_vm.selectWorkActive = data)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "con-color" },
            [
              _c("setworks", {
                attrs: {
                  exhibitionType: _vm.exhibitionType,
                  materialList: _vm.materialList,
                  guidanceList: _vm.guidanceList,
                  isShow: _vm.isSelectWork,
                },
                on: { getAddWorkInfo: _vm.getAddWorkInfo },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list-btns pd-16" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.addWorkInfo },
                    },
                    [
                      _c("img", {
                        staticClass: "icon-16 save-icon",
                        attrs: {
                          src: require("@/assets/image/guidance/confirm.png"),
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("确认")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn clear",
                      on: { click: _vm.cancelAddWork },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSetDesc,
              expression: "isSetDesc",
            },
          ],
          staticClass: "content-guidance border-radius",
        },
        [
          _c("div", { staticClass: "content-description" }, [
            _c("div", { staticClass: "tit-color flex text-title" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.query.type == "tourIntroductionInfo"
                      ? "导览介绍"
                      : "讲解"
                  ) + "内容文本"
                ),
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "icon-16 cur",
                attrs: { src: require("@/assets/image/guidance/close.png") },
                on: { click: _vm.onCancelDesc },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content con-color" },
              [
                _c("div", { staticClass: "content-desc" }, [
                  _vm._v(
                    "\n          共" +
                      _vm._s(_vm.descLength) +
                      "字，" +
                      _vm._s(
                        _vm.query.type == "tourIntroductionInfo"
                          ? "语音导览介绍"
                          : "语音讲解"
                      ) +
                      "时长约" +
                      _vm._s(_vm.descTime) +
                      "秒\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "desc",
                  attrs: {
                    resize: "none",
                    rows: 10,
                    type: "textarea",
                    "input-style": { "background-color": "transparent" },
                    placeholder:
                      _vm.query.type == "tourIntroductionInfo"
                        ? "请输入导览介绍"
                        : "请输入讲解内容",
                  },
                  on: {
                    focus: function ($event) {
                      return _vm.inpAction(true)
                    },
                    blur: function ($event) {
                      return _vm.inpAction(false)
                    },
                  },
                  model: {
                    value: _vm.query.explain_content_text,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "explain_content_text", $$v)
                    },
                    expression: "query.explain_content_text",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content-btns" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "primary", loading: _vm.loading },
                        on: { click: _vm.onAddDesc },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-16 save-icon",
                          attrs: {
                            src: require("@/assets/image/guidance/confirm.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("保存")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn clear",
                        on: { click: _vm.onCancelDesc },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("audio", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.curPlayInfo.explain_content_url,
            expression: "curPlayInfo.explain_content_url",
          },
        ],
        ref: "audioRef",
        staticClass: "audio",
        attrs: { controls: "", src: _vm.curPlayInfo.explain_content_url },
      }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tit-btn flex" }, [
      _c("div", { staticClass: "tit" }, [
        _c("span", { staticClass: "fs-12" }, [_vm._v("导览介绍")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-icon list-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/image/guidance/list.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }