"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var marketingRouter = {
  path: "".concat(_settings.roterPre, "/marketing"),
  name: 'marketing',
  meta: {
    title: '营销'
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: 'noRedirect',
  children: [{
    path: 'coupon',
    name: 'Coupon',
    meta: {
      title: '优惠券',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'CouponList',
      meta: {
        title: '优惠劵列表',
        noCache: true,
        permissionKey: '/marketing/coupon/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponList/index'));
        });
      }
    }, {
      path: 'user',
      name: 'CouponUser',
      meta: {
        title: '领取记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list"),
        noCache: true,
        permissionKey: '/marketing/coupon/user'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponUser/index'));
        });
      }
    }, {
      path: 'send',
      name: 'CouponSend',
      meta: {
        title: '优惠券发送记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list"),
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponSend/index'));
        });
      }
    }, {
      path: 'use',
      name: 'CouponUse',
      meta: {
        title: '使用记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list"),
        noCache: true,
        permissionKey: '/marketing/coupon/use'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponUse/index'));
        });
      }
    }]
  }, {
    path: 'coupon/creatCoupon',
    name: 'CreatCoupon',
    meta: {
      title: '添加优惠劵',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/coupon/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/coupon/couponList/creatCoupon'));
      });
    }
  }, {
    path: 'studio',
    name: 'Studio',
    meta: {
      title: '直播间',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/studio/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'StudioList',
      meta: {
        title: '直播间列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/studio/studioList/index'));
        });
      }
    }, {
      path: 'creatStudio',
      name: 'CreatStudio',
      meta: {
        title: '创建直播间',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/studio/studioList/creatStudio'));
        });
      }
    }]
  }, {
    path: 'broadcast',
    name: 'Broadcast',
    meta: {
      title: '直播',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/broadcast/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'BroadcastList',
      meta: {
        title: '直播商品列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/broadcast/broadcastList/index'));
        });
      }
    }, {
      path: 'addProduct',
      name: 'BroadcastProduct',
      meta: {
        title: '创建直播商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/broadcast/broadcastList/addProduct'));
        });
      }
    }]
  }, {
    path: 'seckill',
    name: 'Seckill',
    meta: {
      title: '秒杀管理',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/seckill/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'SpikeGoods',
      meta: {
        title: '秒杀商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/seckill/seckillGoods/index'));
        });
      }
    }, {
      path: 'createGoods/:id?/:edit?',
      name: 'CreateSpikeGoods',
      meta: {
        title: '添加秒杀商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/seckill/seckillGoods/createGoods'));
        });
      }
    }]
  }, {
    path: 'newuser',
    name: 'newuser',
    meta: {
      title: '新人专享',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/newuser/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'newuserGoods',
      meta: {
        title: '新人专享',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/newuser/newuserGoods/index'));
        });
      }
    }, {
      path: 'createGoods/:id?/:edit?',
      name: 'newuserSpikeGoods',
      meta: {
        title: '添加新人专享商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/newuser/newuserGoods/createGoods'));
        });
      }
    }]
  }, {
    path: 'presell',
    name: 'preSell',
    meta: {
      title: '预售商品管理',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/seckill/index'));
      });
    },
    children: [{
      path: 'list',
      name: "preSaleList",
      meta: {
        title: '预售商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/preSale/index'));
        });
      }
    }, {
      path: 'create/:id?/:edit?',
      name: "preSaleCreate",
      meta: {
        title: '添加预售商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/preSale/create'));
        });
      }
    }]
  }, {
    path: 'assist',
    name: 'assist',
    meta: {
      title: '助力活动商品',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/seckill/index'));
      });
    },
    children: [{
      path: 'list',
      name: "assistProductList",
      meta: {
        title: '助力活动商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/assist/assist_list/index'));
        });
      }
    }, {
      path: 'create/:id?/:edit?',
      name: "assistProductCreate",
      meta: {
        title: '添加助力活动商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/assist/assist_list/create'));
        });
      }
    }, {
      path: 'assist_set',
      name: "assistSet",
      meta: {
        title: '助力活动列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/assist/assist_activity/index'));
        });
      }
    }]
  }, {
    path: 'combination',
    name: 'combinAtion',
    meta: {
      title: '拼团管理',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/combination/index'));
      });
    },
    children: [{
      path: 'combination_goods',
      name: "combinationGoods",
      meta: {
        title: '拼团商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/combination/combination_goods/index'));
        });
      }
    }, {
      path: 'combination_list',
      name: "combinationLoods",
      meta: {
        title: '拼团列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/combination/store_combination/index'));
        });
      }
    }, {
      path: 'create/:id?/:edit?',
      name: "combinationCreate",
      meta: {
        title: '添加拼团商品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/combination/combination_goods/create'));
        });
      }
    }]
  }, {
    path: 'fanFission',
    name: 'fanFission',
    meta: {
      title: '粉丝裂变活动',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/fanFission/index'));
      });
    },
    children: [{
      path: 'list',
      name: "fanFissionList",
      meta: {
        title: '粉丝裂变活动',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/help/list"),
        permissionKey: '/marketing/fanFission/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/fanFission/list'));
        });
      }
    }, {
      path: 'detail',
      name: "fanFissionDetail",
      meta: {
        title: '粉丝裂变详情',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/help/list"),
        permissionKey: '/marketing/fanFission/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/fanFission/detail'));
        });
      }
    }]
  }, {
    path: 'help',
    name: 'helpIndex',
    meta: {
      title: '助力有奖',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/help/index'));
      });
    },
    children: [{
      path: 'list',
      name: "helpList",
      meta: {
        title: '助力有奖活动',
        noCache: true,
        permissionKey: '/marketing/help/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/help/list'));
        });
      }
    }, {
      path: 'detail',
      name: "helpDetail",
      meta: {
        title: '助力有奖详情',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/help/list"),
        permissionKey: '/marketing/help/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/help/detail'));
        });
      }
    }, {
      path: 'ranking',
      name: "helpRanking",
      meta: {
        title: '助力有奖排行',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/help/list"),
        permissionKey: '/marketing/help/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/help/ranking'));
        });
      }
    }]
  }, {
    path: 'compose',
    name: 'compose',
    meta: {
      title: '合成活动',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/index'));
      });
    },
    children: [{
      path: 'list',
      name: "composeList",
      meta: {
        title: '合成活动',
        noCache: true,
        permissionKey: '/marketing/compose/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/composeList'));
        });
      }
    }, {
      path: 'recordlist',
      name: "composeRecordList",
      meta: {
        title: '合成活动记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/compose/list"),
        permissionKey: '/marketing/compose/recordlist'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/composeRecord'));
        });
      }
    }]
  }, {
    path: 'compose/create',
    name: "composeCreate",
    meta: {
      title: '合成活动详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/compose/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/composeAdd'));
      });
    }
  }, {
    path: 'compose/createnew',
    name: 'composeCreateNew',
    meta: {
      title: '合成活动详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/compose/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/compose/composeAdd/addnew'));
      });
    }
  }, {
    path: 'sms_task',
    name: 'massTexting',
    meta: {
      title: '群发推送',
      noCache: true
    },
    redirect: "".concat(_settings.roterPre, "/marketing/sms_task/subscri"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/all'));
      });
    },
    children: [{
      path: 'subscri',
      name: 'subscri',
      meta: {
        title: '订阅通知',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task"),
        permissionKey: '/marketing/sms_task/subscri'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/subscri/index'));
        });
      }
    }, {
      path: 'spread',
      name: 'spread',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/spread'));
        });
      },
      meta: {
        title: '公众号群发',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task"),
        permissionKey: '/marketing/sms_task/spread'
      }
    }, {
      path: 'appMass',
      name: 'sprappMassead',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/app/index'));
        });
      },
      meta: {
        title: 'APP群发',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task"),
        permissionKey: '/marketing/sms_task/appMass'
      }
    }, {
      path: 'sms',
      name: 'smsSend',
      meta: {
        title: '短信群发',
        noCache: true
      },
      redirect: "".concat(_settings.roterPre, "/marketing/sms_task/sms/message"),
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/smsSend'));
        });
      },
      children: [{
        path: 'message',
        name: 'massTexting',
        meta: {
          title: '群发短信',
          noCache: true,
          activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task"),
          permissionKey: '/marketing/sms_task/sms/message'
        },
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/index'));
          });
        }
      }, {
        path: 'sms_template',
        name: 'mass_sms_template',
        meta: {
          title: '短信内容模板',
          noCache: true,
          activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task"),
          permissionKey: '/marketing/sms_task/sms/message'
        },
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/sms_template'));
          });
        }
      }, {
        path: 'sms_sign',
        name: 'mass_sms_sign',
        meta: {
          title: '短信签名',
          noCache: true,
          activeMenu: "".concat(_settings.roterPre, "/marketing/sms_task"),
          permissionKey: '/marketing/sms_task/sms/message'
        },
        component: function component() {
          return Promise.resolve().then(function () {
            return (0, _interopRequireWildcard2.default)(require('@/views/marketing/massTexting/sms_sign'));
          });
        }
      }]
    }]
  }, {
    path: 'secondhandMarket',
    name: 'secondhandMarket',
    meta: {
      title: '寄售',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/secondhandMarket/index'));
      });
    },
    children: [{
      path: 'list',
      name: "secondhandMarketList",
      meta: {
        title: '寄售 - 藏品列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/secondhandMarket/list'));
        });
      }
    }, {
      path: 'transaction',
      name: "secondhandMarketTransaction",
      meta: {
        title: '寄售 - 交易记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/secondhandMarket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/secondhandMarket/transaction'));
        });
      }
    }, {
      path: 'box_transaction',
      name: "secondhandMarketBoxTransaction",
      meta: {
        title: '寄售 - 盲盒 - 交易记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/secondhandMarket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/secondhandMarket/box_transaction'));
        });
      }
    }, {
      path: 'album_list',
      name: "secondhandMarketAlbumList",
      meta: {
        title: '寄售 - 系列列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/secondhandMarket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/secondhandMarket/albumList'));
        });
      }
    }, {
      path: 'blind_box',
      name: "secondhandMarketblindBox",
      meta: {
        title: '寄售 - 盲盒列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/secondhandMarket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/secondhandMarket/blindBox'));
        });
      }
    }, {
      path: 'rule',
      name: "secondhandMarketRule",
      meta: {
        title: '交易设置',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/secondhandMarket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/secondhandMarket/rule'));
        });
      }
    }]
  }, {
    path: 'retail',
    name: 'retail',
    meta: {
      title: '分销员',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/retail/index'));
      });
    },
    children: [{
      path: 'list',
      name: "retailList",
      meta: {
        title: '分销员',
        noCache: true,
        permissionKey: '/marketing/retail/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/retail/list'));
        });
      }
    }]
  }, {
    path: 'integral',
    name: 'Integral',
    meta: {
      title: '积分',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/index'));
      });
    },
    children: [{
      path: 'survey',
      name: 'IntegralSurvey',
      meta: {
        title: '积分概况',
        noCache: true,
        permissionKey: '/marketing/integral/survey'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/survey'));
        });
      }
    }, {
      path: 'detailed',
      name: 'IntegralDetailed',
      meta: {
        title: '积分明细',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true,
        permissionKey: '/marketing/integral/detailed'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/detailed'));
        });
      }
    }, {
      path: 'receiving',
      name: 'IntegralReceiving',
      meta: {
        title: '发放记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true,
        permissionKey: '/marketing/integral/receiving'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/receiving'));
        });
      }
    }, {
      path: 'use',
      name: 'IntegralUse',
      meta: {
        title: '使用记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true,
        permissionKey: '/marketing/integral/use'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/use'));
        });
      }
    }, {
      path: 'ranking',
      name: 'IntegralRanking',
      meta: {
        title: '积分排行',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true,
        permissionKey: '/marketing/integral/ranking'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/ranking'));
        });
      }
    }, {
      path: 'exchangeGoodsList',
      name: 'jfexchangeGoodsList',
      meta: {
        title: '兑换商城',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true,
        permissionKey: '/marketing/integral/exchangeGoodsList'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/exchangeGoodsList'));
        });
      }
    }, {
      path: 'exchangeGoodsHistory',
      name: 'jfexchangeGoodsHistory',
      meta: {
        title: '兑换记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true,
        permissionKey: '/marketing/integral/exchangeGoodsHistory'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/exchangeGoodsHistory'));
        });
      }
    }, {
      path: 'sign',
      name: 'sign',
      meta: {
        title: '积分签到',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true,
        permissionKey: '/marketing/integral/sign'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/sign'));
        });
      }
    }, {
      path: 'signresault',
      name: 'signresault',
      meta: {
        title: '签到记录',
        activeMenu: "".concat(_settings.roterPre, "/marketing/integral/survey"),
        noCache: true,
        permissionKey: '/marketing/integral/signresault'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/signresault'));
        });
      }
    }]
  }, {
    path: 'integralNFT',
    name: 'integralNFT',
    meta: {
      title: '积分',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integralNFTManage/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'integralNFTList',
      meta: {
        title: '积分列表'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integralNFTManage/nftlist'));
        });
      }
    }, {
      path: 'config',
      name: 'integralNFTConfig',
      meta: {
        title: '积分配置'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integralNFTManage/config'));
        });
      }
    }]
  }, {
    path: 'invite',
    name: 'invite',
    meta: {
      title: '邀请',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/invite/index'));
      });
    },
    children: [{
      path: 'list',
      name: "inviteList",
      meta: {
        title: '邀请',
        noCache: true,
        permissionKey: '/marketing/invite/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/invite/list'));
        });
      }
    }, {
      path: 'setting',
      name: "inviteSetting",
      meta: {
        title: '邀请设置',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/invite/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/invite/setting'));
        });
      }
    }]
  }, {
    path: 'playgame',
    name: "playGame",
    meta: {
      title: '战机挖矿',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/index'));
      });
    },
    children: [{
      path: 'planelist',
      name: 'planelist',
      meta: {
        title: '战机列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/planeList'));
        });
      }
    }, {
      path: 'exchangeGoodsList',
      name: 'exchangeGoodsList',
      meta: {
        title: '兑换商城',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/exchangeGoodsList'));
        });
      }
    }, {
      path: 'exchangeGoodsHistory',
      name: 'exchangeGoodsHistory',
      meta: {
        title: '兑换记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/exchangeGoodsHistory'));
        });
      }
    }]
  }, {
    path: 'planedetail',
    name: 'planedetail',
    meta: {
      title: '创建战机',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/planedetail'));
      });
    }
  }, {
    path: 'userplane',
    name: 'userplane',
    meta: {
      title: '用户持有情况',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/userPlane'));
      });
    }
  }, {
    path: 'exchangeGoodsAdd',
    name: 'exchangeGoodsAdd',
    meta: {
      title: '创建兑换商品',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgame/exchangeGoodsAdd'));
      });
    }
  }, {
    path: 'playgametd',
    name: 'playgametd',
    meta: {
      title: '塔防',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/index'));
      });
    },
    children: [{
      path: 'tdconfig',
      name: 'tdconfig',
      meta: {
        title: '塔防配置',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/tdConfig'));
        });
      }
    }, {
      path: 'canusecollection',
      name: 'canusecollection',
      meta: {
        title: '可投放藏品',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/canUsecollection'));
        });
      }
    }, {
      path: 'attackdata',
      name: 'attackdata',
      meta: {
        title: '进攻数据',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/attackData'));
        });
      }
    }, {
      path: 'exchangeGoodsList',
      name: 'exchangeGoodsList',
      meta: {
        title: '兑换商城',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/exchangeGoodsList'));
        });
      }
    }, {
      path: 'exchangeGoodsHistory',
      name: 'exchangeGoodsHistory',
      meta: {
        title: '兑换记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/exchangeGoodsHistory'));
        });
      }
    }, {
      path: 'rank',
      name: 'rank',
      meta: {
        title: '保卫积分排行',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/rank'));
        });
      }
    }]
  }, {
    path: 'tdexchangeGoodsAdd',
    name: 'tdexchangeGoodsAdd',
    meta: {
      title: '创建兑换商品',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/exchangeGoodsAdd'));
      });
    }
  }, {
    path: 'jfexchangeGoodsAdd',
    name: 'jfexchangeGoodsAdd',
    meta: {
      title: '创建兑换商品',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/integral/exchangeGoodsAdd'));
      });
    }
  }, {
    path: 'attackDetail',
    name: 'attackDetail',
    meta: {
      title: '进攻数据',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/playgameTD/attackDetail'));
      });
    }
  }, {
    path: 'conversionCode',
    name: 'conversionCode',
    meta: {
      title: '兑换码',
      noCache: true,
      permissionKey: '/marketing/conversionCode'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/conversionCode/index'));
      });
    }
  }, {
    path: 'conversionCode/conversionCodeAdd',
    name: 'conversionCodeAdd',
    meta: {
      title: '新增兑换码',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/conversionCode/index"),
      permissionKey: '/marketing/conversionCode'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/conversionCode/codeVersion/index'));
      });
    }
  }, {
    path: 'draw',
    name: "draw",
    meta: {
      title: '抽奖',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/draw/index'));
      });
    },
    children: [{
      path: 'activelist',
      name: 'activelist',
      meta: {
        title: '抽奖活动',
        noCache: true,
        permissionKey: '/marketing/draw/activelist'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/draw/activelist'));
        });
      }
    }, {
      path: 'activerecords',
      name: 'activerecords',
      meta: {
        title: '参与记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/marketing/draw/activelist"),
        permissionKey: '/marketing/draw/activerecords'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/draw/activerecords'));
        });
      }
    }]
  }, {
    path: 'drawcreate',
    name: 'drawcreate',
    meta: {
      title: '创建抽奖',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/draw/activecreate'));
      });
    }
  }, {
    path: 'furnace',
    name: 'furnace',
    meta: {
      title: '熔炉',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/furnace/index'));
      });
    },
    children: [{
      path: 'rule',
      name: 'furnacerule',
      meta: {
        title: '熔炉规则',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/furnace/furnacerule'));
        });
      }
    }, {
      path: 'record',
      name: 'furnacerecord',
      meta: {
        title: '熔炉记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/furnace/furnacerecord'));
        });
      }
    }, {
      path: 'config',
      name: 'furnaceconfig',
      meta: {
        title: '熔炉配置',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/marketing/furnace/furnaceconfig'));
        });
      }
    }]
  }, {
    path: 'furnacecreate',
    name: 'furnacecreate',
    meta: {
      title: '添加规则',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/furnace/furnacecreate'));
      });
    }
  }, {
    path: 'invitation/list',
    name: 'InvitationList',
    meta: {
      title: '邀请函管理',
      noCache: true,
      permissionKey: '/marketing/invitation/list'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/invitation/list'));
      });
    }
  }, {
    path: 'invitation/detail',
    name: 'InvitationDetail',
    meta: {
      title: '邀请函详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/marketing/invitation/list"),
      permissionKey: '/marketing/invitation/list'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/invitation/detail'));
      });
    }
  }, {
    path: 'trend/list',
    name: 'TrendList',
    meta: {
      title: '大盘管理',
      noCache: true,
      permissionKey: '/marketing/trend/list'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/trend/list'));
      });
    }
  }, {
    path: 'trend/detail',
    name: 'TrendDetail',
    meta: {
      title: '产品详情',
      activeMenu: "".concat(_settings.roterPre, "/marketing/trend/list"),
      noCache: true,
      permissionKey: '/marketing/trend/list'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/marketing/trend/detail'));
      });
    }
  }]
};
var _default = exports.default = marketingRouter;