"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var liveRouter = {
  path: "".concat(_settings.roterPre, "/live"),
  name: 'Live',
  meta: {
    icon: 'dashboard',
    title: '直播'
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: 'noRedirect',
  children: [{
    path: 'manage',
    name: 'LiveManage',
    meta: {
      title: '直播管理'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/live/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'LiveList',
      meta: {
        title: '直播管理',
        activeMenu: "".concat(_settings.roterPre, "/live/manage/list"),
        permissionKey: '/live/manage/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/live/list'));
        });
      }
    }, {
      path: 'category',
      name: 'LiveCategory',
      meta: {
        title: '直播分类管理',
        activeMenu: "".concat(_settings.roterPre, "/live/list"),
        permissionKey: '/live/manage/category'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/live/category'));
        });
      }
    }]
  }, {
    path: 'list',
    name: 'LiveList',
    meta: {
      title: '直播管理'
    },
    redirect: "".concat(_settings.roterPre, "/live/manage/list"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/live/list'));
      });
    }
  }, {
    path: 'detail',
    name: 'LiveDetail',
    meta: {
      title: '直播详情',
      activeMenu: "".concat(_settings.roterPre, "/live/list"),
      permissionKey: '/live/manage/list'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/live/liveDetail'));
      });
    }
  }]
};
var _default = exports.default = liveRouter;