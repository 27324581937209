"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _auction = require("@/api/auction");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../../views/user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      isProduct: false,
      albumVisitData: {
        show: false,
        uv: 0,
        num: 0,
        list: []
      },
      otherTotal: 0,
      query: {
        page: 1,
        limit: 10
      },
      uid: '',
      showdetail: false
    };
  },
  methods: {
    init: function init(id) {
      this.otherTotal = 0;
      this.albumVisitData.show = true;
      this.albumVisitData.list = [];
      this.query = {
        page: 1,
        limit: 10,
        auction_id: id
      };
      this.load();
    },
    load: function load() {
      var _this = this;
      (0, _auction.auctionBidRecordDataApi)(this.query).then(function (res) {
        res.data.list.forEach(function (m, i) {
          if (i === 0 && _this.query.page === 1) {
            m.lx = 1;
          } else {
            m.lx = 0;
          }
        });
        _this.albumVisitData.list = res.data.list;
        _this.otherTotal = res.data.count;
      }).catch(function () {});
    },
    pageOtherChange: function pageOtherChange(page) {
      this.query.page = page;
      this.load();
    },
    // 详情
    onDetails: function onDetails(uid) {
      if (uid === 8888) {
        return false;
      }
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    delAction: function delAction(id) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u51FA\u4EF7?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.delBidPrice)({
          bid_id: id,
          auction_id: _this2.query.auction_id
        }).then(function () {
          _this2.$message.success('删除成功');
          _this2.$emit('load');
          _this2.load();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};