var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.memberStatistical
        ? _c("div", { staticClass: "top-data flex" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatPriceNumber(
                      _vm.memberStatistical.totalUserMemberCount,
                      2
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("会员人数")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "t" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatPriceNumber(
                      _vm.memberStatistical.totalOrderPayPrice || 0,
                      2
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("套餐销售总金额")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                {
                  staticClass: "t cur",
                  on: {
                    click: function ($event) {
                      return _vm.goRecordAction(1)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.formatPriceNumber(
                        _vm.memberStatistical.aboutToExpireMembershipCardCount,
                        2
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("套餐即将过期")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c(
                "div",
                {
                  staticClass: "t cur",
                  on: {
                    click: function ($event) {
                      return _vm.goRecordAction(2)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.formatPriceNumber(
                        _vm.memberStatistical.expiredMembershipCardCount,
                        2
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "b" }, [_vm._v("已过期")]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "echarts-data" }, [
        _c("div", { staticClass: "l" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [_vm._v("会员卡购买")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart1")
                        },
                      },
                      model: {
                        value: _vm.echart1.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart1, "type", $$v)
                        },
                        expression: "echart1.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "priceData" } }, [
                        _vm._v("金额"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "againCount" } },
                        [_vm._v("续费")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart1.noData
              ? _c(
                  "div",
                  { staticClass: "d-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart1", staticClass: "d" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [
          _c("div", { staticClass: "item" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.echart2.noData
              ? _c(
                  "div",
                  { staticClass: "d-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart2", staticClass: "d" }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "echarts-data" }, [
        _c("div", { staticClass: "l flex-one" }, [
          _c("div", { staticClass: "item item1" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [_vm._v("性别分布")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart3")
                        },
                      },
                      model: {
                        value: _vm.echart3.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart3, "type", $$v)
                        },
                        expression: "echart3.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "priceData" } }, [
                        _vm._v("金额"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart3.noData
              ? _c(
                  "div",
                  { staticClass: "d1-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart3", staticClass: "d1" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r flex-one" }, [
          _c("div", { staticClass: "item item1" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [_vm._v("年龄分布")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart4")
                        },
                      },
                      model: {
                        value: _vm.echart4.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart4, "type", $$v)
                        },
                        expression: "echart4.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "priceData" } }, [
                        _vm._v("金额"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart4.noData
              ? _c(
                  "div",
                  { staticClass: "d1-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart4", staticClass: "d1" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r flex-one" }, [
          _c("div", { staticClass: "item item1" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [_vm._v("注册渠道")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart5")
                        },
                      },
                      model: {
                        value: _vm.echart5.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart5, "type", $$v)
                        },
                        expression: "echart5.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "priceData" } }, [
                        _vm._v("金额"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart5.noData
              ? _c(
                  "div",
                  { staticClass: "d1-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart5", staticClass: "d1" }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ranking" }, [
        _c("div", { staticClass: "l" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(1, "会员卡销量排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("会员卡销售排行"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.saleList, type: "1", page: 1 },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(2, "会员卡购买金额排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("会员卡购买金额排行"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.salePriceList, type: "2", page: 1 },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(3, "成长值排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("成长值排行"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.levelList, type: "3", page: 1 },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("dataDrawerTemplate", { ref: "dataDrawerTemplateEL" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title flex align-items-c" }, [
      _c("div", { staticClass: "flex-one lt" }, [_vm._v("会员分布")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }