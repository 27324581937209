var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.getList },
                  model: {
                    value: _vm.tableFrom.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "type", $$v)
                    },
                    expression: "tableFrom.type",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: {
                      name: item.type.toString(),
                      label: item.name + "(" + item.count + ")",
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "秒杀状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: { change: _vm.getList },
                              model: {
                                value: _vm.tableFrom.seckill_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "seckill_status", $$v)
                                },
                                expression: "tableFrom.seckill_status",
                              },
                            },
                            _vm._l(_vm.seckillStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关键字搜索：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入商品名称，关键字，产品编号",
                              },
                              model: {
                                value: _vm.tableFrom.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keyword", $$v)
                                },
                                expression: "tableFrom.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.getList },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path:
                        "" + _vm.roterPre + "/marketing/seckill/createGoods",
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [
                      _c("i", { staticClass: "add" }, [_vm._v("+")]),
                      _vm._v(" 添加秒杀商品\n        "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand demo-table-expand1",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "平台分类：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.storeCategory
                                        ? props.row.storeCategory.cate_name
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品分类：" } },
                              [
                                props.row.merCateId.length
                                  ? _vm._l(
                                      props.row.merCateId,
                                      function (item, index) {
                                        return _c(
                                          "span",
                                          { key: index, staticClass: "mr10" },
                                          [
                                            _vm._v(
                                              _vm._s(item.category.cate_name)
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  : _c("span", [_vm._v("-")]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "品牌：" } }, [
                              _c("span", { staticClass: "mr10" }, [
                                _vm._v(
                                  _vm._s(
                                    props.row.brand
                                      ? props.row.brand.brand_name
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "市场价格：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.ot_price)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "成本价：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.cost)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "收藏：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.care_count)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.tableFrom.type === "7"
                              ? _c(
                                  "el-form-item",
                                  {
                                    key: "1",
                                    attrs: { label: "未通过原因：" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("filterEmpty")(
                                            props.row.care_count
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "product_id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "活动标题",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_info",
                  label: "商品简介",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "秒杀价", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "限量剩余", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.stock - scope.row.sales)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "秒杀状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("seckillStatusFilter")(
                                scope.row.seckill_status
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "秒杀活动日期", "min-width": "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "开始日期：" +
                              _vm._s(
                                scope.row.seckillActive &&
                                  scope.row.seckillActive.start_day
                                  ? scope.row.seckillActive.start_day.slice(
                                      0,
                                      10
                                    )
                                  : ""
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "结束日期：" +
                              _vm._s(
                                scope.row.seckillActive &&
                                  scope.row.seckillActive.end_day
                                  ? scope.row.seckillActive.end_day.slice(0, 10)
                                  : ""
                              )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "秒杀活动时间", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "开始时间：" +
                              _vm._s(
                                scope.row.seckillActive &&
                                  scope.row.seckillActive.start_time
                                  ? scope.row.seckillActive.start_time + ":00"
                                  : ""
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "结束时间：" +
                              _vm._s(
                                scope.row.seckillActive &&
                                  scope.row.seckillActive.end_time
                                  ? scope.row.seckillActive.end_time + ":00"
                                  : ""
                              )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "上架",
                            "inactive-text": "下架",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.is_show,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "is_show", $$v)
                            },
                            expression: "scope.row.is_show",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("seckillReviewStatusFilter")(
                                scope.row.status
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.status == -1 || scope.row.status == -2
                          ? _c(
                              "span",
                              { staticStyle: { "font-size": "12px" } },
                              [
                                _c("br"),
                                _vm._v(
                                  "\n            原因：" +
                                    _vm._s(scope.row.refusal) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  _vm.roterPre +
                                  "/marketing/seckill/createGoods/" +
                                  scope.row.product_id,
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "commonedit" },
                                }),
                                _vm._v("编辑"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.type === "5"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRestore(
                                      scope.row.product_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("恢复商品")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type !== "1" &&
                        _vm.tableFrom.type !== "3" &&
                        _vm.tableFrom.type !== "4"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.product_id,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.tableFrom.type === "5"
                                      ? "删除"
                                      : "加入回收站"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }