"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.match");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _selectCategory = _interopRequireDefault(require("@/components/customChildren/selectCategory.vue"));
var _system = require("@/api/system");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default,
    selectCategory: _selectCategory.default
  },
  data: function data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: 'content',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: 'facade',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    confirmUrlAction: function confirmUrlAction() {
      this.readTxtFile('/test.txt');
    },
    decodeHTMLEntities: function decodeHTMLEntities(str) {
      var tempDiv = document.createElement('div');
      tempDiv.innerHTML = str;
      return tempDiv.textContent || tempDiv.innerText;
    },
    readTxtFile: function readTxtFile(url) {
      var _this = this;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'text';
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          var txtContent = xhr.responseText;
          // console.log(txtContent);
          var pattern = /<mp-common-videosnap[\s\S]*?<\/mp-common-videosnap>/g;
          var matches = txtContent.match(pattern);
          if (matches) {
            matches.forEach(function (match) {
              // 去除转义符
              var cleanedContent = match.replace(/\\/g, '');
              var tempDiv = document.createElement('div');
              tempDiv.innerHTML = cleanedContent;
              var element = tempDiv.firstElementChild;
              // 获取元素的所有属性
              var attributes = element.attributes;

              // 创建一个对象来存储属性键值对
              var attributesObject = {};
              // 遍历所有属性并添加到对象中
              for (var i = 0; i < attributes.length; i++) {
                var attr = attributes[i];
                attributesObject[attr.name] = attr.value;
              }
              attributesObject['data-url'] = _this.decodeHTMLEntities(attributesObject['data-url']);
              _this.content.data = Object.assign({}, attributesObject);
            });
          }
          // 在这里可以对txt文件内容进行处理
        }
      };
      xhr.send();
    },
    /**
     * 删除元素
     * @return {[type]} [description]
     */
    handleDelete: function handleDelete(index) {
      this.content.data.splice(index, 1);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      if (this.content.data.length < 20) {
        this.content.data.push({
          title: '文字',
          url: '',
          link: {}
        });
      } else {
        this.$message({
          message: '最多添加20条内容',
          type: 'warning'
        });
      }
    },
    goUp: function goUp(index) {
      if (index === 0) {
        return;
      }
      this.content.data.splice(index - 1, 0, this.content.data[index]);
      this.content.data.splice(index + 1, 1);
    },
    goDown: function goDown(index) {
      if (index === this.content.data - 1) {
        return;
      }
      this.content.data.splice(index + 2, 0, this.content.data[index]);
      this.content.data.splice(index, 1);
    },
    uploadIconAction: function uploadIconAction(i) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.content.data[i].url = img[0];
      }, 1);
    },
    delImg: function delImg(i) {
      this.content.data[i].url = '';
    },
    setCategory: function setCategory(i) {
      this.saveIndex = i;
      this.$refs.setCategoryView.changeshow(this.content.data[i].link);
    },
    selectMessage: function selectMessage(data) {
      this.content.data[this.saveIndex].link = data.data;
    }
  }
};