"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      str: '',
      name: '测试合同'
    };
  },
  created: function created() {},
  methods: {
    getstr: function getstr() {
      var str = '<!DOCTYPE html>' + '<html>' + '<head><meta charset="utf-8"><title>';
      str += this.name + '</title></head>';
      str += '<body>';
      str += '<p>甲方：（盖章） 乙方：（盖章）<p>';
      str += '</body></html>';
    }
  }
};