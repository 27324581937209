"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      brandName: ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadBrand();
      }
      this.showSelectAuction = true;
    },
    searchBrandAction: function searchBrandAction() {
      this.queryParams.brand_name = this.brandName;
      this.queryParams.page = 1;
      this.loadBrand();
    },
    loadBrand: function loadBrand(fun) {
      var _this = this;
      var params = this.queryParams;
      (0, _product.brandListApi)(params).then(function (res) {
        _this.total = res.data.count;
        _this.resultData = res.data.list;
      }).catch(function () {});
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        image: info.pic,
        name: info.brand_name,
        id: info.brand_id,
        brand_id: info.brand_id,
        tags: [],
        price: '',
        sign: ''
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.brand_id;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'brand',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    changePageSize: function changePageSize(limit) {
      this.queryParams.limit = limit;
      this.queryParams.page = 1;
      this.loadBrand();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.loadBrand();
    }
  }
};