var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.val && _vm.val.length > 0
        ? _vm._l(_vm.val, function (m, i) {
            return _c(
              "div",
              { key: "val" + i, staticClass: "line flex align-items-c" },
              [
                _c(
                  "div",
                  {
                    staticClass: "num",
                    class: { h: (_vm.page - 1) * 20 + (i + 1) < 4 },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.formatDateNumber((_vm.page - 1) * 20 + (i + 1))
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "img" }, [
                  _vm.type <= 3
                    ? _c("img", { attrs: { src: m.product.image + "!120a" } })
                    : _c("img", {
                        staticClass: "avatar",
                        attrs: { src: m.user.avatar },
                      }),
                ]),
                _vm._v(" "),
                _vm.type <= 3
                  ? _c(
                      "div",
                      { staticClass: "name flex-one" },
                      [
                        [
                          _vm._v(
                            "LOT" +
                              _vm._s(m.lot) +
                              " " +
                              _vm._s(m.product.store_name)
                          ),
                        ],
                      ],
                      2
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "name flex-one",
                        staticStyle: { color: "#6699dd", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(m.user.uid)
                          },
                        },
                      },
                      [
                        [
                          _vm._v(
                            _vm._s(m.user.real_name || m.user.nickname) + " "
                          ),
                          m.number
                            ? _c("span", [
                                _vm._v("号牌 " + _vm._s(m.number.number)),
                              ])
                            : _vm._e(),
                        ],
                      ],
                      2
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "price" },
                  [
                    _vm.type == 1
                      ? [_vm._v(_vm._s(m.product.browse) + "次")]
                      : _vm.type == 2
                      ? [_vm._v(_vm._s(m.bid_num) + "次")]
                      : _vm.type == 3
                      ? [
                          _vm._v(
                            "￥" + _vm._s(_vm.formatPriceNumber(m.now_price, 2))
                          ),
                        ]
                      : _vm.type == 4
                      ? [
                          _vm._v(
                            "￥" +
                              _vm._s(_vm.formatPriceNumber(m.deal_price, 2))
                          ),
                        ]
                      : _vm.type == 5
                      ? [_vm._v(_vm._s(m.deal_num) + "件")]
                      : _vm.type == 6
                      ? [_vm._v(_vm._s(m.bid_num) + "次")]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }