"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "GoodsList",
  data: function data() {
    return {
      dialogVisible: false,
      templateRadio: 0,
      merCateList: [],
      roterPre: _settings.roterPre,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        cate_id: "",
        store_name: "",
        keyword: "",
        is_gift_bag: 0,
        status: 1
      },
      multipleSelection: {},
      checked: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    this.getCategorySelect();
    window.addEventListener("unload", function (e) {
      return _this.unloadHandler(e);
    });
  },
  methods: {
    getTemplateRow: function getTemplateRow(row) {
      this.multipleSelection = {
        src: row.image,
        id: row.product_id
      };
      this.dialogVisible = false;
      this.$emit("getProduct", this.multipleSelection);
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this2.merCateList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        // this.checked =
        //   window.form_create_helper.get(this.$route.query.field) || [];
        // this.tableData.data.forEach((item) => {
        //   this.checked.forEach((element) => {
        //     if (Number(item.product_id) === Number(element.id)) {
        //       this.$nextTick(() => {
        //         this.$refs.multipleTable.toggleRowSelection(item, true);
        //       });
        //     }
        //   });
        // });
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};