var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex align-items-c",
      style: {
        backgroundColor: _vm.facade.item_background,
        margin:
          _vm.facade.item_margin_top / 2 +
          "px " +
          _vm.facade.item_margin_right / 2 +
          "px " +
          _vm.facade.item_margin_bottom / 2 +
          "px " +
          _vm.facade.item_margin_left / 2 +
          "px",
        padding:
          _vm.facade.item_padding_top / 2 +
          "px " +
          _vm.facade.item_padding_right / 2 +
          "px " +
          _vm.facade.item_padding_bottom / 2 +
          "px " +
          _vm.facade.item_padding_left / 2 +
          "px",
        borderRadius:
          _vm.facade.radius_top_left / 2 +
          "px " +
          _vm.facade.radius_top_right / 2 +
          "px " +
          _vm.facade.radius_bottom_right / 2 +
          "px " +
          _vm.facade.radius_bottom_left / 2 +
          "px",
        backgroundImage: _vm.facade.item_background_url
          ? "url(" + _vm.facade.item_background_url + ")"
          : "",
        backgroundSize: "100% 100%",
        boxShadow: _vm.facade.page_shadow
          ? "0 0 " +
            _vm.facade.page_shadow_size / 2 +
            "px " +
            _vm.facade.page_shadow
          : "",
      },
    },
    [
      _vm.facade.title_icon
        ? _c("div", { staticClass: "title-icon" }, [
            _c("img", {
              style: {
                width: _vm.facade.title_icon_width / 2 + "px",
                height: _vm.facade.title_icon_height / 2 + "px",
                display: "block",
              },
              attrs: { src: _vm.facade.title_icon + "!m640" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "name",
          style: {
            marginLeft: _vm.facade.title_margin_left / 2 + "px",
            marginRight: _vm.facade.title_margin_right / 2 + "px",
            color: _vm.facade.title_color,
            fontWeight: _vm.facade.title_weight,
            fontSize: _vm.facade.title_size / 2 + "px",
            background: _vm.facade.title_color_gradient
              ? "linear-gradient(to bottom, " +
                _vm.facade.title_color +
                " 0%, " +
                _vm.facade.title_color_gradient +
                " 100%)"
              : "",
            backgroundClip: _vm.facade.title_color_gradient ? "text" : "",
            color: _vm.facade.title_color_gradient ? "transparent" : "",
          },
        },
        [_vm._v(_vm._s(_vm.facade.title_name))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "desc flex-one",
          style: {
            marginLeft: _vm.facade.desc_margin_left / 2 + "px",
            marginRight: _vm.facade.desc_margin_right / 2 + "px",
            color: _vm.facade.desc_color,
            fontWeight: _vm.facade.desc_weight,
            fontSize: _vm.facade.desc_size / 2 + "px",
            background: _vm.facade.desc_color_gradient
              ? "linear-gradient(to bottom, " +
                _vm.facade.desc_color +
                " 0%, " +
                _vm.facade.desc_color_gradient +
                " 100%)"
              : "",
            backgroundClip: _vm.facade.desc_color_gradient ? "text" : "",
            color: _vm.facade.desc_color_gradient ? "transparent" : "",
          },
        },
        [_vm._v("3项会员权益待使用")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "level",
          style: {
            color: _vm.facade.level_color,
            fontWeight: _vm.facade.level_weight,
            fontSize: _vm.facade.level_size / 2 + "px",
            background: _vm.facade.level_color_gradient
              ? "linear-gradient(to bottom, " +
                _vm.facade.level_color +
                " 0%, " +
                _vm.facade.level_color_gradient +
                " 100%)"
              : "",
            backgroundClip: _vm.facade.level_color_gradient ? "text" : "",
            color: _vm.facade.level_color_gradient ? "transparent" : "",
          },
        },
        [_vm._v("等级D3")]
      ),
      _vm._v(" "),
      _vm.facade.level_icon
        ? _c(
            "div",
            {
              staticClass: "title-icon",
              style: {
                marginLeft: _vm.facade.level_icon_margin_left / 2 + "px",
                marginRight: _vm.facade.level_icon_margin_right / 2 + "px",
              },
            },
            [
              _c("img", {
                style: {
                  width: _vm.facade.level_icon_width / 2 + "px",
                  height: _vm.facade.level_icon_height / 2 + "px",
                  display: "block",
                },
                attrs: { src: _vm.facade.level_icon + "!m640" },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }