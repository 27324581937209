var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "open3d-propleft" },
    [
      _c(
        "div",
        { staticClass: "open3d-propleft-header" },
        [
          _vm.modifyDetail.editType == "textInfo"
            ? [_vm._v("编辑文字")]
            : _vm.modifyDetail.editType == "exhibitionData" &&
              _vm.modifyDetail.info.exhibitionType == "exhibition"
            ? [_vm._v("编辑展品")]
            : _vm.modifyDetail.editType == "exhibitionData" &&
              _vm.modifyDetail.info.exhibitionType == "material"
            ? [_vm._v("编辑素材")]
            : _vm.modifyDetail.editType == "exhibitionData" &&
              _vm.modifyDetail.info.exhibitionType == "portal"
            ? [_vm._v("编辑传送门")]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.modifyDetail.editType == "textInfo"
        ? [
            _c("textEdit", {
              attrs: { open3dEl: _vm.open3dEl, modifyDetail: _vm.modifyDetail },
              on: {
                textDraw: function (data) {
                  return _vm.$emit("textDraw", data)
                },
                inpFocus: function ($event) {
                  return _vm.inpAction(true)
                },
                inpBlur: function ($event) {
                  return _vm.inpAction(false)
                },
              },
            }),
          ]
        : _vm.modifyDetail.editType == "exhibitionData"
        ? [
            _c("div", { staticClass: "open3d-goods-top-box" }, [
              _c("div", { staticClass: "open3d-goods-top-img" }, [
                _vm.modifyDetail.info
                  ? _c("img", {
                      staticClass: "open3d-goods-top-imgc open3dworkimg",
                      attrs: { src: _vm.modifyDetail.info.coverImage, alt: "" },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "open3d-goods-top-right" }, [
                _c(
                  "div",
                  { staticClass: "open3d-goods-top-name open3dworkname" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.modifyDetail.info
                            ? _vm.modifyDetail.info.name
                            : ""
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "open3d-goods-top-delete",
                    on: { click: _vm.delAction },
                  },
                  [
                    _c("img", {
                      staticClass: "open3d-goods-top-delimg",
                      attrs: {
                        src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805590279650008.png",
                      },
                    }),
                    _vm._v("\n          移除\n        "),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "open3d-font-title open3d-common-title-margin" },
              [_vm._v("尺寸")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "open3d-size-box" }, [
              _c("div", { staticClass: "open3d-size-input-box" }, [
                _c("input", {
                  staticClass: "open3d-size",
                  attrs: { type: "text" },
                  domProps: { value: _vm.modifyDetail.scale },
                  on: { change: _vm.editSizeAction },
                }),
              ]),
              _vm._v(" "),
              _c("input", {
                staticClass: "open3d-size-range",
                style:
                  "\n          background: linear-gradient(\n            to right,\n            rgb(50, 141, 253) 0%,\n            rgb(50, 141, 253) " +
                  _vm.process +
                  "%,\n            rgb(61, 63, 70) " +
                  _vm.process +
                  "%,\n            rgb(61, 63, 70) 100%\n          );\n        ",
                attrs: { type: "range", min: "1", max: "200", step: "1" },
                domProps: { value: _vm.modifyDetail.scale },
                on: { change: _vm.editSizeAction },
              }),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "open3d-font-title open3d-common-title-margin" },
        [_vm._v("方位")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "open3d-control-box" }, [
        _c(
          "div",
          {
            staticClass: "open3d-control-button",
            class: { active: _vm.control == "translate" },
            on: {
              click: function ($event) {
                return _vm.changeControls("translate")
              },
            },
          },
          [
            _c("img", {
              staticClass: "open3d-control-button-img",
              attrs: {
                src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805589854242506.png",
                alt: "",
              },
            }),
            _vm._v("\n      位移\n      "),
            _c("img", {
              staticClass: "open3d-control-button-icon",
              attrs: {
                src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202382/image/1690963842526319665.png",
                alt: "",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "open3d-control-button",
            class: { active: _vm.control == "rotate" },
            on: {
              click: function ($event) {
                return _vm.changeControls("rotate")
              },
            },
          },
          [
            _c("img", {
              staticClass: "open3d-control-button-img",
              attrs: {
                src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805590061206575.png",
                alt: "",
              },
            }),
            _vm._v("\n      旋转\n      "),
            _c("img", {
              staticClass: "open3d-control-button-icon",
              attrs: {
                src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202382/image/1690963842526319665.png",
                alt: "",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.modifyDetail &&
      _vm.modifyDetail.info &&
      _vm.modifyDetail.info.exhibitionType == "portal"
        ? [
            _c(
              "div",
              { staticClass: "open3d-font-title open3d-common-title-margin" },
              [_vm._v("传送至")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "open3d-select-view" }, [
              _c(
                "div",
                { staticClass: "open3d-select-box" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-show-suffix": "",
                        placeholder: "输入空间名称搜索",
                        "remote-method": _vm.searchSpaceAction,
                        loading: _vm.searchSpaceLoading,
                      },
                      on: {
                        change: _vm.changeSpaceIdAction,
                        focus: function ($event) {
                          return _vm.inpAction(true)
                        },
                        blur: function ($event) {
                          return _vm.inpAction(false)
                        },
                      },
                      model: {
                        value: _vm.spaceId,
                        callback: function ($$v) {
                          _vm.spaceId = $$v
                        },
                        expression: "spaceId",
                      },
                    },
                    _vm._l(_vm.spaceList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.spaceName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "open3d-select-box" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择出生点" },
                      model: {
                        value: _vm.spaceStartPointId,
                        callback: function ($$v) {
                          _vm.spaceStartPointId = $$v
                        },
                        expression: "spaceStartPointId",
                      },
                    },
                    _vm._l(_vm.pointList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "open3d-propleft-bottom-button-box" }, [
              _c(
                "div",
                {
                  staticClass: "open3d-propleft-bottom-button-cursor",
                  on: { click: _vm.confirmAction },
                },
                [
                  _c("img", {
                    staticClass: "open3d-propleft-bottom-button-img",
                    attrs: {
                      src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805589127488113.png",
                      alt: "",
                    },
                  }),
                  _vm._v("\n        确认\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "open3d-propleft-bottom-button-cancel",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeAction")
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
            ]),
          ]
        : _vm.modifyDetail && _vm.modifyDetail.extends && _vm.jumpType
        ? [
            _c(
              "div",
              {
                staticClass:
                  "open3d-font-title open3d-common-title-margin anchor-point",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "item",
                    class: { active: _vm.jumpType == "portal" },
                    on: {
                      click: function ($event) {
                        return _vm.editOperateAction("portal")
                      },
                    },
                  },
                  [_vm._v("\n        传送\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "item",
                    class: { active: _vm.jumpType == "jumpTransference" },
                    on: {
                      click: function ($event) {
                        return _vm.editOperateAction("jumpTransference")
                      },
                    },
                  },
                  [_vm._v("\n        版权转让\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "item",
                    class: { active: _vm.jumpType == "jumpEmpower" },
                    on: {
                      click: function ($event) {
                        return _vm.editOperateAction("jumpEmpower")
                      },
                    },
                  },
                  [_vm._v("\n        版权授权\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "item",
                    class: { active: _vm.jumpType == "jumpUrl" },
                    on: {
                      click: function ($event) {
                        return _vm.editOperateAction("jumpUrl")
                      },
                    },
                  },
                  [_vm._v("\n        跳转\n      ")]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.jumpType == "portal"
              ? _c("div", { staticClass: "open3d-select-view" }, [
                  _c(
                    "div",
                    { staticClass: "open3d-select-box" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            "remote-show-suffix": "",
                            placeholder: "输入空间名称搜索",
                            "remote-method": _vm.searchSpaceAction,
                            loading: _vm.searchSpaceLoading,
                          },
                          on: {
                            change: _vm.changeSpaceIdAction,
                            focus: function ($event) {
                              return _vm.inpAction(true)
                            },
                            blur: function ($event) {
                              return _vm.inpAction(false)
                            },
                          },
                          model: {
                            value: _vm.spaceId,
                            callback: function ($$v) {
                              _vm.spaceId = $$v
                            },
                            expression: "spaceId",
                          },
                        },
                        _vm._l(_vm.spaceList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.spaceName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "open3d-select-box" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "选择出生点" },
                          model: {
                            value: _vm.spaceStartPointId,
                            callback: function ($$v) {
                              _vm.spaceStartPointId = $$v
                            },
                            expression: "spaceStartPointId",
                          },
                        },
                        _vm._l(_vm.pointList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm.jumpType == "jumpUrl"
              ? _c("div", { staticClass: "open3d-select-view" }, [
                  _c(
                    "div",
                    {
                      staticClass: "open3d-propleft-search-box",
                      staticStyle: { "margin-left": "0", width: "100%" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.jumpLink,
                            expression: "jumpLink",
                          },
                        ],
                        staticClass: "open3d-propleft-input",
                        attrs: { type: "text", placeholder: "输入跳转地址" },
                        domProps: { value: _vm.jumpLink },
                        on: {
                          focus: function ($event) {
                            return _vm.inpAction(true)
                          },
                          blur: function ($event) {
                            return _vm.inpAction(false)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.jumpLink = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "open3d-select-view" }, [
                  _c(
                    "div",
                    {
                      staticClass: "open3d-propleft-search-box",
                      staticStyle: { "margin-left": "0", width: "100%" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.jumpLink,
                            expression: "jumpLink",
                          },
                        ],
                        staticClass: "open3d-propleft-input",
                        attrs: { type: "text", placeholder: "输入跳转ID" },
                        domProps: { value: _vm.jumpLink },
                        on: {
                          focus: function ($event) {
                            return _vm.inpAction(true)
                          },
                          blur: function ($event) {
                            return _vm.inpAction(false)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.jumpLink = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "open3d-propleft-bottom-button-box" }, [
              _c(
                "div",
                {
                  staticClass: "open3d-propleft-bottom-button-cursor",
                  on: { click: _vm.confirmAction },
                },
                [
                  _c("img", {
                    staticClass: "open3d-propleft-bottom-button-img",
                    attrs: {
                      src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805589127488113.png",
                      alt: "",
                    },
                  }),
                  _vm._v("\n        确认\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "open3d-propleft-bottom-button-cancel",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeAction")
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "30px" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }