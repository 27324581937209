var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search item-align-center",
      style: {
        "background-color": _vm.facade.background_color,
        padding: "8px " + _vm.facade.page_padding / 2 + "px",
      },
    },
    [
      _vm.content.style === 1
        ? _c("img", {
            style: {
              width: _vm.facade.high_style / 2 - 4 + "px",
              height: _vm.facade.high_style / 2 - 4 + "px",
              marginRight: "8px",
            },
            attrs: {
              src:
                (_vm.content.front_icon
                  ? _vm.content.front_icon
                  : "https://saas.cdn.yunzongbu.cn/image/20211026/729b0a306965fb18edc406a6c8913934.png") +
                "!120a",
              alt: "",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "search-content item-align-center",
          style: {
            color: _vm.facade.text_color,
            "background-color": _vm.facade.border_color,
            height: _vm.facade.high_style / 2 + "px",
            borderRadius: _vm.facade.border_radius / 2 + "px",
            fontSize: _vm.facade.text_size / 2 + "px",
            width: _vm.facade.width_style / 2 + "px",
            fontWeight: _vm.facade.text_style,
          },
        },
        [
          _vm.facade.icon_align == "left"
            ? _c("i", {
                staticClass: "el-icon-search",
                style: {
                  color: _vm.facade.icon_color,
                  marginRight: "5px",
                  fontWeight: "bold",
                  fontSize: _vm.facade.icon_size / 2 + "px",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "flex-one" }, [
            _vm._v(_vm._s(_vm.content.text || "请输入关键字搜索")),
          ]),
          _vm._v(" "),
          _vm.facade.icon_align == "right"
            ? _c("i", {
                staticClass: "el-icon-search",
                style: {
                  color: _vm.facade.icon_color,
                  marginLeft: "5px",
                  fontWeight: "bold",
                  fontSize: _vm.facade.icon_size / 2 + "px",
                },
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.content.style === 1
        ? _c("img", {
            style: {
              width: _vm.facade.high_style / 2 - 4 + "px",
              height: _vm.facade.high_style / 2 - 4 + "px",
              marginLeft: "8px",
            },
            attrs: {
              src:
                (_vm.content.after_icon
                  ? _vm.content.after_icon
                  : "https://saas.cdn.yunzongbu.cn/image/20211026/729b0a306965fb18edc406a6c8913934.png") +
                "!120a",
              alt: "",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.content.style === 3
        ? _c(
            "div",
            {
              staticClass: "text-link item-align-center",
              style: {
                color: _vm.facade.text_link_color,
                fontSize: _vm.facade.text_link_size / 2 + "px",
                fontWeight: _vm.facade.text_link_style,
              },
            },
            _vm._l(_vm.content.data, function (item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _vm._v(_vm._s(item.title)),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }