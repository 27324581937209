"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index4 = require("@/utils/index");
var _nft = require("@/api/nft");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      parseTime: _index4.parseTime,
      formatPriceNumber: _index4.formatPriceNumber,
      queryParams: {
        page: 1,
        limit: 10,
        status: ''
      },
      keyword: '',
      curr: '',
      issue_number: '',
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      dialogVisible: false,
      czData: {
        type: 1,
        show: false,
        ids: '',
        remark: ''
      },
      album_id: '',
      albumList: [],
      searchLoading: false,
      // 重新投放 - data
      putInData: {
        uid: '',
        nft_data_id: '',
        show: false,
        code: '',
        title: '',
        btnLoading: false
      },
      userList: []
    };
  },
  created: function created() {
    var _this = this;
    (0, _nft.nftCollectionAlbumListApi)({
      page: 1,
      limit: 50
    }).then(function (res) {
      _this.albumList = res.data.list;
    });
    this.getList(1);
  },
  methods: {
    putInDataConfirmAction: function putInDataConfirmAction() {
      var _this2 = this;
      this.putInData.btnLoading = true;
      this.$confirm('确认投放给此用户？', '提示', {
        confirmButtonText: '确认',
        type: 'warning'
      }).then(function () {
        (0, _nft.recoveryNftTransfersApi)({
          nft_data_id: _this2.putInData.nft_data_id,
          uid: _this2.putInData.uid
        }).then(function () {
          _this2.$message.success('操作成功');
          _this2.clearPutInDataAction();
          _this2.getList();
          _this2.putInData.btnLoading = false;
        });
      }).catch(function (_ref) {
        var message = _ref.message;
        _this2.putInData.btnLoading = false;
      });
    },
    clearPutInDataAction: function clearPutInDataAction() {
      this.putInData = {
        uid: '',
        nft_data_id: '',
        show: false,
        code: '',
        title: '',
        btnLoading: false
      };
    },
    putInAction: function putInAction(item) {
      this.putInData = {
        uid: '',
        nft_data_id: item.nft_data_id,
        show: true,
        code: item.issue_number,
        title: item.product.store_name,
        btnLoading: false
      };
    },
    searchUserAction: function searchUserAction(data) {
      var _this3 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this3.userList = res.data.list;
      });
    },
    batchRecoveryAction: function batchRecoveryAction(id) {
      if (!id && this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.czData = {
        show: true,
        type: 1,
        ids: id || this.checkedIds.join(','),
        remark: ''
      };
    },
    batchDestroyAction: function batchDestroyAction(id) {
      if (!id && this.checkedIds.length === 0) {
        return this.$message.warning('请先选择藏品');
      }
      this.czData = {
        show: true,
        type: 2,
        ids: id || this.checkedIds.join(','),
        remark: ''
      };
    },
    czAction: function czAction() {
      if (this.czData.type === 1) {
        this.recoveryAction(this.czData.ids, this.czData.remark);
      } else if (this.czData.type === 2) {
        this.destroyAction(this.czData.ids, this.czData.remark);
      }
    },
    recoveryAction: function recoveryAction(ids, r) {
      var _this4 = this;
      this.$confirm('确认回收？', '提示', {
        confirmButtonText: '回收',
        type: 'warning'
      }).then(function () {
        (0, _nft.batchRecoveryApi)({
          nft_data_id: ids,
          remark: r
        }).then(function () {
          _this4.checkedIds = [];
          _this4.czData.show = false;
          _this4.$message.success('操作成功');
          _this4.getList();
        });
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    destroyAction: function destroyAction(ids, r) {
      var _this5 = this;
      this.$confirm('确认销毁？', '提示', {
        confirmButtonText: '销毁',
        type: 'warning'
      }).then(function () {
        (0, _nft.batchDestroyApi)({
          nft_data_id: ids,
          remark: r
        }).then(function () {
          _this5.checkedIds = [];
          _this5.czData.show = false;
          _this5.$message.success('操作成功');
          _this5.getList();
        });
      }).catch(function (_ref3) {
        var message = _ref3.message;
      });
    },
    // 选择
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      console.log(v);
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.queryParams.page);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.queryParams.page);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this6 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.nft_data_id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id, i) {
          var index = _this6.checkedIds.indexOf(id);
          if (index === -1) {
            if (_this6.tableData.data[i].status === 1) {
              _this6.checkedIds.push(id);
            }
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this6.checkedIds.indexOf(id);
          if (index > -1) {
            _this6.checkedIds.splice(index, 1);
          }
        });
      }
    },
    // 分开选择
    changeOne: function changeOne(v, collection) {
      if (collection.status !== 1) {
        return false;
      }
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(collection.nft_data_id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(collection.nft_data_id);
          if (_index === -1) this.checkedIds.push(collection.nft_data_id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(collection.nft_data_id);
          if (_index2 === -1) this.noChecked.push(collection.nft_data_id);
        } else {
          var _index3 = this.checkedIds.indexOf(collection.nft_data_id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    getList: function getList(num) {
      var _this7 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      (0, _nft.dataListApi)(this.queryParams).then(function (res) {
        _this7.tableData.data = res.data.list;
        _this7.tableData.total = res.data.count;
        _this7.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    goSearch: function goSearch() {
      this.queryParams.keyword = this.keyword;
      this.queryParams.album_id = this.album_id;
      this.queryParams.issue_number = this.issue_number;
      this.queryParams.curr = this.curr;
      this.getList(1);
    },
    editSortAction: function editSortAction() {
      // 修改排序
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this8 = this;
      var data = (0, _objectSpread2.default)({
        export_type: 'nftData'
      }, this.queryParams);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this8.$createElement;
        _this8.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this8.$msgbox.close();
                _this8.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('nftData');
    },
    addAction: function addAction() {},
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.limit = val;
      this.getList();
    }
  }
};