var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.style === 1
        ? _vm._l(_vm.facade.show ? 2 : 1, function (item) {
            return _c(
              "div",
              {
                key: "album1" + item,
                staticClass: "auction-meeting",
                style: {
                  margin: _vm.facade.show
                    ? 0
                    : "0 " + _vm.facade.page_margin / 2 + "px",
                  paddingBottom: (_vm.content.style === 1 ? 30 : 0) + "px",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  _vm._l(item == 3 ? 1 : 2, function (m) {
                    return _c(
                      "div",
                      {
                        key: m,
                        staticClass: "album flex",
                        style: {
                          marginLeft:
                            (_vm.facade.show
                              ? _vm.facade.page_margin
                              : _vm.facade.item_margin) /
                              2 +
                            "px",
                          width:
                            375 -
                            (_vm.facade.show
                              ? _vm.facade.page_margin
                              : _vm.facade.item_margin) -
                            (item == 3 ? 0 : 32) +
                            "px",
                          paddingBottom: "30px",
                          borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                          borderTopRightRadius:
                            _vm.facade.top_radius / 2 + "px",
                          borderBottomLeftRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          borderBottomRightRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          background: _vm.facade.item_background,
                          paddingBottom:
                            _vm.facade.status_bar_height / 2 + "px",
                          boxShadow: _vm.facade.item_shadow
                            ? "0 0 " +
                              _vm.facade.item_shadow_size / 2 +
                              "px " +
                              _vm.facade.item_shadow
                            : "",
                          marginTop: _vm.facade.show ? "15px" : "0",
                        },
                      },
                      [
                        item == 2
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "album-line flex flex-direction",
                                  staticStyle: { height: "200px" },
                                  style: {
                                    width:
                                      375 -
                                      (_vm.facade.show
                                        ? _vm.facade.page_margin
                                        : _vm.facade.item_margin) -
                                      (item == 3 ? 0 : 32) +
                                      "px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "info flex align-items-c",
                                      staticStyle: { padding: "0" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "name",
                                          style: { color: "#fff" },
                                        },
                                        [
                                          _vm._v(
                                            "第三届大众艺术品拍卖会书画专场传承经典拍卖"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._m(0, true),
                                ]
                              ),
                            ]
                          : [
                              _vm._m(1, true),
                              _vm._v(" "),
                              _c("div", { staticClass: "info flex-one" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "name",
                                    style: { color: _vm.facade.title_color },
                                  },
                                  [
                                    _vm._v(
                                      "第三届大众艺术品拍卖会书画专场传承经典画作拍卖"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "time",
                                    style: {
                                      color: _vm.facade.sub_title_color,
                                    },
                                  },
                                  [_vm._v("3月25日 16:00 至 4月13 19:00")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "lots",
                                    style: { color: _vm.facade.detail_color },
                                  },
                                  [
                                    _c("div", { staticClass: "lot" }, [
                                      _vm._v("拍品：300件"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "lot" }, [
                                      _vm._v("佣金：10-15%"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "lot" }, [
                                      _vm._v("围观：2435次"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "lot" }, [
                                      _vm._v("报名：5人"),
                                    ]),
                                    _vm._v(" "),
                                    item == 3
                                      ? _c("div", { staticClass: "lot" }, [
                                          _vm._v(
                                            "地点：北京饭店北京饭店北京饭店北京饭店"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ],
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "hot-album-b",
                            style: {
                              height: _vm.facade.status_bar_height / 2 + "px",
                              background:
                                "linear-gradient(to right, " +
                                _vm.facade.status_bar_bg +
                                "aa, " +
                                _vm.facade.status_bar_bg +
                                ")",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-bl-bg",
                                style: {
                                  background: _vm.facade.status_bar_bg,
                                  height:
                                    _vm.facade.status_bar_height / 2 + "px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "hot-album-bl-text",
                                    style: {
                                      height:
                                        _vm.facade.status_bar_height / 2 + "px",
                                      color: _vm.facade.status_bar_color,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.facade.status_bar_color ===
                                          "white"
                                            ? "https://saas.cdn.yunzongbu.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png"
                                            : "https://saas.cdn.yunzongbu.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(2, true),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "hot-album-bl-Yinying-wai",
                                    style: {
                                      height:
                                        _vm.facade.status_bar_height / 2 + "px",
                                      width:
                                        (_vm.facade.status_bar_height + 40) /
                                          2 +
                                        "px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "hot-album-bl-Yinying",
                                      style: {
                                        background: _vm.facade.status_bar_bg,
                                        height:
                                          _vm.facade.status_bar_height / 2 +
                                          "px",
                                        width:
                                          _vm.facade.status_bar_height / 2 +
                                          "px",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-br",
                                style: { color: _vm.facade.status_bar_color },
                              },
                              [_vm._v("3月26日 19点 开拍")]
                            ),
                          ]
                        ),
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                item != 3
                  ? _c("pointer", {
                      attrs: {
                        color: _vm.facade.status_bar_bg,
                        type: _vm.facade.indicator_style,
                        align: _vm.facade.indicator_align,
                        margin: -5,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          })
        : _vm.content.style === 2
        ? [
            _c("div", { staticClass: "auction-meeting" }, [
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  {
                    staticClass: "album flex",
                    style: {
                      marginLeft: _vm.facade.page_margin / 2 + "px",
                      width: 375 - _vm.facade.page_margin + "px",
                      paddingBottom: "30px",
                      borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                      borderTopRightRadius: _vm.facade.top_radius / 2 + "px",
                      borderBottomLeftRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      borderBottomRightRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      background: _vm.facade.item_background,
                      paddingBottom: _vm.facade.status_bar_height / 2 + "px",
                      boxShadow: _vm.facade.item_shadow
                        ? "0 0 " +
                          _vm.facade.item_shadow_size / 2 +
                          "px " +
                          _vm.facade.item_shadow
                        : "",
                      marginTop: _vm.facade.show ? "15px" : "0",
                    },
                  },
                  [
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("div", { staticClass: "info flex-one" }, [
                        _c(
                          "div",
                          {
                            staticClass: "name",
                            style: { color: _vm.facade.title_color },
                          },
                          [
                            _vm._v(
                              "第三届大众艺术品拍卖会书画专场传承经典画作拍卖"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "time",
                            style: { color: _vm.facade.sub_title_color },
                          },
                          [_vm._v("3月25日 16:00 至 4月13 19:00")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "lots",
                            style: { color: _vm.facade.detail_color },
                          },
                          [
                            _c("div", { staticClass: "lot" }, [
                              _vm._v("拍品：300件"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lot" }, [
                              _vm._v("佣金：10-15%"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lot" }, [
                              _vm._v("围观：2435次"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lot" }, [
                              _vm._v("报名：5人"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lot" }, [
                              _vm._v("地点：北京饭店北京饭店北京饭店北京饭店"),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "hot-album-b",
                        style: {
                          height: _vm.facade.status_bar_height / 2 + "px",
                          background:
                            "linear-gradient(to right, " +
                            _vm.facade.status_bar_bg +
                            "aa, " +
                            _vm.facade.status_bar_bg +
                            ")",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "hot-album-bl-bg",
                            style: {
                              background: _vm.facade.status_bar_bg,
                              height: _vm.facade.status_bar_height / 2 + "px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-bl-text",
                                style: {
                                  height:
                                    _vm.facade.status_bar_height / 2 + "px",
                                  color: _vm.facade.status_bar_color,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.facade.status_bar_color === "white"
                                        ? "https://saas.cdn.yunzongbu.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png"
                                        : "https://saas.cdn.yunzongbu.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png",
                                  },
                                }),
                                _vm._v(" "),
                                _vm._m(4),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-bl-Yinying-wai",
                                style: {
                                  height:
                                    _vm.facade.status_bar_height / 2 + "px",
                                  width:
                                    (_vm.facade.status_bar_height + 40) / 2 +
                                    "px",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "hot-album-bl-Yinying",
                                  style: {
                                    background: _vm.facade.status_bar_bg,
                                    height:
                                      _vm.facade.status_bar_height / 2 + "px",
                                    width:
                                      _vm.facade.status_bar_height / 2 + "px",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "hot-album-br",
                            style: { color: _vm.facade.status_bar_color },
                          },
                          [_vm._v("3月26日 19点 开拍")]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "album flex",
                  style: {
                    padding:
                      _vm.facade.page_margin / 4 +
                      "px " +
                      _vm.facade.page_margin / 2 +
                      "px",
                    background: _vm.facade.item_background,
                    marginTop: _vm.facade.item_margin / 2 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "c-info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "c-name",
                        class: { line1: !_vm.facade.name_wrap },
                        style: {
                          color: _vm.facade.title_color,
                          fontSize:
                            _vm.facade.column_title_font_size / 2 + "px",
                        },
                      },
                      [_vm._v("第三届大众艺术品拍卖会书画专场传承经典画作拍卖")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "c-lots",
                        style: {
                          color: _vm.facade.detail_color,
                          paddingBottom: "10px",
                        },
                      },
                      [_vm._m(5), _vm._v(" "), _vm._m(6)]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "c-bottom-img",
                        style: {
                          paddingBottom:
                            _vm.facade.status_bar_height / 2 + "px",
                          borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                          borderTopRightRadius:
                            _vm.facade.top_radius / 2 + "px",
                          borderBottomLeftRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          borderBottomRightRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                        },
                      },
                      [
                        _vm._m(7),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "hot-album-b",
                            style: {
                              height: _vm.facade.status_bar_height / 2 + "px",
                              background:
                                "linear-gradient(to right, " +
                                _vm.facade.status_bar_bg +
                                "aa, " +
                                _vm.facade.status_bar_bg +
                                ")",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-bl-bg",
                                style: {
                                  background: _vm.facade.status_bar_bg,
                                  height:
                                    _vm.facade.status_bar_height / 2 + "px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "hot-album-bl-text",
                                    style: {
                                      height:
                                        _vm.facade.status_bar_height / 2 + "px",
                                      color: _vm.facade.status_bar_color,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.facade.status_bar_color ===
                                          "white"
                                            ? "https://saas.cdn.yunzongbu.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png"
                                            : "https://saas.cdn.yunzongbu.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(8),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "hot-album-bl-Yinying-wai",
                                    style: {
                                      height:
                                        _vm.facade.status_bar_height / 2 + "px",
                                      width:
                                        (_vm.facade.status_bar_height + 40) /
                                          2 +
                                        "px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "hot-album-bl-Yinying",
                                      style: {
                                        background: _vm.facade.status_bar_bg,
                                        height:
                                          _vm.facade.status_bar_height / 2 +
                                          "px",
                                        width:
                                          _vm.facade.status_bar_height / 2 +
                                          "px",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-br",
                                style: { color: _vm.facade.status_bar_color },
                              },
                              [_vm._v("3月26日 19点 开拍")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img flex-one" }, [
      _c("div", { staticClass: "no-img item-flex-center" }, [
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "img", staticStyle: { width: "135px", height: "200px" } },
      [
        _c("div", { staticClass: "no-img item-flex-center" }, [
          _c("img", {
            attrs: {
              src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
              alt: "",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", [_vm._v("限时拍")]),
      _vm._v("\n                  ·\n                  "),
      _c("span", [_vm._v("预展中")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "img", staticStyle: { width: "135px", height: "200px" } },
      [
        _c("div", { staticClass: "no-img item-flex-center" }, [
          _c("img", {
            attrs: {
              src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
              alt: "",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", [_vm._v("限时拍")]),
      _vm._v("\n                  ·\n                  "),
      _c("span", [_vm._v("预展中")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lot" }, [
      _c("span", { staticClass: "mr10" }, [
        _vm._v("预展：2020年3月26日 - 27日"),
      ]),
      _vm._v(" | "),
      _c("span", { staticClass: "ml10" }, [_vm._v("拍品：300件")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lot" }, [
      _c("span", { staticClass: "mr10" }, [_vm._v("开拍：2020年3月26日 19点")]),
      _vm._v(" | "),
      _c("span", { staticClass: "ml10" }, [
        _vm._v("地点：北京饭店北京饭店北京饭店北京饭店"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "img", staticStyle: { width: "100%", height: "200px" } },
      [
        _c("div", { staticClass: "no-img item-flex-center" }, [
          _c("img", {
            attrs: {
              src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
              alt: "",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", [_vm._v("限时拍")]),
      _vm._v("\n                    ·\n                    "),
      _c("span", [_vm._v("预展中")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }