var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "空投类型：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.changeTypeAction },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("名单投放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("精准投放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("自定义空投"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.type == 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发放用户：",
                                prop: "user_phone",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "w200",
                                attrs: { placeholder: "请输入用户手机号" },
                                model: {
                                  value: _vm.formValidate.user_phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "user_phone",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.user_phone",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "关联名单：", prop: "cate" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "mr10",
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择关联名单",
                                  },
                                  on: {
                                    change: _vm.handleselectCate,
                                    "remove-tag": _vm.remotetag,
                                  },
                                  model: {
                                    value: _vm.formValidate.cate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "cate", $$v)
                                    },
                                    expression: "formValidate.cate",
                                  },
                                },
                                _vm._l(_vm.merCateList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.title,
                                      value: item.snapshot_id,
                                    },
                                  })
                                }),
                                1
                              ),
                              _vm._v(
                                "\n            总人数：" +
                                  _vm._s(_vm.usercount) +
                                  "\n          "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type == 1
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "空投时间：", prop: "task_time" },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "selwidth",
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择时间",
                                  "value-format": "yyyy/MM/dd HH:mm:ss",
                                  format: "yyyy/MM/dd HH:mm:ss",
                                },
                                model: {
                                  value: _vm.formValidate.task_time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "task_time", $$v)
                                  },
                                  expression: "formValidate.task_time",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type == 3
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "导入名单：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "post" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("导入")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "export-file",
                                        attrs: { type: "file" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeExselAction($event)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ml10",
                                      attrs: { size: "small", type: "text" },
                                      on: { click: _vm.downLoad },
                                    },
                                    [_vm._v("下载导入模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.fileNumber && _vm.type == 3
                        ? _c("el-form-item", [
                            _vm._v(
                              "\n            导入数量：" +
                                _vm._s(_vm.fileNumber) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: "请输入备注",
                              size: "normal",
                              clearable: "",
                            },
                            model: {
                              value: _vm.formValidate.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "remark", $$v)
                              },
                              expression: "formValidate.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "空投内容：", required: "" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "table",
                              staticStyle: {
                                width: "100%",
                                "margin-top": "-10px",
                              },
                              attrs: {
                                "tooltip-effect": "dark",
                                "row-key": function (row) {
                                  return row.order_id
                                },
                                data: _vm.orderArr,
                                size: "mini",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "空投类型" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.editTypeAction(
                                                  scope.$index
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.productType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "productType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.productType",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                value: 1,
                                                label: "数字藏品",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: 2,
                                                label: "NFT盲盒",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: 3,
                                                label: "积分",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: 4,
                                                label: "优惠券",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: 5,
                                                label: "门票",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-option", {
                                              attrs: {
                                                value: 6,
                                                label: "会员卡",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { width: "80", label: "图片" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            scope.row.image
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.image + "!120a",
                                                    "preview-src-list": [
                                                      scope.row.image,
                                                    ],
                                                  },
                                                })
                                              : scope.row.product &&
                                                scope.row.product.image
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.product.image +
                                                      "!120a",
                                                    "preview-src-list": [
                                                      scope.row.product.image,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "名称", "min-width": "100" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.title
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.title) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.name
                                          ? _c(
                                              "div",
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(scope.row.name) +
                                                    " "
                                                ),
                                                scope.row.spec_name
                                                  ? [
                                                      _vm._v(
                                                        "- " +
                                                          _vm._s(
                                                            scope.row.spec_name
                                                          )
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : scope.row.store_name
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.store_name) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : scope.row.product
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    scope.row.product.store_name
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.productType == 1 ||
                                        scope.row.productType == 2 ||
                                        scope.row.productType == 4 ||
                                        scope.row.productType == 5 ||
                                        scope.row.productType == 6
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAdditem(
                                                      scope.row.productType
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("选择")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "价格", "min-width": "100" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.sale_price
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.sale_price) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : scope.row.price
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.price) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : scope.row.product
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    scope.row.product.price
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发售时间",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.sale_time
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.sale_time) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.start_time) +
                                                  "\n                  "
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _vm.type == 2
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "编号",
                                      "min-width": "130",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.productType == 1
                                                ? _c(
                                                    "div",
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            scope.row
                                                              .number_prefix
                                                          ) +
                                                          "#\n                    "
                                                      ),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "可输入编号",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.orderArr[
                                                              scope.$index
                                                            ].issue_number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.orderArr[
                                                                scope.$index
                                                              ],
                                                              "issue_number",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "orderArr[scope.$index].issue_number",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                    /" +
                                                          _vm._s(
                                                            scope.row.issuer_num
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3064990288
                                    ),
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.type != 3
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "空投数量",
                                      "min-width": "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                model: {
                                                  value:
                                                    scope.row.airdrop_number,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "airdrop_number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.airdrop_number",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      958331196
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("formValidate")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "30px" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Coupon", {
        ref: "coupon",
        attrs: { currentData: [], size: "1", usercount: _vm.usercount },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("NFT", {
        ref: "nft",
        attrs: { currentData: [], size: "1", usercount: _vm.usercount },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("NFTBlind", {
        ref: "nftblind",
        attrs: { currentData: [], size: "1", usercount: _vm.usercount },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Ticket", {
        ref: "ticket",
        attrs: { currentData: [], size: "1", usercount: _vm.usercount },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("MemberCard", {
        ref: "member",
        attrs: { currentData: [], size: "1", usercount: _vm.usercount },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }