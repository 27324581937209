"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._changeWorkSort = _changeWorkSort;
exports.workCreateApi = workCreateApi;
exports.workDeleteApi = workDeleteApi;
exports.workDetailApi = workDetailApi;
exports.workListApi = workListApi;
exports.workUpdateApi = workUpdateApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 作品列表
 */
function workListApi(data) {
  return _request.default.get('meta/works/list', data);
}

/**
 * @description 作品详情
 */
function workDetailApi(worksId) {
  return _request.default.get("meta/works/detail/".concat(worksId));
}

/**
 * @description 创建作品
 */
function workCreateApi(data) {
  return _request.default.post('meta/works/create', data);
}

/**
 * @description 修改作品
 */
function workUpdateApi(data) {
  return _request.default.post('meta/works/update', data);
}

/**
 * @description 删除作品
 */
function workDeleteApi(worksId) {
  return _request.default.post("meta/works/delete/".concat(worksId));
}

// 作品排序
function _changeWorkSort(state) {
  return _request.default.post("/meta/works/switchSort/".concat(state.id), state);
}