"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.growthValueGetConfigApi = growthValueGetConfigApi;
exports.growthValueSetConfigApi = growthValueSetConfigApi;
exports.memberCardRefundApi = memberCardRefundApi;
exports.memberDelApi = memberDelApi;
exports.memberExtendEndTimeApi = memberExtendEndTimeApi;
exports.memberOrderListApi = memberOrderListApi;
exports.memberStatisticsAgeChartsApi = memberStatisticsAgeChartsApi;
exports.memberStatisticsApi = memberStatisticsApi;
exports.memberStatisticsGrowthValueRankApi = memberStatisticsGrowthValueRankApi;
exports.memberStatisticsOrderChartsApi = memberStatisticsOrderChartsApi;
exports.memberStatisticsProvinceChartsApi = memberStatisticsProvinceChartsApi;
exports.memberStatisticsRegisterSourceChartsApi = memberStatisticsRegisterSourceChartsApi;
exports.memberStatisticsSalesAmountRankApi = memberStatisticsSalesAmountRankApi;
exports.memberStatisticsSalesCountRankApi = memberStatisticsSalesCountRankApi;
exports.memberStatisticsSexChartsApi = memberStatisticsSexChartsApi;
exports.memberSwitchStatusApi = memberSwitchStatusApi;
exports.membershipCardCreateApi = membershipCardCreateApi;
exports.membershipCardDetailApi = membershipCardDetailApi;
exports.membershipCardEditSortApi = membershipCardEditSortApi;
exports.membershipCardListApi = membershipCardListApi;
exports.membershipCardSwitchStatusApi = membershipCardSwitchStatusApi;
exports.membershipCardUpdateApi = membershipCardUpdateApi;
exports.membershipLevelCreateApi = membershipLevelCreateApi;
exports.membershipLevelDelApi = membershipLevelDelApi;
exports.membershipLevelDetailApi = membershipLevelDetailApi;
exports.membershipLevelListApi = membershipLevelListApi;
exports.membershipLevelUpdateApi = membershipLevelUpdateApi;
exports.membershipRightCreateApi = membershipRightCreateApi;
exports.membershipRightDelApi = membershipRightDelApi;
exports.membershipRightDetailApi = membershipRightDetailApi;
exports.membershipRightEditApi = membershipRightEditApi;
exports.membershipRightListApi = membershipRightListApi;
exports.openCardApi = openCardApi;
exports.userCardBuyRecordApi = userCardBuyRecordApi;
exports.userMemberListApi = userMemberListApi;
exports.venueSignListApi = venueSignListApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 用户会员列表
 */
function userMemberListApi(data) {
  return _request.default.get('membership/userMember/lst', data);
}

/**
 * @description 会员权益列表
 */
function membershipRightListApi(data) {
  return _request.default.get('membership/right/lst', data);
}

/**
 * @description 会员权益详情
 */
function membershipRightDetailApi(id) {
  return _request.default.get("membership/right/detail/".concat(id));
}

/**
 * @description 新增会员权益
 */
function membershipRightCreateApi(id, data) {
  return _request.default.post('membership/right/create', data);
}

/**
 * @description 修改会员权益
 */
function membershipRightEditApi(id, data) {
  return _request.default.post("membership/right/update/".concat(id), data);
}

/**
 * @description 删除会员权益
 */
function membershipRightDelApi(id) {
  return _request.default.post("membership/right/delete/".concat(id));
}

/**
 * @description 会员卡列表
 */
function membershipCardListApi(data) {
  return _request.default.get("membership/card/lst", data);
}

/**
 * @description 会员卡详情
 */
function membershipCardDetailApi(id) {
  return _request.default.get("membership/card/detail/".concat(id));
}

/**
 * @description 修改会员卡排序
 */
function membershipCardEditSortApi(id, data) {
  return _request.default.post("membership/card/sort/".concat(id), data);
}

/**
 * @description 创建会员卡
 */
function membershipCardCreateApi(id, data) {
  return _request.default.post("membership/card/create", data);
}

/**
 * @description 修改会员卡
 */
function membershipCardUpdateApi(id, data) {
  return _request.default.post("membership/card/update/".concat(id), data);
}

/**
 * @description 修改会员卡状态
 */
function membershipCardSwitchStatusApi(id, data) {
  return _request.default.post("membership/card/switchStatus/".concat(id), data);
}

/**
 * @description 查看会员等级列表
 */
function membershipLevelListApi(data) {
  return _request.default.get("membership/level/lst", data);
}
/**
 * @description 查看会员等级
 */
function membershipLevelDetailApi(id) {
  return _request.default.get("membership/level/detail/".concat(id));
}

/**
 * @description 创建会员等级
 */
function membershipLevelCreateApi(id, data) {
  return _request.default.post("membership/level/create", data);
}
/**
 * @description 修改会员等级
 */
function membershipLevelUpdateApi(id, data) {
  return _request.default.post("membership/level/update/".concat(id), data);
}

/**
 * @description 删除会员等级
 */
function membershipLevelDelApi(id) {
  return _request.default.post("membership/level/delete/".concat(id));
}

/**
 * @description 获取升级任务
 */
function growthValueGetConfigApi() {
  return _request.default.get("membership/userMember/growthValue/getConfig");
}

/**
 * @description 设置升级任务
 */
function growthValueSetConfigApi(data) {
  return _request.default.post("membership/userMember/growthValue/setConfig", data);
}

/**
 * @description 会员卡购买记录
 */
function userCardBuyRecordApi(data) {
  return _request.default.get("membership/userCard/lst", data);
}

/**
 * @description 延期
 */
function memberExtendEndTimeApi(data) {
  return _request.default.post("membership/userCard/extendEndTime", data);
}

/**
 * @description 修改状态
 */
function memberSwitchStatusApi(data) {
  return _request.default.post("membership/userCard/switchStatus", data);
}

/**
 * @description 退款
 */
function memberCardRefundApi(data) {
  return _request.default.post("membership/userCard/refund", data);
}

/**
 * @description 场馆签到
 */
function venueSignListApi(data) {
  return _request.default.get("membership/venueSign/lst", data);
}

/**
 * @description 会员统计
 */
function memberStatisticsApi(data) {
  return _request.default.get("membership/statistics/main", data);
}

/**
 * @description 会员统计 - 会员卡购买
 */
function memberStatisticsOrderChartsApi(data) {
  return _request.default.get("membership/statistics/orderCharts", data);
}

/**
 * @description 会员统计 - 城市分布
 */
function memberStatisticsProvinceChartsApi(data) {
  return _request.default.get("membership/statistics/provinceCharts", data);
}

/**
 * @description 会员统计 - 性别分布
 */
function memberStatisticsSexChartsApi(data) {
  return _request.default.get("membership/statistics/sexCharts", data);
}

/**
 * @description 会员统计 - 年龄分布
 */
function memberStatisticsAgeChartsApi(data) {
  return _request.default.get("membership/statistics/ageCharts", data);
}

/**
 * @description 会员统计 - 注册渠道
 */
function memberStatisticsRegisterSourceChartsApi(data) {
  return _request.default.get("membership/statistics/registerSourceCharts", data);
}

/**
 * @description 会员统计 - 会员卡销售排行
 */
function memberStatisticsSalesCountRankApi(data) {
  return _request.default.get("membership/statistics/salesCountRank", data);
}

/**
 * @description 会员统计 - 会员卡购买金额排行
 */
function memberStatisticsSalesAmountRankApi(data) {
  return _request.default.get("membership/statistics/salesAmountRank", data);
}

/**
 * @description 会员统计 - 成长值排行
 */
function memberStatisticsGrowthValueRankApi(data) {
  return _request.default.get("membership/statistics/growthValueRank", data);
}

/**
 * @description 会员订单
 */
function memberOrderListApi(data) {
  return _request.default.get("membership/userCard/order/lst", data);
}

/**
 * @description 会员卡删除
 */
function memberDelApi(id) {
  return _request.default.post("membership/card/delete/".concat(id));
}

/**
 * @description 会员卡开卡
 */
function openCardApi(id, data) {
  return _request.default.post("membership/userCard/openCard/".concat(id), data);
}