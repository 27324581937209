"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    listLoading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      searchQuery: {}
    };
  },
  methods: {
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.$emit('tableChange', e);
    },
    openUserAction: function openUserAction(val, uid) {
      this.$emit('openUserAction', {
        uid: uid,
        status: "".concat(val)
      });
    },
    openUserInfoAction: function openUserInfoAction(uid) {
      this.$emit('openUserInfoAction', uid);
    }
  }
};