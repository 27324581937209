var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "20px" } },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "熔炉名称：", prop: "name", required: "" } },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入熔炉名称" },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "图片上传：", required: "" } }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("div", [
                _vm.ruleForm.config.background_image
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "upload-image flex align-items-c justify-c",
                        on: {
                          click: function ($event) {
                            return _vm.changeImg(
                              "",
                              "background_image",
                              "config"
                            )
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.ruleForm.config.background_image + "!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delImg("background_image", "config")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "upload-image flex align-items-c justify-c",
                        on: {
                          click: function ($event) {
                            return _vm.changeImg(
                              "",
                              "background_image",
                              "config"
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-picture" })]
                    ),
                _vm._v(" "),
                _c("div", [_vm._v("主图")]),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "margin-left": "20px" } }, [
                _vm.ruleForm.config.button_image
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "upload-image flex align-items-c justify-c",
                        on: {
                          click: function ($event) {
                            return _vm.changeImg("", "button_image", "config")
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.ruleForm.config.button_image + "!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delImg("button_image", "config")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "upload-image flex align-items-c justify-c",
                        on: {
                          click: function ($event) {
                            return _vm.changeImg("", "button_image", "config")
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-picture" })]
                    ),
                _vm._v(" "),
                _c("div", [_vm._v("按钮图")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { color: "#999", "line-height": "13px" } },
                  [_vm._v("200*75 png")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "页面背景色：", required: "" } },
            [
              _c(
                "div",
                { staticStyle: { width: "250px", height: "34px" } },
                [
                  _c("colour", {
                    attrs: { colour: "#143323", "no-alpha": "1" },
                    model: {
                      value: _vm.ruleForm.config.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm.config, "background_color", $$v)
                      },
                      expression: "ruleForm.config.background_color",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "文字颜色：", required: "" } }, [
            _c(
              "div",
              { staticStyle: { width: "250px", height: "28px" } },
              [
                _c("colour", {
                  attrs: { colour: "#143323", "no-alpha": "1" },
                  model: {
                    value: _vm.ruleForm.config.text_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm.config, "text_color", $$v)
                    },
                    expression: "ruleForm.config.text_color",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.saveAction("ruleForm")
                      },
                    },
                  },
                  [_vm._v("确认提交")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }