var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "setting-table-box" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "setting-table-list" }, [
          _c(
            "div",
            { staticClass: "setting-table-type" },
            [
              _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择对账人数",
                    filterable: "",
                    "multiple-limit": 2,
                  },
                  model: {
                    value:
                      _vm.formValidateJson.entrust_check_order_people_number,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "entrust_check_order_people_number",
                        $$v
                      )
                    },
                    expression:
                      "formValidateJson.entrust_check_order_people_number",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "单人对账", value: "1" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "双人对账", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-content" },
            [
              _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "超级管理员",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "multiple-limit": 2,
                  },
                  model: {
                    value: _vm.formValidateJson.entrust_check_order_audit_admin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "entrust_check_order_audit_admin",
                        $$v
                      )
                    },
                    expression:
                      "formValidateJson.entrust_check_order_audit_admin",
                  },
                },
                _vm._l(_vm.adminarr, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-people" },
            [
              _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择审核员",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "multiple-limit": 2,
                  },
                  model: {
                    value:
                      _vm.formValidateJson.entrust_check_order_audit_admin_two,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "entrust_check_order_audit_admin_two",
                        $$v
                      )
                    },
                    expression:
                      "formValidateJson.entrust_check_order_audit_admin_two",
                  },
                },
                _vm._l(_vm.adminarr, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { type: "primary", size: "default" },
          on: { click: _vm.save },
        },
        [_vm._v("保存")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-table-list" }, [
      _c("div", { staticClass: "setting-table-type" }, [_vm._v("对账人数")]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-table-content" }, [_vm._v("对账人")]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-table-people" }, [_vm._v("复核人")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }