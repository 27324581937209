"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _digitalHall = require("@/api/digitalHall");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _nft = require("@/api/nft");
var _product = require("@/api/product");
var _auction = require("@/api/auction");
var _system = require("@/api/system");
var _work = require("@/api/work");
var _live = require("@/api/live");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      loading: true,
      step: Number(this.$route.query.s || 1),
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20
      },
      // 第一步
      selectedItem: {},
      exhibitionTemplateId: '',
      merTempId: '',
      num: '',
      // 第二步
      ruleForm: {
        space_name: '',
        sort: '',
        content: '',
        intro: ''
      },
      rules: {
        space_name: [{
          required: true,
          message: '请输入空间名称',
          trigger: 'blur'
        }],
        intro: [{
          required: true,
          message: '请填写空间描述',
          trigger: 'blur'
        }]
      },
      // 第三步
      work_type: 'exhibition',
      // exhibition:作品 product:商品 auction:拍品 live:直播  nft:道具
      workPage: 1,
      workLimit: 20,
      workTotal: 0,
      workList: [],
      works_data: [],
      works_data_ids: [],
      keyword: '',
      file_type: ''
    };
  },
  created: function created() {
    this.tableFrom.page = this.$route.query.p || 1;
    this.num = this.$route.query.num || '';
    var f = localStorage.getItem('exhibitionIntro');
    if (f) {
      this.ruleForm = JSON.parse(f);
    }
    if (this.step !== 1) {
      this.exhibitionTemplateId = this.$route.query.exhibitionTemplateId || '';
      this.merTempId = this.$route.query.merTempId || '';
      if (!this.merTempId || !this.exhibitionTemplateId) {
        this.step = 1;
      }
      this.selectedItem = {
        memberNftId: this.exhibitionTemplateId,
        max_num: this.num
      };
    }
    this.getList();
    this.loadProductList();
  },
  methods: {
    backAction: function backAction() {
      this.$router.back();
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _digitalHall.getTempListApi)(this.tableFrom).then(function (res) {
        if (_this.$route.query.exhibitionTemplateId) {
          var listIds = res.data.list.map(function (m) {
            return m.memberNftId;
          });
          var index = listIds.indexOf(Number(_this.$route.query.exhibitionTemplateId));
          if (index !== -1) {
            _this.selectedItem = res.data.list[index];
            _this.num = _this.selectedItem.boothsCount;
          }
        } else if (res.data.list[0]) {
          _this.selectedItem = res.data.list[0];
          _this.num = 20;
          // this.num = this.selectedItem.boothsCount;
        }
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
        _this.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    loadProductList: function loadProductList(num) {
      var _this2 = this;
      if (num) this.workPage = num;
      var data = {
        page: this.workPage,
        limit: this.workLimit,
        keyword: this.keyword || '',
        attachment_name: this.keyword || ''
      };
      var action = _work.workListApi;
      switch (this.work_type) {
        case "exhibition":
          data.title = this.keyword;
          // data.file_type = this.file_type;
          action = _work.workListApi;
          break;
        case "product":
          data.mer_cate_id = this.mer_cate_id;
          action = _product.productLstApi;
          break;
        case "auction":
          data.cate_id = this.mer_cate_id;
          action = _auction.auctionList;
          break;
        case "live":
          action = _live.liveList;
          break;
        case "nft":
          action = _digitalHall.getTempListApi;
          data.type = 3;
          break;
      }
      action(data).then(function (res) {
        var d = [];
        if (_this2.work_type == 'exhibition') {
          res.data.list.forEach(function (m) {
            var t = 1;
            switch (m.file_type) {
              case 'image':
                t = 1;
                break;
              case 'video':
                t = 2;
                break;
              case 'audio':
                t = 3;
                break;
              case '3d':
                t = 4;
                break;
            }
            d.push({
              id: m.works_id,
              type: t,
              name: m.title,
              url: m.file_url + (t == 1 ? '!m640' : ''),
              coverImage: m.file_cover + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 1,
                type: "work"
              }
            });
          });
        } else if (_this2.work_type == "auction") {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.product_id,
              type: 1,
              name: m.product.store_name,
              url: m.product.image + '!m640',
              coverImage: m.product.image + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 1,
                type: "auction",
                lot: m.lot
              }
            });
          });
        } else if (_this2.work_type == "product") {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.product_id,
              type: 1,
              name: m.store_name,
              url: m.image + '!m640',
              coverImage: m.image + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 3,
                type: "product"
              }
            });
          });
        } else if (_this2.work_type == "nft") {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.memberNftId,
              type: 1,
              name: m.nftName,
              url: m.modelFileUrl,
              coverImage: m.picture + '!webp2600',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 7,
                type: "nft"
              }
            });
          });
        } else if (_this2.work_type.indexOf('material_') == 0) {
          res.data.list.forEach(function (m) {
            var obj = {
              id: m.attachment_id,
              type: m.attachment_type,
              name: m.attachment_name,
              url: m.attachment_src + (m.attachment_type == 1 ? '!m640' : ''),
              coverImage: m.attachment_src + (m.attachment_type == 1 ? '!m640' : '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'),
              exhibitionType: "material",
              active: false,
              extends: {
                work_type: 5,
                type: "material"
              }
            };
            d.push(obj);
          });
        } else if (_this2.work_type == "live") {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.live_id,
              type: 5,
              name: m.live_title,
              url: m.cover_image + '!m640',
              coverImage: m.cover_image + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 6,
                type: "live"
              }
            });
          });
        } else {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.product_id,
              type: 1,
              name: m.product.store_name,
              url: m.product.image + '!m640',
              coverImage: m.product.image + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 1,
                type: "exhibition"
              }
            });
          });
        }
        _this2.workList = d;
        _this2.workTotal = res.data.count;

        // this.workList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    productPageChange: function productPageChange(page) {
      this.workPage = page;
      this.loadProductList('');
    },
    productHandleSizeChange: function productHandleSizeChange(val) {
      this.workLimit = val;
      this.loadProductList('');
    },
    selectExhibition: function selectExhibition(item) {
      // this.selected = item.exhibition_template_id;
      // this.configBooths = item.config.booths;
      this.num = item.boothsCount;
      this.selectedItem = item;
    },
    goNextAction: function goNextAction() {
      var _this3 = this;
      if (this.step === 1) {
        if (!this.selectedItem.memberNftId) {
          this.$message.error('请先选择模板');
          return false;
        }
        this.$router.replace("/merchant/digital_hall/manage/create?s=2&exhibitionTemplateId=".concat(this.selectedItem.memberNftId, "&num=").concat(this.num, "&p=").concat(this.tableFrom.page));
        this.ruleForm = {
          space_name: '',
          sort: '',
          content: '',
          intro: ''
        };
        this.step = 2;
      } else if (this.step === 2) {
        localStorage.setItem('exhibitionIntro', JSON.stringify(this.ruleForm));
        this.$router.replace("/merchant/digital_hall/manage/create?s=3&exhibitionTemplateId=".concat(this.selectedItem.memberNftId, "&num=").concat(this.num, "&p=").concat(this.tableFrom.page));
        this.step = 3;
      } else if (this.step === 3) {
        if (this.lock) return false;
        this.lock = true;
        var mer_id = this.$store.getters.mer_id;
        var data = {
          space_model_id: this.selectedItem.memberNftId,
          space_image: this.selectedItem.picture,
          space_name: this.ruleForm.space_name,
          intro: this.ruleForm.intro,
          content: this.ruleForm.content,
          sort: this.ruleForm.sort || 0,
          mer_id: mer_id
        };
        var arr = [];
        this.works_data.forEach(function (m, i) {
          arr.push({
            extends: m.extends,
            id: m.id,
            type: m.type
          });
        });
        data.extends = {
          products: JSON.stringify(arr)
        };
        (0, _digitalHall.userExhibitionCreate)(data).then(function () {
          _this3.$message.success('操作成功');
          _this3.lock = false;
          localStorage.setItem('exhibitionIntro', '');
          _this3.$router.back();
        }).catch(function () {
          _this3.lock = false;
        });

        // localStorage.setItem('exhibitionIntro', '');
      }
    },
    goPrevAction: function goPrevAction() {
      if (this.step === 2) {
        this.$router.replace("/merchant/digital_hall/manage/create?s=1&exhibitionTemplateId=".concat(this.selectedItem.memberNftId));
        this.step = 1;
      } else if (this.step === 3) {
        var f = localStorage.getItem('exhibitionIntro');
        if (f) {
          this.ruleForm = JSON.parse(f);
        }
        this.$router.replace("/merchant/digital_hall/manage/create?s=2&exhibitionTemplateId=".concat(this.selectedItem.memberNftId, "&num=").concat(this.num, "&p=").concat(this.tableFrom.page));
        this.step = 2;
      } else {
        this.$router.back();
      }
    },
    selectWork: function selectWork(item) {
      var has = this.works_data_ids.indexOf(item.id);
      if (has === -1) {
        if (this.selectedItem && this.works_data_ids.length >= 20) {
          // if (this.selectedItem && this.works_data_ids.length >= this.selectedItem.boothsCount) {
          this.$message.error("\u6700\u591A\u9009\u62E9".concat(this.num, "\u4EF6\u5C55\u54C1"));
          return false;
        }
        this.works_data.push(item);
      } else {
        this.works_data.splice(has, 1);
      }
      this.works_data_ids = this.works_data.map(function (m) {
        return m.id;
      });
    },
    changWorkType: function changWorkType() {
      this.workPage = 1;
      this.file_type = '';
      this.workList = [];
      this.workTotal = 0;
      this.loadProductList();
    },
    previewspace: function previewspace(item) {
      window.open("https://test.ipip.art/designer/modelspacePreview?id=".concat(item.productId));
    }
  }
};