"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _marketing = require("@/api/marketing");
var _system = require("@/api/system");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {
        title: '',
        keyword: '',
        poster_image: ''
      },
      prize_data: [{
        prize_type: 'product',
        prize_name: '',
        receive_price: '',
        help_count: 10,
        prize_num: '',
        day_receive_limit_num: '',
        prize_image: '',
        prize_relation_id: '',
        prize_relation_name: '',
        receive_type: 1,
        max_receive_num: ''
      }],
      rules: {
        title: {
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        }
      },
      open_scan_reply_tweet: true,
      open_keyword_reply_tweet: true,
      gzhContent: {
        keyword_reply_message: '',
        keyword_reply_tweet: {
          is_open: 1,
          title: '',
          url: '',
          image: '',
          description: ''
        },
        scan_reply_message: '',
        scan_reply_tweet: {
          is_open: 1,
          title: '',
          url: '',
          image: '',
          description: ''
        }
      },
      setType: '',
      showSet: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })),
  created: function created() {
    var _this = this;
    (0, _system.getConfigClassKeys)('user_invite').then(function (res) {
      _this.ruleForm = res.data;
    });
  },
  methods: {
    changeImg: function changeImg(i) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.prize_data[i].prize_image = img[0];
      }, 1, '1');
    },
    changeDescImg: function changeDescImg() {
      var _this3 = this;
      this.$modalUpload(function (img) {
        _this3.gzhContent[_this3.setType].image = img[0];
      }, 1, '1');
    },
    uploadPosterAction: function uploadPosterAction() {
      var _this4 = this;
      this.$modalUpload(function (img) {
        _this4.ruleForm.poster_image = img[0];
        (0, _system.configClassKeysSave)('user_invite', _this4.ruleForm);
      }, 1, '1');
    },
    backAction: function backAction() {
      this.$router.back();
    },
    handleAdditem: function handleAdditem(view, i) {
      console.log(i);
      this.changeIndex = i;
      this.$refs[view].changeshow();
    },
    addPrizeAction: function addPrizeAction() {
      this.prize_data.push({
        prize_type: 'product',
        prize_name: '',
        receive_price: '',
        help_count: 10,
        prize_num: '',
        day_receive_limit_num: '',
        prize_image: '',
        prize_relation_id: '',
        prize_relation_name: '',
        receive_type: 1,
        max_receive_num: ''
      });
    },
    delPrizeAction: function delPrizeAction(i) {
      this.prize_data.splice(i, 1);
    },
    editTypeAction: function editTypeAction(i) {
      var d = this.prize_data[i];
      d.prize_image = '';
      d.prize_name = '';
      d.prize_relation_name = '';
      d.prize_relation_id = '';
      this.prize_data[i] = d;
    },
    selectMessage: function selectMessage(data) {
      if (data.data && data.data[0]) {
        var val = data.data[0];
        var d = this.prize_data[this.changeIndex];
        if (!d.prize_image) {
          d.prize_image = val.image.split('!')[0];
        }
        if (!d.prize_name) {
          d.prize_name = val.name;
        }
        if (data.type === 'nft_blind_box') {
          d.prize_relation_id = val.p_id;
        } else {
          d.prize_relation_id = val.id;
        }
        d.prize_relation_name = val.name;
        this.prize_data[this.changeIndex] = d;
      }
    },
    showSetAction: function showSetAction(type) {
      this.setType = type;
      this.showSet = true;
    },
    setOkAction: function setOkAction() {
      if (this.setType === 'keyword_reply_tweet' || this.setType === 'scan_reply_tweet') {
        if (!this.gzhContent[this.setType].title || !this.gzhContent[this.setType].url || !this.gzhContent[this.setType].image || !this.gzhContent[this.setType].description) {
          this.$message.error('请把信息设置完整');
          return false;
        }
        this.ruleForm[this.setType] = JSON.parse(JSON.stringify(this.gzhContent[this.setType]));
      } else {
        if (!this.gzhContent[this.setType]) {
          this.$message.error('请设置文字');
          return false;
        }
        this.ruleForm[this.setType] = JSON.parse(JSON.stringify(this.gzhContent[this.setType]));
      }
      this.showSet = false;
    },
    saveAction: function saveAction(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var prize_data = [];
          var err = '';
          _this5.prize_data.forEach(function (m) {
            if (m.prize_relation_id && m.prize_num && m.prize_num > 0 && m.help_count && m.help_count > 0 && (m.receive_type === 1 || m.receive_type === 2 && m.max_receive_num && m.max_receive_num <= m.prize_num)) {
              prize_data.push(m);
            } else {
              err = '请正确设置奖品，奖品必须设置总数与助力设置';
            }
          });
          if (!_this5.ruleForm.poster_img) {
            _this5.$message.error('请上传海报');
            return false;
          }
          if (!_this5.ruleForm.keyword) {
            _this5.$message.error('请设置触发活动关键词');
            return false;
          }
          if (!_this5.ruleForm.keyword_reply_message) {
            _this5.$message.error('请设置报名成功提示文字');
            return false;
          }
          if (_this5.ruleForm.keyword_reply_tweet.is_open && !_this5.ruleForm.keyword_reply_tweet.title) {
            _this5.$message.error('请设置报名成功推送内容');
            return false;
          }
          // if (!this.ruleForm.scan_reply_message) {
          //   this.$message.error('请设置关注后提示文字');
          //   return false;
          // }
          // if (
          //   this.ruleForm.scan_reply_tweet.is_open &&
          //   !this.ruleForm.scan_reply_tweet.title
          // ) {
          //   this.$message.error('请设置关注后推送内容');
          //   return false;
          // }
          if (err || !prize_data.length) {
            _this5.$message.error(err);
            return false;
          }
          var data = JSON.parse(JSON.stringify(_this5.ruleForm));
          data.prize_data = _this5.prize_data;
          console.log(data);
          var loading = _this5.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _marketing.fissionCreateApi;
          if (_this5.id) {
            data.id = _this5.id;
            action = _marketing.fissionEditApi;
          }
          action(data).then(function () {
            loading.close();
            _this5.$message.success('操作成功');
            _this5.$router.back();
          }).catch(function (msg) {
            loading.close();
            _this5.$message.error(msg);
          });
        } else {
          _this5.$message.error('请填写完整信息');
          return false;
        }
      });
    }
  }
};