var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "top-view", staticStyle: { padding: "20px" } },
        [
          _c(
            "el-radio-group",
            {
              attrs: { type: "button" },
              on: { change: _vm.changeStatusAction },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _vm.perssion1
                ? _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("身份管理"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.perssion2
                ? _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("员工管理"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.perssion4
                ? _c("el-radio-button", { attrs: { label: "4" } }, [
                    _vm._v("部门管理"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.perssion3
                ? _c("el-radio-button", { attrs: { label: "3" } }, [
                    _vm._v("操作日志"),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.status == "1" ? _c("systemRole") : _vm._e(),
      _vm._v(" "),
      _vm.status == "2" ? _c("systemAdmin") : _vm._e(),
      _vm._v(" "),
      _vm.status == "3" ? _c("systemLog") : _vm._e(),
      _vm._v(" "),
      _vm.status == "4" ? _c("systemDepartment") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }