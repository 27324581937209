"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _marketing = require("@/api/marketing");
var _vuex = require("vuex");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _nftblind = _interopRequireDefault(require("@/components/customChildren/nftblind.vue"));
var _blindBox = _interopRequireDefault(require("@/components/customChildren/blindBox.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    selectProduct: _goods.default,
    Nft: _nft.default,
    Tinymce: _Tinymce.default,
    slider: _slider.default,
    colour: _colour.default,
    Nftblind: _nftblind.default,
    Productblind: _blindBox.default
  },
  data: function data() {
    return {
      ruleForm: {
        title: '',
        cover_image: '',
        date_time: [],
        background_image: '',
        background_color: '#FFFFFF',
        content_style: {
          background_image: '',
          background_color: '#FFFFFF',
          share_image: '',
          viewMargin: 30,
          viewPadding: 50,
          viewRadius: 16,
          title_color: '#000000',
          title_font_size: 36,
          text_color: '#000000',
          text_font_size: 28
        },
        help_rule_data: {
          is_new_user: 1,
          reserved_number: 1,
          help_success_Tips: ''
        },
        rule_message: ''
      },
      prize_data: [{
        prize_type: 'product',
        prize_name: '',
        help_count: 10,
        buy_num: 0,
        prize_num: '',
        receive_limit_num: '',
        prize_image: '',
        prize_relation_id: '',
        prize_relation_name: ''
      }],
      buy_product_data: [],
      rules: {
        cover_image: {
          required: true,
          message: '请上传封面图',
          trigger: 'change'
        },
        title: {
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        },
        date_time: [{
          required: true,
          message: '请选择活动时间',
          trigger: 'change'
        }]
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })),
  created: function created() {
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.loadDetail();
    }
  },
  methods: {
    loadDetail: function loadDetail() {
      var _this = this;
      (0, _marketing.helpDetailApi)(this.id).then(function (res) {
        var data = res.data;
        _this.ruleForm = {
          title: data.title,
          cover_image: data.cover_image,
          date_time: [data.start_time * 1000, data.end_time * 1000],
          background_image: data.background_image,
          background_color: data.background_color,
          help_rule_data: {
            is_new_user: data.help_rule_data.is_new_user,
            reserved_number: data.help_rule_data.reserved_number,
            help_success_Tips: data.help_rule_data.help_success_Tips
          },
          rule_message: data.rule_message,
          content_style: data.content_style ? data.content_style : {
            background_image: '',
            background_color: '#FFFFFF',
            share_image: '',
            viewMargin: 30,
            viewPadding: 50,
            viewRadius: 16,
            title_color: '#000000',
            title_font_size: 36,
            text_color: '#000000',
            text_font_size: 28
          }
        };
        if (data.rule_message) {
          _this.$refs['editor'].setContent(data.rule_message);
        }
        if (data.buyProductData && data.buyProductData.length > 0) {
          _this.buy_product_data = data.buyProductData.map(function (m) {
            var t = '';
            switch (m.product_type) {
              case 0:
                t = 'product';
                break;
              case 8:
                t = 'product_nft';
                break;
              case 9:
                t = 'product_nft_blind';
                break;
            }
            return {
              type: t,
              name: m.store_name,
              id: m.product_id,
              image: m.image
            };
          });
        }
        _this.prize_data = data.prize;
      }).catch(function (_ref) {
        var message = _ref.message;
        _this.$message.error(message);
        _this.$router.back();
      });
    },
    changeImg: function changeImg(i, key, otherKey) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        if (key) {
          if (otherKey) {
            _this2.ruleForm[otherKey][key] = img[0];
          } else {
            _this2.ruleForm[key] = img[0];
          }
          if (key === 'cover_image') {
            _this2.$refs.ruleForm.validateField([key], function (Error) {
              if (!Error) {
                // 验证通过
                console.log('通过了');
              } else {
                console.log('验证不通过');
              }
            });
          }
        } else {
          _this2.prize_data[i].prize_image = img[0];
        }
      }, 1, '1');
    },
    backAction: function backAction() {
      this.$router.back();
    },
    handleAdditem: function handleAdditem(view, i) {
      this.changeType = '';
      this.changeIndex = i;
      this.$refs[view].changeshow();
    },
    handleBuyAdditem: function handleBuyAdditem(view, i) {
      this.changeType = 'buy';
      this.changeIndex = i;
      this.$refs[view].changeshow();
    },
    addPrizeAction: function addPrizeAction() {
      this.prize_data.push({
        prize_type: 'product',
        prize_name: '',
        receive_price: '',
        help_count: 10,
        buy_num: 0,
        prize_num: '',
        receive_limit_num: '',
        prize_image: '',
        prize_relation_id: '',
        prize_relation_name: ''
      });
    },
    delPrizeAction: function delPrizeAction(i) {
      this.prize_data.splice(i, 1);
    },
    editTypeAction: function editTypeAction(i) {
      var d = this.prize_data[i];
      d.prize_image = '';
      d.prize_name = '';
      d.prize_relation_name = '';
      d.prize_relation_id = '';
      this.prize_data[i] = d;
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      if (data.data && data.data[0]) {
        var val = data.data[0];
        if (this.changeType === 'buy') {
          var d = this.buy_product_data[this.changeIndex];
          d.image = val.image.split('!')[0];
          d.name = val.name;
          if (val.p_id) {
            d.id = val.p_id;
          } else {
            d.id = val.id;
          }
          this.buy_product_data[this.changeIndex] = d;
        } else {
          var _d = this.prize_data[this.changeIndex];
          if (!_d.prize_image) {
            _d.prize_image = val.image.split('!')[0];
          }
          if (!_d.prize_name) {
            _d.prize_name = val.name;
          }
          if (val.p_id) {
            _d.prize_relation_id = val.p_id;
          } else {
            _d.prize_relation_id = val.id;
          }
          _d.stock = val.stock;
          _d.prize_relation_name = val.name;
          this.prize_data[this.changeIndex] = _d;
        }
      }
    },
    saveAction: function saveAction(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var prize_data = [];
          var error = '';
          _this3.prize_data.forEach(function (m) {
            if (!m.prize_relation_id || !m.prize_num || !m.help_count && !m.buy_num) {
              error = '请正确设置奖品，奖品必须设置数量与领奖条件';
              prize_data.push(m);
            }
            console.log(m.prize_num, m.stock, m.prize_num > m.stock);
            if (m.prize_num > m.stock) {
              error = '奖品总数超过奖品库存';
            }
          });
          if (error) {
            _this3.$message.error(error);
            return false;
          }
          var buy_product_data = [];
          _this3.buy_product_data.forEach(function (m) {
            if (m.id) {
              buy_product_data.push(m);
            }
          });
          var data = JSON.parse(JSON.stringify(_this3.ruleForm));
          if (!data.cover_image) {
            _this3.$message.error('请上传封面图');
            return false;
          }
          data.prize_data = _this3.prize_data;
          if (buy_product_data.length > 0) {
            data.buyProductData = buy_product_data.map(function (m) {
              return {
                product_id: m.id
              };
            });
          }
          data.start_time = data.date_time[0] / 1000;
          data.end_time = data.date_time[1] / 1000;
          console.log(data);
          var loading = _this3.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _marketing.helpCreatedApi;
          if (_this3.id) {
            data.help_invite_id = _this3.id;
            action = _marketing.helpEditApi;
          }
          action(data).then(function () {
            loading.close();
            _this3.$message.success('操作成功');
            _this3.$router.back();
          }).catch(function (msg) {
            loading.close();
            _this3.$message.error(msg);
          });
        } else {
          _this3.$message.error('请填写完整信息');
          return false;
        }
      });
    },
    addBuyAction: function addBuyAction() {
      this.buy_product_data.push({
        type: 'product',
        name: '',
        image: '',
        id: ''
      });
    },
    editBuyTypeAction: function editBuyTypeAction(i) {
      var d = this.buy_product_data[i];
      d.image = '';
      d.name = '';
      d.id = '';
      this.buy_product_data[i] = d;
    },
    delBuyAction: function delBuyAction(i) {
      this.buy_product_data.splice(i, 1);
    },
    delImg: function delImg(key, otherKey) {
      if (otherKey) {
        this.ruleForm[otherKey][key] = '';
      } else {
        this.ruleForm[key] = '';
      }
    }
  }
};