var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "demo-input-suffix acea-row" },
              [
                _c(
                  "el-form",
                  { attrs: { inline: "", size: "small" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库名称：" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text", placeholder: "" },
                          model: {
                            value: _vm.tableFrom.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "name", $$v)
                            },
                            expression: "tableFrom.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库编号：" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text", placeholder: "" },
                          model: {
                            value: _vm.tableFrom.warehouse_no,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "warehouse_no", $$v)
                            },
                            expression: "tableFrom.warehouse_no",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库状态：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "status", $$v)
                              },
                              expression: "tableFrom.status",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "启用", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "禁用", value: "0" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号：" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text", placeholder: "" },
                          model: {
                            value: _vm.tableFrom.contact_phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "contact_phone", $$v)
                            },
                            expression: "tableFrom.contact_phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.search } },
            [_vm._v("筛选")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.createStore } },
            [_vm._v("创建货架")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.createPrint } },
            [_vm._v("打印货架标签")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            "row-key": function (row) {
              return row.shelf_id
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "tableEmpty" }, [
              _c("img", {
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              type: "selection",
              "reserve-selection": true,
              width: "55",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "shelf_id", label: "ID", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "shelf_no", label: "货架编码", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "remark", "min-width": "200" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "warehouse.name",
              label: "所属仓库",
              "min-width": "90",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "product_count",
              label: "货品数量",
              "min-width": "90",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "创建时间",
              "min-width": "90",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "8",
            attrs: {
              label: "操作",
              "min-width": "100",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(scope.row)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "commonedit" },
                        }),
                        _vm._v("编辑"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.del(scope.row)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "commondelete" },
                        }),
                        _vm._v("删除"),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建货架",
            visible: _vm.createFlag,
            width: "1000px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "100px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              !_vm.isedit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formValidate.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "type", $$v)
                            },
                            expression: "formValidate.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("单个创建"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("批量创建"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == 2
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "起始编号：", prop: "start_shelf_no" },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "46%" },
                          attrs: { placeholder: "请输入编号" },
                          model: {
                            value: _vm.formValidate.start_shelf_no,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "start_shelf_no", $$v)
                            },
                            expression: "formValidate.start_shelf_no",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "结束编号：", prop: "end_shelf_no" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "46%" },
                          attrs: { placeholder: "请输入编号" },
                          model: {
                            value: _vm.formValidate.end_shelf_no,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "end_shelf_no", $$v)
                            },
                            expression: "formValidate.end_shelf_no",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == 1
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "货架编号：", prop: "shelf_no" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "46%" },
                          attrs: { placeholder: "请输入仓库编号" },
                          model: {
                            value: _vm.formValidate.shelf_no,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "shelf_no", $$v)
                            },
                            expression: "formValidate.shelf_no",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属仓库：", prop: "warehouse_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "46%" },
                      attrs: {
                        placeholder: "请选择仓库",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "warehouse_id", $$v)
                        },
                        expression: "formValidate.warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeList, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "货架备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入货架备注" },
                    model: {
                      value: _vm.formValidate.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "remark", $$v)
                      },
                      expression: "formValidate.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("创建")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "打印货架标签",
            visible: _vm.showlabel,
            direction: "rtl",
            size: "350px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showlabel = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("span", [_vm._v(" 打印货架标签 ")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.bindPrint },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "printTable" } },
            _vm._l(_vm.labelarr, function (item, index) {
              return _c(
                "div",
                { key: "label" + index, staticClass: "printbox" },
                [
                  _c("div", { staticClass: "print-code" }, [
                    _vm._v(_vm._s(item.shelf_no)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "print-image" }, [
                    _c("img", {
                      attrs: {
                        src: "data:image/png;base64," + item.bar_code_image,
                        alt: "",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "print-code" }, [
                    _vm._v(_vm._s(item.warehouse.name)),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }