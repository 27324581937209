var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("header-breadcrumb", {
        attrs: {
          name: _vm.id ? "拍品详情" : "创建拍品",
          "has-back": true,
          "is-fixed": true,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "remove-control form-view mt20" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "拍品轮播图" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission:disabled",
                        value: _vm.id
                          ? "merchantAuctionUpdate>auction_images"
                          : "",
                        expression:
                          "id ? 'merchantAuctionUpdate>auction_images' : ''",
                        arg: "disabled",
                      },
                    ],
                    staticClass: "flex flex-wrap",
                  },
                  [
                    _vm.ruleForm.auction_images.length > 0
                      ? [
                          _c(
                            "draggable",
                            _vm._b(
                              {
                                staticClass:
                                  "board-column-content flex flex-wrap",
                                attrs: {
                                  list: _vm.ruleForm.auction_images,
                                  "set-data": _vm.setData,
                                },
                              },
                              "draggable",
                              _vm.$attrs,
                              false
                            ),
                            _vm._l(
                              _vm.ruleForm.auction_images,
                              function (m, i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "img-list mr10 mb10" },
                                  [
                                    _c("img", {
                                      attrs: { src: m + "!120a" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImageAction(
                                            i,
                                            "auction_images"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "del",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delImgAction(
                                              i,
                                              "auction_images"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-close",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "upload-image flex align-items-c justify-c mr10 mb10",
                        on: {
                          click: function ($event) {
                            return _vm.changeImg("auction_images")
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-picture" })]
                    ),
                    _vm._v(" "),
                    _vm.ruleForm.auction_video
                      ? _c(
                          "div",
                          {
                            staticClass: "img-list mr10 mb10",
                            staticStyle: { width: "88px" },
                            on: { click: _vm.showVideoAction },
                          },
                          [
                            _c("video", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: _vm.ruleForm.auction_video },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "del",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delVideoAction($event)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "upload-image flex align-items-c justify-c mr10 mb10",
                        on: { click: _vm.uploadVideo },
                      },
                      [_c("i", { staticClass: "el-icon-video-camera" })]
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "拍品名称", prop: "auction_name" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.id
                            ? "merchantAuctionUpdate>auction_name"
                            : "",
                          expression:
                            "id ? 'merchantAuctionUpdate>auction_name' : ''",
                          arg: "disabled",
                        },
                      ],
                    },
                    [
                      _c("el-input", {
                        staticClass: "w100",
                        attrs: { placeholder: "LOT号" },
                        model: {
                          value: _vm.ruleForm.lot,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "lot", $$v)
                          },
                          expression: "ruleForm.lot",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "w300 ml10",
                        attrs: { placeholder: "请输入拍品名称" },
                        model: {
                          value: _vm.ruleForm.auction_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "auction_name", $$v)
                          },
                          expression: "ruleForm.auction_name",
                        },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "maxWid",
                        attrs: { placeholder: "拍品一句话描述" },
                        model: {
                          value: _vm.ruleForm.auction_desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "auction_desc", $$v)
                          },
                          expression: "ruleForm.auction_desc",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "is-required", attrs: { label: "选择分类" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.id ? "merchantAuctionUpdate>cate_id" : "",
                          expression:
                            "id ? 'merchantAuctionUpdate>cate_id' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "flex align-items-c",
                    },
                    [
                      _c("el-cascader", {
                        staticClass: "w300",
                        attrs: {
                          options: _vm.categoryList,
                          props: {
                            expandTrigger: "hover",
                            value: "store_category_id",
                            label: "cate_name",
                          },
                        },
                        model: {
                          value: _vm.changeCategory,
                          callback: function ($$v) {
                            _vm.changeCategory = $$v
                          },
                          expression: "changeCategory",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10 ft12",
                          attrs: { type: "text" },
                          on: { click: _vm.loadCategory },
                        },
                        [_vm._v("刷新分类")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ft12",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.goPageAction(
                                "/merchant/product/classify"
                              )
                            },
                          },
                        },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "commonedit" },
                          }),
                          _vm._v("编辑分类"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择专场", prop: "album_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      model: {
                        value: _vm.ruleForm.album_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "album_id", $$v)
                        },
                        expression: "ruleForm.album_id",
                      },
                    },
                    _vm._l(_vm.zcList, function (m) {
                      return _c("el-option", {
                        key: m.album_id,
                        attrs: { value: m.album_id, label: m.album_name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "竞价阶梯", prop: "plus_price" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission:disabled",
                          value: _vm.id
                            ? "merchantAuctionUpdate>bid_range_id"
                            : "",
                          expression:
                            "id ? 'merchantAuctionUpdate>bid_range_id' : ''",
                          arg: "disabled",
                        },
                      ],
                      staticClass: "flex align-items-c",
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.bid_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "bid_type", $$v)
                            },
                            expression: "ruleForm.bid_type",
                          },
                        },
                        [
                          _vm.venue_type != 3
                            ? _c(
                                "el-radio",
                                { attrs: { label: 1 } },
                                [
                                  _c("el-input", {
                                    staticClass: "w200 ml10 mr10",
                                    attrs: {
                                      disabled: _vm.ruleForm.bid_type !== 1,
                                      placeholder: "输入固定加价幅度",
                                    },
                                    model: {
                                      value: _vm.ruleForm.plus_price,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "plus_price",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "ruleForm.plus_price",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { label: 2 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.ruleForm.bid_type !== 2,
                                    placeholder: "选择竞价阶梯",
                                  },
                                  model: {
                                    value: _vm.ruleForm.bid_range_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "bid_range_id",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.bid_range_id",
                                  },
                                },
                                _vm._l(_vm.bidRList, function (m) {
                                  return _c("el-option", {
                                    key: m.id,
                                    attrs: { value: m.id, label: m.title },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "ft12",
                              attrs: { type: "text" },
                              on: { click: _vm.loadRangeList },
                            },
                            [
                              _vm._v(
                                "\n              刷新竞价阶梯\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "ft12",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.goPageAction(
                                    "/merchant/systemForm/systemAuction/range"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              管理竞价阶梯\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.ruleForm.album_id
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拍卖时间" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "fWeight500",
                          staticStyle: { "padding-right": "5px" },
                        },
                        [_vm._v("开拍")]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w50",
                        attrs: {
                          "controls-position": "right",
                          min: 2010,
                          max: 2100,
                          controls: false,
                        },
                        model: {
                          value: _vm.startTime.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "year", $$v)
                          },
                          expression: "startTime.year",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w30",
                        attrs: { min: 1, max: 12, controls: false },
                        model: {
                          value: _vm.startTime.month,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "month", $$v)
                          },
                          expression: "startTime.month",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w30",
                        attrs: { min: 1, max: 31, controls: false },
                        model: {
                          value: _vm.startTime.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "date", $$v)
                          },
                          expression: "startTime.date",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w30",
                        attrs: { min: 0, max: 23, controls: false },
                        model: {
                          value: _vm.startTime.hour,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "hour", $$v)
                          },
                          expression: "startTime.hour",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("点")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "w30",
                        attrs: { min: 0, max: 59, controls: false },
                        model: {
                          value: _vm.startTime.minute,
                          callback: function ($$v) {
                            _vm.$set(_vm.startTime, "minute", $$v)
                          },
                          expression: "startTime.minute",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "plr10" }, [_vm._v("分")]),
                      _vm._v(" "),
                      [
                        _c(
                          "span",
                          {
                            staticClass: "ml20 fWeight500",
                            staticStyle: { "padding-right": "5px" },
                          },
                          [_vm._v("结拍")]
                        ),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: {
                            "controls-position": "right",
                            min: 2010,
                            max: 2100,
                            controls: false,
                          },
                          model: {
                            value: _vm.endTime.year,
                            callback: function ($$v) {
                              _vm.$set(_vm.endTime, "year", $$v)
                            },
                            expression: "endTime.year",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "w30",
                          attrs: { min: 1, max: 12, controls: false },
                          model: {
                            value: _vm.endTime.month,
                            callback: function ($$v) {
                              _vm.$set(_vm.endTime, "month", $$v)
                            },
                            expression: "endTime.month",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "w30",
                          attrs: { min: 1, max: 31, controls: false },
                          model: {
                            value: _vm.endTime.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.endTime, "date", $$v)
                            },
                            expression: "endTime.date",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "w30",
                          attrs: { min: 0, max: 23, controls: false },
                          model: {
                            value: _vm.endTime.hour,
                            callback: function ($$v) {
                              _vm.$set(_vm.endTime, "hour", $$v)
                            },
                            expression: "endTime.hour",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "plr10" }, [_vm._v("点")]),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticClass: "w30",
                          attrs: { min: 0, max: 59, controls: false },
                          model: {
                            value: _vm.endTime.minute,
                            callback: function ($$v) {
                              _vm.$set(_vm.endTime, "minute", $$v)
                            },
                            expression: "endTime.minute",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "plr10" }, [_vm._v("分")]),
                      ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-form-item", { attrs: { label: "价格信息" } }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "price",
                      attrs: { label: "起拍价", prop: "start_price" },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission:disabled",
                            value: _vm.id
                              ? "merchantAuctionUpdate>auction_price"
                              : "",
                            expression:
                              "id ? 'merchantAuctionUpdate>auction_price' : ''",
                            arg: "disabled",
                          },
                        ],
                        staticClass: "w100",
                        model: {
                          value: _vm.ruleForm.start_price,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "start_price", _vm._n($$v))
                          },
                          expression: "ruleForm.start_price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.venue_type != 2 && _vm.venue_type != 3
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "price ml20",
                          attrs: { label: "保留价", prop: "retain_price" },
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission:disabled",
                                value: _vm.id
                                  ? "merchantAuctionUpdate>auction_price"
                                  : "",
                                expression:
                                  "id ? 'merchantAuctionUpdate>auction_price' : ''",
                                arg: "disabled",
                              },
                            ],
                            staticClass: "w100",
                            model: {
                              value: _vm.ruleForm.retain_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "retain_price",
                                  _vm._n($$v)
                                )
                              },
                              expression: "ruleForm.retain_price",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.venue_type != 2 && _vm.venue_type != 3
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "price ml20",
                          attrs: { label: "一口价", prop: "top_price" },
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission:disabled",
                                value: _vm.id
                                  ? "merchantAuctionUpdate>auction_price"
                                  : "",
                                expression:
                                  "id ? 'merchantAuctionUpdate>auction_price' : ''",
                                arg: "disabled",
                              },
                            ],
                            staticClass: "w100",
                            model: {
                              value: _vm.ruleForm.top_price,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "top_price", _vm._n($$v))
                              },
                              expression: "ruleForm.top_price",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "拍品估价" } },
                [
                  _c("el-input-number", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission:disabled",
                        value: _vm.id
                          ? "merchantAuctionUpdate>reference_price"
                          : "",
                        expression:
                          "id ? 'merchantAuctionUpdate>reference_price' : ''",
                        arg: "disabled",
                      },
                    ],
                    staticClass: "w100",
                    attrs: { controls: false },
                    model: {
                      value: _vm.ruleForm.min_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "min_price", $$v)
                      },
                      expression: "ruleForm.min_price",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticStyle: { margin: "0 15px" } }, [
                    _vm._v("至"),
                  ]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission:disabled",
                        value: _vm.id
                          ? "merchantAuctionUpdate>reference_price"
                          : "",
                        expression:
                          "id ? 'merchantAuctionUpdate>reference_price' : ''",
                        arg: "disabled",
                      },
                    ],
                    staticClass: "w100",
                    attrs: { controls: false },
                    model: {
                      value: _vm.ruleForm.max_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "max_price", $$v)
                      },
                      expression: "ruleForm.max_price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.venue_type != 2 && _vm.venue_type != 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拍品保证金", prop: "bond_price" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission:disabled",
                            value: _vm.id
                              ? "merchantAuctionUpdate>bond_price"
                              : "",
                            expression:
                              "id ? 'merchantAuctionUpdate>bond_price' : ''",
                            arg: "disabled",
                          },
                        ],
                        staticClass: "w300",
                        model: {
                          value: _vm.ruleForm.bond_price,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "bond_price", _vm._n($$v))
                          },
                          expression: "ruleForm.bond_price",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-form-item", { attrs: { label: "运费" } }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "no-width" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission:disabled",
                              value: _vm.id
                                ? "merchantAuctionUpdate>postage"
                                : "",
                              expression:
                                "id ? 'merchantAuctionUpdate>postage' : ''",
                              arg: "disabled",
                            },
                          ],
                          on: { change: _vm.changeYunfeiType },
                          model: {
                            value: _vm.ruleForm.postage_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "postage_type", $$v)
                            },
                            expression: "ruleForm.postage_type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("到付"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("包邮"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("固定"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "no-width ml10",
                      attrs: { prop: "postage_price" },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission:disabled",
                            value: _vm.id
                              ? "merchantAuctionUpdate>postage"
                              : "",
                            expression:
                              "id ? 'merchantAuctionUpdate>postage' : ''",
                            arg: "disabled",
                          },
                        ],
                        staticClass: "w100",
                        attrs: {
                          placeholder: "运费金额",
                          disabled: _vm.ruleForm.postage_type != 2,
                        },
                        model: {
                          value: _vm.ruleForm.postage_price,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "postage_price", _vm._n($$v))
                          },
                          expression: "ruleForm.postage_price",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.attr_info && _vm.attr_info.length > 0
                ? [
                    _vm._l(_vm.attr_info, function (m, i) {
                      return _c(
                        "el-form-item",
                        {
                          key: "attr" + i,
                          attrs: { label: m.attr_name + "：" },
                        },
                        [
                          m.attr_type == 1
                            ? [
                                _c("el-input", {
                                  staticClass: "w200",
                                  model: {
                                    value: m.attr_value,
                                    callback: function ($$v) {
                                      _vm.$set(m, "attr_value", $$v)
                                    },
                                    expression: "m.attr_value",
                                  },
                                }),
                              ]
                            : m.attr_type == 2
                            ? [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: m.attr_value,
                                      callback: function ($$v) {
                                        _vm.$set(m, "attr_value", $$v)
                                      },
                                      expression: "m.attr_value",
                                    },
                                  },
                                  _vm._l(m.attrValue, function (item, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: "attrValue" + index + i,
                                        attrs: {
                                          label: item.attr_value_id,
                                          size: "mini",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.attr_value_name))]
                                    )
                                  }),
                                  1
                                ),
                              ]
                            : m.attr_type == 3
                            ? [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: m.attr_value,
                                      callback: function ($$v) {
                                        _vm.$set(m, "attr_value", $$v)
                                      },
                                      expression: "m.attr_value",
                                    },
                                  },
                                  _vm._l(m.attrValue, function (item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: "attrValue" + index + i,
                                        attrs: {
                                          label: item.attr_value_id,
                                          size: "mini",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.attr_value_name))]
                                    )
                                  }),
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex" }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "优先级" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission:disabled",
                        value: _vm.id
                          ? "merchantAuctionUpdate>other_settings"
                          : "",
                        expression:
                          "id ? 'merchantAuctionUpdate>other_settings' : ''",
                        arg: "disabled",
                      },
                    ],
                    staticClass: "flex align-items-c",
                  },
                  [
                    _c("span", { staticClass: "ft12 mr10" }, [_vm._v("置顶")]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      model: {
                        value: _vm.ruleForm.is_mer_recommend,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_mer_recommend", $$v)
                        },
                        expression: "ruleForm.is_mer_recommend",
                      },
                    }),
                    _vm._v(" "),
                    _vm.venue_type != 2 && _vm.venue_type != 3
                      ? [
                          _c("span", { staticClass: "ft12 mr10 ml50" }, [
                            _vm._v("隐藏"),
                          ]),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: { "active-value": 0, "inactive-value": 1 },
                            model: {
                              value: _vm.ruleForm.is_show,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "is_show", $$v)
                              },
                              expression: "ruleForm.is_show",
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "ft12 mr10 ml50" }, [
                      _vm._v("排序"),
                    ]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      staticClass: "w100",
                      attrs: {
                        min: 0,
                        controls: false,
                        placeholder: "输入数字",
                      },
                      model: {
                        value: _vm.ruleForm.order_num,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "order_num", $$v)
                        },
                        expression: "ruleForm.order_num",
                      },
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "拍品热度" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission:disabled",
                        value: _vm.id
                          ? "merchantAuctionUpdate>other_settings"
                          : "",
                        expression:
                          "id ? 'merchantAuctionUpdate>other_settings' : ''",
                        arg: "disabled",
                      },
                    ],
                    staticClass: "flex align-items-c",
                  },
                  [
                    _c("span", { staticClass: "ft12 mr10" }, [
                      _vm._v("浏览次数"),
                    ]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      staticClass: "w100",
                      attrs: {
                        min: 0,
                        controls: false,
                        placeholder: "浏览次数",
                      },
                      model: {
                        value: _vm.ruleForm.browse,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "browse", $$v)
                        },
                        expression: "ruleForm.browse",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "拍品详情" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission:disabled",
                        value: _vm.id ? "merchantAuctionUpdate>message" : "",
                        expression: "id ? 'merchantAuctionUpdate>message' : ''",
                        arg: "disabled",
                      },
                    ],
                    staticStyle: { "margin-right": "40px" },
                  },
                  [
                    _c("Tinymce", {
                      ref: "editor",
                      attrs: { height: 400 },
                      model: {
                        value: _vm.ruleForm.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "content", $$v)
                        },
                        expression: "ruleForm.content",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "特别提示" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "特别提示" },
                    model: {
                      value: _vm.ruleForm.special_note,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "special_note", _vm._n($$v))
                      },
                      expression: "ruleForm.special_note",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          !_vm.againType
            ? _c(
                "div",
                { staticClass: "formFixedBox" },
                [
                  _vm.id
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "merchantAuctionUpdate",
                              expression: "'merchantAuctionUpdate'",
                            },
                          ],
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.saveAction("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认提交")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.saveAction("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认提交")]
                      ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { staticClass: "btn", on: { click: _vm.backAction } },
                    [_vm._v("取消")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看视频",
            visible: _vm.showVideo,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVideo = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("video", {
              staticStyle: { width: "400px", height: "225px" },
              attrs: { src: _vm.ruleForm.auction_video, controls: "" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.ruleForm[_vm.imagesViewKey][_vm.imagesKey] },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }