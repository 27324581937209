"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ticket = require("@/api/ticket");
var _index = require("@/utils/index");
var _user = require("@/api/user");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        status: "",
        get_channel: "",
        ticket_type: ''
      },
      parseTime: _index.parseTime,
      showAddPeople: {
        show: false,
        uid: "",
        btnLoading: false
      },
      searchLoading: false,
      timeout: null,
      checkerList: [],
      searchTimes: [],
      showVerifiedInfo: {
        show: false,
        data: []
      },
      row: "",
      uid: "",
      showdetail: false,
      showRefundMoney: false,
      refundMoneyData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _ticket.ticketCheckerListApi)({
      page: 1,
      limit: 100
    }).then(function (res) {
      _this.checkerList = res.data.list;
    });
    this.getList(1);
  },
  methods: {
    refundMoneyAction: function refundMoneyAction(data) {
      var _this2 = this;
      data.refund = undefined;
      data.refundCount = 1;
      this.refundMoneyData = {};
      this.showRefundMoney = true;
      (0, _ticket.ticketPreRefundApi)({
        id: data.id
      }).then(function (res) {
        _this2.refundMoneyData = {
          id: data.id,
          num: res.data.num,
          refundCount: res.data.num,
          refund: "",
          refundMaxPrice: res.data.price
        };
      }).catch(function () {
        _this2.showRefundMoney = false;
      });
    },
    confirmRefundAction: function confirmRefundAction() {
      var _this3 = this;
      if (!this.refundMoneyData.refund || isNaN(this.refundMoneyData.refund)) {
        this.$message.error("请输入正确的退款金额");
        return false;
      }
      this.$confirm("\u786E\u8BA4\u9000\u6B3E?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _ticket.ticketRefundApi)({
          id: _this3.refundMoneyData.id,
          price: _this3.refundMoneyData.refund,
          num: _this3.refundMoneyData.num
        }).then(function () {
          _this3.showRefundMoney = false;
          _this3.$message.success("操作成功");
          _this3.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 详情
    onDetails: function onDetails(uid) {
      // this.row = row;
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    showVerifiedAction: function showVerifiedAction(info) {
      this.showVerifiedInfo = {
        show: true,
        data: info.data
      };
    },
    searchDateAction: function searchDateAction() {
      if (this.searchTimes && this.searchTimes[0] && this.searchTimes[1]) {
        this.tableFrom.start_time = this.searchTimes[0];
        this.tableFrom.end_time = this.searchTimes[1];
      } else {
        this.tableFrom.start_time = "";
        this.tableFrom.end_time = "";
      }
      this.getList(1);
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      var params = JSON.parse(JSON.stringify(this.tableFrom));
      if (params.ticket_type == 0) {
        params.ticket_type = '';
      }
      (0, _ticket.ticketSellListApi)(params).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    finishAction: function finishAction(id) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u624B\u52A8\u6838\u9500\u8BE5\u8BB0\u5F55?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _ticket.ticketSellUseApi)({
          id: id
        }).then(function () {
          _this5.$message.success("操作成功");
          _this5.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this6 = this;
      var d = JSON.parse(JSON.stringify(this.tableFrom));
      d.export_type = "ticketUserRecord";
      (0, _system.exportexcel)(d).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this6.$createElement;
        _this6.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this6.$msgbox.close();
                _this6.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("ticketUserRecord");
    }
  }
};