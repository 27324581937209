"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    hasMoreData: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    allImage: {
      type: String,
      default: ''
    },
    query: {
      type: [String, Object],
      default: ''
    }
  },
  data: function data() {
    return {
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      auctionName: '',
      venueLoading: false,
      venue_id: '',
      album_id: '',
      venueList: [],
      albumList: [],
      isAll: false
    };
  },
  watch: {
    flag: function flag(val) {
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
    if (this.query) {
      this.queryParams = Object.assign({}, this.queryParams, this.query);
    }
  },
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadAuction();
      } else {
        this.setAllStatus();
      }
      this.showSelectAuction = true;
    },
    searchAAction: function searchAAction() {
      this.queryParams.venue_id = this.venue_id;
      this.queryParams.album_id = this.album_id;
      this.queryParams.keyword = this.auctionName;
      this.queryParams.page = 1;
      this.loadAuction();
    },
    loadAuction: function loadAuction(fun) {
      var _this = this;
      (0, _auction.auctionList)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.resultData = response.data.list;
        _this.setAllStatus();
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        image: info.product.image + '!300',
        name: info.product.store_name,
        id: info.product_id,
        price: info.now_price || info.start_price
      };
      if (this.hasMoreData) {
        data.image = info.product.image + '!m640';
        data.attr_info = info.attr_info;
        data.store_info = info.product.store_info;
        data.content = info.content && info.content.content ? info.content.content : '';
        data.desc = '';
        data.category = info.product.storeCategory ? info.product.storeCategory.cate_name : '';
        data.album = info.album ? info.album.album_name : '';
        data.reference_price = info.reference_price;
        data.start_price = info.start_price ? info.start_price : '无底价';
        data.success_price = info.now_price;
        data.lot = info.lot;
        data.video_link = info.product.video_link;
      }
      if (this.allImage) {
        data.slider_image = info.product.slider_image;
      }
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.size === '1') {
        this.cursorSelect();
      }
      this.setAllStatus();
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'auction',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    },
    searchVenueAction: function searchVenueAction(data) {
      var _this2 = this;
      var params = {
        keyword: data,
        venue_type: '',
        page: 1,
        limit: 10
      };
      (0, _auction.meetingList)(params).then(function (res) {
        _this2.venueList = res.data.list;
      });
    },
    searchAlbumAction: function searchAlbumAction(data) {
      var _this3 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 10
      };
      (0, _auction.albumlist)(params).then(function (res) {
        _this3.albumList = res.data.list;
      });
    },
    changeMeetingAction: function changeMeetingAction(e, noLoad) {
      var _this4 = this;
      this.album_id = '';
      var params = {
        venue_id: this.venue_id,
        page: 1,
        limit: 100
      };
      (0, _auction.albumlist)(params).then(function (res) {
        _this4.albumList = res.data.list;
      });
      if (!noLoad) {
        this.searchAAction();
      }
    },
    setAllStatus: function setAllStatus() {
      var isAll = true;
      for (var i = 0; i < this.resultData.length; i += 1) {
        var m = this.resultData[i];
        var index = this.selectedAuctionIdList.indexOf(m.product_id);
        if (index === -1) {
          isAll = false;
          break;
        }
      }
      this.isAll = isAll;
    },
    auctionSelectedAllAction: function auctionSelectedAllAction() {
      var _this5 = this;
      if (this.isAll) {
        var allData = JSON.parse(JSON.stringify(this.selectedAuction));
        this.resultData.forEach(function (m) {
          var ids = allData.map(function (m) {
            return m.id;
          });
          var i = ids.indexOf(m.product_id);
          if (i !== -1) {
            allData.splice(i, 1);
          }
        });
        this.selectedAuction = allData;
      } else {
        var data = [];
        this.resultData.forEach(function (m) {
          var i = _this5.selectedAuctionIdList.indexOf(m.product_id);
          if (i === -1) {
            var d = {
              image: m.product.image + '!300',
              name: m.product.store_name,
              id: m.product_id,
              price: m.now_price || m.start_price
            };
            if (_this5.hasMoreData) {
              d.image = m.product.image + '!m640';
              d.attr_info = m.attr_info;
              d.store_info = m.product.store_info;
              d.content = m.content && m.content.content ? m.content.content : '';
              d.desc = '';
            }
            data.push(d);
          }
        });
        this.selectedAuction = this.selectedAuction.concat(data);
      }
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
    }
  }
};