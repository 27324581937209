var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            visible: _vm.showview,
            direction: "rtl",
            "append-to-body": "",
            size: "1000px",
            "before-close": _vm.changeshowdetail,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showview = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "drawer-title" }, [
              _c(
                "div",
                {
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "nft" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("nft")
                    },
                  },
                },
                [_vm._v("\n          团队成员\n        ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.drawtype == "nft"
            ? [
                _c(
                  "el-form",
                  {
                    attrs: {
                      size: "small",
                      inline: "",
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "订单类型：" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { type: "button" },
                            on: {
                              change: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.order_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "order_type", $$v)
                              },
                              expression: "tableFrom.order_type",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "" } }, [
                              _vm._v("全部 "),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "3" } }, [
                              _vm._v("数字藏品"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "4" } }, [
                              _vm._v("数字盲盒"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "6" } }, [
                              _vm._v("寄售藏品"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "7" } }, [
                              _vm._v("寄售盲盒"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("商城"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: "10" } }, [
                              _vm._v("门票"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "width100",
                        staticStyle: { display: "block" },
                        attrs: { label: "时间选择：" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "mr20",
                            attrs: { type: "button", size: "small" },
                            on: {
                              change: function ($event) {
                                return _vm.selectChange(_vm.tableFrom.date)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "date", $$v)
                              },
                              expression: "tableFrom.date",
                            },
                          },
                          _vm._l(_vm.fromList.fromTxt, function (item, i) {
                            return _c(
                              "el-radio-button",
                              { key: i, attrs: { label: item.val } },
                              [_vm._v(_vm._s(item.text))]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            "value-format": "yyyy/MM/dd",
                            format: "yyyy/MM/dd",
                            size: "small",
                            type: "daterange",
                            placement: "bottom-end",
                            placeholder: "自定义时间",
                          },
                          on: { change: _vm.onchangeTime },
                          model: {
                            value: _vm.timeVal,
                            callback: function ($$v) {
                              _vm.timeVal = $$v
                            },
                            expression: "timeVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "width100", attrs: { label: "用户名：" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "selWidth",
                            attrs: {
                              placeholder: "用户名/手机号",
                              size: "small",
                            },
                            model: {
                              value: _vm.tableFrom.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "keyword", $$v)
                              },
                              expression: "tableFrom.keyword",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { padding: "20px" } },
                  [
                    _vm.drawtype == "nft"
                      ? _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "right",
                                  width: "500",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "el-table",
                                  { attrs: { data: _vm.dtotal.saleData } },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "150",
                                        property: "order_type",
                                        label: "类型",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "100",
                                        property: "pay_count",
                                        label: "订单数量",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        width: "300",
                                        property: "pay_price",
                                        label: "销售额",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [_vm._v("查看分销统计")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.changeadduser },
                              },
                              [_vm._v("添加团队成员")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.exportOrder },
                              },
                              [_vm._v("导出")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: { click: _vm.getExportFileList },
                              },
                              [_vm._v("导出已生成列表")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        staticStyle: { width: "100%", color: "#000" },
                        attrs: {
                          data: _vm.tableData.data,
                          "highlight-current-row": "",
                          size: "mini",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "头像", "min-width": "50" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "demo-image__preview" },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "36px",
                                            height: "36px",
                                          },
                                          attrs: {
                                            src: scope.row.avatar
                                              ? scope.row.avatar
                                              : _vm.moren,
                                            "preview-src-list": [
                                              scope.row.avatar || _vm.moren,
                                            ],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3344164952
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "昵称", "min-width": "150" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(row.real_name || row.nickname)
                                      ),
                                      row.real_name
                                        ? _c("span", [
                                            _vm._v(
                                              "(" + _vm._s(row.nickname) + ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3752129250
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "邀请人" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.spread
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                row.spread.real_name ||
                                                  row.spread.nickname
                                              )
                                          ),
                                          row.spread.real_name
                                            ? _c("span", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      row.spread.nickname
                                                    ) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3637503016
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "他邀请了" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(row.invitation_count)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3749920190
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "注册渠道" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.user_type === "wechat_mini"
                                            ? "小程序"
                                            : row.user_type === "wechat"
                                            ? "公众号"
                                            : "H5"
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            324110406
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "手机号" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [_vm._v(_vm._s(row.phone))]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1928382287
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", { attrs: { label: "余额" } }),
                        _vm._v(" "),
                        _c("el-table-column", { attrs: { label: "积分" } }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "交易" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#6699dd",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showOrder(row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.pay_count) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            524854425
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "成交" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [_vm._v(_vm._s(row.pay_price))]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3932188617
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "注册时间" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(row.create_time)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2366882269
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "操作" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delrow(row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                解除关系\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3247059927
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "block",
                        staticStyle: { "margin-top": "20px" },
                      },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "page-sizes": [6, 20, 40, 60, 80],
                            "page-size": _vm.tableFrom.limit,
                            "current-page": _vm.tableFrom.page,
                            layout: "total, sizes, prev, pager, next, jumper",
                            total: _vm.tableData.total,
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.pageChange,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "z-index": "999" },
          attrs: {
            title: "",
            visible: _vm.showusercard,
            width: "800px",
            "append-to-body": true,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showusercard = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "600px", display: "block" },
            attrs: { src: _vm.userjson.idcard_just_photo, alt: "" },
          }),
          _vm._v(" "),
          _c("img", {
            staticStyle: { width: "600px", display: "block" },
            attrs: { src: _vm.userjson.idcard_back_photo, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            "append-to-body": "",
            center: "",
            title: "查看凭证",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.imgSrc + "!m640", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加团队成员",
            visible: _vm.showadd,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "成员：", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择用户",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.adduid,
                        callback: function ($$v) {
                          _vm.adduid = $$v
                        },
                        expression: "adduid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  "border-radius": "50%",
                                  "margin-right": "12px",
                                },
                                attrs: { src: m.avatar, alt: "" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "line-height": "18px",
                                  },
                                },
                                [
                                  _c("div", [_vm._v(_vm._s(m.nickname))]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(m.phone))]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleadd } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单列表",
            visible: _vm.showOrderflag,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showOrderflag = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.orderTableData.data, border: "", stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.extend_data
                                  ? scope.row.extend_data.image
                                  : "",
                                "preview-src-list": [
                                  scope.row.extend_data.image,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "名称",
                  prop: "extend_data.title",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "购买数量",
                  prop: "extend_data.buy_count",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "订单号",
                  prop: "extend_data.order_no",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "订单金额",
                  prop: "pay_price",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "下单时间",
                  prop: "create_time",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [6, 20, 40, 60, 80],
                  "page-size": _vm.orderTableFrom.limit,
                  "current-page": _vm.orderTableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.orderTableData.total,
                },
                on: {
                  "size-change": _vm.handleOrderSizeChange,
                  "current-change": _vm.pageOrderChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }