var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      {
        style: {
          background:
            _vm.facade.backgroundColor +
            " url(" +
            _vm.facade.backgroundImg +
            "!m640) no-repeat left center / 100%",
          margin:
            _vm.facade.marginTop / 2 +
            "px " +
            _vm.facade.marginRight / 2 +
            "px " +
            _vm.facade.marginBottom / 2 +
            "px " +
            _vm.facade.marginLeft / 2 +
            "px",
          padding:
            _vm.facade.paddingTop / 2 +
            "px " +
            _vm.facade.paddingRight / 2 +
            "px " +
            _vm.facade.paddingBottom / 2 +
            "px " +
            _vm.facade.paddingLeft / 2 +
            "px",
          borderRadius:
            _vm.facade.topLeftRadius / 2 +
            "px " +
            _vm.facade.topRightRadius / 2 +
            "px " +
            _vm.facade.bottomRightRadius / 2 +
            "px " +
            _vm.facade.bottomLeftRadius / 2 +
            "px",
          overflow: "hidden",
        },
      },
      [
        _c(
          "div",
          { staticClass: "flex justify-s" },
          _vm._l(_vm.viewData, function (m, i) {
            return _c(
              "div",
              {
                key: i,
                staticStyle: { "flex-shrink": "0" },
                style: {
                  background:
                    _vm.facade.itemBackgroundColor +
                    " url(" +
                    _vm.facade.itemBackgroundImg +
                    "!m640) no-repeat left center / 100%",
                  boxShadow: _vm.facade.itemShadow
                    ? "0 0 " +
                      _vm.facade.itemShadowSize / 2 +
                      "px " +
                      _vm.facade.itemShadow
                    : "",
                  padding:
                    _vm.facade.itemPaddingTop / 2 +
                    "px " +
                    _vm.facade.itemPaddingRight / 2 +
                    "px " +
                    _vm.facade.itemPaddingBottom / 2 +
                    "px " +
                    _vm.facade.itemPaddingLeft / 2 +
                    "px",
                  marginLeft: _vm.facade.itemMargin / 2 + "px",
                  borderRadius:
                    _vm.facade.itemTopLeftRadius / 2 +
                    "px " +
                    _vm.facade.itemTopRightRadius / 2 +
                    "px " +
                    _vm.facade.itemBottomRightRadius / 2 +
                    "px " +
                    _vm.facade.itemBottomLeftRadius / 2 +
                    "px",
                  width: _vm.facade.imgWidth / 2 + "px",
                  boxSizing: "content-box",
                },
              },
              [
                _c("div", [
                  _c("img", {
                    style: {
                      width: _vm.facade.imgWidth / 2 + "px",
                      height: _vm.facade.imgHeight / 2 + "px",
                      borderRadius:
                        _vm.facade.imgTopLeftRadius / 2 +
                        "px " +
                        _vm.facade.imgTopRightRadius / 2 +
                        "px " +
                        _vm.facade.imgBottomLeftRadius / 2 +
                        "px " +
                        _vm.facade.imgBottomRightRadius / 2 +
                        "px",
                      objectFit: "cover",
                    },
                    attrs: {
                      src: m.image + (_vm.content.type == 3 ? "!m640" : ""),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "line1",
                    style: {
                      textAlign: _vm.facade.textAlign,
                      color: _vm.facade.titleColor,
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      padding:
                        _vm.facade.titlePaddingTop / 2 +
                        "px " +
                        _vm.facade.titlePaddingRight / 2 +
                        "px " +
                        _vm.facade.titlePaddingBottom / 2 +
                        "px " +
                        _vm.facade.titlePaddingLeft / 2 +
                        "px",
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [_vm._v(_vm._s(m.name))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "line1",
                    style: {
                      textAlign: _vm.facade.textAlign,
                      color: _vm.facade.descColor,
                      fontSize: _vm.facade.descFontSize / 2 + "px",
                      padding:
                        _vm.facade.descPaddingTop / 2 +
                        "px " +
                        _vm.facade.descPaddingRight / 2 +
                        "px " +
                        _vm.facade.descPaddingBottom / 2 +
                        "px " +
                        _vm.facade.descPaddingLeft / 2 +
                        "px",
                      fontWeight: _vm.facade.descFontWeight,
                    },
                  },
                  [
                    _vm.content.type == 1
                      ? [_vm._v("￥" + _vm._s(m.price))]
                      : _vm.content.type == 2
                      ? [
                          _vm._v(
                            _vm._s(m.price == "咨询" ? m.price : "￥" + m.price)
                          ),
                        ]
                      : _vm.content.type == 3
                      ? [_vm._v(_vm._s(m.price))]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }