"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _ticket = require("@/api/ticket");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        size: 10
      },
      total: 0,
      workName: '',
      searchLoading: false,
      announcer: '',
      searchGoodsId: '',
      parseTime: _index.parseTime
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.spec_id;
      });
      if (this.resultData.length === 0) {
        this.loadArticle();
      }
      this.showSelectAuction = true;
    },
    searchArticleAction: function searchArticleAction() {
      this.queryParams.name = this.workName;
      this.queryParams.page = 1;
      this.loadArticle();
    },
    loadArticle: function loadArticle(fun) {
      var _this = this;
      var params = this.queryParams;
      params.status = 1;
      (0, _ticket.ticketListApi)(params).then(function (res) {
        var d = [];
        res.data.list.forEach(function (m) {
          m.spec.forEach(function (v) {
            d.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
              spec_id: v.spec_id,
              spec_name: v.spec_name || '规格名称',
              price: v.price || '0.00'
            }));
          });
        });
        _this.resultData = d;
        _this.total = res.data.count;
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.spec_id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        name: info.product.store_name,
        id: info.ticket_id,
        image: info.product.image,
        stock: info.product.stock,
        reserved_number: info.reserved_number,
        spec_id: info.spec_id,
        spec_name: info.spec_name,
        price: info.price,
        product_id: info.product_id
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.spec_id;
      });
      if (this.size === '1') {
        this.cursorSelect();
      }
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'nft',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.loadArticle();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.loadArticle();
    }
  }
};