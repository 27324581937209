var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "padding-top": "16px" } }, [
    _c(
      "div",
      { staticClass: "open3d-type-box" },
      [
        _vm.exhibitionType == "material"
          ? [
              _c(
                "div",
                {
                  staticClass: "open3d-type-text",
                  class: { active: _vm.modelType == "material_img" },
                  on: {
                    click: function ($event) {
                      return _vm.changeModelType("material_img")
                    },
                  },
                },
                [_vm._v("\n        图片\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "open3d-type-text",
                  class: { active: _vm.modelType == "material_video" },
                  on: {
                    click: function ($event) {
                      return _vm.changeModelType("material_video")
                    },
                  },
                },
                [_vm._v("\n        视频\n      ")]
              ),
            ]
          : [
              _c(
                "div",
                {
                  staticClass: "open3d-type-text",
                  class: { active: _vm.modelType == "work" },
                  on: {
                    click: function ($event) {
                      return _vm.changeModelType("work")
                    },
                  },
                },
                [_vm._v("\n        作品\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "open3d-type-text",
                  class: { active: _vm.modelType == "auction" },
                  on: {
                    click: function ($event) {
                      return _vm.changeModelType("auction")
                    },
                  },
                },
                [_vm._v("\n        拍品\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "open3d-type-text",
                  class: { active: _vm.modelType == "product" },
                  on: {
                    click: function ($event) {
                      return _vm.changeModelType("product")
                    },
                  },
                },
                [_vm._v("\n        商品\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "open3d-type-text",
                  class: { active: _vm.modelType == "live" },
                  on: {
                    click: function ($event) {
                      return _vm.changeModelType("live")
                    },
                  },
                },
                [_vm._v("\n        直播\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "open3d-type-text",
                  class: { active: _vm.modelType == "nft" },
                  on: {
                    click: function ($event) {
                      return _vm.changeModelType("nft")
                    },
                  },
                },
                [_vm._v("\n        道具\n      ")]
              ),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "open3d-propleft-search-box" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchName,
            expression: "searchName",
          },
        ],
        staticClass: "open3d-propleft-input",
        attrs: { type: "text", placeholder: "搜索" },
        domProps: { value: _vm.searchName },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.goSearchAction($event)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchName = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "open3d-propleft-search-icon open3dtype-search",
        attrs: {
          src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805584592429232.png",
          alt: "",
        },
        on: { click: _vm.goSearchAction },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "works-view" }, [
      _c(
        "div",
        { staticClass: "open3d-works-list flex" },
        _vm._l(_vm.cloneList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "open3d-goods open3d-work-list",
              class: { active: item.active },
              on: {
                click: function ($event) {
                  return _vm.selectWork(item)
                },
              },
            },
            [
              _c("div", { staticClass: "open3d-goods-image-box" }, [
                _c("img", {
                  staticClass: "open3d-goods-image",
                  attrs: { src: item.coverImage, alt: "" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "open3d-goods-pop-sign" }, [
                  item.type == 3
                    ? _c("img", {
                        attrs: {
                          src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805584820754784.png",
                          alt: "",
                        },
                      })
                    : item.type == 2
                    ? _c("img", {
                        attrs: {
                          src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805585081616402.png",
                          alt: "",
                        },
                      })
                    : item.type == 4
                    ? _c("img", {
                        attrs: {
                          src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805585312452731.png",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "open3d-name" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }