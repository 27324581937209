"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      tab: '5',
      stateForm: {
        erp_product_no_prefix_rule: '0',
        erp_product_no_entry_rule: '0'
      },
      userList: []
    };
  },
  created: function created() {
    var _this = this;
    (0, _system.getlistNames)().then(function (res) {
      // console.log(res);
      _this.userList = res.data.list;
      _this.init();
    });
  },
  methods: {
    init: function init() {
      var _this2 = this;
      (0, _system.getConfigClassKeys)('erp_config').then(function (res) {
        // console.log(res);
        _this2.userList.forEach(function (item, index) {
          // res.data.erp_product_no_prefix_admin.forEach(m => {
          if (res.data.erp_product_no_prefix_admin[item.merchant_admin_id]) {
            // item.value = m[item.merchant_admin_id]
            var obj = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              value: res.data.erp_product_no_prefix_admin[item.merchant_admin_id]
            });
            _this2.$set(_this2.userList, index, obj);
          }
          // })
        });
        console.log(_this2.userList);
        _this2.stateForm = res.data;
      });
    },
    onSubmit: function onSubmit() {
      var _this3 = this;
      var obj = {};
      this.userList.forEach(function (item) {
        if (item.value) obj[item.merchant_admin_id] = item.value;
      });
      this.stateForm.erp_product_no_prefix_admin = obj;
      (0, _system.configClassKeysSave)('erp_config', this.stateForm).then(function (res) {
        _this3.$message.success('设置成功');
      });
    },
    changeTab: function changeTab(val) {
      var _this4 = this;
      if (val != 4) {
        this.$router.replace({
          path: '/merchant/warehouse/setColumns',
          query: {
            tab: val
          }
        });
        this.tab = val;
        setTimeout(function () {
          _this4.tab = val;
        });
      } else {
        this.$router.replace({
          path: '/merchant/warehouse/warehouseList'
        });
      }
    }
  }
};