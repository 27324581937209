"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      showdetail: null,
      uid: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        keyword: ''
      },
      timeVal: [],
      expiration_timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    editAction: function editAction(id) {
      this.$router.push('./benefitsDetail?id=' + id);
    },
    addBenefitsAction: function addBenefitsAction() {
      this.$router.push('./benefitsDetail');
    },
    delAction: function delAction(id) {
      var _this = this;
      this.$confirm('确认删除此权益？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '不删除',
        type: 'warning'
      }).then(function () {
        var loading = _this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        (0, _member.membershipRightDelApi)(id).then(function () {
          loading.close();
          _this.$message.success('删除成功');
          _this.getList();
        }).catch(function () {
          loading.close();
        });
      });
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _member.membershipRightListApi)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (m) {
          if (m.right_type === 2 || m.right_type === 3 || m.right_type === 6) {
            m.goodsCount = 0;
            m.right_give_data.forEach(function (c) {
              m.goodsCount += Number(c.number);
            });
          }
        });
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    }
  }
};