var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        {
          staticClass: "flex align-items-c",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "300px" },
              attrs: { placeholder: "请选择拍卖会", filterable: "" },
              on: { change: _vm.changevenue },
              model: {
                value: _vm.formValidate.venue_id,
                callback: function ($$v) {
                  _vm.$set(_vm.formValidate, "venue_id", $$v)
                },
                expression: "formValidate.venue_id",
              },
            },
            _vm._l(_vm.venceList, function (item) {
              return _c("el-option", {
                key: item.venue_id,
                attrs: { label: item.title, value: item.venue_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.onBatch },
            },
            [_vm._v("批量下载PDF")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              on: { click: _vm.getExportFileList },
            },
            [_vm._v("查看下载记录")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 230px)", width: "900px" } },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.reportList,
                height: "100%",
                size: "mini",
                border: "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: { label: "委托人", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.entrustUser.nickname)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            _vm._s(scope.row.entrustUser.real_name) +
                              " " +
                              _vm._s(scope.row.entrustUser.phone)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: { label: "业务员", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            _vm._s(scope.row.contract.salesmanAdmin.real_name)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: { label: "上拍", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.count) + "件"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: { label: "上拍通知书PDF", prop: "", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "default" },
                            on: {
                              click: function ($event) {
                                return _vm.getAuction(scope.row.entrust_uid)
                              },
                            },
                          },
                          [_vm._v("打印")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.printVisible,
            title: "",
            top: "5vh",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.printVisible = $event
            },
          },
        },
        [
          _vm.reportInfo
            ? _c(
                "div",
                { attrs: { id: "printorder" } },
                [
                  _c("div", { attrs: { id: "prientorder-title" } }, [
                    _c("div", { staticClass: "detail-box" }, [
                      _c("div", { staticClass: "detail-left" }, [
                        _c("div", { staticClass: "detail-left-title" }, [
                          _vm._v(
                            _vm._s(_vm.merData.company_name) +
                              " | " +
                              _vm._s(_vm.switchLang("sptzs"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "detail-left-desc",
                            staticStyle: {
                              "font-size": "14px",
                              "font-family": "'printEN'",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.merData.company_english_name) +
                                " Auction Notice\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "detail-abso" }, [
                          _c("img", {
                            attrs: {
                              crossorigin: "anonymous",
                              src: _vm.merData.mer_logo,
                              alt: "",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "secTit",
                        staticStyle: { "margin-top": "30px" },
                      },
                      [
                        _vm._v("\n          致\n          "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              padding: "0 4px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s(_vm.reportInfo.entrustUser.real_name))]
                        ),
                        _vm._v(" "),
                        _vm.reportInfo.entrustUser.sex == 0
                          ? [_vm._v("先生")]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reportInfo.entrustUser.sex == 1
                          ? [_vm._v("女士")]
                          : _vm._e(),
                        _vm._v("\n          ：\n        "),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "13px",
                          "font-family": "'printEN'",
                          "font-weight": "500",
                        },
                      },
                      [
                        _vm._v("\n          To\n          "),
                        _vm.reportInfo.entrustUser.sex == 0
                          ? [_vm._v("Mr.")]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.reportInfo.entrustUser.sex == 1
                          ? [_vm._v("Ms.")]
                          : _vm._e(),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.reportInfo.entrustUser.real_name_english
                            ) +
                            "：\n        "
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "secTit" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("reportTextOne")) +
                          "\n          " +
                          _vm._s(_vm.reportInfo.auctionList.length) +
                          "\n          " +
                          _vm._s(_vm.switchLang("reportTextTwo")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "13px",
                          "font-family": "'printEN'",
                          "font-weight": "500",
                        },
                      },
                      [
                        _vm._v(
                          "\n          here are " +
                            _vm._s(_vm.reportInfo.auctionList.length) +
                            " works that you have commissioned\n          for auction. The specific situation is as follows. If you have any questions,\n          please contact our business. Thank you for your long-term support to our\n          company!\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-title",
                        staticStyle: { "margin-top": "40px" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.venueInfo.title) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "13px",
                          "font-family": "'printEN'",
                          "font-weight": "500",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.venueInfo.title_english) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-list",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.switchLang("yzsj")) +
                                "："
                            ),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(_vm.venueInfo.preview_time_text))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "Prev.Time：" +
                                _vm._s(_vm.venueInfo.preview_time_text_english)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.switchLang("pmsj")) +
                                "："
                            ),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(_vm.venueInfo.start_time_text))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "Auct.Time：" +
                                _vm._s(_vm.venueInfo.start_time_text_english)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.switchLang("zpdz")) +
                                "："
                            ),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(_vm.venueInfo.address_name))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "Exh. & Auct. Addr.：" +
                                _vm._s(_vm.venueInfo.address_name_english)
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.showtworeport
                    ? _c(
                        "div",
                        { staticClass: "auctionBox" },
                        _vm._l(
                          _vm.tworeportarrList,
                          function (twoitem, tindex) {
                            return _c(
                              "div",
                              {
                                key: "t" + tindex,
                                style:
                                  tindex < _vm.tworeportarrList.length - 1
                                    ? "page-break-after: always;"
                                    : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-box",
                                    attrs: {
                                      id:
                                        tindex ==
                                        _vm.tworeportarrList.length - 1
                                          ? "pagBox"
                                          : "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        key: "t" + tindex,
                                        staticClass: "ptd",
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      _vm._l(
                                        _vm.showList,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: "showlist" + index,
                                              staticClass: "ptd-title",
                                              style:
                                                item.field == "lot"
                                                  ? "flex: 1;"
                                                  : "flex:2;",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.zh_name)),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "'printEN'",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.en_name))]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _vm._l(twoitem, function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: "r" + index,
                                          staticClass: "ptd pt-body-box",
                                        },
                                        _vm._l(_vm.showList, function (m, i) {
                                          return _c(
                                            "div",
                                            {
                                              key:
                                                "twoshowlistbody" + tindex + i,
                                              staticClass: "ptd-body",
                                              style:
                                                m.field == "lot"
                                                  ? "flex: 1;"
                                                  : "flex:2;",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  m.field == "lot"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.auction.lot
                                                          ) + " "
                                                        ),
                                                      ]
                                                    : m.field == "contract_no"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.erpProduct
                                                              .contract
                                                              .contract_no
                                                          )
                                                        ),
                                                      ]
                                                    : m.field == "album_name"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.auction.album
                                                              .album_name
                                                          )
                                                        ),
                                                      ]
                                                    : m.field == "auction_name"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.auction.product
                                                              .store_name
                                                          )
                                                        ),
                                                      ]
                                                    : m.field == "start_price"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.auction
                                                              .start_price
                                                          )
                                                        ),
                                                      ]
                                                    : m.field == "product_no"
                                                    ? [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              item.erpProduct
                                                                .product_no
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    : m.field == "retain_price"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.erpProduct
                                                              .retain_price
                                                          )
                                                        ),
                                                      ]
                                                    : m.field ==
                                                      "take_home_price"
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.erpProduct
                                                              .take_home_price
                                                          )
                                                        ),
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.erpProduct
                                                              .attribute
                                                              ? item.erpProduct
                                                                  .attribute[
                                                                  m.field
                                                                ]
                                                              : ""
                                                          )
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : [
                        _c(
                          "div",
                          { staticClass: "ptd-box", attrs: { id: "ptd-box" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd",
                                staticStyle: { "font-weight": "bold" },
                              },
                              _vm._l(_vm.showList, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "showlist" + index,
                                    staticClass: "ptd-title",
                                    style:
                                      item.field == "lot"
                                        ? "flex: 1;"
                                        : "flex:2;",
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.zh_name))]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'printEN'",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.en_name))]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.reportInfo.auctionList,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "r" + index,
                                    staticClass: "ptd pt-body-box calcul",
                                  },
                                  _vm._l(_vm.showList, function (m, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: "showlistbody" + i,
                                        staticClass: "ptd-body",
                                        style:
                                          m.field == "lot"
                                            ? "flex: 1;"
                                            : "flex:2;",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            m.field == "lot"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(item.auction.lot) +
                                                      " "
                                                  ),
                                                ]
                                              : m.field == "contract_no"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.erpProduct.contract
                                                        .contract_no
                                                    )
                                                  ),
                                                ]
                                              : m.field == "album_name"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.auction.album
                                                        .album_name
                                                    )
                                                  ),
                                                ]
                                              : m.field == "auction_name"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.auction.product
                                                        .store_name
                                                    )
                                                  ),
                                                ]
                                              : m.field == "start_price"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.auction.start_price
                                                    )
                                                  ),
                                                ]
                                              : m.field == "product_no"
                                              ? [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        item.erpProduct
                                                          .product_no
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              : m.field == "retain_price"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.erpProduct
                                                        .retain_price
                                                    )
                                                  ),
                                                ]
                                              : m.field == "take_home_price"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.erpProduct
                                                        .take_home_price
                                                    )
                                                  ),
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.erpProduct.attribute
                                                        ? item.erpProduct
                                                            .attribute[m.field]
                                                        : ""
                                                    )
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                  _vm._v(" "),
                  _c("div", {
                    style: _vm.isPag ? "page-break-after: always;" : "",
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "secTit",
                      style:
                        "margin-top: " +
                        _vm.tworeportarrList.length * 20 +
                        "px;",
                    },
                    [_vm._v(_vm._s(_vm.switchLang("reportTextThe")) + ":")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "detail-left-desc",
                      staticStyle: {
                        "font-size": "13px",
                        "font-family": "'printEN'",
                        "font-weight": "500",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        The auction schedule for this auction is arranged as follows.\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-box pagAblumBox" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ptd abluTit",
                          staticStyle: { "font-weight": "bold" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("xh"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("NO.")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("pmzc"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("Auct. Sess.")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "1.5" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("BuyerTabletu"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("LOT NO.")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "1.5" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("pmrq"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("Auct. Date")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "1.5" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("pmsj"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("Auct. Time")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-title",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("pmt"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("Auct. Hall")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.albumList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "auction" + index,
                            staticClass: "ptd abluCalu",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "1" },
                              },
                              [_vm._v(_vm._s(index + 1))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "2" },
                              },
                              [_c("div", [_vm._v(_vm._s(item.album_name))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "1.5" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.LotArea.min || "") +
                                    "-" +
                                    _vm._s(item.LotArea.max || "") +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "1.5" },
                              },
                              [_c("div", [_vm._v(_vm._s(item.timejson.date))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "1.5" },
                              },
                              [_c("div", [_vm._v(_vm._s(item.timejson.time))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "1" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [_vm._v(_vm._s(item.group_key || ""))]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticStyle: {
                      width: "100%",
                      height: "2px",
                      background: "#000",
                      "margin-top": "60px",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-bottom", attrs: { id: "ptd-bottom" } },
                    [
                      _c("div", { staticClass: "ptd-bottom-left" }, [
                        _c("div", { staticClass: "ptd-bottom-left-list" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.switchLang("CompanyAddress")) +
                              "：" +
                              _vm._s(_vm.merData.config.company_address) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ptd-bottom-left-list" }, [
                          _vm._v(
                            "\n            Company Address: " +
                              _vm._s(
                                _vm.merData.config.company_english_address || ""
                              ) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ptd-bottom-left-list" }, [
                          _vm._v(
                            _vm._s(_vm.switchLang("CompanyPhone")) +
                              "/TEL： " +
                              _vm._s(_vm.merData.config.contact_phone)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ptd-bottom-right" },
                        _vm._l(
                          _vm.merData.config.mer_qrcode_data,
                          function (item, index) {
                            return item.imgUrl
                              ? _c(
                                  "div",
                                  {
                                    key: "rcimg" + index,
                                    staticClass: "ptd-bottom-img-list",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        crossorigin: "anonymous",
                                        src: item.imgUrl + "!120a",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ptd-bottom-img-list-title",
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                  ]
                                )
                              : _vm._e()
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showtitleflag, width: "1100px" },
          on: {
            "update:visible": function ($event) {
              _vm.showtitleflag = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "1000px" },
              attrs: { border: "", data: [] },
              on: { "header-dragend": _vm.changeColumnWidth },
            },
            _vm._l(_vm.showList, function (item, index) {
              return _c("el-table-column", {
                key: "table_" + index,
                attrs: {
                  prop: "prop",
                  type: item.field,
                  width: item.width,
                  label: item.zh_name,
                  index: index,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "60px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.showtitleflag = false
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }