var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "container" } }),
      _vm._v(" "),
      _vm.showSetStartPoint
        ? _c("toolbox", {
            attrs: { pointData: _vm.pointData, open3dEl: _vm.newopen3d },
            on: {
              closeAction: _vm.closeAction,
              savePoint: _vm.savePointAction,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showExhibition
        ? _c("exhibition", {
            attrs: { tabType: _vm.exhibitionType, open3dEl: _vm.newopen3d },
            on: { closeAction: _vm.closeAction },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditDetail
        ? _c("editDetail", {
            attrs: { open3dEl: _vm.newopen3d, editDetail: _vm.modifyDetail },
            on: { closeAction: _vm.closeAction },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showGuideWindow
        ? _c("guidance", {
            attrs: {
              tourIntroduction: _vm.tourIntroduction,
              newopen3d: _vm.newopen3d,
              guided_digital_man_config: _vm.guided_digital_man_config,
            },
            on: {
              closeAction: _vm.closeAction,
              editPeople: _vm.editPeopleAction,
              editTourIntroductionInfo: _vm.editTourIntroductionInfoAction,
              editDetaultAudioInfo: _vm.editDetaultAudioInfo,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }