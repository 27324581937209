var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _vm.collectMoney.show
        ? _c(
            "div",
            { attrs: { id: "printorder" } },
            [
              _c("div", { staticClass: "detail-box" }, [
                _c("div", { staticClass: "flex" }, [
                  _vm.merData.config && _vm.merData.config.mer_print_logo
                    ? _c("img", {
                        staticStyle: { height: "70px", "margin-right": "20px" },
                        attrs: {
                          src: _vm.merData.config.mer_print_logo + "!120a",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-left" }, [
                    _c("div", { staticClass: "detail-left-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.collectMoney.data.merConfig.company_name) +
                          " | " +
                          _vm._s(_vm.switchLang("BuyerTitle")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "14px",
                          "font-family": "'printEN'",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.collectMoney.data.merConfig
                                .company_english_name
                            ) +
                            "  ,Ltd Buyer's Transaction Details\n        "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "qrcodeBox" }, [
                  _c("div", { staticClass: "detail-abso" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.switchLang("Buyer")) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "secTit" }, [
                _vm._v(_vm._s(_vm.switchLang("BuyerCompany"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    "Thanks for your support to our company, we beg your understanding and also hope you can support us as usual!"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-title",
                  staticStyle: { "margin-top": "40px" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.collectMoney.data.venueInfo.title) + " "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _vm.collectMoney.data.userVerifyInfo
                    ? _c("div", { staticClass: "flex" }, [
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.switchLang("BuyerName")) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(" BUYER'S NAME\n        "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              "font-size": "16px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.collectMoney.data.userVerifyInfo.real_name
                              ) +
                                " " +
                                _vm._s(
                                  _vm.collectMoney.data.userVerifyInfo
                                    .originPhone
                                )
                            ),
                          ]
                        ),
                      ])
                    : _vm.collectMoney.data.userInfo
                    ? _c("div", { staticClass: "flex" }, [
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.switchLang("BuyerName")) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(" BUYER'S NAME \n        "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              "font-size": "16px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.collectMoney.data.userInfo.real_name) +
                                " " +
                                _vm._s(
                                  _vm.collectMoney.data.userInfo.originPhone
                                )
                            ),
                          ]
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          _vm._s(_vm.switchLang("BuyerName")) + " BUYER'S NAME"
                        ),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("BuyerNumber")) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" PADDLE NO.  \n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.collectMoney.data.numberPlate
                              ? _vm.collectMoney.data.numberPlate.number
                              : ""
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex" }, [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("BuyerTime")) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" DATE OF SALE  \n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.collectMoney.data.order_time))]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.collectMoney.data.orderData, function (item, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    staticClass: "ptd-box",
                    style:
                      ind < _vm.collectMoney.data.orderData.length - 1
                        ? "page-break-after: always;"
                        : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ptd",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTablezc"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("SESSION NAME")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTabletu"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("LOT NO.")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTablepp"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("DESCRIPTION")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTablelc"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("HAMMER PRICE")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.switchLang("BuyerTableyj")) +
                                  "(" +
                                  _vm._s(
                                    _vm.collectMoney.data.commissionConfig
                                      .after_commission &&
                                      _vm.collectMoney.data.commissionConfig
                                        .after_commission >
                                        _vm.collectMoney.data.commissionConfig
                                          .commission
                                      ? _vm.collectMoney.data.commissionConfig
                                          .after_commission
                                      : _vm.collectMoney.data.commissionConfig
                                          .commission
                                  ) +
                                  "%）"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("COMMISSION")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTableqt"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("OTHER")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                          ? _c(
                              "div",
                              {
                                staticClass: "ptd-title",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.feeTypeInfo.name) +
                                      _vm._s(_vm.switchLang("Amount"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.feeTypeInfo.type) + " SUBTOTAL"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTablecj"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("SUBTOTAL")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(item, function (m, i) {
                      return _c(
                        "div",
                        { key: "order" + i, staticClass: "ptd" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [_vm._v(_vm._s(m.album_info.album_name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [_vm._v(_vm._s(m.auction_info.lot))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [_vm._v(_vm._s(m.auction_info.store_name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                    ? _vm.fee_type.replace("CNY", "RMB")
                                    : ""
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      m.auction_now_price,
                                      2
                                    )
                                  )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                    ? _vm.fee_type.replace("CNY", "RMB")
                                    : ""
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      m.max_commission_price,
                                      2
                                    )
                                  )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              m.total_postage && m.delivery_type == 1
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("Freight")) +
                                        "：" +
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.total_postage,
                                            2
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              m.admin_discount_price
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("Discount")) +
                                        "：-" +
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.admin_discount_price,
                                            2
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.fee_type !=
                                        _vm.feeTypeInfo.mer_fee_type
                                        ? _vm.fee_type.replace("CNY", "RMB")
                                        : ""
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          m.fee_origin_total_price,
                                          2
                                        )
                                      )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                    ? _vm.feeTypeInfo.mer_fee_type.replace(
                                        "CNY",
                                        "RMB"
                                      )
                                    : ""
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatPriceNumber(m.total_price, 2)
                                  )
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ptd-box",
                  staticStyle: { "margin-top": "0", "border-top": "none" },
                },
                [
                  _c("div", { staticClass: "ptd" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        staticStyle: {
                          flex: "3",
                          "padding-left": "17px",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "text-align": "left" } }, [
                          _vm._v(_vm._s(_vm.switchLang("Total"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "'printEN'",
                              "text-align": "left",
                            },
                          },
                          [_vm._v("TOTAL")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.collectMoney.orderLength) + "件"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              _vm.collectMoney.data.total_auction_now_price,
                              2
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              _vm.collectMoney.data.total_max_commission_price,
                              2
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                      [
                        _vm.collectMoney.data.total_postage
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.switchLang("Freight")) +
                                  "：" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data.total_postage,
                                      2
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.collectMoney.data.total_admin_discount_price
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.switchLang("Discount")) +
                                  "：-" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .total_admin_discount_price,
                                      2
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                      ? _c("div", {
                          staticClass: "ptd-body",
                          staticStyle: { flex: "2" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                              ? _vm.feeTypeInfo.mer_fee_type.replace(
                                  "CNY",
                                  "RMB"
                                )
                              : ""
                          ) +
                            " " +
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_price,
                                2
                              )
                            )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ptd" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        staticStyle: {
                          flex: "3",
                          "margin-left": "-2px",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "padding-left": "14px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.switchLang("BuyerPending")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-family": "'printEN'",
                              "text-align": "left",
                              "padding-left": "14px",
                            },
                          },
                          [_vm._v("AMOUNT PENDING SETTLEMENT")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        style:
                          _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                            ? "flex: 12;font-weight: bold;margin-right: -57px;"
                            : "flex: 9;font-weight: bold;margin-right: 5px;",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              _vm.collectMoney.data.total_price,
                              2
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.feeTypeInfo.mer_fee_type == "CNY"
                    ? _c("div", { staticClass: "ptd" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: {
                              flex: "3",
                              "margin-left": "-2px",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  "padding-left": "14px",
                                },
                              },
                              [_vm._v("人民币（大写）")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-family": "'printEN'",
                                  "text-align": "left",
                                  "padding-left": "14px",
                                },
                              },
                              [_vm._v("CAPITAL IN RMB")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            style:
                              _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                ? "flex: 12;font-weight: bold;margin-right: -57px;"
                                : "flex: 9;font-weight: bold;margin-right: 5px;",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.collectMoney.data.total_price_rmb)
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.collectMoney.data.commissionConfig.after_commission
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "secTit",
                        staticStyle: { "margin-top": "30px" },
                      },
                      [
                        _vm._v(
                          "*" + _vm._s(_vm.switchLang("BuyerOneText")) + " "
                        ),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.parseTime(
                                _vm.collectMoney.data.commissionConfig
                                  .commission_time,
                                "{y}年{m}月{d}日"
                              )
                            ) + "前"
                          ),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.switchLang("BuyerTwoText")) + " "
                        ),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(_vm.switchLang("BuyerTheText")) +
                              _vm._s(
                                _vm.collectMoney.data.commissionConfig
                                  .commission
                              ) +
                              "%"
                          ),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.switchLang("BuyerFourText")) + ": "
                        ),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_current_pay_price,
                                2
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "13px",
                          "font-family": "'printEN'",
                          "font-weight": "500",
                        },
                      },
                      [
                        _vm._v(
                          "\n        If the buyer pays in full by the " +
                            _vm._s(
                              _vm.parseTime(
                                _vm.collectMoney.data.commissionConfig
                                  .commission_time,
                                "{y}-{m}-{d}"
                              )
                            ) +
                            ", they can enjoy a " +
                            _vm._s(
                              _vm.collectMoney.data.commissionConfig.commission
                            ) +
                            "%, discount on the commission, bringing the total to $" +
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_current_pay_price,
                                2
                              )
                            ) +
                            ".\n      "
                        ),
                      ]
                    ),
                  ]
                : _c("div", { staticStyle: { "margin-top": "30px" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "secTit", staticStyle: { "margin-top": "5px" } },
                [
                  _vm._v(
                    _vm._s(_vm.switchLang("Account")) +
                      "(Account Name)：" +
                      _vm._s(
                        _vm.collectMoney.data.merConfig
                          ? _vm.collectMoney.data.merConfig.bank_name
                          : ""
                      )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "secTit", staticStyle: { "margin-top": "5px" } },
                [
                  _vm._v(
                    _vm._s(_vm.switchLang("Bank")) +
                      "(Bank Name)：" +
                      _vm._s(
                        _vm.collectMoney.data.merConfig
                          ? _vm.collectMoney.data.merConfig.bank
                          : ""
                      )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "secTit", staticStyle: { "margin-top": "5px" } },
                [
                  _vm._v(
                    _vm._s(_vm.switchLang("AccountNum")) +
                      "(Account number)：" +
                      _vm._s(
                        _vm.collectMoney.data.merConfig
                          ? _vm.collectMoney.data.merConfig.bank_number
                          : ""
                      )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticStyle: {
                  width: "100%",
                  "border-top": "2px solid #000",
                  "margin-top": "60px",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ptd-bottom", attrs: { id: "ptd-bottom" } },
                [
                  _c("div", { staticClass: "ptd-bottom-left" }, [
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.switchLang("CompanyAddress")) +
                          "：" +
                          _vm._s(_vm.merData.config.company_address) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        "\n          Company Address: " +
                          _vm._s(
                            _vm.merData.config.company_english_address || ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ptd-bottom-left-list" }, [
                      _vm._v(
                        _vm._s(_vm.switchLang("CompanyPhone")) +
                          "/TEL： " +
                          _vm._s(_vm.merData.config.contact_phone)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ptd-bottom-right" },
                    _vm._l(
                      _vm.merData.config.mer_qrcode_data,
                      function (item, index) {
                        return item.imgUrl
                          ? _c(
                              "div",
                              {
                                key: "rcimg" + index,
                                staticClass: "ptd-bottom-img-list",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    crossorigin: "anonymous",
                                    src: item.imgUrl + "!120a",
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "ptd-bottom-img-list-title" },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    0
                  ),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }