var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "TPItem" }, [
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202461/image/1717235781442273543.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "TPTit" }, [_vm._v(" 现场观众投屏（普通）")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "TPBut" },
          [
            _vm.AData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goScreenProjectionAction("A")
                      },
                    },
                  },
                  [_vm._v(" A厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.BData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goScreenProjectionAction("B")
                      },
                    },
                  },
                  [_vm._v(" B厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.CData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goScreenProjectionAction("C")
                      },
                    },
                  },
                  [_vm._v(" C厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.DData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goScreenProjectionAction("D")
                      },
                    },
                  },
                  [_vm._v(" D厅页面 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "TPItem" }, [
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202461/image/1717235780338164323.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "TPTit" }, [_vm._v(" 现场观众投屏（LED）")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "TPBut" },
          [
            _vm.AData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goLEDAction("A")
                      },
                    },
                  },
                  [_vm._v(" A厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.BData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goLEDAction("B")
                      },
                    },
                  },
                  [_vm._v(" B厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.CData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goLEDAction("C")
                      },
                    },
                  },
                  [_vm._v(" C厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.DData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goLEDAction("D")
                      },
                    },
                  },
                  [_vm._v(" D厅页面 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "TPItem" }, [
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202461/image/1717235780338164323.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "TPTit" }, [
          _vm._v(" 现场观众投屏（LED 三屏）"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "TPBut" },
          [
            _vm.AData.length > 0
              ? _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c("el-button", { attrs: { type: "primary" } }, [
                      _vm._v(" A厅页面 "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "A",
                                    "/merchant/ledThereLeft?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led左")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "A",
                                    "/merchant/ledThereCenter?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led中")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "A",
                                    "/merchant/ledThereRight?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led右")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.BData.length > 0
              ? _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c("el-button", { attrs: { type: "primary" } }, [
                      _vm._v(" B厅页面 "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "B",
                                    "/merchant/ledThereLeft?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led左")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "B",
                                    "/merchant/ledThereCenter?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led中")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "B",
                                    "/merchant/ledThereRight?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led右")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.CData.length > 0
              ? _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c("el-button", { attrs: { type: "primary" } }, [
                      _vm._v(" C厅页面 "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "C",
                                    "/merchant/ledThereLeft?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led左")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "C",
                                    "/merchant/ledThereCenter?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led中")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "C",
                                    "/merchant/ledThereRight?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led右")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.DData.length > 0
              ? _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c("el-button", { attrs: { type: "primary" } }, [
                      _vm._v(" D厅页面 "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "D",
                                    "/merchant/ledThereLeft?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led左")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "D",
                                    "/merchant/ledThereCenter?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led中")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goLEDThereAction(
                                    "D",
                                    "/merchant/ledThereRight?id="
                                  )
                                },
                              },
                            },
                            [_vm._v("led右")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "TPItem" }, [
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202461/image/1717235781025795351.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "TPTit" }, [_vm._v(" 拍卖师屏 ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "TPBut" },
          [
            _vm.AData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goHostAction("A")
                      },
                    },
                  },
                  [_vm._v(" A厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.BData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goHostAction("B")
                      },
                    },
                  },
                  [_vm._v(" B厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.CData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goHostAction("C")
                      },
                    },
                  },
                  [_vm._v(" C厅页面 ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.DData.length > 0
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goHostAction("D")
                      },
                    },
                  },
                  [_vm._v(" D厅页面 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }