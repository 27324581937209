var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    {
      attrs: {
        title: "参拍须知",
        "has-template": _vm.$route.query.type == "manage" ? "1" : "",
      },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "mt20" }, [
                _c("div", { staticClass: "flex-one" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("请在“设置-协议配置-参拍须知”设置完内容后添加组件"),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("风格参数设置"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域外间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginTop", $$v)
                            },
                            expression: "facade.pageMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginBottom", $$v)
                            },
                            expression: "facade.pageMarginBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginLeft", $$v)
                            },
                            expression: "facade.pageMarginLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginRight", $$v)
                            },
                            expression: "facade.pageMarginRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域内间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingTop", $$v)
                            },
                            expression: "facade.pagePaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingBottom", $$v)
                            },
                            expression: "facade.pagePaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingLeft", $$v)
                            },
                            expression: "facade.pagePaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingRight", $$v)
                            },
                            expression: "facade.pagePaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.pageBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageBackground", $$v)
                      },
                      expression: "facade.pageBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框大小" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderTop", $$v)
                            },
                            expression: "facade.borderTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderBottom", $$v)
                            },
                            expression: "facade.borderBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderLeft", $$v)
                            },
                            expression: "facade.borderLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderRight", $$v)
                            },
                            expression: "facade.borderRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.borderColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "borderColor", $$v)
                      },
                      expression: "facade.borderColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "最大高度" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 2000 },
                    model: {
                      value: _vm.facade.maxHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "maxHeight", $$v)
                      },
                      expression: "facade.maxHeight",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.pageColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageColor", $$v)
                      },
                      expression: "facade.pageColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 12, max: 50 },
                    model: {
                      value: _vm.facade.fontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "fontSize", $$v)
                      },
                      expression: "facade.fontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.fontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "fontWeight", $$v)
                        },
                        expression: "facade.fontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "temp" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "模版字段" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.facade.temp,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "temp", $$v)
                        },
                        expression: "facade.temp",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "pageBackground" } },
                            [_vm._v("背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "borderColor" } },
                            [_vm._v("边框颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-checkbox", { attrs: { label: "pageColor" } }, [
                            _vm._v("文字颜色"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }