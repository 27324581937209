var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container help-detail",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.prize_data, border: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "满足条件", prop: "number", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n          连续签到 "),
                    _c("el-input-number", {
                      staticStyle: { width: "100px" },
                      attrs: { controls: false, disabled: scope.$index == 0 },
                      model: {
                        value: scope.row.day,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "day", $$v)
                        },
                        expression: "scope.row.day",
                      },
                    }),
                    _vm._v(" 天 "),
                    scope.$index == 0
                      ? _c("span", [_vm._v("（每天可领的基础奖励）")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.editTypeAction(scope.$index)
                          },
                        },
                        model: {
                          value: scope.row.type,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "type", $$v)
                          },
                          expression: "scope.row.type",
                        },
                      },
                      [
                        _c("el-option", { attrs: { value: 1, label: "积分" } }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: 2, label: "数字藏品" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: 3, label: "数字盲盒" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: 4, label: "优惠券" },
                        }),
                        _vm._v(" "),
                        _c("el-option", { attrs: { value: 5, label: "商品" } }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.name
                      ? _c(
                          "div",
                          {
                            staticClass: "flex align-items-c",
                            staticStyle: { height: "36px", overflow: "hidden" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "line2 flex-one",
                                staticStyle: { "line-height": "18px" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(scope.row.name) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type == 2 ||
                    scope.row.type == 3 ||
                    scope.row.type == 4 ||
                    scope.row.type == 5
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAdditem(
                                  scope.row.type,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("选择")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type == 1
                      ? _c("el-input-number", {
                          staticStyle: { width: "100px" },
                          attrs: { controls: false },
                          model: {
                            value: scope.row.prize,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "prize", $$v)
                            },
                            expression: "scope.row.prize",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.saveRule(scope.row, scope.$index)
                          },
                        },
                      },
                      [_vm._v("\n            保存\n          ")]
                    ),
                    _vm._v(" "),
                    scope.$index > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.delRule(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.addrule },
            },
            [_vm._v("添加规则")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectProduct", {
        ref: "product",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Productblind", {
        ref: "blind_box",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Coupon", {
        ref: "coupon",
        attrs: { currentData: [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }