var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        padding:
          _vm.count === 1 ? "" : "0 " + _vm.facade.listPageMargin / 2 + "px",
        paddingTop:
          _vm.count === 1 ? "0" : _vm.facade.listPageMargin / 2 + "px",
        background: _vm.count === 1 ? "" : "#F9FCFF",
        height: _vm.count === 1 ? "auto" : "600px",
        border: _vm.count === 1 ? "none" : "1px solid #f0f0f0",
      },
    },
    [
      _vm._l(_vm.count, function (m, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "item flex justify-b",
            style: {
              background: _vm.facade.listGradientBackground
                ? "linear-gradient(" +
                  _vm.facade.listGradientDirection +
                  ", " +
                  _vm.facade.listPageBackground +
                  ", " +
                  _vm.facade.listGradientBackground +
                  ")"
                : _vm.facade.listPageBackground,
              "margin-bottom": _vm.facade.listItemMargin / 2 + "px",
              padding:
                _vm.facade.listPaddingTop / 2 +
                "px " +
                _vm.facade.listPaddingRight / 2 +
                "px " +
                _vm.facade.listPaddingBottom / 2 +
                "px " +
                _vm.facade.listPaddingLeft / 2 +
                "px",
              "border-radius":
                _vm.facade.listTopLeftRadius / 2 +
                "px " +
                _vm.facade.listTopRightRadius / 2 +
                "px " +
                _vm.facade.listBottomRightRadius / 2 +
                "px " +
                _vm.facade.listBottomLeftRadius / 2 +
                "px",
              "box-shadow":
                _vm.facade.listShadowSize && _vm.facade.listShadowColor
                  ? "0 0 " +
                    _vm.facade.listShadowSize / 2 +
                    "px " +
                    _vm.facade.listShadowColor
                  : "unset",
              "border-style": "solid",
              "border-width":
                (_vm.facade.listTopBorder || 0) / 2 +
                "px " +
                (_vm.facade.listRightBorder || 0) / 2 +
                "px " +
                (_vm.facade.listBottomBorder || 0) / 2 +
                "px " +
                (_vm.facade.listLeftBorder || 0) / 2 +
                "px",
              "border-color": _vm.facade.listBorderColor,
            },
          },
          [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "name",
                  style: "color: " + _vm.facade.listMainColor,
                },
                [_vm._v("列表标题文字")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "desc",
                  style: "color: " + _vm.facade.listSubColor,
                },
                [_vm._v("列表副文本")]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "special",
                  style: "color: " + _vm.facade.listStatusColor,
                },
                [_vm._v("状态文字")]
              ),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _vm.count !== 1
        ? _c(
            "div",
            { staticClass: "more", style: "color: " + _vm.facade.moreColor },
            [_vm._v("下拉加载更多...")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }