var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "报表", hasBack: true, isFixed: true },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "flex align-items-c",
                    staticStyle: { color: "#3480F4", cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.onGOUrl("/merchant/auction/reportsetting", 1)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "16px",
                        height: "16px",
                        "margin-right": "5px",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                        alt: "",
                      },
                    }),
                    _vm._v("\n          配置\n      "),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c("div", { staticClass: "top-view" }, [
            _c(
              "div",
              { staticStyle: { padding: "0 20px" } },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo default-tabs mt10",
                    attrs: {
                      "default-active": _vm.routerUrl,
                      mode: "horizontal",
                    },
                    on: { select: _vm.changeTab },
                  },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/auction/report/auctionreport",
                        },
                      },
                      [_vm._v("上拍通知书")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        attrs: {
                          index: "/merchant/auction/report/afterNotice",
                        },
                      },
                      [_vm._v("拍后告知书")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value:
                              "merchantSystemExcelExport:albumAuctionRecords",
                            expression:
                              "'merchantSystemExcelExport:albumAuctionRecords'",
                          },
                        ],
                        attrs: { index: "/merchant/auction/report/antique" },
                      },
                      [_vm._v("文物局报表")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }