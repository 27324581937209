var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "set-content",
      staticStyle: { height: "100%", padding: "0" },
    },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "100px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.pageBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageBackground", $$v)
                      },
                      expression: "facade.pageBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "渐变颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.pageGradientBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageGradientBackground", $$v)
                      },
                      expression: "facade.pageGradientBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "渐变方向" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.pageGradientDirection,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "pageGradientDirection", $$v)
                        },
                        expression: "facade.pageGradientDirection",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "to bottom" } }, [
                        _vm._v("上下"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right" } }, [
                        _vm._v("左右"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "to right bottom" } }, [
                        _vm._v("左上右下"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt10" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("背景图"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(1, "pageBackgroundUrl")
                      },
                    },
                  },
                  [
                    _vm.facade.pageBackgroundUrl
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delFacadeImg("pageBackgroundUrl")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.pageBackgroundUrl
                      ? _c("img", {
                          attrs: { src: _vm.facade.pageBackgroundUrl + "!300" },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex-one",
                    staticStyle: { "margin-left": "30px" },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.facade.pagePosition,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "pagePosition", $$v)
                          },
                          expression: "facade.pagePosition",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "fixed" } }, [
                          _vm._v("固定"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "absolute" } }, [
                          _vm._v("跟随滚动"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "主文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "color", $$v)
                      },
                      expression: "facade.color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "副文本" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.subColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "subColor", $$v)
                      },
                      expression: "facade.subColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "链接文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.linkColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "linkColor", $$v)
                      },
                      expression: "facade.linkColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "高亮文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.specialColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "specialColor", $$v)
                      },
                      expression: "facade.specialColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "价格文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.priceColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "priceColor", $$v)
                      },
                      expression: "facade.priceColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.fontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "fontWeight", $$v)
                        },
                        expression: "facade.fontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "页面风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.pageStyle,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "pageStyle", $$v)
                        },
                        expression: "facade.pageStyle",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "black" } }, [
                        _vm._v("浅色系"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "white" } }, [
                        _vm._v("深色系"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }