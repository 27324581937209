var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "common-page-title" }, [
        _c("img", {
          staticClass: "back",
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024529/image/1716954194860207587.png!120a",
            alt: "返回",
          },
          on: { click: _vm.backAction },
        }),
        _vm._v(_vm._s(_vm.id ? "门票详情" : "创建门票")),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "门票封面：", prop: "cover" } },
                [
                  _c("div", [_vm._v("图片尺寸：1200×1600或等比例")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-view flex align-items-c justify-c",
                      on: { click: _vm.changeImg },
                    },
                    [
                      _vm.ruleForm.cover
                        ? _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: _vm.ruleForm.cover + "!m640",
                              fit: "cover",
                            },
                          })
                        : _c("i", { staticClass: "el-icon-plus" }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "门票名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "输入门票名称" },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "门票类型：", prop: "ticket_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择门票类型" },
                      model: {
                        value: _vm.ruleForm.ticket_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "ticket_type", $$v)
                        },
                        expression: "ruleForm.ticket_type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "门票", value: 1 } }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "预约活动", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "问卷调查：", prop: "questionnaire_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择问卷调查" },
                      model: {
                        value: _vm.ruleForm.questionnaire_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "questionnaire_id", $$v)
                        },
                        expression: "ruleForm.questionnaire_id",
                      },
                    },
                    _vm._l(_vm.questionnaireList, function (item) {
                      return _c("el-option", {
                        key: item.naire_id,
                        attrs: { label: item.title, value: item.naire_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上下架时间：", prop: "times" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "选择开始日期",
                      "end-placeholder": "选择结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.ruleForm.times,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "times", $$v)
                      },
                      expression: "ruleForm.times",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "ivu-form-item-required mt10",
                  attrs: { label: "显示状态：" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.is_hide,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_hide", $$v)
                        },
                        expression: "ruleForm.is_hide",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("显示")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("隐藏")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实名购票：", prop: "is_verified" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex align-items-c",
                      staticStyle: { height: "36px" },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        on: { change: _vm.changeVerifiedAction },
                        model: {
                          value: _vm.ruleForm.is_verified,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "is_verified", $$v)
                          },
                          expression: "ruleForm.is_verified",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "ml20",
                          attrs: {
                            "true-label": 1,
                            "false-label": 0,
                            disabled: !_vm.ruleForm.is_verified,
                          },
                          model: {
                            value: _vm.ruleForm.more_verified,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "more_verified", $$v)
                            },
                            expression: "ruleForm.more_verified",
                          },
                        },
                        [_vm._v("购买多张需全部实名")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: { effect: "dark", placement: "top" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                "\n              勾选此项购买几张门票则需要添加几人的实名，"
                              ),
                              _c("br"),
                              _vm._v("不勾选仅提交1人实名即可\n            "),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: { cursor: "pointer" },
                              style: _vm.ruleForm.is_verified
                                ? ""
                                : "color: #C0C4CC",
                            },
                            [_vm._v("\n              （？）\n            ")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ml20",
                          staticStyle: { color: "#606266" },
                        },
                        [_vm._v("可赠送：")]
                      ),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ccc",
                          "active-value": 1,
                          "inactive-value": 0,
                          disabled: !!_vm.ruleForm.is_verified,
                        },
                        model: {
                          value: _vm.ruleForm.is_gift,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "is_gift", $$v)
                          },
                          expression: "ruleForm.is_gift",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "ivu-form-item-required mt10",
                  attrs: { label: "是否可预约当天：" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.is_whether_today,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_whether_today", $$v)
                        },
                        expression: "ruleForm.is_whether_today",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不可以"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("可以")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "ivu-form-item-required mt10",
                  attrs: { label: "可修改预约时间：" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.allow_update_time,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "allow_update_time", $$v)
                        },
                        expression: "ruleForm.allow_update_time",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不可以修改"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("可以修改"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.allow_update_time
                ? _c(
                    "el-form-item",
                    { attrs: { label: "可修改次数：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "w300",
                        attrs: { placeholder: "输入可修改预约次数" },
                        model: {
                          value: _vm.ruleForm.allow_update_time_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "allow_update_time_num", $$v)
                          },
                          expression: "ruleForm.allow_update_time_num",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用地点：", prop: "address" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("el-input", {
                        staticClass: "w300",
                        attrs: { placeholder: "输入地点名称" },
                        model: {
                          value: _vm.ruleForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "address", $$v)
                          },
                          expression: "ruleForm.address",
                        },
                      }),
                      _vm._v(" "),
                      _c("ChangeMap", {
                        staticClass: "ml20",
                        model: {
                          value: _vm.ruleForm.address_coord,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "address_coord", $$v)
                          },
                          expression: "ruleForm.address_coord",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "票种设置：", prop: "spec" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "common-table customize-crowd-table",
                      staticStyle: { background: "#fff" },
                    },
                    [
                      _c("div", { staticClass: "t-head" }, [
                        _c("div", { staticClass: "tr item-align-center" }, [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "15%" },
                            },
                            [_vm._v("票种名称")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "20%" },
                            },
                            [_vm._v("上下架时间")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "20%" },
                            },
                            [_vm._v("有效期")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "td", staticStyle: { width: "9%" } },
                            [_vm._v("数量")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "td", staticStyle: { width: "8%" } },
                            [_vm._v("价格")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "td", staticStyle: { width: "8%" } },
                            [_vm._v("会员价格")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("单人购买限制")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "td", staticStyle: { width: "8%" } },
                            [_vm._v("显示状态")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "td", staticStyle: { width: "8%" } },
                            [_vm._v("操作")]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "t-body" },
                        _vm._l(_vm.ruleForm.spec, function (m, i) {
                          return _c(
                            "div",
                            {
                              key: "spec" + i,
                              staticClass: "tr item-align-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "15%" },
                                },
                                [_vm._v(_vm._s(m.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: {
                                    width: "20%",
                                    "line-height": "20px",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "上架 " +
                                        _vm._s(_vm.parseTime(m.upward_time))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "下架 " +
                                        _vm._s(_vm.parseTime(m.down_time))
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: {
                                    width: "20%",
                                    "line-height": "20px",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "起始 " +
                                        _vm._s(_vm.parseTime(m.start_time))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "截止 " +
                                        _vm._s(_vm.parseTime(m.end_time))
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "9%" },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatPriceNumber(m.stock, 2)
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "8%" },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatPriceNumber(m.price, 2)
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "8%" },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatPriceNumber(m.vip_price, 2) >
                                          0
                                          ? _vm.formatPriceNumber(
                                              m.vip_price,
                                              2
                                            )
                                          : _vm.formatPriceNumber(m.price, 2)
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      m.infinite ? "不限" : m.purchase_limit
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "8%" },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(m.is_show ? "显示" : "已隐藏") +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td flex align-items-c",
                                  staticStyle: { width: "8%" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editTicketTypeAction(i)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "commonedit" },
                                      }),
                                      _vm._v("编辑"),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delTicketTypeAction(i)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSetTicketData,
                          expression: "showSetTicketData",
                        },
                      ],
                      staticClass: "cardinfo",
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ivu-form-item-required",
                          attrs: { label: "票种名称：" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "输入票种名称" },
                            model: {
                              value: _vm.ticketData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ticketData, "name", $$v)
                              },
                              expression: "ticketData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ivu-form-item-required mt20",
                          attrs: { label: "上下架时间：" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "选择开始日期",
                              "end-placeholder": "选择结束日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.ticketData.times,
                              callback: function ($$v) {
                                _vm.$set(_vm.ticketData, "times", $$v)
                              },
                              expression: "ticketData.times",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ivu-form-item-required mt20",
                          attrs: { label: "有效期：" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "选择开始日期",
                              "end-placeholder": "选择结束日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.ticketData.period,
                              callback: function ($$v) {
                                _vm.$set(_vm.ticketData, "period", $$v)
                              },
                              expression: "ticketData.period",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "ivu-form-item-required mt20",
                              attrs: { label: "售价：" },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "w100",
                                attrs: {
                                  disabled: _vm.ruleForm.ticket_type == 2,
                                  step: 100,
                                  min: 0,
                                  controls: false,
                                },
                                model: {
                                  value: _vm.ticketData.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ticketData, "price", $$v)
                                  },
                                  expression: "ticketData.price",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "ivu-form-item-required mt20",
                              attrs: { label: "票种库存：" },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "w100",
                                attrs: {
                                  step: 1,
                                  min: 0,
                                  controls: false,
                                  disabled: !!_vm.ticketData.spec_id,
                                },
                                model: {
                                  value: _vm.ticketData.stock,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ticketData, "stock", $$v)
                                  },
                                  expression: "ticketData.stock",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "ivu-form-item-required mt20",
                              attrs: { label: "单人限购：" },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.ticketData.infinite,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ticketData, "infinite", $$v)
                                    },
                                    expression: "ticketData.infinite",
                                  },
                                },
                                [_vm._v("不限")]
                              ),
                              _vm._v(" "),
                              _c("el-input-number", {
                                staticClass: "w100 ml10",
                                attrs: {
                                  step: 1,
                                  min: 1,
                                  controls: false,
                                  disabled: _vm.ticketData.infinite,
                                },
                                model: {
                                  value: _vm.ticketData.purchase_limit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ticketData,
                                      "purchase_limit",
                                      $$v
                                    )
                                  },
                                  expression: "ticketData.purchase_limit",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("次"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "ivu-form-item-required mt20",
                              attrs: { label: "单张可核销：" },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "w100",
                                attrs: { step: 1, min: 1, controls: false },
                                model: {
                                  value: _vm.ticketData.use_num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ticketData, "use_num", $$v)
                                  },
                                  expression: "ticketData.use_num",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("次"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ivu-form-item-required mt20",
                          attrs: { label: "会员权益：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ticketData.vip_right,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ticketData, "vip_right", $$v)
                                },
                                expression: "ticketData.vip_right",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex align-items-c",
                                  staticStyle: { height: "38px" },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("无"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("会员免费"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticClass: "w100 mr20",
                                    attrs: {
                                      placeholder: "可领数量",
                                      disabled: _vm.ticketData.vip_right != 1,
                                    },
                                    model: {
                                      value: _vm.ticketData.vip_right_discount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ticketData,
                                          "vip_right_discount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ticketData.vip_right_discount",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("会员折扣"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticClass: "w100 mr20",
                                    attrs: {
                                      placeholder: "可购数量",
                                      disabled: _vm.ticketData.vip_right != 2,
                                    },
                                    model: {
                                      value: _vm.ticketData.vip_right_discount1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ticketData,
                                          "vip_right_discount1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ticketData.vip_right_discount1",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "vip-zhekou-box" },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { disabled: "", label: 3 } },
                                        [_vm._v("会员价格")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticClass: "w100",
                                        attrs: {
                                          placeholder: "会员价格",
                                          disabled:
                                            _vm.ticketData.vip_right != 2,
                                        },
                                        model: {
                                          value: _vm.ticketData.vip_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ticketData,
                                              "vip_price",
                                              $$v
                                            )
                                          },
                                          expression: "ticketData.vip_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ivu-form-item-required mt10",
                          attrs: { label: "显示状态：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ticketData.is_show,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ticketData, "is_show", $$v)
                                },
                                expression: "ticketData.is_show",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("显示"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("隐藏"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ivu-form-item-required mt10",
                          attrs: { label: "排序：" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "w100",
                            attrs: { placeholder: "排序" },
                            model: {
                              value: _vm.ticketData.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.ticketData, "sort", $$v)
                              },
                              expression: "ticketData.sort",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ivu-form-item-required mt10",
                          attrs: { label: "使用时段：" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ccc",
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.ticketData.is_period,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ticketData, "is_period", $$v)
                                  },
                                  expression: "ticketData.is_period",
                                },
                              }),
                              _vm._v(" "),
                              !_vm.ticketData.spec_id
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "ml20",
                                      attrs: {
                                        disabled: !_vm.ticketData.is_period,
                                      },
                                      on: { click: _vm.addTimesAction },
                                    },
                                    [_vm._v("+添加使用时段")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.ticketData.spec_id
                            ? _vm._l(_vm.ticketData.date, function (m, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "mt10 flex align-items-c pr20",
                                    staticStyle: { width: "800px" },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(m.start_date) +
                                          " 至 " +
                                          _vm._s(m.end_date)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-one line1 ml10 mr10",
                                      },
                                      _vm._l(m.time, function (item, index) {
                                        return _c(
                                          "span",
                                          {
                                            key: index,
                                            staticClass: "color-gray ft12 mr5",
                                          },
                                          [
                                            _vm._v(
                                              "[" +
                                                _vm._s(item.start_time) +
                                                "-" +
                                                _vm._s(item.end_time) +
                                                "可预约" +
                                                _vm._s(item.max_num) +
                                                "人]"
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editTimesAction(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "commonedit" },
                                        }),
                                        _vm._v("编辑"),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delTimesAction(i)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                )
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: " " } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { type: "primary" },
                              on: { click: _vm.saveTicketTypeAction },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.ticketTypeIndex === null
                                    ? "确认添加"
                                    : "确认修改"
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.clearSetTicketTypeAction },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20" },
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.addTicketTypeAction } },
                        [_vm._v("添加票种")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isAccountPayType == "338349"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "30px" },
                      attrs: { label: "入账账户：" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.pay_account_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "pay_account_id", $$v)
                                },
                                expression: "ruleForm.pay_account_id",
                              },
                            },
                            _vm._l(_vm.paymentAccountList, function (item) {
                              return _c("el-option", {
                                key: item.pay_account_id,
                                attrs: {
                                  label: item.name,
                                  value: item.pay_account_id,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "\n              如果不选择会进入系统配置的默认账户\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { cursor: "pointer" } },
                                [_vm._v("\n              （？）\n            ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "门票介绍：" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.ruleForm.message_reminder,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "message_reminder", $$v)
                      },
                      expression: "ruleForm.message_reminder",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "弹窗提醒：" } },
                [
                  _c("div", [_vm._v("用户进入购票页面时，弹出提示文字：")]),
                  _vm._v(" "),
                  _c("Tinymce", {
                    ref: "editor1",
                    staticClass: "mt10",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.ruleForm.message_introduce,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "message_introduce", $$v)
                      },
                      expression: "ruleForm.message_introduce",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAction("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加使用时段",
            visible: _vm.showSetTimesData.show,
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showSetTimesData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "选择开始日期",
                  "end-placeholder": "选择结束日期",
                },
                model: {
                  value: _vm.showSetTimesData.dates,
                  callback: function ($$v) {
                    _vm.$set(_vm.showSetTimesData, "dates", $$v)
                  },
                  expression: "showSetTimesData.dates",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex align-items-c" },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "350px" },
                  attrs: {
                    placeholder: "请选择排除周几",
                    multiple: "",
                    clearable: "",
                  },
                  model: {
                    value: _vm.showSetTimesData.exclude,
                    callback: function ($$v) {
                      _vm.$set(_vm.showSetTimesData, "exclude", $$v)
                    },
                    expression: "showSetTimesData.exclude",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 1, label: "周一" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 2, label: "周二" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 3, label: "周三" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 4, label: "周四" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 5, label: "周五" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 6, label: "周六" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 7, label: "周日" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.showSetTimesData.times, function (m, i) {
            return _c(
              "div",
              { key: i, staticClass: "mt20 flex align-items-c" },
              [
                _c("el-time-picker", {
                  attrs: {
                    "is-range": "",
                    "range-separator": "至",
                    "start-placeholder": "选择开始时间",
                    "end-placeholder": "选择结束时间",
                    placeholder: "选择时间范围",
                  },
                  model: {
                    value: m.time_range,
                    callback: function ($$v) {
                      _vm.$set(m, "time_range", $$v)
                    },
                    expression: "m.time_range",
                  },
                }),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "ml20 w100 mr10",
                  attrs: { placeholder: "数量" },
                  model: {
                    value: m.count,
                    callback: function ($$v) {
                      _vm.$set(m, "count", $$v)
                    },
                    expression: "m.count",
                  },
                }),
                _vm._v(" "),
                i > 0
                  ? _c("i", {
                      staticClass: "cz el-icon-remove-outline",
                      on: {
                        click: function ($event) {
                          return _vm.removeTimeAction(i)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("i", {
                  staticClass: "cz el-icon-circle-plus-outline",
                  on: { click: _vm.addTimeAction },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "left" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSetTimesData.show = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setTimesAction },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }