"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRechargeOrderApi = createRechargeOrderApi;
exports.isOpen3dApi = isOpen3dApi;
exports.rechargeOrderListApi = rechargeOrderListApi;
exports.templateInfoApi = templateInfoApi;
exports.templateListApi = templateListApi;
exports.templateMarketListApi = templateMarketListApi;
exports.templateSetInfoApi = templateSetInfoApi;
exports.templateSwitchApi = templateSwitchApi;
exports.userSpaceApi = userSpaceApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 模型列表
 */
function templateListApi(data) {
  return _request.default.get('open3d/template/merchantTemplateList', data);
}

/**
 * @description 模型修改状态
 */
function templateSwitchApi(data) {
  return _request.default.post('/open3d/template/switch', data);
}

/**
 * @description 模型详情
 */
function templateInfoApi(data) {
  return _request.default.get('/open3d/template/getInfo', data);
}

/**
 * @description 模型修改设置
 */
function templateSetInfoApi(data) {
  return _request.default.post('/open3d/template/setInfo', data);
}

/**
 * @description 用户空间
 */
function userSpaceApi(data) {
  return _request.default.post('/exhibitionUser/getList', data);
}

/**
 * @description 模型市场列表
 */
function templateMarketListApi(data) {
  return _request.default.get('/open3d/template/market', data);
}
/**
 * @description 模型市场列表 - 获取支付二维码
 */
function createRechargeOrderApi(data) {
  return _request.default.post('/open3d/template/buy', data);
}

/**
 * @description 我的订单列表
 */
function rechargeOrderListApi(data) {
  return _request.default.get('/open3d/template/orderList', data);
}

/**
 * @description 是否有3d空间权限
 */
function isOpen3dApi(data) {
  return _request.default.get('/open3d/isOpen', data);
}