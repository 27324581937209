var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("添加品牌")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 175px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                height: "100%",
                "row-key": "brand_id",
                "default-expand-all": false,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "brand_id", label: "ID", "min-width": "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "品牌名称",
                  prop: "brand_name",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "排序", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "是否显示",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.is_show,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "is_show", $$v)
                            },
                            expression: "scope.row.is_show",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row.brand_id)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "commonedit" },
                            }),
                            _vm._v("编辑"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.brand_id,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("导入")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "uploadImg",
                              staticStyle: { display: "inline-block" },
                              attrs: {
                                action: _vm.fileUrl,
                                "on-success": _vm.handleSuccess,
                                headers: _vm.myHeaders,
                                data: { import_type: "import_store_brand" },
                                "show-file-list": true,
                                name: "excel",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "text" } },
                                [_vm._v("导入")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.downLoad },
                            },
                            [_vm._v("导入模板")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }