"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    listLoading: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      showImg: false,
      imgSrc: ''
    };
  },
  methods: {
    showImgAction: function showImgAction(img) {
      this.imgSrc = img;
      this.showImg = true;
    }
  }
};