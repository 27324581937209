"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      integralData: null,
      routerUrl: ''
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    this.loadStreamInfo();
    this.routerUrl = this.$route.path;
  },
  methods: {
    changeTab: function changeTab(data) {
      var _this = this;
      if (data !== this.routerUrl) {
        this.$router.push({
          path: data
        });
        this.routerUrl = data;
        setTimeout(function () {
          _this.routerUrl = _this.$route.path;
        });
      }
    },
    loadStreamInfo: function loadStreamInfo() {
      var _this2 = this;
      (0, _marketing.integralConfStatisticsApi)().then(function (res) {
        _this2.integralData = res.data;
      });
    }
  }
};