"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Article",
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      tableFrom: {
        page: 1,
        limit: 20,
        title: ""
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _marketing.synthesisListApi)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          var starts = new Date(item.start_time) / 1000;
          var ends = new Date(item.end_time) / 1000;
          if (res.server_time > ends) {
            item.status = '已结束';
          } else if (res.server_time < starts) {
            item.status = '未开始';
          } else {
            item.status = '进行中';
          }
        });
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure().then(function () {
        (0, _marketing.synthesisDeleteApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this2.$message.success(message);
          _this2.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    }
  }
};