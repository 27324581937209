var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channelBox" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox", staticStyle: { top: "-52px" } },
          [
            !_vm.isClickCreate
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onAccount } },
                  [_vm._v("AI创建账号指导")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
                on: { click: _vm.onOpenDia },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c mb20" },
        [
          _c(
            "el-radio-group",
            {
              attrs: { type: "button" },
              on: { change: _vm.getList },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v("公域"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v("私域"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 200px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              attrs: { border: "", height: "100%", data: _vm.resultData },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan1",
                attrs: {
                  label: "账号名称",
                  "min-width": "100",
                  prop: "",
                  "search-key": "account_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticClass: "sortColOne",
                          on: {
                            change: function ($event) {
                              return _vm.mackTitChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.account_name,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "account_name", $$v)
                            },
                            expression: "scope.row.account_name",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan2",
                attrs: {
                  label: "渠道名称",
                  width: "150",
                  prop: "",
                  "search-key": "channels",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: _vm.channelType.channels,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.channelsText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.status == "1"
                ? _c("ytx-table-column", {
                    key: "chan3",
                    attrs: {
                      label: "账号定位",
                      width: "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "nowrap" }, [
                                _vm._v(_vm._s(scope.row.accountPositionText)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3301048487
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.status == "2"
                ? _c("ytx-table-column", {
                    key: "chan4",
                    attrs: {
                      label: "推送方式",
                      width: "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_c("div", { staticClass: "nowrap" })]
                          },
                        },
                      ],
                      null,
                      false,
                      751891053
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan5",
                attrs: {
                  label: "绑定内容",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.accountBindContentText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan6",
                attrs: {
                  label: "内容类型",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.accountBindContentTypeText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan7",
                attrs: {
                  label: "发布方式",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", { staticClass: "nowrap" })]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.status == "2"
                ? _c("ytx-table-column", {
                    key: "chan8",
                    attrs: {
                      label: "分组发布",
                      width: "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [_c("div", { staticClass: "nowrap" })]
                          },
                        },
                      ],
                      null,
                      false,
                      751891053
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan9",
                attrs: {
                  label: "发布频次",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", { staticClass: "nowrap" })]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.status == "2"
                ? _c("ytx-table-column", {
                    key: "chan10",
                    attrs: {
                      label: "资源数量",
                      "min-width": "150",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.extend_data &&
                              scope.row.extend_data.account_info
                                ? _c("el-button", { attrs: { type: "text" } }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          scope.row.extend_data.account_info
                                            .account_fans_number
                                        ) +
                                        _vm._s(
                                          scope.row.extend_data.account_info
                                            .account_fans_units
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2304280224
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "chan11",
                attrs: {
                  label: "",
                  width: "36",
                  align: "center",
                  fixed: "right",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onUpdHand(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDeleHand(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.channelForm.title,
            visible: _vm.channelVisible,
            "close-on-click-modal": false,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.channelVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号类型", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      model: {
                        value: _vm.channelForm.channels_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channels_type", $$v)
                        },
                        expression: "channelForm.channels_type",
                      },
                    },
                    _vm._l(_vm.channelType.channels_type, function (item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账号渠道", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      model: {
                        value: _vm.channelForm.channels,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channels", $$v)
                        },
                        expression: "channelForm.channels",
                      },
                    },
                    [
                      _vm.channelForm.channels_type == 1
                        ? _vm._l(_vm.channelType.channels_1, function (item) {
                            return _c("el-option", {
                              key: item.label,
                              attrs: { label: item.label, value: item.value },
                            })
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.channelForm.channels_type == 2
                        ? _vm._l(_vm.channelType.channels_2, function (item) {
                            return _c("el-option", {
                              key: item.label,
                              attrs: { label: item.label, value: item.value },
                            })
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账号名称", required: "" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入账号名称" },
                    model: {
                      value: _vm.channelForm.account_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.channelForm, "account_name", $$v)
                      },
                      expression: "channelForm.account_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.channelForm.channels_type == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "账号定位", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w300",
                          model: {
                            value: _vm.channelForm.account_position,
                            callback: function ($$v) {
                              _vm.$set(_vm.channelForm, "account_position", $$v)
                            },
                            expression: "channelForm.account_position",
                          },
                        },
                        _vm._l(
                          _vm.channelType.account_position,
                          function (item) {
                            return _c("el-option", {
                              key: item.label,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.channelForm.channels_type == "2"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "资源数量", required: "" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入资源数量" },
                          model: {
                            value: _vm.channelForm.account_fans_number,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.channelForm,
                                "account_fans_number",
                                $$v
                              )
                            },
                            expression: "channelForm.account_fans_number",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "120px" },
                                  model: {
                                    value: _vm.channelForm.account_fans_units,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.channelForm,
                                        "account_fans_units",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "channelForm.account_fans_units",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "个", value: "个" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "个群", value: "个群" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "绑定内容", required: "" } },
                _vm._l(
                  _vm.channelForm.account_bind_content,
                  function (ele, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "flex justify-b align-items-c mb20",
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            model: {
                              value: ele.make_type,
                              callback: function ($$v) {
                                _vm.$set(ele, "make_type", $$v)
                              },
                              expression: "ele.make_type",
                            },
                          },
                          _vm._l(_vm.channelType.make_type, function (item) {
                            return _c("el-option", {
                              key: item.label,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            model: {
                              value: ele.make_tag,
                              callback: function ($$v) {
                                _vm.$set(ele, "make_tag", $$v)
                              },
                              expression: "ele.make_tag",
                            },
                          },
                          _vm._l(_vm.channelType.make_tag, function (item) {
                            return _c("el-option", {
                              key: item.label,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        index == 0
                          ? _c("i", {
                              staticClass: "el-icon-circle-plus cur",
                              staticStyle: { "font-size": "24px" },
                              on: { click: _vm.onAdd },
                            })
                          : _c("div", { staticStyle: { width: "24px" } }),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "-20px" },
                  attrs: { label: "内容类型", required: "" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: { multiple: "", "collapse-tags": "" },
                      model: {
                        value: _vm.channelForm.account_bind_content_type,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.channelForm,
                            "account_bind_content_type",
                            $$v
                          )
                        },
                        expression: "channelForm.account_bind_content_type",
                      },
                    },
                    _vm._l(
                      _vm.channelType.account_bind_content_type,
                      function (item) {
                        return _c("el-option", {
                          key: item.label,
                          attrs: { label: item.label, value: item.value },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.channelVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "margin-left": "20px" } }, [
      _vm._v("内容示例 "),
      _c("i", { staticClass: "el-icon-question" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }