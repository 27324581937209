var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "群发推送" } }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "div",
            { staticClass: "top-view", staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: { "default-active": _vm.menuKey, mode: "horizontal" },
                  on: { select: _vm.changeTab },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "/merchant/marketing/sms_task/subscri" },
                    },
                    [_vm._v("订阅通知")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/marketing/sms_task/spread" } },
                    [_vm._v("公众号群发")]
                  ),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _vm._v("短信群发"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { index: "/merchant/marketing/sms_task/appMass" },
                    },
                    [_vm._v("APP群发")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key, ref: "userChild" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }