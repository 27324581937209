"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _system = require("@/api/system");
var _vector = require("zrender/lib/core/vector");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      showImage: false,
      imagesKey: "",
      ruleData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)("nft_config").then(function (res) {
      if (res.data) {
        res.data.buy_before_experience = Number(res.data.buy_before_experience);
        res.data.no_show_circulation = Number(res.data.no_show_circulation);
        _this.ruleData = res.data;
      } else {
        _this.ruleData = {
          popup_img: "",
          popup_retract_img: ""
        };
      }
    }).catch(function () {});
  },
  methods: {
    delImgAction: function delImgAction(key) {
      this.ruleData[key] = "";
    },
    showImageAction: function showImageAction(img) {
      this.imagesKey = img;
      this.showImage = true;
    },
    changeImg: function changeImg(key) {
      var _this2 = this;
      // if (this.status === 1) {
      //   this.$message.error('已发行不可修改图片');
      //   return false;
      // }
      this.$modalUpload(function (img) {
        _this2.ruleData[key] = img[0];
      }, "1", "1");
    },
    saveAction: function saveAction() {
      var _this3 = this;
      if (this.ruleData.nft_additional_mark && this.ruleData.nft_additional_mark.length > 3) {
        this.$message.error("增发标识最长三个字符");
        return false;
      }
      (0, _system.configClassKeysSave)("nft_config", this.ruleData).then(function (res) {
        _this3.$message.success("设置成功");
      }).catch(function () {});
    }
  }
};