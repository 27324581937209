var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
        display: "flex",
        justifyContent: _vm.facade.align,
      },
    },
    [
      _c(
        "section",
        {
          style: {
            backgroundColor: _vm.facade.pageBackground,
            padding:
              _vm.facade.pagePaddingTop +
              "px " +
              _vm.facade.pagePaddingRight +
              "px " +
              _vm.facade.pagePaddingBottom +
              "px " +
              _vm.facade.pagePaddingLeft +
              "px",
            borderWidth:
              _vm.facade.borderTop +
              "px " +
              _vm.facade.borderRight +
              "px " +
              _vm.facade.borderBottom +
              "px " +
              _vm.facade.borderLeft +
              "px",
            borderRadius:
              _vm.facade.pageRadiusTopLeft +
              "px " +
              _vm.facade.pageRadiusTopRight +
              "px " +
              _vm.facade.pageRadiusBottomRight +
              "px " +
              _vm.facade.pageRadiusBottomLeft +
              "px",
            borderStyle: _vm.facade.borderStyle,
            borderColor: _vm.facade.borderColor,
            color: _vm.facade.pageColor,
            fontSize: _vm.facade.fontSize + "px",
            fontWeight: "" + _vm.facade.fontWeight,
            flex: _vm.facade.widthType == 1 ? 1 : "unset",
            display: "flex",
            justifyContent: _vm.facade.align,
            lineHeight: !_vm.content.title && _vm.isP ? "0" : "auto",
          },
        },
        [_vm._v(_vm._s(_vm.content.title))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }