var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "聚焦画廊" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "item-align-center mt20" },
                  [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("数据选择")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        on: { change: _vm.editTypeAction },
                        model: {
                          value: _vm.content.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "type", $$v)
                          },
                          expression: "content.type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("拍品"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "2" } }, [
                          _vm._v("商品"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "3" } }, [
                          _vm._v("自定义"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.content.type == 3 &&
                _vm.content.data &&
                _vm.content.data.length > 0
                  ? _c(
                      "div",
                      { staticClass: "pt10" },
                      _vm._l(_vm.content.data, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "kuang1 item-align-center mt10",
                          },
                          [
                            index === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "go-down-img",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goDown(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            index > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "go-up-img",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goUp(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "del-img",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "upload-img-view new item-flex-center ml10",
                                on: {
                                  click: function ($event) {
                                    return _vm.uploadIconAction(index)
                                  },
                                },
                              },
                              [
                                item.image
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "del-upload-img",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delImg(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.image
                                  ? _c("img", {
                                      attrs: { src: item.image + "!120a" },
                                    })
                                  : _c("img", {
                                      staticClass: "upload-icon",
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                        alt: "",
                                      },
                                    }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-view new ml4" }, [
                              _c(
                                "div",
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "140px" },
                                    attrs: {
                                      type: "text",
                                      placeholder: "输入标题文本",
                                      maxlength: "10",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                      },
                                    },
                                    model: {
                                      value: item.name,
                                      callback: function ($$v) {
                                        _vm.$set(item, "name", $$v)
                                      },
                                      expression: "item.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "4px" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "140px" },
                                    attrs: {
                                      type: "text",
                                      placeholder: "输入副文本",
                                      maxlength: "10",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                      },
                                    },
                                    model: {
                                      value: item.price,
                                      callback: function ($$v) {
                                        _vm.$set(item, "price", $$v)
                                      },
                                      expression: "item.price",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            item.link.name1 || item.link.name
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-align-center flex-one justify-end ml10 cur-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCategory(index, 1)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "ft14" }, [
                                      _vm._v(
                                        _vm._s(
                                          item.link.name1 || item.link.name
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "arrow-img",
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCategory(index, 1)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "ft14" }, [
                                      _vm._v("设置链接"),
                                    ]),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "arrow-img",
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.content.data &&
                _vm.content.data.length > 0 &&
                _vm.content.type != 3
                  ? _c(
                      "div",
                      { staticClass: "pt10" },
                      [
                        _c(
                          "draggable",
                          _vm._b(
                            { attrs: { list: _vm.content.data } },
                            "draggable",
                            _vm.$attrs,
                            false
                          ),
                          _vm._l(_vm.content.data, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "kuang1 item-align-center mt10",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "item-align-center flex-one" },
                                  [
                                    _c("div", { staticClass: "ft14" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                index === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "go-down-img ml10",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goDown(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "go-up-img ml10",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goUp(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "del-img",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("img", {
                    staticStyle: {
                      width: "12px",
                      height: "12px",
                      "margin-right": "5px",
                      "margin-top": "2px",
                    },
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.content.type == 1
                    ? _c(
                        "a",
                        {
                          staticClass: "fontW500 ft16",
                          on: { click: _vm.handleAdditem },
                        },
                        [_vm._v("选择拍品")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.content.type == 2
                    ? _c(
                        "a",
                        {
                          staticClass: "fontW500 ft16",
                          on: { click: _vm.handleAdditem },
                        },
                        [_vm._v("选择商品")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.content.type == 3
                    ? _c(
                        "a",
                        {
                          staticClass: "fontW500 ft16",
                          on: { click: _vm.addItemAction },
                        },
                        [_vm._v("新增一条")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form-class",
              attrs: {
                model: _vm.facade,
                "label-width": "100px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.backgroundColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "backgroundColor", $$v)
                      },
                      expression: "facade.backgroundColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("背景图片"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("backgroundImg")
                      },
                    },
                  },
                  [
                    _vm.facade.backgroundImg
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delFacadeImg("backgroundImg")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.backgroundImg
                      ? _c("img", {
                          attrs: { src: _vm.facade.backgroundImg + "!300" },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "外边距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.marginBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginBottom", $$v)
                            },
                            expression: "facade.marginBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.marginLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginLeft", $$v)
                            },
                            expression: "facade.marginLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.marginRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginRight", $$v)
                            },
                            expression: "facade.marginRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.paddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "paddingTop", $$v)
                            },
                            expression: "facade.paddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.paddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "paddingBottom", $$v)
                            },
                            expression: "facade.paddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.paddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "paddingLeft", $$v)
                            },
                            expression: "facade.paddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.paddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "paddingRight", $$v)
                            },
                            expression: "facade.paddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.topLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "topLeftRadius", $$v)
                            },
                            expression: "facade.topLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.topRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "topRightRadius", $$v)
                            },
                            expression: "facade.topRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.bottomLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "bottomLeftRadius", $$v)
                            },
                            expression: "facade.bottomLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.bottomRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "bottomRightRadius", $$v)
                            },
                            expression: "facade.bottomRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("背景链接"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-flex-center flex-one" }, [
                  _vm.facade.link.name1 || _vm.facade.link.name
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "item-align-center flex-one justify-s cur-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.setCategory("link")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "ft14" }, [
                            _vm._v(
                              _vm._s(
                                _vm.facade.link.name1 || _vm.facade.link.name
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "arrow-img",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "item-align-center flex-one justify-s color-link cur-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.setCategory("link")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "ft14" }, [
                            _vm._v("设置链接"),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "arrow-img",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500 ft14 mt20" }, [
                _vm._v("内容区域"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容底色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.facade.itemBackgroundColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "itemBackgroundColor", $$v)
                      },
                      expression: "facade.itemBackgroundColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("内容背景图"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("itemBackgroundImg")
                      },
                    },
                  },
                  [
                    _vm.facade.itemBackgroundImg
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delFacadeImg("itemBackgroundImg")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.itemBackgroundImg
                      ? _c("img", {
                          attrs: { src: _vm.facade.itemBackgroundImg + "!300" },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.itemShadow,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "itemShadow", $$v)
                      },
                      expression: "facade.itemShadow",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影大小" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.itemShadowSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "itemShadowSize", $$v)
                      },
                      expression: "facade.itemShadowSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.itemMargin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "itemMargin", $$v)
                      },
                      expression: "facade.itemMargin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemPaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemPaddingTop", $$v)
                            },
                            expression: "facade.itemPaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemPaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemPaddingBottom", $$v)
                            },
                            expression: "facade.itemPaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemPaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemPaddingLeft", $$v)
                            },
                            expression: "facade.itemPaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemPaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemPaddingRight", $$v)
                            },
                            expression: "facade.itemPaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemTopLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemTopLeftRadius", $$v)
                            },
                            expression: "facade.itemTopLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemTopRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemTopRightRadius", $$v)
                            },
                            expression: "facade.itemTopRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemBottomLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemBottomLeftRadius", $$v)
                            },
                            expression: "facade.itemBottomLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemBottomRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemBottomRightRadius", $$v)
                            },
                            expression: "facade.itemBottomRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                  _vm._v("图片尺寸"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "80px" },
                          attrs: { type: "text", placeholder: "宽" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                            },
                          },
                          model: {
                            value: _vm.facade.imgWidth,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgWidth", $$v)
                            },
                            expression: "facade.imgWidth",
                          },
                        },
                        [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                        2
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticStyle: { margin: "0 6px" },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "80px" },
                          attrs: { type: "text", placeholder: "高" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                            },
                          },
                          model: {
                            value: _vm.facade.imgHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgHeight", $$v)
                            },
                            expression: "facade.imgHeight",
                          },
                        },
                        [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgTopLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgTopLeftRadius", $$v)
                            },
                            expression: "facade.imgTopLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgTopRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgTopRightRadius", $$v)
                            },
                            expression: "facade.imgTopRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgBottomLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgBottomLeftRadius", $$v)
                            },
                            expression: "facade.imgBottomLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgBottomRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgBottomRightRadius", $$v)
                            },
                            expression: "facade.imgBottomRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.textAlign,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "textAlign", $$v)
                        },
                        expression: "facade.textAlign",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("居左"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.titleColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "titleColor", $$v)
                      },
                      expression: "facade.titleColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.titleFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "titleFontSize", $$v)
                      },
                      expression: "facade.titleFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.titleFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "titleFontWeight", $$v)
                        },
                        expression: "facade.titleFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题距离" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.titlePaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titlePaddingTop", $$v)
                            },
                            expression: "facade.titlePaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.titlePaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titlePaddingBottom", $$v)
                            },
                            expression: "facade.titlePaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.titlePaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titlePaddingLeft", $$v)
                            },
                            expression: "facade.titlePaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.titlePaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titlePaddingRight", $$v)
                            },
                            expression: "facade.titlePaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "副文本颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.descColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "descColor", $$v)
                      },
                      expression: "facade.descColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "副文本字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.descFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "descFontSize", $$v)
                      },
                      expression: "facade.descFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "副文本样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.descFontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "descFontWeight", $$v)
                        },
                        expression: "facade.descFontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "副文本距离" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.descPaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "descPaddingTop", $$v)
                            },
                            expression: "facade.descPaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.descPaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "descPaddingBottom", $$v)
                            },
                            expression: "facade.descPaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.descPaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "descPaddingLeft", $$v)
                            },
                            expression: "facade.descPaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.descPaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "descPaddingRight", $$v)
                            },
                            expression: "facade.descPaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "current-data": _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("selectViewAuction", {
        ref: "selectViewAuction",
        attrs: { "current-data": _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectLinkMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }