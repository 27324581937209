"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _basic = require("@/api/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      showdetail: false,
      productdetail: {},
      timelog: [],
      catelog: {},
      drawtype: 'detail'
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    loadInit: function loadInit(id) {
      var _this = this;
      this.showdetail = true;
      (0, _basic.getgoodsDetailV2)(id).then(function (res) {
        var detail = res.data;
        // if (res.data.slider_image) {
        detail.imagearr = res.data.image ? res.data.image.split(',') : [];
        // } else {
        //   detail.imagearr = [];
        //   detail.imagearr.unshift(res.data.image);
        // }
        // console.log(detail);
        _this.productdetail = detail;
      });
      (0, _basic.getgoodsHandleLogV2)({
        erp_product_id: id
      }).then(function (res) {
        if (res.status == 200) {
          _this.timelog = res.data.list;
        }
      });
      (0, _basic.getgoodsCateHandleLogV2)({
        erp_product_id: id
      }).then(function (res) {
        if (res.status == 200) {
          _this.catelog = res.data;
        }
      });
    }
  }
};