var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "鉴定管理" } }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("图文")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("专家")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [
                _vm._v("数据报表"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [
                _vm._v("分类设置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [
                _vm._v("基础配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "6" } }, [_vm._v("投诉")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("div", { staticClass: "status item-align-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 0 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(0)
                      },
                    },
                  },
                  [_vm._v("\n          全部\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 8 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(8)
                      },
                    },
                  },
                  [_vm._v("\n          待分单\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 1 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(1)
                      },
                    },
                  },
                  [_vm._v("\n          待回答\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 6 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(6)
                      },
                    },
                  },
                  [_vm._v("\n          追问待回答\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 2 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(2)
                      },
                    },
                  },
                  [_vm._v("\n          已回答\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 3 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(3)
                      },
                    },
                  },
                  [_vm._v("\n          已失效\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 4 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(4)
                      },
                    },
                  },
                  [_vm._v("\n          已退款\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 5 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(5)
                      },
                    },
                  },
                  [_vm._v("\n          未支付\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "status-shu" }, [_vm._v("|")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "status-list",
                    class: { curr: _vm.queryParams.status == 7 },
                    on: {
                      click: function ($event) {
                        return _vm.changeStateEvent(7)
                      },
                    },
                  },
                  [_vm._v("\n          即将超时\n        ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "default-box mt10" }, [
                _c("div", { staticClass: "default-setting" }, [
                  _c(
                    "form",
                    { ref: "exportForm" },
                    [
                      _c("el-tooltip", {
                        attrs: {
                          content: "鉴定分类",
                          placement: "top",
                          disabled: "disabled",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "鉴定分类",
                            filterable: "filterable",
                            name: "category",
                          },
                          model: {
                            value: _vm.category,
                            callback: function ($$v) {
                              _vm.category = $$v
                            },
                            expression: "category",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "全部" },
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.categoryList, function (m) {
                            return _c("el-option", {
                              key: "album" + m.id,
                              attrs: { value: m.id, label: m.category_name },
                            })
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-tooltip", {
                        staticClass: "ml20",
                        attrs: {
                          content: "问题编号",
                          placement: "top",
                          disabled: "disabled",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200",
                          attrs: {
                            placeholder: "问题编号",
                            name: "question_id",
                          },
                          model: {
                            value: _vm.questionId,
                            callback: function ($$v) {
                              _vm.questionId = $$v
                            },
                            expression: "questionId",
                          },
                        },
                        [
                          _c("el-tooltip", {
                            staticClass: "ml20",
                            attrs: {
                              content: "提问人名称",
                              placement: "top",
                              disabled: "disabled",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: { placeholder: "提问人名称", name: "uname" },
                        model: {
                          value: _vm.uname,
                          callback: function ($$v) {
                            _vm.uname = $$v
                          },
                          expression: "uname",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-tooltip", {
                        staticClass: "ml20",
                        attrs: {
                          content: "提问人UID",
                          placement: "top",
                          disabled: "disabled",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "w200",
                          attrs: { placeholder: "提问人UID", name: "uid" },
                          model: {
                            value: _vm.uid,
                            callback: function ($$v) {
                              _vm.uid = $$v
                            },
                            expression: "uid",
                          },
                        },
                        [
                          _c("el-tooltip", {
                            staticClass: "ml20",
                            attrs: { content: "鉴定结果", placement: "top" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          attrs: {
                            placeholder: "选择鉴定结果",
                            name: "collection_status",
                          },
                          model: {
                            value: _vm.cursorResault,
                            callback: function ($$v) {
                              _vm.cursorResault = $$v
                            },
                            expression: "cursorResault",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: "", label: "全部" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "1", label: "真品" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "2", label: "赝品" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: "3", label: "存疑" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt20" }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "选择专家",
                            placement: "top",
                            disabled: "disabled",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w200",
                              attrs: {
                                clearable: "",
                                filterable: "filterable",
                                remote: "remote",
                                "remote-method": _vm.searchPost,
                                loading: _vm.searchLoading,
                                placeholder: "搜索专家",
                                name: "expert_uid",
                              },
                              model: {
                                value: _vm.exportUid,
                                callback: function ($$v) {
                                  _vm.exportUid = $$v
                                },
                                expression: "exportUid",
                              },
                            },
                            _vm._l(_vm.announcerList, function (item) {
                              return _c("el-option", {
                                key: item.uid,
                                attrs: {
                                  value: item.uid,
                                  label: item.nickname,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "value-format": "yyyy/MM/dd",
                          format: "yyyy/MM/dd",
                          size: "small",
                          type: "daterange",
                          placement: "bottom-end",
                          placeholder: "选择时间",
                        },
                        on: { change: _vm.onChangeDate },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-tooltip", {
                        staticClass: "ml20",
                        attrs: {
                          content: "上拍结果",
                          placement: "top",
                          disabled: "disabled",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt20" }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.searchAction($event)
                            },
                          },
                        },
                        [_vm._v("筛选")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.exportAction($event)
                            },
                          },
                        },
                        [_vm._v("导出")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: { click: _vm.getExportFileList },
                        },
                        [_vm._v("导出记录\n            ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticClass: "mt40 no-height",
                  attrs: {
                    "v-loading": _vm.loading,
                    border: "",
                    data: _vm.resultData,
                    size: "small",
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      width: "80",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.refund_status === 1
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#DB1B1B" } },
                                  [
                                    _vm._v(
                                      "\n              已退款\n            "
                                    ),
                                  ]
                                )
                              : row.is_pay === 0
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#0EA3F4" } },
                                  [
                                    _vm._v(
                                      "\n              未支付\n            "
                                    ),
                                  ]
                                )
                              : row.is_answered === 1
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#0AAC28" } },
                                  [
                                    _vm._v(
                                      "\n              已回答\n            "
                                    ),
                                  ]
                                )
                              : !row.designated_expert &&
                                row.is_overdue === 0 &&
                                row.is_answered === 0
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#FFD015" } },
                                  [
                                    _vm._v(
                                      "\n              待分单\n            "
                                    ),
                                  ]
                                )
                              : row.is_overdue === 0 && row.is_answered === 0
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#FFD015" } },
                                  [
                                    _vm._v(
                                      "\n              待回答\n            "
                                    ),
                                  ]
                                )
                              : row.is_overdue === 1
                              ? _c(
                                  "div",
                                  { staticStyle: { color: "#B5B5B5" } },
                                  [
                                    _vm._v(
                                      "\n              已过期\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "封面图",
                      "min-width": "100",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.img.length
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#0EA3F4",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "37px",
                                        height: "37px",
                                        "object-fit": "cover",
                                      },
                                      attrs: {
                                        src: row.img[0] + "!120a",
                                        alt: "",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.onBigImg(row)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                              cursor: "pointer",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(row.img.length + "图") +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "提问人",
                      "min-width": "200",
                      align: "",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                    "border-radius": "50%",
                                    "margin-right": "8px",
                                    "margin-bottom": "8px",
                                    "flex-shrink": "0",
                                  },
                                  attrs: {
                                    src: row.user.avatar + "!120a",
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", [
                                  _c("div", [
                                    _vm._v(_vm._s(row.user.nickname)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(row.user.uid))]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(row.user.phone))]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("提问：" + _vm._s(row.create_time)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "提问/追问",
                      "min-width": "180",
                      align: "",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              row.id
                                ? _c("div", [_vm._v("编号：" + _vm._s(row.id))])
                                : _vm._e(),
                              _vm._v(" "),
                              row.category && row.category.category_name
                                ? _c("div", [
                                    _vm._v(
                                      "\n                分类：" +
                                        _vm._s(row.category.category_name) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.title
                                ? _c("div", [
                                    _vm._v("描述：" + _vm._s(row.title)),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.question_asked_title
                                ? _c("div", [
                                    _vm._v(
                                      "\n                追问：" +
                                        _vm._s(row.question_asked_title) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "回答人/回答时间",
                      "min-width": "190",
                      align: "",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _c("div", [
                                _c("div", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        row.designated_expert ? "指定" : "回答"
                                      ) +
                                      "：" +
                                      _vm._s(row.expert_name) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                  鉴定结果：" +
                                      _vm._s(
                                        row.collection_status == 1
                                          ? "真品"
                                          : row.collection_status == 2
                                          ? "赝品"
                                          : row.collection_status == 3
                                          ? "存疑"
                                          : "无"
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              row.expert_answer_create_time
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cur",
                                        staticStyle: { "margin-top": "10px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onLookHuida(row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  查看回答内容 >>\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            "回答时间：" +
                                              row.expert_answer_create_time +
                                              " "
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.answer_asked_create_time
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cur",
                                        staticStyle: { "margin-top": "10px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onLookZhuida(row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  查看追答内容 >>\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "追答时间：" +
                                          _vm._s(row.answer_asked_create_time)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否上拍",
                      "min-width": "120",
                      align: "",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.user_is_auction
                              ? _c("div", [
                                  _c("div", [_vm._v("要求上拍")]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(row.user.phone))]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            row.expert_is_auction
                              ? _c("div", [
                                  _c("div", [_vm._v("推荐上拍")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "推荐类型：\n                " +
                                        _vm._s(
                                          row.expert_recomment_type == 1
                                            ? "限时拍"
                                            : row.expert_recomment_type == 2
                                            ? "直播拍"
                                            : row.expert_recomment_type == 3
                                            ? "社群拍"
                                            : ""
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "推荐等级：" +
                                        _vm._s(
                                          row.categoryLevel
                                            ? row.categoryLevel.level_name
                                            : ""
                                        )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "偷学",
                      width: "80",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.onLookTouxue(row)
                                  },
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(row.study_order_count + "人偷学")
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      row.study_order_pay_money / 100 + "元收益"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "支付信息",
                      "min-width": "130",
                      align: "",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _c("div", [_vm._v(_vm._s(row.pay_type))]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(row.order_id))]),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(row.pay_time))]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "100",
                      align: "center",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                row.designated_expert &&
                                row.is_pay !== 0 &&
                                row.is_overdue === 0 &&
                                row.is_answered === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onHuidawenti(row)
                                          },
                                        },
                                      },
                                      [_vm._v("回答问题")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !row.designated_expert &&
                                row.is_pay !== 0 &&
                                row.is_overdue === 0 &&
                                row.is_answered === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onFendan(row)
                                          },
                                        },
                                      },
                                      [_vm._v("分单")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-bottom": "5px" },
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onJiandingxiangqingi(row)
                                      },
                                    },
                                  },
                                  [_vm._v("鉴定详情")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-bottom": "5px" },
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onXiugaifenlei(row)
                                      },
                                    },
                                  },
                                  [_vm._v("修改分类")]
                                ),
                                _vm._v(" "),
                                _c("el-popconfirm", {
                                  attrs: { title: "确定删除吗?" },
                                  on: {
                                    onConfirm: function ($event) {
                                      return _vm.onDelete(row)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "reference",
                                        fn: function () {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "5px",
                                                },
                                                attrs: { size: "mini" },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                row.is_pay != 0 && row.refund_status == 0
                                  ? _c("el-popconfirm", {
                                      attrs: { title: "确定退款吗?" },
                                      on: {
                                        onConfirm: function ($event) {
                                          return _vm.onTuikuan(row)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "reference",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "5px",
                                                    },
                                                    attrs: { size: "mini" },
                                                  },
                                                  [_vm._v("退款")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                row.is_hide
                                  ? _c("el-popconfirm", {
                                      attrs: { title: "确定展示问题吗?" },
                                      on: {
                                        onConfirm: function ($event) {
                                          return _vm.onShowTiwen(row)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "reference",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "el-button",
                                                  { attrs: { size: "mini" } },
                                                  [_vm._v("展示提问")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _c("el-popconfirm", {
                                      attrs: { title: "确定隐藏问题吗?" },
                                      on: {
                                        onConfirm: function ($event) {
                                          return _vm.onHideTiwen(row)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "reference",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "el-button",
                                                  { attrs: { size: "mini" } },
                                                  [_vm._v("隐藏提问")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "paginaBox flex" },
                [
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      total: _vm.total,
                      background: "",
                      "page-size": _vm.queryParams.limit,
                      "show-sizer": "show-sizer",
                      "show-total": "show-total",
                      "show-elevator": "show-elevator",
                      "current-page": _vm.queryParams.page,
                    },
                    on: {
                      "size-change": _vm.changePageSize,
                      "current-change": _vm.changePage,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("file-list", { ref: "exportList" }),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showVideo,
                    title: "查看视频",
                    "footer-hide": "footer-hide",
                    width: "800",
                    align: "center",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showVideo = $event
                    },
                  },
                },
                [
                  _c("div", [
                    _vm.video
                      ? _c("video", {
                          staticStyle: { width: "750px", height: "421px" },
                          attrs: { src: _vm.video, controls: true },
                        })
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showImg,
                    title: "查看图片",
                    "footer-hide": "footer-hide",
                    width: "800",
                    align: "center",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showImg = $event
                    },
                  },
                },
                [
                  _vm.img && _vm.img.length > 0
                    ? _c(
                        "div",
                        { staticClass: "h600" },
                        _vm._l(_vm.img, function (m, i) {
                          return m
                            ? _c(
                                "a",
                                {
                                  key: "img" + i,
                                  attrs: { href: m, target: "_blank" },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "750px" },
                                    attrs: { src: m, controls: true },
                                  }),
                                ]
                              )
                            : _vm._e()
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showAnswer,
                    "footer-hide": "footer-hide",
                    title: "查看回答",
                    fullscreen: "fullscreen",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showAnswer = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "question" },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "no-height",
                          attrs: { data: _vm.answerList, size: "small" },
                        },
                        [
                          _c("template", { slot: "empty" }, [
                            _c("div", { staticClass: "tableEmpty" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "tableDiv" }, [
                                _vm._v("暂无数据"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "追问回答",
                              width: "100",
                              align: "center",
                              type: "expand",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-table",
                                      { attrs: { data: row.question_asked } },
                                      [
                                        _c("template", { slot: "empty" }, [
                                          _c(
                                            "div",
                                            { staticClass: "tableEmpty" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                                  alt: "",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "tableDiv" },
                                                [_vm._v("暂无数据")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "title",
                                            label: "文字",
                                            width: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "图片",
                                            "min-width": "140",
                                            align: "center",
                                            prop: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  return [
                                                    row.image_url &&
                                                    row.image_url.length > 0
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onLookImg(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                "icon-class":
                                                                  "commonlook",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "点击查看图片"
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "视频",
                                            "min-width": "140",
                                            align: "center",
                                            prop: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  return [
                                                    row.video_url
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.onLookvideo(
                                                                  row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                "icon-class":
                                                                  "commonlook",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "点击查看视频"
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "语音",
                                            width: "220",
                                            align: "center",
                                            prop: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var row = ref.row
                                                  return _vm._l(
                                                    row.radio_url,
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticClass: "m2",
                                                        },
                                                        [
                                                          _c("audio", {
                                                            staticClass: "w200",
                                                            attrs: {
                                                              src: item,
                                                              controls: "",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "create_time",
                                            label: "回答时间",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "回答人头像",
                              width: "70",
                              align: "center",
                              prop: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "54px",
                                          height: "54px",
                                          margin: "18px 0",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "54px",
                                            height: "54px",
                                            "border-radius": "50%",
                                          },
                                          attrs: {
                                            src: row.expert_data.expert_avatar,
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "回答人信息",
                              "min-width": "120",
                              align: "center",
                              prop: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _c("div", [
                                        _vm._v(
                                          "UID：" + _vm._s(row.expert_data.uid)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          "昵称：" +
                                            _vm._s(row.expert_data.nickname)
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "文字",
                              "min-width": "140",
                              align: "center",
                              prop: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.answer_text
                                      ? _c("div", [
                                          _vm._v(_vm._s(row.answer_text)),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "图片",
                              "min-width": "140",
                              align: "center",
                              prop: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.answer_img_list &&
                                    row.answer_img_list.length > 0
                                      ? _c(
                                          "el-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.onLookImg(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "commonlook",
                                              },
                                            }),
                                            _vm._v("点击查看图片"),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "视频",
                              "min-width": "140",
                              align: "center",
                              prop: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.answer_video
                                      ? _c(
                                          "el-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.onLookvideo(row)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "commonlook",
                                              },
                                            }),
                                            _vm._v("点击查看视频"),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "语音",
                              width: "220",
                              align: "center",
                              prop: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _vm._l(
                                    row.answer_radio_list,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "m2" },
                                        [
                                          _c("audio", {
                                            staticClass: "w200",
                                            attrs: { src: item, controls: "" },
                                          }),
                                        ]
                                      )
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "回答时间",
                              "min-width": "120",
                              align: "center",
                              prop: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(row.human_create_time)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showStudy,
                    "footer-hide": "footer-hide",
                    title: "查看偷学",
                    fullscreen: "fullscreen",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showStudy = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "question" },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "no-height",
                          attrs: { data: _vm.studyList, size: "small" },
                        },
                        [
                          _c("template", { slot: "empty" }, [
                            _c("div", { staticClass: "tableEmpty" }, [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "tableDiv" }, [
                                _vm._v("暂无数据"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "偷学用户",
                              prop: "",
                              align: "",
                              width: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [_vm._v(_vm._s(row.pay_uid))]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v(_vm._s(row.pay_uname))]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "偷学订单号",
                              prop: "out_trade_no",
                              align: "",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "支付时间",
                              prop: "human_pay_time",
                              align: "",
                              width: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "支付金额",
                              prop: "",
                              align: "",
                              width: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s("￥" + row.pay_money / 100) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "提问人信息",
                              prop: "",
                              align: "",
                              width: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(row.commission_user)),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(_vm._s(row.commission_user_name)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "提问人分得",
                              prop: "",
                              align: "",
                              width: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          "￥" + _vm.params.row.amount / 100
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt40" },
                    [
                      _c("el-pagination", {
                        staticStyle: { "text-align": "right" },
                        attrs: {
                          total: _vm.studyTotal,
                          "page-size": _vm.studyParams.limit,
                          "show-sizer": "show-sizer",
                          "show-total": "show-total",
                          "show-elevator": "show-elevator",
                          "current-page": _vm.studyParams.page,
                        },
                        on: { "current-change": _vm.changeStudyPage },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showDetail,
                    "footer-hide": "footer-hide",
                    title: "查看详情",
                    fullscreen: "fullscreen",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showDetail = $event
                    },
                  },
                },
                [
                  _c("div", [
                    _vm._v("问：" + _vm._s(_vm.questionDetail.title)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    _vm._l(
                      _vm.questionDetail.image_url_list,
                      function (item, index) {
                        return _c("img", {
                          key: index,
                          staticClass: "w100 ml20 mt20",
                          attrs: { src: item },
                        })
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _vm.questionDetail.video_url
                    ? _c("div", [
                        _c("video", {
                          staticClass: "mt20",
                          staticStyle: { width: "400px" },
                          attrs: {
                            controls: true,
                            src: _vm.questionDetail.video_url,
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt20" }, [
                    _vm._v("\n          鉴定结果"),
                    _vm.questionDetail.collection_status == 1
                      ? _c("span", [_vm._v("真品")])
                      : _vm._e(),
                    _vm.questionDetail.collection_status == 2
                      ? _c("span", [_vm._v("赝品")])
                      : _vm._e(),
                    _vm.questionDetail.collection_status == 3
                      ? _c("span", [_vm._v("存疑")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.questionDetail.answer_data, function (m, i) {
                    return _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c("div", [_vm._v("答： " + _vm._s(m.answer_text))]),
                        _vm._v(" "),
                        _vm._l(m.answer_radio_list, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "mt20" },
                            [
                              _c("audio", {
                                staticStyle: { width: "400px" },
                                attrs: { src: item, controls: "controls" },
                              }),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt20" },
                          _vm._l(m.answer_img_list, function (item, index) {
                            return _c("img", {
                              key: index,
                              staticClass: "w100 ml20 mt20",
                              attrs: { src: item },
                            })
                          }),
                          0
                        ),
                        _vm._v(" "),
                        m.answer_video
                          ? _c("div", { staticClass: "mt20" }, [
                              _c("video", {
                                staticStyle: { width: "400px" },
                                attrs: { controls: true, src: m.answer_video },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        m.supply_answer.length
                          ? _c(
                              "div",
                              [
                                _vm._v("\n            补充回答\n            "),
                                _vm._l(m.supply_answer, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: "supli_" + index },
                                    [
                                      item.answer_text
                                        ? _c("div", [
                                            _vm._v(
                                              "答： " + _vm._s(item.answer_text)
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(
                                        item.answer_radio_list,
                                        function (it, i) {
                                          return _c("div", { key: i }, [
                                            _c("audio", {
                                              staticStyle: { width: "400px" },
                                              attrs: {
                                                src: it,
                                                controls: "controls",
                                              },
                                            }),
                                          ])
                                        }
                                      ),
                                      _vm._v(" "),
                                      item.answer_img_list &&
                                      item.answer_img_list.length
                                        ? _c(
                                            "div",
                                            { staticClass: "mt20" },
                                            _vm._l(
                                              item.answer_img_list,
                                              function (bm, i) {
                                                return _c("img", {
                                                  key: i,
                                                  staticClass: "w100 ml20 mt20",
                                                  attrs: { src: bm },
                                                })
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.answer_video
                                        ? _c("div", { staticClass: "mt20" }, [
                                            _c("video", {
                                              staticStyle: { width: "400px" },
                                              attrs: {
                                                controls: true,
                                                src: item.answer_video,
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(m.question_asked, function (zq, zi) {
                          return m.question_asked && m.question_asked.length
                            ? _c("div", { key: m.id + "_" + "_" + zi }, [
                                _c("div", [
                                  _vm._v(
                                    "\n              追问：" +
                                      _vm._s(zq.title) +
                                      "\n              "
                                  ),
                                  _c(
                                    "div",
                                    _vm._l(
                                      zq.image_url,
                                      function (item, index) {
                                        return _c("img", {
                                          key: index,
                                          staticClass: "w100 ml20 mt20",
                                          attrs: { src: item },
                                        })
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  zq.video_url
                                    ? _c("div", [
                                        _c("video", {
                                          staticClass: "mt20",
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            src: zq.video_url,
                                            controls: true,
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mt20" }, [
                                    _vm._v("\n                鉴定结果"),
                                    zq.collection_status == 1
                                      ? _c("span", [_vm._v("真品")])
                                      : _vm._e(),
                                    zq.collection_status == 2
                                      ? _c("span", [_vm._v("赝品")])
                                      : _vm._e(),
                                    zq.collection_status == 3
                                      ? _c("span", [_vm._v("存疑")])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  zq.answer_info
                                    ? _c(
                                        "div",
                                        { staticClass: "mt20" },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "答： " +
                                                _vm._s(
                                                  zq.answer_info.answer_text
                                                )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(
                                            zq.answer_info.answer_radio_list,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: "__" + index,
                                                  staticClass: "mt20",
                                                },
                                                [
                                                  _c("audio", {
                                                    staticClass: "w200",
                                                    attrs: {
                                                      src: item,
                                                      controls: "controls",
                                                    },
                                                  }),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "mt20" },
                                            _vm._l(
                                              zq.answer_info.answer_img_list,
                                              function (item, index) {
                                                return _c("img", {
                                                  key: index,
                                                  staticClass: "w100 ml20 mt20",
                                                  attrs: { src: item },
                                                })
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          zq.answer_info.answer_video
                                            ? _c(
                                                "div",
                                                { staticClass: "mt20" },
                                                [
                                                  _c("video", {
                                                    staticStyle: {
                                                      width: "400px",
                                                    },
                                                    attrs: {
                                                      controls: true,
                                                      src: zq.answer_info
                                                        .answer_video,
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    visible: _vm.showCategory,
                    title: "修改问题分类",
                    width: "30%",
                    "class-name": "vertical-center-modal",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showCategory = $event
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        placeholder: "请选择分类",
                        filterable: "filterable",
                        name: "category",
                      },
                      model: {
                        value: _vm.showCategoryId,
                        callback: function ($$v) {
                          _vm.showCategoryId = $$v
                        },
                        expression: "showCategoryId",
                      },
                    },
                    _vm._l(_vm.categoryList, function (m) {
                      return _c("el-option", {
                        key: "cate" + m.id,
                        attrs: { value: m.id, label: m.category_name },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showCategory = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.changeCategory },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    visible: _vm.showSeparate,
                    title: "分单",
                    width: "30%",
                    "class-name": "vertical-center-modal",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showSeparate = $event
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择鉴定师", name: "nickname" },
                      model: {
                        value: _vm.separateUid,
                        callback: function ($$v) {
                          _vm.separateUid = $$v
                        },
                        expression: "separateUid",
                      },
                    },
                    _vm._l(_vm.expertList, function (m) {
                      return _c(
                        "el-option",
                        {
                          key: "cate" + m.id,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [_vm._v(_vm._s(m.nickname))]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showSeparate = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.separateAction },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showGoAnswer.show,
                    title: "快速回答",
                    closable: false,
                    "mask-closable": false,
                    width: "700",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.showGoAnswer, "show", $event)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c("span", { staticClass: "w100" }, [
                        _vm._v("选择分类："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "选择分类",
                            filterable: "filterable",
                            name: "category",
                          },
                          on: { change: _vm.changeCategoryAction },
                          model: {
                            value: _vm.showGoAnswer.categoryIndex,
                            callback: function ($$v) {
                              _vm.$set(_vm.showGoAnswer, "categoryIndex", $$v)
                            },
                            expression: "showGoAnswer.categoryIndex",
                          },
                        },
                        _vm._l(_vm.categoryList, function (m, i) {
                          return _c("el-option", {
                            key: "album" + m.id,
                            attrs: { value: i, label: m.category_name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item-align-center mt20" },
                    [
                      _c("span", { staticClass: "w100" }, [
                        _vm._v("回答内容："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "w500",
                        attrs: { type: "textarea", rows: 3 },
                        model: {
                          value: _vm.showGoAnswer.answer_text,
                          callback: function ($$v) {
                            _vm.$set(_vm.showGoAnswer, "answer_text", $$v)
                          },
                          expression: "showGoAnswer.answer_text",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item-align-center mt20" },
                    [
                      _c("span", { staticClass: "w100" }, [
                        _vm._v("藏品真假："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.showGoAnswer.collection_status,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.showGoAnswer,
                                "collection_status",
                                $$v
                              )
                            },
                            expression: "showGoAnswer.collection_status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("真"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("假"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("存疑"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showGoAnswer.user_is_auction
                    ? _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("推荐上拍："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.showGoAnswer.expertIsAuction,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.showGoAnswer,
                                    "expertIsAuction",
                                    $$v
                                  )
                                },
                                expression: "showGoAnswer.expertIsAuction",
                              },
                            },
                            [_vm._v("推荐")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showGoAnswer.expertIsAuction
                    ? _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c("span", { staticClass: "w100" }, [
                            _vm._v("推荐等级："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "选择推荐等级",
                                filterable: "filterable",
                                name: "category",
                              },
                              model: {
                                value: _vm.showGoAnswer.category_level_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.showGoAnswer,
                                    "category_level_id",
                                    $$v
                                  )
                                },
                                expression: "showGoAnswer.category_level_id",
                              },
                            },
                            _vm._l(
                              _vm.categoryList[_vm.showGoAnswer.categoryIndex]
                                .category_level,
                              function (m) {
                                return _c("el-option", {
                                  key: "album" + m.id,
                                  attrs: { value: m.id, label: m.level_name },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          on: {
                            click: function ($event) {
                              _vm.showGoAnswer.show = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveAnswerAction },
                        },
                        [_vm._v("立即回答")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }