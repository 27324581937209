var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warehouse" },
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 170px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.id
                },
                data: _vm.tableData.data,
                height: "100%",
                "expand-row-keys": _vm.rowkeys,
                size: "mini",
                border: "",
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "expand-change": _vm.exchangeExpand,
                "cell-click": _vm.cellClick,
                "header-dragend": _vm.changeColumnWidth,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  align: "center",
                  width: "36",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand", width: "36" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm.showsearch && props.$index == 0 ? void 0 : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "expand-table-box" },
                          [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  data: props.row.expandData,
                                  size: "small",
                                  "highlight-current-row": "",
                                },
                              },
                              [
                                _c("template", { slot: "empty" }, [
                                  props.row.loading
                                    ? _c("div", [_vm._v("加载中...")])
                                    : _c("div", { staticClass: "tableEmpty" }, [
                                        _vm.$store.getters.userPression
                                          .merchantErpV2WarehouseOrderProductList
                                          ? _c(
                                              "div",
                                              { staticClass: "tableEmpty" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024619/image/171879035028190618.png!120a",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "tableDiv" },
                                                  [_vm._v("暂无数据")]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "tableEmpty" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718954313071950674.png!120a",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "tableDiv" },
                                                  [_vm._v("无权限查看")]
                                                ),
                                              ]
                                            ),
                                      ]),
                                ]),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "图片",
                                    width: "50",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "pictrue" },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "25px",
                                                    height: "25px",
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    src: scope.row.erpProduct
                                                      .image,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "编号" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct
                                                    .product_no
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "货品名称" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct
                                                    .product_name
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "标签" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct.labelNames.join(
                                                    ","
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "货架" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct.shelf
                                                    ? scope.row.erpProduct.shelf
                                                        .shelf_no
                                                    : ""
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "操作",
                                    width: "110",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value:
                                                      "merchantErpV2WarehouseOrderProductUpdateShelf",
                                                    expression:
                                                      "'merchantErpV2WarehouseOrderProductUpdateShelf'",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onSdjus(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("调整货架")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm._l(_vm.pushheaderList, function (item, index) {
                return [
                  item.field == "outWarehouse.name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_id",
                          "search-type": "radio",
                          options: _vm.storeList,
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.outWarehouse
                                            ? scope.row.outWarehouse.name
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "outStatusText"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_status",
                          "search-type": "radio",
                          options: [
                            { label: "已作废", value: "-1" },
                            { label: "待出库", value: "0" },
                            { label: "已出库", value: "1" },
                          ],
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(_vm._s(scope.row[item.field])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "outAdmin.real_name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_id",
                          "search-type": "radio",
                          options: _vm.userList,
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.outAdmin
                                          ? scope.row.outAdmin.real_name
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "enterAdmin.real_name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "out_id",
                          "search-type": "radio",
                          options: _vm.userList,
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.enterAdmin
                                          ? scope.row.enterAdmin.real_name
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "createAdmin.real_name"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "create_admin_id",
                          "search-type": "radio",
                          options: _vm.userList,
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.createAdmin
                                            ? scope.row.createAdmin.real_name
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "enterStatusText"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "enter_status",
                          "search-type": "radio",
                          options: [
                            { label: "已作废", value: "-1" },
                            { label: "待入库", value: "0" },
                            { label: "已入库", value: "1" },
                          ],
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(_vm._s(scope.row[item.field])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "order_no"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          width: item.width,
                          prop: "",
                          "search-key": "order_no",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row[item.field]) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "create_time"
                    ? _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          label: item.name,
                          "min-width": "160",
                          prop: "",
                          "search-key": "order_no",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(scope.row[item.field]) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _c("ytx-table-column", {
                        key: "table" + index,
                        attrs: {
                          prop: "",
                          index: index,
                          label: item.name,
                          width: item.width,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  item.field == "enterWarehouse.name"
                                    ? _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.enterWarehouse.name
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(scope.row[item.field]) +
                                            "\n            "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                ]
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            scope.row.audit_status == 0 &&
                            _vm.merchant_admin_id == scope.row.audit_admin_id
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.revieworder(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 审批")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "merchantErpV2WarehouseOrderDetail",
                                    expression:
                                      "'merchantErpV2WarehouseOrderDetail'",
                                  },
                                ],
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getdetail(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "kufang-diaoboqingdan",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("调拨清单\n            "),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.out_status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.outhouse(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "kufang-chuku",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("出库"),
                                  ],
                                  1
                                )
                              : scope.row.enter_status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.pushhouse(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "class-name": "blue",
                                        "icon-class": "commonimport ",
                                      },
                                    }),
                                    _vm._v("入库"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.enter_status == 0 &&
                            scope.row.out_status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.losewarehouseorder(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "class-name": "blue",
                                        "icon-class": "hetongzuofei ",
                                      },
                                    }),
                                    _vm._v("作废"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.enter_status == 0 &&
                            scope.row.out_status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onUpd(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "class-name": "blue",
                                        "icon-class": "commonedit",
                                      },
                                    }),
                                    _vm._v("编辑"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            {
              staticClass: "flex-one flex",
              staticStyle: { "margin-left": "4px" },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantErpV2WarehouseOrderDetail",
                      expression: "'merchantErpV2WarehouseOrderDetail'",
                    },
                  ],
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-printer",
                  },
                  on: { click: _vm.getalldetail },
                },
                [_vm._v("打印调拨单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value:
                        "merchantSystemExcelExport:erpTransfersWarehouseOrder",
                      expression:
                        "'merchantSystemExcelExport:erpTransfersWarehouseOrder'",
                    },
                  ],
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出调拨单")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value:
                        "merchantSystemExcelExport:erpTransfersWarehouseOrder",
                      expression:
                        "'merchantSystemExcelExport:erpTransfersWarehouseOrder'",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-document",
                  },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.mask,
              callback: function ($$v) {
                _vm.mask = $$v
              },
              expression: "mask",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(-1)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(1)
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showdetail, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.showdetail = $event
            },
          },
        },
        [
          _c("transfersOrder", { attrs: { detailjson: _vm.detailjson } }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.bindPrintorder },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.mask,
              callback: function ($$v) {
                _vm.mask = $$v
              },
              expression: "mask",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showreview = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(-1)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(1)
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "调整货架",
            visible: _vm.sdjusVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sdjusVisible = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "sortCol",
              attrs: { placeholder: "请选择货架", filterable: "" },
              model: {
                value: _vm.sdjusForm.shelf_id,
                callback: function ($$v) {
                  _vm.$set(_vm.sdjusForm, "shelf_id", $$v)
                },
                expression: "sdjusForm.shelf_id",
              },
            },
            _vm._l(_vm.sdjusList, function (item) {
              return _c("el-option", {
                key: item.shelf_id,
                attrs: { label: item.shelf_no, value: item.shelf_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "出库", visible: _vm.showouthouse, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showouthouse = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "100px",
                model: _vm.house,
                rules: _vm.rules,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "凭证", prop: "image" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    attrs: { title: "750*750px" },
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("1")
                      },
                    },
                  },
                  [
                    _vm.house.image
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", { attrs: { src: _vm.house.image } }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.currentrow.out_mode == "express"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "物流公司" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择物流公司",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.house.delivery_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.house, "delivery_name", $$v)
                            },
                            expression: "house.delivery_name",
                          },
                        },
                        _vm._l(_vm.expressList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentrow.out_mode == "express"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "物流单号" } },
                    [
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: {
                          placeholder: "请输入物流单号",
                          size: "normal",
                          clearable: "",
                        },
                        model: {
                          value: _vm.house.delivery_no,
                          callback: function ($$v) {
                            _vm.$set(_vm.house, "delivery_no", $$v)
                          },
                          expression: "house.delivery_no",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticClass: "w200",
                    attrs: {
                      placeholder: "请输入备注",
                      size: "normal",
                      clearable: "",
                      type: "textarea",
                      rows: 3,
                    },
                    model: {
                      value: _vm.house.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.house, "remark", $$v)
                      },
                      expression: "house.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.cursorouthouse } }, [
                    _vm._v("确认出库"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "入库", visible: _vm.showhouse, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showhouse = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "100px",
                model: _vm.pusHouse,
                rules: _vm.rules,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "凭证", prop: "image" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox flex align-items-c flex-wrap",
                    attrs: { title: "750*750px" },
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("2")
                      },
                    },
                  },
                  [
                    _vm.pusHouse.image
                      ? [
                          _c("div", { staticClass: "pictrue mr10 mb10" }, [
                            _c("img", {
                              attrs: { src: _vm.pusHouse.image + "!120a" },
                            }),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "upLoad mb10" }, [
                      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                    ]),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.cursorpush } }, [
                    _vm._v("确认入库"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }