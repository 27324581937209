"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['currentData', 'content', 'facade'],
  data: function data() {
    return {
      list: [],
      lists: [{
        image: 'https://saas.cdn.yunzongbu.cn/image/20211013/5021d314dca0ef49768a69a251e8d84a.png',
        name: '作者名称',
        tags: ['试听', '鉴定'],
        price: '￥525.00',
        sign: '235人已听课'
      }, {
        image: 'https://saas.cdn.yunzongbu.cn/image/20210926/b9f1fc183a1bb8a1dbd4d1c1c067ba79.JPG!120a',
        name: '作者名称',
        tags: ['试听', '鉴定'],
        price: '￥525.00',
        sign: '235人已听课'
      }]
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    listTwoImgStyle: function listTwoImgStyle() {
      var marginWidth = this.facade.item_padding;
      var pageMargin = this.facade.page_padding * 2;
      if (this.content.img_style === 1) {
        console.log(750, marginWidth, pageMargin);
        return {
          width: (750 - marginWidth - pageMargin) / 4 + 'px',
          height: 'auto'
        };
      } else {
        return {
          width: (750 - marginWidth - pageMargin) / 4 + 'px',
          height: (750 - marginWidth - pageMargin) / 4 + 'px'
        };
      }
    }
  },
  watch: {
    currentData: function currentData(val) {
      this.changeData(val);
    }
  },
  created: function created() {
    this.changeData(this.currentData);
  },
  methods: {
    changeData: function changeData(val) {
      this.list = this.lists;
    },
    error: function error(event) {}
  }
};