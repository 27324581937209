var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                style: {
                  lineHeight: _vm.facade.meeting_lineHeight / 2 + "px",
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: "#ffffff",
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      fontSize: _vm.facade.meeting_fontSize / 2 + "px",
                      fontWeight: _vm.facade.meeting_fontWeight,
                      color: _vm.facade.meeting_color,
                    },
                  },
                  [_vm._v("第三届大众艺术品拍卖会")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                style: {
                  lineHeight: _vm.facade.lineHeight / 2 + "px",
                  padding: " 0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: "#ffffff",
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      fontWeight: _vm.facade.fontWeight,
                      color: _vm.facade.color,
                    },
                  },
                  [_vm._v("书画专场传承经典抽象画艺术大展")]
                ),
              ]
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "div",
              {
                staticClass: "flex align-items-c justify-b",
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: "#ffffff",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "time1 flex align-items-c",
                    style: {
                      color: _vm.facade.timeColor,
                      fontSize: _vm.facade.fontSize,
                    },
                  },
                  [
                    _c("auctionStatus", {
                      attrs: {
                        status: _vm.facade.status_type,
                        type: 0,
                        sty: "display: inline-block;position: relative; top: 0;margin-right: 5px;",
                      },
                    }),
                    _vm._v(" "),
                    _vm.facade.time_type == 1
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "bg",
                              style: {
                                background: _vm.facade.bgBtnColor,
                                color: _vm.facade.bgColor,
                                fontWeight: _vm.facade.fontWeight,
                              },
                            },
                            [_vm._v("5")]
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v("时")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bg",
                              style: {
                                background: _vm.facade.bgBtnColor,
                                color: _vm.facade.bgColor,
                                fontWeight: _vm.facade.fontWeight,
                              },
                            },
                            [_vm._v("23")]
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v("分")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bg",
                              style: {
                                background: _vm.facade.bgBtnColor,
                                color: _vm.facade.bgColor,
                                fontWeight: _vm.facade.fontWeight,
                              },
                            },
                            [_vm._v("20")]
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v("秒")]),
                        ]
                      : _vm.facade.time_type == 2
                      ? [
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.facade.activeColor,
                                  fontWeight: _vm.facade.fontWeight,
                                },
                              },
                              [_vm._v("5")]
                            ),
                            _vm._v("时"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.facade.activeColor,
                                  fontWeight: _vm.facade.fontWeight,
                                },
                              },
                              [_vm._v("23")]
                            ),
                            _vm._v("分"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item" }, [
                            _c(
                              "span",
                              {
                                style: {
                                  color: _vm.facade.activeColor,
                                  fontWeight: _vm.facade.fontWeight,
                                },
                              },
                              [_vm._v("20")]
                            ),
                            _vm._v("秒"),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        style:
                          "margin: 0 5px;font-weight: " + _vm.facade.fontWeight,
                      },
                      [_vm._v("结拍")]
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "r-btn flex align-items-c",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20220319/89e60d9d4628815443da91be244552b3.png",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("开拍提醒")]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "detail"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: "#ffffff",
                },
              },
              [
                _vm.pageInfo.show_header_view
                  ? _c("img", {
                      style: {
                        borderRadius: _vm.facade.img_radius / 2 + "px",
                        width: "100%",
                        display: "block",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "desc flex align-items-c" }, [
                  _c(
                    "div",
                    {
                      staticClass: "desc-item flex-one flex align-items-c",
                      style: {
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        color: _vm.facade.color,
                        height: _vm.facade.lineHeight / 2 + "px",
                      },
                    },
                    [
                      _c("div", { staticClass: "item" }, [
                        _vm._v("拍品"),
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.facade.activeColor,
                              fontWeight: _vm.facade.activeFontWeight,
                            },
                          },
                          [_vm._v("5件")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _vm._v("保证金"),
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.facade.activeColor,
                              fontWeight: _vm.facade.activeFontWeight,
                            },
                          },
                          [_vm._v("1525元")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _vm._v("拍品"),
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.facade.activeColor,
                              fontWeight: _vm.facade.activeFontWeight,
                            },
                          },
                          [_vm._v("10-15%")]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png",
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc flex align-items-c" }, [
                  _c(
                    "div",
                    {
                      staticClass: "desc-item flex-one flex align-items-c",
                      style: {
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        color: _vm.facade.color,
                        height: _vm.facade.lineHeight / 2 + "px",
                      },
                    },
                    [
                      _c("div", { staticClass: "item" }, [
                        _vm._v("围观"),
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.facade.activeColor,
                              fontWeight: _vm.facade.activeFontWeight,
                            },
                          },
                          [_vm._v("525次")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _vm._v("报名"),
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.facade.activeColor,
                              fontWeight: _vm.facade.activeFontWeight,
                            },
                          },
                          [_vm._v("155人")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _vm._v("出价"),
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.facade.activeColor,
                              fontWeight: _vm.facade.activeFontWeight,
                            },
                          },
                          [_vm._v("101次")]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png",
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc flex align-items-c" }, [
                  _c(
                    "div",
                    {
                      staticClass: "desc-item flex-one flex align-items-c",
                      style: {
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        color: _vm.facade.color,
                        height: _vm.facade.lineHeight / 2 + "px",
                      },
                    },
                    [
                      _c("div", { staticClass: "item" }, [
                        _c(
                          "span",
                          {
                            style: {
                              color: _vm.facade.activeColor,
                              margin: 0,
                              fontWeight: _vm.facade.activeFontWeight,
                            },
                          },
                          [_vm._v("参拍须知/物流运输/注意事项")]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png",
                      alt: "",
                    },
                  }),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 10px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: "#ffffff",
                },
              },
              [
                _c(
                  "div",
                  {
                    class: { line3: _vm.facade.fold },
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      lineHeight: _vm.facade.lineHeight / 2 + "px",
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        国内众多的博物馆（院）和实力雄厚的财团，通过拍卖公司从国内外市场上购回了许多以前通过各种形式、各种途径流失出去的古玩珍品，如北京故宫博物院以2200万元人民国内众多的博物馆（院）和实力雄厚的财团，通过拍卖公司从国内外市场上购回了许多以前通过各种形式、各种途径流失出去的古玩珍品，如北京故宫博物院以2200万元人民币结拍\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.facade.fold
                  ? _c(
                      "div",
                      { staticClass: "fold flex align-items-c justify-c" },
                      [
                        _c("div", [_vm._v("展开")]),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20220105/12cbbacfd35022e69421fe28bd244796.png",
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "div",
              {
                style: {
                  marginTop: _vm.facade.marginTop / 2 + "px",
                  padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                  background: "#ffffff",
                },
              },
              [_vm._m(0)]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "list" },
              [
                _c("auctionList", {
                  style: {
                    margin: _vm.getMargin(
                      _vm.facade.listShow,
                      _vm.facade.page_margin - _vm.facade.left_margin
                    ),
                  },
                  attrs: {
                    facade: _vm.facade,
                    content: { type: "list", style: _vm.facade.listShow },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab flex align-items-c" }, [
      _c("div", { staticClass: "flex-one flex align-items-c ft12" }, [
        _c("div", { staticClass: "item curr" }, [
          _vm._v("全部 "),
          _c("span", [_vm._v("231")]),
          _vm._v(" "),
          _c("div", { staticClass: "bg" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v("我的参拍 "),
          _c("span", [_vm._v("35")]),
          _vm._v(" "),
          _c("div", { staticClass: "bg" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v("即将结拍 "),
          _c("span", [_vm._v("231")]),
          _vm._v(" "),
          _c("div", { staticClass: "bg" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "icon flex align-items-c" }, [
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20220108/4c8519f925d607be54fd041cbb15d0de.png",
          },
        }),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/image/20220108/f82cd92e7d77f3b3beba77261f7f0ea1.png",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }