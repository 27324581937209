"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      },
      receiveData: {
        show: false,
        data: null,
        listLoading: true,
        list: [],
        total: 0,
        query: {
          page: 1,
          limit: 10
        }
      },
      changeType: '2'
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    changeTypeAction: function changeTypeAction(d) {
      var _this = this;
      if (Number(d) === 1) {
        this.$router.replace('/merchant/marketing/help/list');
        this.changeType = d;
        setTimeout(function () {
          _this.changeType = '2';
        });
      }
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.fissionList)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    createdAction: function createdAction() {
      this.$router.push('./detail');
    },
    editAction: function editAction(id) {
      this.$router.push('./detail?id=' + id);
    },
    editStatusAction: function editStatusAction(id, val) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4".concat(val === 0 ? '关闭' : '开启', "\u8BE5\u6D3B\u52A8?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.fissionSwitchStatusApi)(id, {
          status: val
        }).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList('');
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showReceiveDataAction: function showReceiveDataAction(m) {
      this.receiveData = {
        show: true,
        data: m,
        listLoading: true,
        list: [],
        total: 0,
        query: {
          page: 1,
          limit: 10,
          activity_id: m.fission_activity_id
        }
      };
      this.pageChangeNew(1);
    },
    pageChangeNew: function pageChangeNew(page) {
      this.receiveData.query.page = page;
      this.loadReceiveAction();
    },
    loadReceiveAction: function loadReceiveAction() {
      var _this4 = this;
      this.receiveData.listLoading = true;
      (0, _marketing.fissionTaskListApi)(this.receiveData.query).then(function (res) {
        _this4.receiveData.total = res.data.count;
        _this4.receiveData.list = res.data.list;
        _this4.receiveData.listLoading = false;
      }).catch(function () {});
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this5 = this;
      var data = (0, _objectSpread2.default)({
        export_type: 'activityFissionTask'
      }, this.receiveData.query);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('activityFissionTask');
    }
  }
};