var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称：", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: {
                              placeholder: "请输入名称",
                              disabled: _vm.status > 10,
                            },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "有效期：", prop: "times" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      disabled: _vm.status > 10,
                    },
                    model: {
                      value: _vm.ruleForm.times,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "times", $$v)
                      },
                      expression: "ruleForm.times",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "折扣：", prop: "discount" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c("el-input", {
                        staticClass: "w100 ml10",
                        attrs: {
                          placeholder: "折扣",
                          disabled: _vm.status > 10,
                        },
                        model: {
                          value: _vm.ruleForm.discount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "discount", $$v)
                          },
                          expression: "ruleForm.discount",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml10" }, [_vm._v("折")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml10 color-gray" }, [
                        _vm._v("（在售价基础上折扣）"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择商品：", required: "" } },
                _vm._l(_vm.use_range, function (m, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "tab" },
                    [
                      _c("div", { staticClass: "tr flex align-items-c" }, [
                        _c(
                          "div",
                          { staticClass: "td", staticStyle: { width: "80%" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.status > 10 },
                                on: {
                                  change: function (val) {
                                    return _vm.changeTypeAction(val, i)
                                  },
                                },
                                model: {
                                  value: m.type,
                                  callback: function ($$v) {
                                    _vm.$set(m, "type", $$v)
                                  },
                                  expression: "m.type",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("全部商品"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("指定商品"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            m.type == 1
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ml10",
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.status > 10,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeTypeAction(1, i)
                                        },
                                      },
                                    },
                                    [_vm._v("选择商品")]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: m.type == 1,
                              expression: "m.type == 1",
                            },
                          ],
                          attrs: { data: m.data },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "图片" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "demo-image__preview" },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              src: scope.row.image,
                                              "preview-src-list": [
                                                scope.row.image,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "名称", prop: "name" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "价格", prop: "price" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "状态", prop: "status" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.stock == 0
                                        ? _c("div", [_vm._v("已售罄")])
                                        : scope.row.is_show == 1
                                        ? _c("div", [_vm._v("销售中")])
                                        : _c("div", [_vm._v("下架")]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "库存", prop: "stock" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "default",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delgoods(scope.$index)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAction("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认创建")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.changeType == 2
        ? [
            _c("Product", {
              ref: "product",
              attrs: { "current-data": _vm.goods_list[0].data },
              on: { selectMessage: _vm.selectMessageNew },
            }),
          ]
        : [
            _c("Product", {
              ref: "product",
              attrs: { "current-data": _vm.use_range[0].data },
              on: { selectMessage: _vm.selectMessage },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }