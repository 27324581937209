"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
var _auction = _interopRequireDefault(require("@/components/customChildren/auction"));
var _nftblind = _interopRequireDefault(require("@/components/customChildren/nftblind.vue"));
var _blindBox = _interopRequireDefault(require("@/components/customChildren/blindBox.vue"));
var _tiket = _interopRequireDefault(require("@/components/customChildren/tiket"));
var _product = require("@/api/product");
var _popoverTable = _interopRequireDefault(require("./popoverTable"));
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CreatCoupon',
  components: {
    Product: _goods.default,
    Auction: _auction.default,
    Nft: _nft.default,
    Nftblind: _nftblind.default,
    BlindBox: _blindBox.default,
    PopoverTable: _popoverTable.default,
    Ticket: _tiket.default
  },
  data: function data() {
    var _this = this;
    var failureTypeValidator = function failureTypeValidator(rule, value, callback, txt) {
      if (_this.ruleForm.failure_type === 1 && (!_this.ruleForm.times[0] || !_this.ruleForm.times[1])) {
        callback('请选择有效期');
      } else if (_this.ruleForm.failure_type === 2 && (!_this.ruleForm.effective_day || isNaN(_this.ruleForm.effective_day))) {
        callback('请输入有效期天数');
      } else {
        callback();
      }
    };
    var typeRuleValidator = function typeRuleValidator(rule, value, callback, txt) {
      if (_this.ruleForm.type === 1 && (_this.ruleForm.full === '' || Number(_this.ruleForm.full) === 0 || isNaN(_this.ruleForm.full))) {
        callback('请正确输入需要满足的金额');
      } else if (_this.ruleForm.reduction === '' || Number(_this.ruleForm.reduction) === 0 || isNaN(_this.ruleForm.reduction)) {
        callback("\u8BF7\u6B63\u786E\u8F93\u5165".concat(_this.ruleForm.type === 3 ? '折扣百分比' : '优惠金额'));
      } else if (_this.ruleForm.type === 3 && (_this.ruleForm.reduction < 0 || _this.ruleForm.reduction > 100)) {
        callback("\u6298\u6263\u767E\u5206\u6BD4\u533A\u95F4\u4E3A0-100");
      } else if (_this.ruleForm.type === 1 && Number(_this.ruleForm.reduction) >= Number(_this.ruleForm.full)) {
        callback('优惠金额必须小于需要满足的条件');
      } else {
        callback();
      }
    };
    return {
      id: '',
      ruleForm: {
        name: '',
        total_num: '',
        type: 1,
        full: '',
        superposition: false,
        reduction: '',
        failure_type: 1,
        times: [],
        effective_day: '',
        get_limit: 0,
        mutex: [],
        crowd_limit: 1,
        remark: '',
        snapshot_id: [],
        auto_issue_user_type: 1,
        auto_issue_snapshot_id: [],
        auto_issue: 2
      },
      use_range: {
        // 0 商品  7 拍卖  8数字藏品  9 数字盲盒  10  实物盲盒   11 门票   20 鉴定
        '0': {
          type: 1,
          keyName: '商品',
          data: []
        },
        '7': {
          type: 1,
          keyName: '拍卖',
          data: []
        },
        '8': {
          type: 1,
          keyName: '藏品',
          data: []
        },
        '9': {
          type: 1,
          keyName: '数字盲盒',
          data: []
        },
        '10': {
          type: 1,
          keyName: '实物盲盒',
          data: []
        },
        '11': {
          type: 1,
          keyName: '门票',
          data: []
        },
        '20': {
          type: 1,
          keyName: '鉴定',
          data: []
        }
      },
      goods_list: {
        // 0 商品  7 拍卖  8数字藏品  9 数字盲盒  10  实物盲盒  11 门票
        '0': {
          type: 0,
          keyName: '商品',
          data: []
        },
        '7': {
          type: 0,
          keyName: '拍卖',
          data: []
        },
        '8': {
          type: 0,
          keyName: '藏品',
          data: []
        },
        '9': {
          type: 0,
          keyName: '数字盲盒',
          data: []
        },
        '10': {
          type: 0,
          keyName: '实物盲盒',
          data: []
        },
        '11': {
          type: 0,
          keyName: '门票',
          data: []
        }
      },
      rules: {
        name: {
          required: true,
          message: '请输入优惠券名称',
          trigger: 'blur'
        },
        total_num: [{
          required: true,
          message: '请输入发放数量',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '预留数量必须为数字',
          trigger: 'blur'
        }],
        type: {
          required: true,
          message: '请选择优惠券类型',
          trigger: 'change'
        },
        reduction: [{
          required: true,
          message: '请输入优惠方式',
          trigger: 'blur'
        }, {
          required: true,
          validator: typeRuleValidator,
          trigger: 'blur'
        }],
        failure_type: [{
          required: true,
          trigger: 'change'
        }, {
          validator: failureTypeValidator,
          trigger: 'change'
        }]
      },
      loading: false,
      changeIndex: 0,
      status: 0,
      merCateList: [],
      changeType: 1
    };
  },
  created: function created() {
    var _this2 = this;
    (0, _product.snapshotListApi)({
      page: 1,
      limit: 100,
      perform_status: 1
    }).then(function (res) {
      _this2.merCateList = res.data.list;
    });
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.load();
    }
  },
  methods: {
    load: function load() {
      var _this3 = this;
      (0, _marketing.couponDetailApi)({
        id: this.id
      }).then(function (res) {
        var data = res.data;
        _this3.status = data.status;
        _this3.ruleForm = {
          name: data.name,
          total_num: data.total_num,
          type: data.type,
          full: data.rule.full || '',
          reduction: data.rule.reduction,
          failure_type: data.failure_type,
          times: data.start_time && data.end_time ? [data.start_time, data.end_time] : [],
          effective_day: data.effective_day,
          get_limit: data.get_limit,
          mutex: data.mutex.split(',').map(function (m) {
            return Number(m);
          }),
          crowd_limit: Number(data.crowd_limit),
          remark: data.remark,
          snapshot_id: data.snapshot_id ? data.snapshot_id.split(',').map(function (m) {
            return Number(m);
          }) : [],
          auto_issue: data.auto_issue,
          auto_issue_user_type: data.auto_issue === 1 ? data.autoIssue.auto_issue_user_type : 1,
          auto_issue_snapshot_id: data.auto_issue === 1 && data.autoIssue.auto_issue_snapshot_id ? data.autoIssue.auto_issue_snapshot_id.split(',').map(function (m) {
            return Number(m);
          }) : []
        };
        _this3.ruleForm.superposition = false;
        if (data.type === 1) {
          if (Number(data.rule.type) === 2) {
            _this3.ruleForm.superposition = true;
          }
        }
        for (var i in _this3.use_range) {
          var m = _this3.use_range[i];
          if (data.use_range[i] && data.use_range[i][0] && data.use_range[i][0] !== 0) {
            if (Number(i) === 9 || Number(i) === 10) {
              data.use_range_name[i].forEach(function (item) {
                var id = item.box_id;
                var p_id = item.id;
                item.p_id = p_id;
                item.id = id;
              });
              console.log(data.use_range_name[i]);
            } else if (Number(i) === 11) {
              data.use_range_name[i].forEach(function (item) {
                var id = item.ticket_id;
                var p_id = item.id;
                item.p_id = p_id;
                item.id = id;
              });
            }
            m.data = data.use_range_name[i];
            m.type = 2;
          } else if (data.use_range[i] && data.use_range[i][0] === 0) {
            m.type = 1;
          } else {
            m.type = 0;
          }
        }
        if (data.auto_issue === 1) {
          for (var _i in _this3.goods_list) {
            var _m = _this3.goods_list[_i];
            if (data.autoIssue.goods_list[_i] && data.autoIssue.goods_list[_i][0] && data.autoIssue.goods_list[_i][0] !== 0) {
              if (Number(_i) === 9 || Number(_i) === 10) {
                data.autoIssue.goods_list_name[_i].forEach(function (item) {
                  var id = item.box_id;
                  var p_id = item.id;
                  item.p_id = p_id;
                  item.id = id;
                });
              } else if (Number(_i) === 11) {
                data.autoIssue.goods_list_name[_i].forEach(function (item) {
                  var id = item.ticket_id;
                  var p_id = item.id;
                  item.p_id = p_id;
                  item.id = id;
                });
              }
              _m.data = data.autoIssue.goods_list_name[_i];
              _m.type = 2;
            } else if (data.autoIssue.goods_list[_i] && data.autoIssue.goods_list[_i][0] === 0) {
              _m.type = 1;
            } else {
              _m.type = 0;
            }
          }
        }
      });
    },
    changeTypeAction: function changeTypeAction(val, i) {
      var _this4 = this;
      if (val === 2) {
        this.changeType = 1;
        this.$nextTick(function () {
          _this4.showChangeAction(i);
        });
      }
    },
    changeSendTypeAction: function changeSendTypeAction(val, i) {
      var _this5 = this;
      if (val === 2) {
        this.changeType = 2;
        this.$nextTick(function () {
          _this5.showChangeAction(i);
        });
      }
    },
    showChangeAction: function showChangeAction(i) {
      this.changeIndex = i;
      var view = '';
      switch (Number(i)) {
        case 0:
          view = 'product';
          break;
        case 7:
          view = 'auction';
          break;
        case 8:
          view = 'product_nft';
          break;
        case 9:
          view = 'nft_blind';
          break;
        case 10:
          view = 'product_blind';
          break;
        case 11:
          view = 'ticket';
          break;
      }
      this.$refs[view].changeshow();
    },
    selectMessage: function selectMessage(data) {
      if (this.use_range[this.changeIndex]) {
        this.use_range[this.changeIndex].data = data.data;
      }
    },
    selectMessageNew: function selectMessageNew(data) {
      if (this.goods_list[this.changeIndex]) {
        this.goods_list[this.changeIndex].data = data.data;
      }
    },
    editAction: function editAction(key) {
      this.$refs.ruleForm.validateField([key], function (Error) {
        if (!Error) {
          // 验证通过
          console.log('通过了');
        } else {
          console.log('验证不通过');
        }
      });
    },
    saveAction: function saveAction(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this6.ruleForm));
          if (!data.get_limit) {
            data.get_limit = 0;
          }
          if (_this6.ruleForm.failure_type === 1) {
            data.start_time = data.times[0];
            data.end_time = data.times[1];
          }
          data.rule = {
            full: _this6.ruleForm.full,
            reduction: _this6.ruleForm.reduction
          };
          if (data.type === 1 && data.superposition) {
            data.rule.type = 2;
          }
          data.mutex = _this6.ruleForm.mutex.join(',');
          if (Number(data.crowd_limit) === 3) {
            if (data.snapshot_id.length <= 0) {
              _this6.$message.error('请选择可领取快照名单');
              return false;
            }
            data.snapshot_id = data.snapshot_id.join(',');
          }
          var useR = {};
          for (var key in _this6.use_range) {
            var item = _this6.use_range[key];
            if (item.type === 1) {
              // 选择全部
              useR[key] = [0];
            } else if (item.type === 0) {
              useR[key] = [];
            } else if (item.data && item.data.length > 0) {
              if (Number(key) === 9 || Number(key) === 10 || Number(key) === 11) {
                useR[key] = item.data.map(function (m) {
                  return m.p_id;
                });
              } else {
                useR[key] = item.data.map(function (m) {
                  return m.id;
                });
              }
            }
          }
          data.use_range = useR;
          if (data.auto_issue === 1) {
            if (Number(data.auto_issue_user_type) === 2) {
              if (data.auto_issue_snapshot_id.length <= 0) {
                _this6.$message.error('请选择自动发放快照名单');
                return false;
              }
              data.auto_issue_snapshot_id = data.auto_issue_snapshot_id.join(',');
            }
            var goodsL = {};
            for (var _key in _this6.goods_list) {
              var _item = _this6.goods_list[_key];
              if (_item.type === 1) {
                // 选择全部
                goodsL[_key] = [0];
              } else if (_item.type === 0) {
                goodsL[_key] = [];
              } else if (_item.data && _item.data.length > 0) {
                if (Number(_key) === 9 || Number(_key) === 10 || Number(_key) === 11) {
                  goodsL[_key] = _item.data.map(function (m) {
                    return m.p_id;
                  });
                } else {
                  goodsL[_key] = _item.data.map(function (m) {
                    return m.id;
                  });
                }
              }
            }
            data.goods_list = goodsL;
          }
          var loading = _this6.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _marketing.couponCreatedApi;
          if (_this6.id) {
            data.id = _this6.id;
            action = _marketing.couponUpdateApi;
          }
          action(data).then(function () {
            loading.close();
            _this6.$message.success('操作成功');
            _this6.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          _this6.$message.error('请填写完整信息');
          return false;
        }
      });
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};