var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    {
      attrs: {
        title: "联系方式",
        "has-template": _vm.$route.query.type == "manage" ? "1" : "",
      },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("工作时间"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c("el-input", {
                      attrs: { type: "text", placeholder: "设置工作时间" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return (function () {})($event)
                        },
                      },
                      model: {
                        value: _vm.content.time,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "time", $$v)
                        },
                        expression: "content.time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("总部别称"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c("el-input", {
                      attrs: { type: "text", placeholder: "设置文字" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return (function () {})($event)
                        },
                      },
                      model: {
                        value: _vm.content.headOfficeTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "headOfficeTitle", $$v)
                        },
                        expression: "content.headOfficeTitle",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c(
                  "div",
                  { staticClass: "flex-one fontW500 ft14 color-black" },
                  [_vm._v("设置总部联系方式")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "cur ft14",
                    staticStyle: { color: "#1772f6" },
                    on: {
                      click: function ($event) {
                        return _vm.addAction("headOffice")
                      },
                    },
                  },
                  [_vm._v("新增")]
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.content.headOffice, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "headOffice" + i,
                    staticClass: "item-align-center mt20",
                    staticStyle: {
                      "border-bottom": "1px solid #f5f5f5",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _c("div", { staticClass: "flex-one" }, [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "设置名称" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.name,
                              callback: function ($$v) {
                                _vm.$set(m, "name", $$v)
                              },
                              expression: "m.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt10" },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "设置地址" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.address,
                              callback: function ($$v) {
                                _vm.$set(m, "address", $$v)
                              },
                              expression: "m.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt10" },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "设置联系方式",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.other,
                              callback: function ($$v) {
                                _vm.$set(m, "other", $$v)
                              },
                              expression: "m.other",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "del-img",
                        on: {
                          click: function ($event) {
                            return _vm.delAction("headOffice", i)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c(
                  "div",
                  { staticClass: "flex-one fontW500 ft14 color-black" },
                  [_vm._v("设置分公司联系方式")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "cur ft14",
                    staticStyle: { color: "#1772f6" },
                    on: {
                      click: function ($event) {
                        return _vm.addAction("company")
                      },
                    },
                  },
                  [_vm._v("新增")]
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.content.company, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "company" + i,
                    staticClass: "item-align-center mt20",
                    staticStyle: {
                      "border-bottom": "1px solid #f5f5f5",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _c("div", { staticClass: "flex-one" }, [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "设置名称" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.name,
                              callback: function ($$v) {
                                _vm.$set(m, "name", $$v)
                              },
                              expression: "m.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt10" },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "设置地址" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.address,
                              callback: function ($$v) {
                                _vm.$set(m, "address", $$v)
                              },
                              expression: "m.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt10" },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "设置联系方式",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.other,
                              callback: function ($$v) {
                                _vm.$set(m, "other", $$v)
                              },
                              expression: "m.other",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "del-img",
                        on: {
                          click: function ($event) {
                            return _vm.delAction("company", i)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c(
                  "div",
                  { staticClass: "flex-one fontW500 ft14 color-black" },
                  [_vm._v("设置办事处联系方式")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "cur ft14",
                    staticStyle: { color: "#1772f6" },
                    on: {
                      click: function ($event) {
                        return _vm.addAction("office")
                      },
                    },
                  },
                  [_vm._v("新增")]
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.content.office, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "office" + i,
                    staticClass: "item-align-center mt20",
                    staticStyle: {
                      "border-bottom": "1px solid #f5f5f5",
                      "padding-bottom": "20px",
                    },
                  },
                  [
                    _c("div", { staticClass: "flex-one" }, [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "设置名称" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.name,
                              callback: function ($$v) {
                                _vm.$set(m, "name", $$v)
                              },
                              expression: "m.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt10" },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "设置地址" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.address,
                              callback: function ($$v) {
                                _vm.$set(m, "address", $$v)
                              },
                              expression: "m.address",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt10" },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "设置联系方式",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: m.other,
                              callback: function ($$v) {
                                _vm.$set(m, "other", $$v)
                              },
                              expression: "m.other",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "del-img",
                        on: {
                          click: function ($event) {
                            return _vm.delAction("office", i)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("风格参数设置"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域外间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginTop", $$v)
                            },
                            expression: "facade.pageMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginBottom", $$v)
                            },
                            expression: "facade.pageMarginBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginLeft", $$v)
                            },
                            expression: "facade.pageMarginLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginRight", $$v)
                            },
                            expression: "facade.pageMarginRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域内间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingTop", $$v)
                            },
                            expression: "facade.pagePaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingBottom", $$v)
                            },
                            expression: "facade.pagePaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingLeft", $$v)
                            },
                            expression: "facade.pagePaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingRight", $$v)
                            },
                            expression: "facade.pagePaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusTopLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusTopLeft", $$v)
                            },
                            expression: "facade.pageRadiusTopLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusTopRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusTopRight", $$v)
                            },
                            expression: "facade.pageRadiusTopRight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusBottomLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusBottomLeft", $$v)
                            },
                            expression: "facade.pageRadiusBottomLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusBottomRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusBottomRight", $$v)
                            },
                            expression: "facade.pageRadiusBottomRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "最大高度" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 2000 },
                    model: {
                      value: _vm.facade.maxHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "maxHeight", $$v)
                      },
                      expression: "facade.maxHeight",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.pageBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageBackground", $$v)
                      },
                      expression: "facade.pageBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "行高" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 200 },
                    model: {
                      value: _vm.facade.lineHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "lineHeight", $$v)
                      },
                      expression: "facade.lineHeight",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemPaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemPaddingBottom", $$v)
                            },
                            expression: "facade.itemPaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemPaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemPaddingLeft", $$v)
                            },
                            expression: "facade.itemPaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemPaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemPaddingRight", $$v)
                            },
                            expression: "facade.itemPaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "分割线高度" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.lineSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "lineSize", $$v)
                      },
                      expression: "facade.lineSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "分割线颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.lineBackgound,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "lineBackgound", $$v)
                      },
                      expression: "facade.lineBackgound",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#333333" },
                    model: {
                      value: _vm.facade.titleColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "titleColor", $$v)
                      },
                      expression: "facade.titleColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题字号" } },
                [
                  _c("slider", {
                    attrs: { min: 12, max: 50 },
                    model: {
                      value: _vm.facade.titleFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "titleFontSize", $$v)
                      },
                      expression: "facade.titleFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.pageColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageColor", $$v)
                      },
                      expression: "facade.pageColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "名称字号" } },
                [
                  _c("slider", {
                    attrs: { min: 12, max: 50 },
                    model: {
                      value: _vm.facade.nameFontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "nameFontSize", $$v)
                      },
                      expression: "facade.nameFontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 12, max: 50 },
                    model: {
                      value: _vm.facade.fontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "fontSize", $$v)
                      },
                      expression: "facade.fontSize",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "temp" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "模版字段" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.facade.temp,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "temp", $$v)
                        },
                        expression: "facade.temp",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "contact_pageBackground" } },
                            [_vm._v("背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "contact_lineBackgound" } },
                            [_vm._v("分割线颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "contact_titleColor" } },
                            [_vm._v("标题颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "contact_pageColor" } },
                            [_vm._v("文字颜色")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }