"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.sort");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.split");
var _titleActive = _interopRequireDefault(require("./titleActive.vue"));
var _setworks = _interopRequireDefault(require("./setworks.vue"));
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _utils = require("@/utils");
var _guidance = require("@/api/guidance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    titleActive: _titleActive.default,
    setworks: _setworks.default,
    draggable: _vuedraggable.default
  },
  props: ["newopen3d", "tourIntroduction", 'guided_digital_man_config'],
  data: function data() {
    return {
      loading: false,
      space_id: this.$route.query.id,
      listTitleActive: 0,
      selectWorkActive: 0,
      isSelectWork: false,
      // 显示选中作品和素材
      isSelectNumMerber: false,
      // 显示选择数字人声音
      isSetDesc: false,
      // 显示讲解内容区域
      merberAiList: [],
      // 数字人列表
      merberToneList: [],
      // 音色列表
      curMerberInfo: "",
      // 当前空间数字人id
      tourIntroductionInfo: null,
      // 导览介绍
      loadEnd: false,
      materialList: [],
      guidanceList: [],
      cloneGuidanceList: [],
      // 导览列表
      query: {
        id: "",
        sort: null,
        explain_content_url: "" /* 讲解内容语言地址*/,
        explain_content_text: "",
        explain_duration: 0 /* 语音时长(单位秒)*/,
        type: "",
        link_id: "" /*关联id*/,
        space_id: "" /*空间id*/
      },
      curWorkInfo: "",
      // 添加展品路径的当前展品
      merberData: {
        space_id: "",
        digital_man_id: "",
        digital_man_voice_id: ""
      },
      playData: {
        text: "",
        timbre: ""
      },
      curPlayInfo: {
        explain_content_url: "",
        id: ""
      },
      exhibitionType: null,
      changeSort: "",
      sortListQuery: []
    };
  },
  computed: {
    descLength: function descLength() {
      return this.query.explain_content_text && this.query.explain_content_text.length ? this.query.explain_content_text.length : 0;
    },
    descTime: function descTime() {
      if (!this.query.explain_content_text) return 0;
      return (this.query.explain_content_text.length / 5).toFixed(0) || 1;
    },
    totalTime: function totalTime() {
      var time = this.cloneGuidanceList.reduce(function (cur, itm) {
        if (!itm.link_id) return cur;else return cur + (itm.explain_duration || 0);
      }, 0);
      if (!time) return "0秒";
      var str;
      if (time <= 60) str = time + "秒";else {
        var arr = ((time / 60).toFixed(2) + "").split(".");
        var last = time % 60;
        str = arr[0] + "分" + last + "秒";
      }
      return str;
    },
    curMemberAIPicture: function curMemberAIPicture() {
      if (this.curMerberInfo && this.curMerberInfo.digital_man_id) {
        var ids = this.merberAiList.map(function (m) {
          return m.id;
        });
        var index = ids.indexOf(this.curMerberInfo.digital_man_id);
        if (index === -1) {
          return "";
        } else {
          return this.merberAiList[index].image;
        }
      } else {
        return "";
      }
    },
    curTone: function curTone() {
      var _this = this;
      var obj = this.merberToneList.find(function (item) {
        return item.digital_man_voice_id == _this.merberData.digital_man_voice_id;
      });
      return obj ? obj.name : "请选择";
    }
  },
  watch: {
    guided_digital_man_config: function guided_digital_man_config(val) {
      console.log(val, 1111);
      this.curMerberInfo = JSON.parse(JSON.stringify(val));
      this.merberData = JSON.parse(JSON.stringify(val));
    },
    tourIntroduction: function tourIntroduction(nval) {
      if (nval) {
        this.tourIntroductionInfo = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, nval), {}, {
          isPlay: false,
          timeLength: this.getAudioTime(nval)
        });
      }
    },
    listTitleActive: function listTitleActive(nval) {
      this.isSetDesc && this.resetEditing();
      this.reset();
    },
    selectWorkActive: function selectWorkActive(nval) {
      if (nval === 1) {
        this.exhibitionType = "material";
      } else if (nval === 0) {
        this.exhibitionType = "exhibition";
      }
    }
  },
  created: function created() {
    if (this.tourIntroduction) {
      this.tourIntroductionInfo = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.tourIntroduction), {}, {
        isPlay: false,
        timeLength: this.getAudioTime(this.tourIntroduction)
      });
    }
    if (this.newopen3d) {
      this.getAllState();
    }
  },
  methods: {
    initQuery: function initQuery() {
      // 初始化添加参数
      return {
        id: "",
        sort: null,
        explain_content_url: "" /* 讲解内容*/,
        explain_content_text: "",
        explain_duration: 0 /* 语音时长(单位秒)*/,
        type: "" /* 关联类型 1素材 2展品*/,
        link_id: "" /*关联id*/,
        space_id: "" /*空间id*/
      };
    },
    getAddWorkInfo: function getAddWorkInfo(info) {
      this.curWorkInfo = JSON.parse(JSON.stringify(info));
    },
    addWorkInfo: function addWorkInfo() {
      var _this2 = this;
      if (!this.curWorkInfo) return this.$message.warning("请选择素材或展品");
      if (this.cloneGuidanceList.find(function (item) {
        return item.link_id == _this2.curWorkInfo.id;
      })) return this.$message.warning("不可以重复添加");
      var timeLength;
      if (!this.descTime) timeLength = "";else if (this.descTime <= 60) timeLength = this.descTime;else {
        var arr = ((time / 60).toFixed(2) + "").split(".");
        timeLength = (0, _utils.formatDateNumber)(arr[0]) + ":" + (0, _utils.formatDateNumber)(arr[1]);
      }
      this.curWorkInfo = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.curWorkInfo), {}, {
        productImage: this.curWorkInfo.coverImage,
        productName: this.curWorkInfo.name,
        timeLength: timeLength
      });
      this.onSave(0, this.changeSort + 1);
      this.isSelectWork = false;
      // resetEdit();
    },
    cancelAddWork: function cancelAddWork() {
      this.isSelectWork = false;
      this.curWorkInfo = "";
      this.resetEdit();
    },
    textToSpeechDefault: function textToSpeechDefault() {
      var _this3 = this;
      var defaultData = {};
      this.textToSpeech('第一件。。。', function (res) {
        defaultData.firstAudio = res.data.audioAddress;
        defaultData.firstAudioTime = _this3.timeUtils(res.data.sentences);
        _this3.textToSpeech('上一件。。。', function (res) {
          defaultData.upAudio = res.data.audioAddress;
          defaultData.upAudioTime = _this3.timeUtils(res.data.sentences);
          _this3.textToSpeech('下一件。。。', function (res) {
            defaultData.secondAudio = res.data.audioAddress;
            defaultData.secondAudioTime = _this3.timeUtils(res.data.sentences);
            _this3.$emit("editDetaultAudioInfo", defaultData);
            _this3.isSetDesc = false;
            _this3.loading = false;
            _this3.query.type = "";
          });
        });
      });
    },
    textToSpeech: function textToSpeech(content, cb) {
      var _this4 = this;
      // 展品讲解的保存和取消按钮
      this.playData.timbre = this.curMerberInfo && this.curMerberInfo.digital_man_voice ? this.curMerberInfo.digital_man_voice : '';
      this.playData.text = content;
      this.loading = true;
      (0, _guidance._textToSpeech)(this.playData).then(function (res) {
        if (cb) {
          cb(res);
          return false;
        }
        if (_this4.query.type == "tourIntroductionInfo") {
          var _time = _this4.timeUtils(res.data.sentences);
          _this4.$emit("editTourIntroductionInfo", {
            tourIntroduction: content,
            tourAudioFrequency: res.data.audioAddress,
            speechDuration: _time,
            explain_duration: _time
          });
          _this4.textToSpeechDefault();
          return false;
        }
        _this4.query.explain_content_url = res.data.audioAddress;
        _this4.query.explain_content_text = content;
        _this4.query.explain_duration = _this4.timeUtils(res.data.sentences);
        if (!_this4.query.id) {
          _this4.curWorkInfo.explain_duration = _this4.timeUtils(res.data.sentences);
        }
        _this4.isSetDesc = false;
        _this4.loading = false;
        _this4.onSave(0);
      }).catch(function (err) {
        console.log(err);
        _this4.loading = false;
      });
    },
    onAddDesc: function onAddDesc() {
      if (!this.query.explain_content_text) return this.$message.warning(this.query.type == "tourIntroductionInfo" ? "请输入导览介绍" : "请输入讲解内容");
      if (!this.curMerberInfo || !this.curMerberInfo.digital_man_voice_id) return this.$message.warning("请先设置导览数字人");
      this.textToSpeech(this.query.explain_content_text);
    },
    onCancelDesc: function onCancelDesc() {
      this.resetEditing();
      this.isSetDesc = false;
      this.loading = false;
      this.query = this.initQuery();
    },
    editDesc: function editDesc(item) {
      if (this.loading) return false;
      // 点击编辑按钮 编辑讲解内容
      if (this.curWorkInfo && this.curWorkInfo.id != item.id) return this.$message.warning("请先完成上一个导览点");
      this.resetEdit();
      if (item.link_id) {
        // 编辑已有的
        this.resetEditing();
        item.editIng = true;
        this.query.id = item.id;
        this.query.type = item.type;
        this.query.explain_content_text = item.explain_content_text;
        this.query.link_id = item.link_id;
        this.query.space_id = item.space_id;
        this.query.sort = item.sort;
      }
      this.isSetDesc = true;
      this.isSelectWork = false;
    },
    editInfoAction: function editInfoAction() {
      if (this.loading) return false;
      this.resetEditing();
      this.query.id = "";
      this.query.type = "tourIntroductionInfo";
      this.query.link_id = "";
      this.query.space_id = "";
      this.query.sort = "";
      this.query.explain_content_text = "";
      if (this.tourIntroductionInfo) {
        this.query.explain_content_text = this.tourIntroductionInfo.tourIntroduction;
      }
      this.isSetDesc = true;
      this.isSelectWork = false;
    },
    clearGuidanceList: function clearGuidanceList(type) {
      var _this5 = this;
      // 清空
      if (type == 0) {
        this.$confirm("确认清空导览点？", "操作提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          // 导览列表
          if (!_this5.cloneGuidanceList.length) return;
          _this5.reset();
          _this5.query = _this5.initQuery();
          _this5.curWorkInfo = "";
          _this5.loading = true;
          (0, _guidance._deleteGuidanceAll)({
            space_id: _this5.space_id
          }).then(function (res) {
            _this5.$message.success("已清空所有导览路线");
            _this5.getGuidanceList();
          }).catch(function (err) {
            _this5.loading = false;
            console.log(err);
          });
        }).catch(function () {});
      } else {
        // 数字人列表
        if (this.isSelectNumMerber) {
          this.isSelectNumMerber = false;
          if (this.curMerberInfo && this.merberData.digital_man_voice_id == this.curMerberInfo.digital_man_voice_id) {
            this.merberAiList.forEach(function (item) {
              return item.editIng = false;
            });
          }
        } else {
          this.merberData.digital_man_voice_id = this.curMerberInfo && this.curMerberInfo.digital_man_voice_id ? this.curMerberInfo.digital_man_voice_id : "";
          this.merberAiList.forEach(function (item) {
            return item.editIng = false;
          });
        }
      }
    },
    onSave: function onSave(type, sort) {
      if (type == 0) {
        if (!this.curWorkInfo && !this.query.id && !this.cloneGuidanceList.length) {
          return;
        }
        if (!this.query.id) {
          // 添加
          if (!this.curWorkInfo) {
            this.$emit("closeAction");
            return this.$message.success("保存成功");
          }
          this.query.link_id = this.curWorkInfo.id;
          this.query.space_id = this.space_id;
          if (sort) this.query.sort = sort;
          // type
          this.query.type = this.curWorkInfo.extends.type;
          // if (this.curWorkInfo.extends && this.curWorkInfo.extends.productType == "nft") {
          //   this.query.type = 3;
          // } else {
          //   this.query.type =
          //     this.curWorkInfo.newtype == "material"
          //       ? 1
          //       : this.curWorkInfo.newtype == "works"
          //       ? 2
          //       : "";
          // }
          this.query.space_id = this.space_id;
          this.loading = true;
          this.addGuidance();
          return;
        } else {
          // 修改
          this.loading = true;
          this.editGuidance();
          return;
        }
      } else {
        // 数字人
        if (this.isSelectNumMerber) {
          this.isSelectNumMerber = false;
          this.merberAiList.forEach(function (item) {
            return item.editIng = false;
          });
          return;
        }
        if (this.merberData.digital_man_voice_id && (!this.curMerberInfo || this.merberData.digital_man_voice_id != this.curMerberInfo.digital_man_voice_id)) {
          this.changeMerber();
        }
      }
    },
    sortGuidance: function sortGuidance() {
      var _this6 = this;
      // 排序的请求功能函数
      this.loading = true;
      (0, _guidance._sortGuidance)({
        data: this.sortListQuery
      }).then(function (res) {
        _this6.getGuidanceList();
        _this6.sortListQuery = [];
        _this6.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this6.loading = false;
        _this6.sortListQuery = [];
      });
    },
    batchAddGuidance: function batchAddGuidance(dataList) {
      var _this7 = this;
      // 添加导览的功能函数
      var list = [];
      dataList.forEach(function (m, i) {
        list.push({
          type: m.extends.type,
          link_id: m.id,
          space_id: _this7.space_id,
          sort: i + 1
        });
      });
      (0, _guidance._batchAddGuidance)({
        create_data: list
      }).then(function (res) {
        _this7.getGuidanceList();
        _this7.query = _this7.initQuery();
        _this7.curWorkInfo = "";
      }).catch(function (err) {
        _this7.query = _this7.initQuery();
        _this7.loading = false;
        _this7.curWorkInfo = "";
      });
    },
    addGuidance: function addGuidance() {
      var _this8 = this;
      // 添加导览的功能函数
      (0, _guidance._addGuidance)(this.query).then(function (res) {
        _this8.$message.success("添加导览点成功");
        _this8.getGuidanceList();
        _this8.query = _this8.initQuery();
        _this8.curWorkInfo = "";
      }).catch(function (err) {
        console.log(err);
        _this8.query = _this8.initQuery();
        _this8.loading = false;
        _this8.curWorkInfo = "";
      });
    },
    editGuidance: function editGuidance() {
      var _this9 = this;
      // 编辑导览点功能函数
      (0, _guidance._editGuidance)(this.query).then(function (res) {
        _this9.$message.success("编辑成功");
        _this9.resetEditing();
        _this9.getGuidanceList();
        _this9.query = _this9.initQuery();
      }).catch(function (err) {
        console.log(err);
        _this9.query = _this9.initQuery();
        _this9.loading = false;
      });
    },
    getGuidanceList: function getGuidanceList(isInit) {
      var _this10 = this;
      // 获取导览列表
      this.loading = true;
      this.reset();
      if (this.$refs.audioRef) {
        this.$refs.audioRef.pause();
        this.cloneGuidanceList.forEach(function (itm) {
          return itm.isPlay = false;
        });
      }
      (0, _guidance._getGuidanceList)({
        space_id: this.space_id
      }).then(function (res) {
        if (res.data.list && res.data.list.length) {
          _this10.cloneGuidanceList = res.data.list.map(function (item) {
            var timeLength;
            if (!item.explain_duration) timeLength = "";else if (item.explain_duration <= 60) timeLength = "00:" + (0, _utils.formatDateNumber)(item.explain_duration);else {
              var arr = ((item.explain_duration / 60).toFixed(2) + "").split(".");
              var last = item.explain_duration % 60;
              timeLength = (0, _utils.formatDateNumber)(arr[0]) + ":" + (0, _utils.formatDateNumber)(last);
            }
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
              timeLength: timeLength,
              isEdit: false
            });
          });
          console.log(_this10.cloneGuidanceList);
          _this10.loadEnd = true;
        } else if (isInit) {
          var newD = _this10.newopen3d.getExhibitionData();
          _this10.batchAddGuidance(newD.exhibition);
        } else {
          _this10.loadEnd = true;
          _this10.cloneGuidanceList = [];
        }
        _this10.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this10.loading = false;
      });
    },
    inpAction: function inpAction(val) {
      if (this.newopen3d) {
        this.newopen3d.changeLockMove(val);
      }
    },
    setMember: function setMember(item) {
      // 编辑中的数字人
      this.merberAiList.forEach(function (item) {
        return item.editIng = false;
      });
      item.editIng = true;
      this.isSelectNumMerber = true;
    },
    useMember: function useMember(type, item) {
      var data = {};
      // 使用数字人
      if (type == 1) {
        var vD = {};
        if (!this.merberData.digital_man_voice_id) {
          this.merberData.digital_man_voice_id = this.merberToneList[0].id;
          vD = this.merberToneList[0];
        }
        this.merberData.digital_man_id = item.id;
        this.merberData.space_id = this.space_id;
        data = {
          digital_man_id: item.id,
          digital_man_image: item.image,
          digital_man_name: item.name,
          digital_man_voice: this.merberData.digital_man_voice,
          digital_man_voice_id: this.merberData.digital_man_voice_id,
          digital_man_voice_name: this.merberData.digital_man_voice_name
        };
        if (vD.id) {
          data.digital_man_voice = vD.voice;
          data.digital_man_voice_id = vD.id;
          data.digital_man_voice_name = vD.name;
        }
        this.changeMerber();
      } else {
        this.merberData.digital_man_voice_id = item.id;
        data = JSON.parse(JSON.stringify(this.merberData));
        data.digital_man_voice = item.voice;
        data.digital_man_voice_id = item.id;
        data.digital_man_voice_name = item.name;
        this.merberData.space_id = this.space_id;
        this.changeMerber();
        this.isSelectNumMerber = false;
      }
      this.$emit('editPeople', data);
    },
    changeMerber: function changeMerber() {
      var _this11 = this;
      // 修改数字人功能函数
      this.loading = true;
      if (this.$refs.audioRef) {
        this.$refs.audioRef.pause();
        this.cloneGuidanceList.forEach(function (itm) {
          return itm.isPlay = false;
        });
      }
      (0, _guidance._changeMerber)(this.merberData).then(function (res) {
        _this11.$message.success("设置成功");
        _this11.curPlayInfo = {
          explain_content_url: "",
          id: ""
        };
        _this11.merberAiList.forEach(function (item) {
          return item.editIng = false;
        });
        _this11.getCurMemberInfo();
        _this11.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this11.loading = false;
      });
    },
    getCurMemberInfo: function getCurMemberInfo() {
      this.curMerberInfo = JSON.parse(JSON.stringify(this.guided_digital_man_config));
      this.merberData = JSON.parse(JSON.stringify(this.guided_digital_man_config));
      //  获取当前空间数字人信息
      // _getAIMerberId(this.space_id)
      //   .then((res) => {
      //     this.curMerberInfo = res.data;
      //     if (this.curMerberInfo) {
      //       this.merberData = JSON.parse(JSON.stringify(this.curMerberInfo));
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    onPlayText: function onPlayText(type, item, audioT) {
      var _this12 = this;
      if (type == 1) {
        // 开始播放
        if (!this.curMerberInfo || !this.curMerberInfo.digital_man_voice_id) return this.$message.warning("请先设置导览数字人");
        if (this.curPlayInfo.id == (audioT ? "info" : item.id)) {
          this.$refs.audioRef.play();
          item.isPlay = true;
        } else {
          this.curPlayInfo.explain_content_url = audioT ? item.tourAudioFrequency : item.explain_content_url;
          this.curPlayInfo.id = audioT ? "info" : item.id;
          this.cloneGuidanceList.forEach(function (itm) {
            return itm.isPlay = false;
          });
          item.isPlay = true;
          this.$nextTick(function () {
            _this12.$refs.audioRef.play();
            _this12.$refs.audioRef.addEventListener("ended", function () {
              item.isPlay = false;
            });
          });
        }
      } else {
        // 停止播放
        this.$refs.audioRef.pause();
        item.isPlay = false;
      }
    },
    timeUtils: function timeUtils(timeList) {
      var time = Number(timeList[timeList.length - 1].endTime);
      if (time && time <= 1000) return 1;else return Math.round(time / 1000);
    },
    getAllState: function getAllState() {
      var _this13 = this;
      this.getGuidanceList(true); // 导览列表
      // 数字人列表
      (0, _guidance._digitalHumanList)().then(function (res) {
        res.data.list.forEach(function (m) {
          m.editIng = false;
        });
        _this13.merberAiList = res.data.list || [];
      });
      // 音色列表
      (0, _guidance._getMerberToneList)().then(function (res) {
        _this13.merberToneList = res.data.list;
      }).catch(function (err) {
        console.log(err);
      });
      // 当前空间数字人信息
      this.getCurMemberInfo();

      // 展品和素材列表
      var all = this.newopen3d.getExhibitionData();
      this.materialList = all.material;
      this.guidanceList = all.exhibition;
    },
    getAudioTime: function getAudioTime(val) {
      var timeLength;
      if (!val.explain_duration) timeLength = "";else if (val.explain_duration <= 60) timeLength = "00:" + (0, _utils.formatDateNumber)(val.explain_duration);else {
        var arr = ((val.explain_duration / 60).toFixed(2) + "").split(".");
        timeLength = (0, _utils.formatDateNumber)(arr[0]) + ":" + (0, _utils.formatDateNumber)(arr[1]);
      }
      return timeLength;
    },
    onSelectWork: function onSelectWork(item, index) {
      this.selectWorkActive = index;
      if (index === 1) {
        this.exhibitionType = "material";
      } else if (index === 0) {
        this.exhibitionType = "exhibition";
      }
      this.changeSort = Number(item ? item.sort : 1);
      this.isSelectWork = true;
      this.isSetDesc = false;
    },
    addMaterial: function addMaterial(item) {
      this.resetEdit();
      var editIng = this.cloneGuidanceList.find(function (itm) {
        return !itm.link_id;
      });
      if (editIng) {
        this.$message.warning("请先完成上一个导览");
      } else {
        var editDescIng = this.cloneGuidanceList.find(function (itm) {
          return itm.editIng;
        });
        if (editDescIng) this.$message.warning("请先完成上一个导览");else {
          item.isEdit = true;
        }
      }
    },
    detMaterial: function detMaterial(item) {
      var _this14 = this;
      this.loading = true;
      if (item.link_id) {
        if (item.id == this.query.id) this.query = initQuery();
        (0, _guidance._deleteGuidance)({
          id: item.id
        }).then(function (res) {
          _this14.getGuidanceList();
          _this14.$message.success("删除成功");
          _this14.query = _this14.initQuery();
          _this14.curWorkInfo = "";
        }).catch(function (err) {
          _this14.loading = false;
        });
      } else {
        var index = this.cloneGuidanceList.findIndex(function (item) {
          return !item.link_id;
        });
        if (index !== -1) {
          this.cloneGuidanceList.splice(index, 1);
          this.query = this.initQuery();
          this.curWorkInfo = "";
        }
      }
    },
    draggadbleStart: function draggadbleStart(e) {
      this.clearActive();
      e.item.children[0] && e.item.children[0].classList.add("active");
      var guidanceEl = document.querySelector("#guidance");
    },
    draggableEnd: function draggableEnd() {
      this.clearActive();
      if (this.cloneGuidanceList.length <= 1) return;
      this.sortListQuery = this.cloneGuidanceList.map(function (item, index) {
        return {
          id: item.id,
          sort: index + 1
        };
      });
      this.sortGuidance();
    },
    clearActive: function clearActive() {
      var guidanceEl = document.querySelector("#guidance");
      guidanceEl.querySelectorAll(".active").forEach(function (item) {
        return item.classList.remove("active");
      });
    },
    resetEditing: function resetEditing() {
      // 重置编辑中状态
      this.cloneGuidanceList.forEach(function (item) {
        if (item.editIng) delete item.editIng;
      });
    },
    resetEdit: function resetEdit() {
      // 重置显示添加素材和作品状态
      this.cloneGuidanceList.forEach(function (item) {
        return item.isEdit = false;
      });
    },
    reset: function reset() {
      // 重置所有状态
      this.resetEdit();
      this.isSelectNumMerber = false;
      this.isSetDesc = false;
      this.isSelectWork = false;
    }
  }
};