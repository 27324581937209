"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        size: 10
      },
      total: 0,
      courseName: ''
    };
  },
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.course_id;
      });
      if (this.resultData.length === 0) {
        this.loadCourse();
      }
      this.showSelectAuction = true;
    },
    searchCourseAction: function searchCourseAction() {
      this.queryParams.course_name = this.courseName;
      this.queryParams.page = 1;
      this.loadCourse();
    },
    loadCourse: function loadCourse(fun) {
      var _this = this;
      var params = this.queryParams;
      var msg = this.$message.loading({
        render: function render(h) {
          return h('span', ['加载中...']);
        },
        duration: 0
      });
      this.$http.get('/Course_list', {
        params: params
      }).then(function (res) {
        msg();
        if (res.code === 200) {
          _this.total = res.data.total;
          _this.resultData = res.data.data;
          _this.$nextTick(function () {
            // 赋值后马上更新
            if (fun) fun();
          });
        }
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.course_id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        image: info.course_img.split(',')[0],
        name: info.course_name,
        course_id: info.course_id,
        tags: [],
        price: "\uFFE5".concat(info.price),
        sign: info.click_num + '人已听课'
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.course_id;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'courseOffline',
        data: this.selectedAuction
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.loadCourse();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.loadCourse();
    }
  }
};