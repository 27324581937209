"use strict";

var _interopRequireWildcard = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var THREE = _interopRequireWildcard(require("./model/three.module.js"));
var _TransformControls = require("./model/TransformControls.js");
var _GLTFLoader = require("./model/GLTFLoader.js");
var _RoomEnvironment = require("./model/RoomEnvironment.js");
var _DRACOLoader = require("./model/DRACOLoader.js");
var _libgif = _interopRequireDefault(require("./model/libgif"));
var _index3 = require("@/utils/index");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _digitalHall = require("@/api/digitalHall");
var _basic = require("@/api/basic");
var _nft = require("@/api/nft");
var _product = require("@/api/product");
var _auction = require("@/api/auction");
var _system = require("@/api/system");
var _live = require("@/api/live");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var touchEnabled = !!('ontouchstart' in window);
var skyimage = '';
var ispc = true;
var giftextureArr = [];
// 选中的mesh
var selectedMesh = null;
var configjson = null;
var selectedModel = null;
var mouse, raycaster;
var list = [];
var camera, renderer, scene, controls;
var ambientLight;
var photoMesh = [];
var scenearr = [];
var scenearrmesh = [];
var artworklist = [];
var dragwork = null;
var player, activeCamera, rightCamera;
var speed = 6; // 移动速度
var turnSpeed = 2;
var move = {
  forward: 0,
  turn: 0,
  up: 0
};
var lookAty = 2;
var dragflag = false;
var dragpointflag = false;
var placeholderarr = [];
var placeholdercanuselength = 0;
var placeholderjson = [];
var willpushplaceholder = [];
var controlPoints = [];
var clock = new THREE.Clock();
var colliders = []; // 碰撞物

function onKeyDown(event) {
  // console.log(event);
  switch (event.code) {
    case 'ArrowUp':
    case 'KeyW':
      move.forward = 1;
      break;
    case 'ArrowLeft':
    case 'KeyA':
      move.turn = turnSpeed;
      break;
    case 'ArrowDown':
    case 'KeyS':
      move.forward = -1;
      break;
    case 'ArrowRight':
    case 'KeyD':
      move.turn = -turnSpeed;
      break;
    case 'Space':
      move.up = 1;
      break;
    case 'KeyB':
      move.up = -1;
      break;
    case 'KeyE':
      controls.setMode('rotate');
      break;
    case 'KeyR':
      controls.setMode('translate');
      break;
  }
}
function onKeyUp(event) {
  switch (event.code) {
    case 'ArrowUp':
    case 'KeyW':
      move.forward = 0;
      break;
    case 'ArrowLeft':
    case 'KeyA':
      move.turn = 0;
      break;
    case 'ArrowDown':
    case 'KeyS':
      move.forward = 0;
      break;
    case 'ArrowRight':
    case 'KeyD':
      move.turn = 0;
      break;
    case 'Space':
    case 'KeyB':
      move.up = 0;
      break;
  }
}
var controlsMouseDownFlag = false;
var scenemove = false;
var mixer;
var lockpoint = [];
var index = 0;
var videomesharr = [];
var videosources = [];
var videoarr = [];
var videotextures = [];
// 创建一个 AudioContext 对象，用于控制音频
var audioContext = new AudioContext();
// 创建一个 GainNode 对象，并将其连接到音频输出的目标节点
var gainNode = audioContext.createGain();
gainNode.connect(audioContext.destination);
function onResize() {
  var w = window.innerWidth;
  var h = window.innerHeight;
  camera.aspect = w / h;
  camera.updateProjectionMatrix();
  renderer.setSize(w, h);
}
var timeout = null;
function render() {
  var dt = clock.getDelta();
  update(dt);
  giftextureArr.forEach(function (item) {
    if (item) {
      item.needsUpdate = true;
    }
  });
  renderer.render(scene, camera);
  // 更新音量
  videomesharr.forEach(function (item, index) {
    var distance = camera.position.distanceTo(item.position);
    var volume = 1 - distance / 15;
    if (volume < 0) {
      volume = 0;
    }
    if (videotextures[index]) {
      videotextures[index].needsUpdate = true;
    }
    if (videosources[index]) {
      videosources[index].gain.value = volume;
    }
  });
  window.requestAnimationFrame(render);
}
function update(dt) {
  updatePlayer(dt);
  updateCamera(dt);
  if (mixer) {
    mixer.update(dt);
  }
}

// 更新运动体的方向
function updatePlayer(dt) {
  var pos = player.position.clone();
  pos.y += 2;
  var dir = new THREE.Vector3();
  player.getWorldDirection(dir);
  dir.negate();
  if (move.forward < 0) dir.negate();
  var raycaster = new THREE.Raycaster(pos, dir);
  var blocked = false;
  if (colliders.length > 0) {
    var intersect = raycaster.intersectObjects(colliders);
    if (intersect.length > 0) {
      if (intersect[0].distance < 1) {
        blocked = true;
      }
    }
  }
  // checkStairCollide(player);
  var blockedLeft = false;
  var blockedRight = false;
  if (colliders.length > 0) {
    // 左方向碰撞监测
    dir.set(-1, 0, 0);
    dir.applyMatrix4(player.matrix);
    dir.normalize();
    raycaster = new THREE.Raycaster(pos, dir);
    var _intersect = raycaster.intersectObjects(colliders);
    if (_intersect.length > 0) {
      if (_intersect[0].distance < 1.5) {
        blockedLeft = true;
        // player.translateX(dt * 2*0.5);
        // player.translateX(1 - intersect[0].distance)
      }
    }

    // 右方向碰撞监测
    dir.set(1, 0, 0);
    dir.applyMatrix4(player.matrix);
    dir.normalize();
    raycaster = new THREE.Raycaster(pos, dir);
    _intersect = raycaster.intersectObjects(colliders);
    if (_intersect.length > 0) {
      if (_intersect[0].distance < 1.5) {
        blockedRight = true;
        // player.translateX(-dt * 2*0.5);
        // player.translateX(intersect[0].distance - 1)
      }
    }
  }
  if (!blocked) {
    if (move.forward !== 0) {
      if (move.forward > 0) {
        player.translateZ(-dt * speed);
      } else {
        player.translateZ(dt * speed * 0.5);
      }
    }
  }
  if (move.turn !== 0) {
    if (move.turn > 0) {
      if (!blockedLeft) {
        player.translateX(-dt * 9 * 0.5);
      }
    } else {
      if (!blockedRight) {
        player.translateX(dt * 9 * 0.5);
      }
    }
  }
  if (move.up !== 0) {
    player.translateY(move.up * dt * 9 * 0.5);
  }
}
var cameramoveLook = {
  x: 0,
  y: 0,
  z: 0
};
var cameramoveLock = false;
// 更新camera
function updateCamera(dt) {
  if (cameramoveLock) {
    camera.position.lerp(activeCamera.getWorldPosition(new THREE.Vector3()), 1);
    camera.lookAt(new THREE.Vector3(cameramoveLook.position.x, lookAty, cameramoveLook.position.z));
  } else {
    camera.position.lerp(activeCamera.getWorldPosition(new THREE.Vector3()), 1);
    var pos = player.position.clone();
    pos.y += lookAty;
    camera.lookAt(pos);
  }
}
function createControlPoints(mesh) {
  var geometry = new THREE.BoxGeometry(0.1, 0.1);
  var material = new THREE.MeshBasicMaterial({
    color: 0x00ff00
  });
  controlPoints.push(new THREE.Mesh(geometry, material));
  controlPoints.push(new THREE.Mesh(geometry, material));
  controlPoints.push(new THREE.Mesh(geometry, material));
  controlPoints.push(new THREE.Mesh(geometry, material));
  var box = new THREE.Box3().setFromObject(mesh);
  // const box3Helper = new THREE.Box3Helper(box, 0x000000);
  // scene.add(box3Helper);
  // const boxCenter = box.getCenter(new THREE.Vector3());
  // const boxSize = box.getSize(new THREE.Vector3());
  // controlPoints[0].position.set(new THREE.Vector3(boxCenter.x - boxSize.x / 2, boxCenter.y - boxSize.y / 2, boxCenter.z - boxSize.z / 2));
  // controlPoints[1].position.set(new THREE.Vector3(boxCenter.x - boxSize.x / 2, boxCenter.y + boxSize.y / 2, boxCenter.z - boxSize.z / 2))
  // controlPoints[2].position.set(new THREE.Vector3(boxCenter.x + boxSize.x / 2, boxCenter.y + boxSize.y / 2, boxCenter.z - boxSize.z / 2))
  // controlPoints[3].position.set(new THREE.Vector3(boxCenter.x + boxSize.x / 2, boxCenter.y - boxSize.y / 2, boxCenter.z - boxSize.z / 2))

  var center = box.getCenter(new THREE.Vector3());
  var size = box.getSize(new THREE.Vector3());
  controlPoints[0].position.set(center.x - size.x / 2, center.y + size.y / 2, center.z);
  controlPoints[1].position.set(center.x + size.x / 2, center.y + size.y / 2, center.z);
  controlPoints[2].position.set(center.x - size.x / 2, center.y - size.y / 2, center.z);
  controlPoints[3].position.set(center.x + size.x / 2, center.y - size.y / 2, center.z);
  controlPoints[0].name = 'point1';
  controlPoints[1].name = 'point2';
  controlPoints[2].name = 'point3';
  controlPoints[3].name = 'point4';
  controlPoints.forEach(function (point) {
    scene.add(point);
  });

  // controlPoints.forEach((point) => {
  //     point.addEventListener('mousedown', onControlPointMouseDown);
  //     point.addEventListener('mousemove', onControlPointMouseMove);
  //     point.addEventListener('mouseup', onControlPointMouseUp);
  // });
}
window.updateControlPoints = function () {
  var box = new THREE.Box3().setFromObject(selectedMesh);
  var size = box.getSize(new THREE.Vector3());
  var center = box.getCenter(new THREE.Vector3());
  var halfSize = size.multiplyScalar(0.5);
  var corner1 = new THREE.Vector3(center.x - halfSize.x, center.y - halfSize.y, center.z - halfSize.z);
  var corner2 = new THREE.Vector3(center.x + halfSize.x, center.y - halfSize.y, center.z - halfSize.z);
  var corner3 = new THREE.Vector3(center.x - halfSize.x, center.y - halfSize.y, center.z + halfSize.z);
  var corner4 = new THREE.Vector3(center.x + halfSize.x, center.y - halfSize.y, center.z + halfSize.z);
  controlPoints[0].position.copy(corner1);
  controlPoints[1].position.copy(corner2);
  controlPoints[2].position.copy(corner3);
  controlPoints[3].position.copy(corner4);
};
//   通过点击获取点击对象
// eslint-disable-next-line prefer-const
mouse = new THREE.Vector2();
// eslint-disable-next-line prefer-const
raycaster = new THREE.Raycaster();

// const backAction = () => {
//   nftid.value = '';
// };

// touchStartPoint 记录起始点X坐标
//  touchStartPoint: 0,
// distance 记录划动的距离
//  distance: 0,
var startx1 = 0;
var starty1 = 0;
var starttime = 0;
var startlong = false;
var startTimeOut = null;
var distancex1 = 0;
var distancey1 = 0;
var startvecor = {
  x: 0,
  y: 0,
  z: 0
};
var fonto = '';

// const back = () => {
//   router.back();
// };

// const showeditdetail = ref(false);

// const changeshowwork = () => {
//   showwork.value = true;
// };

var changenftmesh = function changenftmesh(item) {
  var child = selectedMesh;
  var img = item.file_cover + '!m640';
  var loading = showLoadingToast({
    message: '操作中',
    forbidClick: true,
    duration: 0
  });
  var imgtextarea = new THREE.TextureLoader().load(img, function (texture) {
    var SIZE = 2;
    // console.log('这是加载图片模型信息');
    // console.log(texture);
    var img = imgtextarea.image;
    var height = img && img.height || SIZE;
    var width = img && img.width || SIZE;
    height = SIZE / width * height;
    width = SIZE;
    imgtextarea.encoding = THREE.sRGBEncoding;
    // imgtextarea.flipY = false;
    var mat = new THREE.MeshBasicMaterial({
      map: imgtextarea,
      side: THREE.DoubleSide,
      transparent: true
    });

    // const geom = new THREE.BoxGeometry(width, height, 0.04);
    child.material = mat;
    // const mesh = new THREE.Mesh(geom, mat);
    // mesh.position.set(
    //   child.position.x,
    //   child.position.y,
    //   child.position.z
    // );
    // mesh.rotation.set(
    //   child.rotation.x,
    //   child.position.y,
    //   child.rotation.z
    // );
    // mesh.quaternion.set(
    //   child.quaternion.x,
    //   child.quaternion.y,
    //   child.quaternion.z,
    //   child.quaternion.w
    // );
    // // mesh.name = child.name + " copy";
    // // photoMesh.push(mesh);
    // // mesh.workindex = sindex;
    // // scene.add(mesh);

    // photoMesh.forEach(item=>{
    //   if(item.name == child.name) {
    //     mesh.parent = item.parent;
    //     item = mesh;
    //   }
    // })
    // photoMesh.push(mesh);
    // scene.remove(child);
    // scene.add(mesh);
    showwork.value = false;
    loading.close();
  }, function (progress) {
    console.log(progress);
  }, function (err) {
    // alert(err);
  });
};

// const threeid = ref('');

function drawdrawaudio(event, work) {
  var audiourl = work.file_url;
  // 加载音频文件
  var listener = new THREE.AudioListener();
  var sound = new THREE.PositionalAudio(listener);
  var audioLoader = new THREE.AudioLoader();
  audioLoader.load(audiourl, function (buffer) {
    sound.setBuffer(buffer);
    sound.setRefDistance(5);
    sound.setLoop(true);
    // sound.setVolume(1);
    sound.play();
    sound.name = 'audiosound';
  });
  var img = work.file_cover + '!m370';
  var imgtextarea = new THREE.TextureLoader().load(img, function (texture) {
    var SIZE = 2;
    // console.log(texture);
    imgtextarea.encoding = THREE.sRGBEncoding;
    var img = imgtextarea.image;
    var height = img && img.height || SIZE;
    var width = img && img.width || SIZE;
    height = SIZE / width * height;
    width = SIZE;
    // imgtextarea.flipY = false;
    var mat = new THREE.MeshBasicMaterial({
      map: imgtextarea,
      side: THREE.DoubleSide,
      transparent: true
    });
    var geom = new THREE.BoxGeometry(width, height, 0.05);
    var mesh = new THREE.Mesh(geom, mat);
    var mouse = new THREE.Vector2(event.clientX / window.innerWidth * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    var intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      mesh.position.copy(intersects[0].point);
    }
    mesh.name = 'dragaudio';
    mesh.userData.type = 'audio';
    mesh.userData.work = work;
    photoMesh.push(mesh);
    mesh.add(sound);
    scene.add(mesh);
    // console.log(mesh);
  }, function (progress) {
    console.log(progress);
  }, function (err) {
    // alert(err);
  });
  // 将摄像机指定为监听器
  camera.add(listener);
}
function drawdragvideo(event, work) {
  var videourl = work.file_url;
  var dateid = +new Date();
  // const video = document.getElementById("video");
  var video = document.createElement('video');
  video.crossOrigin = 'anonymous';
  video.src = videourl;
  video.loop = true;
  video.playsinline = true;
  video.autoplay = false;
  video.setAttribute('playsinline', true);
  video.setAttribute('webkit-playsinline', 'true');
  video.id = dateid;
  video.load();
  // document.getElementById('videobox').appendChild(video);
  // 创建一个 AudioContext 对象，用于控制音频
  var audioContext = new AudioContext();
  // 创建一个 GainNode 对象，并将其连接到音频输出的目标节点
  var gainNode = audioContext.createGain();
  gainNode.connect(audioContext.destination);
  gainNode.id = dateid;
  // 创建一个 MediaElementAudioSourceNode 对象，并将 HTMLVideoElement 对象作为输入端口
  var source = audioContext.createMediaElementSource(video);
  // 将 MediaElementAudioSourceNode 对象连接到 GainNode 对象的输入端口
  source.connect(gainNode);
  setTimeout(function () {
    var texture = new THREE.VideoTexture(video);
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBAFormat;
    texture.needsUpdate = true;
    texture.encoding = THREE.sRGBEncoding;
    videotextures.push(texture);
    // 创建一个 PlaneGeometry 对象，并使用 video 元素作为纹理
    var geometry = new THREE.PlaneGeometry(2, 2);
    var material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true
    });
    // 创建 ChromaKey 材质
    var chromaKeyMaterial = new THREE.ShaderMaterial({
      uniforms: {
        chromaKeyColor: {
          type: 'c',
          value: new THREE.Color(0x00ff00)
        },
        pointTexture: {
          value: texture
        },
        time: {
          value: 1.0
        }
      },
      vertexShader: "\n                    varying vec2 vUv;\n\n                    void main() {\n                    vUv = uv;\n                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);\n                    }\n                ",
      fragmentShader: "\n                    uniform sampler2D pointTexture;\n                    uniform vec3 chromaKeyColor;\n                    varying vec2 vUv;\n                    void main() {\n                        vec4 color = texture2D(pointTexture, vUv);\n                        float chromaDistance = distance(color.rgb, chromaKeyColor);\n                        if (chromaDistance < 0.8) {\n                            discard;\n                        }\n                        gl_FragColor = vec4(color.rgb, 1.0);\n                    }\n                ",
      side: THREE.DoubleSide
    });
    var mesh = new THREE.Mesh(geometry, material);
    // const mesh = new THREE.Mesh(geometry, chromaKeyMaterial);
    var mouse = new THREE.Vector2(event.clientX / window.innerWidth * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    var intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      mesh.position.copy(intersects[0].point);
    }
    mesh.name = 'dragvideo';
    photoMesh.push(mesh);
    mesh.userData.work = work;
    mesh.userData.type = 'video';
    mesh.userData.dateid = dateid;
    scene.add(mesh);
    videomesharr.push(mesh);
    videosources.push(gainNode);
    videoarr.push(video);
    video.play();
  }, 500);
}
function drawdraggif(event, work) {
  var gifURL = work.file_cover + '!gif330';
  var imgdom = document.createElement('img');
  setTimeout(function () {
    imgdom.onload = function () {
      var sup2 = new _libgif.default({
        gif: imgdom,
        show_progress_bar: false,
        progressbar_height: 0
      });
      sup2.load();
      var llamacanvas = sup2.get_canvas();
      var dispTexture = new THREE.Texture(llamacanvas);
      dispTexture.encoding = THREE.sRGBEncoding;
      var material = new THREE.MeshBasicMaterial({
        map: dispTexture,
        side: THREE.DoubleSide,
        transparent: true,
        needsUpdate: true
      });
      var width = 2;
      var height = 2;
      if (this.width && this.height) {
        height = 2 / this.width * this.height;
      }
      var geom = new THREE.BoxGeometry(width, height, 0.05);
      var mesh = new THREE.Mesh(geom, material);
      var mouse = new THREE.Vector2(event.clientX / window.innerWidth * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
      var raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(scene.children, true);
      if (intersects.length > 0) {
        mesh.position.copy(intersects[0].point);
      }
      mesh.name = 'draggif';
      mesh.userData.work = work;
      photoMesh.push(mesh);
      scene.add(mesh);
      dispTexture.needsUpdate = true;
      giftextureArr.push(dispTexture);
    };
    imgdom.src = gifURL;
  }, 300);
}
function drawsdraggltf(event, work, loading) {
  var gltfloader = new _GLTFLoader.GLTFLoader();
  // 场景
  gltfloader.load(work.file_url, function (gltf) {
    var object = gltf.scene.children[0];
    loading.close();
    var parent = new THREE.Object3D();
    parent.add(object); // 将小模型添加到父物体中

    object.traverse(function (child) {
      // if (child.isMesh) {
      child.userData.type = 'gltfchild';
      child.userData.parent = parent;
      parent.children.push(child); // 将所有的子组件添加到父物体的属性中
      // }
    });
    parent.userData.work = work;
    parent.userData.file_url = work.file_url;
    parent.name = 'draggltf';
    var mouse = new THREE.Vector2(event.clientX / window.innerWidth * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    var intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      parent.position.copy(intersects[0].point);
    }
    scene.add(parent); // 将父物体添加到场景中
    photoMesh.push(parent);
    controls.attach(parent);
    var light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(0, 1, 1).normalize();
    scene.add(light);
  }, function (xhr) {
    console.log(xhr.loaded / xhr.total * 100 + '% loaded');
  }, function () {
    console.log('An error happened');
  });
}
function drawsdragimg(event, work) {
  // const img = work.file_cover + '!m640';
  var img = work.product.image + '!m640';
  var imgtextarea = new THREE.TextureLoader().load(img, function (texture) {
    var SIZE = 2;
    // console.log('这是加载图片模型信息');
    // console.log(texture);
    imgtextarea.encoding = THREE.sRGBEncoding;
    var img = imgtextarea.image;
    var height = img && img.height || SIZE;
    var width = img && img.width || SIZE;
    height = SIZE / width * height;
    width = SIZE;
    // imgtextarea.flipY = false;
    var mat = new THREE.MeshBasicMaterial({
      map: imgtextarea,
      side: THREE.DoubleSide,
      transparent: true
    });
    var geom = new THREE.BoxGeometry(width, height, 0.05);
    var mesh = new THREE.Mesh(geom, mat);
    var mouse = new THREE.Vector2(event.clientX / window.innerWidth * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
    var raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    var intersects = raycaster.intersectObjects(scene.children, true);
    if (intersects.length > 0) {
      startvecor = intersects[0].point.clone();
      mesh.position.copy(intersects[0].point);
    }
    mesh.name = 'drag copy';
    photoMesh.push(mesh);
    mesh.userData.work = work;
    // mesh.workindex = sindex;
    scene.add(mesh);
    selectedMesh = mesh;
    dragflag = true;
  }, function (progress) {
    console.log(progress);
  }, function (err) {
    // alert(err);
  });
}
function drawnewText() {
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  var text = '这里是测试的文字哈哈哈';
  context.font = '18px Arial';
  context.textAlign = 'left';
  context.textBaseline = 'top';
  var maxWidth = 300; // 最大宽度
  var lineHeight = 24; // 行高
  var words = text.split(' ');
  var line = '';
  var x = 10;
  var y = 10;
  for (var i = 0; i < words.length; i++) {
    var testLine = line + words[i] + ' ';
    var _metrics = context.measureText(testLine);
    var testWidth = _metrics.width;
    if (testWidth > maxWidth && i > 0) {
      context.fillText(line, x, y);
      line = words[i] + ' ';
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
  // context.fillText(text, 0, 50);
  var width = canvas.offsetWidth;
  var height = canvas.offsetHeight;
  var canvasTexture = new THREE.CanvasTexture(canvas);
  var planeGeometry = new THREE.PlaneGeometry(5, 5);
  var metrics = context.measureText(text);
  var textWidth = metrics.width;
  var textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
  console.log(textWidth, textHeight);
  // const planeGeometry = new THREE.PlaneGeometry(width * 0.8, height * 0.8);
  var planeMaterial = new THREE.MeshBasicMaterial({
    map: canvasTexture,
    transparent: true,
    side: THREE.DoubleSide
  });
  var plane = new THREE.Mesh(planeGeometry, planeMaterial);
  plane.position.set(0, 0, 0);
  scene.add(plane);
  // const input = document.getElementById('text-input');
  //   input.addEventListener('input', (event) => {
  //     text = event.target.value;
  //     context.clearRect(0, 0, canvas.width, canvas.height);
  //     context.fillText(text, 0, 50);
  //     canvasTexture.needsUpdate = true;
  //   });
}
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      user_exhibition_id: this.$route.query.id || '',
      mer_id: this.$store.getters.mer_id,
      mer_logo: this.$store.getters.mer_logo,
      changeType: 1,
      tagList: [],
      works_type: '',
      label_id: '',
      queryParams: {
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      changeWork: [],
      changeWorkIds: [],
      keyword: '',
      placeWorksList: [],
      placeWorksIds: [],
      videoinfoarr: [],
      direction: 'column',
      nftid: '',
      loadingprograss: 0,
      signFixedFlag: false,
      loadingflag: true,
      placeholderlength: 0,
      surplusnum: 0,
      touchStartPoint: 0,
      startY: 0,
      distance: 0,
      distanceY: 0,
      startmove: false,
      controlsIsUsed: false,
      slidervalue: 0,
      showworkflag: false,
      workdetail: {},
      showwork: false,
      textvalue: '',
      selectmode: 'nft',
      descjson: {
        title: '',
        desc: '',
        exhibition_cover: ''
      },
      nftlist: [],
      id: '',
      file_type: '',
      props: {
        emitPath: false
      },
      merCateList: [],
      // 商户分类筛选
      mer_cate_id: '',
      treeData2: [],
      attachment_category_id: '',
      text: '',
      space_name: '',
      space_intro: '',
      space_desc: '',
      dialogVisible: false
    };
  },
  mounted: function mounted() {
    if (touchEnabled) {
      document.getElementById('container').addEventListener('touchstart', this.onmodelclick);
    } else {
      document.getElementById('container').addEventListener('mousedown', this.onmodelclick);
      document.getElementById('container').addEventListener('mouseup', this.onModelMouseUp);
    }
  },
  created: function created() {
    var _this = this;
    (0, _digitalHall.userExhibitionDetailApi)({
      user_exhibition_id: this.user_exhibition_id,
      mer_id: this.mer_id
    }).then(function (res) {
      if (!res.data.works) {
        res.data.works = [];
      }
      _this.placeWorksList = res.data.works;
      _this.placeWorksIds = _this.placeWorksList.map(function (m) {
        return m.product_id;
      });
      _this.id = _this.user_exhibition_id;
      // skyimage = 'https://cloud-java-test.oss-cn-shanghai.aliyuncs.com/merchant/000000/2023217/image/1676625384295581489.png';
      skyimage = res.data.exhibitionTemplate.exhibition_image;
      _this.descjson.title = res.data.exhibition_title;
      _this.descjson.desc = res.data.exhibition_intro;
      // this.descjson.exhibition_cover = 'https://cloud-java-test.oss-cn-shanghai.aliyuncs.com/merchant/000000/2023217/image/1676625384295581489.png';
      _this.descjson.exhibition_cover = res.data.exhibitionTemplate.exhibition_cover;
      _this.space_name = res.data.exhibition_title;
      _this.space_intro = res.data.exhibition_intro;
      _this.space_desc = res.data.exhibition_desc;
      selectedModel = res.data.exhibitionTemplate;
      // selectedModel = {
      //   exhibition_cover: 'https://cloud-java-test.oss-cn-shanghai.aliyuncs.com/merchant/000000/2023217/image/1676625384295581489.png',
      //   template_url: 'https://saas.cdn.yunzongbu.cn/admin/open3D/1678081765160533059.glb',
      //   exhibition_template_id: 10
      // };
      // selectedModel.template_url = 'https://saas.cdn.yunzongbu.cn/admin/open3D/1682586396839888897.glb'
      selectedModel.mer_temp_id = res.data.mer_temp_id;
      artworklist = res.data.works || [];
      if (res.data.config && res.data.config.workconfig) {
        configjson = JSON.parse(res.data.config.workconfig);
      }
      _this.init();
    }).catch(function () {});
    (0, _basic.labelLstApi)({
      page: 1,
      limit: 100
    }).then(function (res) {
      _this.tagList = res.data.list;
    });
    this.getWorkList();
  },
  methods: {
    exitAction: function exitAction() {
      var _this2 = this;
      photoMesh = [];
      scenearr = [];
      scenearrmesh = [];
      configjson = null;
      placeholderarr = [];
      function clearScene(scene) {
        while (scene.children.length) {
          var child = scene.children[0];

          // 清空子物体
          clearScene(child);

          // 删除视频
          if (child instanceof THREE.VideoTexture) {
            child.dispose();
          }

          // 删除音频
          if (child instanceof THREE.Audio) {
            child.stop();
            child.disconnect();
            child.source.stop();
            child.source.disconnect();
          }

          // 删除纹理
          if (child.material && child.material.map instanceof THREE.Texture) {
            child.material.map.dispose();
          }

          // 删除几何体
          if (child.geometry) {
            child.geometry.dispose();
          }

          // 从场景中删除子物体
          scene.remove(child);
        }
      }
      clearScene(scene);
      var container = document.getElementById("container");
      container.removeChild(renderer.domElement);
      renderer.dispose();
      scene = null;
      controls = null;
      setTimeout(function () {
        _this2.$router.back();
        // setTimeout(()=>{window.location.reload();}, 100)
      }, 500);
    },
    changeEditTypeAction: function changeEditTypeAction(val) {
      var _this3 = this;
      this.changeType = val;
      this.tableData.data = [[], []];
      this.tableData.total = 0;
      if (val == 3 || val == 4) {
        if (this.merCateList.length <= 0) {
          (0, _product.categorySelectTreeApi)().then(function (res) {
            _this3.merCateList = res.data;
          }).catch(function (res) {});
        }
      } else if (val == 5) {
        if (this.treeData2.length <= 0) {
          var data = {
            attachment_category_name: '全部图片',
            attachment_category_id: 0
          };
          (0, _system.formatLstApi)().then(function (res) {
            _this3.treeData = res.data;
            _this3.treeData.unshift(data);
            _this3.treeData2 = (0, _toConsumableArray2.default)(_this3.treeData);
          }).catch(function (res) {
            // this.$message.error(res.message);
          });
        }
      }
      this.refreshAction();
    },
    setSizeChange: function setSizeChange(size) {
      this.queryParams.limit = size;
      this.getWorkList(1);
    },
    refreshAction: function refreshAction() {
      this.label_id = '';
      this.file_type = '';
      this.keyword = '';
      this.mer_cate_id = '';
      this.attachment_category_id = '';
      this.text = '';
      this.getWorkList(1);
    },
    changeWorkAction: function changeWorkAction(m) {
      if (this.placeWorksIds.indexOf(m.product_id) !== -1) {
        return false;
      }
      var changeIndex = this.changeWorkIds.indexOf(m.product_id);
      if (changeIndex === -1) {
        this.changeWork.push(m);
      } else {
        this.changeWork.splice(changeIndex, 1);
        // this.changeWork = JSON.parse(JSON.stringify(m));
        // const d = JSON.parse(JSON.stringify(m));
        // this.placeWorksList.push(d);
        // willpushplaceholder = [d];

        // this.placeWorksIds = this.placeWorksList.map(m => m.product_id);

        // this.initexhibitionOne();
      }
      this.changeWorkIds = this.changeWork.map(function (m) {
        return m.product_id;
      });
    },
    quickDeployment: function quickDeployment() {
      // 一键布展
      // this.changeWork 这个是选中的藏品
      willpushplaceholder = JSON.parse(JSON.stringify(this.changeWork));
      this.initexhibitionOne();
      this.placeWorksList = this.placeWorksList.concat(this.changeWork);
      this.placeWorksIds = this.placeWorksList.map(function (m) {
        return m.product_id;
      });

      // 操作完之后置空
      this.changeWork = [];
      this.changeWorkIds = [];
    },
    getWorkList: function getWorkList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      var data = JSON.parse(JSON.stringify(this.queryParams));
      data.keyword = this.keyword || '';
      data.attachment_name = this.keyword || '';
      var action = _nft.nftCollectionListApi;
      switch (Number(this.changeType)) {
        case 1:
          data.file_type = this.file_type;
          data.label_id = this.label_id;
          action = _nft.nftCollectionListApi;
          break;
        case 2:
          data.file_type = this.file_type;
          data.label_id = this.label_id;
          action = _nft.nftCollectionListApi;
          break;
        case 3:
          data.mer_cate_id = this.mer_cate_id;
          action = _product.productLstApi;
          break;
        case 4:
          data.cate_id = this.mer_cate_id;
          action = _auction.auctionList;
          break;
        case 5:
          data.attachment_category_id = this.attachment_category_id;
          action = _system.attachmentListApi;
          break;
        case 6:
          action = _live.liveList;
          break;
      }
      action(data).then(function (res) {
        var d = [];
        if (Number(_this4.changeType) === 1) {
          res.data.list.forEach(function (m) {
            m.product.work_type = 1;
            m.work_type = 1;
          });
          d = res.data.list;
        } else if (Number(_this4.changeType) === 2) {
          res.data.list.forEach(function (m) {
            m.product.work_type = 1;
            m.work_type = 1;
          });
          d = res.data.list;
        } else if (Number(_this4.changeType) === 3) {
          res.data.list.forEach(function (m) {
            var newD = JSON.parse(JSON.stringify(m));
            newD.work_type = 3;
            m.product = newD;
            m.work_type = 3;
          });
          d = res.data.list;
        } else if (Number(_this4.changeType) === 4) {
          res.data.list.forEach(function (m) {
            m.product.work_type = 4;
            m.work_type = 4;
          });
          d = res.data.list;
        } else if (Number(_this4.changeType) === 5) {
          res.data.list.forEach(function (m) {
            var obj = {
              product_id: m.attachment_id,
              product: {
                image: m.attachment_src,
                store_name: m.attachment_name
              },
              work_type: 5
            };
            d.push(obj);
          });
        } else if (Number(_this4.changeType) === 6) {
          res.data.list.forEach(function (m) {
            var obj = {
              product_id: m.live_id,
              product: {
                image: m.cover_image,
                store_name: m.live_title
              },
              work_type: 6
            };
            d.push(obj);
          });
        } else {
          d = res.data.list;
        }
        var l = 140;
        var r = 0;
        var list = [[], []];
        d.forEach(function (m, i) {
          if (i % 2 == 0) {
            list[0].push(m);
          } else {
            list[1].push(m);
          }
        });
        _this4.tableData.data = list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;

        // this.workList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
      // worksList(this.queryParams)
      //   .then(res => {
      //   })
      //   .catch(res => {
      //     // this.$message.error(res.message);
      //     this.listLoading = false;
      //   });
    },
    addAction: function addAction() {
      // 新增
      var url = '';
      switch (Number(this.changeType)) {
        case 1:
          url = '/merchant/digitalCollection/works/list';
          break;
        case 2:
          url = '/merchant/digitalCollection/works/list';
          break;
        case 3:
          url = '/merchant/product/list';
          break;
        case 4:
          url = '/merchant/pageManagement/auction/meeting';
          break;
        case 5:
          url = '/merchant/config/picture';
          break;
        case 6:
          url = '/merchant/live/manage/list';
          break;
      }
      window.open(url);
    },
    exhibitionOne: function exhibitionOne() {
      this.initexhibitionOne();
    },
    initexhibitionOne: function initexhibitionOne() {
      var _this5 = this;
      var videoPattern = /\.(mp4|webm|avi|mov|flv|wmv|mkv)$/i;
      var audioPattern = /\.(mp3|ogg|wav|aac)$/i;
      var glftPattern = /\.(glb|gltf)$/i;
      var gifPattern = /\.(gif)$/i;
      if (!willpushplaceholder.length) {
        return this.$message.error('您尚未选择作品');
      }
      var loading = this.$loading({
        lock: true,
        text: '布展中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(function () {
        loading.close();
      }, 4000);
      this.showwork = false;
      var placeempty = placeholderjson.filter(function (item) {
        return item.isused === false;
      });
      console.log(placeempty);
      willpushplaceholder.forEach(function (item, index) {
        if (placeempty[index]) {
          placeholdercanuselength -= 1;
          _this5.surplusnum = placeholdercanuselength;
          var placeemptyindex = placeempty[index].placeindex;
          placeholderjson[placeemptyindex].isused = true;
          if (glftPattern.test(item.file_url)) {
            _this5.initDrawsgltf(placeholderarr[placeemptyindex], item, true, placeemptyindex);
          } else if (videoPattern.test(item.file_url)) {
            setTimeout(function () {
              _this5.initDrawsvideo(placeholderarr[placeemptyindex], item, true, placeemptyindex);
            }, index * 10);
          } else if (audioPattern.test(item.file_url)) {
            setTimeout(function () {
              _this5.initDrawsaudio(placeholderarr[placeemptyindex], item, true, placeemptyindex);
            }, index * 10);
          } else if (gifPattern.test(item.file_url)) {
            _this5.initDrawsgif(placeholderarr[placeemptyindex], item, true, placeemptyindex);
          } else {
            _this5.initDraws(placeholderarr[placeemptyindex], item, true, placeemptyindex);
          }
        }
      });
      willpushplaceholder = [];
    },
    initDrawsgltf: function initDrawsgltf(child, work, isused, index) {
      var gltfloader = new _GLTFLoader.GLTFLoader();
      // 场景
      gltfloader.load(work.file_url, function (gltf) {
        var object = gltf.scene.children[0];
        var parent = new THREE.Object3D();
        parent.add(object); // 将小模型添加到父物体中

        object.traverse(function (child) {
          // if (child.isMesh) {
          child.userData.type = 'gltfchild';
          child.userData.parent = parent;
          parent.children.push(child); // 将所有的子组件添加到父物体的属性中
          // }
        });
        parent.userData.work = work;
        parent.userData.file_url = work.file_url;
        if (isused) {
          parent.userData.isusePlaceholder = true;
          parent.userData.placeholderindex = index;
        }
        parent.name = 'initdrawgltf';
        parent.position.set(child.position.x, child.position.y, child.position.z);
        parent.rotation.set(child.rotation.x, child.position.y, child.rotation.z);
        parent.quaternion.set(child.quaternion.x, child.quaternion.y, child.quaternion.z, child.quaternion.w);
        parent.scale.set(child.scale.x, child.scale.y, child.scale.z);
        scene.add(parent); // 将父物体添加到场景中
        photoMesh.push(parent);
        controls.attach(parent);
        var light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(0, 1, 1).normalize();
        scene.add(light);
      }, function (xhr) {
        console.log(xhr.loaded / xhr.total * 100 + '% loaded');
      }, function () {
        console.log('An error happened');
      });
    },
    initDrawsvideo: function initDrawsvideo(child, work, isused, index) {
      var videourl = work.file_url;
      var dateid = +new Date() + '' + Math.floor(Math.random() * 10000);
      this.videoinfoarr.push({
        id: dateid,
        src: videourl
      });
      setTimeout(function () {
        var video = document.getElementById('video' + dateid);
        var texture = new THREE.VideoTexture(video);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.format = THREE.RGBAFormat;
        texture.needsUpdate = true;
        texture.encoding = THREE.sRGBEncoding;
        videotextures.push(texture);
        var width = 2;
        var height = 2;
        if (video.videoWidth && video.videoHeight) {
          height = 2 / video.videoWidth * video.videoHeight;
        }
        // 创建一个 PlaneGeometry 对象，并使用 video 元素作为纹理
        // const geometry = new THREE.PlaneGeometry(2, 2);
        var geometry = new THREE.PlaneGeometry(width, height);
        var material;
        if (!child.isdelScreen) {
          material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide,
            transparent: true
          });
        } else {
          // 创建 ChromaKey 材质
          material = new THREE.ShaderMaterial({
            uniforms: {
              chromaKeyColor: {
                type: 'c',
                value: new THREE.Color(0x00ff00)
              },
              pointTexture: {
                value: texture
              },
              time: {
                value: 1.0
              }
            },
            vertexShader: "\n                      varying vec2 vUv;\n\n                      void main() {\n                      vUv = uv;\n                      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);\n                      }\n                  ",
            fragmentShader: "\n                      uniform sampler2D pointTexture;\n                      uniform vec3 chromaKeyColor;\n                      varying vec2 vUv;\n                      void main() {\n                          vec4 color = texture2D(pointTexture, vUv);\n                          float chromaDistance = distance(color.rgb, chromaKeyColor);\n                          if (chromaDistance < 0.8) {\n                              discard;\n                          }\n                          gl_FragColor = vec4(color.rgb, 1.0);\n                      }\n                  ",
            side: THREE.DoubleSide
          });
        }
        var mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(child.position.x, child.position.y, child.position.z);
        mesh.rotation.set(child.rotation.x, child.position.y, child.rotation.z);
        mesh.quaternion.set(child.quaternion.x, child.quaternion.y, child.quaternion.z, child.quaternion.w);
        mesh.scale.set(child.scale.x, child.scale.y, child.scale.z);
        mesh.name = 'initdrawsvideo';
        mesh.userData.isdelScreen = child.isdelScreen || false;
        photoMesh.push(mesh);
        work.isdelScreen = child.isdelScreen || false;
        mesh.userData.work = work;
        mesh.userData.type = 'video';
        mesh.userData.dateid = dateid;
        if (isused) {
          mesh.userData.isusePlaceholder = true;
          mesh.userData.placeholderindex = index;
        }
        scene.add(mesh);
        videomesharr.push(mesh);
        // videosources.push(gainNode);
        videoarr.push(video);
        document.addEventListener('click', function () {
          if (video.paused) {
            // 创建一个 AudioContext 对象，用于控制音频
            var _audioContext = new AudioContext();
            // 创建一个 GainNode 对象，并将其连接到音频输出的目标节点
            var _gainNode = _audioContext.createGain();
            _gainNode.connect(_audioContext.destination);
            _gainNode.id = dateid;
            // 创建一个 MediaElementAudioSourceNode 对象，并将 HTMLVideoElement 对象作为输入端口
            var source = _audioContext.createMediaElementSource(video);
            // 将 MediaElementAudioSourceNode 对象连接到 GainNode 对象的输入端口
            source.connect(_gainNode);
            video.play();
            videosources.push(_gainNode);
          }
        });
      }, 500);
    },
    initDrawsaudio: function initDrawsaudio(child, work, isused, index) {
      var audiourl = work.file_url;
      // 加载音频文件
      var listener = new THREE.AudioListener();
      var sound = new THREE.PositionalAudio(listener);
      var audioLoader = new THREE.AudioLoader();
      audioLoader.load(audiourl, function (buffer) {
        sound.setBuffer(buffer);
        sound.setRefDistance(5);
        sound.setLoop(true);
        // sound.setVolume(1);
        sound.play();
        sound.name = 'audiosound';
      });
      var img = work.file_cover + '!m370';
      var imgtextarea = new THREE.TextureLoader().load(img, function (texture) {
        var SIZE = 2;
        // console.log(texture);
        var img = imgtextarea.image;
        var height = img && img.height || SIZE;
        var width = img && img.width || SIZE;
        height = SIZE / width * height;
        width = SIZE;
        // imgtextarea.flipY = false;
        imgtextarea.encoding = THREE.sRGBEncoding;
        var mat = new THREE.MeshBasicMaterial({
          map: imgtextarea,
          side: THREE.DoubleSide,
          transparent: true
        });
        var geom = new THREE.BoxGeometry(width, height, 0.05);
        var mesh = new THREE.Mesh(geom, mat);
        mesh.position.set(child.position.x, child.position.y, child.position.z);
        mesh.rotation.set(child.rotation.x, child.position.y, child.rotation.z);
        mesh.quaternion.set(child.quaternion.x, child.quaternion.y, child.quaternion.z, child.quaternion.w);
        mesh.scale.set(child.scale.x, child.scale.y, child.scale.z);
        mesh.name = 'initdrawaudio';
        mesh.userData.type = 'audio';
        mesh.userData.work = work;
        if (isused) {
          mesh.userData.isusePlaceholder = true;
          mesh.userData.placeholderindex = index;
        }
        photoMesh.push(mesh);
        mesh.add(sound);
        scene.add(mesh);
        // console.log(mesh);
      }, function (progress) {
        console.log(progress);
      }, function (err) {
        console.log(err);
      });
      // 将摄像机指定为监听器
      camera.add(listener);
    },
    initDrawsgif: function initDrawsgif(child, work, isused, index) {
      var _this6 = this;
      var gifURL = work.file_cover + '!gif330';
      var imgdom = document.createElement('img');
      setTimeout(function () {
        imgdom.onload = function () {
          var sup2 = new _libgif.default({
            gif: imgdom,
            show_progress_bar: false,
            progressbar_height: 0
          });
          sup2.load();
          var llamacanvas = sup2.get_canvas();
          var dispTexture = new THREE.Texture(llamacanvas);
          dispTexture.encoding = THREE.sRGBEncoding;
          var material = new THREE.MeshBasicMaterial({
            map: dispTexture,
            side: THREE.DoubleSide,
            transparent: true
          });
          var width = 2;
          var height = 2;
          if (_this6.width && _this6.height) {
            height = 2 / _this6.width * _this6.height;
          }
          var geom = new THREE.BoxGeometry(width, height, 0.05);
          var mesh = new THREE.Mesh(geom, material);
          mesh.position.set(child.position.x, child.position.y, child.position.z);
          mesh.rotation.set(child.rotation.x, child.position.y, child.rotation.z);
          mesh.quaternion.set(child.quaternion.x, child.quaternion.y, child.quaternion.z, child.quaternion.w);
          mesh.scale.set(child.scale.x, child.scale.y, child.scale.z);
          mesh.name = 'initdrawgif';
          mesh.userData.work = work;
          if (isused) {
            mesh.userData.isusePlaceholder = true;
            mesh.userData.placeholderindex = index;
          }
          photoMesh.push(mesh);
          scene.add(mesh);
          dispTexture.needsUpdate = true;
          giftextureArr.push(dispTexture);
        };
        imgdom.src = gifURL;
      }, 300);
    },
    initDraws: function initDraws(child, work, isused, isusedindex) {
      // if (index >= exhibitionjson.works.length) {
      //   return;
      // }
      if (!work) {
        return;
      }
      var sindex = index;
      index = index + 1;
      console.log(work, 1);
      var img = work.file_cover + '!m640';
      if (work.product_info) {
        img = work.product_info.image + '!m640';
      } else if (work.product) {
        img = work.product.image + '!m640';
      }
      var imgtextarea = new THREE.TextureLoader().load(img, function (texture) {
        var SIZE = 2;
        // console.log('这是加载图片模型信息');
        // console.log(texture);
        var img = imgtextarea.image;
        var height = img && img.height || SIZE;
        var width = img && img.width || SIZE;
        height = SIZE / width * height;
        width = SIZE;
        imgtextarea.encoding = THREE.sRGBEncoding;
        // imgtextarea.flipY = false;
        var mat = new THREE.MeshBasicMaterial({
          map: imgtextarea,
          side: THREE.DoubleSide,
          transparent: true
        });
        var geom = new THREE.BoxGeometry(width, height, 0.05);
        var mesh = new THREE.Mesh(geom, mat);
        mesh.position.set(child.position.x, child.position.y, child.position.z);
        mesh.rotation.set(child.rotation.x, child.position.y, child.rotation.z);
        mesh.quaternion.set(child.quaternion.x, child.quaternion.y, child.quaternion.z, child.quaternion.w);
        mesh.scale.set(child.scale.x, child.scale.y, child.scale.z);
        mesh.name = child.name + ' copy';
        console.log(mesh, 2);
        if (isused) {
          mesh.userData.isusePlaceholder = true;
          mesh.userData.placeholderindex = isusedindex;
        }
        photoMesh.push(mesh);
        mesh.userData.work = work;
        mesh.workindex = sindex;
        scene.add(mesh);
      }, function (progress) {
        console.log(progress);
      }, function (err) {
        // alert(err);
        console.log(err);
      });
    },
    init: function init() {
      this.createScene();
      this.createObjects();
      this.createPlayer();
      this.createCamera();
      this.createLights();
      this.createEvents();
      render();
    },
    createScene: function createScene() {
      var _this7 = this;
      renderer = new THREE.WebGLRenderer({
        antialias: true,
        enableDamping: false
      });
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      // renderer.shadowMap.enabled = true
      // renderer.shadowMap.type = THREE.PCFSoftShadowMap

      // 透视相机
      camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 5000);
      scene = new THREE.Scene();
      // 创建一个 TransformControls 对象
      controls = new _TransformControls.TransformControls(camera, renderer.domElement);
      controls.addEventListener('mouseDown', function () {
        controlsMouseDownFlag = true;
      });
      // controls.setMode("rotate")
      // 添加控制器到场景中
      scene.add(controls);
      if (skyimage) {
        var textureLoader = new THREE.TextureLoader();
        var txture = textureLoader.load(skyimage);
        scene.background = txture;
      }
      var container = document.querySelector('#container');
      container.appendChild(renderer.domElement);

      // window.addEventListener("resize", onResize);
      if (!ispc) {
        // 判断手机横竖屏状态
        window.addEventListener('onorientationchange' in window ? 'orientationchange' : 'resize', function () {
          if (window.orientation === 180 || window.orientation === 0) {
            setTimeout(function () {
              onResize();
            }, 200);
            _this7.direction = 'column';
            _this7.nftid = '';
          }
          if (window.orientation === 90 || window.orientation === -90) {
            setTimeout(function () {
              onResize();
            }, 200);
            _this7.direction = 'ver';
            _this7.nftid = '';
          }
        }, false);
      } else {
        window.addEventListener('resize', onResize);
      }
    },
    createObjects: function createObjects() {
      var _this8 = this;
      var loader = new _GLTFLoader.GLTFLoader();
      // 配置 DracoLoader
      var dracoLoader = new _DRACOLoader.DRACOLoader();
      dracoLoader.setDecoderPath("/");
      loader.setDRACOLoader(dracoLoader);
      var floor = null;
      if (!selectedModel) {
        return;
      }
      var loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // 场景
      loader.load(selectedModel.template_url, function (gltf) {
        console.log(gltf);
        setTimeout(function () {
          loading.close();
        }, 2000);
        player.position.set(0, 0, 0);
        _this8.loadingprograss = 100;
        setTimeout(function () {
          _this8.signFixedFlag = true;
          _this8.loadingflag = false;
        }, 1000);
        gltf.scene.traverse(function (child) {
          if (child.name.includes('collider')) {
            // colliders.push(child);
            child.visible = false;
          } else if (child.name.includes('invisible')) {
            // colliders.push(child);
            child.visible = false;
          } else if (child.parent && child.parent.name.toLowerCase() == 'placeholder') {
            if (!child.type.includes('Object3D')) {
              // initDraws(child);
              // drawfont(child);
            }
            child.visible = false;
          } else if (child.name.includes('Placeholder') || child.name.includes('placeholder') || child.name.includes('Object') || child.name.includes('Cube') || child.name.includes('neftplace')) {
            // if(child.children.length == 0){
            //   initDraws(child);
            // }
            // child.visible = false;
          } else if (child.name.includes('Camera')) {
            // camera.position.set(child.position.x, child.position.y, child.position.z);
          } else if (child.name.includes('portal')) {
            // child.visible = false;
            // player.position.set(child.position.x, child.position.y, child.position.z)
            // camera.position.set(child.position.x, child.position.y, child.position.z);
          } else if (child.name.includes('floor')) {
            // console.log(child);
            floor = child;
            child.visible = true;
          }
          if (child.isMesh) {
            child.frustumCulled = true;
            if (!child.name.includes("Placeholder") && !child.name.includes("placeholder")) {
              scenearrmesh.push(child);
            }
          }
        });
        gltf.scene.children.forEach(function (item) {
          if (item.name.includes('placeholder-lock')) {
            lockpoint = item.children;
            item.visible = false;
          } else if (item.name.toLowerCase() == 'placeholder') {
            placeholderarr = item.children;
            _this8.placeholderlength = item.children.length;
            placeholdercanuselength = item.children.length;
            _this8.surplusnum = placeholdercanuselength;
            item.children.forEach(function (item, placeindex) {
              placeholderjson.push({
                placeindex: placeindex,
                name: item.name,
                isused: false
              });
            });
          }
          if (!item.name.includes('Placeholder') && !item.name.includes('placeholder') && !item.name.includes('placeholder-lock') && !item.name.includes('placeholderlock') && !item.name.includes('Object') && !item.name.includes('Cube') && !item.name.includes('Camera') && !item.name.includes('portal')) {
            scenearr.push(item);
          }
        });
        gltf.scene.children = scenearr;
        var videoPattern = /\.(mp4|webm|avi|mov|flv|wmv|mkv)$/i;
        var audioPattern = /\.(mp3|ogg|wav|aac)$/i;
        var glftPattern = /\.(glb|gltf)$/i;
        var gifPattern = /\.(gif)$/i;
        var workjson = {};
        if (configjson) {
          if (artworklist && artworklist.length) {
            artworklist.forEach(function (item, index) {
              if (item.work_type == 5) {
                item.product_info = {
                  image: item.attachment_info.attachment_src,
                  product_id: item.attachment_info.attachment_id,
                  store_name: item.attachment_info.attachment_name
                };
              } else if (item.work_type == 6) {
                item.product_info = {
                  image: item.live_info.cover_image,
                  product_id: item.product_id,
                  store_name: item.live_info.live_title
                };
              }
              item.product_info.work_type = item.work_type;
              item.product = item.product_info;
              workjson[item.product_id] = item;
            });
          }
          console.log(artworklist);
          console.log(workjson);
          console.log(configjson);
          configjson.forEach(function (item, index) {
            item.rotation.x = item.rotation._x || item.rotation.x || 0;
            item.rotation.y = item.rotation._y || item.rotation.y || 0;
            item.rotation.z = item.rotation._z || item.rotation.z || 0;
            item.quaternion.x = item.quaternion._x || item.quaternion.x || 0;
            item.quaternion.y = item.quaternion._y || item.quaternion.y || 0;
            item.quaternion.z = item.quaternion._z || item.quaternion.z || 0;
            item.quaternion.w = item.quaternion._w || item.quaternion.w || 0;
            if (item.isusePlaceholder) {
              placeholdercanuselength -= 1;
              _this8.surplusnum = placeholdercanuselength;
              placeholderjson[item.placeholderindex].isused = true;
            }
            if (glftPattern.test(item.file_url)) {
              _this8.initDrawsgltf(item, item.work, item.isusePlaceholder, item.placeholderindex);
            } else if (videoPattern.test(item.file_url)) {
              setTimeout(function () {
                _this8.initDrawsvideo(item, workjson[item.work.product_id], item.isusePlaceholder, item.placeholderindex);
              }, index * 10);
            } else if (audioPattern.test(item.file_url)) {
              setTimeout(function () {
                _this8.initDrawsaudio(item, workjson[item.work.product_id], item.isusePlaceholder, item.placeholderindex);
              }, index * 10);
            } else if (gifPattern.test(item.file_url)) {
              _this8.initDrawsgif(item, workjson[item.work.product_id], item.isusePlaceholder, item.placeholderindex);
            } else {
              _this8.initDraws(item, workjson[item.work.product_id], item.isusePlaceholder, item.placeholderindex);
            }
          });
        } else {
          if (artworklist && artworklist.length) {
            artworklist.forEach(function (item, index) {
              if (item.work_type == 5) {
                item.product_info = {
                  image: item.attachment_info.attachment_src,
                  product_id: item.attachment_info.attachment_id,
                  store_name: item.attachment_info.attachment_name
                };
              } else if (item.work_type == 6) {
                item.product_info = {
                  image: item.live_info.cover_image,
                  product_id: item.product_id,
                  store_name: item.live_info.live_title
                };
              }
              item.product_info.work_type = item.work_type;
              item.product = item.product_info;
              if (placeholderarr[index]) {
                placeholderjson[index].isused = true;
                placeholdercanuselength -= 1;
                _this8.surplusnum = placeholdercanuselength;
                console.log(placeholdercanuselength);
                if (glftPattern.test(item.file_url)) {
                  _this8.initDrawsgltf(placeholderarr[index], item, true, index);
                } else if (videoPattern.test(item.file_url)) {
                  setTimeout(function () {
                    _this8.initDrawsvideo(placeholderarr[index], item, true, index);
                  }, index * 10);
                } else if (audioPattern.test(item.file_url)) {
                  setTimeout(function () {
                    _this8.initDrawsaudio(placeholderarr[index], item, true, index);
                  }, index * 10);
                } else if (gifPattern.test(item.file_url)) {
                  _this8.initDrawsgif(placeholderarr[index], item, true, index);
                } else {
                  _this8.initDraws(placeholderarr[index], item, true, index);
                }
              }
            });
          }
        }
        var roomEnvironment = new _RoomEnvironment.RoomEnvironment();
        var pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();
        scene.environment = pmremGenerator.fromScene(roomEnvironment).texture;
        scene.add(gltf.scene);
        mixer = new THREE.AnimationMixer(gltf.scene);
        // 同时将这个外部模型的动画全部绑定到动画混合器里面
        for (var i = 0; i < gltf.animations.length; i++) {
          mixer.clipAction(gltf.animations[i]).play();
        }
      }, function (xhr) {
        _this8.loadingprograss = parseInt(xhr.loaded / xhr.total * 100);
        // loading.message = parseInt( xhr.loaded / xhr.total * 100 )+'%\n加载中';
        // console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
      }, function (err) {
        console.log(err);
        console.log('An error happened');
      });
    },
    createPlayer: function createPlayer() {
      var geometry = new THREE.BoxGeometry(1, 2, 1);
      var material = new THREE.MeshBasicMaterial({
        // color: 0xff0000,
        // wireframe: true,
      });
      player = new THREE.Mesh(geometry, material);
      player.name = 'player';
      geometry.translate(0, 1, 0);
      player.position.set(-5, 0, 5);
      // scene.add(player)
    },
    createCamera: function createCamera() {
      var back = new THREE.Object3D();
      back.position.set(0, 2, 1);
      back.parent = player;
      // player.add(back)
      activeCamera = back;
      var right = new THREE.Object3D();
      right.position.set(2, 2, 1);
      right.parent = player;
      rightCamera = right;
    },
    createLights: function createLights() {
      ambientLight = new THREE.AmbientLight(0xe0ffff, 0.6);
      scene.add(ambientLight);
    },
    createEvents: function createEvents() {
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
    },
    touchstart: function touchstart(event) {
      // 获得起点X坐标，初始化distance为0
      this.touchStartPoint = (0, _index3.getMousePosition)(event).x;
      this.startY = (0, _index3.getMousePosition)(event).y;
      startx1 = (0, _index3.getMousePosition)(event).x1 || 0;
      starty1 = (0, _index3.getMousePosition)(event).y1 || 0;
      this.distance = 0;
      this.distanceY = 0;
      distancex1 = 0;
      distancey1 = 0;
      this.startmove = true;
      starttime = new Date();
      if (!ispc) {
        startTimeOut = setTimeout(function () {
          var end = new Date();
          if (end - starttime) {
            move.forward = 1;
          }
        }, 100);
      }
    },
    onmodelclick: function onmodelclick(event) {
      if (this.showwork) {
        this.showwork = false;
      }
      mouse.x = (event.targetTouches ? event.targetTouches[0].pageX : event.clientX) / window.innerWidth * 2 - 1;
      mouse.y = -((event.targetTouches ? event.targetTouches[0].pageY : event.clientY) / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(photoMesh, true);
      console.log(intersects);
      var intersectspoint = function () {
        if (controlPoints && controlPoints.length) {
          return raycaster.intersectObjects(controlPoints);
        } else {
          return [];
        }
      }();
      if (intersects[0]) {
        startvecor = intersects[0].point.clone();
        if (intersectspoint && intersectspoint[0]) {
          dragpointflag = true;
        }
        console.log(selectedMesh);
        if (selectedMesh && intersects[0].object.uuid === selectedMesh.uuid && !this.controlsIsUsed) {
          dragflag = true;
        }
        // showwork.value = true;
      } else {
        // showwork.value = false;
        // showworkflag.value = false;
        // controls.detach();
        // selectedMesh = null;
      }
    },
    onModelMouseUp: function onModelMouseUp(event) {
      if (scenemove) {
        scenemove = false;
        return;
      }
      if (controlsMouseDownFlag) {
        controlsMouseDownFlag = false;
      } else {
        mouse.x = (event.targetTouches ? event.targetTouches[0].pageX : event.clientX) / window.innerWidth * 2 - 1;
        mouse.y = -((event.targetTouches ? event.targetTouches[0].pageY : event.clientY) / window.innerHeight) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);
        var arr = scenearrmesh.filter(function (item) {
          return !item.name.includes('invisible') && !item.name.includes("glass") && !item.name.includes("light");
        });
        arr = arr.concat(photoMesh);
        var intersects = raycaster.intersectObjects(arr, true);
        console.log(intersects);
        // const intersects = raycaster.intersectObjects(photoMesh, true);
        if (intersects && intersects[0] && (intersects[0].object.name.includes('drag') || intersects[0].object.name.includes('copy') || intersects[0].object.name.includes('initdraw') || intersects[0].object.name.includes('placeholder'))) {
          photoMesh.forEach(function (item) {
            if (item.material && item.material.color) {
              item.material.color.set(0xffffff);
            }
          });
          if (intersects[0].object && intersects[0].object.userData && intersects[0].object.userData.type && intersects[0].object.userData.type.includes('gltf')) {
            selectedMesh = intersects[0].object.userData.parent;
          } else {
            selectedMesh = intersects[0].object;
            if (selectedMesh.material && selectedMesh.material.color) {
              selectedMesh.material.color.set(0x00ff00);
            }
          }
          if (selectedMesh.userData && selectedMesh.userData.scale) {
            this.slidervalue = selectedMesh.userData.scale * 10;
          } else if (selectedMesh.scale) {
            this.slidervalue = selectedMesh.scale.x * 10;
          } else {
            this.slidervalue = 10;
          }
          // createControlPoints(selectedMesh);
          this.showworkflag = true;
          if (selectedMesh.userData && selectedMesh.userData.work) {
            this.workdetail = selectedMesh.userData.work;
          }
          if (this.controlsIsUsed) {
            controls.attach(selectedMesh);
          }
        } else if (intersects && intersects[1] && (intersects[1].object.name.includes('drag') || intersects[1].object.name.includes('copy') || intersects[1].object.name.includes('initdraw') || intersects[1].object.name.includes('placeholder'))) {
          if (intersects[1].distance - intersects[0].distance < 0.001) {
            photoMesh.forEach(function (item) {
              if (item.material && item.material.color) {
                item.material.color.set(0xffffff);
              }
            });
            if (intersects[1].object && intersects[1].object.userData && intersects[1].object.userData.type && intersects[1].object.userData.type.includes('gltf')) {
              selectedMesh = intersects[1].object.userData.parent;
            } else {
              selectedMesh = intersects[1].object;
              if (selectedMesh.material && selectedMesh.material.color) {
                selectedMesh.material.color.set(0x00ff00);
              }
            }
            if (selectedMesh.userData && selectedMesh.userData.scale) {
              this.slidervalue = selectedMesh.userData.scale * 10;
            } else {
              this.slidervalue = 10;
            }
            // createControlPoints(selectedMesh);
            this.showworkflag = true;
            if (selectedMesh.userData && selectedMesh.userData.work) {
              this.workdetail = selectedMesh.userData.work;
            }
            if (this.controlsIsUsed) {
              controls.attach(selectedMesh);
            }
          }
        } else {
          this.showwork = false;
          this.showworkflag = false;
          controls.detach();
          if (selectedMesh) {
            if (selectedMesh.material && selectedMesh.material.color) {
              selectedMesh.material.color.set(0xffffff);
            }
          }
          selectedMesh = null;
        }
      }
    },
    changeslide: function changeslide(event) {
      console.log(this.slidervalue);
      selectedMesh.userData.scale = this.slidervalue / 10;
      selectedMesh.scale.set(this.slidervalue / 10, this.slidervalue / 10, this.slidervalue / 10);
    },
    touchmove: function touchmove(event) {
      event.preventDefault();
      if (controls && controls.dragging) {
        return;
      }
      if (dragflag) {
        if (dragpointflag) {
          var _index = controlPoints.indexOf(event.target);
          var delta = new THREE.Vector3();
          delta.x = (event.clientX - event.target.offsetLeft) * 2 / window.innerWidth - 1;
          delta.y = -(event.clientY - event.target.offsetTop) * 2 / window.innerHeight + 1;
          delta.z = 0;

          // 计算mesh的缩放比例
          var bbox = new THREE.Box3().setFromObject(selectedMesh);
          var size = bbox.getSize(new THREE.Vector3());
          var scale = new THREE.Vector3((size.x + delta.x) / size.x, (size.y + delta.y) / size.y, (size.z + delta.z) / size.z);
          // 缩放mesh并更新控制点位置
          selectedMesh.scale.copy(scale);
          return;
        }
        mouse.x = (event.targetTouches ? event.targetTouches[0].pageX : event.clientX) / window.innerWidth * 2 - 1;
        mouse.y = -((event.targetTouches ? event.targetTouches[0].pageY : event.clientY) / window.innerHeight) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);
        var arr = scenearr.filter(function (item) {
          return !item.name.includes('placeholder') && !item.name.includes('invisible');
        });
        var intersects = raycaster.intersectObjects(arr, true);
        if (intersects[0]) {
          var intersection = intersects[0].point.clone();
          var selectmeshpoint = selectedMesh.position.clone();
          // console.log(startvecor);
          // console.log(selectmeshpoint);
          // selectedMesh.position.copy(intersection);

          // selectedMesh.scale.set(1,1,1);
          if (intersects[0].object.name.includes('floor')) {
            selectedMesh.rotation.y = 0;
            // 获取模型面的法向量
            var normal = intersects[0].face.normal;

            // 计算图片与模型面的旋转角度
            var up = new THREE.Vector3(0, 0, 1);
            var axis = new THREE.Vector3();
            axis.crossVectors(up, normal).normalize();
            var angle = Math.acos(up.dot(normal));

            // 使用四元数旋转图片
            var quaternion = new THREE.Quaternion();
            quaternion.setFromAxisAngle(axis, angle);
            selectedMesh.quaternion.copy(quaternion);
            selectedMesh.position.copy({
              x: selectmeshpoint.x + (intersection.x - startvecor.x),
              y: intersection.y,
              z: selectmeshpoint.z + (intersection.z - startvecor.z)
            });
          } else {
            // 获取第一个相交的对象
            var intersect = intersects[0];

            // 获取相交的面的法向量
            var _normal = intersect.face.normal;
            // // 将相交点移动到模型面上
            // const point = intersect.point.clone();
            // point.add(normal);

            // 计算物体与模型面的旋转角度
            var normalMatrix = new THREE.Matrix3().getNormalMatrix(intersect.object.matrixWorld);
            _normal.applyMatrix3(normalMatrix);
            var _angle = Math.atan2(_normal.x, _normal.z);

            // 将物体旋转到与模型面平行的角度
            selectedMesh.rotation.y = -_angle;
            selectedMesh.rotation.x = 0;
            selectedMesh.rotation.z = 0;
            selectedMesh.position.copy({
              x: selectmeshpoint.x + (intersection.x - startvecor.x),
              y: selectmeshpoint.y + (intersection.y - startvecor.y),
              z: selectmeshpoint.z + (intersection.z - startvecor.z)
            });
          }
          startvecor = intersection;
          // const oldDirection = new THREE.Vector3();
          // if (!normal.equals(oldDirection)) {
          //   const quaternion = new THREE.Quaternion();
          //   quaternion.setFromUnitVectors(
          //     oldDirection.normalize(),
          //     normal.normalize()
          //   );

          //   selectedMesh.applyQuaternion(quaternion);
          // }
          // updateControlPoints();
        }
      } else {
        // 实时计算distance
        if ((0, _index3.getMousePosition)(event).x > 0) {
          this.distance = (0, _index3.getMousePosition)(event).x - this.touchStartPoint;
          this.distanceY = (0, _index3.getMousePosition)(event).y - this.startY;
        } else {
          this.distance = 0;
          this.distanceY = 0;
        }
        if ((0, _index3.getMousePosition)(event).x1 > 0) {
          distancex1 = (0, _index3.getMousePosition)(event).x1 - startx1;
          distancey1 = (0, _index3.getMousePosition)(event).y1 - starty1;
        } else {
          distancex1 = 0;
          distancey1 = 0;
        }
        if (this.startmove) {
          //   console.log(camera.position.lerp(
          //   activeCamera.getWorldPosition(new THREE.Vector3()),
          //   0.08
          // ))
          if (this.distance || this.distanceY) {
            scenemove = true;
          }
          if (Math.abs(this.distance) + Math.abs(distancex1) > 0) {
            player.rotateY((ispc ? 0.001 : -0.01) * (this.distance + distancex1));
          }
          lookAty += -((-this.distanceY - distancey1) / 1000);
          if (lookAty < 0) {
            lookAty = 0;
          } else if (lookAty > 4) {
            lookAty = 4;
          }
        }
        this.touchStartPoint = (0, _index3.getMousePosition)(event).x;
        this.startY = (0, _index3.getMousePosition)(event).y;
        startx1 = (0, _index3.getMousePosition)(event).x1 || 0;
        starty1 = (0, _index3.getMousePosition)(event).y1 || 0;
      }
    },
    touchend: function touchend(event) {
      this.touchStartPoint = 0;
      dragflag = false;
      dragpointflag = false;
      if (!ispc) {
        if (!event.targetTouches || event.targetTouches.length <= 0) {
          move.forward = 0;
          if (startTimeOut) clearTimeout(startTimeOut);
          startTimeOut = null;
          this.startmove = false;
        }
        if (event.targetTouches) {
          if (!event.targetTouches[0]) {
            this.touchStartPoint = 0;
            this.startY = 0;
            this.distance = 0;
            this.distanceY = 0;
          }
          if (!event.targetTouches[1]) {
            startx1 = 0;
            starty1 = 0;
            distancex1 = 0;
            distancey1 = 0;
          }
        }
      } else {
        this.startmove = false;
      }
    },
    drawfont: function drawfont(child) {
      var _this9 = this;
      var textGeometry;
      var textMaterial;
      var textMesh;
      if (fonto) {
        textGeometry = new THREE.TextGeometry(this.textvalue, {
          font: fonto,
          size: 0.2,
          height: 0.01,
          style: 'normal'
        });
        textMaterial = new THREE.MeshBasicMaterial({
          color: 0xffffff
        });
        child.material = textMaterial;
        textMesh = new THREE.Mesh(textGeometry, textMaterial);
        textMesh.position.set(child.position.x, child.position.y, child.position.z);
        textMesh.rotation.set(child.rotation.x, child.position.y, child.rotation.z);
        textMesh.quaternion.set(child.quaternion.x, child.quaternion.y, child.quaternion.z, child.quaternion.w);
        textMesh.name = child.name + ' copy';
        photoMesh.forEach(function (item) {
          if (item.name == child.name) {
            item = textMesh;
          }
        });
        scene.remove(child);
        scene.add(textMesh);
        this.showwork = false;
        // photoMesh.push(textMesh);
        // selectedMesh = textMesh;
        // scene.add(textMesh);
      } else {
        var loading = this.$message.loading({
          message: '操作中',
          forbidClick: true,
          duration: 0
        });
        var fontLoader = new THREE.FontLoader();
        fontLoader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', function (font) {
          fonto = font;
          textGeometry = new THREE.TextGeometry(_this9.textvalue, {
            font: font,
            size: 0.2,
            height: 0.01,
            style: 'normal'
          });
          textMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff
          });
          child.material = textMaterial;
          textMesh = new THREE.Mesh(textGeometry, textMaterial);
          textMesh.position.set(child.position.x, child.position.y, child.position.z);
          textMesh.rotation.set(child.rotation.x, child.position.y, child.rotation.z);
          textMesh.quaternion.set(child.quaternion.x, child.quaternion.y, child.quaternion.z, child.quaternion.w);
          textMesh.name = child.name + ' copy';
          photoMesh.forEach(function (item) {
            if (item.name == child.name) {
              item = textMesh;
            }
          });
          scene.remove(child);
          scene.add(textMesh);
          _this9.showwork = false;
          loading.close();
          // photoMesh.push(textMesh);

          // scene.add(textMesh);
        });
      }
    },
    changeselectmode: function changeselectmode(mode) {
      this.selectmode = mode;
    },
    addarttext: function addarttext() {
      // drawfont(selectedMesh);
      drawnewText();
      this.showwork = false;
    },
    savepublic: function savepublic() {
      var _this10 = this;
      var worklist = [];
      var workconfig = [];
      photoMesh.forEach(function (item) {
        if (item.userData && item.userData.work) {
          worklist.push({
            product_id: item.userData.work.product_id,
            work_type: item.userData.work.work_type || item.userData.work.works_type,
            work_id: item.userData.work.work_id || ''
          });
          workconfig.push({
            position: item.position,
            rotation: item.rotation,
            quaternion: item.quaternion,
            scale: item.scale,
            file_url: item.userData.work.product.image,
            file_cover: item.userData.work.product.image,
            work: item.userData.work ? {
              product_id: item.userData.work.product_id
            } : {},
            isdelScreen: item.userData.isdelScreen || false,
            isusePlaceholder: item.userData.isusePlaceholder || false,
            placeholderindex: item.userData.placeholderindex || 0
          });
        }
      });
      var savejson = {
        exhibition_template_id: selectedModel.exhibition_template_id,
        mer_temp_id: selectedModel.mer_temp_id,
        exhibition_title: this.space_name,
        exhibition_intro: this.space_intro,
        exhibition_desc: this.space_desc,
        works_data: worklist,
        config: {
          workconfig: JSON.stringify(workconfig)
        }
      };
      var action = _digitalHall.userExhibitionCreate;
      if (this.id) {
        action = _digitalHall.userExhibitionEdit;
        savejson.user_exhibition_id = this.id;
      }
      action(savejson).then(function (res) {
        _this10.$message.success('保存成功');
        // id.value = res.data.user_exhibition_id;
        // router.replace("/?id=" + id.value);
        // if (callback) callback();
      }).catch(function (error) {
        _this10.$message.error(error);
      });
    },
    imgdragstart: function imgdragstart(event, item) {
      console.log('drag');
      dragwork = item;
      this.showwork = false;
    },
    remove: function remove() {
      var index = -1;
      for (var i = 0; i < photoMesh.length; i++) {
        if (photoMesh[i] === selectedMesh) {
          index = i;
          break;
        }
      }
      this.showworkflag = false;
      if (index !== -1) {
        if (selectedMesh.userData && selectedMesh.userData.isusePlaceholder) {
          placeholdercanuselength += 1;
          this.surplusnum = placeholdercanuselength;
          placeholderjson[selectedMesh.userData.placeholderindex].isused = false;
          if (selectedMesh.userData.work) {
            var workproductid = selectedMesh.userData.work.product_id;
            for (var _i = 0; _i < this.nftlist.length; _i++) {
              if (this.nftlist[_i].product_id == workproductid) {
                this.nftlist[_i].selected = false;
                break;
              }
            }
          }
        }
        if (selectedMesh.userData.type == 'video') {
          for (var _i2 = 0; _i2 < videosources.length; _i2++) {
            if (selectedMesh.userData.dateid == videosources[_i2].id) {
              videosources[_i2].gain.value = 0;
              videosources.splice(_i2, 1);
            }
          }
          for (var _i3 = 0; _i3 < videoarr.length; _i3++) {
            videoarr[_i3].pause();
            videoarr[_i3].removeAttribute('src');
            videoarr[_i3].load();
            videoarr.splice(_i3, 1);
          }
          scene.remove(selectedMesh);
          photoMesh.splice(index, 1);
          controls.detach();
        } else if (selectedMesh.userData.type == 'audio') {
          var audio = selectedMesh.getObjectByName('audiosound'); // 根据名称获取PositionalAudio
          selectedMesh.remove(audio); // 从mesh中移除PositionalAudio
          audio.stop(); // 停止音频
          audio.disconnect(); // 断开音频连接
          scene.remove(selectedMesh);
          photoMesh.splice(index, 1);
          controls.detach();
        } else {
          scene.remove(selectedMesh);
          photoMesh.splice(index, 1);
          controls.detach();
        }
      }
    },
    changeaddwork: function changeaddwork() {
      this.showwork = true;
      controls.detach();
      this.showworkflag = false;
    },
    changedelScreen: function changedelScreen() {
      var loading = this.$message.loading({
        message: '处理中',
        forbidClick: true,
        duration: 0
      });
      setTimeout(function () {
        loading.close();
      }, 2000);
      if (!this.workdetail.isdelScreen) {
        this.workdetail.isdelScreen = true;
        if (selectedMesh.userData.type == 'video') {
          for (var i = 0; i < videomesharr.length; i++) {
            if (videomesharr[i].uuid == selectedMesh.uuid) {
              var texture = videotextures[i];
              // 创建 ChromaKey 材质
              var chromaKeyMaterial = new THREE.ShaderMaterial({
                uniforms: {
                  chromaKeyColor: {
                    type: 'c',
                    value: new THREE.Color(0x00ff00)
                  },
                  pointTexture: {
                    value: texture
                  },
                  time: {
                    value: 1.0
                  }
                },
                vertexShader: "\n                          varying vec2 vUv;\n\n                          void main() {\n                          vUv = uv;\n                          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);\n                          }\n                      ",
                fragmentShader: "\n                          uniform sampler2D pointTexture;\n                          uniform vec3 chromaKeyColor;\n                          varying vec2 vUv;\n                          void main() {\n                              vec4 color = texture2D(pointTexture, vUv);\n                              float chromaDistance = distance(color.rgb, chromaKeyColor);\n                              if (chromaDistance < 0.8) {\n                                  discard;\n                              }\n                              gl_FragColor = vec4(color.rgb, 1.0);\n                          }\n                      ",
                side: THREE.DoubleSide
              });
              selectedMesh.material = chromaKeyMaterial;
              break;
            }
          }
          selectedMesh.userData.isdelScreen = true;
        }
      } else {
        this.workdetail.isdelScreen = false;
        if (selectedMesh.userData.type == 'video') {
          selectedMesh.userData.isdelScreen = true;
          for (var _i4 = 0; _i4 < videomesharr.length; _i4++) {
            if (videomesharr[_i4].uuid == selectedMesh.uuid) {
              var _texture = videotextures[_i4];
              var material = new THREE.MeshBasicMaterial({
                map: _texture,
                side: THREE.DoubleSide,
                transparent: true
              });
              selectedMesh.material = material;
              break;
            }
          }
        }
      }
    },
    selectedWork: function selectedWork(item) {
      console.log(placeholdercanuselength);
      if (item.selected) {
        var arr = willpushplaceholder.map(function (item) {
          return item.product_id;
        });
        var _index2 = arr.indexOf(item.product_id);
        if (_index2 >= 0) {
          item.selected = false;
          willpushplaceholder.splice(_index2, 1);
        }
      } else {
        if (willpushplaceholder.length >= placeholdercanuselength) {
          return this.$message.error('提示默认展品数量已达上限，如需继续添加作品请用拖拽方式');
        }
        item.selected = true;
        willpushplaceholder.push(item);
      }
    },
    imgdragend: function imgdragend(event) {
      console.log('dragend');
      console.log(dragwork);
      var newdragwork = JSON.parse(JSON.stringify(dragwork));
      this.placeWorksList = this.placeWorksList.concat(newdragwork);
      this.placeWorksIds = this.placeWorksList.map(function (m) {
        return m.product_id;
      });
      var videoPattern = /\.(mp4|webm|avi|mov|flv|wmv|mkv)$/i;
      var audioPattern = /\.(mp3|ogg|wav|aac)$/i;
      var glftPattern = /\.(glb|gltf)$/i;
      var gifPattern = /\.(gif)$/i;
      if (glftPattern.test(dragwork.file_url)) {
        var loading = this.$message.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        });
        drawsdraggltf(event, dragwork, loading);
        dragwork = null;
        return;
      }
      if (gifPattern.test(dragwork.file_url)) {
        drawdraggif(event, dragwork);
        dragwork = null;
        return;
      }
      if (videoPattern.test(dragwork.file_url)) {
        drawdragvideo(event, dragwork);
        dragwork = null;
        return;
      }
      if (audioPattern.test(dragwork.file_url)) {
        drawdrawaudio(event, dragwork);
        dragwork = null;
        return;
      }
      drawsdragimg(event, dragwork);
      dragwork = null;
    },
    changecontrolsIsUsed: function changecontrolsIsUsed(event) {
      if (this.controlsIsUsed == true) {
        this.controlsIsUsed = false;
        controls.detach();
      } else {
        this.controlsIsUsed = true;
        if (selectedMesh) {
          controls.attach(selectedMesh);
        }
      }
    },
    saveeditdetail: function saveeditdetail() {}
  }
};