"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// const panels = [{
//   icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726658468709141446.png!120a',
//   val: '',
//   auction_weChat: {
//     content: {
//       attrlist: [{ 'key': 'lot', 'name': '图录号', 'selected': true }, { 'key': 'name', 'name': '拍品名称', 'selected': true }, { 'key': 'attr', 'name': '拍品属性', 'selected': true }, { 'key': 'desc', 'name': '一句话介绍', 'selected': false }, { 'key': 'content', 'name': '拍品详情', 'selected': false }, { 'key': 'category', 'name': '拍品分类', 'selected': false }, { 'key': 'album', 'name': '所属专场', 'selected': false }, { 'key': 'reference_price', 'name': '估价', 'selected': false }, { 'key': 'start_price', 'name': '起拍价', 'selected': false }, { 'key': 'success_price', 'name': '成交价', 'selected': true }]
//     },
//     facade: { 'set': true, 'temp': ['price_color', 'content_background', 'auction_spacing_color'], 'style': 4, 'theme': 1, 'title': '结拍快讯', 'radius': 0, 'coverImg': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893240619978780.jpg', 'longMode': '1', 'lot_size': 14, 'desc_size': 14, 'show_area': '1', 'lot_margin': 0, 'lot_weight': 400, 'price_size': 14, 'title_size': 14, 'desc_margin': 6, 'desc_weight': 400, 'item_margin': 25, 'page_margin': 0, 'borderEffect': '1', 'border_width': 0, 'content_size': 14, 'img_position': 'center', 'item_padding': 0, 'price_margin': 6, 'price_weight': 600, 'title_margin': 6, 'title_weight': 600, 'border_radius': 0, 'showLongImage': true, 'border_padding': 15, 'content_margin': 6, 'content_weight': 400, 'lot_text_align': 'left', 'desc_text_align': 'left', 'lot_line_height': 20, 'border_shawdow_x': 0, 'border_shawdow_y': 0, 'desc_line_height': 20, 'price_text_align': 'left', 'title_text_align': 'left', 'price_line_height': 20, 'content_text_align': 'left', 'content_toppadding': 0, 'content_line_height': 22, 'auction_spacing_type': 'dashed', 'auction_spacing_image': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024815/image/1723728879187655050.gif', 'auction_spacing_height': 1, 'background_padding_top': 0, 'background_padding_left': 0, 'background_padding_right': 0, 'background_padding_bottom': 0, 'auction_spacing_image_width': 25, 'auction_spacing_image_margin': 15, 'content_background': '', 'border_padding_top': 10, 'border_padding_bottom': 10, 'border_padding_left': 10, 'border_padding_right': 10, 'borderStyle': 'solid' }
//   }
// }, {
//   icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726660359794163014.png!120a',
//   val: '1',
//   auction_weChat: {
//     content: {
//       attrlist: [{ 'key': 'lot', 'name': '图录号', 'selected': true }, { 'key': 'name', 'name': '拍品名称', 'selected': true }, { 'key': 'attr', 'name': '拍品属性', 'selected': true }, { 'key': 'desc', 'name': '一句话介绍', 'selected': true }, { 'key': 'reference_price', 'name': '估价', 'selected': true }, { 'key': 'content', 'name': '拍品详情', 'selected': true }, { 'key': 'category', 'name': '拍品分类', 'selected': false }, { 'key': 'album', 'name': '所属专场', 'selected': false }, { 'key': 'start_price', 'name': '起拍价', 'selected': false }, { 'key': 'success_price', 'name': '成交价', 'selected': false }]
//     },
//     facade: { 'set': true, 'temp': ['content_color', 'title_color', 'price_color', 'content_background'], 'style': 2, 'theme': 1, 'title': '测试模板的标题', 'radius': 0, 'bg_image': '', 'coverImg': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893241052978250.jpg', 'longMode': '1', 'lot_size': 13, 'desc_size': 13, 'lot_margin': 0, 'lot_weight': 400, 'price_size': 13, 'title_size': 13, 'desc_margin': 5, 'desc_weight': 400, 'item_margin': 20, 'page_margin': 0, 'borderEffect': '1', 'border_width': 0, 'content_size': 13, 'img_position': 'center', 'item_padding': 0, 'price_margin': 5, 'price_weight': 600, 'title_margin': 5, 'title_weight': 600, 'bg_image_mode': 4, 'border_radius': 0, 'border_padding': 18, 'content_margin': 22, 'content_weight': 400, 'lot_text_align': 'left', 'desc_text_align': 'left', 'lot_line_height': 20, 'border_shawdow_x': 0, 'border_shawdow_y': 0, 'desc_line_height': 20, 'price_text_align': 'left', 'title_text_align': 'left', 'price_line_height': 20, 'content_text_align': 'left', 'content_toppadding': 0, 'content_line_height': 28, 'auction_spacing_height': 0, 'auction_spacing_image_width': 0, 'auction_spacing_image_margin': 0, 'background_padding_top': 5, 'background_padding_bottom': 0, 'background_padding_left': 0, 'background_padding_right': 0, 'border_padding_top': 15, 'border_padding_bottom': 15, 'border_padding_left': 15, 'border_padding_right': 15, 'content_background': '', 'show_area': '1' }
//   }
// }, {
//   icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726660218603840774.png!120a',
//   val: '2',
//   auction_weChat: {
//     content: {
//       attrlist: [{ 'key': 'lot', 'name': '图录号', 'selected': false }, { 'key': 'name', 'name': '拍品名称', 'selected': true }, { 'key': 'attr', 'name': '拍品属性', 'selected': true }, { 'key': 'desc', 'name': '一句话介绍', 'selected': false }, { 'key': 'content', 'name': '拍品详情', 'selected': true }, { 'key': 'category', 'name': '拍品分类', 'selected': false }, { 'key': 'album', 'name': '所属专场', 'selected': false }, { 'key': 'reference_price', 'name': '估价', 'selected': false }, { 'key': 'start_price', 'name': '起拍价', 'selected': false }, { 'key': 'success_price', 'name': '成交价', 'selected': false }]
//     },
//     facade: { 'set': true, 'temp': ['title_color', 'border_color'], 'style': 4, 'theme': 1, 'title': '立秋启画卷，秋韵满人间', 'radius': 4, 'bg_image': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024821/image/172417357375873525.webp', 'coverImg': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893241579590133.jpg', 'longMode': '1', 'lot_size': 13, 'desc_size': 13, 'lot_margin': 0, 'lot_weight': 400, 'price_size': 13, 'title_size': 13, 'desc_margin': 8, 'desc_weight': 400, 'item_margin': 20, 'page_margin': 0, 'borderEffect': '1', 'border_width': 1, 'content_size': 13, 'img_position': 'center', 'item_padding': 0, 'price_margin': 5, 'price_weight': 400, 'title_margin': 0, 'title_weight': 600, 'bg_image_mode': 3, 'border_radius': 0, 'border_padding': 20, 'content_margin': 5, 'lot_text_align': 'left', 'desc_text_align': 'left', 'lot_line_height': 20, 'border_shawdow_x': 0, 'border_shawdow_y': 0, 'desc_line_height': 22, 'price_text_align': 'left', 'title_text_align': 'left', 'price_line_height': 34, 'content_toppadding': 0, 'content_line_height': 24, 'auction_spacing_height': 0, 'auction_spacing_image_width': 0, 'auction_spacing_image_margin': 0, 'background_padding_top': 0, 'background_padding_bottom': 0, 'background_padding_left': 0, 'background_padding_right': 0, 'border_padding_top': 10, 'border_padding_bottom': 10, 'border_padding_left': 10, 'border_padding_right': 10, 'content_background': '', 'borderStyle': 'solid', 'show_area': '1', 'content_weight': 400, 'content_text_align': 'left' }
//   }
// }, {
//   icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726658473489314092.png!120a',
//   val: '3',
//   list_bg_img: {
//     top: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726661060339656251.png',
//     center: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726661059928137575.png',
//     bottom: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726661059386318234.png'
//   },
//   auction_weChat: {
//     content: {
//       attrlist: [{ 'key': 'lot', 'name': '图录号', 'selected': true }, { 'key': 'name', 'name': '拍品名称', 'selected': true }, { 'key': 'attr', 'name': '拍品属性', 'selected': true }, { 'key': 'desc', 'name': '一句话介绍', 'selected': true }, { 'key': 'content', 'name': '拍品详情', 'selected': false }, { 'key': 'category', 'name': '拍品分类', 'selected': false }, { 'key': 'album', 'name': '所属专场', 'selected': false }, { 'key': 'reference_price', 'name': '估价', 'selected': false }, { 'key': 'start_price', 'name': '起拍价', 'selected': true }, { 'key': 'success_price', 'name': '成交价', 'selected': false }]
//     },
//     facade: { 'set': true, 'temp': ['border_color', 'border_shawdow_color', 'price_color', 'auction_spacing_color'], 'style': 7, 'theme': 1, 'title': '测试模板', 'radius': 0, 'coverImg': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024712/image/1720762973089191173.png', 'longMode': '1', 'lot_size': 14, 'desc_size': 14, 'lot_margin': 5, 'lot_weight': 400, 'price_size': 14, 'title_size': 14, 'desc_margin': 5, 'desc_weight': 400, 'item_margin': 30, 'page_margin': 0, 'borderEffect': '1', 'border_width': 1, 'content_size': 13, 'img_position': 'center', 'item_padding': 0, 'price_margin': 5, 'price_weight': 600, 'title_margin': 5, 'title_weight': 500, 'border_radius': 0, 'animate_margin': 0, 'border_padding': 15, 'content_margin': 5, 'content_weight': 400, 'lot_text_align': 'left', 'pageBackground': 'rgba(255, 255, 255, 1)', 'desc_text_align': 'left', 'lot_line_height': 20, 'border_shawdow_x': 6, 'border_shawdow_y': 6, 'desc_line_height': 20, 'price_text_align': 'left', 'title_text_align': 'left', 'price_line_height': 20, 'content_text_align': 'left', 'content_toppadding': 6, 'content_line_height': 22, 'auction_spacing_type': 'dashed', 'auction_spacing_image': 'https://saas.cdn.yunzongbu.cn/merchant/23/2024815/image/1723728879187655050.gif', 'auction_spacing_height': 1, 'auction_spacing_image_width': 30, 'auction_spacing_image_margin': 11, 'border_padding_top': 15, 'border_padding_bottom': 15, 'border_padding_left': 15, 'border_padding_right': 15, 'content_background': '#fff', 'borderStyle': 'solid' }
//   }
// }, {
//   icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726658473042590460.png!120a',
//   val: '4',
//   list_bg_img: {
//     top: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726662176973782475.png',
//     center: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726662176540423783.png',
//     bottom: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726662176027717058.png'
//   },
//   auction_weChat: {
//     content: {
//       attrlist: [{ 'key': 'lot', 'name': '图录号', 'selected': true }, { 'key': 'name', 'name': '拍品名称', 'selected': true }, { 'key': 'attr', 'name': '拍品属性', 'selected': false }, { 'key': 'desc', 'name': '一句话介绍', 'selected': false }, { 'key': 'content', 'name': '拍品详情', 'selected': true }, { 'key': 'category', 'name': '拍品分类', 'selected': false }, { 'key': 'album', 'name': '所属专场', 'selected': false }, { 'key': 'reference_price', 'name': '估价', 'selected': false }, { 'key': 'start_price', 'name': '起拍价', 'selected': false }, { 'key': 'success_price', 'name': '成交价', 'selected': false }]
//     },
//     facade: { 'set': true, 'temp': ['border_color', 'lot_color', 'title_color'], 'style': 4, 'theme': 1, 'radius': 4, 'longMode': '1', 'lot_size': 13, 'desc_size': 12, 'show_area': '1', 'lot_margin': 0, 'lot_weight': 600, 'price_size': 12, 'title_size': 13, 'borderStyle': 'solid', 'desc_margin': 15, 'desc_weight': 400, 'item_margin': 15, 'page_margin': 0, 'borderEffect': '2', 'border_width': 1, 'content_size': 13, 'img_position': 'center', 'item_padding': 0, 'price_margin': 15, 'price_weight': 400, 'title_margin': 5, 'title_weight': 600, 'border_radius': 0, 'showLongImage': true, 'content_margin': 5, 'lot_text_align': 'left', 'desc_text_align': 'left', 'lot_line_height': 20, 'border_shawdow_x': 0, 'border_shawdow_y': 0, 'desc_line_height': 20, 'price_text_align': 'left', 'title_text_align': 'left', 'price_line_height': 20, 'border_padding_top': 5, 'content_toppadding': 0, 'border_padding_left': 10, 'content_line_height': 22, 'border_padding_right': 10, 'border_padding_bottom': 5, 'auction_spacing_height': 0, 'background_padding_top': 5, 'background_padding_left': 3, 'background_padding_right': 3, 'background_padding_bottom': 5, 'auction_spacing_image_width': 0, 'auction_spacing_image_margin': 0 }
//   }
// }];

var panels = [{
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024918/image/1726658468709141446.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,30V13.81c6.89-0.49,12.41-5.97,12.9-12.82h347.2c0.49,6.85,6.01,12.33,12.9,12.82V30h1V12.86\n\tc-7.14,0-12.94-5.75-12.94-12.86H12.94c0,7.1-5.79,12.86-12.94,12.86V30C0,30,1,30,1,30z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,0v16.19c6.89,0.49,12.41,5.97,12.9,12.82h347.2c0.49-6.85,6.01-12.33,12.9-12.82V0h1v17.14\n\tc-7.15,0-12.94,5.75-12.94,12.86H12.94c0-7.1-5.79-12.86-12.94-12.86V0H1z\" />\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818609816517.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,30V13.81c6.89-0.49,12.41-5.97,12.9-12.82h347.2c0.49,6.85,6.01,12.33,12.9,12.82V30h1V12.86\n\tc-7.14,0-12.94-5.75-12.94-12.86H12.94c0,7.1-5.79,12.86-12.94,12.86V30C0,30,1,30,1,30z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,0v16.19c6.89,0.49,12.41,5.97,12.9,12.82h347.2c0.49-6.85,6.01-12.33,12.9-12.82V0h1v17.14\n\tc-7.15,0-12.94,5.75-12.94,12.86H12.94c0-7.1-5.79-12.86-12.94-12.86V0H1z\" />\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818610370893.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n\t<g><path class=\"st0\" d=\"M373,8.55V2h-6.55c-0.15-0.64-0.24-1.31-0.24-2H8.79c0,0.69-0.09,1.36-0.24,2H2v6.55\n\t\tC1.36,8.7,0.69,8.79,0,8.79V30h1V9.74C5.6,9.27,9.27,5.6,9.74,1h355.52c0.47,4.6,4.14,8.27,8.74,8.74V30h1V8.79\n\t\tC374.31,8.79,373.64,8.7,373,8.55z M3,8.25V3h5.25C7.37,5.44,5.44,7.37,3,8.25z M372,8.25c-2.44-0.88-4.37-2.81-5.25-5.25H372V8.25\n\t\tz\"/>\n\t<path class=\"st0\" d=\"M362.81,4.58H12.19c-1.32,3.51-4.1,6.28-7.6,7.6V30h1V12.86c3.26-1.42,5.86-4.02,7.28-7.28h349.28\n\t\tc1.42,3.26,4.02,5.86,7.28,7.28V30h1V12.19C366.91,10.87,364.13,8.09,362.81,4.58z\"/></g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n   <g><path class=\"st0\" d=\"M374,0v20.26c-4.6,0.47-8.27,4.14-8.74,8.74H9.74C9.27,24.4,5.6,20.73,1,20.26V0H0v21.21\n\t\tc0.69,0,1.36,0.09,2,0.24V28h6.55c0.15,0.64,0.24,1.31,0.24,2h357.42c0-0.69,0.09-1.36,0.24-2H373v-6.55\n\t\tc0.64-0.15,1.31-0.24,2-0.24V0H374z M3,27v-5.25c2.44,0.88,4.37,2.81,5.25,5.25H3z M372,27h-5.25c0.88-2.44,2.81-4.37,5.25-5.25V27\n\t\tz\"/>\n\t<path class=\"st0\" d=\"M12.19,25.42h350.62c1.32-3.51,4.1-6.29,7.62-7.61V0h-1v17.14c-3.26,1.42-5.86,4.02-7.28,7.28H12.87\n\t\tc-1.42-3.26-4.02-5.86-7.28-7.28V0h-1v17.82C8.09,19.14,10.87,21.91,12.19,25.42z\"/></g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"4.59\" class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"369.43\" class=\"st0\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818610443727.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<path d=\"M368.82,9.56h-3.38V6.18c0-3.41-2.77-6.18-6.18-6.18H15.74c-3.41,0-6.18,2.77-6.18,6.18v3.38H6.18\n\t\tC2.77,9.56,0,12.33,0,15.74V30h1V15.74c0-2.86,2.32-5.18,5.18-5.18h3.38h1v-1V6.18c0-2.86,2.32-5.18,5.18-5.18h343.52\n\t\tc2.86,0,5.18,2.32,5.18,5.18v3.38v1h1h3.38c2.86,0,5.18,2.32,5.18,5.18V30h1V15.74C375,12.33,372.23,9.56,368.82,9.56z\"/>\n\t<path d=\"M366.61,14.09h-5.71V8.38c0-2.13-1.72-3.85-3.85-3.85H17.94c-2.13,0-3.85,1.72-3.85,3.85v5.71H8.38\n\t\tc-2.13,0-3.85,1.72-3.85,3.85V30h1V17.94c0-1.57,1.28-2.85,2.85-2.85h5.71h1v-1V8.38c0-1.57,1.28-2.85,2.85-2.85h339.11\n\t\tc1.57,0,2.85,1.28,2.85,2.85v5.71v1h1h5.71c1.57,0,2.85,1.28,2.85,2.85V30h1V17.94C370.46,15.81,368.74,14.09,366.61,14.09z\"/>\n</g>\n</svg>\n",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<path d=\"M374,0v14.26c0,2.86-2.32,5.18-5.18,5.18h-3.38h-1v1v3.38c0,2.86-2.32,5.18-5.18,5.18H15.74c-2.86,0-5.18-2.32-5.18-5.18\n\t\tv-3.38v-1h-1H6.18C3.32,19.44,1,17.12,1,14.26V0H0v14.26c0,3.41,2.77,6.18,6.18,6.18h3.38v3.38c0,3.41,2.77,6.18,6.18,6.18h343.52\n\t\tc3.41,0,6.18-2.77,6.18-6.18v-3.38h3.38c3.41,0,6.18-2.77,6.18-6.18V0H374z\"/>\n\t<path d=\"M8.38,15.91h5.71v5.71c0,2.13,1.72,3.85,3.85,3.85h339.11c2.13,0,3.85-1.72,3.85-3.85v-5.71h5.71\n\t\tc2.13,0,3.85-1.72,3.85-3.85V0h-1v12.06c0,1.57-1.28,2.85-2.85,2.85h-5.71h-1v1v5.71c0,1.57-1.28,2.85-2.85,2.85H17.94\n\t\tc-1.57,0-2.85-1.28-2.85-2.85v-5.71v-1h-1H8.38c-1.57,0-2.85-1.28-2.85-2.85V0h-1v12.06C4.53,14.19,6.25,15.91,8.38,15.91z\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n\t<rect x=\"4.53\" width=\"1\" height=\"30\"/>\n\t<rect x=\"369.46\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818610621408.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon class=\"st0\" points=\"0,0 0,30 1,30 1,1 374,1 374,30 375,30 375,0 \t\"/>\n\t<path class=\"st0\" d=\"M364.13,4H10.86c0,3.79-3.07,6.86-6.86,6.86V30h1V11.79c3.53-0.45,6.34-3.26,6.79-6.79H363.2\n\t\tc0.45,3.53,3.26,6.34,6.79,6.79V30h1V10.86C367.2,10.86,364.13,7.79,364.13,4z\"/>\n\t<path class=\"st0\" d=\"M3.06,3.05c0.18,1.26,0.75,2.47,1.72,3.44c0.3,0.3,0.64,0.56,0.99,0.79c0.04-0.83,0.67-1.5,1.48-1.61\n\t\tc-0.21-0.32-0.45-0.62-0.73-0.9C5.54,3.8,4.32,3.23,3.06,3.05z\"/>\n\t<path class=\"st0\" d=\"M7.48,8.57c0.62,0,1.11-0.5,1.11-1.11s-0.5-1.11-1.11-1.11s-1.11,0.5-1.11,1.11S6.86,8.57,7.48,8.57z\"/>\n\t<path class=\"st0\" d=\"M367.82,5.63c0.81,0.11,1.44,0.78,1.48,1.61c0.35-0.22,0.68-0.48,0.99-0.79c0.97-0.97,1.54-2.18,1.72-3.44\n\t\tc-1.26,0.18-2.48,0.75-3.45,1.72C368.28,5.01,368.04,5.32,367.82,5.63z\"/>\n\t<path class=\"st0\" d=\"M368.7,7.42c0-0.62-0.5-1.11-1.11-1.11s-1.11,0.5-1.11,1.11s0.5,1.11,1.11,1.11S368.7,8.03,368.7,7.42z\"/>\n</g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon class=\"st0\" points=\"0,30 0,0 1,0 1,29 374,29 374,0 375,0 375,30 \t\"/>\n\t<path class=\"st0\" d=\"M364.13,26H10.86c0-3.79-3.07-6.86-6.86-6.86V0h1v18.21c3.53,0.45,6.34,3.26,6.79,6.79H363.2\n\t\tc0.45-3.53,3.26-6.34,6.79-6.79V0h1v19.14C367.2,19.14,364.13,22.21,364.13,26z\"/>\n\t<path class=\"st0\" d=\"M3.06,26.95c0.18-1.26,0.75-2.47,1.72-3.44c0.3-0.3,0.64-0.56,0.99-0.79c0.04,0.83,0.67,1.5,1.48,1.61\n\t\tc-0.21,0.32-0.45,0.62-0.73,0.9C5.54,26.2,4.32,26.77,3.06,26.95z\"/>\n\t<path class=\"st0\" d=\"M7.48,21.43c0.62,0,1.11,0.5,1.11,1.11s-0.5,1.11-1.11,1.11s-1.11-0.5-1.11-1.11S6.86,21.43,7.48,21.43z\"/>\n\t<path class=\"st0\" d=\"M367.82,24.37c0.81-0.11,1.44-0.78,1.48-1.61c0.35,0.22,0.68,0.48,0.99,0.79c0.97,0.97,1.54,2.18,1.72,3.44\n\t\tc-1.26-0.18-2.48-0.75-3.45-1.72C368.28,24.99,368.04,24.68,367.82,24.37z\"/>\n\t<path class=\"st0\" d=\"M368.7,22.58c0,0.62-0.5,1.11-1.11,1.11s-1.11-0.5-1.11-1.11s0.5-1.11,1.11-1.11S368.7,21.97,368.7,22.58z\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"369.99\" width=\"1\" height=\"30\"/>\n\t<path d=\"M4,30h1c0,0,0-17.74,0-30H4V30z\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818611434910.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M0,0v30h1v-7.7h4.75v-9.31h4.32V6h10.97V1h332.9v5h10.97v6.98h4.32v9.31H374V30h1V0H0z M4.75,21.3H1v-8.31h3.75V21.3z\n\t M20.05,5H4.75v5.16h1V6h3.32v5.98H1V1h19.05V5z M374,21.3h-3.75v-8.31H374V21.3z M374,11.99h-8.07V6h3.32v4.16h1V5h-15.3V1H374\n\tV11.99z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M0,30V0h1v7.7h4.75v9.31h4.32V24h10.97v5h332.9v-5h10.97v-6.98h4.32V7.71H374V0h1v30H0z M4.75,8.7H1v8.31h3.75V8.7z\n\t M20.05,25H4.75v-5.16h1V24h3.32v-5.98H1V29h19.05V25z M374,8.7h-3.75v8.31H374V8.7z M374,18.01h-8.07V24h3.32v-4.16h1V25h-15.3v4\n\tH374V18.01z\"/>\n</svg>\n",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818611764464.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M375,10V0h-10v4.5h-2.13V0H12.13v4.5H10V0H0v10h4.5v2.13H0V30h1V13.13h3.5V30h1V13.13H10V10h3.13V5.5h348.74V10H365v3.13\n\th4.5V30h1V13.13h3.5V30h1V12.13h-4.5V10H375z M9,12.13H5.5V10H9V12.13z M9,9H5.5V5.5H9V9z M9,4.5H4.5V9H1V1h8V4.5z M12.13,9H10V5.5\n\th2.13V9z M361.87,4.5H13.13V1h348.74V4.5z M365,9h-2.13V5.5H365V9z M369.5,12.13H366V10h3.5V12.13z M369.5,9H366V5.5h3.5V9z\n\t M366,4.5V1h8v8h-3.5V4.5H366z\"/>\n</svg>\n",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M375,20v10h-10v-4.5h-2.13V30H12.13v-4.5H10V30H0V20h4.5v-2.13H0V0h1v16.87h3.5V0h1v16.87H10V20h3.13v4.5h348.74V20H365\n\tv-3.13h4.5V0h1v16.87h3.5V0h1v17.87h-4.5V20H375z M9,17.87H5.5V20H9V17.87z M9,21H5.5v3.5H9V21z M9,25.5H4.5V21H1v8h8V25.5z\n\t M12.13,21H10v3.5h2.13V21z M361.87,25.5H13.13V29h348.74V25.5z M365,21h-2.13v3.5H365V21z M369.5,17.87H366V20h3.5V17.87z\n\t M369.5,21H366v3.5h3.5V21z M366,25.5V29h8v-8h-3.5v4.5H366z\"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"369.5\" width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n\t<rect x=\"4.5\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/172718981861167056.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M366.76,0H8.24H0v8.24V30h1V8.24h3V30h1V8.24h3.24V5h358.51v3.24H370V30h1V8.24h3V30h1V8.24V0H366.76z M7.24,7.24H5V5h2.24\n\tV7.24z M7.24,4H4v3.24H1V1h6.24V4z M366.76,4H8.24V1h358.51V4z M370,7.24h-2.24V5H370V7.24z M374,7.24h-3V4h-3.24V1H374V7.24z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M366.76,30H8.24H0v-8.24V0h1v21.76h3V0h1v21.76h3.24V25h358.51v-3.24H370V0h1v21.76h3V0h1v21.76V30H366.76z M7.24,22.76H5\n\tV25h2.24V22.76z M7.24,26H4v-3.24H1V29h6.24V26z M366.76,26H8.24v3h358.51v-3H366.76z M370,22.76h-2.24V25H370V22.76z M374,22.76h-3\n\tV26h-3.24v3H374V22.76z\"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"370\" width=\"1\" height=\"30\"/>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n\t<rect x=\"4\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818612294374.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M374.99,5V0h-5v4h-3.91c-0.55-1.22-0.86-2.57-0.86-4H9.77c0,1.43-0.31,2.78-0.86,4H5V0H0v5h4v3.91\n\tC2.78,9.46,1.43,9.77,0,9.77V30h1V10.72c1.05-0.1,2.06-0.35,3-0.73V30h1V9.53C6.93,8.51,8.51,6.93,9.53,5h355.94\n\tc1.02,1.93,2.6,3.51,4.53,4.53V30h1V9.99c0.94,0.38,1.95,0.64,3,0.73V30h1V9.77c-1.43,0-2.78-0.31-4-0.86V5H374.99z M370.99,1h3v3\n\th-3V1z M4,4H1V1h3V4z M5,8.38V5h3.38C7.55,6.39,6.39,7.55,5,8.38z M9.99,4c0.38-0.94,0.64-1.95,0.73-3h353.54\n\tc0.1,1.05,0.35,2.06,0.73,3H9.99z M369.99,8.38c-1.39-0.83-2.55-1.99-3.38-3.38h3.38V8.38z\"/>\n</svg>\n",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M374.99,25v5h-5v-4h-3.91c-0.55,1.22-0.86,2.57-0.86,4H9.77c0-1.43-0.31-2.78-0.86-4H5v4H0v-5h4v-3.91\n\tc-1.22-0.55-2.57-0.86-4-0.86V0h1v19.28c1.05,0.1,2.06,0.35,3,0.73V0h1v20.47c1.93,1.02,3.51,2.6,4.53,4.53h355.94\n\tc1.02-1.93,2.6-3.51,4.53-4.53V0h1v20.01c0.94-0.38,1.95-0.64,3-0.73V0h1v20.23c-1.43,0-2.78,0.31-4,0.86V25H374.99z M370.99,29h3\n\tv-3h-3V29z M4,26H1v3h3V26z M5,21.62V25h3.38C7.55,23.61,6.39,22.45,5,21.62z M9.99,26c0.38,0.94,0.64,1.95,0.73,3h353.54\n\tc0.1-1.05,0.35-2.06,0.73-3H9.99z M369.99,21.62c-1.39,0.83-2.55,1.99-3.38,3.38h3.38V21.62z\"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"4\" width=\"1\" height=\"30\"/>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n\t<rect x=\"370\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818612584457.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon points=\"6,5.51 7,5.51 7,0 0,0 0,30 1,30 1,1 6,1 \t\"/>\n\t<polygon points=\"368,0 368,5.51 369,5.51 369,1 374,1 374,30 375,30 375,0 \t\"/>\n\t<polygon points=\"371,7.16 365.52,7.16 365.52,0 9.48,0 9.48,7.16 4,7.16 4,2.5 3,2.5 3,8.16 10.48,8.16 10.48,1 364.52,1 \n\t\t364.52,8.16 372,8.16 372,2.5 371,2.5 \t\"/>\n</g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon points=\"6,24.49 7,24.49 7,30 0,30 0,0 1,0 1,29 6,29 \t\"/>\n\t<polygon points=\"368,30 368,24.49 369,24.49 369,29 374,29 374,0 375,0 375,30 \t\"/>\n\t<polygon points=\"371,22.84 365.52,22.84 365.52,30 9.48,30 9.48,22.84 4,22.84 4,27.5 3,27.5 3,21.84 10.48,21.84 10.48,29 \n\t\t364.52,29 364.52,21.84 372,21.84 372,27.5 371,27.5 \t\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818612440008.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"3\" y=\"15.5\" class=\"st0\" width=\"1\" height=\"14.5\"/>\n\t<rect x=\"2.19\" y=\"2.2\" class=\"st0\" width=\"2.6\" height=\"2.6\"/>\n\t<path class=\"st0\" d=\"M375,7V0h-7v6h-5V0H12v6H7V0H0v7h6v5H0v18h1V13h5v6h7v-6h6V6h-6V1h349v5h-6v7h6v6h7v-6h5v17h1V12h-6V7H375z\n\t\t M369,1h5v5h-5V1z M6,6H1V1h5V6z M12,18H7v-5h5V18z M12,12H7V7h5V12z M18,7v5h-5V7H18z M362,12h-5V7h5V12z M368,18h-5v-5h5V18z\n\t\t M368,12h-5V7h5V12z\"/>\n\t<rect x=\"15.5\" y=\"3\" class=\"st0\" width=\"344\" height=\"1\"/>\n\t<rect x=\"371\" y=\"15.5\" class=\"st0\" width=\"1\" height=\"14.5\"/>\n\t<rect x=\"370.2\" y=\"2.2\" class=\"st0\" width=\"2.6\" height=\"2.6\"/>\n</g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"3\" class=\"st0\" width=\"1\" height=\"14.5\"/>\n\t<rect x=\"2.19\" y=\"25.2\" class=\"st0\" width=\"2.6\" height=\"2.6\"/>\n\t<path class=\"st0\" d=\"M375,23v7h-7v-6h-5v6H12v-6H7v6H0v-7h6v-5H0V0h1v17h5v-6h7v6h6v7h-6v5h349v-5h-6v-7h6v-6h7v6h5V0h1v18h-6v5\n\t\tH375z M369,29h5v-5h-5V29z M6,24H1v5h5V24z M12,12H7v5h5V12z M12,18H7v5h5V18z M18,23v-5h-5v5H18z M362,18h-5v5h5V18z M368,12h-5v5\n\t\th5V12z M368,18h-5v5h5V18z\"/>\n\t<rect x=\"15.5\" y=\"26\" class=\"st0\" width=\"344\" height=\"1\"/>\n\t<rect x=\"371\" class=\"st0\" width=\"1\" height=\"14.5\"/>\n\t<rect x=\"370.2\" y=\"25.2\" class=\"st0\" width=\"2.6\" height=\"2.6\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"3\" class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"371\" class=\"st0\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818612109958.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<polygon points=\"1,5 4,5 4,30 5,30 5,5 370,5 370,30 371,30 371,4 5,4 5,1 374,1 374,30 375,30 375,0 4,0 4,4 0,4 0,30 1,30 \"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<polygon points=\"374,25 371,25 371,0 370,0 370,25 5,25 5,0 4,0 4,26 370,26 370,29 1,29 1,0 0,0 0,30 371,30 371,26 375,26 375,0 \n\t374,0 \"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"4\" width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"370\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818613861620.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,20.52C1,9.76,9.76,1,20.52,1H374v29h1V0H20.52C9.19,0,0,9.19,0,20.52V30h1V20.52z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M374,9.48C374,20.24,365.24,29,354.48,29H1V0H0v30h354.48C365.81,30,375,20.81,375,9.48V0h-1V9.48z\"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818613271716.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon points=\"0,0 0,30 2,30 2,2 373,2 373,30 375,30 375,0 \t\"/>\n\t<polygon points=\"4,30 5,30 5,5 370,5 370,30 371,30 371,4 4,4 \t\"/>\n</g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon points=\"0,30 0,0 2,0 2,28 373,28 373,0 375,0 375,30 \t\"/>\n\t<polygon points=\"4,0 5,0 5,25 370,25 370,0 371,0 371,26 4,26 \t\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"373\" width=\"2\" height=\"30\"/>\n\t<rect width=\"2\" height=\"30\"/>\n\t<rect x=\"370\" width=\"1\" height=\"30\"/>\n\t<rect x=\"4\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818613308319.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<path class=\"st0\" d=\"M3,3.72C3.3,3.55,3.55,3.3,3.72,3h367.56c0.17,0.3,0.42,0.55,0.72,0.72V30h1V4c1.1,0,2-0.9,2-2s-0.9-2-2-2\n\t\ts-2,0.9-2,2H4c0-1.1-0.9-2-2-2S0,0.9,0,2s0.9,2,2,2v26h1V3.72z\"/>\n</g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<path class=\"st0\" d=\"M3,26.28C3.3,26.45,3.55,26.7,3.72,27h367.56c0.17-0.3,0.42-0.55,0.72-0.72V0h1v26c1.1,0,2,0.9,2,2s-0.9,2-2,2\n\t\ts-2-0.9-2-2H4c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2V0h1V26.28z\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"372\" class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"2\" class=\"st0\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818614702742.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,22.73C1,14.61,7.61,8,15.73,8h343.54C367.39,8,374,14.61,374,22.73V30h1v-7.27v-7C375,7.06,367.94,0,359.27,0H15.73\n\tC7.06,0,0,7.06,0,15.73v7V30h1V22.73z M1,15.73C1,7.61,7.61,1,15.73,1h343.54C367.39,1,374,7.61,374,15.73v1.53\n\tC371.77,11.28,366.02,7,359.27,7H15.73C8.98,7,3.23,11.28,1,17.26V15.73z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,7.27C1,15.39,7.61,22,15.73,22h343.54C367.39,22,374,15.39,374,7.27V0h1v7.27v7c0,8.67-7.06,15.73-15.73,15.73H15.73\n\tC7.06,30,0,22.94,0,14.27v-7V0h1V7.27z M1,14.27C1,22.39,7.61,29,15.73,29h343.54c8.12,0,14.73-6.61,14.73-14.73v-1.53\n\tC371.77,18.72,366.02,23,359.27,23H15.73C8.98,23,3.23,18.72,1,12.74V14.27z\"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818614423402.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon class=\"st0\" points=\"0,0 0,30 1,30 1,1 374,1 374,30 375,30 375,0 \t\"/>\n\t<path class=\"st0\" d=\"M359,6H16C9.37,6,4,11.37,4,18v12h1V18C5,11.94,9.94,7,16,7H359c6.07,0,11,4.94,11,11v12h1V18\n\t\tC371,11.37,365.63,6,359,6z\"/>\n\t<path class=\"st0\" d=\"M369.11,2c0,2.15-1.74,3.89-3.89,3.89c2.15,0,3.89,1.74,3.89,3.89c0-2.15,1.74-3.89,3.89-3.89\n\t\tC370.85,5.89,369.11,4.15,369.11,2z\"/>\n\t<path class=\"st0\" d=\"M5.89,9.79c0-2.15,1.74-3.89,3.89-3.89C7.64,5.89,5.89,4.15,5.89,2c0,2.15-1.74,3.89-3.89,3.89\n\t\tC4.15,5.89,5.89,7.64,5.89,9.79z\"/>\n</g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon class=\"st0\" points=\"0,30 0,0 1,0 1,29 374,29 374,0 375,0 375,30 \t\"/>\n\t<path class=\"st0\" d=\"M359,24H16C9.37,24,4,18.63,4,12V0h1v12c0,6.06,4.94,11,11,11h343c6.07,0,11-4.94,11-11V0h1v12\n\t\tC371,18.63,365.63,24,359,24z\"/>\n\t<path class=\"st0\" d=\"M369.11,28c0-2.15-1.74-3.89-3.89-3.89c2.15,0,3.89-1.74,3.89-3.89c0,2.15,1.74,3.89,3.89,3.89\n\t\tC370.85,24.11,369.11,25.85,369.11,28z\"/>\n\t<path class=\"st0\" d=\"M5.89,20.21c0,2.15,1.74,3.89,3.89,3.89c-2.14,0.01-3.89,1.75-3.89,3.9c0-2.15-1.74-3.89-3.89-3.89\n\t\tC4.15,24.11,5.89,22.36,5.89,20.21z\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"370\" class=\"st0\" width=\"1\" height=\"30\"/>\n\t<rect x=\"4\" class=\"st0\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818615409617.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M371.46,0l-3.04,3.04H6.57L3.54,0L0,3.54l3.04,3.04V30h1V6.57l2.54-2.54h361.86l2.54,2.54V30h1V6.57L375,3.54L371.46,0z\n\t M3.54,5.66L1.41,3.54l2.12-2.12l2.12,2.12L3.54,5.66z M371.46,5.66l-2.12-2.12l2.12-2.12l2.12,2.12L371.46,5.66z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M371.46,30l-3.04-3.04H6.57L3.54,30L0,26.46l3.04-3.04V0h1v23.43l2.54,2.54h361.86l2.54-2.54V0h1v23.43l3.02,3.03L371.46,30\n\tz M3.54,24.34l-2.13,2.12l2.12,2.12l2.12-2.12L3.54,24.34z M371.46,24.34l-2.12,2.12l2.12,2.12l2.12-2.12L371.46,24.34z\"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"370.98\" width=\"1\" height=\"30\"/>\n\t<rect x=\"3.04\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818609368954.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,6.52C1,4.85,2.35,3.5,4.02,3.5h33.23C37.49,4.92,38.71,6,40.2,6s2.71-1.08,2.95-2.5h1.1C44.49,4.92,45.71,6,47.2,6\n\ts2.71-1.08,2.95-2.5h1.1C51.49,4.92,52.71,6,54.2,6s2.71-1.08,2.95-2.5h313.83c1.66,0,3.02,1.35,3.02,3.02V30h1V6.52\n\tc0-2.22-1.8-4.02-4.02-4.02H57.15C56.91,1.08,55.69,0,54.2,0s-2.71,1.08-2.95,2.5h-1.1C49.91,1.08,48.69,0,47.2,0\n\ts-2.71,1.08-2.95,2.5h-1.1C42.91,1.08,41.69,0,40.2,0s-2.71,1.08-2.95,2.5H4.02C1.8,2.5,0,4.3,0,6.52V30h1V6.52z M54.2,1\n\tc1.1,0,2,0.9,2,2s-0.9,2-2,2c-1.1,0-2-0.9-2-2S53.1,1,54.2,1z M47.2,1c1.1,0,2,0.9,2,2s-0.9,2-2,2c-1.1,0-2-0.9-2-2S46.1,1,47.2,1z\n\t M40.2,1c1.1,0,2,0.9,2,2s-0.9,2-2,2c-1.1,0-2-0.9-2-2S39.1,1,40.2,1z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1,23.48c0,1.67,1.35,3.02,3.02,3.02h33.23c0.24-1.42,1.46-2.5,2.95-2.5s2.71,1.08,2.95,2.5h1.1\n\tc0.24-1.42,1.46-2.5,2.95-2.5s2.71,1.08,2.95,2.5h1.1c0.24-1.42,1.46-2.5,2.95-2.5s2.71,1.08,2.95,2.5h313.83\n\tc1.66,0,3.02-1.35,3.02-3.02V0h1v23.48c0,2.22-1.8,4.02-4.02,4.02H57.15c-0.24,1.42-1.46,2.5-2.95,2.5s-2.71-1.08-2.95-2.5h-1.1\n\tc-0.24,1.42-1.46,2.5-2.95,2.5s-2.71-1.08-2.95-2.5h-1.1c-0.24,1.42-1.46,2.5-2.95,2.5s-2.71-1.08-2.95-2.5H4.02\n\tC1.8,27.5,0,25.7,0,23.48V0h1V23.48z M54.2,29c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S53.1,29,54.2,29z M47.2,29c1.1,0,2-0.9,2-2\n\ts-0.9-2-2-2s-2,0.9-2,2S46.1,29,47.2,29z M40.2,29c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S39.1,29,40.2,29z\"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n\t<rect width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189818607893627.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon class=\"st0\" points=\"55.7,4.65 20.9,4.65 20.9,5.63 0,5.63 0,30 1,30 1,6.62 20.9,6.62 20.9,7.61 55.7,7.61 55.7,6.62 \n\t\t329.12,6.62 329.12,5.63 55.7,5.63 \t\"/>\n\t<polygon class=\"st0\" points=\"343.59,5.63 343.59,6.62 374,6.62 374,30 375,30 375,5.63 \t\"/>\n\t<path class=\"st0\" d=\"M336.33,0c0,3.42-2.76,6.2-6.18,6.2c3.42,0,6.18,2.76,6.18,6.18c0-3.42,2.78-6.18,6.18-6.2\n\t\tC339.09,6.18,336.33,3.42,336.33,0z\"/>\n</g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<polygon class=\"st0\" points=\"375,24.37 375,0 374,0 374,23.38 45.88,23.38 45.88,24.37 \t\"/>\n\t<polygon class=\"st0\" points=\"31.41,24.37 31.41,23.38 1,23.38 1,0 0,0 0,24.37 \t\"/>\n\t<path class=\"st0\" d=\"M38.67,30c0-3.42,2.76-6.2,6.18-6.2c-3.42,0-6.18-2.76-6.18-6.18c0,3.42-2.78,6.18-6.18,6.2\n\t\tC35.91,23.82,38.67,26.58,38.67,30z\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect width=\"1\" height=\"30\"/>\n\t<rect x=\"374\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/172718981861561082.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<path d=\"M2,8.91V5.38L5.38,2h77.33c0.21,0.58,0.76,1,1.41,1c0.83,0,1.5-0.67,1.5-1.5S84.94,0,84.11,0c-0.65,0-1.2,0.42-1.41,1H4.96\n\t\tL1,4.96v3.94c-0.58,0.21-1,0.76-1,1.41c0,0.83,0.67,1.5,1.5,1.5S3,11.14,3,10.32C3,9.66,2.58,9.11,2,8.91z\"/>\n\t<path d=\"M368.78,4H6.15C5.28,4.87,4.8,5.35,3.93,6.22V30h1V6.63L6.56,5h361.81L370,6.63V30h1V6.22\n\t\tC370.13,5.35,369.65,4.87,368.78,4z\"/>\n</g>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<path d=\"M373,21.09v3.53L369.62,28h-77.33c-0.21-0.58-0.76-1-1.41-1c-0.83,0-1.5,0.67-1.5,1.5s0.68,1.5,1.51,1.5\n\t\tc0.65,0,1.2-0.42,1.41-1h77.74l3.96-3.96V21.1c0.58-0.21,1-0.76,1-1.41c0-0.83-0.67-1.5-1.5-1.5s-1.5,0.67-1.5,1.49\n\t\tC372,20.34,372.42,20.89,373,21.09z\"/>\n\t<path d=\"M6.22,26h362.63c0.87-0.87,1.35-1.35,2.22-2.22V0h-1v23.37L368.44,25H6.63L5,23.37V0H4v23.78\n\t\tC4.87,24.65,5.35,25.13,6.22,26z\"/>\n</g>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<g>\n\t<rect x=\"3.93\" width=\"1\" height=\"30\"/>\n\t<rect x=\"370\" width=\"1\" height=\"30\"/>\n</g>\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024924/image/1727189822565801731.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M373.5,4.56c-0.64,0-1.18,0.4-1.4,0.97c-23.15-1.4-70.52-5.64-86.6-5.51c-11.81,0.1-29.66,1.28-46.92,2.41\n\tc-18.67,1.23-37.97,2.5-50.58,2.5s-31.91-1.27-50.58-2.5c-17.26-1.14-35.11-2.31-46.92-2.41C73.22-0.15,29.01,2.29,10.8,3.33\n\tC4.82,3.68,1.11,8.5,1.01,14.36C0.42,14.56,0,15.11,0,15.77c0,0.83,0.67,1.5,1.5,1.5S3,16.6,3,15.77c0-0.65-0.41-1.2-0.99-1.41\n\tc0.1-5.34,3.4-9.73,8.84-10.04C29.05,3.27,73.26,0.85,90.49,1c11.78,0.1,29.61,1.27,46.86,2.41c18.68,1.23,38,2.51,50.64,2.51\n\tc12.64,0,31.96-1.27,50.65-2.51C255.89,2.27,273.72,1.1,285.5,1c16.61-0.14,67.09,4.31,86.58,5.53c0.2,0.6,0.75,1.03,1.42,1.03\n\tc0.83,0,1.5-0.67,1.5-1.5S374.33,4.56,373.5,4.56z\"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<path d=\"M1.5,25.45c0.64,0,1.18-0.4,1.4-0.97c23.15,1.4,70.52,5.64,86.6,5.51c11.81-0.1,29.66-1.28,46.92-2.41\n\tc18.67-1.23,37.97-2.5,50.58-2.5s31.91,1.27,50.58,2.5c17.26,1.14,35.11,2.31,46.92,2.41c17.28,0.17,61.49-2.27,79.7-3.31\n\tc5.98-0.35,9.69-5.17,9.79-11.03c0.59-0.2,1.01-0.75,1.01-1.41c0-0.83-0.67-1.5-1.5-1.5c-0.83,0-1.5,0.67-1.5,1.5\n\tc0,0.65,0.41,1.2,0.99,1.41c-0.1,5.34-3.4,9.73-8.84,10.04c-18.2,1.05-62.41,3.47-79.64,3.32c-11.78-0.1-29.61-1.27-46.86-2.41\n\tc-18.68-1.23-38-2.51-50.64-2.51s-31.96,1.27-50.65,2.51c-17.25,1.14-35.08,2.31-46.86,2.41c-16.61,0.14-67.09-4.31-86.58-5.53\n\tc-0.2-0.6-0.75-1.03-1.42-1.03c-0.83,0-1.5,0.67-1.5,1.5S0.67,25.45,1.5,25.45z\"/>\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n</svg>"
}, {
  icon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024104/image/1728026095274148790.png!120a',
  top: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n<polygon class=\"st0\" points=\"194.22,18.66 195.07,18.65 187.59,11.17 180.1,18.66 105.03,18.66 105.03,19.66 269.97,19.66 \n\t269.97,18.66 \"/>\n</svg>",
  bottom: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n</svg>",
  center: "<svg version=\"1.1\" id=\"\u56FE\u5C42_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n\t viewBox=\"0 0 375 30\" style=\"enable-background:new 0 0 375 30;\" xml:space=\"preserve\">\n</svg>"
}];
var _default = exports.default = panels;