var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      {},
      [
        _c("div", { staticClass: "porBox" }, [
          _c(
            "div",
            { staticClass: "absoBox" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("创建抽奖活动")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              border: "",
              data: _vm.tableData.data,
              size: "small",
              "row-key": "lottery_id",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "lottery_id", label: "ID", "min-width": "60" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "140", label: "名称", prop: "name" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "活动类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n              " +
                          _vm._s(scope.row.type == 1 ? "大转盘" : "九宫格") +
                          "\n          "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                "min-width": "80",
                label: "参与次数",
                prop: "participate_num",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "中奖次数", prop: "win_num", "min-width": "80" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "活动时间", "min-width": "220" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v("开始时间：" + _vm._s(scope.row.start_time)),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("结束时间：" + _vm._s(scope.row.end_time)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status == 10
                        ? _c("span", [_vm._v("待开始")])
                        : scope.row.status == 20
                        ? _c("span", [_vm._v("进行中")])
                        : scope.row.status == 30
                        ? _c("span", [_vm._v("已结束")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "创建时间",
                prop: "add_time",
                "min-width": "220",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "100", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status == 10 || scope.row.status == 20
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.stopactive(scope.row.lottery_id)
                                },
                              },
                            },
                            [_vm._v("停止")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onEdit(scope.row.lottery_id)
                            },
                          },
                        },
                        [_vm._v("\n            编辑\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(
                                scope.row.lottery_id,
                                scope.$index
                              )
                            },
                          },
                        },
                        [_vm._v("\n            删除\n          ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "paginaBox flex" },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                "page-sizes": [20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, prev, pager, next, sizes",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("file-list", { ref: "exportList" }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.showImg,
              "close-on-click-modal": false,
              center: "",
              title: "查看二维码",
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showImg = $event
              },
            },
          },
          [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.showImgUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }