var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        [
          _c("div", { staticClass: "top-view" }, [
            _c(
              "div",
              { staticStyle: { padding: "0 20px" } },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo default-tabs mt10",
                    attrs: {
                      "default-active": _vm.menuKey,
                      mode: "horizontal",
                    },
                    on: { select: _vm.changeTab },
                  },
                  [
                    _c(
                      "el-menu-item",
                      { attrs: { index: "/merchant/live/manage/list" } },
                      [_vm._v("直播管理")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      { attrs: { index: "/merchant/live/manage/category" } },
                      [_vm._v("直播分类")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }