var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "banner",
      style: {
        "background-color": _vm.facade.background_color,
        padding:
          "0 " +
          _vm.facade.page_padding / 2 +
          "px " +
          (_vm.facade.indicator_vertical_align === "bottom" ? "23px" : "0") +
          " " +
          _vm.facade.page_padding / 2 +
          "px",
      },
    },
    [
      _c(
        "div",
        {
          class: {
            "item-flex-center": _vm.content.style != 4,
            flex: _vm.content.style == 4,
            "justify-left": _vm.content.style === 3,
          },
          staticStyle: { position: "relative" },
        },
        [
          _vm.content.style === 2
            ? _c(
                "div",
                {
                  staticClass: "banner-img",
                  style: {
                    boxShadow:
                      (_vm.facade.border_shawdow_x || 0) / 2 +
                      "px " +
                      (_vm.facade.border_shawdow_y || 0) / 2 +
                      "px " +
                      (_vm.facade.border_shawdow_size || 0) / 2 +
                      "px " +
                      _vm.facade.border_shawdow_color,
                  },
                },
                [
                  _vm.content.data[0] && _vm.content.data[0].url
                    ? _c("img", {
                        style: {
                          width: _vm.content.view_width / 2 + "px",
                          height: _vm.content.view_height / 2 + "px",
                          "border-radius":
                            _vm.facade.itemTopLeftRadius / 2 +
                            "px " +
                            _vm.facade.itemTopRightRadius / 2 +
                            "px " +
                            _vm.facade.itemBottomRightRadius / 2 +
                            "px " +
                            _vm.facade.itemBottomLeftRadius / 2 +
                            "px",
                        },
                        attrs: {
                          src: _vm.content.data[0].url + "!m640",
                          alt: "",
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "no-img item-flex-center",
                          style: {
                            width: _vm.content.view_width / 2 + "px",
                            height: _vm.content.view_height / 2 + "px",
                            "border-radius":
                              _vm.facade.itemTopLeftRadius / 2 +
                              "px " +
                              _vm.facade.itemTopRightRadius / 2 +
                              "px " +
                              _vm.facade.itemBottomRightRadius / 2 +
                              "px " +
                              _vm.facade.itemBottomLeftRadius / 2 +
                              "px",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "banner-img",
              style: {
                marginLeft:
                  (_vm.content.style === 2 ? _vm.facade.item_padding / 2 : 0) +
                  "px",
                marginRight:
                  (_vm.content.style !== 1 ? _vm.facade.item_padding / 2 : 0) +
                  "px",
                boxShadow:
                  (_vm.facade.border_shawdow_x || 0) / 2 +
                  "px " +
                  (_vm.facade.border_shawdow_y || 0) / 2 +
                  "px " +
                  (_vm.facade.border_shawdow_size || 0) / 2 +
                  "px " +
                  _vm.facade.border_shawdow_color,
              },
            },
            [
              _vm.content.data[0] && _vm.content.data[0].url
                ? _c("img", {
                    style: {
                      width: _vm.content.view_width / 2 + "px",
                      height: _vm.content.view_height / 2 + "px",
                      "border-radius":
                        _vm.facade.itemTopLeftRadius / 2 +
                        "px " +
                        _vm.facade.itemTopRightRadius / 2 +
                        "px " +
                        _vm.facade.itemBottomRightRadius / 2 +
                        "px " +
                        _vm.facade.itemBottomLeftRadius / 2 +
                        "px",
                    },
                    attrs: { src: _vm.content.data[0].url + "!m640", alt: "" },
                  })
                : _c(
                    "div",
                    {
                      staticClass: "no-img item-flex-center",
                      style: {
                        width: _vm.content.view_width / 2 + "px",
                        height: _vm.content.view_height / 2 + "px",
                        "border-radius":
                          _vm.facade.itemTopLeftRadius / 2 +
                          "px " +
                          _vm.facade.itemTopRightRadius / 2 +
                          "px " +
                          _vm.facade.itemBottomRightRadius / 2 +
                          "px " +
                          _vm.facade.itemBottomLeftRadius / 2 +
                          "px",
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                          alt: "",
                        },
                      }),
                    ]
                  ),
            ]
          ),
          _vm._v(" "),
          _vm.content.style === 2 || _vm.content.style === 3
            ? _c(
                "div",
                {
                  staticClass: "banner-img",
                  style: {
                    boxShadow:
                      (_vm.facade.border_shawdow_x || 0) / 2 +
                      "px " +
                      (_vm.facade.border_shawdow_y || 0) / 2 +
                      "px " +
                      (_vm.facade.border_shawdow_size || 0) / 2 +
                      "px " +
                      _vm.facade.border_shawdow_color,
                  },
                },
                [
                  _vm.content.data[0] && _vm.content.data[0].url
                    ? _c("img", {
                        style: {
                          width: _vm.content.view_width / 2 + "px",
                          height: _vm.content.view_height / 2 + "px",
                          "border-radius":
                            _vm.facade.itemTopLeftRadius / 2 +
                            "px " +
                            _vm.facade.itemTopRightRadius / 2 +
                            "px " +
                            _vm.facade.itemBottomRightRadius / 2 +
                            "px " +
                            _vm.facade.itemBottomLeftRadius / 2 +
                            "px",
                        },
                        attrs: {
                          src: _vm.content.data[0].url + "!m640",
                          alt: "",
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "no-img item-flex-center",
                          style: {
                            width: _vm.content.view_width / 2 + "px",
                            height: _vm.content.view_height / 2 + "px",
                            "border-radius":
                              _vm.facade.itemTopLeftRadius / 2 +
                              "px " +
                              _vm.facade.itemTopRightRadius / 2 +
                              "px " +
                              _vm.facade.itemBottomRightRadius / 2 +
                              "px " +
                              _vm.facade.itemBottomLeftRadius / 2 +
                              "px",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.content.style === 4
            ? [
                _c(
                  "div",
                  {
                    staticClass: "banner-img area2",
                    style: {
                      boxShadow:
                        (_vm.facade.border_shawdow_x || 0) / 2 +
                        "px " +
                        (_vm.facade.border_shawdow_y || 0) / 2 +
                        "px " +
                        (_vm.facade.border_shawdow_size || 0) / 2 +
                        "px " +
                        _vm.facade.border_shawdow_color,
                    },
                  },
                  [
                    _vm.content.data[0] && _vm.content.data[0].url
                      ? _c("img", {
                          style: {
                            width: (_vm.content.view_width - 32) / 2 + "px",
                            height: (_vm.content.view_height - 32) / 2 + "px",
                            "border-radius":
                              _vm.facade.itemTopLeftRadius / 2 +
                              "px " +
                              _vm.facade.itemTopRightRadius / 2 +
                              "px " +
                              _vm.facade.itemBottomRightRadius / 2 +
                              "px " +
                              _vm.facade.itemBottomLeftRadius / 2 +
                              "px",
                          },
                          attrs: {
                            src: _vm.content.data[0].url + "!m640",
                            alt: "",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "no-img item-flex-center",
                            style: {
                              width: (_vm.content.view_width - 32) / 2 + "px",
                              height: (_vm.content.view_height - 32) / 2 + "px",
                              "border-radius":
                                _vm.facade.itemTopLeftRadius / 2 +
                                "px " +
                                _vm.facade.itemTopRightRadius / 2 +
                                "px " +
                                _vm.facade.itemBottomRightRadius / 2 +
                                "px " +
                                _vm.facade.itemBottomLeftRadius / 2 +
                                "px",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "banner-img area3",
                    style: {
                      boxShadow:
                        (_vm.facade.border_shawdow_x || 0) / 2 +
                        "px " +
                        (_vm.facade.border_shawdow_y || 0) / 2 +
                        "px " +
                        (_vm.facade.border_shawdow_size || 0) / 2 +
                        "px " +
                        _vm.facade.border_shawdow_color,
                    },
                  },
                  [
                    _vm.content.data[0] && _vm.content.data[0].url
                      ? _c("img", {
                          style: {
                            width: (_vm.content.view_width - 64) / 2 + "px",
                            height: (_vm.content.view_height - 64) / 2 + "px",
                            "border-radius":
                              _vm.facade.itemTopLeftRadius / 2 +
                              "px " +
                              _vm.facade.itemTopRightRadius / 2 +
                              "px " +
                              _vm.facade.itemBottomRightRadius / 2 +
                              "px " +
                              _vm.facade.itemBottomLeftRadius / 2 +
                              "px",
                          },
                          attrs: {
                            src: _vm.content.data[0].url + "!m640",
                            alt: "",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "no-img item-flex-center",
                            style: {
                              width: (_vm.content.view_width - 64) / 2 + "px",
                              height: (_vm.content.view_height - 64) / 2 + "px",
                              "border-radius":
                                _vm.facade.itemTopLeftRadius / 2 +
                                "px " +
                                _vm.facade.itemTopRightRadius / 2 +
                                "px " +
                                _vm.facade.itemBottomRightRadius / 2 +
                                "px " +
                                _vm.facade.itemBottomLeftRadius / 2 +
                                "px",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.facade.show_name
        ? _c(
            "div",
            {
              staticClass: "banner-name",
              style: {
                position:
                  _vm.facade.name_vertical_align == "top" ? "absolute" : "",
                bottom:
                  _vm.facade.name_vertical_align == "top"
                    ? _vm.facade.indicator_vertical_align == "bottom"
                      ? "23px"
                      : "0"
                    : "",
                left: _vm.facade.name_vertical_align == "top" ? "0" : "",
                right: _vm.facade.name_vertical_align == "top" ? "0" : "",
                background: _vm.facade.name_bg,
                padding:
                  _vm.facade.name_padding_top / 2 +
                  "px " +
                  _vm.facade.name_padding_right / 2 +
                  "px " +
                  _vm.facade.name_padding_bottom / 2 +
                  "px " +
                  _vm.facade.name_padding_left / 2 +
                  "px",
                margin:
                  _vm.facade.name_margin_top / 2 +
                  "px " +
                  _vm.facade.name_margin_right / 2 +
                  "px " +
                  _vm.facade.name_margin_bottom / 2 +
                  "px " +
                  _vm.facade.name_margin_left / 2 +
                  "px",
                "border-radius":
                  _vm.facade.name_border_top_left_radius / 2 +
                  "px " +
                  _vm.facade.name_border_top_right_radius / 2 +
                  "px " +
                  _vm.facade.name_border_bottom_right_radius / 2 +
                  "px " +
                  _vm.facade.name_border_bottom_left_radius / 2 +
                  "px",
                color: _vm.facade.name_color,
                fontSize: _vm.facade.name_fontsize / 2 + "px",
                fontWeight: _vm.facade.name_fontweight,
                zIndex: 10,
              },
            },
            [_vm._v(_vm._s(_vm.content.data[0].title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("pointer", {
        staticStyle: { "z-index": "10" },
        attrs: {
          margin: _vm.margin,
          color: _vm.facade.indicator_color,
          uncolor: _vm.facade.indicator_default_color,
          type: _vm.facade.indicator_style,
          align: _vm.facade.indicator_align,
          vAlign: _vm.facade.indicator_vertical_align,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }