var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20 ft12",
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "tableEmpty" }, [
              _c("img", {
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "通知类型", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.notice_title))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "通知状态", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(scope.row.is_send ? "开启" : "关闭")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设置", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.setSendConfigAction(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.is_send ? "取消通知" : "开启通知")
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }