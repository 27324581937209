"use strict";

var _interopRequireWildcard = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var echarts = _interopRequireWildcard(require("echarts/core"));
var _charts = require("echarts/charts");
var _renderers = require("echarts/renderers");
var _user = require("@/api/user");
var _order = require("@/api/order");
var _index = require("@/utils/index");
var _auction = require("@/api/auction.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 注册必须的组件
echarts.use([_charts.PieChart, _renderers.CanvasRenderer]);
var _default = exports.default = {
  name: 'Cauctionrecord',
  props: {
    uid: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 6
      },
      echart1: {
        type: 'numc',
        result: {},
        chart: null
      },
      echart2: {
        type: 'numc',
        result: {},
        chart: null
      },
      echart3: {
        type: 'numc',
        result: {},
        chart: null
      },
      countjson: {
        bidCount: 0,
        auctionOrderCount: 0,
        auctionOrderSumTotalPrice: 0,
        auctionAveragePrice: 0,
        auctionOrderSumPayPrice: 0
      },
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.uid) {
      //   this.getList();
      (0, _user.userLookRadio)(this.uid).then(function (res) {
        if (res.data.length) {
          _this.echart1.result = res.data;
        } else {
          _this.echart1.result = [{
            name: '',
            value: 0
          }];
        }
        _this.setPieData('echart1');
      });
      (0, _user.auctionRadio)(this.uid).then(function (res) {
        if (res.data.length) {
          _this.echart2.result = res.data;
        } else {
          _this.echart2.result = [{
            name: '',
            value: 0
          }];
        }
        _this.setPieData('echart2');
      });
      (0, _user.payauctionRadio)(this.uid).then(function (res) {
        if (res.data.length) {
          _this.echart3.result = res.data;
        } else {
          _this.echart3.result = [{
            name: '',
            value: 0
          }];
        }
        _this.setPieData('echart3');
      });
      (0, _user.userBidAllstatics)(this.uid).then(function (res) {
        _this.countjson = res.data;
      });
      this.tableFrom.uid = this.uid;
      this.getList();
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.echart1.chart) {
      return;
    }
    this.echart1.chart.dispose();
    this.echart1.chart = null;
    if (!this.echart2.chart) {
      return;
    }
    this.echart2.chart.dispose();
    this.echart2.chart = null;
    if (!this.echart3.chart) {
      return;
    }
    this.echart3.chart.dispose();
    this.echart3.chart = null;
  },
  methods: {
    setPieData: function setPieData(key) {
      this[key].chart = echarts.init(this.$refs[key], 'macarons');
      this.loadPieAction(key);
    },
    loadPieAction: function loadPieAction(key) {
      console.log(this[key]);
      if (this[key].result) {
        this[key].chart.setOption(this.setPie(this[key].result, this[key].type), key);
      } else {
        console.log('no data');
      }
      //   if (this[key].result[this[key].type]) {
      //     this[key].chart.setOption(
      //       this.setPie(this[key].result[this[key].type], this[key].type),
      //       key
      //     );
      //   } else {
      //     console.log('no data');
      //   }
    },
    setPie: function setPie(data, type) {
      var format = '{a|{b}\n{d}%}';
      if (type === 'userNumData') {
        format += ' {c}人';
      } else if (type === 'dealNumData') {
        format += ' {c}件';
      } else if (type === 'dealPriceData') {
        format += ' ￥{c}';
      } else if (type === 'numc') {
        format += '\n{a|{c}次}';
      }
      return {
        series: [{
          color: ['#2479f2', '#84b8fa', '#5f9bf8', '#cde4fc', '#aad0fb', '#e5f4ff'],
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          emphasis: {
            label: {
              show: true,
              fontSize: '14',
              fontWeight: 'bold'
            }
          },
          label: {
            formatter: format,
            rich: {
              a: {
                color: '#000'
              },
              b: {
                color: '#000'
              },
              c: {
                color: '#000'
              },
              d: {
                color: '#000'
              }
            }
          },
          data: data
        }]
      };
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex < 1) {
        if (row.merge > 0) {
          return {
            rowspan: row.merge,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    getList: function getList() {
      var _this2 = this;
      this.tableFrom.group_type = 'user_album';
      (0, _order.auctionStatusOrderListApi)(this.tableFrom).then(function (res) {
        var list = [];
        res.data.list.forEach(function (m) {
          m.orderList.forEach(function (item, i) {
            item.user = m.user;
            if (i === 0) {
              item.admin_discount_price = Number(item.admin_discount_price);
              item.album = m.album;
              item.userNumberPlate = m.userNumberPlate;
              item.venue_name = m.venue_name;
              item.merge = m.orderList.length;
            } else {
              item.venue_name = m.venue_name;
              item.album = m.album;
              item.merge = 0;
            }
            if (m.recharge_order) item.recharge_order = m.recharge_order;
            list.push(item);
          });
        });
        console.log(list);
        _this2.tableData.data = list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(data) {
      this.changeOrder = data;
    },
    // 表格某一行添加特定的样式
    addTdClass: function addTdClass(val) {
      if (val.row.status > 0 && val.row.paid === 1 && val.row.orderProduct && val.row.orderProduct.length) {
        for (var i = 0; i < val.row.orderProduct.length; i++) {
          if (val.row.orderProduct[i].refund_num >= 0 && val.row.orderProduct[i].refund_num < val.row.orderProduct[i].product_num) {
            return 'row-bg';
          }
        }
      } else {
        return ' ';
      }
    },
    changeSortAction: function changeSortAction(_ref2) {
      var column = _ref2.column,
        prop = _ref2.prop,
        order = _ref2.order;
      if (prop === 'payTime' && order === 'ascending') {
        // 箭头向上
        this.tableFrom.order = 'pay_time_asc';
      } else if (prop === 'payTime' && order === 'descending') {
        // 箭头向下
        this.tableFrom.order = 'pay_time_desc';
      } else if (prop === 'payTime') {
        this.tableFrom.order = '';
      }
      this.getList();
    },
    goOrderAction: function goOrderAction() {
      this.$emit('goOrder');
    }
  }
};