"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _marketing = require("@/api/marketing");
var _user = require("@/api/user");
var _selectCategory = _interopRequireDefault(require("@/components/customChildren/selectCategory.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    selectCategory: _selectCategory.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        page: 1,
        limit: 10,
        type: '1'
      },
      newDeliverDetail: {
        show: false,
        jump_link: '',
        send_user_object: '1',
        send_user_group: []
      },
      rules: {
        title: [{
          required: true,
          message: '请输入任务名称',
          trigger: 'blur'
        }],
        push_time: [{
          required: true,
          message: '请输入任务名称',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '请输入任务名称',
          trigger: 'blur'
        }]
      },
      userNumber: 0,
      userGroupList: []
    };
  },
  created: function created() {
    var _this = this;
    (0, _user.groupLstApi)({
      page: 1,
      limit: 50
    }).then(function (res) {
      _this.userGroupList = res.data.list;
    });
    this.getList(1);
  },
  methods: {
    onLink: function onLink() {
      this.$refs.setCategoryView.changeshow({
        name: '',
        link: ''
      });
    },
    onAdd: function onAdd() {
      this.getUNum();
      this.newDeliverDetail = {
        show: true,
        jump_link: '',
        send_user_object: '1',
        send_user_group: []
      };
    },
    onUpdate: function onUpdate(row) {
      console.log(row);
      this.userNumber = row.send_user_number;
      this.newDeliverDetail = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, row), {}, {
        task_id: row.id,
        send_user_object: row.send_user_object + '',
        send_user_group: row.send_user_group ? row.send_user_group.split(',').map(function (item) {
          return Number(item);
        }) : [],
        push_time: row.push_time * 1000,
        show: true
      });
    },
    selectMessage: function selectMessage(data) {
      this.newDeliverDetail.jump_link = data.data.link;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.newDeliverDetail), {}, {
            push_time: _this2.newDeliverDetail.push_time / 1000
          });
          if (_this2.newDeliverDetail.send_user_object == '4') {
            if (_this2.newDeliverDetail.send_user_group.length <= 0) {
              _this2.$message.error('请选择用户分组');
              return false;
            }
            model.send_user_group = _this2.newDeliverDetail.send_user_group.join(',');
          }
          var actionUrl;
          if (_this2.newDeliverDetail.task_id) actionUrl = _marketing.updateAppPushTask;else actionUrl = _marketing.createAppPushTask;
          actionUrl(model).then(function (res) {
            _this2.$message.success('操作成功');
            _this2.newDeliverDetail.show = false;
            _this2.getList(1);
          });
        }
      });
    },
    delAction: function delAction(task_id) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u4EFB\u52A1?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.delAppPushTask)({
          task_id: task_id
        }).then(function () {
          _this3.$message.success('删除成功');
          _this3.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    getUNum: function getUNum(val) {
      var _this4 = this;
      (0, _marketing.getUserNum)({
        group_ids: val ? val : ''
      }).then(function (res) {
        _this4.userNumber = res.data.num;
      });
    },
    changeTypeAction: function changeTypeAction(val) {
      this.userNumber = 0;
      if (Number(val) === 1) {
        this.getUNum();
        this.newDeliverDetail.send_user_group = [];
      }
    },
    changeUserGroupAction: function changeUserGroupAction(val) {
      if (val.length) {
        this.getUNum(val.join(','));
      } else {
        this.userNumber = 0;
      }
    },
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      (0, _marketing.appPushTaskList)(this.queryParams).then(function (res) {
        _this5.tableData.data = res.data.data;
        _this5.tableData.total = res.data.total;
        _this5.listLoading = false;
      }).catch(function (res) {
        _this5.listLoading = false;
      });
    }
  }
};