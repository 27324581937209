"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addClass = addClass;
exports.byteLength = byteLength;
exports.cleanArray = cleanArray;
exports.countDown = countDown;
exports.createUniqueString = createUniqueString;
exports.debounce = debounce;
exports.decrypt = decrypt;
exports.deepClone = deepClone;
exports.encrypt = encrypt;
exports.formatDateNumber = formatDateNumber;
exports.formatPrice = formatPrice;
exports.formatPriceNumber = formatPriceNumber;
exports.formatTime = formatTime;
exports.getMousePosition = getMousePosition;
exports.getQueryObject = getQueryObject;
exports.getTime = getTime;
exports.hasClass = hasClass;
exports.hexToRgb = hexToRgb;
exports.html2Text = html2Text;
exports.objectMerge = objectMerge;
exports.param = param;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
exports.removeClass = removeClass;
exports.setRgbaTo16 = setRgbaTo16;
exports.throttle = throttle;
exports.toggleClass = toggleClass;
exports.uniqueArr = uniqueArr;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
var _typeof2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
/**
 * Created by PanJiaChen on 16/11/18.
 */
var CryptoJS = require('crypto-js');

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if ((0, _typeof2.default)(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/*函数节流*/
function throttle(fn, interval) {
  var enterTime = 0; //触发的时间
  var gapTime = interval || 300; //间隔时间，如果interval不传，则默认300ms
  return function () {
    var context = this;
    var backTime = new Date(); //第一次函数return即触发的时间
    if (backTime - enterTime > gapTime) {
      fn.call(context, arguments[0]);
      enterTime = backTime; //赋值给第一次触发的时间，这样就保存了第二次触发的时间
    }
  };
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var _later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(_later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(_later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
function formatPriceNumber(num, cent, isThousand) {
  num = num.toString().replace(/\$|\,/g, '');
  cent = cent || 0;
  isThousand = isThousand || true;
  // 检查传入数值为数值类型
  if (isNaN(num)) num = "0";
  // 获取符号e799bee5baa6e79fa5e98193e58685e5aeb931333337393634(正/负数)
  var sign = num == (num = Math.abs(num));
  num = Math.floor(num * Math.pow(10, cent) + 0.50000000001); // 把指定的小数位先转换成整数.多余的小数位四舍五入
  var cents = num % Math.pow(10, cent); // 求出小数位数值
  num = Math.floor(num / Math.pow(10, cent)).toString(); // 求出整数位数值
  cents = cents.toString(); // 把小数位转换成字符串,以便求小数位长度
  // 补足小数位到指定的位数
  while (cents.length < cent) cents = "0" + cents;
  if (isThousand) {
    // 对整数部分进行千分位格式化.
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
  }
  if (cent > 0) return (sign ? '' : '-') + num + (cents > 0 ? '.' + cents : '');else return (sign ? '' : '-') + num;
}

/*
 * 格式化数字格式 元转分
 * n -> 要转换的金额(元)
 */
function formatPrice(n) {
  n = n.toString();
  var arr = n.split('');
  arr.reverse();
  var n1 = arr.join('');
  if (n1.indexOf('.') === -1) n = n + '00';else if (n1.indexOf('.') === 1) n = n.replace('.', '') + 0;else if (n1.indexOf('.') === 2) n = n.replace('.', '');else {
    var i = n1.indexOf('.');
    arr.splice(i - 2, 0, n1[i]);
    arr.splice(i + 1, 1);
    n = arr.reverse().join('');
  }
  return Number(n);
}
function countDown(lag, callBack, returnInt, noZero, hasDay) {
  var inter = null;
  if (lag <= 0) {
    return;
  }
  var timer = function timer() {
    lag -= 1;
    if (lag <= 0) {
      clearInterval(inter);
      callBack(false, false, false);
      return;
    }
    var second = '';
    if (noZero) {
      second = Math.floor(lag % 60);
    } else {
      second = formatDateNumber(Math.floor(lag % 60));
    }
    var minite = formatDateNumber(Math.floor(lag / 60 % 60));
    var hour = formatDateNumber(parseInt(lag / 3600));
    if (hasDay) {
      hour = formatDateNumber(parseInt(Math.floor(lag % (3600 * 24)) / 3600));
    }
    var day = formatDateNumber(parseInt(lag / 3600 / 24));
    if (callBack) {
      callBack(hour, minite, second, day);
    }
  };
  timer();
  inter = setInterval(timer, 1000);
  if (returnInt) {
    returnInt(inter);
  }
}
function formatDateNumber(n) {
  n = n.toString();
  return n[1] ? n : '0' + n;
}

/**
 * 接口数据加密函数
 * @param str string 需加密的json字符串
 * @param key string 加密key(16位)
 * @param iv string 加密向量(16位)
 * @return string 加密密文字符串
 */
function encrypt(str, k, i) {
  // 密钥16位
  var key = CryptoJS.enc.Utf8.parse(k);
  // 加密向量16位
  var iv = CryptoJS.enc.Utf8.parse(i);
  var encrypted = CryptoJS.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

/**
* 接口数据解密函数
* @param str string 已加密密文
* @param key string 加密key(16位)
* @param iv string 加密向量(16位)
* @returns {*|string} 解密之后的json字符串
*/
function decrypt(str, k, i) {
  var key = CryptoJS.enc.Utf8.parse(k);
  var iv = CryptoJS.enc.Utf8.parse(i);
  var decrypt = CryptoJS.AES.decrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
}
function getMousePosition(e) {
  var clientX = e.targetTouches ? e.targetTouches[0] ? e.targetTouches[0].pageX : 0 : e.clientX;
  var clientY = e.targetTouches ? e.targetTouches[0] ? e.targetTouches[0].pageY : 0 : e.clientY;
  var clientX1 = e.targetTouches ? e.targetTouches[1] ? e.targetTouches[1].pageX : 0 : '';
  var clientY1 = e.targetTouches ? e.targetTouches[1] ? e.targetTouches[1].pageY : 0 : '';
  return {
    x: clientX,
    y: clientY,
    x1: clientX1,
    y1: clientY1
  };
}
function hexToRgb(hex, opa) {
  // 去除#号（如果有）
  hex = hex.replace("#", "");

  // 将16进制颜色值拆分成r、g、b三个部分
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // 返回RGB颜色值
  return "rgba(" + r + ", " + g + ", " + b + ", " + (opa ? opa : 1) + ")";
}
function setRgbaTo16(str) {
  var reg = /^(rgb|RGB)/;
  if (!reg.test(str)) {
    return str;
  }
  var arr = '';
  if (str.indexOf('rgba') > -1) {
    arr = str.slice(5, str.length - 1).split(",");
  } else {
    arr = str.slice(4, str.length - 1).split(",");
  }
  var color = '#';
  var opaityarr = {
    100: 'FF',
    99: 'FC',
    98: 'FA',
    97: 'F7',
    96: 'F5',
    95: 'F2',
    94: 'F0',
    93: 'ED',
    92: 'E8',
    91: 'E8',
    90: 'E6',
    89: 'E3',
    88: 'E0',
    87: 'DE',
    86: 'DB',
    85: 'D9',
    84: 'D6',
    83: 'D4',
    82: 'D1',
    81: 'CF',
    80: 'CC',
    79: 'C9',
    78: 'C7',
    77: 'C4',
    76: 'C2',
    75: 'BF',
    74: 'BD',
    73: 'BA',
    72: 'B8',
    71: 'B5',
    70: 'B3',
    69: 'B0',
    68: 'AD',
    67: 'AB',
    66: 'A8',
    65: 'A6',
    64: 'A3',
    63: 'A1',
    62: '9E',
    61: '9C',
    60: '99',
    59: '96',
    58: '94',
    57: '91',
    56: '8F',
    55: '8C',
    54: '8A',
    53: '87',
    52: '85',
    51: '82',
    50: '80',
    49: '7D',
    48: '7A',
    47: '78',
    46: '75',
    45: '73',
    44: '70',
    43: '6E',
    42: '6B',
    41: '69',
    40: '66',
    39: '63',
    38: '61',
    37: '5E',
    36: '5C',
    35: '59',
    34: '57',
    33: '54',
    32: '52',
    31: '4F',
    30: '4D',
    29: '4A',
    28: '47',
    27: '45',
    26: '42',
    25: '40',
    24: '3D',
    23: '3B',
    22: '38',
    21: '36',
    20: '33',
    19: '30',
    18: '2E',
    17: '2B',
    16: '29',
    15: '26',
    14: '24',
    13: '21',
    12: '1F',
    11: '1C',
    10: '1A',
    9: '17',
    8: '14',
    7: '12',
    6: '0F',
    5: '0D',
    4: '0A',
    3: '08',
    2: '05',
    1: '03',
    0: '00'
  };
  for (var i = 0; i < arr.length; i++) {
    if (i <= 2) {
      var t = Number(arr[i]).toString(16);
      if (t == "0") {
        //如果为“0”的话，需要补0操作,否则只有5位数
        t = t + "0";
      } else if (t.length == 1) {
        t = "0" + t;
      }
      color += t;
    } else {
      var index = Math.round(arr[i] * 100);
      color += opaityarr[index];
    }
  }
  return color;
}

// setRgbTo16('rgb(255,0,255)');   // #FF00FF