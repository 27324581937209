"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "planeDetail",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      formValidate: {
        type: '',
        product_image: '',
        product_name: '',
        product_num: ''
      },
      ruleValidate: {
        type: [{
          required: true,
          message: "请选择类型"
        }],
        product_image: [{
          required: true,
          message: "请选择图片"
        }],
        product_name: [{
          required: true,
          message: "请输入商品名称"
        }],
        product_num: [{
          required: true,
          message: "请输入商品数量"
        }]
      },
      product: null,
      condition: [{
        image: '',
        name: '',
        neednum: ''
      }],
      conditiontype: '2'
    };
  },
  created: function created() {
    // this.id = this.$route.query.id || '';
    // if (this.id) {
    //     this.getPlaneDetail();
    // }
    // getPlaneDetail()
  },
  methods: {
    getPlaneDetail: function getPlaneDetail() {
      var _this2 = this;
      (0, _marketing.getPlaneDetail)({
        aircraft_id: this.id
      }).then(function (res) {
        console.log(res);
        _this2.formValidate = {
          aircraft_name: res.data.aircraft_name,
          aircraft_img: res.data.aircraft_img,
          success_rate: res.data.success_rate,
          planet_img: res.data.planet_img,
          planet_name: res.data.planet_name,
          durable: res.data.durable,
          duration: res.data.duration
        };
        if (res.data.exchange_conditions) {
          _this2.conditiontype = res.data.exchange_conditions.type + '';
          _this2.condition = res.data.exchange_conditions.data.map(function (item) {
            return {
              image: item.material_image,
              name: item.material_name,
              neednum: item.num,
              id: item.material_id
            };
          });
        }
      });
    },
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          var flag = false;
          // this.condition.forEach((item, index) => {
          //     if (!item.id) {
          //         flag = true
          //     }
          // })
          // if (flag) {
          //     return this.$message.error('请完善兑换条件或者删除无用的条件')
          // }
          var json = JSON.parse(JSON.stringify(_this3.formValidate));
          json.exchange_conditions = {
            type: _this3.conditiontype,
            coin_num: _this3.formValidate.coin_num,
            exchange_type: 3,
            data: []
          };
          // this.condition.forEach((item, index) => {
          //     if (item.id) {
          //         json.exchange_conditions.data.push({
          //             material_id: item.id,
          //             num: item.neednum || 1,
          //         })
          //     }
          // })
          json.game_type = 3;
          (0, _marketing.exchangeGoodsCreate)(json).then(function (res) {
            console.log(res);
            _this3.$message.success('创建成功');
            _this3.$router.back();
          });
        } else {
          _this3.$message.error('请完善信息');
        }
      });
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      var product = data.data[0];
      if (this.changeType == "output") {
        this.output[this.changeIndex].image = product.image;
        this.output[this.changeIndex].name = product.name;
        this.output[this.changeIndex].stock = product.stock;
        this.output[this.changeIndex].id = product.id;
      } else if (this.changeType == "condition") {
        this.condition[this.changeIndex].image = product.image;
        this.condition[this.changeIndex].name = product.name;
        this.condition[this.changeIndex].id = product.id;
      } else if (this.changeType == "durability") {
        this.durability[this.changeIndex].image = product.image;
        this.durability[this.changeIndex].name = product.name;
        this.durability[this.changeIndex].id = product.id;
      } else if (this.changeType == "product") {
        this.product = product;
        this.formValidate.product_id = product.id;
        this.formValidate.product_image = product.image;
        this.formValidate.product_name = product.name;
        // this.formValidate.product_num = product.stock;
      }
    },
    addlist: function addlist(type) {
      this.condition.push({
        image: '',
        name: '',
        neednum: ''
      });
    },
    dellist: function dellist(type, index) {
      if (type == 'output') {
        this.output.splice(index, 1);
      } else if (type == 'condition') {
        this.condition.splice(index, 1);
      } else if (type == 'durability') {
        this.durability.splice(index, 1);
      }
    },
    handleAdditem: function handleAdditem(type, i) {
      this.changeType = type;
      this.changeIndex = i;
      this.$refs.product_nft.changeshow();
    },
    // 修改图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          console.log(img);
          _this.formValidate[num] = img[0];
          _this.$refs.formValidate.clearValidate();
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = '';
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};