"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _ticket = require("@/api/ticket");
var _index = require("@/utils/index");
var _specTimes = _interopRequireDefault(require("./modules/specTimes"));
var _request = _interopRequireDefault(require("@/api/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SpecTimes: _specTimes.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        is_show: "",
        name: '',
        ticket_type: ''
      },
      showImg: false,
      // 查看图片 控制器
      imgUrl: "",
      // 放大查看的图片
      setStockData: {
        show: false,
        data: {
          type: 1,
          num: ""
        }
      },
      parseTime: _index.parseTime
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  mounted: function mounted() {
    var queryData = JSON.parse(JSON.stringify(this.$route.query));
    if (Number(queryData.page)) {
      this.tableFrom = queryData;
      this.tableFrom.page = Number(this.tableFrom.page);
      this.tableFrom.limit = Number(this.tableFrom.limit);
      this.tableFrom.ticket_type = Number(this.tableFrom.ticket_type);
    }
    this.getList(1);
  },
  methods: {
    setTimesAction: function setTimesAction(row) {
      if (this.$refs.specTimesEL) {
        this.$refs.specTimesEL.init(row.ticket_id, row.spec_id);
      }
    },
    setStockAction: function setStockAction(id) {
      this.setStockData = {
        show: true,
        data: {
          spec_id: id,
          type: 1,
          num: ""
        }
      };
    },
    setStockFinishAction: function setStockFinishAction() {
      var _this = this;
      this.$confirm("\u786E\u8BA4".concat(this.setStockData.data.type === 1 ? "增加" : "减少", "\u5E93\u5B58?"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _ticket.ticketSpecStockApi)(_this.setStockData.data).then(function () {
          _this.$message.success("操作成功");
          _this.setStockData.show = false;
          _this.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    createdAction: function createdAction() {
      this.$router.push("./detail");
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      var params = JSON.parse(JSON.stringify(this.tableFrom));
      if (params.ticket_type == 0) {
        params.ticket_type = '';
      }
      (0, _ticket.ticketListApi)(params).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    editSortAction: function editSortAction(val, index) {
      var _this3 = this;
      (0, _ticket.ticketEditSortApi)({
        ticket_id: this.tableData.data[index].ticket_id,
        sort: val
      }).then(function () {
        _this3.$message.success("修改排序成功");
        // this.load();
      }).catch(function () {});
    },
    editAction: function editAction(id) {
      this.$router.push("./detail?id=" + id);
    },
    delAction: function delAction(id) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u95E8\u7968?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _ticket.ticketDeleteApi)({
          ticket_id: id
        }).then(function () {
          _this4.$message.success("操作成功");
          _this4.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    setShowAction: function setShowAction(id, val) {
      var _this5 = this;
      this.$confirm("\u786E\u8BA4\u8981".concat(val == 1 ? "上架" : "下架", "\u8BE5\u95E8\u7968?"), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _ticket.ticketShowApi)({
          ticket_id: id,
          is_show: val
        }).then(function () {
          _this5.$message.success("操作成功");
          _this5.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    copyXcxLink: function copyXcxLink(m, event) {
      var url = "/pages/ticket/index?id=" + m.ticket_id;
      // const u = `/pages/otherPage/otherPage?link=${encodeURIComponent(url)}`;
      var input = document.createElement("input"); // 直接构建input
      input.value = url; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    copyH5Link: function copyH5Link(m, event) {
      var url = "/pages/ticket/index?id=" + m.ticket_id;
      var url1 = "";
      if (this.domain) {
        url1 = "".concat(this.domain).concat(url);
      } else {
        url1 = "".concat(this.domain_url).concat(url);
      }
      var input = document.createElement("input"); // 直接构建input
      input.value = url1; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    showImgAction: function showImgAction(val, m) {
      var _this6 = this;
      // let action = process.env.VUE_APP_BASE_API + '/api/sharePoster';
      var data = {
        ticket_id: m.ticket_id,
        type: val,
        mer_id: this.merId
      };
      this.imgUrl = "";

      // this.imgData.imgUrl = action;
      this.showImg = true;
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      (0, _ticket.ticketShareApi)(data).then(function (res) {
        loading.close();
        if (val == 3 || val == 4) {
          _this6.imgUrl = res.data.base_image;
        } else {
          _this6.imgUrl = res.data;
        }
      }).catch(function () {
        loading.close();
      });
    }
  }
};