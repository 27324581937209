var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "padding20 bg-white borderRadius6" }, [
        _c(
          "div",
          { staticClass: "remove-control form-view " },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm ",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "140px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "等级名称：", prop: "level_name" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入等级名称" },
                          model: {
                            value: _vm.ruleForm.level_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "level_name", $$v)
                            },
                            expression: "ruleForm.level_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "等级图片：", prop: "level_icon" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      [
                        _vm.ruleForm.level_icon
                          ? [
                              _c("div", { staticClass: "img-list mr10" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.level_icon + "!120a",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImageAction("level_icon")
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delImgAction("level_icon")
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-close" })]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c mr10",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg("level_icon")
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "升级所需要成长值：",
                      prop: "upgrade_growth_value",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入升级成长值" },
                          model: {
                            value: _vm.ruleForm.upgrade_growth_value,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "upgrade_growth_value",
                                _vm._n($$v)
                              )
                            },
                            expression: "ruleForm.upgrade_growth_value",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "购买商品折扣：",
                      prop: "product_discount_percent",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input-number", {
                          staticClass: "w100",
                          attrs: {
                            placeholder: "请输入折扣",
                            controls: false,
                            min: 0,
                            max: 100,
                          },
                          model: {
                            value: _vm.ruleForm.product_discount_percent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "product_discount_percent",
                                $$v
                              )
                            },
                            expression: "ruleForm.product_discount_percent",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml5" }, [_vm._v("%")]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "购买门票折扣：",
                      prop: "ticket_discount_percent",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input-number", {
                          staticClass: "w100",
                          attrs: {
                            placeholder: "请输入折扣",
                            controls: false,
                            min: 0,
                            max: 100,
                          },
                          model: {
                            value: _vm.ruleForm.ticket_discount_percent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "ticket_discount_percent",
                                $$v
                              )
                            },
                            expression: "ruleForm.ticket_discount_percent",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml5" }, [_vm._v("%")]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "鉴定折扣：",
                      prop: "identification_discount_percent",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input-number", {
                          staticClass: "w100",
                          attrs: {
                            placeholder: "请输入折扣",
                            controls: false,
                            min: 0,
                            max: 100,
                          },
                          model: {
                            value: _vm.ruleForm.identification_discount_percent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "identification_discount_percent",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.identification_discount_percent",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml5" }, [_vm._v("%")]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "查看答案折扣比例：",
                      prop: "identification_study_discount_percent",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input-number", {
                          staticClass: "w100",
                          attrs: {
                            placeholder: "请输入折扣",
                            controls: false,
                            min: 0,
                            max: 100,
                          },
                          model: {
                            value:
                              _vm.ruleForm
                                .identification_study_discount_percent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "identification_study_discount_percent",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.identification_study_discount_percent",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml5" }, [_vm._v("%")]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "拍卖佣金折扣比例：",
                      prop: "auction_commission_discount_percent",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input-number", {
                          staticClass: "w100",
                          attrs: {
                            placeholder: "请输入折扣",
                            controls: false,
                            min: 0,
                            max: 100,
                          },
                          model: {
                            value:
                              _vm.ruleForm.auction_commission_discount_percent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "auction_commission_discount_percent",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.auction_commission_discount_percent",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml5" }, [_vm._v("%")]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "添加权益：" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleAdditem },
                      },
                      [_vm._v("添加权益")]
                    ),
                    _vm._v(" "),
                    _vm.benefitsArr.length > 0
                      ? _c(
                          "el-table",
                          {
                            staticClass: "table",
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.benefitsArr, size: "mini" },
                          },
                          [
                            _vm.ruleForm.right_type != 6
                              ? _c("el-table-column", {
                                  attrs: { label: "权益图标", width: "80" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("img", {
                                              staticStyle: {
                                                width: "40px",
                                                height: "40px",
                                                display: "block",
                                              },
                                              attrs: {
                                                src: scope.row.image,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1643538639
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "名称", prop: "name" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "权益介绍" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.showDesc) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3154319727
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "操作", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delProductAction(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2318904109
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: " " } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.saveAction("ruleForm")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.id ? "修改等级" : "创建等级"))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("Benefits", {
        ref: "benefits",
        attrs: { "current-data": _vm.benefitsArr },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.ruleForm[_vm.imagesViewKey] },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }