"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      status: 1,
      resultData: [],
      listLoading: true,
      searchQuery: {},
      channelVisible: false,
      channelForm: {},
      channelType: {},
      queryParams: {},
      isClickCreate: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _aiMark.getChannelsTypeApi)().then(function (res) {
      var obj = {};
      for (var k in res.data) {
        var arr = [];
        for (var key in res.data[k]) {
          arr.push({
            label: res.data[k][key],
            value: key
          });
        }
        obj[k] = arr;
      }
      // console.log(obj);
      _this.channelType = obj;
    });
  },
  methods: {
    mackTitChange: function mackTitChange(row) {
      (0, _aiMark.promotionChannelsApi)(row.id, {
        account_name: row.account_name
      }).then(function (res) {});
    },
    onAccount: function onAccount() {
      var _this2 = this;
      (0, _aiMark.createDefaultChannelsApi)().then(function (res) {
        _this2.$message.success('操作成功');
        _this2.getList();
      });
    },
    onUpdHand: function onUpdHand(row) {
      this.channelForm = (0, _objectSpread2.default)((0, _objectSpread2.default)({
        title: '修改资源'
      }, row), {}, {
        channels_type: row.channels_type + ''
      });
      this.channelVisible = true;
    },
    onDeleHand: function onDeleHand(row) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u8D26\u53F7?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _aiMark.promotionChannelsDeleApi)(row.id).then(function (res) {
          _this3.$message.success('操作成功');
          _this3.getList();
        });
      }).catch(function () {});
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.queryParams = Object.assign({}, e);
      this.getList(1);
    },
    onDetails: function onDetails(row) {
      this.$router.push({
        name: 'chanDetails',
        query: {
          resData: JSON.stringify(row)
        }
      });
    },
    onAdd: function onAdd() {
      var arr = this.channelForm.account_bind_content;
      var row = arr[arr.length - 1];
      if (row.make_type && row.make_tag) this.channelForm.account_bind_content.push({
        make_type: '',
        make_tag: ''
      });
    },
    onOpenDia: function onOpenDia() {
      this.channelVisible = true;
      this.channelForm = {
        title: '新增资源',
        account_bind_content: [{
          make_type: '',
          make_tag: ''
        }]
      };
    },
    onSubmit: function onSubmit() {
      var _this4 = this;
      var actionUrl = '';
      var model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.channelForm), {}, {
        account_bind_content_type: this.channelForm.account_bind_content_type.join(',')
      });
      if (this.channelForm.id) {
        actionUrl = (0, _aiMark.promotionChannelsUpdApi)(this.channelForm.id, model);
      } else actionUrl = (0, _aiMark.promotionCreateApi)(model);
      actionUrl.then(function (res) {
        // console.log(res);
        _this4.$message.success('操作成功');
        _this4.channelVisible = false;
        _this4.getList();
      });
    },
    getList: function getList() {
      var _this5 = this;
      this.listLoading = true;
      (0, _aiMark.groupListApi)((0, _objectSpread2.default)({
        channels_type: this.status
      }, this.queryParams)).then(function (res) {
        // console.log(res);
        _this5.isClickCreate = res.data.isClickCreate;
        _this5.resultData = res.data.list;
        _this5.listLoading = false;
      });
    }
  }
};