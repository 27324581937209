"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _fuse = _interopRequireDefault(require("fuse.js"));
var _path = _interopRequireDefault(require("path"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// fuse is a lightweight fuzzy-search module
// make search results more in line with expectations
var _default = exports.default = {
  name: 'headerSearch',
  data: function data() {
    return {
      search: '',
      options: [],
      searchPool: [],
      show: false,
      fuse: undefined
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['menuList'])),
  watch: {
    routes: function routes() {
      this.searchPool = this.generateRoutes(this.menuList);
    },
    searchPool: function searchPool(list) {
      this.initFuse(list);
    },
    show: function show(value) {
      if (value) {
        document.body.addEventListener('click', this.close);
      } else {
        document.body.removeEventListener('click', this.close);
      }
    }
  },
  mounted: function mounted() {
    this.searchPool = this.generateRoutes(this.menuList);
  },
  methods: {
    click: function click() {
      this.show = !this.show;
      if (this.show) {
        this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.focus();
      }
    },
    close: function close() {
      this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.blur();
      this.options = [];
      this.show = false;
    },
    change: function change(val) {
      var _this = this;
      this.$router.push(val.route);
      this.search = '';
      this.options = [];
      this.$nextTick(function () {
        _this.show = false;
      });
    },
    initFuse: function initFuse(list) {
      this.fuse = new _fuse.default(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: 'menu_name',
          weight: 0.7
        }, {
          name: 'route',
          weight: 0.3
        }]
      });
    },
    // Filter out the routes that can be displayed in the sidebar
    // And generate the internationalized title
    generateRoutes: function generateRoutes(routes) {
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var prefixTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var res = [];
      var _iterator = (0, _createForOfIteratorHelper2.default)(routes),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var router = _step.value;
          // skip hidden router
          if (router.hidden) {
            continue;
          }
          var data = {
            route: _path.default.resolve(basePath, router.route),
            menu_name: (0, _toConsumableArray2.default)(prefixTitle),
            children: router.children || []
          };
          if (router.menu_name) {
            data.menu_name = [].concat((0, _toConsumableArray2.default)(data.menu_name), [router.menu_name]);
            if (router.redirect !== 'noRedirect') {
              // only push the routes with title
              // special case: need to exclude parent router without redirect
              res.push(data);
            }
          }
          // recursive child routes
          if (router.children) {
            var tempRoutes = this.generateRoutes(router.children, data.route, data.menu_name);
            if (tempRoutes.length >= 1) {
              res = [].concat((0, _toConsumableArray2.default)(res), (0, _toConsumableArray2.default)(tempRoutes));
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return res;
    },
    querySearch: function querySearch(query) {
      if (query !== '') {
        this.options = this.fuse.search(query);
      } else {
        this.options = [];
      }
    }
  }
};