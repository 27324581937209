import { render, staticRenderFns } from "./auction_status.vue?vue&type=template&id=08564b5d&scoped=true"
import script from "./auction_status.vue?vue&type=script&lang=js"
export * from "./auction_status.vue?vue&type=script&lang=js"
import style0 from "./auction_status.vue?vue&type=style&index=0&id=08564b5d&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08564b5d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08564b5d')) {
      api.createRecord('08564b5d', component.options)
    } else {
      api.reload('08564b5d', component.options)
    }
    module.hot.accept("./auction_status.vue?vue&type=template&id=08564b5d&scoped=true", function () {
      api.rerender('08564b5d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/customPage/auction_status.vue"
export default component.exports