"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      pageLoading: true,
      changeAlbum: 0,
      startLot: 1,
      albumList: [],
      venue_id: '',
      lock: false
    };
  },
  computed: {
    startSort: function startSort() {
      var count = 0;
      for (var i = 0; i < this.changeAlbum; i += 1) {
        count += this.albumList[i].auctionList.length;
      }
      return count;
    }
  },
  created: function created() {
    var _this = this;
    this.venue_id = this.$route.query.v_id;
    (0, _auction.getBatchEditOrderNumLAuctionListApi)({
      venue_id: this.venue_id
    }).then(function (res) {
      if (res.data.auctionList) {
        _this.startLot = res.data.startLot;
        _this.albumList = res.data.auctionList;
      } else {
        _this.$message.error('出现错误，请返回重试');
        setTimeout(function () {
          _this.$router.back();
        }, 1000);
      }
      _this.pageLoading = false;
    }).catch(function () {
      _this.pageLoading = false;
    });
  },
  methods: {
    setData: function setData(e) {},
    saveAction: function saveAction() {
      var _this2 = this;
      if (this.lock) return;
      this.lock = true;
      var data = [];
      var lot = this.startLot || 1;
      this.albumList.forEach(function (album, albumIndex) {
        album.auctionList.forEach(function (auction, i) {
          data.push({
            auction_id: auction.auction_id,
            lot: lot,
            order_num: i + 1
          });
          lot += 1;
        });
      });
      (0, _auction.batchEditOrderNumLAuctionApi)({
        auction: data,
        venue_id: this.venue_id
      }).then(function (res) {
        _this2.$message.success('保存成功');
        _this2.lock = false;
      }).catch(function () {
        _this2.lock = false;
      });
    }
  }
};