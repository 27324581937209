"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _nft = require("@/api/nft");
var _product = require("@/api/product");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "addBlindBox",
  data: function data() {
    return {
      currentTab: 0,
      fullscreenLoading: false,
      formValidate: {
        cate: '',
        ahead_time: ''
      },
      ruleValidate: {
        cate: [{
          required: true,
          message: "请选择关联名单",
          trigger: "change"
        }],
        ahead_time: [{
          required: true,
          message: "请输入提前发售时间",
          trigger: "blur"
        }]
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      queryParams: {
        page: 1,
        limit: 20
      },
      newtableData: {
        data: [],
        total: 0
      },
      newqueryParams: {
        page: 1,
        limit: 20
      },
      newtableFrom: {
        page: 1,
        limit: 20
      },
      albumtableData: {
        data: [],
        total: 0
      },
      albumqueryParams: {
        page: 1,
        limit: 20
      },
      albumtableFrom: {
        page: 1,
        limit: 20
      },
      orderidArr: [],
      orderArr: [],
      merCateList: [],
      cate: '',
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      tab: 'box',
      lockid: '',
      locktype: '',
      usercount: 0
    };
  },
  created: function created() {
    var _this2 = this;
    this.getList(1);
    this.getnewList(1);
    this.getAlbumList(1);
    (0, _product.snapshotListApi)({
      page: 1,
      limit: 100,
      perform_status: 1
    }).then(function (res) {
      _this2.merCateList = res.data.list;
    });
    if (this.$route.query.id) {
      //   this.currentTab = 1;
      this.getBoxDetail(this.$route.query.id);
    }
  },
  methods: {
    leavetab: function leavetab() {
      if (this.locktype) {
        this.tab = this.locktype;
        return false;
      }
    },
    changetab: function changetab(e) {
      if (this.locktype && e.name != this.locktype) {
        return;
      }
      this.tab = e.name;
    },
    handleselectCate: function handleselectCate(e) {
      var arr = this.merCateList.filter(function (item) {
        return e.indexOf(item.snapshot_id) > -1;
      });
      var num = 0;
      if (arr.length) {
        arr.forEach(function (item) {
          num += item.userCount;
        });
        this.usercount = num;
      } else {
        this.usercount = 0;
      }
    },
    remotetag: function remotetag() {},
    getBoxDetail: function getBoxDetail(id) {
      var _this3 = this;
      (0, _product.priorityDetailApi)(id).then(function (res) {
        var data = res.data;
        var cateidarr = data.snapshotData.map(function (item) {
          return item.snapshot_id;
        });
        setTimeout(function () {
          _this3.handleselectCate(cateidarr);
        }, 2000);
        _this3.formValidate = {
          ahead_time: data.ahead_time,
          cate: cateidarr
        };
        if (data.type == 3) {
          data.productData.forEach(function (item) {
            item.start_time = (0, _index.parseTime)(item.start_time);
          });
        }
        _this3.orderArr = data.productData;
        _this3.orderidArr = data.productData.map(function (item) {
          return item.product_id;
        });
        if (data.type == 2) {
          _this3.locktype = 'box';
          _this3.tab = 'box';
        } else if (data.type == 3) {
          _this3.locktype = 'album', _this3.tab = 'album';
        } else {
          _this3.locktype = 'goods';
          _this3.tab = 'goods';
        }
      });
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      (0, _nft.nftCollectionListApi)(this.queryParams).then(function (res) {
        res.data.list.forEach(function (item) {
          if (+new Date(item.start_time) > +new Date() && item.status == 1) {
            item.canselect = true;
          }
        });
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    // 列表
    getnewList: function getnewList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.newtableFrom.page = num || this.newtableFrom.page;
      (0, _nft.getBoxList)(this.newtableFrom).then(function (res) {
        _this5.newtableData.data = res.data.list;
        _this5.newtableData.total = res.data.count;
        _this5.listLoading = false;
      }).catch(function (res) {
        _this5.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.newtableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.newtableFrom.limit = val;
      this.getList();
    },
    handleAlbumSizeChange: function handleAlbumSizeChange(val) {
      this.albumqueryParams.limit = val;
      this.getAlbumList();
    },
    getAlbumList: function getAlbumList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.albumqueryParams.page = num || this.albumqueryParams.page;
      (0, _nft.nftCollectionAlbumListApi)(this.albumqueryParams).then(function (res) {
        res.data.list.forEach(function (item) {
          if (item.start_time > res.server_time && item.album_status == 1) {
            item.canselect = true;
          }
          item.newstart_time = (0, _index.parseTime)(item.start_time);
        });
        _this6.albumtableData.data = res.data.list;
        _this6.albumtableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
    },
    atteLeavetooltip: function atteLeavetooltip(h, _ref) {
      var column = _ref.column,
        $index = _ref.$index;
      return [column.label, h("el-tooltip", {
        props: {
          effect: "dark",
          placement: "top",
          content: "开关打开后，此藏品品在藏品列表中不显示。"
        }
      }, [h("span", {
        class: {
          "el-icon-question": true
        }
      })])];
    },
    submitForm: function submitForm() {
      var _this7 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          if (!_this7.orderidArr.length) {
            _this7.$message.error("请选择藏品");
            return;
          }
          _this7.formValidate.snapshotData = _this7.formValidate.cate;
          if (_this7.locktype == 'album') {
            _this7.formValidate.productData = _this7.orderArr.map(function (item) {
              return {
                product_id: item.nft_album_id || item.product_id,
                nft_album_id: item.nft_album_id || item.product_id,
                buy_limit_num: item.buy_limit_num || 0,
                product_type: 'nft_album'
              };
              // return item.product_id
            });
          } else {
            _this7.formValidate.productData = _this7.orderArr.map(function (item) {
              return {
                product_id: item.product_id,
                buy_limit_num: item.buy_limit_num || 0,
                product_type: 'product'
              };
              // return item.product_id
            });
          }
          if (_this7.locktype == 'goods') {
            _this7.formValidate.type = 1;
          } else if (_this7.locktype == 'album') {
            _this7.formValidate.type = 3;
          } else {
            _this7.formValidate.type = 2;
          }
          var loading = _this7.$loading({
            lock: true,
            text: "保存中……",
            spinner: "el-icon-loading"
          });
          if (_this7.$route.query.id) {
            (0, _product.priorityUpdateApi)(_this7.$route.query.id, _this7.formValidate).then(function () {
              loading.close();
              _this7.$message.success("保存成功");
              _this7.$router.back();
            });
            return;
          }
          (0, _product.priorityCreateApi)(_this7.formValidate).then(function (res) {
            loading.close();
            _this7.$message({
              message: "保存成功",
              type: "success"
            });
            _this7.$router.back();
            _this7.$emit("close");
          }).catch(function (res) {
            loading.close();
            // this.$message.error(res.message);
          });
        }
      });
    },
    backAction: function backAction() {
      this.$router.go(-1);
    },
    selectalbum: function selectalbum(row, index, type) {
      if (this.orderidArr.indexOf(row.nft_album_id) > -1) {
        return;
      }
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      var newrow = JSON.parse(JSON.stringify(row));
      newrow.buy_limit_num = 0;
      this.orderArr.push(newrow);
      this.orderidArr.push(row.nft_album_id);
      this.locktype = this.tab;
    },
    selectinver: function selectinver(row, index, type) {
      if (this.orderidArr.indexOf(row.product_id) > -1) {
        return;
      }
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      var newrow = JSON.parse(JSON.stringify(row));
      this.orderArr.push(newrow);
      this.orderidArr.push(row.product_id);
      this.locktype = this.tab;
    },
    del: function del(index, row) {
      var id = '';
      if (row.album_name) {
        id = row.nft_album_id;
      } else {
        id = row.product_id;
      }
      var nindex = this.orderidArr.indexOf(id);
      this.orderidArr.splice(nindex, 1);
      this.orderArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.orderidArr.indexOf(row.product_id);
      this.orderidArr.splice(nindex, 1);
      this.orderArr.splice(nindex, 1);
      if (this.orderArr.length <= 0) {
        this.locktype = '';
        this.lockid = '';
      }
    },
    cancelSelectAlbum: function cancelSelectAlbum(index, row) {
      row.selected = false;
      var nindex = this.orderidArr.indexOf(row.nft_album_id);
      this.orderidArr.splice(nindex, 1);
      this.orderArr.splice(nindex, 1);
      if (this.orderArr.length <= 0) {
        this.locktype = '';
        this.lockid = '';
      }
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          if (num == "top") {
            _this.formValidate.topimage = img;
          } else if (num == "left") {
            _this.formValidate.leftimage = img;
          } else if (num == "right") {
            _this.formValidate.rightimage = img;
          }
        }
      }, tit);
    }
  }
};