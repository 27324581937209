var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "padding20 bg-white borderRadius6",
          staticStyle: { "min-height": "100vh" },
        },
        [
          _c(
            "div",
            { staticClass: "remove-control form-view" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合成名称：", prop: "title" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入活动名称" },
                            model: {
                              value: _vm.ruleForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "title", $$v)
                              },
                              expression: "ruleForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头部Banner：", prop: "banner_image" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "banner_image")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.banner_image
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.banner_image + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合成时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          align: "right",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.assist_date,
                          callback: function ($$v) {
                            _vm.assist_date = $$v
                          },
                          expression: "assist_date",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v("不填写则默认为长期有效"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合成须知：" } },
                    [
                      _c("Tinymce", {
                        ref: "editornew",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.ruleForm.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "content", $$v)
                          },
                          expression: "ruleForm.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.id,
                expression: "id",
              },
            ],
            attrs: { "content-position": "left" },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.id,
                  expression: "id",
                },
              ],
            },
            [
              _c("div", { staticClass: "playname" }, [_vm._v("玩法设置")]),
              _vm._v(" "),
              _vm._l(_vm.playarr, function (item, index) {
                return _c(
                  "el-form",
                  {
                    key: "rule" + index,
                    staticClass: "demo-ruleForm",
                    attrs: { "label-width": "120px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "玩法名称：", required: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入玩法名称" },
                          model: {
                            value: item.title,
                            callback: function ($$v) {
                              _vm.$set(item, "title", $$v)
                            },
                            expression: "item.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "玩法说明：", required: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入玩法说明" },
                          model: {
                            value: item.synthetic_info,
                            callback: function ($$v) {
                              _vm.$set(item, "synthetic_info", $$v)
                            },
                            expression: "item.synthetic_info",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否展示剩余数量" } },
                      [
                        _c("el-switch", {
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          model: {
                            value: item.is_show_remaining_synthetic_number,
                            callback: function ($$v) {
                              _vm.$set(
                                item,
                                "is_show_remaining_synthetic_number",
                                $$v
                              )
                            },
                            expression:
                              "item.is_show_remaining_synthetic_number",
                          },
                        }),
                        _vm._v("   开启后，用户端展示剩余数量\n        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "合成材料：", required: "" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: item.synthetic_material_condition,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "synthetic_material_condition",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.synthetic_material_condition",
                                },
                              },
                              [_vm._v("全部满足")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: item.synthetic_material_condition,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "synthetic_material_condition",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "item.synthetic_material_condition",
                                },
                              },
                              [_vm._v("满足任意一项")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            staticClass: "table",
                            staticStyle: {
                              width: "100%",
                              "margin-top": "-10px",
                            },
                            attrs: {
                              "tooltip-effect": "dark",
                              data: item.syntheticMaterialData,
                              size: "mini",
                              "span-method": _vm.objectSpanMethod,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { width: "80", label: "编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(scope.row.serial_number) +
                                            "\n                "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { width: "80", label: "图片" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            scope.row.image
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.image + "!120a",
                                                    "preview-src-list": [
                                                      scope.row.image,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "名称", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.store_name) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "合成后处理",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 0 },
                                            model: {
                                              value: scope.row.is_destroy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "is_destroy",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.is_destroy",
                                            },
                                          },
                                          [_vm._v("回收")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 1 },
                                            model: {
                                              value: scope.row.is_destroy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "is_destroy",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.is_destroy",
                                            },
                                          },
                                          [_vm._v("销毁")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: 2 },
                                            model: {
                                              value: scope.row.is_destroy,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "is_destroy",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.is_destroy",
                                            },
                                          },
                                          [_vm._v("保留")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "min-width": "140",
                                label: "" + item.syntheticMaterialData.length,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                合成需要数量\n              "
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input-number", {
                                          attrs: { min: 1 },
                                          on: {
                                            change: function ($event) {
                                              return _vm.editPlay(
                                                item,
                                                $event,
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.need_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "need_number",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.need_number",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                "min-width": "80",
                                fixed: "right",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.cancelSelect(
                                                      scope.$index,
                                                      scope.row,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    删除\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.syntheticMaterialData.length > 1
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "20px" } },
                              [
                                _vm._v(
                                  "\n            设置总量：\n            "
                                ),
                                _vm._l(item.ruleList, function (it, ins) {
                                  return _c(
                                    "span",
                                    { key: "srule" + ins },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(it.name) +
                                          " = " +
                                          _vm._s(it.result)
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-right": "20px",
                                          },
                                          attrs: {
                                            type: "text",
                                            size: "default",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleterule(index, ins)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "default" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addresult(index)
                                      },
                                    },
                                  },
                                  [_vm._v("添加规则")]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAdditem(
                                  "product_nft",
                                  index,
                                  item
                                )
                              },
                            },
                          },
                          [_vm._v("\n            添加合成材料\n          ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "合成结果：", required: "" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: item.synthetic_result_condition,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "synthetic_result_condition",
                                      $$v
                                    )
                                  },
                                  expression: "item.synthetic_result_condition",
                                },
                              },
                              [_vm._v("全部合成")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: item.synthetic_result_condition,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "synthetic_result_condition",
                                      $$v
                                    )
                                  },
                                  expression: "item.synthetic_result_condition",
                                },
                              },
                              [_vm._v("随机合成一项")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            staticClass: "table",
                            staticStyle: {
                              width: "100%",
                              "margin-top": "-10px",
                            },
                            attrs: {
                              "tooltip-effect": "dark",
                              data: item.syntheticProductData,
                              size: "mini",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "类型" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.type == 1
                                          ? _c("span", [_vm._v("NFT藏品")])
                                          : scope.row.type == 2
                                          ? _c("span", [_vm._v("NFT盲盒")])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { width: "80", label: "图片" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            scope.row.image
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.image + "!120a",
                                                    "preview-src-list": [
                                                      scope.row.image,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "名称", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.store_name) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "预留库存", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              scope.row.reserved_number || ""
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "单次合成数量" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "输入单次合成数量",
                                          },
                                          model: {
                                            value:
                                              scope.row.single_synthetic_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "single_synthetic_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.single_synthetic_number",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "单用户最多可合成数量" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "输入单用户最多可合成数量",
                                          },
                                          model: {
                                            value:
                                              scope.row.can_synthetic_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "can_synthetic_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.can_synthetic_number",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "合成总数量" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "输入合成总数量",
                                          },
                                          model: {
                                            value:
                                              scope.row.total_synthetic_number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "total_synthetic_number",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.total_synthetic_number",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "default",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delreault(
                                                  scope.$index,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "compose-result-goods",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectreaultProduct(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              添加合成对象\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    item.synthetic_id
                      ? _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteplay(item.synthetic_id)
                                  },
                                },
                              },
                              [_vm._v("\n            删除玩法\n          ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.newsaveplay(
                                      item.synthetic_id,
                                      index
                                    )
                                  },
                                },
                              },
                              [_vm._v("\n            保存玩法\n          ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    index != _vm.playarr.length - 1
                      ? _c(
                          "el-form-item",
                          [
                            _c("el-divider", {
                              attrs: { "content-position": "left" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "10px", "margin-left": "120px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.savePlay },
                },
                [_vm._v("\n        添加玩法\n      ")]
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              staticStyle: {
                "margin-left": "120px",
                width: "200px",
                "margin-top": "30px",
              },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAction("ruleForm")
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.id ? "确认提交" : "下一步") + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建规则",
            visible: _vm.addruleflag,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addruleflag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.addruleList,
                          callback: function ($$v) {
                            _vm.addruleList = $$v
                          },
                          expression: "addruleList",
                        },
                      },
                      _vm._l(_vm.playslist, function (item, index) {
                        return _c(
                          "el-checkbox",
                          {
                            key: "rule" + index,
                            attrs: { label: item.product_id },
                          },
                          [
                            _vm._v(
                              _vm._s(item.serial_number) +
                                _vm._s(item.store_name)
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "需要数量", size: "normal" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入需要数量" },
                    model: {
                      value: _vm.addrulenum,
                      callback: function ($$v) {
                        _vm.addrulenum = $$v
                      },
                      expression: "addrulenum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "default" },
                      on: { click: _vm.saverule },
                    },
                    [_vm._v("创建")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectProduct", {
        ref: "product",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1", status: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }