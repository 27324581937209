"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var renovationRouter = {
  path: "".concat(_settings.roterPre, "/pageManagement"),
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/pageManagement/renovation/diy"),
  meta: {
    title: '页面装修'
  },
  children: [{
    path: 'renovation',
    name: 'renovation',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/index'));
      });
    },
    meta: {
      title: '页面装修',
      noCache: true
    },
    children: [{
      path: 'diy',
      name: 'renovation_diy',
      redirect: '/merchant/pageManagement/decoration/classicTemplate',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/diy'));
        });
      },
      meta: {
        title: '自定义页面',
        noCache: true
      }
    }, {
      path: 'base_page',
      name: 'renovation_base',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/basicPage'));
        });
      },
      meta: {
        title: '基础页面',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/renovation/diy")
      }
    }, {
      path: 'base_set',
      name: 'renovation_base',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/base'));
        });
      },
      meta: {
        title: '基础页面配置',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/renovation/diy")
      }
    }]
  }, {
    path: 'decoration',
    name: 'decoration',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/decoration'));
      });
    },
    meta: {
      title: '装修',
      noCache: true
    },
    children: [
    // {
    //   path: 'base_page',
    //   name: 'renovation_base',
    //   component: () => import('@/views/page-renovation/basicPage'),
    //   meta: { title: '基础页面', noCache: true, activeMenu: `${roterPre}/pageManagement/renovation/diy` }
    // },
    // {
    //   path: 'base_set',
    //   name: 'renovation_base',
    //   component: () => import('@/views/page-renovation/base'),
    //   meta: { title: '基础页面配置', noCache: true, activeMenu: `${roterPre}/pageManagement/renovation/diy` }
    // },
    {
      path: 'classicTemplate',
      name: 'classic_template',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/classicTemplate'));
        });
      },
      meta: {
        title: '经典模版',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/decoration/classicTemplate"),
        permissionKey: '/pageManagement/decoration/classicTemplate'
      }
    }, {
      path: 'startPage',
      name: 'start_page',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/startPage'));
        });
      },
      meta: {
        title: '启动页图片',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/decoration/classicTemplate")
      }
    }, {
      path: 'diy',
      name: 'renovation_diy',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/diy'));
        });
      },
      meta: {
        title: '自定义页面',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/decoration/classicTemplate"),
        permissionKey: '/pageManagement/decoration/diy'
      }
    }, {
      path: 'templateMall',
      name: 'TemplateMall',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/page-renovation/templateMall'));
        });
      },
      meta: {
        title: '模板商城',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/decoration/classicTemplate")
      }
    }]
  }]
};
var _default = exports.default = renovationRouter;