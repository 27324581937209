var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { position: "relative" } },
    [
      _c("div", { staticClass: "padding20 bg-white borderRadius6" }, [
        _c(
          "div",
          { staticClass: "remove-control form-view " },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm ",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "权益名称：", prop: "right_name" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入权益名称" },
                          model: {
                            value: _vm.ruleForm.right_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "right_name", $$v)
                            },
                            expression: "ruleForm.right_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "权益图标：", prop: "right_image" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      [
                        _vm.ruleForm.right_image
                          ? [
                              _c("div", { staticClass: "img-list mr10" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.right_image + "!120a",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImageAction("right_image")
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delImgAction("right_image")
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-close" })]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c mr10",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg("right_image")
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "权益类型：", prop: "right_type" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w300",
                            on: { change: _vm.editRightTypeAction },
                            model: {
                              value: _vm.ruleForm.right_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "right_type", $$v)
                              },
                              expression: "ruleForm.right_type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 2, label: "商品" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 3, label: "门票" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 6, label: "优惠券" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 4, label: "积分" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 5, label: "余额" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 1, label: "场馆签到" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 0, label: "自定义权益" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.ruleForm.right_type > 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "权益内容：", required: "" } },
                      [
                        _vm.ruleForm.right_type == 4 ||
                        _vm.ruleForm.right_type == 5
                          ? [
                              _c("el-input-number", {
                                staticClass: "w200",
                                attrs: {
                                  placeholder:
                                    "请输入赠送" + _vm.rightTypeName + "数量",
                                  controls: false,
                                },
                                model: {
                                  value: _vm.orderArr[0].count,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.orderArr[0], "count", $$v)
                                  },
                                  expression: "orderArr[0].count",
                                },
                              }),
                            ]
                          : _vm.ruleForm.right_type == 1
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.addVenueAction },
                                },
                                [_vm._v("添加签到信息")]
                              ),
                              _vm._v(" "),
                              _vm.ruleForm.right_venue_data.length > 0
                                ? _vm._l(
                                    _vm.ruleForm.right_venue_data,
                                    function (m, i) {
                                      return _c("div", { key: i }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex align-items-c mt20",
                                          },
                                          [
                                            _c("span", [_vm._v("场馆名称：")]),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              staticStyle: { width: "150px" },
                                              attrs: {
                                                placeholder: "输入场馆名称",
                                              },
                                              model: {
                                                value: m.venue_name,
                                                callback: function ($$v) {
                                                  _vm.$set(m, "venue_name", $$v)
                                                },
                                                expression: "m.venue_name",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "ml10" },
                                              [_vm._v("签到时间：")]
                                            ),
                                            _vm._v(" "),
                                            _c("el-date-picker", {
                                              staticStyle: { width: "370px" },
                                              attrs: {
                                                "value-format": "timestamp",
                                                size: "small",
                                                type: "datetimerange",
                                                placement: "bottom-end",
                                                "ange-separator": "至",
                                                "start-placeholder": "开始日期",
                                                "end-placeholder": "结束日期",
                                                disabled: m.is_membership_card,
                                              },
                                              model: {
                                                value: m.times,
                                                callback: function ($$v) {
                                                  _vm.$set(m, "times", $$v)
                                                },
                                                expression: "m.times",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                staticClass: "ml10",
                                                attrs: {
                                                  "true-label": 1,
                                                  "false-label": 0,
                                                },
                                                model: {
                                                  value: m.is_membership_card,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      m,
                                                      "is_membership_card",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "m.is_membership_card",
                                                },
                                              },
                                              [_vm._v("按会员卡结束时间")]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("签到次数：")]),
                                            _vm._v(" "),
                                            _c(
                                              "el-checkbox",
                                              {
                                                model: {
                                                  value: m.sign_type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      m,
                                                      "sign_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "m.sign_type",
                                                },
                                              },
                                              [_vm._v("不限")]
                                            ),
                                            _vm._v(" "),
                                            _c("el-input-number", {
                                              staticClass: "ml20",
                                              staticStyle: { width: "70px" },
                                              attrs: {
                                                placeholder: "输入次数",
                                                controls: false,
                                                disabled: m.sign_type,
                                              },
                                              model: {
                                                value: m.sign_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    m,
                                                    "sign_number",
                                                    $$v
                                                  )
                                                },
                                                expression: "m.sign_number",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-switch", {
                                              staticClass: "ml20",
                                              attrs: {
                                                "active-value": 1,
                                                "inactive-value": 0,
                                                "active-color": "#13ce66",
                                                "inactive-color": "#ccc",
                                              },
                                              model: {
                                                value: m.is_limit,
                                                callback: function ($$v) {
                                                  _vm.$set(m, "is_limit", $$v)
                                                },
                                                expression: "m.is_limit",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v("一天一次"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "ml10",
                                                attrs: {
                                                  type: "text",
                                                  size: "medium",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delVenueAction(i)
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : _vm.ruleForm.right_type == 2 ||
                            _vm.ruleForm.right_type == 3 ||
                            _vm.ruleForm.right_type == 6
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleAdditem },
                                },
                                [_vm._v("添加" + _vm._s(_vm.rightTypeName))]
                              ),
                              _vm._v(" "),
                              _vm.orderArr.length > 0
                                ? _c(
                                    "el-table",
                                    {
                                      staticClass: "table",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.orderArr,
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _vm.ruleForm.right_type != 6
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: _vm.rightTypeName + "图片",
                                              width: "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "40px",
                                                          height: "40px",
                                                          display: "block",
                                                        },
                                                        attrs: {
                                                          src: scope.row.image,
                                                          alt: "",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1643538639
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.rightTypeName + "名称",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _vm.ruleForm.right_type ==
                                                      2
                                                        ? [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .store_name
                                                              )
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.ruleForm.right_type ==
                                                      3
                                                        ? [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .spec_name
                                                              )
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.ruleForm.right_type ==
                                                      6
                                                        ? [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row.name
                                                              )
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3628881581
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "赠送数量",
                                          width: "200",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "w100",
                                                    model: {
                                                      value: scope.row.count,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "count",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.count",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1541090348
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: { label: "操作", width: "80" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delProductAction(
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2318904109
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "详情介绍：" } },
                  [
                    _c("Tinymce", {
                      ref: "editor",
                      attrs: { height: 400 },
                      model: {
                        value: _vm.ruleForm.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "content", $$v)
                        },
                        expression: "ruleForm.content",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: " " } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.saveAction("ruleForm")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.id ? "修改权益" : "创建权益"))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.ruleForm[_vm.imagesViewKey] },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("Coupon", {
        ref: "coupon",
        attrs: { "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Goods", {
        ref: "goods",
        attrs: { "current-data": _vm.orderArr },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Ticket", {
        ref: "ticket",
        attrs: { "current-data": _vm.orderArr },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }