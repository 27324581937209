"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var cmsRouter = {
  path: "".concat(_settings.roterPre, "/pageManagement"),
  name: 'ticket',
  meta: {
    icon: 'dashboard',
    title: '内容'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'ticket',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/ticket/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/pageManagement/ticket/list"),
    children: [{
      path: 'list',
      name: 'ticketList',
      meta: {
        title: '门票列表',
        noCache: true,
        permissionKey: '/pageManagement/ticket/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/ticket/list'));
        });
      }
    }, {
      path: 'verifier',
      name: 'ticketVerifier',
      meta: {
        title: '门票核销员',
        noCache: true,
        permissionKey: '/pageManagement/ticket/verifier',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/ticket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/ticket/verifier'));
        });
      }
    }, {
      path: 'verifyRecords',
      name: 'ticketVerifyRecords',
      meta: {
        title: '核销记录',
        noCache: true,
        permissionKey: '/pageManagement/ticket/verifyRecords',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/ticket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/ticket/verifyRecords'));
        });
      }
    }, {
      path: 'dataStatistics',
      name: 'ticketDataStatistics',
      meta: {
        title: '数据统计',
        noCache: true,
        permissionKey: '/pageManagement/ticket/dataStatistics',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/ticket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/ticket/dataStatistics'));
        });
      }
    }, {
      path: 'team',
      name: 'ticketTeam',
      meta: {
        title: '团队预约',
        noCache: true,
        permissionKey: '/pageManagement/ticket/team',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/ticket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/ticket/team'));
        });
      }
    }, {
      path: 'rule',
      name: 'ticketRule',
      meta: {
        title: '规则配置',
        noCache: true,
        permissionKey: '/pageManagement/ticket/rule',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/ticket/list")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/ticket/rule'));
        });
      }
    }]
  }, {
    path: 'ticket/detail',
    name: 'ticketDetail',
    meta: {
      title: '门票详情',
      noCache: true,
      permissionKey: '/pageManagement/ticket/list',
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/ticket/list")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/ticket/ticketDetail'));
      });
    }
  }]
};
var _default = exports.default = cmsRouter;