var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": "5",
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("模版")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("框类型")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [_vm._v("框卡纸")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [_vm._v("场景")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [_vm._v("画框")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-20" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { input: _vm.changeType },
                  model: {
                    value: _vm.categoryId,
                    callback: function ($$v) {
                      _vm.categoryId = $$v
                    },
                    expression: "categoryId",
                  },
                },
                _vm._l(_vm.categorys, function (item, index) {
                  return _c("el-radio-button", { attrs: { label: item.id } }, [
                    _vm._v(_vm._s(item.category_name)),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "default-box mt10" }, [
            _c("div", { staticClass: "default-setting" }, [
              _c("div", { staticClass: "logo-r" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "w200",
                        on: { change: _vm.changeTypeStatus },
                        model: {
                          value: _vm.typeStatus,
                          callback: function ($$v) {
                            _vm.typeStatus = $$v
                          },
                          expression: "typeStatus",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { value: "0", label: "全部" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "1", label: "横幅" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "2", label: "竖幅" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { value: "3", label: "斗方" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ml20",
                        attrs: { type: "primary", size: "small" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.addFrame($event)
                          },
                        },
                      },
                      [_vm._v("添加")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default-box" },
            [
              _c(
                "el-table",
                { staticClass: "mt20", attrs: { data: _vm.lists, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "显示图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticStyle: { width: "80px", height: "80px" },
                              attrs: { src: scope.row.img_url, alt: "" },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "所属分类", prop: "category_name" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.category_type == 0
                              ? _c("div", [_vm._v("全部")])
                              : scope.row.category_type == 1
                              ? _c("div", [_vm._v("横幅")])
                              : scope.row.category_type == 2
                              ? _c("div", [_vm._v("竖幅")])
                              : scope.row.category_type == 3
                              ? _c("div", [_vm._v("斗方")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "m2",
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handle(scope.row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "commonlook" },
                                }),
                                _vm._v("查看"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("el-popconfirm", {
                              attrs: { title: "确定删除吗？" },
                              on: {
                                onConfirm: function ($event) {
                                  return _vm.deleteFrame(scope.row)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "m2",
                                            attrs: { size: "small" },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.total > 0
                ? _c("el-pagination", {
                    staticClass: "mt20",
                    attrs: {
                      total: _vm.total,
                      "show-sizer": "",
                      "show-elevator": "",
                      "show-total": "",
                      "current-page": _vm.queryParams.page,
                      "page-size": _vm.queryParams.limit,
                    },
                    on: {
                      "current-change": _vm.changePage,
                      "size-change": _vm.changePageSize,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.frameflag, width: "40%", title: "画框" },
              on: {
                "update:visible": function ($event) {
                  _vm.frameflag = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "formList",
                  attrs: { "label-width": "120px", model: _vm.formList },
                },
                [
                  _c("el-form-item", { attrs: { label: "展示图片" } }, [
                    _vm.formList.img_url
                      ? _c("div", { staticClass: "upload-list" }, [
                          _c("img", { attrs: { src: _vm.formList.img_url } }),
                          _vm._v(" "),
                          _c("div", { staticClass: "upload-list-cover" }, [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              attrs: { size: "20" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRemove("img_url")
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.formList.img_url,
                            expression: "!formList.img_url",
                          },
                        ],
                        staticClass: "border-h",
                        staticStyle: {
                          width: "58px",
                          height: "58px",
                          "line-height": "58px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onUpload("img_url")
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-camera-solid",
                          attrs: { size: "20" },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          model: {
                            value: _vm.formList.category_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formList, "category_id", $$v)
                            },
                            expression: "formList.category_id",
                          },
                        },
                        _vm._l(_vm.categorys, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              value: item.id,
                              label: item.category_name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w200",
                          model: {
                            value: _vm.formList.category_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formList, "category_type", $$v)
                            },
                            expression: "formList.category_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "全部" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 1, label: "横幅" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 2, label: "竖幅" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 3, label: "斗方" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formList.category_id != 33
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "左上角图片" } },
                            [
                              _vm.formList.left_up_img
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.left_up_img },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "left_up_img"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.left_up_img,
                                      expression: "!formList.left_up_img",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("left_up_img")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左上角的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.left_up_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "left_up_width", $$v)
                                  },
                                  expression: "formList.left_up_width",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左上角的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.left_up_height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "left_up_height",
                                      $$v
                                    )
                                  },
                                  expression: "formList.left_up_height",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右上角的图片" } },
                            [
                              _vm.formList.right_up_img
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.right_up_img },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "right_up_img"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.right_up_img,
                                      expression: "!formList.right_up_img",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("right_up_img")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右上角的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.right_up_width,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "right_up_width",
                                      $$v
                                    )
                                  },
                                  expression: "formList.right_up_width",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右上角的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.right_up_height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "right_up_height",
                                      $$v
                                    )
                                  },
                                  expression: "formList.right_up_height",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角图片" } },
                            [
                              _vm.formList.left_down_img
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formList.left_down_img,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "left_down_img"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.left_down_img,
                                      expression: "!formList.left_down_img",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("left_down_img")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.left_down_width,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "left_down_width",
                                      $$v
                                    )
                                  },
                                  expression: "formList.left_down_width",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.left_down_height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "left_down_height",
                                      $$v
                                    )
                                  },
                                  expression: "formList.left_down_height",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右下角的图片" } },
                            [
                              _vm.formList.right_down_img
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formList.right_down_img,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "right_down_img"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.right_down_img,
                                      expression: "!formList.right_down_img",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("right_down_img")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.right_down_width,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "right_down_width",
                                      $$v
                                    )
                                  },
                                  expression: "formList.right_down_width",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左下角的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.right_down_height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "right_down_height",
                                      $$v
                                    )
                                  },
                                  expression: "formList.right_down_height",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上边框图片" } },
                            [
                              _vm.formList.up_img
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.up_img },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove("up_img")
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.up_img,
                                      expression: "!formList.up_img",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("up_img")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上边框的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.up_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "up_height", $$v)
                                  },
                                  expression: "formList.up_height",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上边框的阴影高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.up_shadow_height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "up_shadow_height",
                                      $$v
                                    )
                                  },
                                  expression: "formList.up_shadow_height",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下边框图片" } },
                            [
                              _vm.formList.down_img
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.down_img },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "down_img"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.down_img,
                                      expression: "!formList.down_img",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("down_img")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下边框的高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.down_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "down_height", $$v)
                                  },
                                  expression: "formList.down_height",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "下边框的阴影高度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.down_shadow_height,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "down_shadow_height",
                                      $$v
                                    )
                                  },
                                  expression: "formList.down_shadow_height",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左边框图片" } },
                            [
                              _vm.formList.left_img
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.left_img },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "left_img"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.left_img,
                                      expression: "!formList.left_img",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("left_img")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左边框的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.left_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "left_width", $$v)
                                  },
                                  expression: "formList.left_width",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "左边框的阴影宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.left_shadow_width,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "left_shadow_width",
                                      $$v
                                    )
                                  },
                                  expression: "formList.left_shadow_width",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右边框图片" } },
                            [
                              _vm.formList.right_img
                                ? _c("div", { staticClass: "upload-list" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formList.right_img },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "upload-list-cover" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          attrs: { size: "20" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemove(
                                                "right_img"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.formList.right_img,
                                      expression: "!formList.right_img",
                                    },
                                  ],
                                  staticClass: "border-h",
                                  staticStyle: {
                                    width: "58px",
                                    height: "58px",
                                    "line-height": "58px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUpload("right_img")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-camera-solid",
                                    attrs: { size: "20" },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右边框的宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.right_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formList, "right_width", $$v)
                                  },
                                  expression: "formList.right_width",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "右边框的阴影宽度" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                model: {
                                  value: _vm.formList.right_shadow_width,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formList,
                                      "right_shadow_width",
                                      $$v
                                    )
                                  },
                                  expression: "formList.right_shadow_width",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.frameflag = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }