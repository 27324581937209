"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _marketing = require("@/api/marketing");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
var _request = _interopRequireDefault(require("@/api/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      status: '1',
      keywords: '',
      loadStatus: '1',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20
      },
      showdetail: null,
      uid: '',
      showCreated: false,
      createdData: {
        price: '',
        use: 'wallet_recharge',
        pay_type: 'offline_pos',
        pay_evidence: '',
        mark: '',
        user: {}
      },
      pushData: {
        show: false,
        data: {}
      },
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      drawerData: {
        show: false,
        data: [],
        page: 1,
        limit: 20,
        total: 0,
        listLoading: false,
        id: '',
        name: '',
        phone: '',
        status: ''
      },
      statueNum: {},
      showImg: false,
      // 查看图片 控制器
      imgUrl: '',
      // 放大查看的图片
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      addUserData: {
        show: false,
        name: '',
        phone: ''
      }
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    },
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import/import_invite_letter";
    }
  },
  mounted: function mounted() {
    var queryData = JSON.parse(JSON.stringify(this.$route.query));
    if (Number(queryData.page)) {
      this.tableFrom = queryData;
      this.tableFrom.page = Number(this.tableFrom.page);
      this.tableFrom.limit = Number(this.tableFrom.limit);
    }
    this.getList(1);
  },
  methods: {
    addUserAction: function addUserAction() {
      this.addUserData = {
        show: true,
        name: '',
        phone: ''
      };
    },
    saveUserAction: function saveUserAction() {
      var _this = this;
      if (!this.addUserData.name) {
        this.$message.error('请输入姓名');
        return false;
      }
      if (!this.addUserData.phone) {
        this.$message.error('请输入手机号');
        return false;
      }
      (0, _marketing.inviteLetterUserCreateApi)({
        name: this.addUserData.name,
        phone: this.addUserData.phone,
        invite_letter_id: this.drawerData.id
      }).then(function () {
        _this.addUserData.show = false;
        _this.$message.success('添加成功');
        _this.loadUserList(1);
      });
    },
    delUserAction: function delUserAction(id) {
      var _this2 = this;
      // delLiveApi
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u9080\u8BF7\u7528\u6237?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.inviteLetterUserDelApi)(id).then(function () {
          _this2.$message.success('操作成功');
          _this2.loadUserList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    switchStatusAction: function switchStatusAction(id) {
      var _this3 = this;
      // delLiveApi
      this.$confirm("\u786E\u8BA4\u6838\u9500\u8BE5\u9080\u8BF7\u7528\u6237?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.inviteLetterUserSwitchStatusApi)(id).then(function () {
          _this3.$message.success('操作成功');
          _this3.loadUserList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 下载
    downLoad: function downLoad() {
      window.open(_settingMer.default.https + "/excel/templateDownload?type=import_invite_letter&token=" + (0, _auth.getToken)());
    },
    createdAction: function createdAction() {
      this.$router.push('./detail');
    },
    editAction: function editAction(id) {
      this.$router.push({
        path: './detail',
        query: {
          id: id
        }
      });
    },
    delAction: function delAction(id) {
      var _this4 = this;
      // delLiveApi
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u9080\u8BF7\u51FD?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.inviteLetterDelApi)(id).then(function () {
          _this4.$message.success('操作成功');
          _this4.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 选择时间
    selectChange: function selectChange() {
      this.timeVal = [];
      this.tableData.data = [];
      this.tableData.total = 0;
      this.getList(1);
    },
    // 选择时间
    selectChangeData: function selectChangeData(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      (0, _marketing.inviteLetterListApi)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.data.list;
        _this5.tableData.total = res.data.count;
        _this5.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    showPeopleAction: function showPeopleAction(item) {
      this.drawerData.id = item.id;
      this.drawerData.name = '';
      this.drawerData.phone = '';
      this.drawerData.status = '';
      this.drawerData.page = 1;
      this.drawerData.total = 0;
      this.drawerData.data = [];
      this.drawerData.show = true;
      this.loadUserList();
    },
    goSearchAction: function goSearchAction() {
      this.drawerData.page = 1;
      this.loadUserList();
    },
    loadUserList: function loadUserList(num) {
      var _this6 = this;
      this.drawerData.listLoading = true;
      this.drawerData.page = num || this.drawerData.page;
      (0, _marketing.inviteLetterUserListApi)({
        page: this.drawerData.page,
        limit: this.drawerData.limit,
        invite_letter_id: this.drawerData.id,
        name: this.drawerData.name,
        phone: this.drawerData.phone,
        status: this.drawerData.status
      }).then(function (res) {
        _this6.drawerData.total = res.data.count;
        _this6.drawerData.data = res.data.list;
        _this6.drawerData.listLoading = false;
      }).catch(function () {});
    },
    pageChangeDrawer: function pageChangeDrawer(p) {
      this.drawerData.page = p;
      this.loadUserList();
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    copyXcxLink: function copyXcxLink(m, event) {
      var url = '/users/invitation';
      var input = document.createElement('input'); // 直接构建input
      input.value = url; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    copyH5Link: function copyH5Link(m, event) {
      var url = '/users/invitation';
      var url1 = "".concat(this.domain_url).concat(url);
      var input = document.createElement('input'); // 直接构建input
      input.value = url1; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    showImgAction: function showImgAction(val, m) {
      var _this7 = this;
      var action = process.env.VUE_APP_BASE_API + '/api/sharePoster';
      var mer_id = this.$store.getters.mer_id;
      var url = '/users/invitation';
      switch (val) {
        case 1:
          action += '?return_format=2&poster_type=inviteLetter&qrcode_type=1&Independent_qrcode=1&id=' + m.id + '&path=' + encodeURIComponent('/pages/otherPage/otherPage?link=' + encodeURIComponent(url)) + '&mer_id=' + mer_id;
          break;
        case 2:
          action += '?return_format=2&poster_type=inviteLetter&qrcode_type=2&Independent_qrcode=1&id=' + m.id + '&path=' + url + '&mer_id=' + mer_id;
          break;
        case 3:
          action += '?return_format=2&poster_type=inviteLetter&qrcode_type=1&Independent_qrcode=0&id=' + m.id + '&path=' + encodeURIComponent('/pages/otherPage/otherPage?link=' + encodeURIComponent(url)) + '&mer_id=' + mer_id;
          break;
        case 4:
          action += '?return_format=2&poster_type=inviteLetter&qrcode_type=2&Independent_qrcode=0&id=' + m.id + '&path=' + url + '&mer_id=' + mer_id;
          break;
        default:
          action += '?return_format=2&poster_type=inviteLetter&qrcode_type=1&Independent_qrcode=1&id=' + m.id + '&path=' + url + '&mer_id=' + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;
      this.showImg = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this7.imgUrl = res.data.base_image;
      });
    },
    beforeCloseImg: function beforeCloseImg(done) {
      this.imgUrl = '';
      done();
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this8 = this;
      var model = {
        invite_letter_id: this.drawerData.id,
        export_type: 'inviteLetterUser',
        name: this.drawerData.name,
        phone: this.drawerData.phone,
        status: this.drawerData.status
      };
      // const d = JSON.parse(JSON.stringify(this.drawerData));
      // d.export_type = 'inviteLetterUser';
      // d.invite_letter_id = d.id;
      (0, _system.exportexcel)(model).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this8.$createElement;
        _this8.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this8.$msgbox.close();
                _this8.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('inviteLetterUser');
    },
    handleSuccess: function handleSuccess() {
      this.$message.success('添加成功');
      this.loadUserList(1);
    }
  }
};