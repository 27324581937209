"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _auction = require("@/api/auction");
var _order = require("@/api/order");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      venue_id: '',
      venueList: [],
      albumId: '',
      albumlist: [],
      loading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        type: "albumAuctionRecords"
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.venue_id = Number(this.$route.query.vid) || '';
    (0, _auction.meetingList)({
      page: 1,
      limit: 100
    }).then(function (res) {
      console.log(res.data.list);
      _this.venueList = res.data.list;
    });
    this.init();
    this.exportFileList(1);
  },
  methods: {
    init: function init() {
      var _this2 = this;
      (0, _auction.albumOptionList)({
        page: 1,
        limit: 100,
        venue_id: this.venue_id
      }).then(function (res) {
        _this2.albumlist = res.data.list;
      });
    },
    exportww: function exportww() {
      var _this3 = this;
      var data = {
        export_type: 'albumAuctionRecords',
        album_id: this.albumId
      };
      (0, _system.exportexcel)(data).then(function (res) {
        _this3.exportFileList(1);
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    exportFileList: function exportFileList(num) {
      var _this4 = this;
      this.loading = true;
      if (num) this.tableFrom.num = num;
      (0, _order.exportFileLstApi)(this.tableFrom).then(function (res) {
        _this4.fileVisible = true;
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.loading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    // 下载
    downLoad: function downLoad(id) {
      window.open(_settingMer.default.https + "/excel/download/".concat(id, "?token=") + (0, _auth.getToken)());
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.exportFileList(this.tableFrom.type);
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.exportFileList(this.tableFrom.type);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.exportFileList(this.tableFrom.type);
    }
  }
};