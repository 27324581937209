var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", { staticClass: "default-box" }, [
            _c(
              "div",
              { staticClass: "default-setting" },
              [
                _c("el-input", {
                  staticClass: "ml10 w200",
                  attrs: { placeholder: "请输入标题" },
                  model: {
                    value: _vm.title,
                    callback: function ($$v) {
                      _vm.title = $$v
                    },
                    expression: "title",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml10",
                    attrs: { type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.getSearchList($event)
                      },
                    },
                  },
                  [_vm._v("筛选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml10",
                    attrs: { type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.goAddAsk($event)
                      },
                    },
                  },
                  [_vm._v("新建调查问券")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { data: _vm.list, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "naire_id", width: "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标题", prop: "title", "min-width": "130" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "问题数量",
                  prop: "question_count",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "create_time",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "数据回收", prop: "answer_number" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.goGetAsk(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.answer_count) +
                                "条\n            "
                            ),
                            _c("i", {
                              staticClass: "el-icon-info",
                              staticStyle: { "font-size": "16px" },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分享", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showImgAction(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("h5二维码/链接")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showImgAction(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v("小程序二维码")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.status == 1
                          ? _c("div", { staticStyle: { color: "#F56C6C" } }, [
                              _vm._v("已结束"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        row.status == 0
                          ? _c("div", { staticStyle: { color: "#67c23a" } }, [
                              _vm._v("正常"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editAsk(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-popconfirm", {
                          attrs: { title: "确定删除吗？" },
                          on: {
                            onConfirm: function ($event) {
                              return _vm.deleteAsk(scope.row)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "reference",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "error", size: "small" },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c("el-popconfirm", {
                              attrs: { title: "确定结束此调查吗？" },
                              on: {
                                onConfirm: function ($event) {
                                  return _vm.onChangeStatus(scope.row)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "error",
                                              size: "small",
                                            },
                                          },
                                          [_vm._v("结束")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.showFlag, title: "二维码/h5链接" },
              on: {
                "update:visible": function ($event) {
                  _vm.showFlag = $event
                },
              },
            },
            [
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v("h5地址：" + _vm._s(_vm.showDetail.h5_url)),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _c("img", {
                  staticClass: "w200",
                  attrs: { src: _vm.showDetail.qr_code, alt: "" },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.miniFlag, title: "小程序二维码" },
              on: {
                "update:visible": function ($event) {
                  _vm.miniFlag = $event
                },
              },
            },
            [
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _c("img", {
                  staticClass: "w200",
                  attrs: { src: _vm.miniurl, alt: "" },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.total > 0
            ? _c("el-pagination", {
                staticClass: "mt20",
                attrs: {
                  total: _vm.total,
                  "show-sizer": "",
                  "show-elevator": "",
                  "show-total": "",
                  "current-page": _vm.currentPage,
                  "page-size": _vm.queryParams.size,
                  "page-size-opts": [10, 50, 100],
                },
                on: {
                  "current-change": _vm.changePage,
                  "size-change": _vm.changePageSize,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }