var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSelectAuction,
            width: "720",
            title: "选择作品",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectAuction = $event
            },
            close: function ($event) {
              _vm.queryParams.page = 1
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "作品名称" },
                on: { change: _vm.search },
                model: {
                  value: _vm.title,
                  callback: function ($$v) {
                    _vm.title = $$v
                  },
                  expression: "title",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.search($event)
                    },
                  },
                },
                [_vm._v("筛选")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "h400 mt20",
              staticStyle: { "border-top": "1px solid #eee" },
            },
            [
              _c(
                "table",
                { staticClass: "spec-table" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("td", { attrs: { width: "15%", align: "" } }, [
                        _vm._v("封面图"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "20%", align: "center" } }, [
                        _vm._v("作品名称"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("文件格式"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("操作"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.resultData, function (m, i) {
                    return _c("tbody", { key: i }, [
                      _c("tr", { staticClass: "border-t" }, [
                        _c("td", { staticClass: "bgWhite" }, [
                          _c(
                            "div",
                            { staticClass: "display-flex item-align-center" },
                            [
                              m.file_cover
                                ? _c("img", {
                                    staticStyle: {
                                      width: "70px",
                                      height: "70px",
                                      display: "block",
                                      "flex-shrink": "0",
                                    },
                                    attrs: { src: m.file_cover },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [_c("div", [_vm._v(_vm._s(m.title))])]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            m.file_type == "image"
                              ? _c("div", [_vm._v("图片")])
                              : m.file_type == "video"
                              ? _c("div", [_vm._v("视频")])
                              : m.file_type == "audio"
                              ? _c("div", [_vm._v("音频")])
                              : _vm._e(),
                            _vm._v(" "),
                            m.file_type == "3d"
                              ? _c("div", [_vm._v("3D")])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _vm.selectedAuctionIdList.indexOf(m.works_id) == -1
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.auctionSelectedAction(
                                            $event,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          _vm.auctionCancelAction(
                                            $event,
                                            _vm.selectedAuctionIdList.indexOf(
                                              m.uid
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消选择")]
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c("el-pagination", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  total: _vm.total,
                  "page-size": _vm.queryParams.limit,
                  "show-sizer": "",
                  "show-total": "",
                  "show-elevator": "",
                  "current-page": _vm.queryParams.page,
                },
                on: {
                  "size-change": _vm.changePageSize,
                  "current-change": _vm.changePage,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-20 flex-cc" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSaveOrCancel(1)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onSaveOrCancel(0)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }