"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var systemFormRouter = {
  path: "".concat(_settings.roterPre, "/systemForm"),
  name: 'system',
  meta: {
    icon: 'dashboard',
    title: '设置'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'Basics/:key?',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemForm/setSystem/index'));
      });
    },
    name: 'Basics',
    meta: {
      title: '店铺配置',
      noCache: true,
      permissionKey: '/systemForm/Basics/mer_base'
    }
  }, {
    path: 'systemStore',
    name: 'setting_systemStore',
    meta: {
      title: '提货点设置',
      permissionKey: '/systemForm/systemStore'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemStore/index'));
      });
    }
  }, {
    path: 'systemAuction',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/systemForm/systemAuction/rule"),
    children: [{
      path: 'rule',
      name: 'setting_systemAuctionRule',
      meta: {
        title: '规则配置',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/systemForm/systemAuction/rule'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/rule'));
        });
      }
    }, {
      path: 'order',
      name: 'setting_systemAuctionOrder',
      meta: {
        title: '订单与售后',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/order'));
        });
      }
    }, {
      path: 'store',
      name: 'setting_auction_systemStore',
      meta: {
        title: '提货点设置',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemStore/index'));
        });
      }
    }, {
      path: 'range',
      name: 'setting_systemAuctionRange',
      meta: {
        title: '竞价阶梯',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting"),
        permissionKey: '/systemForm/systemAuction/range'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/range'));
        });
      }
    }, {
      path: 'numberPlatevip',
      name: 'numberPlatevip',
      meta: {
        title: '号牌',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/numberPlate'));
        });
      }
    }, {
      path: 'numberPlatets',
      name: 'numberPlatets',
      meta: {
        title: '号牌',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/numberPlate'));
        });
      }
    }, {
      path: 'numberPlatexc',
      name: 'numberPlatexc',
      meta: {
        title: '号牌',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/numberPlate'));
        });
      }
    }, {
      path: 'exchangeRate',
      name: 'setting_exchangeRate',
      meta: {
        title: '币种和汇率',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/exchangeRate/index'));
        });
      }
    }]
  }, {
    path: 'systemNumber',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/numberIndex'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/systemForm/systemNumber/numberPlatevip"),
    children: [{
      path: 'numberPlatevip',
      name: 'numberPlatevip',
      meta: {
        title: '号牌',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/numberPlate'));
        });
      }
    }, {
      path: 'numberPlatets',
      name: 'numberPlatets',
      meta: {
        title: '号牌',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/numberPlate'));
        });
      }
    }, {
      path: 'numberPlatexc',
      name: 'numberPlatexc',
      meta: {
        title: '号牌',
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemAuction/numberPlate'));
        });
      }
    }]
  }, {
    path: 'picture',
    name: 'sconfig_picture',
    meta: {
      title: '素材管理',
      activeMenu: "".concat(_settings.roterPre, "/setting/systemRule/systemRole")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/config/picture'));
      });
    }
  }, {
    path: 'systemClient/set',
    name: 'setting_systemClient',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemClient/index'));
      });
    },
    meta: {
      title: '客户端设置',
      permissionKey: '/systemForm/systemClient/set'
    }
  }, {
    path: 'systemBank/list',
    name: 'setting_systemBankList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/setting/systemBank/index'));
      });
    },
    meta: {
      title: '收款账号',
      activeMenu: "".concat(_settings.roterPre, "/systemForm/systemBank/list"),
      permissionKey: '/systemForm/systemBank/list'
    }
  }]
};
var _default = exports.default = systemFormRouter;