import { render, staticRenderFns } from "./cancellation.vue?vue&type=template&id=53454fac&scoped=true"
import script from "./cancellation.vue?vue&type=script&lang=js"
export * from "./cancellation.vue?vue&type=script&lang=js"
import style0 from "./cancellation.vue?vue&type=style&index=0&id=53454fac&lang=scss&scoped=true"
import style1 from "./cancellation.vue?vue&type=style&index=1&id=53454fac&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53454fac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53454fac')) {
      api.createRecord('53454fac', component.options)
    } else {
      api.reload('53454fac', component.options)
    }
    module.hot.accept("./cancellation.vue?vue&type=template&id=53454fac&scoped=true", function () {
      api.rerender('53454fac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/list/cancellation.vue"
export default component.exports