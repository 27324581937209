"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  props: {
    val: [Array, Object],
    type: String,
    page: Number
  },
  data: function data() {
    return {
      formatDateNumber: _index.formatDateNumber,
      formatPriceNumber: _index.formatPriceNumber,
      showdetail: null,
      uid: ''
    };
  },
  methods: {
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    }
  }
};