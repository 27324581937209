var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-form",
            { attrs: { size: "small", inline: "", "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "编号状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "status", $$v)
                        },
                        expression: "tableFrom.status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部 "),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("待分配 (" + _vm._s(_vm.status.waitGive) + ")"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("已分配 (" + _vm._s(_vm.status.hasGive) + ")"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("已使用 (" + _vm._s(_vm.status.hasUse) + ")"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("已作废 (" + _vm._s(_vm.status.hasDel) + ")"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "width100", attrs: { label: "业务员ID：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selwidth",
                      attrs: {
                        placeholder: "请选择",
                        clearable: "",
                        filterable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.manage_admin_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "manage_admin_id", $$v)
                        },
                        expression: "tableFrom.manage_admin_id",
                      },
                    },
                    _vm._l(_vm.adminarr, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "width100", attrs: { label: "录入员ID：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selwidth",
                      attrs: {
                        placeholder: "请选择",
                        clearable: "",
                        filterable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.creator_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "creator_id", $$v)
                        },
                        expression: "tableFrom.creator_id",
                      },
                    },
                    _vm._l(_vm.adminarr, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "width100", attrs: { label: "合同编号：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "合同编号", size: "small" },
                      model: {
                        value: _vm.tableFrom.contract_no,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "contract_no", $$v)
                        },
                        expression: "tableFrom.contract_no",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.setmore },
        },
        [_vm._v("分配编号")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.exportOrder },
        },
        [_vm._v("导出")]
      ),
      _vm._v(" "),
      _c(
        "el-upload",
        {
          ref: "uploadImg",
          staticClass: "upload-demo mr10 mb15",
          staticStyle: { display: "inline-block" },
          attrs: {
            action: _vm.fileUrl,
            "on-success": _vm.handleSuccess,
            headers: _vm.myHeaders,
            "show-file-list": true,
            name: "excel_file",
          },
        },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("导入合同编号"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { size: "small", type: "text" }, on: { click: _vm.downLoad } },
        [_vm._v("下载合同编号导入模板")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          attrs: { size: "small", type: "text" },
          on: { click: _vm.getExportFileList },
        },
        [_vm._v("导出记录")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            "highlight-current-row": "",
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "tableEmpty" }, [
              _c("img", {
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "50", label: "选择" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: {
                        value: _vm.checkedIds.indexOf(scope.row.id) > -1,
                      },
                      on: {
                        change: function (v) {
                          return _vm.changeOne(v, scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "导入日期",
              "min-width": "100",
              prop: "create_time",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "contract_no",
              label: "合同编号",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "业务员",
              "min-width": "80",
              prop: "manageAdmin.real_name",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "编号状态", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c("div", [_vm._v("未分配")])
                      : scope.row.status == 1
                      ? _c("div", [_vm._v("已分配")])
                      : scope.row.status == 2
                      ? _c("div", [_vm._v("已使用")])
                      : scope.row.status == 3
                      ? _c("div", [_vm._v("已作废")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "分配时间", "min-width": "100", prop: "give_time" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "使用时间", "min-width": "100", prop: "use_time" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "作废时间", "min-width": "100", prop: "del_time" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "录入员",
              "min-width": "80",
              prop: "creator.real_name",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.status == 0 || scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.losecontract(scope.row)
                                  },
                                },
                              },
                              [_vm._v("作废")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setone(scope.row)
                                  },
                                },
                              },
                              [_vm._v("分配")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 3
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delcontract(scope.row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "commondelete" },
                                }),
                                _vm._v("删除"),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "分配", visible: _vm.showset, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.showset = $event
            },
            close: function ($event) {},
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "selwidth",
              attrs: {
                placeholder: "请选择分配员工",
                clearable: "",
                filterable: "",
              },
              model: {
                value: _vm.userid,
                callback: function ($$v) {
                  _vm.userid = $$v
                },
                expression: "userid",
              },
            },
            _vm._l(_vm.adminarr, function (item) {
              return _c("el-option", {
                key: item.merchant_admin_id,
                attrs: { label: item.real_name, value: item.merchant_admin_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.cursorset } },
                [_vm._v("确认分配")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }