"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _EditorImage = _interopRequireDefault(require("./components/EditorImage"));
var _plugins = _interopRequireDefault(require("./plugins"));
var _toolbar = _interopRequireDefault(require("./toolbar"));
var _dynamicLoadScript = _interopRequireDefault(require("./dynamicLoadScript"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
var tinymceCDN = "https://saas.cdn.yunzongbu.cn/plugins/tinymce/tinymce.min.js";
var _default2 = exports.default = {
  name: "Tinymce",
  components: {
    editorImage: _EditorImage.default
  },
  props: {
    id: {
      type: String,
      default: function _default() {
        return "vue-tinymce-" + +new Date() + ((Math.random() * 1000).toFixed(0) + "");
      }
    },
    value: {
      type: String,
      default: ""
    },
    toolbar: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    menubar: {
      type: String,
      default: "file edit insert view format table"
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: "auto"
    }
  },
  data: function data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        en: "en",
        zh: "zh_CN",
        es: "es_MX",
        ja: "ja"
      }
    };
  },
  computed: {
    containerWidth: function containerWidth() {
      var width = this.width;
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return "".concat(width, "px");
      }
      return width;
    }
  },
  watch: {
    value: function value(val) {
      var _this2 = this;
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(function () {
          return window.tinymce.get(_this2.tinymceId).setContent(val || "");
        });
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  activated: function activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  deactivated: function deactivated() {
    this.destroyTinymce();
  },
  destroyed: function destroyed() {
    this.destroyTinymce();
  },
  methods: {
    init: function init() {
      var _this3 = this;
      // dynamic load tinymce from cdn
      (0, _dynamicLoadScript.default)(tinymceCDN, function (err) {
        if (err) {
          _this3.$message.error(err.message);
          return;
        }
        _this3.initTinymce();
      });
    },
    initTinymce: function initTinymce() {
      var _this4 = this;
      var _this = this;
      window.tinymce.init({
        selector: "#".concat(this.tinymceId),
        language: this.languageTypeList["zh"],
        language_url: 'https://saas.cdn.yunzongbu.cn/plugins/tinymce/langs/zh_CN.js',
        height: this.height,
        body_class: "panel-body ",
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : _toolbar.default,
        fontsize_formats: "10px 12px 14px 16px 18px 20px 24px 36px",
        menubar: this.menubar,
        plugins: _plugins.default,
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        imagetools_cors_hosts: ["www.tinymce.com", "codepen.io"],
        default_link_target: "_blank",
        link_title: false,
        nonbreaking_force_tab: true,
        // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: function init_instance_callback(editor) {
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on("NodeChange Change KeyUp SetContent", function () {
            _this4.hasChange = true;
            _this4.$emit("input", editor.getContent());
          });
        },
        setup: function setup(editor) {
          editor.on("FullscreenStateChanged", function (e) {
            _this.fullscreen = e.state;
          });
        },
        // it will try to keep these URLs intact
        // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
        // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
        convert_urls: false
        // 整合七牛上传
        // images_dataimg_filter(img) {
        //   setTimeout(() => {
        //     const $image = $(img);
        //     $image.removeAttr('width');
        //     $image.removeAttr('height');
        //     if ($image[0].height && $image[0].width) {
        //       $image.attr('data-wscntype', 'image');
        //       $image.attr('data-wscnh', $image[0].height);
        //       $image.attr('data-wscnw', $image[0].width);
        //       $image.addClass('wscnph');
        //     }
        //   }, 0);
        //   return img
        // },
        // images_upload_handler(blobInfo, success, failure, progress) {
        //   progress(0);
        //   const token = _this.$store.getters.token;
        //   getToken(token).then(response => {
        //     const url = response.data.qiniu_url;
        //     const formData = new FormData();
        //     formData.append('token', response.data.qiniu_token);
        //     formData.append('key', response.data.qiniu_key);
        //     formData.append('file', blobInfo.blob(), url);
        //     upload(formData).then(() => {
        //       success(url);
        //       progress(100);
        //     })
        //   }).catch(err => {
        //     failure('出现未知问题，刷新页面，或者联系程序员')
        //     console.log(err);
        //   });
        // },
      });
    },
    destroyTinymce: function destroyTinymce() {
      var tinymce = window.tinymce.get(this.tinymceId);
      if (this.fullscreen) {
        tinymce.execCommand("mceFullScreen");
      }
      if (tinymce) {
        tinymce.destroy();
      }
    },
    setContent: function setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    getContent: function getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    imageSuccessCBK: function imageSuccessCBK(arr) {
      var _this5 = this;
      arr.forEach(function (v) {
        return window.tinymce.get(_this5.tinymceId).insertContent("<img class=\"wscnph\" src=\"".concat(v.url, "\" >"));
      });
    },
    uploadImgAction: function uploadImgAction() {
      var _this6 = this;
      this.$modalUpload(function (img) {
        img.forEach(function (v) {
          return window.tinymce.get(_this6.tinymceId).insertContent("<img class=\"wscnph\" src=\"".concat(v, "\" >"));
        });
      }, 20, "1");
    },
    uploadVideoAction: function uploadVideoAction() {
      var _this7 = this;
      this.$modalUpload(function (img) {
        img.forEach(function (v) {
          return window.tinymce.get(_this7.tinymceId).insertContent("<video controls=\"controls\" style=\"width: 100%;\">\n<source src=\"".concat(v, "\" type=\"video/mp4\" /></video>"));
        });
      }, 1, "2");
    }
  }
};