var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "padding20 bg-white borderRadius6",
          staticStyle: { "min-height": "100vh" },
        },
        [
          _c(
            "div",
            { staticClass: "remove-control form-view" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合成名称：", prop: "title" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入活动名称" },
                            model: {
                              value: _vm.ruleForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "title", $$v)
                              },
                              expression: "ruleForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头部Banner：", prop: "banner_image" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upLoadPicBox",
                          attrs: { title: "正方形图片" },
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1", "banner_image")
                            },
                          },
                        },
                        [
                          _vm.ruleForm.banner_image
                            ? _c("div", { staticClass: "pictrue" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ruleForm.banner_image + "!120a",
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合成时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          align: "right",
                        },
                        on: { change: _vm.onchangeTime },
                        model: {
                          value: _vm.assist_date,
                          callback: function ($$v) {
                            _vm.assist_date = $$v
                          },
                          expression: "assist_date",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v("不填写则默认为长期有效"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合成须知：" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.ruleForm.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "content", $$v)
                          },
                          expression: "ruleForm.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.id,
                expression: "id",
              },
            ],
            attrs: { "content-position": "left" },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.id,
                  expression: "id",
                },
              ],
            },
            [
              _c("div", { staticClass: "playname" }, [_vm._v("玩法设置")]),
              _vm._v(" "),
              _vm._l(_vm.playarr, function (item, index) {
                return _c(
                  "el-form",
                  {
                    staticClass: "demo-ruleForm",
                    attrs: { "label-width": "120px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "玩法名称：", required: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入玩法名称" },
                          on: {
                            blur: function ($event) {
                              return _vm.editPlay(item)
                            },
                          },
                          model: {
                            value: item.title,
                            callback: function ($$v) {
                              _vm.$set(item, "title", $$v)
                            },
                            expression: "item.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "玩法说明：", required: "" } },
                      [
                        _c("el-input", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入玩法说明" },
                          on: {
                            blur: function ($event) {
                              return _vm.editPlay(item)
                            },
                          },
                          model: {
                            value: item.synthetic_info,
                            callback: function ($$v) {
                              _vm.$set(item, "synthetic_info", $$v)
                            },
                            expression: "item.synthetic_info",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "限制合成次数：" } },
                      [
                        _c("el-input-number", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入合成次数" },
                          on: {
                            change: function ($event) {
                              return _vm.editPlay(item)
                            },
                          },
                          model: {
                            value: item.limit_synthetic_number,
                            callback: function ($$v) {
                              _vm.$set(item, "limit_synthetic_number", $$v)
                            },
                            expression: "item.limit_synthetic_number",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v("不填写或0代表无限制"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "最大合成数量：" } },
                      [
                        _c("el-input-number", {
                          staticClass: "w300",
                          attrs: { placeholder: "请输入合成次数" },
                          on: {
                            change: function ($event) {
                              return _vm.editPlay(item)
                            },
                          },
                          model: {
                            value: item.total_synthetic_number,
                            callback: function ($$v) {
                              _vm.$set(item, "total_synthetic_number", $$v)
                            },
                            expression: "item.total_synthetic_number",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v("不填写或0代表无限制"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "合成材料：", required: "" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "table",
                            staticStyle: {
                              width: "100%",
                              "margin-top": "-10px",
                            },
                            attrs: {
                              "tooltip-effect": "dark",
                              data: item.systheticMaterialData,
                              size: "mini",
                              "span-method": _vm.objectSpanMethod,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { width: "80", label: "图片" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            scope.row.image
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.image + "!120a",
                                                    "preview-src-list": [
                                                      scope.row.image,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "名称", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.store_name) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "是否销毁", "min-width": "100" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "active-text": "是",
                                            "inactive-text": "否",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.editPlay(item)
                                            },
                                          },
                                          model: {
                                            value: scope.row.is_destroy,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "is_destroy",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.is_destroy",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "min-width": "140",
                                label: "" + item.systheticMaterialData.length,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                合成需要数量  \n                "
                                        ),
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "active-text": "是",
                                            "inactive-text": "否",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.editNeedAll(index)
                                            },
                                          },
                                          model: {
                                            value: item.isneedall,
                                            callback: function ($$v) {
                                              _vm.$set(item, "isneedall", $$v)
                                            },
                                            expression: "item.isneedall",
                                          },
                                        }),
                                        _vm._v("只需总量\n              "),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        !item.isneedall ||
                                        (item.isneedall && scope.$index == 0)
                                          ? _c("el-input-number", {
                                              attrs: { min: 1 },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.editPlay(item)
                                                },
                                              },
                                              model: {
                                                value: scope.row.need_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "need_number",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.need_number",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                "min-width": "80",
                                fixed: "right",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.cancelSelect(
                                                      scope.$index,
                                                      scope.row,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    删除\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAdditem(
                                  "product_nft",
                                  index,
                                  item
                                )
                              },
                            },
                          },
                          [_vm._v("\n            添加合成材料\n          ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "合成藏品：", required: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "compose-result-goods",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            item.product.image
                              ? _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { src: item.product.image + "!120a" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "20px" } },
                              [_vm._v(_vm._s(item.product.store_name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectreaultProduct(index)
                                  },
                                },
                              },
                              [
                                !item.product.product_id
                                  ? _c("span", [_vm._v("选择合成藏品")])
                                  : _c("span", [_vm._v("更换")]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v("合成份数：")]
                            ),
                            _vm._v(" "),
                            _c("el-input-number", {
                              attrs: { min: 1 },
                              model: {
                                value: item.synthetic_nft_count,
                                callback: function ($$v) {
                                  _vm.$set(item, "synthetic_nft_count", $$v)
                                },
                                expression: "item.synthetic_nft_count",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#999",
                                  "margin-left": "20px",
                                },
                              },
                              [
                                _vm._v(
                                  "该数量是设置的合成材料可以合成几个该藏品，非合成总数量"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    item.synthetic_id
                      ? _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteplay(item.synthetic_id)
                                  },
                                },
                              },
                              [_vm._v("\n            删除玩法\n          ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    index != _vm.playarr.length - 1
                      ? _c(
                          "el-form-item",
                          [
                            _c("el-divider", {
                              attrs: { "content-position": "left" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "10px", "margin-left": "120px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.savePlay },
                },
                [_vm._v("\n        添加玩法\n      ")]
              ),
              _vm._v(" "),
              _c("el-divider", { attrs: { "content-position": "left" } }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "btn",
              staticStyle: {
                "margin-left": "120px",
                width: "200px",
                "margin-top": "30px",
              },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAction("ruleForm")
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.id ? "确认提交" : "下一步") + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectProduct", {
        ref: "product",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1", status: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }