var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.createdAction } },
              [_vm._v("添加核销员")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%", color: "#000" },
          attrs: {
            border: "",
            data: _vm.tableData.data,
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            key: "1",
            attrs: { label: "核销员头像", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _c("img", {
                        staticStyle: { width: "37px", height: "37px" },
                        attrs: { src: scope.row.user.avatar, alt: "" },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "核销员ID", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "line1" }, [
                      _vm._v(_vm._s(scope.row.uid)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "核销员名称", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "line1" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.user.real_name || scope.row.user.nickname
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "核销员电话",
              "min-width": "80",
              prop: "user.phone",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.add_time))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.delAction(scope.row.uid)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加核销员",
            visible: _vm.showAddPeople.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showAddPeople, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt10" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("核销员名称：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w300",
                  attrs: {
                    placeholder: "搜索用户昵称/手机号/真实姓名",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.searchUserAction,
                    loading: _vm.searchLoading,
                  },
                  model: {
                    value: _vm.showAddPeople.uid,
                    callback: function ($$v) {
                      _vm.$set(_vm.showAddPeople, "uid", $$v)
                    },
                    expression: "showAddPeople.uid",
                  },
                },
                _vm._l(_vm.userList, function (m, i) {
                  return _c(
                    "el-option",
                    {
                      key: "user" + i,
                      attrs: { value: m.uid, label: m.nickname },
                    },
                    [
                      _c("div", { staticClass: "avatar flex align-items-c" }, [
                        _c("img", { attrs: { src: m.avatar, alt: "" } }),
                        _vm._v(" "),
                        _c("div", { staticClass: "user-r flex-one" }, [
                          _c(
                            "div",
                            { staticClass: "line1" },
                            [
                              m.real_name
                                ? [_vm._v(_vm._s(m.real_name) + " | ")]
                                : _vm._e(),
                              _vm._v(_vm._s(m.nickname)),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(m.phone))]),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex align-items-c",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showAddPeople.btnLoading,
                  },
                  on: { click: _vm.saveAddPeopleAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.showAddPeople.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }