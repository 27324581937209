var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.content.type === "title"
        ? [
            _c(
              "div",
              {
                staticClass: "item-flex-center",
                style:
                  "padding: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.page_margin / 2 +
                  "px 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "item-flex-center",
                    style: {
                      color: _vm.facade.titleColor,
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [_vm._v("新闻标题清代家具的细分征集活动之济南中心广场站")]
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "author"
        ? [
            _c(
              "div",
              {
                staticClass: "item-flex-center",
                style:
                  "padding: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.page_margin / 2 +
                  "px 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-one",
                    style: {
                      color: _vm.facade.titleColor,
                      fontSize: _vm.facade.titleFontSize / 2 + "px",
                      fontWeight: _vm.facade.titleFontWeight,
                    },
                  },
                  [_vm._v("北京银座拍卖")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    style: {
                      color: _vm.facade.hotColor,
                      fontSize: _vm.facade.hotFontSize / 2 + "px",
                      fontWeight: _vm.facade.hotFontWeight,
                    },
                  },
                  [_vm._v("71浏览")]
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "content"
        ? [
            _c(
              "div",
              {
                style:
                  "padding: " +
                  _vm.facade.marginTop / 2 +
                  "px " +
                  _vm.pageInfo.page_margin / 2 +
                  "px 0 " +
                  _vm.pageInfo.page_margin / 2 +
                  "px;",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    style: {
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      lineHeight: _vm.facade.lineHeight / 2 + "px",
                    },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "徐秀棠（b.1937）：中国工艺美术大师，中国美术家协会会员，中国陶瓷美术协会理事。作品在全国评比中获奖，被国内外博物馆收藏。著有《紫砂陶刻艺术初论》、《中国紫砂》等书。宜兴长乐弘陶艺有限公司是1993年成立的公认紫砂名牌企业，中国工艺美术大师徐秀棠任长乐弘陶艺艺术总监，培养了一批有创作实力的设计人才及传统工艺制作人员。"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(" ")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "time",
                    style: {
                      fontSize: _vm.facade.timeFontSize / 2 + "px",
                      color: _vm.facade.timeColor,
                      fontWeight: _vm.facade.timeFontWeight,
                      textAlign: _vm.facade.timeAlign,
                      padding:
                        _vm.facade.timePaddingTop / 2 +
                        "px " +
                        _vm.facade.timePaddingRight / 2 +
                        "px " +
                        _vm.facade.timePaddingBottom / 2 +
                        "px " +
                        _vm.facade.timePaddingLeft / 2 +
                        "px",
                    },
                  },
                  [_vm._v("发布于2024.06.15 14:15")]
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }