var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.only ? "width: 100%;height: 70px;" : "height: auto;" },
    [
      _c(
        "div",
        {
          staticClass: "tab flex justify-b",
          style: {
            background: _vm.facade.tabBackground,
            color: _vm.facade.tabColor,
            "font-size": _vm.facade.tabFontSize / 2 + "px",
            "font-weight": _vm.facade.tabFontWeight,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "item",
              style: {
                color: _vm.facade.currColor,
                "font-size": _vm.facade.currFontSize / 2 + "px",
                "font-weight": _vm.facade.currFontWeight,
              },
            },
            [
              _vm._v("标签1 "),
              _c(
                "div",
                {
                  staticClass: "bg item-flex-center",
                  style: {
                    bottom: _vm.facade.currBgBottom / 2 + "px",
                  },
                },
                [
                  _c("div", {
                    style: {
                      width: _vm.facade.currBgWidth / 2 + "px",
                      height: _vm.facade.currBgHeight / 2 + "px",
                      background: _vm.facade.currBgColor,
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [_vm._v("标签2")]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [_vm._v("标签3")]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [_vm._v("标签4")]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [_vm._v("标签5")]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }