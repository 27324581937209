var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: { size: "small", inline: "", "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "status", $$v)
                            },
                            expression: "tableFrom.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "" } }, [
                            _vm._v("全部 "),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("未发货"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: "2" } }, [
                            _vm._v("已发货"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "用户：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.tableFrom.user_keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "user_keyword", $$v)
                            },
                            expression: "tableFrom.user_keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "width100", attrs: { label: "数字藏品：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入", size: "small" },
                          model: {
                            value: _vm.tableFrom.product_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "product_name", $$v)
                            },
                            expression: "tableFrom.product_name",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "multipleSelection",
          staticClass: "table",
          staticStyle: { width: "100%" },
          attrs: {
            "tooltip-effect": "dark",
            "row-key": function (row) {
              return row.exchange_id
            },
            data: _vm.tableData.data,
            size: "mini",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "exchange_id", width: "55" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: scope.row.user.avatar
                              ? scope.row.user.avatar
                              : _vm.moren,
                            "preview-src-list": [
                              scope.row.user.avatar || _vm.moren,
                            ],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "昵称", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#6699dd", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetails(row.user)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.user.real_name || row.user.nickname)
                        ),
                        row.user.real_name
                          ? _c("span", [
                              _vm._v("(" + _vm._s(row.user.nickname) + ")"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换类型", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n           " +
                        _vm._s(
                          scope.row.product.type == 2 ? "实物商品" : "数字藏品"
                        ) +
                        "\n       "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换内容", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n           " +
                        _vm._s(scope.row.product.product_name) +
                        "\n       "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换条件", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.exchange_conditions.exchange_type == 2
                      ? _c("div", [
                          _vm._v(
                            _vm._s(scope.row.exchange_conditions.coin_num) +
                              "保卫积分"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(
                      scope.row.exchange_conditions.data,
                      function (item, index) {
                        return _c("div", { key: "c" + index }, [
                          _vm._v(
                            _vm._s(item.product_info.store_name) +
                              "*" +
                              _vm._s(item.num)
                          ),
                        ])
                      }
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "收货信息", "min-width": "180" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.product.type == 2
                      ? [
                          _c("div", [
                            _vm._v(
                              _vm._s(scope.row.address_info.real_name) +
                                "  " +
                                _vm._s(scope.row.address_info.user_phone)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(scope.row.address_info.user_address)),
                          ]),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发货信息", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v("快递公司：" + _vm._s(scope.row.delivery_name)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("快递单号：" + _vm._s(scope.row.delivery_no)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换时间", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n           " +
                        _vm._s(scope.row.create_time) +
                        "\n       "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "80",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.product.type == 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showsend(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.status == 2 ? "修改发货" : "发货"
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "发货", visible: _vm.sendflag },
          on: {
            "update:visible": function ($event) {
              _vm.sendflag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入快递公司" },
                    model: {
                      value: _vm.sendJson.delivery_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendJson, "delivery_name", $$v)
                      },
                      expression: "sendJson.delivery_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入快递公司" },
                    model: {
                      value: _vm.sendJson.delivery_no,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendJson, "delivery_no", $$v)
                      },
                      expression: "sendJson.delivery_no",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.sendflag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.cursorSend } },
                [_vm._v("确认发货")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加分销员", visible: _vm.showadd, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.showadd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "绑定员工：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selwidth",
                      attrs: {
                        placeholder: "请选择分配员工",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.userid,
                        callback: function ($$v) {
                          _vm.userid = $$v
                        },
                        expression: "userid",
                      },
                    },
                    _vm._l(_vm.adminarr, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择用户：", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择用户",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.adduid,
                        callback: function ($$v) {
                          _vm.adduid = $$v
                        },
                        expression: "adduid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  "border-radius": "50%",
                                  "margin-right": "12px",
                                },
                                attrs: { src: m.avatar, alt: "" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "line-height": "18px",
                                  },
                                },
                                [
                                  _c("div", [_vm._v(_vm._s(m.nickname))]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(m.phone))]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.addrestatus,
                        callback: function ($$v) {
                          _vm.addrestatus = $$v
                        },
                        expression: "addrestatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("保留原团队分销员"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("全新分销员"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showadd = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleadd } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "启用分销员",
            visible: _vm.changestatusflag,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changestatusflag = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.restatus,
                callback: function ($$v) {
                  _vm.restatus = $$v
                },
                expression: "restatus",
              },
            },
            [
              _c("el-radio", { attrs: { label: "0" } }, [
                _vm._v("保留原团队分销员"),
              ]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("全新分销员")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.changestatusflag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlechangestatus },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }