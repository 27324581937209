"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var echarts = _interopRequireWildcard(require("echarts/core"));
var _charts = require("echarts/charts");
var _renderers = require("echarts/renderers");
var _data_template = _interopRequireDefault(require("./modules/data_template"));
var _data_drawer_template = _interopRequireDefault(require("./modules/data_drawer_template.vue"));
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = require("@/utils/index");
var _member = require("@/api/member");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 注册必须的组件
echarts.use([_charts.PieChart, _renderers.CanvasRenderer]);
var _default = exports.default = {
  components: {
    dataTemplate: _data_template.default,
    dataDrawerTemplate: _data_drawer_template.default,
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      memberStatistical: '',
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      listLoading: false,
      list: [],
      total: 0,
      queryParams: {
        page: 1,
        limit: 10,
        export_type: 'special_user_deal'
      },
      echart1: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart2: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart3: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart4: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      echart5: {
        type: 'userNumData',
        result: {},
        chart: null,
        noData: false
      },
      saleList: [],
      salePriceList: [],
      levelList: [],
      showdetail: null,
      uid: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _member.memberStatisticsApi)().then(function (res) {
      _this.memberStatistical = res.data;
    }).catch(function () {});
    var params = {
      page: 1,
      limit: 10
    };
    (0, _member.memberStatisticsSalesCountRankApi)(params).then(function (res) {
      _this.saleList = res.data;
    });
    (0, _member.memberStatisticsSalesAmountRankApi)(params).then(function (res) {
      _this.salePriceList = res.data;
    });
    (0, _member.memberStatisticsGrowthValueRankApi)(params).then(function (res) {
      _this.levelList = res.data;
    });
    // userIdeaPriceRankingApi(params).then(res => {
    //   this.ideaPriceRanking = res.data.list;
    // });
    // userIdeaNumRankingApi(params).then(res => {
    //   this.ideaNumRanking = res.data.list;
    // });
    // userBidNumRankingApi(params).then(res => {
    //   this.bidNumRanking = res.data.list;
    // });

    (0, _member.memberStatisticsOrderChartsApi)().then(function (res) {
      var userNumData = [];
      var priceData = [];
      var againCount = [];
      res.data.forEach(function (m) {
        userNumData.push({
          name: m.membershipCardItem.name,
          value: m.buy_user_count
        });
        priceData.push({
          name: m.membershipCardItem.name,
          value: m.total_pay_price
        });
        againCount.push({
          name: m.membershipCardItem.name,
          value: m.again_user_count
        });
      });
      _this.echart1.result = {
        userNumData: userNumData,
        priceData: priceData,
        againCount: againCount
      };
      _this.setPieData('echart1');
    });
    (0, _member.memberStatisticsProvinceChartsApi)().then(function (res) {
      res.data.forEach(function (m) {
        m.name = m.province;
        m.value = m.count;
      });
      _this.echart2.result = {
        userNumData: res.data
      };
      _this.setPieData('echart2');
    });
    (0, _member.memberStatisticsSexChartsApi)().then(function (res) {
      var userNumData = [];
      var priceData = [];
      res.data.forEach(function (m) {
        userNumData.push({
          name: m.sex_text,
          value: m.user_count
        });
        priceData.push({
          name: m.sex_text,
          value: Number(m.total_pay_price)
        });
      });
      _this.echart3.result = {
        userNumData: userNumData,
        priceData: priceData
      };
      _this.setPieData('echart3');
    });
    (0, _member.memberStatisticsAgeChartsApi)().then(function (res) {
      var userNumData = [];
      var priceData = [];
      res.data.forEach(function (m) {
        userNumData.push({
          name: m.age_group,
          value: m.user_count
        });
        priceData.push({
          name: m.age_group,
          value: Number(m.total_pay_price)
        });
      });
      _this.echart4.result = {
        userNumData: userNumData,
        priceData: priceData
      };
      _this.setPieData('echart4');
    });
    (0, _member.memberStatisticsRegisterSourceChartsApi)().then(function (res) {
      var userNumData = [];
      var priceData = [];
      res.data.forEach(function (m) {
        userNumData.push({
          name: m.user_type_text,
          value: m.user_count
        });
        priceData.push({
          name: m.user_type_text,
          value: Number(m.total_pay_price || 0)
        });
      });
      _this.echart5.result = {
        userNumData: userNumData,
        priceData: priceData
      };
      _this.setPieData('echart5');
    });
    this.load();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.echart1.chart) {
      return;
    }
    this.echart1.chart.dispose();
    this.echart1.chart = null;
    if (!this.echart2.chart) {
      return;
    }
    this.echart2.chart.dispose();
    this.echart2.chart = null;
    if (!this.echart3.chart) {
      return;
    }
    this.echart3.chart.dispose();
    this.echart3.chart = null;
    if (!this.echart4.chart) {
      return;
    }
    this.echart4.chart.dispose();
    this.echart4.chart = null;
    if (!this.echart5.chart) {
      return;
    }
    this.echart5.chart.dispose();
    this.echart5.chart = null;
  },
  methods: {
    goRecordAction: function goRecordAction(key) {
      var query = '';
      if (key === 1) {
        query = '?end=lately30';
      } else {
        query = '?status=2';
      }
      this.$router.push('./buyRecord' + query);
    },
    changeTypeAction: function changeTypeAction(key) {
      this.loadPieAction(key);
    },
    load: function load() {
      // this.listLoading = true;
      // specialUserDealListApi(this.queryParams).then(response => {
      //   this.total = response.data.count;
      //   this.list = response.data.list;
      //   this.listLoading = false;
      // });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    showMoreAction: function showMoreAction(type, title) {
      this.$refs.dataDrawerTemplateEL.init(type, title, 'venue_id', this.venue_id);
    },
    setPieData: function setPieData(key) {
      this[key].chart = echarts.init(this.$refs[key]);
      this.loadPieAction(key);
    },
    loadPieAction: function loadPieAction(key) {
      console.log(this[key].result[this[key].type]);
      if (this[key].result[this[key].type].length > 0) {
        this[key].noData = false;
        this[key].chart.setOption(this.setPie(this[key].result[this[key].type], this[key].type), key);
      } else {
        this[key].noData = true;
        console.log('no data');
      }
    },
    setPie: function setPie(data, type) {
      return {
        series: [{
          color: ['#2479f2', '#84b8fa', '#5f9bf8', '#cde4fc', '#aad0fb', '#e5f4ff'],
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          emphasis: {
            label: {
              show: true,
              fontSize: '14',
              fontWeight: 'bold'
            }
          },
          label: {
            show: true,
            color: '#000000',
            formatter: function formatter(data) {
              var val = data.name + ' ';
              if (type === 'userNumData') {
                val += (0, _index.formatPriceNumber)(data.value) + '人';
              } else if (type === 'againCount') {
                val += (0, _index.formatPriceNumber)(data.value) + '人';
              } else if (type === 'priceData') {
                val += '￥' + (0, _index.formatPriceNumber)(data.value);
              }
              return val;
            }
          },
          data: data
        }]
      };
    },
    goAlbumDataAction: function goAlbumDataAction(id, name) {
      this.$router.push("/merchant/pageManagement/auction/album_data?id=".concat(id, "&name=").concat(name));
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this2 = this;
      (0, _system.exportexcel)(this.queryParams).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this2.$msgbox.close();
                _this2.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('special_user_deal');
    }
  }
};