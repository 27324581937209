"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configApi = configApi;
exports.modifyStoreApi = modifyStoreApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 验证码
 */
function configApi(key) {
  return _request.default.get("config/".concat(key));
}
function modifyStoreApi() {
  return _request.default.get("update/form");
}