"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._textToSpeech = exports._sortGuidance = exports._getMerberToneList = exports._getGuidanceList = exports._getAIMerberId = exports._editGuidance = exports._digitalHumanList = exports._deleteGuidanceProduct = exports._deleteGuidanceAll = exports._deleteGuidance = exports._changeMerber = exports._batchAddGuidance = exports._addGuidance = void 0;
var _request = _interopRequireDefault(require("./request"));
//  获取导览路线列表
var _getGuidanceList = exports._getGuidanceList = function _getGuidanceList(data) {
  return _request.default.get("meta/spaceGuidedRoute/list", data);
};
// 数字人列表
var _digitalHumanList = exports._digitalHumanList = function _digitalHumanList(data) {
  return _request.default.get("meta/digitalMan/list", data);
};
// 添加导览路线
var _addGuidance = exports._addGuidance = function _addGuidance(data) {
  return _request.default.post("meta/spaceGuidedRoute/create", data);
};
// 批量添加导览路线
var _batchAddGuidance = exports._batchAddGuidance = function _batchAddGuidance(data) {
  return _request.default.post("meta/spaceGuidedRoute/batchCreate", data);
};
// 修改导览路线
var _editGuidance = exports._editGuidance = function _editGuidance(data) {
  return _request.default.post("meta/spaceGuidedRoute/update", data);
};

// 批量修改导览 排序
var _sortGuidance = exports._sortGuidance = function _sortGuidance(data) {
  return _request.default.post("meta/spaceGuidedRoute/batchSort", data);
};

// 删除导览路线
var _deleteGuidance = exports._deleteGuidance = function _deleteGuidance(data) {
  return _request.default.post("meta/spaceGuidedRoute/delete", data);
};

// 删除空间展品对应的导览点
var _deleteGuidanceProduct = exports._deleteGuidanceProduct = function _deleteGuidanceProduct(data) {
  return _request.default.post("meta/spaceGuidedRoute/deleteByLinkId", data);
};

// 删除空间所有导览路线
var _deleteGuidanceAll = exports._deleteGuidanceAll = function _deleteGuidanceAll(data) {
  return _request.default.post("meta/spaceGuidedRoute/clear", data);
};

// 当前空间使用的人物
var _getAIMerberId = exports._getAIMerberId = function _getAIMerberId(spaceId) {
  return _request.default.get("/open3d/product/app/guided/people/".concat(spaceId, " "));
};

// 人物音色列表
var _getMerberToneList = exports._getMerberToneList = function _getMerberToneList() {
  return _request.default.get("meta/DigitalManVoice/list");
};

// 文字转语音
var _textToSpeech = exports._textToSpeech = function _textToSpeech(data) {
  return _request.default.post("https://api.open3d.net/open3d/resource/video/util/textToSpeech", data);
};

// 修改空间导览人物
var _changeMerber = exports._changeMerber = function _changeMerber(data) {
  return _request.default.post("meta/space/guidedDigitalManConfig", data);
};