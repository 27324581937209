var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "订单管理" } }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "div",
            { staticClass: "top-view", staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: {
                    "default-active": _vm.routerUrl,
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                [
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/order/list/auctionMeeting" } },
                    [_vm._v("自营订单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/order/list/proxy" } },
                    [_vm._v("代拍订单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/order/list/all" } },
                    [_vm._v("商城订单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/order/list/sellmanage" } },
                    [_vm._v("私洽订单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    { attrs: { index: "/merchant/order/list/refund" } },
                    [_vm._v("退款单")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }