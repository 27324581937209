var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
    [
      _c("header-breadcrumb", { attrs: { name: "审核清单", "has-back": "" } }),
      _vm._v(" "),
      _c("el-card", [
        _c(
          "div",
          {
            staticStyle: { height: "calc(100vh - 140px)", overflow: "hidden" },
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                attrs: {
                  data: _vm.tableData,
                  height: "100%",
                  size: "mini",
                  border: "",
                },
              },
              [
                _c("template", { slot: "empty" }, [
                  _c("div", { staticClass: "tableEmpty" }, [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    "search-key": "start_product_no",
                    "search-other-key": "end_product_no",
                    "search-type": "inputPacker",
                    label: "库号",
                    prop: "",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "nowrap" }, [
                            _vm._v(_vm._s(scope.row.product_no)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: { label: "图片", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.image
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "demo-image__preview nowrap" },
                                  [
                                    scope.row.image.split(",").length > 1
                                      ? [
                                          _c(
                                            "el-badge",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                value:
                                                  scope.row.image.split(",")
                                                    .length,
                                              },
                                            },
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                  "margin-right": "5px",
                                                  display:
                                                    "inline-block!important",
                                                },
                                                attrs: {
                                                  src:
                                                    scope.row.image.split(
                                                      ","
                                                    )[0] + "!120a",
                                                  "preview-src-list":
                                                    scope.row.image.split(","),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _c("el-image", {
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px",
                                            "margin-right": "5px",
                                            display: "inline-block!important",
                                          },
                                          attrs: {
                                            src:
                                              scope.row.image.split(",")[0] +
                                              "!120a",
                                            "preview-src-list":
                                              scope.row.image.split(","),
                                          },
                                        }),
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    "search-key": "product_name",
                    label: "货品名称",
                    prop: "",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "nowrap" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.product_name) +
                                "\n            "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    "search-key": "warehouse_id",
                    "search-type": "radio",
                    label: "所在库房",
                    prop: "",
                    "search-query": _vm.searchQuery,
                    options: _vm.storeList,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "nowrap" },
                            [
                              scope.row.warehouse && scope.row.warehouse.name
                                ? [_vm._v(_vm._s(scope.row.warehouse.name))]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: { label: "货架", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "nowrap" }, [
                            _vm._v(
                              _vm._s(
                                scope.row.shelf ? scope.row.shelf.shelf_no : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    "search-key": "entrust_keyword",
                    label: "委托方",
                    prop: "",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.entrustUser
                            ? _c("div", { staticClass: "nowrap" }, [
                                _vm._v(_vm._s(scope.row.entrustUser.real_name)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    "search-key": "salesman_admin_keyword",
                    label: "业务员",
                    prop: "",
                    "search-query": _vm.searchQuery,
                  },
                  on: { changeVal: _vm.searchAction },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.contract
                            ? _c("div", { staticClass: "nowrap" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.contract &&
                                        scope.row.contract.salesmanAdmin
                                        ? scope.row.contract.salesmanAdmin
                                            .real_name
                                        : ""
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: { label: "审核专家", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "nowrap" }, [
                            _vm._v(
                              _vm._s(
                                scope.row.auditItem &&
                                  scope.row.auditItem.auditAdmin
                                  ? scope.row.auditItem.auditAdmin.real_name
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: { label: "审核时间", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "nowrap" }, [
                            _vm._v(
                              _vm._s(
                                scope.row.auditItem
                                  ? scope.row.auditItem.audit_time
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: { label: "审核结果", prop: "", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-dropdown",
                            { attrs: { type: "primary", placement: "bottom" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ft12 cur",
                                  style: {
                                    color:
                                      scope.row.auditItem &&
                                      scope.row.auditItem.audit_status
                                        ? scope.row.auditItem.audit_status == 1
                                          ? "#6c8c25"
                                          : "#91381e"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        scope.row.auditItem &&
                                          scope.row.auditItem.audit_status
                                          ? scope.row.auditItem.audit_status ==
                                            1
                                            ? "通过"
                                            : "未通过"
                                          : "点击选择"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "text-align": "left",
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onUpdStatus(
                                                scope.row,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("通过")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "text-align": "left",
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onUpdStatus(
                                                scope.row,
                                                -1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("未通过")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: { label: "操作", prop: "", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.onRemove(scope.row)
                                },
                              },
                            },
                            [_vm._v("移除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { width: "1000px" }, attrs: { id: "printorder" } },
              [
                _c(
                  "div",
                  { staticClass: "ptd-box" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ptd",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("库号")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("图片")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("货品名称")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("所在库房")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("货架")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("委托方")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("业务员")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("审核专家")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [_c("span", [_vm._v("审核时间")])]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.tableData, function (m, i) {
                      return _c(
                        "div",
                        { key: "order" + i, staticClass: "ptd" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [_vm._v(_vm._s(m.product_no))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              m.image.split(",").length > 1
                                ? [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                        "margin-right": "5px",
                                        display: "inline-block!important",
                                      },
                                      attrs: {
                                        src: m.image.split(",")[0] + "!120a",
                                      },
                                    }),
                                  ]
                                : m.image.split(",")[0]
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                      "margin-right": "5px",
                                      display: "inline-block!important",
                                    },
                                    attrs: {
                                      src: m.image.split(",")[0] + "!120a",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [_vm._v(_vm._s(m.product_name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  m.warehouse && m.warehouse.name
                                    ? m.warehouse.name
                                    : ""
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [_vm._v(_vm._s(m.shelf ? m.shelf.shelf_no : ""))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  m.entrustUser ? m.entrustUser.real_name : ""
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  m.contract && m.contract.salesmanAdmin
                                    ? m.contract.salesmanAdmin.real_name
                                    : ""
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  m.auditItem && m.auditItem.auditAdmin
                                    ? m.auditItem.auditAdmin.real_name
                                    : ""
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  m.auditItem ? m.auditItem.audit_time : ""
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-b orderFoot" }, [
        _c(
          "div",
          { staticClass: "flex-one" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.onPrint },
              },
              [_vm._v("打印清单")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }