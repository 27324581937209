"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      templateName: ''
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  mounted: function mounted() {
    var _this = this;
    // console.log(this.venue_name);
    (0, _system.templatePackageListApi)().then(function (res) {
      var row = res.data.list.find(function (item) {
        return item.is_use;
      });
      _this.templateName = row.name;
    }).catch(function () {});
  },
  methods: {
    changeTab: function changeTab(data) {
      if (data && data !== this.$route.path) {
        this.$router.push({
          path: data
        });
      }
    },
    onGOUrl: function onGOUrl(url, type) {
      this.$router.push({
        path: url
      });
    }
  }
};