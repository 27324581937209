var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c("div", { staticClass: "flex padding20" }, [
            _c("div", { staticClass: "flex-one" }, [
              _c(
                "div",
                { staticClass: "remove-control form-view" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        disabled: _vm.status !== 1,
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品类型：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.file_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "file_type", $$v)
                                },
                                expression: "ruleForm.file_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "3d" } }, [
                                _vm._v("3D"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "image" } }, [
                                _vm._v("图片"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "video" } }, [
                                _vm._v("视频"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "audio" } }, [
                                _vm._v("音频"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择分类：" } },
                        [
                          _c("el-cascader", {
                            staticClass: "w300",
                            attrs: {
                              options: _vm.categoryList,
                              props: {
                                expandTrigger: "hover",
                                label: "cate_name",
                                value: "store_category_id",
                              },
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.categoryValue,
                              callback: function ($$v) {
                                _vm.categoryValue = $$v
                              },
                              expression: "categoryValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品名称：", prop: "title" } },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "输入作品名称" },
                            model: {
                              value: _vm.ruleForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "title", $$v)
                              },
                              expression: "ruleForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备案编号：", prop: "gold_no" } },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "输入金色编号" },
                            model: {
                              value: _vm.ruleForm.gold_no,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "gold_no", $$v)
                              },
                              expression: "ruleForm.gold_no",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "输入银色编号" },
                            model: {
                              value: _vm.ruleForm.silver_no,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "silver_no", $$v)
                              },
                              expression: "ruleForm.silver_no",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.file_type != "image"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "封面图：", prop: "file_cover" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "upload-view flex align-items-c justify-c",
                                  staticStyle: { width: "78px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeImg("file_cover")
                                    },
                                  },
                                },
                                [
                                  _vm.ruleForm.file_cover
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: {
                                          src:
                                            _vm.ruleForm.file_cover + "!m640",
                                          fit: "cover",
                                        },
                                      })
                                    : _c("i", { staticClass: "el-icon-plus" }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.ruleForm.file_type == "image"
                                ? "作品图片："
                                : "作品文件：",
                            prop: "file_url",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "upload-view flex align-items-c justify-c",
                              staticStyle: { width: "78px" },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "el-upload-view",
                                  staticStyle: {
                                    width: "78px",
                                    height: "78px",
                                  },
                                  attrs: {
                                    action: "/",
                                    "show-file-list": false,
                                    "http-request": function (file) {
                                      return _vm.uploadImg(file, "file_url")
                                    },
                                    accept: _vm.typeFormat,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "item-flex-center",
                                      staticStyle: {
                                        cursor: "pointer",
                                        width: "78px",
                                        height: "78px",
                                      },
                                    },
                                    [
                                      _vm.ruleForm.file_url &&
                                      _vm.ruleForm.file_type == "image"
                                        ? _c("el-image", {
                                            staticStyle: {
                                              width: "78px",
                                              height: "78px",
                                            },
                                            attrs: {
                                              src:
                                                _vm.ruleForm.file_url + "!m640",
                                              fit: "cover",
                                            },
                                          })
                                        : _vm.ruleForm.file_url
                                        ? _c("el-image", {
                                            staticStyle: {
                                              width: "48px",
                                              height: "48px",
                                            },
                                            attrs: {
                                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/20231119/image/1700408006832455265.png",
                                              fit: "cover",
                                            },
                                          })
                                        : _c("i", {
                                            staticClass: "el-icon-plus",
                                          }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品简介：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "请输入作品简介",
                              autocomplete: "off",
                              resize: "none",
                              rows: "4",
                            },
                            model: {
                              value: _vm.ruleForm.intro,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "intro", $$v)
                              },
                              expression: "ruleForm.intro",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品标签：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: {
                                multiple: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "输入关键词，选择作品标签",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.searchLoading,
                              },
                              model: {
                                value: _vm.ruleForm.label_ids,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "label_ids", $$v)
                                },
                                expression: "ruleForm.label_ids",
                              },
                            },
                            _vm._l(_vm.labelList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  value: item.label_id,
                                  label: item.label_name,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.file_type == "image"
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "作品年代：" } },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入作品年代",
                                  },
                                  model: {
                                    value: _vm.ruleForm.works_age,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "works_age", $$v)
                                    },
                                    expression: "ruleForm.works_age",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "作品尺寸(CM)：" } },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入作品尺寸",
                                  },
                                  model: {
                                    value: _vm.ruleForm.works_size,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "works_size", $$v)
                                    },
                                    expression: "ruleForm.works_size",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("Tinymce", {
            ref: "editor",
            attrs: { height: 400 },
            model: {
              value: _vm.ruleForm.content,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "content", $$v)
              },
              expression: "ruleForm.content",
            },
          }),
          _vm._v(" "),
          _vm.status === 1
            ? _c(
                "div",
                {
                  staticClass: "common-btns",
                  class: { left0: _vm.device === "mobile" },
                  style: { left: _vm.sidebar.opened ? "230px" : "74px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAction("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认提交")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }