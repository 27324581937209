"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: null,
      listLoading: true
    };
  },
  mounted: function mounted() {
    this.load();
  },
  methods: {
    load: function load() {
      var _this = this;
      this.listLoading = true;
      (0, _accounts.getSendConfigApi)(this.queryParams).then(function (response) {
        _this.list = response.data;
        _this.listLoading = false;
      }).catch(function (message) {
        _this.listLoading = false;
        _this.list = [];
      });
    },
    setSendConfigAction: function setSendConfigAction(item) {
      var _this2 = this;
      this.$confirm(item.is_send === 1 ? '确认取消通知？' : '确认开启通知？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _accounts.setSendConfigApi)({
          notice_key: item.notice_key,
          is_send: item.is_send === 1 ? 0 : 1
        }).then(function () {
          _this2.$message.success('操作成功');
          _this2.load();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};