"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      stateForm: {}
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _system.getConfigClassKeys)('erp_config').then(function (res) {
        // console.log(res);
        if (res.data.erp_contract_create_entrust_price_update_switch) res.data.erp_contract_create_entrust_price_update_switch = Number(res.data.erp_contract_create_entrust_price_update_switch);
        if (res.data.erp_contract_create_special_switch) res.data.erp_contract_create_special_switch = Number(res.data.erp_contract_create_special_switch);
        _this.stateForm = res.data;
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      (0, _system.configClassKeysSave)('erp_config', this.stateForm).then(function (res) {
        _this2.$message.success('设置成功');
      });
    }
  }
};