var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rule-table-height" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ft12",
          attrs: {
            data: _vm.list,
            border: "",
            "span-method": _vm.objectSpanMethod,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "规则设置", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "规则详情", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.valType == 2 &&
                    scope.row.type == "validity_period"
                      ? _c("div", [_vm._v("年底到期")])
                      : scope.row.type.indexOf("get_ratio|") == 0
                      ? _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.desc.replace("{s}", scope.row.val)
                            ) +
                              " " +
                              _vm._s(
                                scope.row.status == 2
                                  ? "已关闭获得"
                                  : "已开启获得"
                              )
                          ),
                        ])
                      : scope.row.type.indexOf("use_range|") == 0
                      ? _c("div", [
                          _vm._v(
                            _vm._s(scope.row.desc) +
                              " " +
                              _vm._s(
                                scope.row.status == 2
                                  ? "已关闭抵现"
                                  : "已开启抵现"
                              )
                          ),
                        ])
                      : _c("div", [
                          _vm._v(
                            _vm._s(scope.row.desc.replace("{s}", scope.row.val))
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.time || "-"))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == "switch"
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.switchAction(scope.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(scope.row.val == 1 ? "关闭" : "开启") +
                                  "积分功能"
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.settingAction(scope.row)
                                },
                              },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ft12",
          attrs: {
            border: "",
            data: _vm.ruleList,
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "积分奖励规则", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { class: scope.row.usable ? "color-lv" : "color-gray" },
                      [_vm._v(_vm._s(scope.row.name))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "规则详情", "min-width": "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { class: scope.row.usable ? "" : "color-gray" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.desc
                            .replace("{s}", scope.row.val)
                            .replace("{v}", scope.row.maxVal)
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.usable
                      ? _c("div", [_vm._v(_vm._s(scope.row.time || "-"))])
                      : _c("div", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "mini",
                          disabled: !scope.row.usable,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.settingAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("设置")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "500px",
            "close-on-click-modal": false,
            visible: _vm.setData.show,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setData, "show", $event)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(_vm._s(_vm.setData.title)),
          ]),
          _vm._v(" "),
          _vm.setData.type == "validity_period"
            ? _c("div", { staticClass: "mt20" }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.setData.valType,
                          callback: function ($$v) {
                            _vm.$set(_vm.setData, "valType", $$v)
                          },
                          expression: "setData.valType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("获得积分后按天计算"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("积分获得当年年底到期"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.setData.type == "validity_period" ? "mt10" : "mt20" },
            [
              _vm.setData.content_l || _vm.setData.content_r
                ? _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _vm.setData.content_l
                        ? _c("span", [_vm._v(_vm._s(_vm.setData.content_l))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.setData.type == "integral_name"
                        ? _c("el-input", {
                            staticClass: "ml5 w100",
                            attrs: { controls: false, size: "small" },
                            model: {
                              value: _vm.setData.val,
                              callback: function ($$v) {
                                _vm.$set(_vm.setData, "val", $$v)
                              },
                              expression: "setData.val",
                            },
                          })
                        : _c("el-input-number", {
                            staticClass: "ml5 w100",
                            attrs: {
                              controls: false,
                              size: "small",
                              disabled:
                                _vm.setData.type == "validity_period" &&
                                _vm.setData.valType == 2,
                            },
                            model: {
                              value: _vm.setData.val,
                              callback: function ($$v) {
                                _vm.$set(_vm.setData, "val", $$v)
                              },
                              expression: "setData.val",
                            },
                          }),
                      _vm._v(" "),
                      _vm.setData.content_r
                        ? _c("span", { staticClass: "ml5" }, [
                            _vm._v(_vm._s(_vm.setData.content_r)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.setData.content_l1 || _vm.setData.content_r1
                ? _c(
                    "div",
                    { staticClass: "flex align-items-c mt10" },
                    [
                      _vm.setData.content_l1
                        ? _c("span", [_vm._v(_vm._s(_vm.setData.content_l1))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "ml5 w100",
                        attrs: { controls: false, size: "small" },
                        model: {
                          value: _vm.setData.val1,
                          callback: function ($$v) {
                            _vm.$set(_vm.setData, "val1", $$v)
                          },
                          expression: "setData.val1",
                        },
                      }),
                      _vm._v(" "),
                      _vm.setData.content_r1
                        ? _c("span", { staticClass: "ml5" }, [
                            _vm._v(_vm._s(_vm.setData.content_r1)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.setData.type.indexOf("get_ratio|") == 0 ||
              _vm.setData.type.indexOf("use_range|") == 0
                ? _c(
                    "div",
                    { staticClass: "flex align-items-c mt20" },
                    [
                      _c("span", [
                        _vm._v(
                          "是否开启" +
                            _vm._s(
                              _vm.setData.type.indexOf("get_ratio|") == 0
                                ? "获得积分"
                                : "积分抵现"
                            ) +
                            "："
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.setData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.setData, "status", $$v)
                            },
                            expression: "setData.status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("开启"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("关闭"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t ft14 fWeight500 mt20" }, [
      _vm._v("积分规则设置\n    "),
      _c("div", { staticClass: "bg" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "t ft14 fWeight500 mt40" }, [
      _vm._v("积分奖励规则设置\n    "),
      _c("div", { staticClass: "bg" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }