"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var groupRouter = {
  path: "".concat(_settings.roterPre, "/group"),
  name: 'system_group',
  meta: {
    icon: 'dashboard',
    title: '组合数据'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'list',
    name: 'system_group_lst',
    meta: {
      title: '组合数据'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/groupData/list'));
      });
    }
  }, {
    path: 'data/:id?',
    name: 'system_group_data',
    meta: {
      title: '组合数据列表'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/groupData/data'));
      });
    }
  }]
};
var _default = exports.default = groupRouter;