"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = llpay;
function llpay() {
  return new Promise(function (resolve, reject) {
    var style = document.createElement('link');
    style.onload = style.onreadystatechange = function () {
      if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
        twoonload();
      }
      style.onload = style.onreadystatechange = null;
    };
    style.type = 'text/css';
    style.rel = "stylesheet";
    style.id = "linkstyle";
    style.href = "https://mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/microdone.css";
    style.onerror = reject;
    console.log(style);
    document.body.appendChild(style);
    function twoonload() {
      var script = document.createElement('script');
      script.onload = script.onreadystatechange = function () {
        if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
          threeonload();
        }
        script.onload = script.onreadystatechange = null;
      };
      script.type = 'text/javascript';
      script.id = "latelyPayTime";
      script.src = 'https://mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/jquery.min.js';
      script.onerror = reject;
      document.body.appendChild(script);
    }
    function threeonload() {
      var script = document.createElement('script');
      script.onload = script.onreadystatechange = function () {
        if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
          resolve();
        }
        script.onload = script.onreadystatechange = null;
      };
      script.type = 'text/javascript';
      script.id = "llpay_mic";
      script.src = 'https://mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/microdone.js';
      script.onerror = reject;
      document.body.appendChild(script);
    }
  });
}