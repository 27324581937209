"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _order = require("@/api/order");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = require("@/utils/index");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [{
          img: 'https://saas.cdn.yunzongbu.cn/merchant/338807/2022426/image/1650954547314824622.jpg',
          name: '天降祥瑞宫猫',
          sdk: '443****480',
          price: 9999.01,
          fx: 100,
          sock: 200,
          type: '图片',
          status: 1,
          order_num: 1,
          admin: '晓琪'
        }],
        total: 0
      },
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      queryParams: {
        page: 1,
        limit: 10,
        type: '1'
      },
      timeVal: [],
      date: '',
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      keyword: '',
      status: '',
      deliverDetail: {
        show: false,
        keyword: '',
        status: '',
        queryParams: {
          page: 1,
          limit: 10
        },
        listLoading: false,
        tableData: {
          data: [{
            img: 'https://saas.cdn.yunzongbu.cn/merchant/338807/2022426/image/1650954547314824622.jpg',
            name: '天降祥瑞宫猫',
            sdk: '443****480',
            price: 9999.01,
            fx: 100,
            sock: 200,
            type: '图片',
            status: 1,
            order_num: 1,
            admin: '晓琪'
          }],
          total: 0
        }
      },
      newDeliverDetail: {
        show: false,
        name: '',
        work: '',
        obj: '1',
        user: '',
        condition: {
          registerTime: [],
          minPayPrice: '',
          minPayTimes: '',
          latelyPayTime: []
        },
        timeType: '1',
        time: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入任务名称',
          trigger: 'blur'
        }],
        work: [{
          required: true,
          message: '请选择空投作品',
          trigger: 'change'
        }],
        time: [{
          required: true,
          message: '请设置空投时间',
          trigger: 'change'
        }]
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      }
    };
  },
  methods: {
    getList: function getList(num) {
      // this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      // auctionOrderListApi(this.queryParams)
      //   .then(res => {
      //     this.tableData.data = res.data.list;
      //     this.tableData.total = res.data.count;
      //     this.listLoading = false;
      //   })
      //   .catch(res => {
      //     // this.$message.error(res.message);
      //     this.listLoading = false;
      //   });
    },
    goSearch: function goSearch() {
      this.queryParams.keyword = this.keyword;
      this.queryParams.status = this.status;
      this.getList(1);
    },
    changeTab: function changeTab(val) {
      this.queryParams.type = val;
      this.getList(1);
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.queryParams.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.queryParams.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this = this;
      this.queryParams.export_type = 'auction_order';
      (0, _system.exportexcel)(this.queryParams).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this.$createElement;
        _this.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this.$msgbox.close();
                _this.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('auction_order');
    },
    addAction: function addAction() {
      this.newDeliverDetail = {
        show: true,
        name: '',
        work: '',
        obj: '1',
        user: '',
        condition: {
          registerTime: [],
          minPayPrice: '',
          minPayTimes: '',
          latelyPayTime: []
        },
        timeType: '1',
        time: ''
      };
    },
    showDetailAction: function showDetailAction() {
      this.deliverDetail = {
        show: true,
        keyword: '',
        status: '',
        queryParams: {
          page: 1,
          limit: 10
        },
        listLoading: false,
        tableData: {
          data: [{
            img: 'https://saas.cdn.yunzongbu.cn/merchant/338807/2022426/image/1650954547314824622.jpg',
            name: '天降祥瑞宫猫',
            sdk: '443****480',
            price: 9999.01,
            fx: 100,
            sock: 200,
            type: '图片',
            status: 1,
            order_num: 1,
            admin: '晓琪'
          }],
          total: 0
        }
      };
      this.loadUserAction(1);
    },
    goSearchUserAction: function goSearchUserAction() {
      this.loadUserAction(1);
    },
    loadUserAction: function loadUserAction(num) {},
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.newDeliverDetail));
          var subData = {
            name: data.name,
            work: data.work,
            obj: data.obj,
            condition: {},
            time: '',
            timeType: data.timeType
          };
          if (subData.obj === '2') {
            if (!data.user) {
              _this2.$message.error('请设置投放用户');
              return false;
            }
            subData.user = data.user;
          } else if (subData.obj === '3') {
            if (!data.condition.registerTime[0] && !data.condition.minPayPrice && isNaN(data.condition.minPayPrice) && !data.condition.minPayTimes && isNaN(data.condition.minPayTimes) && !data.condition.latelyPayTime[0]) {
              _this2.$message.error('最少设置一个正确条件');
              return false;
            }
            if (data.condition.registerTime[0]) {
              subData.condition.registerTime = data.condition.registerTime.map(function (m) {
                return m / 1000;
              });
            }
            if (data.condition.minPayPrice && !isNaN(data.condition.minPayPrice)) {
              subData.condition.minPayPrice = data.condition.minPayPrice;
            }
            if (data.condition.minPayTimes && !isNaN(data.condition.minPayTimes)) {
              subData.condition.minPayTimes = data.condition.minPayTimes;
            }
            if (data.condition.latelyPayTime[0]) {
              subData.condition.latelyPayTime = data.condition.latelyPayTime.map(function (m) {
                return m / 1000;
              });
            }
          }
          if (subData.timeType === '2') {
            subData.time = data.time / 1000;
          }
          console.log(subData);
        } else {
          return false;
        }
      });
    }
  }
};