var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "common-page-title" }, [
        _c("img", {
          staticClass: "back",
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024529/image/1716954194860207587.png!120a",
            alt: "返回",
          },
          on: { click: _vm.backAction },
        }),
        _vm._v(_vm._s(_vm.$route.query.id ? "商品详情" : "创建商品")),
      ]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-steps",
                {
                  attrs: {
                    active: _vm.currentTab,
                    "align-center": "",
                    "finish-status": "success",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "商品信息" } }),
                  _vm._v(" "),
                  _c("el-step", { attrs: { title: "规格设置" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品封面图：", prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "750*750px" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.image + "!120a",
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品轮播图：",
                            prop: "slider_image",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.slider_image,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "false" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: item + "!120a" },
                                      }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.slider_image.length < 10
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "uploadCont",
                                      attrs: { title: "750*750px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("2")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称：", prop: "store_name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入商品名称" },
                            model: {
                              value: _vm.formValidate.store_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "store_name", $$v)
                              },
                              expression: "formValidate.store_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类：", prop: "cate_id" } },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.merCateList,
                              props: {
                                expandTrigger: "hover",
                                value: "store_category_id",
                                label: "cate_name",
                              },
                            },
                            model: {
                              value: _vm.formValidate.cate_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "cate_id", $$v)
                              },
                              expression: "formValidate.cate_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品关键字：" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入商品关键字" },
                            model: {
                              value: _vm.formValidate.keyword,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "keyword", $$v)
                              },
                              expression: "formValidate.keyword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位：", prop: "unit_name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入单位" },
                            model: {
                              value: _vm.formValidate.unit_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "unit_name", $$v)
                              },
                              expression: "formValidate.unit_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品简介：", prop: "store_info" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder: "请输入商品简介",
                              maxlength: "50",
                            },
                            model: {
                              value: _vm.formValidate.store_info,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "store_info", $$v)
                              },
                              expression: "formValidate.store_info",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "虚拟销量：", prop: "ficti" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              type: "number",
                              maxlength: "22",
                            },
                            model: {
                              value: _vm.formValidate.ficti,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "ficti", $$v)
                              },
                              expression: "formValidate.ficti",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主图视频：", prop: "video_link" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "750*750px" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalVideoTap("1")
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.formValidate.video_link
                            ? _c("div", { staticClass: "iview-video-style" }, [
                                _c(
                                  "video",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%!important",
                                      "border-radius": "10px",
                                    },
                                    attrs: {
                                      src: _vm.formValidate.video_link,
                                      controls: "controls",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                您的浏览器不支持 video 标签。\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "mark" }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-delete iconv",
                                  on: { click: _vm.delVideo },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.attr_info && _vm.attr_info.length > 0
                        ? _vm._l(_vm.attr_info, function (m, i) {
                            return _c(
                              "el-form-item",
                              {
                                key: "attr" + i,
                                attrs: { label: m.attr_name + "：" },
                              },
                              [
                                m.attr_type == 1
                                  ? [
                                      _c("el-input", {
                                        staticClass: "w200",
                                        model: {
                                          value: m.attr_value,
                                          callback: function ($$v) {
                                            _vm.$set(m, "attr_value", $$v)
                                          },
                                          expression: "m.attr_value",
                                        },
                                      }),
                                    ]
                                  : m.attr_type == 2
                                  ? [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: m.attr_value,
                                            callback: function ($$v) {
                                              _vm.$set(m, "attr_value", $$v)
                                            },
                                            expression: "m.attr_value",
                                          },
                                        },
                                        _vm._l(
                                          m.attrValue,
                                          function (item, index) {
                                            return _c(
                                              "el-radio",
                                              {
                                                key: "attrValue" + index + i,
                                                attrs: {
                                                  label: item.attr_value_id,
                                                  size: "mini",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.attr_value_name)
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  : m.attr_type == 3
                                  ? [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          model: {
                                            value: m.attr_value,
                                            callback: function ($$v) {
                                              _vm.$set(m, "attr_value", $$v)
                                            },
                                            expression: "m.attr_value",
                                          },
                                        },
                                        _vm._l(
                                          m.attrValue,
                                          function (item, index) {
                                            return _c(
                                              "el-checkbox",
                                              {
                                                key: "attrValue" + index + i,
                                                attrs: {
                                                  label: item.attr_value_id,
                                                  size: "mini",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.attr_value_name)
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "运费方式：", prop: "temp_id" } },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selWidthd mr20",
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formValidate.postage_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "postage_type",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.postage_type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "运费模板", value: 1 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "固定运费", value: 2 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "包邮", value: 3 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "到付", value: 4 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.formValidate.postage_type == 1
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "运费模板：", prop: "temp_id" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidthd mr20",
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formValidate.temp_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "temp_id",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.temp_id",
                                      },
                                    },
                                    _vm._l(_vm.shippingList, function (item) {
                                      return _c("el-option", {
                                        key: item.shipping_template_id,
                                        attrs: {
                                          label: item.name,
                                          value: item.shipping_template_id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: { size: "small" },
                                      on: { click: _vm.addTem },
                                    },
                                    [_vm._v("添加运费模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm.formValidate.postage_type == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "运费金额：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "运费金额" },
                                    model: {
                                      value: _vm.formValidate.postage_price,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formValidate,
                                          "postage_price",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "formValidate.postage_price",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "星级服务：", prop: "temp_id" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("span", { staticClass: "ft12 mr10" }, [
                                _vm._v("7天退换"),
                              ]),
                              _vm._v(" "),
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.formValidate.service_seven_refund,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "service_seven_refund",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formValidate.service_seven_refund",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ft12 mr10 ml50" }, [
                                _vm._v("保真"),
                              ]),
                              _vm._v(" "),
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value:
                                    _vm.formValidate.service_fake_indemnity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "service_fake_indemnity",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formValidate.service_fake_indemnity",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "限购数量：" } },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "请输入数量" },
                            model: {
                              value: _vm.formValidate.buy_limit_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "buy_limit_num", $$v)
                              },
                              expression: "formValidate.buy_limit_num",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品是否展示：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              on: { change: _vm.onChangeGroup },
                              model: {
                                value: _vm.formValidate.is_show,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "is_show", $$v)
                                },
                                expression: "formValidate.is_show",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("展示"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不展示"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序：" } },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "请输入排序" },
                            model: {
                              value: _vm.formValidate.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "sort", $$v)
                              },
                              expression: "formValidate.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.is_trader == 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否开启礼包：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled: _vm.$route.params.id
                                      ? true
                                      : false,
                                  },
                                  model: {
                                    value: _vm.formValidate.is_gift_bag,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "is_gift_bag",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.is_gift_bag",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: 0 },
                                    },
                                    [_vm._v("否")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "自动上架时间：" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              clearable: "",
                              placeholder: "选择上架时间",
                            },
                            model: {
                              value: _vm.formValidate.shelf_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "shelf_time", $$v)
                              },
                              expression: "formValidate.shelf_time",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10 ft12" }, [
                            _vm._v("不设置不启用自动上架功能"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "自动下架时间：" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              clearable: "",
                              placeholder: "选择下架时间",
                            },
                            model: {
                              value: _vm.formValidate.off_shelf_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "off_shelf_time",
                                  $$v
                                )
                              },
                              expression: "formValidate.off_shelf_time",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10 ft12" }, [
                            _vm._v("不设置不启用自动下架功能"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品详情：" } },
                        [
                          _c("Tinymce", {
                            ref: "editor",
                            attrs: { height: 400 },
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "content", $$v)
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1,
                      expression: "currentTab === 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品规格：", props: "spec_type" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeSpec(
                                    _vm.formValidate.spec_type
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.spec_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "spec_type", $$v)
                                },
                                expression: "formValidate.spec_type",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "radio", attrs: { label: 0 } },
                                [_vm._v("单规格")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("多规格"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.extensionStatus > 0
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "佣金设置：",
                                props: "extension_type",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangetype(
                                        _vm.formValidate.extension_type
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formValidate.extension_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "extension_type",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.extension_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "radio",
                                      attrs: { label: 1 },
                                    },
                                    [_vm._v("单独设置")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("默认设置"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.spec_type === 1
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择规格：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.selectRule,
                                        callback: function ($$v) {
                                          _vm.selectRule = $$v
                                        },
                                        expression: "selectRule",
                                      },
                                    },
                                    _vm._l(_vm.ruleList, function (item) {
                                      return _c("el-option", {
                                        key: item.attr_template_id,
                                        attrs: {
                                          label: item.template_name,
                                          value: item.attr_template_id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr20 ml20",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.confirm },
                                    },
                                    [_vm._v("确认")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      on: { click: _vm.addRule },
                                    },
                                    [_vm._v("添加规格模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.formValidate.attr.length > 0
                            ? _c(
                                "el-form-item",
                                _vm._l(
                                  _vm.formValidate.attr,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "acea-row row-middle" },
                                        [
                                          _c("span", { staticClass: "mr5" }, [
                                            _vm._v(_vm._s(item.value)),
                                          ]),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-circle-close",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemoveAttr(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "rulesBox" },
                                        [
                                          _vm._l(
                                            item.detail,
                                            function (j, indexn) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: indexn,
                                                  staticClass: "mb5 mr10",
                                                  attrs: {
                                                    closable: "",
                                                    size: "small",
                                                    "disable-transitions": false,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.handleClose(
                                                        item.detail,
                                                        indexn
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(j))]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          item.inputVisible
                                            ? _c("el-input", {
                                                ref: "saveTagInput",
                                                refInFor: true,
                                                staticClass: "input-new-tag",
                                                attrs: { size: "small" },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.createAttr(
                                                      item.detail.attrsVal,
                                                      index
                                                    )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.createAttr(
                                                      item.detail.attrsVal,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.detail.attrsVal,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.detail,
                                                      "attrsVal",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.detail.attrsVal",
                                                },
                                              })
                                            : _c(
                                                "el-button",
                                                {
                                                  staticClass: "button-new-tag",
                                                  attrs: { size: "small" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showInput(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v("+ 添加")]
                                              ),
                                        ],
                                        2
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isBtn
                            ? _c(
                                "el-col",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 6,
                                        lg: 9,
                                        md: 9,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "规格：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入规格",
                                            },
                                            model: {
                                              value: _vm.formDynamic.attrsName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDynamic,
                                                  "attrsName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDynamic.attrsName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 6,
                                        lg: 9,
                                        md: 9,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "规格值：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入规格值",
                                            },
                                            model: {
                                              value: _vm.formDynamic.attrsVal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formDynamic,
                                                  "attrsVal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDynamic.attrsVal",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xl: 12,
                                        lg: 6,
                                        md: 6,
                                        sm: 24,
                                        xs: 24,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { staticClass: "noLeft" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "mr15",
                                              attrs: { type: "primary" },
                                              on: { click: _vm.createAttrName },
                                            },
                                            [_vm._v("确定")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            { on: { click: _vm.offAttrName } },
                                            [_vm._v("取消")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isBtn
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: {
                                        type: "primary",
                                        icon: "md-add",
                                      },
                                      on: { click: _vm.addBtn },
                                    },
                                    [_vm._v("添加新规格")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.spec_type === 1 &&
                  _vm.formValidate.attr.length > 0
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              attrs: { label: "批量设置：" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.oneFormBatch,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  attrs: { title: "750*750px" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      772438934
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    type:
                                                      _vm.formThead[iii]
                                                        .title === "商品编号"
                                                        ? "text"
                                                        : "number",
                                                    min: 0,
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.extension_type === 1
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "一级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_one,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_one",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_one",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2286159726
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "二级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_two,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_two",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_two",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4057305350
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        align: "center",
                                        label: "操作",
                                        "min-width": "80",
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "submission",
                                            attrs: { type: "text" },
                                            on: { click: _vm.batchAdd },
                                          },
                                          [_vm._v("批量添加")]
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                    [
                      _vm.formValidate.spec_type === 0
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.OneattrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "dan",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.formValidate.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1357914119
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    type:
                                                      _vm.formThead[iii]
                                                        .title === "商品编号"
                                                        ? "text"
                                                        : "number",
                                                    min: 0,
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.extension_type === 1
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "一级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_one,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_one",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_one",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2286159726
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "二级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_two,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_two",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_two",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4057305350
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.spec_type === 1 &&
                      _vm.formValidate.attr.length > 0
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              attrs: { label: "规格列表：" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    border: "",
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              align: "center",
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "图片",
                                      "min-width": "80",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  attrs: { title: "750*750px" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "duo",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1344940579
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        align: "center",
                                        "min-width": "120",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    type:
                                                      _vm.formThead[iii]
                                                        .title === "商品编号"
                                                        ? "text"
                                                        : "number",
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.extension_type === 1
                                    ? [
                                        _c("el-table-column", {
                                          key: "1",
                                          attrs: {
                                            align: "center",
                                            label: "一级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_one,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_one",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_one",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2286159726
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          key: "2",
                                          attrs: {
                                            align: "center",
                                            label: "二级返佣(元)",
                                            "min-width": "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        type: "number",
                                                        min: 0,
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .extension_two,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "extension_two",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.extension_two",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4057305350
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _vm.isAccountPayType == "338349"
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-top": "30px" },
                              attrs: { label: "入账账户：" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.formValidate.pay_account_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "pay_account_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formValidate.pay_account_id",
                                      },
                                    },
                                    _vm._l(
                                      _vm.paymentAccountList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.pay_account_id,
                                          attrs: {
                                            label: item.name,
                                            value: item.pay_account_id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  如果不选择会进入系统配置的默认账户\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticStyle: { cursor: "pointer" } },
                                        [
                                          _vm._v(
                                            "\n                  （？）\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab > 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab == 0,
                          expression: "currentTab == 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 1 || _vm.$route.params.id,
                          expression: "currentTab === 1 || $route.params.id",
                        },
                      ],
                      staticClass: "submission",
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }