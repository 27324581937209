var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "pt10" },
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "80px" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "作品类型：" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "mr20",
                                  staticStyle: { display: "block" },
                                  attrs: { type: "button", size: "small" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.tableFrom.file_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "file_type", $$v)
                                    },
                                    expression: "tableFrom.file_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "" } },
                                    [_vm._v("全部")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "image" } },
                                    [_vm._v("图片")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "video" } },
                                    [_vm._v("视频")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "audio" } },
                                    [_vm._v("音频")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "3d" } },
                                    [_vm._v("3D文件")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "作品名称：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("el-input", {
                              staticClass: "w200",
                              attrs: { placeholder: "输入作品名称搜索" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "title", $$v)
                                },
                                expression: "tableFrom.title",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml-10",
                          attrs: { label: "选择分类：" },
                        },
                        [
                          _c("el-cascader", {
                            staticClass: "w200",
                            attrs: {
                              clearable: "",
                              options: _vm.categoryList,
                              props: {
                                expandTrigger: "hover",
                                label: "cate_name",
                                value: "store_category_id",
                              },
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.categoryValue,
                              callback: function ($$v) {
                                _vm.categoryValue = $$v
                              },
                              expression: "categoryValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.createdAction },
                        },
                        [_vm._v("创建作品")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20 no-height",
          staticStyle: { width: "100%", color: "#000" },
          attrs: { data: _vm.tableData.data, "highlight-current-row": "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", width: "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "num" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.works_id) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "封面图", width: "70", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "num" }, [
                      _c("img", {
                        staticStyle: { width: "37px", height: "37px" },
                        attrs: { src: scope.row.file_cover + "!120a", alt: "" },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "作品名称", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.title))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "文件格式", "min-width": "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.file_type == "image"
                      ? _c("div", [_vm._v("图片")])
                      : scope.row.file_type == "video"
                      ? _c("div", [_vm._v("视频")])
                      : scope.row.file_type == "audio"
                      ? _c("div", [_vm._v("音频")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.file_type == "3d"
                      ? _c("div", [_vm._v("3D")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "标签", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.label_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "作品介绍", "min-width": "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.file_type == "image"
                      ? _c("div", { staticStyle: { "font-size": "12px" } }, [
                          _c("div", [
                            _vm._v(
                              "作品年代：" +
                                _vm._s(scope.row.extends_data.works_age)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "作品尺寸(CM)：" +
                                _vm._s(scope.row.extends_data.works_size)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "简介", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.intro))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", "min-width": "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.create_time))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "排序" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticStyle: { width: "60px" },
                      on: {
                        blur: function ($event) {
                          return _vm.changeSort(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.sort,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "sort", $$v)
                        },
                        expression: "scope.row.sort",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "130", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          width: "100",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImgAction(scope.row, 2)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                h5二维码/链接\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImgAction(scope.row, 1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                小程序二维码\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ft12 m0 plr2",
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [_vm._v("\n            推广链接\n          ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editAction(scope.row.works_id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.delAction(scope.row.works_id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showFlag, title: "二维码/h5链接" },
          on: {
            "update:visible": function ($event) {
              _vm.showFlag = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v("h5地址：" + _vm._s(_vm.showDetail.h5_url)),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticClass: "w200",
              attrs: { src: _vm.showDetail.qr_code, alt: "" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.miniFlag, title: "小程序二维码" },
          on: {
            "update:visible": function ($event) {
              _vm.miniFlag = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticClass: "w200",
              attrs: { src: _vm.miniurl, alt: "" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block mt20 flex" },
        [
          _c("div", { staticClass: "flex-one" }),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }