"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  namespaced: true,
  state: {
    /**
     * 页面信息
     * @type {Object}
     */
    pageInfo: {},
    /**
     * 事件锁
     * @type {Boolean}
     */
    is_lock: false,
    /**
     * 拖拽标记
     * @type {Number}
     */
    dragIndex: -1,
    /**
     * 放置位置
     * @type {String}
     */
    position: 'top',
    /**
     * 标记
     * @type {Number}
     */
    index: -1,
    /**
     * 元件
     * @type {[type]}
     */
    coms: null,
    /**
     * 页面信息
     * @type {Array}
     */
    pages: []
  },
  mutations: {
    /**
     * 设置页面信息
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setPageInfo: function setPageInfo(state, data) {
      state.pageInfo = data;
      state.index = -1;
      state.dragIndex = -1;
    },
    /**
     * 事件锁
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setLock: function setLock(state, data) {
      state.is_lock = data;
    },
    /**
     * 设置当前原件
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setComs: function setComs(state, data) {
      state.coms = data;
    },
    /**
     * 当前激活
     * @param {[type]} state [description]
     * @param {[type]} value [description]
     */
    setIndex: function setIndex(state, value) {
      state.index = value;
    },
    /**
     * 设置拖拽目的地
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setDragIndex: function setDragIndex(state, data) {
      state.dragIndex = data;
    },
    /**
     * 设置拖拽位置
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setDragPosition: function setDragPosition(state, data) {
      state.position = data;
    },
    /**
     * 设置属性信息
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setAttribute: function setAttribute(state, data) {
      var index = state.index;
      //防止Index=-1时的误操作
      if (index >= 0 && data) {
        var key = data.key,
          value = data.value;
        state.pages[index][key] = JSON.parse(JSON.stringify(value));
      }
    },
    /**
     * 设置页面
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setPagesData: function setPagesData(state, data) {
      state.pages = data;
    }
  },
  actions: {
    /**
     * 添加元件
     * @param  {[type]} state [description]
     * @param  {[type]} data  [description]
     * @return {[type]}       [description]
     */
    pushPagesComs: function pushPagesComs(context) {
      var _context$state = context.state,
        dragIndex = _context$state.dragIndex,
        coms = _context$state.coms,
        pages = _context$state.pages,
        position = _context$state.position;
      if (!coms) return false;
      var element = JSON.parse(JSON.stringify(coms));
      //数据阻隔，否则在激活状态下，在顶部的增加数据会错乱
      var data = JSON.parse(JSON.stringify(pages));
      //在其他位置点
      if (dragIndex > 0) {
        if (position == 'top') {
          dragIndex;
        } else {
          dragIndex++;
        }
        data.splice(dragIndex, 0, element);
      }
      //在顶部添加
      if (dragIndex === 0) {
        if (position == 'top') {
          data.unshift(element);
        } else {
          dragIndex++;
          data.splice(dragIndex, 0, element);
        }
      }
      if (dragIndex == -1) {
        data.push(element);
        dragIndex = data.length - 1 < 0 ? 0 : data.length - 1;
      }
      if (dragIndex == -2) {
        data.push(element);
        dragIndex = data.length - 1 < 0 ? 0 : data.length - 1;
      }
      //重新修改数据元素
      context.commit('setPagesData', data);
      context.commit('setIndex', dragIndex);
      //清空数据
      context.commit('setComs', '');
    }
  }
};