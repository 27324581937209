var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: { "min-height": "100vh" },
        },
        [
          _c("header-breadcrumb", {
            attrs: { name: "库房相关配置", "has-back": "" },
          }),
          _vm._v(" "),
          _c(
            "el-card",
            [
              _c(
                "div",
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-demo default-tabs",
                      attrs: { "default-active": _vm.tab, mode: "horizontal" },
                      on: { select: _vm.changeTab },
                    },
                    [
                      _c("el-menu-item", { attrs: { index: "1" } }, [
                        _vm._v("入库单"),
                      ]),
                      _vm._v(" "),
                      _c("el-menu-item", { attrs: { index: "2" } }, [
                        _vm._v("出库单"),
                      ]),
                      _vm._v(" "),
                      _c("el-menu-item", { attrs: { index: "3" } }, [
                        _vm._v("调拨单"),
                      ]),
                      _vm._v(" "),
                      _c("el-menu-item", { attrs: { index: "4" } }, [
                        _vm._v("仓库配置"),
                      ]),
                      _vm._v(" "),
                      _c("el-menu-item", { attrs: { index: "5" } }, [
                        _vm._v("规则配置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.setChangePage },
                        model: {
                          value: _vm.changePage,
                          callback: function ($$v) {
                            _vm.changePage = $$v
                          },
                          expression: "changePage",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("仓库列表"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "2" } }, [
                          _vm._v("货架列表"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { height: "32px" },
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.createStore },
                      },
                      [_vm._v("创建仓库")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: { data: _vm.tableData.data, border: "", size: "mini" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "warehouse_id", label: "ID", width: "40" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.warehouse_id) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "仓库名称", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.name) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "仓库地址",
                      prop: "address_detail",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.address_detail) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contact_name",
                      label: "联系人",
                      width: "90",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.contact_name) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contact_phone",
                      label: "手机号",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.contact_phone) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "创建时间",
                      width: "155",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.create_time) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "当前状态", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.status == 1 ? "启用" : "禁用"
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelf_count",
                      label: "货架数量",
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.shelf_count) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "product_count",
                      label: "商品数",
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.product_count) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column"),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "8",
                    attrs: {
                      label: "操作",
                      width: "80",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "TableRightBtn",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commonedit",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("编辑"),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "template",
                                  { slot: "pop_btn" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeStatus(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "kehu-jinyong",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            scope.row.status == 1
                                              ? "禁用"
                                              : "启用"
                                          )
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "red" },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "commondelete",
                                            color: "red",
                                          },
                                        }),
                                        _vm._v("删除"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "34px" } }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "orderFoot flex align-items-c",
                  staticStyle: { background: "#fff", "z-index": "1" },
                },
                [
                  _c("div", { staticClass: "flex-one" }),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, prev, pager, next, sizes",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("info-from", {
        ref: "infoFrom",
        attrs: { "is-show": _vm.isShow, ids: _vm.OffId },
        on: { subSuccess: _vm.subSuccess },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建仓库",
            visible: _vm.createFlag,
            width: "1000px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "100px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "仓库代码：", prop: "warehouse_no" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: {
                      placeholder: "请输入仓库代码（6位以内英文字母）",
                      maxlength: "6",
                    },
                    on: { input: _vm.changeno },
                    model: {
                      value: _vm.formValidate.warehouse_no,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "warehouse_no", $$v)
                      },
                      expression: "formValidate.warehouse_no",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入仓库名称" },
                    model: {
                      value: _vm.formValidate.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "name", $$v)
                      },
                      expression: "formValidate.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联系人：", prop: "contact_name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入联系人" },
                    model: {
                      value: _vm.formValidate.contact_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "contact_name", $$v)
                      },
                      expression: "formValidate.contact_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号：", prop: "contact_phone" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formValidate.contact_phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "contact_phone", $$v)
                      },
                      expression: "formValidate.contact_phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库地址" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "46%" },
                    attrs: {
                      placeholder: "请选择仓库地址",
                      options: _vm.cityList,
                      props: _vm.props,
                      "collapse-tags": "",
                      clearable: "",
                    },
                    on: { change: _vm.handleCity },
                    model: {
                      value: _vm.formValidate.city_id3,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "city_id3", $$v)
                      },
                      expression: "formValidate.city_id3",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "address_detail" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入详细地址" },
                    model: {
                      value: _vm.formValidate.address_detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "address_detail", $$v)
                      },
                      expression: "formValidate.address_detail",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工作时间", prop: "work_time" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入工作时间" },
                    model: {
                      value: _vm.formValidate.work_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "work_time", $$v)
                      },
                      expression: "formValidate.work_time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formValidate.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "status", $$v)
                        },
                        expression: "formValidate.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("停用")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库类型", prop: "address_detail" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formValidate.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "type", $$v)
                        },
                        expression: "formValidate.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("常规仓"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("临时仓"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库管理员", prop: "manage_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "46%" },
                      attrs: {
                        placeholder: "请选择员工",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.manage_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "manage_id", $$v)
                        },
                        expression: "formValidate.manage_id",
                      },
                    },
                    _vm._l(_vm.manageList, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name || "未命名",
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓储费", prop: "warehouse_fee" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入仓储费" },
                    model: {
                      value: _vm.formValidate.warehouse_fee,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "warehouse_fee", $$v)
                      },
                      expression: "formValidate.warehouse_fee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "搬运费", prop: "porterage_fee" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入搬运费" },
                    model: {
                      value: _vm.formValidate.porterage_fee,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "porterage_fee", $$v)
                      },
                      expression: "formValidate.porterage_fee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }