var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c("div", { staticClass: "flex padding20" }, [
            _c(
              "div",
              { staticClass: "l-upload-img" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: _vm.status !== 1 },
                    model: {
                      value: _vm.ruleForm.image_show_style,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "image_show_style", $$v)
                      },
                      expression: "ruleForm.image_show_style",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "m0", attrs: { label: 1 } },
                          [_vm._v("横图版")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              width: "200",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "175px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20201201/b0d7ed039fdfa80c92d73f94704b0776.jpg",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 plr2 ml10",
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v("预览效果")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt10" },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "m0", attrs: { label: 2 } },
                          [_vm._v("竖图版")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom",
                              width: "200",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "175px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20201201/62207911b240241b7f014cccd96f3f3f.png",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 plr2 ml10",
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v("预览效果")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    {
                      staticClass: "upload-view flex align-items-c justify-c",
                      class: { shu: _vm.ruleForm.image_show_style === 2 },
                      on: { click: _vm.changeImg },
                    },
                    [
                      _vm.ruleForm.cover_image
                        ? _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: _vm.ruleForm.cover_image + "!m640",
                              fit: "cover",
                            },
                          })
                        : _c("i", { staticClass: "el-icon-plus" }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt10 ft14 flex justify-c" }, [
                  _vm._v("直播封面图"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt10 ft12" }, [
                  _c("div", { staticClass: "proposal flex justify-e" }, [
                    _vm._v("建议：图片宽900px"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "proposal flex justify-e" }, [
                    _vm._v(
                      "图片高" +
                        _vm._s(
                          _vm.ruleForm.image_show_style === 2 ? 1200 : 500
                        ) +
                        "px"
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex-one ml20" }, [
              _c(
                "div",
                { staticClass: "remove-control form-view ml20" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm ",
                      attrs: {
                        disabled: _vm.status !== 1,
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播类型：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.live_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "live_type", $$v)
                                },
                                expression: "ruleForm.live_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "news" } }, [
                                _vm._v(
                                  "\n                  新闻\n                "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播标题：", prop: "live_title" } },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "输入直播标题" },
                            model: {
                              value: _vm.ruleForm.live_title,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "live_title", $$v)
                              },
                              expression: "ruleForm.live_title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "直播分类：",
                            "label-for": "cate_id",
                            prop: "cate_id",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.ruleForm.cate_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "cate_id", $$v)
                                },
                                expression: "ruleForm.cate_id",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                {
                                  staticStyle: {
                                    width: "300px",
                                    height: "200px",
                                    overflow: "auto",
                                    "background-color": "#fff",
                                  },
                                  attrs: {
                                    label: _vm.sleOptions.title,
                                    value: _vm.sleOptions.category_id,
                                  },
                                },
                                [
                                  _c("el-tree", {
                                    ref: "tree2",
                                    attrs: {
                                      data: _vm.treeData,
                                      props: _vm.defaultProps,
                                      "highlight-current": "",
                                    },
                                    on: { "node-click": _vm.handleSelClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开播时间：" } },
                        [
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: {
                              "controls-position": "right",
                              min: 2010,
                              max: 2100,
                              controls: false,
                            },
                            model: {
                              value: _vm.startTime.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.startTime, "year", $$v)
                              },
                              expression: "startTime.year",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "plr10" }, [_vm._v("年")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w30",
                            attrs: { min: 1, max: 12, controls: false },
                            model: {
                              value: _vm.startTime.month,
                              callback: function ($$v) {
                                _vm.$set(_vm.startTime, "month", $$v)
                              },
                              expression: "startTime.month",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "plr10" }, [_vm._v("月")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w30",
                            attrs: { min: 1, max: 31, controls: false },
                            model: {
                              value: _vm.startTime.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.startTime, "date", $$v)
                              },
                              expression: "startTime.date",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "plr10" }, [_vm._v("日")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w30",
                            attrs: { min: 0, max: 23, controls: false },
                            model: {
                              value: _vm.startTime.hour,
                              callback: function ($$v) {
                                _vm.$set(_vm.startTime, "hour", $$v)
                              },
                              expression: "startTime.hour",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "plr10" }, [_vm._v("点")]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticClass: "w30",
                            attrs: { min: 0, max: 59, controls: false },
                            model: {
                              value: _vm.startTime.minute,
                              callback: function ($$v) {
                                _vm.$set(_vm.startTime, "minute", $$v)
                              },
                              expression: "startTime.minute",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "plr10" }, [_vm._v("分")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "画面样式：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.live_screen_direction,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "live_screen_direction",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.live_screen_direction",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("竖屏直播"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("横屏直播"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    width: "200",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _vm.ruleForm.live_screen_direction == 1
                                    ? _c("img", {
                                        staticStyle: { width: "175px" },
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20220419/798eab6cc118c1ce9a929cb2656f46bf.png!m640",
                                        },
                                      })
                                    : _vm.ruleForm.live_screen_direction == 2
                                    ? _c("img", {
                                        staticStyle: { width: "175px" },
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20220419/83df8d9513ff997f9aac461e02e21765.png!m640",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12 plr2 ml10",
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("预览效果")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "观看回放：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.is_watch_video_playback,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "is_watch_video_playback",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.is_watch_video_playback",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("结束后可观看回放"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("结束后不可观看回放"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播流类型：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.live_stream_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "live_stream_type",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.live_stream_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "LVB" } }, [
                                _vm._v("标准直播"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "LEB" } }, [
                                _vm._v("快直播"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ruleForm.live_stream_type == "LEB"
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [
                                  _vm._v(
                                    "（选择快直播模式，用手机设备直播时可能会有卡顿等现象。）"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.ruleForm.live_type == "news"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "虚拟人数：" } },
                            [
                              _c("el-input", {
                                staticClass: "w300",
                                attrs: { placeholder: "虚拟人数" },
                                model: {
                                  value: _vm.ruleForm.virtual_num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "virtual_num", $$v)
                                  },
                                  expression: "ruleForm.virtual_num",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { label: "外链二维码：" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "upload-view flex align-items-c justify-c relative",
                            staticStyle: { width: "78px" },
                            on: {
                              click: function ($event) {
                                return _vm.changeImg("qrcode_url")
                              },
                            },
                          },
                          [
                            _vm.ruleForm.qrcode_url
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    src: _vm.ruleForm.qrcode_url + "!m640",
                                    fit: "cover",
                                  },
                                })
                              : _c("i", { staticClass: "el-icon-plus" }),
                            _vm._v(" "),
                            _vm.ruleForm.qrcode_url
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImgAction("qrcode_url")
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-close" })]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.ruleForm.live_type == "news"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "观看权限：" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#cccccc",
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.ruleForm.open_rule,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "open_rule", $$v)
                                  },
                                  expression: "ruleForm.open_rule",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.open_rule
                        ? _c("el-form-item", { attrs: { label: " " } }, [
                            _c(
                              "div",
                              { staticClass: "tab" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tr flex align-items-c" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "th",
                                        staticStyle: { width: "100%" },
                                      },
                                      [_vm._v("符合如下条件可观看")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.orderArr, function (m, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticClass: "tr flex align-items-c",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "td",
                                          staticStyle: { width: "20%" },
                                        },
                                        [
                                          m.type == "nft"
                                            ? _c("div", [_vm._v("数字藏品")])
                                            : m.type == "nft_album"
                                            ? _c("div", [
                                                _vm._v("数字藏品系列"),
                                              ])
                                            : m.type == "snapshot"
                                            ? _c("div", [_vm._v("快照名单")])
                                            : m.type == "product"
                                            ? _c("div", [_vm._v("商品")])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "td",
                                          staticStyle: { width: "65%" },
                                        },
                                        [
                                          m.type === "snapshot"
                                            ? [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "400px",
                                                    },
                                                    attrs: {
                                                      multiple: "",
                                                      filterable: "",
                                                      remote: "",
                                                      "reserve-keyword": "",
                                                      placeholder:
                                                        "请输入关键词",
                                                      "remote-method":
                                                        _vm.remoteMethod,
                                                      loading:
                                                        _vm.searchLoading,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.changeSnapshotAction,
                                                    },
                                                    model: {
                                                      value: m.data,
                                                      callback: function ($$v) {
                                                        _vm.$set(m, "data", $$v)
                                                      },
                                                      expression: "m.data",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.snapshotList,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          value:
                                                            item.snapshot_id,
                                                          label: item.title,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml10 color-gray",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "已选" +
                                                        _vm._s(_vm.peopleNum) +
                                                        "人"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : m.type === "nft"
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showChangeAction(
                                                          "product_nft",
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        m.data && m.data.length
                                                          ? "已选" +
                                                              m.data.length +
                                                              "件 "
                                                          : ""
                                                      ) + "选择"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml10 color-gray",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "（最多选择20个藏品）"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : m.type === "product"
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showChangeAction(
                                                          "product",
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        m.data && m.data.length
                                                          ? "已选" +
                                                              m.data.length +
                                                              "件 "
                                                          : ""
                                                      ) + "选择"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml10 color-gray",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "（最多选择20个商品）"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : m.type === "nft_album"
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showChangeAction(
                                                          "product_nft_album",
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        m.data && m.data.length
                                                          ? "已选" +
                                                              m.data.length +
                                                              "个系列 "
                                                          : ""
                                                      ) + "选择"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml10 color-gray",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "（最多选择20个系列）"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "td",
                                          staticStyle: { width: "15%" },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.resetAction(i)
                                                },
                                              },
                                            },
                                            [_vm._v("清空")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("Tinymce", {
            ref: "editor",
            attrs: { height: 400 },
            model: {
              value: _vm.ruleForm.message,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "message", $$v)
              },
              expression: "ruleForm.message",
            },
          }),
          _vm._v(" "),
          _vm.status === 1 && _vm.liveType == "news"
            ? _c(
                "div",
                {
                  staticClass: "common-btns",
                  class: { left0: _vm.device === "mobile" },
                  style: { left: _vm.sidebar.opened ? "230px" : "74px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAction("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认提交")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": _vm.orderArr[0].data },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("NftAlbum", {
        ref: "product_nft_album",
        attrs: { "current-data": _vm.orderArr[1].data },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Product", {
        ref: "product",
        attrs: { "current-data": _vm.orderArr[2].data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }