var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "直播" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("风格模板选择"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center",
                  staticStyle: { "margin-top": "15px", "flex-wrap": "wrap" },
                },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c("div", { key: index, staticClass: "change-type" }, [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        class: { curr: _vm.is_active(item) },
                      },
                      [
                        _c("label", [
                          _c("img", { attrs: { src: item.src } }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.content.style,
                                expression: "content.style",
                              },
                            ],
                            staticClass: "choose-input",
                            attrs: { type: "radio", name: "choose" },
                            domProps: {
                              value: item.value,
                              checked: _vm._q(_vm.content.style, item.value),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.content,
                                  "style",
                                  item.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "name color-gray align-c ft12" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm.content.style == 3
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("图片展示方式"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style == 3
                ? _c(
                    "div",
                    { staticClass: "item-align-center flex-wrap" },
                    _vm._l(_vm.imgStyleData, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "change-type",
                          staticStyle: {
                            "margin-right": "20px",
                            "margin-top": "15px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "img",
                              class: {
                                curr: item.value === _vm.facade.img_style,
                              },
                            },
                            [
                              _c("label", [
                                _c("img", { attrs: { src: item.src } }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.facade.img_style,
                                      expression: "facade.img_style",
                                    },
                                  ],
                                  staticClass: "choose-input",
                                  attrs: { type: "radio", name: "choose" },
                                  domProps: {
                                    value: item.value,
                                    checked: _vm._q(
                                      _vm.facade.img_style,
                                      item.value
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.facade,
                                        "img_style",
                                        item.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "name color-gray align-c ft12" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("数据选择"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.content.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "type", $$v)
                        },
                        expression: "content.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("系统自动展示"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("手动选择"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.type === 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("系统自动展示条件"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 1
                ? _c(
                    "div",
                    { staticClass: "item-align-center mt10" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.content.system_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.content, "system_type", $$v)
                            },
                            expression: "content.system_type",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("直播中+直播预告"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("直播中+直播预告+已结束"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("已结束"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 1 && !_vm.loadMore
                ? _c(
                    "div",
                    { staticClass: "item-align-center mt20" },
                    [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("展示数据")]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        attrs: { min: 1, max: 50, label: "数量" },
                        model: {
                          value: _vm.content.system_count,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "system_count", $$v)
                          },
                          expression: "content.system_count",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 2
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("手动选择"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.data &&
              _vm.content.data.length > 0 &&
              _vm.content.type === 2
                ? _c(
                    "div",
                    { staticClass: "pt10" },
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          { attrs: { list: _vm.content.data } },
                          "draggable",
                          _vm.$attrs,
                          false
                        ),
                        _vm._l(_vm.content.data, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "kuang1 item-align-center mt10",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item-align-center flex-one" },
                                [
                                  _c("div", { staticClass: "ft14" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              index === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "go-down-img ml10",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goDown(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              index > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "go-up-img ml10",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goUp(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "del-img",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.type === 2
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("img", {
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "margin-right": "5px",
                        "margin-top": "2px",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fontW500 ft16 ",
                        on: { click: _vm.handleAdditem },
                      },
                      [_vm._v("选择直播")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.page_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_margin", $$v)
                      },
                      expression: "facade.page_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_color", $$v)
                      },
                      expression: "facade.title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.title_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_size", $$v)
                      },
                      expression: "facade.title_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.title_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_weight", $$v)
                        },
                        expression: "facade.title_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.style != 3
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "横图标题" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#000000" },
                        model: {
                          value: _vm.facade.sub_title_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "sub_title_color", $$v)
                          },
                          expression: "facade.sub_title_color",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "详情颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.detail_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "detail_color", $$v)
                      },
                      expression: "facade.detail_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: {
                    label: _vm.content.style == 3 ? "状态背景" : "状态条背景",
                  },
                },
                [
                  _c("colour", {
                    attrs: { colour: "#000000", "no-alpha": "1" },
                    model: {
                      value: _vm.facade.status_bar_bg,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_bar_bg", $$v)
                      },
                      expression: "facade.status_bar_bg",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: {
                    label: _vm.content.style == 3 ? "状态高度" : "状态条高度",
                  },
                },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 200 },
                    model: {
                      value: _vm.facade.status_bar_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "status_bar_height", $$v)
                      },
                      expression: "facade.status_bar_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: {
                    label: _vm.content.style == 3 ? "状态文字" : "状态条文字",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.status_bar_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "status_bar_color", $$v)
                        },
                        expression: "facade.status_bar_color",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "white" } }, [
                        _vm._v("白色"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "black" } }, [
                        _vm._v("黑色"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.item_shadow,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_shadow", $$v)
                      },
                      expression: "facade.item_shadow",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影大小" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_shadow_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_shadow_size", $$v)
                      },
                      expression: "facade.item_shadow_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.top_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "top_radius", $$v)
                      },
                      expression: "facade.top_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.bottom_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "bottom_radius", $$v)
                      },
                      expression: "facade.bottom_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "直播底色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.item_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_background", $$v)
                      },
                      expression: "facade.item_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "直播间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_margin", $$v)
                      },
                      expression: "facade.item_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.style == 1
                ? [
                    _c("choose", {
                      staticClass: "mt20",
                      attrs: {
                        data: _vm.pointerData,
                        title: "指示器样式",
                        margin: "0 10px 0 0",
                        width: "75px",
                        height: "30px",
                      },
                      model: {
                        value: _vm.facade.indicator_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicator_style", $$v)
                        },
                        expression: "facade.indicator_style",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "指示器对齐" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.facade.indicator_align,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "indicator_align", $$v)
                              },
                              expression: "facade.indicator_align",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "left" } }, [
                              _vm._v("居左"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "center" } }, [
                              _vm._v("居中"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "right" } }, [
                              _vm._v("居右"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "选中颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.indicator_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "indicator_color", $$v)
                            },
                            expression: "facade.indicator_color",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm.content.style == 2
                ? [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.column_title_font_size,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade,
                                "column_title_font_size",
                                $$v
                              )
                            },
                            expression: "facade.column_title_font_size",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-align-center" },
                      [
                        _c("div", { staticClass: "ft14" }, [
                          _vm._v("直播名自动换行"),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "flex-one",
                          staticStyle: {
                            "font-size": "12px",
                            color: "#999",
                            margin: "0 6px",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#009406",
                            "inactive-color": "#cccccc",
                          },
                          model: {
                            value: _vm.facade.name_wrap,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "name_wrap", $$v)
                            },
                            expression: "facade.name_wrap",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 3
                ? [
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("图片尺寸")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.img_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "img_width", $$v)
                                  },
                                  expression: "facade.img_width",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { margin: "0 6px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.img_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "img_height", $$v)
                                  },
                                  expression: "facade.img_height",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题行数" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.facade.line_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "line_num", $$v)
                              },
                              expression: "facade.line_num",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "line1" } }, [
                              _vm._v("1行"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "line2" } }, [
                              _vm._v("2行"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "line3" } }, [
                              _vm._v("3行"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字边距" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.text_margin,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "text_margin", $$v)
                            },
                            expression: "facade.text_margin",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "current-data": _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }