"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      status: '1'
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  created: function created() {
    if (this.$route.path == '/merchant/marketing/sms_task/sms/message') this.status = '1';
    if (this.$route.path == '/merchant/marketing/sms_task/sms/sms_sign') this.status = '2';
    if (this.$route.path == '/merchant/marketing/sms_task/sms/sms_template') this.status = '3';
  },
  methods: {
    changeStatusAction: function changeStatusAction(e) {
      if (e == '1') this.$router.replace({
        path: '/merchant/marketing/sms_task/sms/message'
      });
      if (e == '2') this.$router.replace({
        path: '/merchant/marketing/sms_task/sms/sms_sign'
      });
      if (e == '3') this.$router.replace({
        path: '/merchant/marketing/sms_task/sms/sms_template'
      });
    }
  }
};