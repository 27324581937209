"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _review = require("@/api/review");
var _settings = require("@/settings");
var _setting = require("@/api/setting");
var _product = require("@/api/product");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Stokcheck',
  data: function data() {
    return {
      showconfig: false,
      logisticsName: 'refund',
      id: 0,
      type: '',
      formValidate: {},
      rules: {
        bind_fans_type: [{
          required: true,
          message: '请选择绑粉逻辑',
          trigger: 'change'
        }]
      },
      showcreate: false,
      storeArr: [],
      adminarr: [],
      time: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      selectionList: [],
      loading: false,
      goodsList: [],
      checkgoods: {
        task_id: '',
        product_id: ''
      },
      checkflag: false,
      certs: [],
      showcert: false
    };
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  mounted: function mounted() {
    this.getList(1);
    this.getadminList();
    this.getstore();
  },
  methods: {
    changecertflag: function changecertflag(row) {
      this.row = row;
      this.showcert = true;
    },
    checkrecord: function checkrecord(row) {
      this.$router.push({
        path: _settings.roterPre + '/warehouse/checkrecord',
        query: {
          id: row.task_id
        }
      });
    },
    checkstart: function checkstart(row) {
      if (row.stock_take_status == 0) {
        (0, _warehouse.startcheck)(row.task_id);
      }
      this.$router.push({
        path: _settings.roterPre + '/warehouse/checkgoods',
        query: {
          id: row.task_id
        }
      });
    },
    cursorcert: function cursorcert() {
      var _this2 = this;
      (0, _warehouse.uptaskcert)(this.row.task_id, {
        credentials: this.certs.join(',')
      }).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.showcert = false;
      });
    },
    checkgood: function checkgood(row) {
      var _this3 = this;
      (0, _basic.goodswarehouseList)({
        warehouse_id: row.warehouse_id
      }).then(function (res) {
        _this3.goodsList = res.data.list;
      });
      this.checkgoods.task_id = row.task_id;
      this.checkflag = true;
    },
    cursorcheck: function cursorcheck() {
      var _this4 = this;
      if (!this.checkgoods.product_id) {
        return this.$message.error('请选择货品');
      }
      (0, _warehouse.editrecordstatus)(this.checkgoods).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.checkflag = false;
      }).catch(function (msg) {
        _this4.$message.error(msg);
        _this4.checkflag = false;
      });
    },
    delcheck: function delcheck(row) {
      var _this5 = this;
      this.$modalSure('是否删除该任务').then(function () {
        (0, _warehouse.delcheckorder)(row.task_id).then(function (res) {
          if (res.status == 200) {
            _this5.$message.success('删除成功');
            _this5.getList();
          }
        });
      });
    },
    successcheck: function successcheck(row) {
      var _this6 = this;
      this.$modalSure('是否完成该任务').then(function () {
        (0, _warehouse.finishcheckorder)(row.task_id).then(function (res) {
          if (res.status == 200) {
            _this6.$message.success('操作成功');
            _this6.getList();
          }
        });
      });
    },
    changeTime: function changeTime(e) {
      console.log(e);
    },
    getadminList: function getadminList() {
      var _this7 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this7.adminarr = res.data.list;
      });
    },
    getstore: function getstore() {
      var _this8 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this8.storeArr = res.data.list;
      });
    },
    submitForm: function submitForm(formName) {
      var _this9 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this9.loading = true;
          (0, _warehouse.checkorder)(_this9.formValidate).then(function (res) {
            _this9.loading = false;
            if (res.status == 200) {
              _this9.$message.success('保存成功');
              _this9.showcreate = false;
              _this9.getList();
            }
          }).catch(function (res) {
            _this9.loading = false;
            // this.$message.error(res.message);
          });
        } else {
          return false;
        }
      });
    },
    checkcreate: function checkcreate() {
      this.showcreate = true;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this10 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _warehouse.getcheckorderlist)(this.tableFrom).then(function (res) {
        _this10.tableData.data = res.data.list;
        _this10.tableData.total = res.data.count;
        _this10.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this10.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this11 = this;
      console.log(tit, num, i);
      var _this = this;
      this.$modalUpload(function (img) {
        console.log(img);
        _this11.certs = _this11.certs.concat(img);
        _this11.$forceUpdate();
      }, 2);
    }
  }
};