"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      uid: '',
      showUser: false,
      keyword: '',
      userFrom: {
        nickname: '',
        page: 1,
        limit: 10
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  methods: {
    init: function init(id) {
      this.uid = id;
      this.keyword = '';
      this.userFrom = {
        nickname: '',
        page: 1,
        limit: 10
      };
      this.tableData = {
        data: [],
        total: 0
      };
      this.showUser = true;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.userFrom.page = num || this.userFrom.page;
      (0, _user.searchUserLstApi)(this.userFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.userFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.limit = val;
      this.getList('');
    },
    handleClick: function handleClick() {
      this.userFrom.page = 1;
      this.userFrom.keyword = this.keyword;
      this.getList();
    },
    changeUserAction: function changeUserAction(row) {
      this.$emit('changeUid', {
        uid: row.uid,
        avatar: row.avatar,
        phone: row.phone,
        nickname: row.nickname,
        real_name: row.real_name
      });
      this.showUser = false;
    }
  }
};