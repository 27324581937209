var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSelectAuction,
            width: "800px",
            "close-on-click-modal": false,
            "append-to-body": "",
            title: "生成推文选拍品",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectAuction = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "h400",
              staticStyle: { "border-top": "1px solid #eee" },
            },
            [
              _c(
                "div",
                { staticClass: "flex", staticStyle: { "padding-top": "20px" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { height: "32px" },
                      attrs: {
                        placeholder:
                          "拍品名称/LOT（多件拍品同时搜索，LOT号用，号隔开）",
                        size: "small",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchAAction($event)
                        },
                      },
                      model: {
                        value: _vm.auctionName,
                        callback: function ($$v) {
                          _vm.auctionName = $$v
                        },
                        expression: "auctionName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: { click: _vm.searchAAction },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.resultData && _vm.resultData.length
                    ? _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          staticStyle: { height: "32px" },
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.cursorSelect },
                        },
                        [_vm._v("确认生成")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          staticStyle: { height: "32px" },
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.quickSelect },
                        },
                        [_vm._v("确认生成")]
                      ),
                  _vm._v(" "),
                  _vm.autionLen < 50
                    ? _c(
                        "div",
                        {
                          staticClass: "flex item-align-center",
                          staticStyle: { "flex-direction": "column" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "ml20",
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.onWholeAll },
                            },
                            [_vm._v("生成整场")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml20",
                              staticStyle: { "font-size": "12px" },
                            },
                            [_vm._v("本场" + _vm._s(_vm.autionLen) + "件拍品")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.resultData && _vm.resultData.length
                ? _c(
                    "table",
                    { staticClass: "spec-table mt20" },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticStyle: { height: "30px" },
                              attrs: { width: "35%", align: "center" },
                            },
                            [_vm._v("拍品信息")]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { attrs: { width: "25%", align: "center" } },
                            [_vm._v("拍卖时间")]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { attrs: { width: "20%", align: "center" } },
                            [_vm._v("价格")]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { attrs: { width: "10%", align: "center" } },
                            [_vm._v("拍品状态")]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { attrs: { width: "10%", align: "center" } },
                            [
                              _vm.size
                                ? _c("div", [_vm._v("操作")])
                                : _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: _vm.auctionSelectedAllAction,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.isAll ? "取消全选" : "全选")
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.resultData, function (m, i) {
                        return _c(
                          "tbody",
                          { key: i, staticClass: "list ft12" },
                          [
                            _c("tr", { staticClass: "border-t" }, [
                              _c("td", { staticClass: "bgWhite" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "display-flex item-align-center",
                                  },
                                  [
                                    m.product.image
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "50px",
                                            height: "50px",
                                            display: "block",
                                          },
                                          attrs: {
                                            src: m.product.image + "!120a",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "ml10 flex-one ft12" },
                                      [
                                        _c("div", [
                                          _vm._v("LOT: " + _vm._s(m.lot)),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(m.product_id) +
                                              " | " +
                                              _vm._s(m.product.store_name)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "bgWhite",
                                  attrs: { align: "center" },
                                },
                                [
                                  m.album_id
                                    ? _c("p", [
                                        _vm._v(
                                          "专场：" +
                                            _vm._s(m.album_id) +
                                            " | " +
                                            _vm._s(
                                              m.album ? m.album.album_name : ""
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  m.album
                                    ? _c("p", [
                                        _vm._v(
                                          "预展：" +
                                            _vm._s(
                                              _vm.parseTime(
                                                m.album.scheduled_show_time,
                                                "{y}-{m}-{d} {h}:{i}"
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  m.start_time
                                    ? _c("p", [
                                        _vm._v(
                                          "开拍：" +
                                            _vm._s(m.start_time_format[1])
                                        ),
                                      ])
                                    : m.album && m.album.album_start_time
                                    ? _c("p", [
                                        _vm._v(
                                          "开拍：" +
                                            _vm._s(
                                              _vm.parseTime(
                                                m.album.album_start_time,
                                                "{y}-{m}-{d} {h}:{i}"
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  m.end_time
                                    ? _c("p", [
                                        _vm._v(
                                          "结拍：" +
                                            _vm._s(m.end_time_format[1])
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "bgWhite",
                                  attrs: { align: "center" },
                                },
                                [
                                  m.now_price != "0.00"
                                    ? _c("p", [
                                        _vm._v(
                                          "当前价：" + _vm._s(m.now_price)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v("起拍价：" + _vm._s(m.start_price)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v("加价幅度：" + _vm._s(m.plus_price)),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "bgWhite",
                                  attrs: { align: "center" },
                                },
                                [
                                  _c(
                                    "p",
                                    [
                                      m.auction_status == 1
                                        ? [_vm._v("进行中")]
                                        : m.auction_status == -1
                                        ? [_vm._v("违约")]
                                        : m.auction_status == 2
                                        ? [_vm._v("预展中")]
                                        : m.auction_status == 3
                                        ? [_vm._v("保留价待确认")]
                                        : m.auction_status == 4
                                        ? [_vm._v("流拍")]
                                        : m.auction_status == 5
                                        ? [_vm._v("已中拍 未付款")]
                                        : m.auction_status == 6
                                        ? [_vm._v("已完成")]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "bgWhite",
                                  attrs: { align: "center" },
                                },
                                [
                                  _vm.selectedAuctionIdList.indexOf(
                                    m.product_id
                                  ) == -1
                                    ? _c("div", [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { color: "#28ab29" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.auctionSelectedAction(
                                                  $event,
                                                  i
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                      ])
                                    : _c("div", [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { color: "#28ab29" },
                                            on: {
                                              click: function ($event) {
                                                _vm.auctionCancelAction(
                                                  $event,
                                                  _vm.selectedAuctionIdList.indexOf(
                                                    m.product_id
                                                  )
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("取消选择")]
                                        ),
                                      ]),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.resultData && _vm.resultData.length
            ? _c(
                "div",
                { staticClass: "flex justify-e mt20" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.queryParams.limit,
                      "current-page": _vm.queryParams.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: { "current-change": _vm.pageChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }