"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _setting = require("@/api/setting");
var _lodash = require("lodash");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "SystemRole",
  data: function data() {
    return {
      grid: {
        xl: 7,
        lg: 7,
        md: 12,
        sm: 24,
        xs: 24
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20,
        role_name: "",
        level: "",
        status: "",
        startTime: "",
        endTime: ""
      },
      isIndeterminate: true,
      oneRuleList: [],
      twoRuleList: [],
      fourRuleList: [],
      threeRuleList: [],
      infoRuleList: [],
      fieldRuleList: [],
      oneall: false,
      twoall: false,
      threeall: false,
      infoall: false,
      fieldall: false,
      rulename: "",
      showruleProp: false,
      editid: "",
      oneitem: {},
      twoitem: {},
      fouritem: {},
      threeitem: {}
    };
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  mounted: function mounted() {
    this.getList();
    this.getallRule();
  },
  methods: {
    getallRule: function getallRule() {
      var _this = this;
      (0, _setting.getRuleListApi)().then(function (res) {
        _this.reduceEditData(res.data, {}, []);
        // res.data.forEach((oneitem) => {
        //   oneitem.selected = false;
        //   if (oneitem.children) {
        //     oneitem.children.forEach((twoitem) => {
        //       twoitem.selected = false;
        //       if (twoitem.children) {
        //         twoitem.children.forEach((threeitem) => {
        //           threeitem.selected = false;
        //           if (threeitem.properties) {
        //             if(threeitem.properties.dataRule){
        //               threeitem.properties.dataRule.forEach((item) => {
        //                 item.selected = false;
        //               });
        //             }
        //             if(threeitem.properties.fields){
        //               threeitem.properties.fields.forEach((item) => {
        //                 item.selected = false;
        //               });
        //             }
        //           }
        //         });
        //       }
        //     });
        //   }
        // });
        _this.oneRuleList = res.data;
        _this.twoRuleList = [];
        _this.threeRuleList = [];
        _this.fourRuleList = [];
        _this.infoRuleList = [];
        _this.fieldRuleList = [];
      });
    },
    clickoneRule: function clickoneRule(item) {
      this.oneitem = item;
      this.twoRuleList = item.children;
      this.fourRuleList = [];
      this.threeRuleList = [];
      this.infoRuleList = [];
      this.fieldRuleList = [];
    },
    clickTwoRule: function clickTwoRule(item) {
      this.twoitem = item;
      if (item.children && item.children.length) {
        if (item.children[0].menu_type == 2) {
          this.fourRuleList = item.children;
          this.threeRuleList = [];
        } else {
          this.threeRuleList = item.children;
          this.fourRuleList = [];
        }
      } else {
        this.threeRuleList = [];
        this.fourRuleList = [];
      }
      this.infoRuleList = [];
      this.fieldRuleList = [];
    },
    clickThreeRule: function clickThreeRule(item) {
      this.threeitem = item;
      console.log(item);
      if (item.properties) {
        this.infoRuleList = item.properties.dataRule || [];
        this.fieldRuleList = item.properties.fields || [];
      } else {
        this.infoRuleList = [];
        this.fieldRuleList = [];
      }
    },
    clickFourRule: function clickFourRule(item) {
      this.fouritem = item;
      this.threeRuleList = item.children;
      this.infoRuleList = [];
      this.fieldRuleList = [];
    },
    changeLast: function changeLast(item, type) {
      this.threeitem.selected = true;
      this.twoitem.selected = true;
      this.oneitem.selected = true;
      if (type == 'data') {
        this.infoRuleList.forEach(function (child) {
          // if(child.key != item.key) {
          //   child.selected = false;
          // }
          if (child.name != item.name) {
            child.selected = false;
          }
        });
      }
    },
    changeChildSelect: function changeChildSelect(selected, list, type, item) {
      var _this2 = this;
      if (type == "one") {} else if (type == "two") {
        this.oneitem.selected = true;
      } else if (type == "three") {
        this.oneitem.selected = true;
        this.twoitem.selected = true;
        if (item.properties) {
          if (item.properties.dataRule) {
            item.properties.dataRule.forEach(function (aitem) {
              if (selected && (aitem.key == "all" || aitem.name == "全部")) {
                aitem.selected = selected;
              }
              if (!selected) {
                aitem.selected = selected;
              }
            });
          }
          if (item.properties.fields) {
            item.properties.fields.forEach(function (aitem) {
              aitem.selected = selected;
            });
          }
        }
      } else if (type == 'four') {
        this.oneitem.selected = true;
        this.twoitem.selected = true;
      }
      list.forEach(function (child) {
        child.selected = selected;
        if (child.children && child.children.length) {
          _this2.changeChildSelect(selected, child.children);
        }
        if (child.properties) {
          if (child.properties.dataRule) {
            child.properties.dataRule.forEach(function (item) {
              if (selected && (item.key == "all" || item.name == "全部")) {
                item.selected = selected;
              }
              if (!selected) {
                item.selected = selected;
              }
            });
          }
          if (child.properties.fields) {
            child.properties.fields.forEach(function (item) {
              item.selected = selected;
            });
          }
        }
      });
    },
    saverule: function saverule() {
      var _this3 = this;
      console.log(this.oneRuleList);
      var oneRuleList = JSON.parse(JSON.stringify(this.oneRuleList));
      var rules = [];
      var properties = [];
      oneRuleList.forEach(function (oneitem) {
        if (oneitem.selected) {
          rules.push(oneitem.id);
        }
        if (oneitem.children) {
          oneitem.children.forEach(function (twoitem) {
            if (twoitem.selected) {
              rules.push(twoitem.id);
            }
            if (twoitem.children) {
              twoitem.children.forEach(function (threeitem) {
                if (threeitem.selected) {
                  rules.push(threeitem.id);
                }
                if (threeitem.children && threeitem.children.length) {
                  threeitem.children.forEach(function (fouritem) {
                    if (fouritem.selected) {
                      rules.push(fouritem.id);
                    }
                    if (fouritem.properties) {
                      var threejson = {
                        display_fields: [],
                        dataRule: {}
                      };
                      var threeitemflag = false;
                      if (fouritem.properties.dataRule) fouritem.properties.dataRule.forEach(function (item) {
                        if (item.selected) {
                          threeitemflag = true;
                          threejson.dataRule = item;
                          // threejson.dataRule.name = item.name;
                          // threejson.dataRule.where = item.where;
                        }
                      });
                      if (fouritem.properties.fields) fouritem.properties.fields.forEach(function (item) {
                        if (item.selected) {
                          threeitemflag = true;
                          threejson.display_fields.push(item.field_key);
                        }
                      });
                      if (threeitemflag) {
                        threejson.menu_id = threeitem.id;
                        properties.push(threejson);
                      }
                    }
                  });
                } else {
                  if (threeitem.properties) {
                    var threejson = {
                      display_fields: [],
                      dataRule: {}
                    };
                    var threeitemflag = false;
                    if (threeitem.properties.dataRule) threeitem.properties.dataRule.forEach(function (item) {
                      if (item.selected) {
                        threeitemflag = true;
                        threejson.dataRule = item;
                        // threejson.dataRule.name = item.name;
                        // threejson.dataRule.where = item.where;
                      }
                    });
                    if (threeitem.properties.fields) threeitem.properties.fields.forEach(function (item) {
                      if (item.selected) {
                        threeitemflag = true;
                        threejson.display_fields.push(item.field_key);
                      }
                    });
                    if (threeitemflag) {
                      threejson.menu_id = threeitem.id;
                      properties.push(threejson);
                    }
                  }
                }
              });
            }
          });
        }
      });
      if (this.editid) {
        (0, _setting.editRuleApi)(this.editid, {
          role_name: this.rulename,
          rules: rules,
          properties_config: properties,
          status: 1
        }).then(function (res) {
          _this3.$message.success("修改成功");
          _this3.showruleProp = false;
          _this3.getList();
        });
        return;
      }
      (0, _setting.saveRuleApi)({
        role_name: this.rulename,
        rules: rules,
        properties_config: properties,
        status: 1
      }).then(function (res) {
        _this3.$message.success("保存成功");
        _this3.showruleProp = false;
        _this3.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      (0, _setting.menuRoleApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this5 = this;
      (0, _setting.roleStatusApi)(row.role_id, row.status).then(function (_ref) {
        var message = _ref.message;
        _this5.$message.success(message);
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this5.$message.error(message);
      });
    },
    // 添加
    onAdd: function onAdd() {
      // this.$modalForm(roleCreateApi()).then(() => this.getList());
      this.editid = "";
      this.rulename = "";
      this.showruleProp = true;
      this.reduceEditData(this.oneRuleList, {}, []);
      this.oneitem = {};
      this.twoitem = {};
      this.threeitem = {};
      this.fouritem = {};
      this.twoRuleList = [];
      this.threeRuleList = [];
      this.fourRuleList = [];
      this.infoRuleList = [];
      this.fieldRuleList = [];
    },
    // 编辑
    onEdit: function onEdit(id, row) {
      this.editid = id;
      this.showruleProp = true;
      var config = {};
      if (row.properties_config) {
        row.properties_config.forEach(function (item) {
          config[item.menu_id] = item;
        });
      }
      this.rulename = row.role_name;
      this.reduceEditData(this.oneRuleList, config, row.rules);
      this.oneitem = {};
      this.twoitem = {};
      this.threeitem = {};
      this.fouritem = {};
      this.fourRuleList = [];
      this.twoRuleList = [];
      this.threeRuleList = [];
      this.infoRuleList = [];
      this.fieldRuleList = [];
      // this.$modalForm(roleUpdateApi(id)).then(() => this.getList());
    },
    reduceEditData: function reduceEditData(item, config, rules) {
      var _this6 = this;
      item.forEach(function (child) {
        if (rules.includes(child.id)) {
          child.selected = true;
        } else {
          child.selected = false;
        }
        if (child.children && child.children.length) {
          _this6.reduceEditData(child.children, config, rules);
        }
        if (child.properties) {
          if (child.properties.dataRule) {
            console.log(child.properties.dataRule);
            child.properties.dataRule.forEach(function (item) {
              if (config[child.id] && item.name == config[child.id].dataRule.name) {
                item.selected = true;
              } else {
                item.selected = false;
              }
            });
          }
          if (child.properties.fields) {
            child.properties.fields.forEach(function (item) {
              if (config[child.id] && config[child.id].display_fields.includes(item.field_key)) {
                item.selected = true;
              } else {
                item.selected = false;
              }
            });
          }
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this7 = this;
      this.$modalSure().then(function () {
        (0, _setting.roleDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this7.$message.success(message);
          _this7.tableData.data.splice(idx, 1);
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this7.$message.error(message);
        });
      });
    }
  }
};