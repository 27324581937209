"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
var _studioDetail = _interopRequireDefault(require("./studioDetail"));
var _index = _interopRequireDefault(require("@/components/importGoods/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'StudioList',
  components: {
    detailsFrom: _studioDetail.default,
    importGoods: _index.default
  },
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      dialogVisible: false,
      importVisible: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableForm: {
        page: 1,
        limit: 20,
        status_tag: '',
        keyword: ''
      },
      liveRoomStatus: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 详情
    onStudioDetails: function onStudioDetails(id, type) {
      this.broadcast_room_id = id;
      this.$refs.studioDetail.dialogVisible = true;
      this.$refs.studioDetail.getData(id, type);
    },
    // 导入
    handleImport: function handleImport(row) {
      this.$refs.uploadGoods.importVisible = true;
      this.$refs.uploadGoods.broadcast_id = row.broadcast_room_id;
      this.$refs.uploadGoods.broadcast_room = row.name;
      this.$refs.uploadGoods.image = '';
      localStorage.setItem('liveRoom_id', row.broadcast_room_id);
    },
    // 删除
    handleDelete: function handleDelete(item, idx) {
      var _this = this;
      item.status == 2 && item.live_status == 101 ? this.$confirm('该直播间正在进行直播，删除后不可恢复，您确认删除吗？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '不删除',
        type: 'warning'
      }).then(function () {
        (0, _marketing.broadcastDeleteApi)(item.broadcast_room_id).then(function (_ref) {
          var message = _ref.message;
          _this.$message.success(message);
          _this.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      }).catch(function (action) {
        _this.$message({
          type: 'info',
          message: '已取消'
        });
      }) : this.$modalSureDelete().then(function () {
        (0, _marketing.broadcastDeleteApi)(item.broadcast_room_id).then(function (_ref3) {
          var message = _ref3.message;
          _this.$message.success(message);
          _this.tableData.data.splice(idx, 1);
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this2 = this;
      this.$modalForm((0, _marketing.studioEdit)(id)).then(function () {
        return _this2.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      console.log(this.tableForm);
      (0, _marketing.broadcastListApi)(this.tableForm).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableForm.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(row) {
      var _this4 = this;
      (0, _marketing.changeStudioRoomDisplayApi)(row.broadcast_room_id, {
        is_show: row.is_mer_show
      }).then(function (_ref5) {
        var message = _ref5.message;
        _this4.$message.success(message);
        _this4.getList();
      }).catch(function (_ref6) {
        var message = _ref6.message;
        _this4.$message.error(message);
      });
    }
  }
};