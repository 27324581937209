"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _choice = _interopRequireDefault(require("@/components/customPage/choice.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _choose = _interopRequireDefault(require("@/components/customPage/choose.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default,
    choice: _choice.default,
    choose: _choose.default
  },
  props: {
    value: '',
    configuration: {
      type: [Array, String],
      default: ''
    },
    colorConfiguration: {
      type: [Array, String],
      default: ''
    }
  },
  data: function data() {
    return {
      fontFamily: '',
      fontFamily_ch: '',
      fontFamilyList: [{
        value: 'PingFangTC-Regular',
        name: '苹方'
      }, {
        value: 'PingFangTC-Medium',
        name: '苹方-粗体'
      }, {
        value: 'PingFangTC-Light',
        name: '苹方-细体'
      }, {
        value: 'PingFangTC-Thin',
        name: '苹方-纤细'
      }, {
        value: 'Optima-Regular',
        name: '优雅体'
      }, {
        value: 'Optima-Bold',
        name: '优雅体-粗体'
      }, {
        value: 'Optima-Italic',
        name: '优雅体-斜体'
      }, {
        value: 'Optima-BoldItalic',
        name: '优雅体-粗斜体'
      }]
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    pageInfo: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    setTimeout(function () {
      _this.fontFamily = _this.pageInfo.fontFamily || 'PingFangTC-Light';
    }, 2000);
  },
  methods: {
    setColor: function setColor(key) {
      this.$emit('setTheme', key);
    },
    setConfiguration: function setConfiguration(i) {
      this.$emit('setConfiguration', i);
    },
    generateColors: function generateColors() {
      this.$emit('generateColors');
    },
    delImg: function delImg(key, key1) {
      if (key1) {
        this.pageInfo[key1][key] = '';
      } else {
        this.pageInfo[key] = '';
      }
    },
    // 上传图片
    modalPicTap: function modalPicTap(tit, key) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.$set(_this2.pageInfo, key, img[0]);
      }, tit);
    },
    changeFontFamilyAction: function changeFontFamilyAction(e) {
      this.pageInfo.fontFamily = e;
    },
    changeFontFamilyCHAction: function changeFontFamilyCHAction(e) {
      this.pageInfo.fontFamily_ch = e;
    }
  }
};