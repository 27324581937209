"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _product = require("@/api/product");
var _system = require("@/api/system");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
var _orderDetail = _interopRequireDefault(require("@/views/order/orderDetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = _interopRequireDefault(require("@/components/cards/index"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    detailsFrom: _orderDetail.default,
    cardsData: _index.default,
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      orderId: 0,
      tableData: {
        data: [],
        total: 0
      },
      changeOrder: [],
      listLoading: true,
      tableFrom: {
        order_sn: this.$route.query.order_sn ? this.$route.query.order_sn : '',
        status: '',
        date: '',
        page: 1,
        limit: 20,
        type: '',
        order_id: this.$route.query.order_id ? this.$route.query.order_id : '',
        order: '',
        box_type: 2
      },
      activityList: [{
        value: '0',
        label: '未开盒'
      }, {
        value: '1',
        label: '已开盒'
      }],
      orderChartType: {},
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      },
      selectionList: [],
      ids: '',
      tableFromLog: {
        page: 1,
        limit: 10
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      LogLoading: false,
      dialogVisible: false,
      fileVisible: false,
      editVisible: false,
      cardLists: [],
      orderDatalist: null,
      headeNum: [],
      editId: '',
      formValidate: {
        total_price: '',
        pay_postage: '',
        pay_price: '',
        coupon_price: ''
      },
      uid: '',
      showdetail: false,
      type: ''
    };
  },
  mounted: function mounted() {
    if (this.$route.query.hasOwnProperty('order_sn')) {
      this.tableFrom.order_sn = this.$route.query.order_sn;
    } else {
      this.tableFrom.order_sn = '';
    }
    if (this.$route.query.hasOwnProperty('type')) {
      this.tableFrom.type = this.$route.query.type;
    }
    if (this.$route.query.hasOwnProperty('page')) {
      this.tableFrom.page = this.$route.query.page;
    }
    if (this.$route.query.hasOwnProperty('limit')) {
      this.tableFrom.limit = this.$route.query.limit;
    }
    if (this.$route.query.hasOwnProperty('date')) {
      this.tableFrom.date = this.$route.query.date;
    }
    this.headerList();
    this.getList(1);
    // this.getHeaderList();
  },
  methods: {
    getAllList: function getAllList() {
      this.headerList();
      this.getList(1);
    },
    // 详情
    onDetails: function onDetails(uid) {
      if (uid === 8888) {
        return false;
      }
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    changeSortAction: function changeSortAction(_ref) {
      var column = _ref.column,
        prop = _ref.prop,
        order = _ref.order;
      if (prop === 'pay_time' && order === 'ascending') {
        // 箭头向上
        this.tableFrom.order = 'pay_time_asc';
      } else if (prop === 'pay_time' && order === 'descending') {
        // 箭头向下
        this.tableFrom.order = 'pay_time_desc';
      } else if (prop === 'pay_time') {
        this.tableFrom.order = '';
      }
      this.getList();
    },
    // 复制
    copy: function copy(item) {
      console.log(item);
      this.copyText = "".concat(item.real_name, "\uFF0C").concat(item.user_phone, "\uFF0C").concat(item.user_address);
      var input = document.createElement('input'); // 直接构建input
      input.value = this.copyText; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('操作成功');
    },
    // 头部
    // getHeaderList() {
    //   orderHeadListApi()
    //     .then(res => {
    //       this.headeNum = res.data;
    //     })
    //     .catch(res => {
    //       // this.$message.error(res.message);
    //     });
    // },
    // 订单筛选
    orderFilter: function orderFilter(item) {
      var status = false;
      item.orderProduct.forEach(function (el) {
        if (el.is_refund) {
          status = true;
        }
      });
      return status;
    },
    // 退款详情页
    onRefundDetail: function onRefundDetail(sn) {
      this.$router.push({
        path: '/merchant/order/refund',
        query: {
          sn: sn
        }
      });
    },
    // 表格某一行添加特定的样式
    addTdClass: function addTdClass(val) {
      if (val.row.status > 0 && val.row.paid == 1) {
        for (var i = 0; i < val.row.orderProduct.length; i++) {
          if (val.row.orderProduct[i].is_refund) {
            return 'row-bg';
          }
        }
      } else {
        return ' ';
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.$router.push({
        path: '/merchant/order/list/all',
        query: {
          order_id: id
        }
      });
      return;
      this.orderId = id;
      this.$refs.orderDetail.dialogVisible = true;
      this.$refs.orderDetail.onOrderDetails(id);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this = this;
      var data = (0, _objectSpread2.default)({
        export_type: 'activityBlindBoxUserList'
      }, this.tableFrom);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this.$createElement;
        _this.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this.$msgbox.close();
                _this.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('activityBlindBoxUserList');
    },
    // 订单核销
    orderCancellation: function orderCancellation() {
      var _this2 = this;
      var that = this;
      this.$prompt('', '提示', {
        confirmButtonText: '立即核销',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入核销码',
        inputErrorMessage: '请输入核销码'
      }).then(function (_ref2) {
        var value = _ref2.value;
        that.handleCancellation(value);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 去核销
    handleCancellation: function handleCancellation(code) {
      var _this3 = this;
      this.$confirm('确定核销此订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _order.orderCancellationApi)(code).then(function (res) {
          _this3.$message.success(res.message);
          _this3.getList('');
        }).catch(function (res) {
          // this.$message.error(res.message);
          _this3.LogLoading = false;
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消核销'
        });
      });
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this4 = this;
      this.dialogVisible = true;
      this.LogLoading = true;
      (0, _order.orderLogApi)(id, this.tableFromLog).then(function (res) {
        _this4.tableDataLog.data = res.data.list;
        _this4.tableDataLog.total = res.data.count;
        _this4.LogLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList('');
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList('');
    },
    // 打印订单
    printOrder: function printOrder(id) {
      var _this5 = this;
      (0, _order.orderPrintApi)(id).then(function (res) {
        _this5.$message.success(res.message);
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this6 = this;
      if (row.is_del === 1) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)(row.order_id).then(function (_ref3) {
            var message = _ref3.message;
            _this6.$message.success(message);
            _this6.tableData.data.splice(idx, 1);
          }).catch(function (_ref4) {
            var message = _ref4.message;
          });
        });
      } else {
        this.$confirm('您选择的的订单存在用户未删除的订单，无法删除用户未删除的订单！', '提示', {
          confirmButtonText: '确定',
          type: 'error'
        });
      }
    },
    // 备注
    onOrderMark: function onOrderMark(id) {
      var _this7 = this;
      this.$modalForm((0, _order.orderRemarkApi)(id)).then(function () {
        return _this7.getList('');
      });
    },
    // handleSelectionChange(val) {
    //   this.selectionList = val;
    //   const data = [];
    //   this.selectionList.map(item => {
    //     data.push(item.id);
    //   });
    //   this.ids = data.join(',');
    // },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 编辑
    edit: function edit(id) {
      var _this8 = this;
      // this.$modalForm(orderUpdateApi(id)).then(() => this.getList(''));
      this.editId = id;
      this.editVisible = true;
      (0, _order.orderDetailApi)(id).then(function (res) {
        _this8.formValidate = {
          pay_postage: res.data.pay_postage,
          total_price: res.data.total_price,
          coupon_price: res.data.coupon_price,
          pay_price: (Number(res.data.total_price) + Number(res.data.pay_postage) - Number(res.data.coupon_price)).toFixed(2)
        };
        _this8.loading = false;
      }).catch(function (_ref5) {
        var message = _ref5.message;
        _this8.loading = false;
      });
    },
    editConfirm: function editConfirm() {
      var _this9 = this;
      (0, _order.orderUpdateApi)(this.editId, this.formValidate).then(function (res) {
        _this9.editVisible = false;
        _this9.$message.success(res.message);
        _this9.getList('');
      }).catch(function (_ref6) {
        var message = _ref6.message;
      });
    },
    changePrice: function changePrice() {
      this.formValidate.pay_price = (this.formValidate.total_price + this.formValidate.pay_postage - this.formValidate.coupon_price).toFixed(2);
    },
    // 发货
    send: function send(id) {
      var _this10 = this;
      this.$router.push({
        path: '/merchant/order/list/all',
        query: {
          order_id: id
        }
      });
      return;
      this.$modalForm((0, _order.auctionDeliveryForm)({
        order_id: id
      })).then(function () {
        return _this10.getList();
      });
    },
    editDeliveryAction: function editDeliveryAction(id) {
      var _this11 = this;
      this.$modalForm((0, _order.auctionUpdateDeliveryForm)(id)).then(function () {
        return _this11.getList();
      });
    },
    // 列表
    getList: function getList(num) {
      var _this12 = this;
      var type = this.tableFrom.type;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      if (type == 1) {
        this.tableFrom.is_open = 0;
        this.tableFrom.is_receive = '';
      } else if (type == 2) {
        this.tableFrom.is_open = 1;
        this.tableFrom.is_receive = '';
      } else if (type == 3) {
        this.tableFrom.is_open = 1;
        this.tableFrom.is_receive = 0;
      } else if (type == 4) {
        this.tableFrom.is_open = 1;
        this.tableFrom.is_receive = 1;
      } else {
        this.tableFrom.is_open = '';
        this.tableFrom.is_receive = '';
      }
      this.$router.push({
        path: '/merchant/product/blind/blindOrder',
        query: (0, _objectSpread2.default)({}, this.tableFrom)
      });
      (0, _product.blindboxOrderListApi)(this.tableFrom).then(function (res) {
        _this12.changeOrder = [];
        _this12.tableData.data = [];
        _this12.tableData.data = res.data.list;
        _this12.tableData.total = res.data.count;
        _this12.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this12.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    headerList: function headerList() {
      var _this13 = this;
      (0, _product.blindboxOrderCountApi)({
        order_type: this.tableFrom.order_type,
        box_type: 2
      }).then(function (res) {
        _this13.orderChartType = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    handleSelectionChange: function handleSelectionChange(data) {
      this.changeOrder = data;
    },
    deliveryAllAction: function deliveryAllAction(id) {
      var _this14 = this;
      if (!this.changeOrder.length) {
        this.$message.error('请选择订单');
        return false;
      }
      var error = '';
      var uid = this.changeOrder[0].uid;
      this.changeOrder.forEach(function (m) {
        if (m.uid !== uid) {
          error = '只能选择同一买家的订单';
        }
        if (m.status !== 1) {
          error = '已选择非待发货状态订单，请检查后重试';
        }
        if (m.delivery_type !== 1) {
          error = '已选择自提订单，请检查后重试';
        }
      });
      if (error) {
        this.$message.error(error);
        return false;
      }
      var ids = this.changeOrder.map(function (m) {
        return m.order_id;
      });
      this.$modalForm((0, _order.auctionDeliveryForm)({
        order_id: ids.join(',')
      })).then(function () {
        return _this14.getList();
      });
    }
  }
};