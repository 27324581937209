"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _template = _interopRequireDefault(require("../../contract/template.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    template: _template.default
  },
  data: function data() {
    return {
      routerUrl: ''
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    },
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  mounted: function mounted() {
    this.routerUrl = this.$route.path;
  },
  methods: {
    onGOUrl: function onGOUrl(url) {
      this.$router.push({
        name: url
      });
    },
    changeTab: function changeTab(data) {
      var _this = this;
      if (data !== this.routerUrl) {
        if (data == '/merchant/user/list/wtList') this.$router.replace({
          path: '/merchant/user/list/wtList?is_entrust=1'
        });else this.$router.replace({
          path: data
        });
        this.routerUrl = data;
        setTimeout(function () {
          _this.routerUrl = _this.$route.path;
        });
      }
    }
  }
};