var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.style === 1
        ? _vm._l(_vm.facade.show ? 2 : 1, function (item) {
            return _c(
              "div",
              {
                key: "album1" + item,
                staticClass: "auction-meeting",
                style: {
                  margin: _vm.facade.show
                    ? 0
                    : "0 " + _vm.facade.page_margin / 2 + "px",
                  paddingBottom: (_vm.content.style === 1 ? 30 : 0) + "px",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  _vm._l(item == 3 ? 1 : 2, function (m) {
                    return _c(
                      "div",
                      {
                        key: m,
                        staticClass: "album flex",
                        style: {
                          marginLeft:
                            (_vm.facade.show
                              ? _vm.facade.page_margin
                              : _vm.facade.item_margin) /
                              2 +
                            "px",
                          width:
                            375 -
                            (_vm.facade.show
                              ? _vm.facade.page_margin
                              : _vm.facade.item_margin) -
                            (item == 3 ? 0 : 32) +
                            "px",
                          paddingBottom: "30px",
                          borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                          borderTopRightRadius:
                            _vm.facade.top_radius / 2 + "px",
                          borderBottomLeftRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          borderBottomRightRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          background: _vm.facade.item_background,
                          paddingBottom:
                            _vm.facade.status_bar_height / 2 + "px",
                          boxShadow: _vm.facade.item_shadow
                            ? "0 0 " +
                              _vm.facade.item_shadow_size / 2 +
                              "px " +
                              _vm.facade.item_shadow
                            : "",
                          marginTop: _vm.facade.show ? "15px" : "0",
                        },
                      },
                      [
                        _vm._m(0, true),
                        _vm._v(" "),
                        _c("div", { staticClass: "info flex-one" }, [
                          _c(
                            "div",
                            {
                              staticClass: "name",
                              style: {
                                fontSize: _vm.facade.title_size / 2 + "px",
                                color: _vm.facade.title_color,
                                fontWeight: _vm.facade.title_weight,
                              },
                            },
                            [
                              _vm._v(
                                "什么是洞见以及其认知特征和意义？|哲学与认知科学明德讲坛第15期暨服务器艺术·人工智能哲学第3期"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "lots",
                              style: { color: _vm.facade.detail_color },
                            },
                            [
                              _c("div", { staticClass: "lot" }, [
                                _vm._v("开播时间"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "lot" }, [
                                _vm._v("2022-05-05 12:00"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "hot-album-b",
                            style: {
                              height: _vm.facade.status_bar_height / 2 + "px",
                              background:
                                "linear-gradient(to right, " +
                                _vm.facade.status_bar_bg +
                                "aa, " +
                                _vm.facade.status_bar_bg +
                                ")",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-bl-bg",
                                style: {
                                  background: _vm.facade.status_bar_bg,
                                  height:
                                    _vm.facade.status_bar_height / 2 + "px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "hot-album-bl-text",
                                    style: {
                                      height:
                                        _vm.facade.status_bar_height / 2 + "px",
                                      color: _vm.facade.status_bar_color,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.facade.status_bar_color ===
                                          "white"
                                            ? "https://saas.cdn.yunzongbu.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png"
                                            : "https://saas.cdn.yunzongbu.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(1, true),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "hot-album-bl-Yinying-wai",
                                    style: {
                                      height:
                                        _vm.facade.status_bar_height / 2 + "px",
                                      width:
                                        (_vm.facade.status_bar_height + 40) /
                                          2 +
                                        "px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "hot-album-bl-Yinying",
                                      style: {
                                        background: _vm.facade.status_bar_bg,
                                        height:
                                          _vm.facade.status_bar_height / 2 +
                                          "px",
                                        width:
                                          _vm.facade.status_bar_height / 2 +
                                          "px",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-br",
                                style: { color: _vm.facade.status_bar_color },
                              },
                              [_vm._v("05月05日 12点 开播")]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                item != 3
                  ? _c("pointer", {
                      attrs: {
                        color: _vm.facade.status_bar_bg,
                        type: _vm.facade.indicator_style,
                        align: _vm.facade.indicator_align,
                        margin: -5,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          })
        : _vm.content.style === 2
        ? [
            _c("div", { staticClass: "auction-meeting" }, [
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  {
                    staticClass: "album flex",
                    style: {
                      marginLeft: _vm.facade.page_margin / 2 + "px",
                      width: 375 - _vm.facade.page_margin + "px",
                      paddingBottom: "30px",
                      borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                      borderTopRightRadius: _vm.facade.top_radius / 2 + "px",
                      borderBottomLeftRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      borderBottomRightRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      background: _vm.facade.item_background,
                      paddingBottom: _vm.facade.status_bar_height / 2 + "px",
                      boxShadow: _vm.facade.item_shadow
                        ? "0 0 " +
                          _vm.facade.item_shadow_size / 2 +
                          "px " +
                          _vm.facade.item_shadow
                        : "",
                      marginTop: _vm.facade.show ? "15px" : "0",
                    },
                  },
                  [
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("div", { staticClass: "info flex-one" }, [
                        _c(
                          "div",
                          {
                            staticClass: "name",
                            style: { color: _vm.facade.title_color },
                          },
                          [
                            _vm._v(
                              "什么是洞见以及其认知特征和意义？|哲学与认知科学明德讲坛第15期暨服务器艺术·人工智能哲学第3期"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "lots",
                            style: { color: _vm.facade.detail_color },
                          },
                          [
                            _c("div", { staticClass: "lot" }, [
                              _vm._v("开播时间"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "lot" }, [
                              _vm._v("2022-05-05 12:00"),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "hot-album-b",
                        style: {
                          height: _vm.facade.status_bar_height / 2 + "px",
                          background:
                            "linear-gradient(to right, " +
                            _vm.facade.status_bar_bg +
                            "aa, " +
                            _vm.facade.status_bar_bg +
                            ")",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "hot-album-bl-bg",
                            style: {
                              background: _vm.facade.status_bar_bg,
                              height: _vm.facade.status_bar_height / 2 + "px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-bl-text",
                                style: {
                                  height:
                                    _vm.facade.status_bar_height / 2 + "px",
                                  color: _vm.facade.status_bar_color,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.facade.status_bar_color === "white"
                                        ? "https://saas.cdn.yunzongbu.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png"
                                        : "https://saas.cdn.yunzongbu.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png",
                                  },
                                }),
                                _vm._v(" "),
                                _vm._m(3),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-bl-Yinying-wai",
                                style: {
                                  height:
                                    _vm.facade.status_bar_height / 2 + "px",
                                  width:
                                    (_vm.facade.status_bar_height + 40) / 2 +
                                    "px",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "hot-album-bl-Yinying",
                                  style: {
                                    background: _vm.facade.status_bar_bg,
                                    height:
                                      _vm.facade.status_bar_height / 2 + "px",
                                    width:
                                      _vm.facade.status_bar_height / 2 + "px",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "hot-album-br",
                            style: { color: _vm.facade.status_bar_color },
                          },
                          [_vm._v("14525人观看")]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "album flex",
                  style: {
                    padding:
                      _vm.facade.page_margin / 4 +
                      "px " +
                      _vm.facade.page_margin / 2 +
                      "px",
                    background: _vm.facade.item_background,
                    marginTop: _vm.facade.item_margin / 2 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "c-info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "c-bottom-img",
                        style: {
                          paddingBottom:
                            _vm.facade.status_bar_height / 2 + "px",
                          borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                          borderTopRightRadius:
                            _vm.facade.top_radius / 2 + "px",
                          borderBottomLeftRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                          borderBottomRightRadius:
                            _vm.facade.bottom_radius / 2 + "px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            staticStyle: {
                              width: "100%",
                              height: "200px",
                              position: "relative",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "abs-name line1",
                                style: {
                                  color: _vm.facade.sub_title_color,
                                  fontSize:
                                    _vm.facade.column_title_font_size / 2 +
                                    "px",
                                  fontWeight: 500,
                                },
                              },
                              [
                                _vm._v(
                                  "什么是洞见以及其认知特征和意义？|哲学与认知科学明德讲坛第15期暨服务器艺术·人工智能哲学第3期"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(4),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "hot-album-b",
                            style: {
                              height: _vm.facade.status_bar_height / 2 + "px",
                              background:
                                "linear-gradient(to right, " +
                                _vm.facade.status_bar_bg +
                                "aa, " +
                                _vm.facade.status_bar_bg +
                                ")",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-bl-bg",
                                style: {
                                  background: _vm.facade.status_bar_bg,
                                  height:
                                    _vm.facade.status_bar_height / 2 + "px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "hot-album-bl-text",
                                    style: {
                                      height:
                                        _vm.facade.status_bar_height / 2 + "px",
                                      color: _vm.facade.status_bar_color,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.facade.status_bar_color ===
                                          "white"
                                            ? "https://saas.cdn.yunzongbu.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png"
                                            : "https://saas.cdn.yunzongbu.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(5),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "hot-album-bl-Yinying-wai",
                                    style: {
                                      height:
                                        _vm.facade.status_bar_height / 2 + "px",
                                      width:
                                        (_vm.facade.status_bar_height + 40) /
                                          2 +
                                        "px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "hot-album-bl-Yinying",
                                      style: {
                                        background: _vm.facade.status_bar_bg,
                                        height:
                                          _vm.facade.status_bar_height / 2 +
                                          "px",
                                        width:
                                          _vm.facade.status_bar_height / 2 +
                                          "px",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "hot-album-br",
                                style: { color: _vm.facade.status_bar_color },
                              },
                              [_vm._v("14525人观看")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        : _vm.content.style == 3
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column-line",
                style: "padding: 0 " + _vm.facade.page_margin / 2 + "px",
              },
              _vm._l(2, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item flex",
                    class: { m0: i == 0 },
                    style: {
                      "background-color": _vm.facade.item_background,
                      marginTop: _vm.facade.item_margin / 2 + "px",
                      borderTopLeftRadius: _vm.facade.top_radius / 2 + "px",
                      borderTopRightRadius: _vm.facade.top_radius / 2 + "px",
                      borderBottomLeftRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      borderBottomRightRadius:
                        _vm.facade.bottom_radius / 2 + "px",
                      boxShadow: _vm.facade.item_shadow
                        ? "0 0 " +
                          _vm.facade.item_shadow_size / 2 +
                          "px " +
                          _vm.facade.item_shadow
                        : "",
                      overflow: "hidden",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          width: _vm.facade.img_width / 2 + "px",
                          height: _vm.facade.img_height / 2 + "px",
                          background: "#e1e1e1",
                          position: "relative",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                            fit:
                              _vm.facade.img_style === 2 ? "contain" : "cover",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "status-view flex align-items-c",
                            style: {
                              lineHeight:
                                _vm.facade.status_bar_height / 2 + "px",
                              color: _vm.facade.status_bar_color,
                              background: _vm.facade.status_bar_bg,
                              borderBottomRightRadius:
                                _vm.facade.bottom_radius / 2 + "px",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.facade.status_bar_color === "white"
                                    ? "https://saas.cdn.yunzongbu.cn/image/20211020/a3cbae95b2a11be4bc547f37376bb960.png"
                                    : "https://saas.cdn.yunzongbu.cn/image/20211210/2090d74445ed130d33288329b1f3409b.png",
                              },
                            }),
                            _vm._v(" "),
                            _vm._m(6, true),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc flex-one",
                        style: {
                          padding: _vm.facade.text_margin / 2 + "px 10px ",
                          color: _vm.facade.detail_color,
                          overflow: "hidden",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "name",
                            class: _vm.facade.line_num || "line3",
                            style: {
                              fontSize: _vm.facade.title_size / 2 + "px",
                              color: _vm.facade.title_color,
                              fontWeight: _vm.facade.title_weight,
                            },
                          },
                          [
                            _vm._v(
                              "什么是洞见以及其认知特征和意义？|哲学与认知科学明德讲坛第15期暨服务器艺术·人工智能哲学第3期"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "bottom flex align-items-c justify-b",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "lots",
                                style: {
                                  color: _vm.facade.detail_color,
                                  fontSize: "12px",
                                  marginTop: "10px",
                                },
                              },
                              [
                                _c("div", { staticClass: "lot" }, [
                                  _vm._v("开播时间 2022-05-05 12:00"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "img", staticStyle: { width: "135px", height: "200px" } },
      [
        _c("div", { staticClass: "no-img item-flex-center" }, [
          _c("img", {
            attrs: {
              src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
              alt: "",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("待开播")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "img", staticStyle: { width: "135px", height: "200px" } },
      [
        _c("div", { staticClass: "no-img item-flex-center" }, [
          _c("img", {
            attrs: {
              src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
              alt: "",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("正在直播")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "no-img item-flex-center" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("正在直播")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("待开播")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }