var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "channels_iframe_wrp",
      staticStyle: { visibility: "visible" },
    },
    [
      _c(
        "mp-common-videosnap",
        {
          class: _vm.content.data.class,
          attrs: {
            contenteditable: "false",
            "data-pluginname": _vm.content.data["data-pluginname"],
            "data-type": _vm.content.data["data-type"],
            "data-id": _vm.content.data["data-id"],
            "data-url": _vm.content.data["data-url"],
            "data-headimgurl": _vm.content.data["data-headimgurl"],
            "data-username": _vm.content.data["data-username"],
            "data-nickname": _vm.content.data["data-nickname"],
            "data-desc": _vm.content.data["data-desc"],
            "data-nonceid": _vm.content.data["data-nonceid"],
            "data-width": _vm.content.data["data-width"],
            "data-height": _vm.content.data["data-height"],
          },
        },
        [
          _c("mpVideosnap", {
            attrs: {
              "data-width": _vm.content.data["data-width"],
              "data-height": _vm.content.data["data-height"],
              "data-nickname": _vm.content.data["data-nickname"],
              "data-url": _vm.content.data["data-url"],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }