var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "mt20" },
      [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("创建战机")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData.data,
              size: "small",
              "row-key": "box_id",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "aircraft_id", label: "ID", "min-width": "60" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "140", label: "战机" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "demo-image__preview",
                              staticStyle: {
                                "flex-shrink": "0",
                                "margin-right": "6px",
                              },
                            },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: scope.row.aircraft_img + "!120a",
                                  "preview-src-list": [scope.row.aircraft_img],
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(" " + _vm._s(scope.row.aircraft_name)),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { width: "140", label: "星球" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "demo-image__preview",
                              staticStyle: {
                                "flex-shrink": "0",
                                "margin-right": "6px",
                              },
                            },
                            [
                              _c("el-image", {
                                attrs: {
                                  src: scope.row.planet_img + "!120a",
                                  "preview-src-list": [scope.row.planet_img],
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(scope.row.planet_name)),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "耐久度", prop: "durable", "min-width": "80" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "成功率", "min-width": "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(scope.row.success_rate) +
                          "%\n                "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "挖矿时间", "min-width": "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(scope.row.duration) +
                          "分钟\n                "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "兑换条件", "min-width": "140" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return _vm._l(
                      scope.row.exchange_conditions.data,
                      function (item, index) {
                        return _c("div", { key: "exchange" + index }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.material_name) +
                              " * " +
                              _vm._s(item.num) +
                              "\n                    "
                          ),
                        ])
                      }
                    )
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "产出", "min-width": "140" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.output && scope.row.output.data
                        ? _vm._l(scope.row.output.data, function (item, index) {
                            return _c("div", { key: "output" + index }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.material_name) +
                                  " (" +
                                  _vm._s(item.output_rate) +
                                  ")\n                    "
                              ),
                            ])
                          })
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "兑换数量",
                "min-width": "100",
                prop: "exchange_num",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.goexchangeNum(scope.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(scope.row.exchange_num))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "add_time",
                label: "创建时间",
                "min-width": "150",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.parseTime(scope.row.add_time)) +
                          "\n                "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "100", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onEdit(scope.row.aircraft_id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        编辑\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(
                                scope.row.aircraft_id,
                                scope.$index
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        删除\n                    "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("file-list", { ref: "exportList" }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.showImg,
              "close-on-click-modal": false,
              center: "",
              title: "查看二维码",
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showImg = $event
              },
            },
          },
          [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.showImgUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }