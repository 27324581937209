var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            visible: _vm.showdetail,
            direction: "rtl",
            size: "1000px",
            "before-close": _vm.changeshowdetail,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showdetail = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "drawer-title" }, [
              _c(
                "div",
                {
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "detail" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("detail")
                    },
                  },
                },
                [_vm._v("\n          货品详情\n        ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "log" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("log")
                    },
                  },
                },
                [_vm._v("\n          日志记录\n        ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "drawer-title-bg" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-body" },
            [
              _vm.drawtype == "detail"
                ? [
                    _c("div", { staticClass: "drawer-name" }, [
                      _vm._v(_vm._s(_vm.productdetail.store_name)),
                    ]),
                    _vm._v(" "),
                    _vm.productdetail.imagearr
                      ? _c(
                          "div",
                          { staticClass: "drawer-image-box" },
                          [
                            _vm._l(
                              _vm.productdetail.imagearr,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "image" + index,
                                    staticClass: "drawer-image",
                                  },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: item + "!120a",
                                        "preview-src-list": [item],
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.productdetail.video_link
                              ? _c("div", { staticClass: "drawer-image" }, [
                                  _c("video", {
                                    attrs: {
                                      src: _vm.productdetail.video_link,
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "drawer-text-box" }, [
                      _c("div", { staticClass: "drawer-text" }, [
                        _vm._v("\n            货品ID："),
                        _c("span", { staticClass: "color-334" }, [
                          _vm._v(_vm._s(_vm.productdetail.product_id)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.productdetail.cate_parent
                        ? _c(
                            "div",
                            { staticClass: "drawer-text" },
                            [
                              _vm._v("\n            货品分类："),
                              _vm._l(
                                _vm.productdetail.cate_parent,
                                function (item, index) {
                                  return _c(
                                    "span",
                                    { staticClass: "color-334" },
                                    [
                                      _vm._v(
                                        _vm._s(item.cate_name) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "drawer-text" }, [
                        _vm._v("\n            作者/品牌："),
                        _vm.productdetail.brand
                          ? _c("span", { staticClass: "color-334" }, [
                              _vm._v(
                                _vm._s(_vm.productdetail.brand.brand_name || "")
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "drawer-text" }, [
                        _vm._v("\n            创建时间："),
                        _c("span", { staticClass: "color-334" }, [
                          _vm._v(_vm._s(_vm.productdetail.create_time)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "drawer-text" }, [
                        _vm._v("\n            录入人员："),
                        _vm.productdetail.creator
                          ? _c("span", { staticClass: "color-334" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.productdetail.creator.real_name || ""
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "drawer-text" }, [
                        _vm._v("\n            货品属性："),
                        _vm.productdetail.attrInfo
                          ? _c(
                              "span",
                              { staticClass: "color-334" },
                              _vm._l(
                                _vm.productdetail.attrInfo,
                                function (m, i) {
                                  return _c("span", { key: "attr_" + i }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(m.attr_value_name) +
                                        "\n                "
                                    ),
                                    i != _vm.productdetail.attrInfo.length - 1
                                      ? _c("span", [_vm._v("/")])
                                      : _vm._e(),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "drawer-text" }, [
                        _vm._v("\n            货品标签："),
                        _vm.productdetail.label &&
                        _vm.productdetail.label.length
                          ? _c(
                              "span",
                              { staticClass: "color-334" },
                              _vm._l(
                                _vm.productdetail.label,
                                function (item, index) {
                                  return _c("span", { key: "label_" + index }, [
                                    _vm._v(
                                      "#" + _vm._s(item) + "\n              "
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("货品介绍"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "drawer-list-right" }, [
                        _vm.productdetail.content
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.productdetail.content),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.drawtype != "log"
                ? _c("div", { staticClass: "drawer-body-title" }, [
                    _vm._v("时间轴"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.drawtype != "log"
                ? _c(
                    "div",
                    { staticClass: "timeline-box" },
                    _vm._l(_vm.timelog, function (item, index) {
                      return _c(
                        "div",
                        { key: "timelog_" + index, staticClass: "timeline" },
                        [
                          _c("div", { staticClass: "timeline-icon" }, [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20220119/677fae73c3e1c270cae37b6f76d23f74.png",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "timeline-content",
                              class: index % 2 == 0 ? "left" : "right",
                            },
                            [
                              index % 2 == 0
                                ? _c(
                                    "div",
                                    { staticClass: "timeline-oneline" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.create_time) + " "),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "timeline-title" },
                                        [_vm._v(_vm._s(item.typeText))]
                                      ),
                                      _vm._v(" "),
                                      item.category == "pm"
                                        ? _c(
                                            "span",
                                            { staticClass: "timeline-title" },
                                            [
                                              _vm._v(
                                                "：" +
                                                  _vm._s(
                                                    item.linkItem.album
                                                      .album_name
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "timeline-oneline" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "timeline-title" },
                                        [_vm._v(_vm._s(item.typeText) + " ")]
                                      ),
                                      _vm._v(" "),
                                      item.category == "pm"
                                        ? _c(
                                            "span",
                                            { staticClass: "timeline-title" },
                                            [
                                              _vm._v(
                                                "：" +
                                                  _vm._s(
                                                    item.linkItem.album
                                                      .album_name
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.create_time) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                              _vm._v(" "),
                              index % 2 == 0
                                ? _c(
                                    "div",
                                    { staticClass: "timeline-twoline" },
                                    [
                                      item.category == "push_warehouse"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "push_warehouse",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "入库单 " +
                                                  _vm._s(item.linkItem.order_no)
                                              ),
                                            ]
                                          )
                                        : item.category == "out_warehouse"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "out_warehouse",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "出库单 " +
                                                  _vm._s(item.linkItem.order_no)
                                              ),
                                            ]
                                          )
                                        : item.category == "entrust"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "entrust",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "委托单 " +
                                                  _vm._s(item.linkItem.no)
                                              ),
                                            ]
                                          )
                                        : item.category == "contract"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "contract",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "合同 " +
                                                  _vm._s(item.linkItem.no)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.linkItem.credentials
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewimg(
                                                    item.linkItem.credentials
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看凭证")]
                                          )
                                        : _vm._e(),
                                      _vm._v("\n              操作人："),
                                      item.creator
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(item.creator.real_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "timeline-twoline" },
                                    [
                                      _vm._v("\n              操作人："),
                                      item.creator
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(item.creator.real_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.category == "push_warehouse"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "push_warehouse",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "入库单 " +
                                                  _vm._s(item.linkItem.order_no)
                                              ),
                                            ]
                                          )
                                        : item.category == "out_warehouse"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "out_warehouse",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "出库单 " +
                                                  _vm._s(item.linkItem.order_no)
                                              ),
                                            ]
                                          )
                                        : item.category == "entrust"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "entrust",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "委托单 " +
                                                  _vm._s(item.linkItem.no)
                                              ),
                                            ]
                                          )
                                        : item.category == "contract"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "contract",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "合同 " +
                                                  _vm._s(item.linkItem.no)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.linkItem.credentials
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewimg(
                                                    item.linkItem.credentials
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看凭证")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.drawtype == "log"
                ? [
                    _vm.catelog.pm
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("上拍记录"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(_vm.catelog.pm, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "pm_" + index,
                                  staticClass: "drawer-l-r-list",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.create_time)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.linkItem.venue.title)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.linkItem.album.album_name)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  item.linkItem.auction.auction_status == -1
                                    ? _c("span", [_vm._v("已违约")])
                                    : item.linkItem.auction.auction_status == 1
                                    ? _c("span", [_vm._v("进行中")])
                                    : item.linkItem.auction.auction_status == 2
                                    ? _c("span", [_vm._v("预展中")])
                                    : item.linkItem.auction.auction_status == 3
                                    ? _c("span", [_vm._v("拍卖结束")])
                                    : item.linkItem.auction.auction_status == 4
                                    ? _c("span", [_vm._v("流拍")])
                                    : item.linkItem.auction.auction_status == 5
                                    ? _c("span", [_vm._v("成交待付款")])
                                    : item.linkItem.auction.auction_status == 6
                                    ? _c("span", [_vm._v("成交已付款")])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.product
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("货品档案"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(_vm.catelog.product, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "push_" + index,
                                  staticClass: "drawer-l-r-list",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.creator.real_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.create_time)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.typeText) + " "),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.entrust
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("委托单"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(_vm.catelog.entrust, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "push_" + index,
                                  staticClass: "drawer-l-r-list",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.creator.real_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.create_time)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.typeText) + " "),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  item.linkItem.no
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.prieviewtype(
                                                "entrust",
                                                item.link_id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.linkItem.no))]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.contract
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("合同"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.contract,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "push_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.creator.real_name)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.typeText) + " "),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    item.linkItem.no
                                      ? _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewtype(
                                                  "contract",
                                                  item.link_id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.linkItem.no))]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.push_warehouse
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("入库单"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.push_warehouse,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "push_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.creator.real_name)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.typeText) + " "),
                                    ]),
                                    _vm._v(" "),
                                    item.linkItem.order_no
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "drawer-l-r-handle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewtype(
                                                  "push_warehouse",
                                                  item.link_id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.linkItem.order_no)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    item.linkItem.pushWarehouse
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.linkItem.pushWarehouse.name
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.push_shelf
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("入位"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.push_shelf,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "push_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.creator.real_name)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.typeText) + " "),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    item.linkItem.credentials
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "drawer-l-r-handle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewimg(
                                                  item.linkItem.credentials
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("查看凭证")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.stock_take
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("盘点记录"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.stock_take,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "stock_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.creator.real_name)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.linkItem.abnormal_status == 1
                                            ? "盘点异常"
                                            : "盘点正常"
                                        ) + "\n              "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    item.linkItem.credentials
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "drawer-l-r-handle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewimg(
                                                  item.linkItem.credentials
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("查看存证")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.out_warehouse
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("出库单"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.out_warehouse,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "out_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.creator.real_name)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.typeText) + " "),
                                    ]),
                                    _vm._v(" "),
                                    item.linkItem.order_no
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "drawer-l-r-handle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewtype(
                                                  "out_warehouse",
                                                  item.link_id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.linkItem.order_no)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    item.linkItem.outWarehouse
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.linkItem.outWarehouse.name
                                            ) + "\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showpreviewcontract,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showpreviewcontract = $event
            },
          },
        },
        [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.html_content) } }),
          _vm._v(" "),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.html_attachment_content) },
          }),
          _vm._v(" "),
          _vm._l(_vm.attachment, function (item, index) {
            return _c(
              "div",
              { key: "attachment" + index },
              [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadf(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.attachment_name))]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "委托标的清单",
            visible: _vm.inventoryDetailFlag,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.inventoryDetailFlag = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm.inventoryDetailAll.status == -1
                ? _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { color: "red" },
                          attrs: { span: 12, offset: 0 },
                        },
                        [
                          _vm._v(
                            "审核失败: " + _vm._s(_vm.inventoryDetailAll.remark)
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v("委托方："),
                    _vm.inventoryDetailAll.userEntrust
                      ? _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.inventoryDetailAll.userEntrust
                                  .entrust_contact_company
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(
                      "单据时间：" + _vm._s(_vm.inventoryDetailAll.create_time)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("入库时间：")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(
                      "单据编号：" + _vm._s(_vm.inventoryDetailAll.order_no)
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(
                _vm.inventoryDetailAll.storeCategory,
                function (pitem, index) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { margin: "20px 0" },
                        attrs: { size: "mini", data: pitem.orderProduct },
                      },
                      [
                        _c("template", { slot: "empty" }, [
                          _c("div", { staticClass: "tableEmpty" }, [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "tableDiv" }, [
                              _vm._v("暂无数据"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "分类", width: "80" } },
                          [_c("div", [_vm._v(_vm._s(pitem.cate_name))])]
                        ),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "图片", width: "50" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "demo-image__preview" },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "36px",
                                            height: "36px",
                                          },
                                          attrs: {
                                            src: scope.row.product.image,
                                            "preview-src-list": [
                                              scope.row.product.image,
                                            ],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "名称", prop: "product.store_name" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "保留价", prop: "retain_price" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.retain_price) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "作者", prop: "product.brand" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.product.brand
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                scope.row.product.brand
                                                  .brand_name
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "属性",
                              prop: "order_pay_amount",
                              align: "center",
                            },
                          },
                          _vm._l(pitem.attribute, function (item, index) {
                            return _c("el-table-column", {
                              key: "ps_" + index,
                              attrs: { label: item.attr_name },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _vm._l(
                                        scope.row.product.attrInfo,
                                        function (m, i) {
                                          return m.attr_id == item.attr_id
                                            ? _c(
                                                "div",
                                                { key: "ps_" + index + i },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        m.attr_value_name
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      )
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "成交收费", align: "center" } },
                          _vm._l(
                            _vm.inventoryConfig.price.sold_price,
                            function (item, index) {
                              return _c("el-table-column", {
                                key: "s_" + index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                scope.row.price_data[0][index]
                                                  .number
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "未成交收费", align: "center" } },
                          _vm._l(
                            _vm.inventoryConfig.price.unsold_price,
                            function (item, index) {
                              return _c("el-table-column", {
                                key: "s_" + index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                scope.row.price_data[1][index]
                                                  .number
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "其他费用", align: "center" } },
                          _vm._l(
                            _vm.inventoryConfig.price.other_price,
                            function (item, index) {
                              return _c("el-table-column", {
                                key: "s_" + index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                scope.row.price_data[2][index]
                                                  .number
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                }
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _vm.inventoryDetailAll.creator
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "制单人：" +
                            _vm._s(_vm.inventoryDetailAll.creator.real_name)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inventoryDetailAll.auditAdmin
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "审批人：" +
                            _vm._s(_vm.inventoryDetailAll.auditAdmin.real_name)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inventoryDetailAll.manageAdmin
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "业务负责人：" +
                            _vm._s(_vm.inventoryDetailAll.manageAdmin.real_name)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inventoryDetailAll.warehouse
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "仓库：" +
                            _vm._s(_vm.inventoryDetailAll.warehouse.name)
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showinto, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.showinto = $event
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "printorder" } },
            [
              _c("div", { staticClass: "detail-box" }, [
                _c("div", { staticClass: "detail-left" }, [
                  _vm._v("货品入库单"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "detail-right" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "data:image/png;base64," +
                        _vm.detailjson.bar_code_image,
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-code" }, [
                    _vm._v(_vm._s(_vm.detailjson.bar_code)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-list" }, [
                _vm.detailjson.pushWarehouse
                  ? _c("div", [
                      _vm._v(
                        "\n          仓库：" +
                          _vm._s(_vm.detailjson.pushWarehouse.name) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm._v("入库时间：" + _vm._s(_vm.detailjson.create_time)),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v(
                    "\n          单据编号：" +
                      _vm._s(_vm.detailjson.order_no) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-list" }, [
                _c("div", [
                  _vm._v("入库类型：" + _vm._s(_vm.detailjson.typeText)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("运输方式：")]),
                _vm._v(" "),
                _c("div", [_vm._v("送货人：")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ptd",
                  staticStyle: {
                    "margin-top": "20px",
                    "font-weight": "bold",
                    "border-bottom": "1px solid #eeeeee",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("图片"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "15%" } }, [
                    _vm._v("拍品名称"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("作者"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("分类"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "13%" } }, [
                    _vm._v("标签"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("货架"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "20%" } }, [
                    _vm._v("属性"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.detailjson.orderProduct, function (item, index) {
                return _c(
                  "div",
                  {
                    staticClass: "ptd",
                    staticStyle: {
                      "margin-top": "20px",
                      "border-bottom": "1px solid #eeeeee",
                      "padding-bottom": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "10%" } },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: item.productItem.image + "!120a",
                            "preview-src-list": [item.productItem.image],
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "15%" } }, [
                      _vm._v(_vm._s(item.productItem.store_name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.productItem.brand
                        ? _c("span", [
                            _vm._v(_vm._s(item.productItem.brand.brand_name)),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.productItem.cate_parent
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.productItem.cate_parent[0].cate_name
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "13%" } }, [
                      item.productItem &&
                      item.productItem.label &&
                      item.productItem.label.length
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.productItem.label.join(",")) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.shelf
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.shelf.shelf_no) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { width: "20%" } },
                      _vm._l(item.productItem.attrInfo, function (m, i) {
                        return _c("span", { key: "oattr_" + i }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(m.attr_name) +
                              ":" +
                              _vm._s(m.attr_value_name) +
                              "\n          "
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "20px" },
                },
                [
                  _vm.detailjson.creator
                    ? _c("div", [
                        _vm._v(
                          "\n          制单人：" +
                            _vm._s(_vm.detailjson.creator.real_name) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailjson.manageAdmin
                    ? _c("div", [
                        _vm._v(
                          "\n          业务负责人：" +
                            _vm._s(_vm.detailjson.manageAdmin.real_name) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [_vm._v("入库员签字：")]),
                ]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.bindPrintorder },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showout, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.showout = $event
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "printorderout" } },
            [
              _c("div", { staticClass: "detail-box" }, [
                _c("div", { staticClass: "detail-left" }, [
                  _vm._v("货品出库单"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "detail-right" }, [
                  _c("img", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      src:
                        "data:image/png;base64," +
                        _vm.detailjson.bar_code_image,
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-code" }, [
                    _vm._v(_vm._s(_vm.detailjson.bar_code)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-list" }, [
                _vm.detailjson.pushWarehouse
                  ? _c("div", [
                      _vm._v(
                        "\n          仓库：" +
                          _vm._s(_vm.detailjson.pushWarehouse.name) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm._v("出库时间：" + _vm._s(_vm.detailjson.create_time)),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v(
                    "\n          单据编号：" +
                      _vm._s(_vm.detailjson.order_no) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "detail-list" }, [
                _c("div", [
                  _vm._v("出库类型：" + _vm._s(_vm.detailjson.typeText)),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("运输方式：")]),
                _vm._v(" "),
                _c("div", [_vm._v("送货人：")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ptd",
                  staticStyle: {
                    "margin-top": "20px",
                    "font-weight": "bold",
                    "border-bottom": "1px solid #eeeeee",
                    "padding-bottom": "10px",
                  },
                },
                [
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("图片"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "15%" } }, [
                    _vm._v("拍品名称"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("作者"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("分类"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "13%" } }, [
                    _vm._v("标签"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v("货架"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "20%" } }, [
                    _vm._v("属性"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.detailjson.orderProduct, function (item, index) {
                return _c(
                  "div",
                  {
                    staticClass: "ptd",
                    staticStyle: {
                      "margin-top": "20px",
                      "border-bottom": "1px solid #eeeeee",
                      "padding-bottom": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "10%" } },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: item.productItem.image,
                            "preview-src-list": [item.productItem.image],
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "15%" } }, [
                      _vm._v(_vm._s(item.productItem.store_name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.productItem.brand
                        ? _c("span", [
                            _vm._v(_vm._s(item.productItem.brand.brand_name)),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.productItem.cate_parent
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.productItem.cate_parent[0].cate_name
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "13%" } }, [
                      item.productItem &&
                      item.productItem.label &&
                      item.productItem.label.length
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.productItem.label.join(",")) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "10%" } }, [
                      item.shelf
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.shelf.shelf_no) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { width: "20%" } },
                      _vm._l(item.productItem.attrInfo, function (m, i) {
                        return _c("span", { key: "oattr_" + i }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(m.attr_name) +
                              ":" +
                              _vm._s(m.attr_value_name) +
                              "\n          "
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "20px" },
                },
                [
                  _vm.detailjson.creator
                    ? _c("div", [
                        _vm._v(
                          "\n          制单人：" +
                            _vm._s(_vm.detailjson.creator.real_name) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailjson.manageAdmin
                    ? _c("div", [
                        _vm._v(
                          "\n          业务负责人：" +
                            _vm._s(_vm.detailjson.manageAdmin.real_name) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [_vm._v("出库员签字：")]),
                ]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: {
                    click: function ($event) {
                      return _vm.bindPrintorder("out")
                    },
                  },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.urlshow
        ? _c("el-image-viewer", {
            staticStyle: { "z-index": "9999" },
            attrs: {
              "on-close": function () {
                _vm.urlshow = false
              },
              "url-list": _vm.urlshowList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }