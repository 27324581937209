var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "用户NFT详情", is_tab: false } },
    [
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.facade,
              "label-width": "80px",
              size: "mini",
              "label-position": "left",
            },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("div", { staticClass: "item-align-center mt20" }, [
              _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                _vm._v("背景图片"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "upload-img-view item-flex-center",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap(1, "bg_image")
                    },
                  },
                },
                [
                  _vm.facade.bg_image
                    ? _c(
                        "div",
                        {
                          staticClass: "del-upload-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg("bg_image")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.facade.bg_image
                    ? _c("img", {
                        attrs: { src: _vm.facade.bg_image + "!300" },
                      })
                    : _c("img", {
                        staticClass: "upload-icon",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                          alt: "",
                        },
                      }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "背景颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#f7f7f7" },
                  model: {
                    value: _vm.facade.background,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "background", $$v)
                    },
                    expression: "facade.background",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "左右边距" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 375 },
                  model: {
                    value: _vm.facade.page_margin,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "page_margin", $$v)
                    },
                    expression: "facade.page_margin",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "高度" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 375 },
                  model: {
                    value: _vm.facade.item_height,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "item_height", $$v)
                    },
                    expression: "facade.item_height",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "圆角" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 200 },
                  model: {
                    value: _vm.facade.page_radius,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "page_radius", $$v)
                    },
                    expression: "facade.page_radius",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "阴影" } },
              [
                _c("colour", {
                  attrs: { colour: "#999999" },
                  model: {
                    value: _vm.facade.page_shadow,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "page_shadow", $$v)
                    },
                    expression: "facade.page_shadow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "阴影大小" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 50 },
                  model: {
                    value: _vm.facade.page_shadow_size,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "page_shadow_size", $$v)
                    },
                    expression: "facade.page_shadow_size",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "内容间距" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 100 },
                  model: {
                    value: _vm.facade.item_padding,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "item_padding", $$v)
                    },
                    expression: "facade.item_padding",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字颜色" } },
              [
                _c("colour", {
                  attrs: { colour: "#000000" },
                  model: {
                    value: _vm.facade.title_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "title_color", $$v)
                    },
                    expression: "facade.title_color",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字风格" } },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "le-radio-group",
                    model: {
                      value: _vm.facade.title_style,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_style", $$v)
                      },
                      expression: "facade.title_style",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 400 } }, [_vm._v("常规")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 500 } }, [_vm._v("加粗")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "mt20", attrs: { label: "文字字号" } },
              [
                _c("slider", {
                  attrs: { min: 0, max: 40 },
                  model: {
                    value: _vm.facade.title_size,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "title_size", $$v)
                    },
                    expression: "facade.title_size",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "item-align-center mt20" }, [
              _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                _vm._v("左侧图标"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "upload-img-view item-flex-center",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap(1, "left_image")
                    },
                  },
                },
                [
                  _vm.facade.left_image
                    ? _c(
                        "div",
                        {
                          staticClass: "del-upload-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg("left_image")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.facade.left_image
                    ? _c("img", {
                        attrs: { src: _vm.facade.left_image + "!300" },
                      })
                    : _c("img", {
                        staticClass: "upload-icon",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                          alt: "",
                        },
                      }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-align-center mt20" }, [
              _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                _vm._v("复制图标"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "upload-img-view item-flex-center",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap(1, "copy_image")
                    },
                  },
                },
                [
                  _vm.facade.copy_image
                    ? _c(
                        "div",
                        {
                          staticClass: "del-upload-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg("copy_image")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.facade.copy_image
                    ? _c("img", {
                        attrs: { src: _vm.facade.copy_image + "!300" },
                      })
                    : _c("img", {
                        staticClass: "upload-icon",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                          alt: "",
                        },
                      }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-align-center mt20" }, [
              _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                _vm._v("二维码图标"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "upload-img-view item-flex-center",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap(1, "ewm_image")
                    },
                  },
                },
                [
                  _vm.facade.ewm_image
                    ? _c(
                        "div",
                        {
                          staticClass: "del-upload-img",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg("ewm_image")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                              alt: "",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.facade.ewm_image
                    ? _c("img", {
                        attrs: { src: _vm.facade.ewm_image + "!300" },
                      })
                    : _c("img", {
                        staticClass: "upload-icon",
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                          alt: "",
                        },
                      }),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }