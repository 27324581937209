var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-button",
        {
          staticClass: "mr20",
          attrs: { size: "mini", icon: "el-icon-back" },
          on: { click: _vm.close },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
                inline: "",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-bottom": "20px",
                        "font-size": "18px",
                      },
                      attrs: { span: 12, offset: 0 },
                    },
                    [_vm._v("创建销售单")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-card",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户：", prop: "uid" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择客户",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.searchUserAction,
                            loading: _vm.searchLoading,
                          },
                          model: {
                            value: _vm.formValidate.uid,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "uid", $$v)
                            },
                            expression: "formValidate.uid",
                          },
                        },
                        _vm._l(_vm.userList, function (m, i) {
                          return _c("el-option", {
                            key: "user" + i,
                            attrs: { value: m.uid, label: m.nickname },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售人员", prop: "sale_admin_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择销售人员",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formValidate.sale_admin_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "sale_admin_id", $$v)
                            },
                            expression: "formValidate.sale_admin_id",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核人", prop: "audit_admin_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: "请选择审核人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formValidate.audit_admin_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "audit_admin_id", $$v)
                            },
                            expression: "formValidate.audit_admin_id",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号", prop: "order_no" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.formValidate.order_no) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.goodsArr,
                        size: "small",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c("div", { staticClass: "tableEmpty" }, [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tableDiv" }, [
                            _vm._v("暂无数据"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "排序", prop: "sort" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.$index + 1) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "货品图" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "demo-image__preview" },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "36px",
                                        height: "36px",
                                      },
                                      attrs: {
                                        src: scope.row.image,
                                        "preview-src-list": [scope.row.image],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "名称", prop: "store_name" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "销售数量", prop: "retain_price" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.sale_number,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sale_number", $$v)
                                    },
                                    expression: "scope.row.sale_number",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "金额", prop: "retain_price" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.product_price,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "product_price", $$v)
                                    },
                                    expression: "scope.row.product_price",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "作者", prop: "brand.name" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "属性", prop: "order_pay_amount" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.attrInfo,
                                function (m, i) {
                                  return _c("span", { key: "attr_" + i }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(m.attr_name) +
                                        ":" +
                                        _vm._s(m.attr_value_name) +
                                        "\n            "
                                    ),
                                  ])
                                }
                              )
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "备注", prop: "retain_price" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.remark,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "remark", $$v)
                                    },
                                    expression: "scope.row.remark",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delGoods(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "commondelete",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                删除\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("\n          确认创建\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "30px" } }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-tabs",
            { attrs: { value: "goods" } },
            [
              _c("el-tab-pane", {
                attrs: { label: "货品档案", name: "goods" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { inline: "", size: "small", "label-width": "110px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "货品搜索：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: {
                        placeholder: "请输入货品名称，关键字，产品编号",
                      },
                      model: {
                        value: _vm.tableFrom.keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "keyword", $$v)
                        },
                        expression: "tableFrom.keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "货品ID：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入货品ID" },
                      model: {
                        value: _vm.tableFrom.product_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "product_id", $$v)
                        },
                        expression: "tableFrom.product_id",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货品ID", prop: "product_id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货品图" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "store_name", label: "货品名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作者", prop: "brand.name" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "属性", prop: "order_pay_amount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.attrInfo, function (m, i) {
                        return _c("span", { key: "attr_" + i }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(m.attr_name) +
                              ":" +
                              _vm._s(m.attr_value_name) +
                              "\n            "
                          ),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "录入",
                  "min-width": "90",
                  prop: "creator.real_name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saleGoods(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.goodsIdArr.indexOf(
                                        scope.row.product_id
                                      ) > -1
                                        ? "已选择货品"
                                        : "选择货品"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.goodsIdArr.indexOf(scope.row.product_id) > -1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelSelect(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              取消选择\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }