"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: "previewcontract",
  props: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
    title: {
      type: String,
      default: '合同名称'
    },
    contractno: {
      type: [String, Number],
      default: ''
    },
    titleDisponsejson: {
      type: Object,
      default: function _default() {
        return {
          direc: 1,
          place: 1,
          linenum: 2
        };
      }
    },
    signDisponsejson: {
      type: Object,
      default: function _default() {
        return {
          direc: 1,
          place: 1
        };
      }
    },
    content: {
      type: String,
      default: ""
    },
    attachmentContent: {
      type: String,
      default: ""
    },
    expiry_date_switch: {
      type: Boolean,
      default: false
    },
    timeVal: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    Catearr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    inventoryConfig: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    entrust_order_switch: {
      type: Boolean,
      default: false
    },
    ownsigndata: {
      type: [Object, Array],
      default: function _default() {
        return [{
          name: "公司名称",
          value: "",
          select: true,
          type: "diabled"
        }, {
          name: "联系人",
          value: "",
          select: true,
          type: "nomal"
        }, {
          name: "声明文字",
          value: "",
          select: true,
          type: "textarea"
        }];
      }
    },
    othersigndata: {
      type: [Object, Array],
      default: function _default() {
        return [{
          name: "公司名称",
          value: "",
          select: true,
          type: "diabled"
        }, {
          name: "联系人",
          value: "",
          select: true,
          type: "nomal"
        }, {
          name: "声明文字",
          value: "",
          select: true,
          type: "textarea"
        }];
      }
    }
  }, "inventoryConfig", {
    type: [Object, Array],
    default: function _default() {
      return null;
    }
  }), "owndata", {
    type: [Object, Array],
    default: function _default() {
      return [{
        name: "公司名称",
        value: "",
        select: true,
        type: "diabled"
      }, {
        name: "座机",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "传真",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "邮箱",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "网址",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "地址",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "联系人",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "手机号",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "开户名称",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "开户行",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "账号",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "国籍",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "证件类型",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "证件号码",
        value: "",
        select: true,
        type: "nomal"
      }];
    }
  }), "otherdata", {
    type: [Object, Array],
    default: function _default() {
      return [{
        name: "公司名称",
        value: "",
        select: true,
        type: "diabled"
      }, {
        name: "座机",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "传真",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "邮箱",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "网址",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "地址",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "联系人",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "手机号",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "开户名称",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "开户行",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "账号",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "国籍",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "证件类型",
        value: "",
        select: true,
        type: "nomal"
      }, {
        name: "证件号码",
        value: "",
        select: true,
        type: "nomal"
      }];
    }
  }),
  data: function data() {
    return {
      formatDate: _index.parseTime,
      nowdate: +new Date()
    };
  }
};