var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "直播间信息",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "box-container" }, [
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("直播间名称："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("主播微信号："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.anchor_wechat)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("直播间ID："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.broadcast_room_id)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("主播昵称："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.anchor_name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("手机号："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.phone)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("审核结果："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("liveReviewStatusFilter")(
                              _vm.FormData.status
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("直播开始时间："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.start_time)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("直播结束时间："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.FormData.end_time)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("直播间类型："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(
                          _vm._s(_vm._f("broadcastType")(_vm.FormData.type))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("显示类型："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("broadcastDisplayType")(
                              _vm.FormData.screen_type
                            )
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp image" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("背景图："),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticStyle: { "max-width": "150px", height: "80px" },
                        attrs: { src: _vm.FormData.cover_img },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp image" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("分享图："),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticStyle: { "max-width": "150px", height: "80px" },
                        attrs: { src: _vm.FormData.share_img },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("是否开启点赞："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info blue" }, [
                        _vm._v(
                          _vm._s(_vm._f("filterClose")(_vm.FormData.close_like))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("是否开启货架："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info blue" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("filterClose")(_vm.FormData.close_goods)
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("是否开启评论："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info blue" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("filterClose")(_vm.FormData.close_comment)
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("是否开启直播回放："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info blue" }, [
                        _vm._v(_vm._s(_vm.FormData.replay_status ? "✔" : "✖")),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("是否开启分享："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info blue" }, [
                        _vm._v(_vm._s(_vm.FormData.close_share ? "✖" : "✔")),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp" }, [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("是否开启客服："),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "info blue" }, [
                        _vm._v(_vm._s(_vm.FormData.close_kf ? "✖" : "✔")),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.FormData.status === 2
                      ? _c(
                          "div",
                          { staticClass: "list sp100" },
                          [
                            _c("label", { staticClass: "name" }, [
                              _vm._v("已导入直播商品："),
                            ]),
                            _vm._v(" "),
                            _vm.type === "edit"
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleImport(_vm.FormData)
                                      },
                                    },
                                  },
                                  [_vm._v("导入直播商品")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("selected-goods", {
                              ref: "selectedGoods",
                              attrs: {
                                type: _vm.type,
                                broadcast_room_id:
                                  _vm.FormData.broadcast_room_id,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "list sp100" }, [
                      _c("label", { staticClass: "name" }, [_vm._v("备注：")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "info" },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 1 },
                            model: {
                              value: _vm.FormData.mark,
                              callback: function ($$v) {
                                _vm.$set(_vm.FormData, "mark", $$v)
                              },
                              expression: "FormData.mark",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "list sp100 mt20" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "el-button el-button--primary el-button--medium",
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "button",
                              disabled: _vm.FormData.mark == "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleRemarks()
                              },
                            },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("import-goods", { ref: "uploadGoods" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }