var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "商城管理" },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#3480F4 !important",
                      "margin-right": "20px",
                    },
                    attrs: { type: "text", icon: "el-icon-picture-outline" },
                    on: {
                      click: function ($event) {
                        return _vm.onGOUrl("/merchant/systemForm/picture")
                      },
                    },
                  },
                  [_vm._v("素材库")]
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown",
                  { attrs: { type: "primary", placement: "bottom" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-items-c",
                        staticStyle: { color: "#3480F4", cursor: "pointer" },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "16px",
                            height: "16px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/202464/image/1717490887632187719.png",
                            alt: "",
                          },
                        }),
                        _vm._v("\n          配置\n        "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/systemForm/systemAuction/order"
                                  )
                                },
                              },
                            },
                            [_vm._v("订单配置")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/config/freight/shippingTemplates"
                                  )
                                },
                              },
                            },
                            [_vm._v("运费模版")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/basic/goodsConfig/classify"
                                  )
                                },
                              },
                            },
                            [_vm._v("分类管理")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onGOUrl(
                                    "/merchant/basic/goodsConfig/attrList"
                                  )
                                },
                              },
                            },
                            [_vm._v("属性管理")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-radio-group",
            {
              staticStyle: { "margin-bottom": "20px" },
              on: {
                change: function ($event) {
                  return _vm.getList(1)
                },
              },
              model: {
                value: _vm.tableFrom.type,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "type", $$v)
                },
                expression: "tableFrom.type",
              },
            },
            _vm._l(_vm.headeNum, function (item, index) {
              return _c(
                "el-radio-button",
                { key: index, attrs: { label: item.type.toString() } },
                [_vm._v(_vm._s(item.name + "(" + item.count + ")"))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "120px",
                    inline: true,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品分类：" } },
                    [
                      _c("el-cascader", {
                        staticClass: "filter-item selWidth mr20",
                        attrs: {
                          options: _vm.merCateList,
                          props: _vm.props,
                          filterable: "",
                          clearable: "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        model: {
                          value: _vm.tableFrom.mer_cate_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "mer_cate_id", $$v)
                          },
                          expression: "tableFrom.mer_cate_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否为礼包：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请选择", clearable: "" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.tableFrom.is_gift_bag,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "is_gift_bag", $$v)
                            },
                            expression: "tableFrom.is_gift_bag",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "是", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "否", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字搜索：" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: { placeholder: "请输入商品名称，关键字" },
                          model: {
                            value: _vm.tableFrom.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keyword", $$v)
                            },
                            expression: "tableFrom.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: {
                              click: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              attrs: {
                to: { path: "" + _vm.roterPre + "/product/list/addProduct" },
              },
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("添加商品"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.downloadAction },
            },
            [_vm._v("下载商品导入模板")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.batchUploadAuction },
            },
            [_vm._v("批量导入")]
          ),
          _vm._v(" "),
          _vm.tableFrom.type != 5 && _vm.tableFrom.type != 2
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.batchStatusAuction(0)
                      },
                    },
                  },
                  [_vm._v("批量下架")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tableFrom.type == 2
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.batchStatusAuction(1)
                      },
                    },
                  },
                  [_vm._v("批量上架")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.batchDelete },
                  },
                  [_vm._v("批量加入回收站")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tableFrom.type == 5
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.batchDelete },
                  },
                  [_vm._v("批量删除")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "no-height",
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: { border: "", data: _vm.tableData.data, size: "mini" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand demo-table-expand1",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商品分类：" } },
                              [
                                props.row.storeCategory
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          props.row.storeCategory.cate_name
                                        )
                                      ),
                                    ]
                                  : _c("span", [_vm._v("-")]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "品牌：" } }, [
                              _c("span", { staticClass: "mr10" }, [
                                _vm._v(
                                  _vm._s(
                                    props.row.brand
                                      ? props.row.brand.brand_name
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "市场价格：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.ot_price)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "成本价：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.cost)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "收藏：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.care_count)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            props.row.attr_info &&
                            props.row.attr_info.length > 0
                              ? _vm._l(props.row.attr_info, function (m, i) {
                                  return _c(
                                    "el-form-item",
                                    { key: i, attrs: { label: m.attr_name } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("filterEmpty")(m.attr_value)
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.tableFrom.type === "7"
                              ? _c(
                                  "el-form-item",
                                  {
                                    key: "1",
                                    attrs: { label: "未通过原因：" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(props.row.refusal)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "product_id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品图", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "商品名称",
                  "min-width": "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "商品售价", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sales", label: "销量", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "库存", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "排序", "min-width": "100", prop: "sort" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          staticClass: "w80",
                          on: {
                            change: function (val) {
                              return _vm.editSortAction(
                                val,
                                scope.row.product_id
                              )
                            },
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "促销" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.buy_limit_num) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              Number(_vm.tableFrom.type) < 5
                ? _c("el-table-column", {
                    key: "1",
                    attrs: {
                      prop: "status",
                      label: "状态",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "上架",
                                  "inactive-text": "下架",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onchangeIsShow(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.mer_status,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "mer_status", $$v)
                                  },
                                  expression: "scope.row.mer_status",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2045393787
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "shelf_time",
                  label: "自动上架时间",
                  "min-width": "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "off_shelf_time",
                  label: "自动下架时间",
                  "min-width": "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "left",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyXcxLink(
                                            scope.row,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序链接\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyH5Link(
                                            scope.row,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  H5链接\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(1, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序码\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(2, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  二维码\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(3, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  小程序海报\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showImgAction(4, scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  二维码海报\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 m0 plr2",
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v("\n              推广链接\n            ")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.type != 5
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    path:
                                      _vm.roterPre +
                                      "/product/list/addProduct/" +
                                      scope.row.product_id,
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "text", size: "small" } },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showSkuAction(scope.row.sku)
                              },
                            },
                          },
                          [_vm._v("查看规格")]
                        ),
                        _vm._v(" "),
                        _vm.tableFrom.type === "5"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRestore(
                                      scope.row.product_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("恢复商品")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type !== "1" &&
                        _vm.tableFrom.type !== "3" &&
                        _vm.tableFrom.type !== "4"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.product_id,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.tableFrom.type === "5"
                                      ? "删除"
                                      : "加入回收站"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: { title: "查看规格", visible: _vm.showSku, size: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.showSku = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "sku-list" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.skuList, size: "mini" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "sku",
                      label: "规格名称",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(scope.row.sku ? scope.row.sku : "默认")
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "price", label: "价格", "min-width": "80" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ot_price",
                      label: "原价",
                      "min-width": "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stock",
                      label: "剩余库存",
                      "min-width": "80",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImg,
            "before-close": _vm.beforeCloseImg,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showBatchUpload
        ? _c("batchUploadTemplate", {
            ref: "batchTemplate",
            attrs: { source: "product_goods" },
            on: { uploadOk: _vm.load, uploadClose: _vm.closeBatchAction },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }