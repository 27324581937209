"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20,
        make_title: '',
        make_type: ''
      },
      videoLogList: [],
      total: 0,
      videoUrl: '',
      visible: false
    };
  },
  filters: {
    formatVideoTime: function formatVideoTime(timeInSeconds) {
      if (!timeInSeconds) return '';
      // 将秒数转换成mm:ss格式
      var minutes = Math.floor(timeInSeconds / 60); // 计算分钟
      var seconds = Math.floor(timeInSeconds % 60); // 计算剩余秒数

      if (minutes == 0) return "".concat(seconds, "\u79D2");else return "".concat(minutes, "\u5206").concat(seconds, "\u79D2");
    },
    bytesToMB: function bytesToMB(bytes) {
      var s = bytes / 1024 / 1024;
      return s.toFixed(2);
      // const s = bytes / 1024;
      // if (s<1024) return 
    }
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    onClickView: function onClickView(url) {
      this.visible = true;
      this.videoUrl = url;
    },
    onDownload: function onDownload(row) {
      fetch(row.extend_data.video_info.url).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        var name;
        if (row.make_type == 1) name = "".concat(row.make_title).concat(row.makeTagText);
        if (row.make_type == 2) name = "".concat(row.make_title).concat(row.makeTagText);
        if (row.make_type == 3 || row.make_type == 4) name = "".concat(row.makeTypeText).concat(row.makeTagText, "-").concat(row.make_title);
        var a = document.createElement("a");
        var objectUrl = window.URL.createObjectURL(blob);
        a.download = "".concat(name, ".mp4");
        a.href = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      });
    },
    changeTab: function changeTab(val) {
      this.tableFrom.make_type = val;
      this.getList(1);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    getList: function getList(num) {
      var _this = this;
      if (num) this.tableFrom.page = num;
      (0, _aiMark.videoListApi)(this.tableFrom).then(function (res) {
        console.log(res);
        _this.videoLogList = res.data.list;
        _this.total = res.data.count;
      });
    }
  }
};