var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container help-detail",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "padding20 bg-white borderRadius6",
          staticStyle: { position: "relative" },
        },
        [
          _c("img", {
            staticClass: "shuoming-icon",
            attrs: {
              src: "https://saas.cdn.yunzongbu.cn/image/20220817/7a08efa4c22c8a89dffee79143ed22bf.png",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "remove-control form-view " },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm ",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "110px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动海报：", prop: "cover_image" } },
                    [
                      _vm.ruleForm.cover_image
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "upload-image flex align-items-c justify-c",
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg("", "cover_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.ruleForm.cover_image + "!120a",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "upload-image flex align-items-c justify-c",
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg("", "cover_image")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-picture" })]
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：", prop: "title" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入活动名称" },
                            model: {
                              value: _vm.ruleForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "title", $$v)
                              },
                              expression: "ruleForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间：", prop: "date_time" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "value-format": "timestamp",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.ruleForm.date_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "date_time", $$v)
                              },
                              expression: "ruleForm.date_time",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "主视觉图：" } }, [
                    _vm.ruleForm.background_image
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg("", "background_image")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.ruleForm.background_image + "!120a",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg("background_image")
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg("", "background_image")
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "页面背景色：" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                          model: {
                            value: _vm.ruleForm.background_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "background_color", $$v)
                            },
                            expression: "ruleForm.background_color",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "版块背景图：" } }, [
                    _vm.ruleForm.content_style.background_image
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg(
                                  "",
                                  "background_image",
                                  "content_style"
                                )
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.ruleForm.content_style.background_image +
                                  "!120a",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg(
                                      "background_image",
                                      "content_style"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg(
                                  "",
                                  "background_image",
                                  "content_style"
                                )
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版块背景色：" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                        model: {
                          value: _vm.ruleForm.content_style.background_color,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm.content_style,
                              "background_color",
                              $$v
                            )
                          },
                          expression: "ruleForm.content_style.background_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "海报图标：" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _vm.ruleForm.content_style.share_image
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "upload-image flex align-items-c justify-c",
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg(
                                    "",
                                    "share_image",
                                    "content_style"
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    _vm.ruleForm.content_style.share_image +
                                    "!120a",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg(
                                        "share_image",
                                        "content_style"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "upload-image flex align-items-c justify-c",
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg(
                                    "",
                                    "share_image",
                                    "content_style"
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-picture" })]
                          ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml10 color-gray" }, [
                        _vm._v("图片建议大小：100×100"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "版块距离：" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("外边距：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.content_style.viewMargin,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.content_style,
                                  "viewMargin",
                                  $$v
                                )
                              },
                              expression: "ruleForm.content_style.viewMargin",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [
                        _vm._v("内边距："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.content_style.viewPadding,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.content_style,
                                  "viewPadding",
                                  $$v
                                )
                              },
                              expression: "ruleForm.content_style.viewPadding",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [_vm._v("圆角：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.content_style.viewRadius,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.content_style,
                                  "viewRadius",
                                  $$v
                                )
                              },
                              expression: "ruleForm.content_style.viewRadius",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "标题设置：" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("标题颜色：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "34px" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                            model: {
                              value: _vm.ruleForm.content_style.title_color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.content_style,
                                  "title_color",
                                  $$v
                                )
                              },
                              expression: "ruleForm.content_style.title_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [
                        _vm._v("标题字号："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.content_style.title_font_size,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.content_style,
                                  "title_font_size",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.content_style.title_font_size",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "内容设置：" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("内容颜色：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "34px" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                            model: {
                              value: _vm.ruleForm.content_style.text_color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.content_style,
                                  "text_color",
                                  $$v
                                )
                              },
                              expression: "ruleForm.content_style.text_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [
                        _vm._v("内容字号："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 375 },
                            model: {
                              value: _vm.ruleForm.content_style.text_font_size,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.content_style,
                                  "text_font_size",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.content_style.text_font_size",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "助力规则：" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("div", { staticClass: "l" }, [
                          _vm._v("只有新用户可助力："),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#eee",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: _vm.ruleForm.help_rule_data.is_new_user,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.help_rule_data,
                                "is_new_user",
                                $$v
                              )
                            },
                            expression: "ruleForm.help_rule_data.is_new_user",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "l ml20" }, [
                          _vm._v("助力需要实名认证："),
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#eee",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: _vm.ruleForm.help_rule_data.reserved_number,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.help_rule_data,
                                "reserved_number",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.help_rule_data.reserved_number",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "助力成功提示：" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: {
                            maxlength: "30",
                            placeholder: "可不填，最多30个字",
                          },
                          model: {
                            value:
                              _vm.ruleForm.help_rule_data.help_success_Tips,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm.help_rule_data,
                                "help_success_Tips",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.help_rule_data.help_success_Tips",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10" }, [
                          _vm._v("不填默认提示”助力成功“"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "奖品设置：" } }, [
                    _c(
                      "div",
                      { staticClass: "tab" },
                      [
                        _c("div", { staticClass: "tr flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("奖品图片")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("奖品类型")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "35%" },
                            },
                            [_vm._v("选择奖品")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "25%" },
                            },
                            [_vm._v("领奖条件")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("发放总数")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("操作")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.prize_data, function (m, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "tr flex align-items-c" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "td item-flex-center",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  m.prize_image
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-image flex align-items-c justify-c",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeImg(i)
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: m.prize_image + "!120a",
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-image flex align-items-c justify-c",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeImg(i)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-picture",
                                          }),
                                        ]
                                      ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.editTypeAction(i)
                                        },
                                      },
                                      model: {
                                        value: m.prize_type,
                                        callback: function ($$v) {
                                          _vm.$set(m, "prize_type", $$v)
                                        },
                                        expression: "m.prize_type",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "product",
                                          label: "商品",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "product_nft",
                                          label: "数字藏品",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "product_nft_blind",
                                          label: "数字盲盒",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "35%" },
                                },
                                [
                                  m.prize_relation_name || m.prize_name
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: " flex align-items-c",
                                          staticStyle: {
                                            height: "36px",
                                            overflow: "hidden",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "line2 flex-one",
                                              staticStyle: {
                                                "line-height": "18px",
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  m.prize_relation_name ||
                                                    m.prize_name
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleAdditem(
                                                m.prize_type,
                                                i
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择")]
                                      ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "25%" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: { width: "120px" },
                                      attrs: { controls: false },
                                      model: {
                                        value: m.help_count,
                                        callback: function ($$v) {
                                          _vm.$set(m, "help_count", $$v)
                                        },
                                        expression: "m.help_count",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("人助力"),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: { width: "120px" },
                                      attrs: { controls: false },
                                      model: {
                                        value: m.buy_num,
                                        callback: function ($$v) {
                                          _vm.$set(m, "buy_num", $$v)
                                        },
                                        expression: "m.buy_num",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("件购买"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: { controls: false },
                                    model: {
                                      value: m.prize_num,
                                      callback: function ($$v) {
                                        _vm.$set(m, "prize_num", $$v)
                                      },
                                      expression: "m.prize_num",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delPrizeAction(i)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tr flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.addPrizeAction },
                                },
                                [_vm._v("新增奖品")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "购买任务指定：" } }, [
                    _c(
                      "div",
                      { staticClass: "tab" },
                      [
                        _c("div", { staticClass: "tr flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("类型")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "10%" },
                            },
                            [_vm._v("图片")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: {
                                width: "60%",
                                "text-align": "left",
                              },
                            },
                            [_vm._v("名称")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "th",
                              staticStyle: { width: "20%" },
                            },
                            [_vm._v("操作")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.buy_product_data, function (m, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "tr flex align-items-c" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.editBuyTypeAction(i)
                                        },
                                      },
                                      model: {
                                        value: m.type,
                                        callback: function ($$v) {
                                          _vm.$set(m, "type", $$v)
                                        },
                                        expression: "m.type",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "product",
                                          label: "商品",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "product_nft",
                                          label: "数字藏品",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          value: "product_nft_blind",
                                          label: "数字盲盒",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td item-flex-center",
                                  staticStyle: { width: "10%" },
                                },
                                [
                                  m.image
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-image flex align-items-c justify-c",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeImg(i)
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: m.image + "!120a",
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-image flex align-items-c justify-c",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-picture",
                                          }),
                                        ]
                                      ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: {
                                    width: "60%",
                                    "text-align": "left",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(m.name || "") +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "20%" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleBuyAdditem(m.type, i)
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.delBuyAction(i)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tr flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.addBuyAction },
                                },
                                [_vm._v("新增指定购买")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("Tinymce", {
            ref: "editor",
            attrs: { height: 400 },
            model: {
              value: _vm.ruleForm.rule_message,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "rule_message", $$v)
              },
              expression: "ruleForm.rule_message",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "common-btns",
          class: { left0: _vm.device === "mobile" },
          style: { left: _vm.sidebar.opened ? "230px" : "74px" },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAction("ruleForm")
                },
              },
            },
            [_vm._v("确认提交")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectProduct", {
        ref: "product",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Productblind", {
        ref: "blind_box",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }