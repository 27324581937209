"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var accountsRouter = {
  path: "".concat(_settings.roterPre, "/accounts"),
  name: 'accounts',
  meta: {
    icon: '',
    title: '财务管理'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'wallet',
    name: 'AccountsWallet',
    redirect: "".concat(_settings.roterPre, "/accounts/wallet/finance"),
    meta: {
      title: '财务',
      noCache: true,
      permissionKey: '/accounts/wallet'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/allWallet'));
      });
    },
    children: [{
      path: 'finance',
      name: 'Accountsfinance',
      meta: {
        title: '钱包',
        noCache: true,
        permissionKey: '/accounts/wallet'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/wallet/index'));
        });
      }
    }, {
      path: 'clientWallet',
      name: 'AccountsClientWallet',
      meta: {
        title: '委托方钱包',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/wallet/finance"),
        permissionKey: '/accounts/clientWallet'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/clientWallet/index'));
        });
      }
    }, {
      path: 'receipt',
      name: 'AccountsReceipt',
      meta: {
        title: '收款单',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/wallet/finance"),
        permissionKey: '/accounts/receipt'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/receipt/index'));
        });
      }
    }, {
      path: 'payment',
      name: 'AccountsPayment',
      meta: {
        title: '付款单',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/wallet/finance"),
        permissionKey: '/accounts/payment'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/payment/index'));
        });
      }
    }, {
      path: 'invoice',
      name: 'OrderInvoice ',
      meta: {
        title: '发票管理',
        activeMenu: "".concat(_settings.roterPre, "/accounts/wallet/finance"),
        permissionKey: '/order/invoice'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/order/orderInvoice/index'));
        });
      }
    }, {
      path: 'reconciliation',
      name: 'AccountsReconciliation',
      meta: {
        title: '财务对账',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/wallet/finance"),
        permissionKey: '/accounts/reconciliation'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/reconciliation/reconciliation'));
        });
      }
    }]
  }, {
    path: 'sxyWallet',
    name: 'AccountsSxyWallet',
    meta: {
      title: '用户提现',
      noCache: true,
      permissionKey: '/accounts/sxywallet'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/sxyWallet/index'));
      });
    }
  }, {
    path: 'payconfig',
    name: 'AccountsPayconfig',
    meta: {
      title: '支付配置',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/payment/payconfig'));
      });
    }
  }, {
    path: 're',
    name: 'AccountsRe',
    meta: {
      title: '财务对账',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/reconciliation/index'));
      });
    }
  }, {
    path: 'reconciliation/order/:id',
    name: 'ReconciliationOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/reconciliation/record'));
      });
    },
    meta: {
      title: '查看订单',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/accounts/reconciliation")
    },
    hidden: true
  }, {
    path: 'capitalFlow',
    name: 'AccountsCapitalFlow',
    meta: {
      title: '资金流水',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/capitalFlow/index'));
      });
    }
  }, {
    path: 'buyNetwork',
    name: 'AccountsBuyNetwork',
    meta: {
      title: '流量购买',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNetwork/index'));
      });
    },
    children: [{
      path: 'show',
      name: 'AccountsBuyNetwork_show',
      meta: {
        title: '流量使用',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/buyNetwork/recharge")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNetwork/show'));
        });
      }
    }, {
      path: 'recharge',
      name: 'AccountsBuyNetwork_recharge',
      meta: {
        title: '流量购买',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNetwork/recharge'));
        });
      }
    }]
  }, {
    path: 'buyNft',
    name: 'AccountsbuyNft',
    meta: {
      title: '上链充值',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNft/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/accounts/buyNft/account"),
    children: [{
      path: 'account',
      name: 'AccountsbuyNft_account',
      meta: {
        title: '我的账户',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNft/account'));
        });
      }
    }, {
      path: 'recharge',
      name: 'AccountsbuyNft_recharge',
      meta: {
        title: '充值',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/buyNft/account")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyNft/recharge'));
        });
      }
    }]
  }, {
    path: 'verify',
    name: 'AccountVerify',
    meta: {
      title: '上链充值',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/verify/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/accounts/verify/account"),
    children: [{
      path: 'account',
      name: 'AccountsVerifyaccount',
      meta: {
        title: '我的账户',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/verify/account'));
        });
      }
    }, {
      path: 'recharge',
      name: 'AccountsVerify_recharge',
      meta: {
        title: '充值',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/verify/account")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/verify/recharge'));
        });
      }
    }]
  }, {
    path: 'buyShortMessage',
    name: 'AccountsbuyShortMessage',
    meta: {
      title: '短信购买',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyShortMessage/index'));
      });
    },
    children: [{
      path: 'show',
      name: 'AccountsbuyShortMessage_show',
      meta: {
        title: '短信使用',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/buyShortMessage/recharge")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyShortMessage/show'));
        });
      }
    }, {
      path: 'recharge',
      name: 'AccountsbuyShortMessage_recharge',
      meta: {
        title: '短信购买',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyShortMessage/recharge'));
        });
      }
    }, {
      path: 'seting',
      name: 'AccountsbuyShortMessage_seting',
      meta: {
        title: '发送配置',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/buyShortMessage/seting'));
        });
      }
    }]
  }, {
    path: 'llAccount',
    name: 'AccountsLlAccount',
    meta: {
      title: '连连账户',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/index'));
      });
    },
    children: [{
      path: 'llopen',
      name: 'AccountsLlAccount_show',
      meta: {
        title: '连连账户',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/llAccount/llopen"),
        permissionKey: '/accounts/llAccount/llopen'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/llpay.vue'));
        });
      }
    }, {
      path: 'llAccountInfo',
      name: 'AccountsLlAccount_recharge',
      meta: {
        title: '连连账户',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/llAccount/llopen")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/llAccountInfo.vue'));
        });
      }
    }, {
      path: 'llUserwithdrawList',
      name: 'AccountsLlAccount_withdrawList',
      meta: {
        title: '连连账户',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/llAccount/llopen")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/llUserwithdrawList.vue'));
        });
      }
    }, {
      path: 'transferMoney',
      name: 'AccountsLlAccount_transferMoney',
      meta: {
        title: '连连账户',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/llAccount/llopen")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/transferMoney.vue'));
        });
      }
    }, {
      path: 'hfTransferMoney',
      name: 'AccountsHfAccount_transferMoney',
      meta: {
        title: '汇付账户转账',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/accounts/llAccount/llopen")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/hfTransferMoney.vue'));
        });
      }
    }]
  }, {
    path: 'llAccount',
    name: 'AccountsllAccount',
    meta: {
      title: '连连账户',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/llpay.vue'));
      });
    }
  }, {
    path: 'llAccount/resetpassword',
    name: 'AccountsllAccountResetpassword',
    meta: {
      title: '连连账户',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/resetllpay.vue'));
      });
    }
  }, {
    path: 'llAccount/llchangebind',
    name: 'AccountsllAccountChangebind',
    meta: {
      title: '连连账户',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/llpay/llChangebind.vue'));
      });
    }
  }, {
    path: 'yeepay',
    name: 'AccountsYeepay',
    meta: {
      title: '易宝账户',
      noCache: true,
      permissionKey: '/accounts/yeepay'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/yeepay/yeepay.vue'));
      });
    }
  }, {
    path: 'sandpay',
    name: 'sandpay',
    meta: {
      title: '杉德转账',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/accounts/sandpay/sandpay'));
      });
    }
  }]
};
var _default = exports.default = accountsRouter;