"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _application = require("@/api/manageLayer/application");
var _digitalHall = require("@/api/digitalHall");
var _elementUi = require("element-ui");
var _toolbox = _interopRequireDefault(require("./modules/toolbox.vue"));
var _exhibition = _interopRequireDefault(require("./modules/exhibition.vue"));
var _editDetail = _interopRequireDefault(require("./modules/editDetail.vue"));
var _guidance = _interopRequireDefault(require("./modules/guidance/guidance.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import open3d from "@/utils/open3d.min.js";
var _default = exports.default = {
  components: {
    toolbox: _toolbox.default,
    exhibition: _exhibition.default,
    editDetail: _editDetail.default,
    guidance: _guidance.default
  },
  data: function data() {
    return {
      token_local: "",
      appId: "0ad97704f6141f5c",
      sid: this.$route.query.sid,
      id: this.$route.query.id,
      newopen3d: null,
      showSetStartPoint: false,
      showExhibition: false,
      showEditDetail: false,
      modifyDetail: {},
      exhibitionType: "",
      pointData: [],
      showGuideWindow: false,
      tourIntroduction: '',
      defaultAudio: {},
      guided_digital_man_config: {}
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (this.newopen3d) this.newopen3d.destroyed();
  },
  created: function created() {
    var _this = this;
    // this.loadOpen3D({});
    (0, _digitalHall.getSdkTokenApi)().then(function (res) {
      _this.token_local = res.data.token;
      _this.appId = res.data.appid;
      (0, _digitalHall.userExhibitionDetailApi)({
        space_id: _this.id
      }).then(function (response) {
        var item = response.data;
        (0, _digitalHall.userExhibitionProductListApi)({
          space_id: _this.id
        }).then(function (resP) {
          if (response.data.extends && response.data.extends.tourIntroduction && response.data.extends.tourAudioFrequency) {
            _this.tourIntroduction = {
              tourIntroduction: response.data.extends.tourIntroduction,
              tourAudioFrequency: response.data.extends.tourAudioFrequency,
              speechDuration: response.data.extends.speechDuration,
              explain_duration: response.data.extends.speechDuration
            };
          }
          if (response.data.extends && response.data.extends.defaultAudio) {
            _this.defaultAudio = JSON.parse(response.data.extends.defaultAudio);
          }
          item.extends.products = JSON.stringify(resP.data.products.map(function (m) {
            return {
              id: m.productItem ? m.productItem.id : '',
              type: m.type,
              name: m.productItem ? m.productItem.name : '',
              url: m.productItem ? (m.productItem.file_url || m.productItem.image) + (m.type == 1 ? '!m640' : '') : '',
              coverImage: m.productItem ? m.productItem.image + '!m640' : '',
              exhibitionType: "exhibition",
              active: false,
              extends: m.extends
            };
          }));
          item.extends.materials = JSON.stringify(resP.data.materials.map(function (m) {
            return {
              id: m.materialItem.attachment_id,
              type: m.type,
              name: m.materialItem.attachment_name,
              url: m.materialItem.attachment_src + (m.type == 1 ? '!m640' : ''),
              coverImage: m.materialItem.attachment_src + (m.type == 1 ? '!m640' : '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'),
              exhibitionType: "material",
              active: false,
              extends: m.extends
            };
          }));
          _this.loadOpen3D((0, _objectSpread2.default)((0, _objectSpread2.default)({}, item.extends), {}, {
            id: item.id,
            space_model_id: item.space_model_id,
            out_space_id: item.out_space_id
          }));
          _this.pointData = item.extends && item.extends.pointList ? JSON.parse(item.extends.pointList) : [];
          _this.guided_digital_man_config = item.guided_digital_man_config || {};
        });
      });
      // userExhibitionDetailApi(this.id).then((res) => {
      //   const data = res.data;
      //   this.loadOpen3D(data);
      // });
    });
  },
  methods: {
    loadOpen3D: function loadOpen3D(spaceData) {
      var _this2 = this;
      var env = process.env.NODE_ENV;
      this.newopen3d = new open3D({
        continerId: "container",
        sdkToken: this.token_local,
        appId: this.appId,
        spaceId: spaceData.out_space_id,
        ownId: spaceData.id,
        chainId: spaceData.chainId || "",
        selectPeople: spaceData.peoples ? JSON.parse(spaceData.peoples) : [],
        selectWorks: spaceData.products ? JSON.parse(spaceData.products) : [],
        openType: this.$route.query.type ? "h5" : "",
        worksData: [],
        worksTag: [],
        startPointJson: spaceData.startPointJson ? JSON.parse(spaceData.startPointJson) : null,
        workConfig: spaceData.workConfig ? JSON.parse(spaceData.workConfig) : [],
        selectMaterial: spaceData.materials ? JSON.parse(spaceData.materials) : [],
        vposition: spaceData.vposition ? JSON.parse(spaceData.vposition) : [],
        materialData: [],
        materialTag: [],
        selectProps: spaceData.props ? JSON.parse(spaceData.props) : [],
        env: env !== 'production' ? 'develop' : '',
        callback: function callback(type, data) {
          console.log(type, data);
          if (type == "initScene") {
            // 场景加载完毕
          } else if (type == 'saveLoading') {
            _this2.$message({
              message: '保存中',
              type: 'warning',
              duration: 0
            });
          } else if (type == "save") {
            _this2.$message.closeAll();
            // 保存
            (0, _digitalHall.userExhibitionEdit)(spaceData.id, {
              extends: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data.conf), {}, {
                materials: JSON.stringify(data.materials),
                peoples: JSON.stringify(data.peoples),
                products: JSON.stringify(data.products),
                props: JSON.stringify(data.props),
                pointList: JSON.stringify(_this2.pointData),
                tourIntroduction: _this2.tourIntroduction && _this2.tourIntroduction.tourIntroduction ? _this2.tourIntroduction.tourIntroduction : '',
                tourAudioFrequency: _this2.tourIntroduction && _this2.tourIntroduction.tourAudioFrequency ? _this2.tourIntroduction.tourAudioFrequency : '',
                speechDuration: _this2.tourIntroduction && _this2.tourIntroduction.speechDuration ? _this2.tourIntroduction.speechDuration : '',
                defaultAudio: _this2.defaultAudio ? JSON.stringify(_this2.defaultAudio) : ''
              })
            }).then(function () {
              _elementUi.Message.success("保存成功");
            }).catch(function () {});
          } else if (type == "preview") {
            // 预览
            (0, _application.postDecorationSpace)((0, _objectSpread2.default)({
              spaceId: _this2.id,
              thirdPartySpaceId: _this2.sid
            }, data)).then(function () {
              _this2.$router.push({
                name: "SpaceReview",
                query: {
                  id: _this2.id,
                  sid: _this2.sid,
                  type: "h5"
                }
              });
            }).catch(function () {});
          } else if (type == "close") {
            // 退出
            _this2.$confirm("\u8BF7\u786E\u8BA4\u60A8\u7684\u6240\u6709\u64CD\u4F5C\u90FD\u5DF2\u4FDD\u5B58", "操作提示", {
              confirmButtonText: "继续关闭",
              cancelButtonText: "取消",
              type: "warning"
            }).then(function () {
              if (_this2.newopen3d) _this2.newopen3d.destroyed();
              // this.$router.back();
              window.close();
            }).catch(function () {});
          } else if (type == "showGuideWindow") {
            _this2.showEditDetail = false;
            _this2.showExhibition = false;
            _this2.showSetStartPoint = false;
            _this2.showGuideWindow = true;
          } else if (type == "showToolboxWindow") {
            _this2.showGuideWindow = false;
            _this2.showEditDetail = false;
            _this2.showExhibition = false;
            _this2.showSetStartPoint = true;
          } else if (type == "showExhibitionWindow") {
            _this2.showGuideWindow = false;
            _this2.showEditDetail = false;
            _this2.showSetStartPoint = false;
            _this2.exhibitionType = "exhibition";
            _this2.showExhibition = true;
          } else if (type == "showDecorationWindow") {
            _this2.showGuideWindow = false;
            _this2.showEditDetail = false;
            _this2.showSetStartPoint = false;
            _this2.exhibitionType = "material_img";
            _this2.showExhibition = true;
          } else if (type == "showEditExhibitionFlag" || type == "editTextFlag") {
            _this2.showSetStartPoint = false;
            _this2.showGuideWindow = false;
            _this2.showExhibition = false;
            if (type == "editTextFlag") {
              _this2.modifyDetail = {
                editType: "textInfo"
              };
            } else {
              _this2.modifyDetail = {};
            }
            _this2.showEditDetail = data;
          } else if (type == "exhibitionData" || type == "textInfo") {
            setTimeout(function () {
              data.editType = type;
              _this2.modifyDetail = data;
            }, type == "textInfo" ? 100 : 0);
          }
        }
      });
    },
    editDetaultAudioInfo: function editDetaultAudioInfo(data) {
      this.defaultAudio = data;
    },
    editPeopleAction: function editPeopleAction(data) {
      this.guided_digital_man_config = data;
    },
    closeAction: function closeAction() {
      // 关闭弹窗
      this.showSetStartPoint = false;
      this.showEditDetail = false;
      this.showGuideWindow = false;
      this.showExhibition = false;
      if (this.newopen3d) {
        this.newopen3d.cancelCurrentSelectedHeader();
        this.newopen3d.cancelSelectModel();
      }
    },
    savePointAction: function savePointAction(e) {
      this.pointData = JSON.parse(JSON.stringify(e));
    },
    editTourIntroductionInfoAction: function editTourIntroductionInfoAction(data) {
      this.tourIntroduction = JSON.parse(JSON.stringify(data));
    }
  }
};