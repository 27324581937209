"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "user-info-template",
  data: function data() {
    var _this = this;
    return {
      showImg: false,
      img: [],
      showVideo: false,
      video: "",
      answerColumns: [{
        title: "文字",
        minWidth: 140,
        render: function render(h, params) {
          var renderHtml = [];
          if (params.row.answer_text) {
            renderHtml = h("div", params.row.answer_text);
          }
          return renderHtml;
        }
      }, {
        title: "图片",
        minWidth: 140,
        render: function render(h, params) {
          var renderHtml = [];
          if (params.row.answer_img_list && params.row.answer_img_list.length > 0) {
            renderHtml = h("div", [h("Button", {
              props: {
                size: "small"
              },
              style: {
                margin: "0 5px"
              },
              on: {
                click: function click() {
                  _this.img = params.row.answer_img_list;
                  _this.showImg = true;
                }
              }
            }, "点击查看图片")]);
          }
          return renderHtml;
        }
      }, {
        title: "视频",
        minWidth: 140,
        render: function render(h, params) {
          var renderHtml = [];
          if (params.row.answer_video) {
            renderHtml = h("div", [h("Button", {
              props: {
                size: "small"
              },
              style: {
                margin: "0 5px"
              },
              on: {
                click: function click() {
                  _this.video = params.row.answer_video;
                  _this.showVideo = true;
                }
              }
            }, "点击查看视频")]);
          }
          return renderHtml;
        }
      }, {
        title: "语音",
        width: 220,
        render: function render(h, params) {
          var renderHtml = [];
          if (params.row.answer_radio) {
            renderHtml = h("div", [h("div", {
              class: "mt10"
            }), h("audio", {
              attrs: {
                src: params.row.answer_radio,
                controls: true
              },
              class: "w200"
            }), h("div", {
              class: "mt10"
            })]);
          }
          return renderHtml;
        }
      }, {
        title: "回答时间",
        minWidth: 120,
        key: "human_create_time"
      }],
      answerList: []
    };
  },
  props: {
    row: Object
  },
  created: function created() {
    if (this.row.supply_answer && this.row.supply_answer.length > 0) {
      this.answerList = this.row.supply_answer;
    } else {
      this.answerList = [];
    }
  },
  methods: {}
};