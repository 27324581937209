var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
            },
          ],
          ref: "formValidate",
          staticClass: "formValidate mt20",
          attrs: {
            rules: _vm.ruleValidate,
            model: _vm.formValidate,
            "label-width": "160px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticStyle: { "margin-left": "160px" } },
                  [
                    _vm._v("审核状态：\n              "),
                    _vm.status == "ACTIVATE_PENDING"
                      ? _c("span", { staticClass: "status-text" }, [
                          _vm._v("已登记或开户失败"),
                        ])
                      : _vm.status == "ACTIVATE_PENDING_NEW"
                      ? _c("span", { staticClass: "status-text" }, [
                          _vm._v("待激活"),
                        ])
                      : _vm.status == "WAIT_COMMIT"
                      ? _c("span", { staticClass: "status-text" }, [
                          _vm._v("待提交"),
                        ])
                      : _vm.status == "NORMAL"
                      ? _c("span", { staticClass: "status-text" }, [
                          _vm._v("正常"),
                        ])
                      : _vm.status == "CANCEL"
                      ? _c("span", { staticClass: "status-text" }, [
                          _vm._v("销户"),
                        ])
                      : _vm.status == "PAUSE"
                      ? _c("span", { staticClass: "status-text" }, [
                          _vm._v("暂停"),
                        ])
                      : _vm.status == "REMITTANCE_VALID_PENDING"
                      ? _c("span", { staticClass: "status-text" }, [
                          _vm._v("审核通过，待打款验证"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.status == "NORMAL"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: { click: _vm.changebind },
                          },
                          [_vm._v("更换绑定")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 0,
                  expression: "currentTab === 0",
                },
              ],
              attrs: { gutter: 24 },
            },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "18px", "font-weight": "bold" },
                  },
                  [_vm._v("基本信息")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户类型", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.formValidate.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "type", $$v)
                            },
                            expression: "formValidate.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("企业对公账户"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "mobile" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: "请输入手机号",
                          maxlength: "11",
                          type: "number",
                          autocomplete: "off",
                          clearable: "",
                          disabled: _vm.statusdisabled,
                        },
                        model: {
                          value: _vm.formValidate.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "mobile", $$v)
                          },
                          expression: "formValidate.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "验证码", prop: "code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: "请输入验证码",
                          maxlength: "6",
                          type: "number",
                          autocomplete: "off",
                          clearable: "",
                          disabled: _vm.statusdisabled,
                        },
                        model: {
                          value: _vm.formValidate.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "code", $$v)
                          },
                          expression: "formValidate.code",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", disabled: _vm.disabled },
                          on: { click: _vm.getCode },
                        },
                        [_vm._v(_vm._s(_vm.text))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 0,
                  expression: "currentTab === 0",
                },
              ],
              attrs: { gutter: 24 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm.formValidate.type == 1
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "真实姓名",
                              prop: "basicInfo.real_name",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入真实姓名",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value: _vm.formValidate.basicInfo.real_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.basicInfo,
                                    "real_name",
                                    $$v
                                  )
                                },
                                expression: "formValidate.basicInfo.real_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "身份证号",
                              prop: "basicInfo.idcard",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入身份证号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value: _vm.formValidate.basicInfo.idcard,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.basicInfo,
                                    "idcard",
                                    $$v
                                  )
                                },
                                expression: "formValidate.basicInfo.idcard",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "身份证有效期限" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.statusdisabled },
                                model: {
                                  value: _vm.formValidate.cardtype,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "cardtype", $$v)
                                  },
                                  expression: "formValidate.cardtype",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("短期有效"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("长期有效"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formValidate.cardtype == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "身份证过期时间",
                                  prop: "basicInfo.expiration_date",
                                  required: "",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择过期日期",
                                    "value-format": "yyyyMMdd",
                                    format: "yyyy-MM-dd",
                                    editable: false,
                                    clearable: false,
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.basicInfo
                                        .expiration_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.basicInfo,
                                        "expiration_date",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.basicInfo.expiration_date",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm.formValidate.type == 2
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "企业名称",
                              prop: "basicInfo.real_name",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入企业名称",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value: _vm.formValidate.basicInfo.real_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.basicInfo,
                                    "real_name",
                                    $$v
                                  )
                                },
                                expression: "formValidate.basicInfo.real_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "营业执照号",
                              prop: "basicInfo.org_code",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入营业执照号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value: _vm.formValidate.basicInfo.org_code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.basicInfo,
                                    "org_code",
                                    $$v
                                  )
                                },
                                expression: "formValidate.basicInfo.org_code",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "营业执照有效期限" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.statusdisabled },
                                model: {
                                  value: _vm.formValidate.exptype,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "exptype", $$v)
                                  },
                                  expression: "formValidate.exptype",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("短期有效"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("长期有效"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formValidate.exptype == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "营业执照过期时间",
                                  prop: "basicInfo.org_exp",
                                  required: "",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择过期日期",
                                    "value-format": "yyyyMMdd",
                                    format: "yyyy-MM-dd",
                                    editable: false,
                                    clearable: false,
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value: _vm.formValidate.basicInfo.org_exp,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.basicInfo,
                                        "org_exp",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.basicInfo.org_exp",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "营业执照图片",
                              prop: "basicInfo.business_licene_photo",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox",
                                attrs: { title: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeImg(
                                      "basicInfo.business_licene_photo"
                                    )
                                  },
                                },
                              },
                              [
                                _vm.formValidate.basicInfo.business_licene_photo
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.formValidate.basicInfo
                                              .business_licene_photo + "!120a",
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("图片不得大于6M"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "企业地址",
                              prop: "basicInfo.org_address",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入企业地址",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value: _vm.formValidate.basicInfo.org_address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.basicInfo,
                                    "org_address",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.basicInfo.org_address",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "注册邮箱",
                              prop: "basicInfo.org_email",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入注册邮箱",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value: _vm.formValidate.basicInfo.org_email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.basicInfo,
                                    "org_email",
                                    $$v
                                  )
                                },
                                expression: "formValidate.basicInfo.org_email",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentTab === 0,
                expression: "currentTab === 0",
              },
            ],
          }),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 0,
                  expression: "currentTab === 0",
                },
              ],
            },
            [
              _vm.formValidate.type == 1
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "银行卡号",
                              prop: "linkedAcctInfo.bank_card_id",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入银行卡号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.linkedAcctInfo.bank_card_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.linkedAcctInfo,
                                    "bank_card_id",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.linkedAcctInfo.bank_card_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "预留手机号",
                              prop: "linkedAcctInfo.bank_card_phone",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入预留手机号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.linkedAcctInfo
                                    .bank_card_phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.linkedAcctInfo,
                                    "bank_card_phone",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.linkedAcctInfo.bank_card_phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.formValidate.type == 2
                ? [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "18px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("开户信息")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "账户类型",
                              prop: "linkedAcctInfo.linked_accttype",
                              required: "",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: "请选择账户类型",
                                  disabled: _vm.statusdisabled,
                                },
                                model: {
                                  value:
                                    _vm.formValidate.linkedAcctInfo
                                      .linked_accttype,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate.linkedAcctInfo,
                                      "linked_accttype",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formValidate.linkedAcctInfo.linked_accttype",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: "企业对公户",
                                    value: "ENTERPRISE_BANK_ACCT",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "银行卡号",
                              prop: "linkedAcctInfo.linked_acctno",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入银行卡号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.linkedAcctInfo.linked_acctno,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.linkedAcctInfo,
                                    "linked_acctno",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.linkedAcctInfo.linked_acctno",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "银行编码",
                              prop: "linkedAcctInfo.linked_bankcode",
                              required: "",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: "请选择银行编码",
                                  filterable: "",
                                  disabled: _vm.statusdisabled,
                                },
                                on: { change: _vm.changebankcode },
                                model: {
                                  value:
                                    _vm.formValidate.linkedAcctInfo
                                      .linked_bankcode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate.linkedAcctInfo,
                                      "linked_bankcode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formValidate.linkedAcctInfo.linked_bankcode",
                                },
                              },
                              _vm._l(_vm.codeList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label:
                                      item.bank_name + "-" + item.bank_code,
                                    value: item.bank_code,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "对公账户开户行名",
                              prop: "linkedAcctInfo.linked_brbankname",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入对公账户开户行名",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.linkedAcctInfo
                                    .linked_brbankname,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.linkedAcctInfo,
                                    "linked_brbankname",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.linkedAcctInfo.linked_brbankname",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "对公账户开户行地址",
                              required: "",
                            },
                          },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请选择开户行地址",
                                options: _vm.cityList,
                                props: _vm.props,
                                "collapse-tags": "",
                                clearable: "",
                                disabled: _vm.statusdisabled,
                              },
                              on: { change: _vm.changecitycode },
                              model: {
                                value: _vm.citycode,
                                callback: function ($$v) {
                                  _vm.citycode = $$v
                                },
                                expression: "citycode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "对公账户开户行号",
                              prop: "linkedAcctInfo.linked_brbankno",
                              required: "",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  placeholder: "请选择对公账户开户行号",
                                  filterable: "",
                                  disabled: _vm.statusdisabled,
                                  remote: "",
                                  "remote-method": _vm.remoteMethod,
                                },
                                on: { focus: _vm.checkbrbankno },
                                model: {
                                  value:
                                    _vm.formValidate.linkedAcctInfo
                                      .linked_brbankno,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate.linkedAcctInfo,
                                      "linked_brbankno",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formValidate.linkedAcctInfo.linked_brbankno",
                                },
                              },
                              _vm._l(_vm.bankcodeList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label:
                                      item.brabank_name + "-" + item.cnaps_code,
                                    value: item.cnaps_code,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "对公账户名",
                              prop: "linkedAcctInfo.linked_acctname",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入对公账户名",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.linkedAcctInfo
                                    .linked_acctname,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.linkedAcctInfo,
                                    "linked_acctname",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.linkedAcctInfo.linked_acctname",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "预留手机号",
                              prop: "linkedAcctInfo.linked_phone",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入预留手机号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.linkedAcctInfo.linked_phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.linkedAcctInfo,
                                    "linked_phone",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.linkedAcctInfo.linked_phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.formValidate.type == 2
            ? [
                _c("el-divider", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                }),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentTab === 0,
                        expression: "currentTab === 0",
                      },
                    ],
                  },
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "18px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("法人信息")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "法定代表人姓名",
                              prop: "legalreptInfo.legalrept_name",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入法定代表人姓名",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.legalreptInfo.legalrept_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.legalreptInfo,
                                    "legalrept_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.legalreptInfo.legalrept_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "法定代表人手机号",
                              prop: "legalreptInfo.legalrept_phone",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入法定代表人手机号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.legalreptInfo
                                    .legalrept_phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.legalreptInfo,
                                    "legalrept_phone",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.legalreptInfo.legalrept_phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "法定代表人证件类型",
                              prop: "legalreptInfo.legalrept_id_type",
                              required: "",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { disabled: _vm.statusdisabled },
                                model: {
                                  value:
                                    _vm.formValidate.legalreptInfo
                                      .legalrept_id_type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate.legalreptInfo,
                                      "legalrept_id_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formValidate.legalreptInfo.legalrept_id_type",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "身份证", value: "ID_CARD" },
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "港澳通行证",
                                    value: "HONG_KONG_AND_MACAO_PASS",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "法定代表人证件号",
                              prop: "legalreptInfo.legalrept_idcard",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入法定代表人证件号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.legalreptInfo
                                    .legalrept_idcard,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.legalreptInfo,
                                    "legalrept_idcard",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.legalreptInfo.legalrept_idcard",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "法人证件正面",
                              prop: "legalreptInfo.idcard_face",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox",
                                attrs: { title: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeImg(
                                      "legalreptInfo.idcard_face"
                                    )
                                  },
                                },
                              },
                              [
                                _vm.formValidate.legalreptInfo.idcard_face
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.formValidate.legalreptInfo
                                              .idcard_face + "!120a",
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("图片不得大于6M"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "法人证件反面",
                              prop: "legalreptInfo.idcard_back",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox",
                                attrs: { title: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeImg(
                                      "legalreptInfo.idcard_back"
                                    )
                                  },
                                },
                              },
                              [
                                _vm.formValidate.legalreptInfo.idcard_back
                                  ? _c("div", { staticClass: "pictrue" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.formValidate.legalreptInfo
                                              .idcard_back + "!120a",
                                        },
                                      }),
                                    ])
                                  : _c("div", { staticClass: "upLoad" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-camera cameraIconfont",
                                      }),
                                    ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("图片不得大于6M"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "证件有效期限" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.statusdisabled },
                                model: {
                                  value: _vm.formValidate.cardtype,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formValidate, "cardtype", $$v)
                                  },
                                  expression: "formValidate.cardtype",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("短期有效"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("长期有效"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.formValidate.cardtype == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "证件过期时间",
                                  prop: "legalreptInfo.expiration_date",
                                  required: "",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择过期日期",
                                    "value-format": "yyyyMMdd",
                                    format: "yyyy-MM-dd",
                                    editable: false,
                                    clearable: false,
                                    disabled: _vm.statusdisabled,
                                  },
                                  model: {
                                    value:
                                      _vm.formValidate.legalreptInfo
                                        .expiration_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate.legalreptInfo,
                                        "expiration_date",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formValidate.legalreptInfo.expiration_date",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-divider", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                }),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentTab === 0,
                        expression: "currentTab === 0",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "联系人姓名",
                              prop: "contactsInfo.contacts_name",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入联系人姓名",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.contactsInfo.contacts_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.contactsInfo,
                                    "contacts_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.contactsInfo.contacts_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "联系人手机号",
                              prop: "contactsInfo.contacts_phone",
                              required: "",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请输入联系人手机号",
                                disabled: _vm.statusdisabled,
                              },
                              model: {
                                value:
                                  _vm.formValidate.contactsInfo.contacts_phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.contactsInfo,
                                    "contacts_phone",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.contactsInfo.contacts_phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-divider", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 0,
                      expression: "currentTab === 0",
                    },
                  ],
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 1 || _vm.formValidate.type == 2,
                  expression: "currentTab === 1 || formValidate.type == 2",
                },
              ],
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === 1,
                      expression: "currentTab === 1",
                    },
                  ],
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "签约验证码", required: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入验证码" },
                        model: {
                          value: _vm.vcode,
                          callback: function ($$v) {
                            _vm.vcode = $$v
                          },
                          expression: "vcode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "密码(6-12位)", required: "" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "200px", height: "36px" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ipt_pwd",
                              attrs: { id: "ipt_pwd2" },
                            },
                            [
                              _c("span", { attrs: { id: "LOGPASS2_SHOW" } }, [
                                _vm._v("请输入密码"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "default input_search",
                                attrs: { id: "LOGPASS2" },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "ipt_pwd_rjp default",
                                attrs: { id: "keyboardBtn2" },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formValidate.type == 1
            ? _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab > 0,
                          expression: "currentTab > 0",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmitUp },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab < 1,
                          expression: "currentTab < 1",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentTab === 1 || _vm.$route.params.id,
                          expression: "currentTab === 1 || $route.params.id",
                        },
                      ],
                      staticClass: "submission",
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.formValidate.type == 2
            ? _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _vm.status == "ACTIVATE_PENDING" ||
                  _vm.status == "WAIT_COMMIT"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "submission",
                          attrs: { loading: _vm.loading, type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleSubmit("formValidate")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }