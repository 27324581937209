var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            size: "60%",
            visible: _vm.albumVisitData.show,
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.albumVisitData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "drawer-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("共" + _vm._s(_vm.otherTotal) + "人出价")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "record-content" }, [
            _c(
              "div",
              { staticClass: "table-view" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "color-black",
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      data: _vm.albumVisitData.list,
                      "element-loading-text": "Loading",
                      fit: "",
                      height: "90%",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c("div", { staticClass: "tableEmpty" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "用户信息", "min-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "flex" }, [
                                _c("div", { staticClass: "avatar-img" }, [
                                  _c("img", {
                                    attrs: { src: row.user.avatar, alt: "" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "user-info flex-one ml10",
                                    staticStyle: {
                                      overflow: "hidden",
                                      cursor: "pointer",
                                    },
                                    style: {
                                      color:
                                        row.user.uid == 8888 ? "" : "#46a6ff",
                                      cursor:
                                        row.user.uid == 8888 ? "" : "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(row.user.uid)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          row.user.real_name ||
                                            row.user.nickname
                                        ) + " "
                                      ),
                                      row.user.real_name
                                        ? _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(row.user.nickname) +
                                                ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("UID:" + _vm._s(row.user.uid)),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "号牌",
                        "min-width": "80",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.numberPlate
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(row.numberPlate.number) + " "
                                    ),
                                    row.numberPlate.number_type == "特殊号牌"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "ts",
                                            staticStyle: {
                                              background: "#fa4f4f",
                                            },
                                          },
                                          [_vm._v("特")]
                                        )
                                      : row.numberPlate.number_type ==
                                        "免保号牌"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "ts",
                                            staticStyle: {
                                              background: "#ddb75b",
                                            },
                                          },
                                          [_vm._v("免")]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "出价",
                        "min-width": "80",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    class: { ft14: row.lx, fWeight500: row.lx },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatPriceNumber(row.bid_price, 2)
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                row.is_proxy_bid
                                  ? _c(
                                      "span",
                                      { staticClass: "ft12 color-gray" },
                                      [_vm._v("委托出价")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "状态",
                        "min-width": "80",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.lx
                                ? _c(
                                    "div",
                                    { staticClass: "ft14 fWeight500" },
                                    [_vm._v("领先")]
                                  )
                                : _c("div", [_vm._v("出局")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "出价时间",
                        "min-width": "120",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [_vm._v(_vm._s(row.create_time))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "120",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.lx
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delAction(row.bid_id)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class": "commondelete",
                                            },
                                          }),
                                          _vm._v("删除"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt20 flex justify-e" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-size": _vm.query.limit,
                        "current-page": _vm.query.page,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.otherTotal,
                      },
                      on: { "current-change": _vm.pageOtherChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }