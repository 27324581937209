var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作品名称", prop: "store_name" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: {
                      placeholder: "请输入作品名称",
                      disabled: _vm.status == 1,
                    },
                    model: {
                      value: _vm.workDetail.store_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "store_name", $$v)
                      },
                      expression: "workDetail.store_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择系列", prop: "album" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        filterable: "",
                        remote: "",
                        clearable: "",
                        "reserve-keyword": "",
                        placeholder: "请选择藏品系列",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading,
                      },
                      model: {
                        value: _vm.workDetail.album_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "album_id", $$v)
                        },
                        expression: "workDetail.album_id",
                      },
                    },
                    _vm._l(_vm.albumList, function (item) {
                      return _c("el-option", {
                        key: item.nft_album_id,
                        attrs: {
                          label: item.album_name,
                          value: item.nft_album_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "文件类型", prop: "file_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "请选择文件类型",
                        disabled: _vm.status == 1,
                      },
                      model: {
                        value: _vm.workDetail.file_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "file_type", $$v)
                        },
                        expression: "workDetail.file_type",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "图片", value: "1" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "3D", value: "2" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "视频", value: "3" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "音频", value: "4" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.workDetail.file_type != 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "详情图片展示" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.workDetail.img_show_style,
                            callback: function ($$v) {
                              _vm.$set(_vm.workDetail, "img_show_style", $$v)
                            },
                            expression: "workDetail.img_show_style",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("动态展示"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("静态展示"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("平铺展示"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "flex-one fontW400 color-gray ft12",
                          staticStyle: { margin: "0 12px" },
                        },
                        [_vm._v("选择动态展示，详情页展示图片3D左右晃动")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类" } },
                [
                  _c("el-cascader", {
                    staticClass: "w300",
                    attrs: {
                      options: _vm.categoryList,
                      props: {
                        expandTrigger: "hover",
                        value: "store_category_id",
                        label: "cate_name",
                      },
                    },
                    model: {
                      value: _vm.workDetail.cate_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "cate_id", $$v)
                      },
                      expression: "workDetail.cate_id",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属标签" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: { multiple: "", placeholder: "请选择所属标签" },
                      model: {
                        value: _vm.workDetail.label_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "label_id", $$v)
                        },
                        expression: "workDetail.label_id",
                      },
                    },
                    _vm._l(_vm.tagList, function (m, i) {
                      return _c("el-option", {
                        key: "tag" + i,
                        attrs: { label: m.label_name, value: m.label_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转赠规则" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: { placeholder: "请选择转赠规则" },
                      model: {
                        value: _vm.workDetail.transfer_config_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "transfer_config_id", $$v)
                        },
                        expression: "workDetail.transfer_config_id",
                      },
                    },
                    _vm._l(_vm.transferList, function (m, i) {
                      return _c("el-option", {
                        key: "transfer" + i,
                        attrs: { label: m.title, value: m.config_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作品封面", prop: "image" } },
                [
                  _c("div", { staticClass: "flex align-items-c" }, [
                    _vm.workDetail.image
                      ? _c("div", { staticClass: "img-list" }, [
                          _c("img", {
                            attrs: { src: _vm.workDetail.image + "!120a" },
                            on: {
                              click: function ($event) {
                                return _vm.showImageAction(_vm.workDetail.image)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del",
                              on: {
                                click: function ($event) {
                                  return _vm.delImgAction("image")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c mr10",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg("image")
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml10 color-gray ft12" }, [
                      _vm._v(
                        "图片格式支持：JPG、JPEG、PNG、GIF；文件大小≤15M；图片比例建议1:1"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.workDetail.file_type != 2
                ? _c("el-form-item", { attrs: { label: "作品展示" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "el-upload-view",
                            attrs: {
                              action: "/",
                              "show-file-list": false,
                              "http-request": function (file) {
                                return _vm.uploadShowImg(file, "show_image")
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "item-flex-center",
                                staticStyle: { cursor: "pointer" },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      loading: _vm.btnLoading1,
                                      type: "primary",
                                    },
                                  },
                                  [_vm._v("上传文件")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.uploadShowType == "__source=video"
                          ? _c("video", {
                              staticStyle: {
                                opacity: "0",
                                position: "absolute",
                                left: "-750px",
                                top: "-3000px",
                              },
                              attrs: { src: _vm.uploadShowUrl, id: "video" },
                              on: { canplay: _vm.canplayAction },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.workDetail.show_image
                          ? _c(
                              "span",
                              { staticClass: "ml10 color-gray ft12" },
                              [
                                _vm._v(
                                  "已上传（详情页展示使用，如不传详情页展示封面图）"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10 color-gray ft12" }, [
                          _vm._v(
                            "(可上传 " +
                              _vm._s(
                                _vm.workDetail.file_type == 1 ? "图片" : ""
                              ) +
                              _vm._s(
                                _vm.workDetail.file_type == 3
                                  ? "视频、图片"
                                  : ""
                              ) +
                              _vm._s(
                                _vm.workDetail.file_type == 4
                                  ? "音频、图片"
                                  : ""
                              ) +
                              "。详情页展示使用，如不传详情页展示封面图)"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.workDetail.file_type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "作品文件", prop: "original_url" } },
                    [
                      _c("div", { staticClass: "flex align-items-c" }, [
                        _c(
                          "div",
                          { staticClass: "upload-btn" },
                          [
                            _c("el-button", { attrs: { type: "primary" } }, [
                              _vm._v("选择文件夹\n              "),
                              _c("input", {
                                ref: "file",
                                staticStyle: {
                                  opacity: "0",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  disabled: _vm.status == 1,
                                  id: "file1",
                                  type: "file",
                                  webkitdirectory: "",
                                  directory: "",
                                  multiple: "multiple",
                                },
                                on: { change: _vm.uploadAllImg },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.workDetail.original_url
                          ? _c(
                              "span",
                              { staticClass: "ml10 color-gray ft12" },
                              [_vm._v("已上传")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.oldFileName
                          ? _c("span", { staticClass: "color-gray ft12" }, [
                              _vm._v("文件：" + _vm._s(_vm.oldFileName)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10 color-gray ft12" }, [
                          _vm._v("(请上传包含 .gltf 文件的文件夹)"),
                        ]),
                      ]),
                    ]
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "作品文件", prop: "original_url" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "el-upload-view",
                              attrs: {
                                action: "/",
                                "show-file-list": false,
                                "http-request": function (file) {
                                  return _vm.uploadImg(file, "original_url")
                                },
                                disabled: _vm.status == 1,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "item-flex-center",
                                  staticStyle: { cursor: "pointer" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        loading: _vm.btnLoading,
                                        type: "primary",
                                      },
                                    },
                                    [_vm._v("上传源文件")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.workDetail.original_url
                            ? _c(
                                "span",
                                { staticClass: "ml10 color-gray ft12" },
                                [_vm._v("已上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.oldFileName
                            ? _c("span", { staticClass: "color-gray ft12" }, [
                                _vm._v("文件：" + _vm._s(_vm.oldFileName)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10 color-gray ft12" }, [
                            _vm._v(
                              "(请上传 " +
                                _vm._s(
                                  _vm.workDetail.file_type == 1 ? "图片" : ""
                                ) +
                                _vm._s(
                                  _vm.workDetail.file_type == 3 ? "视频" : ""
                                ) +
                                _vm._s(
                                  _vm.workDetail.file_type == 4 ? "音频" : ""
                                ) +
                                ")"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "编号前缀", prop: "number_prefix" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: {
                      placeholder: "请输入编号前缀",
                      disabled: _vm.status == 1,
                    },
                    model: {
                      value: _vm.workDetail.number_prefix,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "number_prefix", $$v)
                      },
                      expression: "workDetail.number_prefix",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作品价格", prop: "price" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "w300",
                      attrs: {
                        type: "number",
                        placeholder: "请输入作品价格",
                        disabled: _vm.status == 1,
                      },
                      on: { input: _vm.changePrice },
                      model: {
                        value: _vm.workDetail.price,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "price", _vm._n($$v))
                        },
                        expression: "workDetail.price",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发售时间", prop: "start_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "请选择发售时间",
                      align: "right",
                    },
                    model: {
                      value: _vm.workDetail.start_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "start_time", $$v)
                      },
                      expression: "workDetail.start_time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发行数量", prop: "issuer_num" } },
                [
                  _c(
                    "div",
                    { staticClass: "align-items-c flex" },
                    [
                      _c("el-input", {
                        staticClass: "w300",
                        attrs: {
                          type: "number",
                          placeholder: "请输入发行数量",
                          disabled: _vm.status == 1,
                        },
                        model: {
                          value: _vm.workDetail.issuer_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.workDetail, "issuer_num", _vm._n($$v))
                          },
                          expression: "workDetail.issuer_num",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "align-items-c flex ml10" },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ccc",
                              "active-value": 1,
                              "inactive-value": 0,
                              disabled: _vm.status == 1,
                            },
                            model: {
                              value: _vm.workDetail.is_addIssue,
                              callback: function ($$v) {
                                _vm.$set(_vm.workDetail, "is_addIssue", $$v)
                              },
                              expression: "workDetail.is_addIssue",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("本藏品为合成材料或奖品（可增发）"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "ml5",
                              staticStyle: { color: "#ff9900" },
                            },
                            [
                              _vm._v(
                                "（正常发售藏品切勿选择，选择后不可修改，请慎重）"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "预留数量" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: {
                      type: "number",
                      placeholder: "请输入预留数量(数量不超过发行数量)",
                    },
                    model: {
                      value: _vm.workDetail.reserved_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "reserved_number", _vm._n($$v))
                      },
                      expression: "workDetail.reserved_number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "限购数量" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: {
                      type: "number",
                      placeholder: "每个用户的限购数量",
                    },
                    model: {
                      value: _vm.workDetail.buy_limit_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "buy_limit_num", _vm._n($$v))
                      },
                      expression: "workDetail.buy_limit_num",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "创作者", prop: "author" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入创作者" },
                    model: {
                      value: _vm.workDetail.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "author", $$v)
                      },
                      expression: "workDetail.author",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发行方", prop: "issuer_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "请选择发行方",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.changeletter },
                      model: {
                        value: _vm.workDetail.issuer_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "issuer_id", $$v)
                        },
                        expression: "workDetail.issuer_id",
                      },
                    },
                    _vm._l(_vm.letterarr, function (item) {
                      return _c("el-option", {
                        key: "ent_" + item.entrust_id,
                        attrs: {
                          label:
                            (item.entrust_contact_company || "") +
                            " - " +
                            item.entrust_contact_name,
                          value: item.entrust_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详情介绍" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.workDetail.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "content", $$v)
                      },
                      expression: "workDetail.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showWork = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imagesKey + "!m640" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }