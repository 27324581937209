"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "GoodsList",
  data: function data() {
    return {
      listLoading: true,
      multipleSelection: [],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 3,
        keyword: ""
      }
    };
  },
  props: {
    broadcast_room_id: {
      type: Number
    },
    type: {
      type: String,
      default: 'detail'
    }
  },
  watch: {
    broadcast_room_id: {
      deep: true,
      handler: function handler(val) {
        this.getList(val);
      }
    }
  },
  mounted: function mounted() {
    this.getList(this.broadcast_room_id);
  },
  methods: {
    // 列表
    getList: function getList(id) {
      var _this = this;
      this.listLoading = true;
      (0, _marketing.studioProList)(id, this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList(this.broadcast_room_id);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(this.broadcast_room_id);
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSureDelete().then(function () {
        (0, _marketing.broadcastGoodsDeleteApi)({
          id: id,
          room_id: _this2.broadcast_room_id
        }).then(function (_ref) {
          var message = _ref.message;
          _this2.$message.success(message);
          _this2.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    }
  }
};