var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
    [
      _c("div", { staticClass: "setting-table-box" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "setting-table-list" }, [
          _c("div", { staticClass: "setting-table-type" }, [
            _vm._v("委托合同"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-table-content" }, [
            _vm._v("创建合同、修改合同内容"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-people" },
            [
              _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择审核员",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "multiple-limit": 2,
                  },
                  model: {
                    value: _vm.formValidateJson.erp_v2_contract_admin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "erp_v2_contract_admin",
                        $$v
                      )
                    },
                    expression: "formValidateJson.erp_v2_contract_admin",
                  },
                },
                _vm._l(_vm.adminarr, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-switch" },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.formValidateJson.erp_v2_contract,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidateJson, "erp_v2_contract", $$v)
                  },
                  expression: "formValidateJson.erp_v2_contract",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "setting-table-list" }, [
          _c("div", { staticClass: "setting-table-type" }, [_vm._v("入库单")]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-table-content" }, [
            _vm._v("每次创建入库单时"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-people" },
            [
              _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择审核员",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "multiple-limit": 2,
                  },
                  model: {
                    value: _vm.formValidateJson.erp_v2_enter_warehouse_admin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "erp_v2_enter_warehouse_admin",
                        $$v
                      )
                    },
                    expression: "formValidateJson.erp_v2_enter_warehouse_admin",
                  },
                },
                _vm._l(_vm.adminarr, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-switch" },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.formValidateJson.erp_v2_enter_warehouse,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formValidateJson,
                      "erp_v2_enter_warehouse",
                      $$v
                    )
                  },
                  expression: "formValidateJson.erp_v2_enter_warehouse",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "setting-table-list" }, [
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "setting-table-people",
                staticStyle: { "border-bottom": "1px solid #ccc" },
              },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "selwidth",
                    attrs: {
                      placeholder: "请选择审核员",
                      clearable: "",
                      filterable: "",
                      multiple: "",
                      "multiple-limit": 2,
                    },
                    model: {
                      value:
                        _vm.formValidateJson.erp_v2_out_warehouse_order_admin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formValidateJson,
                          "erp_v2_out_warehouse_order_admin",
                          $$v
                        )
                      },
                      expression:
                        "formValidateJson.erp_v2_out_warehouse_order_admin",
                    },
                  },
                  _vm._l(_vm.adminarr, function (item) {
                    return _c("el-option", {
                      key: item.merchant_admin_id,
                      attrs: {
                        label: item.real_name,
                        value: item.merchant_admin_id,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "setting-table-people",
                staticStyle: { "border-bottom": "1px solid #ccc" },
              },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "selwidth",
                    attrs: {
                      placeholder: "请选择审核员",
                      clearable: "",
                      filterable: "",
                      multiple: "",
                      "multiple-limit": 2,
                    },
                    model: {
                      value:
                        _vm.formValidateJson
                          .erp_v2_out_warehouse_transfers_admin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formValidateJson,
                          "erp_v2_out_warehouse_transfers_admin",
                          $$v
                        )
                      },
                      expression:
                        "formValidateJson.erp_v2_out_warehouse_transfers_admin",
                    },
                  },
                  _vm._l(_vm.adminarr, function (item) {
                    return _c("el-option", {
                      key: item.merchant_admin_id,
                      attrs: {
                        label: item.real_name,
                        value: item.merchant_admin_id,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "setting-table-people",
                staticStyle: { "border-bottom": "1px solid #ccc" },
              },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "selwidth",
                    attrs: {
                      placeholder: "请选择审核员",
                      clearable: "",
                      filterable: "",
                      multiple: "",
                      "multiple-limit": 2,
                    },
                    model: {
                      value:
                        _vm.formValidateJson.erp_v2_out_warehouse_borrow_admin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formValidateJson,
                          "erp_v2_out_warehouse_borrow_admin",
                          $$v
                        )
                      },
                      expression:
                        "formValidateJson.erp_v2_out_warehouse_borrow_admin",
                    },
                  },
                  _vm._l(_vm.adminarr, function (item) {
                    return _c("el-option", {
                      key: item.merchant_admin_id,
                      attrs: {
                        label: item.real_name,
                        value: item.merchant_admin_id,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "setting-table-people" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "selwidth",
                    attrs: {
                      placeholder: "请选择审核员",
                      clearable: "",
                      filterable: "",
                      multiple: "",
                      "multiple-limit": 2,
                    },
                    model: {
                      value:
                        _vm.formValidateJson.erp_v2_out_warehouse_refund_admin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formValidateJson,
                          "erp_v2_out_warehouse_refund_admin",
                          $$v
                        )
                      },
                      expression:
                        "formValidateJson.erp_v2_out_warehouse_refund_admin",
                    },
                  },
                  _vm._l(_vm.adminarr, function (item) {
                    return _c("el-option", {
                      key: item.merchant_admin_id,
                      attrs: {
                        label: item.real_name,
                        value: item.merchant_admin_id,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "setting-table-switch",
                staticStyle: { "border-bottom": "1px solid #ccc" },
              },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.formValidateJson.erp_v2_out_warehouse_order,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "erp_v2_out_warehouse_order",
                        $$v
                      )
                    },
                    expression: "formValidateJson.erp_v2_out_warehouse_order",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "setting-table-switch",
                staticStyle: { "border-bottom": "1px solid #ccc" },
              },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.formValidateJson.erp_v2_out_warehouse_transfers,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "erp_v2_out_warehouse_transfers",
                        $$v
                      )
                    },
                    expression:
                      "formValidateJson.erp_v2_out_warehouse_transfers",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "setting-table-switch",
                staticStyle: { "border-bottom": "1px solid #ccc" },
              },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.formValidateJson.erp_v2_out_warehouse_borrow,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "erp_v2_out_warehouse_borrow",
                        $$v
                      )
                    },
                    expression: "formValidateJson.erp_v2_out_warehouse_borrow",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "setting-table-switch" },
              [
                _c("el-switch", {
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.formValidateJson.erp_v2_out_warehouse_refund,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "erp_v2_out_warehouse_refund",
                        $$v
                      )
                    },
                    expression: "formValidateJson.erp_v2_out_warehouse_refund",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "setting-table-list" }, [
          _c("div", { staticClass: "setting-table-type" }, [_vm._v("调拨单")]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-table-content" }, [
            _vm._v("每次创建调拨单时"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-people" },
            [
              _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择审核员",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "multiple-limit": 2,
                  },
                  model: {
                    value:
                      _vm.formValidateJson.erp_v2_transfers_warehouse_admin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "erp_v2_transfers_warehouse_admin",
                        $$v
                      )
                    },
                    expression:
                      "formValidateJson.erp_v2_transfers_warehouse_admin",
                  },
                },
                _vm._l(_vm.adminarr, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-switch" },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.formValidateJson.erp_v2_transfers_warehouse,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formValidateJson,
                      "erp_v2_transfers_warehouse",
                      $$v
                    )
                  },
                  expression: "formValidateJson.erp_v2_transfers_warehouse",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "setting-table-list" }, [
          _c("div", { staticClass: "setting-table-type" }, [_vm._v("盘点单")]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-table-content" }, [
            _vm._v("每次创建盘点单时"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-people" },
            [
              _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择审核员",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "multiple-limit": 2,
                  },
                  model: {
                    value: _vm.formValidateJson.erp_v2_stock_take_admin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "erp_v2_stock_take_admin",
                        $$v
                      )
                    },
                    expression: "formValidateJson.erp_v2_stock_take_admin",
                  },
                },
                _vm._l(_vm.adminarr, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-switch" },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.formValidateJson.erp_v2_stock_take,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidateJson, "erp_v2_stock_take", $$v)
                  },
                  expression: "formValidateJson.erp_v2_stock_take",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "setting-table-list" }, [
          _c("div", { staticClass: "setting-table-type" }, [_vm._v("营销")]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-table-content" }, [
            _vm._v("每次生成视频时"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-people" },
            [
              _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择审核员",
                    clearable: "",
                    filterable: "",
                    multiple: "",
                    "multiple-limit": 2,
                  },
                  model: {
                    value: _vm.formValidateJson.market_media_make_admin,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formValidateJson,
                        "market_media_make_admin",
                        $$v
                      )
                    },
                    expression: "formValidateJson.market_media_make_admin",
                  },
                },
                _vm._l(_vm.adminarr, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "setting-table-switch" },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.formValidateJson.market_media_make,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidateJson, "market_media_make", $$v)
                  },
                  expression: "formValidateJson.market_media_make",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { type: "primary", size: "default" },
          on: { click: _vm.save },
        },
        [_vm._v("保存")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "setting-table-list" }, [
      _c("div", { staticClass: "setting-table-type" }, [_vm._v("单据类型")]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-table-content" }, [_vm._v("审批场景")]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-table-people" }, [_vm._v("审核人员")]),
      _vm._v(" "),
      _c("div", { staticClass: "setting-table-switch" }, [_vm._v("是否启用")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "setting-table-type",
        staticStyle: { height: "auto", padding: "0" },
      },
      [
        _c("div", { staticStyle: { flex: "1", "padding-left": "8px" } }, [
          _vm._v("出库单"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "setting-table-type nonBor" }, [
            _vm._v("销售出库"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-table-type nonBor" }, [
            _vm._v("调拨出库"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "setting-table-type nonBor" }, [
            _vm._v("借出出库"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "setting-table-type nonBor",
              staticStyle: { "border-bottom": "none" },
            },
            [_vm._v("退回出库")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass: "setting-table-content",
          staticStyle: { "border-bottom": "1px solid #ccc" },
        },
        [_vm._v("每次创建出库单时")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "setting-table-content",
          staticStyle: { "border-bottom": "1px solid #ccc" },
        },
        [_vm._v("每次创建出库单时")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "setting-table-content",
          staticStyle: { "border-bottom": "1px solid #ccc" },
        },
        [_vm._v("每次创建出库单时")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "setting-table-content" }, [
        _vm._v("每次创建出库单时"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }