var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("Table", {
        attrs: {
          columns: _vm.answerColumns,
          data: _vm.answerList,
          size: "small",
        },
      }),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: {
            title: "查看视频",
            "footer-hide": "footer-hide",
            width: 800,
            align: "center",
          },
          model: {
            value: _vm.showVideo,
            callback: function ($$v) {
              _vm.showVideo = $$v
            },
            expression: "showVideo",
          },
        },
        [
          _c("div", [
            _vm.video
              ? _c("video", {
                  staticStyle: { width: "750px", height: "421px" },
                  attrs: { src: _vm.video, controls: true },
                })
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          attrs: {
            title: "查看图片",
            "footer-hide": "footer-hide",
            width: 800,
            align: "center",
          },
          model: {
            value: _vm.showImg,
            callback: function ($$v) {
              _vm.showImg = $$v
            },
            expression: "showImg",
          },
        },
        [
          _vm.img && _vm.img.length > 0
            ? _c(
                "div",
                { staticClass: "h400" },
                _vm._l(_vm.img, function (m, i) {
                  return m
                    ? _c(
                        "a",
                        {
                          key: "img" + i,
                          attrs: { href: m, target: "_blank" },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "750px" },
                            attrs: { src: m, controls: true },
                          }),
                        ]
                      )
                    : _vm._e()
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }