var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", [
        _c(
          "div",
          { staticClass: "top-view plr20" },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo default-tabs mt10",
                attrs: { "default-active": _vm.tab, mode: "horizontal" },
                on: { select: _vm.changeTab },
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("启动页设置"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "2" } }, [
                  _vm._v("iOS更新"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "3" } }, [
                  _vm._v("Android更新"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content mt20" },
          [
            _vm.tab == 1
              ? [
                  _c("div", { staticClass: "flex align-items-c mt20" }, [
                    _c("div", { staticClass: "l w100" }, [_vm._v("启动图片")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one ml10" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.uploadImgAction },
                          },
                          [_vm._v("点击上传")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml10 color-gray" }, [
                          _vm._v(
                            "建议尺寸1080*1920，因适配不同尺寸屏幕，请在图片四周留出安全区域"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.startImg
                    ? _c("div", { staticClass: "flex align-items-c mt20" }, [
                        _c("div", { staticClass: "l w100" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-one ml10" }, [
                          _c(
                            "div",
                            { staticClass: "cover-img" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.startImg + "!webp640",
                                  fit: "cover",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "del",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delImgAction(_vm.i)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex align-items-c mt20" }, [
                    _c("div", { staticClass: "l w100" }, [_vm._v("链接地址")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one ml10" },
                      [
                        _c("el-input", {
                          staticStyle: { width: "500px" },
                          model: {
                            value: _vm.link_url,
                            callback: function ($$v) {
                              _vm.link_url = $$v
                            },
                            expression: "link_url",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex align-items-c mt20" }, [
                    _c("div", { staticClass: "l w100" }, [_vm._v("停留时间")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one ml10" },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          model: {
                            value: _vm.stop_time,
                            callback: function ($$v) {
                              _vm.stop_time = $$v
                            },
                            expression: "stop_time",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml5" }, [_vm._v("秒")]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex mt20" }, [
                    _c("div", { staticClass: "l w100" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one ml10" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.saveAction },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              : [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.showAddAPPAction },
                    },
                    [_vm._v("更新APP")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticClass: "mt10",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData.data,
                        size: "small",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _vm.tab == 3
                        ? _c("el-table-column", {
                            attrs: { label: "包名", "min-width": "200" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "flex align-items-c" },
                                        [
                                          row.env == "pre"
                                            ? _c(
                                                "div",
                                                { staticClass: "new" },
                                                [_vm._v("新")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          row.currentVersion == 1
                                            ? _c(
                                                "div",
                                                { staticClass: "now" },
                                                [_vm._v("当前版本")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(_vm._s(row.appPackageName)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2430103266
                            ),
                          })
                        : _vm.tab == 2
                        ? _c("el-table-column", {
                            attrs: { label: "状态", "min-width": "100" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex align-items-c" },
                                      [
                                        row.env == "pre"
                                          ? _c("div", { staticClass: "new" }, [
                                              _vm._v("新"),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row.currentVersion == 1
                                          ? _c("div", { staticClass: "now" }, [
                                              _vm._v("当前版本"),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "包大小", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      (row.packageSize / 1024 / 1024).toFixed(2)
                                    ) + "Mb"
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "versionCode", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [_vm._v(_vm._s(row.versionCode))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "升级方式", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      row.renewType == 1
                                        ? "可选更新"
                                        : "强制更新"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "更新说明", "min-width": "200" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_c("div", [_vm._v(_vm._s(row.remark))])]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "更新时间", "min-width": "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [_vm._v(_vm._s(row.createTime))]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", "min-width": "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.currentVersion != 1 && row.env == "pre"
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeNow(row)
                                          },
                                        },
                                      },
                                      [_vm._v("使用此版本")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.tab == 3
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadAPP(row.url)
                                          },
                                        },
                                      },
                                      [_vm._v("下载包文件")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block mt20" },
                    [
                      _c("el-pagination", {
                        staticStyle: { "text-align": "right" },
                        attrs: {
                          "page-sizes": [20, 40, 60, 80],
                          "page-size": _vm.tableFrom.limit,
                          "current-page": _vm.tableFrom.page,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.tableData.total,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.pageChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.appUploadShow,
            title: "更新APP",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.appUploadShow = $event
            },
          },
        },
        [
          _vm.tab == 3
            ? [
                !_vm.apkData
                  ? _c("div", { staticClass: "flex align-items-c mt20" }, [
                      _c("div", { staticClass: "l w100" }, [
                        _vm._v("上传包文件"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-one ml10" },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: "/",
                                "show-file-list": false,
                                "http-request": _vm.uploadAPKAction,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("上传APK包")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.apkData
                  ? _c("div", { staticClass: "flex align-items-c mt20" }, [
                      _c("div", { staticClass: "l w100" }, [
                        _vm._v("上传包文件"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-one ml10 flex" }, [
                        _c("div", { staticClass: "img-r ft12" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.apkData.appName)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "txt" }, [
                            _vm._v(
                              "包名：" +
                                _vm._s(_vm.apkData.appPackageName) +
                                " 版本：" +
                                _vm._s(_vm.apkData.version) +
                                " VersionCode：" +
                                _vm._s(_vm.apkData.versionCode) +
                                " 包大小：" +
                                _vm._s(
                                  (
                                    _vm.apkData.packAgeSize /
                                    1024 /
                                    1024
                                  ).toFixed(2)
                                ) +
                                "Mb"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "btn" },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: "/",
                                    "show-file-list": false,
                                    "http-request": _vm.uploadAPKAction,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { type: "text", size: "mini" } },
                                    [_vm._v("重新上传")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tab == 2
            ? [
                _c("div", { staticClass: "flex align-items-c mt20" }, [
                  _c("div", { staticClass: "l w100" }, [
                    _vm._v("应用市场地址"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one ml10" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.application_market_url,
                          callback: function ($$v) {
                            _vm.application_market_url = $$v
                          },
                          expression: "application_market_url",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt20" }, [
            _c("div", { staticClass: "l w100" }, [_vm._v("VersionCode")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one ml10" },
              [
                _c("el-input", {
                  staticClass: "w200",
                  model: {
                    value: _vm.version_code,
                    callback: function ($$v) {
                      _vm.version_code = $$v
                    },
                    expression: "version_code",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt20" }, [
            _c("div", { staticClass: "l w100" }, [_vm._v("升级方式")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one ml10" },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.upgrade_type,
                      callback: function ($$v) {
                        _vm.upgrade_type = $$v
                      },
                      expression: "upgrade_type",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "1" } }, [
                      _vm._v("可选更新"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: "2" } }, [
                      _vm._v("强制更新"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt20" }, [
            _c("div", { staticClass: "l w100" }, [_vm._v("更新说明")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one ml10" },
              [
                _c("el-input", {
                  staticStyle: { width: "500px" },
                  attrs: { type: "textarea", rows: 5 },
                  model: {
                    value: _vm.remark,
                    callback: function ($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt20" }, [
            _c("div", { staticClass: "l w100" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one ml10" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.appUploadShow = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }