var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tag-view flex align-items-c" },
      [
        _c("auctionStatus", {
          attrs: { type: 0, status: 1, "tag-data": _vm.facade },
        }),
        _vm._v(" "),
        _c("auctionStatus", {
          staticClass: "ml20",
          attrs: { type: 0, status: 2, "tag-data": _vm.facade },
        }),
        _vm._v(" "),
        _c("auctionStatus", {
          staticClass: "ml20",
          attrs: { type: 0, status: 3, "tag-data": _vm.facade },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tag-view flex align-items-c mt10" },
      [
        _c("auctionStatus", {
          attrs: { type: 3, status: 1, "tag-data": _vm.facade },
        }),
        _vm._v(" "),
        _c("auctionStatus", {
          staticClass: "ml20",
          attrs: { type: 3, status: 2, "tag-data": _vm.facade },
        }),
        _vm._v(" "),
        _c("auctionStatus", {
          staticClass: "ml20",
          attrs: { type: 3, status: 3, "tag-data": _vm.facade },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tag-view flex align-items-c mt10" },
      [
        _c("auctionStatus", {
          attrs: { type: 1, status: 1, "tag-data": _vm.facade },
        }),
        _vm._v(" "),
        _c("auctionStatus", {
          staticClass: "ml20",
          attrs: { type: 1, status: 2, "tag-data": _vm.facade },
        }),
        _vm._v(" "),
        _c("auctionStatus", {
          staticClass: "ml20",
          attrs: { type: 1, status: 3, "tag-data": _vm.facade },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tag-view flex align-items-c mt10" },
      [
        _c("auctionStatus", {
          attrs: { type: 2, status: 1, "tag-data": _vm.facade },
        }),
        _vm._v(" "),
        _c("auctionStatus", {
          staticClass: "ml20",
          attrs: { type: 2, status: 2, "tag-data": _vm.facade },
        }),
        _vm._v(" "),
        _c("auctionStatus", {
          staticClass: "ml20",
          attrs: { type: 2, status: 3, "tag-data": _vm.facade },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }