var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "rt-btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.createdDiy } },
            [_vm._v("新增自定义页面")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "list-table hasAbsolute",
                attrs: {
                  data: _vm.list,
                  border: "",
                  "element-loading-text": "Loading",
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "页面名称", prop: "name", width: "240" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "line1" }, [
                            _vm._v(_vm._s(scope.row.name)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "站内链接", width: "260" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "color-gray line1" }, [
                            _vm._v(
                              "/pages/home/home?id=" + _vm._s(scope.row.id)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "站外推广", width: "450" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c line1" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "color-black",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createQRCode(scope.row.id, 1)
                                    },
                                  },
                                },
                                [_vm._v("小程序码")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "btn-shu" }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "color-black",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createQRCode(scope.row.id, 2)
                                    },
                                  },
                                },
                                [_vm._v("小程序链接")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "btn-shu" }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "color-black",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createQRCode(scope.row.id, 3)
                                    },
                                  },
                                },
                                [_vm._v("H5二维码")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "btn-shu" }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "color-black",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createQRCode(scope.row.id, 4)
                                    },
                                  },
                                },
                                [_vm._v("H5链接")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发布状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.is_release !== 1
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "cur",
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    effect: "plain",
                                  },
                                },
                                [_vm._v("\n              未发布\n            ")]
                              )
                            : _c(
                                "el-tag",
                                {
                                  staticClass: "cur",
                                  attrs: {
                                    type: "info",
                                    size: "mini",
                                    effect: "plain",
                                  },
                                },
                                [_vm._v("\n              已发布\n            ")]
                              ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "266", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "merchantFitmentDiypageUpdate",
                                  expression: "'merchantFitmentDiypageUpdate'",
                                },
                              ],
                              attrs: {
                                type: "text",
                                icon: "el-icon-edit-outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editDiy(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          [
                            scope.row.is_release !== 1
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "merchantFitmentDiypageCreate",
                                        expression:
                                          "'merchantFitmentDiypageCreate'",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-position",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.sendAction(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("发布")]
                                )
                              : _vm._e(),
                          ],
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "merchantFitmentDiypageCopy",
                                  expression: "'merchantFitmentDiypageCopy'",
                                },
                              ],
                              attrs: {
                                type: "text",
                                icon: "el-icon-document-copy",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.copyAction(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("复制")]
                          ),
                          _vm._v(" "),
                          scope.row.status != 1 && scope.row.status != 2
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: "merchantFitmentDiypageDelete",
                                      expression:
                                        "'merchantFitmentDiypageDelete'",
                                    },
                                  ],
                                  staticClass: "danger",
                                  staticStyle: { color: "red !important" },
                                  attrs: {
                                    icon: "el-icon-delete",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delDiy(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: { "current-change": _vm.pageChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }