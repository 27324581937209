var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "商品信息",
            visible: _vm.dialogVisible,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "divBox" },
            [
              _c("div", { staticClass: "header clearfix" }, [
                _c("div", { staticClass: "filter-container" }, [
                  _c(
                    "div",
                    { staticClass: "demo-input-suffix acea-row" },
                    [
                      _c("span", { staticClass: "seachTiele" }, [
                        _vm._v("商品分类："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth mr20",
                          attrs: { placeholder: "请选择", clearable: "" },
                          on: {
                            change: function ($event) {
                              return _vm.getList()
                            },
                          },
                          model: {
                            value: _vm.tableFrom.mer_cate_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "mer_cate_id", $$v)
                            },
                            expression: "tableFrom.mer_cate_id",
                          },
                        },
                        _vm._l(_vm.merCateList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "seachTiele" }, [
                        _vm._v("商品搜索："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth",
                          attrs: {
                            placeholder: "请输入商品名称，关键字，产品编号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tableFrom.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "keyword", $$v)
                            },
                            expression: "tableFrom.keyword",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.getList },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData.data, size: "mini" },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "55" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: scope.row.product_id },
                                  nativeOn: {
                                    change: function ($event) {
                                      return _vm.getTemplateRow(scope.row)
                                    },
                                  },
                                  model: {
                                    value: _vm.templateRadio,
                                    callback: function ($$v) {
                                      _vm.templateRadio = $$v
                                    },
                                    expression: "templateRadio",
                                  },
                                },
                                [_vm._v(" ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3465899556
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "product_id",
                      label: "ID",
                      "min-width": "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "商品图", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "36px",
                                      height: "36px",
                                    },
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2331550732
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "store_name",
                      label: "商品名称",
                      "min-width": "200",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block mb20" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }