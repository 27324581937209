import { render, staticRenderFns } from "./searchAlbum.vue?vue&type=template&id=3a952542&scoped=true"
import script from "./searchAlbum.vue?vue&type=script&scoped=true&lang=js"
export * from "./searchAlbum.vue?vue&type=script&scoped=true&lang=js"
import style0 from "./searchAlbum.vue?vue&type=style&index=0&id=3a952542&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a952542",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a952542')) {
      api.createRecord('3a952542', component.options)
    } else {
      api.reload('3a952542', component.options)
    }
    module.hot.accept("./searchAlbum.vue?vue&type=template&id=3a952542&scoped=true", function () {
      api.rerender('3a952542', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/searchDataView/searchAlbum.vue"
export default component.exports