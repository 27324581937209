"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "BlindBoxCategory",
  data: function data() {
    return {
      tableData: {
        data: [],
        loading: false,
        indexNum: 0
      },
      listLoading: true,
      previewSrcList: [],
      form: {
        title: "",
        sort: ""
      },
      rules: {
        title: [{
          required: true,
          message: "请输入名称",
          trigger: "blur"
        }],
        sort: [{
          required: false,
          message: "请输入排序",
          trigger: "blur"
        }]
      },
      editflag: false
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _product.blindboxSeriesListApi)().then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableData.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableData.limit = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd(id) {
      this.editflag = true;
      this.newrow = {};
      this.form = {
        title: "",
        sort: ""
      };
    },
    // 编辑
    onEdit: function onEdit(row) {
      this.editflag = true;
      var newrow = JSON.parse(JSON.stringify(row));
      this.form = newrow;
    },
    onsave: function onsave() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this3.form.album_id) {
            (0, _product.blindboxSeriesUpdateApi)(_this3.form.album_id, _this3.form).then(function (_ref) {
              var message = _ref.message;
              _this3.$message.success(message);
              _this3.editflag = false;
              _this3.getList();
            }).catch(function (_ref2) {
              var message = _ref2.message;
              _this3.$message.error(message);
            });
          } else {
            (0, _product.blindboxSeriesCreateApi)(_this3.form).then(function (_ref3) {
              var message = _ref3.message;
              _this3.$message.success(message);
              _this3.editflag = false;
              _this3.getList();
            }).catch(function (_ref4) {
              var message = _ref4.message;
              _this3.$message.error(message);
            });
          }
        }
      });
    },
    concatDiyDetail: function concatDiyDetail(row) {
      this.$router.push({
        path: "/merchant/blindAlbumControl/page?type=blind_page&newtype=album&id=" + row.album_id,
        params: {
          id: row.id
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        (0, _product.blindboxSeriesDeleteApi)(id).then(function (_ref5) {
          var message = _ref5.message;
          _this4.$message.success(message);
          _this4.getList();
        }).catch(function (_ref6) {
          var message = _ref6.message;
        });
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          _this.form.level_image = img[0];
        }
      }, tit);
    }
  }
};