var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "查看详情",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "uid", label: "ID", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nickname",
                  label: "用户名称",
                  "min-width": "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.is_initiator == 1
                            ? _c("span", { staticClass: "initiator" }, [
                                _vm._v("团长"),
                              ])
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(scope.row.nickname))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1665894273
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户头像", "min-width": "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.avatar
                            ? _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "36px",
                                      height: "36px",
                                    },
                                    attrs: {
                                      src: scope.row.avatar,
                                      "preview-src-list": [scope.row.avatar],
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("img", {
                                staticStyle: {
                                  width: "36px",
                                  height: "36px",
                                  "vertical-align": "top",
                                },
                                attrs: {
                                  src: require("../../../../assets/images/f.png"),
                                  alt: "",
                                },
                              }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3128291785
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单编号", "min-width": "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.orderInfo &&
                                    scope.row.orderInfo.order_sn
                                )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2124253839
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "金额", "min-width": "90" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.orderInfo &&
                                    scope.row.orderInfo.pay_price
                                )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2324806425
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态", "min-width": "90" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.orderInfo &&
                          scope.row.orderInfo.status == -1
                            ? _c("span", { staticClass: "refuned" }, [
                                _vm._v(" 已退款"),
                              ])
                            : _c("span", { staticClass: "unrefuned" }, [
                                _vm._v("未退款"),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  726021529
                ),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }