"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "planeDetail",
  components: {
    Nft: _nft.default
  },
  data: function data() {
    return {
      formValidate: {
        aircraft_name: "",
        aircraft_img: "",
        success_rate: 0,
        planet_img: "",
        planet_name: "",
        durable: "",
        duration: ""
      },
      ruleValidate: {
        aircraft_img: [{
          required: true,
          message: "请选择图片"
        }],
        aircraft_name: [{
          required: true,
          message: "请输入战机名称",
          trigger: "blur"
        }],
        planet_img: [{
          required: true,
          message: "请选择图片"
        }],
        planet_name: [{
          required: true,
          message: "请输入星球名称",
          trigger: "blur"
        }],
        durable: [{
          required: true,
          message: "请输入耐久度",
          trigger: "blur"
        }],
        success_rate: [{
          required: true,
          message: "请输入成功率",
          trigger: "blur"
        }],
        duration: [{
          required: true,
          message: "请输入挖矿时长",
          trigger: "blur"
        }]
      },
      output: [{
        image: '',
        name: '',
        stock: '',
        rate: '100',
        num: 1
      }],
      outtype: '1',
      condition: [{
        image: '',
        name: '',
        neednum: ''
      }],
      conditiontype: '1',
      durability: [{
        image: '',
        name: '',
        neednum: ''
      }],
      durabilitytype: '1'
    };
  },
  created: function created() {
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.getPlaneDetail();
    }
    // getPlaneDetail()
  },
  methods: {
    getPlaneDetail: function getPlaneDetail() {
      var _this2 = this;
      (0, _marketing.getPlaneDetail)({
        aircraft_id: this.id
      }).then(function (res) {
        console.log(res);
        _this2.formValidate = {
          aircraft_name: res.data.aircraft_name,
          aircraft_img: res.data.aircraft_img,
          success_rate: res.data.success_rate,
          planet_img: res.data.planet_img,
          planet_name: res.data.planet_name,
          durable: res.data.durable,
          duration: res.data.duration
        };
        if (res.data.output) {
          _this2.outtype = res.data.output.type + '';
          _this2.output = res.data.output.data.map(function (item) {
            return {
              image: item.material_image,
              name: item.material_name,
              stock: '',
              rate: item.output_rate,
              num: item.num,
              id: item.material_id
            };
          });
        }
        if (res.data.exchange_conditions) {
          _this2.conditiontype = res.data.exchange_conditions.type + '';
          _this2.condition = res.data.exchange_conditions.data.map(function (item) {
            return {
              image: item.material_image,
              name: item.material_name,
              neednum: item.num,
              id: item.material_id
            };
          });
        }
        if (res.data.add_durable) {
          _this2.durabilitytype = res.data.add_durable.type + '';
          _this2.durability = res.data.add_durable.data.map(function (item) {
            return {
              image: item.material_image,
              name: item.material_name,
              neednum: item.num,
              id: item.material_id
            };
          });
        }
      });
    },
    submitForm: function submitForm() {
      var _this3 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          var flag = false;
          _this3.output.forEach(function (item, index) {
            if (!item.id) {
              flag = true;
            }
          });
          if (flag) {
            return _this3.$message.error('请完善产出材料或者删除无用的条件');
          }
          _this3.condition.forEach(function (item, index) {
            if (!item.id) {
              flag = true;
            }
          });
          if (flag) {
            return _this3.$message.error('请完善兑换条件或者删除无用的条件');
          }
          var json = JSON.parse(JSON.stringify(_this3.formValidate));
          json.output = {
            type: _this3.outtype,
            data: []
          };
          _this3.output.forEach(function (item, index) {
            if (item.id) {
              json.output.data.push({
                material_id: item.id,
                num: item.num,
                output_rate: item.rate
              });
            }
          });
          json.exchange_conditions = {
            type: _this3.conditiontype,
            data: []
          };
          _this3.condition.forEach(function (item, index) {
            if (item.id) {
              json.exchange_conditions.data.push({
                material_id: item.id,
                num: item.neednum || 1
              });
            }
          });
          json.add_durable = {
            type: _this3.durabilitytype,
            data: []
          };
          _this3.durability.forEach(function (item, index) {
            if (item.id) {
              json.add_durable.data.push({
                material_id: item.id,
                num: item.neednum || 1
              });
            }
          });
          if (_this3.id) {
            json.aircraft_id = _this3.id;
            (0, _marketing.editPlane)(json).then(function () {
              _this3.$message.success('创建成功');
              _this3.$router.back();
            });
            return;
          }
          (0, _marketing.createPlane)(json).then(function (res) {
            console.log(res);
            _this3.$message.success('创建成功');
            _this3.$router.back();
          });
        } else {
          _this3.$message.error('请完善信息');
        }
      });
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      var product = data.data[0];
      if (this.changeType == "output") {
        this.output[this.changeIndex].image = product.image;
        this.output[this.changeIndex].name = product.name;
        this.output[this.changeIndex].stock = product.stock;
        this.output[this.changeIndex].id = product.id;
      } else if (this.changeType == "condition") {
        this.condition[this.changeIndex].image = product.image;
        this.condition[this.changeIndex].name = product.name;
        this.condition[this.changeIndex].id = product.id;
      } else if (this.changeType == "durability") {
        this.durability[this.changeIndex].image = product.image;
        this.durability[this.changeIndex].name = product.name;
        this.durability[this.changeIndex].id = product.id;
      }
    },
    changerate: function changerate() {
      if (this.output.length > 1) {
        var rate = 100;
        this.output.forEach(function (item, index) {
          if (index > 0) {
            rate = rate - Number(item.rate);
          }
        });
        this.output[0].rate = rate;
      } else {
        this.output[0].rate = 100;
      }
    },
    addlist: function addlist(type) {
      if (type == 'output') {
        this.output.push({
          image: '',
          name: '',
          stock: '',
          rate: ''
        });
      } else if (type == 'condition') {
        this.condition.push({
          image: '',
          name: '',
          neednum: ''
        });
      } else if (type == 'durability') {
        this.durability.push({
          image: '',
          name: '',
          neednum: ''
        });
      }
    },
    dellist: function dellist(type, index) {
      if (type == 'output') {
        this.output.splice(index, 1);
      } else if (type == 'condition') {
        this.condition.splice(index, 1);
        this.changerate();
      } else if (type == 'durability') {
        this.durability.splice(index, 1);
      }
    },
    handleAdditem: function handleAdditem(type, i) {
      this.changeType = type;
      this.changeIndex = i;
      this.$refs.product_nft.changeshow();
    },
    // 修改图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          console.log(img);
          _this.formValidate[num] = img[0];
          _this.$refs.formValidate.clearValidate();
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = '';
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};