import { render, staticRenderFns } from "./goods.vue?vue&type=template&id=0cf8f1a7&scoped=true"
import script from "./goods.vue?vue&type=script&lang=js"
export * from "./goods.vue?vue&type=script&lang=js"
import style0 from "./goods.vue?vue&type=style&index=0&id=0cf8f1a7&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf8f1a7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0cf8f1a7')) {
      api.createRecord('0cf8f1a7', component.options)
    } else {
      api.reload('0cf8f1a7', component.options)
    }
    module.hot.accept("./goods.vue?vue&type=template&id=0cf8f1a7&scoped=true", function () {
      api.rerender('0cf8f1a7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/customChildren/goods.vue"
export default component.exports