exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#708294",
	"menuActiveText": "#334456",
	"subMenuActiveText": "#4781f5",
	"menuBg": "#fff",
	"menuHover": "#fff",
	"subMenuBg": "#fff",
	"subMenuHover": "#fff",
	"sideBarWidth": "210px"
};