var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "code-v" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称：", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "名称" },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生成方式：", prop: "create_type" } },
                    [
                      _c(
                        "div",
                        { staticClass: "align-items-c" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.create_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "create_type", $$v)
                                },
                                expression: "ruleForm.create_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("自动生成"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("导入兑换码"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.create_type == 1
                ? _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "兑换码数量：", prop: "total_num" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                attrs: { placeholder: "数量" },
                                model: {
                                  value: _vm.ruleForm.total_num,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "total_num", $$v)
                                  },
                                  expression: "ruleForm.total_num",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.create_type == 2
                ? _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "total_num" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "uploadImg",
                                  staticClass: "upload-demo mr10 mb15",
                                  staticStyle: { display: "inline-block" },
                                  attrs: {
                                    limit: 1,
                                    action: _vm.fileUrl,
                                    "on-success": _vm.handleSuccess,
                                    headers: _vm.myHeaders,
                                    data: { import_type: "redemption_code" },
                                    "show-file-list": true,
                                    name: "excel",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [_vm._v("导入")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "text" },
                                  on: { click: _vm.downLoad },
                                },
                                [_vm._v("下载导入模版")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(_vm.failTextContent))]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期：", prop: "date" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "range-separator": "至",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.ruleForm.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "date", $$v)
                              },
                              expression: "ruleForm.date",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "兑换设置：" } }, [
                _c(
                  "div",
                  { staticClass: "tab" },
                  [
                    _c("div", { staticClass: "tr flex align-items-c" }, [
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "10%" } },
                        [_vm._v("类型")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "10%" } },
                        [_vm._v("图片")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "th",
                          staticStyle: { width: "45%", "text-align": "left" },
                        },
                        [_vm._v("名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "th",
                          staticStyle: { width: "15%", "text-align": "left" },
                        },
                        [_vm._v("\n              预留库存\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "20%" } },
                        [_vm._v("操作")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.buy_product_data, function (m, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "tr flex align-items-c" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "10%" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.editBuyTypeAction(i)
                                    },
                                  },
                                  model: {
                                    value: m.type,
                                    callback: function ($$v) {
                                      _vm.$set(m, "type", $$v)
                                    },
                                    expression: "m.type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      value: "product_nft",
                                      label: "数字藏品",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: {
                                      value: "product_nft_blind",
                                      label: "数字盲盒",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td item-flex-center",
                              staticStyle: { width: "10%" },
                            },
                            [
                              m.image
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "upload-image flex align-items-c justify-c",
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeImg(i)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: m.image + "!120a",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "upload-image flex align-items-c justify-c",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture",
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: {
                                width: "45%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(m.name || "") +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: {
                                width: "15%",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(m.reserved_number || "") +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "20%" },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleBuyAdditem(m.type, i)
                                    },
                                  },
                                },
                                [_vm._v("选择")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delBuyAction(i)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tr flex align-items-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "td",
                          staticStyle: { width: "100%", "text-align": "left" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.addBuyAction },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("el-form-item", { attrs: { label: "", prop: "type" } }, [
                    _c(
                      "div",
                      { staticClass: "flex align-items-c" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.create("ruleForm")
                              },
                            },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Productblind", {
        ref: "blind_box",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "错误原因",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.failTextContent))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }