var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "页面基本配置", is_tab: false } },
    [
      _vm.facade.show
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "80px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "标题名称" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        placeholder: "自定义标题",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.facade.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title", $$v)
                        },
                        expression: "facade.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "背景颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#f7f7f7" },
                      model: {
                        value: _vm.facade.background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "background", $$v)
                        },
                        expression: "facade.background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("背景图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.bg_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.bg_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "背景图设置" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.facade.bg_image_mode,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bg_image_mode", $$v)
                          },
                          expression: "facade.bg_image_mode",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("宽度固定上下居中"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("满屏上下左右截取"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("宽度固定上下平铺循环展示"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("宽度固定高度自适应顶部展示"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 5 } }, [
                              _vm._v("宽度固定高度自适应底部展示"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("音乐播放图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "audio_play_image")
                        },
                      },
                    },
                    [
                      _vm.facade.audio_play_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("audio_play_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.audio_play_image
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.audio_play_image + "!300",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("音乐暂停图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "audio_pause_image")
                        },
                      },
                    },
                    [
                      _vm.facade.audio_pause_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("audio_pause_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.audio_pause_image
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.audio_pause_image + "!300",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("客服图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "contact_image")
                        },
                      },
                    },
                    [
                      _vm.facade.contact_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("contact_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.contact_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.contact_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("优惠券图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "coupon_image")
                        },
                      },
                    },
                    [
                      _vm.facade.coupon_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("coupon_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.coupon_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.coupon_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("免费玩图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "free_image")
                        },
                      },
                    },
                    [
                      _vm.facade.free_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("free_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.free_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.free_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("开盒图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "open_image")
                        },
                      },
                    },
                    [
                      _vm.facade.open_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("open_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.open_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.open_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("我的盒子图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "my_box_image")
                        },
                      },
                    },
                    [
                      _vm.facade.my_box_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("my_box_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.my_box_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.my_box_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("指示图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "point_image")
                        },
                      },
                    },
                    [
                      _vm.facade.point_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("point_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.point_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.point_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("重抽卡图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "refause_image")
                        },
                      },
                    },
                    [
                      _vm.facade.refause_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("refause_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.refause_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.refause_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("玩法图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "tricks_image")
                        },
                      },
                    },
                    [
                      _vm.facade.tricks_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("tricks_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.tricks_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.tricks_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("省钱价格背景图"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "price_bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.price_bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("price_bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.price_bg_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.price_bg_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("查看宝贝图"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "watch_image")
                        },
                      },
                    },
                    [
                      _vm.facade.watch_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("watch_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.watch_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.watch_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("左箭头图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "left_image")
                        },
                      },
                    },
                    [
                      _vm.facade.left_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("left_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.left_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.left_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("右箭头图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "right_image")
                        },
                      },
                    },
                    [
                      _vm.facade.right_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("right_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.right_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.right_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标签宽度" } },
                  [
                    _c("slider", {
                      attrs: { min: 190, max: 500 },
                      model: {
                        value: _vm.facade.item_width,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_width", $$v)
                        },
                        expression: "facade.item_width",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "标签边框" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#E300FF" },
                      model: {
                        value: _vm.facade.item_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "item_color", $$v)
                        },
                        expression: "facade.item_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "文字颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#E300FF" },
                      model: {
                        value: _vm.facade.title_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_color", $$v)
                        },
                        expression: "facade.title_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("当前价格背景图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "current_price_image")
                        },
                      },
                    },
                    [
                      _vm.facade.current_price_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("current_price_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.current_price_image
                        ? _c("img", {
                            attrs: {
                              src: _vm.facade.current_price_image + "!300",
                            },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "当前价格颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#E300FF" },
                      model: {
                        value: _vm.facade.current_price_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "current_price_color", $$v)
                        },
                        expression: "facade.current_price_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "当前价格边框颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#E300FF" },
                      model: {
                        value: _vm.facade.current_price_border_color,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.facade,
                            "current_price_border_color",
                            $$v
                          )
                        },
                        expression: "facade.current_price_border_color",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "底部字号大小" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 50 },
                      model: {
                        value: _vm.facade.bottom_size,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "bottom_size", $$v)
                        },
                        expression: "facade.bottom_size",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }