var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("span", { staticStyle: { "font-size": "20px" } }, [
                _vm._v(_vm._s(this.id ? "编辑" : "新建") + "调查问券"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default-box" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "ivu-form-item-required",
                      attrs: { label: "问券标题：" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "w200",
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "default-box" }, [
            _c("div", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("设置问题"),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.questionList, function (m, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "default-box",
                class: index == 0 ? " m0" : "",
              },
              [
                _c(
                  "el-form",
                  { attrs: { "label-width": "120px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "题目：" } },
                      [
                        _c("el-input", {
                          staticClass: "w400",
                          model: {
                            value: m.content,
                            callback: function ($$v) {
                              _vm.$set(m, "content", $$v)
                            },
                            expression: "m.content",
                          },
                        }),
                        _vm._v(" "),
                        index != 0
                          ? _c(
                              "a",
                              {
                                staticClass: "ml20",
                                staticStyle: { color: "#409eff" },
                                on: {
                                  click: function ($event) {
                                    return _vm.upQuestion(index)
                                  },
                                },
                              },
                              [_vm._v("上移")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注：" } },
                      [
                        _c("el-input", {
                          staticClass: "w400",
                          model: {
                            value: m.description,
                            callback: function ($$v) {
                              _vm.$set(m, "description", $$v)
                            },
                            expression: "m.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w200",
                            model: {
                              value: m.question_type,
                              callback: function ($$v) {
                                _vm.$set(m, "question_type", $$v)
                              },
                              expression: "m.question_type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "1", label: "单选" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "2", label: "多选" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: "3", label: "问答" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "ml20",
                            attrs: { "true-label": 1, "false-label": 0 },
                            model: {
                              value: m.is_require,
                              callback: function ($$v) {
                                _vm.$set(m, "is_require", $$v)
                              },
                              expression: "m.is_require",
                            },
                          },
                          [_vm._v("必填")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(m.optionList, function (item, i) {
                      return m.question_type != 3
                        ? _c("el-form-item", { key: index + "_" + i }, [
                            _c(
                              "div",
                              { staticClass: "flex align-items-c" },
                              [
                                _c("el-input", {
                                  staticClass: "w200",
                                  model: {
                                    value: item.content,
                                    callback: function ($$v) {
                                      _vm.$set(item, "content", $$v)
                                    },
                                    expression: "item.content",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "ml20" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.idEditAllowed(index, i)
                                          },
                                        },
                                        model: {
                                          value: item.is_input_allowed,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "is_input_allowed",
                                              $$v
                                            )
                                          },
                                          expression: "item.is_input_allowed",
                                        },
                                      },
                                      [_vm._v("用户输入")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "ml20",
                                    staticStyle: { color: "#409eff" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delAwardsAction(index, i)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                                _vm._v(" "),
                                i != 0
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "ml20",
                                        staticStyle: { color: "#409eff" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.upAwardsAction(index, i)
                                          },
                                        },
                                      },
                                      [_vm._v("上移")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    m.question_type != 3
                      ? _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "w200",
                                on: {
                                  click: function ($event) {
                                    return _vm.addOption(index)
                                  },
                                },
                              },
                              [_vm._v("新建选项")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { long: "" }, on: { click: _vm.createQuestion } },
            [_vm._v("新建问题")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "mt20",
              attrs: { long: "", type: "primary" },
              on: { click: _vm.saveAsk },
            },
            [_vm._v("保存")]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }