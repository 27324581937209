var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "item flex align-items-c",
            staticStyle: { "padding-top": "0" },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "flex align-items-c" }, [
              _vm.ruleData.popup_img
                ? _c("div", { staticClass: "img-list" }, [
                    _c("img", {
                      attrs: { src: _vm.ruleData.popup_img + "!120a" },
                      on: {
                        click: function ($event) {
                          return _vm.showImageAction(_vm.ruleData.popup_img)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "del",
                        on: {
                          click: function ($event) {
                            return _vm.delImgAction("popup_img")
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-close" })]
                    ),
                  ])
                : _c(
                    "div",
                    {
                      staticClass: "upload-image flex align-items-c justify-c",
                      on: {
                        click: function ($event) {
                          return _vm.changeImg("popup_img")
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-picture" })]
                  ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c" }, [
            _vm.ruleData.popup_retract_img
              ? _c("div", { staticClass: "img-list" }, [
                  _c("img", {
                    attrs: { src: _vm.ruleData.popup_retract_img + "!120a" },
                    on: {
                      click: function ($event) {
                        return _vm.showImageAction(
                          _vm.ruleData.popup_retract_img
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "del",
                      on: {
                        click: function ($event) {
                          return _vm.delImgAction("popup_retract_img")
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-close" })]
                  ),
                ])
              : _c(
                  "div",
                  {
                    staticClass: "upload-image flex align-items-c justify-c",
                    on: {
                      click: function ($event) {
                        return _vm.changeImg("popup_retract_img")
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-picture" })]
                ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.ruleData.show_QRccode,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "show_QRccode", $$v)
                    },
                    expression: "ruleData.show_QRccode",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "" } }, [_vm._v("不弹出")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("提示关注")]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _c("div", { staticClass: "flex-one" }, [
            _vm._v("3D作品购买前可体验"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ccc",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.ruleData.buy_before_experience,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "buy_before_experience", $$v)
                  },
                  expression: "ruleData.buy_before_experience",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ccc",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.ruleData.no_show_circulation,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "no_show_circulation", $$v)
                  },
                  expression: "ruleData.no_show_circulation",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item flex align-items-c h50" }, [
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-input", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ccc",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.ruleData.nft_additional_mark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "nft_additional_mark", $$v)
                  },
                  expression: "ruleData.nft_additional_mark",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "primary" },
              on: { click: _vm.saveAction },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imagesKey + "!m640" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        我的藏品弹出图片"),
      _c("span", [
        _vm._v(
          "（我的藏品页面，当有藏品持仓时，弹出此图片。图片格式支持：JPG、JPEG、PNG、GIF）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        我的藏品浮动小图"),
      _c("span", [
        _vm._v(
          "（我的藏品页面，当有藏品持仓时，关闭弹出图片后，右下角浮动小图。图片格式支持：JPG、JPEG、PNG、GIF）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        公众号弹出配置"),
      _c("span", [
        _vm._v(
          "（未关注公众号用户弹出二维码）（提示关注：可关闭关注弹窗，关闭后一天内不展示）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        不展示流转信息"),
      _c("span", [
        _vm._v(
          "（打开后我的藏品详情不展示区块链流转信息、我的藏品详情哈希值、查看证书按钮）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("\n        增发藏品后缀标识"),
      _c("span", [
        _vm._v(
          "（默认例：SPO1-100，可自定义‘SPO’部分的展示，最长三个英文字符）"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }