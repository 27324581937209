"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = [{
  "headquartersName": "阿拉尔津汇村镇银行",
  "headquartersShortName": "阿拉尔津汇村镇银行",
  "headquartersNo": "ALEJHCUNZB"
}, {
  "headquartersName": "阿拉善农村商业银行",
  "headquartersShortName": "阿拉善农商",
  "headquartersNo": "ALSRCB"
}, {
  "headquartersName": "阿拉善左旗方大村镇银行",
  "headquartersShortName": "阿拉善左旗方大村镇银行",
  "headquartersNo": "ALSZQFDCUNZB"
}, {
  "headquartersName": "阿拉善左旗黄河村镇银行",
  "headquartersShortName": "阿拉善左旗黄河村镇银行",
  "headquartersNo": "ALSZQHHCUNZB"
}, {
  "headquartersName": "埃及国民银行",
  "headquartersShortName": "埃及国民",
  "headquartersNo": "NBE"
}, {
  "headquartersName": "安达幸福村镇银行",
  "headquartersShortName": "安达幸福村镇银行",
  "headquartersNo": "ADXFCUNZB"
}, {
  "headquartersName": "安国中成村镇银行",
  "headquartersShortName": "安国中成村镇银行",
  "headquartersNo": "AGZCCUNZB"
}, {
  "headquartersName": "安徽巢湖扬子村镇银行",
  "headquartersShortName": "安徽巢湖扬子村镇银行",
  "headquartersNo": "AHCHYZCUNZB"
}, {
  "headquartersName": "安徽枞阳农村商业银行",
  "headquartersShortName": "安徽枞阳农商",
  "headquartersNo": "AHZYRCB"
}, {
  "headquartersName": "安徽当涂新华村镇银行",
  "headquartersShortName": "安徽当涂新华村镇银行",
  "headquartersNo": "AHDTXHCUNZB"
}, {
  "headquartersName": "安徽砀山农村商业银行",
  "headquartersShortName": "安徽砀山农商",
  "headquartersNo": "AHDSRCB"
}, {
  "headquartersName": "安徽定远民丰村镇银行",
  "headquartersShortName": "安徽定远民丰村镇银行",
  "headquartersNo": "AHDYMFCUNZB"
}, {
  "headquartersName": "安徽定远农村商业银行",
  "headquartersShortName": "安徽定远农商",
  "headquartersNo": "AHDYRCB"
}, {
  "headquartersName": "安徽东至农村商业银行",
  "headquartersShortName": "安徽东至农商",
  "headquartersNo": "AHDZRCB"
}, {
  "headquartersName": "安徽东至扬子村镇银行",
  "headquartersShortName": "安徽东至扬子村镇银行",
  "headquartersNo": "AHDZYZCUNZB"
}, {
  "headquartersName": "安徽繁昌农村商业银行",
  "headquartersShortName": "安徽繁昌农商",
  "headquartersNo": "AHFCRCB"
}, {
  "headquartersName": "安徽肥东湖商村镇银行",
  "headquartersShortName": "安徽肥东湖商村镇银行",
  "headquartersNo": "AHFDHSCUNZB"
}, {
  "headquartersName": "安徽肥东农村商业银行",
  "headquartersShortName": "安徽肥东农商",
  "headquartersNo": "AHFDRCB"
}, {
  "headquartersName": "安徽肥西农村商业银行",
  "headquartersShortName": "安徽肥西农商",
  "headquartersNo": "AHFXRCB"
}, {
  "headquartersName": "安徽肥西石银村镇银行",
  "headquartersShortName": "安徽肥西石银村镇银行",
  "headquartersNo": "AHFXSYCUNZB"
}, {
  "headquartersName": "安徽凤台农村商业银行",
  "headquartersShortName": "安徽凤台农商",
  "headquartersNo": "AHFTRCB"
}, {
  "headquartersName": "安徽凤台通商村镇银行",
  "headquartersShortName": "安徽凤台通商村镇银行",
  "headquartersNo": "AHFTTSCUNZB"
}, {
  "headquartersName": "安徽凤阳利民村镇银行",
  "headquartersShortName": "安徽凤阳利民村镇银行",
  "headquartersNo": "AHFYLMCUNZB"
}, {
  "headquartersName": "安徽凤阳农村商业银行",
  "headquartersShortName": "安徽凤阳农商",
  "headquartersNo": "AHFYRCB"
}, {
  "headquartersName": "安徽阜南农村商业银行",
  "headquartersShortName": "安徽阜南农商",
  "headquartersNo": "AHFNRCB"
}, {
  "headquartersName": "安徽固镇农村商业银行",
  "headquartersShortName": "安徽固镇农商",
  "headquartersNo": "AHGZRCB"
}, {
  "headquartersName": "安徽固镇新淮河村镇银行",
  "headquartersShortName": "安徽固镇新淮河村镇银行",
  "headquartersNo": "AHGZXHCUNZB"
}, {
  "headquartersName": "安徽广德农村商业银行",
  "headquartersShortName": "安徽广德农商",
  "headquartersNo": "AHGDRCB"
}, {
  "headquartersName": "安徽广德扬子村镇银行",
  "headquartersShortName": "安徽广德扬子村镇银行",
  "headquartersNo": "AHGDYZCUNZB"
}, {
  "headquartersName": "安徽含山农村商业银行",
  "headquartersShortName": "安徽含山农商",
  "headquartersNo": "AHHSRCB"
}, {
  "headquartersName": "安徽和县农村商业银行",
  "headquartersShortName": "安徽和县农商",
  "headquartersNo": "AHHXRCB"
}, {
  "headquartersName": "安徽和县新华村镇银行",
  "headquartersShortName": "安徽和县新华村镇银行",
  "headquartersNo": "AHHXXHCUNZB"
}, {
  "headquartersName": "安徽怀宁江淮村镇银行",
  "headquartersShortName": "安徽怀宁江淮村镇银行",
  "headquartersNo": "AHHNJHCUNZB"
}, {
  "headquartersName": "安徽怀宁农村商业银行",
  "headquartersShortName": "安徽怀宁农商",
  "headquartersNo": "AHHNRCB"
}, {
  "headquartersName": "安徽怀远本富村镇银行",
  "headquartersShortName": "安徽怀远本富村镇银行",
  "headquartersNo": "AHHYBFCUNZB"
}, {
  "headquartersName": "安徽怀远农村商业银行",
  "headquartersShortName": "安徽怀远农商",
  "headquartersNo": "AHHYRCB"
}, {
  "headquartersName": "安徽黄山金桥村镇银行",
  "headquartersShortName": "安徽黄山金桥村镇银行",
  "headquartersNo": "AHHSJQCUNZB"
}, {
  "headquartersName": "安徽徽州铜源村镇银行",
  "headquartersShortName": "安徽徽州铜源村镇银行",
  "headquartersNo": "AHHZTYCUNZB"
}, {
  "headquartersName": "安徽霍邱联合村镇银行",
  "headquartersShortName": "安徽霍邱联合村镇银行",
  "headquartersNo": "AHHQLHCUNZB"
}, {
  "headquartersName": "安徽霍邱农村商业银行",
  "headquartersShortName": "安徽霍邱农商",
  "headquartersNo": "AHQHRCB"
}, {
  "headquartersName": "安徽霍山联合村镇银行",
  "headquartersShortName": "安徽霍山联合村镇银行",
  "headquartersNo": "AHHSLHCUNZB"
}, {
  "headquartersName": "安徽霍山农村商业银行",
  "headquartersShortName": "安徽霍山农商",
  "headquartersNo": "AHHUOSRCB"
}, {
  "headquartersName": "安徽绩溪农村商业银行",
  "headquartersShortName": "安徽绩溪农商",
  "headquartersNo": "AHJXIRCB"
}, {
  "headquartersName": "安徽界首农村商业银行",
  "headquartersShortName": "安徽界首农商",
  "headquartersNo": "AHJSRCB"
}, {
  "headquartersName": "安徽金寨江淮村镇银行",
  "headquartersShortName": "安徽金寨江淮村镇银行",
  "headquartersNo": "AHJZJHCUNZB"
}, {
  "headquartersName": "安徽金寨农村商业银行",
  "headquartersShortName": "安徽金寨农商",
  "headquartersNo": "AHJZRCB"
}, {
  "headquartersName": "安徽泾县农村商业银行",
  "headquartersShortName": "安徽泾县农商",
  "headquartersNo": "AHJXRCB"
}, {
  "headquartersName": "安徽泾县铜源村镇银行",
  "headquartersShortName": "安徽泾县铜源村镇银行",
  "headquartersNo": "AHZXTYCUNZB"
}, {
  "headquartersName": "安徽旌德农村商业银行",
  "headquartersShortName": "安徽旌德农商",
  "headquartersNo": "AHJDRCB"
}, {
  "headquartersName": "安徽来安农村商业银行",
  "headquartersShortName": "安徽来安农商",
  "headquartersNo": "AHLARCB"
}, {
  "headquartersName": "安徽郎溪农村商业银行",
  "headquartersShortName": "安徽郎溪农商",
  "headquartersNo": "AHLXRCB"
}, {
  "headquartersName": "安徽郎溪新华村镇银行",
  "headquartersShortName": "安徽郎溪新华村镇银行",
  "headquartersNo": "AHLXXHCUNZB"
}, {
  "headquartersName": "安徽利辛湖商村镇银行",
  "headquartersShortName": "安徽利辛湖商村镇银行",
  "headquartersNo": "AHLXHSCUNZB"
}, {
  "headquartersName": "安徽利辛农村商业银行",
  "headquartersShortName": "安徽利辛农商",
  "headquartersNo": "AHLIXRCB"
}, {
  "headquartersName": "安徽临泉农村商业银行",
  "headquartersShortName": "安徽临泉农商",
  "headquartersNo": "AHLQRCB"
}, {
  "headquartersName": "安徽灵璧本富村镇银行",
  "headquartersShortName": "安徽灵璧本富村镇银行",
  "headquartersNo": "AHLZBFCUNZB"
}, {
  "headquartersName": "安徽灵璧农村商业银行",
  "headquartersShortName": "安徽灵璧农商",
  "headquartersNo": "AHLBRCB"
}, {
  "headquartersName": "安徽庐江农村商业银行",
  "headquartersShortName": "安徽庐江农商",
  "headquartersNo": "AHLJRCB"
}, {
  "headquartersName": "安徽马鞍山农村商业银行",
  "headquartersShortName": "安徽马鞍山农商",
  "headquartersNo": "AHMASRCB"
}, {
  "headquartersName": "安徽蒙城湖商村镇银行",
  "headquartersShortName": "安徽蒙城湖商村镇银行",
  "headquartersNo": "AHMCHSCUNZB"
}, {
  "headquartersName": "安徽蒙城农村商业银行",
  "headquartersShortName": "安徽蒙城农商",
  "headquartersNo": "AHMCRCB"
}, {
  "headquartersName": "安徽明光民丰村镇银行",
  "headquartersShortName": "安徽明光民丰村镇银行",
  "headquartersNo": "AHMGMFCUNZB"
}, {
  "headquartersName": "安徽明光农村商业银行",
  "headquartersShortName": "安徽明光农商",
  "headquartersNo": "AHMGRCB"
}, {
  "headquartersName": "安徽南陵农村商业银行",
  "headquartersShortName": "安徽南陵农商",
  "headquartersNo": "AHNLRCB"
}, {
  "headquartersName": "安徽宁国农村商业银行",
  "headquartersShortName": "安徽宁国农商",
  "headquartersNo": "AHNGRCB"
}, {
  "headquartersName": "安徽祁门农村商业银行",
  "headquartersShortName": "安徽祁门农商",
  "headquartersNo": "AHQMRCB"
}, {
  "headquartersName": "安徽祁门铜源村镇银行",
  "headquartersShortName": "安徽祁门铜源村镇银行",
  "headquartersNo": "AHQMTYCUNZB"
}, {
  "headquartersName": "安徽潜山江淮村镇银行",
  "headquartersShortName": "安徽潜山江淮村镇银行",
  "headquartersNo": "AHQSJHCUNZB"
}, {
  "headquartersName": "安徽潜山农村商业银行",
  "headquartersShortName": "安徽潜山农商",
  "headquartersNo": "AHQSRCB"
}, {
  "headquartersName": "安徽谯城湖商村镇银行",
  "headquartersShortName": "安徽谯城湖商村镇银行",
  "headquartersNo": "AHZCHSCUNZB"
}, {
  "headquartersName": "安徽青阳九华村镇银行",
  "headquartersShortName": "安徽青阳九华村镇银行",
  "headquartersNo": "AHQYJHCUNZB"
}, {
  "headquartersName": "安徽青阳农村商业银行",
  "headquartersShortName": "安徽青阳农商",
  "headquartersNo": "AHQYRCB"
}, {
  "headquartersName": "安徽全椒农村商业银行",
  "headquartersShortName": "安徽全椒农商",
  "headquartersNo": "AHQJRCB"
}, {
  "headquartersName": "安徽歙县嘉银村镇银行",
  "headquartersShortName": "安徽歙县嘉银村镇银行",
  "headquartersNo": "AHZXJYCUNZB"
}, {
  "headquartersName": "安徽歙县农村商业银行",
  "headquartersShortName": "安徽歙县农商",
  "headquartersNo": "AHXIXRCB"
}, {
  "headquartersName": "安徽省农村信用社联合社",
  "headquartersShortName": "安徽农信社",
  "headquartersNo": "AHNXS"
}, {
  "headquartersName": "安徽石台农村商业银行",
  "headquartersShortName": "安徽石台农商",
  "headquartersNo": "AHSTRCB"
}, {
  "headquartersName": "安徽石台扬子村镇银行",
  "headquartersShortName": "安徽石台扬子村镇银行",
  "headquartersNo": "AHSTYZCUNZB"
}, {
  "headquartersName": "安徽寿县联合村镇银行",
  "headquartersShortName": "安徽寿县联合村镇银行",
  "headquartersNo": "AHSXLHCUNZB"
}, {
  "headquartersName": "安徽寿县农村商业银行",
  "headquartersShortName": "安徽寿县农商",
  "headquartersNo": "AHSXRCB"
}, {
  "headquartersName": "安徽舒城农村商业银行",
  "headquartersShortName": "安徽舒城农商",
  "headquartersNo": "AHSCRCB"
}, {
  "headquartersName": "安徽舒城正兴村镇银行",
  "headquartersShortName": "安徽舒城正兴村镇银行",
  "headquartersNo": "AHSCZXCUNZB"
}, {
  "headquartersName": "安徽泗县农村商业银行",
  "headquartersShortName": "安徽泗县农商",
  "headquartersNo": "AHSIXRCB"
}, {
  "headquartersName": "安徽宿松民丰村镇银行",
  "headquartersShortName": "安徽宿松民丰村镇银行",
  "headquartersNo": "AHSSMFCUNZB"
}, {
  "headquartersName": "安徽宿松农村商业银行",
  "headquartersShortName": "安徽宿松农商",
  "headquartersNo": "AHSSRCB"
}, {
  "headquartersName": "安徽宿州淮海村镇银行",
  "headquartersShortName": "安徽宿州淮海村镇银行",
  "headquartersNo": "AHSZHHCUNZB"
}, {
  "headquartersName": "安徽宿州农村商业银行",
  "headquartersShortName": "安徽宿州农商",
  "headquartersNo": "AHSZRCB"
}, {
  "headquartersName": "安徽濉溪湖商村镇银行",
  "headquartersShortName": "安徽濉溪湖商村镇银行",
  "headquartersNo": "AHZXHSCUNZB"
}, {
  "headquartersName": "安徽濉溪农村商业银行",
  "headquartersShortName": "安徽濉溪农商",
  "headquartersNo": "AHSXIRCB"
}, {
  "headquartersName": "安徽太和农村商业银行",
  "headquartersShortName": "安徽太和农商",
  "headquartersNo": "AHTHRCB"
}, {
  "headquartersName": "安徽太湖江淮村镇银行",
  "headquartersShortName": "安徽太湖江淮村镇银行",
  "headquartersNo": "AHTHJHCUNZB"
}, {
  "headquartersName": "安徽太湖农村商业银行",
  "headquartersShortName": "安徽太湖农商",
  "headquartersNo": "AHTHURCB"
}, {
  "headquartersName": "安徽天长农村商业银行",
  "headquartersShortName": "安徽天长农商",
  "headquartersNo": "AHTCRCB"
}, {
  "headquartersName": "安徽桐城江淮村镇银行",
  "headquartersShortName": "安徽桐城江淮村镇银行",
  "headquartersNo": "AHTCJHCUNZB"
}, {
  "headquartersName": "安徽桐城农村商业银行",
  "headquartersShortName": "安徽桐城农商",
  "headquartersNo": "AHTONGCRCB"
}, {
  "headquartersName": "安徽铜陵铜源村镇银行",
  "headquartersShortName": "安徽铜陵铜源村镇银行",
  "headquartersNo": "AHTLTYCUNZB"
}, {
  "headquartersName": "安徽望江农村商业银行",
  "headquartersShortName": "安徽望江农商",
  "headquartersNo": "AHWJRCB"
}, {
  "headquartersName": "安徽涡阳湖商村镇银行",
  "headquartersShortName": "安徽涡阳湖商村镇银行",
  "headquartersNo": "AHWYHSCUNZB"
}, {
  "headquartersName": "安徽涡阳农村商业银行",
  "headquartersShortName": "安徽涡阳农商",
  "headquartersNo": "AHWYRCB"
}, {
  "headquartersName": "安徽无为农村商业银行",
  "headquartersShortName": "安徽无为农商",
  "headquartersNo": "AHWWRCB"
}, {
  "headquartersName": "安徽五河农村商业银行",
  "headquartersShortName": "安徽五河农商",
  "headquartersNo": "AHWHRCB"
}, {
  "headquartersName": "安徽五河永泰村镇银行",
  "headquartersShortName": "安徽五河永泰村镇银行",
  "headquartersNo": "AHWHYTCUNZB"
}, {
  "headquartersName": "安徽萧县农村商业银行",
  "headquartersShortName": "安徽萧县农商",
  "headquartersNo": "AHXXRCB"
}, {
  "headquartersName": "安徽新安银行",
  "headquartersShortName": "安徽新安",
  "headquartersNo": "AHXAYH"
}, {
  "headquartersName": "安徽休宁大地村镇银行",
  "headquartersShortName": "安徽休宁大地村镇银行",
  "headquartersNo": "AHXNDDCUNZB"
}, {
  "headquartersName": "安徽休宁农村商业银行",
  "headquartersShortName": "安徽休宁农商",
  "headquartersNo": "AHXNRCB"
}, {
  "headquartersName": "安徽宣州湖商村镇银行",
  "headquartersShortName": "安徽宣州湖商村镇银行",
  "headquartersNo": "AHXZHSCUNZB"
}, {
  "headquartersName": "安徽叶集农村商业银行",
  "headquartersShortName": "安徽叶集农商",
  "headquartersNo": "AHYJRCB"
}, {
  "headquartersName": "安徽黟县农村商业银行",
  "headquartersShortName": "安徽黟县农商",
  "headquartersNo": "AHYXRCB"
}, {
  "headquartersName": "安徽黟县新淮河村镇银行",
  "headquartersShortName": "安徽黟县新淮河村镇银行",
  "headquartersNo": "AHZXXHHCUNZB"
}, {
  "headquartersName": "安徽颍上农村商业银行",
  "headquartersShortName": "安徽颍上农商",
  "headquartersNo": "AHYSRCB"
}, {
  "headquartersName": "安徽裕安盛平村镇银行",
  "headquartersShortName": "安徽裕安盛平村镇银行",
  "headquartersNo": "AHYASPCUNZB"
}, {
  "headquartersName": "安徽岳西湖商村镇银行",
  "headquartersShortName": "安徽岳西湖商村镇银行",
  "headquartersNo": "AHYXHSCUNZB"
}, {
  "headquartersName": "安徽岳西农村商业银行",
  "headquartersShortName": "安徽岳西农商",
  "headquartersNo": "AHYXIRCB"
}, {
  "headquartersName": "安徽长丰科源村镇银行",
  "headquartersShortName": "安徽长丰科源村镇银行",
  "headquartersNo": "AHCFKYCUNZB"
}, {
  "headquartersName": "安徽长丰农村商业银行",
  "headquartersShortName": "安徽长丰农商",
  "headquartersNo": "AHCFRCB"
}, {
  "headquartersName": "安康农村商业银行",
  "headquartersShortName": "安康农商",
  "headquartersNo": "AKRCB"
}, {
  "headquartersName": "安龙兴龙村镇银行",
  "headquartersShortName": "安龙兴龙村镇银行",
  "headquartersNo": "ALXLCUNZB"
}, {
  "headquartersName": "安陆楚农商村镇银行",
  "headquartersShortName": "安陆楚农商村镇银行",
  "headquartersNo": "ALCNSCUNZB"
}, {
  "headquartersName": "安平惠民村镇银行",
  "headquartersShortName": "安平惠民村镇银行",
  "headquartersNo": "APHMCUNZB"
}, {
  "headquartersName": "安庆农村商业银行",
  "headquartersShortName": "安庆农商",
  "headquartersNo": "AQRCB"
}, {
  "headquartersName": "安丘北海村镇银行",
  "headquartersShortName": "安丘北海村镇银行",
  "headquartersNo": "AQBHCUNZB"
}, {
  "headquartersName": "安塞农银村镇银行",
  "headquartersShortName": "安塞农银村镇银行",
  "headquartersNo": "ASNYCUNZB"
}, {
  "headquartersName": "安顺农村商业银行",
  "headquartersShortName": "安顺农商",
  "headquartersNo": "ASRCB"
}, {
  "headquartersName": "安顺西航南马村镇银行",
  "headquartersShortName": "安顺西航南马村镇银行",
  "headquartersNo": "ASXHNMCUNZB"
}, {
  "headquartersName": "安顺西秀富民村镇银行",
  "headquartersShortName": "安顺西秀富民村镇银行",
  "headquartersNo": "ASXXFMCUNZB"
}, {
  "headquartersName": "安图农商村镇银行",
  "headquartersShortName": "安图农商村镇银行",
  "headquartersNo": "ATNSCUNZB"
}, {
  "headquartersName": "安溪民生村镇银行",
  "headquartersShortName": "安溪民生村镇银行",
  "headquartersNo": "AXMSCUNZB"
}, {
  "headquartersName": "安新大商村镇银行",
  "headquartersShortName": "安新大商村镇银行",
  "headquartersNo": "AXDSCUNZB"
}, {
  "headquartersName": "安阳珠江村镇银行",
  "headquartersShortName": "安阳珠江村镇银行",
  "headquartersNo": "AYZJCUNZB"
}, {
  "headquartersName": "安义融兴村镇银行",
  "headquartersShortName": "安义融兴村镇银行",
  "headquartersNo": "AYRXCUNZB"
}, {
  "headquartersName": "鞍山农村商业银行",
  "headquartersShortName": "鞍山农商",
  "headquartersNo": "ASHANRCB"
}, {
  "headquartersName": "鞍山银行",
  "headquartersShortName": "鞍山",
  "headquartersNo": "ASYH"
}, {
  "headquartersName": "敖汉惠农村镇银行",
  "headquartersShortName": "敖汉惠农村镇银行",
  "headquartersNo": "AHHNCUNZB"
}, {
  "headquartersName": "奥地利奥合国际银行",
  "headquartersShortName": "奥地利奥合国际",
  "headquartersNo": "RBIAG"
}, {
  "headquartersName": "澳大利亚和新西兰银行集团",
  "headquartersShortName": "澳大利亚和新西兰集团",
  "headquartersNo": "ADLYHXXL"
}, {
  "headquartersName": "澳大利亚西太平洋银行",
  "headquartersShortName": "澳大利亚西太平洋",
  "headquartersNo": "WESTPAC"
}, {
  "headquartersName": "澳门国际银行",
  "headquartersShortName": "澳门国际",
  "headquartersNo": "LUSOBANK"
}, {
  "headquartersName": "澳洲联邦银行",
  "headquartersShortName": "澳洲联邦",
  "headquartersNo": "CBA"
}, {
  "headquartersName": "巴基斯坦哈比银行",
  "headquartersShortName": "巴基斯坦哈比",
  "headquartersNo": "HBL"
}, {
  "headquartersName": "巴彦淖尔河套农村商业银行",
  "headquartersShortName": "巴彦淖尔河套农商",
  "headquartersNo": "BYNEHTRCB"
}, {
  "headquartersName": "巴彦淖尔市临河黄河村镇银行",
  "headquartersShortName": "巴彦淖尔市临河黄河村镇银行",
  "headquartersNo": "BYNESLHHHCUNZB"
}, {
  "headquartersName": "巴彦淖尔市乌拉特村镇银行",
  "headquartersShortName": "巴彦淖尔市乌拉特村镇银行",
  "headquartersNo": "BYNESWLTCUNZB"
}, {
  "headquartersName": "巴彦融兴村镇银行",
  "headquartersShortName": "巴彦融兴村镇银行",
  "headquartersNo": "BYRXCUNZB"
}, {
  "headquartersName": "巴中农村商业银行",
  "headquartersShortName": "巴中农商",
  "headquartersNo": "BZRCB"
}, {
  "headquartersName": "霸州舜丰村镇银行",
  "headquartersShortName": "霸州舜丰村镇银行",
  "headquartersNo": "BZSFCUNZB"
}, {
  "headquartersName": "白城农村商业银行",
  "headquartersShortName": "白城农商",
  "headquartersNo": "BCRCB"
}, {
  "headquartersName": "白城洮北惠民村镇银行",
  "headquartersShortName": "白城洮北惠民村镇银行",
  "headquartersNo": "BCZBHMCUNZB"
}, {
  "headquartersName": "白山浑江恒泰村镇银行",
  "headquartersShortName": "白山浑江恒泰村镇银行",
  "headquartersNo": "BSHJHTCUNZB"
}, {
  "headquartersName": "白山江源汇恒村镇银行",
  "headquartersShortName": "白山江源汇恒村镇银行",
  "headquartersNo": "BSJYHHCUNZB"
}, {
  "headquartersName": "白山江源农村商业银行",
  "headquartersShortName": "白山江源农商",
  "headquartersNo": "BSJYRCB"
}, {
  "headquartersName": "百色靖西长江村镇银行",
  "headquartersShortName": "百色靖西长江村镇银行",
  "headquartersNo": "BSJXCJCUNZB"
}, {
  "headquartersName": "百色右江华润村镇银行",
  "headquartersShortName": "百色右江华润村镇银行",
  "headquartersNo": "BSYJHRCUNZB"
}, {
  "headquartersName": "柏乡融信村镇银行",
  "headquartersShortName": "柏乡融信村镇银行",
  "headquartersNo": "BXRXCUNZB"
}, {
  "headquartersName": "拜泉融兴村镇银行",
  "headquartersShortName": "拜泉融兴村镇银行",
  "headquartersNo": "BQRXCUNZB"
}, {
  "headquartersName": "蚌埠农村商业银行",
  "headquartersShortName": "蚌埠农商",
  "headquartersNo": "BBRCB"
}, {
  "headquartersName": "包头农村商业银行",
  "headquartersShortName": "包头农商",
  "headquartersNo": "BTRCB"
}, {
  "headquartersName": "包头青山河套村镇银行",
  "headquartersShortName": "包头青山河套村镇银行",
  "headquartersNo": "QSHTCUNZB"
}, {
  "headquartersName": "包头市东河金谷村镇银行",
  "headquartersShortName": "包头市东河金谷村镇银行",
  "headquartersNo": "BTSDHJGCUNZB"
}, {
  "headquartersName": "包头市高新银通村镇银行",
  "headquartersShortName": "包头市高新银通村镇银行",
  "headquartersNo": "BTSGXYTCUNZB"
}, {
  "headquartersName": "包头市九原立农村镇银行",
  "headquartersShortName": "包头市九原立农村镇银行",
  "headquartersNo": "BTSJYLNCUNZB"
}, {
  "headquartersName": "包头市昆都仑蒙银村镇银行",
  "headquartersShortName": "包头市昆都仑蒙银村镇银行",
  "headquartersNo": "BTSKDLMYCUNZB"
}, {
  "headquartersName": "宝丰豫丰村镇银行",
  "headquartersShortName": "宝丰豫丰村镇银行",
  "headquartersNo": "BFYFCUNZB"
}, {
  "headquartersName": "宝清广益村镇银行",
  "headquartersShortName": "宝清广益村镇银行",
  "headquartersNo": "BQGYCUNZB"
}, {
  "headquartersName": "保德县慧融村镇银行",
  "headquartersShortName": "保德县慧融村镇银行",
  "headquartersNo": "BDXHRCUNZB"
}, {
  "headquartersName": "保定满城区利丰村镇银行",
  "headquartersShortName": "保定满城区利丰村镇银行",
  "headquartersNo": "BDMCQLFCUNZB"
}, {
  "headquartersName": "保定清苑区邢农商村镇银行",
  "headquartersShortName": "保定清苑区邢农商村镇银行",
  "headquartersNo": "BDQYQXNSCUNZB"
}, {
  "headquartersName": "保定银行",
  "headquartersShortName": "保定",
  "headquartersNo": "BDYH"
}, {
  "headquartersName": "保康楚农商村镇银行",
  "headquartersShortName": "保康楚农商村镇银行",
  "headquartersNo": "BKCNSCUNZB"
}, {
  "headquartersName": "保山昌宁长江村镇银行",
  "headquartersShortName": "保山昌宁长江村镇银行",
  "headquartersNo": "BSCNCJCUNZB"
}, {
  "headquartersName": "保山龙陵长江村镇银行",
  "headquartersShortName": "保山龙陵长江村镇银行",
  "headquartersNo": "BSLLCJCUNZB"
}, {
  "headquartersName": "保山隆阳沪农商村镇银行",
  "headquartersShortName": "保山隆阳沪农商村镇银行",
  "headquartersNo": "BSLYHNSCUNZB"
}, {
  "headquartersName": "保山施甸长江村镇银行",
  "headquartersShortName": "保山施甸长江村镇银行",
  "headquartersNo": "BSSDCJCUNZB"
}, {
  "headquartersName": "北京昌平发展村镇银行",
  "headquartersShortName": "北京昌平发展村镇银行",
  "headquartersNo": "BJCPBSCUNZB"
}, {
  "headquartersName": "北京大兴华夏村镇银行",
  "headquartersShortName": "北京大兴华夏村镇银行",
  "headquartersNo": "BJDXHXCUNZB"
}, {
  "headquartersName": "北京大兴九银村镇银行",
  "headquartersShortName": "北京大兴九银村镇银行",
  "headquartersNo": "BJDXJYCUNZB"
}, {
  "headquartersName": "北京房山沪农商村镇银行",
  "headquartersShortName": "北京房山沪农商村镇银行",
  "headquartersNo": "BJFSHNSCUNZB"
}, {
  "headquartersName": "北京怀柔融兴村镇银行",
  "headquartersShortName": "北京怀柔融兴村镇银行",
  "headquartersNo": "BJHRRXCUNZB"
}, {
  "headquartersName": "北京门头沟珠江村镇银行",
  "headquartersShortName": "北京门头沟珠江村镇银行",
  "headquartersNo": "BJMTGZJCUNZB"
}, {
  "headquartersName": "北京密云汇丰村镇银行",
  "headquartersShortName": "北京密云汇丰村镇银行",
  "headquartersNo": "BJMYHFCUNZB"
}, {
  "headquartersName": "北京农村商业银行",
  "headquartersShortName": "北京农商",
  "headquartersNo": "BRCB"
}, {
  "headquartersName": "北京平谷新华村镇银行",
  "headquartersShortName": "北京平谷新华村镇银行",
  "headquartersNo": "BJPGXHCUNZB"
}, {
  "headquartersName": "北京顺义银座村镇银行",
  "headquartersShortName": "北京顺义银座村镇银行",
  "headquartersNo": "SYYZCUNZB"
}, {
  "headquartersName": "北京延庆村镇银行",
  "headquartersShortName": "北京延庆村镇银行",
  "headquartersNo": "BJYQCUNZB"
}, {
  "headquartersName": "北京银行",
  "headquartersShortName": "北京",
  "headquartersNo": "BCCB"
}, {
  "headquartersName": "北京中关村银行",
  "headquartersShortName": "中关村",
  "headquartersNo": "ZGCBANK"
}, {
  "headquartersName": "北票盛都村镇银行",
  "headquartersShortName": "北票盛都村镇银行",
  "headquartersNo": "BPSDCUNZB"
}, {
  "headquartersName": "北屯国民村镇银行",
  "headquartersShortName": "北屯国民村镇银行",
  "headquartersNo": "BTGMCUNZB"
}, {
  "headquartersName": "本溪丰泰村镇银行",
  "headquartersShortName": "本溪丰泰村镇银行",
  "headquartersNo": "BXFTCUNZB"
}, {
  "headquartersName": "本溪丰业村镇银行",
  "headquartersShortName": "本溪丰业村镇银行",
  "headquartersNo": "BXFYCUNZB"
}, {
  "headquartersName": "本溪禾丰村镇银行",
  "headquartersShortName": "本溪禾丰村镇银行",
  "headquartersNo": "BXHFCUNZB"
}, {
  "headquartersName": "本溪市商业银行",
  "headquartersShortName": "本溪",
  "headquartersNo": "BXSCCB"
}, {
  "headquartersName": "本溪同盛村镇银行",
  "headquartersShortName": "本溪同盛村镇银行",
  "headquartersNo": "BXTSCUNZB"
}, {
  "headquartersName": "比利时联合银行",
  "headquartersShortName": "比利时联合",
  "headquartersNo": "KBC"
}, {
  "headquartersName": "毕节发展村镇银行",
  "headquartersShortName": "毕节发展村镇银行",
  "headquartersNo": "BJFZCUNZB"
}, {
  "headquartersName": "宾阳北部湾村镇银行",
  "headquartersShortName": "宾阳北部湾村镇银行",
  "headquartersNo": "BYBBWCUNZB"
}, {
  "headquartersName": "滨州河海村镇银行",
  "headquartersShortName": "滨州河海村镇银行",
  "headquartersNo": "BZHHCUNZB"
}, {
  "headquartersName": "滨州农村商业银行",
  "headquartersShortName": "滨州农商",
  "headquartersNo": "BINZRCB"
}, {
  "headquartersName": "亳州药都农村商业银行",
  "headquartersShortName": "亳州药都农商",
  "headquartersNo": "BZYDRCB"
}, {
  "headquartersName": "博乐国民村镇银行",
  "headquartersShortName": "博乐国民村镇银行",
  "headquartersNo": "BLGMCUNZB"
}, {
  "headquartersName": "渤海银行",
  "headquartersShortName": "渤海",
  "headquartersNo": "CBHB"
}, {
  "headquartersName": "苍梧深通村镇银行",
  "headquartersShortName": "苍梧深通村镇银行",
  "headquartersNo": "CWSTCUNZB"
}, {
  "headquartersName": "沧县吉银村镇银行",
  "headquartersShortName": "沧县吉银村镇银行",
  "headquartersNo": "CXJYCUNZB"
}, {
  "headquartersName": "沧州海兴新华村镇银行",
  "headquartersShortName": "沧州海兴新华村镇银行",
  "headquartersNo": "CZHXXHCUNZB"
}, {
  "headquartersName": "沧州市运河青隆村镇银行",
  "headquartersShortName": "沧州市运河青隆村镇银行",
  "headquartersNo": "CZSYHQLCUNZB"
}, {
  "headquartersName": "沧州盐山新华村镇银行",
  "headquartersShortName": "沧州盐山新华村镇银行",
  "headquartersNo": "CZYSXHCUNZB"
}, {
  "headquartersName": "沧州银行",
  "headquartersShortName": "沧州",
  "headquartersNo": "CZYH"
}, {
  "headquartersName": "册亨富民村镇银行",
  "headquartersShortName": "册亨富民村镇银行",
  "headquartersNo": "CHFMCUNZB"
}, {
  "headquartersName": "岑溪市北部湾村镇银行",
  "headquartersShortName": "岑溪市北部湾村镇银行",
  "headquartersNo": "ZXSBBWCUNZB"
}, {
  "headquartersName": "茶陵浦发村镇银行",
  "headquartersShortName": "茶陵浦发村镇银行",
  "headquartersNo": "CLPFCUNZB"
}, {
  "headquartersName": "察哈尔右翼前旗蒙银村镇银行",
  "headquartersShortName": "察哈尔右翼前旗蒙银村镇银行",
  "headquartersNo": "CHEYYQQMYCUNZB"
}, {
  "headquartersName": "昌吉国民村镇银行",
  "headquartersShortName": "昌吉国民村镇银行",
  "headquartersNo": "CJGMCUNZB"
}, {
  "headquartersName": "昌乐乐安村镇银行",
  "headquartersShortName": "昌乐乐安村镇银行",
  "headquartersNo": "CLLACUNZB"
}, {
  "headquartersName": "昌黎家银村镇银行",
  "headquartersShortName": "昌黎家银村镇银行",
  "headquartersNo": "CLJYCUNZB"
}, {
  "headquartersName": "昌图民祥村镇银行",
  "headquartersShortName": "昌图民祥村镇银行",
  "headquartersNo": "CTMXCUNZB"
}, {
  "headquartersName": "昌邑北海村镇银行",
  "headquartersShortName": "昌邑北海村镇银行",
  "headquartersNo": "CYBHCUNZB"
}, {
  "headquartersName": "常德农村商业银行",
  "headquartersShortName": "常德农商",
  "headquartersNo": "CDERCB"
}, {
  "headquartersName": "常宁珠江村镇银行",
  "headquartersShortName": "常宁珠江村镇银行",
  "headquartersNo": "CNZJCUNZB"
}, {
  "headquartersName": "常州新北中成村镇银行",
  "headquartersShortName": "常州新北中成村镇银行",
  "headquartersNo": "CZXBZCCUNZB"
}, {
  "headquartersName": "常州钟楼长江村镇银行",
  "headquartersShortName": "常州钟楼长江村镇银行",
  "headquartersNo": "CZZLCJCUNZB"
}, {
  "headquartersName": "巢湖农村商业银行",
  "headquartersShortName": "巢湖农商",
  "headquartersNo": "CHRCB"
}, {
  "headquartersName": "朝阳柳城村镇银行",
  "headquartersShortName": "朝阳柳城村镇银行",
  "headquartersNo": "CYLCCUNZB"
}, {
  "headquartersName": "朝阳银行",
  "headquartersShortName": "朝阳",
  "headquartersNo": "CYYH"
}, {
  "headquartersName": "潮州潮安长江村镇银行",
  "headquartersShortName": "潮州潮安长江村镇银行",
  "headquartersNo": "CZCACJCUNZB"
}, {
  "headquartersName": "郴州农村商业银行",
  "headquartersShortName": "郴州农商",
  "headquartersNo": "CZRCB"
}, {
  "headquartersName": "成安齐鲁村镇银行",
  "headquartersShortName": "成安齐鲁村镇银行",
  "headquartersNo": "CAQLCUNZB"
}, {
  "headquartersName": "成都农村商业银行",
  "headquartersShortName": "成都农商",
  "headquartersNo": "CDRCB"
}, {
  "headquartersName": "成都青白江融兴村镇银行",
  "headquartersShortName": "成都青白江融兴村镇银行",
  "headquartersNo": "CDQBJRXCUNZB"
}, {
  "headquartersName": "成都双流诚民村镇银行",
  "headquartersShortName": "成都双流诚民村镇银行",
  "headquartersNo": "CDSLCMCUNZB"
}, {
  "headquartersName": "成都银行",
  "headquartersShortName": "成都",
  "headquartersNo": "CDYH"
}, {
  "headquartersName": "承德县恒升村镇银行",
  "headquartersShortName": "承德县恒升村镇银行",
  "headquartersNo": "CDXHSCUNZB"
}, {
  "headquartersName": "承德银行",
  "headquartersShortName": "承德",
  "headquartersNo": "CDBANK"
}, {
  "headquartersName": "池州贵池民生村镇银行",
  "headquartersShortName": "池州贵池民生村镇银行",
  "headquartersNo": "CZGCMSCUNZB"
}, {
  "headquartersName": "池州九华农村商业银行",
  "headquartersShortName": "池州九华农商",
  "headquartersNo": "CZJHRCB"
}, {
  "headquartersName": "茌平沪农商村镇银行",
  "headquartersShortName": "茌平沪农商村镇银行",
  "headquartersNo": "CPHNSCUNZB"
}, {
  "headquartersName": "赤城家银村镇银行",
  "headquartersShortName": "赤城家银村镇银行",
  "headquartersNo": "CCJYCUNZB"
}, {
  "headquartersName": "赤峰市红山玉龙村镇银行",
  "headquartersShortName": "赤峰市红山玉龙村镇银行",
  "headquartersNo": "CFSHSYLCUNZB"
}, {
  "headquartersName": "赤峰市松山立农村镇银行",
  "headquartersShortName": "赤峰市松山立农村镇银行",
  "headquartersNo": "CFSSSLNCUNZB"
}, {
  "headquartersName": "赤峰市元宝山玉龙村镇银行",
  "headquartersShortName": "赤峰市元宝山玉龙村镇银行",
  "headquartersNo": "CFSYBSYLCUNZB"
}, {
  "headquartersName": "赤峰松山农村商业银行",
  "headquartersShortName": "赤峰松山农商",
  "headquartersNo": "CFSSRCB"
}, {
  "headquartersName": "赤峰元宝山农村商业银行",
  "headquartersShortName": "赤峰元宝山农商",
  "headquartersNo": "CFYBSRCB"
}, {
  "headquartersName": "崇仁九银村镇银行",
  "headquartersShortName": "崇仁九银村镇银行",
  "headquartersNo": "CRJYCUNZB"
}, {
  "headquartersName": "崇阳楚农商村镇银行",
  "headquartersShortName": "崇阳楚农商村镇银行",
  "headquartersNo": "CYCNSCUNZB"
}, {
  "headquartersName": "崇义农村商业银行",
  "headquartersShortName": "崇义农商",
  "headquartersNo": "CYRCB"
}, {
  "headquartersName": "崇州上银村镇银行",
  "headquartersShortName": "崇州上银村镇银行",
  "headquartersNo": "CZSYCUNZB"
}, {
  "headquartersName": "崇左大新长江村镇银行",
  "headquartersShortName": "崇左大新长江村镇银行",
  "headquartersNo": "CZDXCJCUNZB"
}, {
  "headquartersName": "滁州皖东农村商业银行",
  "headquartersShortName": "滁州皖东农商",
  "headquartersNo": "CZWDRCB"
}, {
  "headquartersName": "楚雄红塔村镇银行",
  "headquartersShortName": "楚雄红塔村镇银行",
  "headquartersNo": "CXHTCUNZB"
}, {
  "headquartersName": "创兴银行有限公司",
  "headquartersShortName": "创兴",
  "headquartersNo": "CXYHYXGS"
}, {
  "headquartersName": "慈利沪农商村镇银行",
  "headquartersShortName": "慈利沪农商村镇银行",
  "headquartersNo": "CLHNSCUNZB"
}, {
  "headquartersName": "慈溪民生村镇银行",
  "headquartersShortName": "慈溪民生村镇银行",
  "headquartersNo": "CXMSCUNZB"
}, {
  "headquartersName": "磁县齐鲁村镇银行",
  "headquartersShortName": "磁县齐鲁村镇银行",
  "headquartersNo": "CXQLCUNZB"
}, {
  "headquartersName": "枞阳泰业村镇银行",
  "headquartersShortName": "枞阳泰业村镇银行",
  "headquartersNo": "ZYTYCUNZB"
}, {
  "headquartersName": "从江月明村镇银行",
  "headquartersShortName": "从江月明村镇银行",
  "headquartersNo": "CJYMCUNZB"
}, {
  "headquartersName": "达尔罕茂明安联合旗蒙商村镇银行",
  "headquartersShortName": "达尔罕茂明安联合旗蒙商村镇银行",
  "headquartersNo": "DEHMMALHQBSCUNZB"
}, {
  "headquartersName": "达州农村商业银行",
  "headquartersShortName": "达州农商",
  "headquartersNo": "DZRCB"
}, {
  "headquartersName": "达州银行",
  "headquartersShortName": "达州",
  "headquartersNo": "DZYX"
}, {
  "headquartersName": "大安惠民村镇银行",
  "headquartersShortName": "大安惠民村镇银行",
  "headquartersNo": "DAHMCUNZB"
}, {
  "headquartersName": "大厂回族自治县新华村镇银行",
  "headquartersShortName": "大厂回族自治县新华村镇银行",
  "headquartersNo": "DCHZZZXXHCUNZB"
}, {
  "headquartersName": "大城舜丰村镇银行",
  "headquartersShortName": "大城舜丰村镇银行",
  "headquartersNo": "DCSFCUNZB"
}, {
  "headquartersName": "大方富民村镇银行",
  "headquartersShortName": "大方富民村镇银行",
  "headquartersNo": "DFFMCUNZB"
}, {
  "headquartersName": "大丰银行",
  "headquartersShortName": "大丰",
  "headquartersNo": "DFYX"
}, {
  "headquartersName": "大华银行（中国）有限公司",
  "headquartersShortName": "大华",
  "headquartersNo": "UOBCHINA"
}, {
  "headquartersName": "大理宾川长江村镇银行",
  "headquartersShortName": "大理宾川长江村镇银行",
  "headquartersNo": "DLBCCJCUNZB"
}, {
  "headquartersName": "大理洱源长江村镇银行",
  "headquartersShortName": "大理洱源长江村镇银行",
  "headquartersNo": "DLEYCJCUNZB"
}, {
  "headquartersName": "大理古城中成村镇银行",
  "headquartersShortName": "大理古城中成村镇银行",
  "headquartersNo": "DLGCZCCUNZB"
}, {
  "headquartersName": "大理巍山长江村镇银行",
  "headquartersShortName": "大理巍山长江村镇银行",
  "headquartersNo": "DLWSCJCUNZB"
}, {
  "headquartersName": "大连保税区珠江村镇银行",
  "headquartersShortName": "大连保税区珠江村镇银行",
  "headquartersNo": "DLBSQZJCUNZB"
}, {
  "headquartersName": "大连甘井子浦发村镇银行",
  "headquartersShortName": "大连甘井子浦发村镇银行",
  "headquartersNo": "DLGJZPFCUNZB"
}, {
  "headquartersName": "大连金州联丰村镇银行",
  "headquartersShortName": "大连金州联丰村镇银行",
  "headquartersNo": "DLJZLFCUNZB"
}, {
  "headquartersName": "大连经济技术开发区鑫汇村镇银行",
  "headquartersShortName": "大连经济技术开发区鑫汇村镇银行",
  "headquartersNo": "DLJJJSKFQZHCUNZB"
}, {
  "headquartersName": "大连旅顺口蒙银村镇银行",
  "headquartersShortName": "大连旅顺口蒙银村镇银行",
  "headquartersNo": "DLLSKMYCUNZB"
}, {
  "headquartersName": "大连农村商业银行",
  "headquartersShortName": "大连农商",
  "headquartersNo": "DLRCB"
}, {
  "headquartersName": "大连普兰店汇丰村镇银行",
  "headquartersShortName": "大连普兰店汇丰村镇银行",
  "headquartersNo": "DLPLDHFCUNZB"
}, {
  "headquartersName": "大连银行",
  "headquartersShortName": "大连",
  "headquartersNo": "DLYH"
}, {
  "headquartersName": "大名恒升村镇银行",
  "headquartersShortName": "大名恒升村镇银行",
  "headquartersNo": "DMHSCUNZB"
}, {
  "headquartersName": "大庆农村商业银行",
  "headquartersShortName": "大庆农商",
  "headquartersNo": "DQRCB"
}, {
  "headquartersName": "大同农村商业银行",
  "headquartersShortName": "大同农商",
  "headquartersNo": "DTRCB"
}, {
  "headquartersName": "大同市南郊区京都村镇银行",
  "headquartersShortName": "大同市南郊区京都村镇银行",
  "headquartersNo": "DTSNJQJDCUNZB"
}, {
  "headquartersName": "大同银行",
  "headquartersShortName": "大同",
  "headquartersNo": "DTYH"
}, {
  "headquartersName": "大洼恒丰村镇银行",
  "headquartersShortName": "大洼恒丰村镇银行",
  "headquartersNo": "DWHFCUNZB"
}, {
  "headquartersName": "大悟楚农商村镇银行",
  "headquartersShortName": "大悟楚农商村镇银行",
  "headquartersNo": "DWCNSCUNZB"
}, {
  "headquartersName": "大西洋银行",
  "headquartersShortName": "大西洋",
  "headquartersNo": "BNU"
}, {
  "headquartersName": "大新银行（中国）有限公司",
  "headquartersShortName": "大新",
  "headquartersNo": "DAHSING"
}, {
  "headquartersName": "大兴安岭农村商业银行",
  "headquartersShortName": "大兴安岭农商",
  "headquartersNo": "DXALRCB"
}, {
  "headquartersName": "大邑交银兴民村镇银行",
  "headquartersShortName": "大邑交银兴民村镇银行",
  "headquartersNo": "DYJYXMCUNZB"
}, {
  "headquartersName": "大众银行（香港）有限公司",
  "headquartersShortName": "大众",
  "headquartersNo": "DZYHXGYXGS"
}, {
  "headquartersName": "代县泓都村镇银行",
  "headquartersShortName": "代县泓都村镇银行",
  "headquartersNo": "DXZDCUNZB"
}, {
  "headquartersName": "丹东鼎安村镇银行",
  "headquartersShortName": "丹东鼎安村镇银行",
  "headquartersNo": "DDDACUNZB"
}, {
  "headquartersName": "丹东鼎元村镇银行",
  "headquartersShortName": "丹东鼎元村镇银行",
  "headquartersNo": "DDDYCUNZB"
}, {
  "headquartersName": "丹东福汇村镇银行",
  "headquartersShortName": "丹东福汇村镇银行",
  "headquartersNo": "DDFHCUNZB"
}, {
  "headquartersName": "丹东农村商业银行",
  "headquartersShortName": "丹东农商",
  "headquartersNo": "DDRCB"
}, {
  "headquartersName": "丹东银行",
  "headquartersShortName": "丹东",
  "headquartersNo": "DDYH"
}, {
  "headquartersName": "丹江口楚农商村镇银行",
  "headquartersShortName": "丹江口楚农商村镇银行",
  "headquartersNo": "DJKCNSCUNZB"
}, {
  "headquartersName": "丹寨富民村镇银行",
  "headquartersShortName": "丹寨富民村镇银行",
  "headquartersNo": "DZFMCUNZB"
}, {
  "headquartersName": "德国北德意志州银行",
  "headquartersShortName": "德国北德意志州",
  "headquartersNo": "NORDLB"
}, {
  "headquartersName": "德国商业银行",
  "headquartersShortName": "德国",
  "headquartersNo": "COMMERZBANK"
}, {
  "headquartersName": "德宏芒市长江村镇银行",
  "headquartersShortName": "德宏芒市长江村镇银行",
  "headquartersNo": "DHMSCJCUNZB"
}, {
  "headquartersName": "德惠敦银村镇银行",
  "headquartersShortName": "德惠敦银村镇银行",
  "headquartersNo": "DHDYCUNZB"
}, {
  "headquartersName": "德江长征村镇银行",
  "headquartersShortName": "德江长征村镇银行",
  "headquartersNo": "DJCZCUNZB"
}, {
  "headquartersName": "德庆华润村镇银行",
  "headquartersShortName": "德庆华润村镇银行",
  "headquartersNo": "DQHRCUNZB"
}, {
  "headquartersName": "德兴蓝海村镇银行",
  "headquartersShortName": "德兴蓝海村镇银行",
  "headquartersNo": "DXLHCUNZB"
}, {
  "headquartersName": "德阳农村商业银行",
  "headquartersShortName": "德阳农商",
  "headquartersNo": "DEYRCB"
}, {
  "headquartersName": "德意志银行（中国）有限公司",
  "headquartersShortName": "德意志",
  "headquartersNo": "DB"
}, {
  "headquartersName": "德州陵城农村商业银行",
  "headquartersShortName": "德州陵城农商",
  "headquartersNo": "DZLCRCB"
}, {
  "headquartersName": "德州陵城圆融村镇银行",
  "headquartersShortName": "德州陵城圆融村镇银行",
  "headquartersNo": "DZLCYRCUNZB"
}, {
  "headquartersName": "德州农村商业银行",
  "headquartersShortName": "德州农商",
  "headquartersNo": "DEZRCB"
}, {
  "headquartersName": "德州银行",
  "headquartersShortName": "德州",
  "headquartersNo": "DZYH"
}, {
  "headquartersName": "登封齐鲁村镇银行",
  "headquartersShortName": "登封齐鲁村镇银行",
  "headquartersNo": "DFQLCUNZB"
}, {
  "headquartersName": "磴口蒙银村镇银行",
  "headquartersShortName": "磴口蒙银村镇银行",
  "headquartersNo": "ZKMYCUNZB"
}, {
  "headquartersName": "第一商业银行",
  "headquartersShortName": "第一",
  "headquartersNo": "FIRSTBANK"
}, {
  "headquartersName": "调兵山惠民村镇银行",
  "headquartersShortName": "调兵山惠民村镇银行",
  "headquartersNo": "DBSHMCUNZB"
}, {
  "headquartersName": "定安合丰村镇银行",
  "headquartersShortName": "定安合丰村镇银行",
  "headquartersNo": "DAHFCUNZB"
}, {
  "headquartersName": "定陶河海村镇银行",
  "headquartersShortName": "定陶河海村镇银行",
  "headquartersNo": "DTHHCUNZB"
}, {
  "headquartersName": "定西农村商业银行",
  "headquartersShortName": "定西农商",
  "headquartersNo": "DXRCB"
}, {
  "headquartersName": "定兴丰源村镇银行",
  "headquartersShortName": "定兴丰源村镇银行",
  "headquartersNo": "DXFYCUNZB"
}, {
  "headquartersName": "定州中成村镇银行",
  "headquartersShortName": "定州中成村镇银行",
  "headquartersNo": "DZZCCUNZB"
}, {
  "headquartersName": "东阿青隆村镇银行",
  "headquartersShortName": "东阿青隆村镇银行",
  "headquartersNo": "DAQLCUNZB"
}, {
  "headquartersName": "东方汇理银行（中国）有限公司",
  "headquartersShortName": "东方汇理",
  "headquartersNo": "BI"
}, {
  "headquartersName": "东方惠丰村镇银行",
  "headquartersShortName": "东方惠丰村镇银行",
  "headquartersNo": "DFHFCUNZB"
}, {
  "headquartersName": "东丰吉银村镇银行",
  "headquartersShortName": "东丰吉银村镇银行",
  "headquartersNo": "DFJYCUNZB"
}, {
  "headquartersName": "东港同合村镇银行",
  "headquartersShortName": "东港同合村镇银行",
  "headquartersNo": "DGTHCUNZB"
}, {
  "headquartersName": "东莞常平新华村镇银行",
  "headquartersShortName": "东莞常平新华村镇银行",
  "headquartersNo": "DZCPXHCUNZB"
}, {
  "headquartersName": "东莞大朗东盈村镇银行",
  "headquartersShortName": "东莞大朗东盈村镇银行",
  "headquartersNo": "DZDLDYCUNZB"
}, {
  "headquartersName": "东莞厚街华业村镇银行",
  "headquartersShortName": "东莞厚街华业村镇银行",
  "headquartersNo": "DZHJHYCUNZB"
}, {
  "headquartersName": "东莞虎门长江村镇银行",
  "headquartersShortName": "东莞虎门长江村镇银行",
  "headquartersNo": "DZHMCJCUNZB"
}, {
  "headquartersName": "东莞黄江珠江村镇银行",
  "headquartersShortName": "东莞黄江珠江村镇银行",
  "headquartersNo": "DZHJZJCUNZB"
}, {
  "headquartersName": "东莞农村商业银行",
  "headquartersShortName": "东莞农商",
  "headquartersNo": "DGRCB"
}, {
  "headquartersName": "东莞银行",
  "headquartersShortName": "东莞",
  "headquartersNo": "DGYH"
}, {
  "headquartersName": "东莞长安村镇银行",
  "headquartersShortName": "东莞长安村镇银行",
  "headquartersNo": "DZCACUNZB"
}, {
  "headquartersName": "东光青隆村镇银行",
  "headquartersShortName": "东光青隆村镇银行",
  "headquartersNo": "DGQLCUNZB"
}, {
  "headquartersName": "东辽农商村镇银行",
  "headquartersShortName": "东辽农商村镇银行",
  "headquartersNo": "DLNSCUNZB"
}, {
  "headquartersName": "东宁润生村镇银行",
  "headquartersShortName": "东宁润生村镇银行",
  "headquartersNo": "DNRSCUNZB"
}, {
  "headquartersName": "东平沪农商村镇银行",
  "headquartersShortName": "东平沪农商村镇银行",
  "headquartersNo": "DPHNSCUNZB"
}, {
  "headquartersName": "东山润鑫村镇银行",
  "headquartersShortName": "东山润鑫村镇银行",
  "headquartersNo": "DSRZCUNZB"
}, {
  "headquartersName": "东乌珠穆沁农信开元村镇银行",
  "headquartersShortName": "东乌珠穆沁农信开元村镇银行",
  "headquartersNo": "DWZMQNXKYCUNZB"
}, {
  "headquartersName": "东兴国民村镇银行",
  "headquartersShortName": "东兴国民村镇银行",
  "headquartersNo": "DXGMCUNZB"
}, {
  "headquartersName": "东亚银行（中国）有限公司",
  "headquartersShortName": "东亚",
  "headquartersNo": "HKBEA"
}, {
  "headquartersName": "东营河口中成村镇银行",
  "headquartersShortName": "东营河口中成村镇银行",
  "headquartersNo": "DYHKZCCUNZB"
}, {
  "headquartersName": "东营莱商村镇银行",
  "headquartersShortName": "东营莱商村镇银行",
  "headquartersNo": "DYLSCUNZB"
}, {
  "headquartersName": "东营农村商业银行",
  "headquartersShortName": "东营农商",
  "headquartersNo": "DYRCB"
}, {
  "headquartersName": "东营融和村镇银行",
  "headquartersShortName": "东营融和村镇银行",
  "headquartersNo": "DYRHCUNZB"
}, {
  "headquartersName": "东营银行",
  "headquartersShortName": "东营",
  "headquartersNo": "DOYYH"
}, {
  "headquartersName": "东源泰业村镇银行",
  "headquartersShortName": "东源泰业村镇银行",
  "headquartersNo": "DYTYCUNZB"
}, {
  "headquartersName": "都昌九银村镇银行",
  "headquartersShortName": "都昌九银村镇银行",
  "headquartersNo": "DCJYCUNZB"
}, {
  "headquartersName": "都江堰金都村镇银行",
  "headquartersShortName": "都江堰金都村镇银行",
  "headquartersNo": "DJYJDCUNZB"
}, {
  "headquartersName": "都匀融通村镇银行",
  "headquartersShortName": "都匀融通村镇银行",
  "headquartersNo": "DYRTCUNZB"
}, {
  "headquartersName": "独山富民村镇银行",
  "headquartersShortName": "独山富民村镇银行",
  "headquartersNo": "DSFMCUNZB"
}, {
  "headquartersName": "杜尔伯特润生村镇银行",
  "headquartersShortName": "杜尔伯特润生村镇银行",
  "headquartersNo": "DEBTRSCUNZB"
}, {
  "headquartersName": "敦化江南村镇银行",
  "headquartersShortName": "敦化江南村镇银行",
  "headquartersNo": "DHJNCUNZB"
}, {
  "headquartersName": "敦煌市金盛村镇银行",
  "headquartersShortName": "敦煌市金盛村镇银行",
  "headquartersNo": "DHSJSCUNZB"
}, {
  "headquartersName": "俄罗斯外贸银行",
  "headquartersShortName": "俄罗斯外贸",
  "headquartersNo": "VTB"
}, {
  "headquartersName": "峨眉山中成村镇银行",
  "headquartersShortName": "峨眉山中成村镇银行",
  "headquartersNo": "EMSZCCUNZB"
}, {
  "headquartersName": "鄂尔多斯农村商业银行",
  "headquartersShortName": "鄂尔多斯农商",
  "headquartersNo": "EEDSRCB"
}, {
  "headquartersName": "鄂尔多斯市东胜蒙银村镇银行",
  "headquartersShortName": "鄂尔多斯市东胜蒙银村镇银行",
  "headquartersNo": "EEDSSDSMYCUNZB"
}, {
  "headquartersName": "鄂尔多斯市罕台村镇银行",
  "headquartersShortName": "鄂尔多斯市罕台村镇银行",
  "headquartersNo": "EEDSSHTCUNZB"
}, {
  "headquartersName": "鄂尔多斯市康巴什村镇银行",
  "headquartersShortName": "鄂尔多斯市康巴什村镇银行",
  "headquartersNo": "EEDSSKBSCUNZB"
}, {
  "headquartersName": "鄂尔多斯市塔拉壕金谷村镇银行",
  "headquartersShortName": "鄂尔多斯市塔拉壕金谷村镇银行",
  "headquartersNo": "EEDSSTLHJGCUNZB"
}, {
  "headquartersName": "鄂尔多斯市天骄蒙银村镇银行",
  "headquartersShortName": "鄂尔多斯市天骄蒙银村镇银行",
  "headquartersNo": "EEDSSTJMYCUNZB"
}, {
  "headquartersName": "鄂尔多斯市铁西蒙银村镇银行",
  "headquartersShortName": "鄂尔多斯市铁西蒙银村镇银行",
  "headquartersNo": "EEDSSTXMYCUNZB"
}, {
  "headquartersName": "鄂尔多斯银行",
  "headquartersShortName": "鄂尔多斯",
  "headquartersNo": "EEDSYH"
}, {
  "headquartersName": "鄂托克旗汇泽村镇银行",
  "headquartersShortName": "鄂托克旗汇泽村镇银行",
  "headquartersNo": "ETKQHZCUNZB"
}, {
  "headquartersName": "鄂托克前旗蒙银村镇银行",
  "headquartersShortName": "鄂托克前旗蒙银村镇银行",
  "headquartersNo": "ETKQQMYCUNZB"
}, {
  "headquartersName": "鄂托克兴生源村镇银行",
  "headquartersShortName": "鄂托克兴生源村镇银行",
  "headquartersNo": "ETKXSYCUNZB"
}, {
  "headquartersName": "鄂温克蒙商村镇银行",
  "headquartersShortName": "鄂温克蒙商村镇银行",
  "headquartersNo": "EWKBSCUNZB"
}, {
  "headquartersName": "二连浩特农村合作银行",
  "headquartersShortName": "二连浩特农村合作银行",
  "headquartersNo": "ELHTRCU"
}, {
  "headquartersName": "法国巴黎银行（中国）有限公司",
  "headquartersShortName": "法国巴黎",
  "headquartersNo": "BNPPARIBAS"
}, {
  "headquartersName": "法国外贸银行",
  "headquartersShortName": "法国外贸",
  "headquartersNo": "NBPBK"
}, {
  "headquartersName": "法国兴业银行",
  "headquartersShortName": "法国兴业",
  "headquartersNo": "SOCIETEGENERATE"
}, {
  "headquartersName": "繁峙县新田村镇银行",
  "headquartersShortName": "繁峙县新田村镇银行",
  "headquartersNo": "FZXXTCUNZB"
}, {
  "headquartersName": "范县德商村镇银行",
  "headquartersShortName": "范县德商村镇银行",
  "headquartersNo": "FXDSCUNZB"
}, {
  "headquartersName": "防城港防城国民村镇银行",
  "headquartersShortName": "防城港防城国民村镇银行",
  "headquartersNo": "FCGFCGMCUNZB"
}, {
  "headquartersName": "房县楚农商村镇银行",
  "headquartersShortName": "房县楚农商村镇银行",
  "headquartersNo": "FXCNSCUNZB"
}, {
  "headquartersName": "菲律宾首都银行及信托",
  "headquartersShortName": "菲律宾首都",
  "headquartersNo": "PHILIPPINESCB"
}, {
  "headquartersName": "费县梁邹村镇银行",
  "headquartersShortName": "费县梁邹村镇银行",
  "headquartersNo": "FXLZCUNZB"
}, {
  "headquartersName": "分宜九银村镇银行",
  "headquartersShortName": "分宜九银村镇银行",
  "headquartersNo": "FYJYCUNZB"
}, {
  "headquartersName": "汾西县太行村镇银行",
  "headquartersShortName": "汾西县太行村镇银行",
  "headquartersNo": "FXXTXCUNZB"
}, {
  "headquartersName": "汾西县亿通村镇银行",
  "headquartersShortName": "汾西县亿通村镇银行",
  "headquartersNo": "FXXYTCUNZB"
}, {
  "headquartersName": "汾阳市九都村镇银行",
  "headquartersShortName": "汾阳市九都村镇银行",
  "headquartersNo": "FYSJDCUNZB"
}, {
  "headquartersName": "丰城顺银村镇银行",
  "headquartersShortName": "丰城顺银村镇银行",
  "headquartersNo": "FCSYCUNZB"
}, {
  "headquartersName": "凤城丰益村镇银行",
  "headquartersShortName": "凤城丰益村镇银行",
  "headquartersNo": "FCFYCUNZB"
}, {
  "headquartersName": "奉化罗蒙村镇银行",
  "headquartersShortName": "奉化罗蒙村镇银行",
  "headquartersNo": "FHLMCUNZB"
}, {
  "headquartersName": "奉新九银村镇银行",
  "headquartersShortName": "奉新九银村镇银行",
  "headquartersNo": "FXJYCUNZB"
}, {
  "headquartersName": "扶风浦发村镇银行",
  "headquartersShortName": "扶风浦发村镇银行",
  "headquartersNo": "FFPFCUNZB"
}, {
  "headquartersName": "扶沟郑银村镇银行",
  "headquartersShortName": "扶沟郑银村镇银行",
  "headquartersNo": "FGZYCUNZB"
}, {
  "headquartersName": "扶绥深通村镇银行",
  "headquartersShortName": "扶绥深通村镇银行",
  "headquartersNo": "FSSTCUNZB"
}, {
  "headquartersName": "扶余惠民村镇银行",
  "headquartersShortName": "扶余惠民村镇银行",
  "headquartersNo": "FYHMCUNZB"
}, {
  "headquartersName": "佛山高明顺银村镇银行",
  "headquartersShortName": "佛山高明顺银村镇银行",
  "headquartersNo": "FSGMSYCUNZB"
}, {
  "headquartersName": "佛山南海新华村镇银行",
  "headquartersShortName": "佛山南海新华村镇银行",
  "headquartersNo": "FSNHXHCUNZB"
}, {
  "headquartersName": "佛山农村商业银行",
  "headquartersShortName": "佛山农商",
  "headquartersNo": "FSRCB"
}, {
  "headquartersName": "浮梁农商村镇银行",
  "headquartersShortName": "浮梁农商村镇银行",
  "headquartersNo": "FLNSCUNZB"
}, {
  "headquartersName": "福建安溪农村商业银行",
  "headquartersShortName": "福建安溪农商",
  "headquartersNo": "FJAXRCB"
}, {
  "headquartersName": "福建大田晋农商村镇银行",
  "headquartersShortName": "福建大田晋农商村镇银行",
  "headquartersNo": "FJDTJNSCUNZB"
}, {
  "headquartersName": "福建德化成功村镇银行",
  "headquartersShortName": "福建德化成功村镇银行",
  "headquartersNo": "FJDHCGCUNZB"
}, {
  "headquartersName": "福建福安渝农商村镇银行",
  "headquartersShortName": "福建福安渝农商村镇银行",
  "headquartersNo": "FJFAYNSCUNZB"
}, {
  "headquartersName": "福建福鼎恒兴村镇银行",
  "headquartersShortName": "福建福鼎恒兴村镇银行",
  "headquartersNo": "FJFDHXCUNZB"
}, {
  "headquartersName": "福建福清汇通农村商业银行",
  "headquartersShortName": "福建福清汇通农商",
  "headquartersNo": "FJFQHTRCB"
}, {
  "headquartersName": "福建福清泰隆村镇银行",
  "headquartersShortName": "福建福清泰隆村镇银行",
  "headquartersNo": "FJFQTLCUNZB"
}, {
  "headquartersName": "福建福州农村商业银行",
  "headquartersShortName": "福建福州农商",
  "headquartersNo": "FJFZRCB"
}, {
  "headquartersName": "福建海峡银行",
  "headquartersShortName": "福建海峡",
  "headquartersNo": "FJHXYH"
}, {
  "headquartersName": "福建华安长信村镇银行",
  "headquartersShortName": "福建华安长信村镇银行",
  "headquartersNo": "FJHACXCUNZB"
}, {
  "headquartersName": "福建华通银行",
  "headquartersShortName": "福建华通",
  "headquartersNo": "FJHTB"
}, {
  "headquartersName": "福建建瓯农村商业银行",
  "headquartersShortName": "福建建瓯农商",
  "headquartersNo": "FJJORCB"
}, {
  "headquartersName": "福建建瓯瑞狮村镇银行",
  "headquartersShortName": "福建建瓯瑞狮村镇银行",
  "headquartersNo": "FJJORSCUNZB"
}, {
  "headquartersName": "福建建阳瑞狮村镇银行",
  "headquartersShortName": "福建建阳瑞狮村镇银行",
  "headquartersNo": "FJJYRSCUNZB"
}, {
  "headquartersName": "福建将乐成功村镇银行",
  "headquartersShortName": "福建将乐成功村镇银行",
  "headquartersNo": "FJJLCGCUNZB"
}, {
  "headquartersName": "福建蕉城刺桐红村镇银行",
  "headquartersShortName": "福建蕉城刺桐红村镇银行",
  "headquartersNo": "FJJCCTHCUNZB"
}, {
  "headquartersName": "福建晋江农村商业银行",
  "headquartersShortName": "福建晋江农商",
  "headquartersNo": "FJJJRCB"
}, {
  "headquartersName": "福建连城杭兴村镇银行",
  "headquartersShortName": "福建连城杭兴村镇银行",
  "headquartersNo": "FJLCHXCUNZB"
}, {
  "headquartersName": "福建连江恒欣村镇银行",
  "headquartersShortName": "福建连江恒欣村镇银行",
  "headquartersNo": "FJLJHXCUNZB"
}, {
  "headquartersName": "福建连江农村商业银行",
  "headquartersShortName": "福建连江农商",
  "headquartersNo": "FJLJRCB"
}, {
  "headquartersName": "福建龙海农村商业银行",
  "headquartersShortName": "福建龙海农商",
  "headquartersNo": "FJLHRCB"
}, {
  "headquartersName": "福建龙海泰隆村镇银行",
  "headquartersShortName": "福建龙海泰隆村镇银行",
  "headquartersNo": "FJLHTLCUNZB"
}, {
  "headquartersName": "福建龙岩农村商业银行",
  "headquartersShortName": "福建龙岩农商",
  "headquartersNo": "FJLYRCB"
}, {
  "headquartersName": "福建罗源汇融村镇银行",
  "headquartersShortName": "福建罗源汇融村镇银行",
  "headquartersNo": "FJLYHRCUNZB"
}, {
  "headquartersName": "福建闽侯民本村镇银行",
  "headquartersShortName": "福建闽侯民本村镇银行",
  "headquartersNo": "FJMHMBCUNZB"
}, {
  "headquartersName": "福建闽清瑞狮村镇银行",
  "headquartersShortName": "福建闽清瑞狮村镇银行",
  "headquartersNo": "FJMQRSCUNZB"
}, {
  "headquartersName": "福建南安汇通村镇银行",
  "headquartersShortName": "福建南安汇通村镇银行",
  "headquartersNo": "FJNAHTCUNZB"
}, {
  "headquartersName": "福建南安农村商业银行",
  "headquartersShortName": "福建南安农商",
  "headquartersNo": "FJNARCB"
}, {
  "headquartersName": "福建南平农村商业银行",
  "headquartersShortName": "福建南平农商",
  "headquartersNo": "FJNPRCB"
}, {
  "headquartersName": "福建宁德农村商业银行",
  "headquartersShortName": "福建宁德农商",
  "headquartersNo": "FJNDRCB"
}, {
  "headquartersName": "福建宁化成功村镇银行",
  "headquartersShortName": "福建宁化成功村镇银行",
  "headquartersNo": "FJNHCGCUNZB"
}, {
  "headquartersName": "福建平潭农村商业银行",
  "headquartersShortName": "福建平潭农商",
  "headquartersNo": "FJPTANRCB"
}, {
  "headquartersName": "福建平潭渝农商村镇银行",
  "headquartersShortName": "福建平潭渝农商村镇银行",
  "headquartersNo": "FJPTYNSCUNZB"
}, {
  "headquartersName": "福建莆田农村商业银行",
  "headquartersShortName": "福建莆田农商",
  "headquartersNo": "FJPTRCB"
}, {
  "headquartersName": "福建泉州台商投资区晋农商村镇银行",
  "headquartersShortName": "福建泉州台商投资区晋农商村镇银行",
  "headquartersNo": "FJQZTSTZQJNSCUNZB"
}, {
  "headquartersName": "福建三明农村商业银行",
  "headquartersShortName": "福建三明农商",
  "headquartersNo": "FJSMRCB"
}, {
  "headquartersName": "福建沙县农村商业银行",
  "headquartersShortName": "福建沙县农商",
  "headquartersNo": "FJSXRCB"
}, {
  "headquartersName": "福建沙县渝农商村镇银行",
  "headquartersShortName": "福建沙县渝农商村镇银行",
  "headquartersNo": "FJSXYNSCUNZB"
}, {
  "headquartersName": "福建上杭农村商业银行",
  "headquartersShortName": "福建上杭农商",
  "headquartersNo": "FJSHRCB"
}, {
  "headquartersName": "福建邵武农村商业银行",
  "headquartersShortName": "福建邵武农商",
  "headquartersNo": "FJSWRCB"
}, {
  "headquartersName": "福建省农村信用社联合社",
  "headquartersShortName": "福建农信社",
  "headquartersNo": "FJNXS"
}, {
  "headquartersName": "福建石狮农村商业银行",
  "headquartersShortName": "福建石狮农商",
  "headquartersNo": "FJSSRCB"
}, {
  "headquartersName": "福建石狮渝农商村镇银行",
  "headquartersShortName": "福建石狮渝农商村镇银行",
  "headquartersNo": "FJSSYRSCUNZB"
}, {
  "headquartersName": "福建松溪长信村镇银行",
  "headquartersShortName": "福建松溪长信村镇银行",
  "headquartersNo": "FJSXCXCUNZB"
}, {
  "headquartersName": "福建泰宁晋农商村镇银行",
  "headquartersShortName": "福建泰宁晋农商村镇银行",
  "headquartersNo": "FJTNJNSCUNZB"
}, {
  "headquartersName": "福建武平杭兴村镇银行",
  "headquartersShortName": "福建武平杭兴村镇银行",
  "headquartersNo": "FJWPHXCUNZB"
}, {
  "headquartersName": "福建武夷山农村商业银行",
  "headquartersShortName": "福建武夷山农商",
  "headquartersNo": "FJWYSRCB"
}, {
  "headquartersName": "福建仙游农村商业银行",
  "headquartersShortName": "福建仙游农商",
  "headquartersNo": "FJXYRCB"
}, {
  "headquartersName": "福建仙游瑞狮村镇银行",
  "headquartersShortName": "福建仙游瑞狮村镇银行",
  "headquartersNo": "FJXYRSCUNZB"
}, {
  "headquartersName": "福建新罗晋农商村镇银行",
  "headquartersShortName": "福建新罗晋农商村镇银行",
  "headquartersNo": "FJXLJNSCUNZB"
}, {
  "headquartersName": "福建永安汇丰村镇银行",
  "headquartersShortName": "福建永安汇丰村镇银行",
  "headquartersNo": "FJYAHFCUNZB"
}, {
  "headquartersName": "福建永安农村商业银行",
  "headquartersShortName": "福建永安农商",
  "headquartersNo": "FJYARCB"
}, {
  "headquartersName": "福建永春漳农商村镇银行",
  "headquartersShortName": "福建永春漳农商村镇银行",
  "headquartersNo": "FJYCZNSCUNZB"
}, {
  "headquartersName": "福建永定瑞狮村镇银行",
  "headquartersShortName": "福建永定瑞狮村镇银行",
  "headquartersNo": "FJYDRSCUNZB"
}, {
  "headquartersName": "福建永泰长信村镇银行",
  "headquartersShortName": "福建永泰长信村镇银行",
  "headquartersNo": "FJYTCXRCB"
}, {
  "headquartersName": "福建尤溪成功村镇银行",
  "headquartersShortName": "福建尤溪成功村镇银行",
  "headquartersNo": "FJYXCGCUNZB"
}, {
  "headquartersName": "福建漳平民泰村镇银行",
  "headquartersShortName": "福建漳平民泰村镇银行",
  "headquartersNo": "FJZPMTCUNZB"
}, {
  "headquartersName": "福建漳平农村商业银行",
  "headquartersShortName": "福建漳平农商",
  "headquartersNo": "FJZPRCB"
}, {
  "headquartersName": "福建漳浦农村商业银行",
  "headquartersShortName": "福建漳浦农商",
  "headquartersNo": "FJZPURCB"
}, {
  "headquartersName": "福建漳州农村商业银行",
  "headquartersShortName": "福建漳州农商",
  "headquartersNo": "FJZZRCB"
}, {
  "headquartersName": "福建长乐农村商业银行",
  "headquartersShortName": "福建长乐农商",
  "headquartersNo": "FJCLRCB"
}, {
  "headquartersName": "福建长乐泰隆村镇银行",
  "headquartersShortName": "福建长乐泰隆村镇银行",
  "headquartersNo": "FJCLTLCUNZB"
}, {
  "headquartersName": "福建诏安汇通村镇银行",
  "headquartersShortName": "福建诏安汇通村镇银行",
  "headquartersNo": "FJZAHTCUNZB"
}, {
  "headquartersName": "福建政和泰隆村镇银行",
  "headquartersShortName": "福建政和泰隆村镇银行",
  "headquartersNo": "FJZHTLCUNZB"
}, {
  "headquartersName": "福泉富民村镇银行",
  "headquartersShortName": "福泉富民村镇银行",
  "headquartersNo": "FQFMCUNZB"
}, {
  "headquartersName": "抚顺东洲抚银村镇银行",
  "headquartersShortName": "抚顺东洲抚银村镇银行",
  "headquartersNo": "FSDZFYCUNZB"
}, {
  "headquartersName": "抚顺市清原村镇银行",
  "headquartersShortName": "抚顺市清原村镇银行",
  "headquartersNo": "FSSQYCUNZB"
}, {
  "headquartersName": "抚顺顺城抚银村镇银行",
  "headquartersShortName": "抚顺顺城抚银村镇银行",
  "headquartersNo": "FSSCFYCUNZB"
}, {
  "headquartersName": "抚顺望花抚银村镇银行",
  "headquartersShortName": "抚顺望花抚银村镇银行",
  "headquartersNo": "FSWHFYCUNZB"
}, {
  "headquartersName": "抚顺新宾抚银村镇银行",
  "headquartersShortName": "抚顺新宾抚银村镇银行",
  "headquartersNo": "FSXBFYCUNZB"
}, {
  "headquartersName": "抚顺银行",
  "headquartersShortName": "抚顺",
  "headquartersNo": "FSYH"
}, {
  "headquartersName": "抚松榆银村镇银行",
  "headquartersShortName": "抚松榆银村镇银行",
  "headquartersNo": "FSYYCUNZB"
}, {
  "headquartersName": "抚州东乡富民村镇银行",
  "headquartersShortName": "抚州东乡富民村镇银行",
  "headquartersNo": "FZDXFMCUNZB"
}, {
  "headquartersName": "抚州农村商业银行",
  "headquartersShortName": "抚州农商",
  "headquartersNo": "FZRCB"
}, {
  "headquartersName": "阜城家银村镇银行",
  "headquartersShortName": "阜城家银村镇银行",
  "headquartersNo": "FCJYCUNZB"
}, {
  "headquartersName": "阜康津汇村镇银行",
  "headquartersShortName": "阜康津汇村镇银行",
  "headquartersNo": "FKJHCUNZB"
}, {
  "headquartersName": "阜宁民生村镇银行",
  "headquartersShortName": "阜宁民生村镇银行",
  "headquartersNo": "FNMSCUNZB"
}, {
  "headquartersName": "阜平大商村镇银行",
  "headquartersShortName": "阜平大商村镇银行",
  "headquartersNo": "FPDSCUNZB"
}, {
  "headquartersName": "阜新农村商业银行",
  "headquartersShortName": "阜新农商",
  "headquartersNo": "FXRCB"
}, {
  "headquartersName": "阜新农商村镇银行",
  "headquartersShortName": "阜新农商村镇银行",
  "headquartersNo": "FXNSCUNZB"
}, {
  "headquartersName": "阜新银行",
  "headquartersShortName": "阜新",
  "headquartersNo": "FXYH"
}, {
  "headquartersName": "阜阳颍东农村商业银行",
  "headquartersShortName": "阜阳颍东农商",
  "headquartersNo": "FYYDRCB"
}, {
  "headquartersName": "阜阳颍淮农村商业银行",
  "headquartersShortName": "阜阳颍淮农商",
  "headquartersNo": "FYYHRCB"
}, {
  "headquartersName": "阜阳颍泉农村商业银行",
  "headquartersShortName": "阜阳颍泉农商",
  "headquartersNo": "FYYQRCB"
}, {
  "headquartersName": "富滇银行",
  "headquartersShortName": "富滇",
  "headquartersNo": "FDYH"
}, {
  "headquartersName": "富锦幸福村镇银行",
  "headquartersShortName": "富锦幸福村镇银行",
  "headquartersNo": "FJXFCUNZB"
}, {
  "headquartersName": "富民浦发村镇银行",
  "headquartersShortName": "富民浦发村镇银行",
  "headquartersNo": "FMPFCUNZB"
}, {
  "headquartersName": "富蕴中成村镇银行",
  "headquartersShortName": "富蕴中成村镇银行",
  "headquartersNo": "FYZCCUNZB"
}, {
  "headquartersName": "甘肃崇信农村商业银行",
  "headquartersShortName": "甘肃崇信农商",
  "headquartersNo": "GSCXRCB"
}, {
  "headquartersName": "甘肃敦煌农村商业银行",
  "headquartersShortName": "甘肃敦煌农商",
  "headquartersNo": "GSDHRCB"
}, {
  "headquartersName": "甘肃高台农村商业银行",
  "headquartersShortName": "甘肃高台农商",
  "headquartersNo": "GSGTRCB"
}, {
  "headquartersName": "甘肃古浪农村商业银行",
  "headquartersShortName": "甘肃古浪农商",
  "headquartersNo": "GSGLRCB"
}, {
  "headquartersName": "甘肃瓜州农村商业银行",
  "headquartersShortName": "甘肃瓜州农商",
  "headquartersNo": "GSGZRCB"
}, {
  "headquartersName": "甘肃华亭农村商业银行",
  "headquartersShortName": "甘肃华亭农商",
  "headquartersNo": "GSHTRCB"
}, {
  "headquartersName": "甘肃会宁农村商业银行",
  "headquartersShortName": "甘肃会宁农商",
  "headquartersNo": "GSHNRCB"
}, {
  "headquartersName": "甘肃泾川农村商业银行",
  "headquartersShortName": "甘肃泾川农商",
  "headquartersNo": "GSJCRCB"
}, {
  "headquartersName": "甘肃靖远农村商业银行",
  "headquartersShortName": "甘肃靖远农商",
  "headquartersNo": "GSJYRCB"
}, {
  "headquartersName": "甘肃静宁农村商业银行",
  "headquartersShortName": "甘肃静宁农商",
  "headquartersNo": "GSJNRCB"
}, {
  "headquartersName": "甘肃康县农村商业银行",
  "headquartersShortName": "甘肃康县农商",
  "headquartersNo": "GSKXRCB"
}, {
  "headquartersName": "甘肃礼县农村商业银行",
  "headquartersShortName": "甘肃礼县农商",
  "headquartersNo": "GSLIXRCB"
}, {
  "headquartersName": "甘肃两当农村商业银行",
  "headquartersShortName": "甘肃两当农商",
  "headquartersNo": "GSLDRCB"
}, {
  "headquartersName": "甘肃临洮农村商业银行",
  "headquartersShortName": "甘肃临洮农商",
  "headquartersNo": "GSLINTRCB"
}, {
  "headquartersName": "甘肃临夏农村商业银行",
  "headquartersShortName": "甘肃临夏农商",
  "headquartersNo": "GSLXRCB"
}, {
  "headquartersName": "甘肃临泽农村商业银行",
  "headquartersShortName": "甘肃临泽农商",
  "headquartersNo": "GSLZRCB"
}, {
  "headquartersName": "甘肃灵台农村商业银行",
  "headquartersShortName": "甘肃灵台农商",
  "headquartersNo": "GSLTRCB"
}, {
  "headquartersName": "甘肃陇西农村合作银行",
  "headquartersShortName": "甘肃陇西农村合作银行",
  "headquartersNo": "GSLXRCU"
}, {
  "headquartersName": "甘肃陇西农村商业银行",
  "headquartersShortName": "甘肃陇西农商",
  "headquartersNo": "GSLXIRCB"
}, {
  "headquartersName": "甘肃民乐农村商业银行",
  "headquartersShortName": "甘肃民乐农商",
  "headquartersNo": "GSMLRCB"
}, {
  "headquartersName": "甘肃民勤农村商业银行",
  "headquartersShortName": "甘肃民勤农商",
  "headquartersNo": "GSMQRCB"
}, {
  "headquartersName": "甘肃岷县农村商业银行",
  "headquartersShortName": "甘肃岷县农商",
  "headquartersNo": "GSMXRCB"
}, {
  "headquartersName": "甘肃宁县农村合作银行",
  "headquartersShortName": "甘肃宁县农村合作银行",
  "headquartersNo": "GSNXRCU"
}, {
  "headquartersName": "甘肃秦安农村商业银行",
  "headquartersShortName": "甘肃秦安农商",
  "headquartersNo": "GSQARCB"
}, {
  "headquartersName": "甘肃山丹农村商业银行",
  "headquartersShortName": "甘肃山丹农商",
  "headquartersNo": "GSSDRCB"
}, {
  "headquartersName": "甘肃省农村信用社联合社",
  "headquartersShortName": "甘肃农信社",
  "headquartersNo": "GSNXS"
}, {
  "headquartersName": "甘肃天祝农村商业银行",
  "headquartersShortName": "甘肃天祝农商",
  "headquartersNo": "GSTZRCB"
}, {
  "headquartersName": "甘肃西固金城村镇银行",
  "headquartersShortName": "甘肃西固金城村镇银行",
  "headquartersNo": "GSXGJCCUNZB"
}, {
  "headquartersName": "甘肃西和农村商业银行",
  "headquartersShortName": "甘肃西和农商",
  "headquartersNo": "GSXHRCB"
}, {
  "headquartersName": "甘肃银行",
  "headquartersShortName": "甘肃",
  "headquartersNo": "GSYH"
}, {
  "headquartersName": "甘肃永昌农村商业银行",
  "headquartersShortName": "甘肃永昌农商",
  "headquartersNo": "GSYCRCB"
}, {
  "headquartersName": "甘肃榆中农村合作银行",
  "headquartersShortName": "甘肃榆中农村合作银行",
  "headquartersNo": "GSYZRCU"
}, {
  "headquartersName": "甘肃玉门农村商业银行",
  "headquartersShortName": "甘肃玉门农商",
  "headquartersNo": "GSYMRCB"
}, {
  "headquartersName": "甘肃庄浪农村合作银行",
  "headquartersShortName": "甘肃庄浪农村合作银行",
  "headquartersNo": "GSZLRCU"
}, {
  "headquartersName": "甘肃庄浪农村商业银行",
  "headquartersShortName": "甘肃庄浪农商",
  "headquartersNo": "GSZLRCB"
}, {
  "headquartersName": "甘孜农村商业银行",
  "headquartersShortName": "甘孜农商",
  "headquartersNo": "SCGZRCB"
}, {
  "headquartersName": "赣州农村商业银行",
  "headquartersShortName": "赣州农商",
  "headquartersNo": "GANZRCB"
}, {
  "headquartersName": "赣州银行",
  "headquartersShortName": "赣州",
  "headquartersNo": "GZBC"
}, {
  "headquartersName": "高碑店中成村镇银行",
  "headquartersShortName": "高碑店中成村镇银行",
  "headquartersNo": "GBDZCCUNZB"
}, {
  "headquartersName": "高密惠民村镇银行",
  "headquartersShortName": "高密惠民村镇银行",
  "headquartersNo": "GMHMCUNZB"
}, {
  "headquartersName": "高平市太行村镇银行",
  "headquartersShortName": "高平市太行村镇银行",
  "headquartersNo": "GPSTXCUNZB"
}, {
  "headquartersName": "高唐青隆村镇银行",
  "headquartersShortName": "高唐青隆村镇银行",
  "headquartersNo": "GTQLCUNZB"
}, {
  "headquartersName": "藁城恒升村镇银行",
  "headquartersShortName": "藁城恒升村镇银行",
  "headquartersNo": "ZCHSCUNZB"
}, {
  "headquartersName": "个旧沪农商村镇银行",
  "headquartersShortName": "个旧沪农商村镇银行",
  "headquartersNo": "GJHNSCUNZB"
}, {
  "headquartersName": "公主岭华兴村镇银行",
  "headquartersShortName": "公主岭华兴村镇银行",
  "headquartersNo": "GZLHXCUNZB"
}, {
  "headquartersName": "公主岭浦发村镇银行",
  "headquartersShortName": "公主岭浦发村镇银行",
  "headquartersNo": "GZLPFCUNZB"
}, {
  "headquartersName": "巩义浦发村镇银行",
  "headquartersShortName": "巩义浦发村镇银行",
  "headquartersNo": "GYPFCUNZB"
}, {
  "headquartersName": "共青农村商业银行",
  "headquartersShortName": "共青农商",
  "headquartersNo": "GQRCB"
}, {
  "headquartersName": "古交市阜民村镇银行",
  "headquartersShortName": "古交市阜民村镇银行",
  "headquartersNo": "GJSFMCUNZB"
}, {
  "headquartersName": "古交市汇泽村镇银行",
  "headquartersShortName": "古交市汇泽村镇银行",
  "headquartersNo": "GJSHZCUNZB"
}, {
  "headquartersName": "古田刺桐红村镇银行",
  "headquartersShortName": "古田刺桐红村镇银行",
  "headquartersNo": "GTCTHCUNZB"
}, {
  "headquartersName": "固安恒升村镇银行",
  "headquartersShortName": "固安恒升村镇银行",
  "headquartersNo": "GAHSCUNZB"
}, {
  "headquartersName": "固始天骄村镇银行",
  "headquartersShortName": "固始天骄村镇银行",
  "headquartersNo": "GSTJCUNZB"
}, {
  "headquartersName": "固阳蒙商村镇银行",
  "headquartersShortName": "固阳蒙商村镇银行",
  "headquartersNo": "GYBSCUNZB"
}, {
  "headquartersName": "故城家银村镇银行",
  "headquartersShortName": "故城家银村镇银行",
  "headquartersNo": "GCJYCUNZB"
}, {
  "headquartersName": "关岭恒升村镇银行",
  "headquartersShortName": "关岭恒升村镇银行",
  "headquartersNo": "GLHSCUNZB"
}, {
  "headquartersName": "光泽刺桐红村镇银行",
  "headquartersShortName": "光泽刺桐红村镇银行",
  "headquartersNo": "GZCTHCUNZB"
}, {
  "headquartersName": "广安恒丰村镇银行",
  "headquartersShortName": "广安恒丰村镇银行",
  "headquartersNo": "GAHFCUNZB"
}, {
  "headquartersName": "广安农村商业银行",
  "headquartersShortName": "广安农商",
  "headquartersNo": "GARCB"
}, {
  "headquartersName": "广安思源农村商业银行",
  "headquartersShortName": "广安思源农商",
  "headquartersNo": "GASYRCB"
}, {
  "headquartersName": "广昌南银村镇银行",
  "headquartersShortName": "广昌南银村镇银行",
  "headquartersNo": "GCNYCUNZB"
}, {
  "headquartersName": "广东博罗农村商业银行",
  "headquartersShortName": "广东博罗农商",
  "headquartersNo": "GDBLRCB"
}, {
  "headquartersName": "广东潮阳农村商业银行",
  "headquartersShortName": "广东潮阳农商",
  "headquartersNo": "GDCYRCB"
}, {
  "headquartersName": "广东潮州农村商业银行",
  "headquartersShortName": "广东潮州农商",
  "headquartersNo": "GDCZRCB"
}, {
  "headquartersName": "广东澄海潮商村镇银行",
  "headquartersShortName": "广东澄海潮商村镇银行",
  "headquartersNo": "GDCHCSCUNZB"
}, {
  "headquartersName": "广东澄海农村商业银行",
  "headquartersShortName": "广东澄海农商",
  "headquartersNo": "GDCHRCB"
}, {
  "headquartersName": "广东大埔农村商业银行",
  "headquartersShortName": "广东大埔农商",
  "headquartersNo": "GDDPRCB"
}, {
  "headquartersName": "广东德庆农村商业银行",
  "headquartersShortName": "广东德庆农商",
  "headquartersNo": "GDDQRCB"
}, {
  "headquartersName": "广东东源农村商业银行",
  "headquartersShortName": "广东东源农商",
  "headquartersNo": "GDDYRCB"
}, {
  "headquartersName": "广东恩平汇丰村镇银行",
  "headquartersShortName": "广东恩平汇丰村镇银行",
  "headquartersNo": "GDEPHFCUNZB"
}, {
  "headquartersName": "广东丰顺农村商业银行",
  "headquartersShortName": "广东丰顺农商",
  "headquartersNo": "GDFSRCB"
}, {
  "headquartersName": "广东封开农村商业银行",
  "headquartersShortName": "广东封开农商",
  "headquartersNo": "GDFKRCB"
}, {
  "headquartersName": "广东佛冈农村商业银行",
  "headquartersShortName": "广东佛冈农商",
  "headquartersNo": "GDFGRCB"
}, {
  "headquartersName": "广东高州农村商业银行",
  "headquartersShortName": "广东高州农商",
  "headquartersNo": "GDGZRCB"
}, {
  "headquartersName": "广东广宁农村商业银行",
  "headquartersShortName": "广东广宁农商",
  "headquartersNo": "GDGNRCB"
}, {
  "headquartersName": "广东海丰农村商业银行",
  "headquartersShortName": "广东海丰农商",
  "headquartersNo": "GDHFRCB"
}, {
  "headquartersName": "广东和平农村商业银行",
  "headquartersShortName": "广东和平农商",
  "headquartersNo": "GDHPRCB"
}, {
  "headquartersName": "广东河源农村商业银行",
  "headquartersShortName": "广东河源农商",
  "headquartersNo": "GDHYNRCB"
}, {
  "headquartersName": "广东鹤山农村商业银行",
  "headquartersShortName": "广东鹤山农商",
  "headquartersNo": "GDHSRCB"
}, {
  "headquartersName": "广东华兴银行",
  "headquartersShortName": "广东华兴",
  "headquartersNo": "GDHXYH"
}, {
  "headquartersName": "广东化州农村商业银行",
  "headquartersShortName": "广东化州农商",
  "headquartersNo": "GDHUAZRCB"
}, {
  "headquartersName": "广东怀集农村商业银行",
  "headquartersShortName": "广东怀集农商",
  "headquartersNo": "GDHJRCB"
}, {
  "headquartersName": "广东惠东农村商业银行",
  "headquartersShortName": "广东惠东农商",
  "headquartersNo": "GDHDRCB"
}, {
  "headquartersName": "广东惠来农村商业银行",
  "headquartersShortName": "广东惠来农商",
  "headquartersNo": "GDHLRCB"
}, {
  "headquartersName": "广东惠州农村商业银行",
  "headquartersShortName": "广东惠州农商",
  "headquartersNo": "GDHZRCB"
}, {
  "headquartersName": "广东蕉岭农村商业银行",
  "headquartersShortName": "广东蕉岭农商",
  "headquartersNo": "GDJLRCB"
}, {
  "headquartersName": "广东揭东农村商业银行",
  "headquartersShortName": "广东揭东农商",
  "headquartersNo": "GDJDRCB"
}, {
  "headquartersName": "广东揭西农村商业银行",
  "headquartersShortName": "广东揭西农商",
  "headquartersNo": "GDJXRCB"
}, {
  "headquartersName": "广东揭阳农村商业银行",
  "headquartersShortName": "广东揭阳农商",
  "headquartersNo": "GDJYRCB"
}, {
  "headquartersName": "广东开平农村商业银行",
  "headquartersShortName": "广东开平农商",
  "headquartersNo": "GDKPRCB"
}, {
  "headquartersName": "广东乐昌农村商业银行",
  "headquartersShortName": "广东乐昌农商",
  "headquartersNo": "GDLECRCB"
}, {
  "headquartersName": "广东雷州农村商业银行",
  "headquartersShortName": "广东雷州农商",
  "headquartersNo": "GDLEIZRCB"
}, {
  "headquartersName": "广东连南农村商业银行",
  "headquartersShortName": "广东连南农商",
  "headquartersNo": "GDLNRCB"
}, {
  "headquartersName": "广东连平农村商业银行",
  "headquartersShortName": "广东连平农商",
  "headquartersNo": "GDLPRCB"
}, {
  "headquartersName": "广东连山农村商业银行",
  "headquartersShortName": "广东连山农商",
  "headquartersNo": "GDLSRCB"
}, {
  "headquartersName": "广东连州农村商业银行",
  "headquartersShortName": "广东连州农商",
  "headquartersNo": "GDLZRCB"
}, {
  "headquartersName": "广东廉江农村商业银行",
  "headquartersShortName": "广东廉江农商",
  "headquartersNo": "GDLJRCB"
}, {
  "headquartersName": "广东龙川农村商业银行",
  "headquartersShortName": "广东龙川农商",
  "headquartersNo": "GDLCRCB"
}, {
  "headquartersName": "广东龙门农村商业银行",
  "headquartersShortName": "广东龙门农商",
  "headquartersNo": "GDLMRCB"
}, {
  "headquartersName": "广东陆丰农村商业银行",
  "headquartersShortName": "广东陆丰农商",
  "headquartersNo": "GDLFRCB"
}, {
  "headquartersName": "广东陆河农村商业银行",
  "headquartersShortName": "广东陆河农商",
  "headquartersNo": "GDLHRCB"
}, {
  "headquartersName": "广东罗定农村商业银行",
  "headquartersShortName": "广东罗定农商",
  "headquartersNo": "GDLDRCB"
}, {
  "headquartersName": "广东茂名农村商业银行",
  "headquartersShortName": "广东茂名农商",
  "headquartersNo": "GDMMRCB"
}, {
  "headquartersName": "广东梅州农村商业银行",
  "headquartersShortName": "广东梅州农商",
  "headquartersNo": "GDMZRCB"
}, {
  "headquartersName": "广东南澳农村商业银行",
  "headquartersShortName": "广东南澳农商",
  "headquartersNo": "GDNARCB"
}, {
  "headquartersName": "广东南海农村商业银行",
  "headquartersShortName": "广东南海农商",
  "headquartersNo": "GDNHRCB"
}, {
  "headquartersName": "广东南雄农村商业银行",
  "headquartersShortName": "广东南雄农商",
  "headquartersNo": "GDNXRCB"
}, {
  "headquartersName": "广东南粤银行",
  "headquartersShortName": "南粤",
  "headquartersNo": "GDNYYH"
}, {
  "headquartersName": "广东平远农村商业银行",
  "headquartersShortName": "广东平远农商",
  "headquartersNo": "GDPYRCB"
}, {
  "headquartersName": "广东普宁汇成村镇银行",
  "headquartersShortName": "广东普宁汇成村镇银行",
  "headquartersNo": "GDPNHCCUNZB"
}, {
  "headquartersName": "广东普宁农村商业银行",
  "headquartersShortName": "广东普宁农商",
  "headquartersNo": "GDPNRCB"
}, {
  "headquartersName": "广东清新农村商业银行",
  "headquartersShortName": "广东清新农商",
  "headquartersNo": "GDQXRCB"
}, {
  "headquartersName": "广东清远农村商业银行",
  "headquartersShortName": "广东清远农商",
  "headquartersNo": "GDQYRCB"
}, {
  "headquartersName": "广东仁化农村商业银行",
  "headquartersShortName": "广东仁化农商",
  "headquartersNo": "GDRHRCB"
}, {
  "headquartersName": "广东乳源农村商业银行",
  "headquartersShortName": "广东乳源农商",
  "headquartersNo": "GDRYRCB"
}, {
  "headquartersName": "广东韶关农村商业银行",
  "headquartersShortName": "广东韶关农商",
  "headquartersNo": "GDSGRCB"
}, {
  "headquartersName": "广东省农村信用社联合社",
  "headquartersShortName": "广东农信社",
  "headquartersNo": "GDNXS"
}, {
  "headquartersName": "广东始兴农村商业银行",
  "headquartersShortName": "广东始兴农商",
  "headquartersNo": "GDSXRCB"
}, {
  "headquartersName": "广东四会农村商业银行",
  "headquartersShortName": "广东四会农商",
  "headquartersNo": "GDSHRCB"
}, {
  "headquartersName": "广东四会泰隆村镇银行",
  "headquartersShortName": "广东四会泰隆村镇银行",
  "headquartersNo": "GDSHTLCUNZB"
}, {
  "headquartersName": "广东遂溪农村商业银行",
  "headquartersShortName": "广东遂溪农商",
  "headquartersNo": "GDSXIRCB"
}, {
  "headquartersName": "广东台山农村商业银行",
  "headquartersShortName": "广东台山农商",
  "headquartersNo": "GDTSRCB"
}, {
  "headquartersName": "广东翁源农村商业银行",
  "headquartersShortName": "广东翁源农商",
  "headquartersNo": "GDWYRCB"
}, {
  "headquartersName": "广东吴川农村商业银行",
  "headquartersShortName": "广东吴川农商",
  "headquartersNo": "GDWCRCB"
}, {
  "headquartersName": "广东五华农村商业银行",
  "headquartersShortName": "广东五华农商",
  "headquartersNo": "GDWHRCB"
}, {
  "headquartersName": "广东新丰农村商业银行",
  "headquartersShortName": "广东新丰农商",
  "headquartersNo": "GDXFRCB"
}, {
  "headquartersName": "广东新兴农村商业银行",
  "headquartersShortName": "广东新兴农商",
  "headquartersNo": "GDXXRCB"
}, {
  "headquartersName": "广东信宜农村商业银行",
  "headquartersShortName": "广东信宜农商",
  "headquartersNo": "GDXYRCB"
}, {
  "headquartersName": "广东兴宁农村商业银行",
  "headquartersShortName": "广东兴宁农商",
  "headquartersNo": "GDXNRCB"
}, {
  "headquartersName": "广东徐闻农村商业银行",
  "headquartersShortName": "广东徐闻农商",
  "headquartersNo": "GDXWRCB"
}, {
  "headquartersName": "广东阳春农村商业银行",
  "headquartersShortName": "广东阳春农商",
  "headquartersNo": "GDYCRCB"
}, {
  "headquartersName": "广东阳江农村商业银行",
  "headquartersShortName": "广东阳江农商",
  "headquartersNo": "GDYJRCB"
}, {
  "headquartersName": "广东阳山农村商业银行",
  "headquartersShortName": "广东阳山农商",
  "headquartersNo": "GDYSRCB"
}, {
  "headquartersName": "广东阳西农村商业银行",
  "headquartersShortName": "广东阳西农商",
  "headquartersNo": "GDYXRCB"
}, {
  "headquartersName": "广东英德农村商业银行",
  "headquartersShortName": "广东英德农商",
  "headquartersNo": "GDYDERCB"
}, {
  "headquartersName": "广东英德泰隆村镇银行",
  "headquartersShortName": "广东英德泰隆村镇银行",
  "headquartersNo": "GDYDTLRCB"
}, {
  "headquartersName": "广东郁南农村商业银行",
  "headquartersShortName": "广东郁南农商",
  "headquartersNo": "GDYNRCB"
}, {
  "headquartersName": "广东云浮农村商业银行",
  "headquartersShortName": "广东云浮农商",
  "headquartersNo": "GDYFRCB"
}, {
  "headquartersName": "广东紫金农村商业银行",
  "headquartersShortName": "广东紫金农商",
  "headquartersNo": "GDZJRCB"
}, {
  "headquartersName": "广发银行",
  "headquartersShortName": "广发",
  "headquartersNo": "CGB"
}, {
  "headquartersName": "广丰广信村镇银行",
  "headquartersShortName": "广丰广信村镇银行",
  "headquartersNo": "GFGXCUNZB"
}, {
  "headquartersName": "广汉珠江村镇银行",
  "headquartersShortName": "广汉珠江村镇银行",
  "headquartersNo": "GHZJCUNZB"
}, {
  "headquartersName": "广饶梁邹村镇银行",
  "headquartersShortName": "广饶梁邹村镇银行",
  "headquartersNo": "GRLZCUNZB"
}, {
  "headquartersName": "广水楚农商村镇银行",
  "headquartersShortName": "广水楚农商村镇银行",
  "headquartersNo": "GSCNSCUNZB"
}, {
  "headquartersName": "广西巴马农村商业银行",
  "headquartersShortName": "广西巴马农商",
  "headquartersNo": "GXBMRCB"
}, {
  "headquartersName": "广西百色右江农村合作银行",
  "headquartersShortName": "广西百色右江农村合作银行",
  "headquartersNo": "GXBSYJRCU"
}, {
  "headquartersName": "广西北部湾银行",
  "headquartersShortName": "北部湾",
  "headquartersNo": "BBWYH"
}, {
  "headquartersName": "广西北流柳银村镇银行",
  "headquartersShortName": "广西北流柳银村镇银行",
  "headquartersNo": "GXBLLYCUNZB"
}, {
  "headquartersName": "广西宾阳农村商业银行",
  "headquartersShortName": "广西宾阳农商",
  "headquartersNo": "GXBYRCB"
}, {
  "headquartersName": "广西博白柳银村镇银行",
  "headquartersShortName": "广西博白柳银村镇银行",
  "headquartersNo": "GXBBLYCUNZB"
}, {
  "headquartersName": "广西苍梧农村商业银行",
  "headquartersShortName": "广西苍梧农商",
  "headquartersNo": "GXCWRCB"
}, {
  "headquartersName": "广西岑溪农村商业银行",
  "headquartersShortName": "广西岑溪农商",
  "headquartersNo": "GXCXRCB"
}, {
  "headquartersName": "广西崇左农村商业银行",
  "headquartersShortName": "广西崇左农商",
  "headquartersNo": "GXCZGNRCB"
}, {
  "headquartersName": "广西大新农村商业银行",
  "headquartersShortName": "广西大新农商",
  "headquartersNo": "GXDAXRCB"
}, {
  "headquartersName": "广西东兴农村商业银行",
  "headquartersShortName": "广西东兴农商",
  "headquartersNo": "GXDXRCB"
}, {
  "headquartersName": "广西都安农村商业银行",
  "headquartersShortName": "广西都安农商",
  "headquartersNo": "GXDARCB"
}, {
  "headquartersName": "广西扶绥农村商业银行",
  "headquartersShortName": "广西扶绥农商",
  "headquartersNo": "GXFSRCB"
}, {
  "headquartersName": "广西富川农村商业银行",
  "headquartersShortName": "广西富川农商",
  "headquartersNo": "GXFCRCB"
}, {
  "headquartersName": "广西恭城农村商业银行",
  "headquartersShortName": "广西恭城农商",
  "headquartersNo": "GXGCRCB"
}, {
  "headquartersName": "广西灌阳农村商业银行",
  "headquartersShortName": "广西灌阳农商",
  "headquartersNo": "GXGYRCB"
}, {
  "headquartersName": "广西贵港农村商业银行",
  "headquartersShortName": "广西贵港农商",
  "headquartersNo": "GXGGRCB"
}, {
  "headquartersName": "广西桂林漓江农村合作银行",
  "headquartersShortName": "广西桂林漓江农村合作银行",
  "headquartersNo": "GXGLLJRCU"
}, {
  "headquartersName": "广西桂平桂银村镇银行",
  "headquartersShortName": "广西桂平桂银村镇银行",
  "headquartersNo": "GXGPGYCUNZB"
}, {
  "headquartersName": "广西贺州桂东农村合作银行",
  "headquartersShortName": "广西贺州桂东农村合作银行",
  "headquartersNo": "GXHZGDRCU"
}, {
  "headquartersName": "广西横县桂商村镇银行",
  "headquartersShortName": "广西横县桂商村镇银行",
  "headquartersNo": "GXHXGSCUNZB"
}, {
  "headquartersName": "广西金秀农村商业银行",
  "headquartersShortName": "广西金秀农商",
  "headquartersNo": "GXJINXRCB"
}, {
  "headquartersName": "广西靖西农村商业银行",
  "headquartersShortName": "广西靖西农商",
  "headquartersNo": "GXJXRCB"
}, {
  "headquartersName": "广西来宾桂中农村合作银行",
  "headquartersShortName": "广西来宾桂中农村合作银行",
  "headquartersNo": "GXLBGZRCU"
}, {
  "headquartersName": "广西乐业农村商业银行",
  "headquartersShortName": "广西乐业农商",
  "headquartersNo": "GXLEYRCB"
}, {
  "headquartersName": "广西荔浦农村商业银行",
  "headquartersShortName": "广西荔浦农商",
  "headquartersNo": "GXLPRCB"
}, {
  "headquartersName": "广西临桂农村商业银行",
  "headquartersShortName": "广西临桂农商",
  "headquartersNo": "GXLGRCB"
}, {
  "headquartersName": "广西灵川农村商业银行",
  "headquartersShortName": "广西灵川农商",
  "headquartersNo": "GXLINGCRCB"
}, {
  "headquartersName": "广西凌云农村商业银行",
  "headquartersShortName": "广西凌云农商",
  "headquartersNo": "GXLYRCB"
}, {
  "headquartersName": "广西柳城农村商业银行",
  "headquartersShortName": "广西柳城农商",
  "headquartersNo": "GXLCRCU"
}, {
  "headquartersName": "广西柳江柳银村镇银行",
  "headquartersShortName": "广西柳江柳银村镇银行",
  "headquartersNo": "GXLJLYCUNZB"
}, {
  "headquartersName": "广西柳江农村合作银行",
  "headquartersShortName": "广西柳江农村合作银行",
  "headquartersNo": "GXLJRCU"
}, {
  "headquartersName": "广西龙胜农村商业银行",
  "headquartersShortName": "广西龙胜农商",
  "headquartersNo": "GXLSRCB"
}, {
  "headquartersName": "广西龙州农村商业银行",
  "headquartersShortName": "广西龙州农商",
  "headquartersNo": "GXLZRCB"
}, {
  "headquartersName": "广西隆安农村商业银行",
  "headquartersShortName": "广西隆安农商",
  "headquartersNo": "GXLARCB"
}, {
  "headquartersName": "广西隆林农村商业银行",
  "headquartersShortName": "广西隆林农商",
  "headquartersNo": "GXLLRCB"
}, {
  "headquartersName": "广西陆川柳银村镇银行",
  "headquartersShortName": "广西陆川柳银村镇银行",
  "headquartersNo": "GXLCLYCUNZB"
}, {
  "headquartersName": "广西陆川农村商业银行",
  "headquartersShortName": "广西陆川农商",
  "headquartersNo": "GXLCRCB"
}, {
  "headquartersName": "广西鹿寨农村商业银行",
  "headquartersShortName": "广西鹿寨农商",
  "headquartersNo": "GXLUZRCB"
}, {
  "headquartersName": "广西鹿寨渝农商村镇银行",
  "headquartersShortName": "广西鹿寨渝农商村镇银行",
  "headquartersNo": "GXLZYNSCUNZB"
}, {
  "headquartersName": "广西罗城农村商业银行",
  "headquartersShortName": "广西罗城农商",
  "headquartersNo": "GXLUOCRCB"
}, {
  "headquartersName": "广西马山农村商业银行",
  "headquartersShortName": "广西马山农商",
  "headquartersNo": "GXMASRCB"
}, {
  "headquartersName": "广西蒙山农村商业银行",
  "headquartersShortName": "广西蒙山农商",
  "headquartersNo": "GXMSRCB"
}, {
  "headquartersName": "广西那坡农村商业银行",
  "headquartersShortName": "广西那坡农商",
  "headquartersNo": "GXNPRCB"
}, {
  "headquartersName": "广西宁明农村商业银行",
  "headquartersShortName": "广西宁明农商",
  "headquartersNo": "GXNMRCB"
}, {
  "headquartersName": "广西平果农村合作银行",
  "headquartersShortName": "广西平果农村合作银行",
  "headquartersNo": "GXPGRCU"
}, {
  "headquartersName": "广西平乐农村合作银行",
  "headquartersShortName": "广西平乐农村合作银行",
  "headquartersNo": "GXPLRCU"
}, {
  "headquartersName": "广西平南桂银村镇银行",
  "headquartersShortName": "广西平南桂银村镇银行",
  "headquartersNo": "GXPNGYCUNZB"
}, {
  "headquartersName": "广西凭祥农村商业银行",
  "headquartersShortName": "广西凭祥农商",
  "headquartersNo": "GXPXRCB"
}, {
  "headquartersName": "广西浦北国民村镇银行",
  "headquartersShortName": "广西浦北国民村镇银行",
  "headquartersNo": "GXPBGMCUNZB"
}, {
  "headquartersName": "广西浦北农村商业银行",
  "headquartersShortName": "广西浦北农商",
  "headquartersNo": "GXPBRCB"
}, {
  "headquartersName": "广西钦州市钦南国民村镇银行",
  "headquartersShortName": "广西钦州市钦南国民村镇银行",
  "headquartersNo": "GXQZSQNGMCUNZB"
}, {
  "headquartersName": "广西全州农村合作银行",
  "headquartersShortName": "广西全州农村合作银行",
  "headquartersNo": "GXQZRCU"
}, {
  "headquartersName": "广西容县桂银村镇银行",
  "headquartersShortName": "广西容县桂银村镇银行",
  "headquartersNo": "GXRXGYCUNZB"
}, {
  "headquartersName": "广西融安农村商业银行",
  "headquartersShortName": "广西融安农商",
  "headquartersNo": "GXRARCB"
}, {
  "headquartersName": "广西融水柳银村镇银行",
  "headquartersShortName": "广西融水柳银村镇银行",
  "headquartersNo": "GXRSLYCUNZB"
}, {
  "headquartersName": "广西融水农村商业银行",
  "headquartersShortName": "广西融水农商",
  "headquartersNo": "GXRSRCB"
}, {
  "headquartersName": "广西三江农村商业银行",
  "headquartersShortName": "广西三江农商",
  "headquartersNo": "GXSJRCB"
}, {
  "headquartersName": "广西上林国民村镇银行",
  "headquartersShortName": "广西上林国民村镇银行",
  "headquartersNo": "GXSLGMCUNZB"
}, {
  "headquartersName": "广西上林农村商业银行",
  "headquartersShortName": "广西上林农商",
  "headquartersNo": "GXSLRCB"
}, {
  "headquartersName": "广西上思农村商业银行",
  "headquartersShortName": "广西上思农商",
  "headquartersNo": "GXSSRCB"
}, {
  "headquartersName": "广西藤县桂银村镇银行",
  "headquartersShortName": "广西藤县桂银村镇银行",
  "headquartersNo": "GXTXGYCUNZB"
}, {
  "headquartersName": "广西天等农村商业银行",
  "headquartersShortName": "广西天等农商",
  "headquartersNo": "GXTDRCB"
}, {
  "headquartersName": "广西天峨农村商业银行",
  "headquartersShortName": "广西天峨农商",
  "headquartersNo": "GXTERCB"
}, {
  "headquartersName": "广西田东农村商业银行",
  "headquartersShortName": "广西田东农商",
  "headquartersNo": "GXTDONGRCB"
}, {
  "headquartersName": "广西田林农村商业银行",
  "headquartersShortName": "广西田林农商",
  "headquartersNo": "GXTLRCB"
}, {
  "headquartersName": "广西田阳农村商业银行",
  "headquartersShortName": "广西田阳农商",
  "headquartersNo": "GXTYRCB"
}, {
  "headquartersName": "广西武宣农村商业银行",
  "headquartersShortName": "广西武宣农商",
  "headquartersNo": "GXWXRCB"
}, {
  "headquartersName": "广西西林农村商业银行",
  "headquartersShortName": "广西西林农商",
  "headquartersNo": "GXXLRCB"
}, {
  "headquartersName": "广西象州农村合作银行",
  "headquartersShortName": "广西象州农村合作银行",
  "headquartersNo": "GXXZRCU"
}, {
  "headquartersName": "广西兴安民兴村镇银行",
  "headquartersShortName": "广西兴安民兴村镇银行",
  "headquartersNo": "GXXAMXCUNZB"
}, {
  "headquartersName": "广西兴安农村合作银行",
  "headquartersShortName": "广西兴安农村合作银行",
  "headquartersNo": "GXXARCU"
}, {
  "headquartersName": "广西兴业柳银村镇银行",
  "headquartersShortName": "广西兴业柳银村镇银行",
  "headquartersNo": "GXXYLYCUNZB"
}, {
  "headquartersName": "广西阳朔农村合作银行",
  "headquartersShortName": "广西阳朔农村合作银行",
  "headquartersNo": "GXYSRCU"
}, {
  "headquartersName": "广西宜州农村合作银行",
  "headquartersShortName": "广西宜州农村合作银行",
  "headquartersNo": "GXYZRCU"
}, {
  "headquartersName": "广西银海国民村镇银行",
  "headquartersShortName": "广西银海国民村镇银行",
  "headquartersNo": "GXYHGMCUNZB"
}, {
  "headquartersName": "广西永福农村商业银行",
  "headquartersShortName": "广西永福农商",
  "headquartersNo": "GXYFRCU"
}, {
  "headquartersName": "广西鱼峰信合村镇银行",
  "headquartersShortName": "广西鱼峰信合村镇银行",
  "headquartersNo": "GXYFXHCUNZB"
}, {
  "headquartersName": "广西昭平农村商业银行",
  "headquartersShortName": "广西昭平农商",
  "headquartersNo": "GXZPRCB"
}, {
  "headquartersName": "广西壮族自治区农村信用社联合社",
  "headquartersShortName": "广西农信社",
  "headquartersNo": "GXNXS"
}, {
  "headquartersName": "广西资源农村商业银行",
  "headquartersShortName": "广西资源农商",
  "headquartersNo": "GXZYRCB"
}, {
  "headquartersName": "广元市发展村镇银行",
  "headquartersShortName": "广元市发展村镇银行",
  "headquartersNo": "GYSBSGMCUNZB"
}, {
  "headquartersName": "广元市贵商村镇银行",
  "headquartersShortName": "广元市贵商村镇银行",
  "headquartersNo": "GYSGSCUNZB"
}, {
  "headquartersName": "广州白云民泰村镇银行",
  "headquartersShortName": "广州白云民泰村镇银行",
  "headquartersNo": "GZBYMTCUNZB"
}, {
  "headquartersName": "广州从化柳银村镇银行",
  "headquartersShortName": "广州从化柳银村镇银行",
  "headquartersNo": "GZCHLYCUNZB"
}, {
  "headquartersName": "广州番禺新华村镇银行",
  "headquartersShortName": "广州番禺新华村镇银行",
  "headquartersNo": "GZFZXHCUNZB"
}, {
  "headquartersName": "广州花都稠州村镇银行",
  "headquartersShortName": "广州花都稠州村镇银行",
  "headquartersNo": "GZHDCZCUNZB"
}, {
  "headquartersName": "广州黄埔惠民村镇银行",
  "headquartersShortName": "广州黄埔惠民村镇银行",
  "headquartersNo": "GZHPHMCUNZB"
}, {
  "headquartersName": "广州黄埔融和村镇银行",
  "headquartersShortName": "广州黄埔融和村镇银行",
  "headquartersNo": "GZHPRHCUNZB"
}, {
  "headquartersName": "广州农村商业银行",
  "headquartersShortName": "广州农商",
  "headquartersNo": "GZRCB"
}, {
  "headquartersName": "广州银行",
  "headquartersShortName": "广州",
  "headquartersNo": "GUAZYH"
}, {
  "headquartersName": "广州增城长江村镇银行",
  "headquartersShortName": "广州增城长江村镇银行",
  "headquartersNo": "GZZCCJCUNZB"
}, {
  "headquartersName": "贵安新区发展村镇银行",
  "headquartersShortName": "贵安新区发展村镇银行",
  "headquartersNo": "GAXQFZCUNZB"
}, {
  "headquartersName": "贵定恒升村镇银行",
  "headquartersShortName": "贵定恒升村镇银行",
  "headquartersNo": "GDHSCUNZB"
}, {
  "headquartersName": "贵溪九银村镇银行",
  "headquartersShortName": "贵溪九银村镇银行",
  "headquartersNo": "GXJYCUNZB"
}, {
  "headquartersName": "贵阳白云德信村镇银行",
  "headquartersShortName": "贵阳白云德信村镇银行",
  "headquartersNo": "GYBYDXCUNZB"
}, {
  "headquartersName": "贵阳观山湖富民村镇银行",
  "headquartersShortName": "贵阳观山湖富民村镇银行",
  "headquartersNo": "GYGSHFMCUNZB"
}, {
  "headquartersName": "贵阳花溪建设村镇银行",
  "headquartersShortName": "贵阳花溪建设村镇银行",
  "headquartersNo": "GYHXJSCUNZB"
}, {
  "headquartersName": "贵阳南明富民村镇银行",
  "headquartersShortName": "贵阳南明富民村镇银行",
  "headquartersNo": "GYNMFMCUNZB"
}, {
  "headquartersName": "贵阳农村商业银行",
  "headquartersShortName": "贵阳农商",
  "headquartersNo": "GYRCB"
}, {
  "headquartersName": "贵阳乌当富民村镇银行",
  "headquartersShortName": "贵阳乌当富民村镇银行",
  "headquartersNo": "GYWDFMCUNZB"
}, {
  "headquartersName": "贵阳小河科技村镇银行",
  "headquartersShortName": "贵阳小河科技村镇银行",
  "headquartersNo": "GYXHKJCUNZB"
}, {
  "headquartersName": "贵阳银行",
  "headquartersShortName": "贵阳",
  "headquartersNo": "GYYH"
}, {
  "headquartersName": "贵阳云岩富民村镇银行",
  "headquartersShortName": "贵阳云岩富民村镇银行",
  "headquartersNo": "GYYYFMCUNZB"
}, {
  "headquartersName": "贵州安龙农村商业银行",
  "headquartersShortName": "贵州安龙农商",
  "headquartersNo": "GZALRCB"
}, {
  "headquartersName": "贵州毕节农村商业银行",
  "headquartersShortName": "贵州毕节农商",
  "headquartersNo": "GZBJRCB"
}, {
  "headquartersName": "贵州册亨农村商业银行",
  "headquartersShortName": "贵州册亨农商",
  "headquartersNo": "GZCHRCB"
}, {
  "headquartersName": "贵州从江农村商业银行",
  "headquartersShortName": "贵州从江农商",
  "headquartersNo": "GZCJRCB"
}, {
  "headquartersName": "贵州大方农村商业银行",
  "headquartersShortName": "贵州大方农商",
  "headquartersNo": "GZDFRCB"
}, {
  "headquartersName": "贵州丹寨农村商业银行",
  "headquartersShortName": "贵州丹寨农商",
  "headquartersNo": "GZDZRCB"
}, {
  "headquartersName": "贵州道真农村商业银行",
  "headquartersShortName": "贵州道真农商",
  "headquartersNo": "GZDAOZRCB"
}, {
  "headquartersName": "贵州都匀农村商业银行",
  "headquartersShortName": "贵州都匀农商",
  "headquartersNo": "GZDYRCB"
}, {
  "headquartersName": "贵州独山农村商业银行",
  "headquartersShortName": "贵州独山农商",
  "headquartersNo": "GZDSRCB"
}, {
  "headquartersName": "贵州凤冈农村商业银行",
  "headquartersShortName": "贵州凤冈农商",
  "headquartersNo": "GZFGRCB"
}, {
  "headquartersName": "贵州福泉农村商业银行",
  "headquartersShortName": "贵州福泉农商",
  "headquartersNo": "GZFQRCB"
}, {
  "headquartersName": "贵州关岭农村商业银行",
  "headquartersShortName": "贵州关岭农商",
  "headquartersNo": "GZGLRCB"
}, {
  "headquartersName": "贵州贵定农村商业银行",
  "headquartersShortName": "贵州贵定农商",
  "headquartersNo": "GZGDRCB"
}, {
  "headquartersName": "贵州赫章农村商业银行",
  "headquartersShortName": "贵州赫章农商",
  "headquartersNo": "GZHZRCB"
}, {
  "headquartersName": "贵州花溪农村商业银行",
  "headquartersShortName": "贵州花溪农商",
  "headquartersNo": "GZHXRCB"
}, {
  "headquartersName": "贵州黄平农村商业银行",
  "headquartersShortName": "贵州黄平农商",
  "headquartersNo": "GZHPRCB"
}, {
  "headquartersName": "贵州惠水农村商业银行",
  "headquartersShortName": "贵州惠水农商",
  "headquartersNo": "GZHSRCB"
}, {
  "headquartersName": "贵州剑河农村商业银行",
  "headquartersShortName": "贵州剑河农商",
  "headquartersNo": "GZJHRCB"
}, {
  "headquartersName": "贵州凯里农村商业银行",
  "headquartersShortName": "贵州凯里农商",
  "headquartersNo": "GZKLRCB"
}, {
  "headquartersName": "贵州黎平农村商业银行",
  "headquartersShortName": "贵州黎平农商",
  "headquartersNo": "GZLPRCB"
}, {
  "headquartersName": "贵州荔波农村商业银行",
  "headquartersShortName": "贵州荔波农商",
  "headquartersNo": "GZLBRCB"
}, {
  "headquartersName": "贵州龙里农村商业银行",
  "headquartersShortName": "贵州龙里农商",
  "headquartersNo": "GZLLRCB"
}, {
  "headquartersName": "贵州麻江农村商业银行",
  "headquartersShortName": "贵州麻江农商",
  "headquartersNo": "GZMJRCB"
}, {
  "headquartersName": "贵州湄潭农村商业银行",
  "headquartersShortName": "贵州湄潭农商",
  "headquartersNo": "GZMTRCB"
}, {
  "headquartersName": "贵州平塘农村商业银行",
  "headquartersShortName": "贵州平塘农商",
  "headquartersNo": "GZPTRCB"
}, {
  "headquartersName": "贵州普安农村商业银行",
  "headquartersShortName": "贵州普安农商",
  "headquartersNo": "GZPARCB"
}, {
  "headquartersName": "贵州普定农村商业银行",
  "headquartersShortName": "贵州普定农商",
  "headquartersNo": "GZPDRCB"
}, {
  "headquartersName": "贵州黔西农村商业银行",
  "headquartersShortName": "贵州黔西农商",
  "headquartersNo": "GZQXRCB"
}, {
  "headquartersName": "贵州清镇农村商业银行",
  "headquartersShortName": "贵州清镇农商",
  "headquartersNo": "GZQZRCB"
}, {
  "headquartersName": "贵州晴隆农村商业银行",
  "headquartersShortName": "贵州晴隆农商",
  "headquartersNo": "GZQLRCB"
}, {
  "headquartersName": "贵州仁怀茅台农村商业银行",
  "headquartersShortName": "贵州仁怀茅台农商",
  "headquartersNo": "GZRHMRCB"
}, {
  "headquartersName": "贵州三都农村商业银行",
  "headquartersShortName": "贵州三都农商",
  "headquartersNo": "GZSDRCB"
}, {
  "headquartersName": "贵州三穗农村商业银行",
  "headquartersShortName": "贵州三穗农商",
  "headquartersNo": "GZSSRCB"
}, {
  "headquartersName": "贵州省农村信用社联合社",
  "headquartersShortName": "贵州农信社",
  "headquartersNo": "GZNXS"
}, {
  "headquartersName": "贵州思南农村商业银行",
  "headquartersShortName": "贵州思南农商",
  "headquartersNo": "GZSNRCB"
}, {
  "headquartersName": "贵州天柱农村商业银行",
  "headquartersShortName": "贵州天柱农商",
  "headquartersNo": "GZTZRCB"
}, {
  "headquartersName": "贵州桐梓农村商业银行",
  "headquartersShortName": "贵州桐梓农商",
  "headquartersNo": "GZTZIRCB"
}, {
  "headquartersName": "贵州望谟农村商业银行",
  "headquartersShortName": "贵州望谟农商",
  "headquartersNo": "GZWMRCB"
}, {
  "headquartersName": "贵州瓮安农村商业银行",
  "headquartersShortName": "贵州瓮安农商",
  "headquartersNo": "GZWARCB"
}, {
  "headquartersName": "贵州乌当农村商业银行",
  "headquartersShortName": "贵州乌当农商",
  "headquartersNo": "GZWDRCB"
}, {
  "headquartersName": "贵州务川农村商业银行",
  "headquartersShortName": "贵州务川农商",
  "headquartersNo": "GZWCRCB"
}, {
  "headquartersName": "贵州息烽农村商业银行",
  "headquartersShortName": "贵州息烽农商",
  "headquartersNo": "GZXFRCB"
}, {
  "headquartersName": "贵州习水农村商业银行",
  "headquartersShortName": "贵州习水农商",
  "headquartersNo": "GZXSRCB"
}, {
  "headquartersName": "贵州兴仁农村商业银行",
  "headquartersShortName": "贵州兴仁农商",
  "headquartersNo": "GZXRRCB"
}, {
  "headquartersName": "贵州兴义农村商业银行",
  "headquartersShortName": "贵州兴义农商",
  "headquartersNo": "GZXYRCB"
}, {
  "headquartersName": "贵州修文农村商业银行",
  "headquartersShortName": "贵州修文农商",
  "headquartersNo": "GZXWRCB"
}, {
  "headquartersName": "贵州银行",
  "headquartersShortName": "贵州",
  "headquartersNo": "GUZYH"
}, {
  "headquartersName": "贵州印江农村商业银行",
  "headquartersShortName": "贵州印江农商",
  "headquartersNo": "GZYJRCB"
}, {
  "headquartersName": "贵州余庆农村商业银行",
  "headquartersShortName": "贵州余庆农商",
  "headquartersNo": "GZYQRCB"
}, {
  "headquartersName": "贵州玉屏农村商业银行",
  "headquartersShortName": "贵州玉屏农商",
  "headquartersNo": "GZYPRCB"
}, {
  "headquartersName": "贵州贞丰农村商业银行",
  "headquartersShortName": "贵州贞丰农商",
  "headquartersNo": "GZZFRCB"
}, {
  "headquartersName": "贵州镇宁农村商业银行",
  "headquartersShortName": "贵州镇宁农商",
  "headquartersNo": "GZZNRCB"
}, {
  "headquartersName": "贵州镇远农村商业银行",
  "headquartersShortName": "贵州镇远农商",
  "headquartersNo": "GZZYUANRCB"
}, {
  "headquartersName": "贵州织金农村商业银行",
  "headquartersShortName": "贵州织金农商",
  "headquartersNo": "GZZJRCB"
}, {
  "headquartersName": "贵州紫云农村商业银行",
  "headquartersShortName": "贵州紫云农商",
  "headquartersNo": "GZZYRCB"
}, {
  "headquartersName": "贵州遵义红花岗农村商业银行",
  "headquartersShortName": "贵州遵义红花岗农商",
  "headquartersNo": "GZZYHHGRCB"
}, {
  "headquartersName": "贵州遵义汇川农村商业银行",
  "headquartersShortName": "贵州遵义汇川农商",
  "headquartersNo": "GZZYHCRCB"
}, {
  "headquartersName": "贵州遵义农村商业银行",
  "headquartersShortName": "贵州遵义农商",
  "headquartersNo": "GZZYIRCB"
}, {
  "headquartersName": "桂林国民村镇银行",
  "headquartersShortName": "桂林国民村镇银行",
  "headquartersNo": "GLGMCUNZB"
}, {
  "headquartersName": "桂林银行",
  "headquartersShortName": "桂林",
  "headquartersNo": "GLYH"
}, {
  "headquartersName": "桂阳沪农商村镇银行",
  "headquartersShortName": "桂阳沪农商村镇银行",
  "headquartersNo": "GYHNSCUNZB"
}, {
  "headquartersName": "国家开发银行",
  "headquartersShortName": "国开",
  "headquartersNo": "CDBB"
}, {
  "headquartersName": "国民银行（中国）有限公司",
  "headquartersShortName": "国民",
  "headquartersNo": "GMYHZHYXGS"
}, {
  "headquartersName": "国泰世华商业银行",
  "headquartersShortName": "国泰世华",
  "headquartersNo": "CATHAYBK"
}, {
  "headquartersName": "哈尔滨阿城农商村镇银行",
  "headquartersShortName": "哈尔滨阿城农商村镇银行",
  "headquartersNo": "HEBACNSCUNZB"
}, {
  "headquartersName": "哈尔滨宾洲村镇银行",
  "headquartersShortName": "哈尔滨宾洲村镇银行",
  "headquartersNo": "HEBBZCUNZB"
}, {
  "headquartersName": "哈尔滨呼兰浦发村镇银行",
  "headquartersShortName": "哈尔滨呼兰浦发村镇银行",
  "headquartersNo": "HEBHLPFCUNZB"
}, {
  "headquartersName": "哈尔滨农村商业银行",
  "headquartersShortName": "哈尔滨农商",
  "headquartersNo": "HEBRCB"
}, {
  "headquartersName": "哈尔滨农信村镇银行",
  "headquartersShortName": "哈尔滨农信村镇银行",
  "headquartersNo": "HEBNXCUNZB"
}, {
  "headquartersName": "哈尔滨幸福村镇银行",
  "headquartersShortName": "哈尔滨幸福村镇银行",
  "headquartersNo": "HEBXFCUNZB"
}, {
  "headquartersName": "哈尔滨银行",
  "headquartersShortName": "哈尔滨",
  "headquartersNo": "HEBYH"
}, {
  "headquartersName": "哈密红星国民村镇银行",
  "headquartersShortName": "哈密红星国民村镇银行",
  "headquartersNo": "HMHXGMCUNZB"
}, {
  "headquartersName": "哈密市商业银行",
  "headquartersShortName": "哈密",
  "headquartersNo": "HMSCCB"
}, {
  "headquartersName": "哈密天山村镇银行",
  "headquartersShortName": "哈密天山村镇银行",
  "headquartersNo": "HMTSCUNZB"
}, {
  "headquartersName": "海口联合农村商业银行",
  "headquartersShortName": "海口联合农商",
  "headquartersNo": "HKLHRCB"
}, {
  "headquartersName": "海口农村商业银行",
  "headquartersShortName": "海口农商",
  "headquartersNo": "HKRCB"
}, {
  "headquartersName": "海口苏南村镇银行",
  "headquartersShortName": "海口苏南村镇银行",
  "headquartersNo": "HKSNCUNZB"
}, {
  "headquartersName": "海林远东村镇银行",
  "headquartersShortName": "海林远东村镇银行",
  "headquartersNo": "HLYDCUNZB"
}, {
  "headquartersName": "海伦惠丰村镇银行",
  "headquartersShortName": "海伦惠丰村镇银行",
  "headquartersNo": "HLHFCUNZB"
}, {
  "headquartersName": "海南白沙农村商业银行",
  "headquartersShortName": "海南白沙农商",
  "headquartersNo": "HNBSRCB"
}, {
  "headquartersName": "海南白沙长江村镇银行",
  "headquartersShortName": "海南白沙长江村镇银行",
  "headquartersNo": "HNBSCJCUNZB"
}, {
  "headquartersName": "海南保亭融兴村镇银行",
  "headquartersShortName": "海南保亭融兴村镇银行",
  "headquartersNo": "HNBTRXCUNZB"
}, {
  "headquartersName": "海南昌江长江村镇银行",
  "headquartersShortName": "海南昌江长江村镇银行",
  "headquartersNo": "HNCJCJCUNZB"
}, {
  "headquartersName": "海南澄迈农村商业银行",
  "headquartersShortName": "海南澄迈农商",
  "headquartersNo": "HNCMRCB"
}, {
  "headquartersName": "海南澄迈长江村镇银行",
  "headquartersShortName": "海南澄迈长江村镇银行",
  "headquartersNo": "HNCMCJCUNZB"
}, {
  "headquartersName": "海南儋州绿色村镇银行",
  "headquartersShortName": "海南儋州绿色村镇银行",
  "headquartersNo": "HNZZLSCUNZB"
}, {
  "headquartersName": "海南临高农村商业银行",
  "headquartersShortName": "海南临高农商",
  "headquartersNo": "HNLGRCB"
}, {
  "headquartersName": "海南琼中长江村镇银行",
  "headquartersShortName": "海南琼中长江村镇银行",
  "headquartersNo": "HNQZCJCUNZB"
}, {
  "headquartersName": "海南省农村信用社联合社",
  "headquartersShortName": "海南农信社",
  "headquartersNo": "HNNXS"
}, {
  "headquartersName": "海南屯昌农村商业银行",
  "headquartersShortName": "海南屯昌农商",
  "headquartersNo": "HNTCRCB"
}, {
  "headquartersName": "海南屯昌长江村镇银行",
  "headquartersShortName": "海南屯昌长江村镇银行",
  "headquartersNo": "HNTCCJCUNZB"
}, {
  "headquartersName": "海南万宁农村商业银行",
  "headquartersShortName": "海南万宁农商",
  "headquartersNo": "HNWNRCB"
}, {
  "headquartersName": "海南文昌农村商业银行",
  "headquartersShortName": "海南文昌农商",
  "headquartersNo": "HNWCRCB"
}, {
  "headquartersName": "海南五指山长江村镇银行",
  "headquartersShortName": "海南五指山长江村镇银行",
  "headquartersNo": "HNWZSCJCUNZB"
}, {
  "headquartersName": "海南银行",
  "headquartersShortName": "海南",
  "headquartersNo": "HNYH"
}, {
  "headquartersName": "海阳珠江村镇银行",
  "headquartersShortName": "海阳珠江村镇银行",
  "headquartersNo": "HYZJCUNZB"
}, {
  "headquartersName": "邯郸肥乡恒升村镇银行",
  "headquartersShortName": "邯郸肥乡恒升村镇银行",
  "headquartersNo": "HDFXHSCUNZB"
}, {
  "headquartersName": "邯郸邯山齐鲁村镇银行",
  "headquartersShortName": "邯郸邯山齐鲁村镇银行",
  "headquartersNo": "HDHSQLCUNZB"
}, {
  "headquartersName": "邯郸银行",
  "headquartersShortName": "邯郸",
  "headquartersNo": "HDCB"
}, {
  "headquartersName": "邯郸永年齐鲁村镇银行",
  "headquartersShortName": "邯郸永年齐鲁村镇银行",
  "headquartersNo": "HDYNQLCUNZB"
}, {
  "headquartersName": "含山惠民村镇银行",
  "headquartersShortName": "含山惠民村镇银行",
  "headquartersNo": "HSHMCUNZB"
}, {
  "headquartersName": "韩城浦发村镇银行",
  "headquartersShortName": "韩城浦发村镇银行",
  "headquartersNo": "HCPFCUNZB"
}, {
  "headquartersName": "韩国产业银行",
  "headquartersShortName": "韩国产业",
  "headquartersNo": "KDBK"
}, {
  "headquartersName": "韩国大邱银行",
  "headquartersShortName": "韩国大邱",
  "headquartersNo": "DGB"
}, {
  "headquartersName": "韩国釜山银行",
  "headquartersShortName": "韩国釜山",
  "headquartersNo": "BSB"
}, {
  "headquartersName": "韩亚银行（中国）有限公司",
  "headquartersShortName": "韩亚",
  "headquartersNo": "HYYH"
}, {
  "headquartersName": "汉口银行",
  "headquartersShortName": "汉口",
  "headquartersNo": "HKYH"
}, {
  "headquartersName": "汉中汉台铺镇聚利村镇银行",
  "headquartersShortName": "汉中汉台铺镇聚利村镇银行",
  "headquartersNo": "HZHTPZJLCUNZB"
}, {
  "headquartersName": "杭锦大众村镇银行",
  "headquartersShortName": "杭锦大众村镇银行",
  "headquartersNo": "HJDZCUNZB"
}, {
  "headquartersName": "杭锦后旗河套村镇银行",
  "headquartersShortName": "杭锦后旗河套村镇银行",
  "headquartersNo": "HJHQHTCUNZB"
}, {
  "headquartersName": "杭州联合农村商业银行",
  "headquartersShortName": "杭州联合农商",
  "headquartersNo": "HZLHRCB"
}, {
  "headquartersName": "杭州银行",
  "headquartersShortName": "杭州",
  "headquartersNo": "HZYH"
}, {
  "headquartersName": "合肥科技农村商业银行",
  "headquartersShortName": "合肥科技农商",
  "headquartersNo": "HFKJRCB"
}, {
  "headquartersName": "合浦国民村镇银行",
  "headquartersShortName": "合浦国民村镇银行",
  "headquartersNo": "HPGMCUNZB"
}, {
  "headquartersName": "合水县金城村镇银行",
  "headquartersShortName": "合水县金城村镇银行",
  "headquartersNo": "HSXJCCUNZB"
}, {
  "headquartersName": "合阳惠民村镇银行",
  "headquartersShortName": "合阳惠民村镇银行",
  "headquartersNo": "HYHMCUNZB"
}, {
  "headquartersName": "合作金库商业银行",
  "headquartersShortName": "合作金库商业银行",
  "headquartersNo": "TCBBANK"
}, {
  "headquartersName": "和龙敦银村镇银行",
  "headquartersShortName": "和龙敦银村镇银行",
  "headquartersNo": "HLDYCUNZB"
}, {
  "headquartersName": "和顺县贵都村镇银行",
  "headquartersShortName": "和顺县贵都村镇银行",
  "headquartersNo": "HSXGDCUNZB"
}, {
  "headquartersName": "和政神舟村镇银行",
  "headquartersShortName": "和政神舟村镇银行",
  "headquartersNo": "HZSZCUNZB"
}, {
  "headquartersName": "河北安国农村商业银行",
  "headquartersShortName": "河北安国农商",
  "headquartersNo": "HBAGRCB"
}, {
  "headquartersName": "河北安平农村商业银行",
  "headquartersShortName": "河北安平农商",
  "headquartersNo": "HBAPRCB"
}, {
  "headquartersName": "河北霸州农村商业银行",
  "headquartersShortName": "河北霸州农商",
  "headquartersNo": "HBBZRCB"
}, {
  "headquartersName": "河北柏乡农村商业银行",
  "headquartersShortName": "河北柏乡农商",
  "headquartersNo": "HBBXRCB"
}, {
  "headquartersName": "河北保定农村商业银行",
  "headquartersShortName": "河北保定农商",
  "headquartersNo": "HEBBDRCB"
}, {
  "headquartersName": "河北沧州农村商业银行",
  "headquartersShortName": "河北沧州农商",
  "headquartersNo": "HBCZRCB"
}, {
  "headquartersName": "河北承德热河农村商业银行",
  "headquartersShortName": "河北承德热河农商",
  "headquartersNo": "HBCDRHRCB"
}, {
  "headquartersName": "河北大厂农村商业银行",
  "headquartersShortName": "河北大厂农商",
  "headquartersNo": "HBDCRCB"
}, {
  "headquartersName": "河北大名农村商业银行",
  "headquartersShortName": "河北大名农商",
  "headquartersNo": "HBDMRCB"
}, {
  "headquartersName": "河北定州农村商业银行",
  "headquartersShortName": "河北定州农商",
  "headquartersNo": "HBDZRCB"
}, {
  "headquartersName": "河北丰宁农村商业银行",
  "headquartersShortName": "河北丰宁农商",
  "headquartersNo": "HBFNRCB"
}, {
  "headquartersName": "河北阜城农村商业银行",
  "headquartersShortName": "河北阜城农商",
  "headquartersNo": "HBFCRCB"
}, {
  "headquartersName": "河北高碑店农村商业银行",
  "headquartersShortName": "河北高碑店农商",
  "headquartersNo": "HBGBDRCB"
}, {
  "headquartersName": "河北沽源农村商业银行",
  "headquartersShortName": "河北沽源农商",
  "headquartersNo": "HBGYRCB"
}, {
  "headquartersName": "河北固安农村商业银行",
  "headquartersShortName": "河北固安农商",
  "headquartersNo": "HEBGARCB"
}, {
  "headquartersName": "河北广宗农村商业银行",
  "headquartersShortName": "河北广宗农商",
  "headquartersNo": "HBGZRCB"
}, {
  "headquartersName": "河北邯郸农村商业银行",
  "headquartersShortName": "河北邯郸农商",
  "headquartersNo": "HBHDRCB"
}, {
  "headquartersName": "河北衡水农村商业银行",
  "headquartersShortName": "河北衡水农商",
  "headquartersNo": "HBHSRCB"
}, {
  "headquartersName": "河北怀来农村商业银行",
  "headquartersShortName": "河北怀来农商",
  "headquartersNo": "HBHLRCB"
}, {
  "headquartersName": "河北黄骅农村商业银行",
  "headquartersShortName": "河北黄骅农商",
  "headquartersNo": "HBHHRCB"
}, {
  "headquartersName": "河北鸡泽农村商业银行",
  "headquartersShortName": "河北鸡泽农商",
  "headquartersNo": "HBJIZRCB"
}, {
  "headquartersName": "河北冀州农村商业银行",
  "headquartersShortName": "河北冀州农商",
  "headquartersNo": "HBYZRCB"
}, {
  "headquartersName": "河北晋州农村商业银行",
  "headquartersShortName": "河北晋州农商",
  "headquartersNo": "HBJINZRCB"
}, {
  "headquartersName": "河北井陉农村商业银行",
  "headquartersShortName": "河北井陉农商",
  "headquartersNo": "HBJJRCB"
}, {
  "headquartersName": "河北景州农村商业银行",
  "headquartersShortName": "河北景州农商",
  "headquartersNo": "HBJZRCB"
}, {
  "headquartersName": "河北巨鹿农村商业银行",
  "headquartersShortName": "河北巨鹿农商",
  "headquartersNo": "HEBJLRCB"
}, {
  "headquartersName": "河北宽城农村商业银行",
  "headquartersShortName": "河北宽城农商",
  "headquartersNo": "HBKCRCB"
}, {
  "headquartersName": "河北涞水农村商业银行",
  "headquartersShortName": "河北涞水农商",
  "headquartersNo": "HBLSRCB"
}, {
  "headquartersName": "河北蠡州北银农村商业银行",
  "headquartersShortName": "河北蠡州北银农商",
  "headquartersNo": "HBLZBYRCB"
}, {
  "headquartersName": "河北临城农村商业银行",
  "headquartersShortName": "河北临城农商",
  "headquartersNo": "HBLCRCB"
}, {
  "headquartersName": "河北临西农村商业银行",
  "headquartersShortName": "河北临西农商",
  "headquartersNo": "HBLXRCB"
}, {
  "headquartersName": "河北灵寿农村商业银行",
  "headquartersShortName": "河北灵寿农商",
  "headquartersNo": "HBLINGSRCB"
}, {
  "headquartersName": "河北卢龙农村商业银行",
  "headquartersShortName": "河北卢龙农商",
  "headquartersNo": "HBLLRCB"
}, {
  "headquartersName": "河北滦南农村商业银行",
  "headquartersShortName": "河北滦南农商",
  "headquartersNo": "HBLNRCB"
}, {
  "headquartersName": "河北滦平农村商业银行",
  "headquartersShortName": "河北滦平农商",
  "headquartersNo": "HBLPRCB"
}, {
  "headquartersName": "河北滦州农村商业银行",
  "headquartersShortName": "河北滦州农商",
  "headquartersNo": "HBLZRCB"
}, {
  "headquartersName": "河北南和农村商业银行",
  "headquartersShortName": "河北南和农商",
  "headquartersNo": "HBNHRCB"
}, {
  "headquartersName": "河北南皮农村商业银行",
  "headquartersShortName": "河北南皮农商",
  "headquartersNo": "HBNPRCB"
}, {
  "headquartersName": "河北宁晋农村商业银行",
  "headquartersShortName": "河北宁晋农商",
  "headquartersNo": "HBNJRCB"
}, {
  "headquartersName": "河北平山农村商业银行",
  "headquartersShortName": "河北平山农商",
  "headquartersNo": "HBPSRCB"
}, {
  "headquartersName": "河北迁安农村商业银行",
  "headquartersShortName": "河北迁安农商",
  "headquartersNo": "HBQARCB"
}, {
  "headquartersName": "河北清河农村商业银行",
  "headquartersShortName": "河北清河农商",
  "headquartersNo": "HBQHRCB"
}, {
  "headquartersName": "河北任县农村商业银行",
  "headquartersShortName": "河北任县农商",
  "headquartersNo": "HBRXRCB"
}, {
  "headquartersName": "河北三河农村商业银行",
  "headquartersShortName": "河北三河农商",
  "headquartersNo": "HEBSHRCB"
}, {
  "headquartersName": "河北沙河农村商业银行",
  "headquartersShortName": "河北沙河农商",
  "headquartersNo": "HBSHRCB"
}, {
  "headquartersName": "河北涉县农村商业银行",
  "headquartersShortName": "河北涉县农商",
  "headquartersNo": "HBSXRCB"
}, {
  "headquartersName": "河北深州农村商业银行",
  "headquartersShortName": "河北深州农商",
  "headquartersNo": "HEBSZRCB"
}, {
  "headquartersName": "河北省农村信用社联合社",
  "headquartersShortName": "河北农信社",
  "headquartersNo": "HEBNXS"
}, {
  "headquartersName": "河北唐山曹妃甸农村商业银行",
  "headquartersShortName": "河北唐山曹妃甸农商",
  "headquartersNo": "HBTSCFDRCB"
}, {
  "headquartersName": "河北唐山农村商业银行",
  "headquartersShortName": "河北唐山农商",
  "headquartersNo": "HBTSRCB"
}, {
  "headquartersName": "河北围场农村商业银行",
  "headquartersShortName": "河北围场农商",
  "headquartersNo": "HBWCRCB"
}, {
  "headquartersName": "河北文安农村商业银行",
  "headquartersShortName": "河北文安农商",
  "headquartersNo": "HBWARCB"
}, {
  "headquartersName": "河北武强农村商业银行",
  "headquartersShortName": "河北武强农商",
  "headquartersNo": "HBWUQRCB"
}, {
  "headquartersName": "河北献县农村商业银行",
  "headquartersShortName": "河北献县农商",
  "headquartersNo": "HBXXRCB"
}, {
  "headquartersName": "河北香河农村商业银行",
  "headquartersShortName": "河北香河农商",
  "headquartersNo": "HBXHRCB"
}, {
  "headquartersName": "河北辛集农村商业银行",
  "headquartersShortName": "河北辛集农商",
  "headquartersNo": "HBXJRCB"
}, {
  "headquartersName": "河北邢台农村商业银行",
  "headquartersShortName": "河北邢台农商",
  "headquartersNo": "HBXTRCB"
}, {
  "headquartersName": "河北邢州农村商业银行",
  "headquartersShortName": "河北邢州农商",
  "headquartersNo": "HBXZRCB"
}, {
  "headquartersName": "河北阳原农村商业银行",
  "headquartersShortName": "河北阳原农商",
  "headquartersNo": "HBYYRCB"
}, {
  "headquartersName": "河北银行",
  "headquartersShortName": "河北",
  "headquartersNo": "HBYH"
}, {
  "headquartersName": "河北永清农村商业银行",
  "headquartersShortName": "河北永清农商",
  "headquartersNo": "HBYQRCB"
}, {
  "headquartersName": "河北玉田农村商业银行",
  "headquartersShortName": "河北玉田农商",
  "headquartersNo": "HBYTRCB"
}, {
  "headquartersName": "河北元氏农村商业银行",
  "headquartersShortName": "河北元氏农商",
  "headquartersNo": "HEBYSRCB"
}, {
  "headquartersName": "河北枣强农村商业银行",
  "headquartersShortName": "河北枣强农商",
  "headquartersNo": "HBZQRCB"
}, {
  "headquartersName": "河北张北农村商业银行",
  "headquartersShortName": "河北张北农商",
  "headquartersNo": "HBZBRCB"
}, {
  "headquartersName": "河北正定农村商业银行",
  "headquartersShortName": "河北正定农商",
  "headquartersNo": "HBZDRCB"
}, {
  "headquartersName": "河北涿鹿农村商业银行",
  "headquartersShortName": "河北涿鹿农商",
  "headquartersNo": "HBZLRCB"
}, {
  "headquartersName": "河北涿州农村商业银行",
  "headquartersShortName": "河北涿州农商",
  "headquartersNo": "HBZZRCB"
}, {
  "headquartersName": "河间融惠村镇银行",
  "headquartersShortName": "河间融惠村镇银行",
  "headquartersNo": "HJRHCUNZB"
}, {
  "headquartersName": "河津市龙都村镇银行",
  "headquartersShortName": "河津市龙都村镇银行",
  "headquartersNo": "HJSLDCUNZB"
}, {
  "headquartersName": "河南安阳商都农村商业银行",
  "headquartersShortName": "河南安阳商都农商",
  "headquartersNo": "HNAYSDRCB"
}, {
  "headquartersName": "河南安阳相州农村商业银行",
  "headquartersShortName": "河南安阳相州农商",
  "headquartersNo": "HNAYXZRCB"
}, {
  "headquartersName": "河南宝丰农村商业银行",
  "headquartersShortName": "河南宝丰农商",
  "headquartersNo": "HNBFRCB"
}, {
  "headquartersName": "河南汴京农村商业银行",
  "headquartersShortName": "河南汴京农商",
  "headquartersNo": "HNBJRCB"
}, {
  "headquartersName": "河南博爱农村商业银行",
  "headquartersShortName": "河南博爱农商",
  "headquartersNo": "HNBARCB"
}, {
  "headquartersName": "河南登封农村商业银行",
  "headquartersShortName": "河南登封农商",
  "headquartersNo": "HNDFRCB"
}, {
  "headquartersName": "河南邓州农村商业银行",
  "headquartersShortName": "河南邓州农商",
  "headquartersNo": "HNDZRCB"
}, {
  "headquartersName": "河南范县农村商业银行",
  "headquartersShortName": "河南范县农商",
  "headquartersNo": "HNFXRCB"
}, {
  "headquartersName": "河南方城凤裕村镇银行",
  "headquartersShortName": "河南方城凤裕村镇银行",
  "headquartersNo": "HNFCFYCUNZB"
}, {
  "headquartersName": "河南扶沟农村商业银行",
  "headquartersShortName": "河南扶沟农商",
  "headquartersNo": "HNFGRCB"
}, {
  "headquartersName": "河南巩义农村商业银行",
  "headquartersShortName": "河南巩义农商",
  "headquartersNo": "HNGYRCB"
}, {
  "headquartersName": "河南固始农村商业银行",
  "headquartersShortName": "河南固始农商",
  "headquartersNo": "HNGSRCB"
}, {
  "headquartersName": "河南光山农村商业银行",
  "headquartersShortName": "河南光山农商",
  "headquartersNo": "HNGSHRCB"
}, {
  "headquartersName": "河南滑县农村商业银行",
  "headquartersShortName": "河南滑县农商",
  "headquartersNo": "HNHUAXRCB"
}, {
  "headquartersName": "河南淮滨农村商业银行",
  "headquartersShortName": "河南淮滨农商",
  "headquartersNo": "HNHBRCB"
}, {
  "headquartersName": "河南潢川农村商业银行",
  "headquartersShortName": "河南潢川农商",
  "headquartersNo": "HNHCRCB"
}, {
  "headquartersName": "河南辉县农村商业银行",
  "headquartersShortName": "河南辉县农商",
  "headquartersNo": "HNHXRCB"
}, {
  "headquartersName": "河南获嘉农村商业银行",
  "headquartersShortName": "河南获嘉农商",
  "headquartersNo": "HNHJRCB"
}, {
  "headquartersName": "河南济源农村商业银行",
  "headquartersShortName": "河南济源农商",
  "headquartersNo": "HNJYRCB"
}, {
  "headquartersName": "河南浚县农村商业银行",
  "headquartersShortName": "河南浚县农商",
  "headquartersNo": "HNXUNXRCB"
}, {
  "headquartersName": "河南兰考农村商业银行",
  "headquartersShortName": "河南兰考农商",
  "headquartersNo": "HNLKRCB"
}, {
  "headquartersName": "河南林州农村商业银行",
  "headquartersShortName": "河南林州农商",
  "headquartersNo": "HNLZRCB"
}, {
  "headquartersName": "河南灵宝农村商业银行",
  "headquartersShortName": "河南灵宝农商",
  "headquartersNo": "HNLBRCB"
}, {
  "headquartersName": "河南卢氏农村商业银行",
  "headquartersShortName": "河南卢氏农商",
  "headquartersNo": "HNLUSRCB"
}, {
  "headquartersName": "河南鹿邑农村商业银行",
  "headquartersShortName": "河南鹿邑农商",
  "headquartersNo": "HNLUYRCB"
}, {
  "headquartersName": "河南栾川民丰村镇银行",
  "headquartersShortName": "河南栾川民丰村镇银行",
  "headquartersNo": "HNZCMFCUNZB"
}, {
  "headquartersName": "河南栾川农村商业银行",
  "headquartersShortName": "河南栾川农商",
  "headquartersNo": "HNLCRCB"
}, {
  "headquartersName": "河南罗山农村商业银行",
  "headquartersShortName": "河南罗山农商",
  "headquartersNo": "HNLUOSRCB"
}, {
  "headquartersName": "河南洛宁农村商业银行",
  "headquartersShortName": "河南洛宁农商",
  "headquartersNo": "HNLNRCB"
}, {
  "headquartersName": "河南孟津农村商业银行",
  "headquartersShortName": "河南孟津农商",
  "headquartersNo": "HNMJRCB"
}, {
  "headquartersName": "河南孟州农村商业银行",
  "headquartersShortName": "河南孟州农商",
  "headquartersNo": "HNMZRCB"
}, {
  "headquartersName": "河南泌阳农村商业银行",
  "headquartersShortName": "河南泌阳农商",
  "headquartersNo": "HNBYRCB"
}, {
  "headquartersName": "河南渑池农村商业银行",
  "headquartersShortName": "河南渑池农商",
  "headquartersNo": "HNMCRCB"
}, {
  "headquartersName": "河南民权农村商业银行",
  "headquartersShortName": "河南民权农商",
  "headquartersNo": "HNMQRCB"
}, {
  "headquartersName": "河南南乐农村商业银行",
  "headquartersShortName": "河南南乐农商",
  "headquartersNo": "HNNLRCB"
}, {
  "headquartersName": "河南南召农村商业银行",
  "headquartersShortName": "河南南召农商",
  "headquartersNo": "HNNZRCB"
}, {
  "headquartersName": "河南内乡农村商业银行",
  "headquartersShortName": "河南内乡农商",
  "headquartersNo": "HNNEIXRCB"
}, {
  "headquartersName": "河南平舆农村商业银行",
  "headquartersShortName": "河南平舆农商",
  "headquartersNo": "HNPYRCB"
}, {
  "headquartersName": "河南濮阳开州农村商业银行",
  "headquartersShortName": "河南濮阳开州农商",
  "headquartersNo": "HNPYKZRCB"
}, {
  "headquartersName": "河南杞县农村商业银行",
  "headquartersShortName": "河南杞县农商",
  "headquartersNo": "HNQXRCB"
}, {
  "headquartersName": "河南沁阳江南村镇银行",
  "headquartersShortName": "河南沁阳江南村镇银行",
  "headquartersNo": "HNQYJNCUNZB"
}, {
  "headquartersName": "河南沁阳农村商业银行",
  "headquartersShortName": "河南沁阳农商",
  "headquartersNo": "HNQINYRCB"
}, {
  "headquartersName": "河南清丰农村商业银行",
  "headquartersShortName": "河南清丰农商",
  "headquartersNo": "HNQFRCB"
}, {
  "headquartersName": "河南确山农村商业银行",
  "headquartersShortName": "河南确山农商",
  "headquartersNo": "HNQSRCB"
}, {
  "headquartersName": "河南汝南农村商业银行",
  "headquartersShortName": "河南汝南农商",
  "headquartersNo": "HNNNRCB"
}, {
  "headquartersName": "河南汝南泰隆村镇银行",
  "headquartersShortName": "河南汝南泰隆村镇银行",
  "headquartersNo": "HNRNTLCUNZB"
}, {
  "headquartersName": "河南汝阳农村商业银行",
  "headquartersShortName": "河南汝阳农商",
  "headquartersNo": "HNRYRCB"
}, {
  "headquartersName": "河南汝州农村商业银行",
  "headquartersShortName": "河南汝州农商",
  "headquartersNo": "HNRZRCB"
}, {
  "headquartersName": "河南商水农村商业银行",
  "headquartersShortName": "河南商水农商",
  "headquartersNo": "HNSSRCB"
}, {
  "headquartersName": "河南商水厦农商村镇银行",
  "headquartersShortName": "河南商水厦农商村镇银行",
  "headquartersNo": "HNSSXNSCUNZB"
}, {
  "headquartersName": "河南上蔡农村商业银行",
  "headquartersShortName": "河南上蔡农商",
  "headquartersNo": "HNSCRCB"
}, {
  "headquartersName": "河南社旗农村商业银行",
  "headquartersShortName": "河南社旗农商",
  "headquartersNo": "HNSHEQRCB"
}, {
  "headquartersName": "河南沈丘农村商业银行",
  "headquartersShortName": "河南沈丘农商",
  "headquartersNo": "HNSQRCB"
}, {
  "headquartersName": "河南省农村信用社联合社",
  "headquartersShortName": "河南农信社",
  "headquartersNo": "HENNXS"
}, {
  "headquartersName": "河南嵩县农村商业银行",
  "headquartersShortName": "河南嵩县农商",
  "headquartersNo": "HNSXRCB"
}, {
  "headquartersName": "河南遂平农村商业银行",
  "headquartersShortName": "河南遂平农商",
  "headquartersNo": "HNSUIPRCB"
}, {
  "headquartersName": "河南台前农村商行银行",
  "headquartersShortName": "河南台前农商",
  "headquartersNo": "HNTQRCB"
}, {
  "headquartersName": "河南太康农村商业银行",
  "headquartersShortName": "河南太康农商",
  "headquartersNo": "HNTKRCB"
}, {
  "headquartersName": "河南汤阴农村商业银行",
  "headquartersShortName": "河南汤阴农商",
  "headquartersNo": "HNTYRCB"
}, {
  "headquartersName": "河南通许农村商业银行",
  "headquartersShortName": "河南通许农商",
  "headquartersNo": "HNTXRCB"
}, {
  "headquartersName": "河南桐柏农村商业银行",
  "headquartersShortName": "河南桐柏农商",
  "headquartersNo": "HNTBRCB"
}, {
  "headquartersName": "河南卫辉农村商业银行",
  "headquartersShortName": "河南卫辉农商",
  "headquartersNo": "HNWHRCB"
}, {
  "headquartersName": "河南尉氏农村商业银行",
  "headquartersShortName": "河南尉氏农商",
  "headquartersNo": "HNWSRCB"
}, {
  "headquartersName": "河南武陟农村商业银行",
  "headquartersShortName": "河南武陟农商",
  "headquartersNo": "HNWZRCB"
}, {
  "headquartersName": "河南舞钢农村商业银行",
  "headquartersShortName": "河南舞钢农商",
  "headquartersNo": "HNWGRCB"
}, {
  "headquartersName": "河南西华厦农商村镇银行",
  "headquartersShortName": "河南西华厦农商村镇银行",
  "headquartersNo": "HNXHXNSCUNZB"
}, {
  "headquartersName": "河南西平农村商业银行",
  "headquartersShortName": "河南西平农商",
  "headquartersNo": "HNXPRCB"
}, {
  "headquartersName": "河南西峡农村商业银行",
  "headquartersShortName": "河南西峡农商",
  "headquartersNo": "HNXIXRCB"
}, {
  "headquartersName": "河南息县农村商业银行",
  "headquartersShortName": "河南息县农商",
  "headquartersNo": "HENXXRCB"
}, {
  "headquartersName": "河南淅川农村商业银行",
  "headquartersShortName": "河南淅川农商",
  "headquartersNo": "HNXICRCB"
}, {
  "headquartersName": "河南襄城农村商业银行",
  "headquartersShortName": "河南襄城农商",
  "headquartersNo": "HNXIANGCRCB"
}, {
  "headquartersName": "河南项城农村商业银行",
  "headquartersShortName": "河南项城农商",
  "headquartersNo": "HENXCRCB"
}, {
  "headquartersName": "河南新安农村商业银行",
  "headquartersShortName": "河南新安农商",
  "headquartersNo": "HNXARCB"
}, {
  "headquartersName": "河南新蔡农村商业银行",
  "headquartersShortName": "河南新蔡农商",
  "headquartersNo": "HNXCRCB"
}, {
  "headquartersName": "河南新密农村商业银行",
  "headquartersShortName": "河南新密农商",
  "headquartersNo": "HNXMRCB"
}, {
  "headquartersName": "河南新县农村商业银行",
  "headquartersShortName": "河南新县农商",
  "headquartersNo": "HNXXRCB"
}, {
  "headquartersName": "河南新乡平原农村商业银行",
  "headquartersShortName": "河南新乡平原农商",
  "headquartersNo": "XXPYRCB"
}, {
  "headquartersName": "河南新野农村商业银行",
  "headquartersShortName": "河南新野农商",
  "headquartersNo": "HNXYERCB"
}, {
  "headquartersName": "河南新郑农村商业银行",
  "headquartersShortName": "河南新郑农商",
  "headquartersNo": "HNXZRCB"
}, {
  "headquartersName": "河南荥阳农村商业银行",
  "headquartersShortName": "河南荥阳农商",
  "headquartersNo": "HNXYRCB"
}, {
  "headquartersName": "河南修武农村商业银行",
  "headquartersShortName": "河南修武农商",
  "headquartersNo": "HNXWRCB"
}, {
  "headquartersName": "河南许昌许都农村商业银行",
  "headquartersShortName": "河南许昌许都农商",
  "headquartersNo": "HNXCXDRCB"
}, {
  "headquartersName": "河南鄢陵农村商业银行",
  "headquartersShortName": "河南鄢陵农商",
  "headquartersNo": "HNYLRCB"
}, {
  "headquartersName": "河南延津农村商业银行",
  "headquartersShortName": "河南延津农商",
  "headquartersNo": "HENYJRCB"
}, {
  "headquartersName": "河南偃师农村商业银行",
  "headquartersShortName": "河南偃师农商",
  "headquartersNo": "HNYSRCB"
}, {
  "headquartersName": "河南叶县农村商业银行",
  "headquartersShortName": "河南叶县农商",
  "headquartersNo": "HNYEXRCB"
}, {
  "headquartersName": "河南叶县泰隆村镇银行",
  "headquartersShortName": "河南叶县泰隆村镇银行",
  "headquartersNo": "HNYXTLCUNZB"
}, {
  "headquartersName": "河南伊川农村商业银行",
  "headquartersShortName": "河南伊川农商",
  "headquartersNo": "HNYICRCB"
}, {
  "headquartersName": "河南宜阳农村商业银行",
  "headquartersShortName": "河南宜阳农商",
  "headquartersNo": "HNYYRCB"
}, {
  "headquartersName": "河南义马农村商业银行",
  "headquartersShortName": "河南义马农商",
  "headquartersNo": "HNYMRCB"
}, {
  "headquartersName": "河南永城农村商业银行",
  "headquartersShortName": "河南永城农商",
  "headquartersNo": "HNYCRCB"
}, {
  "headquartersName": "河南原阳农村商业银行",
  "headquartersShortName": "河南原阳农商",
  "headquartersNo": "HNYYANRCB"
}, {
  "headquartersName": "河南长葛农村商业银行",
  "headquartersShortName": "河南长葛农商",
  "headquartersNo": "HNCGRCB"
}, {
  "headquartersName": "河南长垣农村商业银行",
  "headquartersShortName": "河南长垣农商",
  "headquartersNo": "HNCYRCB"
}, {
  "headquartersName": "河南柘城农村商业银行",
  "headquartersShortName": "河南柘城农商",
  "headquartersNo": "HNZCRCB"
}, {
  "headquartersName": "河南镇平农村商业银行",
  "headquartersShortName": "河南镇平农商",
  "headquartersNo": "HNZPRCB"
}, {
  "headquartersName": "河南正阳农村商业银行",
  "headquartersShortName": "河南正阳农商",
  "headquartersNo": "HNZYRCB"
}, {
  "headquartersName": "河南中牟农村商业银行",
  "headquartersShortName": "河南中牟农商",
  "headquartersNo": "HNZMRCB"
}, {
  "headquartersName": "荷兰安智银行",
  "headquartersShortName": "荷兰安智",
  "headquartersNo": "HLAZYH"
}, {
  "headquartersName": "荷兰合作银行",
  "headquartersShortName": "荷兰合作",
  "headquartersNo": "HLHZYH"
}, {
  "headquartersName": "菏泽牡丹北海村镇银行",
  "headquartersShortName": "菏泽牡丹北海村镇银行",
  "headquartersNo": "HZMDBHCUNZB"
}, {
  "headquartersName": "菏泽农村商业银行",
  "headquartersShortName": "菏泽农商",
  "headquartersNo": "HZRCB"
}, {
  "headquartersName": "贺州八步东盈村镇银行",
  "headquartersShortName": "贺州八步东盈村镇银行",
  "headquartersNo": "HZBBDYCUNZB"
}, {
  "headquartersName": "赫章富民村镇银行",
  "headquartersShortName": "赫章富民村镇银行",
  "headquartersNo": "HZFMCUNZB"
}, {
  "headquartersName": "鹤壁农村商业银行",
  "headquartersShortName": "鹤壁农商",
  "headquartersNo": "HEBIRCB"
}, {
  "headquartersName": "鹤山珠江村镇银行",
  "headquartersShortName": "鹤山珠江村镇银行",
  "headquartersNo": "HSZJCUNZB"
}, {
  "headquartersName": "黑河农村商业银行",
  "headquartersShortName": "黑河农商",
  "headquartersNo": "HHERCB"
}, {
  "headquartersName": "黑龙江安达农村商业银行",
  "headquartersShortName": "黑龙江安达农商",
  "headquartersNo": "HLJADRCB"
}, {
  "headquartersName": "黑龙江宝清农村商业银行",
  "headquartersShortName": "黑龙江宝清农商",
  "headquartersNo": "HLJBQRCB"
}, {
  "headquartersName": "黑龙江北安农村商业银行",
  "headquartersShortName": "黑龙江北安农商",
  "headquartersNo": "HLJBARCB"
}, {
  "headquartersName": "黑龙江宾州农村商业银行",
  "headquartersShortName": "黑龙江宾州农商",
  "headquartersNo": "HLJBZRCB"
}, {
  "headquartersName": "黑龙江东宁农村商业银行",
  "headquartersShortName": "黑龙江东宁农商",
  "headquartersNo": "HLJDNRCB"
}, {
  "headquartersName": "黑龙江杜尔伯特农村商业银行",
  "headquartersShortName": "黑龙江杜尔伯特农商",
  "headquartersNo": "HLJDEBTRCB"
}, {
  "headquartersName": "黑龙江方正农村商业银行",
  "headquartersShortName": "黑龙江方正农商",
  "headquartersNo": "HLJFZRCB"
}, {
  "headquartersName": "黑龙江抚远农村商业银行",
  "headquartersShortName": "黑龙江抚远农商",
  "headquartersNo": "HLJFYRCB"
}, {
  "headquartersName": "黑龙江富裕农村商业银行",
  "headquartersShortName": "黑龙江富裕农商",
  "headquartersNo": "HLJFYURCB"
}, {
  "headquartersName": "黑龙江甘南农村商业银行",
  "headquartersShortName": "黑龙江甘南农商",
  "headquartersNo": "HLJGNRCB"
}, {
  "headquartersName": "黑龙江呼玛农村商业银行",
  "headquartersShortName": "黑龙江呼玛农商",
  "headquartersNo": "HLJHMRCB"
}, {
  "headquartersName": "黑龙江虎林农村商业银行",
  "headquartersShortName": "黑龙江虎林农商",
  "headquartersNo": "HLJHLRCB"
}, {
  "headquartersName": "黑龙江鸡东农村商业银行",
  "headquartersShortName": "黑龙江鸡东农商",
  "headquartersNo": "HLJJDRCB"
}, {
  "headquartersName": "黑龙江鸡西农村商业银行",
  "headquartersShortName": "黑龙江鸡西农商",
  "headquartersNo": "HLJJIXRCB"
}, {
  "headquartersName": "黑龙江集贤农村商业银行",
  "headquartersShortName": "黑龙江集贤农商",
  "headquartersNo": "HLJJXRCB"
}, {
  "headquartersName": "黑龙江建三江农村商业银行",
  "headquartersShortName": "黑龙江建三江农商",
  "headquartersNo": "HLJJSJRCB"
}, {
  "headquartersName": "黑龙江克东农村商业银行",
  "headquartersShortName": "黑龙江克东农商",
  "headquartersNo": "HLJKDRCB"
}, {
  "headquartersName": "黑龙江克山农村商业银行",
  "headquartersShortName": "黑龙江克山农商",
  "headquartersNo": "HLJKSRCB"
}, {
  "headquartersName": "黑龙江林甸农村商业银行",
  "headquartersShortName": "黑龙江林甸农商",
  "headquartersNo": "HLJLDRCB"
}, {
  "headquartersName": "黑龙江萝北农村商业银行",
  "headquartersShortName": "黑龙江萝北农商",
  "headquartersNo": "HLJLBRCB"
}, {
  "headquartersName": "黑龙江密山农村商业银行",
  "headquartersShortName": "黑龙江密山农商",
  "headquartersNo": "HLJMISRCB"
}, {
  "headquartersName": "黑龙江明水农村商业银行",
  "headquartersShortName": "黑龙江明水农商",
  "headquartersNo": "HLJMSRCB"
}, {
  "headquartersName": "黑龙江漠河农村商业银行",
  "headquartersShortName": "黑龙江漠河农商",
  "headquartersNo": "HLJMHRCB"
}, {
  "headquartersName": "黑龙江讷河农村商业银行",
  "headquartersShortName": "黑龙江讷河农商",
  "headquartersNo": "HLJNHRCB"
}, {
  "headquartersName": "黑龙江嫩江农村商业银行",
  "headquartersShortName": "黑龙江嫩江农商",
  "headquartersNo": "HLJNJRCB"
}, {
  "headquartersName": "黑龙江庆安农村商业银行",
  "headquartersShortName": "黑龙江庆安农商",
  "headquartersNo": "HLJQARCB"
}, {
  "headquartersName": "黑龙江饶河农村商业银行",
  "headquartersShortName": "黑龙江饶河农商",
  "headquartersNo": "HLJRHRCB"
}, {
  "headquartersName": "黑龙江省农村信用社联合社",
  "headquartersShortName": "黑龙江农信社",
  "headquartersNo": "HLJNXS"
}, {
  "headquartersName": "黑龙江绥滨农村商业银行",
  "headquartersShortName": "黑龙江绥滨农商",
  "headquartersNo": "HLJSBRCB"
}, {
  "headquartersName": "黑龙江绥芬河农村商业银行",
  "headquartersShortName": "黑龙江绥芬河农商",
  "headquartersNo": "HLJSFHRCB"
}, {
  "headquartersName": "黑龙江绥化农村商业银行",
  "headquartersShortName": "黑龙江绥化农商",
  "headquartersNo": "HLJSHRCB"
}, {
  "headquartersName": "黑龙江绥棱农村商业银行",
  "headquartersShortName": "黑龙江绥棱农商",
  "headquartersNo": "HLJSLRCB"
}, {
  "headquartersName": "黑龙江孙吴农村商业银行",
  "headquartersShortName": "黑龙江孙吴农商",
  "headquartersNo": "HLJSWRCB"
}, {
  "headquartersName": "黑龙江塔河农村商业银行",
  "headquartersShortName": "黑龙江塔河农商",
  "headquartersNo": "HLJTHRCB"
}, {
  "headquartersName": "黑龙江泰来农村商业银行",
  "headquartersShortName": "黑龙江泰来农商",
  "headquartersNo": "HLJTLRCB"
}, {
  "headquartersName": "黑龙江汤原农村商业银行",
  "headquartersShortName": "黑龙江汤原农商",
  "headquartersNo": "HLJTYRCB"
}, {
  "headquartersName": "黑龙江通河农村商业银行",
  "headquartersShortName": "黑龙江通河农商",
  "headquartersNo": "HLJTONGHRCB"
}, {
  "headquartersName": "黑龙江同江农村商业银行",
  "headquartersShortName": "黑龙江同江农商",
  "headquartersNo": "HLJTJRCB"
}, {
  "headquartersName": "黑龙江五常农村商业银行",
  "headquartersShortName": "黑龙江五常农商",
  "headquartersNo": "HLJWCRCB"
}, {
  "headquartersName": "黑龙江五大连池农村商业银行",
  "headquartersShortName": "黑龙江五大连池农商",
  "headquartersNo": "HLJWDLCRCB"
}, {
  "headquartersName": "黑龙江逊克农村商业银行",
  "headquartersShortName": "黑龙江逊克农商",
  "headquartersNo": "HLJXKRCB"
}, {
  "headquartersName": "黑龙江延寿农村商业银行",
  "headquartersShortName": "黑龙江延寿农商",
  "headquartersNo": "HLJYSRCB"
}, {
  "headquartersName": "黑龙江友谊农村商业银行",
  "headquartersShortName": "黑龙江友谊农商",
  "headquartersNo": "HLJYYRCB"
}, {
  "headquartersName": "黑龙江肇源农村商业银行",
  "headquartersShortName": "黑龙江肇源农商",
  "headquartersNo": "HLJZYRCB"
}, {
  "headquartersName": "黑龙江肇州农村商业银行",
  "headquartersShortName": "黑龙江肇州农商",
  "headquartersNo": "HLJZZRCB"
}, {
  "headquartersName": "恒丰银行",
  "headquartersShortName": "恒丰",
  "headquartersNo": "EGB"
}, {
  "headquartersName": "恒生银行（中国）有限公司",
  "headquartersShortName": "恒生",
  "headquartersNo": "HSB"
}, {
  "headquartersName": "横峰恒通村镇银行",
  "headquartersShortName": "横峰恒通村镇银行",
  "headquartersNo": "HFHTCUNZB"
}, {
  "headquartersName": "衡南浦发村镇银行",
  "headquartersShortName": "衡南浦发村镇银行",
  "headquartersNo": "HNPFCUNZB"
}, {
  "headquartersName": "衡水冀州丰源村镇银行",
  "headquartersShortName": "衡水冀州丰源村镇银行",
  "headquartersNo": "HSJZFYCUNZB"
}, {
  "headquartersName": "衡水银行",
  "headquartersShortName": "衡水",
  "headquartersNo": "HSYH"
}, {
  "headquartersName": "衡阳农村商业银行",
  "headquartersShortName": "衡阳农商",
  "headquartersNo": "HYRCB"
}, {
  "headquartersName": "衡阳县沪农商村镇银行",
  "headquartersShortName": "衡阳县沪农商村镇银行",
  "headquartersNo": "HYXHNSCUNZB"
}, {
  "headquartersName": "洪洞县洪都村镇银行",
  "headquartersShortName": "洪洞县洪都村镇银行",
  "headquartersNo": "HDXHDCUNZB"
}, {
  "headquartersName": "洪湖融兴村镇银行",
  "headquartersShortName": "洪湖融兴村镇银行",
  "headquartersNo": "HHRXCUNZB"
}, {
  "headquartersName": "侯马市太行村镇银行",
  "headquartersShortName": "侯马市太行村镇银行",
  "headquartersNo": "HMSTXCUNZB"
}, {
  "headquartersName": "呼和浩特金桥河套村镇银行",
  "headquartersShortName": "呼和浩特金桥河套村镇银行",
  "headquartersNo": "HHHTJQHTCUNZB"
}, {
  "headquartersName": "呼和浩特市如意蒙银村镇银行",
  "headquartersShortName": "呼和浩特市如意蒙银村镇银行",
  "headquartersNo": "HHHTSRYMYCUNZB"
}, {
  "headquartersName": "呼和浩特市赛罕金谷村镇银行",
  "headquartersShortName": "呼和浩特市赛罕金谷村镇银行",
  "headquartersNo": "HHHTSSHJGCUNZB"
}, {
  "headquartersName": "呼和浩特市赛罕蒙银村镇银行",
  "headquartersShortName": "呼和浩特市赛罕蒙银村镇银行",
  "headquartersNo": "HHHTSSHMYCUNZB"
}, {
  "headquartersName": "呼和浩特市新城蒙银村镇银行",
  "headquartersShortName": "呼和浩特市新城蒙银村镇银行",
  "headquartersNo": "HHHTSXCMYCUNZB"
}, {
  "headquartersName": "呼和浩特市玉泉蒙银村镇银行",
  "headquartersShortName": "呼和浩特市玉泉蒙银村镇银行",
  "headquartersNo": "HHHTSYQMYCUNZB"
}, {
  "headquartersName": "呼伦贝尔农村商业银行",
  "headquartersShortName": "呼伦贝尔农商",
  "headquartersNo": "HLBERCB"
}, {
  "headquartersName": "呼图壁津汇村镇银行",
  "headquartersShortName": "呼图壁津汇村镇银行",
  "headquartersNo": "HTBJHCUNZB"
}, {
  "headquartersName": "壶关县晋融村镇银行",
  "headquartersShortName": "壶关县晋融村镇银行",
  "headquartersNo": "HGXJRCUNZB"
}, {
  "headquartersName": "葫芦岛国信村镇银行",
  "headquartersShortName": "葫芦岛国信村镇银行",
  "headquartersNo": "HLDGXCUNZB"
}, {
  "headquartersName": "葫芦岛农村商业银行",
  "headquartersShortName": "葫芦岛农商",
  "headquartersNo": "HLDRCB"
}, {
  "headquartersName": "葫芦岛银行",
  "headquartersShortName": "葫芦岛",
  "headquartersNo": "HLDYH"
}, {
  "headquartersName": "湖北安陆农村商业银行",
  "headquartersShortName": "湖北安陆农商",
  "headquartersNo": "HBALRCB"
}, {
  "headquartersName": "湖北巴东农村商业银行",
  "headquartersShortName": "湖北巴东农商",
  "headquartersNo": "HBBDRCB"
}, {
  "headquartersName": "湖北保康农村商业银行",
  "headquartersShortName": "湖北保康农商",
  "headquartersNo": "HBBKRCB"
}, {
  "headquartersName": "湖北赤壁农村商业银行",
  "headquartersShortName": "湖北赤壁农商",
  "headquartersNo": "HBCBRCB"
}, {
  "headquartersName": "湖北赤壁长江村镇银行",
  "headquartersShortName": "湖北赤壁长江村镇银行",
  "headquartersNo": "HBCBCJCUNZB"
}, {
  "headquartersName": "湖北崇阳农村商业银行",
  "headquartersShortName": "湖北崇阳农商",
  "headquartersNo": "HBCYRCB"
}, {
  "headquartersName": "湖北大悟农村商业银行",
  "headquartersShortName": "湖北大悟农商",
  "headquartersNo": "HBDWRCB"
}, {
  "headquartersName": "湖北大冶农村商业银行",
  "headquartersShortName": "湖北大冶农商",
  "headquartersNo": "HBDAYRCB"
}, {
  "headquartersName": "湖北大冶泰隆村镇银行",
  "headquartersShortName": "湖北大冶泰隆村镇银行",
  "headquartersNo": "HBDYTLCUNZB"
}, {
  "headquartersName": "湖北丹江口农村商业银行",
  "headquartersShortName": "湖北丹江口农商",
  "headquartersNo": "HBDJKRCB"
}, {
  "headquartersName": "湖北当阳农村商业银行",
  "headquartersShortName": "湖北当阳农商",
  "headquartersNo": "HBDYRCB"
}, {
  "headquartersName": "湖北鄂州农村商业银行",
  "headquartersShortName": "湖北鄂州农商",
  "headquartersNo": "HBEZRCB"
}, {
  "headquartersName": "湖北恩施农村商业银行",
  "headquartersShortName": "湖北恩施农商",
  "headquartersNo": "HBESRCB"
}, {
  "headquartersName": "湖北房县农村商业银行",
  "headquartersShortName": "湖北房县农商",
  "headquartersNo": "HBFXRCB"
}, {
  "headquartersName": "湖北公安农村商业银行",
  "headquartersShortName": "湖北公安农商",
  "headquartersNo": "HBGARCB"
}, {
  "headquartersName": "湖北谷城农村商业银行",
  "headquartersShortName": "湖北谷城农商",
  "headquartersNo": "HBGCRCB"
}, {
  "headquartersName": "湖北广水农村商业银行",
  "headquartersShortName": "湖北广水农商",
  "headquartersNo": "HBGSRCB"
}, {
  "headquartersName": "湖北汉川农村商业银行",
  "headquartersShortName": "湖北汉川农商",
  "headquartersNo": "HBHCRCB"
}, {
  "headquartersName": "湖北汉川农银村镇银行",
  "headquartersShortName": "湖北汉川农银村镇银行",
  "headquartersNo": "HBHCNYCUNZB"
}, {
  "headquartersName": "湖北鹤峰农村商业银行",
  "headquartersShortName": "湖北鹤峰农商",
  "headquartersNo": "HBHFRCB"
}, {
  "headquartersName": "湖北红安农村商业银行",
  "headquartersShortName": "湖北红安农商",
  "headquartersNo": "HBHARCB"
}, {
  "headquartersName": "湖北红安长江村镇银行",
  "headquartersShortName": "湖北红安长江村镇银行",
  "headquartersNo": "HBHACJCUNZB"
}, {
  "headquartersName": "湖北洪湖农村商业银行",
  "headquartersShortName": "湖北洪湖农商",
  "headquartersNo": "HUBHHRCB"
}, {
  "headquartersName": "湖北黄冈农村商业银行",
  "headquartersShortName": "湖北黄冈农商",
  "headquartersNo": "HBHGRCB"
}, {
  "headquartersName": "湖北黄梅农村商业银行",
  "headquartersShortName": "湖北黄梅农商",
  "headquartersNo": "HBHMRCB"
}, {
  "headquartersName": "湖北嘉鱼农村商业银行",
  "headquartersShortName": "湖北嘉鱼农商",
  "headquartersNo": "HBJYRCB"
}, {
  "headquartersName": "湖北嘉鱼吴江村镇银行",
  "headquartersShortName": "湖北嘉鱼吴江村镇银行",
  "headquartersNo": "HBJYWJCUNZB"
}, {
  "headquartersName": "湖北监利农村商业银行",
  "headquartersShortName": "湖北监利农商",
  "headquartersNo": "HBJLIRCB"
}, {
  "headquartersName": "湖北建始农村商业银行",
  "headquartersShortName": "湖北建始农商",
  "headquartersNo": "HBJSRCB"
}, {
  "headquartersName": "湖北江陵农村商业银行",
  "headquartersShortName": "湖北江陵农商",
  "headquartersNo": "HBJLRCB"
}, {
  "headquartersName": "湖北京山农村商业银行",
  "headquartersShortName": "湖北京山农商",
  "headquartersNo": "HBJINGSRCB"
}, {
  "headquartersName": "湖北荆门掇刀德通村镇银行",
  "headquartersShortName": "湖北荆门掇刀德通村镇银行",
  "headquartersNo": "HBJMDDBSCUNZB"
}, {
  "headquartersName": "湖北荆门农村商业银行",
  "headquartersShortName": "湖北荆门农商",
  "headquartersNo": "HBJMRCB"
}, {
  "headquartersName": "湖北荆州农村商业银行",
  "headquartersShortName": "湖北荆州农商",
  "headquartersNo": "HUBJZRCB"
}, {
  "headquartersName": "湖北来凤农村商业银行",
  "headquartersShortName": "湖北来凤农商",
  "headquartersNo": "HBLFRCB"
}, {
  "headquartersName": "湖北老河口农村商业银行",
  "headquartersShortName": "湖北老河口农商",
  "headquartersNo": "HBLHKRCB"
}, {
  "headquartersName": "湖北利川农村商业银行",
  "headquartersShortName": "湖北利川农商",
  "headquartersNo": "HUBLCRCB"
}, {
  "headquartersName": "湖北罗田农村商业银行",
  "headquartersShortName": "湖北罗田农商",
  "headquartersNo": "HBLTRCB"
}, {
  "headquartersName": "湖北麻城汇丰村镇银行",
  "headquartersShortName": "湖北麻城汇丰村镇银行",
  "headquartersNo": "HBMCHFCUNZB"
}, {
  "headquartersName": "湖北麻城农村商业银行",
  "headquartersShortName": "湖北麻城农商",
  "headquartersNo": "HBMCRCB"
}, {
  "headquartersName": "湖北南漳农村商业银行",
  "headquartersShortName": "湖北南漳农商",
  "headquartersNo": "HBNZRCB"
}, {
  "headquartersName": "湖北蕲春农村商业银行",
  "headquartersShortName": "湖北蕲春农商",
  "headquartersNo": "HBQCRCB"
}, {
  "headquartersName": "湖北潜江农村商业银行",
  "headquartersShortName": "湖北潜江农商",
  "headquartersNo": "HBQJRCB"
}, {
  "headquartersName": "湖北三峡农村商业银行",
  "headquartersShortName": "湖北三峡农商",
  "headquartersNo": "HBSXIARCB"
}, {
  "headquartersName": "湖北沙洋农村商业银行",
  "headquartersShortName": "湖北沙洋农商",
  "headquartersNo": "HBSYRCB"
}, {
  "headquartersName": "湖北神农架农村商业银行",
  "headquartersShortName": "湖北神农架农商",
  "headquartersNo": "HBSNJRCB"
}, {
  "headquartersName": "湖北省农村信用社联合社",
  "headquartersShortName": "湖北农信社",
  "headquartersNo": "HBNXS"
}, {
  "headquartersName": "湖北十堰农村商业银行",
  "headquartersShortName": "湖北十堰农商",
  "headquartersNo": "HBSHIYRCB"
}, {
  "headquartersName": "湖北石首农村商业银行",
  "headquartersShortName": "湖北石首农商",
  "headquartersNo": "HBSSRCB"
}, {
  "headquartersName": "湖北松滋农村商业银行",
  "headquartersShortName": "湖北松滋农商",
  "headquartersNo": "HBSZIRCB"
}, {
  "headquartersName": "湖北随州曾都汇丰村镇银行",
  "headquartersShortName": "湖北随州曾都汇丰村镇银行",
  "headquartersNo": "HBSZZDHFCUNZB"
}, {
  "headquartersName": "湖北随州农村商业银行",
  "headquartersShortName": "湖北随州农商",
  "headquartersNo": "HBSZRCB"
}, {
  "headquartersName": "湖北天门汇丰村镇银行",
  "headquartersShortName": "湖北天门汇丰村镇银行",
  "headquartersNo": "HBTMHFCUNZB"
}, {
  "headquartersName": "湖北天门农村商业银行",
  "headquartersShortName": "湖北天门农商",
  "headquartersNo": "HBTMRCB"
}, {
  "headquartersName": "湖北通城农村商业银行",
  "headquartersShortName": "湖北通城农商",
  "headquartersNo": "HBTCRCB"
}, {
  "headquartersName": "湖北通山农村商业银行",
  "headquartersShortName": "湖北通山农商",
  "headquartersNo": "HBTONGSRCB"
}, {
  "headquartersName": "湖北团风农村商业银行",
  "headquartersShortName": "湖北团风农商",
  "headquartersNo": "HBTFRCB"
}, {
  "headquartersName": "湖北五峰农村商业银行",
  "headquartersShortName": "湖北五峰农商",
  "headquartersNo": "HBWFRCB"
}, {
  "headquartersName": "湖北武当山农村商业银行",
  "headquartersShortName": "湖北武当山农商",
  "headquartersNo": "HBWDSRCB"
}, {
  "headquartersName": "湖北武穴农村商业银行",
  "headquartersShortName": "湖北武穴农商",
  "headquartersNo": "HBWXRCB"
}, {
  "headquartersName": "湖北浠水农村商业银行",
  "headquartersShortName": "湖北浠水农商",
  "headquartersNo": "HBXISRCB"
}, {
  "headquartersName": "湖北仙桃农村商业银行",
  "headquartersShortName": "湖北仙桃农商",
  "headquartersNo": "HUBXTRCB"
}, {
  "headquartersName": "湖北咸安武农商村镇银行",
  "headquartersShortName": "湖北咸安武农商村镇银行",
  "headquartersNo": "HBXAWNSCUNZB"
}, {
  "headquartersName": "湖北咸安长江村镇银行",
  "headquartersShortName": "湖北咸安长江村镇银行",
  "headquartersNo": "HBXACJCUNZB"
}, {
  "headquartersName": "湖北咸丰农村商业银行",
  "headquartersShortName": "湖北咸丰农商",
  "headquartersNo": "HBXFRCB"
}, {
  "headquartersName": "湖北咸宁农村商业银行",
  "headquartersShortName": "湖北咸宁农商",
  "headquartersNo": "HBXNRCB"
}, {
  "headquartersName": "湖北襄阳农村商业银行",
  "headquartersShortName": "湖北襄阳农商",
  "headquartersNo": "HBXYRCB"
}, {
  "headquartersName": "湖北孝昌农村商业银行",
  "headquartersShortName": "湖北孝昌农商",
  "headquartersNo": "HBXCRCB"
}, {
  "headquartersName": "湖北孝感农村商业银行",
  "headquartersShortName": "湖北孝感农商",
  "headquartersNo": "HBXGRCB"
}, {
  "headquartersName": "湖北兴山农村商业银行",
  "headquartersShortName": "湖北兴山农商",
  "headquartersNo": "HBXSRCB"
}, {
  "headquartersName": "湖北宣恩农村商业银行",
  "headquartersShortName": "湖北宣恩农商",
  "headquartersNo": "HBXERCB"
}, {
  "headquartersName": "湖北阳新农村商业银行",
  "headquartersShortName": "湖北阳新农商",
  "headquartersNo": "HBYXRCB"
}, {
  "headquartersName": "湖北宜城农村商业银行",
  "headquartersShortName": "湖北宜城农商",
  "headquartersNo": "HBYICRCB"
}, {
  "headquartersName": "湖北宜都农村商业银行",
  "headquartersShortName": "湖北宜都农商",
  "headquartersNo": "HBYDRCB"
}, {
  "headquartersName": "湖北银行",
  "headquartersShortName": "湖北",
  "headquartersNo": "HUBYH"
}, {
  "headquartersName": "湖北应城农村商业银行",
  "headquartersShortName": "湖北应城农商",
  "headquartersNo": "HBYCRCB"
}, {
  "headquartersName": "湖北英山农村商业银行",
  "headquartersShortName": "湖北英山农商",
  "headquartersNo": "HBYSRCB"
}, {
  "headquartersName": "湖北英山长江村镇银行",
  "headquartersShortName": "湖北英山长江村镇银行",
  "headquartersNo": "HBYSCJCUNZB"
}, {
  "headquartersName": "湖北远安农村商业银行",
  "headquartersShortName": "湖北远安农商",
  "headquartersNo": "HBYARCB"
}, {
  "headquartersName": "湖北云梦农村商业银行",
  "headquartersShortName": "湖北云梦农商",
  "headquartersNo": "HBYMRCB"
}, {
  "headquartersName": "湖北郧西农村商业银行",
  "headquartersShortName": "湖北郧西农商",
  "headquartersNo": "HBYXIRCB"
}, {
  "headquartersName": "湖北郧县农村商业银行",
  "headquartersShortName": "湖北郧县农商",
  "headquartersNo": "HBYUNXRCB"
}, {
  "headquartersName": "湖北枣阳农村商业银行",
  "headquartersShortName": "湖北枣阳农商",
  "headquartersNo": "HBZYRCB"
}, {
  "headquartersName": "湖北长阳农村商业银行",
  "headquartersShortName": "湖北长阳农商",
  "headquartersNo": "HBCYANRCB"
}, {
  "headquartersName": "湖北枝江农村商业银行",
  "headquartersShortName": "湖北枝江农商",
  "headquartersNo": "HBZJRCB"
}, {
  "headquartersName": "湖北钟祥农村商业银行",
  "headquartersShortName": "湖北钟祥农商",
  "headquartersNo": "HBZXRCB"
}, {
  "headquartersName": "湖北竹山农村商业银行",
  "headquartersShortName": "湖北竹山农商",
  "headquartersNo": "HBZSRCB"
}, {
  "headquartersName": "湖北竹溪农村商业银行",
  "headquartersShortName": "湖北竹溪农商",
  "headquartersNo": "HBZXIRCB"
}, {
  "headquartersName": "湖北秭归农村商业银行",
  "headquartersShortName": "湖北秭归农商",
  "headquartersNo": "HBZGRCB"
}, {
  "headquartersName": "湖口九银村镇银行",
  "headquartersShortName": "湖口九银村镇银行",
  "headquartersNo": "HKJYCUNZB"
}, {
  "headquartersName": "湖南安化农村商业银行",
  "headquartersShortName": "湖南安化农商",
  "headquartersNo": "HNAHRCB"
}, {
  "headquartersName": "湖南安化湘淮村镇银行",
  "headquartersShortName": "湖南安化湘淮村镇银行",
  "headquartersNo": "HNAHXHCUNZB"
}, {
  "headquartersName": "湖南安仁农村商业银行",
  "headquartersShortName": "湖南安仁农商",
  "headquartersNo": "HNARRCB"
}, {
  "headquartersName": "湖南安仁新阳村镇银行",
  "headquartersShortName": "湖南安仁新阳村镇银行",
  "headquartersNo": "HNARXYCUNZB"
}, {
  "headquartersName": "湖南安乡农村商业银行",
  "headquartersShortName": "湖南安乡农商",
  "headquartersNo": "HNAXRCB"
}, {
  "headquartersName": "湖南保靖农村商业银行",
  "headquartersShortName": "湖南保靖农商",
  "headquartersNo": "HUNBJRCB"
}, {
  "headquartersName": "湖南茶陵农村商业银行",
  "headquartersShortName": "湖南茶陵农商",
  "headquartersNo": "HNCLRCB"
}, {
  "headquartersName": "湖南常宁农村商业银行",
  "headquartersShortName": "湖南常宁农商",
  "headquartersNo": "HNCNRCB"
}, {
  "headquartersName": "湖南辰溪农村商业银行",
  "headquartersShortName": "湖南辰溪农商",
  "headquartersNo": "HNCXRCB"
}, {
  "headquartersName": "湖南城步农村商业银行",
  "headquartersShortName": "湖南城步农商",
  "headquartersNo": "HNCBRCB"
}, {
  "headquartersName": "湖南慈利农村商业银行",
  "headquartersShortName": "湖南慈利农商",
  "headquartersNo": "HNCILRCB"
}, {
  "headquartersName": "湖南道县农村商业银行",
  "headquartersShortName": "湖南道县农商",
  "headquartersNo": "HNDXRCB"
}, {
  "headquartersName": "湖南道县神农村镇银行",
  "headquartersShortName": "湖南道县神农村镇银行",
  "headquartersNo": "HNDXSRCUNZB"
}, {
  "headquartersName": "湖南东安农村商业银行",
  "headquartersShortName": "湖南东安农商",
  "headquartersNo": "HNDARCB"
}, {
  "headquartersName": "湖南东安星龙村镇银行",
  "headquartersShortName": "湖南东安星龙村镇银行",
  "headquartersNo": "HNDAXLCUNZB"
}, {
  "headquartersName": "湖南洞口农村商业银行",
  "headquartersShortName": "湖南洞口农商",
  "headquartersNo": "HNDKRCB"
}, {
  "headquartersName": "湖南洞口潭农商村镇银行",
  "headquartersShortName": "湖南洞口潭农商村镇银行",
  "headquartersNo": "HNDKTNSCUNZB"
}, {
  "headquartersName": "湖南凤凰农村商业银行",
  "headquartersShortName": "湖南凤凰农商",
  "headquartersNo": "HNFHRCB"
}, {
  "headquartersName": "湖南古丈农村商业银行",
  "headquartersShortName": "湖南古丈农商",
  "headquartersNo": "HNGZRCB"
}, {
  "headquartersName": "湖南桂东农村商业银行",
  "headquartersShortName": "湖南桂东农商",
  "headquartersNo": "HNGDRCB"
}, {
  "headquartersName": "湖南桂阳农村商业银行",
  "headquartersShortName": "湖南桂阳农商",
  "headquartersNo": "HUNGYRCB"
}, {
  "headquartersName": "湖南汉寿农村商业银行",
  "headquartersShortName": "湖南汉寿农商",
  "headquartersNo": "HNHANSRCB"
}, {
  "headquartersName": "湖南汉寿星龙村镇银行",
  "headquartersShortName": "湖南汉寿星龙村镇银行",
  "headquartersNo": "HNHSXLCUNZB"
}, {
  "headquartersName": "湖南衡东农村商业银行",
  "headquartersShortName": "湖南衡东农商",
  "headquartersNo": "HNHDRCB"
}, {
  "headquartersName": "湖南衡东新阳村镇银行",
  "headquartersShortName": "湖南衡东新阳村镇银行",
  "headquartersNo": "HNHDXYCUNZB"
}, {
  "headquartersName": "湖南衡南农村商业银行",
  "headquartersShortName": "湖南衡南农商",
  "headquartersNo": "HNHNRCB"
}, {
  "headquartersName": "湖南衡山农村商业银行",
  "headquartersShortName": "湖南衡山农商",
  "headquartersNo": "HNHSRCB"
}, {
  "headquartersName": "湖南衡山潭农商村镇银行",
  "headquartersShortName": "湖南衡山潭农商村镇银行",
  "headquartersNo": "HNHSTNSCUNZB"
}, {
  "headquartersName": "湖南衡阳衡州农村商业银行",
  "headquartersShortName": "湖南衡阳衡州农商",
  "headquartersNo": "HNHYHZRCB"
}, {
  "headquartersName": "湖南洪江农村商业银行",
  "headquartersShortName": "湖南洪江农商",
  "headquartersNo": "HUNHJRCB"
}, {
  "headquartersName": "湖南洪江湘农村镇银行",
  "headquartersShortName": "湖南洪江湘农村镇银行",
  "headquartersNo": "HNHJXNCUNZB"
}, {
  "headquartersName": "湖南花垣农村商业银行",
  "headquartersShortName": "湖南花垣农商",
  "headquartersNo": "HNHYRCB"
}, {
  "headquartersName": "湖南华容农村商业银行",
  "headquartersShortName": "湖南华容农商",
  "headquartersNo": "HNHRRCB"
}, {
  "headquartersName": "湖南华容星龙村镇银行",
  "headquartersShortName": "湖南华容星龙村镇银行",
  "headquartersNo": "HNHRXLCUNZB"
}, {
  "headquartersName": "湖南会同农村商业银行",
  "headquartersShortName": "湖南会同农商",
  "headquartersNo": "HNHTRCB"
}, {
  "headquartersName": "湖南吉首农村商业银行",
  "headquartersShortName": "湖南吉首农商",
  "headquartersNo": "HNJISRCB"
}, {
  "headquartersName": "湖南嘉禾农村商业银行",
  "headquartersShortName": "湖南嘉禾农商",
  "headquartersNo": "HNJHERCB"
}, {
  "headquartersName": "湖南江华农村商业银行",
  "headquartersShortName": "湖南江华农商",
  "headquartersNo": "HNJHRCB"
}, {
  "headquartersName": "湖南江华新阳村镇银行",
  "headquartersShortName": "湖南江华新阳村镇银行",
  "headquartersNo": "HNJHXYCUNZB"
}, {
  "headquartersName": "湖南江永农村商业银行",
  "headquartersShortName": "湖南江永农商",
  "headquartersNo": "HNJYONGRCB"
}, {
  "headquartersName": "湖南江永湘农村镇银行",
  "headquartersShortName": "湖南江永湘农村镇银行",
  "headquartersNo": "HNJYXNCUNZB"
}, {
  "headquartersName": "湖南津市农村商业银行",
  "headquartersShortName": "湖南津市农商",
  "headquartersNo": "HNJSRCB"
}, {
  "headquartersName": "湖南津市湘淮村镇银行",
  "headquartersShortName": "湖南津市湘淮村镇银行",
  "headquartersNo": "HNJSXHCUNZB"
}, {
  "headquartersName": "湖南靖州农村商业银行",
  "headquartersShortName": "湖南靖州农商",
  "headquartersNo": "HNJZRCB"
}, {
  "headquartersName": "湖南蓝山农村商业银行",
  "headquartersShortName": "湖南蓝山农商",
  "headquartersNo": "HNLANSRCB"
}, {
  "headquartersName": "湖南蓝山神农村镇银行",
  "headquartersShortName": "湖南蓝山神农村镇银行",
  "headquartersNo": "HNLSSNCUNZB"
}, {
  "headquartersName": "湖南耒阳农村商业银行",
  "headquartersShortName": "湖南耒阳农商",
  "headquartersNo": "HNLEIYRCB"
}, {
  "headquartersName": "湖南冷水江农村商业银行",
  "headquartersShortName": "湖南冷水江农商",
  "headquartersNo": "HNLSJRCB"
}, {
  "headquartersName": "湖南冷水江湘淮村镇银行",
  "headquartersShortName": "湖南冷水江湘淮村镇银行",
  "headquartersNo": "HNLSJXHCUNZB"
}, {
  "headquartersName": "湖南澧县农村商业银行",
  "headquartersShortName": "湖南澧县农商",
  "headquartersNo": "HNLIXRCB"
}, {
  "headquartersName": "湖南醴陵农村商业银行",
  "headquartersShortName": "湖南醴陵农商",
  "headquartersNo": "HNLLRCB"
}, {
  "headquartersName": "湖南涟源农村商业银行",
  "headquartersShortName": "湖南涟源农商",
  "headquartersNo": "HNLYRCB"
}, {
  "headquartersName": "湖南临澧农村商业银行",
  "headquartersShortName": "湖南临澧农商",
  "headquartersNo": "HNLLIRCB"
}, {
  "headquartersName": "湖南临武农村商业银行",
  "headquartersShortName": "湖南临武农商",
  "headquartersNo": "HNLWRCB"
}, {
  "headquartersName": "湖南临湘农村商业银行",
  "headquartersShortName": "湖南临湘农商",
  "headquartersNo": "HNLXRCB"
}, {
  "headquartersName": "湖南浏阳江淮村镇银行",
  "headquartersShortName": "湖南浏阳江淮村镇银行",
  "headquartersNo": "HNZYJHCUNZB"
}, {
  "headquartersName": "湖南浏阳农村商业银行",
  "headquartersShortName": "湖南浏阳农商",
  "headquartersNo": "HNLYANGRCB"
}, {
  "headquartersName": "湖南龙山农村商业银行",
  "headquartersShortName": "湖南龙山农商",
  "headquartersNo": "HNLSRCB"
}, {
  "headquartersName": "湖南隆回农村商业银行",
  "headquartersShortName": "湖南隆回农商",
  "headquartersNo": "HNLHRCB"
}, {
  "headquartersName": "湖南隆回湘江村镇银行",
  "headquartersShortName": "湖南隆回湘江村镇银行",
  "headquartersNo": "HNLHXJCUNZB"
}, {
  "headquartersName": "湖南娄底农村商业银行",
  "headquartersShortName": "湖南娄底农商",
  "headquartersNo": "HNLDRCB"
}, {
  "headquartersName": "湖南泸溪农村商业银行",
  "headquartersShortName": "湖南泸溪农商",
  "headquartersNo": "HNLUXRCB"
}, {
  "headquartersName": "湖南麻阳农村商业银行",
  "headquartersShortName": "湖南麻阳农商",
  "headquartersNo": "HNMYRCB"
}, {
  "headquartersName": "湖南汨罗农村商业银行",
  "headquartersShortName": "湖南汨罗农商",
  "headquartersNo": "HNMLRCB"
}, {
  "headquartersName": "湖南南县农村商业银行",
  "headquartersShortName": "湖南南县农商",
  "headquartersNo": "HNNANXRCB"
}, {
  "headquartersName": "湖南南县湘江村镇银行",
  "headquartersShortName": "湖南南县湘江村镇银行",
  "headquartersNo": "HNNXXJCUNZB"
}, {
  "headquartersName": "湖南南岳农村商业银行",
  "headquartersShortName": "湖南南岳农商",
  "headquartersNo": "HNNANYRCB"
}, {
  "headquartersName": "湖南宁乡农村商业银行",
  "headquartersShortName": "湖南宁乡农商",
  "headquartersNo": "HNNXRCB"
}, {
  "headquartersName": "湖南宁远农村商业银行",
  "headquartersShortName": "湖南宁远农商",
  "headquartersNo": "HNNYRCB"
}, {
  "headquartersName": "湖南宁远潭农商村镇银行",
  "headquartersShortName": "湖南宁远潭农商村镇银行",
  "headquartersNo": "HNNYTNSCUNZB"
}, {
  "headquartersName": "湖南平江汇丰村镇银行",
  "headquartersShortName": "湖南平江汇丰村镇银行",
  "headquartersNo": "HNPJHFCUNZB"
}, {
  "headquartersName": "湖南平江农村商业银行",
  "headquartersShortName": "湖南平江农商",
  "headquartersNo": "HNPJRCB"
}, {
  "headquartersName": "湖南祁东农村商业银行",
  "headquartersShortName": "湖南祁东农商",
  "headquartersNo": "HNQDRCB"
}, {
  "headquartersName": "湖南祁阳农村商业银行",
  "headquartersShortName": "湖南祁阳农商",
  "headquartersNo": "HNQYRCB"
}, {
  "headquartersName": "湖南汝城农村商业银行",
  "headquartersShortName": "湖南汝城农商",
  "headquartersNo": "HNRCRCB"
}, {
  "headquartersName": "湖南汝城新阳村镇银行",
  "headquartersShortName": "湖南汝城新阳村镇银行",
  "headquartersNo": "HNRCXYCUNZB"
}, {
  "headquartersName": "湖南三湘银行股份有限公司",
  "headquartersShortName": "三湘",
  "headquartersNo": "csxbank"
}, {
  "headquartersName": "湖南桑植农村商业银行",
  "headquartersShortName": "湖南桑植农商",
  "headquartersNo": "HNSZRCB"
}, {
  "headquartersName": "湖南桑植湘农村镇银行",
  "headquartersShortName": "湖南桑植湘农村镇银行",
  "headquartersNo": "HNSZXNCUNZB"
}, {
  "headquartersName": "湖南韶山农村商业银行",
  "headquartersShortName": "湖南韶山农商",
  "headquartersNo": "HUNSSRCB"
}, {
  "headquartersName": "湖南邵东农村商业银行",
  "headquartersShortName": "湖南邵东农商",
  "headquartersNo": "HNSDRCB"
}, {
  "headquartersName": "湖南邵东湘淮村镇银行",
  "headquartersShortName": "湖南邵东湘淮村镇银行",
  "headquartersNo": "HNSDXHCUNZB"
}, {
  "headquartersName": "湖南邵阳湘江村镇银行",
  "headquartersShortName": "湖南邵阳湘江村镇银行",
  "headquartersNo": "HNSYXJCUNZB"
}, {
  "headquartersName": "湖南邵阳昭阳农村商业银行",
  "headquartersShortName": "湖南邵阳昭阳农商",
  "headquartersNo": "HUNSYRCB"
}, {
  "headquartersName": "湖南省农村信用社联合社",
  "headquartersShortName": "湖南农信社",
  "headquartersNo": "HUNNXS"
}, {
  "headquartersName": "湖南石门农村商业银行",
  "headquartersShortName": "湖南石门农商",
  "headquartersNo": "HNSMRCB"
}, {
  "headquartersName": "湖南双峰农村商业银行",
  "headquartersShortName": "湖南双峰农商",
  "headquartersNo": "HNSFRCB"
}, {
  "headquartersName": "湖南双牌农村商业银行",
  "headquartersShortName": "湖南双牌农商",
  "headquartersNo": "HNSPRCB"
}, {
  "headquartersName": "湖南双牌湘农村镇银行",
  "headquartersShortName": "湖南双牌湘农村镇银行",
  "headquartersNo": "HNSPXNCUNZB"
}, {
  "headquartersName": "湖南绥宁农村商业银行",
  "headquartersShortName": "湖南绥宁农商",
  "headquartersNo": "HNSNRCB"
}, {
  "headquartersName": "湖南绥宁湘农村镇银行",
  "headquartersShortName": "湖南绥宁湘农村镇银行",
  "headquartersNo": "HNSNXNCUNZB"
}, {
  "headquartersName": "湖南桃江农村商业银行",
  "headquartersShortName": "湖南桃江农商",
  "headquartersNo": "HNTJRCB"
}, {
  "headquartersName": "湖南桃源农村商业银行",
  "headquartersShortName": "湖南桃源农商",
  "headquartersNo": "HUNTYRCB"
}, {
  "headquartersName": "湖南桃源湘淮村镇银行",
  "headquartersShortName": "湖南桃源湘淮村镇银行",
  "headquartersNo": "HNTYXHCUNZB"
}, {
  "headquartersName": "湖南通道农村商业银行",
  "headquartersShortName": "湖南通道农商",
  "headquartersNo": "HNTDRCB"
}, {
  "headquartersName": "湖南武冈农村商业银行",
  "headquartersShortName": "湖南武冈农商",
  "headquartersNo": "HUNWGRCB"
}, {
  "headquartersName": "湖南湘江新区农村商业银行",
  "headquartersShortName": "湖南湘江新区农商",
  "headquartersNo": "HNXJXQRCB"
}, {
  "headquartersName": "湖南湘潭天易农村商业银行",
  "headquartersShortName": "湖南湘潭天易农商",
  "headquartersNo": "HNXTTYRCB"
}, {
  "headquartersName": "湖南湘潭湘淮村镇银行",
  "headquartersShortName": "湖南湘潭湘淮村镇银行",
  "headquartersNo": "HNXTXHCUNZB"
}, {
  "headquartersName": "湖南湘乡农村商业银行",
  "headquartersShortName": "湖南湘乡农商",
  "headquartersNo": "HUNXXRCB"
}, {
  "headquartersName": "湖南湘阴农村商业银行",
  "headquartersShortName": "湖南湘阴农商",
  "headquartersNo": "HUNXYRCB"
}, {
  "headquartersName": "湖南湘阴星龙村镇银行",
  "headquartersShortName": "湖南湘阴星龙村镇银行",
  "headquartersNo": "HNXYXLCUNZB"
}, {
  "headquartersName": "湖南新化农村商业银行",
  "headquartersShortName": "湖南新化农商",
  "headquartersNo": "HNXHRCB"
}, {
  "headquartersName": "湖南新化星龙村镇银行",
  "headquartersShortName": "湖南新化星龙村镇银行",
  "headquartersNo": "HNXHXLCUNZB"
}, {
  "headquartersName": "湖南新晃农村商业银行",
  "headquartersShortName": "湖南新晃农商",
  "headquartersNo": "HNXHUANGRCB"
}, {
  "headquartersName": "湖南新宁农村商业银行",
  "headquartersShortName": "湖南新宁农商",
  "headquartersNo": "HNXNRCB"
}, {
  "headquartersName": "湖南新宁潭农商村镇银行",
  "headquartersShortName": "湖南新宁潭农商村镇银行",
  "headquartersNo": "HNXNTNSCUNZB"
}, {
  "headquartersName": "湖南新邵农村商业银行",
  "headquartersShortName": "湖南新邵农商",
  "headquartersNo": "HNXINSRCB"
}, {
  "headquartersName": "湖南新邵湘淮村镇银行",
  "headquartersShortName": "湖南新邵湘淮村镇银行",
  "headquartersNo": "HNXSXHCUNZB"
}, {
  "headquartersName": "湖南新田农村商业银行",
  "headquartersShortName": "湖南新田农商",
  "headquartersNo": "HNXTRCB"
}, {
  "headquartersName": "湖南新田湘农村镇银行",
  "headquartersShortName": "湖南新田湘农村镇银行",
  "headquartersNo": "HNXTXNCUNZB"
}, {
  "headquartersName": "湖南星沙农村商业银行",
  "headquartersShortName": "湖南星沙农商",
  "headquartersNo": "HNXSRCB"
}, {
  "headquartersName": "湖南溆浦农村商业银行",
  "headquartersShortName": "湖南溆浦农商",
  "headquartersNo": "HUNXPRCB"
}, {
  "headquartersName": "湖南溆浦湘江村镇银行",
  "headquartersShortName": "湖南溆浦湘江村镇银行",
  "headquartersNo": "HNXPXJCUNZB"
}, {
  "headquartersName": "湖南炎陵农村商业银行",
  "headquartersShortName": "湖南炎陵农商",
  "headquartersNo": "HUNYANLRCB"
}, {
  "headquartersName": "湖南炎陵星龙村镇银行",
  "headquartersShortName": "湖南炎陵星龙村镇银行",
  "headquartersNo": "HNYLXLCUNZB"
}, {
  "headquartersName": "湖南宜章农村商业银行",
  "headquartersShortName": "湖南宜章农商",
  "headquartersNo": "HNYZRCB"
}, {
  "headquartersName": "湖南永顺农村商业银行",
  "headquartersShortName": "湖南永顺农商",
  "headquartersNo": "HUNYSRCB"
}, {
  "headquartersName": "湖南永兴农村商业银行",
  "headquartersShortName": "湖南永兴农商",
  "headquartersNo": "HNYXRCB"
}, {
  "headquartersName": "湖南攸县农村商业银行",
  "headquartersShortName": "湖南攸县农商",
  "headquartersNo": "HNYOUXRCB"
}, {
  "headquartersName": "湖南攸县潭农商村镇银行",
  "headquartersShortName": "湖南攸县潭农商村镇银行",
  "headquartersNo": "HNYXTNSCUNZB"
}, {
  "headquartersName": "湖南沅江农村商业银行",
  "headquartersShortName": "湖南沅江农商",
  "headquartersNo": "HNYJRCB"
}, {
  "headquartersName": "湖南沅陵农村商业银行",
  "headquartersShortName": "湖南沅陵农商",
  "headquartersNo": "HUNYLRCB"
}, {
  "headquartersName": "湖南沅陵新阳村镇银行",
  "headquartersShortName": "湖南沅陵新阳村镇银行",
  "headquartersNo": "HNYLXYCUNZB"
}, {
  "headquartersName": "湖南岳阳巴陵农村商业银行",
  "headquartersShortName": "湖南岳阳巴陵农商",
  "headquartersNo": "HNYYBLRCB"
}, {
  "headquartersName": "湖南岳阳农村商业银行",
  "headquartersShortName": "湖南岳阳农商",
  "headquartersNo": "HUNYYRCB"
}, {
  "headquartersName": "湖南岳阳湘江村镇银行",
  "headquartersShortName": "湖南岳阳湘江村镇银行",
  "headquartersNo": "HNYYXJCUNZB"
}, {
  "headquartersName": "湖南芷江农村商业银行",
  "headquartersShortName": "湖南芷江农商",
  "headquartersNo": "HNZJRCB"
}, {
  "headquartersName": "湖南芷江湘江村镇银行",
  "headquartersShortName": "湖南芷江湘江村镇银行",
  "headquartersNo": "HNZJXJCUNZB"
}, {
  "headquartersName": "湖南中方农村商业银行",
  "headquartersShortName": "湖南中方农商",
  "headquartersNo": "HNZFRCB"
}, {
  "headquartersName": "湖南中方新阳村镇银行",
  "headquartersShortName": "湖南中方新阳村镇银行",
  "headquartersNo": "HNZFXYCUNZB"
}, {
  "headquartersName": "湖南株洲珠江农村商业银行",
  "headquartersShortName": "湖南株洲珠江农商",
  "headquartersNo": "HNZZZJRCB"
}, {
  "headquartersName": "湖南资兴农村商业银行",
  "headquartersShortName": "湖南资兴农商",
  "headquartersNo": "HNZXRCB"
}, {
  "headquartersName": "湖州吴兴农村商业银行",
  "headquartersShortName": "湖州吴兴农商",
  "headquartersNo": "HZWXRCB"
}, {
  "headquartersName": "湖州银行",
  "headquartersShortName": "湖州",
  "headquartersNo": "HUZYH"
}, {
  "headquartersName": "花旗银行",
  "headquartersShortName": "花旗",
  "headquartersNo": "CITIBANK"
}, {
  "headquartersName": "华美银行",
  "headquartersShortName": "华美",
  "headquartersNo": "EASTWESTBANK"
}, {
  "headquartersName": "华南商业银行",
  "headquartersShortName": "华南",
  "headquartersNo": "HNCCB"
}, {
  "headquartersName": "华侨永亨银行（中国）有限公司",
  "headquartersShortName": "华侨永亨",
  "headquartersNo": "OCBC"
}, {
  "headquartersName": "华融湘江银行",
  "headquartersShortName": "华融湘江",
  "headquartersNo": "HRXJYH"
}, {
  "headquartersName": "华商银行",
  "headquartersShortName": "华商",
  "headquartersNo": "CMBCN"
}, {
  "headquartersName": "华夏银行",
  "headquartersShortName": "华夏",
  "headquartersNo": "HXB"
}, {
  "headquartersName": "华一银行",
  "headquartersShortName": "华一",
  "headquartersNo": "COB"
}, {
  "headquartersName": "化德蒙商村镇银行",
  "headquartersShortName": "化德蒙商村镇银行",
  "headquartersNo": "HDBSCUNZB"
}, {
  "headquartersName": "桦川融兴村镇银行",
  "headquartersShortName": "桦川融兴村镇银行",
  "headquartersNo": "ZCRXCUNZB"
}, {
  "headquartersName": "桦甸惠民村镇银行",
  "headquartersShortName": "桦甸惠民村镇银行",
  "headquartersNo": "ZDHMCUNZB"
}, {
  "headquartersName": "桦南融兴村镇银行",
  "headquartersShortName": "桦南融兴村镇银行",
  "headquartersNo": "ZNRXCUNZB"
}, {
  "headquartersName": "怀化农村商业银行",
  "headquartersShortName": "怀化农商",
  "headquartersNo": "HHRCB"
}, {
  "headquartersName": "怀来利丰村镇银行",
  "headquartersShortName": "怀来利丰村镇银行",
  "headquartersNo": "HLLFCUNZB"
}, {
  "headquartersName": "怀仁县慧融村镇银行",
  "headquartersShortName": "怀仁县慧融村镇银行",
  "headquartersNo": "HRXRHCUNZB"
}, {
  "headquartersName": "淮北农村商业银行",
  "headquartersShortName": "淮北农商",
  "headquartersNo": "HBRCB"
}, {
  "headquartersName": "淮南淮河农村商业银行",
  "headquartersShortName": "淮南淮河农商",
  "headquartersNo": "HNHHRCB"
}, {
  "headquartersName": "淮南通商农村商业银行",
  "headquartersShortName": "淮南通商农商",
  "headquartersNo": "HNTSRCB"
}, {
  "headquartersName": "桓台青隆村镇银行",
  "headquartersShortName": "桓台青隆村镇银行",
  "headquartersNo": "HTQLCUNZB"
}, {
  "headquartersName": "黄骅青隆村镇银行",
  "headquartersShortName": "黄骅青隆村镇银行",
  "headquartersNo": "HZQLCUNZB"
}, {
  "headquartersName": "黄平振兴村镇银行",
  "headquartersShortName": "黄平振兴村镇银行",
  "headquartersNo": "HPZXCUNZB"
}, {
  "headquartersName": "黄山徽州农村商业银行",
  "headquartersShortName": "黄山徽州农商",
  "headquartersNo": "HSHZRCB"
}, {
  "headquartersName": "黄山太平农村商业银行",
  "headquartersShortName": "黄山太平农商",
  "headquartersNo": "HSTPRCB"
}, {
  "headquartersName": "黄山屯溪农村商业银行",
  "headquartersShortName": "黄山屯溪农商",
  "headquartersNo": "HSTXRCB"
}, {
  "headquartersName": "黄石农村商业银行",
  "headquartersShortName": "黄石农商",
  "headquartersNo": "HSRCB"
}, {
  "headquartersName": "珲春吉银村镇银行",
  "headquartersShortName": "珲春吉银村镇银行",
  "headquartersNo": "ZCJYCUNZB"
}, {
  "headquartersName": "辉南榆银村镇银行",
  "headquartersShortName": "辉南榆银村镇银行",
  "headquartersNo": "HNYYCUNZB"
}, {
  "headquartersName": "辉县珠江村镇银行",
  "headquartersShortName": "辉县珠江村镇银行",
  "headquartersNo": "HXZJCUNZB"
}, {
  "headquartersName": "徽商银行",
  "headquartersShortName": "徽商",
  "headquartersNo": "AHCB"
}, {
  "headquartersName": "汇丰银行（中国）有限公司",
  "headquartersShortName": "汇丰",
  "headquartersNo": "HFB"
}, {
  "headquartersName": "会宁会师村镇银行",
  "headquartersShortName": "会宁会师村镇银行",
  "headquartersNo": "HNHSCUNZB"
}, {
  "headquartersName": "惠安中成村镇银行",
  "headquartersShortName": "惠安中成村镇银行",
  "headquartersNo": "HAZCCUNZB"
}, {
  "headquartersName": "惠东惠民村镇银行",
  "headquartersShortName": "惠东惠民村镇银行",
  "headquartersNo": "HDHMCUNZB"
}, {
  "headquartersName": "惠水恒升村镇银行",
  "headquartersShortName": "惠水恒升村镇银行",
  "headquartersNo": "HSHSCUNZB"
}, {
  "headquartersName": "惠州博罗长江村镇银行",
  "headquartersShortName": "惠州博罗长江村镇银行",
  "headquartersNo": "HZBLCJCUNZB"
}, {
  "headquartersName": "惠州仲恺东盈村镇银行",
  "headquartersShortName": "惠州仲恺东盈村镇银行",
  "headquartersNo": "HZZZDYCUNZB"
}, {
  "headquartersName": "浑源县慧融村镇银行",
  "headquartersShortName": "浑源县慧融村镇银行",
  "headquartersNo": "HYXHRCUNZB"
}, {
  "headquartersName": "霍林郭勒蒙银村镇银行",
  "headquartersShortName": "霍林郭勒蒙银村镇银行",
  "headquartersNo": "HLGLMYCUNZB"
}, {
  "headquartersName": "吉安稠州村镇银行",
  "headquartersShortName": "吉安稠州村镇银行",
  "headquartersNo": "JACZCUNZB"
}, {
  "headquartersName": "吉安农村商业银行",
  "headquartersShortName": "吉安农商",
  "headquartersNo": "JARCB"
}, {
  "headquartersName": "吉林安图农村商业银行",
  "headquartersShortName": "吉林安图农商",
  "headquartersNo": "JLATRCB"
}, {
  "headquartersName": "吉林昌邑榆银村镇银行",
  "headquartersShortName": "吉林昌邑榆银村镇银行",
  "headquartersNo": "JLCYYYCUNZB"
}, {
  "headquartersName": "吉林船营惠民村镇银行",
  "headquartersShortName": "吉林船营惠民村镇银行",
  "headquartersNo": "JLCYHMCUNZB"
}, {
  "headquartersName": "吉林春城农村商业银行",
  "headquartersShortName": "吉林春城农商",
  "headquartersNo": "JLCCRCB"
}, {
  "headquartersName": "吉林大安农村商业银行",
  "headquartersShortName": "吉林大安农商",
  "headquartersNo": "JLDARCB"
}, {
  "headquartersName": "吉林德惠农村商业银行",
  "headquartersShortName": "吉林德惠农商",
  "headquartersNo": "JLHDNCYH"
}, {
  "headquartersName": "吉林东丰农村商业银行",
  "headquartersShortName": "吉林东丰农商",
  "headquartersNo": "JLDFRCB"
}, {
  "headquartersName": "吉林敦化农村商业银行",
  "headquartersShortName": "吉林敦化农商",
  "headquartersNo": "JLDHRCB"
}, {
  "headquartersName": "吉林丰满惠民村镇银行",
  "headquartersShortName": "吉林丰满惠民村镇银行",
  "headquartersNo": "JLFMHMCUNZB"
}, {
  "headquartersName": "吉林公主岭农村商业银行",
  "headquartersShortName": "吉林公主岭农商",
  "headquartersNo": "JLGZLRCB"
}, {
  "headquartersName": "吉林郭尔罗斯农村商业银行",
  "headquartersShortName": "吉林郭尔罗斯农商",
  "headquartersNo": "JLGELSRCB"
}, {
  "headquartersName": "吉林和龙农村商业银行",
  "headquartersShortName": "吉林和龙农商",
  "headquartersNo": "JLHLRCB"
}, {
  "headquartersName": "吉林桦甸农村商业银行",
  "headquartersShortName": "吉林桦甸农商",
  "headquartersNo": "JLHDRCB"
}, {
  "headquartersName": "吉林环城农村商业银行",
  "headquartersShortName": "吉林环城农商",
  "headquartersNo": "JLHCRCB"
}, {
  "headquartersName": "吉林珲春农村商业银行",
  "headquartersShortName": "吉林珲春农商",
  "headquartersNo": "JLHCHUNRCB"
}, {
  "headquartersName": "吉林辉南农村商业银行",
  "headquartersShortName": "吉林辉南农商",
  "headquartersNo": "JLHNRCB"
}, {
  "headquartersName": "吉林浑江农村商业银行",
  "headquartersShortName": "吉林浑江农商",
  "headquartersNo": "JLHJRCB"
}, {
  "headquartersName": "吉林集安农村商业银行",
  "headquartersShortName": "吉林集安农商",
  "headquartersNo": "JLJARCB"
}, {
  "headquartersName": "吉林蛟河农村商业银行",
  "headquartersShortName": "吉林蛟河农商",
  "headquartersNo": "JLJHRCB"
}, {
  "headquartersName": "吉林靖宇农村商业银行",
  "headquartersShortName": "吉林靖宇农商",
  "headquartersNo": "JLJYRCB"
}, {
  "headquartersName": "吉林九台农村商业银行",
  "headquartersShortName": "吉林九台农商",
  "headquartersNo": "JLJTRCB"
}, {
  "headquartersName": "吉林临江农村商业银行",
  "headquartersShortName": "吉林临江农商",
  "headquartersNo": "JLLJRCB"
}, {
  "headquartersName": "吉林柳河农村商业银行",
  "headquartersShortName": "吉林柳河农商",
  "headquartersNo": "JLLHRCB"
}, {
  "headquartersName": "吉林龙井农村商业银行",
  "headquartersShortName": "吉林龙井农商",
  "headquartersNo": "JLLONGJRCB"
}, {
  "headquartersName": "吉林龙潭华益村镇银行",
  "headquartersShortName": "吉林龙潭华益村镇银行",
  "headquartersNo": "JLLTHYCUNZB"
}, {
  "headquartersName": "吉林农安农村商业银行",
  "headquartersShortName": "吉林农安农商",
  "headquartersNo": "JLNARCB"
}, {
  "headquartersName": "吉林磐石农村商业银行",
  "headquartersShortName": "吉林磐石农商",
  "headquartersNo": "JLPSRCB"
}, {
  "headquartersName": "吉林省农村信用社联合社",
  "headquartersShortName": "吉林农信社",
  "headquartersNo": "JLNXS"
}, {
  "headquartersName": "吉林舒兰农村商业银行",
  "headquartersShortName": "吉林舒兰农商",
  "headquartersNo": "JLSLRCB"
}, {
  "headquartersName": "吉林双阳农村商业银行",
  "headquartersShortName": "吉林双阳农商",
  "headquartersNo": "JLSYRCB"
}, {
  "headquartersName": "吉林通化海科农村商业银行",
  "headquartersShortName": "吉林通化海科农商",
  "headquartersNo": "JLTHHKRCB"
}, {
  "headquartersName": "吉林通榆农村商业银行",
  "headquartersShortName": "吉林通榆农商",
  "headquartersNo": "JLTYRCB"
}, {
  "headquartersName": "吉林汪清农村商业银行",
  "headquartersShortName": "吉林汪清农商",
  "headquartersNo": "JLWQRCB"
}, {
  "headquartersName": "吉林亿联银行",
  "headquartersShortName": "吉林亿联",
  "headquartersNo": "JLYLYH"
}, {
  "headquartersName": "吉林银行",
  "headquartersShortName": "吉林",
  "headquartersNo": "JLYH"
}, {
  "headquartersName": "吉林永吉农村商业银行",
  "headquartersShortName": "吉林永吉农商",
  "headquartersNo": "JLYJRCB"
}, {
  "headquartersName": "吉林榆树农村商业银行",
  "headquartersShortName": "吉林榆树农商",
  "headquartersNo": "JLYSRCB"
}, {
  "headquartersName": "吉林镇赉农村商业银行",
  "headquartersShortName": "吉林镇赉农商",
  "headquartersNo": "JLZLRCB"
}, {
  "headquartersName": "吉水庐陵村镇银行",
  "headquartersShortName": "吉水庐陵村镇银行",
  "headquartersNo": "JSLLCUNZB"
}, {
  "headquartersName": "吉县新田村镇银行",
  "headquartersShortName": "吉县新田村镇银行",
  "headquartersNo": "JXXTCUNZB"
}, {
  "headquartersName": "吉州珠江村镇银行",
  "headquartersShortName": "吉州珠江村镇银行",
  "headquartersNo": "JZZJCUNZB"
}, {
  "headquartersName": "集安惠鑫村镇银行",
  "headquartersShortName": "集安惠鑫村镇银行",
  "headquartersNo": "JAHZCUNZB"
}, {
  "headquartersName": "集贤润生村镇银行",
  "headquartersShortName": "集贤润生村镇银行",
  "headquartersNo": "JXRSCUNZB"
}, {
  "headquartersName": "集友银行有限公司",
  "headquartersShortName": "集友",
  "headquartersNo": "CHIYUBANK"
}, {
  "headquartersName": "济南高新北海村镇银行",
  "headquartersShortName": "济南高新北海村镇银行",
  "headquartersNo": "JNGXBHCUNZB"
}, {
  "headquartersName": "济南槐荫沪农商村镇银行",
  "headquartersShortName": "济南槐荫沪农商村镇银行",
  "headquartersNo": "JNHYHNSCUNZB"
}, {
  "headquartersName": "济南农村商业银行",
  "headquartersShortName": "济南农商",
  "headquartersNo": "JNRCB"
}, {
  "headquartersName": "济南长清沪农商村镇银行",
  "headquartersShortName": "济南长清沪农商村镇银行",
  "headquartersNo": "JNCQHNSCUNZB"
}, {
  "headquartersName": "济宁高新村镇银行",
  "headquartersShortName": "济宁高新村镇银行",
  "headquartersNo": "JNGXCUNZB"
}, {
  "headquartersName": "济宁蓝海村镇银行",
  "headquartersShortName": "济宁蓝海村镇银行",
  "headquartersNo": "JNLHCUNZB"
}, {
  "headquartersName": "济宁农村商业银行",
  "headquartersShortName": "济宁农商",
  "headquartersNo": "JNINGRCB"
}, {
  "headquartersName": "济宁儒商村镇银行",
  "headquartersShortName": "济宁儒商村镇银行",
  "headquartersNo": "JNRSCUNZB"
}, {
  "headquartersName": "济宁银行",
  "headquartersShortName": "济宁",
  "headquartersNo": "JNYH"
}, {
  "headquartersName": "济阳北海村镇银行",
  "headquartersShortName": "济阳北海村镇银行",
  "headquartersNo": "JYBHCUNZB"
}, {
  "headquartersName": "济源齐鲁村镇银行",
  "headquartersShortName": "济源齐鲁村镇银行",
  "headquartersNo": "JYQLCUNZB"
}, {
  "headquartersName": "稷山县河东村镇银行",
  "headquartersShortName": "稷山县河东村镇银行",
  "headquartersNo": "JSXHDCUNZB"
}, {
  "headquartersName": "加拿大丰业银行",
  "headquartersShortName": "加拿大丰业",
  "headquartersNo": "SCTABK"
}, {
  "headquartersName": "嘉兴银行",
  "headquartersShortName": "嘉兴",
  "headquartersNo": "JXCCB"
}, {
  "headquartersName": "嘉峪关农村商业银行",
  "headquartersShortName": "嘉峪关农商",
  "headquartersNo": "JYGRCB"
}, {
  "headquartersName": "郏县广天村镇银行",
  "headquartersShortName": "郏县广天村镇银行",
  "headquartersNo": "ZXGTCUNZB"
}, {
  "headquartersName": "犍为中成村镇银行",
  "headquartersShortName": "犍为中成村镇银行",
  "headquartersNo": "QWZCCUNZB"
}, {
  "headquartersName": "建昌恒昌村镇银行",
  "headquartersShortName": "建昌恒昌村镇银行",
  "headquartersNo": "JCHCCUNZB"
}, {
  "headquartersName": "建湖中成村镇银行",
  "headquartersShortName": "建湖中成村镇银行",
  "headquartersNo": "JHZCCUNZB"
}, {
  "headquartersName": "建宁刺桐红村镇银行",
  "headquartersShortName": "建宁刺桐红村镇银行",
  "headquartersNo": "JNCTHCUNZB"
}, {
  "headquartersName": "建平红山村镇银行",
  "headquartersShortName": "建平红山村镇银行",
  "headquartersNo": "JPHSCUNZB"
}, {
  "headquartersName": "建水沪农商村镇银行",
  "headquartersShortName": "建水沪农商村镇银行",
  "headquartersNo": "JSHNSCUNZB"
}, {
  "headquartersName": "剑河富民村镇银行",
  "headquartersShortName": "剑河富民村镇银行",
  "headquartersNo": "JHFMCUNZB"
}, {
  "headquartersName": "江口长征村镇银行",
  "headquartersShortName": "江口长征村镇银行",
  "headquartersNo": "JKCZCUNZB"
}, {
  "headquartersName": "江陵楚农商村镇银行",
  "headquartersShortName": "江陵楚农商村镇银行",
  "headquartersNo": "JLCNSCUNZB"
}, {
  "headquartersName": "江门开平长江村镇银行",
  "headquartersShortName": "江门开平长江村镇银行",
  "headquartersNo": "JMKPCJCUNZB"
}, {
  "headquartersName": "江门农村商业银行",
  "headquartersShortName": "江门农商",
  "headquartersNo": "JMRCB"
}, {
  "headquartersName": "江门新会新华村镇银行",
  "headquartersShortName": "江门新会新华村镇银行",
  "headquartersNo": "JMXHXHCUNZB"
}, {
  "headquartersName": "江苏宝应锦程村镇银行",
  "headquartersShortName": "江苏宝应锦程村镇银行",
  "headquartersNo": "JSBYJCCUNZB"
}, {
  "headquartersName": "江苏宝应农村商业银行",
  "headquartersShortName": "江苏宝应农商",
  "headquartersNo": "JSBYRCB"
}, {
  "headquartersName": "江苏滨海农村商业银行",
  "headquartersShortName": "江苏滨海农商",
  "headquartersNo": "JSBHRCB"
}, {
  "headquartersName": "江苏常熟农村商业银行",
  "headquartersShortName": "江苏常熟农商",
  "headquartersNo": "CSRCB"
}, {
  "headquartersName": "江苏大丰江南村镇银行",
  "headquartersShortName": "江苏大丰江南村镇银行",
  "headquartersNo": "JSDFJNCUNZB"
}, {
  "headquartersName": "江苏大丰农村商业银行",
  "headquartersShortName": "江苏大丰农商",
  "headquartersNo": "JSDFRCB"
}, {
  "headquartersName": "江苏丹徒蒙银村镇银行",
  "headquartersShortName": "江苏丹徒蒙银村镇银行",
  "headquartersNo": "JSDTMYCUNZB"
}, {
  "headquartersName": "江苏丹阳农村商业银行",
  "headquartersShortName": "江苏丹阳农商",
  "headquartersNo": "JSDYRCB"
}, {
  "headquartersName": "江苏丹阳苏银村镇银行",
  "headquartersShortName": "江苏丹阳苏银村镇银行",
  "headquartersNo": "JSDYBDCUNZB"
}, {
  "headquartersName": "江苏东海农村商业银行",
  "headquartersShortName": "江苏东海农商",
  "headquartersNo": "JSDHRCB"
}, {
  "headquartersName": "江苏东海张农商村镇银行",
  "headquartersShortName": "江苏东海张农商村镇银行",
  "headquartersNo": "JSDHZNSCUNZB"
}, {
  "headquartersName": "江苏东台稠州村镇银行",
  "headquartersShortName": "江苏东台稠州村镇银行",
  "headquartersNo": "JSDTCZCUNZB"
}, {
  "headquartersName": "江苏东台农村商业银行",
  "headquartersShortName": "江苏东台农商",
  "headquartersNo": "JSDTRCB"
}, {
  "headquartersName": "江苏丰县民丰村镇银行",
  "headquartersShortName": "江苏丰县民丰村镇银行",
  "headquartersNo": "JSFXMFCUNZB"
}, {
  "headquartersName": "江苏丰县农村商业银行",
  "headquartersShortName": "江苏丰县农商",
  "headquartersNo": "JSFXRCB"
}, {
  "headquartersName": "江苏阜宁农村商业银行",
  "headquartersShortName": "江苏阜宁农商",
  "headquartersNo": "JSFNRCB"
}, {
  "headquartersName": "江苏赣榆农村商业银行",
  "headquartersShortName": "江苏赣榆农商",
  "headquartersNo": "JSGYRCB"
}, {
  "headquartersName": "江苏赣榆通商村镇银行",
  "headquartersShortName": "江苏赣榆通商村镇银行",
  "headquartersNo": "JSGYTSCUNZB"
}, {
  "headquartersName": "江苏高淳农村商业银行",
  "headquartersShortName": "江苏高淳农商",
  "headquartersNo": "JSGCRCB"
}, {
  "headquartersName": "江苏高邮农村商业银行",
  "headquartersShortName": "江苏高邮农商",
  "headquartersNo": "JSGAOYRCB"
}, {
  "headquartersName": "江苏灌南民丰村镇银行",
  "headquartersShortName": "江苏灌南民丰村镇银行",
  "headquartersNo": "JSGNMFCUNZB"
}, {
  "headquartersName": "江苏灌南农村商业银行",
  "headquartersShortName": "江苏灌南农商",
  "headquartersNo": "JSGNRCB"
}, {
  "headquartersName": "江苏灌云民丰村镇银行",
  "headquartersShortName": "江苏灌云民丰村镇银行",
  "headquartersNo": "JSGYMFCUNZB"
}, {
  "headquartersName": "江苏灌云农村商业银行",
  "headquartersShortName": "江苏灌云农商",
  "headquartersNo": "JSGYUNRCB"
}, {
  "headquartersName": "江苏海安农村商业银行",
  "headquartersShortName": "江苏海安农商",
  "headquartersNo": "HARCB"
}, {
  "headquartersName": "江苏海安盐海村镇银行",
  "headquartersShortName": "江苏海安盐海村镇银行",
  "headquartersNo": "JSHAYHCUNZB"
}, {
  "headquartersName": "江苏海门农村商业银行",
  "headquartersShortName": "江苏海门农商",
  "headquartersNo": "JSHMRCB"
}, {
  "headquartersName": "江苏邗江联合村镇银行",
  "headquartersShortName": "江苏邗江联合村镇银行",
  "headquartersNo": "JSZJLHCUNZB"
}, {
  "headquartersName": "江苏邗江民泰村镇银行",
  "headquartersShortName": "江苏邗江民泰村镇银行",
  "headquartersNo": "JSZJMTCUNZB"
}, {
  "headquartersName": "江苏洪泽金阳光村镇银行",
  "headquartersShortName": "江苏洪泽金阳光村镇银行",
  "headquartersNo": "HZJYGCUNZB"
}, {
  "headquartersName": "江苏洪泽农村商业银行",
  "headquartersShortName": "江苏洪泽农商",
  "headquartersNo": "JSHZRCB"
}, {
  "headquartersName": "江苏淮安光大村镇银行",
  "headquartersShortName": "江苏淮安光大村镇银行",
  "headquartersNo": "HAGDCUNZB"
}, {
  "headquartersName": "江苏淮安农村商业银行",
  "headquartersShortName": "江苏淮安农商",
  "headquartersNo": "JSHARCB"
}, {
  "headquartersName": "江苏惠山民泰村镇银行",
  "headquartersShortName": "江苏惠山民泰村镇银行",
  "headquartersNo": "JSHSMTCUNZB"
}, {
  "headquartersName": "江苏建湖农村商业银行",
  "headquartersShortName": "江苏建湖农商",
  "headquartersNo": "JSJIANHRCB"
}, {
  "headquartersName": "江苏江都吉银村镇银行",
  "headquartersShortName": "江苏江都吉银村镇银行",
  "headquartersNo": "JSJDJYCUNZB"
}, {
  "headquartersName": "江苏江都农村商业银行",
  "headquartersShortName": "江苏江都农商",
  "headquartersNo": "JSJDRCB"
}, {
  "headquartersName": "江苏江南农村商业银行",
  "headquartersShortName": "江苏江南农商",
  "headquartersNo": "JSJNRCB"
}, {
  "headquartersName": "江苏江宁上银村镇银行",
  "headquartersShortName": "江苏江宁上银村镇银行",
  "headquartersNo": "JSJNSYCUNZB"
}, {
  "headquartersName": "江苏江阴农村商业银行",
  "headquartersShortName": "江苏江阴农商",
  "headquartersNo": "JSJYRCB"
}, {
  "headquartersName": "江苏姜堰农村商业银行",
  "headquartersShortName": "江苏姜堰农商",
  "headquartersNo": "JSJYANRCB"
}, {
  "headquartersName": "江苏金湖民泰村镇银行",
  "headquartersShortName": "江苏金湖民泰村镇银行",
  "headquartersNo": "JHMTCUNZB"
}, {
  "headquartersName": "江苏金湖农村商业银行",
  "headquartersShortName": "江苏金湖农商",
  "headquartersNo": "JSJHRCB"
}, {
  "headquartersName": "江苏靖江农村商业银行",
  "headquartersShortName": "江苏靖江农商",
  "headquartersNo": "JSJJRCB"
}, {
  "headquartersName": "江苏靖江润丰村镇银行",
  "headquartersShortName": "江苏靖江润丰村镇银行",
  "headquartersNo": "JSJJRFCUNZB"
}, {
  "headquartersName": "江苏句容农村商业银行",
  "headquartersShortName": "江苏句容农商",
  "headquartersNo": "JSJRRCB"
}, {
  "headquartersName": "江苏昆山农村商业银行",
  "headquartersShortName": "江苏昆山农商",
  "headquartersNo": "KSRCB"
}, {
  "headquartersName": "江苏溧水民丰村镇银行",
  "headquartersShortName": "江苏溧水民丰村镇银行",
  "headquartersNo": "JSZSMFCUNZB"
}, {
  "headquartersName": "江苏溧水农村商业银行",
  "headquartersShortName": "江苏溧水农商",
  "headquartersNo": "JSLSRCB"
}, {
  "headquartersName": "江苏涟水农村商业银行",
  "headquartersShortName": "江苏涟水农商",
  "headquartersNo": "JSLIANSRCB"
}, {
  "headquartersName": "江苏涟水太商村镇银行",
  "headquartersShortName": "江苏涟水太商村镇银行",
  "headquartersNo": "LSTSCUNZB"
}, {
  "headquartersName": "江苏民丰农村商业银行",
  "headquartersShortName": "江苏民丰农商",
  "headquartersNo": "JSMFRCB"
}, {
  "headquartersName": "江苏南通农村商业银行",
  "headquartersShortName": "江苏南通农商",
  "headquartersNo": "JSNTRCB"
}, {
  "headquartersName": "江苏沛县汉源村镇银行",
  "headquartersShortName": "江苏沛县汉源村镇银行",
  "headquartersNo": "JSPXHYCUNZB"
}, {
  "headquartersName": "江苏沛县农村商业银行",
  "headquartersShortName": "江苏沛县农商",
  "headquartersNo": "JSPXRCB"
}, {
  "headquartersName": "江苏邳州陇海村镇银行",
  "headquartersShortName": "江苏邳州陇海村镇银行",
  "headquartersNo": "JSZZLHCUNZB"
}, {
  "headquartersName": "江苏邳州农村商业银行",
  "headquartersShortName": "江苏邳州农商",
  "headquartersNo": "JSPZRCB"
}, {
  "headquartersName": "江苏启东农村商业银行",
  "headquartersShortName": "江苏启东农商",
  "headquartersNo": "JSQDRCB"
}, {
  "headquartersName": "江苏启东珠江村镇银行",
  "headquartersShortName": "江苏启东珠江村镇银行",
  "headquartersNo": "JSQDZJCUNZB"
}, {
  "headquartersName": "江苏如东农村商业银行",
  "headquartersShortName": "江苏如东农商",
  "headquartersNo": "JSRDRCB"
}, {
  "headquartersName": "江苏如东融兴村镇银行",
  "headquartersShortName": "江苏如东融兴村镇银行",
  "headquartersNo": "JSRDRXCUNZB"
}, {
  "headquartersName": "江苏如皋农村商业银行",
  "headquartersShortName": "江苏如皋农商",
  "headquartersNo": "JSRGRCB"
}, {
  "headquartersName": "江苏射阳农村商业银行",
  "headquartersShortName": "江苏射阳农商",
  "headquartersNo": "JSSYRCB"
}, {
  "headquartersName": "江苏射阳太商村镇银行",
  "headquartersShortName": "江苏射阳太商村镇银行",
  "headquartersNo": "JSSYTSCUNZB"
}, {
  "headquartersName": "江苏省农村信用社联合社",
  "headquartersShortName": "江苏农信社",
  "headquartersNo": "JSNXS"
}, {
  "headquartersName": "江苏沭阳东吴村镇银行",
  "headquartersShortName": "江苏沭阳东吴村镇银行",
  "headquartersNo": "JSSYDWCUNZB"
}, {
  "headquartersName": "江苏沭阳农村商业银行",
  "headquartersShortName": "江苏沭阳农商",
  "headquartersNo": "JSSHUYRCB"
}, {
  "headquartersName": "江苏泗洪东吴村镇银行",
  "headquartersShortName": "江苏泗洪东吴村镇银行",
  "headquartersNo": "JSSHDWCUNZB"
}, {
  "headquartersName": "江苏泗洪农村商业银行",
  "headquartersShortName": "江苏泗洪农商",
  "headquartersNo": "JSSHRCB"
}, {
  "headquartersName": "江苏泗阳东吴村镇银行",
  "headquartersShortName": "江苏泗阳东吴村镇银行",
  "headquartersNo": "JSSIYDWCUNZB"
}, {
  "headquartersName": "江苏泗阳农村商业银行",
  "headquartersShortName": "江苏泗阳农商",
  "headquartersNo": "JSSIYRCB"
}, {
  "headquartersName": "江苏苏宁银行",
  "headquartersShortName": "江苏苏宁",
  "headquartersNo": "SUNINGBANK"
}, {
  "headquartersName": "江苏宿豫东吴村镇银行",
  "headquartersShortName": "江苏宿豫东吴村镇银行",
  "headquartersNo": "JSSUYDWCUNZB"
}, {
  "headquartersName": "江苏睢宁农村商业银行",
  "headquartersShortName": "江苏睢宁农商",
  "headquartersNo": "JSSNRCB"
}, {
  "headquartersName": "江苏泰兴农村商业银行",
  "headquartersShortName": "江苏泰兴农商",
  "headquartersNo": "JSTXRCB"
}, {
  "headquartersName": "江苏泰州农村商业银行",
  "headquartersShortName": "江苏泰州农商",
  "headquartersNo": "JSTZRCB"
}, {
  "headquartersName": "江苏通州华商村镇银行",
  "headquartersShortName": "江苏通州华商村镇银行",
  "headquartersNo": "JSTZHSCUNZB"
}, {
  "headquartersName": "江苏铜山锡州村镇银行",
  "headquartersShortName": "江苏铜山锡州村镇银行",
  "headquartersNo": "JSTSXZCUNZB"
}, {
  "headquartersName": "江苏响水农村商业银行",
  "headquartersShortName": "江苏响水农商",
  "headquartersNo": "JSXSRCB"
}, {
  "headquartersName": "江苏新沂汉源村镇银行",
  "headquartersShortName": "江苏新沂汉源村镇银行",
  "headquartersNo": "JSXYHYCUNZB"
}, {
  "headquartersName": "江苏新沂农村商业银行",
  "headquartersShortName": "江苏新沂农商",
  "headquartersNo": "JSXINYRCB"
}, {
  "headquartersName": "江苏兴化农村商业银行",
  "headquartersShortName": "江苏兴化农商",
  "headquartersNo": "JSXHRCB"
}, {
  "headquartersName": "江苏盱眙农村商业银行",
  "headquartersShortName": "江苏盱眙农商",
  "headquartersNo": "JSXYRCB"
}, {
  "headquartersName": "江苏盱眙珠江村镇银行",
  "headquartersShortName": "江苏盱眙珠江村镇银行",
  "headquartersNo": "XYZJCUNZB"
}, {
  "headquartersName": "江苏盐城农村商业银行",
  "headquartersShortName": "江苏盐城农商",
  "headquartersNo": "JSYCRCB"
}, {
  "headquartersName": "江苏扬中农村商业银行",
  "headquartersShortName": "江苏扬中农商",
  "headquartersNo": "JSYANGZRCB"
}, {
  "headquartersName": "江苏扬州农村商业银行",
  "headquartersShortName": "江苏扬州农商",
  "headquartersNo": "JISYZRCB"
}, {
  "headquartersName": "江苏仪征农村商业银行",
  "headquartersShortName": "江苏仪征农商",
  "headquartersNo": "JSYZRCB"
}, {
  "headquartersName": "江苏宜兴农村商业银行",
  "headquartersShortName": "江苏宜兴农商",
  "headquartersNo": "JSYXRCB"
}, {
  "headquartersName": "江苏银行",
  "headquartersShortName": "江苏",
  "headquartersNo": "JSBCHINA"
}, {
  "headquartersName": "江苏张家港农村商业银行",
  "headquartersShortName": "江苏张家港农商",
  "headquartersNo": "JSZJGRCB"
}, {
  "headquartersName": "江苏张家港渝农商村镇银行",
  "headquartersShortName": "江苏张家港渝农商村镇银行",
  "headquartersNo": "JSZJGYNSCUNZB"
}, {
  "headquartersName": "江苏长江商业银行",
  "headquartersShortName": "江苏长江",
  "headquartersNo": "JSCJCCB"
}, {
  "headquartersName": "江苏镇江农村商业银行",
  "headquartersShortName": "江苏镇江农商",
  "headquartersNo": "JSZJRCB"
}, {
  "headquartersName": "江苏紫金农村商业银行",
  "headquartersShortName": "江苏紫金农商",
  "headquartersNo": "JSZIJRCB"
}, {
  "headquartersName": "江西安福农村商业银行",
  "headquartersShortName": "江西安福农商",
  "headquartersNo": "JXAFRCB"
}, {
  "headquartersName": "江西安义农村商业银行",
  "headquartersShortName": "江西安义农商",
  "headquartersNo": "JXAYIRCB"
}, {
  "headquartersName": "江西安远农村商业银行",
  "headquartersShortName": "江西安远农商",
  "headquartersNo": "JXAYRCB"
}, {
  "headquartersName": "江西崇仁农村商业银行",
  "headquartersShortName": "江西崇仁农商",
  "headquartersNo": "JXCRRCB"
}, {
  "headquartersName": "江西大余农村商业银行",
  "headquartersShortName": "江西大余农商",
  "headquartersNo": "JXDYRCB"
}, {
  "headquartersName": "江西德兴农村商业银行",
  "headquartersShortName": "江西德兴农商",
  "headquartersNo": "JXDEXRCB"
}, {
  "headquartersName": "江西定南农村商业银行",
  "headquartersShortName": "江西定南农商",
  "headquartersNo": "JXDNRCB"
}, {
  "headquartersName": "江西东乡农村商业银行",
  "headquartersShortName": "江西东乡农商",
  "headquartersNo": "JXDXRCB"
}, {
  "headquartersName": "江西都昌农村商业银行",
  "headquartersShortName": "江西都昌农商",
  "headquartersNo": "JXDCRCB"
}, {
  "headquartersName": "江西丰城农村商业银行",
  "headquartersShortName": "江西丰城农商",
  "headquartersNo": "JXFCRCB"
}, {
  "headquartersName": "江西奉新农村商业银行",
  "headquartersShortName": "江西奉新农商",
  "headquartersNo": "JXFXRCB"
}, {
  "headquartersName": "江西赣昌农村商业银行",
  "headquartersShortName": "江西赣昌农商",
  "headquartersNo": "JXGANCRCB"
}, {
  "headquartersName": "江西赣县农村商业银行",
  "headquartersShortName": "江西赣县农商",
  "headquartersNo": "JXGXRCB"
}, {
  "headquartersName": "江西赣州银座村镇银行",
  "headquartersShortName": "江西赣州银座村镇银行",
  "headquartersNo": "GZYZCUNZB"
}, {
  "headquartersName": "江西高安富民村镇银行",
  "headquartersShortName": "江西高安富民村镇银行",
  "headquartersNo": "JXGAFMCUNZB"
}, {
  "headquartersName": "江西高安农村商业银行",
  "headquartersShortName": "江西高安农商",
  "headquartersNo": "JXGARCB"
}, {
  "headquartersName": "江西广昌农村商业银行",
  "headquartersShortName": "江西广昌农商",
  "headquartersNo": "JXGCRCB"
}, {
  "headquartersName": "江西广丰农村商业银行",
  "headquartersShortName": "江西广丰农商",
  "headquartersNo": "JXGFRCB"
}, {
  "headquartersName": "江西广信农村商业银行",
  "headquartersShortName": "江西广信农商",
  "headquartersNo": "JXGXINRCB"
}, {
  "headquartersName": "江西横峰农村商业银行",
  "headquartersShortName": "江西横峰农商",
  "headquartersNo": "JXHFRCB"
}, {
  "headquartersName": "江西湖口农村商业银行",
  "headquartersShortName": "江西湖口农商",
  "headquartersNo": "JXHKRCB"
}, {
  "headquartersName": "江西会昌农村商业银行",
  "headquartersShortName": "江西会昌农商",
  "headquartersNo": "JXHCRCB"
}, {
  "headquartersName": "江西吉水农村商业银行",
  "headquartersShortName": "江西吉水农商",
  "headquartersNo": "JXJSRCB"
}, {
  "headquartersName": "江西江州农村商业银行",
  "headquartersShortName": "江西江州农商",
  "headquartersNo": "JXJZRCB"
}, {
  "headquartersName": "江西金溪农村商业银行",
  "headquartersShortName": "江西金溪农商",
  "headquartersNo": "JXJXIRCB"
}, {
  "headquartersName": "江西进贤农村商业银行",
  "headquartersShortName": "江西进贤农商",
  "headquartersNo": "JXJXRCB"
}, {
  "headquartersName": "江西井冈山农村商业银行",
  "headquartersShortName": "江西井冈山农商",
  "headquartersNo": "JXJGSRCB"
}, {
  "headquartersName": "江西靖安农村商业银行",
  "headquartersShortName": "江西靖安农商",
  "headquartersNo": "JXJARCB"
}, {
  "headquartersName": "江西乐安农村商业银行",
  "headquartersShortName": "江西乐安农商",
  "headquartersNo": "JXLARCB"
}, {
  "headquartersName": "江西黎川农村商业银行",
  "headquartersShortName": "江西黎川农商",
  "headquartersNo": "JXLCRCB"
}, {
  "headquartersName": "江西莲花富民村镇银行",
  "headquartersShortName": "江西莲花富民村镇银行",
  "headquartersNo": "JXLHFMCUNZB"
}, {
  "headquartersName": "江西莲花农村商业银行",
  "headquartersShortName": "江西莲花农商",
  "headquartersNo": "JXLHRCB"
}, {
  "headquartersName": "江西龙南农村商业银行",
  "headquartersShortName": "江西龙南农商",
  "headquartersNo": "JXLNRCB"
}, {
  "headquartersName": "江西芦溪富民村镇银行",
  "headquartersShortName": "江西芦溪富民村镇银行",
  "headquartersNo": "JXLXFMCUNZB"
}, {
  "headquartersName": "江西芦溪农村商业银行",
  "headquartersShortName": "江西芦溪农商",
  "headquartersNo": "JXLXRCB"
}, {
  "headquartersName": "江西庐陵农村商业银行",
  "headquartersShortName": "江西庐陵农商",
  "headquartersNo": "JXLLRCB"
}, {
  "headquartersName": "江西庐山农村商业银行",
  "headquartersShortName": "江西庐山农商",
  "headquartersNo": "JXLSRCB"
}, {
  "headquartersName": "江西南城农村商业银行",
  "headquartersShortName": "江西南城农商",
  "headquartersNo": "JXNCRCB"
}, {
  "headquartersName": "江西南丰农村商业银行",
  "headquartersShortName": "江西南丰农商",
  "headquartersNo": "JXNFRCB"
}, {
  "headquartersName": "江西宁都农村商业银行",
  "headquartersShortName": "江西宁都农商",
  "headquartersNo": "JXNDRCB"
}, {
  "headquartersName": "江西彭泽农村商业银行",
  "headquartersShortName": "江西彭泽农商",
  "headquartersNo": "JXPZRCB"
}, {
  "headquartersName": "江西鄱阳农村商业银行",
  "headquartersShortName": "江西鄱阳农商",
  "headquartersNo": "JXPYRCB"
}, {
  "headquartersName": "江西铅山农村商业银行",
  "headquartersShortName": "江西铅山农商",
  "headquartersNo": "JXQSRCB"
}, {
  "headquartersName": "江西全南农村商业银行",
  "headquartersShortName": "江西全南农商",
  "headquartersNo": "JXQNRCB"
}, {
  "headquartersName": "江西瑞昌农村商业银行",
  "headquartersShortName": "江西瑞昌农商",
  "headquartersNo": "JXRCRCB"
}, {
  "headquartersName": "江西瑞金光大村镇银行",
  "headquartersShortName": "江西瑞金光大村镇银行",
  "headquartersNo": "JXRJGDCUNZB"
}, {
  "headquartersName": "江西瑞金农村商业银行",
  "headquartersShortName": "江西瑞金农商",
  "headquartersNo": "JXRJRCB"
}, {
  "headquartersName": "江西上高富民村镇银行",
  "headquartersShortName": "江西上高富民村镇银行",
  "headquartersNo": "JXSGFMCUNZB"
}, {
  "headquartersName": "江西上高农村商业银行",
  "headquartersShortName": "江西上高农商",
  "headquartersNo": "JXSGRCB"
}, {
  "headquartersName": "江西上栗富民村镇银行",
  "headquartersShortName": "江西上栗富民村镇银行",
  "headquartersNo": "JXSLFMCUNZB"
}, {
  "headquartersName": "江西上栗农村商业银行",
  "headquartersShortName": "江西上栗农商",
  "headquartersNo": "JXSLRCB"
}, {
  "headquartersName": "江西上犹农村商业银行",
  "headquartersShortName": "江西上犹农商",
  "headquartersNo": "JXSYRCB"
}, {
  "headquartersName": "江西省农村信用社联合社",
  "headquartersShortName": "江西农信社",
  "headquartersNo": "JXNXS"
}, {
  "headquartersName": "江西石城农村商业银行",
  "headquartersShortName": "江西石城农商",
  "headquartersNo": "JXSCRCB"
}, {
  "headquartersName": "江西遂川农村商业银行",
  "headquartersShortName": "江西遂川农商",
  "headquartersNo": "JXSUICRCB"
}, {
  "headquartersName": "江西泰和农村商业银行",
  "headquartersShortName": "江西泰和农商",
  "headquartersNo": "JXTHRCB"
}, {
  "headquartersName": "江西铜鼓农村商业银行",
  "headquartersShortName": "江西铜鼓农商",
  "headquartersNo": "JXTGRCB"
}, {
  "headquartersName": "江西湾里农村商业银行",
  "headquartersShortName": "江西湾里农商",
  "headquartersNo": "JXWLRCB"
}, {
  "headquartersName": "江西万安农村商业银行",
  "headquartersShortName": "江西万安农商",
  "headquartersNo": "JXWARCB"
}, {
  "headquartersName": "江西万年农村商业银行",
  "headquartersShortName": "江西万年农商",
  "headquartersNo": "JXWNRCB"
}, {
  "headquartersName": "江西万载富民村镇银行",
  "headquartersShortName": "江西万载富民村镇银行",
  "headquartersNo": "JXWZFMCUNZB"
}, {
  "headquartersName": "江西万载农村商业银行",
  "headquartersShortName": "江西万载农商",
  "headquartersNo": "JXWZRCB"
}, {
  "headquartersName": "江西武宁农村商业银行",
  "headquartersShortName": "江西武宁农商",
  "headquartersNo": "JXWUNRCB"
}, {
  "headquartersName": "江西婺源江淮村镇银行",
  "headquartersShortName": "江西婺源江淮村镇银行",
  "headquartersNo": "JXZYJHCUNZB"
}, {
  "headquartersName": "江西婺源农村商业银行",
  "headquartersShortName": "江西婺源农商",
  "headquartersNo": "JXWYRCB"
}, {
  "headquartersName": "江西峡江农村商业银行",
  "headquartersShortName": "江西峡江农商",
  "headquartersNo": "JXXJRCB"
}, {
  "headquartersName": "江西新干农村商业银行",
  "headquartersShortName": "江西新干农商",
  "headquartersNo": "JXXGANRCB"
}, {
  "headquartersName": "江西新建农村商业银行",
  "headquartersShortName": "江西新建农商",
  "headquartersNo": "JXXINJRCB"
}, {
  "headquartersName": "江西信丰农村商业银行",
  "headquartersShortName": "江西信丰农商",
  "headquartersNo": "JXXFRCB"
}, {
  "headquartersName": "江西信州江淮村镇银行",
  "headquartersShortName": "江西信州江淮村镇银行",
  "headquartersNo": "JXXZJHCUNZB"
}, {
  "headquartersName": "江西兴国农村商业银行",
  "headquartersShortName": "江西兴国农商",
  "headquartersNo": "JXXGRCB"
}, {
  "headquartersName": "江西兴国新华村镇银行",
  "headquartersShortName": "江西兴国新华村镇银行",
  "headquartersNo": "JXXGXHCUNZB"
}, {
  "headquartersName": "江西修水农村商业银行",
  "headquartersShortName": "江西修水农商",
  "headquartersNo": "JXXSRCB"
}, {
  "headquartersName": "江西寻乌农村商业银行",
  "headquartersShortName": "江西寻乌农商",
  "headquartersNo": "JXXWRCB"
}, {
  "headquartersName": "江西宜丰农村商业银行",
  "headquartersShortName": "江西宜丰农商",
  "headquartersNo": "JXYIFRCB"
}, {
  "headquartersName": "江西宜黄农村商业银行",
  "headquartersShortName": "江西宜黄农商",
  "headquartersNo": "JXYHRCB"
}, {
  "headquartersName": "江西弋阳农村商业银行",
  "headquartersShortName": "江西弋阳农商",
  "headquartersNo": "JXYYRCB"
}, {
  "headquartersName": "江西银行(南昌银行)",
  "headquartersShortName": "江西",
  "headquartersNo": "NCYH"
}, {
  "headquartersName": "江西永丰农村商业银行",
  "headquartersShortName": "江西永丰农商",
  "headquartersNo": "JXYFRCB"
}, {
  "headquartersName": "江西永新农村商业银行",
  "headquartersShortName": "江西永新农商",
  "headquartersNo": "JXYXRCB"
}, {
  "headquartersName": "江西永修农村商业银行",
  "headquartersShortName": "江西永修农商",
  "headquartersNo": "JXYXIURCB"
}, {
  "headquartersName": "江西于都农村商业银行",
  "headquartersShortName": "江西于都农商",
  "headquartersNo": "JXYDRCB"
}, {
  "headquartersName": "江西余干农村商业银行",
  "headquartersShortName": "江西余干农商",
  "headquartersNo": "JXYGRCB"
}, {
  "headquartersName": "江西玉山农村商业银行",
  "headquartersShortName": "江西玉山农商",
  "headquartersNo": "JXYSRCB"
}, {
  "headquartersName": "江西裕民银行",
  "headquartersShortName": "江西裕民",
  "headquartersNo": "YMBANK"
}, {
  "headquartersName": "江西樟树农村商业银行",
  "headquartersShortName": "江西樟树农商",
  "headquartersNo": "JXZSRCB"
}, {
  "headquartersName": "江西资溪农村商业银行",
  "headquartersShortName": "江西资溪农商",
  "headquartersNo": "JXZXRCB"
}, {
  "headquartersName": "江阴浦发村镇银行",
  "headquartersShortName": "江阴浦发村镇银行",
  "headquartersNo": "JYPFCUNZB"
}, {
  "headquartersName": "交城县太行村镇银行",
  "headquartersShortName": "交城县太行村镇银行",
  "headquartersNo": "JCXTXCUNZB"
}, {
  "headquartersName": "交口县融都村镇银行",
  "headquartersShortName": "交口县融都村镇银行",
  "headquartersNo": "JKXRDCUNZB"
}, {
  "headquartersName": "交通银行",
  "headquartersShortName": "交通",
  "headquartersNo": "BOCO"
}, {
  "headquartersName": "蛟河吉银村镇银行",
  "headquartersShortName": "蛟河吉银村镇银行",
  "headquartersNo": "ZHJYCUNZB"
}, {
  "headquartersName": "焦作解放农村商业银行",
  "headquartersShortName": "焦作解放农商",
  "headquartersNo": "JZJFRCB"
}, {
  "headquartersName": "焦作中旅银行",
  "headquartersShortName": "焦作中旅",
  "headquartersNo": "JZSCCB"
}, {
  "headquartersName": "介休市华都村镇银行",
  "headquartersShortName": "介休市华都村镇银行",
  "headquartersNo": "JXSHDCUNZB"
}, {
  "headquartersName": "金昌农村商业银行",
  "headquartersShortName": "金昌农商",
  "headquartersNo": "JINCRCB"
}, {
  "headquartersName": "金华银行",
  "headquartersShortName": "金华",
  "headquartersNo": "JHYH"
}, {
  "headquartersName": "金沙富民村镇银行",
  "headquartersShortName": "金沙富民村镇银行",
  "headquartersNo": "JSFMCUNZB"
}, {
  "headquartersName": "金堂汇金村镇银行",
  "headquartersShortName": "金堂汇金村镇银行",
  "headquartersNo": "JTHJCUNZB"
}, {
  "headquartersName": "金溪抚商村镇银行",
  "headquartersShortName": "金溪抚商村镇银行",
  "headquartersNo": "JXFSCUNZB"
}, {
  "headquartersName": "金寨徽银村镇银行",
  "headquartersShortName": "金寨徽银村镇银行",
  "headquartersNo": "JZHYCUNZB"
}, {
  "headquartersName": "锦州农村商业银行",
  "headquartersShortName": "锦州农商",
  "headquartersNo": "JZRCB"
}, {
  "headquartersName": "锦州松山农商村镇银行",
  "headquartersShortName": "锦州松山农商村镇银行",
  "headquartersNo": "JZSSNSCUNZB"
}, {
  "headquartersName": "锦州太和锦银村镇银行",
  "headquartersShortName": "锦州太和锦银村镇银行",
  "headquartersNo": "JZTHJYCUNZB"
}, {
  "headquartersName": "锦州银行",
  "headquartersShortName": "锦州",
  "headquartersNo": "JINZYH"
}, {
  "headquartersName": "进贤瑞丰村镇银行",
  "headquartersShortName": "进贤瑞丰村镇银行",
  "headquartersNo": "JXRFCUNZB"
}, {
  "headquartersName": "晋城农村商业银行",
  "headquartersShortName": "晋城农商",
  "headquartersNo": "JCRCB"
}, {
  "headquartersName": "晋城银行",
  "headquartersShortName": "晋城",
  "headquartersNo": "JCYH"
}, {
  "headquartersName": "晋商银行",
  "headquartersShortName": "晋商",
  "headquartersNo": "JSYH"
}, {
  "headquartersName": "晋中开发区农村商业银行",
  "headquartersShortName": "晋中开发区农商",
  "headquartersNo": "JZKFQRCB"
}, {
  "headquartersName": "晋中市榆次融信村镇银行",
  "headquartersShortName": "晋中市榆次融信村镇银行",
  "headquartersNo": "JZSYCRXCUNZB"
}, {
  "headquartersName": "晋中市左权华丰村镇银行",
  "headquartersShortName": "晋中市左权华丰村镇银行",
  "headquartersNo": "JZSZQHFCUNZB"
}, {
  "headquartersName": "晋中银行",
  "headquartersShortName": "晋中",
  "headquartersNo": "JZYH"
}, {
  "headquartersName": "晋州恒升村镇银行",
  "headquartersShortName": "晋州恒升村镇银行",
  "headquartersNo": "JZHSCUNZB"
}, {
  "headquartersName": "荆门东宝惠民村镇银行",
  "headquartersShortName": "荆门东宝惠民村镇银行",
  "headquartersNo": "JMDBHMCUNZB"
}, {
  "headquartersName": "旌德民商村镇银行",
  "headquartersShortName": "旌德民商村镇银行",
  "headquartersNo": "SDMSCUNZB"
}, {
  "headquartersName": "井冈山九银村镇银行",
  "headquartersShortName": "井冈山九银村镇银行",
  "headquartersNo": "JGSJYCUNZB"
}, {
  "headquartersName": "景德镇昌江九银村镇银行",
  "headquartersShortName": "景德镇昌江九银村镇银行",
  "headquartersNo": "JDZCJJYCUNZB"
}, {
  "headquartersName": "景德镇农村商业银行",
  "headquartersShortName": "景德镇农商",
  "headquartersNo": "JDZRCB"
}, {
  "headquartersName": "景洪民生村镇银行",
  "headquartersShortName": "景洪民生村镇银行",
  "headquartersNo": "JHMSCUNZB"
}, {
  "headquartersName": "景县丰源村镇银行",
  "headquartersShortName": "景县丰源村镇银行",
  "headquartersNo": "JXFYCUNZB"
}, {
  "headquartersName": "靖安九银村镇银行",
  "headquartersShortName": "靖安九银村镇银行",
  "headquartersNo": "JAJYCUNZB"
}, {
  "headquartersName": "靖宇乾丰村镇银行",
  "headquartersShortName": "靖宇乾丰村镇银行",
  "headquartersNo": "JYQFCUNZB"
}, {
  "headquartersName": "九江共青村镇银行",
  "headquartersShortName": "九江共青村镇银行",
  "headquartersNo": "JJGQCUNZB"
}, {
  "headquartersName": "九江恒通村镇银行",
  "headquartersShortName": "九江恒通村镇银行",
  "headquartersNo": "JJHTCUNZB"
}, {
  "headquartersName": "九江庐山浔银村镇银行",
  "headquartersShortName": "九江庐山浔银村镇银行",
  "headquartersNo": "JJLSZYCUNZB"
}, {
  "headquartersName": "九江农村商业银行",
  "headquartersShortName": "九江农商",
  "headquartersNo": "JJRCB"
}, {
  "headquartersName": "九江银行",
  "headquartersShortName": "九江",
  "headquartersNo": "JJYH"
}, {
  "headquartersName": "九台龙嘉村镇银行",
  "headquartersShortName": "九台龙嘉村镇银行",
  "headquartersNo": "JTLJCUNZB"
}, {
  "headquartersName": "酒泉农村商业银行",
  "headquartersShortName": "酒泉农商",
  "headquartersNo": "JQRCB"
}, {
  "headquartersName": "莒县金谷村镇银行",
  "headquartersShortName": "莒县金谷村镇银行",
  "headquartersNo": "JXJGCUNZB"
}, {
  "headquartersName": "巨鹿融信村镇银行",
  "headquartersShortName": "巨鹿融信村镇银行",
  "headquartersNo": "JLRXCUNZB"
}, {
  "headquartersName": "句容苏南村镇银行",
  "headquartersShortName": "句容苏南村镇银行",
  "headquartersNo": "JRSNCUNZB"
}, {
  "headquartersName": "鄄城牡丹村镇银行",
  "headquartersShortName": "鄄城牡丹村镇银行",
  "headquartersNo": "YCBSCUNZB"
}, {
  "headquartersName": "浚县郑银村镇银行",
  "headquartersShortName": "浚县郑银村镇银行",
  "headquartersNo": "JXZYCUNZB"
}, {
  "headquartersName": "喀喇沁玉龙村镇银行",
  "headquartersShortName": "喀喇沁玉龙村镇银行",
  "headquartersNo": "KLQYLCUNZB"
}, {
  "headquartersName": "开封宋都农村商业银行",
  "headquartersShortName": "开封宋都农商",
  "headquartersNo": "KFSDRCB"
}, {
  "headquartersName": "开封新东方村镇银行",
  "headquartersShortName": "开封新东方村镇银行",
  "headquartersNo": "KFXDFCUNZB"
}, {
  "headquartersName": "开鲁蒙银村镇银行",
  "headquartersShortName": "开鲁蒙银村镇银行",
  "headquartersNo": "KLMYCUNZB"
}, {
  "headquartersName": "开泰银行",
  "headquartersShortName": "开泰",
  "headquartersNo": "KASIKORNBANK"
}, {
  "headquartersName": "开阳富民村镇银行",
  "headquartersShortName": "开阳富民村镇银行",
  "headquartersNo": "KYFMCUNZB"
}, {
  "headquartersName": "开原象牙山村镇银行",
  "headquartersShortName": "开原象牙山村镇银行",
  "headquartersNo": "KYXYSCUNZB"
}, {
  "headquartersName": "开远沪农商村镇银行",
  "headquartersShortName": "开远沪农商村镇银行",
  "headquartersNo": "KYHNSCUNZB"
}, {
  "headquartersName": "凯里东南村镇银行",
  "headquartersShortName": "凯里东南村镇银行",
  "headquartersNo": "KLDNCUNZB"
}, {
  "headquartersName": "康保银丰村镇银行",
  "headquartersShortName": "康保银丰村镇银行",
  "headquartersNo": "KBYFCUNZB"
}, {
  "headquartersName": "克拉玛依金龙国民村镇银行",
  "headquartersShortName": "克拉玛依金龙国民村镇银行",
  "headquartersNo": "KLMYJLGMCUNZB"
}, {
  "headquartersName": "克山润生村镇银行",
  "headquartersShortName": "克山润生村镇银行",
  "headquartersNo": "KSRSCUNZB"
}, {
  "headquartersName": "垦利乐安村镇银行",
  "headquartersShortName": "垦利乐安村镇银行",
  "headquartersNo": "KLLACUNZB"
}, {
  "headquartersName": "库车国民村镇银行",
  "headquartersShortName": "库车国民村镇银行",
  "headquartersNo": "KCGMCUNZB"
}, {
  "headquartersName": "库尔勒市商业银行",
  "headquartersShortName": "库尔勒",
  "headquartersNo": "KELSCCB"
}, {
  "headquartersName": "宽城融兴村镇银行",
  "headquartersShortName": "宽城融兴村镇银行",
  "headquartersNo": "KCRXCUNZB"
}, {
  "headquartersName": "宽甸百丰村镇银行",
  "headquartersShortName": "宽甸百丰村镇银行",
  "headquartersNo": "KDBFCUNZB"
}, {
  "headquartersName": "奎屯国民村镇银行",
  "headquartersShortName": "奎屯国民村镇银行",
  "headquartersNo": "KTGMCUNZB"
}, {
  "headquartersName": "昆仑银行",
  "headquartersShortName": "昆仑",
  "headquartersNo": "KLYH"
}, {
  "headquartersName": "昆明呈贡华夏村镇银行",
  "headquartersShortName": "昆明呈贡华夏村镇银行",
  "headquartersNo": "KMCGHXCUNZB"
}, {
  "headquartersName": "昆明东川中成村镇银行",
  "headquartersShortName": "昆明东川中成村镇银行",
  "headquartersNo": "KMDCZCCUNZB"
}, {
  "headquartersName": "昆明官渡沪农商村镇银行",
  "headquartersShortName": "昆明官渡沪农商村镇银行",
  "headquartersNo": "KMALHNSCUNZB"
}, {
  "headquartersName": "昆明官渡农村合作银行",
  "headquartersShortName": "昆明官渡农村合作银行",
  "headquartersNo": "KMGDRCU"
}, {
  "headquartersName": "昆明晋宁融丰村镇银行",
  "headquartersShortName": "昆明晋宁融丰村镇银行",
  "headquartersNo": "KMJNRFCUNZB"
}, {
  "headquartersName": "昆明禄劝中成村镇银行",
  "headquartersShortName": "昆明禄劝中成村镇银行",
  "headquartersNo": "KMLQZCCUNZB"
}, {
  "headquartersName": "昆明马金铺中成村镇银行",
  "headquartersShortName": "昆明马金铺中成村镇银行",
  "headquartersNo": "KMMJPZCCUNZB"
}, {
  "headquartersName": "昆明石林中成村镇银行",
  "headquartersShortName": "昆明石林中成村镇银行",
  "headquartersNo": "KMSLZCCUNZB"
}, {
  "headquartersName": "昆明五华长江村镇银行",
  "headquartersShortName": "昆明五华长江村镇银行",
  "headquartersNo": "KMWHCJCUNZB"
}, {
  "headquartersName": "昆明宜良融丰村镇银行",
  "headquartersShortName": "昆明宜良融丰村镇银行",
  "headquartersNo": "KMYLRFCUNZB"
}, {
  "headquartersName": "昆山鹿城村镇银行",
  "headquartersShortName": "昆山鹿城村镇银行",
  "headquartersNo": "KSLCCUNZB"
}, {
  "headquartersName": "来宾象州长江村镇银行",
  "headquartersShortName": "来宾象州长江村镇银行",
  "headquartersNo": "LBXZCJCUNZB"
}, {
  "headquartersName": "莱商银行",
  "headquartersShortName": "莱商",
  "headquartersNo": "ISBC"
}, {
  "headquartersName": "莱芜中成村镇银行",
  "headquartersShortName": "莱芜中成村镇银行",
  "headquartersNo": "LWZCCUNZB"
}, {
  "headquartersName": "莱芜珠江村镇银行",
  "headquartersShortName": "莱芜珠江村镇银行",
  "headquartersNo": "LWZJCUNZB"
}, {
  "headquartersName": "莱阳胶东村镇银行",
  "headquartersShortName": "莱阳胶东村镇银行",
  "headquartersNo": "LYJDCUNZB"
}, {
  "headquartersName": "莱州珠江村镇银行",
  "headquartersShortName": "莱州珠江村镇银行",
  "headquartersNo": "LZZJCUNZB"
}, {
  "headquartersName": "涞水利丰村镇银行",
  "headquartersShortName": "涞水利丰村镇银行",
  "headquartersNo": "ZSLFCUNZB"
}, {
  "headquartersName": "兰考齐鲁村镇银行",
  "headquartersShortName": "兰考齐鲁村镇银行",
  "headquartersNo": "LKQLCUNZB"
}, {
  "headquartersName": "兰西农商村镇银行",
  "headquartersShortName": "兰西农商村镇银行",
  "headquartersNo": "LXNSCUNZB"
}, {
  "headquartersName": "兰州安宁神舟村镇银行",
  "headquartersShortName": "兰州安宁神舟村镇银行",
  "headquartersNo": "LZANSZCUNZB"
}, {
  "headquartersName": "兰州皋兰新华村镇银行",
  "headquartersShortName": "兰州皋兰新华村镇银行",
  "headquartersNo": "LZGLXHCUNZB"
}, {
  "headquartersName": "兰州农村商业银行",
  "headquartersShortName": "兰州农商",
  "headquartersNo": "LZRCB"
}, {
  "headquartersName": "兰州七里河新华村镇银行",
  "headquartersShortName": "兰州七里河新华村镇银行",
  "headquartersNo": "LZQLHXHCUNZB"
}, {
  "headquartersName": "兰州银行",
  "headquartersShortName": "兰州",
  "headquartersNo": "LZYH"
}, {
  "headquartersName": "兰州永登新华村镇银行",
  "headquartersShortName": "兰州永登新华村镇银行",
  "headquartersNo": "LZYDXHCUNZB"
}, {
  "headquartersName": "岚县慧融村镇银行",
  "headquartersShortName": "岚县慧融村镇银行",
  "headquartersNo": "ZXHRCUNZB"
}, {
  "headquartersName": "阆中融兴村镇银行",
  "headquartersShortName": "阆中融兴村镇银行",
  "headquartersNo": "ZZRXCUNZB"
}, {
  "headquartersName": "廊坊开发区融商村镇银行",
  "headquartersShortName": "廊坊开发区融商村镇银行",
  "headquartersNo": "LFKFQRSCUNZB"
}, {
  "headquartersName": "廊坊市安次区惠民村镇银行",
  "headquartersShortName": "廊坊市安次区惠民村镇银行",
  "headquartersNo": "LFSACQHMCUNZB"
}, {
  "headquartersName": "廊坊市广阳舜丰村镇银行",
  "headquartersShortName": "廊坊市广阳舜丰村镇银行",
  "headquartersNo": "LFSGYSFCUNZB"
}, {
  "headquartersName": "廊坊银行",
  "headquartersShortName": "廊坊",
  "headquartersNo": "LFYH"
}, {
  "headquartersName": "乐安洪都村镇银行",
  "headquartersShortName": "乐安洪都村镇银行",
  "headquartersNo": "LAHDCUNZB"
}, {
  "headquartersName": "乐东惠丰村镇银行",
  "headquartersShortName": "乐东惠丰村镇银行",
  "headquartersNo": "LDHFCUNZB"
}, {
  "headquartersName": "乐陵圆融村镇银行",
  "headquartersShortName": "乐陵圆融村镇银行",
  "headquartersNo": "LLYRCUNZB"
}, {
  "headquartersName": "乐平融兴村镇银行",
  "headquartersShortName": "乐平融兴村镇银行",
  "headquartersNo": "LPRXCUNZB"
}, {
  "headquartersName": "乐山嘉州民富村镇银行",
  "headquartersShortName": "乐山嘉州民富村镇银行",
  "headquartersNo": "LSJZMFCUNZB"
}, {
  "headquartersName": "乐山昆仑村镇银行",
  "headquartersShortName": "乐山昆仑村镇银行",
  "headquartersNo": "LSKLCUNZB"
}, {
  "headquartersName": "乐山三江农村商业银行",
  "headquartersShortName": "乐山三江农商",
  "headquartersNo": "LSSJRCB"
}, {
  "headquartersName": "乐山市商业银行",
  "headquartersShortName": "乐山",
  "headquartersNo": "LSSCCB"
}, {
  "headquartersName": "乐亭舜丰村镇银行",
  "headquartersShortName": "乐亭舜丰村镇银行",
  "headquartersNo": "LTSFCUNZB"
}, {
  "headquartersName": "雷州惠民村镇银行",
  "headquartersShortName": "雷州惠民村镇银行",
  "headquartersNo": "LZHMCUNZB"
}, {
  "headquartersName": "耒阳融兴村镇银行",
  "headquartersShortName": "耒阳融兴村镇银行",
  "headquartersNo": "LYRXCUNZB"
}, {
  "headquartersName": "梨树源泰村镇银行",
  "headquartersShortName": "梨树源泰村镇银行",
  "headquartersNo": "LSYTCUNZB"
}, {
  "headquartersName": "黎川抚商村镇银行",
  "headquartersShortName": "黎川抚商村镇银行",
  "headquartersNo": "LCFSCUNZB"
}, {
  "headquartersName": "澧县沪农商村镇银行",
  "headquartersShortName": "澧县沪农商村镇银行",
  "headquartersNo": "ZXHNSCUNZB"
}, {
  "headquartersName": "醴陵沪农商村镇银行",
  "headquartersShortName": "醴陵沪农商村镇银行",
  "headquartersNo": "ZLHNSCUNZB"
}, {
  "headquartersName": "丽江古城富滇村镇银行",
  "headquartersShortName": "丽江古城富滇村镇银行",
  "headquartersNo": "LJGCFDCUNZB"
}, {
  "headquartersName": "丽江古城农村商业银行",
  "headquartersShortName": "丽江古城农商",
  "headquartersNo": "LJGCRCB"
}, {
  "headquartersName": "丽江永胜长江村镇银行",
  "headquartersShortName": "丽江永胜长江村镇银行",
  "headquartersNo": "LJYSCJCUNZB"
}, {
  "headquartersName": "丽江玉龙长江村镇银行",
  "headquartersShortName": "丽江玉龙长江村镇银行",
  "headquartersNo": "LJYLCJCUNZB"
}, {
  "headquartersName": "荔波富民村镇银行",
  "headquartersShortName": "荔波富民村镇银行",
  "headquartersNo": "LBFMCUNZB"
}, {
  "headquartersName": "溧阳浦发村镇银行",
  "headquartersShortName": "溧阳浦发村镇银行",
  "headquartersNo": "ZYPFCUNZB"
}, {
  "headquartersName": "连云港东方农村商业银行",
  "headquartersShortName": "连云港东方农商",
  "headquartersNo": "LYGDFRCB"
}, {
  "headquartersName": "涟源沪农商村镇银行",
  "headquartersShortName": "涟源沪农商村镇银行",
  "headquartersNo": "LYHNSCUNZB"
}, {
  "headquartersName": "凉城县乌拉特村镇银行",
  "headquartersShortName": "凉城县乌拉特村镇银行",
  "headquartersNo": "LCXWLTCUNZB"
}, {
  "headquartersName": "凉山农村商业银行",
  "headquartersShortName": "凉山农商",
  "headquartersNo": "LSRCB"
}, {
  "headquartersName": "凉山州商业银行",
  "headquartersShortName": "凉山",
  "headquartersNo": "LSZCCB"
}, {
  "headquartersName": "辽宁北镇锦银村镇银行",
  "headquartersShortName": "辽宁北镇锦银村镇银行",
  "headquartersNo": "LNBZJYCUNZB"
}, {
  "headquartersName": "辽宁本溪南芬农村商业银行",
  "headquartersShortName": "辽宁本溪南芬农商",
  "headquartersNo": "LNBXNFRCB"
}, {
  "headquartersName": "辽宁辰州汇通村镇银行",
  "headquartersShortName": "辽宁辰州汇通村镇银行",
  "headquartersNo": "LNCZHTCUNZB"
}, {
  "headquartersName": "辽宁大石桥隆丰村镇银行",
  "headquartersShortName": "辽宁大石桥隆丰村镇银行",
  "headquartersNo": "LNDSQLFCUNZB"
}, {
  "headquartersName": "辽宁大石桥农村商业银行",
  "headquartersShortName": "辽宁大石桥农商",
  "headquartersNo": "LNDSQRCB"
}, {
  "headquartersName": "辽宁大洼农村商业银行",
  "headquartersShortName": "辽宁大洼农商",
  "headquartersNo": "LNDWRCB"
}, {
  "headquartersName": "辽宁灯塔村镇银行",
  "headquartersShortName": "辽宁灯塔村镇银行",
  "headquartersNo": "LNDTCUNZB"
}, {
  "headquartersName": "辽宁灯塔农村商业银行",
  "headquartersShortName": "辽宁灯塔农商",
  "headquartersNo": "LNDTRCB"
}, {
  "headquartersName": "辽宁东港农村商业银行",
  "headquartersShortName": "辽宁东港农商",
  "headquartersNo": "LNDGRCB"
}, {
  "headquartersName": "辽宁凤城农村商业银行",
  "headquartersShortName": "辽宁凤城农商",
  "headquartersNo": "LNFCRCB"
}, {
  "headquartersName": "辽宁盖州农村商业银行",
  "headquartersShortName": "辽宁盖州农商",
  "headquartersNo": "LNGZRCB"
}, {
  "headquartersName": "辽宁海城金海村镇银行",
  "headquartersShortName": "辽宁海城金海村镇银行",
  "headquartersNo": "LNHCJHCUNZB"
}, {
  "headquartersName": "辽宁海城农村商业银行",
  "headquartersShortName": "辽宁海城农商",
  "headquartersNo": "LNHCRCB"
}, {
  "headquartersName": "辽宁黑山锦银村镇银行",
  "headquartersShortName": "辽宁黑山锦银村镇银行",
  "headquartersNo": "LNHSJYCUNZB"
}, {
  "headquartersName": "辽宁桓仁锦银村镇银行",
  "headquartersShortName": "辽宁桓仁锦银村镇银行",
  "headquartersNo": "LNHRJYCUNZB"
}, {
  "headquartersName": "辽宁桓仁农村商业银行",
  "headquartersShortName": "辽宁桓仁农商",
  "headquartersNo": "LNHRRCB"
}, {
  "headquartersName": "辽宁建平农村商业银行",
  "headquartersShortName": "辽宁建平农商",
  "headquartersNo": "LNJPRCB"
}, {
  "headquartersName": "辽宁喀左锦银村镇银行",
  "headquartersShortName": "辽宁喀左锦银村镇银行",
  "headquartersNo": "LNKZJYCUNZB"
}, {
  "headquartersName": "辽宁宽甸农村商业银行",
  "headquartersShortName": "辽宁宽甸农商",
  "headquartersNo": "LNKDRCB"
}, {
  "headquartersName": "辽宁凌海锦银村镇银行",
  "headquartersShortName": "辽宁凌海锦银村镇银行",
  "headquartersNo": "LNLHJYCUNZB"
}, {
  "headquartersName": "辽宁千山金泉村镇银行",
  "headquartersShortName": "辽宁千山金泉村镇银行",
  "headquartersNo": "LNQSJQCUNZB"
}, {
  "headquartersName": "辽宁清原农村商业银行",
  "headquartersShortName": "辽宁清原农商",
  "headquartersNo": "LNQYRCB"
}, {
  "headquartersName": "辽宁沈本农村商业银行",
  "headquartersShortName": "辽宁沈本农商",
  "headquartersNo": "LNSBRCB"
}, {
  "headquartersName": "辽宁沈东村镇银行",
  "headquartersShortName": "辽宁沈东村镇银行",
  "headquartersNo": "LNSDCUNZB"
}, {
  "headquartersName": "辽宁沈抚农村商业银行",
  "headquartersShortName": "辽宁沈抚农商",
  "headquartersNo": "LNSFRCB"
}, {
  "headquartersName": "辽宁省农村信用社联合社",
  "headquartersShortName": "辽宁农信社",
  "headquartersNo": "LNNXS"
}, {
  "headquartersName": "辽宁首山村镇银行",
  "headquartersShortName": "辽宁首山村镇银行",
  "headquartersNo": "LNSSCUNZB"
}, {
  "headquartersName": "辽宁台安金安村镇银行",
  "headquartersShortName": "辽宁台安金安村镇银行",
  "headquartersNo": "LNTAJACUNZB"
}, {
  "headquartersName": "辽宁太子河村镇银行",
  "headquartersShortName": "辽宁太子河村镇银行",
  "headquartersNo": "LNTZHCUNZB"
}, {
  "headquartersName": "辽宁新宾农村商业银行",
  "headquartersShortName": "辽宁新宾农商",
  "headquartersNo": "LNXBRCB"
}, {
  "headquartersName": "辽宁新民农村商业银行",
  "headquartersShortName": "辽宁新民农商",
  "headquartersNo": "LNXMRCB"
}, {
  "headquartersName": "辽宁兴城农村商业银行",
  "headquartersShortName": "辽宁兴城农商",
  "headquartersNo": "LNXCRCB"
}, {
  "headquartersName": "辽宁岫岩金玉村镇银行",
  "headquartersShortName": "辽宁岫岩金玉村镇银行",
  "headquartersNo": "LNZYJYCUNZB"
}, {
  "headquartersName": "辽宁岫岩农村商业银行",
  "headquartersShortName": "辽宁岫岩农商",
  "headquartersNo": "LNXYRCB"
}, {
  "headquartersName": "辽宁义县锦银村镇银行",
  "headquartersShortName": "辽宁义县锦银村镇银行",
  "headquartersNo": "LNYXJYCUNZB"
}, {
  "headquartersName": "辽宁彰武金通村镇银行",
  "headquartersShortName": "辽宁彰武金通村镇银行",
  "headquartersNo": "LNZWJTCUNZB"
}, {
  "headquartersName": "辽宁振兴银行",
  "headquartersShortName": "辽宁振兴",
  "headquartersNo": "LNZXYH"
}, {
  "headquartersName": "辽沈银行",
  "headquartersShortName": "辽沈",
  "headquartersNo": "LSBANK"
}, {
  "headquartersName": "辽阳辽东农村商业银行",
  "headquartersShortName": "辽阳辽东农商",
  "headquartersNo": "LYLDRCB"
}, {
  "headquartersName": "辽阳农村商业银行",
  "headquartersShortName": "辽阳农商",
  "headquartersNo": "LYANGRCB"
}, {
  "headquartersName": "辽阳银行",
  "headquartersShortName": "辽阳",
  "headquartersNo": "LYYH"
}, {
  "headquartersName": "辽源龙山榆银村镇银行",
  "headquartersShortName": "辽源龙山榆银村镇银行",
  "headquartersNo": "LYLSYYCUNZB"
}, {
  "headquartersName": "辽源农村商业银行",
  "headquartersShortName": "辽源农商",
  "headquartersNo": "LYRCB"
}, {
  "headquartersName": "辽源西安区榆银村镇银行",
  "headquartersShortName": "辽源西安区榆银村镇银行",
  "headquartersNo": "LYXAQYYCUNZB"
}, {
  "headquartersName": "聊城沪农商村镇银行",
  "headquartersShortName": "聊城沪农商村镇银行",
  "headquartersNo": "LCHNSCUNZB"
}, {
  "headquartersName": "聊城农村商业银行",
  "headquartersShortName": "聊城农商",
  "headquartersNo": "LCRCB"
}, {
  "headquartersName": "林芝民生村镇银行",
  "headquartersShortName": "林芝民生村镇银行",
  "headquartersNo": "LZMSCUNZB"
}, {
  "headquartersName": "林州中原村镇银行",
  "headquartersShortName": "林州中原村镇银行",
  "headquartersNo": "LZZYCUNZB"
}, {
  "headquartersName": "临沧临翔沪农商村镇银行",
  "headquartersShortName": "临沧临翔沪农商村镇银行",
  "headquartersNo": "LCLXHNSCUNZB"
}, {
  "headquartersName": "临川浦发村镇银行",
  "headquartersShortName": "临川浦发村镇银行",
  "headquartersNo": "LCPFCUNZB"
}, {
  "headquartersName": "临汾市尧都区惠都村镇银行",
  "headquartersShortName": "临汾市尧都区惠都村镇银行",
  "headquartersNo": "LFSYDQHDCUNZB"
}, {
  "headquartersName": "临高惠丰村镇银行",
  "headquartersShortName": "临高惠丰村镇银行",
  "headquartersNo": "LGHFCUNZB"
}, {
  "headquartersName": "临江蛟银村镇银行",
  "headquartersShortName": "临江蛟银村镇银行",
  "headquartersNo": "LJZYCUNZB"
}, {
  "headquartersName": "临澧沪农商村镇银行",
  "headquartersShortName": "临澧沪农商村镇银行",
  "headquartersNo": "LZHNSCUNZB"
}, {
  "headquartersName": "临清沪农商村镇银行",
  "headquartersShortName": "临清沪农商村镇银行",
  "headquartersNo": "LQHNSCUNZB"
}, {
  "headquartersName": "临商银行",
  "headquartersShortName": "临商",
  "headquartersNo": "LSYH"
}, {
  "headquartersName": "临沭民丰村镇银行",
  "headquartersShortName": "临沭民丰村镇银行",
  "headquartersNo": "LSMFCUNZB"
}, {
  "headquartersName": "临洮县金城村镇银行",
  "headquartersShortName": "临洮县金城村镇银行",
  "headquartersNo": "LZXJCCUNZB"
}, {
  "headquartersName": "临武浦发村镇银行",
  "headquartersShortName": "临武浦发村镇银行",
  "headquartersNo": "LWPFCUNZB"
}, {
  "headquartersName": "临县泉都村镇银行",
  "headquartersShortName": "临县泉都村镇银行",
  "headquartersNo": "LXQDCUNZB"
}, {
  "headquartersName": "临猗县新田村镇银行",
  "headquartersShortName": "临猗县新田村镇银行",
  "headquartersNo": "LZXXTCUNZB"
}, {
  "headquartersName": "临沂河东齐商村镇银行",
  "headquartersShortName": "临沂河东齐商村镇银行",
  "headquartersNo": "LYHDQSCUNZB"
}, {
  "headquartersName": "灵宝融丰村镇银行",
  "headquartersShortName": "灵宝融丰村镇银行",
  "headquartersNo": "LBRFCUNZB"
}, {
  "headquartersName": "灵川深通村镇银行",
  "headquartersShortName": "灵川深通村镇银行",
  "headquartersNo": "LCSTCUNZB"
}, {
  "headquartersName": "灵丘县长青村镇银行",
  "headquartersShortName": "灵丘县长青村镇银行",
  "headquartersNo": "LQXCQCUNZB"
}, {
  "headquartersName": "灵山泰业村镇银行",
  "headquartersShortName": "灵山泰业村镇银行",
  "headquartersNo": "LSTYCUNZB"
}, {
  "headquartersName": "灵石县汇民村镇银行",
  "headquartersShortName": "灵石县汇民村镇银行",
  "headquartersNo": "LSXHMCUNZB"
}, {
  "headquartersName": "凌源天元村镇银行",
  "headquartersShortName": "凌源天元村镇银行",
  "headquartersNo": "LYTYCUNZB"
}, {
  "headquartersName": "陵川县太行村镇银行",
  "headquartersShortName": "陵川县太行村镇银行",
  "headquartersNo": "LCXTXCUNZB"
}, {
  "headquartersName": "陵水惠民村镇银行",
  "headquartersShortName": "陵水惠民村镇银行",
  "headquartersNo": "LSHMCUNZB"
}, {
  "headquartersName": "柳河蒙银村镇银行",
  "headquartersShortName": "柳河蒙银村镇银行",
  "headquartersNo": "LHMYCUNZB"
}, {
  "headquartersName": "柳林汇泽村镇银行",
  "headquartersShortName": "柳林汇泽村镇银行",
  "headquartersNo": "LLHZCUNZB"
}, {
  "headquartersName": "柳州银行",
  "headquartersShortName": "柳州",
  "headquartersNo": "LIUZYH"
}, {
  "headquartersName": "六安农村商业银行",
  "headquartersShortName": "六安农商",
  "headquartersNo": "LARCB"
}, {
  "headquartersName": "六盘水六枝富民村镇银行",
  "headquartersShortName": "六盘水六枝富民村镇银行",
  "headquartersNo": "LPSLZFMCUNZB"
}, {
  "headquartersName": "六盘水农村商业银行",
  "headquartersShortName": "六盘水农商",
  "headquartersNo": "LPSRCB"
}, {
  "headquartersName": "六盘水钟山凉都村镇银行",
  "headquartersShortName": "六盘水钟山凉都村镇银行",
  "headquartersNo": "LPSZSLDCUNZB"
}, {
  "headquartersName": "龙川融和村镇银行",
  "headquartersShortName": "龙川融和村镇银行",
  "headquartersNo": "LCRHCUNZB"
}, {
  "headquartersName": "龙江银行",
  "headquartersShortName": "龙江",
  "headquartersNo": "LJYH"
}, {
  "headquartersName": "龙井榆银村镇银行",
  "headquartersShortName": "龙井榆银村镇银行",
  "headquartersNo": "LJYYCUNZB"
}, {
  "headquartersName": "龙里国丰村镇银行",
  "headquartersShortName": "龙里国丰村镇银行",
  "headquartersNo": "LLGFCUNZB"
}, {
  "headquartersName": "隆德六盘山村镇银行",
  "headquartersShortName": "隆德六盘山村镇银行",
  "headquartersNo": "LDLPSCUNZB"
}, {
  "headquartersName": "隆尧邢农商村镇银行",
  "headquartersShortName": "隆尧邢农商村镇银行",
  "headquartersNo": "LYXNCUNZB"
}, {
  "headquartersName": "陇南市武都金桥村镇银行",
  "headquartersShortName": "陇南市武都金桥村镇银行",
  "headquartersNo": "LNSWDJQCUNZB"
}, {
  "headquartersName": "陇南武都农村合作银行",
  "headquartersShortName": "陇南武都农村合作银行",
  "headquartersNo": "LNWDRCU"
}, {
  "headquartersName": "陇西神舟村镇银行",
  "headquartersShortName": "陇西神舟村镇银行",
  "headquartersNo": "LXSZCUNZB"
}, {
  "headquartersName": "娄烦县三禾村镇银行",
  "headquartersShortName": "娄烦县三禾村镇银行",
  "headquartersNo": "LFXSHCUNZB"
}, {
  "headquartersName": "卢龙家银村镇银行",
  "headquartersShortName": "卢龙家银村镇银行",
  "headquartersNo": "LLJYCUNZB"
}, {
  "headquartersName": "卢氏中原村镇银行",
  "headquartersShortName": "卢氏中原村镇银行",
  "headquartersNo": "LSZYCUNZB"
}, {
  "headquartersName": "庐江惠民村镇银行",
  "headquartersShortName": "庐江惠民村镇银行",
  "headquartersNo": "LJHMCUNZB"
}, {
  "headquartersName": "庐山九银艺术村镇银行",
  "headquartersShortName": "庐山九银艺术村镇银行",
  "headquartersNo": "LSJYYSCUNZB"
}, {
  "headquartersName": "泸水中成村镇银行",
  "headquartersShortName": "泸水中成村镇银行",
  "headquartersNo": "LSZCCUNZB"
}, {
  "headquartersName": "泸县元通村镇银行",
  "headquartersShortName": "泸县元通村镇银行",
  "headquartersNo": "ZXYTCUNZB"
}, {
  "headquartersName": "泸州江阳农村商业银行",
  "headquartersShortName": "泸州江阳农商",
  "headquartersNo": "LZJYRCB"
}, {
  "headquartersName": "泸州龙马潭农村商业银行",
  "headquartersShortName": "泸州龙马潭农商",
  "headquartersNo": "LZLMTRCB"
}, {
  "headquartersName": "泸州农村商业银行",
  "headquartersShortName": "泸州农商",
  "headquartersNo": "LUZRCB"
}, {
  "headquartersName": "泸州市商业银行",
  "headquartersShortName": "泸州",
  "headquartersNo": "HZSCCB"
}, {
  "headquartersName": "鹿泉恒升村镇银行",
  "headquartersShortName": "鹿泉恒升村镇银行",
  "headquartersNo": "LQHSCUNZB"
}, {
  "headquartersName": "禄丰龙城富滇村镇银行",
  "headquartersShortName": "禄丰龙城富滇村镇银行",
  "headquartersNo": "LFLCFDCUNZB"
}, {
  "headquartersName": "滦南中成村镇银行",
  "headquartersShortName": "滦南中成村镇银行",
  "headquartersNo": "LNZCCUNZB"
}, {
  "headquartersName": "滦平盛阳村镇银行",
  "headquartersShortName": "滦平盛阳村镇银行",
  "headquartersNo": "LPSYCUNZB"
}, {
  "headquartersName": "滦州中成村镇银行",
  "headquartersShortName": "滦州中成村镇银行",
  "headquartersNo": "LXZCCUNZB"
}, {
  "headquartersName": "罗甸发展村镇银行",
  "headquartersShortName": "罗甸发展村镇银行",
  "headquartersNo": "LDFZCUNZB"
}, {
  "headquartersName": "罗田楚农商村镇银行",
  "headquartersShortName": "罗田楚农商村镇银行",
  "headquartersNo": "LTCNSCUNZB"
}, {
  "headquartersName": "洛阳农村商业银行",
  "headquartersShortName": "洛阳农商",
  "headquartersNo": "LUOYRCB"
}, {
  "headquartersName": "洛阳银行",
  "headquartersShortName": "洛阳",
  "headquartersNo": "LYBC"
}, {
  "headquartersName": "漯河市郾城发展村镇银行",
  "headquartersShortName": "漯河市郾城发展村镇银行",
  "headquartersNo": "ZHSZCBSCUNZB"
}, {
  "headquartersName": "吕梁孝义汇通村镇银行",
  "headquartersShortName": "吕梁孝义汇通村镇银行",
  "headquartersNo": "LLXYHTCUNZB"
}, {
  "headquartersName": "马来西亚联昌银行",
  "headquartersShortName": "马来西亚联昌",
  "headquartersNo": "MLXYLCB"
}, {
  "headquartersName": "马来西亚马来亚银行",
  "headquartersShortName": "马来西亚马来亚",
  "headquartersNo": "MALAYSIABANK"
}, {
  "headquartersName": "满洲里农村商业银行",
  "headquartersShortName": "满洲里农商",
  "headquartersNo": "MZLRCB"
}, {
  "headquartersName": "茂名电白长江村镇银行",
  "headquartersShortName": "茂名电白长江村镇银行",
  "headquartersNo": "MMDBCJCUNZB"
}, {
  "headquartersName": "茂名高州长江村镇银行",
  "headquartersShortName": "茂名高州长江村镇银行",
  "headquartersNo": "MMGZCJCUNZB"
}, {
  "headquartersName": "眉山农村商业银行",
  "headquartersShortName": "眉山农商",
  "headquartersNo": "MSRCB"
}, {
  "headquartersName": "梅河口民生村镇银行",
  "headquartersShortName": "梅河口民生村镇银行",
  "headquartersNo": "MHKMSCUNZB"
}, {
  "headquartersName": "梅州客家村镇银行",
  "headquartersShortName": "梅州客家村镇银行",
  "headquartersNo": "MXKJCUNZB"
}, {
  "headquartersName": "梅州客商银行",
  "headquartersShortName": "梅州客商",
  "headquartersNo": "MZKSB"
}, {
  "headquartersName": "美国道富银行",
  "headquartersShortName": "美国道富",
  "headquartersNo": "SSC"
}, {
  "headquartersName": "美国建东银行",
  "headquartersShortName": "美国建东",
  "headquartersNo": "AMERICANBUILTEASTBANK"
}, {
  "headquartersName": "美国银行有限公司",
  "headquartersShortName": "美国",
  "headquartersNo": "BAC"
}, {
  "headquartersName": "蒙商银行(包商银行)",
  "headquartersShortName": "蒙商",
  "headquartersNo": "BTCB"
}, {
  "headquartersName": "蒙特利尔银行",
  "headquartersShortName": "蒙特利尔",
  "headquartersNo": "BMOBK"
}, {
  "headquartersName": "蒙自沪农商村镇银行",
  "headquartersShortName": "蒙自沪农商村镇银行",
  "headquartersNo": "MZHNSCUNZB"
}, {
  "headquartersName": "孟村回族自治县融信村镇银行",
  "headquartersShortName": "孟村回族自治县融信村镇银行",
  "headquartersNo": "MCHZZZXRXCUNZB"
}, {
  "headquartersName": "孟津民丰村镇银行",
  "headquartersShortName": "孟津民丰村镇银行",
  "headquartersNo": "MJMFCUNZB"
}, {
  "headquartersName": "孟州射阳村镇银行",
  "headquartersShortName": "孟州射阳村镇银行",
  "headquartersNo": "MZSYCUNZB"
}, {
  "headquartersName": "弥勒沪农商村镇银行",
  "headquartersShortName": "弥勒沪农商村镇银行",
  "headquartersNo": "MLHNSCUNZB"
}, {
  "headquartersName": "泌阳玉川村镇银行",
  "headquartersShortName": "泌阳玉川村镇银行",
  "headquartersNo": "MYYCCUNZB"
}, {
  "headquartersName": "密山民意村镇银行",
  "headquartersShortName": "密山民意村镇银行",
  "headquartersNo": "MSMYCUNZB"
}, {
  "headquartersName": "绵阳市商业银行",
  "headquartersShortName": "绵阳市商业",
  "headquartersNo": "MYCCB"
}, {
  "headquartersName": "绵竹浦发村镇银行",
  "headquartersShortName": "绵竹浦发村镇银行",
  "headquartersNo": "MZPFCUNZB"
}, {
  "headquartersName": "渑池齐鲁村镇银行",
  "headquartersShortName": "渑池齐鲁村镇银行",
  "headquartersNo": "MCQLCUNZB"
}, {
  "headquartersName": "民勤融信村镇银行",
  "headquartersShortName": "民勤融信村镇银行",
  "headquartersNo": "MQRXCUNZB"
}, {
  "headquartersName": "民权德商村镇银行",
  "headquartersShortName": "民权德商村镇银行",
  "headquartersNo": "MQDSCUNZB"
}, {
  "headquartersName": "摩根大通银行（中国）有限公司",
  "headquartersShortName": "摩根大通",
  "headquartersNo": "JPMC"
}, {
  "headquartersName": "摩根士丹利国际",
  "headquartersShortName": "摩根士丹利国际",
  "headquartersNo": "MGSDL"
}, {
  "headquartersName": "莫力达瓦蒙商村镇银行",
  "headquartersShortName": "莫力达瓦蒙商村镇银行",
  "headquartersNo": "MLDWBSCUNZB"
}, {
  "headquartersName": "牟平胶东村镇银行",
  "headquartersShortName": "牟平胶东村镇银行",
  "headquartersNo": "MPJDCUNZB"
}, {
  "headquartersName": "穆棱远东村镇银行",
  "headquartersShortName": "穆棱远东村镇银行",
  "headquartersNo": "MLYDCUNZB"
}, {
  "headquartersName": "纳雍富民村镇银行",
  "headquartersShortName": "纳雍富民村镇银行",
  "headquartersNo": "NYFMCUNZB"
}, {
  "headquartersName": "南部县中成村镇银行",
  "headquartersShortName": "南部县中成村镇银行",
  "headquartersNo": "NBXZCCUNZB"
}, {
  "headquartersName": "南昌昌东九银村镇银行",
  "headquartersShortName": "南昌昌东九银村镇银行",
  "headquartersNo": "NCCDJYCUNZB"
}, {
  "headquartersName": "南昌大丰村镇银行",
  "headquartersShortName": "南昌大丰村镇银行",
  "headquartersNo": "NCDFCUNZB"
}, {
  "headquartersName": "南昌农村商业银行",
  "headquartersShortName": "南昌农商",
  "headquartersNo": "NCRCB"
}, {
  "headquartersName": "南昌新建恒通村镇银行",
  "headquartersShortName": "南昌新建恒通村镇银行",
  "headquartersNo": "NCXJHTCUNZB"
}, {
  "headquartersName": "南城富民村镇银行",
  "headquartersShortName": "南城富民村镇银行",
  "headquartersNo": "NCFMCUNZB"
}, {
  "headquartersName": "南充嘉陵中成村镇银行",
  "headquartersShortName": "南充嘉陵中成村镇银行",
  "headquartersNo": "NCJLZCCUNZB"
}, {
  "headquartersName": "南充农村商业银行",
  "headquartersShortName": "南充农商",
  "headquartersNo": "NCHONGRCB"
}, {
  "headquartersName": "南丰桔都村镇银行",
  "headquartersShortName": "南丰桔都村镇银行",
  "headquartersNo": "NFJDCUNZB"
}, {
  "headquartersName": "南和融信村镇银行",
  "headquartersShortName": "南和融信村镇银行",
  "headquartersNo": "NHRXCUNZB"
}, {
  "headquartersName": "南江农科村镇银行",
  "headquartersShortName": "南江农科村镇银行",
  "headquartersNo": "NJNKCUNZB"
}, {
  "headquartersName": "南京六合九银村镇银行",
  "headquartersShortName": "南京六合九银村镇银行",
  "headquartersNo": "NJLHJYCUNZB"
}, {
  "headquartersName": "南京浦口靖发村镇银行",
  "headquartersShortName": "南京浦口靖发村镇银行",
  "headquartersNo": "NJPKJFCUNZB"
}, {
  "headquartersName": "南京银行",
  "headquartersShortName": "南京",
  "headquartersNo": "NJYH"
}, {
  "headquartersName": "南靖中成村镇银行",
  "headquartersShortName": "南靖中成村镇银行",
  "headquartersNo": "NJZCCUNZB"
}, {
  "headquartersName": "南康赣商村镇银行",
  "headquartersShortName": "南康赣商村镇银行",
  "headquartersNo": "NKGSCUNZB"
}, {
  "headquartersName": "南陵太平村镇银行",
  "headquartersShortName": "南陵太平村镇银行",
  "headquartersNo": "NLTPCUNZB"
}, {
  "headquartersName": "南宁江南国民村镇银行",
  "headquartersShortName": "南宁江南国民村镇银行",
  "headquartersNo": "NNJNGMCUNZB"
}, {
  "headquartersName": "南宁隆安长江村镇银行",
  "headquartersShortName": "南宁隆安长江村镇银行",
  "headquartersNo": "NNLACJCUNZB"
}, {
  "headquartersName": "南宁马山长江村镇银行",
  "headquartersShortName": "南宁马山长江村镇银行",
  "headquartersNo": "NNMSCJCUNZB"
}, {
  "headquartersName": "南宁武鸣漓江村镇银行",
  "headquartersShortName": "南宁武鸣漓江村镇银行",
  "headquartersNo": "NNWMLJCUNZB"
}, {
  "headquartersName": "南宁兴宁长江村镇银行",
  "headquartersShortName": "南宁兴宁长江村镇银行",
  "headquartersNo": "NNXNCJCUNZB"
}, {
  "headquartersName": "南皮融信村镇银行",
  "headquartersShortName": "南皮融信村镇银行",
  "headquartersNo": "NPRXCUNZB"
}, {
  "headquartersName": "南通如皋润泽村镇银行",
  "headquartersShortName": "南通如皋润泽村镇银行",
  "headquartersNo": "JSNTRGBSCUNZB"
}, {
  "headquartersName": "南阳村镇银行",
  "headquartersShortName": "南阳村镇银行",
  "headquartersNo": "NYCUNZB"
}, {
  "headquartersName": "南洋商业银行（中国）有限公司",
  "headquartersShortName": "南洋",
  "headquartersNo": "NOBC"
}, {
  "headquartersName": "讷河融兴村镇银行",
  "headquartersShortName": "讷河融兴村镇银行",
  "headquartersNo": "ZHRXCUNZB"
}, {
  "headquartersName": "内江兴隆村镇银行",
  "headquartersShortName": "内江兴隆村镇银行",
  "headquartersNo": "NJXLCUNZB"
}, {
  "headquartersName": "内蒙古阿巴嘎农村商业银行",
  "headquartersShortName": "内蒙古阿巴嘎农商",
  "headquartersNo": "NMGABGRCB"
}, {
  "headquartersName": "内蒙古阿尔山农村商业银行",
  "headquartersShortName": "内蒙古阿尔山农商",
  "headquartersNo": "NMGAESRCB"
}, {
  "headquartersName": "内蒙古阿鲁科尔沁农村商业银行",
  "headquartersShortName": "内蒙古阿鲁科尔沁农商",
  "headquartersNo": "NMGALKEQRCB"
}, {
  "headquartersName": "内蒙古敖汉农村商业银行",
  "headquartersShortName": "内蒙古敖汉农商",
  "headquartersNo": "NMGAHRCB"
}, {
  "headquartersName": "内蒙古察哈尔右翼前旗农村商业银行",
  "headquartersShortName": "内蒙古察哈尔右翼前旗农商",
  "headquartersNo": "NMGCHEYYQQRCB"
}, {
  "headquartersName": "内蒙古鄂伦春农村商业银行",
  "headquartersShortName": "内蒙古鄂伦春农商",
  "headquartersNo": "NMGELCRCB"
}, {
  "headquartersName": "内蒙古鄂托克农村商业银行",
  "headquartersShortName": "内蒙古鄂托克农商",
  "headquartersNo": "NMGETKRCB"
}, {
  "headquartersName": "内蒙古鄂托克前旗农村商业银行",
  "headquartersShortName": "内蒙古鄂托克前旗农商",
  "headquartersNo": "NMGETKQQRCB"
}, {
  "headquartersName": "内蒙古鄂温克农村商业银行",
  "headquartersShortName": "内蒙古鄂温克农商",
  "headquartersNo": "NMGEWKRCB"
}, {
  "headquartersName": "内蒙古杭锦农村商业银行",
  "headquartersShortName": "内蒙古杭锦农商",
  "headquartersNo": "NMGHJRCB"
}, {
  "headquartersName": "内蒙古和林格尔蒙商村镇银行",
  "headquartersShortName": "内蒙古和林格尔蒙商村镇银行",
  "headquartersNo": "NMGHLGEBSCUNZB"
}, {
  "headquartersName": "内蒙古呼和浩特金谷农村商业银行",
  "headquartersShortName": "内蒙古呼和浩特金谷农商",
  "headquartersNo": "HHHTJGRCB"
}, {
  "headquartersName": "内蒙古开鲁农村商业银行",
  "headquartersShortName": "内蒙古开鲁农商",
  "headquartersNo": "NMGKLURCB"
}, {
  "headquartersName": "内蒙古克什克腾农村商业银行",
  "headquartersShortName": "内蒙古克什克腾农商",
  "headquartersNo": "NMGKSKTRCB"
}, {
  "headquartersName": "内蒙古库伦农村商业银行",
  "headquartersShortName": "内蒙古库伦农商",
  "headquartersNo": "NMGKLRCB"
}, {
  "headquartersName": "内蒙古林西农村商业银行",
  "headquartersShortName": "内蒙古林西农商",
  "headquartersNo": "NMGLXRCB"
}, {
  "headquartersName": "内蒙古宁城农村商业银行",
  "headquartersShortName": "内蒙古宁城农商",
  "headquartersNo": "NMGNCRCB"
}, {
  "headquartersName": "内蒙古陕坝农村商业银行",
  "headquartersShortName": "内蒙古陕坝农商",
  "headquartersNo": "NMGSBRCB"
}, {
  "headquartersName": "内蒙古太仆寺农村商业银行",
  "headquartersShortName": "内蒙古太仆寺农商",
  "headquartersNo": "NMGTPSRCB"
}, {
  "headquartersName": "内蒙古土默特右旗农村商业银行",
  "headquartersShortName": "内蒙古土默特右旗农商",
  "headquartersNo": "NMGTMTYQRCB"
}, {
  "headquartersName": "内蒙古托克托立农村镇银行",
  "headquartersShortName": "内蒙古托克托立农村镇银行",
  "headquartersNo": "NMGTKTLNCUNZB"
}, {
  "headquartersName": "内蒙古托克托农村商业银行",
  "headquartersShortName": "内蒙古托克托农商",
  "headquartersNo": "NMGTKTRCB"
}, {
  "headquartersName": "内蒙古乌拉特农村商业银行",
  "headquartersShortName": "内蒙古乌拉特农商",
  "headquartersNo": "NMGWLTRCB"
}, {
  "headquartersName": "内蒙古五原农村商业银行",
  "headquartersShortName": "内蒙古五原农商",
  "headquartersNo": "NMGWYRCB"
}, {
  "headquartersName": "内蒙古西乌珠穆沁农村商业银行",
  "headquartersShortName": "内蒙古西乌珠穆沁农商",
  "headquartersNo": "NMGXWZMQRCB"
}, {
  "headquartersName": "内蒙古牙克石农村商业银行",
  "headquartersShortName": "内蒙古牙克石农商",
  "headquartersNo": "NMGYKSRCB"
}, {
  "headquartersName": "内蒙古伊金霍洛农村商业银行",
  "headquartersShortName": "内蒙古伊金霍洛农商",
  "headquartersNo": "NMGYJHLRCB"
}, {
  "headquartersName": "内蒙古银行",
  "headquartersShortName": "内蒙古",
  "headquartersNo": "NMGYH"
}, {
  "headquartersName": "内蒙古扎鲁特农村商业银行",
  "headquartersShortName": "内蒙古扎鲁特农商",
  "headquartersNo": "NMGZLTRCB"
}, {
  "headquartersName": "内蒙古自治区农村信用社联合社",
  "headquartersShortName": "内蒙古农信社",
  "headquartersNo": "NMGNXS"
}, {
  "headquartersName": "嫩江幸福村镇银行",
  "headquartersShortName": "嫩江幸福村镇银行",
  "headquartersNo": "NJXFCUNZB"
}, {
  "headquartersName": "宁安融兴村镇银行",
  "headquartersShortName": "宁安融兴村镇银行",
  "headquartersNo": "NARXCUNZB"
}, {
  "headquartersName": "宁波北仑农村商业银行",
  "headquartersShortName": "宁波北仑农商",
  "headquartersNo": "NBBLRCB"
}, {
  "headquartersName": "宁波慈溪农村商业银行",
  "headquartersShortName": "宁波慈溪农商",
  "headquartersNo": "NBCXRCB"
}, {
  "headquartersName": "宁波东海银行",
  "headquartersShortName": "宁波东海",
  "headquartersNo": "NBDHYH"
}, {
  "headquartersName": "宁波奉化农村商业银行",
  "headquartersShortName": "宁波奉化农商",
  "headquartersNo": "NBFHRCB"
}, {
  "headquartersName": "宁波海曙浦发村镇银行",
  "headquartersShortName": "宁波海曙浦发村镇银行",
  "headquartersNo": "NBHSPFCUNZB"
}, {
  "headquartersName": "宁波江北富民村镇银行",
  "headquartersShortName": "宁波江北富民村镇银行",
  "headquartersNo": "NBJBFMCUNZB"
}, {
  "headquartersName": "宁波宁海农村商业银行",
  "headquartersShortName": "宁波宁海农商",
  "headquartersNo": "NBNHRCB"
}, {
  "headquartersName": "宁波市海曙国民村镇银行",
  "headquartersShortName": "宁波市海曙国民村镇银行",
  "headquartersNo": "NBSHZGMCUNZB"
}, {
  "headquartersName": "宁波通商银行",
  "headquartersShortName": "宁波通商",
  "headquartersNo": "NBTSYH"
}, {
  "headquartersName": "宁波银行",
  "headquartersShortName": "宁波",
  "headquartersNo": "NBYH"
}, {
  "headquartersName": "宁波鄞州农村商业银行",
  "headquartersShortName": "宁波鄞州农商",
  "headquartersNo": "NBYZRCB"
}, {
  "headquartersName": "宁波甬城农村商业银行",
  "headquartersShortName": "宁波甬城农商",
  "headquartersNo": "NBYCRCB"
}, {
  "headquartersName": "宁波余姚农村商业银行",
  "headquartersShortName": "宁波余姚农商",
  "headquartersNo": "NBYYRCB"
}, {
  "headquartersName": "宁波镇海农村商业银行",
  "headquartersShortName": "宁波镇海农商",
  "headquartersNo": "NBZHRCB"
}, {
  "headquartersName": "宁城蒙商村镇银行",
  "headquartersShortName": "宁城蒙商村镇银行",
  "headquartersNo": "NCBSCUNZB"
}, {
  "headquartersName": "宁国民生村镇银行",
  "headquartersShortName": "宁国民生村镇银行",
  "headquartersNo": "NGMSCUNZB"
}, {
  "headquartersName": "宁津胶东村镇银行",
  "headquartersShortName": "宁津胶东村镇银行",
  "headquartersNo": "NJJDCUNZB"
}, {
  "headquartersName": "宁晋民生村镇银行",
  "headquartersShortName": "宁晋民生村镇银行",
  "headquartersNo": "NJMSCUNZB"
}, {
  "headquartersName": "宁陵德商村镇银行",
  "headquartersShortName": "宁陵德商村镇银行",
  "headquartersNo": "NLDSCUNZB"
}, {
  "headquartersName": "宁武县瑞都村镇银行",
  "headquartersShortName": "宁武县瑞都村镇银行",
  "headquartersNo": "NWXRDCUNZB"
}, {
  "headquartersName": "宁夏固原农村商业银行",
  "headquartersShortName": "宁夏固原农商",
  "headquartersNo": "NXGYRCB"
}, {
  "headquartersName": "宁夏海原农村商业银行",
  "headquartersShortName": "宁夏海原农商",
  "headquartersNo": "NXHYRCB"
}, {
  "headquartersName": "宁夏贺兰回商村镇银行",
  "headquartersShortName": "宁夏贺兰回商村镇银行",
  "headquartersNo": "NXHLHSCUNZB"
}, {
  "headquartersName": "宁夏贺兰农村商业银行",
  "headquartersShortName": "宁夏贺兰农商",
  "headquartersNo": "NXHLRCB"
}, {
  "headquartersName": "宁夏红寺堡汇发村镇银行",
  "headquartersShortName": "宁夏红寺堡汇发村镇银行",
  "headquartersNo": "NXHSBHFCUNZB"
}, {
  "headquartersName": "宁夏红寺堡农村商业银行",
  "headquartersShortName": "宁夏红寺堡农商",
  "headquartersNo": "NXHSBRCB"
}, {
  "headquartersName": "宁夏黄河农村商业银行",
  "headquartersShortName": "宁夏黄河农商",
  "headquartersNo": "NXHHRCB"
}, {
  "headquartersName": "宁夏回族自治区农村信用社联合社",
  "headquartersShortName": "宁夏农信社",
  "headquartersNo": "NXNXS"
}, {
  "headquartersName": "宁夏惠农贺兰山村镇银行",
  "headquartersShortName": "宁夏惠农贺兰山村镇银行",
  "headquartersNo": "NXHNHLSCUNZB"
}, {
  "headquartersName": "宁夏泾源农村商业银行",
  "headquartersShortName": "宁夏泾源农商",
  "headquartersNo": "NXJYRCB"
}, {
  "headquartersName": "宁夏灵武农村商业银行",
  "headquartersShortName": "宁夏灵武农商",
  "headquartersNo": "NXLWRCB"
}, {
  "headquartersName": "宁夏隆德农村商业银行",
  "headquartersShortName": "宁夏隆德农商",
  "headquartersNo": "NXLDRCB"
}, {
  "headquartersName": "宁夏宁东本富村镇银行",
  "headquartersShortName": "宁夏宁东本富村镇银行",
  "headquartersNo": "NXNDBFCUNZB"
}, {
  "headquartersName": "宁夏彭阳贺兰山村镇银行",
  "headquartersShortName": "宁夏彭阳贺兰山村镇银行",
  "headquartersNo": "NXPYHLSCUNZB"
}, {
  "headquartersName": "宁夏彭阳农村商业银行",
  "headquartersShortName": "宁夏彭阳农商",
  "headquartersNo": "NXPYRCB"
}, {
  "headquartersName": "宁夏平罗农村商业银行",
  "headquartersShortName": "宁夏平罗农商",
  "headquartersNo": "NXPLRCB"
}, {
  "headquartersName": "宁夏青铜峡贺兰山村镇银行",
  "headquartersShortName": "宁夏青铜峡贺兰山村镇银行",
  "headquartersNo": "NXQTXHLSCUNZB"
}, {
  "headquartersName": "宁夏石嘴山农村商业银行",
  "headquartersShortName": "宁夏石嘴山农商",
  "headquartersNo": "NXSZSRCB"
}, {
  "headquartersName": "宁夏同心津汇村镇银行",
  "headquartersShortName": "宁夏同心津汇村镇银行",
  "headquartersNo": "NXTXJHCUNZB"
}, {
  "headquartersName": "宁夏同心农村商业银行",
  "headquartersShortName": "宁夏同心农商",
  "headquartersNo": "NXTXRCB"
}, {
  "headquartersName": "宁夏吴忠农村商业银行",
  "headquartersShortName": "宁夏吴忠农商",
  "headquartersNo": "NXWZRCB"
}, {
  "headquartersName": "宁夏西吉汇发村镇银行",
  "headquartersShortName": "宁夏西吉汇发村镇银行",
  "headquartersNo": "NXXJHFCUNZB"
}, {
  "headquartersName": "宁夏西吉农村商业银行",
  "headquartersShortName": "宁夏西吉农商",
  "headquartersNo": "NXXJRCB"
}, {
  "headquartersName": "宁夏西夏贺兰山村镇银行",
  "headquartersShortName": "宁夏西夏贺兰山村镇银行",
  "headquartersNo": "NXXXHLSCUNZB"
}, {
  "headquartersName": "宁夏盐池汇发村镇银行",
  "headquartersShortName": "宁夏盐池汇发村镇银行",
  "headquartersNo": "NXYCHFCUNZB"
}, {
  "headquartersName": "宁夏盐池农村商业银行",
  "headquartersShortName": "宁夏盐池农商",
  "headquartersNo": "NXYCRCB"
}, {
  "headquartersName": "宁夏银行",
  "headquartersShortName": "宁夏",
  "headquartersNo": "NXYH"
}, {
  "headquartersName": "宁夏永宁汇发村镇银行",
  "headquartersShortName": "宁夏永宁汇发村镇银行",
  "headquartersNo": "NXYNHFCUNZB"
}, {
  "headquartersName": "宁夏原州津汇村镇银行",
  "headquartersShortName": "宁夏原州津汇村镇银行",
  "headquartersNo": "NXYZJHCUNZB"
}, {
  "headquartersName": "宁夏中宁农村商业银行",
  "headquartersShortName": "宁夏中宁农商",
  "headquartersNo": "NXZNRCB"
}, {
  "headquartersName": "宁夏中宁青银村镇银行",
  "headquartersShortName": "宁夏中宁青银村镇银行",
  "headquartersNo": "NXZNQYCUNZB"
}, {
  "headquartersName": "宁夏中卫农村商业银行",
  "headquartersShortName": "宁夏中卫农商",
  "headquartersNo": "NXZWRCB"
}, {
  "headquartersName": "宁乡沪农商村镇银行",
  "headquartersShortName": "宁乡沪农商村镇银行",
  "headquartersNo": "NXHNSCUNZB"
}, {
  "headquartersName": "宁阳沪农商村镇银行",
  "headquartersShortName": "宁阳沪农商村镇银行",
  "headquartersNo": "NYHNSCUNZB"
}, {
  "headquartersName": "农安北银村镇银行",
  "headquartersShortName": "农安北银村镇银行",
  "headquartersNo": "NABYCUNZB"
}, {
  "headquartersName": "挪威银行公共",
  "headquartersShortName": "挪威公共",
  "headquartersNo": "NORWAYBANK"
}, {
  "headquartersName": "攀枝花农村商业银行",
  "headquartersShortName": "攀枝花农商",
  "headquartersNo": "PZHRCB"
}, {
  "headquartersName": "攀枝花市商业银行",
  "headquartersShortName": "攀枝花",
  "headquartersNo": "PZHCCB"
}, {
  "headquartersName": "盘谷银行",
  "headquartersShortName": "盘谷",
  "headquartersNo": "BKB"
}, {
  "headquartersName": "盘锦农村商业银行",
  "headquartersShortName": "盘锦农商",
  "headquartersNo": "PJRCB"
}, {
  "headquartersName": "盘锦市商业银行",
  "headquartersShortName": "盘锦",
  "headquartersNo": "PJSCCB"
}, {
  "headquartersName": "盘山安泰村镇银行",
  "headquartersShortName": "盘山安泰村镇银行",
  "headquartersNo": "PSATCUNZB"
}, {
  "headquartersName": "盘州万和村镇银行",
  "headquartersShortName": "盘州万和村镇银行",
  "headquartersNo": "PZWHCUNZB"
}, {
  "headquartersName": "磐石吉银村镇银行",
  "headquartersShortName": "磐石吉银村镇银行",
  "headquartersNo": "PSJYCUNZB"
}, {
  "headquartersName": "彭山珠江村镇银行",
  "headquartersShortName": "彭山珠江村镇银行",
  "headquartersNo": "PSZJCUNZB"
}, {
  "headquartersName": "彭泽九银村镇银行",
  "headquartersShortName": "彭泽九银村镇银行",
  "headquartersNo": "PZJYCUNZB"
}, {
  "headquartersName": "彭州民生村镇银行",
  "headquartersShortName": "彭州民生村镇银行",
  "headquartersNo": "PZMSCUNZB"
}, {
  "headquartersName": "蓬莱民生村镇银行",
  "headquartersShortName": "蓬莱民生村镇银行",
  "headquartersNo": "PLMSCUNZB"
}, {
  "headquartersName": "平安银行(深圳发展银行)",
  "headquartersShortName": "平安",
  "headquartersNo": "SDB"
}, {
  "headquartersName": "平坝鼎立村镇银行",
  "headquartersShortName": "平坝鼎立村镇银行",
  "headquartersNo": "PBDLCUNZB"
}, {
  "headquartersName": "平昌农科村镇银行",
  "headquartersShortName": "平昌农科村镇银行",
  "headquartersNo": "PCNKCUNZB"
}, {
  "headquartersName": "平顶山卫东农村商业银行",
  "headquartersShortName": "平顶山卫东农商",
  "headquartersNo": "PDSWDRCB"
}, {
  "headquartersName": "平顶山银行",
  "headquartersShortName": "平顶山",
  "headquartersNo": "PDSYH"
}, {
  "headquartersName": "平顶山鹰城农村商业银行",
  "headquartersShortName": "平顶山鹰城农商",
  "headquartersNo": "PDSYCRCB"
}, {
  "headquartersName": "平定县昌都村镇银行",
  "headquartersShortName": "平定县昌都村镇银行",
  "headquartersNo": "PDCDCUNZB"
}, {
  "headquartersName": "平果国民村镇银行",
  "headquartersShortName": "平果国民村镇银行",
  "headquartersNo": "PGGMCUNZB"
}, {
  "headquartersName": "平和润丰村镇银行",
  "headquartersShortName": "平和润丰村镇银行",
  "headquartersNo": "PHRFCUNZB"
}, {
  "headquartersName": "平凉崆峒融兴村镇银行",
  "headquartersShortName": "平凉崆峒融兴村镇银行",
  "headquartersNo": "PLZZRXCUNZB"
}, {
  "headquartersName": "平凉农村商业银行",
  "headquartersShortName": "平凉农商",
  "headquartersNo": "PLRCB"
}, {
  "headquartersName": "平凉市静宁成纪村镇银行",
  "headquartersShortName": "平凉市静宁成纪村镇银行",
  "headquartersNo": "PLSJNCJCUNZB"
}, {
  "headquartersName": "平陆县河东村镇银行",
  "headquartersShortName": "平陆县河东村镇银行",
  "headquartersNo": "PLXHDCUNZB"
}, {
  "headquartersName": "平罗沙湖村镇银行",
  "headquartersShortName": "平罗沙湖村镇银行",
  "headquartersNo": "PLSHCUNZB"
}, {
  "headquartersName": "平泉恒升村镇银行",
  "headquartersShortName": "平泉恒升村镇银行",
  "headquartersNo": "PQHSCUNZB"
}, {
  "headquartersName": "平山西柏坡冀银村镇银行",
  "headquartersShortName": "平山西柏坡冀银村镇银行",
  "headquartersNo": "PSXBPJYCUNZB"
}, {
  "headquartersName": "平塘富民村镇银行",
  "headquartersShortName": "平塘富民村镇银行",
  "headquartersNo": "PTFMCUNZB"
}, {
  "headquartersName": "平遥县晋融村镇银行",
  "headquartersShortName": "平遥县晋融村镇银行",
  "headquartersNo": "PYXJRCUNZB"
}, {
  "headquartersName": "平阴蓝海村镇银行",
  "headquartersShortName": "平阴蓝海村镇银行",
  "headquartersNo": "PYLHCUNZB"
}, {
  "headquartersName": "平舆玉川村镇银行",
  "headquartersShortName": "平舆玉川村镇银行",
  "headquartersNo": "PYYCCUNZB"
}, {
  "headquartersName": "平原圆融村镇银行",
  "headquartersShortName": "平原圆融村镇银行",
  "headquartersNo": "PYYRCUNZB"
}, {
  "headquartersName": "屏南刺桐红村镇银行",
  "headquartersShortName": "屏南刺桐红村镇银行",
  "headquartersNo": "PNCTHCUNZB"
}, {
  "headquartersName": "萍乡安源富民村镇银行",
  "headquartersShortName": "萍乡安源富民村镇银行",
  "headquartersNo": "PXAYFMCUNZB"
}, {
  "headquartersName": "萍乡农村商业银行",
  "headquartersShortName": "萍乡农商",
  "headquartersNo": "PXRCB"
}, {
  "headquartersName": "萍乡湘东黄海村镇银行",
  "headquartersShortName": "萍乡湘东黄海村镇银行",
  "headquartersNo": "PXXDHHCUNZB"
}, {
  "headquartersName": "鄱阳恒通村镇银行",
  "headquartersShortName": "鄱阳恒通村镇银行",
  "headquartersNo": "PYHTCUNZB"
}, {
  "headquartersName": "濮阳农村商业银行",
  "headquartersShortName": "濮阳农商",
  "headquartersNo": "PYRCB"
}, {
  "headquartersName": "濮阳中原村镇银行",
  "headquartersShortName": "濮阳中原村镇银行",
  "headquartersNo": "ZYZYCUNZB"
}, {
  "headquartersName": "浦城中成村镇银行",
  "headquartersShortName": "浦城中成村镇银行",
  "headquartersNo": "PCZCCUNZB"
}, {
  "headquartersName": "浦发硅谷银行",
  "headquartersShortName": "浦发硅谷",
  "headquartersNo": "SPDSV"
}, {
  "headquartersName": "普安普惠村镇银行",
  "headquartersShortName": "普安普惠村镇银行",
  "headquartersNo": "PAPHCUNZB"
}, {
  "headquartersName": "普定富民村镇银行",
  "headquartersShortName": "普定富民村镇银行",
  "headquartersNo": "PDFMCUNZB"
}, {
  "headquartersName": "普洱民生村镇银行",
  "headquartersShortName": "普洱民生村镇银行",
  "headquartersNo": "PEMSCUNZB"
}, {
  "headquartersName": "齐河胶东村镇银行",
  "headquartersShortName": "齐河胶东村镇银行",
  "headquartersNo": "QHJDCUNZB"
}, {
  "headquartersName": "齐鲁银行",
  "headquartersShortName": "齐鲁",
  "headquartersNo": "QLYH"
}, {
  "headquartersName": "齐齐哈尔农村商业银行",
  "headquartersShortName": "齐齐哈尔农商",
  "headquartersNo": "QQHERCB"
}, {
  "headquartersName": "齐商银行",
  "headquartersShortName": "齐商",
  "headquartersNo": "QSYH"
}, {
  "headquartersName": "祁东惠丰村镇银行",
  "headquartersShortName": "祁东惠丰村镇银行",
  "headquartersNo": "QDHFCUNZB"
}, {
  "headquartersName": "祁县晋融村镇银行",
  "headquartersShortName": "祁县晋融村镇银行",
  "headquartersNo": "QXJRCUNZB"
}, {
  "headquartersName": "祁阳村镇银行",
  "headquartersShortName": "祁阳村镇银行",
  "headquartersNo": "QYCUNZB"
}, {
  "headquartersName": "奇台利丰村镇银行",
  "headquartersShortName": "奇台利丰村镇银行",
  "headquartersNo": "QTLFCUNZB"
}, {
  "headquartersName": "淇县中原村镇银行",
  "headquartersShortName": "淇县中原村镇银行",
  "headquartersNo": "ZXZYCUNZB"
}, {
  "headquartersName": "綦江民生村镇银行",
  "headquartersShortName": "綦江民生村镇银行",
  "headquartersNo": "ZJMSCUNZB"
}, {
  "headquartersName": "企业银行（中国）有限公司",
  "headquartersShortName": "企业",
  "headquartersNo": "IBKK"
}, {
  "headquartersName": "迁安襄隆村镇银行",
  "headquartersShortName": "迁安襄隆村镇银行",
  "headquartersNo": "QAXLCUNZB"
}, {
  "headquartersName": "前郭县阳光村镇银行",
  "headquartersShortName": "前郭县阳光村镇银行",
  "headquartersNo": "QGXYGCUNZB"
}, {
  "headquartersName": "乾安惠民村镇银行",
  "headquartersShortName": "乾安惠民村镇银行",
  "headquartersNo": "QAHMCUNZB"
}, {
  "headquartersName": "黔西花都村镇银行",
  "headquartersShortName": "黔西花都村镇银行",
  "headquartersNo": "QXHDCUNZB"
}, {
  "headquartersName": "黔西南义龙浦发村镇银行",
  "headquartersShortName": "黔西南义龙浦发村镇银行",
  "headquartersNo": "QXNYLPFCUNZB"
}, {
  "headquartersName": "秦皇岛抚宁家银村镇银行",
  "headquartersShortName": "秦皇岛抚宁家银村镇银行",
  "headquartersNo": "QHDFNJYCUNZB"
}, {
  "headquartersName": "秦皇岛银行",
  "headquartersShortName": "秦皇岛",
  "headquartersNo": "QHDYH"
}, {
  "headquartersName": "沁源县长青村镇银行",
  "headquartersShortName": "沁源县长青村镇银行",
  "headquartersNo": "QYXCQCUNZB"
}, {
  "headquartersName": "青岛城阳珠江村镇银行",
  "headquartersShortName": "青岛城阳珠江村镇银行",
  "headquartersNo": "QDCYZJCUNZB"
}, {
  "headquartersName": "青岛黄岛舜丰村镇银行",
  "headquartersShortName": "青岛黄岛舜丰村镇银行",
  "headquartersNo": "QDHDSFCUNZB"
}, {
  "headquartersName": "青岛即墨惠民村镇银行",
  "headquartersShortName": "青岛即墨惠民村镇银行",
  "headquartersNo": "QDJMHMCUNZB"
}, {
  "headquartersName": "青岛胶州中成村镇银行",
  "headquartersShortName": "青岛胶州中成村镇银行",
  "headquartersNo": "QDJZZCCUNZB"
}, {
  "headquartersName": "青岛莱西元泰村镇银行",
  "headquartersShortName": "青岛莱西元泰村镇银行",
  "headquartersNo": "QDLXYTCUNZB"
}, {
  "headquartersName": "青岛崂山交银村镇银行",
  "headquartersShortName": "青岛崂山交银村镇银行",
  "headquartersNo": "QDZSJYCUNZB"
}, {
  "headquartersName": "青岛农村商业银行",
  "headquartersShortName": "青岛农商",
  "headquartersNo": "QDRCB"
}, {
  "headquartersName": "青岛平度惠民村镇银行",
  "headquartersShortName": "青岛平度惠民村镇银行",
  "headquartersNo": "QDPDHMCUNZB"
}, {
  "headquartersName": "青岛西海岸海汇村镇银行",
  "headquartersShortName": "青岛西海岸海汇村镇银行",
  "headquartersNo": "QDXHAHHCUNZB"
}, {
  "headquartersName": "青岛银行",
  "headquartersShortName": "青岛",
  "headquartersNo": "QDYH"
}, {
  "headquartersName": "青海柴达木农村商业银行",
  "headquartersShortName": "青海柴达木农商",
  "headquartersNo": "QHCDMRCB"
}, {
  "headquartersName": "青海大通农村商业银行",
  "headquartersShortName": "青海大通农商",
  "headquartersNo": "QHDTRCB"
}, {
  "headquartersName": "青海都兰农村商业银行",
  "headquartersShortName": "青海都兰农商",
  "headquartersNo": "QHDLRCB"
}, {
  "headquartersName": "青海刚察农村商业银行",
  "headquartersShortName": "青海刚察农商",
  "headquartersNo": "QHGCRCB"
}, {
  "headquartersName": "青海格尔木农村商业银行",
  "headquartersShortName": "青海格尔木农商",
  "headquartersNo": "QHGEMRCB"
}, {
  "headquartersName": "青海共和农村商业银行",
  "headquartersShortName": "青海共和农商",
  "headquartersNo": "QHGHRCB"
}, {
  "headquartersName": "青海贵德农村商业银行",
  "headquartersShortName": "青海贵德农商",
  "headquartersNo": "QHGDRCB"
}, {
  "headquartersName": "青海贵南农村商业银行",
  "headquartersShortName": "青海贵南农商",
  "headquartersNo": "QHGNRCB"
}, {
  "headquartersName": "青海果洛农村商业银行",
  "headquartersShortName": "青海果洛农商",
  "headquartersNo": "QHGLRCB"
}, {
  "headquartersName": "青海海东农村商业银行",
  "headquartersShortName": "青海海东农商",
  "headquartersNo": "QHHDPARCB"
}, {
  "headquartersName": "青海海晏农村商业银行",
  "headquartersShortName": "青海海晏农商",
  "headquartersNo": "QHHYANRCB"
}, {
  "headquartersName": "青海河南农村商业银行",
  "headquartersShortName": "青海河南农商",
  "headquartersNo": "QHHNRCB"
}, {
  "headquartersName": "青海互助农村商业银行",
  "headquartersShortName": "青海互助农商",
  "headquartersNo": "QHHZRCB"
}, {
  "headquartersName": "青海化隆农村商业银行",
  "headquartersShortName": "青海化隆农商",
  "headquartersNo": "QHHLRCB"
}, {
  "headquartersName": "青海湟源农村商业银行",
  "headquartersShortName": "青海湟源农商",
  "headquartersNo": "QHHYRCB"
}, {
  "headquartersName": "青海湟中农村商业银行",
  "headquartersShortName": "青海湟中农商",
  "headquartersNo": "QHHZHRCB"
}, {
  "headquartersName": "青海湟中三江村镇银行",
  "headquartersShortName": "青海湟中三江村镇银行",
  "headquartersNo": "QHZZSJCUNZB"
}, {
  "headquartersName": "青海尖扎农村商业银行",
  "headquartersShortName": "青海尖扎农商",
  "headquartersNo": "QHJZRCB"
}, {
  "headquartersName": "青海乐都三江村镇银行",
  "headquartersShortName": "青海乐都三江村镇银行",
  "headquartersNo": "QHLDSJCUNZB"
}, {
  "headquartersName": "青海门源大通村镇银行",
  "headquartersShortName": "青海门源大通村镇银行",
  "headquartersNo": "QHMYDTCUNZB"
}, {
  "headquartersName": "青海门源农村商业银行",
  "headquartersShortName": "青海门源农商",
  "headquartersNo": "QHMYRCB"
}, {
  "headquartersName": "青海民和农村商业银行",
  "headquartersShortName": "青海民和农商",
  "headquartersNo": "QHMHRCB"
}, {
  "headquartersName": "青海平安大通村镇银行",
  "headquartersShortName": "青海平安大通村镇银行",
  "headquartersNo": "QHPADTCUNZB"
}, {
  "headquartersName": "青海祁连农村商业银行",
  "headquartersShortName": "青海祁连农商",
  "headquartersNo": "QHQLRCB"
}, {
  "headquartersName": "青海省农村信用社联合社",
  "headquartersShortName": "青海农信社",
  "headquartersNo": "QHNXS"
}, {
  "headquartersName": "青海天峻农村商业银行",
  "headquartersShortName": "青海天峻农商",
  "headquartersNo": "QHTJRCB"
}, {
  "headquartersName": "青海同德农村商业银行",
  "headquartersShortName": "青海同德农商",
  "headquartersNo": "QHTDRCB"
}, {
  "headquartersName": "青海同仁农村商业银行",
  "headquartersShortName": "青海同仁农商",
  "headquartersNo": "QHTRRCB"
}, {
  "headquartersName": "青海乌兰农村商业银行",
  "headquartersShortName": "青海乌兰农商",
  "headquartersNo": "QHWLRCB"
}, {
  "headquartersName": "青海西宁农村商业银行",
  "headquartersShortName": "青海西宁农商",
  "headquartersNo": "QHXNRCB"
}, {
  "headquartersName": "青海兴海农村商业银行",
  "headquartersShortName": "青海兴海农商",
  "headquartersNo": "QHXHRCB"
}, {
  "headquartersName": "青海循化农村商业银行",
  "headquartersShortName": "青海循化农商",
  "headquartersNo": "QHXUNHRCB"
}, {
  "headquartersName": "青海银行",
  "headquartersShortName": "青海",
  "headquartersNo": "QHYH"
}, {
  "headquartersName": "青海玉树农村商业银行",
  "headquartersShortName": "青海玉树农商",
  "headquartersNo": "QHYSRCB"
}, {
  "headquartersName": "青海泽库农村商业银行",
  "headquartersShortName": "青海泽库农商",
  "headquartersNo": "QHZJRCB"
}, {
  "headquartersName": "青龙融兴村镇银行",
  "headquartersShortName": "青龙融兴村镇银行",
  "headquartersNo": "QLRXCUNZB"
}, {
  "headquartersName": "青县青隆村镇银行",
  "headquartersShortName": "青县青隆村镇银行",
  "headquartersNo": "QXQLCUNZB"
}, {
  "headquartersName": "青原庐陵村镇银行",
  "headquartersShortName": "青原庐陵村镇银行",
  "headquartersNo": "QYLLCUNZB"
}, {
  "headquartersName": "清河金农村镇银行",
  "headquartersShortName": "清河金农村镇银行",
  "headquartersNo": "QHJNCUNZB"
}, {
  "headquartersName": "清徐惠民村镇银行",
  "headquartersShortName": "清徐惠民村镇银行",
  "headquartersNo": "QXHMCUNZB"
}, {
  "headquartersName": "清徐晋商村镇银行",
  "headquartersShortName": "清徐晋商村镇银行",
  "headquartersNo": "QXJSCUNZB"
}, {
  "headquartersName": "清远清新惠民村镇银行",
  "headquartersShortName": "清远清新惠民村镇银行",
  "headquartersNo": "QYQXHMCUNZB"
}, {
  "headquartersName": "清镇兴邦村镇银行",
  "headquartersShortName": "清镇兴邦村镇银行",
  "headquartersNo": "QZXBCUNZB"
}, {
  "headquartersName": "晴隆兴安村镇银行",
  "headquartersShortName": "晴隆兴安村镇银行",
  "headquartersNo": "QLXACUNZB"
}, {
  "headquartersName": "庆城县金城村镇银行",
  "headquartersShortName": "庆城县金城村镇银行",
  "headquartersNo": "QCXJCCUNZB"
}, {
  "headquartersName": "庆阳农村商业银行",
  "headquartersShortName": "庆阳农商",
  "headquartersNo": "QYRCB"
}, {
  "headquartersName": "庆阳市西峰瑞信村镇银行",
  "headquartersShortName": "庆阳市西峰瑞信村镇银行",
  "headquartersNo": "QYSXFRXCUNZB"
}, {
  "headquartersName": "庆云乐安村镇银行",
  "headquartersShortName": "庆云乐安村镇银行",
  "headquartersNo": "QYLACUNZB"
}, {
  "headquartersName": "邛崃国民村镇银行",
  "headquartersShortName": "邛崃国民村镇银行",
  "headquartersNo": "ZZGMCUNZB"
}, {
  "headquartersName": "琼海大众村镇银行",
  "headquartersShortName": "琼海大众村镇银行",
  "headquartersNo": "QHDZCUNZB"
}, {
  "headquartersName": "曲靖富源富滇村镇银行",
  "headquartersShortName": "曲靖富源富滇村镇银行",
  "headquartersNo": "QJFYFDCUNZB"
}, {
  "headquartersName": "曲靖会泽长江村镇银行",
  "headquartersShortName": "曲靖会泽长江村镇银行",
  "headquartersNo": "QJHZCJCUNZB"
}, {
  "headquartersName": "曲靖惠民村镇银行",
  "headquartersShortName": "曲靖惠民村镇银行",
  "headquartersNo": "QJHMCUNZB"
}, {
  "headquartersName": "曲靖市商业银行",
  "headquartersShortName": "曲靖",
  "headquartersNo": "QJSCCB"
}, {
  "headquartersName": "曲靖宣威长江村镇银行",
  "headquartersShortName": "曲靖宣威长江村镇银行",
  "headquartersNo": "QJXWCJCUNZB"
}, {
  "headquartersName": "曲沃县新田村镇银行",
  "headquartersShortName": "曲沃县新田村镇银行",
  "headquartersNo": "QWXXTCUNZB"
}, {
  "headquartersName": "曲阳中成村镇银行",
  "headquartersShortName": "曲阳中成村镇银行",
  "headquartersNo": "QYZCCUNZB"
}, {
  "headquartersName": "曲周恒升村镇银行",
  "headquartersShortName": "曲周恒升村镇银行",
  "headquartersNo": "QZHSCUNZB"
}, {
  "headquartersName": "泉州农村商业银行",
  "headquartersShortName": "泉州农商",
  "headquartersNo": "QZRCB"
}, {
  "headquartersName": "泉州银行",
  "headquartersShortName": "泉州",
  "headquartersNo": "QZYH"
}, {
  "headquartersName": "确山郑银村镇银行",
  "headquartersShortName": "确山郑银村镇银行",
  "headquartersNo": "QSZYCUNZB"
}, {
  "headquartersName": "饶平融和村镇银行",
  "headquartersShortName": "饶平融和村镇银行",
  "headquartersNo": "RPRHCUNZB"
}, {
  "headquartersName": "饶阳民商村镇银行",
  "headquartersShortName": "饶阳民商村镇银行",
  "headquartersNo": "RYMSCUNZB"
}, {
  "headquartersName": "仁怀蒙银村镇银行",
  "headquartersShortName": "仁怀蒙银村镇银行",
  "headquartersNo": "RHMYCUNZB"
}, {
  "headquartersName": "仁寿民富村镇银行",
  "headquartersShortName": "仁寿民富村镇银行",
  "headquartersNo": "RSMFCUNZB"
}, {
  "headquartersName": "任丘村镇银行",
  "headquartersShortName": "任丘村镇银行",
  "headquartersNo": "RQCUNZB"
}, {
  "headquartersName": "任县邢农商村镇银行",
  "headquartersShortName": "任县邢农商村镇银行",
  "headquartersNo": "RXXNSCUNZB"
}, {
  "headquartersName": "日本横滨银行",
  "headquartersShortName": "日本横滨",
  "headquartersNo": "RBHBB"
}, {
  "headquartersName": "日本名古屋银行",
  "headquartersShortName": "日本名古屋",
  "headquartersNo": "MEIGIN"
}, {
  "headquartersName": "日本山口银行",
  "headquartersShortName": "日本山口",
  "headquartersNo": "JAPANYAMAGUCHIBANK"
}, {
  "headquartersName": "日照东港农村商业银行",
  "headquartersShortName": "日照东港农商",
  "headquartersNo": "RZDGRCB"
}, {
  "headquartersName": "日照沪农商村镇银行",
  "headquartersShortName": "日照沪农商村镇银行",
  "headquartersNo": "RZHNSCUNZB"
}, {
  "headquartersName": "日照九银村镇银行",
  "headquartersShortName": "日照九银村镇银行",
  "headquartersNo": "RZJYCUNZB"
}, {
  "headquartersName": "日照岚山农村商业银行",
  "headquartersShortName": "日照岚山农商",
  "headquartersNo": "RZLSRCB"
}, {
  "headquartersName": "日照蓝海村镇银行",
  "headquartersShortName": "日照蓝海村镇银行",
  "headquartersNo": "RZLHCUNZB"
}, {
  "headquartersName": "日照银行",
  "headquartersShortName": "日照",
  "headquartersNo": "RZYH"
}, {
  "headquartersName": "容城邢农商村镇银行",
  "headquartersShortName": "容城邢农商村镇银行",
  "headquartersNo": "RCXNSCUNZB"
}, {
  "headquartersName": "汝州玉川村镇银行",
  "headquartersShortName": "汝州玉川村镇银行",
  "headquartersNo": "RZYCCUNZB"
}, {
  "headquartersName": "乳山天骄村镇银行",
  "headquartersShortName": "乳山天骄村镇银行",
  "headquartersNo": "RSTJCUNZB"
}, {
  "headquartersName": "瑞昌九银村镇银行",
  "headquartersShortName": "瑞昌九银村镇银行",
  "headquartersNo": "RCJYCUNZB"
}, {
  "headquartersName": "瑞典北欧斯安银行有限公司",
  "headquartersShortName": "瑞典北欧斯安",
  "headquartersNo": "RDBOSA"
}, {
  "headquartersName": "瑞典银行有限公司",
  "headquartersShortName": "瑞典",
  "headquartersNo": "RDYHYXGS"
}, {
  "headquartersName": "瑞丽沪农商村镇银行",
  "headquartersShortName": "瑞丽沪农商村镇银行",
  "headquartersNo": "RLHNSCUNZB"
}, {
  "headquartersName": "瑞士信贷银行",
  "headquartersShortName": "瑞士信贷",
  "headquartersNo": "SWISSCB"
}, {
  "headquartersName": "瑞士银行有限公司",
  "headquartersShortName": "瑞士",
  "headquartersNo": "UBS"
}, {
  "headquartersName": "瑞穗实业银行（中国）有限公司",
  "headquartersShortName": "瑞穗",
  "headquartersNo": "RSSY"
}, {
  "headquartersName": "三都富民村镇银行",
  "headquartersShortName": "三都富民村镇银行",
  "headquartersNo": "SDFMCUNZB"
}, {
  "headquartersName": "三河蒙银村镇银行",
  "headquartersShortName": "三河蒙银村镇银行",
  "headquartersNo": "SHMYCUNZB"
}, {
  "headquartersName": "三井住友银行（中国）有限公司",
  "headquartersShortName": "三井住友",
  "headquartersNo": "SMBC"
}, {
  "headquartersName": "三菱东京日联银行",
  "headquartersShortName": "三菱东京日联",
  "headquartersNo": "TOKYOMITSUBISHIBANK"
}, {
  "headquartersName": "三门峡湖滨农村商业银行",
  "headquartersShortName": "三门峡湖滨农商",
  "headquartersNo": "SMXHBRCB"
}, {
  "headquartersName": "三门峡陕州农村商业银行",
  "headquartersShortName": "三门峡陕州农商",
  "headquartersNo": "SMXSZRCB"
}, {
  "headquartersName": "三水珠江村镇银行",
  "headquartersShortName": "三水珠江村镇银行",
  "headquartersNo": "SSZJCUNZB"
}, {
  "headquartersName": "三亚惠民村镇银行",
  "headquartersShortName": "三亚惠民村镇银行",
  "headquartersNo": "SYHMCUNZB"
}, {
  "headquartersName": "三亚农村商业银行",
  "headquartersShortName": "三亚农商",
  "headquartersNo": "SYRCB"
}, {
  "headquartersName": "沙河襄通村镇银行",
  "headquartersShortName": "沙河襄通村镇银行",
  "headquartersNo": "SHXTCUNZB"
}, {
  "headquartersName": "厦门国际银行",
  "headquartersShortName": "厦门国际",
  "headquartersNo": "XIB"
}, {
  "headquartersName": "厦门农村商业银行",
  "headquartersShortName": "厦门农商",
  "headquartersNo": "XMRCB"
}, {
  "headquartersName": "厦门同安农银村镇银行",
  "headquartersShortName": "厦门同安农银村镇银行",
  "headquartersNo": "XMTANYCUNZB"
}, {
  "headquartersName": "厦门翔安民生村镇银行",
  "headquartersShortName": "厦门翔安民生村镇银行",
  "headquartersNo": "XMXAMSCUNZB"
}, {
  "headquartersName": "厦门银行",
  "headquartersShortName": "厦门",
  "headquartersNo": "XMYH"
}, {
  "headquartersName": "山东安丘农村商业银行",
  "headquartersShortName": "山东安丘农商",
  "headquartersNo": "SDAQRCB"
}, {
  "headquartersName": "山东博兴农村商业银行",
  "headquartersShortName": "山东博兴农商",
  "headquartersNo": "SDBXRCB"
}, {
  "headquartersName": "山东博兴新华村镇银行",
  "headquartersShortName": "山东博兴新华村镇银行",
  "headquartersNo": "SDBXXHCUNZB"
}, {
  "headquartersName": "山东曹县农村商业银行",
  "headquartersShortName": "山东曹县农商",
  "headquartersNo": "SDCXRCB"
}, {
  "headquartersName": "山东昌乐农村商业银行",
  "headquartersShortName": "山东昌乐农商",
  "headquartersNo": "SDCLRCB"
}, {
  "headquartersName": "山东昌邑农村商业银行",
  "headquartersShortName": "山东昌邑农商",
  "headquartersNo": "SDCYRCB"
}, {
  "headquartersName": "山东成武汉源村镇银行",
  "headquartersShortName": "山东成武汉源村镇银行",
  "headquartersNo": "SDCWHYCUNZB"
}, {
  "headquartersName": "山东成武农村商业银行",
  "headquartersShortName": "山东成武农商",
  "headquartersNo": "SDCWRCB"
}, {
  "headquartersName": "山东茌平农村商业银行",
  "headquartersShortName": "山东茌平农商",
  "headquartersNo": "SDCPRCB"
}, {
  "headquartersName": "山东单县农村商业银行",
  "headquartersShortName": "山东单县农商",
  "headquartersNo": "SDDXRCB"
}, {
  "headquartersName": "山东定陶农村商业银行",
  "headquartersShortName": "山东定陶农商",
  "headquartersNo": "SDDTRCB"
}, {
  "headquartersName": "山东东阿农村商业银行",
  "headquartersShortName": "山东东阿农商",
  "headquartersNo": "SDDARCB"
}, {
  "headquartersName": "山东东明农村商业银行",
  "headquartersShortName": "山东东明农商",
  "headquartersNo": "SDDMRCB"
}, {
  "headquartersName": "山东东平农村商业银行",
  "headquartersShortName": "山东东平农商",
  "headquartersNo": "SDDPRCB"
}, {
  "headquartersName": "山东肥城民丰村镇银行",
  "headquartersShortName": "山东肥城民丰村镇银行",
  "headquartersNo": "SDFCMFCUNZB"
}, {
  "headquartersName": "山东肥城农村商业银行",
  "headquartersShortName": "山东肥城农商",
  "headquartersNo": "SDFCRCB"
}, {
  "headquartersName": "山东费县农村商业银行",
  "headquartersShortName": "山东费县农商",
  "headquartersNo": "SDFXRCB"
}, {
  "headquartersName": "山东高密农村商业银行",
  "headquartersShortName": "山东高密农商",
  "headquartersNo": "SDGMRCB"
}, {
  "headquartersName": "山东高青汇金村镇银行",
  "headquartersShortName": "山东高青汇金村镇银行",
  "headquartersNo": "SDGQHJCUNZB"
}, {
  "headquartersName": "山东高青农村商业银行",
  "headquartersShortName": "山东高青农商",
  "headquartersNo": "SDGQRCB"
}, {
  "headquartersName": "山东高唐农村商业银行",
  "headquartersShortName": "山东高唐农商",
  "headquartersNo": "SDGTRCB"
}, {
  "headquartersName": "山东冠县齐丰村镇银行",
  "headquartersShortName": "山东冠县齐丰村镇银行",
  "headquartersNo": "SDGXQFCUNZB"
}, {
  "headquartersName": "山东广饶农村商业银行",
  "headquartersShortName": "山东广饶农商",
  "headquartersNo": "SDGRRCB"
}, {
  "headquartersName": "山东海阳农村商业银行",
  "headquartersShortName": "山东海阳农商",
  "headquartersNo": "SDHYRCB"
}, {
  "headquartersName": "山东桓台农村商业银行",
  "headquartersShortName": "山东桓台农商",
  "headquartersNo": "SDHTRCB"
}, {
  "headquartersName": "山东惠民农村商业银行",
  "headquartersShortName": "山东惠民农商",
  "headquartersNo": "SDHMRCB"
}, {
  "headquartersName": "山东惠民舜丰村镇银行",
  "headquartersShortName": "山东惠民舜丰村镇银行",
  "headquartersNo": "SDHMSFCUNZB"
}, {
  "headquartersName": "山东济宁兖州农村商业银行",
  "headquartersShortName": "山东济宁兖州农商",
  "headquartersNo": "SDJNYZRCB"
}, {
  "headquartersName": "山东济阳农村商业银行",
  "headquartersShortName": "山东济阳农商",
  "headquartersNo": "SDJIYRCB"
}, {
  "headquartersName": "山东嘉祥农村商业银行",
  "headquartersShortName": "山东嘉祥农商",
  "headquartersNo": "SDJIAXRCB"
}, {
  "headquartersName": "山东金乡蓝海村镇银行",
  "headquartersShortName": "山东金乡蓝海村镇银行",
  "headquartersNo": "SDJXLHCUNZB"
}, {
  "headquartersName": "山东金乡农村商业银行",
  "headquartersShortName": "山东金乡农商",
  "headquartersNo": "SDJXRCB"
}, {
  "headquartersName": "山东莒南村镇银行",
  "headquartersShortName": "山东莒南村镇银行",
  "headquartersNo": "SDJNCUNZB"
}, {
  "headquartersName": "山东莒南农村商业银行",
  "headquartersShortName": "山东莒南农商",
  "headquartersNo": "SDJNRCB"
}, {
  "headquartersName": "山东莒县农村商业银行",
  "headquartersShortName": "山东莒县农商",
  "headquartersNo": "SDJUXRCB"
}, {
  "headquartersName": "山东巨野农村商业银行",
  "headquartersShortName": "山东巨野农商",
  "headquartersNo": "SDJYRCB"
}, {
  "headquartersName": "山东鄄城农村商业银行",
  "headquartersShortName": "山东鄄城农商",
  "headquartersNo": "SDYCRCB"
}, {
  "headquartersName": "山东垦利农村商业银行",
  "headquartersShortName": "山东垦利农商",
  "headquartersNo": "SDKLRCB"
}, {
  "headquartersName": "山东莱山齐丰村镇银行",
  "headquartersShortName": "山东莱山齐丰村镇银行",
  "headquartersNo": "SDLSQFCUNZB"
}, {
  "headquartersName": "山东莱芜农村商业银行",
  "headquartersShortName": "山东莱芜农商",
  "headquartersNo": "SDLWRCB"
}, {
  "headquartersName": "山东莱阳农村商业银行",
  "headquartersShortName": "山东莱阳农商",
  "headquartersNo": "SDLYRCB"
}, {
  "headquartersName": "山东莱州农村商业银行",
  "headquartersShortName": "山东莱州农商",
  "headquartersNo": "SDLAIZRCB"
}, {
  "headquartersName": "山东兰陵村镇银行",
  "headquartersShortName": "山东兰陵村镇银行",
  "headquartersNo": "SDLLCUNZB"
}, {
  "headquartersName": "山东兰陵农村商业银行",
  "headquartersShortName": "山东兰陵农商",
  "headquartersNo": "SDLLRCB"
}, {
  "headquartersName": "山东乐陵农村商业银行",
  "headquartersShortName": "山东乐陵农商",
  "headquartersNo": "SDLELRCB"
}, {
  "headquartersName": "山东历城圆融村镇银行",
  "headquartersShortName": "山东历城圆融村镇银行",
  "headquartersNo": "SDLCYRCUNZB"
}, {
  "headquartersName": "山东利津农村商业银行",
  "headquartersShortName": "山东利津农商",
  "headquartersNo": "SDLJRCB"
}, {
  "headquartersName": "山东利津舜丰村镇银行",
  "headquartersShortName": "山东利津舜丰村镇银行",
  "headquartersNo": "SDLJSFCUNZB"
}, {
  "headquartersName": "山东梁山民丰村镇银行",
  "headquartersShortName": "山东梁山民丰村镇银行",
  "headquartersNo": "SDLSMFCUNZB"
}, {
  "headquartersName": "山东梁山农村商业银行",
  "headquartersShortName": "山东梁山农商",
  "headquartersNo": "SDLSRCB"
}, {
  "headquartersName": "山东聊城润昌农村商业银行",
  "headquartersShortName": "山东聊城润昌农商",
  "headquartersNo": "SDLCRCRCB"
}, {
  "headquartersName": "山东临清农村商业银行",
  "headquartersShortName": "山东临清农商",
  "headquartersNo": "SDLQRCB"
}, {
  "headquartersName": "山东临朐聚丰村镇银行",
  "headquartersShortName": "山东临朐聚丰村镇银行",
  "headquartersNo": "SDLQJFCUNZB"
}, {
  "headquartersName": "山东临朐农村商业银行",
  "headquartersShortName": "山东临朐农商",
  "headquartersNo": "SDLQURCB"
}, {
  "headquartersName": "山东临沭农村商业银行",
  "headquartersShortName": "山东临沭农商",
  "headquartersNo": "SDLINSRCB"
}, {
  "headquartersName": "山东临沂河东农村商业银行",
  "headquartersShortName": "山东临沂河东农商",
  "headquartersNo": "SDLYHDRCB"
}, {
  "headquartersName": "山东临沂兰山农村商业银行",
  "headquartersShortName": "山东临沂兰山农商",
  "headquartersNo": "SDLYLSRCB"
}, {
  "headquartersName": "山东临沂罗庄农村商业银行",
  "headquartersShortName": "山东临沂罗庄农商",
  "headquartersNo": "SDLYLZRCB"
}, {
  "headquartersName": "山东临邑农村商业银行",
  "headquartersShortName": "山东临邑农商",
  "headquartersNo": "SDLYIRCB"
}, {
  "headquartersName": "山东临淄汇金村镇银行",
  "headquartersShortName": "山东临淄汇金村镇银行",
  "headquartersNo": "SDLZHJCUNZB"
}, {
  "headquartersName": "山东临淄农村商业银行",
  "headquartersShortName": "山东临淄农商",
  "headquartersNo": "SDLZRCB"
}, {
  "headquartersName": "山东龙口农村商业银行",
  "headquartersShortName": "山东龙口农商",
  "headquartersNo": "SDLKRCB"
}, {
  "headquartersName": "山东蒙阴农村商业银行",
  "headquartersShortName": "山东蒙阴农商",
  "headquartersNo": "SDMYRCB"
}, {
  "headquartersName": "山东蒙阴齐丰村镇银行",
  "headquartersShortName": "山东蒙阴齐丰村镇银行",
  "headquartersNo": "SDMYQFCUNZB"
}, {
  "headquartersName": "山东宁津农村商业银行",
  "headquartersShortName": "山东宁津农商",
  "headquartersNo": "SDNJRCB"
}, {
  "headquartersName": "山东宁阳农村商业银行",
  "headquartersShortName": "山东宁阳农商",
  "headquartersNo": "SDNYRCB"
}, {
  "headquartersName": "山东蓬莱农村商业银行",
  "headquartersShortName": "山东蓬莱农商",
  "headquartersNo": "SDPLRCB"
}, {
  "headquartersName": "山东平邑汉源村镇银行",
  "headquartersShortName": "山东平邑汉源村镇银行",
  "headquartersNo": "SDPYHYCUNZB"
}, {
  "headquartersName": "山东平邑农村商业银行",
  "headquartersShortName": "山东平邑农商",
  "headquartersNo": "SDPYIRCB"
}, {
  "headquartersName": "山东平阴农村商业银行",
  "headquartersShortName": "山东平阴农商",
  "headquartersNo": "SDPYINRCB"
}, {
  "headquartersName": "山东平原农村商业银行",
  "headquartersShortName": "山东平原农商",
  "headquartersNo": "SDPYRCB"
}, {
  "headquartersName": "山东栖霞农村商业银行",
  "headquartersShortName": "山东栖霞农商",
  "headquartersNo": "SDQXRCB"
}, {
  "headquartersName": "山东齐河农村商业银行",
  "headquartersShortName": "山东齐河农商",
  "headquartersNo": "SDQHRCB"
}, {
  "headquartersName": "山东青州农村商业银行",
  "headquartersShortName": "山东青州农商",
  "headquartersNo": "SDQZRCB"
}, {
  "headquartersName": "山东庆云农村商业银行",
  "headquartersShortName": "山东庆云农商",
  "headquartersNo": "SDQYRCB"
}, {
  "headquartersName": "山东曲阜农村商业银行",
  "headquartersShortName": "山东曲阜农商",
  "headquartersNo": "SDQFRCB"
}, {
  "headquartersName": "山东荣成汇丰村镇银行",
  "headquartersShortName": "山东荣成汇丰村镇银行",
  "headquartersNo": "SDRCHFCUNZB"
}, {
  "headquartersName": "山东荣成农村商业银行",
  "headquartersShortName": "山东荣成农商",
  "headquartersNo": "SDRCRCB"
}, {
  "headquartersName": "山东乳山农村商业银行",
  "headquartersShortName": "山东乳山农商",
  "headquartersNo": "SDRSRCB"
}, {
  "headquartersName": "山东商河汇金村镇银行",
  "headquartersShortName": "山东商河汇金村镇银行",
  "headquartersNo": "SDSHHJCUNZB"
}, {
  "headquartersName": "山东商河农村商业银行",
  "headquartersShortName": "山东商河农商",
  "headquartersNo": "SDSHRCB"
}, {
  "headquartersName": "山东莘县农村商业银行",
  "headquartersShortName": "山东莘县农商",
  "headquartersNo": "SDSXRCB"
}, {
  "headquartersName": "山东省农村信用社联合社",
  "headquartersShortName": "山东农信社",
  "headquartersNo": "SDNXS"
}, {
  "headquartersName": "山东寿光农村商业银行",
  "headquartersShortName": "山东寿光农商",
  "headquartersNo": "SDSGRCB"
}, {
  "headquartersName": "山东泗水农村商业银行",
  "headquartersShortName": "山东泗水农商",
  "headquartersNo": "SDSSRCB"
}, {
  "headquartersName": "山东泗水齐丰村镇银行",
  "headquartersShortName": "山东泗水齐丰村镇银行",
  "headquartersNo": "SDSSQFCUNZB"
}, {
  "headquartersName": "山东郯城汉源村镇银行",
  "headquartersShortName": "山东郯城汉源村镇银行",
  "headquartersNo": "SDZCHYCUNZB"
}, {
  "headquartersName": "山东郯城农村商业银行",
  "headquartersShortName": "山东郯城农商",
  "headquartersNo": "SDTCRCB"
}, {
  "headquartersName": "山东滕州农村商业银行",
  "headquartersShortName": "山东滕州农商",
  "headquartersNo": "SDTZRCB"
}, {
  "headquartersName": "山东威海农村商业银行",
  "headquartersShortName": "山东威海农商",
  "headquartersNo": "SDWHRCB"
}, {
  "headquartersName": "山东微山农村商业银行",
  "headquartersShortName": "山东微山农商",
  "headquartersNo": "SDWEISRCB"
}, {
  "headquartersName": "山东文登农村商业银行",
  "headquartersShortName": "山东文登农商",
  "headquartersNo": "SDWDRCB"
}, {
  "headquartersName": "山东汶上农村商业银行",
  "headquartersShortName": "山东汶上农商",
  "headquartersNo": "SDWSRCB"
}, {
  "headquartersName": "山东无棣农村商业银行",
  "headquartersShortName": "山东无棣农商",
  "headquartersNo": "SDWLIRCB"
}, {
  "headquartersName": "山东五莲农村商业银行",
  "headquartersShortName": "山东五莲农商",
  "headquartersNo": "SDWLRCB"
}, {
  "headquartersName": "山东武城农村商业银行",
  "headquartersShortName": "山东武城农商",
  "headquartersNo": "SDWCRCB"
}, {
  "headquartersName": "山东夏津农村商业银行",
  "headquartersShortName": "山东夏津农商",
  "headquartersNo": "SDXJRCB"
}, {
  "headquartersName": "山东新泰农村商业银行",
  "headquartersShortName": "山东新泰农商",
  "headquartersNo": "SDXTRCB"
}, {
  "headquartersName": "山东新泰齐丰村镇银行",
  "headquartersShortName": "山东新泰齐丰村镇银行",
  "headquartersNo": "SDXTQFCUNZB"
}, {
  "headquartersName": "山东阳谷农村商业银行",
  "headquartersShortName": "山东阳谷农商",
  "headquartersNo": "SDYGRCB"
}, {
  "headquartersName": "山东阳信农村商业银行",
  "headquartersShortName": "山东阳信农商",
  "headquartersNo": "SDYXRCB"
}, {
  "headquartersName": "山东沂南蓝海村镇银行",
  "headquartersShortName": "山东沂南蓝海村镇银行",
  "headquartersNo": "SDYNLHCUNZB"
}, {
  "headquartersName": "山东沂南农村商业银行",
  "headquartersShortName": "山东沂南农商",
  "headquartersNo": "SDYNRCB"
}, {
  "headquartersName": "山东沂水农村商业银行",
  "headquartersShortName": "山东沂水农商",
  "headquartersNo": "SDYSRCB"
}, {
  "headquartersName": "山东沂源农村商业银行",
  "headquartersShortName": "山东沂源农商",
  "headquartersNo": "SDYYRCB"
}, {
  "headquartersName": "山东鱼台农村商业银行",
  "headquartersShortName": "山东鱼台农商",
  "headquartersNo": "SDYTRCB"
}, {
  "headquartersName": "山东禹城农村商业银行",
  "headquartersShortName": "山东禹城农商",
  "headquartersNo": "SDYUCRCB"
}, {
  "headquartersName": "山东郓城农村商业银行",
  "headquartersShortName": "山东郓城农商",
  "headquartersNo": "SDYUNCRCB"
}, {
  "headquartersName": "山东沾化青云村镇银行",
  "headquartersShortName": "山东沾化青云村镇银行",
  "headquartersNo": "SDZHQYCUNZB"
}, {
  "headquartersName": "山东张店农村商业银行",
  "headquartersShortName": "山东张店农商",
  "headquartersNo": "SDZDRCB"
}, {
  "headquartersName": "山东章丘农村商业银行",
  "headquartersShortName": "山东章丘农商",
  "headquartersNo": "SDZQRCB"
}, {
  "headquartersName": "山东长岛农村商业银行",
  "headquartersShortName": "山东长岛农商",
  "headquartersNo": "SDCDRCB"
}, {
  "headquartersName": "山东招远农村商业银行",
  "headquartersShortName": "山东招远农商",
  "headquartersNo": "SDZYRCB"
}, {
  "headquartersName": "山东芝罘齐丰村镇银行",
  "headquartersShortName": "山东芝罘齐丰村镇银行",
  "headquartersNo": "SDZZQFCUNZB"
}, {
  "headquartersName": "山东周村农村商业银行",
  "headquartersShortName": "山东周村农商",
  "headquartersNo": "SDZCRCB"
}, {
  "headquartersName": "山东周村青隆村镇银行",
  "headquartersShortName": "山东周村青隆村镇银行",
  "headquartersNo": "SDZCQLCUNZB"
}, {
  "headquartersName": "山东诸城农村商业银行",
  "headquartersShortName": "山东诸城农商",
  "headquartersNo": "SDZHUCRCB"
}, {
  "headquartersName": "山东邹城农村商业银行",
  "headquartersShortName": "山东邹城农商",
  "headquartersNo": "SDZOUCRCB"
}, {
  "headquartersName": "山东邹平农村商业银行",
  "headquartersShortName": "山东邹平农商",
  "headquartersNo": "SDZPRCB"
}, {
  "headquartersName": "山东邹平青隆村镇银行",
  "headquartersShortName": "山东邹平青隆村镇银行",
  "headquartersNo": "SDZPQLCUNZB"
}, {
  "headquartersName": "山西安泽农村商业银行",
  "headquartersShortName": "山西安泽农商",
  "headquartersNo": "SXAZRCB"
}, {
  "headquartersName": "山西保德农村商业银行",
  "headquartersShortName": "山西保德农商",
  "headquartersNo": "SXBDRCB"
}, {
  "headquartersName": "山西大宁农村商业银行",
  "headquartersShortName": "山西大宁农商",
  "headquartersNo": "SXDNRCB"
}, {
  "headquartersName": "山西定襄农村商业银行",
  "headquartersShortName": "山西定襄农商",
  "headquartersNo": "SXDXRCB"
}, {
  "headquartersName": "山西繁峙农村商业银行",
  "headquartersShortName": "山西繁峙农商",
  "headquartersNo": "SXFZRCB"
}, {
  "headquartersName": "山西汾阳农村商业银行",
  "headquartersShortName": "山西汾阳农商",
  "headquartersNo": "SXFYRCB"
}, {
  "headquartersName": "山西浮山农村商业银行",
  "headquartersShortName": "山西浮山农商",
  "headquartersNo": "SXFSRCB"
}, {
  "headquartersName": "山西高平农村商业银行",
  "headquartersShortName": "山西高平农商",
  "headquartersNo": "SXGPRCB"
}, {
  "headquartersName": "山西古交农村商业银行",
  "headquartersShortName": "山西古交农商",
  "headquartersNo": "SXGJRCB"
}, {
  "headquartersName": "山西古县农村商业银行",
  "headquartersShortName": "山西古县农商",
  "headquartersNo": "SXGXRCB"
}, {
  "headquartersName": "山西和顺农村商业银行",
  "headquartersShortName": "山西和顺农商",
  "headquartersNo": "SXHESRCB"
}, {
  "headquartersName": "山西河津农村商业银行",
  "headquartersShortName": "山西河津农商",
  "headquartersNo": "SXHJRCB"
}, {
  "headquartersName": "山西河曲农村商业银行",
  "headquartersShortName": "山西河曲农商",
  "headquartersNo": "SXHQRCB"
}, {
  "headquartersName": "山西洪洞农村商业银行",
  "headquartersShortName": "山西洪洞农商",
  "headquartersNo": "SXHDRCB"
}, {
  "headquartersName": "山西侯马农村商业银行",
  "headquartersShortName": "山西侯马农商",
  "headquartersNo": "SXHMRCB"
}, {
  "headquartersName": "山西壶关农村商业银行",
  "headquartersShortName": "山西壶关农商",
  "headquartersNo": "SXHGRCB"
}, {
  "headquartersName": "山西怀仁农村商业银行",
  "headquartersShortName": "山西怀仁农商",
  "headquartersNo": "SXHRRCB"
}, {
  "headquartersName": "山西浑源农村商业银行",
  "headquartersShortName": "山西浑源农商",
  "headquartersNo": "SHXHYRCB"
}, {
  "headquartersName": "山西霍州农村商业银行",
  "headquartersShortName": "山西霍州农商",
  "headquartersNo": "SXHZRCB"
}, {
  "headquartersName": "山西吉县农村商业银行",
  "headquartersShortName": "山西吉县农商",
  "headquartersNo": "SXJIXRCB"
}, {
  "headquartersName": "山西稷山农村商业银行",
  "headquartersShortName": "山西稷山农商",
  "headquartersNo": "SXSSRCB"
}, {
  "headquartersName": "山西绛县农村商业银行",
  "headquartersShortName": "山西绛县农商",
  "headquartersNo": "SXJXRCB"
}, {
  "headquartersName": "山西交城农村商业银行",
  "headquartersShortName": "山西交城农商",
  "headquartersNo": "SXJCRCB"
}, {
  "headquartersName": "山西介休农村商业银行",
  "headquartersShortName": "山西介休农商",
  "headquartersNo": "SXJIEXRCB"
}, {
  "headquartersName": "山西静乐农村商业银行",
  "headquartersShortName": "山西静乐农商",
  "headquartersNo": "SXJLRCB"
}, {
  "headquartersName": "山西岢岚农村商业银行",
  "headquartersShortName": "山西岢岚农商",
  "headquartersNo": "SXKLRCB"
}, {
  "headquartersName": "山西岚县农村商业银行",
  "headquartersShortName": "山西岚县农商",
  "headquartersNo": "SXLANXRCB"
}, {
  "headquartersName": "山西黎城农村商业银行",
  "headquartersShortName": "山西黎城农商",
  "headquartersNo": "SXLICRCB"
}, {
  "headquartersName": "山西临猗农村商业银行",
  "headquartersShortName": "山西临猗农商",
  "headquartersNo": "SXLYRCB"
}, {
  "headquartersName": "山西灵丘农村商业银行",
  "headquartersShortName": "山西灵丘农商",
  "headquartersNo": "SXLQRCB"
}, {
  "headquartersName": "山西灵石农村商业银行",
  "headquartersShortName": "山西灵石农商",
  "headquartersNo": "SXLSRCB"
}, {
  "headquartersName": "山西柳林农村商业银行",
  "headquartersShortName": "山西柳林农商",
  "headquartersNo": "SXLLRCB"
}, {
  "headquartersName": "山西潞城农村商业银行",
  "headquartersShortName": "山西潞城农商",
  "headquartersNo": "SXLCRCB"
}, {
  "headquartersName": "山西吕梁农村商业银行",
  "headquartersShortName": "山西吕梁农商",
  "headquartersNo": "SXLVLRCB"
}, {
  "headquartersName": "山西宁武农村商业银行",
  "headquartersShortName": "山西宁武农商",
  "headquartersNo": "SXNWRCB"
}, {
  "headquartersName": "山西偏关农村商业银行",
  "headquartersShortName": "山西偏关农商",
  "headquartersNo": "SXPGRCB"
}, {
  "headquartersName": "山西平定农村商业银行",
  "headquartersShortName": "山西平定农商",
  "headquartersNo": "SXPDRCB"
}, {
  "headquartersName": "山西平陆农村商业银行",
  "headquartersShortName": "山西平陆农商",
  "headquartersNo": "SXPLRCB"
}, {
  "headquartersName": "山西平顺农村商业银行",
  "headquartersShortName": "山西平顺农商",
  "headquartersNo": "SXPSRCB"
}, {
  "headquartersName": "山西平遥农村商业银行",
  "headquartersShortName": "山西平遥农商",
  "headquartersNo": "SXPYRCB"
}, {
  "headquartersName": "山西蒲县农村商业银行",
  "headquartersShortName": "山西蒲县农商",
  "headquartersNo": "SXPXRCB"
}, {
  "headquartersName": "山西祁县农村商业银行",
  "headquartersShortName": "山西祁县农商",
  "headquartersNo": "SXQIXRCB"
}, {
  "headquartersName": "山西沁水农村商业银行",
  "headquartersShortName": "山西沁水农商",
  "headquartersNo": "SXQSRCB"
}, {
  "headquartersName": "山西沁县农村商业银行",
  "headquartersShortName": "山西沁县农商",
  "headquartersNo": "SXQINXRCB"
}, {
  "headquartersName": "山西沁源农村商业银行",
  "headquartersShortName": "山西沁源农商",
  "headquartersNo": "SXQINYRCB"
}, {
  "headquartersName": "山西清徐农村商业银行",
  "headquartersShortName": "山西清徐农商",
  "headquartersNo": "SXQXRCB"
}, {
  "headquartersName": "山西曲沃农村商业银行",
  "headquartersShortName": "山西曲沃农商",
  "headquartersNo": "SXQWRCB"
}, {
  "headquartersName": "山西芮城农村商业银行",
  "headquartersShortName": "山西芮城农商",
  "headquartersNo": "SXRCRCB"
}, {
  "headquartersName": "山西山阴农村商业银行",
  "headquartersShortName": "山西山阴农商",
  "headquartersNo": "SXSYIRCB"
}, {
  "headquartersName": "山西神池农村商业银行",
  "headquartersShortName": "山西神池农商",
  "headquartersNo": "SXSCRCB"
}, {
  "headquartersName": "山西省农村信用社联合社",
  "headquartersShortName": "山西农信社",
  "headquartersNo": "SHXNXS"
}, {
  "headquartersName": "山西寿阳农村商业银行",
  "headquartersShortName": "山西寿阳农商",
  "headquartersNo": "SXSYRCB"
}, {
  "headquartersName": "山西太谷农村商业银行",
  "headquartersShortName": "山西太谷农商",
  "headquartersNo": "SXTGRCB"
}, {
  "headquartersName": "山西屯留农村商业银行",
  "headquartersShortName": "山西屯留农商",
  "headquartersNo": "SXTLRCB"
}, {
  "headquartersName": "山西万荣农村商业银行",
  "headquartersShortName": "山西万荣农商",
  "headquartersNo": "SXWRRCB"
}, {
  "headquartersName": "山西闻喜农村商业银行",
  "headquartersShortName": "山西闻喜农商",
  "headquartersNo": "SXWXRCB"
}, {
  "headquartersName": "山西五台农村商业银行",
  "headquartersShortName": "山西五台农商",
  "headquartersNo": "SXWTRCB"
}, {
  "headquartersName": "山西五寨农村商业银行",
  "headquartersShortName": "山西五寨农商",
  "headquartersNo": "SXWZRCB"
}, {
  "headquartersName": "山西武乡农村商业银行",
  "headquartersShortName": "山西武乡农商",
  "headquartersNo": "SXWUXRCB"
}, {
  "headquartersName": "山西昔阳农村商业银行",
  "headquartersShortName": "山西昔阳农商",
  "headquartersNo": "SXXIYRCB"
}, {
  "headquartersName": "山西隰县农村商业银行",
  "headquartersShortName": "山西隰县农商",
  "headquartersNo": "SXXIXRCB"
}, {
  "headquartersName": "山西夏县农村商业银行",
  "headquartersShortName": "山西夏县农商",
  "headquartersNo": "SXXXRCB"
}, {
  "headquartersName": "山西乡宁农村商业银行",
  "headquartersShortName": "山西乡宁农商",
  "headquartersNo": "SXXNRCB"
}, {
  "headquartersName": "山西襄汾农村商业银行",
  "headquartersShortName": "山西襄汾农商",
  "headquartersNo": "SXXFRCB"
}, {
  "headquartersName": "山西襄垣农村商业银行",
  "headquartersShortName": "山西襄垣农商",
  "headquartersNo": "SXXYRCB"
}, {
  "headquartersName": "山西孝义农村商业银行",
  "headquartersShortName": "山西孝义农商",
  "headquartersNo": "SXXYIRCB"
}, {
  "headquartersName": "山西忻州农村商业银行",
  "headquartersShortName": "山西忻州农商",
  "headquartersNo": "SXXZRCB"
}, {
  "headquartersName": "山西新绛农村商业银行",
  "headquartersShortName": "山西新绛农商",
  "headquartersNo": "SXXJRCB"
}, {
  "headquartersName": "山西兴县农村商业银行",
  "headquartersShortName": "山西兴县农商",
  "headquartersNo": "SXXINGXRCB"
}, {
  "headquartersName": "山西阳城农村商业银行",
  "headquartersShortName": "山西阳城农商",
  "headquartersNo": "SXYCRCB"
}, {
  "headquartersName": "山西阳曲农村商业银行",
  "headquartersShortName": "山西阳曲农商",
  "headquartersNo": "SXYQRCB"
}, {
  "headquartersName": "山西尧都农村商业银行",
  "headquartersShortName": "山西尧都农商",
  "headquartersNo": "SXYDRCB"
}, {
  "headquartersName": "山西应县农村商业银行",
  "headquartersShortName": "山西应县农商",
  "headquartersNo": "SXYIXRCB"
}, {
  "headquartersName": "山西永和农村商业银行",
  "headquartersShortName": "山西永和农商",
  "headquartersNo": "SXYHRCB"
}, {
  "headquartersName": "山西永济农村商业银行",
  "headquartersShortName": "山西永济农商",
  "headquartersNo": "SXYJRCB"
}, {
  "headquartersName": "山西右玉农村商业银行",
  "headquartersShortName": "山西右玉农商",
  "headquartersNo": "SXYYRCB"
}, {
  "headquartersName": "山西盂县农村商业银行",
  "headquartersShortName": "山西盂县农商",
  "headquartersNo": "SXYXRCB"
}, {
  "headquartersName": "山西榆次农村商业银行",
  "headquartersShortName": "山西榆次农商",
  "headquartersNo": "SXYUCRCB"
}, {
  "headquartersName": "山西榆社农村商业银行",
  "headquartersShortName": "山西榆社农商",
  "headquartersNo": "SXYSRCB"
}, {
  "headquartersName": "山西垣曲农村商业银行",
  "headquartersShortName": "山西垣曲农商",
  "headquartersNo": "SXYQURCB"
}, {
  "headquartersName": "山西原平农村商业银行",
  "headquartersShortName": "山西原平农商",
  "headquartersNo": "SXYPRCB"
}, {
  "headquartersName": "山西云州农村商业银行",
  "headquartersShortName": "山西云州农商",
  "headquartersNo": "SXYZRCB"
}, {
  "headquartersName": "山西运城农村商业银行",
  "headquartersShortName": "山西运城农商",
  "headquartersNo": "SXYUNCRCB"
}, {
  "headquartersName": "山西泽州农村商业银行",
  "headquartersShortName": "山西泽州农商",
  "headquartersNo": "SXZZRCB"
}, {
  "headquartersName": "山西长治黎都农村商业银行",
  "headquartersShortName": "山西长治黎都农商",
  "headquartersNo": "SXCZLDRCB"
}, {
  "headquartersName": "山西长子农村商业银行",
  "headquartersShortName": "山西长子农商",
  "headquartersNo": "SXCZRCB"
}, {
  "headquartersName": "山西左权农村商业银行",
  "headquartersShortName": "山西左权农商",
  "headquartersNo": "SXZQRCB"
}, {
  "headquartersName": "山西左云农村商业银行",
  "headquartersShortName": "山西左云农商",
  "headquartersNo": "SXZUOYRCB"
}, {
  "headquartersName": "山阴县太行村镇银行",
  "headquartersShortName": "山阴县太行村镇银行",
  "headquartersNo": "SYXTXCUNZB"
}, {
  "headquartersName": "陕西安塞农村商业银行",
  "headquartersShortName": "陕西安塞农商",
  "headquartersNo": "SXASRCB"
}, {
  "headquartersName": "陕西白河农村商业银行",
  "headquartersShortName": "陕西白河农商",
  "headquartersNo": "SXBHRCB"
}, {
  "headquartersName": "陕西宝鸡金台农村商业银行",
  "headquartersShortName": "陕西宝鸡金台农商",
  "headquartersNo": "SXBJJTRCB"
}, {
  "headquartersName": "陕西宝鸡渭滨农村商业银行",
  "headquartersShortName": "陕西宝鸡渭滨农商",
  "headquartersNo": "SXBJWBRCB"
}, {
  "headquartersName": "陕西彬县农村商业银行",
  "headquartersShortName": "陕西彬县农商",
  "headquartersNo": "SXBXRCB"
}, {
  "headquartersName": "陕西彬州农村商业银行",
  "headquartersShortName": "陕西彬州农商",
  "headquartersNo": "SXBZRCB"
}, {
  "headquartersName": "陕西丹凤农村商业银行",
  "headquartersShortName": "陕西丹凤农商",
  "headquartersNo": "SXDFRCB"
}, {
  "headquartersName": "陕西定边农村商业银行",
  "headquartersShortName": "陕西定边农商",
  "headquartersNo": "SXDBRCB"
}, {
  "headquartersName": "陕西凤县农村商业银行",
  "headquartersShortName": "陕西凤县农商",
  "headquartersNo": "SXFXRCB"
}, {
  "headquartersName": "陕西凤翔农村商业银行",
  "headquartersShortName": "陕西凤翔农商",
  "headquartersNo": "SXFXIANGRCB"
}, {
  "headquartersName": "陕西佛坪农村商业银行",
  "headquartersShortName": "陕西佛坪农商",
  "headquartersNo": "SXFPRCB"
}, {
  "headquartersName": "陕西府谷农村商业银行",
  "headquartersShortName": "陕西府谷农商",
  "headquartersNo": "SXFGRCB"
}, {
  "headquartersName": "陕西富平汇发村镇银行",
  "headquartersShortName": "陕西富平汇发村镇银行",
  "headquartersNo": "SXFPHFCUNZB"
}, {
  "headquartersName": "陕西富县农村合作银行",
  "headquartersShortName": "陕西富县农村合作银行",
  "headquartersNo": "SXFXRCU"
}, {
  "headquartersName": "陕西甘泉农村商业银行",
  "headquartersShortName": "陕西甘泉农商",
  "headquartersNo": "SXGQRCB"
}, {
  "headquartersName": "陕西韩城农村商业银行",
  "headquartersShortName": "陕西韩城农商",
  "headquartersNo": "SXHCRCB"
}, {
  "headquartersName": "陕西汉阴农村商业银行",
  "headquartersShortName": "陕西汉阴农商",
  "headquartersNo": "SXHYRCB"
}, {
  "headquartersName": "陕西汉中南郑汇发村镇银行",
  "headquartersShortName": "陕西汉中南郑汇发村镇银行",
  "headquartersNo": "SXHZNZHFCUNZB"
}, {
  "headquartersName": "陕西户县海丝村镇银行",
  "headquartersShortName": "陕西户县海丝村镇银行",
  "headquartersNo": "SXHXHSCUNZB"
}, {
  "headquartersName": "陕西佳县农村商业银行",
  "headquartersShortName": "陕西佳县农商",
  "headquartersNo": "SXIJXRCB"
}, {
  "headquartersName": "陕西泾阳泰隆村镇银行",
  "headquartersShortName": "陕西泾阳泰隆村镇银行",
  "headquartersNo": "SXJYTLCUNZB"
}, {
  "headquartersName": "陕西靖边农村商业银行",
  "headquartersShortName": "陕西靖边农商",
  "headquartersNo": "SXJBRCB"
}, {
  "headquartersName": "陕西岚皋农村商业银行",
  "headquartersShortName": "陕西岚皋农商",
  "headquartersNo": "SXLGRCB"
}, {
  "headquartersName": "陕西蓝田农村商业银行",
  "headquartersShortName": "陕西蓝田农商",
  "headquartersNo": "SXLTRCB"
}, {
  "headquartersName": "陕西临潼海丝村镇银行",
  "headquartersShortName": "陕西临潼海丝村镇银行",
  "headquartersNo": "SXLZHSCUNZB"
}, {
  "headquartersName": "陕西麟游农村商业银行",
  "headquartersShortName": "陕西麟游农商",
  "headquartersNo": "SXILYRCB"
}, {
  "headquartersName": "陕西陇县农村商业银行",
  "headquartersShortName": "陕西陇县农商",
  "headquartersNo": "SXLXRCB"
}, {
  "headquartersName": "陕西陇县长银村镇银行",
  "headquartersShortName": "陕西陇县长银村镇银行",
  "headquartersNo": "SXLXCYCUNZB"
}, {
  "headquartersName": "陕西洛川农村商业银行",
  "headquartersShortName": "陕西洛川农商",
  "headquartersNo": "SXILCRCB"
}, {
  "headquartersName": "陕西洛南阳光村镇银行",
  "headquartersShortName": "陕西洛南阳光村镇银行",
  "headquartersNo": "SXLNYGCUNZB"
}, {
  "headquartersName": "陕西眉县农村商业银行",
  "headquartersShortName": "陕西眉县农商",
  "headquartersNo": "SXMXRCB"
}, {
  "headquartersName": "陕西眉县泰隆村镇银行",
  "headquartersShortName": "陕西眉县泰隆村镇银行",
  "headquartersNo": "SXMXTLCUNZB"
}, {
  "headquartersName": "陕西米脂农村商业银行",
  "headquartersShortName": "陕西米脂农商",
  "headquartersNo": "SXMZRCB"
}, {
  "headquartersName": "陕西南郑农村商业银行",
  "headquartersShortName": "陕西南郑农商",
  "headquartersNo": "SXNZRCB"
}, {
  "headquartersName": "陕西宁陕农村商业银行",
  "headquartersShortName": "陕西宁陕农商",
  "headquartersNo": "SXNSRCB"
}, {
  "headquartersName": "陕西平利农村商业银行",
  "headquartersShortName": "陕西平利农商",
  "headquartersNo": "SXIPLRCB"
}, {
  "headquartersName": "陕西岐山农村商业银行",
  "headquartersShortName": "陕西岐山农商",
  "headquartersNo": "SXQISRCB"
}, {
  "headquartersName": "陕西岐山长银村镇银行",
  "headquartersShortName": "陕西岐山长银村镇银行",
  "headquartersNo": "SXQSCYCUNZB"
}, {
  "headquartersName": "陕西千阳农村商业银行",
  "headquartersShortName": "陕西千阳农商",
  "headquartersNo": "SXQYRCB"
}, {
  "headquartersName": "陕西秦农农村商业银行",
  "headquartersShortName": "陕西秦农农商",
  "headquartersNo": "SXQNRCB"
}, {
  "headquartersName": "陕西清涧农村商业银行",
  "headquartersShortName": "陕西清涧农商",
  "headquartersNo": "SXQJRCB"
}, {
  "headquartersName": "陕西三原海丝村镇银行",
  "headquartersShortName": "陕西三原海丝村镇银行",
  "headquartersNo": "SXSYHSCUNZB"
}, {
  "headquartersName": "陕西山阳农村商业银行",
  "headquartersShortName": "陕西山阳农商",
  "headquartersNo": "SXISYRCB"
}, {
  "headquartersName": "陕西商南聚利村镇银行",
  "headquartersShortName": "陕西商南聚利村镇银行",
  "headquartersNo": "SXSNJLCUNZB"
}, {
  "headquartersName": "陕西商南农村商业银行",
  "headquartersShortName": "陕西商南农商",
  "headquartersNo": "SXSNRCB"
}, {
  "headquartersName": "陕西神木农村商业银行",
  "headquartersShortName": "陕西神木农商",
  "headquartersNo": "SXSMRCB"
}, {
  "headquartersName": "陕西省农村信用社联合社",
  "headquartersShortName": "陕西农信社",
  "headquartersNo": "SXNXS"
}, {
  "headquartersName": "陕西石泉农村商业银行",
  "headquartersShortName": "陕西石泉农商",
  "headquartersNo": "SXSQRCB"
}, {
  "headquartersName": "陕西绥德农村商业银行",
  "headquartersShortName": "陕西绥德农商",
  "headquartersNo": "SXSDRCB"
}, {
  "headquartersName": "陕西太白农村商业银行",
  "headquartersShortName": "陕西太白农商",
  "headquartersNo": "SXTBRCB"
}, {
  "headquartersName": "陕西太白长银村镇银行",
  "headquartersShortName": "陕西太白长银村镇银行",
  "headquartersNo": "SXTBCYCUNZB"
}, {
  "headquartersName": "陕西吴堡农村商业银行",
  "headquartersShortName": "陕西吴堡农商",
  "headquartersNo": "SXWBRCB"
}, {
  "headquartersName": "陕西吴起汇发村镇银行",
  "headquartersShortName": "陕西吴起汇发村镇银行",
  "headquartersNo": "SXWQHFCUNZB"
}, {
  "headquartersName": "陕西吴起农村合作银行",
  "headquartersShortName": "陕西吴起农村合作银行",
  "headquartersNo": "SXWQRCU"
}, {
  "headquartersName": "陕西西乡农村商业银行",
  "headquartersShortName": "陕西西乡农商",
  "headquartersNo": "SXIXXRCB"
}, {
  "headquartersName": "陕西咸阳秦都农村商业银行",
  "headquartersShortName": "陕西咸阳秦都农商",
  "headquartersNo": "SXXYQDRCB"
}, {
  "headquartersName": "陕西咸阳渭城汇发村镇银行",
  "headquartersShortName": "陕西咸阳渭城汇发村镇银行",
  "headquartersNo": "SXXYWCHFCUNZB"
}, {
  "headquartersName": "陕西咸阳渭城农村商业银行",
  "headquartersShortName": "陕西咸阳渭城农商",
  "headquartersNo": "SXXYWCRCB"
}, {
  "headquartersName": "陕西旬阳农村商业银行",
  "headquartersShortName": "陕西旬阳农商",
  "headquartersNo": "SXIXYRCB"
}, {
  "headquartersName": "陕西旬阳泰隆村镇银行",
  "headquartersShortName": "陕西旬阳泰隆村镇银行",
  "headquartersNo": "SXXYTLCUNZB"
}, {
  "headquartersName": "陕西杨凌农村商业银行",
  "headquartersShortName": "陕西杨凌农商",
  "headquartersNo": "SXYLRCB"
}, {
  "headquartersName": "陕西榆林横山汇发村镇银行",
  "headquartersShortName": "陕西榆林横山汇发村镇银行",
  "headquartersNo": "SXYLHSHFCUNZB"
}, {
  "headquartersName": "陕西榆林农村商业银行",
  "headquartersShortName": "陕西榆林农商",
  "headquartersNo": "SXYLYYRCB"
}, {
  "headquartersName": "陕西柞水聚利村镇银行",
  "headquartersShortName": "陕西柞水聚利村镇银行",
  "headquartersNo": "SXZSJLCUNZB"
}, {
  "headquartersName": "陕西柞水农村商业银行",
  "headquartersShortName": "陕西柞水农商",
  "headquartersNo": "SXZSRCB"
}, {
  "headquartersName": "陕西长武农村商业银行",
  "headquartersShortName": "陕西长武农商",
  "headquartersNo": "SXCWRCB"
}, {
  "headquartersName": "陕西镇安农村商业银行",
  "headquartersShortName": "陕西镇安农商",
  "headquartersNo": "SXZARCB"
}, {
  "headquartersName": "陕西镇巴农村商业银行",
  "headquartersShortName": "陕西镇巴农商",
  "headquartersNo": "SXZBRCB"
}, {
  "headquartersName": "陕西镇坪农村商业银行",
  "headquartersShortName": "陕西镇坪农商",
  "headquartersNo": "SXZPRCB"
}, {
  "headquartersName": "陕西周至农村商业银行",
  "headquartersShortName": "陕西周至农商",
  "headquartersNo": "SXZZHRCB"
}, {
  "headquartersName": "陕西周至农科村镇银行",
  "headquartersShortName": "陕西周至农科村镇银行",
  "headquartersNo": "SXZZNKCUNZB"
}, {
  "headquartersName": "陕西子长农村商业银行",
  "headquartersShortName": "陕西子长农商",
  "headquartersNo": "SXZCRCB"
}, {
  "headquartersName": "陕西子洲农村商业银行",
  "headquartersShortName": "陕西子洲农商",
  "headquartersNo": "SXIZZRCB"
}, {
  "headquartersName": "陕西紫阳农村商业银行",
  "headquartersShortName": "陕西紫阳农商",
  "headquartersNo": "SXZYRCB"
}, {
  "headquartersName": "汕头潮阳融和村镇银行",
  "headquartersShortName": "汕头潮阳融和村镇银行",
  "headquartersNo": "STCYRHCUNZB"
}, {
  "headquartersName": "汕头海湾农村商业银行",
  "headquartersShortName": "汕头海湾农商",
  "headquartersNo": "STHWRCB"
}, {
  "headquartersName": "汕头龙湖长江村镇银行",
  "headquartersShortName": "汕头龙湖长江村镇银行",
  "headquartersNo": "STLHCJCUNZB"
}, {
  "headquartersName": "汕尾农村商业银行",
  "headquartersShortName": "汕尾农商",
  "headquartersNo": "SWRCB"
}, {
  "headquartersName": "鄯善中成村镇银行",
  "headquartersShortName": "鄯善中成村镇银行",
  "headquartersNo": "ZSZCCUNZB"
}, {
  "headquartersName": "商丘华商农村商业银行",
  "headquartersShortName": "商丘华商农商",
  "headquartersNo": "SQHSRCB"
}, {
  "headquartersName": "上蔡惠民村镇银行",
  "headquartersShortName": "上蔡惠民村镇银行",
  "headquartersNo": "SCAIHMCUNZB"
}, {
  "headquartersName": "上海宝山富民村镇银行",
  "headquartersShortName": "上海宝山富民村镇银行",
  "headquartersNo": "SHBSFMCUNZB"
}, {
  "headquartersName": "上海宝山扬子村镇银行",
  "headquartersShortName": "上海宝山扬子村镇银行",
  "headquartersNo": "SHBSYZCUNZB"
}, {
  "headquartersName": "上海崇明沪农商村镇银行",
  "headquartersShortName": "上海崇明沪农商村镇银行",
  "headquartersNo": "SHCMHNSCUNZB"
}, {
  "headquartersName": "上海奉贤浦发村镇银行",
  "headquartersShortName": "上海奉贤浦发村镇银行",
  "headquartersNo": "SHFXPFCUNZB"
}, {
  "headquartersName": "上海华瑞银行股份有限公司",
  "headquartersShortName": "上海华瑞",
  "headquartersNo": "SHRB"
}, {
  "headquartersName": "上海嘉定洪都村镇银行",
  "headquartersShortName": "上海嘉定洪都村镇银行",
  "headquartersNo": "SHJDHDCUNZB"
}, {
  "headquartersName": "上海嘉定民生村镇银行",
  "headquartersShortName": "上海嘉定民生村镇银行",
  "headquartersNo": "SHJDMSCUNZB"
}, {
  "headquartersName": "上海金山惠民村镇银行",
  "headquartersShortName": "上海金山惠民村镇银行",
  "headquartersNo": "SHJSHMCUNZB"
}, {
  "headquartersName": "上海闵行上银村镇银行",
  "headquartersShortName": "上海闵行上银村镇银行",
  "headquartersNo": "SHZXSYCUNZB"
}, {
  "headquartersName": "上海农村商业银行",
  "headquartersShortName": "上海农商",
  "headquartersNo": "SHRCB"
}, {
  "headquartersName": "上海浦东发展银行",
  "headquartersShortName": "浦发",
  "headquartersNo": "SPDB"
}, {
  "headquartersName": "上海浦东恒通村镇银行",
  "headquartersShortName": "上海浦东恒通村镇银行",
  "headquartersNo": "SHPDHTCUNZB"
}, {
  "headquartersName": "上海浦东江南村镇银行",
  "headquartersShortName": "上海浦东江南村镇银行",
  "headquartersNo": "SHPDJNCUNZB"
}, {
  "headquartersName": "上海青浦惠金村镇银行",
  "headquartersShortName": "上海青浦惠金村镇银行",
  "headquartersNo": "SHQPHJCUNZB"
}, {
  "headquartersName": "上海商业银行",
  "headquartersShortName": "上海商业",
  "headquartersNo": "SHCB"
}, {
  "headquartersName": "上海松江富明村镇银行",
  "headquartersShortName": "上海松江富明村镇银行",
  "headquartersNo": "SHSJFMCUNZB"
}, {
  "headquartersName": "上海松江民生村镇银行",
  "headquartersShortName": "上海松江民生村镇银行",
  "headquartersNo": "SHSJMSCUNZB"
}, {
  "headquartersName": "上海银行",
  "headquartersShortName": "上海",
  "headquartersNo": "SHYH"
}, {
  "headquartersName": "上杭中成村镇银行",
  "headquartersShortName": "上杭中成村镇银行",
  "headquartersNo": "SHZCCUNZB"
}, {
  "headquartersName": "上饶农村商业银行",
  "headquartersShortName": "上饶农商",
  "headquartersNo": "SRRCB"
}, {
  "headquartersName": "上饶银行",
  "headquartersShortName": "上饶",
  "headquartersNo": "SRB"
}, {
  "headquartersName": "尚志惠鑫村镇银行",
  "headquartersShortName": "尚志惠鑫村镇银行",
  "headquartersNo": "SZHXCUNZB"
}, {
  "headquartersName": "韶山光大村镇银行",
  "headquartersShortName": "韶山光大村镇银行",
  "headquartersNo": "SSGDCUNZB"
}, {
  "headquartersName": "邵武刺桐红村镇银行",
  "headquartersShortName": "邵武刺桐红村镇银行",
  "headquartersNo": "SWCTHCUNZB"
}, {
  "headquartersName": "邵阳农村商业银行",
  "headquartersShortName": "邵阳农商",
  "headquartersNo": "HNSYRCB"
}, {
  "headquartersName": "绍兴银行",
  "headquartersShortName": "绍兴",
  "headquartersNo": "SXYH"
}, {
  "headquartersName": "涉县齐鲁村镇银行",
  "headquartersShortName": "涉县齐鲁村镇银行",
  "headquartersNo": "SXQLCUNZB"
}, {
  "headquartersName": "莘县青隆村镇银行",
  "headquartersShortName": "莘县青隆村镇银行",
  "headquartersNo": "ZXQLCUNZB"
}, {
  "headquartersName": "深泽利丰村镇银行",
  "headquartersShortName": "深泽利丰村镇银行",
  "headquartersNo": "SZLFCUNZB"
}, {
  "headquartersName": "深圳宝安桂银村镇银行",
  "headquartersShortName": "深圳宝安桂银村镇银行",
  "headquartersNo": "SZBAGYCUNZB"
}, {
  "headquartersName": "深圳宝安融兴村镇银行",
  "headquartersShortName": "深圳宝安融兴村镇银行",
  "headquartersNo": "SZBARXCUNZB"
}, {
  "headquartersName": "深圳福田银座村镇银行",
  "headquartersShortName": "深圳福田银座村镇银行",
  "headquartersNo": "FTYZCUNZB"
}, {
  "headquartersName": "深圳光明沪农商村镇银行",
  "headquartersShortName": "深圳光明沪农商村镇银行",
  "headquartersNo": "SZGMHNSCUNZB"
}, {
  "headquartersName": "深圳龙岗鼎业村镇银行",
  "headquartersShortName": "深圳龙岗鼎业村镇银行",
  "headquartersNo": "SZLGDYCUNZB"
}, {
  "headquartersName": "深圳龙华新华村镇银行",
  "headquartersShortName": "深圳龙华新华村镇银行",
  "headquartersNo": "SZLHXHCUNZB"
}, {
  "headquartersName": "深圳罗湖蓝海村镇银行",
  "headquartersShortName": "深圳罗湖蓝海村镇银行",
  "headquartersNo": "SZLHLHCUNZB"
}, {
  "headquartersName": "深圳南山宝生村镇银行",
  "headquartersShortName": "深圳南山宝生村镇银行",
  "headquartersNo": "SZNSBSCUNZB"
}, {
  "headquartersName": "深圳农村商业银行",
  "headquartersShortName": "深圳农商",
  "headquartersNo": "SZRCB"
}, {
  "headquartersName": "深圳坪山珠江村镇银行",
  "headquartersShortName": "深圳坪山珠江村镇银行",
  "headquartersNo": "SZPSZJCUNZB"
}, {
  "headquartersName": "深圳前海微众银行",
  "headquartersShortName": "微众",
  "headquartersNo": "WEBANK"
}, {
  "headquartersName": "深州丰源村镇银行",
  "headquartersShortName": "深州丰源村镇银行",
  "headquartersNo": "SZFYCUNZB"
}, {
  "headquartersName": "什邡思源村镇银行",
  "headquartersShortName": "什邡思源村镇银行",
  "headquartersNo": "SZSYCUNZB"
}, {
  "headquartersName": "神池县河东村镇银行",
  "headquartersShortName": "神池县河东村镇银行",
  "headquartersNo": "SCXHDCUNZB"
}, {
  "headquartersName": "神农架楚农商村镇银行",
  "headquartersShortName": "神农架楚农商村镇银行",
  "headquartersNo": "SNJCNSCUNZB"
}, {
  "headquartersName": "沈阳法库富民村镇银行",
  "headquartersShortName": "沈阳法库富民村镇银行",
  "headquartersNo": "SYFKFMCUNZB"
}, {
  "headquartersName": "沈阳康平抚银村镇银行",
  "headquartersShortName": "沈阳康平抚银村镇银行",
  "headquartersNo": "SYKPFYCUNZB"
}, {
  "headquartersName": "沈阳辽中富民村镇银行",
  "headquartersShortName": "沈阳辽中富民村镇银行",
  "headquartersNo": "SYLZFMCUNZB"
}, {
  "headquartersName": "沈阳农村商业银行",
  "headquartersShortName": "沈阳农商",
  "headquartersNo": "SHENYRCB"
}, {
  "headquartersName": "沈阳沈北富民村镇银行",
  "headquartersShortName": "沈阳沈北富民村镇银行",
  "headquartersNo": "SYSBFMCUNZB"
}, {
  "headquartersName": "沈阳新民富民村镇银行",
  "headquartersShortName": "沈阳新民富民村镇银行",
  "headquartersNo": "SYXMFMCUNZB"
}, {
  "headquartersName": "沈阳于洪永安村镇银行",
  "headquartersShortName": "沈阳于洪永安村镇银行",
  "headquartersNo": "SYYHYACUNZB"
}, {
  "headquartersName": "盛京银行",
  "headquartersShortName": "盛京",
  "headquartersNo": "SJYH"
}, {
  "headquartersName": "施秉金鼎村镇银行",
  "headquartersShortName": "施秉金鼎村镇银行",
  "headquartersNo": "SBJDCUNZB"
}, {
  "headquartersName": "石河子国民村镇银行",
  "headquartersShortName": "石河子国民村镇银行",
  "headquartersNo": "SHZGMCUNZB"
}, {
  "headquartersName": "石家庄汇融农村合作银行",
  "headquartersShortName": "石家庄汇融农村合作银行",
  "headquartersNo": "SJZHRRCU"
}, {
  "headquartersName": "石家庄鹿泉农村商业银行",
  "headquartersShortName": "石家庄鹿泉农商",
  "headquartersNo": "SJZLQRCB"
}, {
  "headquartersName": "石家庄栾城齐鲁村镇银行",
  "headquartersShortName": "石家庄栾城齐鲁村镇银行",
  "headquartersNo": "SJZLCQLCUNZB"
}, {
  "headquartersName": "石家庄新华恒升村镇银行",
  "headquartersShortName": "石家庄新华恒升村镇银行",
  "headquartersNo": "SJZXHHSCUNZB"
}, {
  "headquartersName": "石门沪农商村镇银行",
  "headquartersShortName": "石门沪农商村镇银行",
  "headquartersNo": "SMHNSCUNZB"
}, {
  "headquartersName": "石阡长征村镇银行",
  "headquartersShortName": "石阡长征村镇银行",
  "headquartersNo": "SQCZCUNZB"
}, {
  "headquartersName": "石首楚农商村镇银行",
  "headquartersShortName": "石首楚农商村镇银行",
  "headquartersNo": "SSCNSCUNZB"
}, {
  "headquartersName": "石嘴山市大武口石银村镇银行",
  "headquartersShortName": "石嘴山市大武口石银村镇银行",
  "headquartersNo": "SZSSDWKSYCUNZB"
}, {
  "headquartersName": "石嘴山银行",
  "headquartersShortName": "石嘴山",
  "headquartersNo": "SZSYH"
}, {
  "headquartersName": "始兴大众村镇银行",
  "headquartersShortName": "始兴大众村镇银行",
  "headquartersNo": "SXDZCUNZB"
}, {
  "headquartersName": "寿光张农商村镇银行",
  "headquartersShortName": "寿光张农商村镇银行",
  "headquartersNo": "SGZNSCUNZB"
}, {
  "headquartersName": "寿宁刺桐红村镇银行",
  "headquartersShortName": "寿宁刺桐红村镇银行",
  "headquartersNo": "SNCTHCUNZB"
}, {
  "headquartersName": "寿阳县汇都村镇银行",
  "headquartersShortName": "寿阳县汇都村镇银行",
  "headquartersNo": "SYXHDCUNZB"
}, {
  "headquartersName": "舒兰吉银村镇银行",
  "headquartersShortName": "舒兰吉银村镇银行",
  "headquartersNo": "SHULJYCUNZB"
}, {
  "headquartersName": "双城惠民村镇银行",
  "headquartersShortName": "双城惠民村镇银行",
  "headquartersNo": "SCHMCUNZB"
}, {
  "headquartersName": "双峰沪农商村镇银行",
  "headquartersShortName": "双峰沪农商村镇银行",
  "headquartersNo": "SFHNSCUNZB"
}, {
  "headquartersName": "双辽吉银村镇银行",
  "headquartersShortName": "双辽吉银村镇银行",
  "headquartersNo": "SLJYCUNZB"
}, {
  "headquartersName": "水城蒙银村镇银行",
  "headquartersShortName": "水城蒙银村镇银行",
  "headquartersNo": "SCMYCUNZB"
}, {
  "headquartersName": "顺德农村商业银行",
  "headquartersShortName": "顺德农商",
  "headquartersNo": "SDRCB"
}, {
  "headquartersName": "朔州农村商业银行",
  "headquartersShortName": "朔州农商",
  "headquartersNo": "SXSZRCB"
}, {
  "headquartersName": "朔州市朔城区蒙银村镇银行",
  "headquartersShortName": "朔州市朔城区蒙银村镇银行",
  "headquartersNo": "SZSSCQMYCUNZB"
}, {
  "headquartersName": "思南长征村镇银行",
  "headquartersShortName": "思南长征村镇银行",
  "headquartersNo": "SNCZCUNZB"
}, {
  "headquartersName": "四川安岳农村商业银行",
  "headquartersShortName": "四川安岳农商",
  "headquartersNo": "SCAYRCB"
}, {
  "headquartersName": "四川安州农村商业银行",
  "headquartersShortName": "四川安州农商",
  "headquartersNo": "SCAZRCB"
}, {
  "headquartersName": "四川北川羌族自治县富民村镇银行",
  "headquartersShortName": "四川北川羌族自治县富民村镇银行",
  "headquartersNo": "SCBCQZZZXFMCUNZB"
}, {
  "headquartersName": "四川苍溪农村商业银行",
  "headquartersShortName": "四川苍溪农商",
  "headquartersNo": "SCCXRCB"
}, {
  "headquartersName": "四川成都龙泉驿稠州村镇银行",
  "headquartersShortName": "四川成都龙泉驿稠州村镇银行",
  "headquartersNo": "SCCDLQZCZCUNZB"
}, {
  "headquartersName": "四川成都蒲江民富村镇银行",
  "headquartersShortName": "四川成都蒲江民富村镇银行",
  "headquartersNo": "SCCDPJMFCUNZB"
}, {
  "headquartersName": "四川大英农村商业银行",
  "headquartersShortName": "四川大英农商",
  "headquartersNo": "SCDYRCB"
}, {
  "headquartersName": "四川大竹农村商业银行",
  "headquartersShortName": "四川大竹农商",
  "headquartersNo": "SCDZRCB"
}, {
  "headquartersName": "四川大竹渝农商村镇银行",
  "headquartersShortName": "四川大竹渝农商村镇银行",
  "headquartersNo": "SCDZYNSCUNZB"
}, {
  "headquartersName": "四川丹棱农村商业银行",
  "headquartersShortName": "四川丹棱农商",
  "headquartersNo": "SCDLRCB"
}, {
  "headquartersName": "四川峨眉山农村商业银行",
  "headquartersShortName": "四川峨眉山农商",
  "headquartersNo": "SCEMSRCB"
}, {
  "headquartersName": "四川富顺农村商业银行",
  "headquartersShortName": "四川富顺农商",
  "headquartersNo": "SCFSRCB"
}, {
  "headquartersName": "四川高县农村商业银行",
  "headquartersShortName": "四川高县农商",
  "headquartersNo": "SCGAOXRCB"
}, {
  "headquartersName": "四川珙县农村商业银行",
  "headquartersShortName": "四川珙县农商",
  "headquartersNo": "SCGXRCB"
}, {
  "headquartersName": "四川古蔺农村商业银行",
  "headquartersShortName": "四川古蔺农商",
  "headquartersNo": "SCGLRCB"
}, {
  "headquartersName": "四川广汉农村商业银行",
  "headquartersShortName": "四川广汉农商",
  "headquartersNo": "SCGHRCB"
}, {
  "headquartersName": "四川广元农村商业银行",
  "headquartersShortName": "四川广元农商",
  "headquartersNo": "SCGYRCB"
}, {
  "headquartersName": "四川合江农村商业银行",
  "headquartersShortName": "四川合江农商",
  "headquartersNo": "SCHJRCB"
}, {
  "headquartersName": "四川华蓥农村商业银行",
  "headquartersShortName": "四川华蓥农商",
  "headquartersNo": "SCHYRCB"
}, {
  "headquartersName": "四川犍为农村商业银行",
  "headquartersShortName": "四川犍为农商",
  "headquartersNo": "SCJWRCB"
}, {
  "headquartersName": "四川简阳农村商业银行",
  "headquartersShortName": "四川简阳农商",
  "headquartersNo": "SCJYRCB"
}, {
  "headquartersName": "四川剑阁农村商业银行",
  "headquartersShortName": "四川剑阁农商",
  "headquartersNo": "SCJGRCB"
}, {
  "headquartersName": "四川江安农村商业银行",
  "headquartersShortName": "四川江安农商",
  "headquartersNo": "SCJARCB"
}, {
  "headquartersName": "四川江油华夏村镇银行",
  "headquartersShortName": "四川江油华夏村镇银行",
  "headquartersNo": "SCJYHXCUNZB"
}, {
  "headquartersName": "四川江油农村商业银行",
  "headquartersShortName": "四川江油农商",
  "headquartersNo": "SCJYORCB"
}, {
  "headquartersName": "四川阆中农村商业银行",
  "headquartersShortName": "四川阆中农商",
  "headquartersNo": "SCLZRCB"
}, {
  "headquartersName": "四川乐至农村商业银行",
  "headquartersShortName": "四川乐至农商",
  "headquartersNo": "SCLEZRCB"
}, {
  "headquartersName": "四川邻水农村商业银行",
  "headquartersShortName": "四川邻水农商",
  "headquartersNo": "SCLSRCB"
}, {
  "headquartersName": "四川隆昌农村商业银行",
  "headquartersShortName": "四川隆昌农商",
  "headquartersNo": "SCCLRCB"
}, {
  "headquartersName": "四川泸县农村商业银行",
  "headquartersShortName": "四川泸县农商",
  "headquartersNo": "SCLXRCB"
}, {
  "headquartersName": "四川罗江农村商业银行",
  "headquartersShortName": "四川罗江农商",
  "headquartersNo": "SCLJRCB"
}, {
  "headquartersName": "四川绵竹农村商业银行",
  "headquartersShortName": "四川绵竹农商",
  "headquartersNo": "SCMZRCB"
}, {
  "headquartersName": "四川名山锦程村镇银行",
  "headquartersShortName": "四川名山锦程村镇银行",
  "headquartersNo": "SCMSJCCUNZB"
}, {
  "headquartersName": "四川南部农村商业银行",
  "headquartersShortName": "四川南部农商",
  "headquartersNo": "SCNBRCB"
}, {
  "headquartersName": "四川内江农村商业银行",
  "headquartersShortName": "四川内江农商",
  "headquartersNo": "SCNJRCB"
}, {
  "headquartersName": "四川蓬安农村商业银行",
  "headquartersShortName": "四川蓬安农商",
  "headquartersNo": "SCPARCB"
}, {
  "headquartersName": "四川蓬溪农村商业银行",
  "headquartersShortName": "四川蓬溪农商",
  "headquartersNo": "SCPXRCB"
}, {
  "headquartersName": "四川平武农村商业银行",
  "headquartersShortName": "四川平武农商",
  "headquartersNo": "SCPWRCB"
}, {
  "headquartersName": "四川屏山农村商业银行",
  "headquartersShortName": "四川屏山农商",
  "headquartersNo": "SCPSRCB"
}, {
  "headquartersName": "四川青川农村商业银行",
  "headquartersShortName": "四川青川农商",
  "headquartersNo": "SCQCRCB"
}, {
  "headquartersName": "四川青神农村商业银行",
  "headquartersShortName": "四川青神农商",
  "headquartersNo": "SCQSRCB"
}, {
  "headquartersName": "四川渠县农村商业银行",
  "headquartersShortName": "四川渠县农商",
  "headquartersNo": "SCQXRCB"
}, {
  "headquartersName": "四川仁寿农村商业银行",
  "headquartersShortName": "四川仁寿农商",
  "headquartersNo": "SCRSRCB"
}, {
  "headquartersName": "四川三台农村商业银行",
  "headquartersShortName": "四川三台农商",
  "headquartersNo": "SCSTRCB"
}, {
  "headquartersName": "四川射洪农村商业银行",
  "headquartersShortName": "四川射洪农商",
  "headquartersNo": "SCSHRCB"
}, {
  "headquartersName": "四川什邡农村商业银行",
  "headquartersShortName": "四川什邡农商",
  "headquartersNo": "SCSFRCB"
}, {
  "headquartersName": "四川省农村信用社联合社",
  "headquartersShortName": "四川农信社",
  "headquartersNo": "SCNXS"
}, {
  "headquartersName": "四川石棉农村商业银行",
  "headquartersShortName": "四川石棉农商",
  "headquartersNo": "SCSMRCB"
}, {
  "headquartersName": "四川天府银行",
  "headquartersShortName": "四川天府",
  "headquartersNo": "CGNB"
}, {
  "headquartersName": "四川天全农村商业银行",
  "headquartersShortName": "四川天全农商",
  "headquartersNo": "SCTQRCB"
}, {
  "headquartersName": "四川万源农村商业银行",
  "headquartersShortName": "四川万源农商",
  "headquartersNo": "SCWANYRCB"
}, {
  "headquartersName": "四川旺苍农村商业银行",
  "headquartersShortName": "四川旺苍农商",
  "headquartersNo": "SCWCRCB"
}, {
  "headquartersName": "四川威远农村商业银行",
  "headquartersShortName": "四川威远农商",
  "headquartersNo": "SCWYRCB"
}, {
  "headquartersName": "四川武胜农村商业银行",
  "headquartersShortName": "四川武胜农商",
  "headquartersNo": "SCWSRCB"
}, {
  "headquartersName": "四川西充农村商业银行",
  "headquartersShortName": "四川西充农商",
  "headquartersNo": "SCXCRCB"
}, {
  "headquartersName": "四川新网银行",
  "headquartersShortName": "新网",
  "headquartersNo": "XWB"
}, {
  "headquartersName": "四川兴文石海农村商业银行",
  "headquartersShortName": "四川兴文石海农商",
  "headquartersNo": "SCXWSHRCB"
}, {
  "headquartersName": "四川叙永农村商业银行",
  "headquartersShortName": "四川叙永农商",
  "headquartersNo": "SCXYRCB"
}, {
  "headquartersName": "四川宣汉农村商业银行",
  "headquartersShortName": "四川宣汉农商",
  "headquartersNo": "SCXHRCB"
}, {
  "headquartersName": "四川仪陇惠民村镇银行",
  "headquartersShortName": "四川仪陇惠民村镇银行",
  "headquartersNo": "SCYLHMCUNZB"
}, {
  "headquartersName": "四川仪陇农村商业银行",
  "headquartersShortName": "四川仪陇农商",
  "headquartersNo": "SCYLRCB"
}, {
  "headquartersName": "四川宜宾金江农村商业银行",
  "headquartersShortName": "四川宜宾金江农商",
  "headquartersNo": "SCYBJJRCB"
}, {
  "headquartersName": "四川银行",
  "headquartersShortName": "四川",
  "headquartersNo": "SCBANK"
}, {
  "headquartersName": "四川营山农村商业银行",
  "headquartersShortName": "四川营山农商",
  "headquartersNo": "SCYSRCB"
}, {
  "headquartersName": "四川岳池农村商业银行",
  "headquartersShortName": "四川岳池农商",
  "headquartersNo": "SCYCRCB"
}, {
  "headquartersName": "四川筠连农村商业银行",
  "headquartersShortName": "四川筠连农商",
  "headquartersNo": "SCJLRCB"
}, {
  "headquartersName": "四川长宁竹海农村商业银行",
  "headquartersShortName": "四川长宁竹海农商",
  "headquartersNo": "SCCNZHRCB"
}, {
  "headquartersName": "四川中江农村商业银行",
  "headquartersShortName": "四川中江农商",
  "headquartersNo": "SCZJRCB"
}, {
  "headquartersName": "四川梓潼农村商业银行",
  "headquartersShortName": "四川梓潼农商",
  "headquartersNo": "SCZTRCB"
}, {
  "headquartersName": "四平辽河蛟银村镇银行",
  "headquartersShortName": "四平辽河蛟银村镇银行",
  "headquartersNo": "SPLHZYCUNZB"
}, {
  "headquartersName": "四平铁东德丰村镇银行",
  "headquartersShortName": "四平铁东德丰村镇银行",
  "headquartersNo": "SPTDDFCUNZB"
}, {
  "headquartersName": "四平铁西敦银村镇银行",
  "headquartersShortName": "四平铁西敦银村镇银行",
  "headquartersNo": "SPTXDYCUNZB"
}, {
  "headquartersName": "四子王蒙银村镇银行",
  "headquartersShortName": "四子王蒙银村镇银行",
  "headquartersNo": "SZWMYCUNZB"
}, {
  "headquartersName": "松桃长征村镇银行",
  "headquartersShortName": "松桃长征村镇银行",
  "headquartersNo": "STCZCUNZB"
}, {
  "headquartersName": "松原宁江惠民村镇银行",
  "headquartersShortName": "松原宁江惠民村镇银行",
  "headquartersNo": "SYNJHMCUNZB"
}, {
  "headquartersName": "嵩明沪农商村镇银行",
  "headquartersShortName": "嵩明沪农商村镇银行",
  "headquartersNo": "ZMHNSCUNZB"
}, {
  "headquartersName": "苏州农村商业银行",
  "headquartersShortName": "苏州农商",
  "headquartersNo": "SUZRCB"
}, {
  "headquartersName": "苏州吴中珠江村镇银行",
  "headquartersShortName": "苏州吴中珠江村镇银行",
  "headquartersNo": "SZWZZJCUNZB"
}, {
  "headquartersName": "苏州银行",
  "headquartersShortName": "苏州",
  "headquartersNo": "SZYH"
}, {
  "headquartersName": "睢县德商村镇银行",
  "headquartersShortName": "睢县德商村镇银行",
  "headquartersNo": "ZXDSCUNZB"
}, {
  "headquartersName": "绥阳黔北村镇银行",
  "headquartersShortName": "绥阳黔北村镇银行",
  "headquartersNo": "SYQBCUNZB"
}, {
  "headquartersName": "绥中长丰村镇银行",
  "headquartersShortName": "绥中长丰村镇银行",
  "headquartersNo": "SZCFCUNZB"
}, {
  "headquartersName": "随县楚农商村镇银行",
  "headquartersShortName": "随县楚农商村镇银行",
  "headquartersNo": "SXCNSCUNZB"
}, {
  "headquartersName": "遂川洪都村镇银行",
  "headquartersShortName": "遂川洪都村镇银行",
  "headquartersNo": "SCHDCUNZB"
}, {
  "headquartersName": "遂宁安居融兴村镇银行",
  "headquartersShortName": "遂宁安居融兴村镇银行",
  "headquartersNo": "SNAJRXCUNZB"
}, {
  "headquartersName": "遂宁农村商业银行",
  "headquartersShortName": "遂宁农商",
  "headquartersNo": "SNRCB"
}, {
  "headquartersName": "遂宁市商业银行",
  "headquartersShortName": "遂宁",
  "headquartersNo": "SNSCCB"
}, {
  "headquartersName": "遂平中原村镇银行",
  "headquartersShortName": "遂平中原村镇银行",
  "headquartersNo": "SPZYCUNZB"
}, {
  "headquartersName": "塔城津汇村镇银行",
  "headquartersShortName": "塔城津汇村镇银行",
  "headquartersNo": "TCJHCUNZB"
}, {
  "headquartersName": "塔城昆仑村镇银行",
  "headquartersShortName": "塔城昆仑村镇银行",
  "headquartersNo": "TCKLCUNZB"
}, {
  "headquartersName": "台江富民村镇银行",
  "headquartersShortName": "台江富民村镇银行",
  "headquartersNo": "TJFMCUNZB"
}, {
  "headquartersName": "台前德商村镇银行",
  "headquartersShortName": "台前德商村镇银行",
  "headquartersNo": "TQDSCUNZB"
}, {
  "headquartersName": "台湾土地银行",
  "headquartersShortName": "台湾土地",
  "headquartersNo": "TWTDB"
}, {
  "headquartersName": "台湾银行",
  "headquartersShortName": "台湾",
  "headquartersNo": "TWYH"
}, {
  "headquartersName": "台湾中小企业银行",
  "headquartersShortName": "台湾中小企业",
  "headquartersNo": "TWZXQYB"
}, {
  "headquartersName": "台州银行",
  "headquartersShortName": "台州",
  "headquartersNo": "TZYH"
}, {
  "headquartersName": "太仓民生村镇银行",
  "headquartersShortName": "太仓民生村镇银行",
  "headquartersNo": "TAICMSCUNZB"
}, {
  "headquartersName": "太仓农村商业银行",
  "headquartersShortName": "太仓农商",
  "headquartersNo": "TCRCB"
}, {
  "headquartersName": "太谷县兴泰村镇银行",
  "headquartersShortName": "太谷县兴泰村镇银行",
  "headquartersNo": "TGXXTCUNZB"
}, {
  "headquartersName": "太仆寺旗鑫源村镇银行",
  "headquartersShortName": "太仆寺旗鑫源村镇银行",
  "headquartersNo": "TPSQZYCUNZB"
}, {
  "headquartersName": "太原农村商业银行",
  "headquartersShortName": "太原农商",
  "headquartersNo": "TYRCB"
}, {
  "headquartersName": "太原市尖草坪区信都村镇银行",
  "headquartersShortName": "太原市尖草坪区信都村镇银行",
  "headquartersNo": "TYSJCPQXDCUNZB"
}, {
  "headquartersName": "泰安岱岳农村商业银行",
  "headquartersShortName": "泰安岱岳农商",
  "headquartersNo": "TADYRCB"
}, {
  "headquartersName": "泰安沪农商村镇银行",
  "headquartersShortName": "泰安沪农商村镇银行",
  "headquartersNo": "TAHNSCUNZB"
}, {
  "headquartersName": "泰安泰山农村商业银行",
  "headquartersShortName": "泰安泰山农商",
  "headquartersNo": "TATSRCB"
}, {
  "headquartersName": "泰安银行",
  "headquartersShortName": "泰安",
  "headquartersNo": "TACCB"
}, {
  "headquartersName": "泰国汇商银行",
  "headquartersShortName": "泰国汇商",
  "headquartersNo": "SIAMCB"
}, {
  "headquartersName": "泰国泰京银行",
  "headquartersShortName": "泰国泰京",
  "headquartersNo": "TGTJB"
}, {
  "headquartersName": "泰州姜堰锡州村镇银行",
  "headquartersShortName": "泰州姜堰锡州村镇银行",
  "headquartersNo": "TZJYXZCUNZB"
}, {
  "headquartersName": "唐山市丰南舜丰村镇银行",
  "headquartersShortName": "唐山市丰南舜丰村镇银行",
  "headquartersNo": "TSSFNSFCUNZB"
}, {
  "headquartersName": "唐山市开平汇金村镇银行",
  "headquartersShortName": "唐山市开平汇金村镇银行",
  "headquartersNo": "TSSKPHJCUNZB"
}, {
  "headquartersName": "唐山银行",
  "headquartersShortName": "唐山",
  "headquartersNo": "TSYH"
}, {
  "headquartersName": "唐县汇泽村镇银行",
  "headquartersShortName": "唐县汇泽村镇银行",
  "headquartersNo": "TXHZCUNZB"
}, {
  "headquartersName": "洮南惠民村镇银行",
  "headquartersShortName": "洮南惠民村镇银行",
  "headquartersNo": "ZNHMCUNZB"
}, {
  "headquartersName": "腾冲民生村镇银行",
  "headquartersShortName": "腾冲民生村镇银行",
  "headquartersNo": "TENGCMSCUNZB"
}, {
  "headquartersName": "天津宝坻浦发村镇银行",
  "headquartersShortName": "天津宝坻浦发村镇银行",
  "headquartersNo": "TJBZPFCUNZB"
}, {
  "headquartersName": "天津滨海德商村镇银行",
  "headquartersShortName": "天津滨海德商村镇银行",
  "headquartersNo": "TJBHDSCUNZB"
}, {
  "headquartersName": "天津滨海惠民村镇银行",
  "headquartersShortName": "天津滨海惠民村镇银行",
  "headquartersNo": "TJBHHMCUNZB"
}, {
  "headquartersName": "天津滨海江淮村镇银行",
  "headquartersShortName": "天津滨海江淮村镇银行",
  "headquartersNo": "TJBJJHCUNZB"
}, {
  "headquartersName": "天津滨海农村商业银行",
  "headquartersShortName": "天津滨海农商",
  "headquartersNo": "TJBHB"
}, {
  "headquartersName": "天津滨海扬子村镇银行",
  "headquartersShortName": "天津滨海扬子村镇银行",
  "headquartersNo": "TJBHYZCUNZB"
}, {
  "headquartersName": "天津华明村镇银行",
  "headquartersShortName": "天津华明村镇银行",
  "headquartersNo": "TJHMCUNZB"
}, {
  "headquartersName": "天津金城银行",
  "headquartersShortName": "天津金城",
  "headquartersNo": "TJJCB"
}, {
  "headquartersName": "天津津南村镇银行",
  "headquartersShortName": "天津津南村镇银行",
  "headquartersNo": "TJJNCUNZB"
}, {
  "headquartersName": "天津静海新华村镇银行",
  "headquartersShortName": "天津静海新华村镇银行",
  "headquartersNo": "TJJHXHCUNZB"
}, {
  "headquartersName": "天津宁河村镇银行",
  "headquartersShortName": "天津宁河村镇银行",
  "headquartersNo": "TJNHCUNZB"
}, {
  "headquartersName": "天津农村商业银行",
  "headquartersShortName": "天津农商",
  "headquartersNo": "TJRCB"
}, {
  "headquartersName": "天津市北辰村镇银行",
  "headquartersShortName": "天津市北辰村镇银行",
  "headquartersNo": "TJSBCCUNZB"
}, {
  "headquartersName": "天津市蓟州村镇银行",
  "headquartersShortName": "天津市蓟州村镇银行",
  "headquartersNo": "TJSJZCUNZB"
}, {
  "headquartersName": "天津武清村镇银行",
  "headquartersShortName": "天津武清村镇银行",
  "headquartersNo": "TJWQCUNZB"
}, {
  "headquartersName": "天津银行",
  "headquartersShortName": "天津",
  "headquartersNo": "TJYH"
}, {
  "headquartersName": "天水麦积农村合作银行",
  "headquartersShortName": "天水麦积农村合作银行",
  "headquartersNo": "TSMJRCU"
}, {
  "headquartersName": "天水麦积融兴村镇银行",
  "headquartersShortName": "天水麦积融兴村镇银行",
  "headquartersNo": "TSMJRXCUNZB"
}, {
  "headquartersName": "天水秦州农村合作银行",
  "headquartersShortName": "天水秦州农村合作银行",
  "headquartersNo": "TSQZRCU"
}, {
  "headquartersName": "天水秦州长银村镇银行",
  "headquartersShortName": "天水秦州长银村镇银行",
  "headquartersNo": "TSQZCYCUNZB"
}, {
  "headquartersName": "天水市秦安众信村镇银行",
  "headquartersShortName": "天水市秦安众信村镇银行",
  "headquartersNo": "TSSQAZXCUNZB"
}, {
  "headquartersName": "天长民生村镇银行",
  "headquartersShortName": "天长民生村镇银行",
  "headquartersNo": "TCMSCUNZB"
}, {
  "headquartersName": "天镇县河东村镇银行",
  "headquartersShortName": "天镇县河东村镇银行",
  "headquartersNo": "TZXHDCUNZB"
}, {
  "headquartersName": "田东北部湾村镇银行",
  "headquartersShortName": "田东北部湾村镇银行",
  "headquartersNo": "TDBBWCUNZB"
}, {
  "headquartersName": "田阳兴阳村镇银行",
  "headquartersShortName": "田阳兴阳村镇银行",
  "headquartersNo": "TYXYCUNZB"
}, {
  "headquartersName": "铁岭农商村镇银行",
  "headquartersShortName": "铁岭农商村镇银行",
  "headquartersNo": "TLNSCUNZB"
}, {
  "headquartersName": "铁岭新星村镇银行",
  "headquartersShortName": "铁岭新星村镇银行",
  "headquartersNo": "TLXXCUNZB"
}, {
  "headquartersName": "铁岭银行",
  "headquartersShortName": "铁岭",
  "headquartersNo": "TLYH"
}, {
  "headquartersName": "铁门关津汇村镇银行",
  "headquartersShortName": "铁门关津汇村镇银行",
  "headquartersNo": "TMGJHCUNZB"
}, {
  "headquartersName": "通城惠民村镇银行",
  "headquartersShortName": "通城惠民村镇银行",
  "headquartersNo": "TCHMCUNZB"
}, {
  "headquartersName": "通化东昌榆银村镇银行",
  "headquartersShortName": "通化东昌榆银村镇银行",
  "headquartersNo": "THDCYYCUNZB"
}, {
  "headquartersName": "通化二道江瑞丰村镇银行",
  "headquartersShortName": "通化二道江瑞丰村镇银行",
  "headquartersNo": "THEDJRFCUNZB"
}, {
  "headquartersName": "通化农村商业银行股份有限公司",
  "headquartersShortName": "通化农商",
  "headquartersNo": "THRCB"
}, {
  "headquartersName": "通化融达村镇银行",
  "headquartersShortName": "通化融达村镇银行",
  "headquartersNo": "THRDCUNZB"
}, {
  "headquartersName": "通辽金谷村镇银行",
  "headquartersShortName": "通辽金谷村镇银行",
  "headquartersNo": "TLJGCUNZB"
}, {
  "headquartersName": "通辽奈曼农村合作银行",
  "headquartersShortName": "通辽奈曼农村合作银行",
  "headquartersNo": "TLNMRCU"
}, {
  "headquartersName": "通山楚农商村镇银行",
  "headquartersShortName": "通山楚农商村镇银行",
  "headquartersNo": "TSCNSCUNZB"
}, {
  "headquartersName": "通许融信村镇银行",
  "headquartersShortName": "通许融信村镇银行",
  "headquartersNo": "TXRXCUNZB"
}, {
  "headquartersName": "通榆农商村镇银行",
  "headquartersShortName": "通榆农商村镇银行",
  "headquartersNo": "TYNSCUNZB"
}, {
  "headquartersName": "同江汇鑫村镇银行",
  "headquartersShortName": "同江汇鑫村镇银行",
  "headquartersNo": "TJHXCUNZB"
}, {
  "headquartersName": "铜川耀州新华村镇银行",
  "headquartersShortName": "铜川耀州新华村镇银行",
  "headquartersNo": "TCYZXHCUNZB"
}, {
  "headquartersName": "铜川印台恒通村镇银行",
  "headquartersShortName": "铜川印台恒通村镇银行",
  "headquartersNo": "TCYTHTCUNZB"
}, {
  "headquartersName": "铜鼓九银村镇银行",
  "headquartersShortName": "铜鼓九银村镇银行",
  "headquartersNo": "TGJYCUNZB"
}, {
  "headquartersName": "铜陵农村商业银行",
  "headquartersShortName": "铜陵农商",
  "headquartersNo": "TLRCB"
}, {
  "headquartersName": "铜陵皖江农村商业银行",
  "headquartersShortName": "铜陵皖江农商",
  "headquartersNo": "TLWJRCB"
}, {
  "headquartersName": "铜仁丰源村镇银行",
  "headquartersShortName": "铜仁丰源村镇银行",
  "headquartersNo": "TRFYCUNZB"
}, {
  "headquartersName": "铜仁农村商业银行",
  "headquartersShortName": "铜仁农商",
  "headquartersNo": "TRRCB"
}, {
  "headquartersName": "铜仁万山长征村镇银行",
  "headquartersShortName": "铜仁万山长征村镇银行",
  "headquartersNo": "TRWSCZCUNZB"
}, {
  "headquartersName": "潼南民生村镇银行",
  "headquartersShortName": "潼南民生村镇银行",
  "headquartersNo": "ZNMSCUNZB"
}, {
  "headquartersName": "突泉蒙银村镇银行",
  "headquartersShortName": "突泉蒙银村镇银行",
  "headquartersNo": "TQMYCUNZB"
}, {
  "headquartersName": "图们敦银村镇银行",
  "headquartersShortName": "图们敦银村镇银行",
  "headquartersNo": "TMDYCUNZB"
}, {
  "headquartersName": "土默特右旗蒙银村镇银行",
  "headquartersShortName": "土默特右旗蒙银村镇银行",
  "headquartersNo": "TMTYQMYCUNZB"
}, {
  "headquartersName": "土默特左旗金谷村镇银行",
  "headquartersShortName": "土默特左旗金谷村镇银行",
  "headquartersNo": "TMTZQJGCUNZB"
}, {
  "headquartersName": "团风楚农商村镇银行",
  "headquartersShortName": "团风楚农商村镇银行",
  "headquartersNo": "TFCNSCUNZB"
}, {
  "headquartersName": "屯留县三禾村镇银行",
  "headquartersShortName": "屯留县三禾村镇银行",
  "headquartersNo": "TLXSHCUNZB"
}, {
  "headquartersName": "瓦房店长兴村镇银行",
  "headquartersShortName": "瓦房店长兴村镇银行",
  "headquartersNo": "WFDCXCUNZB"
}, {
  "headquartersName": "万安洪都村镇银行",
  "headquartersShortName": "万安洪都村镇银行",
  "headquartersNo": "WAHDCUNZB"
}, {
  "headquartersName": "万年稻源村镇银行",
  "headquartersShortName": "万年稻源村镇银行",
  "headquartersNo": "WNHHCUNZB"
}, {
  "headquartersName": "万宁金谷村镇银行",
  "headquartersShortName": "万宁金谷村镇银行",
  "headquartersNo": "WNGMCUNZB"
}, {
  "headquartersName": "万荣县汇民村镇银行",
  "headquartersShortName": "万荣县汇民村镇银行",
  "headquartersNo": "WRXHMCUNZB"
}, {
  "headquartersName": "汪清和润村镇银行",
  "headquartersShortName": "汪清和润村镇银行",
  "headquartersNo": "WQHRCUNZB"
}, {
  "headquartersName": "望都中成村镇银行",
  "headquartersShortName": "望都中成村镇银行",
  "headquartersNo": "WDZCCUNZB"
}, {
  "headquartersName": "望江新华村镇银行",
  "headquartersShortName": "望江新华村镇银行",
  "headquartersNo": "WJXHCUNZB"
}, {
  "headquartersName": "望谟富民村镇银行",
  "headquartersShortName": "望谟富民村镇银行",
  "headquartersNo": "WZFMCUNZB"
}, {
  "headquartersName": "威海富民村镇银行",
  "headquartersShortName": "威海富民村镇银行",
  "headquartersNo": "WHFMCUNZB"
}, {
  "headquartersName": "威海蓝海银行",
  "headquartersShortName": "威海蓝海",
  "headquartersNo": "WHLHB"
}, {
  "headquartersName": "威海市商业银行",
  "headquartersShortName": "威海",
  "headquartersNo": "WHCCB"
}, {
  "headquartersName": "威宁富民村镇银行",
  "headquartersShortName": "威宁富民村镇银行",
  "headquartersNo": "WNFMCUNZB"
}, {
  "headquartersName": "威县邢农商村镇银行",
  "headquartersShortName": "威县邢农商村镇银行",
  "headquartersNo": "WXXNSCUNZB"
}, {
  "headquartersName": "微山北海村镇银行",
  "headquartersShortName": "微山北海村镇银行",
  "headquartersNo": "WSBHCUNZB"
}, {
  "headquartersName": "围场满族蒙古族自治县华商村镇银行",
  "headquartersShortName": "围场满族蒙古族自治县华商村镇银行",
  "headquartersNo": "WCMZMGZZZXHSCUNZB"
}, {
  "headquartersName": "潍坊农村商业银行",
  "headquartersShortName": "潍坊农商",
  "headquartersNo": "WFRCB"
}, {
  "headquartersName": "潍坊市寒亭区蒙银村镇银行",
  "headquartersShortName": "潍坊市寒亭区蒙银村镇银行",
  "headquartersNo": "WFSHTQMYCUNZB"
}, {
  "headquartersName": "潍坊市奎文区中成村镇银行",
  "headquartersShortName": "潍坊市奎文区中成村镇银行",
  "headquartersNo": "WFSKWQZCCUNZB"
}, {
  "headquartersName": "潍坊市潍城区北海村镇银行",
  "headquartersShortName": "潍坊市潍城区北海村镇银行",
  "headquartersNo": "WFSWCQBHCUNZB"
}, {
  "headquartersName": "潍坊银行",
  "headquartersShortName": "潍坊",
  "headquartersNo": "WFYH"
}, {
  "headquartersName": "卫辉富民村镇银行",
  "headquartersShortName": "卫辉富民村镇银行",
  "headquartersNo": "WHUIFMCUNZB"
}, {
  "headquartersName": "尉犁达西冀银村镇银行",
  "headquartersShortName": "尉犁达西冀银村镇银行",
  "headquartersNo": "WLDXJYCUNZB"
}, {
  "headquartersName": "尉氏合益村镇银行",
  "headquartersShortName": "尉氏合益村镇银行",
  "headquartersNo": "WSHYCUNZB"
}, {
  "headquartersName": "蔚县银泰村镇银行",
  "headquartersShortName": "蔚县银泰村镇银行",
  "headquartersNo": "WXYTCUNZB"
}, {
  "headquartersName": "魏县齐鲁村镇银行",
  "headquartersShortName": "魏县齐鲁村镇银行",
  "headquartersNo": "WXQLCUNZB"
}, {
  "headquartersName": "温县齐鲁村镇银行",
  "headquartersShortName": "温县齐鲁村镇银行",
  "headquartersNo": "WENXQLCUNZB"
}, {
  "headquartersName": "温州民商银行",
  "headquartersShortName": "温州民商",
  "headquartersNo": "WZMSYH"
}, {
  "headquartersName": "温州银行股份有限公司",
  "headquartersShortName": "温州",
  "headquartersNo": "WZYH"
}, {
  "headquartersName": "文安县惠民村镇银行",
  "headquartersShortName": "文安县惠民村镇银行",
  "headquartersNo": "WAXHMCUNZB"
}, {
  "headquartersName": "文昌大众村镇银行",
  "headquartersShortName": "文昌大众村镇银行",
  "headquartersNo": "WCDZCUNZB"
}, {
  "headquartersName": "文山富宁长江村镇银行",
  "headquartersShortName": "文山富宁长江村镇银行",
  "headquartersNo": "WSFNCJCUNZB"
}, {
  "headquartersName": "文山广南长江村镇银行",
  "headquartersShortName": "文山广南长江村镇银行",
  "headquartersNo": "WSGNCJCUNZB"
}, {
  "headquartersName": "文山马关长江村镇银行",
  "headquartersShortName": "文山马关长江村镇银行",
  "headquartersNo": "WSMGCJCUNZB"
}, {
  "headquartersName": "文山民丰村镇银行",
  "headquartersShortName": "文山民丰村镇银行",
  "headquartersNo": "WSMFCUNZB"
}, {
  "headquartersName": "文山丘北长江村镇银行",
  "headquartersShortName": "文山丘北长江村镇银行",
  "headquartersNo": "WSQBCJCUNZB"
}, {
  "headquartersName": "文山砚山长江村镇银行",
  "headquartersShortName": "文山砚山长江村镇银行",
  "headquartersNo": "WSYSCJCUNZB"
}, {
  "headquartersName": "文水县润都村镇银行",
  "headquartersShortName": "文水县润都村镇银行",
  "headquartersNo": "WSXRDCUNZB"
}, {
  "headquartersName": "闻喜县晋融村镇银行",
  "headquartersShortName": "闻喜县晋融村镇银行",
  "headquartersNo": "WXXJRCUNZB"
}, {
  "headquartersName": "翁牛特立农村镇银行",
  "headquartersShortName": "翁牛特立农村镇银行",
  "headquartersNo": "WNTLNCUNZB"
}, {
  "headquartersName": "瓮安富民村镇银行",
  "headquartersShortName": "瓮安富民村镇银行",
  "headquartersNo": "WAFMCUNZB"
}, {
  "headquartersName": "乌海千里山河套村镇银行",
  "headquartersShortName": "乌海千里山河套村镇银行",
  "headquartersNo": "WHQLSHTCUNZB"
}, {
  "headquartersName": "乌海市海勃湾黄河村镇银行",
  "headquartersShortName": "乌海市海勃湾黄河村镇银行",
  "headquartersNo": "WHSHBWHHCUNZB"
}, {
  "headquartersName": "乌海市乌拉特村镇银行",
  "headquartersShortName": "乌海市乌拉特村镇银行",
  "headquartersNo": "WHSWLTCUNZB"
}, {
  "headquartersName": "乌海银行",
  "headquartersShortName": "乌海",
  "headquartersNo": "WHYH"
}, {
  "headquartersName": "乌拉特前旗大众村镇银行",
  "headquartersShortName": "乌拉特前旗大众村镇银行",
  "headquartersNo": "WLTQQDZCUNZB"
}, {
  "headquartersName": "乌拉特中旗蒙银村镇银行",
  "headquartersShortName": "乌拉特中旗蒙银村镇银行",
  "headquartersNo": "WLTZQMYCUNZB"
}, {
  "headquartersName": "乌兰察布农村商业银行",
  "headquartersShortName": "乌兰察布农商",
  "headquartersNo": "WLCBRCB"
}, {
  "headquartersName": "乌兰察布市集宁蒙商村镇银行",
  "headquartersShortName": "乌兰察布市集宁蒙商村镇银行",
  "headquartersNo": "WLCBSJNBSCUNZB"
}, {
  "headquartersName": "乌鲁木齐米东浦发村镇银行",
  "headquartersShortName": "乌鲁木齐米东浦发村镇银行",
  "headquartersNo": "WLMQMDPFCUNZB"
}, {
  "headquartersName": "乌鲁木齐县利丰村镇银行",
  "headquartersShortName": "乌鲁木齐县利丰村镇银行",
  "headquartersNo": "WLMQXLFCUNZB"
}, {
  "headquartersName": "乌鲁木齐银行",
  "headquartersShortName": "乌鲁木齐",
  "headquartersNo": "WLMQYH"
}, {
  "headquartersName": "乌审旗蒙商村镇银行",
  "headquartersShortName": "乌审旗蒙商村镇银行",
  "headquartersNo": "WSQBSCUNZB"
}, {
  "headquartersName": "乌苏利丰村镇银行",
  "headquartersShortName": "乌苏利丰村镇银行",
  "headquartersNo": "WSLFCUNZB"
}, {
  "headquartersName": "无棣中成村镇银行",
  "headquartersShortName": "无棣中成村镇银行",
  "headquartersNo": "WZZCCUNZB"
}, {
  "headquartersName": "无为徽银村镇银行",
  "headquartersShortName": "无为徽银村镇银行",
  "headquartersNo": "WWHYCUNZB"
}, {
  "headquartersName": "无锡农村商业银行",
  "headquartersShortName": "无锡农商",
  "headquartersNo": "WXRCB"
}, {
  "headquartersName": "无锡锡商银行",
  "headquartersShortName": "无锡锡商",
  "headquartersNo": "WXXSB"
}, {
  "headquartersName": "芜湖津盛农村商业银行",
  "headquartersShortName": "芜湖津盛农商",
  "headquartersNo": "WHJSRCB"
}, {
  "headquartersName": "芜湖扬子农村商业银行",
  "headquartersShortName": "芜湖扬子农商",
  "headquartersNo": "WHYZRCB"
}, {
  "headquartersName": "芜湖圆融村镇银行",
  "headquartersShortName": "芜湖圆融村镇银行",
  "headquartersNo": "WHYHCUNZB"
}, {
  "headquartersName": "吴桥融信村镇银行",
  "headquartersShortName": "吴桥融信村镇银行",
  "headquartersNo": "WQRXCUNZB"
}, {
  "headquartersName": "吴忠市滨河村镇银行",
  "headquartersShortName": "吴忠市滨河村镇银行",
  "headquartersNo": "WZSBHCUNZB"
}, {
  "headquartersName": "五常惠民村镇银行",
  "headquartersShortName": "五常惠民村镇银行",
  "headquartersNo": "WCHMCUNZB"
}, {
  "headquartersName": "五大连池惠丰村镇银行",
  "headquartersShortName": "五大连池惠丰村镇银行",
  "headquartersNo": "WDLCHFCUNZB"
}, {
  "headquartersName": "五峰金谷村镇银行",
  "headquartersShortName": "五峰金谷村镇银行",
  "headquartersNo": "WFJGCUNZB"
}, {
  "headquartersName": "五华惠民村镇银行",
  "headquartersShortName": "五华惠民村镇银行",
  "headquartersNo": "WHHMCUNZB"
}, {
  "headquartersName": "五家渠国民村镇银行",
  "headquartersShortName": "五家渠国民村镇银行",
  "headquartersNo": "WJQGMCUNZB"
}, {
  "headquartersName": "五台莱商村镇银行",
  "headquartersShortName": "五台莱商村镇银行",
  "headquartersNo": "WTLSCUNZB"
}, {
  "headquartersName": "五原蒙银村镇银行",
  "headquartersShortName": "五原蒙银村镇银行",
  "headquartersNo": "WYMYCUNZB"
}, {
  "headquartersName": "武安村镇银行",
  "headquartersShortName": "武安村镇银行",
  "headquartersNo": "WACUNZB"
}, {
  "headquartersName": "武城圆融村镇银行",
  "headquartersShortName": "武城圆融村镇银行",
  "headquartersNo": "WCYRCUNZB"
}, {
  "headquartersName": "武川立农村镇银行",
  "headquartersShortName": "武川立农村镇银行",
  "headquartersNo": "WCLNCUNZB"
}, {
  "headquartersName": "武冈发展村镇银行",
  "headquartersShortName": "武冈发展村镇银行",
  "headquartersNo": "WGBSCUNZB"
}, {
  "headquartersName": "武汉东西湖扬子村镇银行",
  "headquartersShortName": "武汉东西湖扬子村镇银行",
  "headquartersNo": "WHDXHYZCUNZB"
}, {
  "headquartersName": "武汉江夏民生村镇银行",
  "headquartersShortName": "武汉江夏民生村镇银行",
  "headquartersNo": "WHJXMSCUNZB"
}, {
  "headquartersName": "武汉农村商业银行",
  "headquartersShortName": "武汉农商",
  "headquartersNo": "WHRCB"
}, {
  "headquartersName": "武汉众邦银行",
  "headquartersShortName": "武汉众邦",
  "headquartersNo": "WHZBB"
}, {
  "headquartersName": "武隆融兴村镇银行",
  "headquartersShortName": "武隆融兴村镇银行",
  "headquartersNo": "WLRXCUNZB"
}, {
  "headquartersName": "武宁恒通村镇银行",
  "headquartersShortName": "武宁恒通村镇银行",
  "headquartersNo": "WNHTCUNZB"
}, {
  "headquartersName": "武强家银村镇银行",
  "headquartersShortName": "武强家银村镇银行",
  "headquartersNo": "WQJYCUNZB"
}, {
  "headquartersName": "武威农村商业银行",
  "headquartersShortName": "武威农商",
  "headquartersNo": "WWRCB"
}, {
  "headquartersName": "武乡县泽都村镇银行",
  "headquartersShortName": "武乡县泽都村镇银行",
  "headquartersNo": "WXXZDCUNZB"
}, {
  "headquartersName": "武夷山本富村镇银行",
  "headquartersShortName": "武夷山本富村镇银行",
  "headquartersNo": "WYSBFCUNZB"
}, {
  "headquartersName": "武邑邢农商村镇银行",
  "headquartersShortName": "武邑邢农商村镇银行",
  "headquartersNo": "WYXNSCUNZB"
}, {
  "headquartersName": "武陟射阳村镇银行",
  "headquartersShortName": "武陟射阳村镇银行",
  "headquartersNo": "WZSYCUNZB"
}, {
  "headquartersName": "舞阳玉川村镇银行",
  "headquartersShortName": "舞阳玉川村镇银行",
  "headquartersNo": "WYYCCUNZB"
}, {
  "headquartersName": "西安高陵阳光村镇银行",
  "headquartersShortName": "西安高陵阳光村镇银行",
  "headquartersNo": "XAGLYGCUNZB"
}, {
  "headquartersName": "西安鄠邑农村商业银行",
  "headquartersShortName": "西安鄠邑农商",
  "headquartersNo": "XAHYRCB"
}, {
  "headquartersName": "西安雁塔恒通村镇银行",
  "headquartersShortName": "西安雁塔恒通村镇银行",
  "headquartersNo": "XAYTHTCUNZB"
}, {
  "headquartersName": "西安银行",
  "headquartersShortName": "西安",
  "headquartersNo": "XAYH"
}, {
  "headquartersName": "西安长安新华村镇银行",
  "headquartersShortName": "西安长安新华村镇银行",
  "headquartersNo": "XACAXHCUNZB"
}, {
  "headquartersName": "西班牙对外银行",
  "headquartersShortName": "西班牙对外银行",
  "headquartersNo": "BBVA"
}, {
  "headquartersName": "西班牙桑坦德银行",
  "headquartersShortName": "西班牙桑坦德",
  "headquartersNo": "SANTANDER"
}, {
  "headquartersName": "西藏堆龙民泰村镇银行",
  "headquartersShortName": "西藏堆龙民泰村镇银行",
  "headquartersNo": "XZDLMTCUNZB"
}, {
  "headquartersName": "西藏银行",
  "headquartersShortName": "西藏",
  "headquartersNo": "XICYH"
}, {
  "headquartersName": "西昌金信村镇银行",
  "headquartersShortName": "西昌金信村镇银行",
  "headquartersNo": "XCJXCUNZB"
}, {
  "headquartersName": "西丰鹿城村镇银行",
  "headquartersShortName": "西丰鹿城村镇银行",
  "headquartersNo": "XFLCCUNZB"
}, {
  "headquartersName": "西平中原村镇银行",
  "headquartersShortName": "西平中原村镇银行",
  "headquartersNo": "XPCFCUNZB"
}, {
  "headquartersName": "西双版纳勐海长江村镇银行",
  "headquartersShortName": "西双版纳勐海长江村镇银行",
  "headquartersNo": "XSBNZHCJCUNZB"
}, {
  "headquartersName": "西双版纳勐腊长江村镇银行",
  "headquartersShortName": "西双版纳勐腊长江村镇银行",
  "headquartersNo": "XSBNZLCJCUNZB"
}, {
  "headquartersName": "西乌珠穆沁蒙商惠丰村镇银行",
  "headquartersShortName": "西乌珠穆沁蒙商惠丰村镇银行",
  "headquartersNo": "XWZMQBSHFCUNZB"
}, {
  "headquartersName": "息烽发展村镇银行",
  "headquartersShortName": "息烽发展村镇银行",
  "headquartersNo": "XFBSQLCUNZB"
}, {
  "headquartersName": "浠水楚农商村镇银行",
  "headquartersShortName": "浠水楚农商村镇银行",
  "headquartersNo": "XSCNSCUNZB"
}, {
  "headquartersName": "锡林浩特农村合作银行",
  "headquartersShortName": "锡林浩特农村合作银行",
  "headquartersNo": "XLHTRCU"
}, {
  "headquartersName": "隰县新田村镇银行",
  "headquartersShortName": "隰县新田村镇银行",
  "headquartersNo": "ZXXTCUNZB"
}, {
  "headquartersName": "峡江洪都村镇银行",
  "headquartersShortName": "峡江洪都村镇银行",
  "headquartersNo": "XJHDCUNZB"
}, {
  "headquartersName": "霞浦刺桐红村镇银行",
  "headquartersShortName": "霞浦刺桐红村镇银行",
  "headquartersNo": "XPCTHCUNZB"
}, {
  "headquartersName": "夏津胶东村镇银行",
  "headquartersShortName": "夏津胶东村镇银行",
  "headquartersNo": "XJJDCUNZB"
}, {
  "headquartersName": "夏县河东村镇银行",
  "headquartersShortName": "夏县河东村镇银行",
  "headquartersNo": "XXHDCUNZB"
}, {
  "headquartersName": "仙桃楚农商村镇银行",
  "headquartersShortName": "仙桃楚农商村镇银行",
  "headquartersNo": "XTCNSCUNZB"
}, {
  "headquartersName": "献县融和村镇银行",
  "headquartersShortName": "献县融和村镇银行",
  "headquartersNo": "XXRHCUNZB"
}, {
  "headquartersName": "香河益民村镇银行",
  "headquartersShortName": "香河益民村镇银行",
  "headquartersNo": "XHYMCUNZB"
}, {
  "headquartersName": "湘潭农村商业银行",
  "headquartersShortName": "湘潭农商",
  "headquartersNo": "XTRCB"
}, {
  "headquartersName": "湘西长行村镇银行",
  "headquartersShortName": "湘西长行村镇银行",
  "headquartersNo": "XXCXCUNZB"
}, {
  "headquartersName": "湘乡市村镇银行",
  "headquartersShortName": "湘乡市村镇银行",
  "headquartersNo": "XXSCUNZB"
}, {
  "headquartersName": "襄城汇浦村镇银行",
  "headquartersShortName": "襄城汇浦村镇银行",
  "headquartersNo": "XCHPCUNZB"
}, {
  "headquartersName": "襄汾县万都村镇银行",
  "headquartersShortName": "襄汾县万都村镇银行",
  "headquartersNo": "XFXWDCUNZB"
}, {
  "headquartersName": "襄垣县融汇村镇银行",
  "headquartersShortName": "襄垣县融汇村镇银行",
  "headquartersNo": "XYXRHCUNZB"
}, {
  "headquartersName": "象山国民村镇银行",
  "headquartersShortName": "象山国民村镇银行",
  "headquartersNo": "XSGMCUNZB"
}, {
  "headquartersName": "孝昌本富村镇银行",
  "headquartersShortName": "孝昌本富村镇银行",
  "headquartersNo": "XCBFCUNZB"
}, {
  "headquartersName": "辛集齐鲁村镇银行",
  "headquartersShortName": "辛集齐鲁村镇银行",
  "headquartersNo": "XJQLCUNZB"
}, {
  "headquartersName": "忻州市忻府区秀都村镇银行",
  "headquartersShortName": "忻州市忻府区秀都村镇银行",
  "headquartersNo": "XZSXFQXDCUNZB"
}, {
  "headquartersName": "新安融兴村镇银行",
  "headquartersShortName": "新安融兴村镇银行",
  "headquartersNo": "XARXCUNZB"
}, {
  "headquartersName": "新都桂城村镇银行",
  "headquartersShortName": "新都桂城村镇银行",
  "headquartersNo": "XDGCCUNZB"
}, {
  "headquartersName": "新韩银行（中国）有限公司",
  "headquartersShortName": "新韩",
  "headquartersNo": "XHYH"
}, {
  "headquartersName": "新疆阿克苏农村商业银行",
  "headquartersShortName": "新疆阿克苏农商",
  "headquartersNo": "XJAKSRCB"
}, {
  "headquartersName": "新疆阿勒泰农村商业银行",
  "headquartersShortName": "新疆阿勒泰农商",
  "headquartersNo": "XJALTRCB"
}, {
  "headquartersName": "新疆阿瓦提农村商业银行",
  "headquartersShortName": "新疆阿瓦提农商",
  "headquartersNo": "XJAWTRCB"
}, {
  "headquartersName": "新疆北屯农村商业银行",
  "headquartersShortName": "新疆北屯农商",
  "headquartersNo": "XJBTRCB"
}, {
  "headquartersName": "新疆博湖农村商业银行",
  "headquartersShortName": "新疆博湖农商",
  "headquartersNo": "XJBHRCB"
}, {
  "headquartersName": "新疆博乐农村商业银行",
  "headquartersShortName": "新疆博乐农商",
  "headquartersNo": "XJBLRCB"
}, {
  "headquartersName": "新疆布尔津喀纳斯农村商业银行",
  "headquartersShortName": "新疆布尔津喀纳斯农商",
  "headquartersNo": "XJBEJKNSRCB"
}, {
  "headquartersName": "新疆昌吉农村商业银行",
  "headquartersShortName": "新疆昌吉农商",
  "headquartersNo": "XJCJRCB"
}, {
  "headquartersName": "新疆阜康农村商业银行",
  "headquartersShortName": "新疆阜康农商",
  "headquartersNo": "XJFKRCB"
}, {
  "headquartersName": "新疆富蕴农村商业银行",
  "headquartersShortName": "新疆富蕴农商",
  "headquartersNo": "XJFYRCB"
}, {
  "headquartersName": "新疆呼图壁农村商业银行",
  "headquartersShortName": "新疆呼图壁农商",
  "headquartersNo": "XJHTBRCB"
}, {
  "headquartersName": "新疆汇和银行",
  "headquartersShortName": "新疆汇和",
  "headquartersNo": "XJHHB"
}, {
  "headquartersName": "新疆霍城农村商业银行",
  "headquartersShortName": "新疆霍城农商",
  "headquartersNo": "XJHCRCB"
}, {
  "headquartersName": "新疆吉木萨尔农村商业银行",
  "headquartersShortName": "新疆吉木萨尔农商",
  "headquartersNo": "XJJMSERCB"
}, {
  "headquartersName": "新疆建新成功村镇银行",
  "headquartersShortName": "新疆建新成功村镇银行",
  "headquartersNo": "XJJXCGCUNZB"
}, {
  "headquartersName": "新疆喀什农村商业银行",
  "headquartersShortName": "新疆喀什农商",
  "headquartersNo": "XJKSRCB"
}, {
  "headquartersName": "新疆库尔勒富民村镇银行",
  "headquartersShortName": "新疆库尔勒富民村镇银行",
  "headquartersNo": "XJKELFMCUNZB"
}, {
  "headquartersName": "新疆库尔勒农村商业银行",
  "headquartersShortName": "新疆库尔勒农商",
  "headquartersNo": "XJKELRCB"
}, {
  "headquartersName": "新疆轮台农村商业银行",
  "headquartersShortName": "新疆轮台农商",
  "headquartersNo": "XJLTRCB"
}, {
  "headquartersName": "新疆绿洲国民村镇银行",
  "headquartersShortName": "新疆绿洲国民村镇银行",
  "headquartersNo": "XJLZGMCUNZB"
}, {
  "headquartersName": "新疆玛纳斯农村商业银行",
  "headquartersShortName": "新疆玛纳斯农商",
  "headquartersNo": "XJMNSRCB"
}, {
  "headquartersName": "新疆木垒农村商业银行",
  "headquartersShortName": "新疆木垒农商",
  "headquartersNo": "XJMLRCB"
}, {
  "headquartersName": "新疆奇台农村商业银行",
  "headquartersShortName": "新疆奇台农商",
  "headquartersNo": "XJQTRCB"
}, {
  "headquartersName": "新疆沙湾农村商业银行",
  "headquartersShortName": "新疆沙湾农商",
  "headquartersNo": "XJSWRCB"
}, {
  "headquartersName": "新疆沙雅农村商业银行",
  "headquartersShortName": "新疆沙雅农商",
  "headquartersNo": "XJSYRCB"
}, {
  "headquartersName": "新疆鄯善农村商业银行",
  "headquartersShortName": "新疆鄯善农商",
  "headquartersNo": "XJSSRB"
}, {
  "headquartersName": "新疆石河子交银村镇银行",
  "headquartersShortName": "新疆石河子交银村镇银行",
  "headquartersNo": "XJSHZJYCUNZB"
}, {
  "headquartersName": "新疆石河子农村合作银行",
  "headquartersShortName": "新疆石河子农村合作银行",
  "headquartersNo": "XJSHZRCU"
}, {
  "headquartersName": "新疆塔城农村商业银行",
  "headquartersShortName": "新疆塔城农商",
  "headquartersNo": "XJTCRCB"
}, {
  "headquartersName": "新疆天山农村商业银行",
  "headquartersShortName": "新疆天山农商",
  "headquartersNo": "XJTSRCB"
}, {
  "headquartersName": "新疆维吾尔自治区农村信用社联合社",
  "headquartersShortName": "新疆农信社",
  "headquartersNo": "XJNXS"
}, {
  "headquartersName": "新疆尉犁农村商业银行",
  "headquartersShortName": "新疆尉犁农商",
  "headquartersNo": "XJYLIRCB"
}, {
  "headquartersName": "新疆温宿农村商业银行",
  "headquartersShortName": "新疆温宿农商",
  "headquartersNo": "XJWSURCB"
}, {
  "headquartersName": "新疆乌鲁木齐农村商业银行",
  "headquartersShortName": "新疆乌鲁木齐农商",
  "headquartersNo": "XJWLMQRCB"
}, {
  "headquartersName": "新疆乌什农村商业银行",
  "headquartersShortName": "新疆乌什农商",
  "headquartersNo": "XJWUSRCB"
}, {
  "headquartersName": "新疆乌苏农村商业银行",
  "headquartersShortName": "新疆乌苏农商",
  "headquartersNo": "XJWSRCB"
}, {
  "headquartersName": "新疆新源农村商业银行",
  "headquartersShortName": "新疆新源农商",
  "headquartersNo": "XJXYRCB"
}, {
  "headquartersName": "新疆伊犁农村商业银行",
  "headquartersShortName": "新疆伊犁农商",
  "headquartersNo": "XJYLRCB"
}, {
  "headquartersName": "新疆伊宁农村商业银行",
  "headquartersShortName": "新疆伊宁农商",
  "headquartersNo": "XJYNRCB"
}, {
  "headquartersName": "新疆银行",
  "headquartersShortName": "新疆",
  "headquartersNo": "XJB"
}, {
  "headquartersName": "新疆昭苏农村商业银行",
  "headquartersShortName": "新疆昭苏农商",
  "headquartersNo": "XJZSRCB"
}, {
  "headquartersName": "新绛县新田村镇银行",
  "headquartersShortName": "新绛县新田村镇银行",
  "headquartersNo": "XZXXTCUNZB"
}, {
  "headquartersName": "新津珠江村镇银行",
  "headquartersShortName": "新津珠江村镇银行",
  "headquartersNo": "XJZJCUNZB"
}, {
  "headquartersName": "新联商业银行",
  "headquartersShortName": "新联",
  "headquartersNo": "XLSYYY"
}, {
  "headquartersName": "新密郑银村镇银行",
  "headquartersShortName": "新密郑银村镇银行",
  "headquartersNo": "XMZYCUNZB"
}, {
  "headquartersName": "新乡中原村镇银行",
  "headquartersShortName": "新乡中原村镇银行",
  "headquartersNo": "XXZYCUNZB"
}, {
  "headquartersName": "新余孔目江成功村镇银行",
  "headquartersShortName": "新余孔目江成功村镇银行",
  "headquartersNo": "XYKMJCGCUNZB"
}, {
  "headquartersName": "新余农村商业银行",
  "headquartersShortName": "新余农商",
  "headquartersNo": "XYRCB"
}, {
  "headquartersName": "新余渝水湘淮村镇银行",
  "headquartersShortName": "新余渝水湘淮村镇银行",
  "headquartersNo": "XYYSXHCUNZB"
}, {
  "headquartersName": "新郑郑银村镇银行",
  "headquartersShortName": "新郑郑银村镇银行",
  "headquartersNo": "XZZYCUNZB"
}, {
  "headquartersName": "信阳平桥中原村镇银行",
  "headquartersShortName": "信阳平桥中原村镇银行",
  "headquartersNo": "XYPQZYCUNZB"
}, {
  "headquartersName": "信阳珠江村镇银行",
  "headquartersShortName": "信阳珠江村镇银行",
  "headquartersNo": "XINYZJCUNZB"
}, {
  "headquartersName": "星展银行",
  "headquartersShortName": "星展",
  "headquartersNo": "DBSBANK"
}, {
  "headquartersName": "邢台县邢农商村镇银行",
  "headquartersShortName": "邢台县邢农商村镇银行",
  "headquartersNo": "XTXXNSCUNZB"
}, {
  "headquartersName": "邢台银行",
  "headquartersShortName": "邢台",
  "headquartersNo": "XTYH"
}, {
  "headquartersName": "行唐利丰村镇银行",
  "headquartersShortName": "行唐利丰村镇银行",
  "headquartersNo": "XTLFCUNZB"
}, {
  "headquartersName": "荥阳利丰村镇银行",
  "headquartersShortName": "荥阳利丰村镇银行",
  "headquartersNo": "XYLFCUNZB"
}, {
  "headquartersName": "兴安盟科尔沁蒙商村镇银行",
  "headquartersShortName": "兴安盟科尔沁蒙商村镇银行",
  "headquartersNo": "XAMKEQBSCUNZB"
}, {
  "headquartersName": "兴城长兴村镇银行",
  "headquartersShortName": "兴城长兴村镇银行",
  "headquartersNo": "XCCXCUNZB"
}, {
  "headquartersName": "兴福村镇银行",
  "headquartersShortName": "兴福村镇银行",
  "headquartersNo": "XFCUNZB"
}, {
  "headquartersName": "兴和蒙商村镇银行",
  "headquartersShortName": "兴和蒙商村镇银行",
  "headquartersNo": "XHBSCUNZB"
}, {
  "headquartersName": "兴化苏南村镇银行",
  "headquartersShortName": "兴化苏南村镇银行",
  "headquartersNo": "XHSNCUNZB"
}, {
  "headquartersName": "兴宁珠江村镇银行",
  "headquartersShortName": "兴宁珠江村镇银行",
  "headquartersNo": "XNZJCUNZB"
}, {
  "headquartersName": "兴仁振兴村镇银行",
  "headquartersShortName": "兴仁振兴村镇银行",
  "headquartersNo": "XRZXCUNZB"
}, {
  "headquartersName": "兴山本富村镇银行",
  "headquartersShortName": "兴山本富村镇银行",
  "headquartersNo": "XSBFCUNZB"
}, {
  "headquartersName": "兴县汇泽村镇银行",
  "headquartersShortName": "兴县汇泽村镇银行",
  "headquartersNo": "XXHZCUNZB"
}, {
  "headquartersName": "兴业银行",
  "headquartersShortName": "兴业",
  "headquartersNo": "CIB"
}, {
  "headquartersName": "兴义万丰村镇银行",
  "headquartersShortName": "兴义万丰村镇银行",
  "headquartersNo": "XYWFCUNZB"
}, {
  "headquartersName": "雄县丰源村镇银行",
  "headquartersShortName": "雄县丰源村镇银行",
  "headquartersNo": "XXFYCUNZB"
}, {
  "headquartersName": "修水九银村镇银行",
  "headquartersShortName": "修水九银村镇银行",
  "headquartersNo": "XSJYCUNZB"
}, {
  "headquartersName": "修文江海村镇银行",
  "headquartersShortName": "修文江海村镇银行",
  "headquartersNo": "XWJHCUNZB"
}, {
  "headquartersName": "徐州农村商业银行",
  "headquartersShortName": "徐州农商",
  "headquartersNo": "XZRCB"
}, {
  "headquartersName": "许昌农村商业银行",
  "headquartersShortName": "许昌农商",
  "headquartersNo": "XCRCB"
}, {
  "headquartersName": "许昌新浦村镇银行",
  "headquartersShortName": "许昌新浦村镇银行",
  "headquartersNo": "XCXPCUNZB"
}, {
  "headquartersName": "宣城皖南农村商业银行",
  "headquartersShortName": "宣城皖南农商",
  "headquartersNo": "XCWNRCB"
}, {
  "headquartersName": "宣汉诚民村镇银行",
  "headquartersShortName": "宣汉诚民村镇银行",
  "headquartersNo": "XHCMCUNZB"
}, {
  "headquartersName": "宣化中成村镇银行",
  "headquartersShortName": "宣化中成村镇银行",
  "headquartersNo": "XHZCCUNZB"
}, {
  "headquartersName": "寻甸中成村镇银行",
  "headquartersShortName": "寻甸中成村镇银行",
  "headquartersNo": "XDZCCUNZB"
}, {
  "headquartersName": "雅安农村商业银行",
  "headquartersShortName": "雅安农商",
  "headquartersNo": "YARCB"
}, {
  "headquartersName": "雅安市商业银行",
  "headquartersShortName": "雅安",
  "headquartersNo": "YASCCB"
}, {
  "headquartersName": "雅安雨城惠民村镇银行",
  "headquartersShortName": "雅安雨城惠民村镇银行",
  "headquartersNo": "YAYCHMCUNZB"
}, {
  "headquartersName": "烟台福山珠江村镇银行",
  "headquartersShortName": "烟台福山珠江村镇银行",
  "headquartersNo": "YTFSZJCUNZB"
}, {
  "headquartersName": "烟台农村商业银行",
  "headquartersShortName": "烟台农商",
  "headquartersNo": "YTRCB"
}, {
  "headquartersName": "烟台银行",
  "headquartersShortName": "烟台",
  "headquartersNo": "YTYH"
}, {
  "headquartersName": "鄢陵郑银村镇银行",
  "headquartersShortName": "鄢陵郑银村镇银行",
  "headquartersNo": "ZLZYCUNZB"
}, {
  "headquartersName": "延安农村商业银行",
  "headquartersShortName": "延安农商",
  "headquartersNo": "YANRCB"
}, {
  "headquartersName": "延边农村商业银行",
  "headquartersShortName": "延边农商",
  "headquartersNo": "YBRCB"
}, {
  "headquartersName": "延吉和润村镇银行",
  "headquartersShortName": "延吉和润村镇银行",
  "headquartersNo": "YJHRCUNZB"
}, {
  "headquartersName": "延寿融兴村镇银行",
  "headquartersShortName": "延寿融兴村镇银行",
  "headquartersNo": "YSRXCUNZB"
}, {
  "headquartersName": "沿河长征村镇银行",
  "headquartersShortName": "沿河长征村镇银行",
  "headquartersNo": "YHCZCUNZB"
}, {
  "headquartersName": "兖州中成村镇银行",
  "headquartersShortName": "兖州中成村镇银行",
  "headquartersNo": "SZZCCUNZB"
}, {
  "headquartersName": "偃师融兴村镇银行",
  "headquartersShortName": "偃师融兴村镇银行",
  "headquartersNo": "ZSRXCUNZB"
}, {
  "headquartersName": "扬中恒丰村镇银行",
  "headquartersShortName": "扬中恒丰村镇银行",
  "headquartersNo": "YZHFCUNZB"
}, {
  "headquartersName": "扬州广陵中成村镇银行",
  "headquartersShortName": "扬州广陵中成村镇银行",
  "headquartersNo": "YZGLZCCUNZB"
}, {
  "headquartersName": "扬州仪征玉丰村镇银行",
  "headquartersShortName": "扬州仪征玉丰村镇银行",
  "headquartersNo": "JSYZBSCUNZB"
}, {
  "headquartersName": "阳城县三禾村镇银行",
  "headquartersShortName": "阳城县三禾村镇银行",
  "headquartersNo": "YCXSHCUNZB"
}, {
  "headquartersName": "阳高县兴都村镇银行",
  "headquartersShortName": "阳高县兴都村镇银行",
  "headquartersNo": "YGXXDCUNZB"
}, {
  "headquartersName": "阳谷沪农商村镇银行",
  "headquartersShortName": "阳谷沪农商村镇银行",
  "headquartersNo": "YGHNSCUNZB"
}, {
  "headquartersName": "阳江阳春长江村镇银行",
  "headquartersShortName": "阳江阳春长江村镇银行",
  "headquartersNo": "YJYCCJCUNZB"
}, {
  "headquartersName": "阳曲县汇民村镇银行",
  "headquartersShortName": "阳曲县汇民村镇银行",
  "headquartersNo": "YQXHMCUNZB"
}, {
  "headquartersName": "阳泉农村商业银行",
  "headquartersShortName": "阳泉农商",
  "headquartersNo": "YQRCB"
}, {
  "headquartersName": "阳泉市商业银行",
  "headquartersShortName": "阳泉",
  "headquartersNo": "YQSCCB"
}, {
  "headquartersName": "阳新汉银村镇银行",
  "headquartersShortName": "阳新汉银村镇银行",
  "headquartersNo": "YXHYCUNZB"
}, {
  "headquartersName": "阳信河海村镇银行",
  "headquartersShortName": "阳信河海村镇银行",
  "headquartersNo": "YXHHCUNZB"
}, {
  "headquartersName": "伊川齐鲁村镇银行",
  "headquartersShortName": "伊川齐鲁村镇银行",
  "headquartersNo": "YICQLCUNZB"
}, {
  "headquartersName": "伊春农村商业银行",
  "headquartersShortName": "伊春农商",
  "headquartersNo": "YICRCB"
}, {
  "headquartersName": "伊金霍洛金谷村镇银行",
  "headquartersShortName": "伊金霍洛金谷村镇银行",
  "headquartersNo": "YJHLJGCUNZB"
}, {
  "headquartersName": "伊金霍洛六菱村镇银行",
  "headquartersShortName": "伊金霍洛六菱村镇银行",
  "headquartersNo": "YJHLLLCUNZB"
}, {
  "headquartersName": "伊犁国民村镇银行",
  "headquartersShortName": "伊犁国民村镇银行",
  "headquartersNo": "YLGMCUNZB"
}, {
  "headquartersName": "伊通榆银村镇银行",
  "headquartersShortName": "伊通榆银村镇银行",
  "headquartersNo": "YTYYCUNZB"
}, {
  "headquartersName": "依安润生村镇银行",
  "headquartersShortName": "依安润生村镇银行",
  "headquartersNo": "YARSCUNZB"
}, {
  "headquartersName": "依兰惠鑫村镇银行",
  "headquartersShortName": "依兰惠鑫村镇银行",
  "headquartersNo": "YLHXCUNZB"
}, {
  "headquartersName": "沂源博商村镇银行",
  "headquartersShortName": "沂源博商村镇银行",
  "headquartersNo": "YYBSCUNZB"
}, {
  "headquartersName": "宜宾农村商业银行",
  "headquartersShortName": "宜宾农商",
  "headquartersNo": "YIBRCB"
}, {
  "headquartersName": "宜宾市商业银行",
  "headquartersShortName": "宜宾",
  "headquartersNo": "YBSCCB"
}, {
  "headquartersName": "宜宾兴宜村镇银行",
  "headquartersShortName": "宜宾兴宜村镇银行",
  "headquartersNo": "YBXYCUNZB"
}, {
  "headquartersName": "宜春农村商业银行",
  "headquartersShortName": "宜春农商",
  "headquartersNo": "YCRCB"
}, {
  "headquartersName": "宜都民生村镇银行",
  "headquartersShortName": "宜都民生村镇银行",
  "headquartersNo": "YDMSCUNZB"
}, {
  "headquartersName": "宜黄抚商村镇银行",
  "headquartersShortName": "宜黄抚商村镇银行",
  "headquartersNo": "YHFSCUNZB"
}, {
  "headquartersName": "宜兴阳羡村镇银行",
  "headquartersShortName": "宜兴阳羡村镇银行",
  "headquartersNo": "YXYXCUNZB"
}, {
  "headquartersName": "宜章长行村镇银行",
  "headquartersShortName": "宜章长行村镇银行",
  "headquartersNo": "YZCXCUNZB"
}, {
  "headquartersName": "宜州深通村镇银行",
  "headquartersShortName": "宜州深通村镇银行",
  "headquartersNo": "YZSTCUNZB"
}, {
  "headquartersName": "弋阳蓝海村镇银行",
  "headquartersShortName": "弋阳蓝海村镇银行",
  "headquartersNo": "ZYLHCUNZB"
}, {
  "headquartersName": "益阳农村商业银行",
  "headquartersShortName": "益阳农商",
  "headquartersNo": "YYRCB"
}, {
  "headquartersName": "意大利联合圣保罗银行",
  "headquartersShortName": "意大利联合圣保罗",
  "headquartersNo": "YDLLHSBL"
}, {
  "headquartersName": "意大利裕信银行",
  "headquartersShortName": "意大利裕信",
  "headquartersNo": "UNICREDITO"
}, {
  "headquartersName": "银川掌政石银村镇银行",
  "headquartersShortName": "银川掌政石银村镇银行",
  "headquartersNo": "YCZZSYCUNZB"
}, {
  "headquartersName": "印度国家银行",
  "headquartersShortName": "印度国家",
  "headquartersNo": "INDIASB"
}, {
  "headquartersName": "印度尼西亚曼底利银行",
  "headquartersShortName": "印度尼西亚曼底利",
  "headquartersNo": "YDNXYMDLB"
}, {
  "headquartersName": "印江长征村镇银行",
  "headquartersShortName": "印江长征村镇银行",
  "headquartersNo": "YJCZCUNZB"
}, {
  "headquartersName": "应城融兴村镇银行",
  "headquartersShortName": "应城融兴村镇银行",
  "headquartersNo": "YCRXCUNZB"
}, {
  "headquartersName": "应县金都村镇银行",
  "headquartersShortName": "应县金都村镇银行",
  "headquartersNo": "YXJDCUNZB"
}, {
  "headquartersName": "英国巴克莱银行有限公司",
  "headquartersShortName": "英国巴克莱",
  "headquartersNo": "YGBLKYH"
}, {
  "headquartersName": "鹰潭农村商业银行",
  "headquartersShortName": "鹰潭农商",
  "headquartersNo": "YINGTRCB"
}, {
  "headquartersName": "鹰潭月湖恒通村镇银行",
  "headquartersShortName": "鹰潭月湖恒通村镇银行",
  "headquartersNo": "YTYHHTCUNZB"
}, {
  "headquartersName": "营口宏诚村镇银行",
  "headquartersShortName": "营口宏诚村镇银行",
  "headquartersNo": "YKHCCUNZB"
}, {
  "headquartersName": "营口农村商业银行",
  "headquartersShortName": "营口农商",
  "headquartersNo": "YKRCB"
}, {
  "headquartersName": "营口融生农村商业银行",
  "headquartersShortName": "营口融生农商",
  "headquartersNo": "YKRSRCB"
}, {
  "headquartersName": "营口沿海银行",
  "headquartersShortName": "营口沿海",
  "headquartersNo": "YKYHYH"
}, {
  "headquartersName": "营口银行",
  "headquartersShortName": "营口",
  "headquartersNo": "YKYH"
}, {
  "headquartersName": "永城齐鲁村镇银行",
  "headquartersShortName": "永城齐鲁村镇银行",
  "headquartersNo": "YCQLCUNZB"
}, {
  "headquartersName": "永丰洪都村镇银行",
  "headquartersShortName": "永丰洪都村镇银行",
  "headquartersNo": "YFHDCUNZB"
}, {
  "headquartersName": "永丰银行",
  "headquartersShortName": "永丰",
  "headquartersNo": "SINOPAC"
}, {
  "headquartersName": "永吉吉庆村镇银行",
  "headquartersShortName": "永吉吉庆村镇银行",
  "headquartersNo": "YJJQCUNZB"
}, {
  "headquartersName": "永济市三禾村镇银行",
  "headquartersShortName": "永济市三禾村镇银行",
  "headquartersNo": "YJSSHCUNZB"
}, {
  "headquartersName": "永靖县金城村镇银行",
  "headquartersShortName": "永靖县金城村镇银行",
  "headquartersNo": "YJXJCCUNZB"
}, {
  "headquartersName": "永隆银行有限公司",
  "headquartersShortName": "永隆",
  "headquartersNo": "WINGLBANK"
}, {
  "headquartersName": "永清吉银村镇银行",
  "headquartersShortName": "永清吉银村镇银行",
  "headquartersNo": "YQJYCUNZB"
}, {
  "headquartersName": "永新庐陵村镇银行",
  "headquartersShortName": "永新庐陵村镇银行",
  "headquartersNo": "YXLLCUNZB"
}, {
  "headquartersName": "永兴沪农商村镇银行",
  "headquartersShortName": "永兴沪农商村镇银行",
  "headquartersNo": "YXHNSCUNZB"
}, {
  "headquartersName": "永修浔银村镇银行",
  "headquartersShortName": "永修浔银村镇银行",
  "headquartersNo": "YXZYCUNZB"
}, {
  "headquartersName": "永州农村商业银行",
  "headquartersShortName": "永州农商",
  "headquartersNo": "YZRCB"
}, {
  "headquartersName": "友利银行（中国）有限公司",
  "headquartersShortName": "友利",
  "headquartersNo": "YLYH"
}, {
  "headquartersName": "右玉县长青村镇银行",
  "headquartersShortName": "右玉县长青村镇银行",
  "headquartersNo": "YYXCQCUNZB"
}, {
  "headquartersName": "余干恒通村镇银行",
  "headquartersShortName": "余干恒通村镇银行",
  "headquartersNo": "YGHTCUNZB"
}, {
  "headquartersName": "余姚通济村镇银行",
  "headquartersShortName": "余姚通济村镇银行",
  "headquartersNo": "YYTJCUNZB"
}, {
  "headquartersName": "盂县汇民村镇银行",
  "headquartersShortName": "盂县汇民村镇银行",
  "headquartersNo": "YXHMCUNZB"
}, {
  "headquartersName": "鱼台青隆村镇银行",
  "headquartersShortName": "鱼台青隆村镇银行",
  "headquartersNo": "YTQLCUNZB"
}, {
  "headquartersName": "榆林榆阳民生村镇银行",
  "headquartersShortName": "榆林榆阳民生村镇银行",
  "headquartersNo": "YLYYMSCUNZB"
}, {
  "headquartersName": "榆树融兴村镇银行",
  "headquartersShortName": "榆树融兴村镇银行",
  "headquartersNo": "YUSRXCUNZB"
}, {
  "headquartersName": "榆中浦发村镇银行",
  "headquartersShortName": "榆中浦发村镇银行",
  "headquartersNo": "YZPFCUNZB"
}, {
  "headquartersName": "虞城通商村镇银行",
  "headquartersShortName": "虞城通商村镇银行",
  "headquartersNo": "YCTSCUNZB"
}, {
  "headquartersName": "禹城胶东村镇银行",
  "headquartersShortName": "禹城胶东村镇银行",
  "headquartersNo": "YCJDCUNZB"
}, {
  "headquartersName": "禹州新民生村镇银行",
  "headquartersShortName": "禹州新民生村镇银行",
  "headquartersNo": "YZXMSCUNZB"
}, {
  "headquartersName": "玉屏长征村镇银行",
  "headquartersShortName": "玉屏长征村镇银行",
  "headquartersNo": "YPCZCUNZB"
}, {
  "headquartersName": "玉山三清山村镇银行",
  "headquartersShortName": "玉山三清山村镇银行",
  "headquartersNo": "YSSQSCUNZB"
}, {
  "headquartersName": "玉山银行",
  "headquartersShortName": "玉山",
  "headquartersNo": "YSYH"
}, {
  "headquartersName": "玉田大商村镇银行",
  "headquartersShortName": "玉田大商村镇银行",
  "headquartersNo": "YTDSCUNZB"
}, {
  "headquartersName": "玉溪澄江中成村镇银行",
  "headquartersShortName": "玉溪澄江中成村镇银行",
  "headquartersNo": "YXCJZCCUNZB"
}, {
  "headquartersName": "玉溪红塔村镇银行",
  "headquartersShortName": "玉溪红塔村镇银行",
  "headquartersNo": "YXHTCUNZB"
}, {
  "headquartersName": "元氏信融村镇银行",
  "headquartersShortName": "元氏信融村镇银行",
  "headquartersNo": "YSXRCUNZB"
}, {
  "headquartersName": "沅江浦发村镇银行",
  "headquartersShortName": "沅江浦发村镇银行",
  "headquartersNo": "YJPFCUNZB"
}, {
  "headquartersName": "垣曲县河东村镇银行",
  "headquartersShortName": "垣曲县河东村镇银行",
  "headquartersNo": "YQXHDCUNZB"
}, {
  "headquartersName": "原平市汇民村镇银行",
  "headquartersShortName": "原平市汇民村镇银行",
  "headquartersNo": "YPSHMCUNZB"
}, {
  "headquartersName": "远安金谷村镇银行",
  "headquartersShortName": "远安金谷村镇银行",
  "headquartersNo": "YAJGCUNZB"
}, {
  "headquartersName": "云安惠民村镇银行",
  "headquartersShortName": "云安惠民村镇银行",
  "headquartersNo": "YAHMCUNZB"
}, {
  "headquartersName": "云浮新兴东盈村镇银行",
  "headquartersShortName": "云浮新兴东盈村镇银行",
  "headquartersNo": "YFXXDYCUNZB"
}, {
  "headquartersName": "云梦楚农商村镇银行",
  "headquartersShortName": "云梦楚农商村镇银行",
  "headquartersNo": "YMCNSCUNZB"
}, {
  "headquartersName": "云南安宁稠州村镇银行",
  "headquartersShortName": "云南安宁稠州村镇银行",
  "headquartersNo": "YNANCZCUNZB"
}, {
  "headquartersName": "云南宾川农村商业银行",
  "headquartersShortName": "云南宾川农商",
  "headquartersNo": "YNBCRCB"
}, {
  "headquartersName": "云南昌宁农村商业银行",
  "headquartersShortName": "云南昌宁农商",
  "headquartersNo": "YNCNRCB"
}, {
  "headquartersName": "云南澄江农村商业银行",
  "headquartersShortName": "云南澄江农商",
  "headquartersNo": "YNCJRCB"
}, {
  "headquartersName": "云南楚雄农村商业银行",
  "headquartersShortName": "云南楚雄农商",
  "headquartersNo": "YNCXRCB"
}, {
  "headquartersName": "云南大理农村商业银行",
  "headquartersShortName": "云南大理农商",
  "headquartersNo": "YNDLRCB"
}, {
  "headquartersName": "云南大理渝农商村镇银行",
  "headquartersShortName": "云南大理渝农商村镇银行",
  "headquartersNo": "YNDLYNSCUNZB"
}, {
  "headquartersName": "云南大姚农村商业银行",
  "headquartersShortName": "云南大姚农商",
  "headquartersNo": "YNDYRCB"
}, {
  "headquartersName": "云南洱源农村商业银行",
  "headquartersShortName": "云南洱源农商",
  "headquartersNo": "YNEYRCB"
}, {
  "headquartersName": "云南凤庆农村商业银行",
  "headquartersShortName": "云南凤庆农商",
  "headquartersNo": "YNFQRCB"
}, {
  "headquartersName": "云南广南农村商业银行",
  "headquartersShortName": "云南广南农商",
  "headquartersNo": "YNGNRCB"
}, {
  "headquartersName": "云南河口农村商业银行",
  "headquartersShortName": "云南河口农商",
  "headquartersNo": "YNHKRCB"
}, {
  "headquartersName": "云南鹤庆农村商业银行",
  "headquartersShortName": "云南鹤庆农商",
  "headquartersNo": "YNHQRCB"
}, {
  "headquartersName": "云南鹤庆渝农商村镇银行",
  "headquartersShortName": "云南鹤庆渝农商村镇银行",
  "headquartersNo": "YNHQYNSCUNZB"
}, {
  "headquartersName": "云南红塔银行",
  "headquartersShortName": "云南红塔",
  "headquartersNo": "YNHTBANK"
}, {
  "headquartersName": "云南华宁农村商业银行",
  "headquartersShortName": "云南华宁农商",
  "headquartersNo": "YNHNRCB"
}, {
  "headquartersName": "云南会泽农村商业银行",
  "headquartersShortName": "云南会泽农商",
  "headquartersNo": "YNHZRCB"
}, {
  "headquartersName": "云南建水农村商业银行",
  "headquartersShortName": "云南建水农商",
  "headquartersNo": "YNJSRCB"
}, {
  "headquartersName": "云南剑川农村商业银行",
  "headquartersShortName": "云南剑川农商",
  "headquartersNo": "YNJCRCB"
}, {
  "headquartersName": "云南景东农村商业银行",
  "headquartersShortName": "云南景东农商",
  "headquartersNo": "YNJDRCB"
}, {
  "headquartersName": "云南景洪农村商业银行",
  "headquartersShortName": "云南景洪农商",
  "headquartersNo": "YNJHRCB"
}, {
  "headquartersName": "云南梁河农村商业银行",
  "headquartersShortName": "云南梁河农商",
  "headquartersNo": "YNLHRCB"
}, {
  "headquartersName": "云南临沧临翔农村商业银行",
  "headquartersShortName": "云南临沧临翔农商",
  "headquartersNo": "YNLCLXRCB"
}, {
  "headquartersName": "云南龙陵农村商业银行",
  "headquartersShortName": "云南龙陵农商",
  "headquartersNo": "YNLONGLRCB"
}, {
  "headquartersName": "云南隆阳农村商业银行",
  "headquartersShortName": "云南隆阳农商",
  "headquartersNo": "YNLYRCB"
}, {
  "headquartersName": "云南陇川农村商业银行",
  "headquartersShortName": "云南陇川农商",
  "headquartersNo": "YNLCRCB"
}, {
  "headquartersName": "云南陆良农村商业银行",
  "headquartersShortName": "云南陆良农商",
  "headquartersNo": "YNLLRCB"
}, {
  "headquartersName": "云南禄丰农村商业银行",
  "headquartersShortName": "云南禄丰农商",
  "headquartersNo": "YNLFRCB"
}, {
  "headquartersName": "云南罗平农村商业银行",
  "headquartersShortName": "云南罗平农商",
  "headquartersNo": "YNLPRCB"
}, {
  "headquartersName": "云南麻栗坡农村商业银行",
  "headquartersShortName": "云南麻栗坡农商",
  "headquartersNo": "YNMLPRCB"
}, {
  "headquartersName": "云南马关农村商业银行",
  "headquartersShortName": "云南马关农商",
  "headquartersNo": "YNMGRCB"
}, {
  "headquartersName": "云南马龙北银村镇银行",
  "headquartersShortName": "云南马龙北银村镇银行",
  "headquartersNo": "YNMLBYCUNZB"
}, {
  "headquartersName": "云南芒市农村商业银行",
  "headquartersShortName": "云南芒市农商",
  "headquartersNo": "YNMSRCB"
}, {
  "headquartersName": "云南蒙自农村商业银行",
  "headquartersShortName": "云南蒙自农商",
  "headquartersNo": "YNMZRCB"
}, {
  "headquartersName": "云南勐腊农村商业银行",
  "headquartersShortName": "云南勐腊农商",
  "headquartersNo": "YNMLRCB"
}, {
  "headquartersName": "云南孟连农村商业银行",
  "headquartersShortName": "云南孟连农商",
  "headquartersNo": "YNMLIRCB"
}, {
  "headquartersName": "云南弥渡农村商业银行",
  "headquartersShortName": "云南弥渡农商",
  "headquartersNo": "YNMDRCB"
}, {
  "headquartersName": "云南弥勒农村商业银行",
  "headquartersShortName": "云南弥勒农商",
  "headquartersNo": "YNMILRCB"
}, {
  "headquartersName": "云南南华农村商业银行",
  "headquartersShortName": "云南南华农商",
  "headquartersNo": "YNNHRCB"
}, {
  "headquartersName": "云南宁蒗农村商业银行",
  "headquartersShortName": "云南宁蒗农商",
  "headquartersNo": "YNNLRCB"
}, {
  "headquartersName": "云南普洱思茅农村商业银行",
  "headquartersShortName": "云南普洱思茅农商",
  "headquartersNo": "YNPESMRCB"
}, {
  "headquartersName": "云南瑞丽南屏农村商业银行",
  "headquartersShortName": "云南瑞丽南屏农商",
  "headquartersNo": "YNRLNPRCB"
}, {
  "headquartersName": "云南省农村信用社联合社",
  "headquartersShortName": "云南农信社",
  "headquartersNo": "YNNXS"
}, {
  "headquartersName": "云南施甸农村商业银行",
  "headquartersShortName": "云南施甸农商",
  "headquartersNo": "YNSDRCB"
}, {
  "headquartersName": "云南石屏北银村镇银行",
  "headquartersShortName": "云南石屏北银村镇银行",
  "headquartersNo": "YNSPBYCUNZB"
}, {
  "headquartersName": "云南双柏农村商业银行",
  "headquartersShortName": "云南双柏农商",
  "headquartersNo": "YNSBRCB"
}, {
  "headquartersName": "云南水富农村商业银行",
  "headquartersShortName": "云南水富农商",
  "headquartersNo": "YNSFRCB"
}, {
  "headquartersName": "云南嵩明农村商业银行",
  "headquartersShortName": "云南嵩明农商",
  "headquartersNo": "YNSMRCB"
}, {
  "headquartersName": "云南腾冲农村商业银行",
  "headquartersShortName": "云南腾冲农商",
  "headquartersNo": "YNTCRCB"
}, {
  "headquartersName": "云南通海农村商业银行",
  "headquartersShortName": "云南通海农商",
  "headquartersNo": "YNTHRCB"
}, {
  "headquartersName": "云南威信农村商业银行",
  "headquartersShortName": "云南威信农商",
  "headquartersNo": "YNWXRCB"
}, {
  "headquartersName": "云南巍山农村商业银行",
  "headquartersShortName": "云南巍山农商",
  "headquartersNo": "YNWSRCB"
}, {
  "headquartersName": "云南文山市农村商业银行",
  "headquartersShortName": "云南文山市农商",
  "headquartersNo": "YNWSSRCB"
}, {
  "headquartersName": "云南西山北银村镇银行",
  "headquartersShortName": "云南西山北银村镇银行",
  "headquartersNo": "YNXSBYCUNZB"
}, {
  "headquartersName": "云南西山渝农商村镇银行",
  "headquartersShortName": "云南西山渝农商村镇银行",
  "headquartersNo": "YNXSYNSCUNZB"
}, {
  "headquartersName": "云南香格里拉渝农商村镇银行",
  "headquartersShortName": "云南香格里拉渝农商村镇银行",
  "headquartersNo": "YNXGLLYNSCUNZB"
}, {
  "headquartersName": "云南祥云农村商业银行",
  "headquartersShortName": "云南祥云农商",
  "headquartersNo": "YNXYRCB"
}, {
  "headquartersName": "云南祥云渝农商村镇银行",
  "headquartersShortName": "云南祥云渝农商村镇银行",
  "headquartersNo": "YNXYYNSCUNZB"
}, {
  "headquartersName": "云南新平北银村镇银行",
  "headquartersShortName": "云南新平北银村镇银行",
  "headquartersNo": "YNXPBYCUNZB"
}, {
  "headquartersName": "云南新平农村商业银行",
  "headquartersShortName": "云南新平农商",
  "headquartersNo": "YNXPRCB"
}, {
  "headquartersName": "云南寻甸农村商业银行",
  "headquartersShortName": "云南寻甸农商",
  "headquartersNo": "YNXDRCB"
}, {
  "headquartersName": "云南砚山农村商业银行",
  "headquartersShortName": "云南砚山农商",
  "headquartersNo": "YNYSHRCB"
}, {
  "headquartersName": "云南彝良农村商业银行",
  "headquartersShortName": "云南彝良农商",
  "headquartersNo": "YNYILRCB"
}, {
  "headquartersName": "云南易门农村商业银行",
  "headquartersShortName": "云南易门农商",
  "headquartersNo": "YNYMRCB"
}, {
  "headquartersName": "云南盈江农村商业银行",
  "headquartersShortName": "云南盈江农商",
  "headquartersNo": "YNYJRCB"
}, {
  "headquartersName": "云南永平农村商业银行",
  "headquartersShortName": "云南永平农商",
  "headquartersNo": "YNYPRCB"
}, {
  "headquartersName": "云南永胜农村商业银行",
  "headquartersShortName": "云南永胜农商",
  "headquartersNo": "YNYSRCB"
}, {
  "headquartersName": "云南玉龙农村商业银行",
  "headquartersShortName": "云南玉龙农商",
  "headquartersNo": "YNYULRCB"
}, {
  "headquartersName": "云南玉溪红塔农村商业银行",
  "headquartersShortName": "云南玉溪红塔农商",
  "headquartersNo": "YNYXHTRCB"
}, {
  "headquartersName": "云南元江北银村镇银行",
  "headquartersShortName": "云南元江北银村镇银行",
  "headquartersNo": "YNYJBYCUNZB"
}, {
  "headquartersName": "云南元谋农村商业银行",
  "headquartersShortName": "云南元谋农商",
  "headquartersNo": "YNYUANMRCB"
}, {
  "headquartersName": "云南元阳农村商业银行",
  "headquartersShortName": "云南元阳农商",
  "headquartersNo": "YNYYRCB"
}, {
  "headquartersName": "云南云龙农村商业银行",
  "headquartersShortName": "云南云龙农商",
  "headquartersNo": "YNYLRCB"
}, {
  "headquartersName": "云南云县农村商业银行",
  "headquartersShortName": "云南云县农商",
  "headquartersNo": "YNYXRCB"
}, {
  "headquartersName": "云南昭通昭阳农村商业银行",
  "headquartersShortName": "云南昭通昭阳农商",
  "headquartersNo": "YNZTZYRCB"
}, {
  "headquartersName": "云霄润发村镇银行",
  "headquartersShortName": "云霄润发村镇银行",
  "headquartersNo": "YXRFCUNZB"
}, {
  "headquartersName": "郧西楚农商村镇银行",
  "headquartersShortName": "郧西楚农商村镇银行",
  "headquartersNo": "YXICNSCUNZB"
}, {
  "headquartersName": "郧县楚农商村镇银行",
  "headquartersShortName": "郧县楚农商村镇银行",
  "headquartersNo": "YXCNSCUNZB"
}, {
  "headquartersName": "筠连中成村镇银行",
  "headquartersShortName": "筠连中成村镇银行",
  "headquartersNo": "ZLZCCUNZB"
}, {
  "headquartersName": "郓城北海村镇银行",
  "headquartersShortName": "郓城北海村镇银行",
  "headquartersNo": "ZCBHCUNZB"
}, {
  "headquartersName": "赞皇隆兴村镇银行",
  "headquartersShortName": "赞皇隆兴村镇银行",
  "headquartersNo": "ZHLXCUNZB"
}, {
  "headquartersName": "枣强丰源村镇银行",
  "headquartersShortName": "枣强丰源村镇银行",
  "headquartersNo": "ZQFYCUNZB"
}, {
  "headquartersName": "枣庄农村商业银行",
  "headquartersShortName": "枣庄农商",
  "headquartersNo": "ZZRCB"
}, {
  "headquartersName": "枣庄银行",
  "headquartersShortName": "枣庄",
  "headquartersNo": "ZAZYH"
}, {
  "headquartersName": "泽州浦发村镇银行",
  "headquartersShortName": "泽州浦发村镇银行",
  "headquartersNo": "ZZPFCUNZB"
}, {
  "headquartersName": "扎赉特蒙银村镇银行",
  "headquartersShortName": "扎赉特蒙银村镇银行",
  "headquartersNo": "ZZTMYCUNZB"
}, {
  "headquartersName": "扎兰屯蒙银村镇银行",
  "headquartersShortName": "扎兰屯蒙银村镇银行",
  "headquartersNo": "ZLTMYCUNZB"
}, {
  "headquartersName": "渣打银行（中国）有限责任公司",
  "headquartersShortName": "渣打",
  "headquartersNo": "SCB"
}, {
  "headquartersName": "湛江廉江长江村镇银行",
  "headquartersShortName": "湛江廉江长江村镇银行",
  "headquartersNo": "ZJLJCJCUNZB"
}, {
  "headquartersName": "湛江农村商业银行",
  "headquartersShortName": "湛江农商",
  "headquartersNo": "ZJRCB"
}, {
  "headquartersName": "张北信达村镇银行",
  "headquartersShortName": "张北信达村镇银行",
  "headquartersNo": "ZBXDCUNZB"
}, {
  "headquartersName": "张家界农村商业银行",
  "headquartersShortName": "张家界农商",
  "headquartersNo": "ZJJRCB"
}, {
  "headquartersName": "张家口崇礼邢农商村镇银行",
  "headquartersShortName": "张家口崇礼邢农商村镇银行",
  "headquartersNo": "ZJKCLXNSCUNZB"
}, {
  "headquartersName": "张家口农村商业银行",
  "headquartersShortName": "张家口农商",
  "headquartersNo": "ZJKRCB"
}, {
  "headquartersName": "张家口万全家银村镇银行",
  "headquartersShortName": "张家口万全家银村镇银行",
  "headquartersNo": "ZJKWQJYCUNZB"
}, {
  "headquartersName": "张家口宣化家银村镇银行",
  "headquartersShortName": "张家口宣化家银村镇银行",
  "headquartersNo": "ZJKXHJYCUNZB"
}, {
  "headquartersName": "张家口银行",
  "headquartersShortName": "张家口",
  "headquartersNo": "ZJKYH"
}, {
  "headquartersName": "张掖农村商业银行",
  "headquartersShortName": "张掖农商",
  "headquartersNo": "ZYRCB"
}, {
  "headquartersName": "章丘齐鲁村镇银行",
  "headquartersShortName": "章丘齐鲁村镇银行",
  "headquartersNo": "ZQQLCUNZB"
}, {
  "headquartersName": "彰化商业银行",
  "headquartersShortName": "彰化",
  "headquartersNo": "ZHB"
}, {
  "headquartersName": "漳浦民生村镇银行",
  "headquartersShortName": "漳浦民生村镇银行",
  "headquartersNo": "ZPMSCUNZB"
}, {
  "headquartersName": "樟树顺银村镇银行",
  "headquartersShortName": "樟树顺银村镇银行",
  "headquartersNo": "ZSSYCUNZB"
}, {
  "headquartersName": "长安银行",
  "headquartersShortName": "长安",
  "headquartersNo": "CAYH"
}, {
  "headquartersName": "长白山农村商业银行",
  "headquartersShortName": "长白山农商",
  "headquartersNo": "CBSRCB"
}, {
  "headquartersName": "长白榆银村镇银行",
  "headquartersShortName": "长白榆银村镇银行",
  "headquartersNo": "CBYYCUNZB"
}, {
  "headquartersName": "长城华西银行",
  "headquartersShortName": "长城华西",
  "headquartersNo": "GWB"
}, {
  "headquartersName": "长春朝阳和润村镇银行",
  "headquartersShortName": "长春朝阳和润村镇银行",
  "headquartersNo": "CCCYHRCUNZB"
}, {
  "headquartersName": "长春二道农商村镇银行",
  "headquartersShortName": "长春二道农商村镇银行",
  "headquartersNo": "CCEDNSCUNZB"
}, {
  "headquartersName": "长春发展农村商业银行",
  "headquartersShortName": "长春发展农商",
  "headquartersNo": "CCFZRCB"
}, {
  "headquartersName": "长春高新惠民村镇银行",
  "headquartersShortName": "长春高新惠民村镇银行",
  "headquartersNo": "CCGXHMCUNZB"
}, {
  "headquartersName": "长春经开融丰村镇银行",
  "headquartersShortName": "长春经开融丰村镇银行",
  "headquartersNo": "CCJKRFCUNZB"
}, {
  "headquartersName": "长春净月榆银村镇银行",
  "headquartersShortName": "长春净月榆银村镇银行",
  "headquartersNo": "CCJYYYCUNZB"
}, {
  "headquartersName": "长春宽城融汇村镇银行",
  "headquartersShortName": "长春宽城融汇村镇银行",
  "headquartersNo": "CCKCRHCUNZB"
}, {
  "headquartersName": "长春绿园融泰村镇银行",
  "headquartersShortName": "长春绿园融泰村镇银行",
  "headquartersNo": "CCLYRTCUNZB"
}, {
  "headquartersName": "长春南关惠民村镇银行",
  "headquartersShortName": "长春南关惠民村镇银行",
  "headquartersNo": "CCNGHMCUNZB"
}, {
  "headquartersName": "长春农村商业银行",
  "headquartersShortName": "长春农商",
  "headquartersNo": "CCRCB"
}, {
  "headquartersName": "长春双阳吉银村镇银行",
  "headquartersShortName": "长春双阳吉银村镇银行",
  "headquartersNo": "CCSYJYCUNZB"
}, {
  "headquartersName": "长葛轩辕村镇银行",
  "headquartersShortName": "长葛轩辕村镇银行",
  "headquartersNo": "CGXYCUNZB"
}, {
  "headquartersName": "长岭蛟银村镇银行",
  "headquartersShortName": "长岭蛟银村镇银行",
  "headquartersNo": "CLZYCUNZB"
}, {
  "headquartersName": "长宁中成村镇银行",
  "headquartersShortName": "长宁中成村镇银行",
  "headquartersNo": "CNZCCUNZB"
}, {
  "headquartersName": "长沙农村商业银行",
  "headquartersShortName": "长沙农商",
  "headquartersNo": "CSHARCB"
}, {
  "headquartersName": "长沙星沙沪农商村镇银行",
  "headquartersShortName": "长沙星沙沪农商村镇银行",
  "headquartersNo": "CSXSHNSCUNZB"
}, {
  "headquartersName": "长沙银行",
  "headquartersShortName": "长沙",
  "headquartersNo": "CSCB"
}, {
  "headquartersName": "长顺富民村镇银行",
  "headquartersShortName": "长顺富民村镇银行",
  "headquartersNo": "CSFMCUNZB"
}, {
  "headquartersName": "长汀汀州红村镇银行",
  "headquartersShortName": "长汀汀州红村镇银行",
  "headquartersNo": "CTTZHCUNZB"
}, {
  "headquartersName": "长垣民生村镇银行",
  "headquartersShortName": "长垣民生村镇银行",
  "headquartersNo": "CYMSCUNZB"
}, {
  "headquartersName": "长治潞州农村商业银行",
  "headquartersShortName": "长治潞州农商",
  "headquartersNo": "CZLZRCB"
}, {
  "headquartersName": "长治市上党区泰都村镇银行",
  "headquartersShortName": "长治市上党区泰都村镇银行",
  "headquartersNo": "CZXTDCUNZB"
}, {
  "headquartersName": "长治银行",
  "headquartersShortName": "长治",
  "headquartersNo": "CHANGZYH"
}, {
  "headquartersName": "长治漳泽农村商业银行",
  "headquartersShortName": "长治漳泽农商",
  "headquartersNo": "CZZZRCB"
}, {
  "headquartersName": "长子县融汇村镇银行",
  "headquartersShortName": "长子县融汇村镇银行",
  "headquartersNo": "CZRHCUNZB"
}, {
  "headquartersName": "招商银行",
  "headquartersShortName": "招行",
  "headquartersNo": "CMBCHINA"
}, {
  "headquartersName": "昭通昭阳富滇村镇银行",
  "headquartersShortName": "昭通昭阳富滇村镇银行",
  "headquartersNo": "ZTZYFDCUNZB"
}, {
  "headquartersName": "兆丰国际商业银行",
  "headquartersShortName": "兆丰国际",
  "headquartersNo": "ZFGJB"
}, {
  "headquartersName": "肇庆农村商业银行",
  "headquartersShortName": "肇庆农商",
  "headquartersNo": "ZQRCB"
}, {
  "headquartersName": "柘城黄淮村镇银行",
  "headquartersShortName": "柘城黄淮村镇银行",
  "headquartersNo": "ZCHHCUNZB"
}, {
  "headquartersName": "柘荣刺桐红村镇银行",
  "headquartersShortName": "柘荣刺桐红村镇银行",
  "headquartersNo": "ZRCTHCUNZB"
}, {
  "headquartersName": "浙江安吉交银村镇银行",
  "headquartersShortName": "浙江安吉交银村镇银行",
  "headquartersNo": "ZJAJJYCUNZB"
}, {
  "headquartersName": "浙江安吉农村商业银行",
  "headquartersShortName": "浙江安吉农商",
  "headquartersNo": "ZJAJRCB"
}, {
  "headquartersName": "浙江苍南农村商业银行",
  "headquartersShortName": "浙江苍南农商",
  "headquartersNo": "ZJCNRCB"
}, {
  "headquartersName": "浙江常山联合村镇银行",
  "headquartersShortName": "浙江常山联合村镇银行",
  "headquartersNo": "ZJCSLHCUNZB"
}, {
  "headquartersName": "浙江常山农村商业银行",
  "headquartersShortName": "浙江常山农商",
  "headquartersNo": "ZJCSRCB"
}, {
  "headquartersName": "浙江稠州商业银行",
  "headquartersShortName": "浙江稠州",
  "headquartersNo": "CZCB"
}, {
  "headquartersName": "浙江淳安农村商业银行",
  "headquartersShortName": "浙江淳安农商",
  "headquartersNo": "ZJCARCB"
}, {
  "headquartersName": "浙江岱山稠州村镇银行",
  "headquartersShortName": "浙江岱山稠州村镇银行",
  "headquartersNo": "ZJZSCZCUNZB"
}, {
  "headquartersName": "浙江岱山农村商业银行",
  "headquartersShortName": "浙江岱山农商",
  "headquartersNo": "ZJDSRCB"
}, {
  "headquartersName": "浙江德清湖商村镇银行",
  "headquartersShortName": "浙江德清湖商村镇银行",
  "headquartersNo": "ZJDQHSCUNZB"
}, {
  "headquartersName": "浙江德清农村商业银行",
  "headquartersShortName": "浙江德清农商",
  "headquartersNo": "ZJDQRCB"
}, {
  "headquartersName": "浙江定海德商村镇银行",
  "headquartersShortName": "浙江定海德商村镇银行",
  "headquartersNo": "ZJDHDSCUNZB"
}, {
  "headquartersName": "浙江东阳富民村镇银行",
  "headquartersShortName": "浙江东阳富民村镇银行",
  "headquartersNo": "ZJDYFMCUNZB"
}, {
  "headquartersName": "浙江东阳农村商业银行",
  "headquartersShortName": "浙江东阳农商",
  "headquartersNo": "ZJDYRCB"
}, {
  "headquartersName": "浙江洞头富民村镇银行",
  "headquartersShortName": "浙江洞头富民村镇银行",
  "headquartersNo": "ZJDTFMCUNZB"
}, {
  "headquartersName": "浙江富阳恒通村镇银行",
  "headquartersShortName": "浙江富阳恒通村镇银行",
  "headquartersNo": "ZJFYHTCUNZB"
}, {
  "headquartersName": "浙江富阳农村商业银行",
  "headquartersShortName": "浙江富阳农商",
  "headquartersNo": "ZJFYRCB"
}, {
  "headquartersName": "浙江海宁德商村镇银行",
  "headquartersShortName": "浙江海宁德商村镇银行",
  "headquartersNo": "ZJHNDSCUNZB"
}, {
  "headquartersName": "浙江海宁农村商业银行",
  "headquartersShortName": "浙江海宁农商",
  "headquartersNo": "ZJHNRCB"
}, {
  "headquartersName": "浙江海盐湖商村镇银行",
  "headquartersShortName": "浙江海盐湖商村镇银行",
  "headquartersNo": "ZJHYHSCUNZB"
}, {
  "headquartersName": "浙江海盐农村商业银行",
  "headquartersShortName": "浙江海盐农商",
  "headquartersNo": "ZJHYRCB"
}, {
  "headquartersName": "浙江杭州余杭农村商业银行",
  "headquartersShortName": "浙江杭州余杭农商",
  "headquartersNo": "HZYHRCB"
}, {
  "headquartersName": "浙江禾城农村商业银行",
  "headquartersShortName": "浙江禾城农商",
  "headquartersNo": "ZJHCRCB"
}, {
  "headquartersName": "浙江嘉善联合村镇银行",
  "headquartersShortName": "浙江嘉善联合村镇银行",
  "headquartersNo": "ZJJSLHCUNZB"
}, {
  "headquartersName": "浙江嘉善农村商业银行",
  "headquartersShortName": "浙江嘉善农商",
  "headquartersNo": "ZJJIASRCB"
}, {
  "headquartersName": "浙江建德湖商村镇银行",
  "headquartersShortName": "浙江建德湖商村镇银行",
  "headquartersNo": "ZJJDHSCUNZB"
}, {
  "headquartersName": "浙江建德农村商业银行",
  "headquartersShortName": "浙江建德农商",
  "headquartersNo": "ZJJDRCB"
}, {
  "headquartersName": "浙江江山农村商业银行",
  "headquartersShortName": "浙江江山农商",
  "headquartersNo": "ZJJSRCB"
}, {
  "headquartersName": "浙江金华成泰农村商业银行",
  "headquartersShortName": "浙江金华成泰农商",
  "headquartersNo": "ZJJHCTRCB"
}, {
  "headquartersName": "浙江缙云联合村镇银行",
  "headquartersShortName": "浙江缙云联合村镇银行",
  "headquartersNo": "ZJZYLHCUNZB"
}, {
  "headquartersName": "浙江缙云农村商业银行",
  "headquartersShortName": "浙江缙云农商",
  "headquartersNo": "ZJJYRCB"
}, {
  "headquartersName": "浙江景宁农村商业银行",
  "headquartersShortName": "浙江景宁农商",
  "headquartersNo": "ZJJNRCB"
}, {
  "headquartersName": "浙江景宁银座村镇银行",
  "headquartersShortName": "浙江景宁银座村镇银行",
  "headquartersNo": "JNYZCUNZB"
}, {
  "headquartersName": "浙江开化农村商业银行",
  "headquartersShortName": "浙江开化农商",
  "headquartersNo": "ZJKHRCB"
}, {
  "headquartersName": "浙江柯桥联合村镇银行",
  "headquartersShortName": "浙江柯桥联合村镇银行",
  "headquartersNo": "ZJKQLHCUNZB"
}, {
  "headquartersName": "浙江兰溪农村商业银行",
  "headquartersShortName": "浙江兰溪农商",
  "headquartersNo": "ZJLXRCB"
}, {
  "headquartersName": "浙江兰溪越商村镇银行",
  "headquartersShortName": "浙江兰溪越商村镇银行",
  "headquartersNo": "ZJLXYSCUNZB"
}, {
  "headquartersName": "浙江乐清联合村镇银行",
  "headquartersShortName": "浙江乐清联合村镇银行",
  "headquartersNo": "ZJLQLHCUNZB"
}, {
  "headquartersName": "浙江乐清农村商业银行",
  "headquartersShortName": "浙江乐清农商",
  "headquartersNo": "ZJLQRCB"
}, {
  "headquartersName": "浙江丽水莲都农村商业银行",
  "headquartersShortName": "浙江丽水莲都农商",
  "headquartersNo": "ZJLSLDRCB"
}, {
  "headquartersName": "浙江临安农村商业银行",
  "headquartersShortName": "浙江临安农商",
  "headquartersNo": "ZJLARCB"
}, {
  "headquartersName": "浙江临安中信村镇银行",
  "headquartersShortName": "浙江临安中信村镇银行",
  "headquartersNo": "ZJLAZXCUNZB"
}, {
  "headquartersName": "浙江临海湖商村镇银行",
  "headquartersShortName": "浙江临海湖商村镇银行",
  "headquartersNo": "ZJLHHSCUNZB"
}, {
  "headquartersName": "浙江临海农村商业银行",
  "headquartersShortName": "浙江临海农商",
  "headquartersNo": "ZJLHRCB"
}, {
  "headquartersName": "浙江龙港农村商业银行",
  "headquartersShortName": "浙江龙港农商",
  "headquartersNo": "ZJLGRCB"
}, {
  "headquartersName": "浙江龙泉民泰村镇银行",
  "headquartersShortName": "浙江龙泉民泰村镇银行",
  "headquartersNo": "ZJLQMTCUNZB"
}, {
  "headquartersName": "浙江龙泉农村商业银行",
  "headquartersShortName": "浙江龙泉农商",
  "headquartersNo": "ZJLQUANRCB"
}, {
  "headquartersName": "浙江龙游农村商业银行",
  "headquartersShortName": "浙江龙游农商",
  "headquartersNo": "ZJLYRCB"
}, {
  "headquartersName": "浙江龙游义商村镇银行",
  "headquartersShortName": "浙江龙游义商村镇银行",
  "headquartersNo": "ZJLYYSCUNZB"
}, {
  "headquartersName": "浙江民泰商业银行",
  "headquartersShortName": "民泰",
  "headquartersNo": "MINTAIBANK"
}, {
  "headquartersName": "浙江南浔农村商业银行",
  "headquartersShortName": "浙江南浔农商",
  "headquartersNo": "ZJNXRCB"
}, {
  "headquartersName": "浙江磐安农村商业银行",
  "headquartersShortName": "浙江磐安农商",
  "headquartersNo": "ZJPARCB"
}, {
  "headquartersName": "浙江磐安婺商村镇银行",
  "headquartersShortName": "浙江磐安婺商村镇银行",
  "headquartersNo": "ZJPAZSCUNZB"
}, {
  "headquartersName": "浙江平湖工银村镇银行",
  "headquartersShortName": "浙江平湖工银村镇银行",
  "headquartersNo": "ZJPHGYCUNZB"
}, {
  "headquartersName": "浙江平湖农村商业银行",
  "headquartersShortName": "浙江平湖农商",
  "headquartersNo": "ZJPHRCB"
}, {
  "headquartersName": "浙江平阳农村商业银行",
  "headquartersShortName": "浙江平阳农商",
  "headquartersNo": "ZJPYRCB"
}, {
  "headquartersName": "浙江平阳浦发村镇银行",
  "headquartersShortName": "浙江平阳浦发村镇银行",
  "headquartersNo": "ZJPYPFCUNZB"
}, {
  "headquartersName": "浙江浦江嘉银村镇银行",
  "headquartersShortName": "浙江浦江嘉银村镇银行",
  "headquartersNo": "ZJPJJYCUNZB"
}, {
  "headquartersName": "浙江浦江农村商业银行",
  "headquartersShortName": "浙江浦江农商",
  "headquartersNo": "ZJPJRCB"
}, {
  "headquartersName": "浙江青田农村商业银行",
  "headquartersShortName": "浙江青田农商",
  "headquartersNo": "ZJQTRCB"
}, {
  "headquartersName": "浙江庆元农村商业银行",
  "headquartersShortName": "浙江庆元农商",
  "headquartersNo": "ZJQYRCB"
}, {
  "headquartersName": "浙江庆元泰隆村镇银行",
  "headquartersShortName": "浙江庆元泰隆村镇银行",
  "headquartersNo": "ZJQYTLCUNZB"
}, {
  "headquartersName": "浙江衢州柯城农村商业银行",
  "headquartersShortName": "浙江衢州柯城农商",
  "headquartersNo": "ZJQZKCRCB"
}, {
  "headquartersName": "浙江衢州衢江农村商业银行",
  "headquartersShortName": "浙江衢州衢江农商",
  "headquartersNo": "ZJQZQJRCB"
}, {
  "headquartersName": "浙江衢州衢江上银村镇银行",
  "headquartersShortName": "浙江衢州衢江上银村镇银行",
  "headquartersNo": "ZJZZZJSYCUNZB"
}, {
  "headquartersName": "浙江瑞安湖商村镇银行",
  "headquartersShortName": "浙江瑞安湖商村镇银行",
  "headquartersNo": "ZJRAHSCUNZB"
}, {
  "headquartersName": "浙江瑞安农村商业银行",
  "headquartersShortName": "浙江瑞安农商",
  "headquartersNo": "ZJRARCB"
}, {
  "headquartersName": "浙江三门农村商业银行",
  "headquartersShortName": "浙江三门农商",
  "headquartersNo": "ZJSMRCB"
}, {
  "headquartersName": "浙江三门银座村镇银行",
  "headquartersShortName": "浙江三门银座村镇银行",
  "headquartersNo": "SMYZCUNZB"
}, {
  "headquartersName": "浙江上虞富民村镇银行",
  "headquartersShortName": "浙江上虞富民村镇银行",
  "headquartersNo": "ZJSYFMCUNZB"
}, {
  "headquartersName": "浙江上虞农村商业银行",
  "headquartersShortName": "浙江上虞农商",
  "headquartersNo": "ZJSYRCB"
}, {
  "headquartersName": "浙江绍兴恒信农村商业银行",
  "headquartersShortName": "浙江绍兴恒信农商",
  "headquartersNo": "ZJSXHXRCB"
}, {
  "headquartersName": "浙江绍兴瑞丰农村商业银行",
  "headquartersShortName": "浙江绍兴瑞丰农商",
  "headquartersNo": "ZJSXRFRCB"
}, {
  "headquartersName": "浙江省农村信用社联合社",
  "headquartersShortName": "浙江农信社",
  "headquartersNo": "ZJNXS"
}, {
  "headquartersName": "浙江嵊泗农村商业银行",
  "headquartersShortName": "浙江嵊泗农商",
  "headquartersNo": "ZJSSRCB"
}, {
  "headquartersName": "浙江嵊州农村商业银行",
  "headquartersShortName": "浙江嵊州农商",
  "headquartersNo": "ZJSZRCB"
}, {
  "headquartersName": "浙江嵊州瑞丰村镇银行",
  "headquartersShortName": "浙江嵊州瑞丰村镇银行",
  "headquartersNo": "ZJSZRFCUNZB"
}, {
  "headquartersName": "浙江松阳恒通村镇银行",
  "headquartersShortName": "浙江松阳恒通村镇银行",
  "headquartersNo": "ZJSYHTCUNZB"
}, {
  "headquartersName": "浙江松阳农村商业银行",
  "headquartersShortName": "浙江松阳农商",
  "headquartersNo": "ZJSONGYRCB"
}, {
  "headquartersName": "浙江遂昌富民村镇银行",
  "headquartersShortName": "浙江遂昌富民村镇银行",
  "headquartersNo": "ZJSCFMCUNZB"
}, {
  "headquartersName": "浙江遂昌农村商业银行",
  "headquartersShortName": "浙江遂昌农商",
  "headquartersNo": "ZJSCRCB"
}, {
  "headquartersName": "浙江台州黄岩恒升村镇银行",
  "headquartersShortName": "浙江台州黄岩恒升村镇银行",
  "headquartersNo": "ZJTZHYHSCUNZB"
}, {
  "headquartersName": "浙江台州黄岩农村商业银行",
  "headquartersShortName": "浙江台州黄岩农商",
  "headquartersNo": "ZJTZHYRCB"
}, {
  "headquartersName": "浙江台州椒江农村商业银行",
  "headquartersShortName": "浙江台州椒江农商",
  "headquartersNo": "ZJTZJJRCB"
}, {
  "headquartersName": "浙江台州路桥富民村镇银行",
  "headquartersShortName": "浙江台州路桥富民村镇银行",
  "headquartersNo": "ZJTZLQFMCUNZB"
}, {
  "headquartersName": "浙江台州路桥农村商业银行",
  "headquartersShortName": "浙江台州路桥农商",
  "headquartersNo": "ZJTZLQRCB"
}, {
  "headquartersName": "浙江泰隆商业银行",
  "headquartersShortName": "泰隆",
  "headquartersNo": "ZJTLCCB"
}, {
  "headquartersName": "浙江泰顺农村商业银行",
  "headquartersShortName": "浙江泰顺农商",
  "headquartersNo": "ZJTSRCB"
}, {
  "headquartersName": "浙江泰顺温银村镇银行",
  "headquartersShortName": "浙江泰顺温银村镇银行",
  "headquartersNo": "ZJTSWYCUNZB"
}, {
  "headquartersName": "浙江天台民生村镇银行",
  "headquartersShortName": "浙江天台民生村镇银行",
  "headquartersNo": "ZJTTMSCUNZB"
}, {
  "headquartersName": "浙江天台农村商业银行",
  "headquartersShortName": "浙江天台农商",
  "headquartersNo": "ZJTTRCB"
}, {
  "headquartersName": "浙江桐庐恒丰村镇银行",
  "headquartersShortName": "浙江桐庐恒丰村镇银行",
  "headquartersNo": "ZJTLHFCUNZB"
}, {
  "headquartersName": "浙江桐庐农村商业银行",
  "headquartersShortName": "浙江桐庐农商",
  "headquartersNo": "ZJTLRCB"
}, {
  "headquartersName": "浙江桐乡民泰村镇银行",
  "headquartersShortName": "浙江桐乡民泰村镇银行",
  "headquartersNo": "ZJTXMTCUNZB"
}, {
  "headquartersName": "浙江桐乡农村商业银行",
  "headquartersShortName": "浙江桐乡农商",
  "headquartersNo": "ZJTXRCB"
}, {
  "headquartersName": "浙江网商银行股份有限公司",
  "headquartersShortName": "网商",
  "headquartersNo": "ZJWS"
}, {
  "headquartersName": "浙江温岭联合村镇银行",
  "headquartersShortName": "浙江温岭联合村镇银行",
  "headquartersNo": "ZJWLLHCUNZB"
}, {
  "headquartersName": "浙江温岭农村商业银行",
  "headquartersShortName": "浙江温岭农商",
  "headquartersNo": "ZJWLRCB"
}, {
  "headquartersName": "浙江温州洞头农村商业银行",
  "headquartersShortName": "浙江温州洞头农商",
  "headquartersNo": "ZJWZDTRCB"
}, {
  "headquartersName": "浙江温州龙湾农村商业银行",
  "headquartersShortName": "浙江温州龙湾农商",
  "headquartersNo": "ZJWZLWRCB"
}, {
  "headquartersName": "浙江温州鹿城农村商业银行",
  "headquartersShortName": "浙江温州鹿城农商",
  "headquartersNo": "ZJWZLCRCB"
}, {
  "headquartersName": "浙江温州瓯海农村商业银行",
  "headquartersShortName": "浙江温州瓯海农商",
  "headquartersNo": "ZJWZOHRCB"
}, {
  "headquartersName": "浙江文成北银村镇银行",
  "headquartersShortName": "浙江文成北银村镇银行",
  "headquartersNo": "ZJWCBYCUNZB"
}, {
  "headquartersName": "浙江文成农村商业银行",
  "headquartersShortName": "浙江文成农商",
  "headquartersNo": "ZJWCRCB"
}, {
  "headquartersName": "浙江武义农村商业银行",
  "headquartersShortName": "浙江武义农商",
  "headquartersNo": "ZJWYRCB"
}, {
  "headquartersName": "浙江仙居富民村镇银行",
  "headquartersShortName": "浙江仙居富民村镇银行",
  "headquartersNo": "ZJXJFMCUNZB"
}, {
  "headquartersName": "浙江仙居农村商业银行",
  "headquartersShortName": "浙江仙居农商",
  "headquartersNo": "ZJXJRCB"
}, {
  "headquartersName": "浙江萧山湖商村镇银行",
  "headquartersShortName": "浙江萧山湖商村镇银行",
  "headquartersNo": "ZJXSHSCUNZB"
}, {
  "headquartersName": "浙江萧山农村商业银行",
  "headquartersShortName": "浙江萧山农商",
  "headquartersNo": "ZJXSRCB"
}, {
  "headquartersName": "浙江新昌农村商业银行",
  "headquartersShortName": "浙江新昌农商",
  "headquartersNo": "ZJXCRCB"
}, {
  "headquartersName": "浙江新昌浦发村镇银行",
  "headquartersShortName": "浙江新昌浦发村镇银行",
  "headquartersNo": "ZJXCPFCUNZB"
}, {
  "headquartersName": "浙江秀洲德商村镇银行",
  "headquartersShortName": "浙江秀洲德商村镇银行",
  "headquartersNo": "ZJXZDSCUNZB"
}, {
  "headquartersName": "浙江义乌联合村镇银行",
  "headquartersShortName": "浙江义乌联合村镇银行",
  "headquartersNo": "ZJYWLHCUNZB"
}, {
  "headquartersName": "浙江义乌农村商业银行",
  "headquartersShortName": "浙江义乌农商",
  "headquartersNo": "ZJYWRCB"
}, {
  "headquartersName": "浙江永嘉恒升村镇银行",
  "headquartersShortName": "浙江永嘉恒升村镇银行",
  "headquartersNo": "ZJYJHSCUNZB"
}, {
  "headquartersName": "浙江永嘉农村商业银行",
  "headquartersShortName": "浙江永嘉农商",
  "headquartersNo": "ZJYJRCB"
}, {
  "headquartersName": "浙江永康农村商业银行",
  "headquartersShortName": "浙江永康农商",
  "headquartersNo": "ZJYKRCB"
}, {
  "headquartersName": "浙江永康农银村镇银行",
  "headquartersShortName": "浙江永康农银村镇银行",
  "headquartersNo": "ZJYKNYCUNZB"
}, {
  "headquartersName": "浙江余杭德商村镇银行",
  "headquartersShortName": "浙江余杭德商村镇银行",
  "headquartersNo": "ZJYHDSCUNZB"
}, {
  "headquartersName": "浙江玉环农村商业银行",
  "headquartersShortName": "浙江玉环农商",
  "headquartersNo": "ZJYHRCB"
}, {
  "headquartersName": "浙江玉环永兴村镇银行",
  "headquartersShortName": "浙江玉环永兴村镇银行",
  "headquartersNo": "ZJYHYXCUNZB"
}, {
  "headquartersName": "浙江云和联合村镇银行",
  "headquartersShortName": "浙江云和联合村镇银行",
  "headquartersNo": "ZJYHLHCUNZB"
}, {
  "headquartersName": "浙江云和农村商业银行",
  "headquartersShortName": "浙江云和农商",
  "headquartersNo": "ZJYHERCB"
}, {
  "headquartersName": "浙江长兴联合村镇银行",
  "headquartersShortName": "浙江长兴联合村镇银行",
  "headquartersNo": "ZJCXLHCUNZB"
}, {
  "headquartersName": "浙江长兴农村商业银行",
  "headquartersShortName": "浙江长兴农商",
  "headquartersNo": "ZJCXRCB"
}, {
  "headquartersName": "浙江舟山定海海洋农村商业银行",
  "headquartersShortName": "浙江舟山定海海洋农商",
  "headquartersNo": "ZJZSDHHYRCB"
}, {
  "headquartersName": "浙江舟山普陀稠州村镇银行",
  "headquartersShortName": "浙江舟山普陀稠州村镇银行",
  "headquartersNo": "ZJZSPTCZCUNZB"
}, {
  "headquartersName": "浙江舟山普陀农村商业银行",
  "headquartersShortName": "浙江舟山普陀农商",
  "headquartersNo": "ZJZSPTRCB"
}, {
  "headquartersName": "浙江诸暨联合村镇银行",
  "headquartersShortName": "浙江诸暨联合村镇银行",
  "headquartersNo": "ZJZZLHCUNZB"
}, {
  "headquartersName": "浙江诸暨农村商业银行",
  "headquartersShortName": "浙江诸暨农商",
  "headquartersNo": "ZJZJRCB"
}, {
  "headquartersName": "浙商银行",
  "headquartersShortName": "浙商",
  "headquartersNo": "CZ"
}, {
  "headquartersName": "贞丰兴贞村镇银行",
  "headquartersShortName": "贞丰兴贞村镇银行",
  "headquartersNo": "ZFXZCUNZB"
}, {
  "headquartersName": "镇江润州长江村镇银行",
  "headquartersShortName": "镇江润州长江村镇银行",
  "headquartersNo": "ZJRZCJCUNZB"
}, {
  "headquartersName": "镇宁汇商村镇银行",
  "headquartersShortName": "镇宁汇商村镇银行",
  "headquartersNo": "ZNHSCUNZB"
}, {
  "headquartersName": "正定恒升村镇银行",
  "headquartersShortName": "正定恒升村镇银行",
  "headquartersNo": "ZDHSCUNZB"
}, {
  "headquartersName": "正蓝旗汇泽村镇银行",
  "headquartersShortName": "正蓝旗汇泽村镇银行",
  "headquartersNo": "ZLQHZCUNZB"
}, {
  "headquartersName": "正阳玉川村镇银行",
  "headquartersShortName": "正阳玉川村镇银行",
  "headquartersNo": "ZYYCCUNZB"
}, {
  "headquartersName": "郑州金水厦农商村镇银行",
  "headquartersShortName": "郑州金水厦农商村镇银行",
  "headquartersNo": "ZZJSSNSCUNZB"
}, {
  "headquartersName": "郑州农村商业银行",
  "headquartersShortName": "郑州农商",
  "headquartersNo": "ZHENGZRCB"
}, {
  "headquartersName": "郑州银行",
  "headquartersShortName": "郑州",
  "headquartersNo": "ZHENGZYH"
}, {
  "headquartersName": "郑州珠江村镇银行",
  "headquartersShortName": "郑州珠江村镇银行",
  "headquartersNo": "ZZZJCUNZB"
}, {
  "headquartersName": "枝江汉银村镇银行",
  "headquartersShortName": "枝江汉银村镇银行",
  "headquartersNo": "ZJHYCUNZB"
}, {
  "headquartersName": "织金惠民村镇银行",
  "headquartersShortName": "织金惠民村镇银行",
  "headquartersNo": "ZJHMCUNZB"
}, {
  "headquartersName": "志丹民生村镇银行",
  "headquartersShortName": "志丹民生村镇银行",
  "headquartersNo": "ZDMSCUNZB"
}, {
  "headquartersName": "中德住房储蓄银行有限责任公司",
  "headquartersShortName": "中德住房储蓄",
  "headquartersNo": "SGB"
}, {
  "headquartersName": "中国工商银行",
  "headquartersShortName": "工行",
  "headquartersNo": "ICBC"
}, {
  "headquartersName": "中国光大银行",
  "headquartersShortName": "光大",
  "headquartersNo": "CEB"
}, {
  "headquartersName": "中国建设银行",
  "headquartersShortName": "建行",
  "headquartersNo": "CCB"
}, {
  "headquartersName": "中国进出口银行",
  "headquartersShortName": "进出口",
  "headquartersNo": "EXIMBANK"
}, {
  "headquartersName": "中国民生银行",
  "headquartersShortName": "民生",
  "headquartersNo": "CMBC"
}, {
  "headquartersName": "中国农业发展银行",
  "headquartersShortName": "农发",
  "headquartersNo": "NYFZYH"
}, {
  "headquartersName": "中国农业银行",
  "headquartersShortName": "农行",
  "headquartersNo": "ABC"
}, {
  "headquartersName": "中国信托商业银行",
  "headquartersShortName": "中国信托商业银行",
  "headquartersNo": "CTBCBANK"
}, {
  "headquartersName": "中国银行",
  "headquartersShortName": "中行",
  "headquartersNo": "BOC"
}, {
  "headquartersName": "中国邮政储蓄银行",
  "headquartersShortName": "邮储",
  "headquartersNo": "PSBC"
}, {
  "headquartersName": "中江融兴村镇银行",
  "headquartersShortName": "中江融兴村镇银行",
  "headquartersNo": "ZJRXCUNZB"
}, {
  "headquartersName": "中牟郑银村镇银行",
  "headquartersShortName": "中牟郑银村镇银行",
  "headquartersNo": "ZMZYCUNZB"
}, {
  "headquartersName": "中山东凤珠江村镇银行",
  "headquartersShortName": "中山东凤珠江村镇银行",
  "headquartersNo": "ZSDFZJCUNZB"
}, {
  "headquartersName": "中山古镇南粤村镇银行",
  "headquartersShortName": "中山古镇南粤村镇银行",
  "headquartersNo": "ZSGZNYCUNZB"
}, {
  "headquartersName": "中山农村商业银行",
  "headquartersShortName": "中山农商",
  "headquartersNo": "ZSRCB"
}, {
  "headquartersName": "中山小榄村镇银行",
  "headquartersShortName": "中山小榄村镇银行",
  "headquartersNo": "ZSXZCUNZB"
}, {
  "headquartersName": "中卫香山村镇银行",
  "headquartersShortName": "中卫香山村镇银行",
  "headquartersNo": "ZWXSCUNZB"
}, {
  "headquartersName": "中信百信银行",
  "headquartersShortName": "中信百信",
  "headquartersNo": "HXBXB"
}, {
  "headquartersName": "中信银行",
  "headquartersShortName": "中信",
  "headquartersNo": "ECITIC"
}, {
  "headquartersName": "中阳县太行村镇银行",
  "headquartersShortName": "中阳县太行村镇银行",
  "headquartersNo": "ZYXTXCUNZB"
}, {
  "headquartersName": "中银富登村镇银行",
  "headquartersShortName": "中银富登村镇银行",
  "headquartersNo": "ZYFDCUNZB"
}, {
  "headquartersName": "中原银行",
  "headquartersShortName": "中原",
  "headquartersNo": "ZYYH"
}, {
  "headquartersName": "钟祥民生村镇银行",
  "headquartersShortName": "钟祥民生村镇银行",
  "headquartersNo": "ZXMSCUNZB"
}, {
  "headquartersName": "重庆巴南浦发村镇银行",
  "headquartersShortName": "重庆巴南浦发村镇银行",
  "headquartersNo": "ZQBNPFCUNZB"
}, {
  "headquartersName": "重庆北碚稠州村镇银行",
  "headquartersShortName": "重庆北碚稠州村镇银行",
  "headquartersNo": "ZQBZCZCUNZB"
}, {
  "headquartersName": "重庆璧山工银村镇银行",
  "headquartersShortName": "重庆璧山工银村镇银行",
  "headquartersNo": "ZQZSGYCUNZB"
}, {
  "headquartersName": "重庆大足汇丰村镇银行",
  "headquartersShortName": "重庆大足汇丰村镇银行",
  "headquartersNo": "ZQDZHFCUNZB"
}, {
  "headquartersName": "重庆丰都汇丰村镇银行",
  "headquartersShortName": "重庆丰都汇丰村镇银行",
  "headquartersNo": "ZQFDHFCUNZB"
}, {
  "headquartersName": "重庆富民银行",
  "headquartersShortName": "重庆富民",
  "headquartersNo": "CQFMYH"
}, {
  "headquartersName": "重庆江北恒丰村镇银行",
  "headquartersShortName": "重庆江北恒丰村镇银行",
  "headquartersNo": "ZQJBHFCUNZB"
}, {
  "headquartersName": "重庆江津石银村镇银行",
  "headquartersShortName": "重庆江津石银村镇银行",
  "headquartersNo": "ZQJJSYCUNZB"
}, {
  "headquartersName": "重庆九龙坡民泰村镇银行",
  "headquartersShortName": "重庆九龙坡民泰村镇银行",
  "headquartersNo": "ZQJLPMTCUNZB"
}, {
  "headquartersName": "重庆开州泰业村镇银行",
  "headquartersShortName": "重庆开州泰业村镇银行",
  "headquartersNo": "KXTYCUNZB"
}, {
  "headquartersName": "重庆梁平澳新村镇银行",
  "headquartersShortName": "重庆梁平澳新村镇银行",
  "headquartersNo": "ZQLPAXCUNZB"
}, {
  "headquartersName": "重庆南川石银村镇银行",
  "headquartersShortName": "重庆南川石银村镇银行",
  "headquartersNo": "ZQNCSYCUNZB"
}, {
  "headquartersName": "重庆农村商业银行",
  "headquartersShortName": "重庆农商",
  "headquartersNo": "CQRCB"
}, {
  "headquartersName": "重庆彭水民泰村镇银行",
  "headquartersShortName": "重庆彭水民泰村镇银行",
  "headquartersNo": "ZQPSMTCUNZB"
}, {
  "headquartersName": "重庆黔江银座村镇银行",
  "headquartersShortName": "重庆黔江银座村镇银行",
  "headquartersNo": "QJYZCUNZB"
}, {
  "headquartersName": "重庆荣昌汇丰村镇银行",
  "headquartersShortName": "重庆荣昌汇丰村镇银行",
  "headquartersNo": "ZQRCHFCUNZB"
}, {
  "headquartersName": "重庆三峡银行",
  "headquartersShortName": "重庆三峡",
  "headquartersNo": "CQSXYH"
}, {
  "headquartersName": "重庆市大渡口融兴村镇银行",
  "headquartersShortName": "重庆市大渡口融兴村镇银行",
  "headquartersNo": "ZQSDDKRXCUNZB"
}, {
  "headquartersName": "重庆市沙坪坝融兴村镇银行",
  "headquartersShortName": "重庆市沙坪坝融兴村镇银行",
  "headquartersNo": "ZQSSPBRXCUNZB"
}, {
  "headquartersName": "重庆市酉阳融兴村镇银行",
  "headquartersShortName": "重庆市酉阳融兴村镇银行",
  "headquartersNo": "ZQSYYRXCUNZB"
}, {
  "headquartersName": "重庆铜梁浦发村镇银行",
  "headquartersShortName": "重庆铜梁浦发村镇银行",
  "headquartersNo": "ZQTLPFCUNZB"
}, {
  "headquartersName": "重庆秀山北银村镇银行",
  "headquartersShortName": "重庆秀山北银村镇银行",
  "headquartersNo": "ZQXSBYCUNZB"
}, {
  "headquartersName": "重庆银行",
  "headquartersShortName": "重庆",
  "headquartersNo": "CQYH"
}, {
  "headquartersName": "重庆永川北银村镇银行",
  "headquartersShortName": "重庆永川北银村镇银行",
  "headquartersNo": "ZQYCBYCUNZB"
}, {
  "headquartersName": "重庆渝北银座村镇银行",
  "headquartersShortName": "重庆渝北银座村镇银行",
  "headquartersNo": "YBYZCUNZB"
}, {
  "headquartersName": "重庆云阳恒丰村镇银行",
  "headquartersShortName": "重庆云阳恒丰村镇银行",
  "headquartersNo": "ZQYYHFCUNZB"
}, {
  "headquartersName": "重庆忠县稠州村镇银行",
  "headquartersShortName": "重庆忠县稠州村镇银行",
  "headquartersNo": "ZQZXCZCUNZB"
}, {
  "headquartersName": "周口农村商业银行",
  "headquartersShortName": "周口农商",
  "headquartersNo": "ZKRCB"
}, {
  "headquartersName": "周宁刺桐红村镇银行",
  "headquartersShortName": "周宁刺桐红村镇银行",
  "headquartersNo": "ZNCTHCUNZB"
}, {
  "headquartersName": "珠海横琴村镇银行",
  "headquartersShortName": "珠海横琴村镇银行",
  "headquartersNo": "ZHHQCUNZB"
}, {
  "headquartersName": "珠海华润银行",
  "headquartersShortName": "珠海华润",
  "headquartersNo": "ZHCCB"
}, {
  "headquartersName": "珠海南屏村镇银行",
  "headquartersShortName": "珠海南屏村镇银行",
  "headquartersNo": "ZHNPCUNZB"
}, {
  "headquartersName": "珠海农村商业银行",
  "headquartersShortName": "珠海农商",
  "headquartersNo": "ZHRCB"
}, {
  "headquartersName": "株洲农村商业银行",
  "headquartersShortName": "株洲农商",
  "headquartersNo": "ZHUZRCB"
}, {
  "headquartersName": "株洲县融兴村镇银行",
  "headquartersShortName": "株洲县融兴村镇银行",
  "headquartersNo": "ZZXRXCUNZB"
}, {
  "headquartersName": "竹山楚农商村镇银行",
  "headquartersShortName": "竹山楚农商村镇银行",
  "headquartersNo": "ZSCNSCUNZB"
}, {
  "headquartersName": "竹溪楚农商村镇银行",
  "headquartersShortName": "竹溪楚农商村镇银行",
  "headquartersNo": "ZXCNSCUNZB"
}, {
  "headquartersName": "驻马店农村商业银行",
  "headquartersShortName": "驻马店农商",
  "headquartersNo": "ZMDRCB"
}, {
  "headquartersName": "庄河汇通村镇银行",
  "headquartersShortName": "庄河汇通村镇银行",
  "headquartersNo": "ZHHTCUNZB"
}, {
  "headquartersName": "准格尔旗蒙商村镇银行",
  "headquartersShortName": "准格尔旗蒙商村镇银行",
  "headquartersNo": "ZGEQBSCUNZB"
}, {
  "headquartersName": "涿鹿利丰村镇银行",
  "headquartersShortName": "涿鹿利丰村镇银行",
  "headquartersNo": "ZLLFCUNZB"
}, {
  "headquartersName": "涿州中成村镇银行",
  "headquartersShortName": "涿州中成村镇银行",
  "headquartersNo": "ZZZCCUNZB"
}, {
  "headquartersName": "卓资蒙银村镇银行",
  "headquartersShortName": "卓资蒙银村镇银行",
  "headquartersNo": "ZZMYCUNZB"
}, {
  "headquartersName": "资溪九银村镇银行",
  "headquartersShortName": "资溪九银村镇银行",
  "headquartersNo": "ZXJYCUNZB"
}, {
  "headquartersName": "资兴浦发村镇银行",
  "headquartersShortName": "资兴浦发村镇银行",
  "headquartersNo": "ZXPFCUNZB"
}, {
  "headquartersName": "资阳民生村镇银行",
  "headquartersShortName": "资阳民生村镇银行",
  "headquartersNo": "ZYMSCUNZB"
}, {
  "headquartersName": "资阳农村商业银行",
  "headquartersShortName": "资阳农商",
  "headquartersNo": "ZIYRCB"
}, {
  "headquartersName": "淄博博山北海村镇银行",
  "headquartersShortName": "淄博博山北海村镇银行",
  "headquartersNo": "ZBBSBHCUNZB"
}, {
  "headquartersName": "淄博博山农村商业银行",
  "headquartersShortName": "淄博博山农商",
  "headquartersNo": "ZBBSRCB"
}, {
  "headquartersName": "淄博淄川北海村镇银行",
  "headquartersShortName": "淄博淄川北海村镇银行",
  "headquartersNo": "ZBZCBHCUNZB"
}, {
  "headquartersName": "淄博淄川农村商业银行",
  "headquartersShortName": "淄博淄川农商",
  "headquartersNo": "ZBZCRCB"
}, {
  "headquartersName": "紫云富民村镇银行",
  "headquartersShortName": "紫云富民村镇银行",
  "headquartersNo": "ZYFMCUNZB"
}, {
  "headquartersName": "自贡农村商业银行",
  "headquartersShortName": "自贡农商",
  "headquartersNo": "ZGRCB"
}, {
  "headquartersName": "自贡银行股份有限公司",
  "headquartersShortName": "自贡",
  "headquartersNo": "ZGCCB"
}, {
  "headquartersName": "自贡中成村镇银行",
  "headquartersShortName": "自贡中成村镇银行",
  "headquartersNo": "ZGZCCUNZB"
}, {
  "headquartersName": "邹平浦发村镇银行",
  "headquartersShortName": "邹平浦发村镇银行",
  "headquartersNo": "ZPPFCUNZB"
}, {
  "headquartersName": "遵化融和村镇银行",
  "headquartersShortName": "遵化融和村镇银行",
  "headquartersNo": "ZHRHCUNZB"
}, {
  "headquartersName": "遵义播州汇隆村镇银行",
  "headquartersShortName": "遵义播州汇隆村镇银行",
  "headquartersNo": "ZYBZHLCUNZB"
}, {
  "headquartersName": "遵义红花岗富民村镇银行",
  "headquartersShortName": "遵义红花岗富民村镇银行",
  "headquartersNo": "ZYHHGFMCUNZB"
}, {
  "headquartersName": "遵义汇川黔兴村镇银行",
  "headquartersShortName": "遵义汇川黔兴村镇银行",
  "headquartersNo": "ZYHCQXCUNZB"
}, {
  "headquartersName": "遵义市商业银行",
  "headquartersShortName": "遵义",
  "headquartersNo": "ZYCCB"
}, {
  "headquartersName": "遵义新蒲长征村镇银行",
  "headquartersShortName": "遵义新蒲长征村镇银行",
  "headquartersNo": "ZYXPCZCUNZB"
}, {
  "headquartersName": "左云县长青村镇银行",
  "headquartersShortName": "左云县长青村镇银行",
  "headquartersNo": "ZYXCQCUNZB"
}];