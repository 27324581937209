var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ft12",
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            fit: "",
            border: "",
            "highlight-current-row": "",
            "span-method": _vm.objectSpanMethod,
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "tableEmpty" }, [
              _c("img", {
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "专场封面", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "30px", "margin-top": "4px" },
                      attrs: { src: scope.row.album_image + "!120a" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.album_status === 1
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "danger" } },
                          [_vm._v("预展中")]
                        )
                      : scope.row.album_status === 2
                      ? _c("el-tag", { attrs: { size: "small" } }, [
                          _vm._v("进行中"),
                        ])
                      : scope.row.album_status === 3
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "danger" } },
                          [_vm._v("已结束")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.is_show === 0
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "info" } },
                          [_vm._v("已隐藏")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "专场名称", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.album_name))]),
                    _vm._v(" "),
                    _c("span", [_vm._v("ID:" + _vm._s(scope.row.album_id))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "专场类型", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "2px" } },
                        [
                          scope.row.album_type == 1
                            ? [_vm._v("限时拍")]
                            : scope.row.album_type == 2
                            ? [_vm._v("即时拍")]
                            : scope.row.album_type == 3
                            ? [_vm._v("同步拍")]
                            : scope.row.album_type == 4
                            ? [_vm._v("即刻拍")]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "拍卖时间", "min-width": "330" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.isSearch
                      ? [
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("预展："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.formatDate(
                                scope.row.scheduled_show_time * 1000,
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            ) + "\n          "
                          ),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("开拍："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.formatDate(
                                scope.row.album_start_time * 1000,
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            ) + "\n          "
                          ),
                          scope.row.album_type === 1 &&
                          scope.row.end_type === 2 &&
                          scope.row.album_status !== 3
                            ? [
                                _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v("结拍："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.first_end_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) + " 开始\n          "
                                ),
                              ]
                            : scope.row.album_type === 1
                            ? [
                                _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v("结拍："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.album_end_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  )
                                ),
                              ]
                            : scope.row.album_end_time
                            ? [
                                _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v("结拍："),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.album_end_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  )
                                ),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "拍卖厅", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "80px" },
                        on: {
                          change: function ($event) {
                            return _vm.setAlbumGroup(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.group,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "group", $$v)
                          },
                          expression: "scope.row.group",
                        },
                      },
                      [
                        _c("el-option", { attrs: { value: "A" } }, [
                          _vm._v("A"),
                        ]),
                        _vm._v(" "),
                        _c("el-option", { attrs: { value: "B" } }, [
                          _vm._v("B"),
                        ]),
                        _vm._v(" "),
                        _c("el-option", { attrs: { value: "C" } }, [
                          _vm._v("C"),
                        ]),
                        _vm._v(" "),
                        _c("el-option", { attrs: { value: "D" } }, [
                          _vm._v("D"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "/auctionConsole",
                                expression: "'/auctionConsole'",
                              },
                            ],
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.goConsoleAction(scope.row)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "zhuanchang-chujiakongzhi",
                                "class-name": "blue",
                              },
                            }),
                            _vm._v("出价控制台"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }