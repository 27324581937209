var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    {
      attrs: {
        title: "文字区域",
        "has-template": _vm.$route.query.type == "manage" ? "1" : "",
      },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "fWeight500" }, [_vm._v("组件定义")]),
              _vm._v(" "),
              _c("div", { staticClass: "pt10" }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c mt10" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.changeType },
                        model: {
                          value: _vm.content.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "type", $$v)
                          },
                          expression: "content.type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "" } }, [
                          _vm._v("普通文字"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { attrs: { label: "venueEndMessage" } },
                          [_vm._v("拍卖会总结")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          { attrs: { label: "venueHighQualityAlbumMessage" } },
                          [_vm._v("专场总结")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "venueHighQualityProductMessage" },
                          },
                          [_vm._v("拍品总结")]
                        ),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "venue" } }, [
                          _vm._v("拍卖会介绍"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "album" } }, [
                          _vm._v("专场介绍"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "auction" } }, [
                          _vm._v("拍品介绍"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字内容" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.content.data ? "已设置内容" : "未设置") +
                          "\n          "
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml20",
                          attrs: { type: "primary" },
                          on: { click: _vm.setMessageAction },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.content.data ? "点击修改" : "点击设置")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题宽度" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.widthType,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "widthType", $$v)
                            },
                            expression: "facade.widthType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("通栏"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("自适应"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题位置" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.align,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "align", $$v)
                            },
                            expression: "facade.align",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "flex-start" } }, [
                            _vm._v("居左"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "center" } }, [
                            _vm._v("居中"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "flex-end" } }, [
                            _vm._v("居右"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域外间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginTop", $$v)
                            },
                            expression: "facade.pageMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginBottom", $$v)
                            },
                            expression: "facade.pageMarginBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginLeft", $$v)
                            },
                            expression: "facade.pageMarginLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginRight", $$v)
                            },
                            expression: "facade.pageMarginRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域内间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingTop", $$v)
                            },
                            expression: "facade.pagePaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingBottom", $$v)
                            },
                            expression: "facade.pagePaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingLeft", $$v)
                            },
                            expression: "facade.pagePaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingRight", $$v)
                            },
                            expression: "facade.pagePaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusTopLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusTopLeft", $$v)
                            },
                            expression: "facade.pageRadiusTopLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusTopRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusTopRight", $$v)
                            },
                            expression: "facade.pageRadiusTopRight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusBottomLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusBottomLeft", $$v)
                            },
                            expression: "facade.pageRadiusBottomLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusBottomRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusBottomRight", $$v)
                            },
                            expression: "facade.pageRadiusBottomRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.pageBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageBackground", $$v)
                      },
                      expression: "facade.pageBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框大小" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderTop", $$v)
                            },
                            expression: "facade.borderTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderBottom", $$v)
                            },
                            expression: "facade.borderBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderLeft", $$v)
                            },
                            expression: "facade.borderLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderRight", $$v)
                            },
                            expression: "facade.borderRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.borderColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "borderColor", $$v)
                      },
                      expression: "facade.borderColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.borderStyle,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "borderStyle", $$v)
                        },
                        expression: "facade.borderStyle",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "solid" } }, [
                        _vm._v("实线"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "dotted" } }, [
                        _vm._v("点状"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "dashed" } }, [
                        _vm._v("虚线"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框折叠角" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.hasFold,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hasFold", $$v)
                            },
                            expression: "facade.hasFold",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("无"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("右上"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "行间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.lineHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "lineHeight", $$v)
                      },
                      expression: "facade.lineHeight",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.letterSpacing,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "letterSpacing", $$v)
                      },
                      expression: "facade.letterSpacing",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.pageColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageColor", $$v)
                      },
                      expression: "facade.pageColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "特殊文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    on: { changeColor: _vm.editColorAction },
                    model: {
                      value: _vm.facade.specialColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "specialColor", $$v)
                      },
                      expression: "facade.specialColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.fontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "fontSize", $$v)
                      },
                      expression: "facade.fontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.fontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "fontWeight", $$v)
                        },
                        expression: "facade.fontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "temp" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "模版字段" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.facade.temp,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "temp", $$v)
                        },
                        expression: "facade.temp",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "pageBackground" } },
                            [_vm._v("背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "borderColor" } },
                            [_vm._v("边框颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-checkbox", { attrs: { label: "pageColor" } }, [
                            _vm._v("文字颜色"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "pageColor_border" } },
                            [_vm._v("文字用边框颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "pageColor_color" } },
                            [_vm._v("文字用图文文字")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "specialColor" } },
                            [_vm._v("特殊文字颜色")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置内容",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("Tinymce", {
                ref: "editor1",
                attrs: { height: 300 },
                model: {
                  value: _vm.message,
                  callback: function ($$v) {
                    _vm.message = $$v
                  },
                  expression: "message",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveMessageAction },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }