var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "160px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticStyle: { "margin-left": "160px" } }, [
                      _vm._v("审核状态：\n              "),
                      _vm.status == "REVIEWING"
                        ? _c("span", { staticClass: "status-text" }, [
                            _vm._v("申请审核中"),
                          ])
                        : _vm.status == "AUTHENTICITY_VERIFYING"
                        ? _c("span", { staticClass: "status-text" }, [
                            _vm._v("真实性验证中"),
                          ])
                        : _vm.status == "REVIEW_BACK"
                        ? _c("span", { staticClass: "status-text" }, [
                            _vm._v("申请已驳回"),
                          ])
                        : _vm.status == "AGREEMENT_SIGNING"
                        ? _c("span", { staticClass: "status-text" }, [
                            _vm._v("协议待签署"),
                          ])
                        : _vm.status == "BUSINESS_OPENING"
                        ? _c("span", { staticClass: "status-text" }, [
                            _vm._v("业务开通中"),
                          ])
                        : _vm.status == "COMPLETED"
                        ? _c("span", { staticClass: "status-text" }, [
                            _vm._v("申请已完成"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "160px",
                          "margin-top": "20px",
                        },
                      },
                      [_vm._v("声明：手续费0.6%，T+1自动结算")]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("商户主体信息")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商户签约类型",
                            prop: "subject_info.signType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择商户签约类型" },
                              model: {
                                value: _vm.formValidate.subject_info.signType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.subject_info,
                                    "signType",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.subject_info.signType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "企业", value: "ENTERPRISE" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "一般为有限公司、有限责任公司",
                                placement: "right-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { color: "#999" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商户证件编号",
                            prop: "subject_info.licenceNo",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入商户证件编号",
                              autocomplete: "off",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value: _vm.formValidate.subject_info.licenceNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.subject_info,
                                  "licenceNo",
                                  $$v
                                )
                              },
                              expression: "formValidate.subject_info.licenceNo",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "统一社会信用代码证编号",
                                placement: "right-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { color: "#999" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商户证件照片",
                            prop: "subject_info.licenceUrl",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg(
                                    "subject_info.licenceUrl"
                                  )
                                },
                              },
                            },
                            [
                              _vm.formValidate.subject_info.licenceUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.subject_info
                                          .licenceUrl_show,
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("图片不得大于6M"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商户签约名称",
                            prop: "subject_info.signName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入商户签约名称",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value: _vm.formValidate.subject_info.signName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.subject_info,
                                  "signName",
                                  $$v
                                )
                              },
                              expression: "formValidate.subject_info.signName",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "与商户证件主体名称一致。",
                                placement: "right-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { color: "#999" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商户简称",
                            prop: "subject_info.shortName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入商户简称",
                              autocomplete: "off",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value: _vm.formValidate.subject_info.shortName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.subject_info,
                                  "shortName",
                                  $$v
                                )
                              },
                              expression: "formValidate.subject_info.shortName",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content:
                                  "将在收银台页面或者支付完成页向买家展示",
                                placement: "right-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { color: "#999" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开户许可证编号",
                            prop: "subject_info.openAccountLicenceNo",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.subject_info
                                  .openAccountLicenceNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.subject_info,
                                  "openAccountLicenceNo",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.subject_info.openAccountLicenceNo",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content:
                                  "\t开户许可证编号和开户许可证照片需要同时上传；如无开户许可证编号，可传基本存款账户编号。\n商户类型为个体工商户时，可以上传bankCardNo（结算银行卡号）。",
                                placement: "right-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { color: "#999" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开户许可证照片",
                            prop: "subject_info.openAccountLicenceUrl",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg(
                                    "subject_info.openAccountLicenceUrl"
                                  )
                                },
                              },
                            },
                            [
                              _vm.formValidate.subject_info
                                .openAccountLicenceUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.subject_info
                                          .openAccountLicenceUrl_show,
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("图片不得大于6M"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content:
                                  "为增加商户入网审核通过率，请上传开户许可证；如无开户许可证，请上传基本存款账户信息表照片",
                                placement: "right-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { color: "#999" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手持营业执照",
                            prop: "subject_info.handLicenceUrl",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg(
                                    "subject_info.handLicenceUrl"
                                  )
                                },
                              },
                            },
                            [
                              _vm.formValidate.subject_info.handLicenceUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.subject_info
                                          .handLicenceUrl_show,
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("图片不得大于6M"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "手持营业执照在经营场所的照片",
                                placement: "right-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { color: "#999" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("商户法人信息")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "法人姓名",
                            prop: "corporation_info.legalName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入商户法人名称",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.corporation_info.legalName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.corporation_info,
                                  "legalName",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.corporation_info.legalName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "法人证件类型",
                            prop: "corporation_info.legalLicenceType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择法人证件类型" },
                              model: {
                                value:
                                  _vm.formValidate.corporation_info
                                    .legalLicenceType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.corporation_info,
                                    "legalLicenceType",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.corporation_info.legalLicenceType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "法人身份证",
                                  value: "ID_CARD",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "护照", value: "PASSPORT" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  label: "港澳居民往来内地通行证",
                                  value: "HM_VISITORPASS",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "台胞证", value: "TAIWAN" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "士兵证", value: "SOLDIER" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "军官证", value: "OFFICERS" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "法人证件号码",
                            prop: "corporation_info.legalLicenceNo",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入法人证件号码",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.corporation_info
                                  .legalLicenceNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.corporation_info,
                                  "legalLicenceNo",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.corporation_info.legalLicenceNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "法人证件正面",
                            prop: "corporation_info.legalLicenceFrontUrl",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg(
                                    "corporation_info.legalLicenceFrontUrl"
                                  )
                                },
                              },
                            },
                            [
                              _vm.formValidate.corporation_info
                                .legalLicenceFrontUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.corporation_info
                                          .legalLicenceFrontUrl_show,
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("图片不得大于6M"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "法人证件反面",
                            prop: "corporation_info.legalLicenceBackUrl",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeImg(
                                    "corporation_info.legalLicenceBackUrl"
                                  )
                                },
                              },
                            },
                            [
                              _vm.formValidate.corporation_info
                                .legalLicenceBackUrl
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.corporation_info
                                          .legalLicenceBackUrl_show,
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("图片不得大于6M"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("商户联系人")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "联系人姓名",
                            prop: "contact_info.contactName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入联系人姓名",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value: _vm.formValidate.contact_info.contactName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.contact_info,
                                  "contactName",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.contact_info.contactName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "联系人证件号码",
                            prop: "contact_info.contactLicenceNo",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入联系人证件号码",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.contact_info.contactLicenceNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.contact_info,
                                  "contactLicenceNo",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.contact_info.contactLicenceNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "联系人手机号",
                            prop: "contact_info.contactMobile",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入联系人手机号",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.contact_info.contactMobile,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.contact_info,
                                  "contactMobile",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.contact_info.contactMobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "联系人邮箱",
                            prop: "contact_info.contactEmail",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入联系人邮箱",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value: _vm.formValidate.contact_info.contactEmail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.contact_info,
                                  "contactEmail",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.contact_info.contactEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客服电话",
                            prop: "contact_info.servicePhone",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入客服电话",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value: _vm.formValidate.contact_info.servicePhone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.contact_info,
                                  "servicePhone",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.contact_info.servicePhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("行业分类")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "一级行业分类",
                            prop: "industry_category_info.primaryIndustryCategory",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择行业分类" },
                              model: {
                                value:
                                  _vm.formValidate.industry_category_info
                                    .primaryIndustryCategory,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.industry_category_info,
                                    "primaryIndustryCategory",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.industry_category_info.primaryIndustryCategory",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "数字藏品", value: "135" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "二级行业分类",
                            prop: "industry_category_info.secondaryIndustryCategory",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择行业分类" },
                              model: {
                                value:
                                  _vm.formValidate.industry_category_info
                                    .secondaryIndustryCategory,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.industry_category_info,
                                    "secondaryIndustryCategory",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.industry_category_info.secondaryIndustryCategory",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "交易平台", value: "135002" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "行业相关资质",
                            prop: "industry_category_info.industryQualificationUrl",
                          },
                        },
                        [
                          _c("uploadPDF", {
                            attrs: {
                              "upload-type": "PDF",
                              type: "industry_category_info.industryQualificationUrl",
                            },
                            on: { setVideo: _vm.uploadPDF },
                          }),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("请上传文件"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("商户经营信息")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "经营地址", required: "" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请选择省市区",
                              options: _vm.cityList,
                              props: _vm.props,
                              "collapse-tags": "",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            on: { change: _vm.changecitycode },
                            model: {
                              value: _vm.citycode,
                              callback: function ($$v) {
                                _vm.citycode = $$v
                              },
                              expression: "citycode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "详细地址",
                            prop: "business_address_info.address",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入详细地址",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.business_address_info.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.business_address_info,
                                  "address",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.business_address_info.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("结算账户信息")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "结算方向",
                            prop: "settlement_account_info.settlementDirection",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择结算方向" },
                              model: {
                                value:
                                  _vm.formValidate.settlement_account_info
                                    .settlementDirection,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.settlement_account_info,
                                    "settlementDirection",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.settlement_account_info.settlementDirection",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "结算到银行账户",
                                  value: "BANKCARD",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "银行账户类型",
                            prop: "settlement_account_info.bankAccountType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择银行账户类型" },
                              model: {
                                value:
                                  _vm.formValidate.settlement_account_info
                                    .bankAccountType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.settlement_account_info,
                                    "bankAccountType",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.settlement_account_info.bankAccountType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "企业",
                                  value: "ENTERPRISE_ACCOUNT",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "银行账户号码",
                            prop: "settlement_account_info.bankCardNo",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入银行账户号码",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.settlement_account_info
                                  .bankCardNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.settlement_account_info,
                                  "bankCardNo",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.settlement_account_info.bankCardNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开户总行编码",
                            prop: "settlement_account_info.bankCode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placeholder: "请选择开户",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value:
                                  _vm.formValidate.settlement_account_info
                                    .bankCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate.settlement_account_info,
                                    "bankCode",
                                    $$v
                                  )
                                },
                                expression:
                                  "formValidate.settlement_account_info.bankCode",
                              },
                            },
                            _vm._l(_vm.yyepayList, function (item) {
                              return _c("el-option", {
                                key: item.headquartersNo,
                                attrs: {
                                  label:
                                    item.headquartersName +
                                    " (" +
                                    item.headquartersNo +
                                    ")",
                                  value: item.headquartersNo,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v("产品资质信息")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "h5地址",
                            prop: "product_qualification_info.terminalName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入h5地址",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.product_qualification_info
                                  .terminalName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.product_qualification_info,
                                  "terminalName",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.product_qualification_info.terminalName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机号",
                            prop: "product_qualification_info.terminalTestAccount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入手机号",
                              clearable: "",
                              disabled: _vm.statusdisabled,
                            },
                            model: {
                              value:
                                _vm.formValidate.product_qualification_info
                                  .terminalTestAccount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate.product_qualification_info,
                                  "terminalTestAccount",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.product_qualification_info.terminalTestAccount",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机端认证证明",
                            prop: "product_qualification_info.phoneCertificationUrl",
                          },
                        },
                        [
                          _c("uploadPDF", {
                            attrs: {
                              "upload-type": "PDF",
                              type: "product_qualification_info.phoneCertificationUrl",
                            },
                            on: { setVideo: _vm.uploadPDF },
                          }),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("请上传文件"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机端支付流程截图",
                            prop: "product_qualification_info.screenshotOfPaymentProcessUrl",
                          },
                        },
                        [
                          _c("uploadPDF", {
                            attrs: {
                              "upload-type": "PDF",
                              type: "product_qualification_info.screenshotOfPaymentProcessUrl",
                            },
                            on: { setVideo: _vm.uploadPDF },
                          }),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("请上传文件"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交\n          ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }