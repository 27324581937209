var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-bottom": "20px" },
              attrs: {
                "label-width": "100px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "ft14 color-black",
                    staticStyle: { "font-weight": "500" },
                  },
                  [
                    _vm._v("初始状态 "),
                    _c(
                      "span",
                      {
                        staticClass: "ft12 color-gray",
                        staticStyle: { "font-weight": "400" },
                      },
                      [_vm._v("页面初始上滑前样式")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "标题栏背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.setHeaderSetting.header_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.setHeaderSetting, "header_background", $$v)
                      },
                      expression: "setHeaderSetting.header_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字颜色" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.setHeaderSetting.header_color,
                        callback: function ($$v) {
                          _vm.$set(_vm.setHeaderSetting, "header_color", $$v)
                        },
                        expression: "setHeaderSetting.header_color",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "black" } }, [
                        _vm._v("黑色"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "white" } }, [
                        _vm._v("白色"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 12, max: 50 },
                    model: {
                      value: _vm.setHeaderSetting.header_text_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.setHeaderSetting, "header_text_size", $$v)
                      },
                      expression: "setHeaderSetting.header_text_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.setHeaderSetting.fontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.setHeaderSetting, "fontWeight", $$v)
                        },
                        expression: "setHeaderSetting.fontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex item-align-center justify-b mt20" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "fontW400 ft14 color-black",
                      staticStyle: { "font-weight": "500" },
                    },
                    [
                      _vm._v("滑动后 "),
                      _c(
                        "span",
                        {
                          staticClass: "ft12 color-gray",
                          staticStyle: { "font-weight": "400" },
                        },
                        [_vm._v("页面向上滑动后样式")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#009406",
                          "inactive-color": "#cccccc",
                        },
                        model: {
                          value: _vm.setHeaderSetting.slide_style_no_change,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.setHeaderSetting,
                              "slide_style_no_change",
                              $$v
                            )
                          },
                          expression: "setHeaderSetting.slide_style_no_change",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "标题栏背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#ffffff" },
                    model: {
                      value: _vm.setHeaderSetting.slide_header_background,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.setHeaderSetting,
                          "slide_header_background",
                          $$v
                        )
                      },
                      expression: "setHeaderSetting.slide_header_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字颜色" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.setHeaderSetting.slide_header_color,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.setHeaderSetting,
                            "slide_header_color",
                            $$v
                          )
                        },
                        expression: "setHeaderSetting.slide_header_color",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "black" } }, [
                        _vm._v("黑色"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "white" } }, [
                        _vm._v("白色"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 12, max: 50 },
                    model: {
                      value: _vm.setHeaderSetting.slide_header_text_size,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.setHeaderSetting,
                          "slide_header_text_size",
                          $$v
                        )
                      },
                      expression: "setHeaderSetting.slide_header_text_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt10", attrs: { label: "文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.setHeaderSetting.slide_fontWeight,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.setHeaderSetting,
                            "slide_fontWeight",
                            $$v
                          )
                        },
                        expression: "setHeaderSetting.slide_fontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }