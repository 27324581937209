var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "control",
              { attrs: { title: "NFT头图", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 700 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "边框宽度" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderWidth", $$v)
                              },
                              expression: "facade.borderWidth",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "orderTop"
        ? [
            _c(
              "control",
              { attrs: { title: "订单列表标题", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "选中文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.selectColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "selectColor", $$v)
                              },
                              expression: "facade.selectColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "orderList"
        ? [
            _c(
              "control",
              { attrs: { title: "订单列表", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "80px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "边框颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.borderColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderColor", $$v)
                              },
                              expression: "facade.borderColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "边框圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.borderRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "borderRadius", $$v)
                              },
                              expression: "facade.borderRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "列表背景" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.listBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "listBgcolor", $$v)
                              },
                              expression: "facade.listBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("列表背景图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "listBgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.listBgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("listBgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.listBgimage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.listBgimage + "!300",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "商品列表背景" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.listGoodsBgcolor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "listGoodsBgcolor", $$v)
                              },
                              expression: "facade.listGoodsBgcolor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "商品列表圆角" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.listGoodsRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "listGoodsRadius", $$v)
                              },
                              expression: "facade.listGoodsRadius",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("商品列表背景图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "listGoodsBgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.listGoodsBgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("listGoodsBgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.listGoodsBgimage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.listGoodsBgimage + "!300",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "时间颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.timeColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "timeColor", $$v)
                              },
                              expression: "facade.timeColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "盲盒标题颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.blindNameColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "blindNameColor", $$v)
                              },
                              expression: "facade.blindNameColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "商品标题颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.productNameColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "productNameColor", $$v)
                              },
                              expression: "facade.productNameColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "按钮背景颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.buttonBgColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "buttonBgColor", $$v)
                              },
                              expression: "facade.buttonBgColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "按钮文字颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.buttonColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "buttonColor", $$v)
                              },
                              expression: "facade.buttonColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("按钮背景图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.modalPicTap(1, "buttonBgimage")
                              },
                            },
                          },
                          [
                            _vm.facade.buttonBgimage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("buttonBgimage")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.buttonBgimage
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.buttonBgimage + "!300",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "按钮圆角" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.buttonRadiu,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "buttonRadiu", $$v)
                              },
                              expression: "facade.buttonRadiu",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }