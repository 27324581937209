"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _coupon = _interopRequireDefault(require("@/components/customChildren/coupon"));
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
var _ticket_spec = _interopRequireDefault(require("@/components/customChildren/ticket_spec"));
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default,
    Coupon: _coupon.default,
    Goods: _goods.default,
    Ticket: _ticket_spec.default
  },
  data: function data() {
    return {
      id: '',
      ruleForm: {
        right_name: '',
        right_image: '',
        right_type: 2,
        content: '',
        right_venue_data: []
      },
      timeVal: [],
      rules: {
        right_name: {
          required: true,
          message: '请输入权益名称',
          trigger: 'blur'
        },
        right_image: {
          required: true,
          message: '请选择权益图标',
          trigger: 'change'
        },
        right_type: {
          required: true,
          message: '请选择权益类型',
          trigger: 'change'
        }
      },
      showImage: false,
      imagesViewKey: 'auction_images',
      imagesKey: 0,
      orderArr: []
    };
  },
  computed: {
    rightTypeName: function rightTypeName() {
      var txt = '';
      switch (this.ruleForm.right_type) {
        case 2:
          txt = '商品';
          break;
        case 3:
          txt = '门票';
          break;
        case 6:
          txt = '优惠券';
          break;
        case 4:
          txt = '积分';
          break;
        case 5:
          txt = '余额';
          break;
        default:
          txt = '';
          break;
      }
      return txt;
    }
  },
  created: function created() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.loadDetail();
    }
  },
  methods: {
    loadDetail: function loadDetail() {
      var _this = this;
      (0, _member.membershipRightDetailApi)(this.id).then(function (res) {
        _this.ruleForm = {
          right_name: res.data.right_name,
          right_image: res.data.right_image,
          right_type: res.data.right_type,
          content: res.data.content,
          right_venue_data: []
        };
        if (res.data.right_type === 1) {
          _this.ruleForm.right_venue_data = res.data.venueItem.map(function (m) {
            return {
              venue_id: m.venue_id,
              venue_name: m.venue_name,
              times: [m.sign_stime * 1000, m.sign_etime * 1000],
              sign_stime: m.sign_stime,
              sign_etime: m.sign_etime,
              sign_type: !m.sign_number,
              is_membership_card: !!m.is_membership_card,
              sign_number: m.sign_number === 0 ? undefined : m.sign_number,
              is_limit: m.is_limit
            };
          });
        } else if (res.data.right_type === 2 || res.data.right_type === 3 || res.data.right_type === 6) {
          _this.orderArr = res.data.right_give_data.map(function (m) {
            return {
              image: m.image,
              name: m.name,
              store_name: m.store_name,
              count: m.number,
              id: m.link_id,
              spec_id: m.spec_id,
              spec_name: m.spec_name
            };
          });
        } else if (res.data.right_type === 4 || res.data.right_type === 5) {
          _this.orderArr = res.data.right_give_data.map(function (m) {
            return {
              count: m.number
            };
          });
        }
        _this.$refs['editor'].setContent(res.data.content);
      });
    },
    addVenueAction: function addVenueAction() {
      this.ruleForm.right_venue_data = this.ruleForm.right_venue_data.concat([{
        venue_id: '',
        venue_name: '',
        times: [],
        sign_stime: '',
        sign_etime: '',
        sign_type: false,
        is_membership_card: false,
        sign_number: undefined,
        is_limit: 0
      }]);
    },
    delVenueAction: function delVenueAction(i) {
      this.ruleForm.right_venue_data.splice(i, 1);
    },
    delProductAction: function delProductAction(i) {
      this.orderArr.splice(i, 1);
    },
    changeImg: function changeImg(key) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.ruleForm[key] = img[0];
        _this2.$refs['ruleForm'].validateField(key);
      }, 1, '1');
    },
    showImageAction: function showImageAction(key) {
      this.imagesViewKey = key;
      this.showImage = true;
    },
    delImgAction: function delImgAction(key) {
      this.ruleForm[key] = '';
      this.$refs['ruleForm'].validateField(key);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      var txt = '';
      switch (this.ruleForm.right_type) {
        case 2:
          txt = 'goods';
          break;
        case 3:
          txt = 'ticket';
          break;
        case 6:
          txt = 'coupon';
          break;
      }
      if (txt) this.$refs[txt].changeshow();
    },
    selectMessage: function selectMessage(data) {
      var _this3 = this;
      var d = [];
      data.data.forEach(function (m) {
        if (m.image.indexOf('!300') === -1) m.image += '!300';
        var newData = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
          count: 1
        });
        var ids = _this3.orderArr.map(function (val) {
          return val.id;
        });
        var index = ids.indexOf(m.id);
        if (index !== -1) {
          newData.count = _this3.orderArr[index].count;
        }
        d.push(newData);
      });
      this.orderArr = d;
      // this.content.data = data.data;
    },
    editRightTypeAction: function editRightTypeAction() {
      if (this.ruleForm.right_type === 4 || this.ruleForm.right_type === 5) {
        this.orderArr = [{
          count: undefined
        }];
      } else {
        this.orderArr = [];
      }
    },
    saveAction: function saveAction(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.ruleForm));
          if (data.right_type === 1) {
            if (!data.right_venue_data || data.right_venue_data.length <= 0) {
              _this4.$message.error('请设置场馆签到信息');
              return false;
            }
            var error = '';
            var venueData = [];
            data.right_venue_data.forEach(function (m) {
              if (!m.venue_name) {
                error = '请设置场馆名称';
              } else if ((!m.times[0] || !m.times[1]) && !m.is_membership_card) {
                error = '请设置场馆签到时间';
              } else if (!m.sign_type && !m.sign_number) {
                error = '请设置场馆签到次数';
              }
              if (m.sign_type) m.sign_number = 0;
              venueData.push({
                venue_id: m.venue_id,
                venue_name: m.venue_name,
                sign_stime: m.times[0] / 1000,
                sign_etime: m.times[1] / 1000,
                sign_number: m.sign_number,
                is_membership_card: m.is_membership_card,
                is_limit: m.is_limit
              });
            });
            if (error) {
              _this4.$message.error(error);
              return false;
            }
            data.right_give_data = [];
            data.right_venue_data = venueData;
          } else if (data.right_type === 2 || data.right_type === 3 || data.right_type === 6) {
            if (!_this4.orderArr || _this4.orderArr.length <= 0) {
              _this4.$message.error('请选择权益内容');
              return false;
            }
            var goods = [];
            var _error = '';
            _this4.orderArr.forEach(function (m) {
              if (!m.count) {
                _error = '请设置权益赠送数量';
              }
              var cData = {
                right_type: data.right_type,
                link_id: m.id,
                number: m.count
              };
              if (data.right_type === 3) {
                cData.spec_id = m.spec_id;
              }
              goods.push(cData);
            });
            if (_error) {
              _this4.$message.error(_error);
              return false;
            }
            data.right_give_data = goods;
            data.right_venue_data = [];
          } else if (data.right_type === 4 || data.right_type === 5) {
            if (!_this4.orderArr || !_this4.orderArr[0]) {
              _this4.$message.error('请选择权益内容');
              return false;
            }
            if (!_this4.orderArr[0].count) {
              _this4.$message.error("\u8BF7\u8BBE\u7F6E\u8D60\u9001".concat(_this4.rightTypeName, "\u6570\u91CF"));
              return false;
            }
            data.right_give_data = [{
              right_type: data.right_type,
              link_id: 0,
              number: _this4.orderArr[0].count
            }];
            data.right_venue_data = [];
          } else {
            data.right_give_data = [];
            data.right_venue_data = [];
          }
          var loading = _this4.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _member.membershipRightCreateApi;
          if (_this4.id) {
            action = _member.membershipRightEditApi;
          }
          action(_this4.id, data).then(function () {
            loading.close();
            _this4.$message.success('操作成功');
            _this4.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          _this4.$message.error('请填写完整信息');
          return false;
        }
      });
    }
  }
};