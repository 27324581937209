"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _contract = require("@/api/contract");
var _settings = require("@/settings");
var _preview = _interopRequireDefault(require("./preview.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    preview: _preview.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20
      },
      tempName: "",
      tempDesc: "",
      createflag: false,
      showpreview: false,
      titleDisponsejson: {},
      signDisponsejson: {},
      expiry_date_switch: false,
      entrust_order_switch: false,
      owndata: [],
      otherdata: [],
      ownsigndata: [],
      othersigndata: [],
      content: '',
      attachmentContent: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    goappendx: function goappendx(id) {
      this.$router.push({
        path: _settings.roterPre + '/contract/appendix/' + id
      });
    },
    getdetail: function getdetail(id) {
      var _this = this;
      (0, _contract.getmodalDetail)(id).then(function (res) {
        var data = res.data;
        if (!data.title_fields_data) {
          _this.$message.error('当前模板未配置');
          return;
        }
        _this.expiry_date_switch = !!data.expiry_date_switch || false;
        _this.entrust_order_switch = !!data.entrust_order_switch || false;
        if (data.content) {
          _this.content = data.content.content;
        } else {
          _this.content = '';
        }
        if (data.content.attachment_content) {
          _this.attachmentContent = data.content.attachment_content;
        } else {
          _this.attachmentContent = '';
        }
        if (data.title_fields_data) {
          var titledata = JSON.parse(data.title_fields_data);
          _this.titleDisponsejson = titledata.config;
          _this.owndata = titledata.owndata;
          _this.otherdata = titledata.otherdata;
        }
        if (data.signature_area_fields_data) {
          var signdata = JSON.parse(data.signature_area_fields_data);
          _this.signDisponsejson = signdata.config;
          _this.ownsigndata = signdata.ownsigndata;
          _this.othersigndata = signdata.othersigndata;
        }
        _this.showpreview = true;
      });
    },
    addContract: function addContract() {
      this.createflag = true;
      this.row = null;
      this.tempName = '';
      this.tempDesc = '';
    },
    cursoradd: function cursoradd() {
      var _this2 = this;
      if (this.row) {
        (0, _contract.editmodal)(this.row.template_id, {
          name: this.tempName,
          scene: this.tempDesc
        }).then(function (res) {
          _this2.getList();
          _this2.createflag = false;
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
        return;
      }
      (0, _contract.createmodal)({
        name: this.tempName,
        scene: this.tempDesc
      }).then(function (res) {
        _this2.getList();
        _this2.createflag = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    delcontract: function delcontract(row) {
      var _this3 = this;
      this.$confirm("确认删除此合同模板？", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "不删除",
        type: "warning"
      }).then(function () {
        (0, _contract.delmodal)(row.template_id).then(function () {
          _this3.$message.success("删除成功");
          _this3.getList();
        });
      });
    },
    showeditdetail: function showeditdetail(row) {
      this.row = row;
      this.createflag = true;
      this.tempName = row.name;
      this.tempDesc = row.scene;
    },
    showdetail: function showdetail(row) {
      this.$router.push({
        path: _settings.roterPre + '/contract/contractdetail/' + row.template_id
      });
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _contract.modalList)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.data.list;
        _this4.tableData.total = res.data.count;
        _this4.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    handleClose: function handleClose() {
      this.createflag = false;
    }
  }
};