var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "toolboxEL", staticClass: "open3d-propleft" }, [
      _c("div", { staticClass: "open3d-propleft-header" }, [_vm._v("工具箱")]),
      _vm._v(" "),
      _c("div", { staticClass: "toolbox" }, [
        _c(
          "div",
          {
            staticClass: "item",
            on: {
              click: function ($event) {
                _vm.showStartPoint = true
              },
            },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "txt" }, [_vm._v("出生点")]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item",
            on: {
              dragend: function ($event) {
                return _vm.dragendAction($event)
              },
            },
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "txt" }, [_vm._v("传送门")]),
          ]
        ),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
      ]),
    ]),
    _vm._v(" "),
    !_vm.showAdd && _vm.showStartPoint
      ? _c("div", { staticClass: "open3d-propcenter open3dstartpoint-prop" }, [
          _c("div", { staticClass: "open3d-propcenter-header" }, [
            _vm._v("\n      设置出生点\n      "),
            _c("img", {
              staticClass:
                "open3d-propcenter-header-del open3dstartpoint-close",
              attrs: {
                src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/16908055857177884.png",
                alt: "",
              },
              on: {
                click: function ($event) {
                  _vm.showStartPoint = false
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "open3d-propcenter-start-list" },
            _vm._l(_vm.pointList, function (m, i) {
              return _c(
                "div",
                { key: i, staticClass: "open3d-propcenter-start-list-item" },
                [
                  _vm._m(4, true),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "open3d-propcenter-start-list-item-desc" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "open3d-propcenter-start-list-item-name",
                        },
                        [
                          _vm._v("\n            " + _vm._s(m.name)),
                          m.hasDefault
                            ? _c("span", [_vm._v("默认")])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "open3d-propcenter-start-list-item-point",
                        },
                        [
                          _vm._v(
                            "\n            坐标：" +
                              _vm._s(Number(m.coordinate.point.x).toFixed(2)) +
                              " x" +
                              _vm._s(Number(m.coordinate.point.y).toFixed(2)) +
                              "\n            x" +
                              _vm._s(Number(m.coordinate.point.z).toFixed(2)) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "open3d-propcenter-start-list-item-btn" },
                    [
                      !m.hasDefault
                        ? [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "open3d-propcenter-start-list-item-btn-setdefault",
                                on: {
                                  click: function ($event) {
                                    return _vm.setDefaultAction(i)
                                  },
                                },
                              },
                              [_vm._v("设为默认")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "open3d-propcenter-start-list-item-btn-del",
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(i)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "open3d-propcenter-cursor open3d-propcenter-start-cursor",
              on: { click: _vm.showAddPointAction },
            },
            [
              _c("img", {
                staticClass: "open3d-propcenter-cursor-img",
                attrs: {
                  src: require("@/assets/manageImage/point_add.png"),
                  alt: "",
                },
              }),
              _vm._v("\n      新增出生点\n    "),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "51px" } }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showAdd
      ? _c("div", { staticClass: "open3d-propcenter" }, [
          _c("div", { staticClass: "open3d-propcenter-header" }, [
            _vm._v("\n      设置出生点\n      "),
            _c("img", {
              staticClass:
                "open3d-propcenter-header-del open3dstartpoint-close",
              attrs: {
                src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/16908055857177884.png",
                alt: "",
              },
              on: {
                click: function ($event) {
                  _vm.showAdd = false
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "open3d-propcenter-start" }, [
            _vm._v("将“当前位置”保存为出生点？"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "open3d-propcenter-start-desc" }, [
            _vm._v("\n      保存出生点后，用户进入空间后默认在当前位置\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "open3d-propcenter-start-sign" }, [
            _vm._v("\n      坐标\n      "),
            _c("span", { staticClass: "open3d-propcenter-start-text" }, [
              _vm._v(
                _vm._s(Number(_vm.nowPoint.point.x).toFixed(2)) +
                  " x" +
                  _vm._s(Number(_vm.nowPoint.point.y).toFixed(2)) +
                  "\n        x" +
                  _vm._s(Number(_vm.nowPoint.point.z).toFixed(2))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "open3d-propcenter-cursor open3d-propcenter-start-cursor",
              on: { click: _vm.addPointAction },
            },
            [
              _c("img", {
                staticClass: "open3d-propcenter-cursor-img",
                attrs: {
                  src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/2023731/image/1690805589127488113.png",
                  alt: "",
                },
              }),
              _vm._v("\n      确认\n    "),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "51px" } }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/manageImage/startpoint_icon.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/manageImage/portal_icon.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "img" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/manageImage/people_icon.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("用户角色")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "img" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/manageImage/video_icon.png"),
            alt: "",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "txt" }, [_vm._v("录制短视频")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "open3d-propcenter-start-list-item-icon" },
      [
        _c("img", {
          attrs: { src: require("@/assets/manageImage/anchor.png"), alt: "" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }