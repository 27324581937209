import { render, staticRenderFns } from "./createGoods.vue?vue&type=template&id=181b77a2&scoped=true"
import script from "./createGoods.vue?vue&type=script&lang=js"
export * from "./createGoods.vue?vue&type=script&lang=js"
import style0 from "./createGoods.vue?vue&type=style&index=0&id=181b77a2&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "181b77a2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('181b77a2')) {
      api.createRecord('181b77a2', component.options)
    } else {
      api.reload('181b77a2', component.options)
    }
    module.hot.accept("./createGoods.vue?vue&type=template&id=181b77a2&scoped=true", function () {
      api.rerender('181b77a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/basic/createGoods.vue"
export default component.exports