"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find-index");
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'videoAuthor',
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      radioVal: '1',
      showSelectAuthor: false,
      artistList: [],
      seleList: [],
      searchQuery: {},
      queryParams: {
        page: 1,
        limit: 10,
        name: ''
      },
      total: 0
    };
  },
  filters: {},
  mounted: function mounted() {},
  methods: {
    seleFilter: function seleFilter(id) {
      var index = this.seleList.findIndex(function (item) {
        return item.id == id;
      });
      return index === -1 ? '选择' : '已选择';
    },
    openInit: function openInit(list) {
      if (list && list.length > 0) {
        this.seleList = list;
      }
      this.searchAuthor();
      this.showSelectAuthor = true;
    },
    searchAuthor: function searchAuthor() {
      var _this = this;
      (0, _auction.artistListApi)(this.queryParams).then(function (res) {
        // console.log('1---',res);
        _this.artistList = res.data.list;
        _this.total = res.data.count;
      });
    },
    onSubmit: function onSubmit() {
      this.$emit('onMessage', this.seleList);
      this.showSelectAuthor = false;
    },
    selctAll: function selctAll() {
      var _this2 = this;
      this.artistList.forEach(function (item) {
        var index = _this2.seleList.findIndex(function (ele) {
          return ele.id == item.id;
        });
        if (index === -1) _this2.onHandSeleAuthor(item);
      });
    },
    onHandSeleAuthor: function onHandSeleAuthor(row) {
      var index = this.seleList.findIndex(function (item) {
        return item.id == row.id;
      });
      if (index != -1) {
        this.seleList.splice(index, 1);
      } else {
        this.seleList.push(row);
      }
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.searchAuthor();
    }
  }
};