var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-content", staticStyle: { height: "100%" } },
    [
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-bottom": "120px" },
              attrs: {
                "label-width": "90px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "标签圆角" } }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上左圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 30 },
                    model: {
                      value: _vm.facade.topLeftRadius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "topLeftRadius", $$v)
                      },
                      expression: "facade.topLeftRadius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上右圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 30 },
                    model: {
                      value: _vm.facade.topRightRadius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "topRightRadius", $$v)
                      },
                      expression: "facade.topRightRadius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下左圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 30 },
                    model: {
                      value: _vm.facade.bottomLeftRadius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "bottomLeftRadius", $$v)
                      },
                      expression: "facade.bottomLeftRadius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下右圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 30 },
                    model: {
                      value: _vm.facade.bottomRightRadius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "bottomRightRadius", $$v)
                      },
                      expression: "facade.bottomRightRadius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "margin-bottom": "18px" },
                },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("预展中"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one flex align-items-c" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实心背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000", "no-alpha": "1" },
                    model: {
                      value: _vm.facade.previewBtnBg,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "previewBtnBg", $$v)
                      },
                      expression: "facade.previewBtnBg",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实心文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.previewBtnColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "previewBtnColor", $$v)
                      },
                      expression: "facade.previewBtnColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空心边框" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.previewBorder,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "previewBorder", $$v)
                      },
                      expression: "facade.previewBorder",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空心文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.previewBorderColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "previewBorderColor", $$v)
                      },
                      expression: "facade.previewBorderColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "纯文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.previewActiveColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "previewActiveColor", $$v)
                      },
                      expression: "facade.previewActiveColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "margin-bottom": "18px" },
                },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("进行中"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one flex align-items-c" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实心背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000", "no-alpha": "1" },
                    model: {
                      value: _vm.facade.goingBtnBg,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "goingBtnBg", $$v)
                      },
                      expression: "facade.goingBtnBg",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实心文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.goingBtnColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "goingBtnColor", $$v)
                      },
                      expression: "facade.goingBtnColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空心边框" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.goingBorder,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "goingBorder", $$v)
                      },
                      expression: "facade.goingBorder",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空心文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.goingBorderColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "goingBorderColor", $$v)
                      },
                      expression: "facade.goingBorderColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "纯文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.goingActiveColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "goingActiveColor", $$v)
                      },
                      expression: "facade.goingActiveColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "margin-bottom": "18px" },
                },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("可洽购"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one flex align-items-c" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实心背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000", "no-alpha": "1" },
                    model: {
                      value: _vm.facade.discussBtnBg,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "discussBtnBg", $$v)
                      },
                      expression: "facade.discussBtnBg",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实心文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.discussBtnColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "discussBtnColor", $$v)
                      },
                      expression: "facade.discussBtnColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空心边框" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.discussBorder,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "discussBorder", $$v)
                      },
                      expression: "facade.discussBorder",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空心文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.discussBorderColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "discussBorderColor", $$v)
                      },
                      expression: "facade.discussBorderColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "纯文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.discussActiveColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "discussActiveColor", $$v)
                      },
                      expression: "facade.discussActiveColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "margin-bottom": "18px" },
                },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("已结拍"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one flex align-items-c" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实心背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000", "no-alpha": "1" },
                    model: {
                      value: _vm.facade.finishBtnBg,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "finishBtnBg", $$v)
                      },
                      expression: "facade.finishBtnBg",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实心文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.finishBtnColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "finishBtnColor", $$v)
                      },
                      expression: "facade.finishBtnColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空心边框" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.finishBorder,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "finishBorder", $$v)
                      },
                      expression: "facade.finishBorder",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空心文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.finishBorderColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "finishBorderColor", $$v)
                      },
                      expression: "facade.finishBorderColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "纯文字" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.finishActiveColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "finishActiveColor", $$v)
                      },
                      expression: "facade.finishActiveColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "margin-bottom": "18px" },
                },
                [
                  _c("div", { staticClass: "w80 ft18 fWeight500 mr10" }, [
                    _vm._v("举牌图标"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one flex align-items-c" }, [
                    _c("img", {
                      staticClass: "a-img",
                      attrs: { src: _vm.facade.firstImg },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "ml20 a-img",
                      attrs: { src: _vm.facade.endImg },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c(
                  "div",
                  { staticClass: "w80 fontW400 ft14 color-black mr10" },
                  [_vm._v("领先")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.uploadIconAction("firstImg")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "a-img",
                      attrs: { src: _vm.facade.firstImg + "!120a" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "ft12 color-gray ml20" }, [
                  _vm._v("1:1正方形图片"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c(
                  "div",
                  { staticClass: "w80 fontW400 ft14 color-black mr10" },
                  [_vm._v("出局")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.uploadIconAction("endImg")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "a-img",
                      attrs: { src: _vm.facade.endImg + "!120a" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "ft12 color-gray ml20" }, [
                  _vm._v("1:1正方形图片"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }