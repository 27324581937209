var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-button",
        {
          staticClass: "mr20",
          staticStyle: { "margin-bottom": "20px" },
          attrs: { size: "mini", icon: "el-icon-back" },
          on: { click: _vm.close },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step == 1,
                  expression: "step == 1",
                },
              ],
              ref: "formValidate",
              attrs: {
                size: "small",
                "label-width": "100px",
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同模板", prop: "template_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selwidth",
                              attrs: {
                                placeholder: "请选择模板",
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.changetemp },
                              model: {
                                value: _vm.formValidate.template_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "template_id", $$v)
                                },
                                expression: "formValidate.template_id",
                              },
                            },
                            _vm._l(_vm.templist, function (item) {
                              return _c("el-option", {
                                key: item.template_id,
                                attrs: {
                                  label: item.name,
                                  value: item.template_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _vm.opensign
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "合同编号", prop: "contract_no" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "selwidth",
                                attrs: {
                                  placeholder: "合同编号",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.formValidate.contract_no,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "contract_no",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.contract_no",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            {
                              attrs: { label: "合同编号", prop: "contract_no" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selwidth",
                                  attrs: {
                                    placeholder: "请选择合同编号",
                                    filterable: "",
                                    remote: "",
                                    "remote-method": _vm.searchno,
                                    loading: _vm.searchLoading,
                                  },
                                  on: { change: _vm.changecontractNo },
                                  model: {
                                    value: _vm.formValidate.contract_no,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "contract_no",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.contract_no",
                                  },
                                },
                                _vm._l(_vm.contractList, function (m, i) {
                                  return _c("el-option", {
                                    key: "user" + i,
                                    attrs: {
                                      value: m.contract_no,
                                      label: m.contract_no,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "电子签章" } },
                        [
                          _c("el-switch", {
                            staticStyle: { width: "40px" },
                            on: { change: _vm.changeNo },
                            model: {
                              value: _vm.opensign,
                              callback: function ($$v) {
                                _vm.opensign = $$v
                              },
                              expression: "opensign",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同名称", prop: "contract_name" } },
                        [
                          _c("el-input", {
                            staticClass: "selwidth",
                            attrs: { placeholder: "合同名称" },
                            model: {
                              value: _vm.formValidate.contract_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "contract_name", $$v)
                              },
                              expression: "formValidate.contract_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员", prop: "manage_admin_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selwidth",
                              attrs: {
                                placeholder: "请选择业务员",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formValidate.manage_admin_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "manage_admin_id",
                                    $$v
                                  )
                                },
                                expression: "formValidate.manage_admin_id",
                              },
                            },
                            _vm._l(_vm.adminarr, function (item) {
                              return _c("el-option", {
                                key: item.merchant_admin_id,
                                attrs: {
                                  label: item.real_name,
                                  value: item.merchant_admin_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "审批人", prop: "audit_admin_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selwidth",
                              attrs: {
                                placeholder: "请选择审批人",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.formValidate.audit_admin_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "audit_admin_id",
                                    $$v
                                  )
                                },
                                expression: "formValidate.audit_admin_id",
                              },
                            },
                            _vm._l(_vm.reviewuserList, function (item) {
                              return _c("el-option", {
                                key: item.merchant_admin_id,
                                attrs: {
                                  label: item.real_name,
                                  value: item.merchant_admin_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "委托方", prop: "entrust_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selwidth",
                              attrs: {
                                placeholder: "请选择委托方",
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.changeletter },
                              model: {
                                value: _vm.formValidate.entrust_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "entrust_id", $$v)
                                },
                                expression: "formValidate.entrust_id",
                              },
                            },
                            _vm._l(_vm.letterarr, function (item) {
                              return _c("el-option", {
                                key: "ent_" + item.entrust_id,
                                attrs: {
                                  label:
                                    (item.entrust_contact_company || "") +
                                    " - " +
                                    item.entrust_contact_name,
                                  value: item.entrust_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.signuser
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { "font-size": "13px" },
                          attrs: { span: 8, offset: 0 },
                        },
                        [
                          _c("el-form-item", { attrs: { label: "签约人" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.signuser.real_name ||
                                    _vm.signuser.nickname
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "签约人手机号", prop: "sign_phone" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "selwidth",
                            attrs: { placeholder: "签约人手机号" },
                            model: {
                              value: _vm.formValidate.sign_phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "sign_phone", $$v)
                              },
                              expression: "formValidate.sign_phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.tempdetail.title_fields_data
                ? _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    _vm._l(
                      _vm.tempdetail.title_fields_data.owndata,
                      function (item, index) {
                        return item.select
                          ? _c(
                              "el-col",
                              {
                                key: "owndata_" + index,
                                attrs: { span: 8, offset: 0 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: item.value || item.name } },
                                  [
                                    _c("el-input", {
                                      staticClass: "selwidth",
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: item.input,
                                        callback: function ($$v) {
                                          _vm.$set(item, "input", $$v)
                                        },
                                        expression: "item.input",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tempdetail.title_fields_data
                ? _c(
                    "el-row",
                    {
                      staticStyle: {
                        "padding-top": "20px",
                        "border-top": "1px dashed #f7f7f7",
                      },
                      attrs: { gutter: 20 },
                    },
                    _vm._l(
                      _vm.tempdetail.title_fields_data.otherdata,
                      function (item, index) {
                        return item.select
                          ? _c(
                              "el-col",
                              {
                                key: "otherdata_" + index,
                                attrs: { span: 8, offset: 0 },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: item.value || item.name } },
                                  [
                                    _c("el-input", {
                                      staticClass: "selwidth",
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: item.input,
                                        callback: function ($$v) {
                                          _vm.$set(item, "input", $$v)
                                        },
                                        expression: "item.input",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tempdetail.expiry_date_switch
                ? _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合同有效期" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  "value-format": "yyyy/MM/dd",
                                  format: "yyyy/MM/dd",
                                  size: "small",
                                  type: "daterange",
                                  placement: "bottom-end",
                                  placeholder: "自定义时间",
                                },
                                on: { change: _vm.onchangeTime },
                                model: {
                                  value: _vm.timeVal,
                                  callback: function ($$v) {
                                    _vm.timeVal = $$v
                                  },
                                  expression: "timeVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tempdetail.entrust_order_switch
                ? _c(
                    "el-row",
                    {
                      staticStyle: {
                        "margin-top": "24px",
                        "font-size": "13px",
                      },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4, offset: 0 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                icon: "el-icon-circle-plus-outline",
                              },
                              on: { click: _vm.changecreateContract },
                            },
                            [_vm._v("创建委托标的清单")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.Catearr, function (citem, cindex) {
                return [
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-top": "30px" },
                      attrs: {
                        data: citem.orderProduct,
                        size: "small",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c("div", { staticClass: "tableEmpty" }, [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tableDiv" }, [
                            _vm._v("暂无数据"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "分类", prop: "sort" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(citem.cate_name) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "图片" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "demo-image__preview" },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "36px",
                                          height: "36px",
                                        },
                                        attrs: {
                                          src: scope.row.product.image,
                                          "preview-src-list": [
                                            scope.row.product.image,
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "拍品名称",
                          prop: "product.store_name",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "保留价", prop: "retain_price" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    model: {
                                      value: scope.row.retain_price,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "retain_price", $$v)
                                      },
                                      expression: "scope.row.retain_price",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "作者", prop: "brand" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.product.brand
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.product.brand.brand_name
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        { attrs: { label: "属性", align: "center" } },
                        _vm._l(citem.attribute, function (item, index) {
                          return _c("el-table-column", {
                            key: "ps_" + index,
                            attrs: { label: item.attr_name },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      scope.row.product.attrInfo,
                                      function (m, i) {
                                        return m.attr_id == item.attr_id
                                          ? _c(
                                              "span",
                                              { key: "attr_" + cindex + i },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(m.attr_value_name) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    )
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm.inventoryConfig.switch.sold_price_switch
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "成交收费", align: "center" } },
                            _vm._l(
                              _vm.inventoryConfig.price.sold_price,
                              function (item, index) {
                                return item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: { label: item.name },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value:
                                                      scope.row.price_data[0][
                                                        index
                                                      ].number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row.price_data[0][
                                                          index
                                                        ],
                                                        "number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.price_data[0][index].number",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e()
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.inventoryConfig.switch.unsold_price_switch
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "未成交收费", align: "center" } },
                            _vm._l(
                              _vm.inventoryConfig.price.unsold_price,
                              function (item, index) {
                                return item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: { label: item.name },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value:
                                                      scope.row.price_data[1][
                                                        index
                                                      ].number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row.price_data[1][
                                                          index
                                                        ],
                                                        "number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.price_data[1][index].number",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e()
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.inventoryConfig.switch.other_price_switch
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "其他费用", align: "center" } },
                            _vm._l(
                              _vm.inventoryConfig.price.other_price,
                              function (item, index) {
                                return item.name
                                  ? _c("el-table-column", {
                                      key: "s_" + index,
                                      attrs: { label: item.name },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  model: {
                                                    value:
                                                      scope.row.price_data[2][
                                                        index
                                                      ].number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row.price_data[2][
                                                          index
                                                        ],
                                                        "number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.price_data[2][index].number",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e()
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "24px", "font-size": "13px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "uploadImg",
                          staticClass: "upload-demo mr10 mb15",
                          attrs: {
                            action: "",
                            "show-file-list": true,
                            multiple: "",
                            "http-request": _vm.modeUpload,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                icon: "el-icon-connection",
                              },
                            },
                            [_vm._v("\n              上传附件\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.tempdetail.entrust_order_switch && _vm.step == 1
            ? [
                _c(
                  "el-tabs",
                  { attrs: { value: "goods" } },
                  [
                    _c("el-tab-pane", {
                      attrs: { label: "委托清单", name: "goods" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tempdetail.entrust_order_switch && _vm.step == 1
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "multipleSelection",
                  staticClass: "table",
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: {
                    "tooltip-effect": "dark",
                    "row-key": function (row) {
                      return row.order_id
                    },
                    data: _vm.tableData.data,
                    size: "mini",
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "委托方", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.userEntrust
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          scope.row.userEntrust
                                            .entrust_contact_company
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2162048696
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "单据时间",
                      "min-width": "90",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "order_no",
                      label: "单据编号",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creator.real_name",
                      label: "制单人",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditAdmin.real_name",
                      label: "审批人",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: { "min-width": "600" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.entrustOrderProduct,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "enp_" + index,
                                      staticClass: "flex-box",
                                      class: {
                                        "flex-box-border-bottom":
                                          index <
                                          scope.row.entrustOrderProduct.length -
                                            1,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-image" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview",
                                            },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  src: item.productItem.image,
                                                  "preview-src-list": [
                                                    item.productItem.image,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-name" },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                item.productItem.store_name
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-price" },
                                        [_vm._v(_vm._s(item.retain_price))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-autor" },
                                        [_vm._v(_vm._s(item.brand))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-name" },
                                        _vm._l(
                                          item.productItem.attrInfo,
                                          function (m, i) {
                                            return _c(
                                              "span",
                                              { key: "attr_" + i },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(m.attr_name) +
                                                    ":" +
                                                    _vm._s(m.attr_value_name) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                }
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        3434393402
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", { staticClass: "flex-box" }, [
                          _c("div", { staticClass: "flex-box-image" }, [
                            _vm._v("图片"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-name" }, [
                            _vm._v("拍品名称"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-price" }, [
                            _vm._v("保留价"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-autor" }, [
                            _vm._v("作者"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-name" }, [
                            _vm._v("属性"),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "80",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectinver(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.orderidArr.indexOf(
                                              scope.row.order_id
                                            ) > -1
                                              ? "已选择委托单"
                                              : "选择委托单"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.orderidArr.indexOf(scope.row.order_id) >
                                  -1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelSelect(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              取消选择\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      36857722
                    ),
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step == 2,
                  expression: "step == 2",
                },
              ],
              staticStyle: { "margin-top": "24px" },
            },
            [
              _c("ueditorFrom", {
                staticStyle: { width: "1076px" },
                attrs: { content: _vm.content },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "templ-box" },
        [
          _vm.step == 1 && !_vm.opensign
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "success", size: "default" },
                  on: {
                    click: function ($event) {
                      return _vm.editDetail(0)
                    },
                  },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.opensign && _vm.step == 1
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "success", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.changestep(2)
                    },
                  },
                },
                [_vm._v("下一步")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 1
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "success", plain: "" },
                  on: { click: _vm.close },
                },
                [_vm._v("取消")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 2
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "success", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.changestep(1)
                    },
                  },
                },
                [_vm._v("上一步")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 2
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "success", size: "default" },
                  on: {
                    click: function ($event) {
                      return _vm.editDetail(0)
                    },
                  },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 2
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "success", size: "default" },
                  on: {
                    click: function ($event) {
                      return _vm.editDetail(1)
                    },
                  },
                },
                [_vm._v("保存并提审")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 2
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "150px" },
                  attrs: { type: "success", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.showpreview = true
                    },
                  },
                },
                [_vm._v("合同预览")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "120px" } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showpreview, width: "1200px" },
          on: {
            "update:visible": function ($event) {
              _vm.showpreview = $event
            },
          },
        },
        [
          _vm.tempdetail.title_fields_data &&
          _vm.tempdetail.signature_area_fields_data
            ? _c(
                "div",
                { attrs: { id: "prieview" } },
                [
                  _c("preview", {
                    attrs: {
                      inventoryConfig: _vm.inventoryConfig,
                      Catearr: _vm.Catearr,
                      timeVal: _vm.timeVal,
                      attachmentContent: _vm.attachmentContent,
                      title: _vm.formValidate.contract_name,
                      contractno: _vm.formValidate.contract_no,
                      titleDisponsejson:
                        _vm.tempdetail.title_fields_data.config,
                      signDisponsejson:
                        _vm.tempdetail.signature_area_fields_data.config,
                      expiry_date_switch: _vm.tempdetail.expiry_date_switch,
                      entrust_order_switch: _vm.tempdetail.entrust_order_switch,
                      owndata: _vm.tempdetail.title_fields_data.owndata,
                      otherdata: _vm.tempdetail.title_fields_data.otherdata,
                      ownsigndata:
                        _vm.tempdetail.signature_area_fields_data.ownsigndata,
                      othersigndata:
                        _vm.tempdetail.signature_area_fields_data.othersigndata,
                      content: _vm.content,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showinventorycreate,
            width: "1200px",
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showinventorycreate = $event
            },
          },
        },
        [
          _vm.showinventorycreate
            ? _c(
                "div",
                [
                  _c("inventorycreate", {
                    attrs: {
                      refser: "cdetail",
                      adminid: _vm.formValidate.entrust_id,
                      aduitid: _vm.formValidate.audit_admin_id,
                      serveid: _vm.formValidate.manage_admin_id,
                    },
                    on: {
                      close: function ($event) {
                        _vm.showinventorycreate = false
                      },
                      savesuccess: _vm.savesuccess,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }