var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "mt20", staticStyle: { height: "calc(100vh - 170px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "promoTable",
              attrs: {
                border: "",
                height: "100%",
                data: _vm.resultList,
                "row-key": "channels",
                "expand-row-keys": _vm.rowkeys,
              },
              on: { "cell-click": _vm.onCellClick },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand", width: "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("div", { staticClass: "meeting-list" }, [
                          _c(
                            "div",
                            { staticClass: "meeting-box" },
                            [
                              _c(
                                "el-table",
                                { attrs: { border: "", data: _vm.rowList } },
                                [
                                  _c("template", { slot: "empty" }, [
                                    _vm.listPropsLoading
                                      ? _c("div", [_vm._v(" 加载中... ")])
                                      : _c(
                                          "div",
                                          { staticClass: "tableEmpty" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "tableDiv" },
                                              [_vm._v("暂无数据")]
                                            ),
                                          ]
                                        ),
                                  ]),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "发布日期",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "nowrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .released_plan_date
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "发布时间",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "nowrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .released_plan_time
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "账号名称",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.channelsItem
                                                ? _c(
                                                    "div",
                                                    { staticClass: "nowrap" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.channelsItem
                                                            .account_name
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "账号定位",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.channelsItem
                                                ? _c(
                                                    "div",
                                                    { staticClass: "nowrap" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.channelsItem
                                                            .accountPositionText
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "类型",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.channelsItem
                                                ? _c(
                                                    "div",
                                                    { staticClass: "nowrap" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.channelsItem
                                                            .accountBindContentTypeText
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "内容",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.marketMake &&
                                              (scope.row.marketMake
                                                .make_status == 3 ||
                                                scope.row.marketMake
                                                  .make_status == 4)
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onOpenDetails(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("查看内容")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "发布方式",
                                      width: "120",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "nowrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .promotionTypeText
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "制作人",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.marketMake &&
                                              scope.row.marketMake.makeAdmin
                                                ? _c(
                                                    "div",
                                                    { staticClass: "nowrap" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.marketMake
                                                            .makeAdmin.real_name
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "制作时间",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "nowrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.marketMake
                                                        .make_time
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "发布人",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.releasedAdmin
                                                ? _c(
                                                    "div",
                                                    { staticClass: "nowrap" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row
                                                            .releasedAdmin
                                                            .real_name
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "完成时间",
                                      "min-width": "100",
                                      prop: "",
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "nowrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.released_time
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("ytx-table-column", {
                                    attrs: {
                                      label: "操作",
                                      width: "120",
                                      align: "center",
                                      fixed: "right",
                                      "search-clear": true,
                                      "search-query": _vm.searchQuery,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.is_released == 0
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onUpd(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("修改")]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onDelePlan(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex align-items-c",
                                  staticStyle: { padding: "5px 0 8px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-one flex",
                                      staticStyle: { "padding-left": "10px" },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                          },
                                          on: { click: _vm.onOpen },
                                        },
                                        [_vm._v("新增")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("el-pagination", {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: {
                                      background: "",
                                      "page-sizes": [20, 40, 60, 80],
                                      "page-size": _vm.tableFrom.limit,
                                      "current-page": _vm.tableFrom.page,
                                      layout: "total, prev, pager, next, sizes",
                                      total: _vm.total,
                                    },
                                    on: {
                                      "size-change": _vm.handleSizeChange,
                                      "current-change": _vm.pageChange,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发布渠道",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.channelsText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "使用/总数",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(_vm.formatCount(scope.row))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm._l(_vm.columnList, function (item, index) {
                return _c("ytx-table-column", {
                  key: index,
                  attrs: {
                    label: item,
                    "min-width": "120",
                    prop: "",
                    "search-query": _vm.searchQuery,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "nowrap",
                                style: {
                                  color:
                                    scope.row[item] && scope.row.isNo
                                      ? "red"
                                      : "#000",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row[item] ? scope.row[item] : "-"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增计划",
            visible: _vm.planVisible,
            top: "5vh",
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.planVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              attrs: {
                "label-width": "80px",
                rules: _vm.ruleValidate,
                model: _vm.planForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "计划内容", prop: "market_make_id" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "内容类型" },
                          on: { change: _vm.onTypeChange },
                          model: {
                            value: _vm.planForm.make_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "make_type", $$v)
                            },
                            expression: "planForm.make_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "拍卖会", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "专场/专题", value: "2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "单件拍品", value: "3" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "110px",
                            "margin-left": "10px",
                          },
                          attrs: { placeholder: "拍卖阶段" },
                          on: { change: _vm.onTypeChange },
                          model: {
                            value: _vm.planForm.make_tag,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "make_tag", $$v)
                            },
                            expression: "planForm.make_tag",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "拍卖导览", value: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "结拍战报", value: "2" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "120px",
                            "margin-left": "10px",
                          },
                          attrs: { placeholder: "计划内容" },
                          model: {
                            value: _vm.planForm.market_make_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "market_make_id", $$v)
                            },
                            expression: "planForm.market_make_id",
                          },
                        },
                        _vm._l(_vm.videoMakeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.make_title + "-" + item.typeText,
                              value: item.market_make_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "推送方式", prop: "promotion_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      model: {
                        value: _vm.planForm.promotion_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.planForm, "promotion_type", $$v)
                        },
                        expression: "planForm.promotion_type",
                      },
                    },
                    _vm._l(_vm.typeList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          placeholder: "请选择推送方式",
                          label: item.label,
                          value: item.value,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "选择账号" } }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100px" },
                        attrs: { placeholder: "请选择渠道" },
                        on: { change: _vm.groupInit },
                        model: {
                          value: _vm.planForm.channels,
                          callback: function ($$v) {
                            _vm.$set(_vm.planForm, "channels", $$v)
                          },
                          expression: "planForm.channels",
                        },
                      },
                      _vm._l(_vm.channelType.channels, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "190px", "margin-left": "10px" },
                        attrs: {
                          multiple: "",
                          "collapse-tags": "",
                          placeholder: "请选择账号",
                        },
                        on: { change: _vm.onChannChange },
                        model: {
                          value: _vm.planForm.chanIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.planForm, "chanIds", $$v)
                          },
                          expression: "planForm.chanIds",
                        },
                      },
                      _vm._l(_vm.channelsList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            label: item.account_name,
                            value: item.account_id,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.taskList } },
                [
                  _c("ytx-table-column", {
                    attrs: {
                      label: "发布账号",
                      "min-width": "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.account_name) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "发布时间",
                      width: "220",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                type: "datetime",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: scope.row.released_plan_time,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "released_plan_time", $$v)
                                },
                                expression: "scope.row.released_plan_time",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "操作",
                      width: "50",
                      prop: "",
                      align: "center",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDele(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.planVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "dialogBody" }, [
            _c("div", { staticClass: "flex dialogCon" }, [
              _c("span", [
                _vm._v("视频类型: \n          "),
                _vm.moduleForm.make_type == 1
                  ? _c("span", [_vm._v("拍卖会介绍视频")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 2
                  ? _c("span", [_vm._v("专场介绍视频")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                  ? _c("span", [_vm._v("拍品介绍视频")])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "计划发布时间: \n          " +
                    _vm._s(_vm.moduleForm.released_plan_date) +
                    " " +
                    _vm._s(_vm.moduleForm.released_plan_time) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                ? _c("span", [
                    _vm._v(
                      "拍品信息: \n            " +
                        _vm._s(
                          _vm.moduleForm.makeContentData.product.store_name
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm.moduleForm.make_type == 2
                  ? _c("span", [
                      _vm._v(
                        "所属拍卖会: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                  ? _c("span", [
                      _vm._v(
                        "\n            所属专场: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title) +
                          " - " +
                          _vm._s(
                            _vm.moduleForm.makeContentData.album.album_name
                          ) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _vm.moduleForm.extend_data &&
              _vm.moduleForm.extend_data.video_info
                ? _c("div", { staticStyle: { width: "50%" } }, [
                    _c("video", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        controls: "",
                        src: _vm.moduleForm.extend_data.video_info.url,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "10px",
                          "text-align": "center",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.onDownload },
                          },
                          [_vm._v("下载视频")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑任务",
            visible: _vm.channelVisible,
            "close-on-click-modal": false,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.channelVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号渠道", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      on: { change: _vm.getChannList },
                      model: {
                        value: _vm.channelForm.channels,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channels", $$v)
                        },
                        expression: "channelForm.channels",
                      },
                    },
                    _vm._l(_vm.channelType.channels, function (item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账号", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      model: {
                        value: _vm.channelForm.channels_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channels_id", $$v)
                        },
                        expression: "channelForm.channels_id",
                      },
                    },
                    _vm._l(_vm.channList, function (item) {
                      return _c("el-option", {
                        key: item.account_name,
                        attrs: { label: item.account_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发布时间", required: "" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.channelForm.released_plan_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.channelForm, "released_plan_time", $$v)
                      },
                      expression: "channelForm.released_plan_time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onUpdSubmit } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.channelVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("videoDetails", { ref: "videoViewRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }