var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "screen-box", style: { color: _vm.word_color } },
    [
      _c(
        "div",
        {
          staticClass: "screen-center",
          style: _vm.showauction ? "opacity:0;" : "opacity:1;",
          attrs: { id: "screen-center" },
        },
        [
          _vm.origin == 2 && _vm.auctionData.product
            ? [
                _vm.nextUrl
                  ? _c("video", {
                      staticStyle: {
                        width: "0px",
                        height: "0px",
                        opacity: "0",
                      },
                      style:
                        "" +
                        (_vm.videoModelType == 1
                          ? "object-fit: contain;"
                          : "object-fit: cover"),
                      attrs: {
                        muted: "",
                        src: _vm.nextUrl,
                        id: "videonew",
                        preload: "auto",
                      },
                      domProps: { muted: true },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.auctionData.product &&
                _vm.auctionData.product.video_link &&
                _vm.showvideo
                  ? [
                      _c("video", {
                        staticStyle: { width: "100%", height: "100%" },
                        style:
                          "" +
                          (_vm.videoModelType == 1
                            ? "object-fit: contain;"
                            : "object-fit: cover"),
                        attrs: {
                          muted: "",
                          src: _vm.auctionData.product.video_link,
                          id: "video",
                          loop: "loop",
                        },
                        domProps: { muted: true },
                        on: { loadedmetadata: _vm.onVideoLoadedMetadata },
                      }),
                    ]
                  : _vm._l(
                      _vm.auctionData.product.slider_image,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "screen-center-slide",
                            style:
                              _vm.duration +
                              (_vm.animateindex < index ? _vm.csstext : ""),
                          },
                          [
                            _vm.showmodel == 3
                              ? [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.playvideo &&
                                            _vm.imgdisplat[index],
                                          expression:
                                            "!playvideo && imgdisplat[index]",
                                        },
                                      ],
                                      staticClass: "solidimg",
                                      style:
                                        (_vm.imgdisplat[index] == "shu"
                                          ? "height: auto;"
                                          : "") +
                                        (_vm.imgdisplat[index] == "heng"
                                          ? "width: auto;"
                                          : "") +
                                        (_vm.imgdisplat[index] == "fang" ||
                                        _vm.imgdisplat[index] == "fangheng" ||
                                        _vm.imgdisplat[index] == "fangshu"
                                          ? "width:" +
                                            _vm.imgdisjson[index].width +
                                            "px;height:" +
                                            _vm.imgdisjson[index].height +
                                            "px;" +
                                            "transform: translate(-" +
                                            _vm.imgdisjson[index].width * 0.1 +
                                            "px, -" +
                                            _vm.imgdisjson[index].height * 0.1 +
                                            "px);"
                                          : "") +
                                        (_vm.slideAnimateindex == index
                                          ? _vm.scrollimgcss
                                          : "") +
                                        (_vm.upindex == index ? _vm.upcss : ""),
                                      attrs: {
                                        src:
                                          item +
                                          "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                        alt: "",
                                      },
                                      on: {
                                        load: function ($event) {
                                          return _vm.animatesolid(item, index)
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              : [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.animateindex == index,
                                        expression: "animateindex == index",
                                      },
                                    ],
                                    style:
                                      "" +
                                      (_vm.showmodel == 1
                                        ? "object-fit: contain;"
                                        : "object-fit: cover"),
                                    attrs: {
                                      src:
                                        item +
                                        "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                      alt: "",
                                    },
                                  }),
                                ],
                          ],
                          2
                        )
                      }
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.end
        ? _c("div", { staticClass: "is-end item-flex-center" }, [
            _c("div", { staticClass: "end-view" }, [
              _c("div", { staticClass: "top-name" }, [
                _vm._v(_vm._s(_vm.endname)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "top1" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.parseTime(_vm.endtime || 0, "{y}年{m}月{d}日 {h}:{i}")
                    ) +
                    " 结拍\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center1" }, [
                _vm._v(
                  "\n        本" +
                    _vm._s(_vm.showvence ? "拍卖会" : "场") +
                    _vm._s(_vm.total_num) +
                    "件/成交" +
                    _vm._s(_vm.deal_num) +
                    "件/成交率" +
                    _vm._s(_vm.deal_rate) +
                    "\n        "
                ),
                _c("span", { staticStyle: { "font-family": "none" } }, [
                  _vm._v("%"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center2" }, [
                _vm._v(
                  "\n        本" +
                    _vm._s(_vm.showvence ? "拍卖会" : "场") +
                    "成交额" +
                    _vm._s(_vm.fee_type == "CNY" ? "RMB" : _vm.fee_type) +
                    "\n        " +
                    _vm._s(_vm.formatPriceNumber(Number(_vm.deal_price), 2)) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom-copyright" }, [
                _vm._v("艺天下提供技术支持"),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showauction
        ? _c("div", { staticClass: "screen-bottom-auction" }, [
            _c(
              "div",
              {
                staticClass: "screen-bottom-auction-top",
                staticStyle: { "font-size": "36px" },
              },
              [
                _c("div", { staticClass: "screen-bottom-auction-name" }, [
                  _c("div", { staticClass: "screen-bottom-auction-lot" }, [
                    _vm._v("LOT " + _vm._s(_vm.auctionData.lot)),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.auctionData.product
                            ? _vm.auctionData.product.store_name
                            : ""
                        )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "screen-bottom-auction-price" },
                  [
                    _vm.albumData &&
                    _vm.albumData.feeTypeInfo &&
                    _vm.albumData.feeTypeInfo.type
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.albumData.feeTypeInfo.type == "CNY"
                                  ? "RMB"
                                  : _vm.albumData.feeTypeInfo.type
                              ) +
                              "\n        "
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.auctionData.now_price || 0,
                            2
                          )
                        ) +
                        "\n      "
                    ),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "screen-bottom-auction-bottom" }, [
              _c("div", { staticClass: "screen-bottom-auction-lot" }),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "24px", color: "#999" } },
                [
                  _vm.auctionData.attr_info &&
                  _vm.auctionData.attr_info.length > 0
                    ? _vm._l(_vm.auctionData.attr_info, function (item, index) {
                        return _c(
                          "span",
                          { key: "" + index },
                          [
                            item.attr_value
                              ? [
                                  _vm._v(
                                    _vm._s(item.attr_name) +
                                      _vm._s(item.attr_value) +
                                      "  "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }