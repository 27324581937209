var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "页面基本配置", is_tab: false } },
    [
      _vm.facade.show
        ? [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.facade,
                  "label-width": "100px",
                  size: "mini",
                  "label-position": "left",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "标题名称" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        placeholder: "自定义标题",
                        maxlength: "10",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.facade.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title", $$v)
                        },
                        expression: "facade.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt20",
                    staticStyle: { color: "#000" },
                    attrs: { label: "背景颜色" },
                  },
                  [
                    _c("colour", {
                      attrs: { colour: "#f7f7f7" },
                      model: {
                        value: _vm.facade.background,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "background", $$v)
                        },
                        expression: "facade.background",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("背景图片"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "bg_image")
                        },
                      },
                    },
                    [
                      _vm.facade.bg_image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("bg_image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.bg_image
                        ? _c("img", {
                            attrs: { src: _vm.facade.bg_image + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "背景图设置" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.facade.bg_image_mode,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bg_image_mode", $$v)
                          },
                          expression: "facade.bg_image_mode",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("宽度固定上下居中"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("满屏上下左右截取"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("宽度固定上下平铺循环展示"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("宽度固定高度自适应顶部展示"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "页面边距" } },
                  [
                    _c("slider", {
                      attrs: { min: 0, max: 100 },
                      model: {
                        value: _vm.facade.page_margin,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "page_margin", $$v)
                        },
                        expression: "facade.page_margin",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                    _vm._v("用户登录方式"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one ml20" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.login_config,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "login_config", $$v)
                            },
                            expression: "facade.login_config",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("提示"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("强制"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("按需"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "h30" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-align-center mt20" },
                  [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("显示标题栏"),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "flex-one",
                      staticStyle: { "font-size": "12px", color: "#999" },
                    }),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#009406",
                        "inactive-color": "#cccccc",
                      },
                      model: {
                        value: _vm.facade.show_header_view,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "show_header_view", $$v)
                        },
                        expression: "facade.show_header_view",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "标题栏颜色" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        model: {
                          value: _vm.facade.header_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "header_color", $$v)
                          },
                          expression: "facade.header_color",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "black" } }, [
                          _vm._v("黑色"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "white" } }, [
                          _vm._v("白色"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.facade.show_header_view
                  ? [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "标题栏背景" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#ffffff" },
                            model: {
                              value: _vm.facade.header_background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "header_background", $$v)
                              },
                              expression: "facade.header_background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 12, max: 50 },
                            model: {
                              value: _vm.facade.header_text_size,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "header_text_size", $$v)
                              },
                              expression: "facade.header_text_size",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("底部客服图标"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "concatIcon")
                        },
                      },
                    },
                    [
                      _vm.facade.concatIcon
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("concatIcon")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.concatIcon
                        ? _c("img", {
                            attrs: { src: _vm.facade.concatIcon + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-align-center mt20" }, [
                  _c("div", { staticClass: "w100 fontW400 ft14 color-black" }, [
                    _vm._v("底部分享图标"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "shareIcon")
                        },
                      },
                    },
                    [
                      _vm.facade.shareIcon
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("shareIcon")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.shareIcon
                        ? _c("img", {
                            attrs: { src: _vm.facade.shareIcon + "!300" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }