var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    {
      attrs: {
        title: "图文",
        "has-template": _vm.$route.query.type == "manage" ? "1" : "",
      },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "fWeight500" }, [_vm._v("组件定义")]),
              _vm._v(" "),
              _c("div", { staticClass: "pt10" }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c mt10" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.changeType },
                        model: {
                          value: _vm.content.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.content, "type", $$v)
                          },
                          expression: "content.type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("图文排列"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "2" } }, [
                          _vm._v("推荐阅读"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "3" } }, [
                          _vm._v("底部图片"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "4" } }, [
                          _vm._v("专场封面"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500 mt20" }, [
                _vm._v("设置内容"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pt10" },
                _vm._l(_vm.content.data, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "kuang1 item-align-center mt10",
                    },
                    [
                      index === 0
                        ? _c(
                            "div",
                            {
                              staticClass: "go-down-img",
                              on: {
                                click: function ($event) {
                                  return _vm.goDown(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      index > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "go-up-img",
                              on: {
                                click: function ($event) {
                                  return _vm.goUp(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "del-img",
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.content.type != 4
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "upload-img-view item-flex-center ml10",
                              staticStyle: { width: "40px", height: "40px" },
                              on: {
                                click: function ($event) {
                                  return _vm.uploadIconAction(index)
                                },
                              },
                            },
                            [
                              item.url
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "del-upload-img",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delImg(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.url
                                ? _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                    },
                                    attrs: { src: item.url + "!120a" },
                                  })
                                : _c("img", {
                                    staticClass: "upload-icon",
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                      alt: "",
                                    },
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.content.type == 4
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "upload-img-view item-flex-center ml10",
                              staticStyle: { width: "40px", height: "40px" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleExchange(true)
                                },
                              },
                            },
                            [
                              item.url
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "del-upload-img",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delImg(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.url
                                ? _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                    },
                                    attrs: { src: item.url + "!120a" },
                                  })
                                : _c("img", {
                                    staticClass: "upload-icon",
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                      alt: "",
                                    },
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-one ml10" },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "设置文字" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function () {})($event)
                              },
                            },
                            model: {
                              value: item.title,
                              callback: function ($$v) {
                                _vm.$set(item, "title", $$v)
                              },
                              expression: "item.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      item.link.name1 || item.link.name
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end ml10 cur-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.setCategory(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v(
                                  _vm._s(item.link.name1 || item.link.name)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.setCategory(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v("设置跳转链接"),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.content.type != 4
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("img", {
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "margin-right": "5px",
                        "margin-top": "2px",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fontW500 ft16",
                        on: { click: _vm.handleAdditem },
                      },
                      [_vm._v("新增一条")]
                    ),
                  ])
                : _vm.content.type == 4
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "a",
                      {
                        staticClass: "fontW500 ft16",
                        on: { click: _vm.handleExchange },
                      },
                      [_vm._v("换一换")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域外间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginTop", $$v)
                            },
                            expression: "facade.pageMarginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginBottom", $$v)
                            },
                            expression: "facade.pageMarginBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginLeft", $$v)
                            },
                            expression: "facade.pageMarginLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageMarginRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageMarginRight", $$v)
                            },
                            expression: "facade.pageMarginRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域内间距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingTop", $$v)
                            },
                            expression: "facade.pagePaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingBottom", $$v)
                            },
                            expression: "facade.pagePaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingLeft", $$v)
                            },
                            expression: "facade.pagePaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pagePaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pagePaddingRight", $$v)
                            },
                            expression: "facade.pagePaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "区域圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusTopLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusTopLeft", $$v)
                            },
                            expression: "facade.pageRadiusTopLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusTopRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusTopRight", $$v)
                            },
                            expression: "facade.pageRadiusTopRight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusBottomLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusBottomLeft", $$v)
                            },
                            expression: "facade.pageRadiusBottomLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.pageRadiusBottomRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "pageRadiusBottomRight", $$v)
                            },
                            expression: "facade.pageRadiusBottomRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.pageBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "pageBackground", $$v)
                      },
                      expression: "facade.pageBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框大小" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderTop", $$v)
                            },
                            expression: "facade.borderTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderBottom", $$v)
                            },
                            expression: "facade.borderBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderLeft", $$v)
                            },
                            expression: "facade.borderLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.borderRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderRight", $$v)
                            },
                            expression: "facade.borderRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.borderColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "borderColor", $$v)
                      },
                      expression: "facade.borderColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgRadiusTopLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadiusTopLeft", $$v)
                            },
                            expression: "facade.imgRadiusTopLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgRadiusTopRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadiusTopRight", $$v)
                            },
                            expression: "facade.imgRadiusTopRight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgRadiusBottomLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadiusBottomLeft", $$v)
                            },
                            expression: "facade.imgRadiusBottomLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.imgRadiusBottomRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "imgRadiusBottomRight", $$v)
                            },
                            expression: "facade.imgRadiusBottomRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字位置" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.textArea,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textArea", $$v)
                            },
                            expression: "facade.textArea",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("图片右边"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("图片下边"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.facade.textArea == 1
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字宽度" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.textWidth,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "textWidth", $$v)
                              },
                              expression: "facade.textWidth",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.facade.textArea == 1
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字垂直位置" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.textItemArea,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "textItemArea", $$v)
                                },
                                expression: "facade.textItemArea",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "flex-start" } },
                                [_vm._v("顶部")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "center" } }, [
                                _vm._v("垂直居中"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "flex-end" } }, [
                                _vm._v("底部"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字对齐" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.textAlign,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textAlign", $$v)
                            },
                            expression: "facade.textAlign",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "left" } }, [
                            _vm._v("居左"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "center" } }, [
                            _vm._v("居中"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "right" } }, [
                            _vm._v("居右"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#F5F5F5" },
                    model: {
                      value: _vm.facade.textBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "textBackground", $$v)
                      },
                      expression: "facade.textBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字距离" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.textPaddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textPaddingTop", $$v)
                            },
                            expression: "facade.textPaddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.textPaddingBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textPaddingBottom", $$v)
                            },
                            expression: "facade.textPaddingBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.textPaddingLeft,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textPaddingLeft", $$v)
                            },
                            expression: "facade.textPaddingLeft",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.textPaddingRight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "textPaddingRight", $$v)
                            },
                            expression: "facade.textPaddingRight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "距上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.marginTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "marginTop", $$v)
                      },
                      expression: "facade.marginTop",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.facade.color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "color", $$v)
                      },
                      expression: "facade.color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 50 },
                    model: {
                      value: _vm.facade.fontSize,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "fontSize", $$v)
                      },
                      expression: "facade.fontSize",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.fontWeight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "fontWeight", $$v)
                        },
                        expression: "facade.fontWeight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "temp" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "模版字段" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.facade.temp,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "temp", $$v)
                        },
                        expression: "facade.temp",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "pageBackground" } },
                            [_vm._v("背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "borderColor" } },
                            [_vm._v("边框颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "borderColor_decorative" } },
                            [_vm._v("边框用装饰色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "textBackground" } },
                            [_vm._v("文字区域背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-checkbox", { attrs: { label: "color" } }, [
                            _vm._v("文字颜色"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择专场封面图",
            visible: _vm.showselectImage,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showselectImage = $event
            },
            close: function ($event) {
              _vm.showselectImage = false
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%", display: "flex" } },
            _vm._l(_vm.selectImageArr, function (item, index) {
              return _c(
                "div",
                {
                  key: "select" + index,
                  staticClass: "albumPreviewList",
                  class: index === _vm.selectedIndex ? "select" : "",
                  on: {
                    click: function ($event) {
                      return _vm.selectCoverAlbumImage(item, index)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "200px", "margin-right": "20px" },
                    attrs: { src: item.url, alt: "" },
                  }),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "30px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.cursorCoverAlbumImage },
                },
                [_vm._v("\n        确认选择此海报\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "30px" },
                  attrs: { size: "small" },
                  on: { click: _vm.handleExchange },
                },
                [_vm._v("\n        换一换\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }