"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _auction = require("@/api/auction");
var _index = require("@/utils/index");
var _batchUploadTemplate = _interopRequireDefault(require("@/components/batchUploadTemplate.vue"));
var _ImportAuction = _interopRequireDefault(require("@/components/ImportAuction.vue"));
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
var _AlbumVisitData = _interopRequireDefault(require("@/components/auction/AlbumVisitData"));
var _AlbumRelationData = _interopRequireDefault(require("@/components/auction/AlbumRelationData"));
var _AlbumBondMoney = _interopRequireDefault(require("@/components/auction/AlbumBondMoney"));
var _user = require("@/api/user.js");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    batchUploadTemplate: _batchUploadTemplate.default,
    ImportAuction: _ImportAuction.default,
    albumVisitData: _AlbumVisitData.default,
    albumRelationData: _AlbumRelationData.default,
    albumBondMoney: _AlbumBondMoney.default,
    fileList: _fileList.default
  },
  filters: {
    handTime: function handTime(val) {
      if (val.indexOf(',') == -1) return '';else {
        var arr = val.split(',');
        return "".concat(arr[0] / 60, "\u5206\u949F\u5185\u51FA\u4EF7\u52A0\u65F6").concat(arr[1] / 60, "\u5206\u949F");
      }
    },
    handTiOne: function handTiOne(val) {
      if (val.indexOf(',') == -1) return '';else {
        var arr = val.split(',');
        return "\u5EF6\u65F6\u89C4\u5219\uFF1A\u6700\u540E".concat(arr[0], "\u79D2\u5185\u51FA\u4EF7\uFF0C\u5EF6\u65F6").concat(arr[1], "\u79D2\u91CD\u65B0\u5012\u8BA1\u65F6");
      }
    }
  },
  data: function data() {
    return {
      visible: false,
      formatPriceNumber: _index.formatPriceNumber,
      formatDate: _index.parseTime,
      list: null,
      listLoading: true,
      specIsNo: false,
      keyword: '',
      queryParams: {
        page: 1,
        limit: 20,
        status: '',
        isSearch: false
      },
      total: 0,
      venue_id: '',
      venue_type: '',
      venue_name: '',
      albumData: {},
      showAddVirtual: false,
      virtual_click_num: 0,
      showBatchUpload: false,
      btnLoading: false,
      albumPriceData: {},
      printData: [],
      orderConfirmData: {
        show: false
      },
      printObj2: {
        id: 'printConfirm',
        popTitle: ''
      },
      searchQuery: {}
    };
  },
  created: function created() {
    var query = JSON.parse(JSON.stringify(this.$route.query));
    this.venue_id = query.vid || '';
    if (this.venue_id) {
      this.queryParams.venue_id = this.venue_id;
      this.loadAlbumData();
    }
    if (query.keyword) {
      this.queryParams.keyword = query.keyword;
      this.keyword = query.keyword;
    }
    if (query.page) this.queryParams.page = Number(query.page);
    if (query.status) this.queryParams.status = query.status;
    this.venue_type = query.vtype || '';
    this.venue_name = query.vname || '';
    this.load();
  },
  methods: {
    goLinkAction: function goLinkAction() {
      this.$router.push({
        path: "/merchant/pageManagement/auction/promotionMaterials",
        query: this.$route.query
      });
    },
    // 导出
    exportData: function exportData(album_id) {
      var _this = this;
      var data = {
        export_type: 'albumBidPriceRecord',
        album_id: album_id
      };
      (0, _system.exportexcel)(data).then(function (res) {
        var h = _this.$createElement;
        _this.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this.$msgbox.close();
                _this.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    exportww: function exportww(album_id) {
      var _this2 = this;
      var data = {
        export_type: 'albumAuctionRecords',
        album_id: album_id
      };
      (0, _system.exportexcel)(data).then(function (res) {
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this2.$msgbox.close();
                _this2.getExportwwFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      var type = 'albumBidPriceRecord';
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    },
    getExportwwFileList: function getExportwwFileList() {
      var type = 'albumAuctionRecords';
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    },
    focus: function focus(e) {
      e.currentTarget.select();
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.queryParams = Object.assign({
        venue_id: this.queryParams.venue_id,
        page: 1,
        limit: this.queryParams.limit
      }, e);
      this.load(1);
    },
    onClickSpec: function onClickSpec() {
      this.specIsNo = true;
      this.$nextTick(function () {
        this.$refs.specRef.focus();
      });
    },
    onAfterEnterOne: function onAfterEnterOne() {
      this.$refs.specRef.focus();
    },
    onAfterEnter: function onAfterEnter() {
      this.$refs.aucRef.focus();
    },
    clearsearch: function clearsearch(str) {
      if (str == 'status') {
        this.queryParams.status = '';
        if (!this.queryParams.keyword) this.queryParams.isSearch = false;
      }
      if (str == 'keyword') {
        this.keyword = '';
        this.queryParams.keyword = '';
        if (!this.queryParams.status) this.queryParams.isSearch = false;
      }
      if (str == 'all') {
        this.keyword = '';
        this.queryParams = {
          page: 1,
          limit: 10,
          status: '',
          isSearch: false
        };
      }
      this.pageChange(1);
    },
    loadPrintAction: function loadPrintAction(m) {
      var _this3 = this;
      this.btnLoading = true;
      var nTime = new Date(m.album_start_time * 1000);
      this.albumPriceData = {
        name: m.venue.title,
        commission: m.commission_config_json.after_commission ? m.commission_config_json.after_commission : m.commission_config_json.commission,
        mLogo: '',
        mName: '',
        d: "".concat(nTime.getFullYear(), "\u5E74").concat(nTime.getMonth() + 1, "\u6708").concat(nTime.getDate(), "\u65E5"),
        mer_id: m.mer_id
      };
      (0, _user.getBaseInfo)().then(function (res) {
        _this3.albumPriceData.mLogo = res.data.mer_logo;
        _this3.albumPriceData.mName = res.data.company_name;
      });
      this.printData = [];
      var data = {
        album_id: m.album_id,
        page: 1,
        limit: 50,
        venue_id: m.venue_id
      };
      this.loadPrintAuction(data);
    },
    loadPrintAuction: function loadPrintAuction(params) {
      var _this4 = this;
      (0, _auction.auctionList)(params).then(function (res) {
        var d = [];
        res.data.list.forEach(function (m) {
          console.log(m);
          d = d.concat([{
            title: m.product.store_name,
            tuluhao: m.lot
          }]);
        });
        _this4.printData = _this4.printData.concat(d);
        console.log(_this4.printData);
        if (_this4.printData.length < res.data.count) {
          var data = params;
          data.page += 1;
          _this4.loadPrintAuction(data);
        } else {
          _this4.btnLoading = false;
          // const nTime = new Date();
          _this4.orderConfirmData = {
            show: true
          };
        }
      });
    },
    loadAlbumData: function loadAlbumData() {
      var _this5 = this;
      (0, _auction.albumStatusNum)(this.venue_id).then(function (res) {
        _this5.albumData = res.data;
      });
    },
    load: function load() {
      var _this6 = this;
      this.listLoading = true;
      (0, _auction.albumlist)(this.queryParams).then(function (response) {
        _this6.total = response.data.count;
        _this6.list = response.data.list;
        _this6.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.setTypeAction();
      this.load();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.limit = val;
      this.setTypeAction();
      this.load();
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    goSearchAction: function goSearchAction() {
      this.queryParams.keyword = this.keyword;
      this.queryParams.isSearch = true;
      this.pageChange(1);
    },
    changeStatus: function changeStatus() {
      this.visible = false;
      this.queryParams.isSearch = true;
      this.pageChange(1);
    },
    createdAlbum: function createdAlbum() {
      this.$router.push({
        name: 'albumDetail',
        query: {
          vid: this.venue_id || ''
        }
      });
    },
    editAlbum: function editAlbum(id, type, m) {
      this.$router.push({
        name: 'albumDetail',
        query: {
          vid: m.venue_id || '',
          vtype: type || '',
          id: id
        }
      });
    },
    hideAlbum: function hideAlbum(row, index) {
      var _this7 = this;
      if (this.lock) return;
      this.lock = true;
      // 设置隐藏/ 取消隐藏
      (0, _auction.switchAlbumShowApi)({
        album_id: row.album_id,
        is_show: Number(!row.is_show)
      }).then(function (res) {
        _this7.list[index].is_show = Number(!row.is_show);
        _this7.$message.success('操作成功');
        _this7.lock = false;
      }).catch(function () {
        _this7.lock = false;
      });
    },
    batchUploadAuction: function batchUploadAuction(row) {
      var _this8 = this;
      // 批量上传
      this.showBatchUpload = true;
      this.$nextTick(function () {
        _this8.$refs['batchTemplate'].init(row);
      });
    },
    addAuction: function addAuction(id, type, m) {
      this.$router.push({
        name: 'auctionDetail',
        query: {
          vid: m.venue_id || '',
          vtype: type || '',
          zcid: id,
          zcname: m.album_name
        }
      });
    },
    goAuctionPage: function goAuctionPage(id, type, m) {
      this.$router.push({
        name: 'auction',
        query: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.$route.query), {}, {
          vid: m.venue_id || '',
          vname: m.venue.title || '',
          vtype: type || '',
          album_id: id
        })
      });
    },
    delAlbum: function delAlbum(id) {
      var _this9 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u4E13\u573A?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.delAlbum)(id).then(function () {
          _this9.$message.success('删除成功');
          _this9.load();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    endAlbum: function endAlbum(id) {
      var _this10 = this;
      this.$confirm("\u786E\u8BA4\u7ED3\u675F\u8BE5\u4E13\u573A?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.endAlbumApi)({
          album_id: id
        }).then(function () {
          _this10.$message.success('结束成功');
          _this10.load();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    editVirtualAction: function editVirtualAction(val, index) {
      var _this11 = this;
      (0, _auction.addVirtualClickNumApi)({
        album_id: this.list[index].album_id,
        num: val
      }).then(function (res) {
        _this11.$message.success('设置成功');
      }).catch(function () {});
    },
    editSortAction: function editSortAction(val, index) {
      var _this12 = this;
      (0, _auction.albumEditOrderApi)({
        album_id: this.list[index].album_id,
        order_num: val
      }).then(function () {
        _this12.$message.success('修改排序成功');
        _this12.load();
      }).catch(function () {});
    },
    closeBatchAction: function closeBatchAction() {
      this.showBatchUpload = false;
    },
    showAlbumVisitDataAction: function showAlbumVisitDataAction(id, uv, pv) {
      this.$refs.albumVisitEL.showAlbumVisitDataAction(id, uv, pv);
    },
    showAlbumRelationDataAction: function showAlbumRelationDataAction(id, uv) {
      this.$refs.albumRelationEL.showAlbumRelationDataAction(id, uv);
    },
    showBondMoneyUserAction: function showBondMoneyUserAction(id) {
      this.$refs.albumBondMoneyEL.init(id);
    }
  }
};