var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.workDetail,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品名称", prop: "product_name" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入产品标题" },
                    model: {
                      value: _vm.workDetail.product_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "product_name", $$v)
                      },
                      expression: "workDetail.product_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品图片", prop: "image" } },
                [
                  _c("div", { staticClass: "flex align-items-c" }, [
                    _vm.workDetail.image
                      ? _c("div", { staticClass: "img-list" }, [
                          _c("img", {
                            attrs: { src: _vm.workDetail.image + "!120a" },
                            on: {
                              click: function ($event) {
                                return _vm.showImageAction(_vm.workDetail.image)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del",
                              on: {
                                click: function ($event) {
                                  return _vm.delImgAction("no")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c mr10",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg(1)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml10 color-gray ft12" }, [
                      _vm._v(
                        "图片格式支持：JPG、JPEG、PNG、GIF；文件大小≤20M；图片比例建议1:1"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品标签", prop: "label_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        multiple: "",
                        filterable: "",
                        placeholder: "选择标签",
                      },
                      model: {
                        value: _vm.workDetail.label_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.workDetail, "label_id", $$v)
                        },
                        expression: "workDetail.label_id",
                      },
                    },
                    _vm._l(_vm.tagList, function (item) {
                      return _c("el-option", {
                        key: item.label_id,
                        attrs: { label: item.label_name, value: item.label_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "规格信息", prop: "spec" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.workDetail.spec,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "spec", $$v)
                      },
                      expression: "workDetail.spec",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "出厂价格", prop: "factory_price" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.workDetail.factory_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "factory_price", $$v)
                      },
                      expression: "workDetail.factory_price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "到货日期", prop: "arrival_date" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "请选择征集开始时间",
                      align: "right",
                    },
                    model: {
                      value: _vm.workDetail.arrival_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.workDetail, "arrival_date", $$v)
                      },
                      expression: "workDetail.arrival_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关联商品", prop: "arrival_date" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _vm.changeGoods.id
                        ? _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("img", {
                                staticClass: "mr10",
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  display: "block",
                                  "border-radius": "4px",
                                },
                                attrs: { src: _vm.changeGoods.image },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "mr10" }, [
                                _vm._v(_vm._s(_vm.changeGoods.name)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr10",
                                  attrs: { type: "text" },
                                  on: { click: _vm.handleDelitem },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.handleAdditem },
                        },
                        [_vm._v("选择商品")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.backAction } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImage,
            width: "450px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imagesKey + "!m640" },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }