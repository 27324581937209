"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleData: {
        home_tab_show: [],
        consign_ban_time: 0,
        work_info_show: ['1', '2', '3'],
        my_trade_info: 1,
        consign_record: 2
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)('secondhand_rule').then(function (res) {
      res.data.service_charge = Number(res.data.service_charge);
      res.data.no_show_hash = Number(res.data.no_show_hash);
      res.data.no_show_record = Number(res.data.no_show_record);
      res.data.no_show_create_time = Number(res.data.no_show_create_time);
      res.data.number_display = Number(res.data.number_display ? res.data.number_display : 1);
      if (!res.data.home_tab_show) res.data.home_tab_show = '1,2';
      res.data.home_tab_show = res.data.home_tab_show.split(',');
      if (!res.data.hasOwnProperty('work_info_show')) {
        res.data.work_info_show = ['1', '2', '3'];
      } else {
        res.data.work_info_show = res.data.work_info_show.split(',');
      }
      if (!res.data.hasOwnProperty('consign_ban_time')) {
        res.data.consign_ban_time = 0;
      }
      if (!res.data.hasOwnProperty('my_trade_info')) {
        res.data.my_trade_info = 1;
      } else {
        res.data.my_trade_info = Number(res.data.my_trade_info);
      }
      if (!res.data.hasOwnProperty('consign_record')) {
        res.data.consign_record = 2;
      } else {
        res.data.consign_record = Number(res.data.consign_record);
      }
      _this.ruleData = res.data;
    }).catch(function () {});
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      var val = this.ruleData.service_charge;
      if (!this.ruleData.service_charge || isNaN(this.ruleData.service_charge)) {
        this.$message.error('比例必须为数字，已自动变更为0%');
        val = 0;
      }
      if (this.ruleData.service_charge > 100) {
        this.$message.error('比例最大设置100%，已自动变更为100%');
        val = 100;
      }
      if (this.ruleData.work_info_show.indexOf('3') > -1) {
        this.ruleData.no_show_hash = 0;
      } else {
        this.ruleData.no_show_hash = 1;
      }
      if (this.ruleData.work_info_show.indexOf('2') > -1) {
        this.ruleData.no_show_create_time = 0;
      } else {
        this.ruleData.no_show_create_time = 1;
      }
      if (this.ruleData.consign_record == 3) {
        this.ruleData.no_show_record = 1;
      } else {
        this.ruleData.no_show_record = 0;
      }
      if (this.ruleData.consign_record > 1) {
        this.ruleData.consign_log_customize_name = '';
      }
      (0, _system.configClassKeysSave)('secondhand_rule', {
        service_charge: val,
        no_show_hash: this.ruleData.no_show_hash,
        no_show_record: this.ruleData.no_show_record,
        no_show_create_time: this.ruleData.no_show_create_time,
        number_display: this.ruleData.number_display,
        home_tab_show: this.ruleData.home_tab_show.join(','),
        consign_log_customize_name: this.ruleData.consign_log_customize_name,
        work_info_show: this.ruleData.work_info_show.join(','),
        consign_ban_time: this.ruleData.consign_ban_time,
        my_trade_info: this.ruleData.my_trade_info,
        consign_record: this.ruleData.consign_record
      }).then(function (res) {
        if (!val) {
          _this2.ruleData.service_charge = 0;
        } else if (val === 100) {
          _this2.ruleData.service_charge = 100;
        }
        _this2.$message.success('设置成功');
      }).catch(function () {});
    }
  }
};