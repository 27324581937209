var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticStyle: { margin: "20px 0 20px 20px" },
          attrs: { icon: "el-icon-back" },
          on: { click: _vm.close },
        },
        [_vm._v("返回")]
      ),
      _vm._v(" "),
      _c("ueditorFrom", {
        staticStyle: { width: "1076px", margin: "20px", "margin-top": "0" },
        attrs: { content: _vm.content },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "20px" },
          attrs: { type: "primary", size: "default" },
          on: { click: _vm.setdetail },
        },
        [_vm._v("保存")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticStyle: { "margin-left": "20px" },
          attrs: { type: "error", size: "default" },
          on: { click: _vm.close },
        },
        [_vm._v("关闭")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }