var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "轮播图" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c(
                "div",
                { staticClass: "pt10" },
                _vm._l(_vm.content.data, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "kuang1 item-align-center mt10",
                    },
                    [
                      index === 0
                        ? _c(
                            "div",
                            {
                              staticClass: "go-down-img",
                              on: {
                                click: function ($event) {
                                  return _vm.goDown(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      index > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "go-up-img",
                              on: {
                                click: function ($event) {
                                  return _vm.goUp(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "del-img",
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center ml10",
                          staticStyle: { width: "50px", height: "50px" },
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction(1, index)
                            },
                          },
                        },
                        [
                          item.url
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.url
                            ? _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: { src: item.url + "!120a" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.facade.show_name
                        ? _c(
                            "div",
                            { staticClass: "set-inp flex-one ml10" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "text",
                                  placeholder: "请输入标题",
                                },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.link.name1 || item.link.name
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end ml10 cur-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.setCategory(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v(
                                  _vm._s(item.link.name1 || item.link.name)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.setCategory(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "ft14" }, [
                                _vm._v("设置跳转链接"),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "arrow-img",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("img", {
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "margin-right": "5px",
                    "margin-top": "2px",
                  },
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "fontW500 ft16",
                    on: { click: _vm.handleAdditem },
                  },
                  [_vm._v("新增一条")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "div",
            {
              staticClass: "flex banner-control",
              staticStyle: { "margin-top": "15px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "w80 fontW400 ft14 color-black",
                  staticStyle: { "line-height": "56px" },
                },
                [_vm._v("风格模板")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex flex-wrap flex-one" },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "change-type mr20 ft12 mb20" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          class: { curr: item.value === _vm.content.style },
                          on: {
                            click: function ($event) {
                              return _vm.setStyle(item.value)
                            },
                          },
                        },
                        [_c("label", [_c("img", { attrs: { src: item.src } })])]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "name color-gray align-c" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
              _vm._v("图片尺寸"),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "item-align-center" },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "80px" },
                      attrs: { type: "text", placeholder: "宽" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                        },
                      },
                      model: {
                        value: _vm.content.view_width,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "view_width", $$v)
                        },
                        expression: "content.view_width",
                      },
                    },
                    [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                    2
                  ),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: { margin: "0 6px" },
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "80px" },
                      attrs: { type: "text", placeholder: "宽" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                        },
                      },
                      model: {
                        value: _vm.content.view_height,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "view_height", $$v)
                        },
                        expression: "content.view_height",
                      },
                    },
                    [_c("template", { slot: "suffix" }, [_vm._v("px")])],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-align-center",
              staticStyle: { "margin-top": "5px" },
            },
            [
              _c("div", { staticClass: "w80 fontW400 ft14 color-black" }),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { "font-size": "12px", color: "#999" } },
                [_vm._v("宽x高，所有图片统一尺寸比例")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.border_shawdow_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_shawdow_color", $$v)
                      },
                      expression: "facade.border_shawdow_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影大小" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.border_shawdow_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_shawdow_size", $$v)
                      },
                      expression: "facade.border_shawdow_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影x距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.border_shawdow_x,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_shawdow_x", $$v)
                      },
                      expression: "facade.border_shawdow_x",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影y距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.border_shawdow_y,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_shawdow_y", $$v)
                      },
                      expression: "facade.border_shawdow_y",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "左右边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 375 },
                    model: {
                      value: _vm.facade.page_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_padding", $$v)
                      },
                      expression: "facade.page_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片圆角" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list tl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemTopLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemTopLeftRadius", $$v)
                            },
                            expression: "facade.itemTopLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list tr" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemTopRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemTopRightRadius", $$v)
                            },
                            expression: "facade.itemTopRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list bl" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemBottomLeftRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemBottomLeftRadius", $$v)
                            },
                            expression: "facade.itemBottomLeftRadius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list br" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.itemBottomRightRadius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemBottomRightRadius", $$v)
                            },
                            expression: "facade.itemBottomRightRadius",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "显示标题" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "margin-top": "4px" },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#009406",
                          "inactive-color": "#cccccc",
                        },
                        model: {
                          value: _vm.facade.show_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "show_name", $$v)
                          },
                          expression: "facade.show_name",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.facade.show_name
                ? [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题位置" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.facade.name_vertical_align,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "name_vertical_align", $$v)
                              },
                              expression: "facade.name_vertical_align",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "top" } }, [
                              _vm._v("图片上"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "bottom" } }, [
                              _vm._v("图片下"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.name_bg,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "name_bg", $$v)
                            },
                            expression: "facade.name_bg",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "圆角" } },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "radius-list tl" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_border_top_left_radius,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_border_top_left_radius",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "facade.name_border_top_left_radius",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list tr" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value:
                                    _vm.facade.name_border_top_right_radius,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_border_top_right_radius",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "facade.name_border_top_right_radius",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list bl" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value:
                                    _vm.facade.name_border_bottom_left_radius,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_border_bottom_left_radius",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "facade.name_border_bottom_left_radius",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list br" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value:
                                    _vm.facade.name_border_bottom_right_radius,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_border_bottom_right_radius",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "facade.name_border_bottom_right_radius",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "外间距" } },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "radius-list t" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_margin_top,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "name_margin_top", $$v)
                                  },
                                  expression: "facade.name_margin_top",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list b" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_margin_bottom,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_margin_bottom",
                                      $$v
                                    )
                                  },
                                  expression: "facade.name_margin_bottom",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list l" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_margin_left,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_margin_left",
                                      $$v
                                    )
                                  },
                                  expression: "facade.name_margin_left",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list r" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_margin_right,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_margin_right",
                                      $$v
                                    )
                                  },
                                  expression: "facade.name_margin_right",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内间距" } },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "radius-list t" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_padding_top,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_padding_top",
                                      $$v
                                    )
                                  },
                                  expression: "facade.name_padding_top",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list b" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_padding_bottom,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_padding_bottom",
                                      $$v
                                    )
                                  },
                                  expression: "facade.name_padding_bottom",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list l" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_padding_left,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_padding_left",
                                      $$v
                                    )
                                  },
                                  expression: "facade.name_padding_left",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list r" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.name_padding_right,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "name_padding_right",
                                      $$v
                                    )
                                  },
                                  expression: "facade.name_padding_right",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.name_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "name_color", $$v)
                            },
                            expression: "facade.name_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.name_fontsize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "name_fontsize", $$v)
                            },
                            expression: "facade.name_fontsize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字样式" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.name_fontweight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "name_fontweight", $$v)
                              },
                              expression: "facade.name_fontweight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style !== 1 && _vm.content.style !== 4
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "图片间距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.facade.item_padding,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "item_padding", $$v)
                          },
                          expression: "facade.item_padding",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "h30" }),
              _vm._v(" "),
              _c("choose", {
                staticClass: "mt20",
                attrs: {
                  data: _vm.pointerData,
                  title: "指示器类型",
                  margin: "0 10px 0 0",
                  width: "75px",
                  height: "30px",
                },
                model: {
                  value: _vm.facade.indicator_style,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "indicator_style", $$v)
                  },
                  expression: "facade.indicator_style",
                },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "指示器样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.indicator_vertical_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicator_vertical_align", $$v)
                        },
                        expression: "facade.indicator_vertical_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "top" } }, [
                        _vm._v("图片上"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "bottom" } }, [
                        _vm._v("图片下"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "指示器对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.indicator_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicator_align", $$v)
                        },
                        expression: "facade.indicator_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("中间"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("左"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "指示器颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.indicator_default_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "indicator_default_color", $$v)
                      },
                      expression: "facade.indicator_default_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "选中颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.indicator_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "indicator_color", $$v)
                      },
                      expression: "facade.indicator_color",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }