"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
var _marketing = require("@/api/marketing");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _nftblind = _interopRequireDefault(require("@/components/customChildren/nftblind.vue"));
var _blindBox = _interopRequireDefault(require("@/components/customChildren/blindBox.vue"));
var _coupon = _interopRequireDefault(require("@/components/customChildren/coupon.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var prizearr = [{
  day: 1,
  type: 1,
  total_num: 0,
  name: ''
}];
var _default = exports.default = {
  components: {
    selectProduct: _goods.default,
    Nft: _nft.default,
    Nftblind: _nftblind.default,
    Productblind: _blindBox.default,
    Coupon: _coupon.default
  },
  data: function data() {
    return {
      prize_data: prizearr
    };
  },
  computed: {},
  created: function created() {
    this.loadDetail();
  },
  methods: {
    addrule: function addrule() {
      this.prize_data.push({
        day: 1,
        type: 1,
        total_num: 0,
        name: ''
      });
    },
    saveRule: function saveRule(row, index) {
      var _this = this;
      if (!row.prize && row.type != 1) {
        this.$message.error('请选择奖励');
        return;
      }
      if (row.rule_id) {
        (0, _marketing.integralRuleEditApi)(row).then(function (res) {
          _this.$message.success('保存成功');
        });
        return;
      }
      (0, _marketing.integralRuleCreateApi)(row).then(function (res) {
        _this.$message.success('保存成功');
        _this.prize_data[index].rule_id = res.data;
      });
    },
    delRule: function delRule(row, index) {
      var _this2 = this;
      if (row.rule_id) {
        this.$confirm("\u8BE5\u89C4\u5219\u6B63\u5728\u4F7F\u7528\uFF0C\u786E\u8BA4\u5220\u9664\u8BE5\u89C4\u5219?", '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _marketing.integralRuleDelApi)({
            rule_id: row.rule_id
          }).then(function (res) {});
          _this2.prize_data.splice(index, 1);
        }).catch(function () {});
        return;
      }
      this.prize_data.splice(index, 1);
    },
    loadDetail: function loadDetail() {
      var _this3 = this;
      (0, _marketing.integralRuleListApi)().then(function (res) {
        var data = res.data.list;
        data.forEach(function (item) {
          if (item.type == 2 || item.type == 3 || item.type == 5) {
            item.name = item.prize_info.store_name;
          } else if (item.type == 4) {
            item.name = item.prize_info.name;
          }
        });
        _this3.prize_data = data;
        //   this.prize_data = data.prize;
      }).catch(function (_ref) {
        var message = _ref.message;
        _this3.$message.error(message);
        _this3.$router.back();
      });
    },
    backAction: function backAction() {
      this.$router.back();
    },
    handleAdditem: function handleAdditem(view, i) {
      this.changeType = "";
      this.changeIndex = i;
      var name = "";
      if (view == 2) {
        name = "product_nft";
      } else if (view == 3) {
        name = "product_nft_blind";
      } else if (view == 5) {
        name = "product";
      } else if (view == 4) {
        name = "coupon";
      }
      this.$refs[name].changeshow();
    },
    handleBuyAdditem: function handleBuyAdditem(view, i) {
      this.changeType = "buy";
      this.changeIndex = i;
      this.$refs[view].changeshow();
    },
    delPrizeAction: function delPrizeAction(i) {
      this.prize_data.splice(i, 1);
    },
    editTypeAction: function editTypeAction(i) {
      var d = this.prize_data[i];
      d.prize_image = "";
      d.prize_name = "";
      d.prize_relation_name = "";
      d.prize_relation_id = "";
      d.name = "";
      d.prize = "";
      this.prize_data[i] = d;
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data[0]);
      if (data.data && data.data[0]) {
        var val = data.data[0];
        if (this.changeType === "buy") {
          var d = this.buy_product_data[this.changeIndex];
          d.image = val.image.split("!")[0];
          d.name = val.name;
          if (val.p_id) {
            d.id = val.p_id;
          } else {
            d.id = val.id;
          }
          this.buy_product_data[this.changeIndex] = d;
        } else {
          var _d = this.prize_data[this.changeIndex];
          if (!_d.prize_image) {
            _d.prize_image = val.image.split("!")[0];
          }
          if (!_d.prize_name) {
            _d.prize_name = val.name;
          }
          if (val.p_id) {
            _d.prize_relation_id = val.p_id;
          } else {
            _d.prize_relation_id = val.id;
          }
          _d.stock = val.stock;
          _d.prize_relation_name = val.name;
          _d.name = val.name;
          _d.prize = _d.prize_relation_id;
          this.prize_data[this.changeIndex] = _d;
        }
      }
    },
    delImg: function delImg(key, otherKey) {
      if (otherKey) {
        this.ruleForm[otherKey][key] = "";
      } else {
        this.ruleForm[key] = "";
      }
    }
  }
};