var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "digital-collection" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.addAction },
              },
              [_vm._v("新增规则")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "规则ID", "min-width": "40" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.config_id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "规则名称", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.title))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "规则详情", "min-width": "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.is_transfer
                          ? _c(
                              "div",
                              _vm._l(row.config_data, function (m, i) {
                                return _c("span", { key: i }, [
                                  _vm._v(
                                    _vm._s(
                                      i == 0
                                        ? "首次转赠（" +
                                            m.day +
                                            "天）" +
                                            m.price +
                                            "元"
                                        : "/第" +
                                            (i + 1) +
                                            "次（" +
                                            m.day +
                                            "天）" +
                                            m.price +
                                            "元"
                                    )
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c("div", [_vm._v("不可转赠")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.create_time))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editAction(row)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "commonedit" },
                            }),
                            _vm._v("编辑"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.delAction(row.config_id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: { "current-change": _vm.getList },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新建转赠规则",
            visible: _vm.newDeliverDetail.show,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.newDeliverDetail, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.newDeliverDetail,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "规则名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入规则名称" },
                    model: {
                      value: _vm.newDeliverDetail.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "name", $$v)
                      },
                      expression: "newDeliverDetail.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "不可转赠", prop: "name" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ccc",
                      "active-value": 0,
                      "inactive-value": 1,
                    },
                    model: {
                      value: _vm.newDeliverDetail.is_transfer,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "is_transfer", $$v)
                      },
                      expression: "newDeliverDetail.is_transfer",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.newDeliverDetail.is_transfer
                ? _c(
                    "div",
                    { staticClass: "obj-view" },
                    [
                      _vm._l(_vm.newDeliverDetail.condition, function (m, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "dl flex align-items-c" },
                          [
                            _c(
                              "div",
                              { staticClass: "r flex align-items-c" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "padding-left": "34px" } },
                                  [_vm._v("第")]
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w50 ml5 mr5",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { value: i + 1, disabled: "" },
                                }),
                                _c("span", [_vm._v("次转赠")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("，获得")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w100 ml5 mr5",
                                  staticStyle: { "text-align": "center" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.editDayAction(i)
                                    },
                                  },
                                  model: {
                                    value: m.day,
                                    callback: function ($$v) {
                                      _vm.$set(m, "day", $$v)
                                    },
                                    expression: "m.day",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("天后可转赠，并收取")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w100 ml5 mr5",
                                  staticStyle: { "text-align": "center" },
                                  model: {
                                    value: m.price,
                                    callback: function ($$v) {
                                      _vm.$set(m, "price", $$v)
                                    },
                                    expression: "m.price",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("元手续费")]),
                                _vm._v(" "),
                                i > 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "ml20",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delRuleAction(i)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pop",
                          staticStyle: { "padding-left": "34px" },
                        },
                        [_vm._v("最后一个规则包含设置的次数及以上天数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mt10",
                          staticStyle: { "padding-left": "34px" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.addRuleAction },
                            },
                            [_vm._v("+增加规则")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", [_vm._v("取消")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }