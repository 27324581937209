"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bindPmAlbumApi = bindPmAlbumApi;
exports.getSdkTokenApi = getSdkTokenApi;
exports.getTempListApi = getTempListApi;
exports.userExhibitionCreate = userExhibitionCreate;
exports.userExhibitionDel = userExhibitionDel;
exports.userExhibitionDetailApi = userExhibitionDetailApi;
exports.userExhibitionEdit = userExhibitionEdit;
exports.userExhibitionListApi = userExhibitionListApi;
exports.userExhibitionProductListApi = userExhibitionProductListApi;
exports.userExhibitionUpdateSort = userExhibitionUpdateSort;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 用户展馆
 */
function userExhibitionListApi(data) {
  return _request.default.get('meta/space/list', data);
}

/**
 * @description 用户展馆详情
 */
function userExhibitionDetailApi(data) {
  return _request.default.get('meta/space/detail', data);
}

/**
 * @description 用户展馆 展品详情
 */
function userExhibitionProductListApi(data) {
  return _request.default.get('meta/space/getProductList', data);
}

/**
 * @description 创建展馆
 */
function userExhibitionCreate(data) {
  return _request.default.post('meta/space/create', data);
}

/**
 * @description 修改展馆排序
 */
function userExhibitionUpdateSort(data) {
  return _request.default.post('open3d/userExhibition/updateSort', data);
}

/**
 * @description 删除展馆
 */
function userExhibitionDel(id) {
  return _request.default.post("meta/space/delete/".concat(id));
}

/**
 * @description 展厅模板列表
 */
function getTempListApi(data) {
  return _request.default.get('meta/space/modelList', data);
}

/**
 * @description 修改展馆
 */
function userExhibitionEdit(id, data) {
  return _request.default.post("meta/space/update/".concat(id), data);
}

/**
 * @description 获取sdk token
 */
function getSdkTokenApi() {
  return _request.default.get('meta/space/getToken');
}

/**
 * @description 空间绑定专场
 */
function bindPmAlbumApi(id, data) {
  return _request.default.post("meta/space/bindPmAlbum/".concat(id), data);
}