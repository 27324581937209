var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "demo-ruleForm ft14 color-black",
          attrs: { "label-width": "150px" },
        },
        [
          _c("el-form-item", { attrs: { label: "排行榜用户数量" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("el-input-number", {
                  staticClass: "w100",
                  attrs: { controls: false },
                  model: {
                    value:
                      _vm.formData.kuanTangIntegralConfig_integralRankUserCount,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "kuanTangIntegralConfig_integralRankUserCount",
                        $$v
                      )
                    },
                    expression:
                      "formData.kuanTangIntegralConfig_integralRankUserCount",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ft14 ml10 fWeight500" }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "欢迎图片" } }, [
            _c("div", { staticClass: "flex align-items-c" }, [
              _c(
                "div",
                {
                  staticClass: "upload-view flex align-items-c justify-c mr20",
                  staticStyle: { position: "relative" },
                  on: {
                    click: function ($event) {
                      return _vm.changeImg(
                        "kuanTangIntegralConfig_welcomeMessage",
                        _vm.i
                      )
                    },
                  },
                },
                [
                  _vm.formData.kuanTangIntegralConfig_welcomeMessage
                    ? _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src:
                            _vm.formData.kuanTangIntegralConfig_welcomeMessage +
                            "!m640",
                          fit: "cover",
                        },
                      })
                    : _c("div", { staticClass: "item-flex-center flex-col" }, [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" "),
                        _c("div", [_vm._v("欢迎图片")]),
                      ]),
                  _vm._v(" "),
                  _vm.formData.kuanTangIntegralConfig_welcomeMessage
                    ? _c(
                        "div",
                        {
                          staticClass: "del-img item-flex-center",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.formData.kuanTangIntegralConfig_welcomeMessage =
                                ""
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "排行榜活动规则" } }, [
            _c("div", { staticClass: "flex align-items-c" }, [
              _c(
                "div",
                {
                  staticClass: "upload-view flex align-items-c justify-c mr20",
                  staticStyle: { position: "relative" },
                  on: {
                    click: function ($event) {
                      return _vm.changeImg(
                        "kuanTangIntegralConfig_integralRuleMessage",
                        _vm.i
                      )
                    },
                  },
                },
                [
                  _vm.formData.kuanTangIntegralConfig_integralRuleMessage
                    ? _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src:
                            _vm.formData
                              .kuanTangIntegralConfig_integralRuleMessage +
                            "!m640",
                          fit: "cover",
                        },
                      })
                    : _c("div", { staticClass: "item-flex-center flex-col" }, [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" "),
                        _c("div", [_vm._v("活动规则")]),
                      ]),
                  _vm._v(" "),
                  _vm.m
                    ? _c(
                        "div",
                        {
                          staticClass: "del-img item-flex-center",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.formData.kuanTangIntegralConfig_integralRuleMessage =
                                ""
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "活动奖品" } }, [
            _c("div", { staticClass: "flex align-items-c" }, [
              _c(
                "div",
                {
                  staticClass: "upload-view flex align-items-c justify-c mr20",
                  staticStyle: { position: "relative" },
                  on: {
                    click: function ($event) {
                      return _vm.changeImg(
                        "kuanTangIntegralConfig_integralRankPrizesImage",
                        _vm.i
                      )
                    },
                  },
                },
                [
                  _vm.formData.kuanTangIntegralConfig_integralRankPrizesImage
                    ? _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src:
                            _vm.formData
                              .kuanTangIntegralConfig_integralRankPrizesImage +
                            "!m640",
                          fit: "cover",
                        },
                      })
                    : _c("div", { staticClass: "item-flex-center flex-col" }, [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" "),
                        _c("div", [_vm._v("活动奖品")]),
                      ]),
                  _vm._v(" "),
                  _vm.formData.kuanTangIntegralConfig_integralRankPrizesImage
                    ? _c(
                        "div",
                        {
                          staticClass: "del-img item-flex-center",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.formData.kuanTangIntegralConfig_integralRankPrizesImage =
                                ""
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "积分规则" } }, [
            _c("div", { staticClass: "flex align-items-c" }, [
              _c(
                "div",
                {
                  staticClass: "upload-view flex align-items-c justify-c mr20",
                  staticStyle: { position: "relative" },
                  on: {
                    click: function ($event) {
                      return _vm.changeImg(
                        "kuanTangIntegralConfig_integralRankMessage",
                        _vm.i
                      )
                    },
                  },
                },
                [
                  _vm.formData.kuanTangIntegralConfig_integralRankMessage
                    ? _c("el-image", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src:
                            _vm.formData
                              .kuanTangIntegralConfig_integralRankMessage +
                            "!m640",
                          fit: "cover",
                        },
                      })
                    : _c("div", { staticClass: "item-flex-center flex-col" }, [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" "),
                        _c("div", [_vm._v("积分规则")]),
                      ]),
                  _vm._v(" "),
                  _vm.m
                    ? _c(
                        "div",
                        {
                          staticClass: "del-img item-flex-center",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.formData.kuanTangIntegralConfig_integralRankMessage =
                                ""
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-delete" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "big",
                    attrs: { type: "primary" },
                    on: { click: _vm.save },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }