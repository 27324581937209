import { render, staticRenderFns } from "./attribute.vue?vue&type=template&id=6f6da098&scoped=true"
import script from "./attribute.vue?vue&type=script&lang=js"
export * from "./attribute.vue?vue&type=script&lang=js"
import style0 from "./attribute.vue?vue&type=style&index=0&id=6f6da098&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6da098",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f6da098')) {
      api.createRecord('6f6da098', component.options)
    } else {
      api.reload('6f6da098', component.options)
    }
    module.hot.accept("./attribute.vue?vue&type=template&id=6f6da098&scoped=true", function () {
      api.rerender('6f6da098', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/page-renovation/modules/template/coupon/attribute.vue"
export default component.exports