var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "name"
        ? [
            _c(
              "control",
              { attrs: { title: "专场名称", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "100px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.paddingTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "paddingTop", $$v)
                              },
                              expression: "facade.paddingTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上内间距" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.area_background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "area_background", $$v)
                              },
                              expression: "facade.area_background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "拍卖会颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.meeting_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "meeting_color", $$v)
                              },
                              expression: "facade.meeting_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "拍卖会字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.meeting_fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "meeting_fontSize", $$v)
                              },
                              expression: "facade.meeting_fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "拍卖会行高" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.meeting_lineHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "meeting_lineHeight", $$v)
                              },
                              expression: "facade.meeting_lineHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "拍卖会风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.meeting_fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "meeting_fontWeight",
                                    $$v
                                  )
                                },
                                expression: "facade.meeting_fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "专场名称距上" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.nameMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "nameMarginTop", $$v)
                              },
                              expression: "facade.nameMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "专场名称颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "专场字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "专场行高" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.lineHeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "lineHeight", $$v)
                              },
                              expression: "facade.lineHeight",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "专场风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "control",
              { attrs: { title: "专场状态", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.paddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "paddingTop", $$v)
                            },
                            expression: "facade.paddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上内间距" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.area_background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "area_background", $$v)
                            },
                            expression: "facade.area_background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("提醒图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "remindIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.remindIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("remindIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.remindIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.remindIcon + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图标文字距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.remindMargin,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "remindMargin", $$v)
                            },
                            expression: "facade.remindMargin",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "detail"
        ? [
            _c(
              "control",
              { attrs: { title: "专场详情", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.paddingTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "paddingTop", $$v)
                            },
                            expression: "facade.paddingTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上内间距" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.area_background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "area_background", $$v)
                            },
                            expression: "facade.area_background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图片圆角" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.img_radius,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "img_radius", $$v)
                            },
                            expression: "facade.img_radius",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("热度图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "hotIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.hotIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("hotIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.hotIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.hotIcon + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "热度颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.hotColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hotColor", $$v)
                            },
                            expression: "facade.hotColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "热度字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.hotFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hotFontSize", $$v)
                            },
                            expression: "facade.hotFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "热度风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.hotFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hotFontWeight", $$v)
                              },
                              expression: "facade.hotFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("出价次数图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "bidCountIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.bidCountIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("bidCountIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.bidCountIcon
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.bidCountIcon + "!300",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价次数颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.bidCountColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "bidCountColor", $$v)
                            },
                            expression: "facade.bidCountColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价次数字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.bidCountFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "bidCountFontSize", $$v)
                            },
                            expression: "facade.bidCountFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价次数风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.bidCountFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "bidCountFontWeight", $$v)
                              },
                              expression: "facade.bidCountFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容间距" } },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "radius-list t" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.infoMarginTop,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "infoMarginTop", $$v)
                                  },
                                  expression: "facade.infoMarginTop",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list b" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.infoMarginBottom,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "infoMarginBottom",
                                      $$v
                                    )
                                  },
                                  expression: "facade.infoMarginBottom",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 50, max: 300 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "内容颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("更多图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "moreIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.moreIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("moreIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.moreIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.moreIcon + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "竖版分割线" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.divisionLine,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "divisionLine", $$v)
                            },
                            expression: "facade.divisionLine",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品列表", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.area_background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "area_background", $$v)
                            },
                            expression: "facade.area_background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "操作栏高度" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 200 },
                          model: {
                            value: _vm.facade.tabHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabHeight", $$v)
                            },
                            expression: "facade.tabHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "TAB文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.tabColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabColor", $$v)
                            },
                            expression: "facade.tabColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "TAB文字字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.tabFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "tabFontSize", $$v)
                            },
                            expression: "facade.tabFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "TAB文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.tabFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "tabFontWeight", $$v)
                              },
                              expression: "facade.tabFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "TAB选中颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.currTabColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "currTabColor", $$v)
                            },
                            expression: "facade.currTabColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "TAB文字字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.currTabFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "currTabFontSize", $$v)
                            },
                            expression: "facade.currTabFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "TAB文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.currTabFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "currTabFontWeight", $$v)
                              },
                              expression: "facade.currTabFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("切换单列图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "oneIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.oneIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("oneIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.oneIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.oneIcon + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("切换列表图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "listIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.listIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("listIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.listIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.listIcon + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("切换双列图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "listIconCol")
                            },
                          },
                        },
                        [
                          _vm.facade.listIconCol
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("listIconCol")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.listIconCol
                            ? _c("img", {
                                attrs: { src: _vm.facade.listIconCol + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("搜索图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "searchIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.searchIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("searchIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.searchIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.searchIcon + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "默认模板" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.listShow,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "listShow", $$v)
                              },
                              expression: "facade.listShow",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("单列"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("双列"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("列表"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "即将结拍排序" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.endOrderType,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "endOrderType", $$v)
                              },
                              expression: "facade.endOrderType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "end" } }, [
                              _vm._v("按结束时间"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "order_num" } }, [
                              _vm._v("按LOT号"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "ft14" }, [
                  _vm._v("拍品请在左侧拍品组件里设置"),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }