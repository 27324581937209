var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品图片", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "banner-control",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("区域尺寸")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.view_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "view_width", $$v)
                                  },
                                  expression: "facade.view_width",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { margin: "0 6px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.view_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "view_height", $$v)
                                  },
                                  expression: "facade.view_height",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("图片尺寸")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.img_width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "img_width", $$v)
                                  },
                                  expression: "facade.img_width",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { margin: "0 6px" },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "80px" },
                                attrs: { type: "text", placeholder: "宽" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.facade.img_height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "img_height", $$v)
                                  },
                                  expression: "facade.img_height",
                                },
                              },
                              [
                                _c("template", { slot: "suffix" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图片圆角" } },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "radius-list tl" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.imgTopLeftRadius,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "imgTopLeftRadius",
                                      $$v
                                    )
                                  },
                                  expression: "facade.imgTopLeftRadius",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list tr" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.imgTopRightRadius,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "imgTopRightRadius",
                                      $$v
                                    )
                                  },
                                  expression: "facade.imgTopRightRadius",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list br" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.imgBottomRightRadius,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "imgBottomRightRadius",
                                      $$v
                                    )
                                  },
                                  expression: "facade.imgBottomRightRadius",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list bl" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.imgBottomLeftRadius,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "imgBottomLeftRadius",
                                      $$v
                                    )
                                  },
                                  expression: "facade.imgBottomLeftRadius",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("choose", {
                      staticClass: "mt20",
                      attrs: {
                        data: _vm.pointerData,
                        title: "指示器类型",
                        margin: "0 10px 0 0",
                        width: "75px",
                        height: "30px",
                      },
                      model: {
                        value: _vm.facade.indicator_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "indicator_style", $$v)
                        },
                        expression: "facade.indicator_style",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "指示器对齐" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.facade.indicator_align,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "indicator_align", $$v)
                              },
                              expression: "facade.indicator_align",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "center" } }, [
                              _vm._v("中间"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "left" } }, [
                              _vm._v("左"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "right" } }, [
                              _vm._v("右"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "指示器未选中" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.indicator_uncolor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "indicator_uncolor", $$v)
                            },
                            expression: "facade.indicator_uncolor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "指示器选中" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.indicator_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "indicator_color", $$v)
                            },
                            expression: "facade.indicator_color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("图片展示方式"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-align-center flex-wrap" },
                      _vm._l(_vm.imgStyleData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "change-type",
                            staticStyle: {
                              "margin-right": "20px",
                              "margin-top": "15px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img",
                                class: {
                                  curr: item.value === _vm.facade.img_style,
                                },
                              },
                              [
                                _c("label", [
                                  _c("img", { attrs: { src: item.src } }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.facade.img_style,
                                        expression: "facade.img_style",
                                      },
                                    ],
                                    staticClass: "choose-input",
                                    attrs: { type: "radio", name: "choose" },
                                    domProps: {
                                      value: item.value,
                                      checked: _vm._q(
                                        _vm.facade.img_style,
                                        item.value
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.facade,
                                          "img_style",
                                          item.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "name color-gray align-c ft12" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "control",
              { attrs: { title: "状态区域", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("提醒图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "remindIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.remindIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("remindIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.remindIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.remindIcon + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("关注图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "followIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.followIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("followIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.followIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.followIcon + "!300" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center ml10",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap(1, "followAfterIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.followAfterIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delFacadeImg("followAfterIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.followAfterIcon
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.followAfterIcon + "!300",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "图标文字距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.remindMargin,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "remindMargin", $$v)
                            },
                            expression: "facade.remindMargin",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "name"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品名称", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "100px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "price"
        ? [
            _c(
              "control",
              { attrs: { title: "价格", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "100px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距下距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginBottom,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginBottom", $$v)
                              },
                              expression: "facade.marginBottom",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "h30" }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.priceColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "priceColor", $$v)
                              },
                              expression: "facade.priceColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.priceFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "priceFontSize", $$v)
                              },
                              expression: "facade.priceFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.priceFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "priceFontWeight", $$v)
                                },
                                expression: "facade.priceFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "h30" }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "拍品数量字号" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.aroundFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "aroundFontSize", $$v)
                              },
                              expression: "facade.aroundFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "拍品数量颜色" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.aroundColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "aroundColor", $$v)
                              },
                              expression: "facade.aroundColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "拍品当前位置" },
                        },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.aroundCurrColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "aroundCurrColor", $$v)
                              },
                              expression: "facade.aroundCurrColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w100 fontW400 ft14 color-black" },
                          [_vm._v("上一件")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("aroundPrevImg")
                              },
                            },
                          },
                          [
                            _vm.facade.aroundPrevImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("aroundPrevImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.aroundPrevImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.aroundPrevImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w100 fontW400 ft14 color-black" },
                          [_vm._v("下一件")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("aroundNextImg")
                              },
                            },
                          },
                          [
                            _vm.facade.aroundNextImg
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("aroundNextImg")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.aroundNextImg
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.aroundNextImg + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "h30" }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "估价信息距上" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.referencePriceMarginTop,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "referencePriceMarginTop",
                                  $$v
                                )
                              },
                              expression: "facade.referencePriceMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.referenceColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "referenceColor", $$v)
                              },
                              expression: "facade.referenceColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.referenceFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "referenceFontSize", $$v)
                              },
                              expression: "facade.referenceFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.referenceFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "referenceFontWeight",
                                    $$v
                                  )
                                },
                                expression: "facade.referenceFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.referencePriceColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "referencePriceColor", $$v)
                              },
                              expression: "facade.referencePriceColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.referencePriceFontSize,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "referencePriceFontSize",
                                  $$v
                                )
                              },
                              expression: "facade.referencePriceFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.referencePriceFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "referencePriceFontWeight",
                                    $$v
                                  )
                                },
                                expression: "facade.referencePriceFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "h30" }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mt20",
                          attrs: { label: "委托信息距上" },
                        },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.proxyBidMarginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "proxyBidMarginTop", $$v)
                              },
                              expression: "facade.proxyBidMarginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.proxyBidColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "proxyBidColor", $$v)
                              },
                              expression: "facade.proxyBidColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.proxyBidFontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "proxyBidFontSize", $$v)
                              },
                              expression: "facade.proxyBidFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.proxyBidFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "proxyBidFontWeight",
                                    $$v
                                  )
                                },
                                expression: "facade.proxyBidFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.proxyBidPriceColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "proxyBidPriceColor", $$v)
                              },
                              expression: "facade.proxyBidPriceColor",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.proxyBidPriceFontSize,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.facade,
                                  "proxyBidPriceFontSize",
                                  $$v
                                )
                              },
                              expression: "facade.proxyBidPriceFontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "价格风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.proxyBidPriceFontWeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "proxyBidPriceFontWeight",
                                    $$v
                                  )
                                },
                                expression: "facade.proxyBidPriceFontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w100 fontW400 ft14 color-black" },
                          [_vm._v("查看箭头")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction("proxyBidIcon")
                              },
                            },
                          },
                          [
                            _vm.facade.proxyBidIcon
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg("proxyBidIcon")
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.facade.proxyBidIcon
                              ? _c("img", {
                                  attrs: {
                                    src: _vm.facade.proxyBidIcon + "!120a",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "ft12 color-gray ml20" }, [
                          _vm._v("1:1正方形图片"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.content.type === "desc"
        ? [
            _c(
              "control",
              { attrs: { title: "一句话介绍", is_tab: false } },
              [
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.facade,
                        "label-width": "100px",
                        size: "mini",
                        "label-position": "left",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "距上距离" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.marginTop,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "marginTop", $$v)
                              },
                              expression: "facade.marginTop",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "背景颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF" },
                            model: {
                              value: _vm.facade.background,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "background", $$v)
                              },
                              expression: "facade.background",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字颜色" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#000000" },
                            model: {
                              value: _vm.facade.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "color", $$v)
                              },
                              expression: "facade.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "字号" } },
                        [
                          _c("slider", {
                            attrs: { min: 0, max: 100 },
                            model: {
                              value: _vm.facade.fontSize,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontSize", $$v)
                              },
                              expression: "facade.fontSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "文字风格" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.facade.fontWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "fontWeight", $$v)
                                },
                                expression: "facade.fontWeight",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 400 } }, [
                                _vm._v("正常"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 500 } }, [
                                _vm._v("加粗"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 300 } }, [
                                _vm._v("加细"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-align-center mt20" },
                        [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("只展示一行")]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "flex-one",
                            staticStyle: { "font-size": "12px", color: "#999" },
                          }),
                          _vm._v(" "),
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#009406",
                              "inactive-color": "#cccccc",
                            },
                            model: {
                              value: _vm.facade.fold,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fold", $$v)
                              },
                              expression: "facade.fold",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        : _vm.content.type === "bid_record"
        ? [
            _c(
              "control",
              { attrs: { title: "竞价记录", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "边框颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.borderColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderColor", $$v)
                            },
                            expression: "facade.borderColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("标题设置"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题高度" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 200 },
                          model: {
                            value: _vm.facade.titleHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleHeight", $$v)
                            },
                            expression: "facade.titleHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.titleColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleColor", $$v)
                            },
                            expression: "facade.titleColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.titleFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleFontSize", $$v)
                            },
                            expression: "facade.titleFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.titleFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "titleFontWeight", $$v)
                              },
                              expression: "facade.titleFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价人颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价人字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价人风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价时间颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.timeColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "timeColor", $$v)
                            },
                            expression: "facade.timeColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价时间字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.timeFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "timeFontSize", $$v)
                            },
                            expression: "facade.timeFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "出价时间风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.timeFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "timeFontWeight", $$v)
                              },
                              expression: "facade.timeFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "价格颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.priceColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "priceColor", $$v)
                            },
                            expression: "facade.priceColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "价格字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.priceFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "priceFontSize", $$v)
                            },
                            expression: "facade.priceFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "价格风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.priceFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "priceFontWeight", $$v)
                              },
                              expression: "facade.priceFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "查看更多颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.moreColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "moreColor", $$v)
                            },
                            expression: "facade.moreColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "查看更多字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.moreFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "moreFontSize", $$v)
                            },
                            expression: "facade.moreFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "查看更多风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.moreFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "moreFontWeight", $$v)
                              },
                              expression: "facade.moreFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("查看更多图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction("moreIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.moreIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("moreIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.moreIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.moreIcon + "!120a" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ft12 color-gray ml20" }, [
                        _vm._v("1:1正方形图片"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("领先图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction("leadIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.leadIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("leadIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.leadIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.leadIcon + "!120a" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ft12 color-gray ml20" }, [
                        _vm._v("1:1正方形图片"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("出局图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction("outIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.outIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("outIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.outIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.outIcon + "!120a" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ft12 color-gray ml20" }, [
                        _vm._v("1:1正方形图片"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品详情", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("标题设置"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题高度" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 200 },
                          model: {
                            value: _vm.facade.titleHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleHeight", $$v)
                            },
                            expression: "facade.titleHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.titleColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleColor", $$v)
                            },
                            expression: "facade.titleColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.titleFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleFontSize", $$v)
                            },
                            expression: "facade.titleFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.titleFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "titleFontWeight", $$v)
                              },
                              expression: "facade.titleFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "热度下距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 200 },
                          model: {
                            value: _vm.facade.hotMarginBottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hotMarginBottom", $$v)
                            },
                            expression: "facade.hotMarginBottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "热度位置" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.hotAlign,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hotAlign", $$v)
                              },
                              expression: "facade.hotAlign",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "flex-start" } }, [
                              _vm._v("居左"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "center" } }, [
                              _vm._v("居中"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "flex-end" } }, [
                              _vm._v("居右"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("热度图标")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction("hotIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.hotIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("hotIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.hotIcon
                            ? _c("img", {
                                attrs: { src: _vm.facade.hotIcon + "!120a" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ft12 color-gray ml20" }, [
                        _vm._v("1:1正方形图片"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "热度颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.hotColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hotColor", $$v)
                            },
                            expression: "facade.hotColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "热度字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.hotFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "hotFontSize", $$v)
                            },
                            expression: "facade.hotFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "热度风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.hotFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "hotFontWeight", $$v)
                              },
                              expression: "facade.hotFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("属性介绍"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性内距离" } },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "radius-list t" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.attrPaddingTop,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "attrPaddingTop", $$v)
                                  },
                                  expression: "facade.attrPaddingTop",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list b" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.attrPaddingBottom,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "attrPaddingBottom",
                                      $$v
                                    )
                                  },
                                  expression: "facade.attrPaddingBottom",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list l" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.attrPaddingLeft,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "attrPaddingLeft", $$v)
                                  },
                                  expression: "facade.attrPaddingLeft",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "radius-list r" },
                            [
                              _c("el-input-number", {
                                staticClass: "w50",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.attrPaddingRight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "attrPaddingRight",
                                      $$v
                                    )
                                  },
                                  expression: "facade.attrPaddingRight",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性边框颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.borderColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "borderColor", $$v)
                            },
                            expression: "facade.borderColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性标题文字" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.attrColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrColor", $$v)
                            },
                            expression: "facade.attrColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.attrFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrFontSize", $$v)
                            },
                            expression: "facade.attrFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性标题风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.attrFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "attrFontWeight", $$v)
                              },
                              expression: "facade.attrFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性内容文字" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#999999" },
                          model: {
                            value: _vm.facade.attrOtherColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrOtherColor", $$v)
                            },
                            expression: "facade.attrOtherColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性内容风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.attrOtherFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "attrOtherFontWeight", $$v)
                              },
                              expression: "facade.attrOtherFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性统计文字" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#999999" },
                          model: {
                            value: _vm.facade.attrShowColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attrShowColor", $$v)
                            },
                            expression: "facade.attrShowColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "属性统计风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.attrShowFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "attrShowFontWeight", $$v)
                              },
                              expression: "facade.attrShowFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w100 fontW400 ft14 color-black" },
                        [_vm._v("查看箭头")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction("showDetailIcon")
                            },
                          },
                        },
                        [
                          _vm.facade.showDetailIcon
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg("showDetailIcon")
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.showDetailIcon
                            ? _c("img", {
                                attrs: {
                                  src: _vm.facade.showDetailIcon + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ft12 color-gray ml20" }, [
                        _vm._v("1:1正方形图片"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("详情介绍"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "color", $$v)
                            },
                            expression: "facade.color",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "fontSize", $$v)
                            },
                            expression: "facade.fontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "文字风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.fontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "fontWeight", $$v)
                              },
                              expression: "facade.fontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "行高" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lineHeight", $$v)
                            },
                            expression: "facade.lineHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "control",
              { attrs: { title: "拍品列表", is_tab: false } },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.facade,
                      "label-width": "100px",
                      size: "mini",
                      "label-position": "left",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "距上距离" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.marginTop,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "marginTop", $$v)
                            },
                            expression: "facade.marginTop",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "背景颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#FFFFFF" },
                          model: {
                            value: _vm.facade.background,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "background", $$v)
                            },
                            expression: "facade.background",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("标题设置"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题高度" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 200 },
                          model: {
                            value: _vm.facade.titleHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleHeight", $$v)
                            },
                            expression: "facade.titleHeight",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题颜色" } },
                      [
                        _c("colour", {
                          attrs: { colour: "#000000" },
                          model: {
                            value: _vm.facade.titleColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleColor", $$v)
                            },
                            expression: "facade.titleColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题字号" } },
                      [
                        _c("slider", {
                          attrs: { min: 0, max: 100 },
                          model: {
                            value: _vm.facade.titleFontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "titleFontSize", $$v)
                            },
                            expression: "facade.titleFontSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "mt20", attrs: { label: "标题风格" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.facade.titleFontWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "titleFontWeight", $$v)
                              },
                              expression: "facade.titleFontWeight",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 400 } }, [
                              _vm._v("正常"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 500 } }, [
                              _vm._v("加粗"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 300 } }, [
                              _vm._v("加细"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "h30" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 color-black fWeight500" }, [
                      _vm._v("拍品设置"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ft14 mt10" }, [
                      _vm._v("请在左侧拍品组件里设置"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }