var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c("div", { staticClass: "absoBox" }, [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce66", "inactive-color": "#ccc" },
                on: { change: _vm.setOpenAction },
                model: {
                  value: _vm.opened,
                  callback: function ($$v) {
                    _vm.opened = $$v
                  },
                  expression: "opened",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "ft14 fWeight500 ml10",
                  staticStyle: { "margin-top": "3px", cursor: "pointer" },
                  on: { click: _vm.openAction },
                },
                [_vm._v("开启注册码")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.showAddAction },
                },
                [_vm._v("生成注册码")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出记录")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { border: "", data: _vm.tableData.data, size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "生成日期",
              "min-width": "120",
              prop: "create_time",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "生成数量", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showCodeAction(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(row.num))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "180", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "m0",
                        attrs: {
                          icon: "el-icon-tickets",
                          type: "text",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.exportOrder(scope.row.group_id)
                          },
                        },
                      },
                      [_vm._v("导出注册码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "m0",
                        staticStyle: { color: "red" },
                        attrs: {
                          icon: "el-icon-delete",
                          type: "text",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.delAction(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.userFrom.limit,
              "current-page": _vm.userFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showAddView,
            title: "生成注册码",
            "close-on-click-modal": false,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddView = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入生成数量" },
            model: {
              value: _vm.regiestCodeCount,
              callback: function ($$v) {
                _vm.regiestCodeCount = $$v
              },
              expression: "regiestCodeCount",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addRegiestCodeGroup },
                },
                [_vm._v("生成")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showCodeView,
            title: "查看注册码",
            "close-on-click-modal": false,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCodeView = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10" },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.loadCode(1)
                    },
                  },
                  model: {
                    value: _vm.userFromNew.is_use,
                    callback: function ($$v) {
                      _vm.$set(_vm.userFromNew, "is_use", $$v)
                    },
                    expression: "userFromNew.is_use",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 0 } }, [
                    _vm._v("未使用"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("已使用"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoadingNew,
                  expression: "listLoadingNew",
                },
              ],
              staticClass: "mt20",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableDataNew.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "注册码", "min-width": "120", prop: "code" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否使用", "min-width": "120", prop: "code" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(row.is_use ? "已使用" : "未使用")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.userFromNew.limit,
                  "current-page": _vm.userFromNew.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataNew.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeNew,
                  "current-change": _vm.pageChangeNew,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }