"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _index = require("@/utils/index");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _system = require("@/api/system");
var _member = require("@/api/member");
var _request = _interopRequireDefault(require("@/api/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      showdetail: null,
      uid: '',
      showImg: false,
      // 查看图片 控制器
      imgUrl: "",
      // 放大查看的图片

      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        keyword: ''
      },
      timeVal: [],
      expiration_timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this = this;
      this.$modalSure('是否删除此会员卡').then(function () {
        (0, _member.memberDelApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this.$message.success(message);
          _this.getList();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        });
      });
    },
    editAction: function editAction(id) {
      this.$router.push('./memberCardDetail?id=' + id);
    },
    addAction: function addAction() {
      this.$router.push('./memberCardDetail');
    },
    editStatueAction: function editStatueAction(id, s) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4".concat(s ? '下架' : '上架', "\u6B64\u4F1A\u5458\u5361\uFF1F"), '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var loading = _this2.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        (0, _member.membershipCardSwitchStatusApi)(id, {
          status: s
        }).then(function () {
          loading.close();
          _this2.$message.success('操作成功');
          _this2.getList();
        }).catch(function () {
          loading.close();
        });
      });
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _member.membershipCardListApi)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.rightItem.forEach(function (m) {
            if (m.right_type === 2 || m.right_type === 3 || m.right_type === 6) {
              m.goodsCount = 0;
              m.right_give_data.forEach(function (c) {
                m.goodsCount += Number(c.number);
              });
            }
            switch (m.right_type) {
              case 0:
                m.showDesc = m.right_type_text;
                break;
              case 1:
                m.showDesc = "\u8D60\u9001".concat(m.right_type_text);
                break;
              case 2:
                m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u4EF6\u5546\u54C1");
                break;
              case 3:
                m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u5F20\u95E8\u7968");
                break;
              case 4:
                m.showDesc = "\u8D60\u9001".concat(m.right_give_data[0].number, "\u79EF\u5206");
                break;
              case 5:
                m.showDesc = "\u8D60\u9001".concat(m.right_give_data[0].number, "\u4F59\u989D");
                break;
              case 6:
                m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u5F20\u4F18\u60E0\u5238");
                break;
            }
          });
        });
        console.log(res.data.list);
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    editSortAction: function editSortAction(val, index) {
      var _this4 = this;
      (0, _member.membershipCardEditSortApi)(this.tableData.data[index].membership_card_id, {
        sort: val
      }).then(function () {
        _this4.$message.success('修改排序成功');
      }).catch(function () {});
    },
    copyXcxLink: function copyXcxLink(m, event) {
      var url = "/pages/member/detail?id=" + m.membership_card_id;
      var u = "/pages/otherPage/otherPage?link=".concat(encodeURIComponent(url));
      var input = document.createElement("input"); // 直接构建input
      input.value = u; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    copyH5Link: function copyH5Link(m, event) {
      var url = "/pages/member/detail?id=" + m.membership_card_id;
      var url1 = "";
      if (this.domain) {
        url1 = "".concat(this.domain).concat(url);
      } else {
        url1 = "".concat(this.domain_url).concat(url);
      }
      var input = document.createElement("input"); // 直接构建input
      input.value = url1; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功");
    },
    showImgAction: function showImgAction(val, m) {
      var _this5 = this;
      this.imgUrl = '';
      var action = process.env.VUE_APP_BASE_API + "/api/sharePoster";
      var mer_id = this.$store.getters.mer_id;
      switch (val) {
        case 1:
          action += "?return_format=2&poster_type=membershipCard&qrcode_type=1&Independent_qrcode=1&source=1&id=" + m.membership_card_id + "&path=" + "/pages/member/index?id=".concat(m.membership_card_id) + "&mer_id=" + mer_id;
          break;
        case 2:
          action += "?return_format=2&poster_type=membershipCard&qrcode_type=2&Independent_qrcode=1&source=1&id=" + m.membership_card_id + "&path=" + "/pages/member/index?id=".concat(m.membership_card_id) + "&mer_id=" + mer_id;
          break;
        case 3:
          action += "?return_format=2&poster_type=membershipCard&qrcode_type=1&Independent_qrcode=0&source=1&id=" + m.membership_card_id + "&path=" + "/pages/member/index?id=".concat(m.membership_card_id) + "&mer_id=" + mer_id;
          break;
        case 4:
          action += "?return_format=2&poster_type=membershipCard&qrcode_type=2&Independent_qrcode=0&source=1&id=" + m.membership_card_id + "&path=" + "/pages/member/index?id=".concat(m.membership_card_id) + "&mer_id=" + mer_id;
          break;
        default:
          action += "?return_format=2&poster_type=membershipCard&qrcode_type=1&Independent_qrcode=1&source=1&id=" + m.membership_card_id + "&path=" + "/pages/member/index?id=".concat(m.membership_card_id) + "&mer_id=" + mer_id;
          break;
      }
      // this.imgData.imgUrl = action;
      this.showImg = true;
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this5.imgUrl = res.data.base_image;
      });
    }
  }
};