"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      templateList: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      (0, _system.templatePackageListApi)().then(function (res) {
        _this.templateList = res.data.list;
      }).catch(function () {});
    },
    useAction: function useAction(id) {
      var _this2 = this;
      this.$modalYouSure('点击确认后，小程序、APP页面会即时应用至该新模板风格，请确认！').then(function () {
        (0, _system.templatePackageUseApi)({
          id: id
        }).then(function () {
          _this2.getList();
          _this2.$message.success('使用模版成功');
        }).catch(function () {});
      });
    },
    preAction: function preAction(id) {},
    resetAction: function resetAction(id) {
      var _this3 = this;
      this.$modalYouSure('点击确认后，小程序、APP页面会即时应用至该新模板风格，请确认！').then(function () {
        (0, _system.templatePackageResetApi)({
          id: id
        }).then(function () {
          _this3.getList();
          _this3.$message.success('恢复成功');
        }).catch(function () {});
      });
    }
  }
};