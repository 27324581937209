"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _auction = require("@/api/auction");
var _userdetail = _interopRequireDefault(require("../list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        page: 1,
        limit: 20
      },
      showdetail: false,
      row: '',
      uid: '',
      dialogVisible: false,
      content: ''
    };
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      (0, _user.feedbackListApi)(this.queryParams).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    viewContent: function viewContent(item) {
      this.content = item.content;
      this.dialogVisible = true;
    },
    delAction: function delAction(id) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u5F81\u96C6\u6D3B\u52A8?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.collectAuctionDelApi)(id).then(function () {
          _this2.$message.success('删除成功');
          _this2.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};