var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "app-container",
          staticStyle: { "min-height": "100vh" },
        },
        [
          _c("header-breadcrumb", {
            attrs: { name: "库房相关配置", "has-back": "" },
          }),
          _vm._v(" "),
          _c(
            "el-card",
            [
              _c(
                "div",
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-demo default-tabs",
                      attrs: { "default-active": _vm.tab, mode: "horizontal" },
                      on: { select: _vm.changeTab },
                    },
                    [
                      _c("el-menu-item", { attrs: { index: "1" } }, [
                        _vm._v("入库单配置"),
                      ]),
                      _vm._v(" "),
                      _c("el-menu-item", { attrs: { index: "2" } }, [
                        _vm._v("出库单配置"),
                      ]),
                      _vm._v(" "),
                      _c("el-menu-item", { attrs: { index: "3" } }, [
                        _vm._v("调拨单配置"),
                      ]),
                      _vm._v(" "),
                      _c("el-menu-item", { attrs: { index: "4" } }, [
                        _vm._v("仓库配置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.setChangePage },
                        model: {
                          value: _vm.changePage,
                          callback: function ($$v) {
                            _vm.changePage = $$v
                          },
                          expression: "changePage",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("仓库列表"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio-button", { attrs: { label: "2" } }, [
                          _vm._v("货架列表"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { height: "32px" },
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.createStore },
                      },
                      [_vm._v("创建货架")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "has-search",
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: {
                    data: _vm.tableData.data,
                    border: "",
                    "row-key": function (row) {
                      return row.shelf_id
                    },
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      "reserve-selection": true,
                      width: "45",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("div", {
                              staticClass: "eltable-title relative",
                            }),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c("div", { staticClass: "search-list" })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "shelf_id", label: "ID", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "eltable-title relative" },
                              [
                                _c("span", { staticClass: "flex-one line1" }, [
                                  _vm._v("ID"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c("div", { staticClass: "search-list" })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelf_no",
                      label: "货架编码",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "eltable-title relative",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAfterEnter("shelf_no_EL")
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "flex-one line1" }, [
                                  _vm._v("货架编码"),
                                ]),
                                _vm._v(" "),
                                _c("i", { staticClass: "el-icon-search" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.openSetkey == "shelf_no_EL",
                                        expression:
                                          "openSetkey == 'shelf_no_EL'",
                                      },
                                    ],
                                    staticClass: "search-inp",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      ref: "shelf_no_EL",
                                      attrs: { placeholder: "请输入" },
                                      on: {
                                        blur: function ($event) {
                                          _vm.openSetkey = ""
                                        },
                                        change: _vm.search,
                                      },
                                      model: {
                                        value: _vm.tableFrom.shelf_no,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "shelf_no",
                                            $$v
                                          )
                                        },
                                        expression: "tableFrom.shelf_no",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c("div", { staticClass: "search-list" }, [
                                  _c("div", { staticClass: "line2" }, [
                                    _vm._v(_vm._s(_vm.openSearchData.shelf_no)),
                                  ]),
                                  _vm._v(" "),
                                  _vm.openSearchData.shelf_no
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-close cur ml5",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.clearsearch("shelf_no")
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(_vm._s(row.shelf_no)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "warehouse.name",
                      label: "所属仓库",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "eltable-title relative",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAfterEnter(
                                      "warehouse_keyword_EL"
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "flex-one line1" }, [
                                  _vm._v("所属仓库"),
                                ]),
                                _vm._v(" "),
                                _c("i", { staticClass: "el-icon-search" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.openSetkey ==
                                          "warehouse_keyword_EL",
                                        expression:
                                          "openSetkey == 'warehouse_keyword_EL'",
                                      },
                                    ],
                                    staticClass: "search-inp",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      ref: "warehouse_keyword_EL",
                                      attrs: { placeholder: "请输入" },
                                      on: {
                                        blur: function ($event) {
                                          _vm.openSetkey = ""
                                        },
                                        change: _vm.search,
                                      },
                                      model: {
                                        value: _vm.tableFrom.warehouse_keyword,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "warehouse_keyword",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "tableFrom.warehouse_keyword",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c("div", { staticClass: "search-list" }, [
                                  _c("div", { staticClass: "line2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.openSearchData.warehouse_keyword
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.openSearchData.warehouse_keyword
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-close cur ml5",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.clearsearch(
                                              "warehouse_keyword"
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(
                                _vm._s(row.warehouse ? row.warehouse.name : "")
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "product_count",
                      label: "货品数量",
                      width: "90",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "eltable-title relative" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c cur" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "flex-one line1" },
                                      [_vm._v("货品数量")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c("div", { staticClass: "search-list" })
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(_vm._s(scope.row.product_count)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "创建时间",
                      width: "155",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "eltable-title relative" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c cur" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "flex-one line1" },
                                      [_vm._v("创建时间")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c("div", { staticClass: "search-list" })
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(_vm._s(scope.row.create_time)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "product_count",
                      label: "货架状态",
                      width: "90",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "eltable-title relative" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c cur" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "flex-one line1" },
                                      [_vm._v("货架状态")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c("div", { staticClass: "search-list" })
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(_vm._s(scope.row.product_count)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "备注", prop: "remark" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "eltable-title relative" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c cur" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "flex-one line1" },
                                      [_vm._v("备注")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c("div", { staticClass: "search-list" })
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line1" }, [
                              _vm._v(_vm._s(scope.row.remark)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    key: "8",
                    attrs: { label: "操作", width: "80", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "eltable-title relative" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "flex-one line1",
                                    staticStyle: { "text-align": "center" },
                                  },
                                  [_vm._v("操作")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.openSearchData
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "search-list item-flex-center",
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-delete",
                                        },
                                        on: { click: _vm.clearAll },
                                      },
                                      [_vm._v("清空")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "TableRightBtn",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-edit-outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "template",
                                  { slot: "pop_btn" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "34px" } }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "orderFoot flex align-items-c",
                  staticStyle: { background: "#fff", "z-index": "1" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-one" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.createPrint },
                        },
                        [_vm._v("打印货架标签")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, prev, pager, next, sizes",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建货架",
            visible: _vm.createFlag,
            width: "1000px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "100px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              !_vm.isedit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formValidate.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "type", $$v)
                            },
                            expression: "formValidate.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("单个创建"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("批量创建"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == 2
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "起始编号：", prop: "start_shelf_no" },
                      },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "46%" },
                          attrs: { placeholder: "请输入编号" },
                          model: {
                            value: _vm.formValidate.start_shelf_no,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "start_shelf_no", $$v)
                            },
                            expression: "formValidate.start_shelf_no",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "结束编号：", prop: "end_shelf_no" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "46%" },
                          attrs: { placeholder: "请输入编号" },
                          model: {
                            value: _vm.formValidate.end_shelf_no,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "end_shelf_no", $$v)
                            },
                            expression: "formValidate.end_shelf_no",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == 1
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "货架编号：", prop: "shelf_no" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "46%" },
                          attrs: { placeholder: "请输入仓库编号" },
                          model: {
                            value: _vm.formValidate.shelf_no,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "shelf_no", $$v)
                            },
                            expression: "formValidate.shelf_no",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属仓库：", prop: "warehouse_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "46%" },
                      attrs: {
                        placeholder: "请选择仓库",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "warehouse_id", $$v)
                        },
                        expression: "formValidate.warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeList, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "货架备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入货架备注" },
                    model: {
                      value: _vm.formValidate.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "remark", $$v)
                      },
                      expression: "formValidate.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("创建")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "打印货架标签",
            visible: _vm.showlabel,
            direction: "rtl",
            size: "350px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showlabel = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("span", [_vm._v(" 打印货架标签 ")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.bindPrint },
                },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "printTable" } },
            _vm._l(_vm.labelarr, function (item, index) {
              return _c(
                "div",
                { key: "label" + index, staticClass: "printbox" },
                [
                  _c("div", { staticClass: "print-code" }, [
                    _vm._v(_vm._s(item.shelf_no)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "print-image" }, [
                    _c("img", {
                      attrs: {
                        src: "data:image/png;base64," + item.bar_code_image,
                        alt: "",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "print-code" }, [
                    _vm._v(_vm._s(item.warehouse.name)),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }