"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _nft = _interopRequireDefault(require("@/components/customChildren/nft"));
var _goods = _interopRequireDefault(require("@/components/customChildren/goods"));
var _auction = _interopRequireDefault(require("@/components/customChildren/auction"));
var _nftblind = _interopRequireDefault(require("@/components/customChildren/nftblind.vue"));
var _blindBox = _interopRequireDefault(require("@/components/customChildren/blindBox.vue"));
var _product = require("@/api/product");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//   import PopoverTable from './popoverTable';
var _default = exports.default = {
  name: "discountCreate",
  components: {
    Product: _goods.default,
    Auction: _auction.default,
    Nft: _nft.default,
    Nftblind: _nftblind.default,
    BlindBox: _blindBox.default
  },
  data: function data() {
    return {
      id: "",
      ruleForm: {
        name: "",
        type: 1,
        discount: "",
        product: [],
        times: []
      },
      use_range: {
        // 0 商品  7 拍卖  8数字藏品  9 数字盲盒  10  实物盲盒
        0: {
          type: 1,
          keyName: "商品",
          data: []
        }
      },
      goods_list: {
        // 0 商品  7 拍卖  8数字藏品  9 数字盲盒  10  实物盲盒
        0: {
          type: 0,
          keyName: "商品",
          data: []
        }
      },
      rules: {
        name: {
          required: true,
          message: "请输入名称",
          trigger: "blur"
        },
        type: {
          required: true,
          message: "请选择类型",
          trigger: "change"
        },
        times: {
          required: true,
          message: "请选择时间"
        },
        discount: {
          required: true,
          message: "请输入折扣"
        }
      },
      loading: false,
      changeIndex: 0,
      status: 0,
      merCateList: [],
      changeType: 1
    };
  },
  created: function created() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.load();
    }
  },
  methods: {
    load: function load() {
      var _this = this;
      (0, _product.discountDetailApi)({
        id: this.id
      }).then(function (res) {
        var data = res.data;
        _this.status = data.status;
        _this.ruleForm = {
          name: data.name,
          type: data.type,
          times: data.start_time && data.end_time ? [data.start_time, data.end_time] : [],
          discount: data.discount
        };
        _this.use_range[0].type = data.type;
        (0, _product.productLstApi)({
          product_ids: data.product.join(',')
        }).then(function (res) {
          res.data.list.forEach(function (item) {
            item.name = item.store_name;
            item.id = item.product_id;
          });
          _this.use_range[0].data = res.data.list;
        });
      });
    },
    changeTypeAction: function changeTypeAction(val, i) {
      var _this2 = this;
      if (val === 1) {
        this.changeType = 1;
        this.$nextTick(function () {
          _this2.showChangeAction(i);
        });
      }
    },
    changeSendTypeAction: function changeSendTypeAction(val, i) {
      var _this3 = this;
      if (val === 2) {
        this.changeType = 2;
        this.$nextTick(function () {
          _this3.showChangeAction(i);
        });
      }
    },
    showChangeAction: function showChangeAction(i) {
      console.log(this.changeType);
      this.changeIndex = i;
      var view = "";
      switch (Number(i)) {
        case 0:
          view = "product";
          break;
        case 7:
          view = "auction";
          break;
        case 8:
          view = "product_nft";
          break;
        case 9:
          view = "nft_blind";
          break;
        case 10:
          view = "product_blind";
          break;
      }
      this.$refs[view].changeshow();
    },
    selectMessage: function selectMessage(data) {
      if (this.use_range[this.changeIndex]) {
        this.use_range[this.changeIndex].data = data.data;
      }
    },
    selectMessageNew: function selectMessageNew(data) {
      if (this.goods_list[this.changeIndex]) {
        this.goods_list[this.changeIndex].data = data.data;
      }
    },
    editAction: function editAction(key) {
      this.$refs.ruleForm.validateField([key], function (Error) {
        if (!Error) {
          // 验证通过
          console.log("通过了");
        } else {
          console.log("验证不通过");
        }
      });
    },
    delgoods: function delgoods(index) {
      this.use_range[0].data.splice(index, 1);
    },
    saveAction: function saveAction(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this4.use_range[0].type == 1 && !_this4.use_range[0].data.length) {
            _this4.$message.error("请选择折扣商品");
            return;
          }
          var data = JSON.parse(JSON.stringify(_this4.ruleForm));
          data.start_time = data.times[0];
          data.end_time = data.times[1];
          data.product = _this4.use_range[0].data.map(function (m) {
            return m.id;
          });
          data.type = _this4.use_range[0].type;
          var loading = _this4.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          var action = _product.discountCreateApi;
          if (_this4.id) {
            data.id = _this4.id;
            action = _product.discountEditApi;
          }
          action(data).then(function () {
            loading.close();
            _this4.$message.success("操作成功");
            _this4.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          _this4.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};