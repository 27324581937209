"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var basicRouter = {
  path: "".concat(_settings.roterPre, "/basic"),
  name: 'basic',
  meta: {
    icon: '',
    title: '货品资料'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'basiclist',
    name: 'basiclist',
    meta: {
      title: '货品资料',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/basic'));
      });
    }
  }, {
    path: 'shelflist',
    name: 'shelflist',
    meta: {
      title: '货架列表',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/shelfList'));
      });
    }
  }, {
    path: 'goodslist',
    name: 'goodslist',
    meta: {
      title: '货品列表',
      noCache: true,
      permissionKey: '/basic/goodslist'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/goodsList'));
      });
    }
  }, {
    path: 'auditCheck',
    name: 'auditCheck',
    meta: {
      title: '审核清单',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/basic/goodslist")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/auditCheck'));
      });
    }
  }, {
    path: 'goodsConfig',
    name: 'goodsConfig',
    meta: {
      title: '货品配置',
      noCache: true
    },
    // redirect: `${roterPre}/contract/contractConfig/contractSetting`,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/allbasic'));
      });
    },
    children: [{
      path: 'picture',
      name: 'system_config_picture',
      meta: {
        title: '素材管理',
        activeMenu: "".concat(_settings.roterPre, "/basic/goodslist")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/config/picture'));
        });
      }
    }, {
      path: 'classify',
      name: 'ProductClassify',
      meta: {
        title: '商品分类',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/basic/goodslist")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/productClassify'));
        });
      }
    }, {
      path: 'attrList',
      name: 'AttrList',
      meta: {
        title: '属性管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/basic/goodslist")
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/attr/attrList'));
        });
      }
    }, {
      path: 'lable',
      name: 'lable',
      meta: {
        title: '标签',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/basic/goodslist"),
        permissionKey: '/basic/lable'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/basic/lable'));
        });
      }
    }, {
      path: 'brandList',
      name: 'BrandList',
      meta: {
        title: '品牌列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/basic/goodslist"),
        permissionKey: '/product/band/brandList'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/band/bandList'));
        });
      }
    }, {
      path: 'attr',
      name: "ProductAttr",
      meta: {
        title: '商品规格',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/basic/goodslist"),
        permissionKey: '/product/attr'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/product/productAttr'));
        });
      }
    }]
  }, {
    path: 'upgoods',
    name: 'upgoods',
    meta: {
      title: '上架到拍卖',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/goodstoauction'));
      });
    }
  }, {
    path: 'creategoods/:id?',
    name: 'creategoods',
    meta: {
      title: '创建货品',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/createGoods'));
      });
    }
  }, {
    path: 'letter',
    name: 'letter',
    meta: {
      title: '委托人',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/letter'));
      });
    }
  }, {
    path: 'inventory',
    name: 'inventory',
    meta: {
      title: '委托标清单',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/inventory'));
      });
    }
  }, {
    path: 'inventorycreate',
    name: 'inventorycreate',
    meta: {
      title: '创建委托清单',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/basic/inventory")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/inventorycreate'));
      });
    }
  },
  // {
  //   path: 'sellmanage',
  //   name: 'sellmanage',
  //   meta: {
  //     title: '销售管理',
  //     noCache: true,
  //     permissionKey: '/basic/sellmanage'
  //   },
  //   component: () => import('@/views/basic/sellmanage')
  // },
  {
    path: 'sellcreate',
    name: 'sellcreate',
    meta: {
      title: '创建销售单',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/basic/sellmanage")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/basic/sellcreate'));
      });
    }
  }]
};
var _default = exports.default = basicRouter;