"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _index = require("@/utils/index");
var _contract = require("@/api/contract");
var _order = require("@/api/order");
var _user = require("@/api/user.js");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      total_rmb: '',
      result: [],
      isNo: true,
      merData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
  },
  methods: {
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      var that = this;
      (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page {margin:5mm 10mm 12mm}",
        onPrintDialogClose: function onPrintDialogClose() {
          console.log('关闭');
        }
      }, "onPrintDialogClose", function onPrintDialogClose() {
        console.log('pdf');
        that.$emit('load');
      }), "onPdfOpen", function onPdfOpen() {
        console.log('pdf1');
      }));
    },
    loadData: function loadData(data) {
      var _this2 = this;
      this.isNo = true;
      var blTotal = 0;
      var sdTotal = 0;
      var lcTotal = 0;
      data.auctionList.forEach(function (m) {
        blTotal += m.auction ? Number(m.erpProduct.retain_price) : 0;
        sdTotal += m.erpProduct ? Number(m.erpProduct.take_home_price) : 0;
        lcTotal += m.erpProduct ? Number(m.auction.now_price) : 0;
      });
      this.collectMoney = {
        data: data,
        show: true,
        blTotal: blTotal,
        sdTotal: sdTotal,
        lcTotal: lcTotal
      };
      this.$nextTick(function () {
        // 赋值后马上更新
        _this2.compendHeight();
      });
    },
    compendHeight: function compendHeight() {
      var num = 0;
      var heightAll = 0;
      var arr = [];
      var dom = document.getElementsByClassName('calcul');
      for (var i = 0; i < dom.length; i++) {
        heightAll += Number(window.getComputedStyle(dom[i]).height.replace('px', ''));
        if (num == 0 && heightAll > 1020) {
          arr.push(i);
          num += 1;
        } else if (num > 0 && heightAll > 1300 * num + 1020) {
          arr.push(i);
          num += 1;
        }
      }
      ;
      this.result = this.sliceArrayIntoChunks(this.collectMoney.data.auctionList, arr);
      this.isNo = false;
    },
    sliceArrayIntoChunks: function sliceArrayIntoChunks(array, indexes) {
      var arr = [];
      var len = array.length;
      var ind = 0;
      indexes.forEach(function (item, index) {
        arr.push(array.slice(ind, item));
        ind = item;
      });
      arr.push(array.slice(indexes[indexes.length - 1], len));
      return arr;
    }
  }
};