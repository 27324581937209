var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "calc(100vh - 140px)" } },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            height: "100%",
            data: _vm.tableList,
            "span-method": _vm.spanMethod,
            size: "mini",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "业务板块", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.bus) + " ")]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "提醒内容", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.con) + " ")]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "业务场景", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.ywcj) + " ")]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "推送人群", "min-width": "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    [
                      "entrustContractSignedToAdmin",
                      "entrustAuctionLeaveWarehouseToAdmin",
                      "newProductOrderToAdmin",
                      "entrustNewTaskToAdmin",
                    ].includes(row.filed)
                      ? [
                          _c(
                            "el-select",
                            {
                              staticClass: "w100",
                              on: {
                                change: function ($event) {
                                  return _vm.onSelectChange(row.filed)
                                },
                              },
                              model: {
                                value: _vm.tableOptions[row.filed].admin_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tableOptions[row.filed],
                                    "admin_id",
                                    $$v
                                  )
                                },
                                expression: "tableOptions[row.filed].admin_id",
                              },
                            },
                            _vm._l(_vm.userList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  value: item.merchant_admin_id,
                                  label: item.real_name,
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      : [_vm._v(_vm._s(row.tsrq) + " ")],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "推送时间", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.filed == "auctionStartNoticeSyn"
                      ? [
                          _vm._v("\n                  提前"),
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false, min: 0 },
                            on: {
                              change: function ($event) {
                                return _vm.onInputChange(row.filed)
                              },
                            },
                            model: {
                              value: _vm.tableOptions[row.filed].push_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableOptions[row.filed],
                                  "push_time",
                                  $$v
                                )
                              },
                              expression: "tableOptions[row.filed].push_time",
                            },
                          }),
                          _vm._v("件推送\n              "),
                        ]
                      : row.filed == "identificationResult"
                      ? [
                          _vm._v("\n                  鉴定结果后"),
                          _c("el-input-number", {
                            staticClass: "w50",
                            attrs: { controls: false, min: 0 },
                            on: {
                              change: function ($event) {
                                return _vm.onInputChange(row.filed)
                              },
                            },
                            model: {
                              value: _vm.tableOptions[row.filed].push_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableOptions[row.filed],
                                  "push_time",
                                  $$v
                                )
                              },
                              expression: "tableOptions[row.filed].push_time",
                            },
                          }),
                          _vm._v("小时\n              "),
                        ]
                      : [_vm._v(_vm._s(row.tssj) + " ")],
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.bz) + " ")]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "微信小程序", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    [
                      "auctionStartNotice",
                      "auctionStartNoticeSyn",
                      "albumEndNotice",
                      "auctionSoonEndNotice",
                      "auctionDealNotice",
                      "auctionBidBeyond",
                      "auctionCollectResult",
                      "auctionBidBeyondSyn",
                      "identificationPay",
                      "identificationResult",
                      "orderPaymentReminder",
                      "orderDelivery",
                    ].includes(row.filed)
                      ? [
                          _vm.tableOptions[row.filed].channel
                            ? [
                                _c("el-switch", {
                                  attrs: {
                                    disabled: _vm.handOptions.wechatMini == 0,
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  on: {
                                    change: function (e) {
                                      return _vm.onChange(
                                        row,
                                        e,
                                        "wechatSubscribe"
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.tableOptions[row.filed].channel
                                        .wechatSubscribe,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tableOptions[row.filed].channel,
                                        "wechatSubscribe",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "tableOptions[row.filed].channel.wechatSubscribe",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "微信公众号", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    ![
                      "newProductOrderToAdmin",
                      "entrustNewTaskToAdmin",
                      "entrustContractSignedToAdmin",
                      "entrustAuctionLeaveWarehouseToAdmin",
                      "erpAuditCreateToAdmin",
                    ].includes(row.filed)
                      ? [
                          _vm.tableOptions[row.filed].channel
                            ? [
                                _c("el-switch", {
                                  attrs: {
                                    disabled:
                                      _vm.handOptions.wechatOfficial == 0,
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  on: {
                                    change: function (e) {
                                      return _vm.onChange(
                                        row,
                                        e,
                                        "wechatTemplate"
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.tableOptions[row.filed].channel
                                        .wechatTemplate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tableOptions[row.filed].channel,
                                        "wechatTemplate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "tableOptions[row.filed].channel.wechatTemplate",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "短信通知", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.tableOptions[row.filed].channel
                      ? [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            on: {
                              change: function (e) {
                                return _vm.onChange(row, e, "sms")
                              },
                            },
                            model: {
                              value: _vm.tableOptions[row.filed].channel.sms,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableOptions[row.filed].channel,
                                  "sms",
                                  $$v
                                )
                              },
                              expression: "tableOptions[row.filed].channel.sms",
                            },
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "抖音小程序", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    [
                      "auctionStartNotice",
                      "auctionStartNoticeSyn",
                      "albumEndNotice",
                      "auctionSoonEndNotice",
                      "auctionDealNotice",
                      "auctionBidBeyond",
                      "auctionCollectResult",
                      "auctionBidBeyondSyn",
                      "identificationPay",
                      "identificationResult",
                      "orderPaymentReminder",
                      "orderDelivery",
                    ].includes(row.filed)
                      ? [
                          _vm.tableOptions[row.filed].channel
                            ? [
                                _c("el-switch", {
                                  attrs: {
                                    disabled: _vm.handOptions.tiktok == 0,
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  on: {
                                    change: function (e) {
                                      return _vm.onChange(row, e, "tiktok")
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.tableOptions[row.filed].channel
                                        .tiktok,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tableOptions[row.filed].channel,
                                        "tiktok",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "tableOptions[row.filed].channel.tiktok",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "APP", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    ![
                      "newProductOrderToAdmin",
                      "entrustNewTaskToAdmin",
                      "entrustContractSignedToAdmin",
                      "entrustAuctionLeaveWarehouseToAdmin",
                      "erpAuditCreateToAdmin",
                    ].includes(row.filed)
                      ? [
                          _vm.tableOptions[row.filed].channel
                            ? [
                                _c("el-switch", {
                                  attrs: {
                                    disabled: _vm.handOptions.app == 0,
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  on: {
                                    change: function (e) {
                                      return _vm.onChange(row, e, "app")
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.tableOptions[row.filed].channel.app,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tableOptions[row.filed].channel,
                                        "app",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "tableOptions[row.filed].channel.app",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }