var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSelectAuction,
            width: "720px",
            "close-on-click-modal": false,
            "append-to-body": "",
            title: "选择作者",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectAuction = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200 ml10",
                  attrs: { placeholder: "名称搜索", size: "small" },
                  on: { change: _vm.searchBrandAction },
                  model: {
                    value: _vm.brandName,
                    callback: function ($$v) {
                      _vm.brandName = $$v
                    },
                    expression: "brandName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "h400 mt20",
              staticStyle: { "border-top": "1px solid #eee" },
            },
            [
              _c(
                "table",
                { staticClass: "spec-table mt20" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("td", { attrs: { width: "70%", align: "center" } }, [
                        _vm._v("品牌信息"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("是否展示"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "15%", align: "center" } }, [
                        _vm._v("操作"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.resultData, function (m, i) {
                    return _c("tbody", { key: i, staticClass: "list ft12" }, [
                      _c("tr", { staticClass: "border-t" }, [
                        _c("td", { staticClass: "bgWhite" }, [
                          _c(
                            "div",
                            { staticClass: "display-flex item-align-center" },
                            [
                              m.pic
                                ? _c("img", {
                                    staticClass: "mr10",
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                      display: "block",
                                      "flex-shrink": "0",
                                    },
                                    attrs: { src: m.pic + "!120a" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", [
                                _c("p", [
                                  _vm._v("作者：" + _vm._s(m.brand_id)),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "mt5" }, [
                                  _vm._v(_vm._s(m.brand_name)),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c("div", [
                              _vm._v(_vm._s(m.is_show ? "展示" : "不展示")),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _vm.selectedAuctionIdList.indexOf(m.brand_id) == -1
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.auctionSelectedAction(
                                            $event,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          _vm.auctionCancelAction(
                                            $event,
                                            _vm.selectedAuctionIdList.indexOf(
                                              m.brand_id
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消选择")]
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: { "current-change": _vm.changePage },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr20",
                  attrs: { type: "primary" },
                  on: { click: _vm.cursorSelect },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSelectAuction = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }