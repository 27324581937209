"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      queryParams: {
        page: 1,
        limit: 10,
        product_name: ''
      },
      updatePriceCon: false,
      priceListLoading: false,
      priceListData: {
        data: [],
        total: 0
      },
      priceQueryParams: {
        page: 1,
        limit: 5,
        wine_id: ''
      },
      priceListNew: [],
      updatePriceLoading: false
    };
  },
  computed: {
    priceData: function priceData() {
      return this.priceListNew.concat(this.priceListData.data);
    }
  },
  created: function created() {
    var query = JSON.parse(JSON.stringify(this.$route.query));
    if (query.page) {
      this.queryParams.page = Number(query.page);
      this.queryParams.limit = Number(query.limit || "20");
    }
    this.getList();
  },
  methods: {
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      this.setTypeAction();
      (0, _marketing.wineListApi)(this.queryParams).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    createdAction: function createdAction() {
      this.$router.push('./detail');
    },
    editAction: function editAction(item) {
      this.$router.push('./detail?id=' + item.id);
    },
    delAction: function delAction(id) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u4EA7\u54C1?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.wineDeleteApi)(id).then(function () {
          _this2.$message.success('删除成功');
          _this2.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showAction: function showAction(val, index) {
      var _this3 = this;
      (0, _marketing.wineShowApi)(this.tableData.data[index].id, {
        is_show: Number(val)
      }).then(function () {
        _this3.$message.success('修改状态成功');
        _this3.tableData.data[index].is_show = Number(val);
      }).catch(function () {});
    },
    editSortAction: function editSortAction(val, index) {
      var _this4 = this;
      (0, _marketing.wineSortApi)(this.tableData.data[index].id, {
        sort: val
      }).then(function () {
        _this4.$message.success('修改排序成功');
      }).catch(function () {});
    },
    showUpdatePriceCon: function showUpdatePriceCon(id) {
      this.priceQueryParams.wine_id = id;
      this.getPriceList(1);
      this.updatePriceLoading = false;
      this.priceListNew = [];
      this.updatePriceWineId = id;
      this.updatePriceCon = true;
    },
    getPriceList: function getPriceList(num) {
      var _this5 = this;
      this.priceListLoading = true;
      this.priceQueryParams.page = num || this.priceQueryParams.page;
      (0, _marketing.winePriceRecordApi)(this.priceQueryParams).then(function (res) {
        _this5.priceListData.data = res.data.list;
        _this5.priceListData.total = res.data.count;
        _this5.priceListLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.priceListLoading = false;
      });
    },
    addNewAction: function addNewAction() {
      if (this.priceListNew.length < 1) {
        this.priceListNew.push({
          isNew: true,
          wine_id: this.updatePriceWineId,
          current_price: undefined,
          unit: this.priceListData.data[0] ? this.priceListData.data[0].unit : '件',
          date: (0, _index.parseTime)(new Date(), '{y}-{m}-{d}')
        });
      }
    },
    delPriceAction: function delPriceAction(index) {
      if (this.priceListNew[index]) {
        this.priceListNew.splice(index, 1);
      }
    },
    delOldPriceAction: function delOldPriceAction(id) {
      var _this6 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u4EF7\u683C?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.winePriceRecordDeleteApi)(id).then(function () {
          _this6.$message.success('删除成功');
          _this6.getPriceList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    updatePriceAction: function updatePriceAction() {
      var _this7 = this;
      var data = [];
      this.priceListNew.forEach(function (m) {
        if (m.current_price && m.unit) {
          data.push({
            wine_id: m.wine_id,
            current_price: m.current_price,
            unit: m.unit,
            date: m.date
          });
        }
      });
      if (data.length <= 0) {
        this.$message.error('请新增价格信息');
        return;
      }
      this.updatePriceLoading = true;
      (0, _marketing.winePriceRecordCreateApi)((0, _objectSpread2.default)({}, data[0])).then(function (res) {
        _this7.$message.success('操作成功');
        _this7.getList();
        _this7.updatePriceLoading = false;
        _this7.updatePriceCon = false;
      }).catch(function () {
        _this7.updatePriceLoading = false;
      });
      // winePriceRecordCreateApi()
    }
  }
};