"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      auctionName: '',
      isAll: false
    };
  },
  watch: {
    flag: function flag(val) {
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
  },
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadAuction();
      }
      this.showSelectAuction = true;
    },
    searchAAction: function searchAAction() {
      this.queryParams.keyword = this.auctionName;
      this.queryParams.page = 1;
      this.loadAuction();
    },
    loadAuction: function loadAuction(fun) {
      var _this = this;
      var params = this.queryParams;
      (0, _auction.meetingList)(params).then(function (res) {
        _this.total = res.data.count;
        _this.resultData = res.data.list;
        _this.setAllStatus();
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var m = this.resultData[index];
      var data = {
        name: m.title,
        id: m.venue_id,
        album_count: m.album_count
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'auction',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    },
    setAllStatus: function setAllStatus() {
      var isAll = true;
      for (var i = 0; i < this.resultData.length; i += 1) {
        var m = this.resultData[i];
        var index = this.selectedAuctionIdList.indexOf(m.venue_id);
        if (index === -1) {
          isAll = false;
          break;
        }
      }
      this.isAll = isAll;
    },
    auctionSelectedAllAction: function auctionSelectedAllAction() {
      var _this2 = this;
      if (this.isAll) {
        var allData = JSON.parse(JSON.stringify(this.selectedAuction));
        this.resultData.forEach(function (m) {
          var ids = allData.map(function (m) {
            return m.id;
          });
          var i = ids.indexOf(m.venue_id);
          if (i !== -1) {
            allData.splice(i, 1);
          }
        });
        this.selectedAuction = allData;
      } else {
        var data = [];
        this.resultData.forEach(function (m) {
          var i = _this2.selectedAuctionIdList.indexOf(m.venue_id);
          if (i === -1) {
            data.push({
              name: m.title,
              id: m.venue_id,
              album_count: m.album_count
            });
          }
        });
        this.selectedAuction = this.selectedAuction.concat(data);
      }
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
    }
  }
};