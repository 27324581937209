var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "demo-input-suffix acea-row" },
              [
                _c(
                  "el-form",
                  { attrs: { inline: "", size: "small" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库名称：" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text", placeholder: "" },
                          model: {
                            value: _vm.tableFrom.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "name", $$v)
                            },
                            expression: "tableFrom.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库编号：" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text", placeholder: "" },
                          model: {
                            value: _vm.tableFrom.warehouse_no,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "warehouse_no", $$v)
                            },
                            expression: "tableFrom.warehouse_no",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库状态：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                return _vm.getList(1)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "status", $$v)
                              },
                              expression: "tableFrom.status",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "启用", value: "1" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "禁用", value: "0" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号：" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text", placeholder: "" },
                          model: {
                            value: _vm.tableFrom.contact_phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.tableFrom, "contact_phone", $$v)
                            },
                            expression: "tableFrom.contact_phone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.search } },
            [_vm._v("筛选")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.createStore } },
            [_vm._v("创建仓库")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData.data, size: "mini" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "warehouse_id", label: "ID", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "仓库名称", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "仓库地址",
              prop: "address_detail",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "contact_name", label: "联系人", "min-width": "90" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "contact_phone",
              label: "手机号",
              "min-width": "90",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "创建时间",
              "min-width": "90",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "当前状态", "min-width": "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.status == 1 ? "启用" : "禁用") +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "shelf_count",
              label: "货架数量",
              "min-width": "70",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "product_count",
              label: "商品数",
              "min-width": "70",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "8",
            attrs: {
              label: "操作",
              "min-width": "100",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(scope.row)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "commonedit" },
                        }),
                        _vm._v("编辑"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.changeStatus(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.status == 1 ? "禁用" : "启用"))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.del(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("info-from", {
        ref: "infoFrom",
        attrs: { "is-show": _vm.isShow, ids: _vm.OffId },
        on: { subSuccess: _vm.subSuccess },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建仓库",
            visible: _vm.createFlag,
            width: "1000px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "100px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "仓库代码：", prop: "warehouse_no" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: {
                      placeholder: "请输入仓库代码（6位以内英文字母）",
                      maxlength: "6",
                    },
                    on: { input: _vm.changeno },
                    model: {
                      value: _vm.formValidate.warehouse_no,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "warehouse_no", $$v)
                      },
                      expression: "formValidate.warehouse_no",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入仓库名称" },
                    model: {
                      value: _vm.formValidate.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "name", $$v)
                      },
                      expression: "formValidate.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联系人：", prop: "contact_name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入联系人" },
                    model: {
                      value: _vm.formValidate.contact_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "contact_name", $$v)
                      },
                      expression: "formValidate.contact_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号：", prop: "contact_phone" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formValidate.contact_phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "contact_phone", $$v)
                      },
                      expression: "formValidate.contact_phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库地址" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "46%" },
                    attrs: {
                      placeholder: "请选择仓库地址",
                      options: _vm.cityList,
                      props: _vm.props,
                      "collapse-tags": "",
                      clearable: "",
                    },
                    on: { change: _vm.handleCity },
                    model: {
                      value: _vm.formValidate.city_id3,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "city_id3", $$v)
                      },
                      expression: "formValidate.city_id3",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "address_detail" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入详细地址" },
                    model: {
                      value: _vm.formValidate.address_detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "address_detail", $$v)
                      },
                      expression: "formValidate.address_detail",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formValidate.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "status", $$v)
                        },
                        expression: "formValidate.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("停用")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库类型", prop: "address_detail" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formValidate.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "type", $$v)
                        },
                        expression: "formValidate.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("常规仓"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("临时仓"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓库管理员", prop: "manage_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "46%" },
                      attrs: {
                        placeholder: "请选择员工",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.manage_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "manage_id", $$v)
                        },
                        expression: "formValidate.manage_id",
                      },
                    },
                    _vm._l(_vm.manageList, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name || "未命名",
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "仓储费", prop: "warehouse_fee" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入仓储费" },
                    model: {
                      value: _vm.formValidate.warehouse_fee,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "warehouse_fee", $$v)
                      },
                      expression: "formValidate.warehouse_fee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "搬运费", prop: "porterage_fee" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "46%" },
                    attrs: { placeholder: "请输入搬运费" },
                    model: {
                      value: _vm.formValidate.porterage_fee,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "porterage_fee", $$v)
                      },
                      expression: "formValidate.porterage_fee",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }