var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "digital-collection mt20" },
    [
      _c(
        "div",
        { staticClass: "tab flex align-items-c" },
        [
          _c(
            "el-form",
            {
              attrs: { size: "small", "label-width": "80px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单状态：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button" },
                      on: {
                        change: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v(
                          "未开始 " +
                            _vm._s(
                              "(" + _vm.orderChartType.album_status_1
                                ? _vm.orderChartType.album_status_1
                                : 0 + ")"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v(
                          "进行中 " +
                            _vm._s(
                              "(" + _vm.orderChartType.album_status_2
                                ? _vm.orderChartType.album_status_2
                                : 0 + ")"
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v(
                          "已售罄 " +
                            _vm._s(
                              "(" + _vm.orderChartType.album_status_3
                                ? _vm.orderChartType.album_status_3
                                : 0 + ")"
                            )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "w300 ml20",
                      staticStyle: { position: "relative", bottom: "-2px" },
                      attrs: { placeholder: "系列名称：" },
                      on: { change: _vm.goSearch },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.goSearch($event)
                        },
                      },
                      model: {
                        value: _vm.keyword,
                        callback: function ($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: { click: _vm.goSearch },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt10" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addAction },
            },
            [_vm._v("新增藏品系列")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.downloadAction },
            },
            [_vm._v("下载藏品导入模板")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table no-height",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "系列信息", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c("div", { staticClass: "work-img" }, [
                            _c("img", {
                              attrs: {
                                src: row.album_image + "!120a",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "user-info flex-one ml10",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c("div", { staticClass: "fWeight500" }, [
                                _vm._v("ID：" + _vm._s(row.nft_album_id)),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "fWeight500" }, [
                                _vm._v(_vm._s(row.album_name)),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "起售时间", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm.parseTime(row.start_time))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "藏品数量", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.nft_num))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发行/库存", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.issuer_num) + "/" + _vm._s(row.stock)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.album_status == 1
                          ? _c("div", { staticClass: "color-red" }, [
                              _vm._v("未起售"),
                            ])
                          : row.album_status == 2
                          ? _c("div", { staticClass: "color-lv" }, [
                              _vm._v("销售中"),
                            ])
                          : row.album_status == 3
                          ? _c("div", [_vm._v("已售罄")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.create_time))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(row.nft_album_id)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "commonedit" },
                                }),
                                _vm._v("编辑"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(row.nft_album_id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addWorkAction(row.nft_album_id)
                                  },
                                },
                              },
                              [_vm._v("添加作品")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.batchUploadAuction(
                                      row.nft_album_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("批量上传")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        row.nft_num
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showWorkAction(
                                          row.nft_album_id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "查看" + _vm._s(row.nft_num) + "件作品"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.showBatchUpload
        ? _c("batchUploadTemplate", {
            ref: "batchTemplate",
            attrs: { source: "nft_collection" },
            on: {
              uploadOk: function ($event) {
                return _vm.getList(1)
              },
              uploadClose: _vm.closeBatchAction,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }