"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _title = _interopRequireDefault(require("./title"));
var _button = _interopRequireDefault(require("./button"));
var _banner = _interopRequireDefault(require("./banner"));
var _tag = _interopRequireDefault(require("./tag"));
var _img = _interopRequireDefault(require("./img"));
var _coupon = _interopRequireDefault(require("./coupon"));
var _pageColor = _interopRequireDefault(require("./template/pageColor"));
var _pageList = _interopRequireDefault(require("./template/pageList"));
var _title2 = _interopRequireDefault(require("./template/title"));
var _footer = _interopRequireDefault(require("./template/footer"));
var _img2 = _interopRequireDefault(require("./template/img"));
var _tab = _interopRequireDefault(require("./template/tab"));
var _button2 = _interopRequireDefault(require("./template/button"));
var _tag2 = _interopRequireDefault(require("./template/tag"));
var _coupon2 = _interopRequireDefault(require("./template/coupon"));
var _default = exports.default = {
  title: _title.default,
  button: _button.default,
  banner: _banner.default,
  tag: _tag.default,
  img: _img.default,
  coupon: _coupon.default,
  templatePage: _pageColor.default,
  templateList: _pageList.default,
  templateTitle: _title2.default,
  templateFooter: _footer.default,
  templateImg: _img2.default,
  templateTab: _tab.default,
  templateButton: _button2.default,
  templateTag: _tag2.default,
  templateCoupon: _coupon2.default
};