var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scBox" },
    [
      _c(
        "div",
        {
          staticClass: "flex justify-b",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "el-radio-group",
            {
              attrs: { type: "button", disabled: _vm.listLoading },
              on: { change: _vm.changeStatusAction },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "" } }, [
                _vm._v(
                  "全部 " +
                    _vm._s(
                      "(" + _vm.orderChartType.all
                        ? _vm.orderChartType.all
                        : 0 + ")"
                    )
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "8" } }, [
                _vm._v(
                  "待确认 " +
                    _vm._s(
                      "(" + _vm.orderChartType.waitConfirm
                        ? _vm.orderChartType.waitConfirm
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v(
                  "待付款 " +
                    _vm._s(
                      "(" + _vm.orderChartType.unpaid
                        ? _vm.orderChartType.unpaid
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v(
                  "待发货 " +
                    _vm._s(
                      "(" + _vm.orderChartType.unshipped
                        ? _vm.orderChartType.unshipped
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "3" } }, [
                _vm._v(
                  "待收货 " +
                    _vm._s(
                      "(" + _vm.orderChartType.untake
                        ? _vm.orderChartType.untake
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "4" } }, [
                _vm._v(
                  "待评价 " +
                    _vm._s(
                      "(" + _vm.orderChartType.unevaluate
                        ? _vm.orderChartType.unevaluate
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "5" } }, [
                _vm._v(
                  "已完成 " +
                    _vm._s(
                      "(" + _vm.orderChartType.complete
                        ? _vm.orderChartType.complete
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "9" } }, [
                _vm._v(
                  "已举回 " +
                    _vm._s(
                      "(" + _vm.orderChartType.juhui
                        ? _vm.orderChartType.juhui
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "6" } }, [
                _vm._v(
                  "已退款 " +
                    _vm._s(
                      "(" + _vm.orderChartType.refund
                        ? _vm.orderChartType.refund
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "7" } }, [
                _vm._v(
                  "已取消 " +
                    _vm._s(
                      "(" + _vm.orderChartType.cancel
                        ? _vm.orderChartType.cancel
                        : 0 + ")"
                    ) +
                    "\n       "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 224px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "scTableRef",
              staticClass: "order-table table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                height: "100%",
                size: "mini",
                "highlight-current-row": "",
                "cell-class-name": _vm.addTdClass,
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "sort-change": _vm.changeSortAction,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "30" },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单编号",
                  "min-width": "200",
                  prop: "",
                  "search-key": "order_sn",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { display: "block" },
                          domProps: { textContent: _vm._s(scope.row.order_sn) },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.is_del > 0,
                                expression: "scope.row.is_del > 0",
                              },
                            ],
                            staticStyle: { color: "#ED4014", display: "block" },
                          },
                          [_vm._v("用户已删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单类型",
                  "min-width": "100",
                  prop: "",
                  "search-key": "order_type",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: [
                    { label: "全部", value: "10" },
                    { label: "商品订单", value: "1" },
                    { label: "数字藏品", value: "3" },
                    { label: "数字盲盒订单", value: "4" },
                    { label: "艺术盲盒订单", value: "5" },
                    { label: "数字藏品寄售", value: "6" },
                    { label: "数字盲盒寄售", value: "7" },
                  ],
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_type == 1
                          ? _c("span", [_vm._v("商品订单")])
                          : scope.row.order_type == 2
                          ? _c("span", [_vm._v("拍卖订单")])
                          : scope.row.order_type == 3
                          ? _c("span", [_vm._v("数字藏品")])
                          : scope.row.order_type == 4
                          ? _c("span", [_vm._v("数字盲盒订单")])
                          : scope.row.order_type == 5
                          ? _c("span", [_vm._v("艺术盲盒订单")])
                          : scope.row.order_type == 6
                          ? _c("span", [_vm._v("寄售订单")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "活动类型",
                  "min-width": "100",
                  prop: "",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableFrom.order_type == 1 ||
                        _vm.tableFrom.order_type == 3 ||
                        _vm.tableFrom.order_type == 4
                          ? [
                              scope.row.activity_type == "airdrop"
                                ? _c("span", [_vm._v("空投")])
                                : scope.row.activity_type == "fission"
                                ? _c("span", [_vm._v("粉丝裂变")])
                                : scope.row.activity_type == "helpInvite"
                                ? _c("span", [_vm._v("助力活动")])
                                : scope.row.activity_type ==
                                  "blind_box_product_receive"
                                ? _c("span", [_vm._v("实物盲盒")])
                                : scope.row.activity_type != 4
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.activity_type === 1
                                          ? "秒杀"
                                          : scope.row.activity_type === 2
                                          ? "预售"
                                          : scope.row.activity_type === 3
                                          ? "助力"
                                          : "--"
                                      )
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v("拼团订单\n                 "),
                                    scope.row.groupUser &&
                                    scope.row.groupUser.groupBuying
                                      ? _c("span", [
                                          _vm._v(
                                            "-" +
                                              _vm._s(
                                                _vm._f("activityOrderStatus")(
                                                  scope.row.groupUser
                                                    .groupBuying.status
                                                )
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "活动信息",
                  "min-width": "300",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableFrom.order_type == 1 ||
                        _vm.tableFrom.order_type == 3 ||
                        _vm.tableFrom.order_type == 4 ||
                        _vm.tableFrom.order_type == 5
                          ? [
                              scope.row.activityInfo &&
                              scope.row.activity_type ==
                                "blind_box_product_receive"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "tabBox acea-row row-middle",
                                      staticStyle: { display: "flex" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "demo-image__preview" },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              src:
                                                scope.row.activityInfo
                                                  .orderProduct[0].cart_info
                                                  .product.image + "!120a",
                                              "preview-src-list": [
                                                scope.row.activityInfo
                                                  .orderProduct[0].cart_info
                                                  .product.image,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            flex: "1",
                                            "margin-left": "8px",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "盲盒订单编号：" +
                                                _vm._s(
                                                  scope.row.activityInfo
                                                    .order_sn
                                                )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                     盲盒名称：" +
                                                _vm._s(
                                                  scope.row.activityInfo
                                                    .orderProduct[0].cart_info
                                                    .product.store_name
                                                ) +
                                                "\n                   "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "盲盒价格：" +
                                                _vm._s(
                                                  scope.row.activityInfo
                                                    .pay_price
                                                )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "支付时间：" +
                                                _vm._s(
                                                  scope.row.activityInfo
                                                    .pay_time
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "客户",
                  "min-width": "160",
                  "search-key": "real_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(scope.row.uid)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.user.real_name ||
                                      scope.row.user.nickname
                                  )
                                ),
                                scope.row.user.real_name
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(scope.row.user.nickname) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "手机号",
                  "min-width": "140",
                  "search-key": "phone",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user
                          ? _c("div", [_vm._v(_vm._s(scope.row.user.phone))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "收货地址",
                  width: "200",
                  "show-overflow-tooltip": "",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableFrom.order_type == 1 ||
                        _vm.tableFrom.order_type == 2 ||
                        _vm.tableFrom.order_type == 5
                          ? [
                              scope.row.delivery_insured_price > 0
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#ae0000" } },
                                    [
                                      _vm._v(
                                        "\n               保价金额：" +
                                          _vm._s(
                                            scope.row.delivery_insured_price
                                          ) +
                                          "\n             "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(scope.row.real_name) +
                                    " " +
                                    _vm._s(scope.row.user_phone)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(scope.row.user_address)),
                              ]),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "货品信息",
                  "min-width": "330",
                  prop: "",
                  "search-key": "keywords",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.orderProduct, function (val, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "tabBox acea-row row-middle" },
                          [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: val.cart_info.product.image + "!120a",
                                    "preview-src-list": [
                                      val.cart_info.product.image,
                                    ],
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "tabBox_tit" }, [
                              _vm._v(
                                _vm._s(
                                  val.cart_info.product.lot
                                    ? "LOT" + val.cart_info.product.lot + " "
                                    : ""
                                ) +
                                  _vm._s(val.cart_info.product.store_name) +
                                  _vm._s(
                                    val.cart_info.productAttr.sku
                                      ? " | " + val.cart_info.productAttr.sku
                                      : ""
                                  )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "tabBox_pice" },
                              [
                                scope.row.activity_type === 2 &&
                                val.cart_info.productPresellAttr
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "￥" +
                                            val.cart_info.productPresellAttr
                                              .presell_price +
                                            " x " +
                                            val.product_num
                                        )
                                      ),
                                    ])
                                  : scope.row.activity_type === 3 &&
                                    val.cart_info.productAssistAttr
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "￥" +
                                            val.cart_info.productAssistAttr
                                              .assist_price +
                                            " x " +
                                            val.product_num
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "￥" +
                                            (val.cart_info.productAttr
                                              .origin_price ||
                                              val.cart_info.productAttr.price) +
                                            " x " +
                                            val.product_num
                                        )
                                      ),
                                    ]),
                                _vm._v(" "),
                                _vm.tableFrom.order_type == 5
                                  ? [
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "已开：" +
                                            _vm._s(scope.row.openBlinxBoxCount)
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "实际支付",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pay_price
                          ? _c("span", [_vm._v(_vm._s(scope.row.pay_price))])
                          : _c("span", [_vm._v("免费领取")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "平台服务费",
                  "min-width": "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableFrom.order_type != 5
                          ? [
                              scope.row.consignInfo
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.consignInfo.fee)),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "支付状态",
                  "min-width": "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.paid === 0 ? "未支付" : "已支付")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "订单状态",
                  width: "100",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == -2
                          ? [_c("div", [_vm._v("已取消")])]
                          : scope.row.status == -1
                          ? [_c("div", [_vm._v("已退款")])]
                          : scope.row.is_refund !== 0
                          ? [
                              _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v("售后中"),
                              ]),
                            ]
                          : scope.row.status == -3 || scope.row.status == 0
                          ? [
                              _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v("待结算"),
                              ]),
                            ]
                          : scope.row.status == -4
                          ? [
                              _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v("转账待审核"),
                              ]),
                            ]
                          : scope.row.delivery_type == 2 &&
                            scope.row.status == 1
                          ? [_c("div", [_vm._v("待自提")])]
                          : scope.row.delivery_type == 3
                          ? [_c("div", [_vm._v("待他人提货")])]
                          : scope.row.status == 1
                          ? [_c("div", [_vm._v("待发货")])]
                          : scope.row.status == 2
                          ? [_c("div", [_vm._v("待收货")])]
                          : scope.row.status == 3
                          ? [_c("div", [_vm._v("待评价")])]
                          : scope.row.status == 4
                          ? [_c("div", [_vm._v("已完成")])]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "下单时间",
                  "min-width": "160",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(scope.row.create_time) +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "支付时间",
                  "min-width": "160",
                  prop: "",
                  sortable: "custom",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(scope.row.pay_time) +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "用户备注",
                  "min-width": "160",
                  prop: "",
                  sortable: "custom",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n           " +
                            _vm._s(scope.row.mark) +
                            "\n         "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            scope.row.order_type === 3 &&
                            scope.row.paid === 1 &&
                            !scope.row.is_refund
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.refundAction(
                                          scope.row.order_id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "order-return",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("退款\n             "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderFilter(scope.row)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onRefundDetail(
                                          scope.row.order_sn
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commonlook",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("查看退款单\n             "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.paid === 0 && scope.row.is_del === 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(scope.row.order_id)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commonedit",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("编辑\n             "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status === 1 &&
                            scope.row.delivery_type == 2
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.send(scope.row.order_id)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "common-shenhe",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("确认自提\n             "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status === 1 &&
                            scope.row.delivery_type == 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.send(scope.row.order_id)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "order-send",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("发货\n             "),
                                  ],
                                  1
                                )
                              : scope.row.status == 2 &&
                                scope.row.delivery_type == 1
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ft13 mr5",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editDeliveryAction(
                                            scope.row.order_id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "icon-class": "order-send",
                                          "class-name": "blue",
                                        },
                                      }),
                                      _vm._v("修改发货\n               "),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.order_type == 0 &&
                            scope.row.status === 0 &&
                            scope.row.paid === 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copy(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("复制收件人信息\n             ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status > 2 ||
                            scope.row.is_del === 1 ||
                            scope.row.delivery_type != 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onOrderDetails(
                                          scope.row.order_id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "order-jilu",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("订单详情\n             "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.order_type == 1 &&
                            scope.row.paid == 1 &&
                            scope.row.status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "mr10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCancellation(
                                          scope.row.verify_code
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "order-jiesuan",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("去核销\n             "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.paid === 1 && scope.row.order_type === 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.printOrder(
                                          scope.row.order_id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commonprint",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("订单打印"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.status < 3 && scope.row.is_del === 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onOrderDetails(
                                          scope.row.order_id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "order-jilu",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("订单详情"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onOrderLog(scope.row.order_id)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "order-jilu",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("订单记录"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onOrderMark(scope.row.order_id)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "order-remark",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("订单备注"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.is_del !== 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleDelete(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commondelete",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("删除订单"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.deliveryAllAction },
                },
                [_vm._v("批量发货")]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("打印")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        { staticStyle: { "margin-bottom": "10px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.printAllAction("pay")
                                },
                              },
                            },
                            [_vm._v("打印结算单")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.printAllAction("send")
                                },
                              },
                            },
                            [_vm._v("打印提货单")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("cards-data", { attrs: { "card-lists": _vm.cardLists } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "order_id",
                  align: "center",
                  label: "订单ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_message",
                  label: "操作记录",
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_time",
                  label: "操作时间",
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改订单",
            visible: _vm.editVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              attrs: { model: _vm.formValidate, "label-width": "120px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单总价：" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "请输入订单总价" },
                    on: { change: _vm.changePrice },
                    model: {
                      value: _vm.formValidate.total_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "total_price", $$v)
                      },
                      expression: "formValidate.total_price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "实际支付邮费：" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, placeholder: "请输入订单油费" },
                    on: { change: _vm.changePrice },
                    model: {
                      value: _vm.formValidate.pay_postage,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "pay_postage", $$v)
                      },
                      expression: "formValidate.pay_postage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "优惠金额" } }, [
                _c("span", [_vm._v(_vm._s(_vm.formValidate.coupon_price))]),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "实际支付金额：" } }, [
                _c("span", [_vm._v(_vm._s(_vm.formValidate.pay_price))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editConfirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("details-from", { ref: "orderDetail" }),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("SettlementPrint", { ref: "printView" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }