var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "open3d-propleft-textarea-box" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.textValue,
            expression: "textValue",
          },
        ],
        staticClass: "open3d-propleft-textarea",
        attrs: { cols: "30", rows: "10", placeholder: "请输入文字内容" },
        domProps: { value: _vm.textValue },
        on: {
          change: _vm.editTextAction,
          focus: function ($event) {
            return _vm.$emit("inpFocus")
          },
          blur: function ($event) {
            return _vm.$emit("inpBlur")
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.textValue = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "open3d-font-title open3d-common-title-margin" }, [
      _vm._v("字体"),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "open3d-font-title open3d-common-title-margin" }, [
      _vm._v("对齐"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "open3d-font-align-box" }, [
      _c(
        "div",
        {
          staticClass: "open3d-font-align",
          class: { active: _vm.textAlign == "left" },
          on: {
            click: function ($event) {
              return _vm.editTextAlignAction("left")
            },
          },
        },
        [
          _c("img", {
            staticClass: "open3d-font-align-img",
            attrs: {
              src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202382/image/1690963841403393663.png",
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "open3d-font-align",
          class: { active: _vm.textAlign == "center" },
          on: {
            click: function ($event) {
              return _vm.editTextAlignAction("center")
            },
          },
        },
        [
          _c("img", {
            staticClass: "open3d-font-align-img",
            attrs: {
              src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202382/image/1690963841790945478.png",
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "open3d-font-align",
          class: { active: _vm.textAlign == "right" },
          on: {
            click: function ($event) {
              return _vm.editTextAlignAction("right")
            },
          },
        },
        [
          _c("img", {
            staticClass: "open3d-font-align-img",
            attrs: {
              src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202382/image/1690963842030369632.png",
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "open3d-font-align",
          class: { active: _vm.textAlign == "justify" },
          on: {
            click: function ($event) {
              return _vm.editTextAlignAction("justify")
            },
          },
        },
        [
          _c("img", {
            staticClass: "open3d-font-align-img",
            attrs: {
              src: "https://open3d.cdn.yunzongbu.cn/merchant/000000/202382/image/169096384229541622.png",
              alt: "",
            },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "open3d-font-two-box" }, [
      _c("div", { staticClass: "open3d-font-two" }, [
        _c("div", { staticClass: "open3d-font-title" }, [_vm._v("字号")]),
        _vm._v(" "),
        _c("div", { staticClass: "open3d-font-two-input-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.textFontSize,
                expression: "textFontSize",
              },
            ],
            staticClass: "open3d-font-two-input",
            attrs: { type: "text" },
            domProps: { value: _vm.textFontSize },
            on: {
              change: function ($event) {
                return _vm.editTextInput($event, "fontSize")
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.textFontSize = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", [_vm._v("PX")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "open3d-font-two" }, [
        _c("div", { staticClass: "open3d-font-title" }, [_vm._v("行高")]),
        _vm._v(" "),
        _c("div", { staticClass: "open3d-font-two-input-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.textLineHeight,
                expression: "textLineHeight",
              },
            ],
            staticClass: "open3d-font-two-input",
            attrs: { type: "text" },
            domProps: { value: _vm.textLineHeight },
            on: {
              change: function ($event) {
                return _vm.editTextInput($event, "lineHeight")
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.textLineHeight = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", [_vm._v("PX")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "open3d-font-two" }, [
        _c("div", { staticClass: "open3d-font-title" }, [_vm._v("字间距")]),
        _vm._v(" "),
        _c("div", { staticClass: "open3d-font-two-input-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.textLetterSpacing,
                expression: "textLetterSpacing",
              },
            ],
            staticClass: "open3d-font-two-input",
            attrs: { type: "text" },
            domProps: { value: _vm.textLetterSpacing },
            on: {
              change: function ($event) {
                return _vm.editTextInput($event, "letterSpacing")
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.textLetterSpacing = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", [_vm._v("PX")]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "open3d-font-two-box" }, [
      _c("div", { staticClass: "open3d-font-two" }, [
        _c("div", { staticClass: "open3d-font-title" }, [_vm._v("宽度")]),
        _vm._v(" "),
        _c("div", { staticClass: "open3d-font-two-input-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.textWidth,
                expression: "textWidth",
              },
            ],
            staticClass: "open3d-font-two-input",
            attrs: { type: "text" },
            domProps: { value: _vm.textWidth },
            on: {
              change: function ($event) {
                return _vm.editTextInput($event, "width")
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.textWidth = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", [_vm._v("PX")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "open3d-font-two" }, [
        _c("div", { staticClass: "open3d-font-title" }, [_vm._v("首字缩进")]),
        _vm._v(" "),
        _c("div", { staticClass: "open3d-font-two-input-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.textIndent,
                expression: "textIndent",
              },
            ],
            staticClass: "open3d-font-two-input",
            attrs: { type: "text" },
            domProps: { value: _vm.textIndent },
            on: {
              change: function ($event) {
                return _vm.editTextInput($event, "textIndent")
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.textIndent = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", [_vm._v("PX")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "open3d-font-two" }, [
        _c("div", { staticClass: "open3d-font-title" }, [_vm._v("颜色")]),
        _vm._v(" "),
        _c("div", { staticClass: "open3d-font-two-input-box" }, [
          _c("div", { staticClass: "open3d-font-two-input" }, [
            _vm._v("\n          " + _vm._s(_vm.textColor) + "\n        "),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "open3d-color-box",
            style: "background: " + _vm.textColor,
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "open3d-color",
            attrs: { type: "color" },
            domProps: { value: _vm.textColor },
            on: { change: _vm.changeTextAction },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "open3d-common-title-margin" }, [
      _c("div", { staticClass: "open3d-select-box" }, [
        _c("select", { staticClass: "open3d-select", attrs: { name: "" } }, [
          _c("option", { attrs: { value: "微软雅黑" } }, [_vm._v("微软雅黑")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }