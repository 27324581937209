"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.function.name");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _choose = _interopRequireDefault(require("@/components/customPage/choose.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _selectClass = _interopRequireDefault(require("@/components/customChildren/selectClass"));
var _select = _interopRequireDefault(require("@/components/hyperlinkSelect/select.vue"));
var _panels = _interopRequireDefault(require("../../../../libs/panels.js"));
var _modules = _interopRequireDefault(require("../../modules"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    choose: _choose.default,
    colour: _colour.default,
    slider: _slider.default,
    selectView: _selectClass.default,
    hyperlinkSelect: _select.default
  },
  data: function data() {
    return {
      fullHeight: document.documentElement.clientHeight,
      showDataType: false,
      modules: _modules.default,
      panels: _panels.default,
      styleData: [{
        value: 1,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/b2b72c44fca1b113071328fcf9247bf0.png",
        active: true
      }, {
        value: 2,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/9d8a65912aea9ad305ddf8b25c0af19d.png",
        active: true
      }, {
        value: 3,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/a1ed8515c4c1e306187276cf908104ee.png",
        active: true
      }, {
        value: 4,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/f1804c0972137bb5941213a464c2b966.png",
        active: true
      }, {
        value: 5,
        src: "https://saas.cdn.yunzongbu.cn/image/20211013/23294ee4a8c84e4938455e8eff74f433.png",
        active: true
      }],
      drawer: false
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        console.log(this.$store.getters.content);
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: "content",
          value: value
        };
        this.$store.commit("finish/setAttribute", data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: "facade",
          value: value
        };
        this.$store.commit("finish/setAttribute", data);
      }
    },
    scrollHeight: function scrollHeight() {
      return {
        height: parseInt(this.fullHeight) - 70 + "px"
      };
    },
    drawerControl: function drawerControl() {
      return this.drawer && this.content.data[this.content.active].link.name;
    }
  },
  methods: {
    onChange: function onChange() {
      console.log(this);
      this.active = this.content.defaultNum - 1;
    },
    goUp: function goUp(index) {
      if (index === 0) {
        return;
      }
      this.content.data.splice(index - 1, 0, this.content.data[index]);
      this.content.data.splice(index + 1, 1);
    },
    goDown: function goDown(index) {
      if (index === this.content.data - 1) {
        return;
      }
      this.content.data.splice(index + 2, 0, this.content.data[index]);
      this.content.data.splice(index, 1);
    },
    /**
     * 删除元素
     * @return {[type]} [description]
     */
    handleDelete: function handleDelete(index) {
      this.content.data.splice(index, 1);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      this.content.data.push({
        name: "",
        title: "文字",
        link: {
          title: ""
        }
      });
    },
    handleAddCategoryitem: function handleAddCategoryitem() {
      this.$refs.selectView.changeshow();
    },
    mouseOver: function mouseOver(index) {
      this.content.active = index;
    },
    changeActive: function changeActive(index) {
      // this.content.active = index;
      this.drawer = true;
    },
    selectMessage: function selectMessage(data) {
      var _this = this;
      if (data.data && data.data.length > 0) {
        var defaultData = {};
        var name = "";
        if (data.type === "auctionClass") {
          defaultData = {
            content: {
              style: 2,
              // 风格模板选择 1 单列展示 2 双列展示  3三列模式   4列表模式  5左右滑动
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
              open_live_type: false,
              // 直播筛选  true 开启直播筛选  false 不开启直播筛选
              live_type: 0,
              // 1 只展示直播专场  2 不展示直播专场
              album_type: [1, 2, 3, 4],
              // 专场类型  1限时拍  2同步拍  3 社群拍
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              page_margin: 30,
              // 页面间距
              top_radius: 8,
              // 顶部圆角
              bottom_radius: 8,
              // 底部圆角
              title_color: "#000000",
              // 标题颜色
              sub_title_color: "#999999",
              // 副标题颜色
              price_color: "#347FF4",
              // 价格颜色
              bid_num_color: "#000000",
              // 出价次数颜色
              status_type: 1,
              // 状态样式
              img_style: 2,
              // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
              btn_bg_color: "#003D7C",
              // 按钮背景
              btn_color: "#FFFFFF",
              // 按钮文字颜色
              btn_radius: 8,
              // 按钮圆角
              top_margin: 20,
              // 内容上间距
              left_margin: 20,
              // 内容左间距
              text_margin: 20,
              // 文件撑开距离
              item_background: "#FFFFFF",
              // 拍品底色
              item_shadow: "#DEDEDE",
              // 拍品阴影颜色
              item_shadow_size: 10,
              // 拍品阴影大小
              title_size: 28,
              // 标题颜色
              title_weight: 500,
              // 标题文字类型 300 加细  400正常  500 加粗
              price_size: 28,
              // 标题价格字号
              price_weight: 500,
              // 标题价格文字类型 300 加细  400正常  500 加粗
              bid_num_size: 24,
              // 标题出价次数字号
              bid_num_weight: 400 // 标题出价次数 300 加细  400正常  500 加粗
            }
          };
          name = "auction";
        } else if (data.type === "articleClass") {
          defaultData = {
            content: {
              style: 2,
              // 风格模板选择 1 单列模式 2 双列模式 3 三列模式  4 列表模式  5 左右滑动 6 多图模式
              img_style: 2,
              // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 全部 （右侧不修改）
              system_sort: 0,
              // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              background_color: "",
              view_radius: 8,
              // 顶部圆角 多图模式
              img_radius: 8,
              // 图片圆角
              page_padding: 30,
              // 页面边距
              item_padding: 30,
              // 容器边距
              status_background: "#FFFFFF",
              status_high: 60,
              status_radius: 8,
              item_bottom_padding: 30,
              price_color: "#003D9C",
              img_width: 120,
              img_height: 120
            }
          };
          name = "word";
        } else if (data.type === "productClass") {
          defaultData = {
            content: {
              style: 1,
              // 风格模板选择 1 左右滑动 2 上下排列
              img_style: 1,
              // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
              open_live_type: false,
              // 直播筛选  true 开启直播筛选  false 不开启直播筛选
              live_type: 0,
              // 1 只展示直播专场  2 不展示直播专场
              album_type: [1, 2, 3],
              // 专场类型  1限时拍  2同步拍  3 社群拍
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              page_margin: 30,
              // 页面间距
              top_radius: 8,
              // 顶部圆角
              bottom_radius: 8,
              // 底部圆角
              title_color: "#000000",
              // 标题颜色
              sub_title_color: "#999999",
              // 副标题颜色
              price_color: "#347FF4",
              // 价格颜色
              sales_volumes_color: "#999999",
              // 销量颜色
              img_style: 2,
              // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
              top_margin: 20,
              // 内容上间距
              left_margin: 20,
              // 内容左间距
              text_margin: 20,
              // 文件撑开距离
              item_background: "#FFFFFF",
              // 拍品底色
              item_shadow: "#DEDEDE",
              // 拍品阴影颜色
              item_shadow_size: 10,
              // 拍品阴影大小
              title_size: 28,
              // 标题颜色
              title_weight: 500,
              // 标题文字类型 300 加细  400正常  500 加粗
              price_size: 28,
              // 标题价格字号
              price_weight: 500,
              // 标题价格文字类型 300 加细  400正常  500 加粗
              sales_volumes_size: 24,
              // 销量次数字号
              sales_volumes_weight: 400,
              // 销量次数 300 加细  400正常  500 加粗
              img_width: 120,
              img_height: 120,
              show_sale: true
            }
          };
          name = "product";
        } else if (data.type === "nftClass") {
          defaultData = {
            content: {
              style: 1,
              // 风格模板选择 1 单列模式 2 日历模式
              img_style: 2,
              // 显示方式 2 对其-完整图片 3 对其-截取正方形
              type: 0,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 0,
              // 系统自动抓取  1 全部 （右侧不修改）
              system_sort: 0,
              // 系统自动抓取排序 1 按热度排序  2 按最新发布排序
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              background_color: "#1E1E1E",
              page_margin: 30,
              img_radius: 8,
              status_radius: 8,
              item_margin: 30,
              status_background: "#333333",
              status_bg_image: "",
              font_weight: 400,
              title_color: "#ffffff",
              title_font_size: 32,
              title_font_weight: 500,
              stock_color: "#00eeff",
              stock_font_size: 24,
              price_font_size: 48,
              price_color: "#e6cb95",
              img_style: 2,
              status_btn_bg_color: "#000000",
              status_btn_color: "#00eeff",
              status_font_size: 24,
              btn_font_size: 22,
              btn_radius: 2,
              main_btn_bg_color: "#00eeff",
              main_btn_color: "#000000",
              sub_btn_bg_color: "#5f5f5f",
              sub_btn_color: "#ffffff",
              sub_font_size: 24,
              sub_color: "#999999",
              month_color: "#FFFFFF",
              month_font_size: 28,
              month_font_weight: 400,
              time_color: "#999999",
              time_font_size: 24,
              time_font_weight: 400,
              dian_color: "#00eeff",
              xian_color: "#333"
            }
          };
          name = "nftCollection";
        } else if (data.type === "liveClass") {
          defaultData = {
            content: {
              style: 2,
              // 风格模板选择 1 左右滑动 2 上下排列 3 列表
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 直播中+直播预告  2 直播中+直播预告+已结束
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              column_title_font_size: 32,
              // 标题文字字号
              item_margin: 30,
              // 专场间距
              item_background: "",
              // 专场背景颜色
              name_wrap: false,
              // 专场名字是否换行   不换行就是超出一行显示...
              indicator_style: 2,
              // 滑动指示器样式  1 圆点  2 横条  3 数字
              indicator_align: "center",
              // 指示器位置
              indicator_color: "#003D7C",
              // 指示器颜色
              page_margin: 30,
              // 页面间距
              title_color: "#000000",
              // 标题颜色
              sub_title_color: "#ffffff",
              // 副标题颜色
              detail_color: "#999999",
              // 详细介绍颜色
              status_bar_bg: "#003D7C",
              // 状态栏背景色
              status_bar_color: "white",
              // 状态栏文字颜色   white  black 只有这俩
              status_bar_height: 60,
              // 状态栏高度
              item_shadow: "#DEDEDE",
              // 专场阴影颜色
              item_shadow_size: 10,
              // 专场阴影大小
              top_radius: 8,
              // 专场上圆角
              bottom_radius: 8,
              // 转下下圆角
              img_style: 2,
              text_margin: 24,
              img_width: 240,
              img_height: 240,
              line_num: "line3",
              title_size: 30,
              title_weight: 500
            }
          };
          name = "live";
        } else if (data.type === "brandClass") {
          defaultData = {
            content: {
              style: 1,
              // 风格模板选择 1 单列 2 双列 3 列表
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_sort: 1,
              // 系统排序  1 热度  2 最新
              system_count: 20,
              // 系统自动抓取数量
              img_style: 3,
              data: []
            },
            facade: {
              item_background: "",
              // 背景颜色
              page_padding: 30,
              // 页面间距
              item_padding: 30,
              // 间距
              item_bottom_padding: 30,
              // 内容下间距
              content_background: "",
              // 内容背景色
              title_color: "#000000",
              // 标题颜色
              title_fontsize: 28,
              // 标题颜色
              title_weight: 500,
              sub_title_color: "#999",
              // 副标题颜色
              sub_title_fontsize: 24,
              // 副标题颜色
              sub_title_weight: 400,
              // 副标题颜色
              item_shadow: "#DEDEDE",
              // 阴影颜色
              item_shadow_size: 10,
              // 阴影大小
              img_radius: 8,
              // 上圆角
              status_radius: 8,
              // 下圆角
              img_width: 240,
              img_height: 240,
              sub_title_padding: 20
            }
          };
          name = "brand";
        } else if (data.type === "worksClass") {
          defaultData = {
            content: {
              style: 2,
              // 风格模板选择 1 单列展示 2 双列展示  3三列模式   4列表模式  5左右滑动
              type: 1,
              // 按类型去数据  1 系统自动 2 手动选择
              system_type: 1,
              // 系统自动抓取  1 预展+进行+结束  2 预展+进行  3 预展 4 进行 5结束
              open_live_type: false,
              // 直播筛选  true 开启直播筛选  false 不开启直播筛选
              live_type: 0,
              // 1 只展示直播专场  2 不展示直播专场
              album_type: [1, 2, 3, 4],
              // 专场类型  1限时拍  2同步拍  3 社群拍
              system_count: 20,
              // 系统自动抓取数量
              data: []
            },
            facade: {
              page_margin: 30,
              // 页面间距
              top_radius: 8,
              // 顶部圆角
              bottom_radius: 8,
              // 底部圆角
              title_color: "#000000",
              // 标题颜色
              sub_title_color: "#999999",
              // 副标题颜色
              price_color: "#347FF4",
              // 价格颜色
              bid_num_color: "#000000",
              // 出价次数颜色
              status_type: 1,
              // 状态样式
              img_style: 2,
              // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
              btn_bg_color: "#003D7C",
              // 按钮背景
              btn_color: "#FFFFFF",
              // 按钮文字颜色
              btn_radius: 8,
              // 按钮圆角
              top_margin: 20,
              // 内容上间距
              left_margin: 20,
              // 内容左间距
              text_margin: 20,
              // 文件撑开距离
              item_background: "#FFFFFF",
              // 拍品底色
              item_shadow: "#DEDEDE",
              // 拍品阴影颜色
              item_shadow_size: 10,
              // 拍品阴影大小
              title_size: 28,
              // 标题颜色
              title_weight: 500,
              // 标题文字类型 300 加细  400正常  500 加粗
              price_size: 28,
              // 标题价格字号
              price_weight: 500,
              // 标题价格文字类型 300 加细  400正常  500 加粗
              bid_num_size: 24,
              // 标题出价次数字号
              bid_num_weight: 400 // 标题出价次数 300 加细  400正常  500 加粗
            }
          };
          name = "works";
        }
        data.data.forEach(function (m) {
          _this.content.data.push({
            title: m.cate_name,
            link: {
              name: name,
              dataType: "category",
              categoryType: data.type,
              categoryTypeTxt: data.typeTxt,
              category_id: m.store_category_id,
              content: JSON.parse(JSON.stringify(defaultData.content)),
              facade: JSON.parse(JSON.stringify(defaultData.facade))
            }
          });
        });
      }
    },
    setData: function setData(index) {
      this.content.active = index;
      this.showDataType = true;
    },
    changeDataType: function changeDataType(item) {
      this.content.data[this.content.active].link = {
        name: item.name,
        dataType: item.name,
        icon: item.icon,
        title: item.title,
        content: JSON.parse(JSON.stringify(item.content)),
        facade: JSON.parse(JSON.stringify(item.facade))
      };
      this.showDataType = false;
      this.drawer = true;
    },
    uploadIconAction: function uploadIconAction(index, key) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.content.data[index][key] = img[0];
      }, 1);
    },
    delImg: function delImg(index, key) {
      this.content.data[index][key] = '';
    }
  }
};