"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      list: null,
      listLoading: true,
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      list1: null,
      listLoading1: true,
      queryParams1: {
        page: 1,
        limit: 10
      },
      total1: 0,
      accountInfo: null
    };
  },
  mounted: function mounted() {
    this.loadAccount();
    this.load();
    this.load1();
  },
  methods: {
    loadAccount: function loadAccount() {
      var _this = this;
      (0, _accounts.nftAccountInfoApi)().then(function (res) {
        _this.accountInfo = res.data;
      }).catch(function (message) {});
    },
    load: function load() {
      var _this2 = this;
      this.listLoading = true;
      (0, _accounts.accountHistoryListApi)(this.queryParams).then(function (response) {
        _this2.total = response.data.count;
        _this2.list = response.data.list;
        _this2.listLoading = false;
      }).catch(function (message) {
        _this2.listLoading = false;
        _this2.list = [];
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    load1: function load1() {
      var _this3 = this;
      this.listLoading = true;
      (0, _accounts.getNftRechargeOrderApi)(this.queryParams).then(function (response) {
        _this3.total1 = response.data.count;
        _this3.list1 = response.data.list;
        _this3.listLoading1 = false;
      }).catch(function (message) {
        _this3.listLoading1 = false;
        _this3.list1 = [];
      });
    },
    pageChange1: function pageChange1(page) {
      this.queryParams1.page = page;
      this.load1();
    },
    goRechargeAction: function goRechargeAction() {
      this.$router.push('./recharge');
    }
  }
};