import { render, staticRenderFns } from "./auctionAlbum.vue?vue&type=template&id=74a5e9ab&scoped=true"
import script from "./auctionAlbum.vue?vue&type=script&lang=js"
export * from "./auctionAlbum.vue?vue&type=script&lang=js"
import style0 from "./auctionAlbum.vue?vue&type=style&index=0&id=74a5e9ab&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a5e9ab",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74a5e9ab')) {
      api.createRecord('74a5e9ab', component.options)
    } else {
      api.reload('74a5e9ab', component.options)
    }
    module.hot.accept("./auctionAlbum.vue?vue&type=template&id=74a5e9ab&scoped=true", function () {
      api.rerender('74a5e9ab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/customChildren/auctionAlbum.vue"
export default component.exports