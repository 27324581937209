var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "搜索框" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black" }, [
              _vm._v("风格模板选择"),
            ]),
          ]),
          _vm._v(" "),
          _c("choose", {
            attrs: {
              data: _vm.styleData,
              width: "100%",
              height: "auto",
              margin: "13px 0 0 0",
            },
            on: { changeType: _vm.changeType },
            model: {
              value: _vm.content.style,
              callback: function ($$v) {
                _vm.$set(_vm.content, "style", $$v)
              },
              expression: "content.style",
            },
          }),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "80px",
                size: "small",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("搜索栏内容和跳转链接"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("搜索框文字"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c("el-input", {
                      attrs: { type: "text", placeholder: "设置文字" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return (function () {})($event)
                        },
                      },
                      model: {
                        value: _vm.content.text,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "text", $$v)
                        },
                        expression: "content.text",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.content.style === 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("前图标")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "upload-img-view item-flex-center",
                        on: {
                          click: function ($event) {
                            return _vm.uploadIconAction(2)
                          },
                        },
                      },
                      [
                        _vm.content.front_icon
                          ? _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg(2)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.content.front_icon
                          ? _c("img", {
                              attrs: { src: _vm.content.front_icon + "!120a" },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.content.front_link.name1 || _vm.content.front_link.name
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "item-align-center flex-one justify-end ml10 cur-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.setCategory("front_link")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "ft14" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.content.front_link.name1 ||
                                    _vm.content.front_link.name
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "arrow-img",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.setCategory("front_link")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "ft14" }, [
                              _vm._v("设置跳转链接"),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "arrow-img",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("后图标")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "upload-img-view item-flex-center",
                        on: {
                          click: function ($event) {
                            return _vm.uploadIconAction(3)
                          },
                        },
                      },
                      [
                        _vm.content.after_icon
                          ? _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg(3)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.content.after_icon
                          ? _c("img", {
                              attrs: { src: _vm.content.after_icon + "!120a" },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.content.after_link.name1 || _vm.content.after_link.name
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "item-align-center flex-one justify-end ml10 cur-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.setCategory("after_link")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "ft14" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.content.after_link.name1 ||
                                    _vm.content.after_link.name
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "arrow-img",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.setCategory("after_link")
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "ft14" }, [
                              _vm._v("设置跳转链接"),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "arrow-img",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 3
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("文字链设置"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 3
                ? _c(
                    "div",
                    { staticClass: "pt10" },
                    _vm._l(_vm.content.data, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kuang1 item-align-center mt10",
                        },
                        [
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-down-img",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDown(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-up-img",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUp(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del-img",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "input-view ml4" },
                            [
                              _c("el-input", {
                                staticStyle: { width: "140px" },
                                attrs: {
                                  type: "text",
                                  placeholder: "设置文字",
                                  maxlength: "10",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          item.link.name1 || item.link.name
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center flex-one justify-end ml10 cur-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCategory(index)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "ft14" }, [
                                    _vm._v(
                                      _vm._s(item.link.name1 || item.link.name)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "arrow-img",
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211018/af608f8a4c004a9383cba8f25f5b1eba.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center flex-one justify-end color-link ml10 cur-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCategory(index)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "ft14" }, [
                                    _vm._v("设置跳转链接"),
                                  ]),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "arrow-img",
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/c5f979532bd97fda46f9274bd90ebae2.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 3
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("img", {
                      staticStyle: {
                        width: "12px",
                        height: "12px",
                        "margin-right": "5px",
                        "margin-top": "2px",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fontW500 ft16",
                        on: { click: _vm.handleAdditem },
                      },
                      [_vm._v("新增一条文字链")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("风格参数设置"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.page_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_padding", $$v)
                      },
                      expression: "facade.page_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("搜索框参数设置"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "搜索框长度" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 750 },
                    model: {
                      value: _vm.facade.width_style,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "width_style", $$v)
                      },
                      expression: "facade.width_style",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "搜索框高度" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 200 },
                    model: {
                      value: _vm.facade.high_style,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "high_style", $$v)
                      },
                      expression: "facade.high_style",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "搜索框圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.border_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_radius", $$v)
                      },
                      expression: "facade.border_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "搜索框颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.border_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_color", $$v)
                      },
                      expression: "facade.border_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.text_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_color", $$v)
                      },
                      expression: "facade.text_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.text_style,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "text_style", $$v)
                        },
                        expression: "facade.text_style",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 500 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "文字字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 60 },
                    model: {
                      value: _vm.facade.text_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "text_size", $$v)
                      },
                      expression: "facade.text_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图标颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.icon_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "icon_color", $$v)
                      },
                      expression: "facade.icon_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图标位置" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.icon_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "icon_align", $$v)
                        },
                        expression: "facade.icon_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("居左"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图标大小" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.icon_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "icon_size", $$v)
                      },
                      expression: "facade.icon_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.style === 3 ? _c("el-divider") : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 3
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("文字链参数设置"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 3
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#000000" },
                        model: {
                          value: _vm.facade.text_link_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "text_link_color", $$v)
                          },
                          expression: "facade.text_link_color",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 3
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字风格" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.text_link_style,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "text_link_style", $$v)
                            },
                            expression: "facade.text_link_style",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("常规"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style === 3
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字字号" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 60 },
                        model: {
                          value: _vm.facade.text_link_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "text_link_size", $$v)
                          },
                          expression: "facade.text_link_size",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }