"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _basic = require("@/api/basic");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
var _warehouse = require("@/api/warehouse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "sellcreate",
  data: function data() {
    return {
      userList: [],
      searchLoading: false,
      loading: false,
      rules: {
        uid: [{
          required: true,
          message: "请选择客户",
          trigger: "change"
        }],
        sale_admin_id: [{
          required: true,
          message: "请选择销售人员",
          trigger: "change"
        }],
        audit_admin_id: [{
          required: true,
          message: "请选择审核人",
          trigger: "change"
        }]
      },
      formValidate: {
        transport_type: "",
        order_no: ""
      },
      timeVal: [],
      goodsData: [],
      selectFlag: false,
      tableFrom: {
        page: 1,
        limit: 20,
        type: "1",
        cate_id: "",
        store_name: "",
        mer_id: "",
        keyword: "",
        product_id: ""
      },
      tableData: {
        data: [],
        total: 0
      },
      merSelect: [],
      editFlag: false,
      num: 0,
      id: this.$route.query.id || "",
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      },
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: "",
      letterArr: [],
      storeArr: [],
      adminarr: [],
      goodsArr: [],
      goodsIdArr: [],
      orderno: '',
      mannagename: ''
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    // if (this.id) {
    //   this.getGoodsList();
    // }
    // this.getDetail();

    this.getletter();
    this.getadminList();
    this.searchUserAction();
    this.getList();
    (0, _warehouse.getOrderNo)().then(function (res) {
      _this2.orderno = res.data.order_no;
      _this2.formValidate.order_no = res.data.order_no;
    });
  },
  methods: {
    close: function close() {
      this.$router.back();
    },
    searchUserAction: function searchUserAction(data) {
      var _this3 = this;
      var params = {
        nickname: data,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this3.userList = res.data.list;
      });
    },
    getadminList: function getadminList() {
      var _this4 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this4.adminarr = res.data.list;
      });
    },
    getletter: function getletter() {
      var _this5 = this;
      (0, _basic.letterList)().then(function (res) {
        console.log(res);
        _this5.letterArr = res.data.list;
      });
    },
    submitForm: function submitForm(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!_this6.goodsArr.length) {
            return _this6.$message.error('请选择商品');
          }
          _this6.loading = true;
          var goodsarr = JSON.parse(JSON.stringify(_this6.goodsArr));
          goodsarr.forEach(function (item) {
            item.price_data = JSON.stringify(item.price_data);
          });
          _this6.formValidate.product_data = goodsarr;
          (0, _basic.createsellOrder)(_this6.formValidate).then(function (res) {
            _this6.loading = false;
            if (res.status == 200) {
              _this6.$message.success("保存成功");
              _this6.$router.back();
            }
            console.log(res);
          }).catch(function (res) {
            _this6.loading = false;
            // this.$message.error(res.message);
          });
        } else {
          return false;
        }
      });
    },
    showImage: function showImage(item) {
      this.dialogVisible = true;
      this.dialogImageUrl = item;
    },
    del: function del(index) {
      this.formValidate.bannerArr[index].img = "";
    },
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      //   this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.formValidate.stime = e[0];
      this.formValidate.etime = e[1];
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.formValidate.bannerArr[tit].img = img[0];
      }, tit);
    },
    getList: function getList() {
      var _this7 = this;
      this.tableFrom.activity_id = this.id;
      (0, _basic.goodsList)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.selected = false;
        });
        _this7.tableData.data = res.data.list;
        _this7.tableData.total = res.data.count;
        _this7.listLoading = false;
      }).catch(function (res) {
        _this7.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    // 商户列表；
    getMerSelect: function getMerSelect() {
      var _this8 = this;
      merSelectApi().then(function (res) {
        _this8.merSelect = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    selectActiveGoods: function selectActiveGoods() {
      this.selectFlag = true;
      this.getList();
    },
    saleGoods: function saleGoods(row, index) {
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      var newrow = JSON.parse(JSON.stringify(row));
      // newrow.success = JSON.parse(
      //   JSON.stringify(this.inventoryConfig.price.sold_price)
      // );
      // newrow.fail = JSON.parse(
      //   JSON.stringify(this.inventoryConfig.price.unsold_price)
      // );
      // newrow.other = JSON.parse(
      //   JSON.stringify(this.inventoryConfig.price.other_price)
      // );
      // newrow.price_data = [newrow.success, newrow.fail, newrow.other];
      // newrow.retain_price = "";
      newrow.sale_number = '';
      newrow.product_price = '';
      newrow.remark = '';
      this.goodsArr.push(newrow);
      this.goodsIdArr.push(row.product_id);
    },
    closeSelect: function closeSelect() {
      this.selectFlag = false;
    },
    delGoods: function delGoods(index, row) {
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.goodsIdArr.indexOf(row.product_id);
      this.goodsIdArr.splice(nindex, 1);
      this.goodsArr.splice(nindex, 1);
      return;
      row.selected = false;
      this.tableData.data.splice(index, 1);
      this.tableData.data.push(row);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};