"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _basic = require("@/api/basic");
var _warehouse = require("@/api/warehouse");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _user = require("@/api/user");
var _system = require("@/api/system");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "createpushorder",
  data: function data() {
    return {
      loading: false,
      listLoading: false,
      rules: {
        warehouse_id: [{
          required: true,
          message: "请选择仓库ID",
          trigger: "change"
        }],
        manage_admin_id: [{
          required: false,
          message: "请选择业务负责人",
          trigger: "change"
        }],
        push_type: [{
          required: false,
          message: "请选择入库类型",
          trigger: "change"
        }]
      },
      formValidate: {
        warehouse_id: "",
        manage_admin_id: "",
        push_type: ""
      },
      goodsData: [],
      tableFrom: {
        page: 1,
        limit: 10,
        contract_status: 4,
        product_warehouse_status: 0
      },
      tableData: {
        data: [],
        total: 0
      },
      id: this.$route.query.id || "",
      storeArr: [],
      adminarr: [],
      BrandList: [],
      attrList: [],
      attr: [],
      lockid: "",
      locktype: "",
      shelfList: [{
        shelf_id: 0,
        shelf_no: "请选择货架"
      }
      // {
      //   shelf_id: "0",
      //   shelf_no: "请选择货架",
      // },
      ],
      orderno: "",
      mannagename: "",
      goodsIdArr: [],
      enterGoodsList: [],
      labelarr: [],
      attributeList: [],
      pushheaderList: [],
      pushHideList: [],
      searchQuery: {},
      userList: [],
      reductjson: {
        erp_v2_enter_warehouse: '0',
        is_approval_admin: 0,
        adminData: []
      },
      reviewAduitId: '',
      removeList: []
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    (0, _setting.getApprovalApi)({
      type: 'erp_v2_enter_warehouse'
    }).then(function (res) {
      console.log(res);
      _this2.reductjson = res.data;
    });
    (0, _setting.adminListApi)().then(function (res) {
      // console.log(res);
      _this2.userList = res.data.list.map(function (item) {
        return {
          label: item.real_name,
          value: item.merchant_admin_id
        };
      });
    });
    (0, _warehouse.getFieldSetAPI)({
      types: "erp_v2_enter_warehouse_create"
    }).then(function (res) {
      res.data.erp_v2_enter_warehouse_create.show_list.forEach(function (item) {
        if (/^\d*$/.test(item.field)) {
          item.newtype = "attribute";
        }
      });
      _this2.pushheaderList = res.data.erp_v2_enter_warehouse_create.show_list;
      _this2.pushHideList = res.data.erp_v2_enter_warehouse_create.hide_list;
      // console.log(this.pushheaderList);
    });
    // getOrderNo().then(res=>{
    //   this.orderno = res.data.order_no
    // })
    // this.getstore();
    // this.getadminList();
    // this.getBrandListApi();
    (0, _product.getStoreList)().then(function (r) {
      _this2.storeArr = r.data.list;
      if (_this2.$route.query.id) {
        _this2.id = _this2.$route.query.id;
        _this2.tab = "goods";
        _this2.getInfoAction();
      } else {
        (0, _warehouse.getLastWarehouseIdApi)({
          order_type: 1
        }).then(function (res) {
          console.log(res);
          _this2.formValidate.warehouse_id = res.data.warehouse_id;
        });
        // getBaseInfo().then(res => {
        //   getStoreList({ manage_admin_id: res.data.merchantAdmin.merchant_admin_id, page: 1, limit: 999999 }).then(result => {
        //     if (result.data.list.length>0) {
        //       this.formValidate.warehouse_id = result.data.list[0].warehouse_id
        //     }else{
        //       this.formValidate.warehouse_id = r.data.list[0].warehouse_id
        //     }
        //     this.pushshelf();
        //   })
        // })
      }
    });

    // .catch(({ message }) => {
    //   //
    // });
    this.getList();
    this.getlabel();
    (0, _system.gettableheader)({
      types: "store_attribute"
    }).then(function (res) {
      _this2.attributeList = res.data;
    }).catch(function (res) {
      // this.$message.error(res.message);
    });
    // this.getinvent();
  },
  methods: {
    focus: function focus(e) {
      e.currentTarget.select();
    },
    changeColumnWidth: function changeColumnWidth(newWidth, oldWidth, column) {
      // console.log(column);
      var index = column.index;
      this.pushheaderList[index].width = newWidth;
      (0, _system.setCustomHeader)({
        types: "erp_v2_enter_warehouse_create",
        show_data: this.pushheaderList,
        hide_data: this.pushHideList
      }).then(function (res) {}).catch(function (res) {});
      // this.columnWidthArr[index] = newWidth;
      // this.setColumnsWidth();
    },
    getlabel: function getlabel() {
      var _this3 = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 50
      }).then(function (res) {
        _this3.labelarr = res.data.list;
        var json = {};
        res.data.list.forEach(function (item) {
          json[item.label_id] = item.label_name;
        });
        _this3.labeljson = json;
      }).catch(function (res) {
        // this.$message.error(res.message)
      });
    },
    getInfoAction: function getInfoAction() {
      var _this4 = this;
      (0, _warehouse.getwarehouseOrderDetailApiV2)(this.id).then(function (res) {
        console.log(1, res.data);
        // this.formValidate.manage_admin_id = res.data.manage_admin_id;
        _this4.formValidate.warehouse_id = res.data.enterWarehouse.warehouse_id;
        _this4.$set(_this4.formValidate, 'remark', res.data.remark);
        _this4.$set(_this4.formValidate, 'enter_type', res.data.enter_type);
        // this.formValidate.push_type = res.data.type;
        _this4.pushshelf();
        res.data.warehouseOrderProduct.forEach(function (m) {
          m = (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, m.erpProduct), m), {}, {
            label_ids: m.erpProduct.label_ids ? m.erpProduct.label_ids.split(',').map(function (item) {
              return Number(item);
            }) : [],
            id: m.erp_product_id
          });
          // m.auction_name = m.erpProduct.auction_name;
          // m.product_name = m.erpProduct.product_name;
          // m.image = m.erpProduct.image;
          // m.product_no = m.erpProduct.product_no;
          // m.label_ids = m.erpProduct.label_ids?m.erpProduct.label_ids.split(','):[];
          // m.attribute = m.erpProduct.attribute;
          // m.id = m.erp_product_id;
          m.edit = true;
          _this4.saleGoods(m, "goods", "");
        });
      }).catch(function () {});
    },
    close: function close() {
      this.$router.back();
    },
    pushshelf: function pushshelf() {
      var _this5 = this;
      this.formValidate.push_warehouse_id = this.formValidate.warehouse_id;
      (0, _basic.shelfList)({
        warehouse_id: this.formValidate.push_warehouse_id,
        page: 1,
        limit: 200
      }).then(function (res) {
        res.data.list.unshift({
          shelf_id: 0,
          shelf_no: "请选择货架"
        });
        _this5.shelfList = res.data.list;
      });
    },
    leavetab: function leavetab() {
      if (this.locktype) {
        this.tab = this.locktype;
        return false;
      }
    },
    changetab: function changetab(e) {
      if (this.locktype && e.name != this.locktype) {
        return;
      }
      this.tab = e.name;
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this6 = this;
      brandListApi().then(function (res) {
        _this6.BrandList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getadminList: function getadminList() {
      var _this7 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this7.adminarr = res.data.list;
      });
    },
    // getstore() {
    // },
    submitForm: function submitForm(formName) {
      var _this8 = this;
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      this.loading = true;
      if (!this.enterGoodsList.length) {
        return this.$message.error("请选择货品");
      }
      var json = {};
      json.enter_id = this.formValidate.warehouse_id;
      json.enter_type = this.formValidate.enter_type;
      json.remark = this.formValidate.remark;
      json.warehouseProduct = this.enterGoodsList.map(function (item) {
        return {
          erp_product_id: item.id,
          product_no: item.product_no,
          shelf_id: item.shelf_id,
          image: item.slider_image.join(','),
          // slider_image: [item.image],
          product_name: item.product_name,
          label_ids: item.label_ids.join(","),
          attribute: item.attribute
        };
      });
      if (this.reductjson.erp_v2_enter_warehouse == 1) {
        json.audit_admin_id = this.reviewAduitId;
      }
      if (this.id) {
        (0, _warehouse.warehouseDetailUpdateApi)(this.id, json).then(function (res) {
          _this8.loading = false;
          if (res.status == 200) {
            _this8.$message.success("保存成功");
            _this8.$router.back();
          }
        }).catch(function (res) {
          _this8.loading = false;
          // this.$message.error(res.message);
        });
      } else {
        (0, _warehouse.createpushOrderApiV2)(json).then(function (res) {
          _this8.loading = false;
          if (res.status == 200) {
            _this8.$message.success("保存成功");
            _this8.$router.back();
          }
        }).catch(function (res) {
          _this8.loading = false;
          // this.$message.error(res.message);
        });
      }
      //   } else {
      //     return false;
      //   }
      // });
    },
    getDetail: function getDetail() {
      if (!this.id) {
        return;
      }
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        contract_status: 4,
        product_warehouse_status: 0,
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    onChange: function onChange() {
      this.tableFrom.page == 1;
      this.getList();
    },
    getList: function getList() {
      var _this9 = this;
      if (this.formValidate.enter_type == 'enter_warehouse_refund' || this.formValidate.enter_type == 'enter_warehouse_return') this.tableFrom.product_warehouse_status = 4;else this.tableFrom.product_warehouse_status = 0;
      (0, _basic.getgoodsListV2Api)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          if (item.label_ids) {
            item.label_ids = item.label_ids.split(",").map(function (item) {
              return Number(item);
            });
          } else {
            item.label_ids = [];
          }
        });
        // 
        if (_this9.tableFrom.page == 1 && _this9.removeList.length > 0) {
          _this9.tableData.data = [].concat((0, _toConsumableArray2.default)(_this9.removeList), (0, _toConsumableArray2.default)(res.data.list));
        } else _this9.tableData.data = res.data.list;
        _this9.tableData.total = res.data.count;
        _this9.listLoading = false;
      }).catch(function (res) {
        _this9.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    saleGoodsAll: function saleGoodsAll() {
      var _this10 = this;
      this.tableData.data.forEach(function (m) {
        _this10.saleGoods(m, "goods", "");
      });
    },
    saleGoods: function saleGoods(row, type, eid, inrow) {
      if (this.goodsIdArr.indexOf(row.id) > -1) {
        return;
      }
      // if (this.lockid && eid != this.lockid) {
      //   this.$message.error("不能选择不同委托人的货品");
      //   return;
      // }
      var newrow = JSON.parse(JSON.stringify(row));
      newrow.slider_image = row.image ? [row.image] : [];
      console.log(newrow);
      this.enterGoodsList.push(newrow);
      // console.log(this.enterGoodsList);
      this.locktype = type;
      this.lockid = eid || "";
      // if (this.lockid && !this.formValidate.manage_admin_id) {
      //   this.formValidate.manage_admin_id = this.lockid;
      // }
      // this.goodsArr.push(row);
      this.goodsIdArr.push(row.id);
    },
    delGoods: function delGoods(index, row, cindex) {
      console.log(row);
      if (row.edit) {
        this.tableData.data.unshift(row);
        this.removeList.push(row);
      }
      var nindex = this.goodsIdArr.indexOf(row.id);
      this.goodsIdArr.splice(nindex, 1);
      this.enterGoodsList.splice(index, 1);
      if (this.goodsIdArr.length <= 0) {
        this.locktype = "";
        this.lockid = "";
      }
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 点击货品图
    modalPicTap: function modalPicTap(tit, num, i, nitem) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        if (tit === "1" && !num) {
          _this.formValidate.image = img[0];
          _this.OneattrValue[0].image = img[0];
        }
        if (tit === "2" && !num) {
          console.log(img);
          img.map(function (item) {
            attr.push(item.attachment_src);
            nitem.slider_image.push(item);
            if (nitem.slider_image.length > 10) nitem.slider_image.length = 10;
          });
        }
        if (tit === "1" && num === "dan") {
          _this.OneattrValue[0].image = img[0];
        }
        if (tit === "1" && num === "duo") {
          _this.ManyAttrValue[i].image = img[0];
        }
      }, "9");
    }
  }
};