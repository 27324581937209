var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "top-menu" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo",
              attrs: { "default-active": _vm.tab, mode: "horizontal" },
              on: { select: _vm.changeTab },
            },
            [
              _c("el-menu-item", { attrs: { index: "0" } }, [
                _vm._v("竖屏模式配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "1" } }, [
                _vm._v("横屏模式配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [
                _vm._v("币种与汇率配置"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "close", on: { click: _vm.backAction } }, [
            _c("div", [_vm._v("返回")]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tab == "2"
        ? [
            _c("div", { staticClass: "default-currency" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "seeting-title mt40 ml20 ft14" }, [
                _vm._v("大屏幕显示币种"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-content mt20 ml20" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.changeAbbreviation,
                        callback: function ($$v) {
                          _vm.changeAbbreviation = $$v
                        },
                        expression: "changeAbbreviation",
                      },
                    },
                    _vm._l(_vm.abbreviationList, function (m, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "item-align-center mt10" },
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: i } },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                model: {
                                  value: m.abb,
                                  callback: function ($$v) {
                                    _vm.$set(m, "abb", $$v)
                                  },
                                  expression: "m.abb",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "ml20",
                                  staticStyle: { color: "#666" },
                                },
                                [_vm._v("兑换比率：1RMB = ")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "w100",
                            model: {
                              value: m.rate,
                              callback: function ($$v) {
                                _vm.$set(m, "rate", $$v)
                              },
                              expression: "m.rate",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v(_vm._s(m.abb)),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "seeting-title mt40 ml20 ft14" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveAction },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.backAction } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ]),
          ]
        : [
            _vm.loadEnd
              ? _c("div", { staticClass: "seeting-content flex" }, [
                  _c("div", { staticClass: "seeting-show" }, [
                    _c("div", { staticClass: "content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "main",
                          style:
                            "width: " +
                            _vm.windowSizeList[_vm.windowSize].width +
                            "px;height: " +
                            _vm.windowSizeList[_vm.windowSize].height +
                            "px;",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "top",
                              style:
                                "background: " +
                                _vm.title_style_background_color +
                                ";color: " +
                                _vm.title_style_font_color +
                                ";" +
                                (_vm.title_style_height &&
                                _vm.title_style_height != 0
                                  ? "height:" + _vm.title_style_height + "px"
                                  : "") +
                                ";box-shadow: 0px 1px 0px 0px " +
                                _vm.title_style_shadow_color +
                                ";",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "top-view",
                                  style:
                                    "" +
                                    (_vm.title_style_height &&
                                    _vm.title_style_height != 0
                                      ? "height:" +
                                        _vm.title_style_height +
                                        "px"
                                      : ""),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "top-left",
                                      style:
                                        "padding-left: " +
                                        parseInt(_vm.fix * 64) +
                                        "px",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tltop-name",
                                          style:
                                            "font-size: " +
                                            _vm.title_font_size_venue +
                                            "px;margin-top: " +
                                            parseInt(_vm.fix * 46) +
                                            "px;",
                                        },
                                        [_vm._v("北京海王村第九十九届拍卖会")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "tlbottom" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tlbl",
                                            style:
                                              "font-size: " +
                                              _vm.title_font_size_befor +
                                              "px;color: " +
                                              _vm.title_style_background_color_next +
                                              ";",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mr10" },
                                              [_vm._v("上一件")]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("LOT 1093")]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tlbc",
                                            style:
                                              "font-size: " +
                                              _vm.title_font_size_befor +
                                              "px;color: " +
                                              _vm.title_style_background_color_over +
                                              ";",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("落槌价：999,999"),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tlbr",
                                            style:
                                              "font-size: " +
                                              _vm.title_font_size_befor +
                                              "px;color: " +
                                              _vm.title_style_background_color_auction_num +
                                              ";",
                                          },
                                          [
                                            _c("img", {
                                              style:
                                                "width: " +
                                                _vm.title_font_size_befor +
                                                "px;height: " +
                                                _vm.title_font_size_befor +
                                                "px;",
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20210724/e719a91f4113853e64c5df8ebe0dc253.png",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("待拍卖30件")]),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "top-right",
                                      style:
                                        "font-size: " +
                                        _vm.title_font_size_company_name +
                                        "px;" +
                                        (_vm.title_style_height &&
                                        _vm.title_style_height != 0
                                          ? "height:" +
                                            _vm.title_style_height +
                                            "px"
                                          : ""),
                                    },
                                    [
                                      _vm.compImg || _vm.compName
                                        ? _c(
                                            "div",
                                            { staticClass: "top-right-view" },
                                            [
                                              _vm.compImg
                                                ? _c("img", {
                                                    style:
                                                      "width:" +
                                                      _vm.compWidth +
                                                      "px;height:" +
                                                      _vm.compHeight +
                                                      "px;",
                                                    attrs: {
                                                      src:
                                                        _vm.compImg + "!120a",
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.compName
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.compName)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.compImg1 || _vm.compName1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "top-right-view",
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _vm.compImg1
                                                ? _c("img", {
                                                    style:
                                                      "width:" +
                                                      _vm.compWidth1 +
                                                      "px;height:" +
                                                      _vm.compHeight1 +
                                                      "px;",
                                                    attrs: {
                                                      src:
                                                        _vm.compImg1 + "!120a",
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.compName1
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.compName1)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bottom zIndex",
                              class: _vm.tab == 0 ? "heng" : "shu",
                              style:
                                _vm.title_style_height &&
                                _vm.title_style_height != 0
                                  ? "top:" +
                                    (Number(_vm.title_style_height) + 1) +
                                    "px"
                                  : "",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "bottom-left",
                                  style:
                                    "background: " +
                                    _vm.image_background_color +
                                    ";" +
                                    (_vm.tab == 0
                                      ? "width: " + _vm.image_style_width + "%;"
                                      : "") +
                                    (_vm.tab == 1
                                      ? "height:" + _vm.image_style_height + "%"
                                      : ""),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.tab == 0,
                                          expression: "tab == 0",
                                        },
                                      ],
                                      staticClass: "bottom-left-img",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "img",
                                          style:
                                            "width:" +
                                            _vm.img_width +
                                            "px;height:" +
                                            _vm.img_height +
                                            "px;box-shadow: 0px 0px 20px 0px " +
                                            _vm.img_shadow +
                                            ";background:" +
                                            _vm.img_bg +
                                            ";",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "https://saas.cdn.yunzongbu.cn/image/20210129/5ee9bc05f909f664560fe751dac83b5f.jpg!m640",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.tab == 1,
                                          expression: "tab == 1",
                                        },
                                      ],
                                      staticClass: "bottom-left-img",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "img",
                                          style:
                                            "padding: " +
                                            parseInt(62 * _vm.fix) +
                                            "px;height: 100%;width: 100%;",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "https://saas.cdn.yunzongbu.cn/image/20210129/5ee9bc05f909f664560fe751dac83b5f.jpg!m640",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.tab == 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "bottom-right",
                                      style:
                                        "background: " +
                                        _vm.auction_style_background_color +
                                        ";",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "bottom-r-t",
                                          style:
                                            "height: " +
                                            _vm.auction_style_height +
                                            "%;background: " +
                                            _vm.auction_style_background_color +
                                            ";padding: " +
                                            parseInt(58 * _vm.fix) +
                                            "px " +
                                            parseInt(80 * _vm.fix) +
                                            "px 0 " +
                                            parseInt(91 * _vm.fix) +
                                            "px;",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "lot",
                                              style:
                                                (_vm.auction_font_size_lot &&
                                                _vm.auction_font_size_lot != 0
                                                  ? "font-size:" +
                                                    _vm.auction_font_size_lot +
                                                    "px"
                                                  : "") +
                                                ";color: " +
                                                _vm.auction_style_font_color +
                                                ";",
                                            },
                                            [_vm._v("LOT 1000")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "name",
                                              style:
                                                (_vm.auction_font_size_name &&
                                                _vm.auction_font_size_name != 0
                                                  ? "font-size:" +
                                                    _vm.auction_font_size_name +
                                                    "px"
                                                  : "") +
                                                ";color: " +
                                                _vm.auction_style_name +
                                                ";margin-top: " +
                                                parseInt(16 * _vm.fix) +
                                                "px;height: " +
                                                parseInt(134 * _vm.fix) +
                                                "px;line-height: " +
                                                parseInt(67 * _vm.fix) +
                                                "px;",
                                            },
                                            [
                                              _vm._v(
                                                "清乾隆青花苍龙教子图抱月瓶三件真品"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "desc",
                                              style:
                                                (_vm.auction_font_size_intro &&
                                                _vm.auction_font_size_intro != 0
                                                  ? "font-size:" +
                                                    _vm.auction_font_size_intro +
                                                    "px"
                                                  : "") +
                                                ";color: " +
                                                _vm.auction_style_desc +
                                                ";margin-top: " +
                                                parseInt(16 * _vm.fix) +
                                                "px;",
                                            },
                                            [_vm._v("拍品的一句话介绍")]
                                          ),
                                          _vm._v(" "),
                                          _vm.show_start_price
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "attr",
                                                  style:
                                                    (_vm.auction_font_size_intro &&
                                                    _vm.auction_font_size_price !=
                                                      0
                                                      ? "font-size:" +
                                                        _vm.auction_font_size_price +
                                                        "px"
                                                      : "") +
                                                    ";color: " +
                                                    _vm.auction_style_price +
                                                    ";margin-top: " +
                                                    parseInt(16 * _vm.fix) +
                                                    "px;",
                                                },
                                                [_vm._v("起拍价：100,000")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "bottom-r-b",
                                          style:
                                            "height: " +
                                            _vm.price_style_height +
                                            "%;background: " +
                                            _vm.price_style_background_color +
                                            ";padding-top: " +
                                            parseInt(24 * _vm.fix) +
                                            "px;margin: 0 " +
                                            parseInt(80 * _vm.fix) +
                                            "px 0 " +
                                            parseInt(91 * _vm.fix) +
                                            "px;",
                                        },
                                        _vm._l(
                                          _vm.changeAbbreviation,
                                          function (m, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: "changeAbbreviation" + i,
                                                staticClass:
                                                  "list item-flex-center",
                                                style:
                                                  "margin-top: " +
                                                  parseInt(16 * _vm.fix) +
                                                  "px;",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "l",
                                                    style:
                                                      "font-size:" +
                                                      _vm.price_font_size_currency +
                                                      "px;color: " +
                                                      _vm.price_style_font_color_unit,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.abbreviationList[m]
                                                          .abb
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "r flex-one align-r",
                                                    style:
                                                      (_vm.price_font_size_money &&
                                                      _vm.price_font_size_money !=
                                                        0
                                                        ? "font-size:" +
                                                          _vm.price_font_size_money +
                                                          "px"
                                                        : "") +
                                                      ";color: " +
                                                      _vm.price_style_font_color,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.moneyAdd(
                                                          200000,
                                                          _vm.abbreviationList[
                                                            m
                                                          ].rate,
                                                          2
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.tab == 1
                                ? _c("div", { staticClass: "bottom-right" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "bottom-r-t",
                                        style:
                                          "width: " +
                                          _vm.auction_style_width +
                                          "%;background: " +
                                          _vm.auction_style_background_color +
                                          ";padding: " +
                                          parseInt(30 * _vm.fix) +
                                          "px " +
                                          parseInt(60 * _vm.fix) +
                                          "px 0 " +
                                          parseInt(60 * _vm.fix) +
                                          "px;",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "lot",
                                            style:
                                              (_vm.auction_font_size_lot_horizontal &&
                                              _vm.auction_font_size_lot_horizontal !=
                                                0
                                                ? "font-size:" +
                                                  _vm.auction_font_size_lot_horizontal +
                                                  "px"
                                                : "") +
                                              ";color: " +
                                              _vm.auction_style_font_color +
                                              ";margin-top: " +
                                              parseInt(10 * _vm.fix) +
                                              "px;",
                                          },
                                          [_vm._v("LOT 1000")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "name name1",
                                            style:
                                              (_vm.auction_font_size_name_horizontal &&
                                              _vm.auction_font_size_name_horizontal !=
                                                0
                                                ? "font-size:" +
                                                  _vm.auction_font_size_name_horizontal +
                                                  "px"
                                                : "") +
                                              ";color: " +
                                              _vm.auction_style_name +
                                              ";margin-top: " +
                                              parseInt(10 * _vm.fix) +
                                              "px;height: " +
                                              parseInt(50 * _vm.fix) +
                                              "px;line-height: " +
                                              parseInt(50 * _vm.fix) +
                                              "px;",
                                          },
                                          [
                                            _vm._v(
                                              "清乾隆青花苍龙教子图抱月瓶三件真品"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "item-align-center",
                                            style:
                                              "margin-top: " +
                                              parseInt(10 * _vm.fix) +
                                              "px;",
                                          },
                                          [
                                            _vm.show_start_price
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "attr",
                                                    style:
                                                      (_vm.auction_font_size_intro_horizontal &&
                                                      _vm.auction_font_size_price_horizontal !=
                                                        0
                                                        ? "font-size:" +
                                                          _vm.auction_font_size_price_horizontal +
                                                          "px"
                                                        : "") +
                                                      ";color: " +
                                                      _vm.auction_style_price +
                                                      ";margin-top: 0;padding-right: " +
                                                      parseInt(20 * _vm.fix) +
                                                      "px;",
                                                  },
                                                  [_vm._v("起拍价：100,000")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "desc",
                                                style:
                                                  (_vm.auction_font_size_intro_horizontal &&
                                                  _vm.auction_font_size_intro_horizontal !=
                                                    0
                                                    ? "font-size:" +
                                                      _vm.auction_font_size_intro_horizontal +
                                                      "px"
                                                    : "") +
                                                  ";color: " +
                                                  _vm.auction_style_desc +
                                                  ";flex: 1;margin-top: 0;",
                                              },
                                              [_vm._v("拍品的一句话介绍")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bottom-r-b display-flex flex-wrap",
                                        style:
                                          "width: " +
                                          _vm.price_style_width +
                                          "%;background: " +
                                          _vm.price_style_background_color +
                                          ";padding: " +
                                          parseInt(56 * _vm.fix) +
                                          "px 0;margin: 0;",
                                      },
                                      _vm._l(
                                        _vm.changeAbbreviation,
                                        function (m, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              staticClass:
                                                "list item-flex-center",
                                              style:
                                                "width: " +
                                                parseInt(420 * _vm.fix) +
                                                "px;margin-top: " +
                                                parseInt(10 * _vm.fix) +
                                                "px;margin-right: " +
                                                (i % 2 == 0
                                                  ? parseInt(125 * _vm.fix)
                                                  : parseInt(70 * _vm.fix)) +
                                                "px;",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "l",
                                                  style:
                                                    (_vm.price_font_size_currency_horizontal &&
                                                    _vm.price_font_size_currency_horizontal !=
                                                      0
                                                      ? "font-size:" +
                                                        _vm.price_font_size_currency_horizontal +
                                                        "px"
                                                      : "") +
                                                    ";color: " +
                                                    _vm.price_style_font_color_unit,
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.abbreviationList[m]
                                                        .abb
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "r flex-one align-r",
                                                  style:
                                                    (_vm.price_font_size_money_horizontal &&
                                                    _vm.price_font_size_money_horizontal !=
                                                      0
                                                      ? "font-size:" +
                                                        _vm.price_font_size_money_horizontal +
                                                        "px"
                                                      : "") +
                                                    ";color: " +
                                                    _vm.price_style_font_color,
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.moneyAdd(
                                                          200000,
                                                          _vm.abbreviationList[
                                                            m
                                                          ].rate,
                                                          2
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "seeting-view" }, [
                    _c("div", { staticClass: "seeting" }, [
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item-l" }, [_vm._v("1")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-r remove-control" }, [
                            _c("div", { staticClass: "item-title" }, [
                              _vm._v("框架规则配置"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("屏幕尺寸：")]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w200",
                                    model: {
                                      value: _vm.windowSize,
                                      callback: function ($$v) {
                                        _vm.windowSize = $$v
                                      },
                                      expression: "windowSize",
                                    },
                                  },
                                  _vm._l(_vm.windowSizeList, function (m, i) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: i,
                                        attrs: { value: i, label: m.name },
                                      },
                                      [_vm._v(_vm._s(m.name))]
                                    )
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml10" }, [
                                  _vm._v("开启自适应："),
                                ]),
                                _vm._v(" "),
                                _c("el-switch", {
                                  model: {
                                    value: _vm.widthSizeSwitch,
                                    callback: function ($$v) {
                                      _vm.widthSizeSwitch = $$v
                                    },
                                    expression: "widthSizeSwitch",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("横竖屏自动切换：")]),
                                _vm._v(" "),
                                _c("el-switch", {
                                  model: {
                                    value: _vm.screen_auto_switch,
                                    callback: function ($$v) {
                                      _vm.screen_auto_switch = $$v
                                    },
                                    expression: "screen_auto_switch",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml5" }, [
                                  _vm._v(
                                    "(打开后，系统会根据拍品图片比例，自动切换横竖屏)"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item-l" }, [_vm._v("2")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-r remove-control" }, [
                            _c("div", { staticClass: "item-title" }, [
                              _vm._v("标题栏区域"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("风格：")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("高度")]),
                                _vm._v(" "),
                                _c("el-input-number", {
                                  staticClass: "inp1 ml5",
                                  attrs: { controls: false },
                                  model: {
                                    value: _vm.title_style_height,
                                    callback: function ($$v) {
                                      _vm.title_style_height = $$v
                                    },
                                    expression: "title_style_height",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml5" }, [
                                  _vm._v("px"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("背景色"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  model: {
                                    value: _vm.title_style_background_color,
                                    callback: function ($$v) {
                                      _vm.title_style_background_color = $$v
                                    },
                                    expression: "title_style_background_color",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("阴影颜色"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  model: {
                                    value: _vm.title_style_shadow_color,
                                    callback: function ($$v) {
                                      _vm.title_style_shadow_color = $$v
                                    },
                                    expression: "title_style_shadow_color",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "item-content" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("颜色：")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("主文字")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value: _vm.title_style_font_color,
                                    callback: function ($$v) {
                                      _vm.title_style_font_color = $$v
                                    },
                                    expression: "title_style_font_color",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml10" }, [
                                  _vm._v("上一件"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value:
                                      _vm.title_style_background_color_next,
                                    callback: function ($$v) {
                                      _vm.title_style_background_color_next =
                                        $$v
                                    },
                                    expression:
                                      "title_style_background_color_next",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml10" }, [
                                  _vm._v("落槌价"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value:
                                      _vm.title_style_background_color_over,
                                    callback: function ($$v) {
                                      _vm.title_style_background_color_over =
                                        $$v
                                    },
                                    expression:
                                      "title_style_background_color_over",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml10" }, [
                                  _vm._v("拍卖数"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value:
                                      _vm.title_style_background_color_auction_num,
                                    callback: function ($$v) {
                                      _vm.title_style_background_color_auction_num =
                                        $$v
                                    },
                                    expression:
                                      "title_style_background_color_auction_num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("字号：")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("拍卖会")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp ml5",
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.title_font_size_venue,
                                    callback: function ($$v) {
                                      _vm.title_font_size_venue = $$v
                                    },
                                    expression: "title_font_size_venue",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml5" }, [
                                  _vm._v("px"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("拍卖进行信息"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp ml5",
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.title_font_size_befor,
                                    callback: function ($$v) {
                                      _vm.title_font_size_befor = $$v
                                    },
                                    expression: "title_font_size_befor",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml5" }, [
                                  _vm._v("px"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("公司名称"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp ml5",
                                  attrs: { type: "number" },
                                  model: {
                                    value: _vm.title_font_size_company_name,
                                    callback: function ($$v) {
                                      _vm.title_font_size_company_name = $$v
                                    },
                                    expression: "title_font_size_company_name",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml5" }, [
                                  _vm._v("px"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "item-content" }, [
                              _c("span", [_vm._v("公司：")]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "item-align-center" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-img-view item-flex-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.uploadIconAction(
                                              "compImg"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.compImg
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "del-upload-img",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delImg("compImg")
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.compImg
                                          ? _c("img", {
                                              attrs: {
                                                src: _vm.compImg + "!120a",
                                              },
                                            })
                                          : _c("img", {
                                              staticClass: "upload-icon",
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                                alt: "",
                                              },
                                            }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml10" }, [
                                      _vm._v("企业LOGO"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w50 ml10",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.compWidth,
                                        callback: function ($$v) {
                                          _vm.compWidth = $$v
                                        },
                                        expression: "compWidth",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml5 mr5" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w50",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.compHeight,
                                        callback: function ($$v) {
                                          _vm.compHeight = $$v
                                        },
                                        expression: "compHeight",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w200 ml5",
                                      attrs: { placeholder: "请输入企业名称" },
                                      model: {
                                        value: _vm.compName,
                                        callback: function ($$v) {
                                          _vm.compName = $$v
                                        },
                                        expression: "compName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "item-align-center mt10" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-img-view item-flex-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.uploadIconAction(
                                              "compImg1"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.compImg1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "del-upload-img",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delImg(
                                                      "compImg1"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.compImg1
                                          ? _c("img", {
                                              attrs: {
                                                src: _vm.compImg1 + "!120a",
                                              },
                                            })
                                          : _c("img", {
                                              staticClass: "upload-icon",
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                                alt: "",
                                              },
                                            }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml10" }, [
                                      _vm._v("企业LOGO"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w50 ml10",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.compWidth1,
                                        callback: function ($$v) {
                                          _vm.compWidth1 = $$v
                                        },
                                        expression: "compWidth1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml5 mr5" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w50",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.compHeight1,
                                        callback: function ($$v) {
                                          _vm.compHeight1 = $$v
                                        },
                                        expression: "compHeight1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w200 ml5",
                                      attrs: { placeholder: "请输入企业名称" },
                                      model: {
                                        value: _vm.compName1,
                                        callback: function ($$v) {
                                          _vm.compName1 = $$v
                                        },
                                        expression: "compName1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item-l" }, [_vm._v("3")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-r remove-control" }, [
                            _c("div", { staticClass: "item-title" }, [
                              _vm._v("图片区域"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("区域配置：")]),
                                _vm._v(" "),
                                _vm.tab == 0
                                  ? _c("span", [_vm._v("宽度")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.tab == 0
                                  ? _c("el-input-number", {
                                      staticClass: "inp1 ml5",
                                      attrs: { controls: false },
                                      model: {
                                        value: _vm.image_style_width,
                                        callback: function ($$v) {
                                          _vm.image_style_width = $$v
                                        },
                                        expression: "image_style_width",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.tab == 1
                                  ? _c("span", [_vm._v("高度")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.tab == 1
                                  ? _c("el-input-number", {
                                      staticClass: "inp1 ml5",
                                      attrs: { controls: false },
                                      model: {
                                        value: _vm.image_style_height,
                                        callback: function ($$v) {
                                          _vm.image_style_height = $$v
                                        },
                                        expression: "image_style_height",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml5" }, [
                                  _vm._v("%"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("主背景色"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  model: {
                                    value: _vm.image_background_color,
                                    callback: function ($$v) {
                                      _vm.image_background_color = $$v
                                    },
                                    expression: "image_background_color",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.tab == 0
                              ? _c(
                                  "div",
                                  { staticClass: "item-content" },
                                  [
                                    _c("span", [_vm._v("图片配置：")]),
                                    _vm._v(" "),
                                    _vm.tab == 0
                                      ? _c("span", [_vm._v("背景色")])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp1 ml5",
                                      model: {
                                        value: _vm.img_bg,
                                        callback: function ($$v) {
                                          _vm.img_bg = $$v
                                        },
                                        expression: "img_bg",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.tab == 0
                                      ? _c("span", { staticClass: "ml10" }, [
                                          _vm._v("阴影"),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.tab == 0
                                      ? _c("el-input", {
                                          staticClass: "inp1 ml5",
                                          model: {
                                            value: _vm.img_shadow,
                                            callback: function ($$v) {
                                              _vm.img_shadow = $$v
                                            },
                                            expression: "img_shadow",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml10" }, [
                                      _vm._v("宽"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.img_width,
                                        callback: function ($$v) {
                                          _vm.img_width = $$v
                                        },
                                        expression: "img_width",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml5" }, [
                                      _vm._v("px"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml10" }, [
                                      _vm._v("高"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.img_height,
                                        callback: function ($$v) {
                                          _vm.img_height = $$v
                                        },
                                        expression: "img_height",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml5" }, [
                                      _vm._v("px"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item-l" }, [_vm._v("4")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-r remove-control" }, [
                            _c("div", { staticClass: "item-title" }, [
                              _vm._v("拍品信息区域"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  model: {
                                    value: _vm.show_start_price,
                                    callback: function ($$v) {
                                      _vm.show_start_price = $$v
                                    },
                                    expression: "show_start_price",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml5" }, [
                                  _vm._v("展示起拍价"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("风格：")]),
                                _vm._v(" "),
                                _vm.tab == 1
                                  ? _c(
                                      "div",
                                      { staticClass: "item-align-center" },
                                      [
                                        _c("span", [_vm._v("宽度")]),
                                        _vm._v(" "),
                                        _c("el-input-number", {
                                          staticClass: "inp1 ml5",
                                          attrs: { controls: false },
                                          model: {
                                            value: _vm.auction_style_width,
                                            callback: function ($$v) {
                                              _vm.auction_style_width = $$v
                                            },
                                            expression: "auction_style_width",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml5" }, [
                                          _vm._v("%"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm.tab == 0
                                  ? _c(
                                      "div",
                                      { staticClass: "item-align-center" },
                                      [
                                        _c("span", [_vm._v("高度")]),
                                        _vm._v(" "),
                                        _c("el-input-number", {
                                          staticClass: "inp1 ml5",
                                          attrs: { controls: false },
                                          model: {
                                            value: _vm.auction_style_height,
                                            callback: function ($$v) {
                                              _vm.auction_style_height = $$v
                                            },
                                            expression: "auction_style_height",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "ml5" }, [
                                          _vm._v("%"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("背景色"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  model: {
                                    value: _vm.auction_style_background_color,
                                    callback: function ($$v) {
                                      _vm.auction_style_background_color = $$v
                                    },
                                    expression:
                                      "auction_style_background_color",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("颜色：")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("LOT号")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value: _vm.auction_style_font_color,
                                    callback: function ($$v) {
                                      _vm.auction_style_font_color = $$v
                                    },
                                    expression: "auction_style_font_color",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("名称"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value: _vm.auction_style_name,
                                    callback: function ($$v) {
                                      _vm.auction_style_name = $$v
                                    },
                                    expression: "auction_style_name",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("介绍"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value: _vm.auction_style_desc,
                                    callback: function ($$v) {
                                      _vm.auction_style_desc = $$v
                                    },
                                    expression: "auction_style_desc",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("起拍价"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  staticStyle: { width: "70px" },
                                  model: {
                                    value: _vm.auction_style_price,
                                    callback: function ($$v) {
                                      _vm.auction_style_price = $$v
                                    },
                                    expression: "auction_style_price",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.tab == 0
                              ? _c(
                                  "div",
                                  { staticClass: "item-content" },
                                  [
                                    _c("span", [_vm._v("字号：")]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("LOT号")]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.auction_font_size_lot,
                                        callback: function ($$v) {
                                          _vm.auction_font_size_lot = $$v
                                        },
                                        expression: "auction_font_size_lot",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml20" }, [
                                      _vm._v("拍品名称"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.auction_font_size_name,
                                        callback: function ($$v) {
                                          _vm.auction_font_size_name = $$v
                                        },
                                        expression: "auction_font_size_name",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml20" }, [
                                      _vm._v("拍品介绍"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.auction_font_size_intro,
                                        callback: function ($$v) {
                                          _vm.auction_font_size_intro = $$v
                                        },
                                        expression: "auction_font_size_intro",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml20" }, [
                                      _vm._v("起拍价"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.auction_font_size_price,
                                        callback: function ($$v) {
                                          _vm.auction_font_size_price = $$v
                                        },
                                        expression: "auction_font_size_price",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm.tab == 1
                              ? _c(
                                  "div",
                                  { staticClass: "item-content" },
                                  [
                                    _c("span", [_vm._v("字号：")]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("LOT号")]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.auction_font_size_lot_horizontal,
                                        callback: function ($$v) {
                                          _vm.auction_font_size_lot_horizontal =
                                            $$v
                                        },
                                        expression:
                                          "auction_font_size_lot_horizontal",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml20" }, [
                                      _vm._v("拍品名称"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.auction_font_size_name_horizontal,
                                        callback: function ($$v) {
                                          _vm.auction_font_size_name_horizontal =
                                            $$v
                                        },
                                        expression:
                                          "auction_font_size_name_horizontal",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml20" }, [
                                      _vm._v("拍品介绍"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.auction_font_size_intro_horizontal,
                                        callback: function ($$v) {
                                          _vm.auction_font_size_intro_horizontal =
                                            $$v
                                        },
                                        expression:
                                          "auction_font_size_intro_horizontal",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml20" }, [
                                      _vm._v("起拍价"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.auction_font_size_price_horizontal,
                                        callback: function ($$v) {
                                          _vm.auction_font_size_price_horizontal =
                                            $$v
                                        },
                                        expression:
                                          "auction_font_size_price_horizontal",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "item-l" }, [_vm._v("5")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-r remove-control" }, [
                            _c("div", { staticClass: "item-title" }, [
                              _vm._v("价格区域"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "item-content" }, [
                              _c("span", [_vm._v("风格：")]),
                              _vm._v(" "),
                              _vm.tab == 1
                                ? _c(
                                    "div",
                                    { staticClass: "item-align-center" },
                                    [
                                      _c("span", [_vm._v("宽度")]),
                                      _vm._v(" "),
                                      _c("el-input-number", {
                                        staticClass: "inp1 ml5",
                                        attrs: { controls: false },
                                        model: {
                                          value: _vm.price_style_width,
                                          callback: function ($$v) {
                                            _vm.price_style_width = $$v
                                          },
                                          expression: "price_style_width",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml5" }, [
                                        _vm._v("%"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm.tab == 0
                                ? _c(
                                    "div",
                                    { staticClass: "item-align-center" },
                                    [
                                      _c("span", [_vm._v("高度")]),
                                      _vm._v(" "),
                                      _c("el-input-number", {
                                        staticClass: "inp1 ml5",
                                        attrs: { controls: false },
                                        model: {
                                          value: _vm.price_style_height,
                                          callback: function ($$v) {
                                            _vm.price_style_height = $$v
                                          },
                                          expression: "price_style_height",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml5" }, [
                                        _vm._v("%"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "item-content" },
                              [
                                _c("span", [_vm._v("颜色：")]),
                                _vm._v(" "),
                                _c("span", [_vm._v("背景色")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  model: {
                                    value: _vm.price_style_background_color,
                                    callback: function ($$v) {
                                      _vm.price_style_background_color = $$v
                                    },
                                    expression: "price_style_background_color",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("币种"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  model: {
                                    value: _vm.price_style_font_color_unit,
                                    callback: function ($$v) {
                                      _vm.price_style_font_color_unit = $$v
                                    },
                                    expression: "price_style_font_color_unit",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "ml20" }, [
                                  _vm._v("价格"),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "inp1 ml5",
                                  model: {
                                    value: _vm.price_style_font_color,
                                    callback: function ($$v) {
                                      _vm.price_style_font_color = $$v
                                    },
                                    expression: "price_style_font_color",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.tab == 0
                              ? _c(
                                  "div",
                                  { staticClass: "item-content" },
                                  [
                                    _c("span", [_vm._v("字号：")]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("币种")]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.price_font_size_currency,
                                        callback: function ($$v) {
                                          _vm.price_font_size_currency = $$v
                                        },
                                        expression: "price_font_size_currency",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml20" }, [
                                      _vm._v("价格"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value: _vm.price_font_size_money,
                                        callback: function ($$v) {
                                          _vm.price_font_size_money = $$v
                                        },
                                        expression: "price_font_size_money",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm.tab == 1
                              ? _c(
                                  "div",
                                  { staticClass: "item-content" },
                                  [
                                    _c("span", [_vm._v("字号：")]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("币种")]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.price_font_size_currency_horizontal,
                                        callback: function ($$v) {
                                          _vm.price_font_size_currency_horizontal =
                                            $$v
                                        },
                                        expression:
                                          "price_font_size_currency_horizontal",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "ml20" }, [
                                      _vm._v("价格"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "inp ml5",
                                      attrs: { type: "number" },
                                      model: {
                                        value:
                                          _vm.price_font_size_money_horizontal,
                                        callback: function ($$v) {
                                          _vm.price_font_size_money_horizontal =
                                            $$v
                                        },
                                        expression:
                                          "price_font_size_money_horizontal",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btns" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.saveAction },
                          },
                          [_vm._v("保存")]
                        ),
                        _vm._v(" "),
                        _c("el-button", { on: { click: _vm.backAction } }, [
                          _vm._v("取消"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "seeting-title mt20 ml20 ft14",
        staticStyle: {
          border: "1px solid #ccc",
          width: "240px",
          "text-align": "center",
          "line-height": "50px",
        },
      },
      [
        _vm._v("默认币种 "),
        _c("span", { staticClass: "ml40" }, [_vm._v("人民币 CNY")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }