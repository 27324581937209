var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: { path: "" + _vm.roterPre + "/basic/sellcreate" },
                },
              },
              [
                _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                  _vm._v("创建销售单"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "multipleSelection",
          staticClass: "table",
          staticStyle: { width: "100%" },
          attrs: {
            "tooltip-effect": "dark",
            "row-key": function (row) {
              return row.order_id
            },
            data: _vm.tableData.data,
            border: "",
            size: "mini",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "tableEmpty" }, [
              _c("img", {
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "单据编号", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(scope.row.order_no) +
                          "\n          "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "客户", "min-width": "90", prop: "user.nickname" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "销售人员",
              "min-width": "90",
              prop: "saleAdmin.real_name",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "creator.real_name",
              label: "制单人",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "auditAdmin.real_name",
              label: "审批人",
              "min-width": "120",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "金额合计",
              "min-width": "100",
              prop: "total_price",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.audit_status == 0
                      ? _c("div", [_vm._v("\n             待审核\n          ")])
                      : scope.row.audit_status == 1
                      ? _c("div", [_vm._v("\n            已审核\n          ")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { "min-width": "600" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return _vm._l(
                      scope.row.entrustOrderProduct,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: "enp_" + index,
                            staticClass: "flex-box",
                            class: {
                              "flex-box-border-bottom":
                                index <
                                scope.row.entrustOrderProduct.length - 1,
                            },
                          },
                          [
                            _c("div", { staticClass: "flex-box-image" }, [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: item.productItem.image,
                                      "preview-src-list": [
                                        item.productItem.image,
                                      ],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex-box-name" }, [
                              _vm._v(_vm._s(item.productItem.store_name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex-box-price" }, [
                              _vm._v(_vm._s(item.retain_price)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex-box-autor" }, [
                              _vm._v(_vm._s(item.brand ? item.brand.name : "")),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex-box-name" },
                              _vm._l(
                                item.productItem.attrInfo,
                                function (m, i) {
                                  return _c("span", { key: "attr_" + i }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(m.attr_name) +
                                        ":" +
                                        _vm._s(m.attr_value_name) +
                                        "\n              "
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }
                    )
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticClass: "flex-box" }, [
                  _c("div", { staticClass: "flex-box-image" }, [
                    _vm._v("图片"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-box-name" }, [_vm._v("名称")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-box-price" }, [
                    _vm._v("保留价"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-box-autor" }, [
                    _vm._v("作者"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-box-name" }, [_vm._v("属性")]),
                ]),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "80",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return undefined
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }