var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-header",
        {
          staticStyle: { position: "fixed", left: "0", top: "0", right: "0" },
          attrs: { height: "64px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "item-align-center",
              staticStyle: {
                width: "1345px",
                margin: "0 auto",
                height: "64px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-back item-align-center",
                  on: { click: _vm.back },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20211022/b8b0d796b5afef0149e76265443f1cbd.png",
                      alt: "",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.connected
        ? _c("div", { staticClass: "obs-content" }, [
            _c("div", { staticClass: "obs-title" }, [
              _vm._v("欢迎使用 艺天下 OBS 控制器!"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "obs-desc" }, [
              _vm._v("要开始，请在下面输入您的OBS主机：端口，然后单击“连接”。"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c("el-input", {
                  staticClass: "input-with-select",
                  attrs: { placeholder: "ws://localhost:4455" },
                  model: {
                    value: _vm.input,
                    callback: function ($$v) {
                      _vm.input = $$v
                    },
                    expression: "input",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "密码 (请输入密码，如果密码空可不输入)",
                    size: "normal",
                    clearable: "",
                  },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-10" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.contentlink },
                  },
                  [_vm._v("确认链接")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "obs-sign" }, [
              _vm._v(
                "\n      确保你的obs版本 OBS v28+ 或者 安装 obs-websocket 5.0.1 plugin 在版本v27.\n      如果你正在使用老的obs, 请下载新的obs\n    "
              ),
            ]),
          ])
        : _c("div", { staticClass: "obs-content" }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "default" },
                    on: { click: _vm.startCreate },
                  },
                  [_vm._v("创建场景")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "default" },
                    on: { click: _vm.showposter },
                  },
                  [_vm._v("展示直播海报")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "default" },
                    on: { click: _vm.openautochange },
                  },
                  [_vm._v("开启自动切换")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "default" },
                    on: { click: _vm.settingQuickUrl },
                  },
                  [_vm._v("设置快直播推流地址")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "default" },
                    on: { click: _vm.settingNormalUrl },
                  },
                  [_vm._v("设置普通推流地址")]
                ),
                _vm._v(" "),
                _vm.heartbeat &&
                _vm.heartbeat.streaming &&
                _vm.heartbeat.streaming.outputActive
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "default" },
                        on: { click: _vm.stopPush },
                      },
                      [_vm._v("停止推流")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "default" },
                        on: { click: _vm.startPush },
                      },
                      [_vm._v("开始推流")]
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.imageurl
              ? _c("div", [
                  _c("img", { attrs: { src: _vm.imageurl, alt: "" } }),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }