"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.articleAddApi = articleAddApi;
exports.articleCreateApi = articleCreateApi;
exports.articleDeleApi = articleDeleApi;
exports.articleDeleteApi = articleDeleteApi;
exports.articleDetailApi = articleDetailApi;
exports.articleEditApi = articleEditApi;
exports.articleListApi = articleListApi;
exports.articleLstApi = articleLstApi;
exports.articleSearchListApi = articleSearchListApi;
exports.articleStatuseApi = articleStatuseApi;
exports.articleUpdateApi = articleUpdateApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 文章分类 -- 列表
 */
function articleListApi() {
  return _request.default.get('system/article/category/lst');
}
/**
 * @description 文章分类 -- 列表 (id, name)
 */
function articleSearchListApi(data) {
  return _request.default.get('system/article/article/searchList', data);
}
/**
 * @description 文章分类 -- 新增表单
 */
function articleCreateApi() {
  return _request.default.get('system/article/category/create/form');
}
/**
 * @description 文章分类 -- 编辑表单
 */
function articleUpdateApi(id) {
  return _request.default.get("system/article/category/update/form/".concat(id));
}
/**
 * @description 文章分类 -- 删除
 */
function articleDeleteApi(id) {
  return _request.default.delete("system/article/category/delete/".concat(id));
}
/**
 * @description 文章分类 -- 修改开启状态
 */
function articleStatuseApi(id, status) {
  return _request.default.post("system/article/category/status/".concat(id), {
    status: status
  });
}
/**
 * @description 文章管理 -- 列表
 */
function articleLstApi(data) {
  return _request.default.get('system/article/article/lst', data);
}
/**
 * @description 文章管理 -- 详情
 */
function articleDetailApi(id) {
  return _request.default.get("system/article/article/detail/".concat(id));
}
/**
 * @description 文章管理 -- 添加
 */
function articleAddApi(data) {
  return _request.default.post('system/article/article/create', data);
}
/**
 * @description 文章管理 -- 编辑
 */
function articleEditApi(data, id) {
  return _request.default.post("system/article/article/update/".concat(id), data);
}
/**
 * @description 文章管理 -- 删除
 */
function articleDeleApi(id) {
  return _request.default.delete("system/article/article/delete/".concat(id));
}