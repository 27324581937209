var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("header-breadcrumb", { attrs: { name: "文章详情", "has-back": "" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml20 mt20" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "form",
              attrs: {
                model: _vm.formValidate,
                "label-width": "120px",
                rules: _vm.ruleValidate,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "dividerTitle" },
                [
                  _c("span", { staticClass: "title mr10" }, [
                    _vm._v("文章信息"),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "标题：",
                            prop: "title",
                            "label-for": "title",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "90%" },
                            attrs: {
                              placeholder: "请输入",
                              "element-id": "title",
                            },
                            model: {
                              value: _vm.formValidate.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "title", $$v)
                              },
                              expression: "formValidate.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({ staticClass: "mr50" }, "el-col", _vm.grid, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "作者：",
                            prop: "author",
                            "label-for": "author",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "90%" },
                            attrs: {
                              placeholder: "请输入",
                              maxLength: "32",
                              "element-id": "author",
                            },
                            model: {
                              value: _vm.formValidate.author,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "author", $$v)
                              },
                              expression: "formValidate.author",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "文章分类：",
                            "label-for": "cid",
                            prop: "cid",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90%" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.formValidate.cid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "cid", $$v)
                                },
                                expression: "formValidate.cid",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                {
                                  staticStyle: {
                                    width: "560px",
                                    height: "200px",
                                    overflow: "auto",
                                    "background-color": "#fff",
                                  },
                                  attrs: {
                                    label: _vm.sleOptions.title,
                                    value: _vm.sleOptions.article_category_id,
                                  },
                                },
                                [
                                  _c("el-tree", {
                                    ref: "tree2",
                                    attrs: {
                                      data: _vm.treeData,
                                      props: _vm.defaultProps,
                                      "highlight-current": "",
                                    },
                                    on: { "node-click": _vm.handleSelClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({ staticClass: "mr50" }, "el-col", _vm.grid, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公众号地址：",
                            prop: "synopsis",
                            "label-for": "synopsis",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "90%" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formValidate.synopsis,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "synopsis", $$v)
                              },
                              expression: "formValidate.synopsis",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({ staticClass: "mr50" }, "el-col", _vm.grid, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "图文封面：", prop: "image_input" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image_input
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.formValidate.image_input,
                                      },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "腾讯视频地址：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              placeholder: "请输入",
                              "element-id": "title",
                            },
                            model: {
                              value: _vm.formValidate.video_url,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "video_url", $$v)
                              },
                              expression: "formValidate.video_url",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dividerTitle" },
                [
                  _c("span", { staticClass: "title" }, [_vm._v("文章内容")]),
                  _vm._v(" "),
                  _c("el-divider"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "文章内容：", prop: "content" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.formValidate.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "content", $$v)
                      },
                      expression: "formValidate.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dividerTitle" },
                [
                  _c("span", { staticClass: "title" }, [_vm._v("其他设置")]),
                  _vm._v(" "),
                  _c("el-divider"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否显示：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "status", $$v)
                                },
                                expression: "formValidate.status",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "radio", attrs: { label: 1 } },
                                [_vm._v("显示")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("不显示"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "submission",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onsubmit("formValidate")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }