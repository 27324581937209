"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  props: {
    value: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: "确认"
    },
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    width: {
      type: String,
      default: '344px'
    }
  },
  data: function data() {
    return {
      visible: false,
      cacheData: "",
      is_error: false,
      msg: "此名称已被占用，请重新输入"
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    name: {
      get: function get() {
        if (this.value && Number(this.value.length) == 13) {
          return "默认微页面" + this.pageInfo.id;
        } else {
          return this.value;
        }
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    handleDialog: function handleDialog() {
      this.cacheData = this.value;
      this.visible = true;
    },
    /**
     * 获取商品信息
     * @return {[type]} [description]
     */
    handleConfirm: function handleConfirm() {
      var _this = this;
      if (!this.value) {
        this.msg = "微页面名称不能为空";
        this.is_error = true;
        return;
      }
      this.$heshop.pages("PUT", {
        behavior: 'check_title',
        id: this.pageInfo.id
      }, {
        title: this.name
      }).then(function (data) {
        _this.is_error = data;
        _this.msg = "此名称已被占用，请重新输入";
        if (!data) {
          _this.visible = false;
        }
      }).catch(function (error) {
        console.error("错误提示", error);
      });
      // this.visible = false;
      // this.$emit('confirm');
    },
    /**
     * 获取分组信息
     * @return {[type]} [description]
     */
    handleCancel: function handleCancel() {
      this.visible = false;
      this.$emit('cancel');
    }
  },
  render: function render(h) {
    var _this2 = this;
    var handleConfirm = this.handleConfirm,
      handleDialog = this.handleDialog,
      handleCancel = this.handleCancel,
      confirmButtonText = this.confirmButtonText,
      cancelButtonText = this.cancelButtonText,
      is_error = this.is_error;
    var dialogData = {
      props: {
        title: "微页面名称",
        visible: this.visible,
        width: this.width,
        center: true,
        top: "15%",
        "append-to-body": true,
        "modal-append-to-body": false,
        "before-close": handleCancel
      }
    };
    var inputData = {
      props: {
        size: "small",
        maxlength: 8,
        placeholder: "请输入页面名称"
      }
    };
    //判断插槽是否存在DOM结构，如果存在则获取后放入upload目录
    var trigger = this.$slots.trigger || this.$slots.default;
    return h("div", {
      "class": "pagetitle"
    }, [h("span", {
      "on": {
        "click": handleDialog
      }
    }, [trigger]), h("el-dialog", (0, _babelHelperVueJsxMergeProps.default)([{}, dialogData]), [name, h("el-input", (0, _babelHelperVueJsxMergeProps.default)([{
      "attrs": {
        "type": "text"
      }
    }, inputData, {
      "attrs": {
        "show-word-limit": true
      },
      "model": {
        value: _this2.name,
        callback: function callback($$v) {
          _this2.name = $$v;
        }
      }
    }])), is_error ? h("span", {
      "class": "pagetitle_error"
    }, [this.msg]) : '', h("span", {
      "slot": "footer",
      "class": "dialog-footer"
    }, [h("el-button", {
      "on": {
        "click": handleCancel
      }
    }, [cancelButtonText]), h("el-button", {
      "attrs": {
        "type": "primary"
      },
      "on": {
        "click": handleConfirm
      }
    }, [confirmButtonText])])])]);
  }
};