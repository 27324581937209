var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.style == 1
      ? _c(
          "div",
          {
            staticClass: "content-box",
            style:
              "padding: 0 " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(2, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "one-list",
                class: { mt0: index == 0 },
                style:
                  "width: 100%;margin-top:" +
                  _vm.facade.item_margin / 2 +
                  "px;border-top-right-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-top-left-radius: " +
                  _vm.facade.img_radius / 2 +
                  "px;border-bottom-left-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;border-bottom-right-radius: " +
                  _vm.facade.status_radius / 2 +
                  "px;background: " +
                  _vm.facade.status_background +
                  ";",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img",
                    style:
                      "border-bottom-left-radius: " +
                      _vm.facade.img_bottom_radius / 2 +
                      "px;border-bottom-right-radius: " +
                      _vm.facade.img_bottom_radius / 2 +
                      "px;",
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex-one",
                    staticStyle: { overflow: "hidden" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text color-black line1",
                        style:
                          "padding: 10px 10px 0 10px;color: " +
                          _vm.facade.title_color +
                          ";font-size: " +
                          _vm.facade.title_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.title_font_weight +
                          ";",
                      },
                      [_vm._v("\n          聚核-2023破冰艺术展蓝灰")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l-price",
                          style: "color: " + _vm.facade.price_color + ";",
                        },
                        [
                          _vm._v("￥"),
                          _c(
                            "span",
                            {
                              style:
                                "font-size: " +
                                _vm.facade.price_font_size / 2 +
                                "px;",
                            },
                            [_vm._v("100")]
                          ),
                          _vm._v("元"),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        )
      : _vm.content.style == 3
      ? _c(
          "div",
          {
            staticClass: "content-box flex",
            style:
              "padding: 0 " +
              _vm.facade.page_margin / 2 +
              "px;background: " +
              _vm.facade.background_color +
              ";font-weight: " +
              _vm.facade.font_weight,
          },
          _vm._l(2, function (m, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "l flex-one",
                staticStyle: { overflow: "hidden" },
                style:
                  i > 0
                    ? "padding-left: " + _vm.facade.item_margin / 2 + "px;"
                    : "",
              },
              _vm._l(2, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "one-list",
                    class: { mt0: index == 0 },
                    style:
                      "width: 100%;margin-top:" +
                      _vm.facade.item_margin / 2 +
                      "px;border-top-right-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-top-left-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-bottom-left-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;border-bottom-right-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;background: " +
                      _vm.facade.status_background +
                      ";",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        staticStyle: { background: "#999" },
                        style:
                          _vm.getHeight +
                          "border-bottom-left-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;border-bottom-right-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;",
                      },
                      [
                        _vm.facade.img_style === 1
                          ? _c("img", {
                              staticStyle: { display: "block" },
                              attrs: {
                                src:
                                  index == 0 && i == 0
                                    ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                    : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                              },
                            })
                          : _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src:
                                  index == 0 && i == 0
                                    ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                    : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                                fit:
                                  _vm.facade.img_style === 2
                                    ? "contain"
                                    : "cover",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "text color-black line1",
                        style:
                          "padding: 10px 10px 0 10px;color: " +
                          _vm.facade.title_color +
                          ";font-size: " +
                          _vm.facade.title_font_size / 2 +
                          "px;font-weight: " +
                          _vm.facade.title_font_weight +
                          ";",
                      },
                      [_vm._v("\n          聚核-2023破冰艺术展蓝灰")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "l-price",
                          style:
                            "color: " +
                            _vm.facade.price_color +
                            ";font-weight: " +
                            _vm.facade.price_font_weight +
                            ";",
                        },
                        [
                          _vm._v("￥"),
                          _c(
                            "span",
                            {
                              style:
                                "font-size: " +
                                _vm.facade.price_font_size / 2 +
                                "px;",
                            },
                            [_vm._v("100")]
                          ),
                          _vm._v("元"),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              0
            )
          }),
          0
        )
      : _vm.content.style == 5
      ? _c(
          "div",
          { staticClass: "content-box", staticStyle: { padding: "0" } },
          [
            _c(
              "div",
              {
                staticClass: "auction-column flex",
                style:
                  "padding: 0 " +
                  _vm.facade.page_margin / 2 +
                  "px;background: " +
                  _vm.facade.background_color +
                  ";font-weight: " +
                  _vm.facade.font_weight +
                  ";overflow: hidden;",
              },
              _vm._l(2, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "one-list",
                    class: { ml0: index == 0 },
                    style:
                      "width: " +
                      _vm.facade.img_width / 2 +
                      "px;margin-left:" +
                      _vm.facade.item_margin / 2 +
                      "px;border-top-right-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-top-left-radius: " +
                      _vm.facade.img_radius / 2 +
                      "px;border-bottom-left-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;border-bottom-right-radius: " +
                      _vm.facade.status_radius / 2 +
                      "px;background: " +
                      _vm.facade.status_background +
                      ";",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style:
                          "border-bottom-left-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;border-bottom-right-radius: " +
                          _vm.facade.img_bottom_radius / 2 +
                          "px;width: " +
                          _vm.facade.img_width / 2 +
                          "px;height: " +
                          _vm.facade.img_height / 2 +
                          "px;",
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src:
                              index == 0
                                ? "https://saas.cdn.yunzongbu.cn/image/20211023/7c0028ed94e3f35e7859cac94884f6ed.png!m640"
                                : "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!m640",
                            fit:
                              _vm.facade.img_style === 2 ? "contain" : "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex-one",
                        staticStyle: { overflow: "hidden" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text color-black line1",
                            style:
                              "padding: 10px 10px 0 10px;color: " +
                              _vm.facade.title_color +
                              ";font-size: " +
                              _vm.facade.title_font_size / 2 +
                              "px;font-weight: " +
                              _vm.facade.title_font_weight +
                              ";",
                          },
                          [_vm._v("\n            聚核-2023破冰艺术展蓝灰")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "bottom flex" }, [
                          _c(
                            "div",
                            {
                              staticClass: "l-price",
                              style: "color: " + _vm.facade.price_color + ";",
                            },
                            [
                              _vm._v("￥"),
                              _c(
                                "span",
                                {
                                  style:
                                    "font-size: " +
                                    _vm.facade.price_font_size / 2 +
                                    "px;",
                                },
                                [_vm._v("100")]
                              ),
                              _vm._v("元"),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }