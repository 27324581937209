"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _nft = require("@/api/nft");
var _index = require("@/utils/index");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      goodsArr: [],
      orderidArr: [],
      tab: 'goods',
      listLoading: false,
      queryParams: {
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber
    };
  },
  created: function created() {
    this.getCollection();
    this.getList(1);
  },
  methods: {
    getCollection: function getCollection() {
      var _this = this;
      (0, _marketing.getGameCanuseCollection)({
        page: 1,
        limit: 50
      }).then(function (res) {
        _this.goodsArr = res.data.list;
        _this.orderidArr = res.data.list.map(function (item) {
          return item.product_id;
        });
      });
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      (0, _nft.nftCollectionListApi)(this.queryParams).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    selectinver: function selectinver(row) {
      var _this3 = this;
      if (this.orderidArr.indexOf(row.product_id) > -1) {
        return;
      }
      (0, _marketing.setGameCanuseCollection)({
        product_id: row.product_id,
        addition: 0
      }).then(function (res) {
        _this3.getCollection();
      });
    },
    cancelSelect: function cancelSelect(row) {},
    delgoods: function delgoods(row) {
      var _this4 = this;
      (0, _marketing.delGameCanuseCollection)({
        put_id: row.put_id
      }).then(function (res) {
        _this4.getCollection();
      });
    },
    changeaddition: function changeaddition(row) {
      // customize_price   自定义价格
      // is_customize    是否使用自定义价格 1:是 2:否
      (0, _marketing.setGameCanuseCollectionProperties)({
        put_id: row.put_id,
        addition: row.addition,
        is_customize: row.is_customize,
        customize_price: row.customize_price
      }).then(function (res) {});
    }
  }
};