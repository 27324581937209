"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.match");
var _nft = require("@/api/nft");
var _index = require("@/utils/index");
var _product = require("@/api/product");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "addBlindBox",
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      currentTab: 0,
      fullscreenLoading: false,
      formValidate: {
        image: "",
        leftimage: "",
        rightimage: "",
        topimage: "",
        title: "",
        box_image: "",
        sale_time: "",
        sale_price: "",
        sale_number: "",
        total_number: "",
        reserved_number: "",
        limit_buy_number: "",
        transfer_config_id: "",
        is_show_total_number: '1',
        content: ""
      },
      ruleValidate: {
        title: [{
          required: true,
          message: "请输入盲盒名称",
          trigger: "blur"
        }],
        sale_time: [{
          required: true,
          message: "请选择发售时间",
          trigger: "change"
        }],
        sale_price: [{
          required: true,
          message: "请输入发售价格",
          trigger: "blur"
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error("请输入正确的发售价格"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }],
        total_number: [{
          required: false,
          message: "请输入发售数量",
          trigger: "blur"
        }, {
          validator: function validator(rule, value, callback) {
            if (value < 0) {
              callback(new Error("请输入正确的发售数量"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }]
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      queryParams: {
        page: 1,
        limit: 20
      },
      tabelform: {
        page: 1,
        limit: 20
      },
      selectTableData: {
        data: [],
        total: 0
      },
      orderidArr: [],
      orderArr: [],
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      nftid: '',
      sellistLoading: false,
      category: [],
      categoryList: [],
      all: {
        issale: '0'
      },
      disabled: false,
      transferList: []
    };
  },
  created: function created() {
    var _this2 = this;
    (0, _product.storeCategoryListApi)().then(function (res) {
      _this2.categoryList = res.data;
    });
    (0, _nft.transferListApi)({
      page: 1,
      limit: 50
    }).then(function (res) {
      _this2.transferList = res.data.list;
    });
    if (this.$route.query.id) {
      //   this.currentTab = 1;
      this.nftid = this.$route.query.id;
      this.getBoxDetail(this.$route.query.id);
      this.getselectList();
    }
    this.getList(1);
    // .replace(
    //           /https:\/\/saas.cdn.yunzongbu.cn/g,
    //           "https://saas.cdn.yunzongbu.cn"
    //         )
    // this.$nextTick(() => {
    //   var canvas = document.getElementById("canvas");
    //   var context = canvas.getContext("2d");
    //   var img = new Image();
    //   img.src = "https://saas.cdn.yunzongbu.cn/merchant/23/2022518/image/1652879927398736631.png!120a";
    //   // img.crossOrigin = "Anonymous";

    //   // context.transform(a,b,c,d,e,f);
    //   // a	水平缩放绘图 b	水平倾斜绘图 c	垂直倾斜绘图 d	垂直缩放绘图 e	水平移动绘图 f	垂直移动绘图
    //   img.onload = function () {
    //     // Top left 
    //     // context.rotate(0.7854);
    //     // context.transform(1,0.65,-0.5,1,30,10)

    //     context.transform(1,0,.5,.5,0,0);
    //     context.drawImage(img, 0, 0, 100, 100);
    //     context.setTransform(1,0,0,1,0,0);
    //     context.transform(.5,.5,0,1,0,0);
    //     context.drawImage(img, 0, 0, 100, 100);
    //     context.setTransform(1,0,0,1,0,0);
    //     context.transform(1,0,0,1,50,50);
    //     context.drawImage(img, 0, 0, 100, 100);
    //     //  var deg  =  Math.PI / 180 ;
    //     // context.transform(1,0,Math.sin(105*deg),1,0,0);
    //     // context.drawImage(img, 0, 0, 100, 60);
    //     // img.src = 'https://saas.cdn.yunzongbu.cn/merchant/23/2022518/image/1652854654212116396.png!120a'
    //     // img.onload = function () {
    //     //   context.setTransform(1,0,0,1,0,0);
    //     //   context.transform(1,Math.sin(75*deg),0,1,0,0);
    //     //   context.drawImage(img, 0, 0, 60, 100);
    //     //   img.src = 'https://saas.cdn.yunzongbu.cn/merchant/23/2022513/image/1652420141581904054.jpeg!120a'
    //     //   img.onload=function () {
    //     //       context.setTransform(1,0,0,1,0,0);
    //     //       context.transform(1,0,0,1,60,60);
    //     //       context.drawImage(img, 0, 0, 100, 100);
    //     //   }

    //     // }
    //     // console.log(canvas.toDataURL("image/png"));
    //   };
    // });
  },
  methods: {
    changePrice: function changePrice(val) {
      console.log(val);
      if (val) {
        var price = (val + '').match(/\d+(\.)?(\d{0,2})?/);
        console.log(price);
        if (price) {
          this.formValidate.sale_price = price[0];
        } else {
          this.formValidate.sale_price = '';
        }
      }
    },
    getBoxDetail: function getBoxDetail(id) {
      var _this3 = this;
      (0, _nft.getBoxDetail)(id).then(function (res) {
        var data = res.data;
        var imagearr = data.box_image.split(",");
        _this3.formValidate = {
          image: data.image || imagearr[0],
          leftimage: [imagearr[0]],
          rightimage: imagearr[1] ? [imagearr[1]] : '',
          topimage: imagearr[2] ? [imagearr[2]] : '',
          title: data.title,
          box_image: data.box_image,
          sale_time: data.sale_time,
          sale_price: data.sale_price,
          total_number: data.total_number,
          // sale_number: data.sale_number,
          reserved_number: data.reserved_number,
          limit_buy_number: data.limit_buy_number,
          transfer_config_id: data.transfer_config_id || '',
          is_show_total_number: data.is_show_total_number + '' || '1',
          content: ''
        };
        if (data.product && data.product.content && data.product.content.content) {
          _this3.formValidate.content = data.product.content.content;
        }
        if (data.issue_status == 1) {
          _this3.disabled = true;
        } else {
          _this3.disabled = false;
        }
        // data.boxProducts.forEach(item=>{
        //     this.orderidArr.push(item.product_id);
        //     item.issale = item.is_show == 1 ? "0" : "1";
        //     item.is_hide = item.is_hide+'';
        //     item.product = {
        //         image: item.image,
        //         stock: item.stock,
        //         store_name: item.store_name,
        //     }
        // });
        // console.log(data.boxProducts)
        // this.orderArr = data.boxProducts
      });
    },
    getselectList: function getselectList(num) {
      var _this4 = this;
      this.sellistLoading = true;
      this.tabelform.page = num || this.tabelform.page;
      this.tabelform.box_id = this.nftid;
      (0, _nft.getBoxGoodsList)(this.tabelform).then(function (res) {
        res.data.list.forEach(function (item) {
          _this4.orderidArr.push(item.product_id);
          item.issale = item.product.mer_status == 1 ? "0" : "1";
          item.is_hide = item.is_hide + '';
        });
        _this4.selectTableData.data = res.data.list;
        _this4.selectTableData.total = res.data.count;
        _this4.sellistLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this4.sellistLoading = false;
      });
    },
    editBoxGoods: function editBoxGoods(row, index) {
      // if(row.product.stock < row.stock_number){
      //   this.$message.error('投入数量不能大于库存数量');
      //   return;
      // }
      console.log(index);
      var json = {
        box_id: this.nftid,
        id: row.id,
        hide_image: row.hide_image,
        op_mer_status: row.issale == "0" ? 1 : 0,
        is_hide: row.is_hide,
        stock_number: row.stock_number
      };
      (0, _nft.editBoxGoods)(json).then(function (res) {
        // this.$message.success(res.message);
        // this.getselectList();
      });
      // this.formValidate.box_products = this.orderArr.map(item=>{
      //     return {
      //         product_id: item.product_id,
      //         product_collection_id: item.product_collection_id,
      //         original_product_is_show: item.issale == 1 ? 0 : 1,
      //         stock_number: item.stock_number,
      //         is_hide:item.is_hide,
      //         hide_image:item.hide_image,
      //     }
      // })
    },
    editBoxGoodsAll: function editBoxGoodsAll() {
      var _this5 = this;
      var json = {
        box_id: this.nftid,
        mer_status: this.all.issale == "0" ? 1 : 0
      };
      (0, _nft.editBoxGoodsStatus)(json).then(function (res) {
        _this5.$message.success(res.message);
        _this5.getselectList();
      });
    },
    batchAdd: function batchAdd() {
      var _this6 = this;
      (0, _nft.addBoxGoods)({
        box_id: this.nftid,
        type: 2,
        cate_id: this.category[this.category.length - 1]
      }).then(function (res) {
        _this6.$message.success(res.message);
        _this6.getselectList();
      });
    },
    getList: function getList(num) {
      var _this7 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      this.queryParams.nft_box_id = this.nftid;
      (0, _nft.nftCollectionListApi)(this.queryParams).then(function (res) {
        _this7.tableData.data = res.data.list;
        _this7.tableData.total = res.data.count;
        _this7.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this7.listLoading = false;
      });
    },
    goSearch: function goSearch() {
      if (this.category.length > 0) {
        this.queryParams.cate_id = this.category[this.category.length - 1];
      } else {
        this.queryParams.cate_id = '';
      }
      this.getList(1);
    },
    atteLeavetooltip: function atteLeavetooltip(h, _ref) {
      var column = _ref.column,
        $index = _ref.$index;
      return [column.label, h("el-tooltip", {
        props: {
          effect: "dark",
          placement: "top",
          content: "开关打开后，此藏品品在藏品列表中不显示。"
        }
      }, [h("span", {
        class: {
          "el-icon-question": true
        }
      })])];
    },
    submitForm: function submitForm() {
      var _this8 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          // if (!this.formValidate.leftimage) {
          //   this.$message.error("请上传左侧图片");
          //   return;
          // }
          if (!_this8.formValidate.image) {
            _this8.$message.error("请上传封面图片");
            return;
          }
          if (!_this8.formValidate.leftimage) {
            _this8.formValidate.leftimage = [_this8.formValidate.image];
          }
          if (_this8.formValidate.rightimage || _this8.formValidate.topimage) {
            if (!_this8.formValidate.rightimage) {
              _this8.$message.error("请上传右侧图片");
              return;
            }
            if (!_this8.formValidate.topimage) {
              _this8.$message.error("请上传顶部图片");
              return;
            }
            _this8.formValidate.box_image = _this8.formValidate.leftimage[0] + ',' + _this8.formValidate.rightimage[0] + ',' + _this8.formValidate.topimage[0];
          } else {
            _this8.formValidate.box_image = _this8.formValidate.leftimage[0];
          }
          // if (!this.orderidArr.length) {
          //   this.$message.error("请选择藏品");
          //   return;
          // }
          // if(!this.formValidate.image){
          //    this.formValidate.image = this.formValidate.leftimage[0]
          // }
          // this.formValidate.box_products = this.orderArr.map(item=>{
          //     return {
          //         product_id: item.product_id,
          //         product_collection_id: item.product_collection_id,
          //         original_product_is_show: item.issale == 1 ? 0 : 1,
          //         stock_number: item.stock_number,
          //         is_hide:item.is_hide,
          //         hide_image:item.hide_image,
          //     }
          // })
          var loading = _this8.$loading({
            lock: true,
            text: "保存中……",
            spinner: "el-icon-loading"
          });
          if (_this8.nftid) {
            (0, _nft.editBox)(_this8.nftid, _this8.formValidate).then(function () {
              loading.close();
              _this8.$message.success("保存成功");
              _this8.$router.back();
            });
            return;
          }
          (0, _nft.createBox)(_this8.formValidate).then(function (res) {
            loading.close();
            _this8.nftid = res.message;
            // this.$message({
            //   message: "保存成功",
            //   type: "success",
            // });
            // this.$router.back();
            // this.$emit("close");
          }).catch(function (res) {
            loading.close();
            // this.$message.error(res.message);
          });
        }
      });
    },
    backAction: function backAction() {
      this.$router.go(-1);
    },
    selectinver: function selectinver(row, index) {
      var _this9 = this;
      if (this.orderidArr.indexOf(row.product_id) > -1) {
        return;
      }
      (0, _nft.addBoxGoods)({
        box_id: this.nftid,
        type: 1,
        productIds: [row.product_id]
      }).then(function (res) {
        _this9.getselectList();
      });
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      var newrow = JSON.parse(JSON.stringify(row));
      newrow.stock_number = newrow.product.stock;
      newrow.issale = false;
      newrow.is_hide = false;
      newrow.hide_image = '';
      this.orderArr.push(newrow);
      this.orderidArr.push(row.product_id);
    },
    del: function del(index, row) {
      var _this10 = this;
      var nindex = this.orderidArr.indexOf(row.product_id);
      this.orderidArr.splice(nindex, 1);
      this.orderArr.splice(nindex, 1);
      (0, _nft.delBoxGoods)({
        box_id: this.nftid,
        id: row.id
      }).then(function (res) {
        _this10.getselectList();
      });
    },
    addissnum: function addissnum(index, row) {
      var _this11 = this;
      var that = this;
      this.$prompt("", "增加数量", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputPlaceholder: "请输入数量",
        inputErrorMessage: "请输入数量",
        inputValue: ''
      }).then(function (_ref2) {
        var value = _ref2.value;
        (0, _nft.addBoxGoodsNum)({
          box_id: _this11.nftid,
          id: row.id,
          num: value
        }).then(function (res) {
          that.$message.success('修改成功');
          that.getselectList();
        });
      }).catch(function () {
        _this11.$message({
          type: "info",
          message: "取消输入"
        });
      });
    },
    cancelSelect: function cancelSelect(index, row) {
      row.selected = false;
      var nindex = this.orderidArr.indexOf(row.product_id);
      this.orderidArr.splice(nindex, 1);
      this.orderArr.splice(nindex, 1);
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          if (num == "top") {
            _this.formValidate.topimage = img;
          } else if (num == "left") {
            _this.formValidate.leftimage = img;
          } else if (num == "right") {
            _this.formValidate.rightimage = img;
          } else if (num == "product") {
            // _this.orderArr[i].hide_image = img[0];
            console.log(_this.selectTableData);
            _this.selectTableData.data[i].hide_image = img[0];
            _this.editBoxGoods(_this.selectTableData.data[i]);
          } else if (num == "showimage") {
            _this.formValidate.image = img[0];
          }
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = '';
    }
  }
};