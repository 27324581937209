var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    _vm.numberVisible = true
                  },
                },
              },
              [_vm._v("办理号牌")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.onGoMJFX },
              },
              [_vm._v("买家分析")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    _vm.scannVisible = true
                  },
                },
              },
              [_vm._v("扫码办牌")]
            ),
            _vm._v(" "),
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "merchantSystemExcelExport:pmUserAnalysis",
                    expression: "'merchantSystemExcelExport:pmUserAnalysis'",
                  },
                ],
                attrs: { type: "primary", placement: "bottom" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "primary", size: "small" },
                  },
                  [_vm._v("导出")]
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: { click: _vm.exportOrder },
                          },
                          [_vm._v("导出")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.getExportFileList },
                          },
                          [_vm._v("导出记录")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.twoNum > 0
        ? _c(
            "el-radio-group",
            {
              attrs: { type: "button" },
              on: { change: _vm.statusChange },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "0" } }, [
                _vm._v("号牌列表"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v("扫码办牌"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.twoNum > 0
        ? _c(
            "div",
            { staticStyle: { height: "calc(100vh - 230px)" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.status == 0,
                      expression: "status == 0",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "auctionRef",
                  staticClass: "mt20 ft12",
                  attrs: {
                    data: _vm.list,
                    height: "100%",
                    "element-loading-text": "Loading",
                    fit: "",
                    border: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "头像",
                      width: "50",
                      align: "center",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: {
                                  width: "25px",
                                  height: "25px",
                                  "margin-top": "5px",
                                },
                                attrs: {
                                  src: scope.row.user.avatar
                                    ? scope.row.user.avatar
                                    : "",
                                  alt: "",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2837129083
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "姓名",
                      width: "150",
                      prop: "",
                      "search-key": "keyword",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetails(scope.row.user.uid)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.user.real_name ||
                                          scope.row.user.nickname
                                      ) +
                                      "\n              "
                                  ),
                                  scope.row.user.real_name
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(scope.row.user.nickname) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1429771978
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "手机号",
                      width: "150",
                      prop: "",
                      "search-key": "phone",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [_vm._v(_vm._s(scope.row.user.phone))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2690906994
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "号牌",
                      width: "60",
                      prop: "",
                      "search-key": "number",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "key-point" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.number ? scope.row.number : ""
                                      ) +
                                      " "
                                  ),
                                  _vm.type == 2
                                    ? [_vm._v("(只展示效果)")]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2649100527
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "冻结保证金",
                      width: "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.userAccount && _vm.status == 0
                                ? _c("div", { staticClass: "key-point" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          scope.row.userAccount.frozen_balance
                                        ) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4071312167
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "账户余额",
                      width: "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.userAccount && _vm.status == 0
                                ? _c("div", { staticClass: "key-point" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.userAccount.balance) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3872094066
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "办牌方式",
                      width: "100",
                      prop: "",
                      "search-key": "source_type",
                      "search-type": "radio",
                      options: [
                        { label: "网络办理", value: "1" },
                        { label: "扫码办理", value: "2" },
                        { label: "后台办理", value: "3" },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.source_type) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1943319175
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "号牌类型",
                      width: "120",
                      prop: "",
                      "search-key": "number_type",
                      "search-type": "radio",
                      options: [
                        { label: "普通号牌", value: "1" },
                        { label: "特殊号牌", value: "2" },
                        { label: "免保号牌", value: "3" },
                        { label: "VIP号牌", value: "5" },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.number_type)),
                              ]),
                              _vm._v(" "),
                              scope.row.number_type == "普通号牌"
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "ft12",
                                      attrs: {
                                        type: "text",
                                        loading: _vm.btnLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editUserNumber(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      269039185
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "保证金类型",
                      width: "120",
                      prop: "",
                      "search-key": "bond_type",
                      "search-type": "radio",
                      options: [
                        { label: "免保", value: "4" },
                        { label: "比例", value: "0" },
                        { label: "拍卖会", value: "3" },
                        { label: "专场", value: "2" },
                        { label: "拍品", value: "1" },
                        { label: "VIP拍品", value: "5" },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.bondTypeText) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      673535679
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "参拍范围",
                      "min-width": "140",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  scope.row.bondInfo
                                    ? _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.bondInfo.album_bond_names
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.number_type == "免保号牌"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onOpenRange(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("修改")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2786122683
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "办牌人员",
                      width: "120",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.operation_name) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1613838512
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "办牌时间",
                      width: "160",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "key-point" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.create_time) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3166066511
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "备注",
                      "min-width": "120",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.remarks) +
                                  "\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4036243244
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    key: "20",
                    attrs: {
                      permission: "merchantAuctionNumberPlateDelUserNumber",
                      label: "操作",
                      width: "150",
                      fixed: "right",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.number_type == "普通号牌" &&
                              (scope.row.source_type == "后台办理" ||
                                scope.row.source_type == "扫码办理")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-printer",
                                        type: "text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPrint(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("重打")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "red !important" },
                                  attrs: {
                                    icon: "el-icon-delete",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delUserNumber(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      396992855
                    ),
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.status == 1,
                      expression: "status == 1",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "smRef",
                  staticClass: "mt20 ft12",
                  attrs: {
                    data: _vm.list,
                    height: "100%",
                    "element-loading-text": "Loading",
                    fit: "",
                    border: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "头像",
                      width: "50",
                      align: "center",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: {
                                  width: "25px",
                                  height: "25px",
                                  "margin-top": "5px",
                                },
                                attrs: {
                                  src: scope.row.user.avatar
                                    ? scope.row.user.avatar
                                    : "",
                                  alt: "",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2837129083
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "姓名",
                      width: "155",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetails(scope.row.user.uid)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.user.real_name ||
                                          scope.row.user.nickname
                                      ) +
                                      "\n              "
                                  ),
                                  scope.row.user.real_name
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(scope.row.user.nickname) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1429771978
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "手机号",
                      width: "150",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [_vm._v(_vm._s(scope.row.user.phone))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2690906994
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "申请时间",
                      "min-width": "150",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(_vm._s(scope.row.create_time)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3407778047
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "办牌状态",
                      "min-width": "120",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status == 1 ? "已办" : "未办"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3861410793
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    key: "20",
                    attrs: {
                      label: "操作",
                      width: "80",
                      fixed: "right",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status == 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-postcard",
                                        type: "text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onUpd(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("办牌")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3246895275
                    ),
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticStyle: { height: "calc(100vh - 180px)" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "auctionRef",
                  staticClass: "mt20 ft12",
                  attrs: {
                    data: _vm.list,
                    height: "100%",
                    "element-loading-text": "Loading",
                    fit: "",
                    border: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "tableEmpty" }, [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "tableDiv" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "头像",
                      width: "50",
                      align: "center",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticStyle: {
                                width: "25px",
                                height: "25px",
                                "margin-top": "5px",
                              },
                              attrs: {
                                src: scope.row.user.avatar
                                  ? scope.row.user.avatar
                                  : "",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "姓名",
                      width: "150",
                      prop: "",
                      "search-key": "keyword",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(scope.row.user.uid)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.user.real_name ||
                                        scope.row.user.nickname
                                    ) +
                                    "\n              "
                                ),
                                scope.row.user.real_name
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(scope.row.user.nickname) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "手机号",
                      width: "150",
                      prop: "",
                      "search-key": "phone",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.user.phone))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "号牌",
                      width: "60",
                      prop: "",
                      "search-key": "number",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "key-point" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.number ? scope.row.number : ""
                                    ) +
                                    " "
                                ),
                                _vm.type == 2
                                  ? [_vm._v("(只展示效果)")]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "冻结保证金",
                      width: "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.userAccount && _vm.status == 0
                              ? _c("div", { staticClass: "key-point" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.userAccount.frozen_balance
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "账户余额",
                      width: "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.userAccount && _vm.status == 0
                              ? _c("div", { staticClass: "key-point" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.userAccount.balance) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "办牌方式",
                      width: "100",
                      prop: "",
                      "search-key": "source_type",
                      "search-type": "radio",
                      options: [
                        { label: "网络", value: "1" },
                        { label: "现场自助", value: "2" },
                        { label: "现场人工", value: "3" },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.source_type) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "办牌时间",
                      width: "160",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "key-point" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.create_time) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "号牌类型",
                      width: "120",
                      prop: "",
                      "search-key": "number_type",
                      "search-type": "radio",
                      options: [
                        { label: "普通号牌", value: "1" },
                        { label: "特殊号牌", value: "2" },
                        { label: "免保号牌", value: "3" },
                        { label: "现场号牌", value: "4" },
                        { label: "VIP号牌", value: "5" },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.number_type))]),
                            _vm._v(" "),
                            scope.row.number_type == "普通号牌"
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "ft12",
                                    attrs: {
                                      type: "text",
                                      loading: _vm.btnLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editUserNumber(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("免保")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "免保时长",
                      width: "120",
                      prop: "",
                      "search-key": "exempt_bond_time",
                      "search-type": "radio",
                      options: [
                        { label: "长期", value: "1" },
                        { label: "本场", value: "2" },
                      ],
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.exempt_bond_time) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "免保范围",
                      "min-width": "140",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                scope.row.bondInfo
                                  ? _c("div", { staticClass: "nowrap" }, [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.bondInfo.album_bond_names
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.number_type == "免保号牌"
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onOpenRange(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "办牌人员",
                      width: "120",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.operation_name) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "备注",
                      "min-width": "120",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.remarks) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    key: "20",
                    attrs: {
                      permission: "merchantAuctionNumberPlateDelUserNumber",
                      label: "操作",
                      width: "80",
                      fixed: "right",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "red !important" },
                                attrs: { icon: "el-icon-delete", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delUserNumber(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [10, 20, 40, 60, 80],
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.numberVisible, width: "720px" },
          on: {
            "update:visible": function ($event) {
              _vm.numberVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex", staticStyle: { margin: "20px 0" } },
            [
              _c(
                "div",
                { staticStyle: { width: "180px", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.onGoXCBP(1)
                        },
                      },
                    },
                    [_vm._v("普通号牌")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "5px",
                      },
                    },
                    [_vm._v("本次拍卖会：指定专场")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "180px", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.onGoXCBP(5)
                        },
                      },
                    },
                    [_vm._v("VIP号牌")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "5px",
                      },
                    },
                    [_vm._v("本次拍卖会：含VIP拍品")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "180px", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.MBBut("3", "新增免保号牌")
                        },
                      },
                    },
                    [_vm._v("免保号牌")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "5px",
                      },
                    },
                    [_vm._v("自定义范围和时长")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "180px", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.MBBut("2", "新增特殊号牌")
                        },
                      },
                    },
                    [_vm._v("特殊号牌")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "5px",
                      },
                    },
                    [_vm._v("自定义范围和时长")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "扫码自助办牌",
            visible: _vm.scannVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.scannVisible = $event
            },
          },
        },
        [
          _vm.scanningQRCode
            ? _c("div", { staticClass: "handCard" }, [
                _c("div", { staticClass: "item-flex-center erbox" }, [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.scanningQRCode },
                  }),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人工录入",
            visible: _vm.showUserSearch,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUserSearch = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入客户真实姓名" },
            model: {
              value: _vm.uname,
              callback: function ($$v) {
                _vm.uname = $$v
              },
              expression: "uname",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c justify-c mt40" },
            [
              _c("el-button", [_vm._v("取消")]),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v("\n          确认\n        "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改免保范围",
            visible: _vm.butVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.butVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "album-filter flex align-items-c mt10" }, [
            _c("span", { staticClass: "w100 mt10" }, [_vm._v("参拍专场：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.onCHange },
                    model: {
                      value: _vm.stateForm.change_album_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.stateForm, "change_album_type", $$v)
                      },
                      expression: "stateForm.change_album_type",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 0 } }, [
                      _c(
                        "span",
                        { staticStyle: { width: "65px", display: "block" } },
                        [_vm._v("全部")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _c(
                        "span",
                        { staticStyle: { width: "65px", display: "block" } },
                        [_vm._v("选择专场")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.stateForm.change_album_type === 1
            ? _c(
                "div",
                { staticClass: "album-filter flex align-items-c mt10" },
                [
                  _c("span", { staticClass: "w100 mt10" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flex-one" },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.stateForm.number_plate_rule,
                            callback: function ($$v) {
                              _vm.$set(_vm.stateForm, "number_plate_rule", $$v)
                            },
                            expression: "stateForm.number_plate_rule",
                          },
                        },
                        _vm._l(_vm.albumList, function (m, i) {
                          return _c(
                            "el-checkbox-button",
                            { key: i, attrs: { label: m.album_id } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(m.album_name) +
                                  " " +
                                  _vm._s(m.auction_num) +
                                  "件\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.showAddNumber.tit,
            visible: _vm.showAddNumber.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showAddNumber, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-item-center mt20" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.showAddNumber.MBType,
                    callback: function ($$v) {
                      _vm.$set(_vm.showAddNumber, "MBType", $$v)
                    },
                    expression: "showAddNumber.MBType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "3" } }, [
                    _vm._v(
                      "本场" +
                        _vm._s(
                          _vm.showAddNumber.number_type == "1" ? "免保" : ""
                        ) +
                        " "
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-top": "5px",
                          "margin-left": "22px",
                        },
                      },
                      [_vm._v("本次拍卖会")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v(
                      "长期" +
                        _vm._s(
                          _vm.showAddNumber.number_type == "1" ? "免保" : ""
                        ) +
                        " "
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "margin-top": "5px",
                          "margin-left": "22px",
                        },
                      },
                      [_vm._v("未来所有拍卖会")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("客户名称：")]),
              _vm._v(" "),
              _vm.showAddNumber.user
                ? _c("div", [_vm._v(_vm._s(_vm.showAddNumber.user.nickname))])
                : _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: {
                        placeholder: "搜索客户昵称",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      on: { change: _vm.onUserCHange },
                      model: {
                        value: _vm.showAddNumber.uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.showAddNumber, "uid", $$v)
                        },
                        expression: "showAddNumber.uid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", { attrs: { src: m.avatar, alt: "" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "user-r flex-one" }, [
                                _c(
                                  "div",
                                  { staticClass: "line1" },
                                  [
                                    m.real_name
                                      ? [_vm._v(_vm._s(m.real_name) + " | ")]
                                      : _vm._e(),
                                    _vm._v(_vm._s(m.nickname)),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v(_vm._s(m.phone))]),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showAddNumber.number_type == "3"
            ? _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c("span", { staticClass: "w100" }, [_vm._v("设置号牌：")]),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "w200",
                    staticStyle: { "text-align": "left" },
                    attrs: {
                      type: "number",
                      controls: false,
                      placeholder: "例如8888",
                    },
                    model: {
                      value: _vm.showAddNumber.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.showAddNumber, "number", $$v)
                      },
                      expression: "showAddNumber.number",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showAddNumber.MBType == "3"
            ? [
                _c(
                  "div",
                  { staticClass: "album-filter flex align-items-c mt10" },
                  [
                    _c("span", { staticClass: "w100 mt10" }, [
                      _vm._v("参拍专场："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.showAddNumber.change_album_type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.showAddNumber,
                                  "change_album_type",
                                  $$v
                                )
                              },
                              expression: "showAddNumber.change_album_type",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: 0 } }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "65px",
                                    display: "block",
                                  },
                                },
                                [_vm._v("全部")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-radio-button", { attrs: { label: 1 } }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "65px",
                                    display: "block",
                                  },
                                },
                                [_vm._v("选择专场")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.showAddNumber.change_album_type === 1
                  ? _c(
                      "div",
                      { staticClass: "album-filter flex align-items-c mt10" },
                      [
                        _c("span", { staticClass: "w100 mt10" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-one" },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.showAddNumber.number_plate_rule,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.showAddNumber,
                                      "number_plate_rule",
                                      $$v
                                    )
                                  },
                                  expression: "showAddNumber.number_plate_rule",
                                },
                              },
                              _vm._l(_vm.albumList, function (m, i) {
                                return _c(
                                  "el-checkbox-button",
                                  { key: i, attrs: { label: m.album_id } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(m.album_name) +
                                        " " +
                                        _vm._s(m.auction_num) +
                                        "件\n              "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "flex align-items-c mt20" },
                  [
                    _c("span", { staticClass: "w100" }, [_vm._v("有效期：")]),
                    _vm._v(" "),
                    _c("el-date-picker", {
                      staticStyle: { width: "300px" },
                      attrs: {
                        type: "date",
                        "value-format": "timestamp",
                        clearable: false,
                        placeholder: "结束日期",
                      },
                      model: {
                        value: _vm.showAddNumber.expire_time,
                        callback: function ($$v) {
                          _vm.$set(_vm.showAddNumber, "expire_time", $$v)
                        },
                        expression: "showAddNumber.expire_time",
                      },
                    }),
                  ],
                  1
                ),
              ],
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "album-filter flex mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("备注:")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w300",
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "请输入备注内容",
                },
                model: {
                  value: _vm.showAddNumber.remarks,
                  callback: function ($$v) {
                    _vm.$set(_vm.showAddNumber, "remarks", $$v)
                  },
                  expression: "showAddNumber.remarks",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showAddNumber.btnLoading,
                  },
                  on: { click: _vm.saveAddNumberAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.showAddNumber.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加号牌组",
            visible: _vm.showAddNumberGroup.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showAddNumberGroup, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("号牌组名称：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { placeholder: "输入名称" },
                model: {
                  value: _vm.showAddNumberGroup.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.showAddNumberGroup, "name", $$v)
                  },
                  expression: "showAddNumberGroup.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showAddNumberGroup.btnLoading,
                  },
                  on: { click: _vm.saveNumberGroupAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { staticClass: "ml20" }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加号牌",
            visible: _vm.showBatchAddNumber.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showBatchAddNumber, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("输入号段：")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w100",
                attrs: { min: 0, controls: false, placeholder: "最小号段" },
                model: {
                  value: _vm.showBatchAddNumber.min,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "min", $$v)
                  },
                  expression: "showBatchAddNumber.min",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml10 mr10" }, [_vm._v("至")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w100",
                attrs: { min: 0, controls: false, placeholder: "最大号段" },
                model: {
                  value: _vm.showBatchAddNumber.max,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "max", $$v)
                  },
                  expression: "showBatchAddNumber.max",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("排除数字：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w300",
                attrs: { placeholder: "输入数字(多个,分隔 如：4,7)" },
                model: {
                  value: _vm.showBatchAddNumber.filter_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.showBatchAddNumber, "filter_number", $$v)
                  },
                  expression: "showBatchAddNumber.filter_number",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.showBatchAddNumber.btnLoading,
                  },
                  on: { click: _vm.batchAddNumberAction },
                },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c("el-button", { staticClass: "ml20" }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看号牌",
            visible: _vm.numberList.show,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.numberList, "show", $event)
            },
          },
        },
        [
          _vm.numberList.query
            ? _c(
                "div",
                { staticClass: "list" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.numberList.listLoading,
                          expression: "numberList.listLoading",
                        },
                      ],
                      staticClass: "mt20 ft12",
                      attrs: {
                        data: _vm.numberList.list,
                        "element-loading-text": "Loading",
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c("div", { staticClass: "tableEmpty" }, [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tableDiv" }, [
                            _vm._v("暂无数据"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: "1",
                        attrs: { label: "号牌", prop: "name" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.row.num) +
                                        "\n              "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2459134823
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: "2",
                        attrs: { label: "号牌类型", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          scope.row.type == 1
                                            ? "普通号牌"
                                            : "特殊号牌"
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          583370106
                        ),
                      }),
                      _vm._v(" "),
                      [
                        _c("el-table-column", {
                          key: "3",
                          attrs: { label: "创建时间", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(scope.row.create_time) +
                                          "\n                "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            193064351
                          ),
                        }),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20 flex justify-e" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-size": _vm.numberList.query.limit,
                          "current-page": _vm.numberList.query.page,
                          layout: "total, prev, pager, next, jumper",
                          total: _vm.numberList.total,
                        },
                        on: { "current-change": _vm.pageChangeNumber },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            center: "",
            title: "查看图片",
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("CompetePrint", { ref: "compete" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }