"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBoxGoods = addBoxGoods;
exports.addBoxGoodsNum = addBoxGoodsNum;
exports.batchDestroyApi = batchDestroyApi;
exports.batchRecoveryApi = batchRecoveryApi;
exports.createBox = createBox;
exports.dataListApi = dataListApi;
exports.delBox = delBox;
exports.delBoxGoods = delBoxGoods;
exports.destroyListApi = destroyListApi;
exports.editBox = editBox;
exports.editBoxGoods = editBoxGoods;
exports.editBoxGoodsStatus = editBoxGoodsStatus;
exports.editBoxIssueStatus = editBoxIssueStatus;
exports.editBoxStatus = editBoxStatus;
exports.editOrderNumApi = editOrderNumApi;
exports.getBoxDetail = getBoxDetail;
exports.getBoxGoodsList = getBoxGoodsList;
exports.getBoxList = getBoxList;
exports.nftAddIssueNum = nftAddIssueNum;
exports.nftCollectionAlbumCreateApi = nftCollectionAlbumCreateApi;
exports.nftCollectionAlbumDelApi = nftCollectionAlbumDelApi;
exports.nftCollectionAlbumDetailApi = nftCollectionAlbumDetailApi;
exports.nftCollectionAlbumFilterApi = nftCollectionAlbumFilterApi;
exports.nftCollectionAlbumListApi = nftCollectionAlbumListApi;
exports.nftCollectionAlbumUpdateApi = nftCollectionAlbumUpdateApi;
exports.nftCollectionBatchIssueApi = nftCollectionBatchIssueApi;
exports.nftCollectionCreateApi = nftCollectionCreateApi;
exports.nftCollectionInfoApi = nftCollectionInfoApi;
exports.nftCollectionIssueApi = nftCollectionIssueApi;
exports.nftCollectionListApi = nftCollectionListApi;
exports.nftCollectionUpdateApi = nftCollectionUpdateApi;
exports.recoveryListApi = recoveryListApi;
exports.recoveryNftTransfersApi = recoveryNftTransfersApi;
exports.transferBlindNftApi = transferBlindNftApi;
exports.transferCreateApi = transferCreateApi;
exports.transferDelApi = transferDelApi;
exports.transferListApi = transferListApi;
exports.transferUpdateApi = transferUpdateApi;
exports.transfersListApi = transfersListApi;
var _request = _interopRequireDefault(require("./request"));
/**
  * @description 列表
  */
function nftCollectionListApi(data) {
  return _request.default.get("store/nftCollection/list", data);
}
/**
  * @description 系列列表
  */
function nftCollectionAlbumListApi(data) {
  return _request.default.get("store/nftCollection/album/list", data);
}
/**
  * @description 系列类型nft数量
  */
function nftCollectionAlbumFilterApi(data) {
  return _request.default.get("store/nftCollection/album/statusFilter", data);
}
/**
  * @description 系列详情
  */
function nftCollectionAlbumDetailApi(id) {
  return _request.default.get("store/nftCollection/album/info/".concat(id));
}

/**
  * @description 创建系列
  */
function nftCollectionAlbumCreateApi(id, data) {
  return _request.default.post("store/nftCollection/album/create", data);
}

/**
  * @description 修改系列
  */
function nftCollectionAlbumUpdateApi(id, data) {
  return _request.default.post("store/nftCollection/album/update/".concat(id), data);
}

/**
  * @description 删除系列
  */
function nftCollectionAlbumDelApi(id) {
  return _request.default.post("store/nftCollection/album/del/".concat(id));
}

/**
  * @description nft上链数据列表
  */
function dataListApi(data) {
  return _request.default.get("nft/dataList", data);
}

/**
  * @description 赠送列表
  */
function transfersListApi(data) {
  return _request.default.get("nft/transfersList", data);
}

/**
  * @description 详情
  */
function nftCollectionInfoApi(id) {
  return _request.default.get("store/nftCollection/info/".concat(id));
}

/**
  * @description 修改排序
  */
function editOrderNumApi(id, data) {
  return _request.default.post("store/nftCollection/editOrderNum/".concat(id), data);
}

/**
  * @description 创建
  */
function nftCollectionCreateApi(id, data) {
  return _request.default.post("store/nftCollection/create", data);
}

/**
  * @description 修改
  */
function nftCollectionUpdateApi(id, data) {
  return _request.default.post("store/nftCollection/update/".concat(id), data);
}

/**
  * @description 发行
  */
function nftCollectionIssueApi(id) {
  return _request.default.post("store/nftCollection/issue/".concat(id));
}
/**
  * @description 批量发行
  */
function nftCollectionBatchIssueApi(data) {
  return _request.default.post("store/nftCollection/batchIssue", data);
}
// 获取盲盒详情
function getBoxDetail(id) {
  return _request.default.get("activity/nft/blindBox/detail/".concat(id));
}
// 获取盲盒列表
function getBoxList(data) {
  return _request.default.get('activity/nft/blindBox/lst', data);
}
// 创建盲盒
function createBox(data) {
  return _request.default.post('activity/nft/blindBox/create', data);
}
// 编辑盲盒
function editBox(id, data) {
  return _request.default.post("activity/nft/blindBox/update/".concat(id), data);
}
// 删除盲盒
function delBox(id) {
  return _request.default.delete("activity/nft/blindBox/delete/".concat(id));
}
// 修改盲盒状态
function editBoxStatus(id, data) {
  return _request.default.post("activity/nft/blindBox/switchStatus/".concat(id), data);
}
// 修改盲盒发行状态
function editBoxIssueStatus(id, data) {
  return _request.default.post("activity/nft/blindBox/switchIssueStatus/".concat(id), data);
}
// 盲盒藏品列表
function getBoxGoodsList(data) {
  return _request.default.get("activity/nft/blindBox/product/lst", data);
}
// 数字盲盒添加藏品
function addBoxGoods(data) {
  return _request.default.post("activity/nft/blindBox/product/create", data);
}
// 数字盲盒修改藏品
function editBoxGoods(data) {
  return _request.default.post("activity/nft/blindBox/product/update", data);
}
// 批量修改原商品的状态
function editBoxGoodsStatus(data) {
  return _request.default.post("activity/nft/blindBox/product/batchSwitchMerStatus", data);
}
// 删除盲盒藏品
function delBoxGoods(data) {
  return _request.default.post("activity/nft/blindBox/product/delete", data);
}
// 添加转赠规则
function transferListApi(data) {
  return _request.default.get("nft/transferConfig/lst", data);
}
// 添加转赠规则
function transferCreateApi(id, data) {
  return _request.default.post("nft/transferConfig/create", data);
}
// 修改转赠规则
function transferUpdateApi(id, data) {
  return _request.default.post("nft/transferConfig/update/".concat(id), data);
}
// 删除转赠规则
function transferDelApi(id) {
  return _request.default.post("nft/transferConfig/delete/".concat(id));
}
// 数字藏品增发
function nftAddIssueNum(data) {
  return _request.default.post("store/nftCollection/addIssueNum", data);
}

// nft回收记录列表
function recoveryListApi(data) {
  return _request.default.get("nft/recoveryList", data);
}

// nft销毁记录列表
function destroyListApi(data) {
  return _request.default.get("nft/destroyList", data);
}

// 批量回收nft
function batchRecoveryApi(data) {
  return _request.default.post("nft/batchRecovery", data);
}

// 批量销毁nft
function batchDestroyApi(data) {
  return _request.default.post("nft/batchDestroy", data);
}
// 数字盲盒藏品增加数量
function addBoxGoodsNum(data) {
  return _request.default.post("activity/nft/blindBox/product/addIssueNum", data);
}
// 数字藏品重新投放
function recoveryNftTransfersApi(data) {
  return _request.default.post("nft/recoveryNftTransfers", data);
}
// 转增盲盒
function transferBlindNftApi(data) {
  return _request.default.get("activity/nft/blindBox/transfersList", data);
}