"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _frame = require("@/api/frame");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var activeIndexObj = {
  "1": "frameList",
  "2": "frameType",
  "3": "framePaper",
  "4": "frameScence",
  "5": "frameFrame"
};
var _default = exports.default = {
  name: "frameType",
  data: function data() {
    return {
      pageConfig: {
        page: 1,
        limit: 20
      },
      total: 0,
      type: "1",
      lists: [],
      tagName: ""
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      this.$router.push({
        name: activeIndexObj[index]
      });
    },
    changeType: function changeType(type) {
      this.pageConfig.page = 1;
      this.getList();
    },
    changePageSize: function changePageSize(size) {
      this.pageConfig.limit = size;
      this.pageConfig.page = 1;
      this.getList();
    },
    changePage: function changePage(page) {
      this.pageConfig.page = page;
      /* const newPage = page;
      this.$router.push({ path: this.$route.path, query: { page: newPage } }); */
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      var params = JSON.parse(JSON.stringify(this.pageConfig));
      params.type = this.type;
      if (params.type == 0) params.type = "";
      (0, _frame._getTypeList)(params).then(function (res) {
        if (res.status === 200) {
          _this.lists = res.data.list;
          _this.total = res.data.count;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    addtag: function addtag() {
      var _this2 = this;
      var name = this.tagName.trim();
      var type = this.type;
      this.tagName = "";
      if (!name) {
        return this.$message.warning("请输入类型名称");
      }
      if (type == 0) {
        return this.$message.warning("请选择类型");
      }
      (0, _frame._createType)({
        category_name: name,
        type: type
      }).then(function (res) {
        if (res.status === 200) {
          _this2.$message.success("添加成功");
          _this2.pageConfig.page = 1;
          _this2.getList();
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    deltag: function deltag(row) {
      var _this3 = this;
      (0, _frame._deleteType)({
        id: row.id,
        type: row.type
      }).then(function (res) {
        if (res.status === 200) {
          _this3.$message.success("删除成功");
          _this3.pageConfig.page = 1;
          _this3.getList();
        } else {
          _this3.$message.warning(res.message);
        }
      }).catch(function (err) {
        console.log(err);
        _this3.$message.warning(err.message);
      });
    },
    changeSort: function changeSort(row) {
      var _this4 = this;
      var value = row.sort;
      if (isNaN(value)) {
        this.$message.error("请输入数字");
        this.getList();
        return;
      }
      (0, _frame._ChangeTypeSort)({
        id: row.id,
        sort: value
      }).then(function (res) {
        if (res.status === 200) {
          _this4.$message.success("保存成功");
          _this4.pageConfig.page = 1;
          _this4.getList();
        }
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};