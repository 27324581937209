"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _contract = require("@/api/contract");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (!this.$route.query.ids) {
      this.$message.error("请选择拍品");
      return;
    }
    (0, _contract.createEntrustCheckOrderPreReturnApi)({
      check_auction_ids: this.$route.query.ids
    }).then(function (res) {
      _this.list = res.data;
    });
  },
  methods: {
    createPushOrder: function createPushOrder() {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u521B\u5EFA\u51FA\u5E93\u5355?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _contract.createEntrustCheckOrderReturnApi)({
          check_auction_ids: _this2.$route.query.ids
        }).then(function (res) {
          _this2.$message.success("创建成功");
          _this2.$router.back();
        });
      }).catch(function () {});
    }
  }
};