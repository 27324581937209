"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _user = require("@/api/user.js");
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      uid: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      fee_type: '',
      feeTypeInfo: {},
      isOutbound: false,
      qrcodeImg: '',
      merData: {}
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    }
  },
  filters: {
    filterPercent: function filterPercent(val) {
      var remainder = val % 10;
      if (remainder > 0) val;else return val / 10;
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
  },
  methods: {
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    },
    onPrintOut: function onPrintOut() {
      var _this2 = this;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _order.orderProductCreateOut)({
        order_ids: this.ids,
        uid: this.uid
      }).then(function (res) {
        console.log(res);
        _this2.qrcodeImg = res.data.bar_code_image;
        setTimeout(function () {
          _this2.bindPrintorder();
          loading.close();
        }, 500);
      }).catch(function (err) {
        return loading.close();
      });
    },
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      var that = this;
      setTimeout(function () {
        (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
          printable: "printorder",
          type: "html",
          header: null,
          targetStyles: ["*"],
          scanStyles: true,
          maxWidth: 1000,
          font_size: "auto",
          style: "@page { margin: 5mm 7mm 12mm;size: auto; } .paging{page-break-after: always;}",
          onPrintDialogClose: function onPrintDialogClose() {
            console.log('关闭');
          }
        }, "onPrintDialogClose", function onPrintDialogClose() {
          console.log('pdf');
        }), "onPdfOpen", function onPdfOpen() {
          console.log('pdf1');
        }));
        (0, _order.batchCreate)({
          order_ids: that.collectMoney.order_ids,
          order_type: 'order'
        });
        // that.collectMoney.show = false;
        that.$emit('load');
      }, 500);
    },
    getNewArray: function getNewArray(array) {
      var newArray = [];
      if (array.length > 20) {
        newArray.push(array.slice(0, 20));
        var ffarr = array.slice(20, array.length);
        var aaa = this.getSplArray(ffarr, 26);
        // console.log(aaa);
        aaa.forEach(function (item) {
          newArray.push(item);
        });
      } else newArray.push(array);
      return newArray;
    },
    getSplArray: function getSplArray(array, subGroupLength) {
      // array 代表一个数组
      // subGroupLength 代表数组里面的数组的length值
      var index = 0;
      var newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    loadData: function loadData(ids, uid, source) {
      var _this3 = this;
      this.source = source;
      this.ids = ids;
      this.uid = uid;
      (0, _order.getAuctionOrderBillInfo)({
        order_ids: ids,
        uid: uid
      }).then(function (res) {
        // console.log(res.data);
        var total_price = 0;
        res.data.orderList.forEach(function (m) {
          _this3.fee_type = m.fee_type;
          _this3.feeTypeInfo = m.feeTypeInfo;
          total_price += m.product_price;
          console.log(m.erpProduct);
          if (m.erpProduct && m.erpProduct.warehouse_id > 0 && m.erpProduct.product_warehouse_status == 2) {
            _this3.isOutbound = true;
          }
        });
        var orderLength = res.data.orderList.length;
        res.data.orderList = _this3.getNewArray(res.data.orderList);
        _this3.total_price = total_price;
        _this3.collectMoney = {
          order_ids: ids,
          data: res.data,
          show: true,
          orderLength: orderLength
        };
      }).catch(function () {});
    }
  }
};