"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      btnLoading: false,
      id: '',
      workDetail: {
        title: '',
        image: '',
        start_time: '',
        end_time: '',
        content: ''
      },
      rules: {
        title: [{
          required: true,
          message: '请输入作品名称',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请选择封面图',
          trigger: 'change'
        }],
        start_time: [{
          required: true,
          message: '请选择征集开始时间',
          trigger: 'change'
        }],
        end_time: [{
          required: true,
          message: '请选择征集结束时间',
          trigger: 'change'
        }]
      },
      showImage: false,
      imagesKey: ''
    };
  },
  created: function created() {
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.loadDetail();
    }
  },
  methods: {
    loadDetail: function loadDetail() {
      var _this = this;
      (0, _auction.collectDetailApi)(this.id).then(function (res) {
        var d = res.data;
        _this.workDetail = {
          title: d.title,
          image: d.image,
          start_time: Date.parse(new Date(d.start_time)),
          end_time: Date.parse(new Date(d.end_time)),
          content: d.content
        };
        _this.$refs['editor'].setContent(d.content);
      });
    },
    delImgAction: function delImgAction(i) {
      this.workDetail.image = '';
    },
    showImageAction: function showImageAction(img) {
      this.imagesKey = img;
      this.showImage = true;
    },
    changeImg: function changeImg(key) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.workDetail.image = img[0];
        _this2.$refs.ruleForm.validateField(['image'], function (Error) {
          if (!Error) {
            // 验证通过
            console.log('通过了');
          } else {
            console.log('验证不通过');
          }
        });
      }, '1', '1');
    },
    setData: function setData(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/ordernumable/issues/1012
      dataTransfer.setData('Text', '');
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this3.workDetail));
          data.end_time = _this3.workDetail.end_time / 1000;
          data.start_time = _this3.workDetail.start_time / 1000;
          data.status = 0;
          var loading = _this3.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _auction.collectCreateApi;
          if (_this3.id) {
            action = _auction.collectUpdateApi;
          }
          action(_this3.id, data).then(function () {
            loading.close();
            _this3.$message.success('操作成功');
            _this3.$router.back();
          }).catch(function (msg) {
            loading.close();
            _this3.$message.error(msg);
          });
        } else {
          return false;
        }
      });
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};