"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _review = require("@/api/review");
var _user = require("@/api/user");
var _settings = require("@/settings");
var _system = require("@/api/system");
var _setting = require("@/api/setting");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _marketing = require("@/api/marketing");
var _userdetail = _interopRequireDefault(require("@/views/user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import retaildetail from './detail';
var _default = exports.default = {
  name: "retail",
  components: {
    fileList: _fileList.default,
    userdetail: _userdetail.default
    // retaildetail
  },
  data: function data() {
    return {
      moren: require('@/assets/images/f.png'),
      showconfig: false,
      logisticsName: "refund",
      id: 0,
      type: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        username: "",
        type: 1,
        date: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: ""
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      tableFromLog: {
        page: 1,
        limit: 20
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      orderDatalist: null,
      invoiceInfo: {},
      invoiceData: {
        number: "",
        mark: ""
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      showreview: false,
      mask: "",
      showadd: false,
      adduid: "",
      userList: [],
      userid: "",
      adminarr: [],
      addrestatus: "",
      searchLoading: false,
      showdetail: false,
      showretaildetail: false,
      changestatusflag: false,
      restatus: "",
      dtotal: {},
      sendflag: false,
      sendJson: {
        delivery_name: '',
        delivery_no: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    showsend: function showsend(row) {
      this.row = row;
      this.sendflag = true;
      this.sendJson = {
        delivery_name: '',
        delivery_no: ''
      };
    },
    cursorSend: function cursorSend() {
      var _this = this;
      var json = JSON.parse(JSON.stringify(this.sendJson));
      json.exchange_id = this.row.exchange_id;
      (0, _marketing.sendExchangeGoods)(json).then(function () {
        _this.$message.success('操作成功');
        _this.sendflag = false;
        _this.getList();
      });
    },
    getadminList: function getadminList() {
      var _this2 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this2.adminarr = res.data.list;
      });
    },
    changeretailstatus: function changeretailstatus(row) {
      var _this3 = this;
      this.$confirm("确定解除分销吗？").then(function () {
        (0, _marketing.distributorSwitchStatusApi)(row.salesman_id, {
          status: 1
        }).then(function (res) {
          _this3.getList();
        });
      });
    },
    retailstatus: function retailstatus(row) {
      this.restatus = '0';
      this.rerow = row;
      this.changestatusflag = true;
    },
    handlechangestatus: function handlechangestatus() {
      var _this4 = this;
      this.changestatusflag = false;
      (0, _marketing.distributorSwitchStatusApi)(this.rerow.salesman_id, {
        status: 0,
        is_new_start: this.restatus
      }).then(function (res) {
        _this4.getList();
      });
    },
    delretail: function delretail(row) {
      var _this5 = this;
      this.$confirm("确定删除该分销员吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _marketing.distributorDeleteApi)(row.salesman_id).then(function (res) {
          _this5.$message({
            type: "success",
            message: "删除成功"
          });
          _this5.getList();
        });
      });
    },
    showteamDetail: function showteamDetail(row) {
      this.showretaildetail = true;
      this.uid = row.uid;
    },
    changeadduser: function changeadduser() {
      this.showadd = true;
      this.adduid = "";
      this.userid = "";
      this.addrestatus = "1";
      this.searchUserAction();
    },
    searchUserAction: function searchUserAction(data) {
      var _this6 = this;
      var params = {
        keyword: data,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this6.userList = res.data.list;
      });
    },
    handleadd: function handleadd() {
      var _this7 = this;
      var params = {
        uid: this.adduid,
        bind_admin_id: this.userid || '',
        type: this.addrestatus
      };
      (0, _marketing.distributorCreateApi)(params).then(function (res) {
        _this7.showadd = false;
        _this7.getList(1);
      });
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    retaildetailclose: function retaildetailclose() {
      this.showretaildetail = false;
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    revieworder: function revieworder(row) {
      this.handlerow = row;
      this.showreview = true;
      this.mask = "";
    },
    handlereview: function handlereview(state) {
      var _this8 = this;
      if (state == -1 && !this.mask) {
        this.$message.error("请填写拒绝备注");
        return;
      }
      (0, _review.review)(this.handlerow.audit_id, {
        status: state,
        remark: this.mask
      }).then(function (res) {
        _this8.$message.success("操作成功");
        _this8.getList();
        _this8.showreview = false;
      }).catch(function (err) {
        _this8.$message.error(err.message);
      });
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList("");
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList("");
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.join_data = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.join_data = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this9 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _marketing.exchangeGoodsRecords)(this.tableFrom).then(function (res) {
        _this9.orderChartType = res.data.stat;
        _this9.tableData.data = res.data.list;
        _this9.tableData.total = res.data.count;
        _this9.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this9.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    },
    retaildetailrefash: function retaildetailrefash() {
      console.log("刷新");
      this.getList();
    },
    // 导出
    exportRecord: function exportRecord() {
      var _this10 = this;
      this.tableFrom.export_type = 'salesman';
      (0, _system.exportexcel)(this.tableFrom).then(function (res) {
        /*this.$message.success(res.message);
        this.$refs.exportList.exportFileList();*/
        var h = _this10.$createElement;
        _this10.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this10.$msgbox.close();
                _this10.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 导出列表
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList('salesman');
    }
  }
};