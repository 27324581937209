"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _warehouse = require("@/api/warehouse");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "stokcheck",
  data: function data() {
    return {
      showconfig: false,
      id: 0,
      type: "",
      showcreate: false,
      time: "",
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        username: "",
        type: "",
        page: 1,
        limit: 20,
        receipt_sn: "",
        order_type: "",
        keyword: "",
        status: "",
        task_id: this.$route.query.id || ''
      },
      orderChartType: {},
      timeVal: [],
      multipleSelection: [],
      selectionList: [],
      loading: false
    };
  },
  mounted: function mounted() {
    this.getList(1);
    // this.getadminList();
    // this.getstore();
  },
  methods: {
    changeTime: function changeTime(e) {
      console.log(e);
    },
    // 订单详情
    onOrderDetail: function onOrderDetail(order_id) {
      this.$router.push({
        name: "OrderList",
        query: {
          order_id: order_id
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑
    onOrderMark: function onOrderMark(id) {
      // this.$modalForm(invoiceorderMarkApi(id)).then(() => this.getList(''))
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _warehouse.getcheckrecordlist)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    }
  }
};