var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
      },
    },
    [
      _c("section", { style: _vm.personalizedDecorationOutsideStyle }, [
        _c("section", { style: _vm.personalizedDecorationStyle }, [
          _c(
            "section",
            {
              style: {
                padding:
                  _vm.facade.pagePaddingTop +
                  "px " +
                  _vm.facade.pagePaddingRight +
                  "px " +
                  _vm.facade.pagePaddingBottom +
                  "px " +
                  _vm.facade.pagePaddingLeft +
                  "px",
              },
            },
            [
              _vm.facade.showVenueName
                ? _c(
                    "section",
                    {
                      style: {
                        marginTop: _vm.facade.venueNameMarginTop + "px",
                        marginBottom: _vm.facade.venueNameMarginBottom + "px",
                        display: "flex",
                        justifyContent: "center",
                      },
                    },
                    [
                      _c(
                        "section",
                        {
                          style:
                            "padding: 5px 10px;background: " +
                            _vm.facade.venueNameBackground +
                            ";color: " +
                            _vm.facade.venueNameColor +
                            ";font-size: " +
                            _vm.facade.venueNameFontSize +
                            "px;font-weight: " +
                            _vm.facade.venueNameFontWeight +
                            ";",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.facade.venueName) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.viewData, function (m, i) {
                return _c(
                  "section",
                  {
                    key: i,
                    style: {
                      backgroundColor: _vm.facade.pageBackground,
                      paddingTop:
                        (i == 0 ? 0 : _vm.facade.itemPaddingTop) + "px",
                      borderRadius:
                        _vm.facade.pageRadiusTopLeft +
                        "px " +
                        _vm.facade.pageRadiusTopRight +
                        "px " +
                        _vm.facade.pageRadiusBottomRight +
                        "px " +
                        _vm.facade.pageRadiusBottomLeft +
                        "px",
                      color: _vm.facade.pageColor,
                      fontSize: _vm.facade.fontSize + "px",
                      fontWeight: "" + _vm.facade.fontWeight,
                      display: "flex",
                      flexDirection: m.image_show_style == 2 ? "row" : "column",
                    },
                  },
                  [
                    !_vm.facade.noShowImg
                      ? _c(
                          "section",
                          {
                            style: {
                              overflow: "hidden",
                              borderRadius:
                                _vm.facade.imgRadiusTopLeft +
                                "px " +
                                _vm.facade.imgRadiusTopRight +
                                "px " +
                                _vm.facade.imgRadiusBottomRight +
                                "px " +
                                _vm.facade.imgRadiusBottomLeft +
                                "px",
                              flex: m.image_show_style == 2 ? 1 : "unset",
                            },
                          },
                          [
                            m.id && _vm.miniAppid
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "weapp_image_link",
                                    attrs: {
                                      "data-miniprogram-appid": _vm.miniAppid,
                                      "data-miniprogram-path":
                                        "pages/albumDetail/index?id=" + m.id,
                                      "data-miniprogram-nickname": _vm.miniName,
                                      href: "",
                                      "data-miniprogram-type": "image",
                                      "data-miniprogram-servicetype": "2",
                                      "wah-hotarea": "click",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "rich_pages wxw-img",
                                      staticStyle: {
                                        display: "block",
                                        width: "100%",
                                      },
                                      attrs: {
                                        "data-ratio": "1",
                                        "data-s": "300,640",
                                        "data-fail": "0",
                                        "data-src": m.image,
                                        src: m.image,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _c("img", {
                                  staticStyle: {
                                    display: "block",
                                    width: "100%",
                                  },
                                  attrs: { src: m.image, alt: "" },
                                }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "section",
                      {
                        style: {
                          display: m.image_show_style == 2 ? "flex" : "block",
                          flexDirection:
                            m.image_show_style == 2 ? "column" : "unset",
                          justifyContent:
                            m.image_show_style == 2
                              ? _vm.facade.textItemArea
                              : "unset",
                          textAlign:
                            m.image_show_style == 2 || _vm.facade.noShowImg
                              ? _vm.facade.textAlign
                              : "left",
                          padding:
                            _vm.facade.textPaddingTop +
                            "px " +
                            _vm.facade.textPaddingRight +
                            "px " +
                            _vm.facade.textPaddingBottom +
                            "px " +
                            _vm.facade.textPaddingLeft +
                            "px",
                          backgroundColor: _vm.facade.textBackground,
                          width:
                            m.image_show_style == 2
                              ? _vm.facade.textWidth + "%"
                              : "100%",
                          boxSzing: "border-box",
                          lineHeight: "20px",
                        },
                      },
                      [
                        _c(
                          "section",
                          {
                            style:
                              "color: " +
                              _vm.facade.titleColor +
                              ";font-size: " +
                              _vm.facade.titleFontSize +
                              "px;font-weight: " +
                              _vm.facade.titleFontWeight +
                              ";padding-top: " +
                              (m.image_show_style == 2 &&
                              _vm.facade.textItemArea == "center"
                                ? 0
                                : _vm.facade.titleMarginTop) +
                              "px;",
                          },
                          [
                            m.id && _vm.miniAppid && _vm.facade.noShowImg
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "weapp_text_link",
                                    attrs: {
                                      "data-miniprogram-appid": _vm.miniAppid,
                                      "data-miniprogram-path":
                                        "pages/albumDetail/index?id=" + m.id,
                                      "data-miniprogram-nickname": _vm.miniName,
                                      href: "",
                                      "data-miniprogram-type": "text",
                                      "data-miniprogram-servicetype": "2",
                                      "wah-hotarea": "click",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style:
                                          "color: " +
                                          _vm.facade.titleColor +
                                          ";font-size: " +
                                          _vm.facade.titleFontSize +
                                          "px;font-weight: " +
                                          _vm.facade.titleFontWeight +
                                          ";",
                                      },
                                      [_vm._v(_vm._s(m.name))]
                                    ),
                                  ]
                                )
                              : _c("section", [_vm._v(_vm._s(m.name))]),
                          ]
                        ),
                        _vm._v(" "),
                        m.scheduled_show_time_format
                          ? _c(
                              "section",
                              {
                                style:
                                  "display: " +
                                  (m.image_show_style == 2 ||
                                  _vm.facade.preTimeTextStyle == 1
                                    ? "block"
                                    : "flex") +
                                  ";margin-top:" +
                                  (m.image_show_style == 2 ||
                                  _vm.facade.preTimeTextStyle == 1
                                    ? _vm.facade.preTimeTextMarginTop
                                    : _vm.facade.preTimeMarginTop) +
                                  "px;",
                              },
                              [
                                _vm.facade.preTimeText
                                  ? _c(
                                      "section",
                                      {
                                        style:
                                          "color: " +
                                          _vm.facade.preTimeTextColor +
                                          ";font-size: " +
                                          _vm.facade.preTimeTextFontSize +
                                          "px;font-weight: " +
                                          _vm.facade.preTimeTextFontWeight +
                                          ";",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.facade.preTimeText) +
                                            _vm._s(
                                              m.image_show_style == 2 ||
                                                _vm.facade.timeTextStyle == 1
                                                ? ""
                                                : "："
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "section",
                                  {
                                    style:
                                      "color: " +
                                      _vm.facade.preTimeColor +
                                      ";font-size: " +
                                      _vm.facade.preTimeFontSize +
                                      "px;font-weight: " +
                                      _vm.facade.preTimeFontWeight +
                                      ";margin-top:" +
                                      (m.image_show_style == 2 ||
                                      _vm.facade.preTimeTextStyle == 1
                                        ? _vm.facade.preTimeMarginTop
                                        : 0) +
                                      "px;",
                                  },
                                  [_vm._v(_vm._s(m.scheduled_show_time_format))]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        m.stime_format
                          ? _c(
                              "section",
                              {
                                style:
                                  "display: " +
                                  (m.image_show_style == 2 ||
                                  _vm.facade.timeTextStyle == 1
                                    ? "block"
                                    : "flex") +
                                  ";margin-top:" +
                                  (m.image_show_style == 2 ||
                                  _vm.facade.timeTextStyle == 1
                                    ? _vm.facade.timeTextMarginTop
                                    : _vm.facade.timeMarginTop) +
                                  "px;",
                              },
                              [
                                _vm.facade.timeText
                                  ? _c(
                                      "section",
                                      {
                                        style:
                                          "color: " +
                                          _vm.facade.timeTextColor +
                                          ";font-size: " +
                                          _vm.facade.timeTextFontSize +
                                          "px;font-weight: " +
                                          _vm.facade.timeTextFontWeight +
                                          ";",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.facade.timeText) +
                                            _vm._s(
                                              m.image_show_style == 2 ||
                                                _vm.facade.timeTextStyle == 1
                                                ? ""
                                                : "："
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "section",
                                  {
                                    style:
                                      "color: " +
                                      _vm.facade.timeColor +
                                      ";font-size: " +
                                      _vm.facade.timeFontSize +
                                      "px;font-weight: " +
                                      _vm.facade.timeFontWeight +
                                      ";margin-top:" +
                                      (m.image_show_style == 2 ||
                                      _vm.facade.timeTextStyle == 1
                                        ? _vm.facade.timeMarginTop
                                        : 0) +
                                      "px;",
                                  },
                                  [_vm._v(_vm._s(m.stime_format))]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        m.live_stime_format
                          ? _c(
                              "section",
                              {
                                style:
                                  "display: " +
                                  (m.image_show_style == 2 ||
                                  _vm.facade.liveTimeTextStyle == 1
                                    ? "block"
                                    : "flex") +
                                  ";margin-top:" +
                                  (m.image_show_style == 2 ||
                                  _vm.facade.liveTimeTextStyle == 1
                                    ? _vm.facade.liveTimeTextMarginTop
                                    : _vm.facade.liveTimeMarginTop) +
                                  "px;",
                              },
                              [
                                _vm.facade.liveTimeText
                                  ? _c(
                                      "section",
                                      {
                                        style:
                                          "color: " +
                                          _vm.facade.liveTimeTextColor +
                                          ";font-size: " +
                                          _vm.facade.liveTimeTextFontSize +
                                          "px;font-weight: " +
                                          _vm.facade.liveTimeTextFontWeight +
                                          ";",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.facade.liveTimeText) +
                                            _vm._s(
                                              m.image_show_style == 2 ||
                                                _vm.facade.liveTimeTextStyle ==
                                                  1
                                                ? ""
                                                : "："
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "section",
                                  {
                                    style:
                                      "color: " +
                                      _vm.facade.liveTimeColor +
                                      ";font-size: " +
                                      _vm.facade.liveTimeFontSize +
                                      "px;font-weight: " +
                                      _vm.facade.liveTimeFontWeight +
                                      ";margin-top:" +
                                      (m.image_show_style == 2 ||
                                      _vm.facade.liveTimeTextStyle == 1
                                        ? _vm.facade.liveTimeMarginTop
                                        : 0) +
                                      "px;",
                                  },
                                  [_vm._v(_vm._s(m.live_stime_format))]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.facade.showVenueAddress
                ? _c(
                    "section",
                    {
                      style: {
                        marginTop: _vm.facade.venueAddressMarginTop + "px",
                        marginBottom:
                          _vm.facade.venueAddressMarginBottom + "px",
                        display: "flex",
                        justifyContent: "center",
                        alignItmes: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      },
                    },
                    [
                      _c(
                        "section",
                        {
                          style:
                            "color: " +
                            _vm.facade.addressTagColor +
                            ";font-size: " +
                            _vm.facade.addressTagFontSize +
                            "px;font-weight: " +
                            _vm.facade.addressTagFontWeight +
                            ";",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.facade.addressTag) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.facade.venueAddressBrief &&
                      _vm.facade.venueAddressLongLat
                        ? _c(
                            "section",
                            {
                              style:
                                "margin-top: " +
                                _vm.facade.addressBriefMarginTop +
                                "px;",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "js_poi_entry wx_poi_link",
                                  style:
                                    "font-size: " +
                                    _vm.facade.addressBriefFontSize +
                                    "px;color: " +
                                    _vm.facade.addressBriefColor +
                                    ";font-weight: " +
                                    _vm.facade.addressBriefFontWeight +
                                    ";",
                                  attrs: {
                                    "data-name": _vm.facade.venueAddressBrief,
                                    "data-address": _vm.facade.venueAddressName,
                                    "data-poiid": "1",
                                    "data-type": "1",
                                    href: "",
                                    "data-districtid": "",
                                    "data-longitude":
                                      _vm.facade.venueAddressLongLat.split(
                                        ","
                                      )[0],
                                    "data-latitude":
                                      _vm.facade.venueAddressLongLat.split(
                                        ","
                                      )[1],
                                  },
                                },
                                [_vm._v(_vm._s(_vm.facade.venueAddressBrief))]
                              ),
                            ]
                          )
                        : _vm.facade.venueAddressBrief
                        ? _c(
                            "section",
                            {
                              style:
                                "margin-top: " +
                                _vm.facade.addressBriefMarginTop +
                                "px;color: " +
                                _vm.facade.addressBriefColor +
                                ";font-size: " +
                                _vm.facade.addressBriefFontSize +
                                "px;font-weight: " +
                                _vm.facade.addressBriefFontWeight +
                                ";",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.facade.venueAddressBrief) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.venueAddressName
                        ? _c(
                            "section",
                            {
                              style:
                                "margin-top: " +
                                _vm.facade.addressNameMarginTop +
                                "px;color: " +
                                _vm.facade.addressNameColor +
                                ";font-size: " +
                                _vm.facade.addressNameFontSize +
                                "px;font-weight: " +
                                _vm.facade.addressNameFontWeight +
                                ";",
                            },
                            [
                              _vm.miniAppid &&
                              _vm.facade.venueAddressName.indexOf(
                                "#小程序://"
                              ) === 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "weapp_text_link",
                                      attrs: {
                                        "data-miniprogram-appid": _vm.miniAppid,
                                        "data-miniprogram-path":
                                          "pages/home/home",
                                        "data-miniprogram-nickname":
                                          _vm.miniName,
                                        href: "",
                                        "data-miniprogram-type": "text",
                                        "data-miniprogram-servicetype": "2",
                                        "wah-hotarea": "click",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          style:
                                            "color: " +
                                            _vm.facade.addressNameColor +
                                            ";font-size: " +
                                            _vm.facade.addressNameFontSize +
                                            "px;font-weight: " +
                                            _vm.facade.addressNameFontWeight +
                                            ";",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.facade.venueAddressName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.facade.venueAddressName)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }