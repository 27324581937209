"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auction_status = _interopRequireDefault(require("@/components/customPage/auction_status.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    auctionStatus: _auction_status.default
  },
  props: {
    facade: {
      type: [Object, Array]
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    itemBackgound: function itemBackgound() {
      if (this.facade.backgroundImage) {
        return "url(".concat(this.facade.backgroundImage, ") no-repeat left top / 100% 100%");
      }
      return this.facade.gradientBackground ? "linear-gradient(".concat(this.facade.gradientDirection, ", ").concat(this.facade.background, ", ").concat(this.facade.gradientBackground, ")") : this.facade.background;
    }
  },
  methods: {
    statusBackgound: function statusBackgound(key) {
      var f = this.facade;
      var background = '';
      switch (key) {
        case 1:
          if (f.preBackgroundImage) {
            background = "url(".concat(f.preBackgroundImage, ") no-repeat left top / 100% 100%");
          } else {
            background = f.preGradientBackground ? "linear-gradient(".concat(f.preGradientDirection, ", ").concat(f.preBackground, ", ").concat(f.preGradientBackground, ")") : f.preBackground;
          }
          break;
        case 2:
          if (f.ingBackgroundImage) {
            background = "url(".concat(f.ingBackgroundImage, ") no-repeat left top / 100% 100%");
          } else {
            background = f.ingGradientBackground ? "linear-gradient(".concat(f.ingGradientDirection, ", ").concat(f.ingBackground, ", ").concat(f.ingGradientBackground, ")") : f.ingBackground;
          }
          break;
        case 3:
          if (f.endBackgroundImage) {
            background = "url(".concat(f.endBackgroundImage, ") no-repeat left top / 100% 100%");
          } else {
            background = f.endGradientBackground ? "linear-gradient(".concat(f.endGradientDirection, ", ").concat(f.endBackground, ", ").concat(f.endGradientBackground, ")") : f.endBackground;
          }
          break;
        case 4:
          if (!f.quickBackground) {
            if (f.preBackgroundImage) {
              background = "url(".concat(f.preBackgroundImage, ") no-repeat left top / 100% 100%");
            } else {
              background = f.preGradientBackground ? "linear-gradient(".concat(f.preGradientDirection, ", ").concat(f.preBackground, ", ").concat(f.preGradientBackground, ")") : f.preBackground;
            }
          } else if (f.quickBackgroundImage) {
            background = "url(".concat(f.quickBackgroundImage, ") no-repeat left top / 100% 100%");
          } else {
            background = f.quickGradientBackground ? "linear-gradient(".concat(f.quickGradientDirection, ", ").concat(f.quickBackground, ", ").concat(f.quickGradientBackground, ")") : f.quickBackground;
          }
          break;
      }
      return background;
    }
  }
};