var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "blank",
      style: {
        height: _vm.facade.height + "px",
        backgroundColor: _vm.facade.background_color,
        display: "flex",
        alignItems: "center",
      },
    },
    [
      _vm.content.has_line
        ? _c("section", {
            staticClass: "line",
            style: {
              height: _vm.facade.line_height + "px",
              width:
                _vm.facade.line_width == 375
                  ? "100%"
                  : _vm.facade.line_width + "px",
              backgroundColor: _vm.facade.line_color,
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }