"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _basic = require("@/api/basic");
var _contract = require("@/api/contract");
var _warehouse = require("@/api/warehouse");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    'el-image-viewer': function elImageViewer() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('element-ui/packages/image/src/image-viewer'));
      });
    }
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    showdetail: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      productdetail: {},
      timelog: [],
      catelog: {},
      drawtype: "detail",
      timeVal: [],
      urlshow: false,
      urlshowList: [],
      showpreviewcontract: false,
      html_content: "",
      html_attachment_content: "",
      attachment: [],
      inventoryConfig: {
        switch: {
          other_price_switch: 0,
          sold_price_switch: 0,
          unsold_price_switch: 0
        },
        price: {
          other_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          sold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          unsold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }]
        }
      },
      inventoryDetailAll: {
        entrustOrderProduct: []
      },
      inventoryDetailFlag: false,
      showinto: false,
      detailjson: {},
      showout: false
    };
  },
  mounted: function mounted() {
    if (this.id) {
      this.getconfig();
      this.getdetail(this.id);
    }
  },
  methods: {
    changedrawtype: function changedrawtype(type) {
      this.drawtype = type;
    },
    changeshowdetail: function changeshowdetail() {
      this.$emit('close');
    },
    getdetail: function getdetail(id) {
      var _this = this;
      this.showdetail = true;
      (0, _basic.goodsDetail)(id).then(function (res) {
        var detail = res.data;
        detail.imagearr = res.data.slider_image.split(',');
        detail.imagearr.unshift(res.data.image);
        console.log(detail);
        _this.productdetail = detail;
      });
      (0, _basic.getproductlog)({
        product_id: id
      }).then(function (res) {
        if (res.status == 200) {
          _this.timelog = res.data.list;
        }
      });
      (0, _basic.getproductcatelog)({
        product_id: id
      }).then(function (res) {
        if (res.status == 200) {
          _this.catelog = res.data;
        }
      });
    },
    prieviewimg: function prieviewimg(img) {
      this.urlshow = true;
      this.urlshowList = [img];
    },
    prieviewtype: function prieviewtype(type, id) {
      var _this2 = this;
      if (type == "push_warehouse") {
        (0, _warehouse.getwarehouseDetail)(id).then(function (res) {
          if (res.status == 200) {
            _this2.detailjson = res.data;
            _this2.showinto = true;
          }
        });
      } else if (type == "out_warehouse") {
        (0, _warehouse.getwarehouseDetail)(id).then(function (res) {
          if (res.status == 200) {
            _this2.detailjson = res.data;
            _this2.showout = true;
          }
        });
      } else if (type == "entrust") {
        this.getconfig();
        (0, _basic.getinventoryOrderDetail)(id).then(function (res) {
          console.log(res);
          _this2.inventoryDetailFlag = true;
          _this2.inventoryDetailAll = res.data;
          _this2.$forceUpdate();
        });
      } else if (type == "contract") {
        (0, _contract.getcontractDetail)(id).then(function (res) {
          if (!res.data.content || !res.data.content.html_attachment_content) {
            _this2.$message.error('合同不存在');
            return;
          }
          _this2.html_attachment_content = res.data.content.html_attachment_content;
          _this2.html_content = res.data.content.html_content;
          _this2.attachment = res.data.attachment;
          _this2.showpreviewcontract = true;
        });
      }
    },
    bindPrintorder: function bindPrintorder(ptype) {
      if (ptype == 'out') {
        (0, _printJs.default)({
          printable: 'printorderout',
          type: 'html',
          header: null,
          targetStyles: ['*'],
          style: "@page {margin:0 10mm}"
        });
      } else {
        (0, _printJs.default)({
          printable: 'printorder',
          type: 'html',
          header: null,
          targetStyles: ['*'],
          style: "@page {margin:0 10mm}"
        });
      }
    },
    getconfig: function getconfig() {
      var _this3 = this;
      (0, _basic.contractConfig)().then(function (res) {
        console.log(res);
        if (res.status == 200) {
          if (res.data.price && res.data.price.sold_price && res.data.price.sold_price.length > 1) {
            _this3.inventoryConfig = res.data;
            _this3.$forceUpdate();
          }
        }
      });
    },
    downloadf: function downloadf(item) {
      window.open(item.attachment_url);
    }
  }
};