"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _default = exports.default = {
  namespaced: true,
  state: {
    goodsData: [],
    pageInfo: {
      size: 7,
      total: 0,
      current: 1
    }
  },
  mutations: {
    /**
     * 设置图片
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setGoodsData: function setGoodsData(state, data) {
      state.goodsData = data;
    },
    /**
     * 设置分页
     * @param {[type]} state [description]
     * @param {[type]} data  [description]
     */
    setPageInfo: function setPageInfo(state, data) {
      state.pageInfo = data;
    }
  },
  actions: {
    /**
     * 登录
     * @param  {[type]} options.commit   [description]
     * @param  {[type]} options.userName [description]
     * @param  {[type]} options.password [description]
     * @return {[type]}                  [description]
     */
    handleGoods: function handleGoods(_ref, _ref2) {
      var commit = _ref.commit;
      var current = _ref2.current,
        keyword = _ref2.keyword;
      keyword.tab_key = 'onsale';
      var $heshop = this._vm.$heshop;
      return new Promise(function (resolve, reject) {
        $heshop.search('POST', {
          include: 'goods'
        }, {
          keyword: keyword
        }).page(current, 7).then(function (data) {
          var size = parseInt(data.headers['x-pagination-per-page']);
          var total = parseInt(data.headers['x-pagination-total-count']);
          var current = parseInt(data.headers['x-pagination-current-page']);
          commit('setPageInfo', {
            size: size,
            total: total,
            current: current
          });
          commit('setGoodsData', data.data);
          resolve(data.data);
        }).catch(function (error) {
          console.error('错误提示', error);
          reject();
        });
      });
    },
    /**
     * [handleGoods description]
     * @param  {[type]} options.commit [description]
     * @return {[type]}                [description]
     */
    handleSearch: function handleSearch(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {});
    }
  }
};