"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var questionnaireRouter = {
  path: "".concat(_settings.roterPre, "/questionnaire"),
  name: "questionnaire",
  meta: {
    title: "问卷调查"
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/questionnaire/askList"),
  children: [{
    path: "askList",
    name: "AskList",
    meta: {
      title: "问卷调查",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/questionnaire/askList"),
      permissionKey: '/questionnaire/askList'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/questionnaire/askList"));
      });
    }
  }, {
    path: "createAsk",
    name: "CreateAsk",
    meta: {
      title: "新增调查问卷",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/questionnaire/askList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/questionnaire/createAsk"));
      });
    }
  }, {
    path: "getAsk",
    name: "GetAsk",
    meta: {
      title: "回收详情",
      cacheable: false,
      activeMenu: "".concat(_settings.roterPre, "/questionnaire/askList")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/questionnaire/getAsk"));
      });
    }
  }]
};
var _default = exports.default = questionnaireRouter;