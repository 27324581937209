"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _nft = require("@/api/nft");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      queryParams: {
        page: 1,
        limit: 10,
        type: '1'
      },
      keyword: '',
      status: '',
      newDeliverDetail: {
        show: false,
        is_transfer: 0,
        name: '',
        id: '',
        condition: [{
          day: '',
          price: ''
        }]
      },
      rules: {
        name: [{
          required: true,
          message: '请输入规则名称',
          trigger: 'blur'
        }]
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    editDayAction: function editDayAction(i) {
      this.newDeliverDetail.condition[i].day = Math.ceil(this.newDeliverDetail.condition[i].day);
    },
    addRuleAction: function addRuleAction() {
      this.newDeliverDetail.condition.push({
        day: '',
        price: ''
      });
    },
    delRuleAction: function delRuleAction(i) {
      if (this.newDeliverDetail.condition[i]) {
        this.newDeliverDetail.condition.splice(i, 1);
      }
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      (0, _nft.transferListApi)(this.queryParams).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    goSearch: function goSearch() {
      this.queryParams.keyword = this.keyword;
      this.queryParams.status = this.status;
      this.getList(1);
    },
    changeTab: function changeTab(val) {
      this.queryParams.type = val;
      this.getList(1);
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.queryParams.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.queryParams.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 导出
    // exportOrder() {
    //   exportOrderApi(this.queryParams)
    //     .then(res => {
    //       // this.$message.success(res.message);
    //       // this.fileVisible = true;
    //       // this.$refs.exportList.exportFileList();
    //       const h = this.$createElement;
    //       this.$msgbox({
    //         title: '提示',
    //         message: h('p', null, [
    //           h('span', null, '文件正在生成中，请稍后点击"'),
    //           h('span', { style: 'color: #69d' }, '导出已生成列表'),
    //           h('span', null, '"查看~ ')
    //         ]),
    //         confirmButtonText: '我知道了'
    //       }).then(action => {});
    //     })
    //     .catch(res => {
    //       // this.$message.error(res.message);
    //     });
    // },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('auction_order');
    },
    addAction: function addAction() {
      this.newDeliverDetail = {
        show: true,
        name: '',
        id: '',
        is_transfer: 0,
        condition: [{
          day: '',
          price: ''
        }]
      };
    },
    showDetailAction: function showDetailAction() {
      this.deliverDetail = {
        show: true,
        keyword: '',
        status: '',
        queryParams: {
          page: 1,
          limit: 10
        },
        listLoading: false,
        tableData: {
          data: [{
            img: 'https://saas.cdn.yunzongbu.cn/merchant/338807/2022426/image/1650954547314824622.jpg',
            name: '天降祥瑞宫猫',
            sdk: '443****480',
            price: 9999.01,
            fx: 100,
            sock: 200,
            type: '图片',
            status: 1,
            order_num: 1,
            admin: '晓琪'
          }],
          total: 0
        }
      };
      this.loadUserAction(1);
    },
    goSearchUserAction: function goSearchUserAction() {
      this.loadUserAction(1);
    },
    loadUserAction: function loadUserAction(num) {},
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this2.newDeliverDetail));
          data.condition.forEach(function (m) {
            if (!m.day) m.day = 0;
            if (!m.price) m.price = 0;
          });
          var action = _nft.transferCreateApi;
          if (data.id) {
            action = _nft.transferUpdateApi;
          }
          action(data.id, {
            title: data.name,
            config_data: data.condition,
            is_transfer: data.is_transfer
          }).then(function () {
            _this2.$message.success('操作成功');
            _this2.newDeliverDetail.show = false;
            _this2.getList(1);
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    delAction: function delAction(id) {
      var _this3 = this;
      this.$confirm('确认删除此规则？', '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      }).then(function () {
        (0, _nft.transferDelApi)(id).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList();
        });
      }).catch(function (_ref) {
        var message = _ref.message;
      });
    },
    editAction: function editAction(row) {
      this.newDeliverDetail = {
        show: true,
        name: row.title,
        id: row.config_id,
        condition: row.config_data,
        is_transfer: Number(row.is_transfer)
      };
    }
  }
};