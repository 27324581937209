var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "t ft14" }, [
      _vm._v(
        "以下模板，适用于所有移动端，包括APP端（安卓+苹果）、小程序端（微信+抖音）。"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex" },
      _vm._l(_vm.templateList, function (m, i) {
        return _c("div", { key: i, staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "img" },
            [
              _c("el-image", {
                staticClass: "img-view",
                attrs: {
                  "preview-src-list": [m.image],
                  src: m.image + "!m640",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(m.name) + " \n          "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-btn flex align-items-c" }, [
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  m.is_use
                    ? _c("el-tag", { attrs: { size: "small" } }, [
                        _vm._v("已使用"),
                      ])
                    : _c("el-tag", { attrs: { size: "small", type: "info" } }, [
                        _vm._v("未使用"),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  !m.is_use
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-setting" },
                          on: {
                            click: function ($event) {
                              return _vm.useAction(m.id)
                            },
                          },
                        },
                        [_vm._v("使用模版")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "el-icon-view" },
                      on: {
                        click: function ($event) {
                          return _vm.preAction(m.id)
                        },
                      },
                    },
                    [_vm._v("预览")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }