"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSceneApi = createSceneApi;
exports.decorationSpace = decorationSpace;
exports.deleteSceneApi = deleteSceneApi;
exports.getModelListApi = getModelListApi;
exports.getSceneListApi = getSceneListApi;
exports.getToken = getToken;
exports.postDecorationSpace = postDecorationSpace;
var _request = _interopRequireDefault(require("../request"));
// 获取token
function getToken(data) {
  return _request.default.post('http://test-sdk-demo.yunzongbu.cn/getToken', data);
}
// 获取模型列表
function getModelListApi(data) {
  return _request.default.get("http://test-sdk-demo.yunzongbu.cn/sceneList", data);
}
// 新建空间
function createSceneApi(data) {
  return _request.default.post("http://test-sdk-demo.yunzongbu.cn/saveSpace", data);
}
// 空间列表
function getSceneListApi(data) {
  return _request.default.get("http://test-sdk-demo.yunzongbu.cn/system/spaceList", data);
}
// 删除空间
function deleteSceneApi(id) {
  return _request.default.delete("http://test-sdk-demo.yunzongbu.cn/deleteSpace/".concat(id, " "));
}
// 空间里的内容
function decorationSpace(id) {
  return _request.default.get("http://test-sdk-demo.yunzongbu.cn/decorationSpace/".concat(id));
}
// 保存空间
function postDecorationSpace(data) {
  return _request.default.post("http://test-sdk-demo.yunzongbu.cn/decorationSpace", data);
}