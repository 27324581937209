"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formData: {
        kuanTangIntegralConfig_integralRankUserCount: '',
        kuanTangIntegralConfig_welcomeMessage: '',
        kuanTangIntegralConfig_integralRuleMessage: '',
        kuanTangIntegralConfig_integralRankPrizesImage: '',
        kuanTangIntegralConfig_integralRankMessage: ''
      }
    };
  },
  created: function created() {
    this.getData();
  },
  mounted: function mounted() {},
  methods: {
    getData: function getData() {
      var _this = this;
      (0, _marketing.nftIntegralConfig)().then(function (res) {
        _this.formData = res.data;
      });
    },
    save: function save() {
      var _this2 = this;
      (0, _marketing.nftIntegralConfigSave)(this.formData).then(function (res) {
        _this2.$message.success("保存成功");
      });
    },
    changeImg: function changeImg(key, index) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        if (index === undefined) {
          _this3.formData[key] = img[0];
        } else {
          _this3.$set(_this3.formData, key, img[0]);
          // this.ruleForm[key][index] = img[0];
        }
      }, 1, '1');
    }
  }
};