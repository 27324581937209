var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _c(
      "div",
      {
        staticClass: "footer",
        style: {
          background: _vm.facade.footerSetting.footer_background,
          color: _vm.facade.footerSetting.footer_text_color,
          fontSize: _vm.facade.footerSetting.footer_text_size / 2 + "px",
          "box-shadow":
            _vm.facade.footerSetting.itemShadow &&
            _vm.facade.footerSetting.itemShadowSize
              ? "0 0 " +
                _vm.facade.footerSetting.itemShadowSize / 2 +
                "px " +
                _vm.facade.footerSetting.itemShadow
              : "",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "footer-view item-align-center justify-between",
            style: {
              padding: "0 " + _vm.facade.footerSetting.footer_margin / 2 + "px",
            },
          },
          _vm._l(_vm.facade.footerSetting.content, function (m, i) {
            return _c("div", { key: i, staticClass: "footer-item" }, [
              _c("div", { staticClass: "img" }, [
                m.url
                  ? _c("img", {
                      class: {
                        tuchu:
                          _vm.facade.footerSetting.footer_type === 2 &&
                          parseInt(
                            _vm.facade.footerSetting.content.length / 2,
                            10
                          ) === i,
                      },
                      style:
                        "width: " +
                        _vm.facade.footerSetting.footer_icon_width / 2 +
                        "px;height: " +
                        _vm.facade.footerSetting.footer_icon_height / 2 +
                        "px;",
                      attrs: { src: m.url + "!120a", alt: "" },
                    })
                  : _c(
                      "div",
                      {
                        staticClass: "no-img item-flex-center",
                        class: {
                          tuchu:
                            _vm.facade.footerSetting.footer_type === 2 &&
                            parseInt(
                              _vm.facade.footerSetting.content.length / 2,
                              10
                            ) === i,
                        },
                        style:
                          "width: " +
                          _vm.facade.footerSetting.footer_icon_width / 2 +
                          "px;height: " +
                          _vm.facade.footerSetting.footer_icon_height / 2 +
                          "px;",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/9c832b44e7ceea1718b1ab8d84f91300.png",
                            alt: "",
                          },
                        }),
                      ]
                    ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-txt",
                  style: {
                    color:
                      i === 0
                        ? _vm.facade.footerSetting.footer_active_color
                        : _vm.facade.footerSetting.footer_text_color,
                    marginTop:
                      _vm.facade.footerSetting.footer_margin_top / 2 + "px",
                  },
                },
                [_vm._v(_vm._s(m.title))]
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "hengtiao" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }