var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.updLoading,
          expression: "updLoading",
        },
      ],
      staticClass: "flex",
      attrs: {
        "element-loading-text":
          "图片上传中" + _vm.updSussNum + "/" + _vm.imgLength,
      },
    },
    [
      _c("div", { staticStyle: { width: "200px", "margin-right": "30px" } }, [
        _c("div", { staticClass: "Nav" }, [
          _c(
            "div",
            { staticClass: "input" },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  placeholder: "搜索分类",
                  "prefix-icon": "el-icon-search",
                  clearable: "",
                },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "trees-coadd",
              style: !(
                this.params !== "/merchant/config/picture" &&
                !this.changeCategory &&
                !this.ssif
              )
                ? { height: "84vh" }
                : { height: "78vh" },
            },
            [
              _c("div", { staticClass: "scollhide" }, [
                _c(
                  "div",
                  { staticClass: "trees" },
                  [
                    _c("el-tree", {
                      ref: "tree",
                      attrs: {
                        data: _vm.treeData2,
                        "filter-node-method": _vm.filterNode,
                        props: _vm.defaultProps,
                        "highlight-current": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "div",
                              {
                                staticClass: "custom-tree-node",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleNodeClick(data)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "light",
                                          trigger: "click",
                                          content: node.label,
                                          placement: "top",
                                        },
                                      },
                                      [_c("span", [_vm._v(_vm._s(node.label))])]
                                    ),
                                    _vm._v(" "),
                                    data.space_property_name
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "11px",
                                              color: "#3889b1",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "（" +
                                                _vm._s(
                                                  data.attachment_category_name
                                                ) +
                                                "）"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                data.is_system == 1
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "el-ic",
                                        staticStyle: {
                                          display: "inline-block",
                                          "font-style": "unset",
                                          "font-size": "10px",
                                          color: "#ccc",
                                        },
                                      },
                                      [_vm._v("系统分类")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                data.is_system != 1
                                  ? _c("span", { staticClass: "el-ic" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus-outline",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.onAdd(
                                              data.attachment_category_id
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      data.space_id != "0" &&
                                      (!data.children ||
                                        data.children == "undefined") &&
                                      data.attachment_category_id
                                        ? _c("i", {
                                            staticClass: "el-icon-edit",
                                            attrs: { title: "修改" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onEdit(
                                                  data.attachment_category_id
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      data.space_id != "0" &&
                                      (!data.children ||
                                        data.children == "undefined") &&
                                      data.attachment_category_id
                                        ? _c("i", {
                                            staticClass: "el-icon-delete",
                                            attrs: { title: "删除分类" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return (function () {
                                                  return _vm.handleDelete(
                                                    data.attachment_category_id
                                                  )
                                                })($event)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onAdd(0)
                    },
                  },
                },
                [_vm._v("创建分类")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "conter",
        },
        [
          _c(
            "div",
            {
              staticClass: "flex justify-b bnt",
              staticStyle: { height: "36px" },
            },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "请输入图片名称搜索" },
                  model: {
                    value: _vm.tableData.attachment_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableData, "attachment_name", $$v)
                    },
                    expression: "tableData.attachment_name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getFileList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.params !== "/merchant/config/picture" &&
                  !_vm.changeCategory &&
                  !_vm.ssif
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mb10 mr10",
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-circle-check",
                          },
                          on: { click: _vm.checkPics },
                        },
                        [_vm._v("使用选中图片")]
                      )
                    : _vm.params !== "/merchant/config/picture" &&
                      _vm.changeCategory &&
                      !_vm.ssif
                    ? _c(
                        "el-button",
                        {
                          staticClass: "mb10 mr10",
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-circle-check",
                          },
                          on: { click: _vm.checkCategory },
                        },
                        [_vm._v("使用选中分类")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      ref: "uploadImg",
                      staticClass: "upload-demo mr10 mb15",
                      attrs: {
                        action: _vm.fileUrl,
                        "on-success": _vm.handleSuccess,
                        headers: _vm.myHeaders,
                        "show-file-list": false,
                        multiple: "",
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-change": _vm.onChange,
                        "http-request": _vm.modeUpload,
                        accept: "image/*,video/*,audio/*",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-upload",
                          },
                        },
                        [_vm._v("点击上传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pictrueList acea-row mb15",
              style: !(
                this.params !== "/merchant/config/picture" &&
                !this.changeCategory &&
                !this.ssif
              )
                ? { height: "calc(84vh - 32px)" }
                : { height: "calc(78vh - 32px)" },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowPic,
                      expression: "isShowPic",
                    },
                  ],
                  staticClass: "imagesNo",
                },
                [
                  _c("i", {
                    staticClass: "el-icon-picture",
                    staticStyle: {
                      "font-size": "60px",
                      color: "rgb(219, 219, 219)",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "imagesNo_sp" }, [
                    _vm._v("图片库为空"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "conters" },
                _vm._l(_vm.pictrueList.list, function (item, index) {
                  return _c("div", { key: index, staticClass: "gridPic" }, [
                    item.num > 0
                      ? _c(
                          "p",
                          { staticClass: "number" },
                          [
                            _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                attrs: { value: item.num },
                              },
                              [
                                _c("a", {
                                  staticClass: "demo-badge",
                                  attrs: { href: "#" },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.attachment_type === 1
                      ? _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value:
                                item.attachment_src +
                                (item.attachment_src.indexOf(".ico") == -1
                                  ? "!120a"
                                  : ""),
                              expression:
                                "item.attachment_src + (item.attachment_src.indexOf('.ico') == -1 ? '!120a' : '')",
                            },
                          ],
                          class: item.isSelect ? "on" : "",
                          on: {
                            click: function ($event) {
                              return _vm.changImage(
                                item,
                                index,
                                _vm.pictrueList.list
                              )
                            },
                          },
                        })
                      : item.attachment_type === 2
                      ? _c("video", {
                          class: item.isSelect ? "on" : "",
                          attrs: { src: item.attachment_src },
                          on: {
                            click: function ($event) {
                              return _vm.changImage(
                                item,
                                index,
                                _vm.pictrueList.list
                              )
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.attachment_type === 2
                      ? _c("div", { staticClass: "bofang" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _vm.editId === item.attachment_id
                          ? _c("el-input", {
                              model: {
                                value: item.attachment_name,
                                callback: function ($$v) {
                                  _vm.$set(item, "attachment_name", $$v)
                                },
                                expression: "item.attachment_name",
                              },
                            })
                          : _c(
                              "p",
                              {
                                staticClass: "name",
                                staticStyle: { width: "80%" },
                              },
                              [_vm._v(_vm._s(item.attachment_name))]
                            ),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-edit imgIcon",
                          on: {
                            click: function ($event) {
                              return _vm.handleEdit(
                                item.attachment_id,
                                item.attachment_name
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-b" },
            [
              _c(
                "div",
                { staticClass: "flex-one" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectAll($event)
                        },
                      },
                    },
                    [_vm._v("全选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: _vm.checkPicList.length === 0,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.editPicList("图片")
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        height: "36px",
                        width: "100px",
                        "margin-left": "10px",
                      },
                      attrs: { placeholder: "移动至", size: "small" },
                      model: {
                        value: _vm.sleOptions.attachment_category_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.sleOptions,
                            "attachment_category_name",
                            $$v
                          )
                        },
                        expression: "sleOptions.attachment_category_name",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        {
                          staticStyle: {
                            "max-width": "560px",
                            height: "200px",
                            overflow: "auto",
                            "background-color": "#fff",
                          },
                          attrs: {
                            label: _vm.sleOptions.attachment_category_name,
                            value: _vm.sleOptions.attachment_category_id,
                          },
                        },
                        [
                          _c("el-tree", {
                            ref: "tree2",
                            attrs: {
                              data: _vm.treeData2,
                              "filter-node-method": _vm.filterNode,
                              props: _vm.defaultProps,
                              "highlight-current": "",
                            },
                            on: { "node-click": _vm.handleSelClick },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [12, 20, 40, 60, 100],
                  "page-size": _vm.tableData.limit,
                  "pager-count": 5,
                  "current-page": _vm.tableData.page,
                  layout: "total, sizes, prev, pager, next",
                  total: _vm.pictrueList.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "20px 0" } }, [
            _c("div", { staticClass: "flex justify-c mb20" }, [
              _c("i", {
                staticClass: "el-icon-warning-outline",
                staticStyle: { color: "red", "font-size": "40px" },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex justify-c mb20",
                staticStyle: {
                  color: "red",
                  "font-size": "18px",
                  "text-align": "center",
                },
              },
              [
                _vm._v("删除该图片后，所有使用该图的位置(例：专场，拍品) "),
                _c("br"),
                _vm._v(" 将同步删除！"),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex justify-c mb20",
                staticStyle: { color: "red", "font-size": "20px" },
              },
              [_vm._v("请谨慎操作！")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-c" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.moduleVisible = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onNext } },
                  [_vm._v("确认删除")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.flVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "530px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.flVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "20px 0" } }, [
            _c("div", { staticClass: "flex justify-c mb20" }, [
              _c("i", {
                staticClass: "el-icon-warning-outline",
                staticStyle: { color: "red", "font-size": "40px" },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex justify-c mb20",
                staticStyle: {
                  color: "red",
                  "font-size": "18px",
                  "text-align": "center",
                },
              },
              [
                _vm._v(
                  "删除该分类后，所有使用该分类图片的位置(例：专场，拍品) "
                ),
                _c("br"),
                _vm._v(" 将同步删除！"),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex justify-c mb20",
                staticStyle: { color: "red", "font-size": "20px" },
              },
              [_vm._v("请谨慎操作！")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-c" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.flVisible = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onflNext } },
                  [_vm._v("确认删除")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }