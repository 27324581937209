"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _authenticate = require("@/api/authenticate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem("identityid") === "11",
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 5
      },
      total: 0,
      uname: ""
    };
  },
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.load();
      }
      this.showSelectAuction = true;
    },
    search: function search() {
      this.queryParams.uname = this.uname;
      this.queryParams.page = 1;
      this.load();
    },
    load: function load(fun) {
      var _this = this;
      var params = this.queryParams;
      (0, _authenticate._getQuestionList)(params).then(function (res) {
        if (res.status === 200) {
          _this.total = res.data.count;
          res.data.list.forEach(function (m) {
            var newM = m;
            newM.img = m.image_url ? m.image_url.split(",")[0] : "";
          });
          _this.resultData = res.data.list;
          _this.$nextTick(function () {
            // 赋值后马上更新
            if (fun) fun();
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      console.log(info);
      var data = {
        imgs: info.image_url ? info.image_url.split(",") : [],
        name: info.title,
        tag: "酬金",
        time: info.human_create_time,
        price: "￥" + info.reward / 100,
        sign: "",
        id: info.id,
        click_num: info.click_num
      };
      if (info.is_answered == 1 || info.answer_num > 0) {
        data.sign = "已解答";
      }
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      e.preventDefault();
    },
    cursorSelect: function cursorSelect(type) {
      this.showSelectAuction = false;
      this.queryParams.page = 1;
      if (type == 1) {
        this.$emit("selectMessage", {
          type: "appraisalQuestions",
          data: this.selectedAuction
        });
      }
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.limit = size;
      this.queryParams.page = 1;
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.load();
    }
  }
};