"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var qiniu = _interopRequireWildcard(require("qiniu-js"));
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  videoUpload: function videoUpload(config) {
    if (config.type === 'COS') {
      return this.cosHttp(config.evfile, config.res, config.uploading);
    } else if (config.type === 'OSS') {
      return this.ossHttp(config.evfile, config.res, config.uploading);
    } else {
      return this.qiniuHttp(config.evfile, config.res, config.uploading);
    }
  },
  cosHttp: function cosHttp(evfile, res, videoIng) {
    // 腾讯云
    // 对更多字符编码的 url encode 格式
    var camSafeUrlEncode = function camSafeUrlEncode(str) {
      return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A');
    };
    var fileObject = evfile.target.files[0];
    var Key = fileObject.name;
    var pos = Key.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = Key.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var data = res.data;
    var XCosSecurityToken = data.credentials.sessionToken;
    var url = data.url + camSafeUrlEncode(filename).replace(/%2F/g, '/');
    var xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    XCosSecurityToken && xhr.setRequestHeader('x-cos-security-token', XCosSecurityToken);
    xhr.upload.onprogress = function (e) {
      var progress = Math.round(e.loaded / e.total * 10000) / 100;
      videoIng(true, progress);
    };
    return new Promise(function (resolve, reject) {
      xhr.onload = function () {
        if (/^2\d\d$/.test('' + xhr.status)) {
          var ETag = xhr.getResponseHeader('etag');
          console.log(null, {
            url: url,
            ETag: ETag
          });
          videoIng(false, 0);
          resolve({
            url: url,
            ETag: ETag
          });
        } else {
          reject({
            msg: '文件 ' + filename + ' 上传失败，状态码：' + xhr.statu
          });
        }
      };
      xhr.onerror = function () {
        reject({
          msg: '文件 ' + filename + '上传失败，请检查是否没配置 CORS 跨域规'
        });
      };
      xhr.send(fileObject);
    });
  },
  ossHttp: function ossHttp(evfile, res, videoIng) {
    var that = this;
    var fileObject = evfile.target.files[0];
    var file = fileObject.name;
    var pos = file.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = file.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var formData = new FormData();
    var data = res.data;
    // 注意formData里append添加的键的大小写
    formData.append('key', filename); // 存储在oss的文件路径
    formData.append('OSSAccessKeyId', data.accessid); // accessKeyId
    formData.append('policy', data.policy); // policy
    formData.append('Signature', data.signature); // 签名
    // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
    formData.append('file', fileObject);
    formData.append('success_action_status', 200); // 成功后返回的操作码
    var url = data.host;
    var fileUrl = url + '/' + filename;
    videoIng(true, 100);
    return new Promise(function (resolve, reject) {
      _axios.default.defaults.withCredentials = false;
      _axios.default.post(url, formData).then(function () {
        // that.progress = 0;
        videoIng(false, 0);
        resolve({
          url: fileUrl
        });
      }).catch(function (res) {
        reject({
          msg: res
        });
      });
    });
  },
  qiniuHttp: function qiniuHttp(evfile, res, videoIng) {
    var uptoken = res.data.token;
    var file = evfile.target.files[0]; // Blob 对象，上传的文件
    var Key = file.name; // 上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。
    var pos = Key.lastIndexOf('.');
    var suffix = '';
    if (pos !== -1) {
      suffix = Key.substring(pos);
    }
    var filename = new Date().getTime() + suffix;
    var fileUrl = res.data.domain + '/' + filename;
    var config = {
      useCdnDomain: true
    };
    var putExtra = {
      fname: '',
      // 文件原文件名
      params: {},
      // 用来放置自定义变量
      mimeType: null // 用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
    };
    var observable = qiniu.upload(file, filename, uptoken, putExtra, config);
    return new Promise(function (resolve, reject) {
      observable.subscribe({
        next: function next(result) {
          console.log(result);
          var progress = Math.round(result.total.loaded / result.total.size);
          videoIng(true, progress);
          // 主要用来展示进度
        },
        error: function error(errResult) {
          // 失败报错信息
          reject({
            msg: errResult
          });
        },
        complete: function complete(result) {
          // 接收成功后返回的信息
          videoIng(false, 0);
          resolve({
            url: fileUrl
          });
        }
      });
    });
  }
};