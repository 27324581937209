var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "overflow-hidden bor",
      style: {
        height: _vm.h,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "w-full",
          style: {
            height: "600px",
            background: _vm.facade.pageGradientBackground
              ? "linear-gradient(" +
                _vm.facade.pageGradientDirection +
                ", " +
                _vm.facade.pageBackground +
                ", " +
                _vm.facade.pageGradientBackground +
                ")"
              : _vm.facade.pageBackground,
            "font-weight": _vm.facade.fontWeight,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "w-full page h-full flex justify-b",
              style: {
                background:
                  "url(" +
                  _vm.facade.pageBackgroundUrl +
                  ") no-repeat center top / 100%",
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "name", style: "color: " + _vm.facade.color },
                  [_vm._v("页面主文字")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "desc",
                    style: "color: " + _vm.facade.subColor,
                  },
                  [
                    _vm._v("页面副文本 "),
                    _c(
                      "span",
                      {
                        style:
                          "color: " +
                          _vm.facade.linkColor +
                          ";margin-left: 10px;",
                      },
                      [_vm._v("链接文本")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "special",
                    style: "color: " + _vm.facade.specialColor,
                  },
                  [_vm._v("突出文字")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "price",
                    style: "color: " + _vm.facade.priceColor,
                  },
                  [_c("span", [_vm._v("￥")]), _vm._v("120")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }