"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _ticket = require("@/api/ticket");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _user = require("@/api/user");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        ticket_name: ''
      },
      parseTime: _index.parseTime,
      showAddPeople: {
        show: false,
        uid: '',
        btnLoading: false
      },
      searchLoading: false,
      timeout: null,
      userList: [],
      searchTimes: []
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex < 3) {
        return {
          rowspan: row.rowspan,
          colspan: row.colspan
        };
      }
    },
    createdAction: function createdAction() {
      this.showAddPeople = {
        show: true,
        uid: '',
        btnLoading: false
      };
    },
    searchDateAction: function searchDateAction() {
      if (this.searchTimes && this.searchTimes[0] && this.searchTimes[1]) {
        this.tableFrom.start_time = this.searchTimes[0];
        this.tableFrom.end_time = this.searchTimes[1];
      } else {
        this.tableFrom.start_time = '';
        this.tableFrom.end_time = '';
      }
      this.getList(1);
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _ticket.ticketStatisticsApi)(this.tableFrom).then(function (res) {
        var list = [];
        res.data.list.forEach(function (m) {
          m.spec_info.forEach(function (child, i) {
            var d = child;
            d.ticket_id = m.ticket_id;
            d.ticket_name = m.ticket_name;
            d.statistics = m.statistics;
            if (m.spec_info.length > 1) {
              if (i === 0) {
                d.rowspan = m.spec_info.length;
                d.colspan = 1;
              } else {
                d.rowspan = 0;
                d.colspan = 0;
              }
            } else {
              d.rowspan = 1;
              d.colspan = 1;
            }
            list.push(d);
          });
        });
        console.log(list);
        _this.tableData.data = list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    searchUserAction: function searchUserAction(data) {
      var _this2 = this;
      this.searchLoading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        var params = {
          keyword: data,
          page: 1,
          limit: 10
        };
        (0, _user.userLstApi)(params).then(function (res) {
          _this2.userList = res.data.list;
          _this2.searchLoading = false;
        });
        _this2.timeout = null;
      }, 500);
    },
    saveAddPeopleAction: function saveAddPeopleAction() {
      var _this3 = this;
      (0, _ticket.ticketCheckerCreateApi)({
        uid: this.showAddPeople.uid
      }).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.showAddPeople.show = false;
        _this3.getList();
      });
    },
    editSortAction: function editSortAction(m) {
      console.log(m.sort);
    },
    editAction: function editAction(id) {
      this.$router.push('./detail?id=' + id);
    },
    delAction: function delAction(id) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u6838\u9500\u5458?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _ticket.ticketCheckerDelApi)({
          uid: id
        }).then(function () {
          _this4.$message.success('操作成功');
          _this4.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this5 = this;
      var d = JSON.parse(JSON.stringify(this.tableFrom));
      d.export_type = 'ticketUserStatistics';
      (0, _system.exportexcel)(d).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this5.$createElement;
        _this5.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this5.$msgbox.close();
                _this5.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('ticketUserStatistics');
    }
  }
};