"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _nftSaleTmp = _interopRequireDefault(require("@/components/nftSaleTmp"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    NftSaleTmp: _nftSaleTmp.default,
    userdetail: _userdetail.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      orderChartType: {},
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 10,
        date: ''
      },
      showdetail: null,
      uid: '',
      timeVal: [],
      fromList: {
        title: '选择时间',
        custom: true,
        fromTxt: [{
          text: '全部',
          val: ''
        }, {
          text: '今天',
          val: 'today'
        }, {
          text: '昨天',
          val: 'yesterday'
        }, {
          text: '最近7天',
          val: 'lately7'
        }, {
          text: '最近30天',
          val: 'lately30'
        }, {
          text: '本月',
          val: 'month'
        }, {
          text: '本年',
          val: 'year'
        }]
      }
    };
  },
  created: function created() {
    this.getList(1);
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  methods: {
    changeStatusAction: function changeStatusAction() {
      this.loadStatus = false;
      this.getList(1);
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.boxTradeListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    onDetails: function onDetails(row) {
      if (row && row.uid) {
        this.uid = row.uid;
        // this.visibleDetail = true
        this.showdetail = true;
      }
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this2 = this;
      var data = JSON.parse(JSON.stringify(this.tableFrom));
      data.export_type = 'consign_box_trade';
      (0, _system.exportexcel)(data).then(function (res) {
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this2.$msgbox.close();
                _this2.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('consign_box_trade');
    }
  }
};