"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  props: ["modifyDetail", "open3dEl"],
  data: function data() {
    return {
      textColor: "#000000",
      textAlign: "left",
      textFontSize: 10,
      textLetterSpacing: 0,
      textLineHeight: "10",
      textWidth: "100",
      textIndent: "0",
      textValue: ""
    };
  },
  created: function created() {
    var _this = this;
    setTimeout(function () {
      // 延迟处理文字问题
      _this.setDefault();
    }, 100);
  },
  watch: {
    modifyDetail: {
      handler: function handler(val) {
        if (this.modifyDetail && this.modifyDetail.value) {
          this.setDefault();
        }
      },
      immediate: true
    }
  },
  methods: {
    setDefault: function setDefault() {
      if (this.modifyDetail && this.modifyDetail.editType == "textInfo") {
        this.textColor = this.modifyDetail.color;
        this.textAlign = this.modifyDetail.align;
        this.textFontSize = this.modifyDetail.fontSize;
        this.textLetterSpacing = this.modifyDetail.letterSpacing;
        this.textLineHeight = this.modifyDetail.lineHeight;
        this.textIndent = this.modifyDetail.textIndent;
        this.textValue = this.modifyDetail.value;
      }
    },
    changeTextAction: function changeTextAction(e) {
      // 修改文字颜色
      this.textColor = e.target.value;
      if (this.open3dEl) {
        this.open3dEl.editText("color", e.target.value);
      }
    },
    editTextAction: function editTextAction(e) {
      // 修改文字
      if (this.modifyDetail && this.modifyDetail.editType == "textInfo") {
        if (this.open3dEl) {
          this.open3dEl.editText("value", this.textValue);
        }
        return false;
      }
      if (this.open3dEl) {
        this.open3dEl.addText(this.textValue);
      }
    },
    editTextAlignAction: function editTextAlignAction(val) {
      // 修改对其方式
      this.textAlign = val;
      if (this.open3dEl) {
        this.open3dEl.editText('align', this.textAlign);
      }
    },
    editTextInput: function editTextInput(e, type) {
      if (this.open3dEl) {
        this.open3dEl.editText(type, e.target.value);
      }
    },
    inpAction: function inpAction(val) {
      if (this.open3dEl) {
        this.open3dEl.changeLockMove(val);
      }
    }
  }
};