"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "OrderDetail",
  data: function data() {
    return {
      dialogVisible: false,
      // orderDatalist: null,
      loading: false,
      orderDatalist: {},
      rules: {
        status: [{
          required: true,
          message: "请选择审核状态",
          trigger: "change"
        }],
        fail_message: [{
          required: true,
          message: "请填写拒绝原因",
          trigger: "blur"
        }]
      },
      ruleForm: {
        fail_message: "",
        status: 1,
        order_id: ""
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    onOrderDetails: function onOrderDetails(id, cb) {
      var _this = this;
      if (!cb) {
        this.orderDatalist = {};
      }
      this.loading = true;
      (0, _order.auctionOrderDetailApi)(id).then(function (res) {
        _this.orderDatalist = res.data;
        _this.ruleForm.order_id = res.data.order_id;
        _this.loading = false;
      }).catch(function (_ref) {
        var message = _ref.message;
        _this.loading = false;
      });
    },
    onSubmit: function onSubmit(name) {
      var _this2 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          (0, _order.auctionOrderSwitchRefund)(_this2.ruleForm).then(function (res) {
            _this2.onOrderDetails(_this2.ruleForm.order_id, 1);
            _this2.$message.success(res.message);
            _this2.$emit("subSuccess");
          }).catch(function (res) {
            // this.$message.error(res.message);
          });
        }
      });
    },
    confirmReceiving: function confirmReceiving() {
      var _this3 = this;
      this.$confirm("确认收到货物，确认此操作后会自动退回款项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _order.auctionOrderRefundReceiving)({
          order_id: _this3.ruleForm.order_id
        }).then(function (res) {
          _this3.onOrderDetails(_this3.ruleForm.order_id, 1);
          _this3.$message.success(res.message);
          _this3.$emit("subSuccess");
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      }).catch(function () {
        console.log("cancel");
      });
    }
  }
};