"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _index = require("@/utils/index");
var _digitalHall = require("@/api/digitalHall");
var _auction = require("@/api/auction");
var _open3d = require("@/api/open3d");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20
      },
      hasOpen: null,
      spaceId: '',
      albumId: '',
      showChangeAlbum: false,
      albumList: [],
      btnLoading: false
    };
  },
  computed: {
    merId: function merId() {
      return this.$store.getters.mer_id;
    },
    domain_url: function domain_url() {
      return this.$store.getters.domain_url;
    }
  },
  mounted: function mounted() {
    this.isAuth();
    var queryData = JSON.parse(JSON.stringify(this.$route.query));
    if (Number(queryData.page)) {
      this.tableFrom = queryData;
      this.tableFrom.page = Number(this.tableFrom.page);
      this.tableFrom.limit = Number(this.tableFrom.limit);
    }
    this.getList(1);
  },
  methods: {
    isAuth: function isAuth() {
      var is3dAuth = localStorage.getItem('is3dAuth');
      if (is3dAuth) {
        this.hasOpen = true;
        return false;
      } else {
        this.hasOpen = true;
        if (this.hasOpen) {
          localStorage.setItem('is3dAuth', '1');
        }
      }
      // isOpen3dApi().then(res => {
      //   this.hasOpen = !!res.data;
      //   if (this.hasOpen) {
      //     localStorage.setItem('is3dAuth', '1');
      //   }
      // });
    },
    showDetailAction: function showDetailAction(row) {
      window.open("".concat(this.domain_url.split('/#')[0], "/pages/threepreview?id=").concat(row.id));
    },
    createdAction: function createdAction() {
      this.$router.push("/merchant/digital_hall/manage/create");
    },
    editAction: function editAction(id) {
      window.open("/merchant/digital_hall/manage/edit3d?id=".concat(id));
      // this.$router.push(`/merchant/digital_hall/manage/edit3d?id=${id}`);
    },
    delAction: function delAction(id) {
      var _this = this;
      // delLiveApi
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u5C55\u5385?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _digitalHall.userExhibitionDel)(id).then(function () {
          _this.$message.success('操作成功');
          _this.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 选择时间
    selectChange: function selectChange() {
      this.timeVal = [];
      this.tableData.data = [];
      this.tableData.total = 0;
      this.getList(1);
    },
    // 选择时间
    selectChangeData: function selectChangeData(tab) {
      this.tableFrom.date = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join('-') : '';
      this.getList(1);
    },
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      (0, _digitalHall.userExhibitionListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    editVirtualAction: function editVirtualAction(val, index) {
      var _this3 = this;
      (0, _digitalHall.userExhibitionUpdateSort)({
        user_exhibition_id: this.tableData.data[index].user_exhibition_id,
        sort: val || 0
      }).then(function (res) {
        _this3.$message.success('设置成功');
      }).catch(function () {});
    },
    addAlbumAction: function addAlbumAction(row) {
      var _this4 = this;
      if (row.album_id && row.albumInfo) {
        this.albumList = [row.albumInfo];
      } else {
        this.albumList = [];
      }
      this.spaceId = row.id;
      this.albumId = row.album_id || '';
      this.showChangeAlbum = true;
      (0, _auction.albumlist)({
        page: 1,
        limit: 30,
        album_type: 3,
        status: 2
      }).then(function (response) {
        if (row.album_id && row.albumInfo && response.data.list.map(function (m) {
          return m.album_id;
        }).indexOf(row.album_id) === -1) {
          _this4.albumList = [row.albumInfo].concat(response.data.list);
        } else {
          _this4.albumList = response.data.list;
        }
      });
    },
    changeAlbumAuction: function changeAlbumAuction() {
      var _this5 = this;
      this.btnLoading = true;
      (0, _digitalHall.bindPmAlbumApi)(this.spaceId, {
        album_id: this.albumId
      }).then(function () {
        _this5.$message.success('操作成功');
        _this5.btnLoading = false;
        _this5.showChangeAlbum = false;
        _this5.getList();
      }).catch(function () {});
    }
  }
};