var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: { size: "small", "label-width": "0px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("el-form-item", { attrs: { label: " " } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("span", { staticClass: "fWeight500" }, [_vm._v("类型：")]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "mr20",
                    attrs: { type: "button" },
                    on: {
                      change: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    model: {
                      value: _vm.tableFrom.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFrom, "type", $$v)
                      },
                      expression: "tableFrom.type",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "" } }, [
                      _vm._v("全部"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "1" } }, [
                      _vm._v("满减券"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "2" } }, [
                      _vm._v("立减券"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "3" } }, [
                      _vm._v("折扣券"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "4" } }, [
                      _vm._v("折扣券"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "fWeight500" }, [_vm._v("状态：")]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "mr20",
                    attrs: { type: "button" },
                    on: {
                      change: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    model: {
                      value: _vm.tableFrom.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFrom, "status", $$v)
                      },
                      expression: "tableFrom.status",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "" } }, [
                      _vm._v("全部"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "10" } }, [
                      _vm._v("待发行"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "20" } }, [
                      _vm._v("已发行"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "30" } }, [
                      _vm._v("停止发行"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "40" } }, [
                      _vm._v("已结束"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("span", { staticClass: "fWeight500" }, [
                _vm._v("优惠券名称："),
              ]),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "请输入优惠券名称", clearable: "" },
                  model: {
                    value: _vm.tableFrom.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "name", $$v)
                    },
                    expression: "tableFrom.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "" + _vm.roterPre + "/marketing/coupon/creatCoupon",
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("添加优惠劵")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            data: _vm.tableData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "ID", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "优惠劵名称", "min-width": "120" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "优惠劵类型", "min-width": "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.type_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "优惠方式", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.type == 1
                      ? _c("span", [
                          _vm._v(
                            _vm._s(row.rule.type == 2 ? "每" : "") +
                              "满" +
                              _vm._s(row.rule.full) +
                              "元减" +
                              _vm._s(row.rule.reduction) +
                              "元"
                          ),
                        ])
                      : row.type == 2
                      ? _c("span", [
                          _vm._v("立减" + _vm._s(row.rule.reduction) + "元"),
                        ])
                      : row.type == 3
                      ? _c("span", [
                          _vm._v(_vm._s(row.rule.reduction / 10) + "折"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "人群限制", "min-width": "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.crowd_limit_name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "120", label: "使用范围" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.use_range, function (m, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        (m[0] || m[0] === 0) && m[0] != -1
                          ? [
                              i == 0
                                ? _c("span", [_vm._v("商品：")])
                                : i == 7
                                ? _c("span", [_vm._v("拍品：")])
                                : i == 8
                                ? _c("span", [_vm._v("数字藏品：")])
                                : i == 9
                                ? _c("span", [_vm._v("数字盲盒：")])
                                : i == 10
                                ? _c("span", [_vm._v("实物盲盒：")])
                                : i == 20
                                ? _c("span", [_vm._v("鉴定：")])
                                : _vm._e(),
                              _vm._v(" "),
                              m[0] == 0
                                ? _c("span", [_vm._v("全部")])
                                : _c("span", [_vm._v(_vm._s(m.length) + "件")]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "100", label: "已领取/数量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.total_num - row.surplus_num) +
                          "/" +
                          _vm._s(row.total_num)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "150", label: "可用时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.failure_type == 1
                      ? _c("div", [
                          _vm._v(
                            "\n          " + _vm._s(row.start_time) + " - "
                          ),
                          _c("br"),
                          _vm._v(_vm._s(row.end_time) + "\n        "),
                        ])
                      : _c("span", [_vm._v(_vm._s(row.effective_day) + "天")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 10
                      ? _c("span", { staticStyle: { color: "#EE4C4C" } }, [
                          _vm._v(_vm._s(scope.row.status_name)),
                        ])
                      : scope.row.status == 20
                      ? _c("span", { staticStyle: { color: "#5DB741" } }, [
                          _vm._v(_vm._s(scope.row.status_name)),
                        ])
                      : _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v(_vm._s(scope.row.status_name)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "150", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 10
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.issueAction(
                                    scope.row.id,
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [_vm._v("发行")]
                          ),
                        ]
                      : scope.row.status == 20
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.onchangeIsShow(
                                    scope.row.id,
                                    scope.$index,
                                    30
                                  )
                                },
                              },
                            },
                            [_vm._v("停止发行")]
                          ),
                        ]
                      : scope.row.status == 30
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.onchangeIsShow(
                                    scope.row.id,
                                    scope.$index,
                                    20
                                  )
                                },
                              },
                            },
                            [_vm._v("继续发行")]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status != 40
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.editAction(scope.row.id)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonedit" },
                              }),
                              _vm._v("编辑"),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 10
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(
                                    scope.row.id,
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 40
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.goLinkAction("user", scope.row)
                                },
                              },
                            },
                            [_vm._v("领取记录")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.goLinkAction("use", scope.row)
                                },
                              },
                            },
                            [_vm._v("使用记录")]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            staticClass: "mt20",
            staticStyle: { "text-align": "right" },
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }