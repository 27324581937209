"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _auction = require("@/api/auction");
var _index = require("@/utils/index");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      formatDate: _index.parseTime,
      list: null,
      listLoading: true,
      queryParams: {
        page: 1,
        limit: 10,
        status: '3'
      },
      total: 0,
      venue_id: '',
      changePlatform: false,
      setAlbumId: '',
      platformList: [],
      status: null,
      adminPhone: '',
      companyName: '',
      code: '',
      signSync: false,
      isSend: false,
      count: 60
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.venue_id = this.$route.query.vid || '';
    if (this.venue_id) {
      this.queryParams.venue_id = this.venue_id;
    }
    (0, _auction.proxyPlatformSignStatusApi)().then(function (res) {
      _this.status = Number(res.data.status);
      _this.adminPhone = res.data.adminPhone;
      _this.companyName = res.data.companyName;
    });
    this.load();
  },
  methods: {
    showSignAction: function showSignAction() {
      this.signSync = true;
    },
    sendMsg: function sendMsg() {
      var _this2 = this;
      if (this.isSend) return false;
      (0, _auction.proxyPlatformSendSignSmsApi)().then();
      this.isSend = true;
      (0, _index.countDown)(60, function (h, m, s) {
        if (s) {
          _this2.count = s;
        } else {
          _this2.isSend = false;
        }
      });
    },
    confirmSign: function confirmSign() {
      var _this3 = this;
      if (!this.code) {
        this.$message.error('请输入验证码');
        return;
      }
      if (isNaN(this.code)) {
        this.$message.error('验证码只能为数字');
        return;
      }
      if (this.lock) return;
      this.lock = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _auction.proxyPlatformSignApi)({
        sms_code: this.code
      }).then(function (res) {
        loading.close();
        _this3.$message.success('签约成功');
        _this3.signSync = false;
        _this3.status = 1;
        _this3.lock = false;
      }).catch(function () {
        loading.close();
        _this3.lock = false;
      });
    },
    showChangePlatformAction: function showChangePlatformAction(id) {
      var _this4 = this;
      if (this.status === 0) {
        this.signSync = true;
        return;
      } else if (!this.status) {
        this.$message.error('出现错误，请刷新重试');
        return;
      }
      this.setAlbumId = id;
      this.changePlatform = true;
      (0, _auction.proxyPlatformListApi)({
        album_id: id
      }).then(function (res) {
        res.data.forEach(function (m) {
          if (!m.isConfig) {
            m.isSetExemptNumberPlate = 1;
            m.numberPlate = '';
          }
          if (m.userNumber) {
            m.isSetExemptNumberPlate = 1;
            m.numberPlate = m.userNumber.number;
            m.isSet = true;
          }
        });
        _this4.platformList = res.data;
      });
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex < 4 || columnIndex > 7) {
        return {
          rowspan: row.rowspan,
          colspan: 1
        };
      }
    },
    load: function load() {
      var _this5 = this;
      this.listLoading = true;
      (0, _auction.proxyPlatformAlbumListApi)(this.queryParams).then(function (response) {
        var data = [];
        response.data.forEach(function (m, i) {
          if (m.proxyPlatformList && m.proxyPlatformList.length) {
            m.proxyPlatformList.forEach(function (item, index) {
              data.push((0, _objectSpread2.default)({
                rowspan: index === 0 ? m.proxyPlatformList.length : 0,
                platformData: item
              }, m));
            });
          } else {
            data.push((0, _objectSpread2.default)({
              rowspan: 1
            }, m));
          }
        });
        // this.total = response.data;
        _this5.list = data;
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    sycsAlbum: function sycsAlbum() {
      var _this6 = this;
      var list = this.platformList.filter(function (val) {
        return !!val.isConfig;
      });
      var platformList = [];
      var error = '';
      list.forEach(function (m) {
        platformList.push({
          proxyPlatformId: m.id,
          isSetExemptNumberPlate: m.isSetExemptNumberPlate || 0,
          numberPlate: m.numberPlate || ''
        });
        if (m.isSetExemptNumberPlate && !m.numberPlate) {
          error = '请设置免保号牌';
        } else if (m.isSetExemptNumberPlate && isNaN(m.numberPlate)) {
          error = '请设置正确的免保号牌，号牌只能是数字';
        }
      });
      if (error) {
        this.$message.error(error);
        return;
      }
      if (platformList.length <= 0) {
        this.$message.error('请先设置要合作的代拍平台');
        return;
      }
      this.$confirm('确定与选择的代拍平台进行合作？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.setAlbumProxyPlatformApi)({
          album_id: _this6.setAlbumId,
          proxyPlatformData: platformList
          // proxyPlatformIds: list.length ? list.map(m => m.id).join(',') : ''
        }).then(function (res) {
          _this6.$message({
            type: 'success',
            message: '操作成功'
          });
          _this6.changePlatform = false;
          _this6.load();
        }).catch(function (err) {});
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    pushAction: function pushAction(id, appId, is_pay) {
      var _this7 = this;
      if (!is_pay) {
        this.$message({
          type: 'warning',
          message: '请先支付!'
        });
        return;
      }
      this.$confirm("\u786E\u8BA4\u63A8\u9001?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.pushAuctionDataApi)({
          album_id: id,
          appid: appId
        }).then(function () {
          _this7.$message.success('操作成功');
          _this7.load();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    payServicePriceAction: function payServicePriceAction(id, appId) {
      var _this8 = this;
      this.$confirm("\u786E\u8BA4\u652F\u4ED8\u6280\u672F\u670D\u52A1\u8D39?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.payServiceChargeApi)({
          album_id: id,
          appid: appId
        }).then(function () {
          _this8.$message.success('操作成功');
          _this8.load();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};