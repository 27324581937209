var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "-10px" } },
                [
                  _c(
                    "el-tabs",
                    {
                      on: {
                        "tab-click": function ($event) {
                          return _vm.getList(1)
                        },
                      },
                      model: {
                        value: _vm.tableFrom.is_del,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "is_del", $$v)
                        },
                        expression: "tableFrom.is_del",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "委托人", name: "0" },
                      }),
                      _vm._v(" "),
                      _c("el-tab-pane", {
                        attrs: { label: "回收站", name: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "demo-input-suffix acea-row" },
                  [
                    _c(
                      "el-form",
                      { attrs: { inline: "", size: "small" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "名称：" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  type: "text",
                                  placeholder: "名称/手机号/ID",
                                },
                                model: {
                                  value: _vm.tableFrom.nicknameOrPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tableFrom,
                                      "nicknameOrPhone",
                                      $$v
                                    )
                                  },
                                  expression: "tableFrom.nicknameOrPhone",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-search",
                                    size: "small",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.search(1)
                                    },
                                  },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "UID：" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { type: "text", placeholder: "" },
                                model: {
                                  value: _vm.tableFrom.entrust_uid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tableFrom, "entrust_uid", $$v)
                                  },
                                  expression: "tableFrom.entrust_uid",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-search",
                                    size: "small",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.search(1)
                                    },
                                  },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.createStore },
                },
                [_vm._v("创建合作方")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  ref: "uploadImg",
                  staticClass: "upload-demo mr10 mb15",
                  staticStyle: { display: "inline-block" },
                  attrs: {
                    action: _vm.fileUrl,
                    "on-success": _vm.handleSuccess,
                    headers: _vm.myHeaders,
                    data: { import_type: "import_user_entrust" },
                    "show-file-list": true,
                    name: "excel",
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("导入")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出记录")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.downLoad },
                },
                [_vm._v("下载导入模板")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "mini" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "entrust_id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "合作方", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrust_contact_company
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.entrust_contact_company) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "entrust_contact_name",
                  label: "合作方联系人",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "证件类型", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.card_type == 0 ? "身份证" : "护照"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "nationality",
                  label: "国籍",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "card_number",
                  label: "证件号码",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "entrust_contact_phone",
                  label: "手机号",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "contact_address",
                  label: "通讯地址",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark", "min-width": "200" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "8",
                attrs: {
                  label: "操作",
                  "min-width": "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        !scope.row.is_del
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 1px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "commondelete" },
                                }),
                                _vm._v("删除"),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.is_del
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 1px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.restoreApi(scope.row)
                                  },
                                },
                              },
                              [_vm._v("恢复")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block flex align-items-c" },
            [
              _c("div", { staticClass: "flex-one" }),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建合作方",
            visible: _vm.createFlag,
            width: "1000px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "合作方名称：",
                            prop: "entrust_contact_company",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入委托方：" },
                            model: {
                              value: _vm.formValidate.entrust_contact_company,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "entrust_contact_company",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.entrust_contact_company",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "合作方联系人：",
                            prop: "entrust_contact_name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入委托人联系人：" },
                            model: {
                              value: _vm.formValidate.entrust_contact_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "entrust_contact_name",
                                  $$v
                                )
                              },
                              expression: "formValidate.entrust_contact_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机：",
                            prop: "entrust_contact_phone",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入手机" },
                            model: {
                              value: _vm.formValidate.entrust_contact_phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "entrust_contact_phone",
                                  $$v
                                )
                              },
                              expression: "formValidate.entrust_contact_phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "证件类型：", prop: "card_type" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formValidate.card_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "card_type", $$v)
                                },
                                expression: "formValidate.card_type",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("身份证"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("护照"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "证件号码：", prop: "card_number" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入证件号码" },
                            model: {
                              value: _vm.formValidate.card_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "card_number", $$v)
                              },
                              expression: "formValidate.card_number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "国籍：", prop: "nationality" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入国籍" },
                            model: {
                              value: _vm.formValidate.nationality,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "nationality", $$v)
                              },
                              expression: "formValidate.nationality",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "通讯地址：", prop: "contact_address" } },
                    [
                      _c("span", [_vm._v("（接收信函地址）")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "70%" },
                        attrs: { placeholder: "请输入通讯地址" },
                        model: {
                          value: _vm.formValidate.contact_address,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "contact_address", $$v)
                          },
                          expression: "formValidate.contact_address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定电话：", prop: "fixed_phone" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入固定电话" },
                            model: {
                              value: _vm.formValidate.fixed_phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "fixed_phone", $$v)
                              },
                              expression: "formValidate.fixed_phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "传真：", prop: "fax" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入传真" },
                            model: {
                              value: _vm.formValidate.fax,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "fax", $$v)
                              },
                              expression: "formValidate.fax",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Email：", prop: "email" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入邮箱" },
                            model: {
                              value: _vm.formValidate.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "email", $$v)
                              },
                              expression: "formValidate.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开户行：", prop: "account_bank" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formValidate.account_bank,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "account_bank", $$v)
                              },
                              expression: "formValidate.account_bank",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "开户名称：", prop: "account_name" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formValidate.account_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "account_name", $$v)
                              },
                              expression: "formValidate.account_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "银行账号：",
                            prop: "account_bank_card_number",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formValidate.account_bank_card_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "account_bank_card_number",
                                  $$v
                                )
                              },
                              expression:
                                "formValidate.account_bank_card_number",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "期初应付：", prop: "begin_amount" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formValidate.begin_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "begin_amount", $$v)
                              },
                              expression: "formValidate.begin_amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "期末应付：", prop: "end_amount" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formValidate.end_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "end_amount", $$v)
                              },
                              expression: "formValidate.end_amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "税率：", prop: "rate" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formValidate.rate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "rate", $$v)
                              },
                              expression: "formValidate.rate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.formValidate.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "remark", $$v)
                      },
                      expression: "formValidate.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关联用户：", prop: "entrust_uid" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择用户",
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.searchUserAction,
                                loading: _vm.searchLoading,
                              },
                              model: {
                                value: _vm.formValidate.entrust_uid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "entrust_uid", $$v)
                                },
                                expression: "formValidate.entrust_uid",
                              },
                            },
                            _vm._l(_vm.userList, function (m, i) {
                              return _c("el-option", {
                                key: "user" + i,
                                attrs: { value: m.uid, label: m.nickname },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合作方logo：", prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              attrs: { title: "750*750px" },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }