var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group", {
        on: { handleSetLineChartData: _vm.handleSetLineChartData },
      }),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticClass: "bordershan",
          staticStyle: { background: "#fff", margin: "20px 0" },
        },
        [
          _c(
            "div",
            { staticClass: "panel-title" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", [_vm._v("支付订单")]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "align-right", attrs: { span: 12 } },
                [
                  _c("i", { staticClass: "el-icon-date select-icon-date" }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item selWidth",
                      attrs: {
                        placeholder: "请选择",
                        "popper-class": "rewriteStyle",
                      },
                      on: {
                        change: function (value) {
                          _vm.getCurrentData(value)
                        },
                      },
                      model: {
                        value: _vm.time3,
                        callback: function ($$v) {
                          _vm.time3 = $$v
                        },
                        expression: "time3",
                      },
                    },
                    _vm._l(_vm.timeList1, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("line-chart", {
            ref: "lineChart",
            staticClass: "mt20",
            attrs: { "chart-data": _vm.lineChartData, date: _vm.time3 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticClass: "panel-warp bordershan",
          staticStyle: {
            height: "440px",
            margin: "0",
            "margin-bottom": "20px",
            overflow: "hidden",
          },
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 16 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "panel-title",
                  staticStyle: { background: "#fff", padding: "20px" },
                },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", [_vm._v("成交客户")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "align-right", attrs: { span: 12 } },
                    [
                      _c("i", { staticClass: "el-icon-date select-icon-date" }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.getCustomerData },
                          model: {
                            value: _vm.time1,
                            callback: function ($$v) {
                              _vm.time1 = $$v
                            },
                            expression: "time1",
                          },
                        },
                        _vm._l(_vm.timeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        background: "#fff",
                        height: "410px",
                        padding: "0 20px",
                        position: "relative",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "grid-floating",
                          staticStyle: { position: "absolute" },
                        },
                        [
                          _vm._v(
                            "\n            访客-下单转化率：\n            "
                          ),
                          _c(
                            "span",
                            { staticClass: "grid-conversion-number" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderCustomer.orderRate
                                    ? (
                                        _vm.orderCustomer.orderRate * 100
                                      ).toFixed(2)
                                    : ""
                                ) + "%"
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "grid-floating" }, [
                        _vm._v("\n            下单-支付转化率：\n            "),
                        _c("span", { staticClass: "grid-conversion-number" }, [
                          _vm._v(
                            _vm._s(
                              _vm.orderCustomer.payOrderRate
                                ? (
                                    _vm.orderCustomer.payOrderRate * 100
                                  ).toFixed(2)
                                : ""
                            ) + "%"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-blue",
                                attrs: { span: 18 },
                              },
                              [
                                _vm._v(
                                  "\n                访客人数\n                "
                                ),
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(_vm._s(_vm.orderCustomer.visitUser)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "blue-trapezoid bg-trapezoid",
                                attrs: { span: 10 },
                              },
                              [_c("span", [_vm._v("访客")])]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-green",
                                attrs: { span: 4 },
                              },
                              [
                                _vm._v(
                                  "\n                下单人数\n                "
                                ),
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.orderCustomer.orderUser) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-green",
                                attrs: { span: 4 },
                              },
                              [
                                _vm._v(
                                  "\n                下单金额\n                "
                                ),
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(_vm._s(_vm.orderCustomer.orderPrice)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-col", {
                              staticClass: "bg-color bg-green",
                              staticStyle: { height: "100px" },
                              attrs: { span: 8 },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "green-trapezoid bg-trapezoid",
                                attrs: { span: 10 },
                              },
                              [_c("span", [_vm._v("下单")])]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-gray-dark",
                                attrs: { span: 4 },
                              },
                              [
                                _vm._v(
                                  "\n                支付人数\n                "
                                ),
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(
                                    _vm._s(_vm.orderCustomer.payOrderUser)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-gray-dark",
                                attrs: { span: 4 },
                              },
                              [
                                _vm._v(
                                  "\n                支付金额\n                "
                                ),
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(
                                    _vm._s(_vm.orderCustomer.payOrderPrice)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "bg-color bg-gray-dark",
                                attrs: { span: 4 },
                              },
                              [
                                _vm._v(
                                  "\n                客单价\n                "
                                ),
                                _c("span", { staticClass: "grid-count" }, [
                                  _vm._v(_vm._s(_vm.orderCustomer.userRate)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-col", {
                              staticClass: "bg-color bg-gray-dark",
                              staticStyle: { height: "100px" },
                              attrs: { span: 2 },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "gray-dark-trapezoid bg-trapezoid",
                                attrs: { span: 10 },
                              },
                              [_c("span", [_vm._v("支付")])]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 8 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "panel-title",
                  staticStyle: {
                    background: "#fff",
                    padding: "20px 20px 50px",
                  },
                },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", [_vm._v("成交客户占比")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "align-right", attrs: { span: 16 } },
                    [
                      _c("i", { staticClass: "el-icon-date select-icon-date" }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth",
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function (value) {
                              _vm.getCustomerRatioData(value)
                            },
                          },
                          model: {
                            value: _vm.time2,
                            callback: function ($$v) {
                              _vm.time2 = $$v
                            },
                            expression: "time2",
                          },
                        },
                        _vm._l(_vm.timeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "pieChart-switch" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: _vm.isAmount ? "primary" : "",
                                value: "金额",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.chooseAmount($event)
                                },
                              },
                            },
                            [_vm._v("金额")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: !_vm.isAmount ? "primary" : "",
                                value: "客户数",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.chooseCustomers($event)
                                },
                              },
                            },
                            [_vm._v("客户数")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c("pie-chart", {
                    ref: "pieChart",
                    attrs: { amount: _vm.isAmount, date: _vm.time2 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticClass: "bordershan",
          staticStyle: { margin: "20px 0" },
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            {
              staticStyle: { "margin-bottom": "30px" },
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 12 },
                lg: { span: 8 },
                xl: { span: 8 },
              },
            },
            [
              _c(
                "el-row",
                {
                  staticClass: "panel-title",
                  staticStyle: { background: "#fff" },
                },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", [_vm._v("商品支付排行")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "align-right", attrs: { span: 16 } },
                    [
                      _c("i", { staticClass: "el-icon-date select-icon-date" }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth",
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function (value) {
                              _vm.getRankingData(value)
                            },
                          },
                          model: {
                            value: _vm.rankingTime1,
                            callback: function ($$v) {
                              _vm.rankingTime1 = $$v
                            },
                            expression: "rankingTime1",
                          },
                        },
                        _vm._l(_vm.timeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-title-count" },
                [
                  _c(
                    "el-row",
                    { staticClass: "grid-title" },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-left": "20px" },
                          attrs: { span: 4 },
                        },
                        [_vm._v("排名")]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 16 } }, [_vm._v("名称")]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 4 } }, [_vm._v("支付数")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-list-content" },
                _vm._l(_vm.commodityPaymentList, function (list, index) {
                  return _c(
                    "el-row",
                    { key: index, staticClass: "grid-count" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 4 } },
                        [
                          _c(
                            "span",
                            { class: index > 2 ? "gray" : "navy-blue" },
                            [_vm._v(_vm._s(index + 1))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 16 } },
                        [
                          _c("img", { attrs: { src: list.picSrc, alt: "" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(list.name))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 4 } },
                        [_vm._v(_vm._s(list.count))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { "margin-bottom": "30px" },
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 12 },
                lg: { span: 8 },
                xl: { span: 8 },
              },
            },
            [
              _c(
                "el-row",
                {
                  staticClass: "panel-title",
                  staticStyle: { background: "#fff" },
                },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", [_vm._v("商品访客排行")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "align-right", attrs: { span: 16 } },
                    [
                      _c("i", { staticClass: "el-icon-date select-icon-date" }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth",
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function (value) {
                              _vm.getVisitorRankingData(value)
                            },
                          },
                          model: {
                            value: _vm.rankingTime2,
                            callback: function ($$v) {
                              _vm.rankingTime2 = $$v
                            },
                            expression: "rankingTime2",
                          },
                        },
                        _vm._l(_vm.timeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-title-count" },
                [
                  _c(
                    "el-row",
                    { staticClass: "grid-title" },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-left": "20px" },
                          attrs: { span: 4 },
                        },
                        [_vm._v("排名")]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 16 } }, [_vm._v("名称")]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 4 } }, [_vm._v("访问数")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-list-content" },
                _vm._l(_vm.visitorRankingList, function (list, index) {
                  return _c(
                    "el-row",
                    { key: index, staticClass: "grid-count" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 4 } },
                        [
                          _c(
                            "span",
                            { class: index > 2 ? "gray" : "navy-blue" },
                            [_vm._v(_vm._s(index + 1))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 16 } },
                        [
                          _c("img", { attrs: { src: list.image, alt: "" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(list.store_name))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 4 } },
                        [_vm._v(_vm._s(list.total))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { "margin-bottom": "30px" },
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 12 },
                lg: { span: 8 },
                xl: { span: 8 },
              },
            },
            [
              _c(
                "el-row",
                {
                  staticClass: "panel-title",
                  staticStyle: { background: "#fff" },
                },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", [_vm._v("商品加购排行")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "align-right", attrs: { span: 16 } },
                    [
                      _c("i", { staticClass: "el-icon-date select-icon-date" }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item selWidth",
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function (value) {
                              _vm.getProductPlusData(value)
                            },
                          },
                          model: {
                            value: _vm.rankingTime3,
                            callback: function ($$v) {
                              _vm.rankingTime3 = $$v
                            },
                            expression: "rankingTime3",
                          },
                        },
                        _vm._l(_vm.timeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-title-count" },
                [
                  _c(
                    "el-row",
                    { staticClass: "grid-title" },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-left": "20px" },
                          attrs: { span: 4 },
                        },
                        [_vm._v("排名")]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 16 } }, [_vm._v("名称")]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 4 } }, [_vm._v("支付数")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "grid-list-content" },
                _vm._l(_vm.productPlusList, function (list, index) {
                  return _c(
                    "el-row",
                    { key: index, staticClass: "grid-count" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 4 } },
                        [
                          _c(
                            "span",
                            { class: index > 2 ? "gray" : "navy-blue" },
                            [_vm._v(_vm._s(index + 1))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 16 } },
                        [
                          _c("img", { attrs: { src: list.image, alt: "" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(list.store_name))]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "grid-list", attrs: { span: 4 } },
                        [_vm._v(_vm._s(list.total))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }