"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _order = require("@/api/order");
var _nft = require("@/api/nft");
var _product = require("@/api/product");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = require("@/utils/index");
var _system = require("@/api/system");
var _batchUploadTemplate = _interopRequireDefault(require("@/components/batchUploadTemplate.vue"));
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default,
    batchUploadTemplate: _batchUploadTemplate.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      queryParams: {
        page: 1,
        limit: 10,
        status: ''
      },
      keyword: '',
      file_type: '',
      category: [],
      status: '',
      business_type: '',
      categoryList: [],
      showImg: false,
      showImgUrl: '',
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      showBatchUpload: false,
      orderChartType: {}
    };
  },
  created: function created() {
    var _this = this;
    (0, _nft.nftCollectionAlbumFilterApi)(this.tableFrom).then(function (res) {
      _this.orderChartType = res.data;
    });
    this.getList(1);
  },
  methods: {
    batchUploadAuction: function batchUploadAuction(id) {
      var _this2 = this;
      // 批量上传
      this.showBatchUpload = true;
      this.$nextTick(function () {
        _this2.$refs['batchTemplate'].init(id);
      });
    },
    closeBatchAction: function closeBatchAction() {
      this.showBatchUpload = false;
    },
    setTypeAction: function setTypeAction() {
      var params = (0, _index.getQueryObject)();
      for (var key in this.queryParams) {
        var m = this.queryParams[key];
        params[key] = m;
      }
      this.$router.replace({
        query: params
      });
    },
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.queryParams.page = num || this.queryParams.page;
      this.setTypeAction();
      (0, _nft.nftCollectionAlbumListApi)(this.queryParams).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    goSearch: function goSearch() {
      this.queryParams.keyword = this.keyword;
      this.getList(1);
    },
    resetAction: function resetAction() {
      this.keyword = '';
      this.file_type = '';
      this.status = '';
      this.category = [];
      this.goSearch();
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this4 = this;
      (0, _order.exportOrderApi)(this.queryParams).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this4.$createElement;
        _this4.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this4.$msgbox.close();
                _this4.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('auction_order');
    },
    addAction: function addAction() {
      this.$router.push('./album_detail');
    },
    editAction: function editAction(id) {
      this.$router.push('./album_detail?id=' + id);
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this5 = this;
      (0, _product.batchstatusApi)({
        id: row.product_id,
        status: Number(!row.mer_status)
      }).then(function (_ref) {
        var message = _ref.message;
        _this5.$message.success(message);
        _this5.checkedIds = [];
        _this5.getList('');
      });
    },
    delAction: function delAction(id) {
      var _this6 = this;
      this.$confirm('确认删除此系列？', '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      }).then(function () {
        (0, _nft.nftCollectionAlbumDelApi)(id).then(function () {
          _this6.$message.success('操作成功');
          _this6.getList();
        });
      }).catch(function (_ref2) {
        var message = _ref2.message;
      });
    },
    copyAction: function copyAction(val) {
      var input = document.createElement('input'); // 直接构建input
      input.value = val; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    previewAction: function previewAction(id, key) {
      var _this7 = this;
      this.showImg = true;
      (0, _system.createQRCodeApi)({
        path: "/pages/nftCollection/index?id=".concat(id),
        type: 1
      }).then(function (res) {
        _this7.showImgUrl = res.data.base_image;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.limit = val;
      this.getList();
    },
    downloadAction: function downloadAction() {
      window.open(_settingMer.default.https + "/excel/templateDownload?type=import_nft_collection&token=" + (0, _auth.getToken)());
    },
    addWorkAction: function addWorkAction(id) {
      this.$router.push('./detail?aid=' + id);
    },
    showWorkAction: function showWorkAction(id) {
      this.$router.push('./list?album_id=' + id);
    }
  }
};