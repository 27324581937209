"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/web.dom.iterable");
var _basic = require("@/api/basic");
var _nft = require("@/api/nft");
var _product = require("@/api/product");
var _auction = require("@/api/auction");
var _system = require("@/api/system");
var _live = require("@/api/live");
var _work = require("@/api/work");
var _digitalHall = require("@/api/digitalHall");
var _textEdit = _interopRequireDefault(require("./textEdit.vue"));
//

var lock = false;
var listTimeout = null;
var _default = exports.default = {
  components: {
    textEdit: _textEdit.default
  },
  props: ["tabType", "open3dEl"],
  data: function data() {
    return {
      id: this.$route.query.id,
      list: [],
      query: {
        page: 1,
        limit: 12
      },
      hasMore: true,
      isLoading: false,
      checkAll: false,
      placeholderCount: "",
      // 总展位数
      open3dHasData: [],
      // 3d场景里存在的数据
      exhibitionType: "",
      // open3d类型
      merCateList: [],
      // 分类信息

      label_id: "",
      file_type: "",
      keyword: "",
      mer_cate_id: "",
      attachment_category_id: "",
      text: "",
      props: {
        emitPath: false
      },
      treeData2: [],
      labelList: [],
      control: ''
    };
  },
  watch: {
    tabType: function tabType(val) {
      this.editExhibitionTypeAction(val);
    }
  },
  computed: {
    changePlaceholder: function changePlaceholder() {
      return this.list.filter(function (item) {
        return item.active;
      }).length + this.open3dHasData.length;
    },
    open3dHasDataIds: function open3dHasDataIds() {
      return this.open3dHasData.map(function (m) {
        return m.id;
      });
    }
  },
  created: function created() {
    var _this = this;
    this.open3dHasData = this.open3dEl.getExhibitionData().all || []; // 获取展厅里的藏品
    var countData = this.open3dEl.getPlaceholderCount(); // 获得展位数量
    this.placeholderCount = countData.allPlaceholderCount; // 展位总数量
    this.editExhibitionTypeAction(this.tabType);
    (0, _basic.labelLstApi)({
      page: 1,
      size: 15
    }).then(function (res) {
      _this.labelList = res.data.list;
    });
  },
  methods: {
    getList: function getList(num) {
      var _this2 = this;
      this.isLoading = true;
      this.query.page = num || this.query.page;
      var data = JSON.parse(JSON.stringify(this.query));
      data.keyword = this.keyword || "";
      data.attachment_name = this.keyword || "";
      var action = _nft.nftCollectionListApi;
      switch (this.exhibitionType) {
        case "exhibition":
          data.title = this.keyword;
          data.label_id = this.label_id;
          action = _work.workListApi;
          break;
        case "product":
          data.mer_cate_id = this.mer_cate_id;
          action = _product.productLstApi;
          break;
        case "auction":
          data.cate_id = this.mer_cate_id;
          action = _auction.auctionList;
          break;
        case 'material_img':
          data.attachment_category_id = this.attachment_category_id;
          data.attachment_type = 1;
          action = _system.attachmentListApi;
          break;
        case 'material_video':
          data.attachment_category_id = this.attachment_category_id;
          data.attachment_type = 2;
          action = _system.attachmentListApi;
          break;
        case "live":
          action = _live.liveList;
          break;
        case "nft":
          action = _digitalHall.getTempListApi;
          data.type = 3;
          break;
      }
      action(data).then(function (res) {
        var d = [];
        if (_this2.exhibitionType == 'exhibition') {
          res.data.list.forEach(function (m) {
            var t = 1;
            switch (m.file_type) {
              case 'image':
                t = 1;
                break;
              case 'video':
                t = 2;
                break;
              case 'audio':
                t = 3;
                break;
              case '3d':
                t = 4;
                break;
            }
            d.push({
              id: m.works_id,
              type: t,
              name: m.title,
              url: m.file_url + (t == 1 ? '!m640' : ''),
              coverImage: m.file_cover + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 1,
                type: "work"
              }
            });
          });
        } else if (_this2.exhibitionType == "auction") {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.product_id,
              type: 1,
              name: m.product.store_name,
              url: m.product.image + '!m640',
              coverImage: m.product.image + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 1,
                type: "auction"
              }
            });
          });
        } else if (_this2.exhibitionType == "product") {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.product_id,
              type: 1,
              name: m.store_name,
              url: m.image + '!m640',
              coverImage: m.image + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 3,
                type: "product"
              }
            });
          });
        } else if (_this2.exhibitionType == "nft") {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.memberNftId,
              type: 1,
              name: m.nftName,
              url: m.modelFileUrl,
              coverImage: m.picture + '!webp2600',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 7,
                type: "nft"
              }
            });
          });
        } else if (_this2.exhibitionType.indexOf('material_') == 0) {
          res.data.list.forEach(function (m) {
            var obj = {
              id: m.attachment_id,
              type: m.attachment_type,
              name: m.attachment_name,
              url: m.attachment_src + (m.attachment_type == 1 ? '!m640' : ''),
              coverImage: m.attachment_src + (m.attachment_type == 1 ? '!m640' : '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'),
              exhibitionType: "material",
              active: false,
              extends: {
                work_type: 5,
                type: _this2.exhibitionType
              }
            };
            d.push(obj);
          });
        } else if (_this2.exhibitionType == "live") {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.live_id,
              type: 5,
              name: m.live_title,
              url: m.cover_image + '!m640',
              coverImage: m.cover_image + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 6,
                type: "live"
              }
            });
          });
        } else {
          res.data.list.forEach(function (m) {
            d.push({
              id: m.product_id,
              type: 1,
              name: m.product.store_name,
              url: m.product.image + '!m640',
              coverImage: m.product.image + '!m640',
              exhibitionType: "exhibition",
              active: false,
              extends: {
                work_type: 1,
                type: "exhibition"
              }
            });
          });
        }
        if (data.page == 1) {
          _this2.list = d;
        } else {
          _this2.list = _this2.list.concat(d);
        }
        if (_this2.list.length >= res.data.count) {
          _this2.hasMore = false;
        } else {
          _this2.hasMore = true;
        }
        _this2.isLoading = false;
        _this2.checkAll = false;

        // this.workList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    reloadList: function reloadList() {
      this.list = [];
      this.hasMore = true;
      this.isLoading = true;
      this.query.page = 1;
    },
    editExhibitionTypeAction: function editExhibitionTypeAction(val) {
      var _this3 = this;
      // 切换类型
      if (this.exhibitionType != val) {
        this.exhibitionType = val;
        if (val == "auction" || val == "product") {
          if (this.merCateList.length <= 0) {
            (0, _product.categorySelectTreeApi)().then(function (res) {
              _this3.merCateList = res.data;
            }).catch(function (res) {});
          }
        } else if (val.indexOf("material_") == 0) {
          if (this.treeData2.length <= 0) {
            var data = {
              attachment_category_name: "全部图片",
              attachment_category_id: 0
            };
            (0, _system.formatLstApi)().then(function (res) {
              _this3.treeData = res.data;
              _this3.treeData.unshift(data);
              _this3.treeData2 = (0, _toConsumableArray2.default)(_this3.treeData);
            }).catch(function (res) {
              // this.$message.error(res.message);
            });
          }
        }
        this.refreshAction();
      }
    },
    goSearchAction: function goSearchAction() {
      this.query.page = 1;
      this.getList();
    },
    listScrollAction: function listScrollAction(e) {
      var _this4 = this;
      if (listTimeout) clearTimeout(listTimeout);
      listTimeout = setTimeout(function () {
        var scrollBottom =
        // 滚动到底部的距离
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
        if (scrollBottom < 50) {
          if (_this4.hasMore && !_this4.isLoading) {
            _this4.query.page += 1;
            _this4.getList();
          }
        }
      }, 100);
    },
    dragendAction: function dragendAction(e, index) {
      // 拖拽结束事件

      var mouseX = e.clientX;
      var mouseY = e.clientY;
      var parentRect = this.$refs.open3dPropleftEL.getBoundingClientRect(); // 获取添加展品弹出层的边界信息

      if (mouseX < parentRect.left || mouseX > parentRect.right || mouseY < parentRect.top || mouseY > parentRect.bottom) {
        // 判断拖拽到3d场景中
        var m = JSON.parse(JSON.stringify(this.list[index]));
        if (this.open3dEl) {
          console.log({
            id: m.id,
            name: m.name,
            coverImage: m.coverImage,
            url: m.url,
            type: m.type,
            exhibitionType: this.exhibitionType.indexOf("material_") == 0 ? "material" : "exhibition",
            extends: m.extends || {}
          });
          this.open3dEl.drawExhibition({
            id: m.id,
            name: m.name,
            coverImage: m.coverImage,
            url: m.url,
            type: m.type,
            exhibitionType: this.exhibitionType.indexOf("material_") == 0 ? "material" : "exhibition",
            extends: m.extends || {}
          }, e);
        }
        this.open3dHasData = this.open3dHasData.concat(m);
      }
    },
    changeAction: function changeAction(index) {
      // 展品、素材等点击选中/取消选中  如果已在场景里  return
      if (this.open3dHasDataIds.indexOf(this.list[index].id) != -1) return false;
      this.list[index].active = !this.list[index].active;
    },
    checkAllAction: function checkAllAction() {
      var _this5 = this;
      try {
        this.checkAll = !this.checkAll;
        this.list.forEach(function (m, i) {
          if (_this5.open3dHasDataIds.indexOf(m.id) == -1) {
            if (_this5.changePlaceholder >= _this5.placeholderCount) {
              throw new Error("超出总展位数");
            }
            _this5.list[i].active = _this5.checkAll;
          }
        });
      } catch (message) {
        this.checkAll = false;
      }
    },
    quickAction: function quickAction() {
      var _this6 = this;
      // 一键布展
      if (lock) return false;
      lock = true;
      var activeList = [];
      this.list.forEach(function (m) {
        if (m.active) {
          activeList.push(m);
        }
      });
      if (activeList.length) {
        if (this.open3dEl) {
          this.open3dEl.drawExhibitionQuick(activeList.map(function (m) {
            return {
              id: m.id,
              name: m.name,
              coverImage: m.coverImage,
              url: m.url,
              type: m.type,
              exhibitionType: _this6.exhibitionType.indexOf("material_") == 0 ? "material" : "exhibition",
              extends: m.extends || {}
            };
          }));
        }
        lock = false;
        this.open3dHasData = this.open3dHasData.concat(activeList);
        this.list.forEach(function (m) {
          if (m.active) {
            m.active = false;
          }
        });
        this.$message.success('一键布展成功');
      }
    },
    changeControls: function changeControls(type) {
      if (this.control == type) {
        this.control = "";
        if (this.open3dEl) {
          this.open3dEl.changeControls(false);
        }
        return false;
      }
      this.control = type;
      if (this.open3dEl) {
        this.open3dEl.changeControls(true, type);
      }
    },
    changeEditTypeAction: function changeEditTypeAction(val) {
      var _this7 = this;
      this.productType = val;
      this.list = [];
      if (val == "auction" || val == "product") {
        if (this.merCateList.length <= 0) {
          (0, _product.categorySelectTreeApi)().then(function (res) {
            _this7.merCateList = res.data;
          }).catch(function (res) {});
        }
      } else if (val == 5) {
        if (this.treeData2.length <= 0) {
          var data = {
            attachment_category_name: "全部图片",
            attachment_category_id: 0
          };
          (0, _system.formatLstApi)().then(function (res) {
            _this7.treeData = res.data;
            _this7.treeData.unshift(data);
            _this7.treeData2 = (0, _toConsumableArray2.default)(_this7.treeData);
          }).catch(function (res) {
            // this.$message.error(res.message);
          });
        }
      }
      this.refreshAction();
    },
    changeTagAction: function changeTagAction(id) {
      if (this.label_id != id) {
        this.reloadList();
        this.label_id = id;
        this.file_type = "";
        this.keyword = "";
        this.mer_cate_id = "";
        this.attachment_category_id = "";
        this.text = "";
        this.getList(1);
      }
    },
    refreshAction: function refreshAction() {
      this.reloadList();
      this.label_id = "";
      this.file_type = "";
      this.keyword = "";
      this.mer_cate_id = "";
      this.attachment_category_id = "";
      this.text = "";
      this.getList(1);
    },
    inpAction: function inpAction(val) {
      if (this.open3dEl) {
        this.open3dEl.changeLockMove(val);
      }
    },
    textDrawAction: function textDrawAction(res) {
      // 文字相关交互
      if (this.open3dEl) {
        if (res.editType) {
          this.open3dEl[res.method](res.editType, res.data);
        } else {
          this.open3dEl[res.method](res.data);
        }
      }
    },
    kong: function kong(event) {
      event.preventDefault();
    }
  }
};