var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        color: "#000",
        "font-weight": "500",
        width: "1076px",
        margin: "0 auto",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            width: "1076px",
            overflow: "hidden",
            margin: "0 auto",
            "margin-top": "32px",
            "margin-left": "0",
            "justify-content": "space-between",
            "align-items": "center",
          },
        },
        [
          _c("div", { staticStyle: { float: "left" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "36px", color: "#000" } },
              [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                float: "right",
                "line-height": "48px",
                "font-size": "16px",
                color: "#000",
                "padding-top": "3px",
              },
            },
            [
              _c("span", [_vm._v("合同编号：")]),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "#000" } }, [
                _vm._v(_vm._s(_vm.contractno || "自动生成")),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "1076px",
          height: "3px",
          "margin-top": "32px",
          background: "#000",
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "1076px",
          "margin-top": "4px",
          height: "2px",
          background: "#000",
        },
      }),
      _vm._v(" "),
      _vm.titleDisponsejson.place == 1 && _vm.titleDisponsejson.direc == 1
        ? _c(
            "div",
            { staticStyle: { width: "1076px", "margin-top": "24px" } },
            [
              _c(
                "div",
                { staticStyle: { width: "1076px" } },
                _vm._l(_vm.owndata, function (item, index) {
                  return item.select
                    ? _c(
                        "div",
                        {
                          key: "own_t_" + index,
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "16px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                          style:
                            _vm.titleDisponsejson.linenum == 2
                              ? "width:50%"
                              : "width:33%",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(item.value || item.name) + "："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#000" } }, [
                            _vm._v(_vm._s(item.input || "")),
                          ]),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "1076px" } },
                _vm._l(_vm.otherdata, function (item, index) {
                  return item.select
                    ? _c(
                        "div",
                        {
                          key: "own_o_" + index,
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "16px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                          style:
                            _vm.titleDisponsejson.linenum == 2
                              ? "width:50%"
                              : "width:33%",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(item.value || item.name) + "："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#000" } }, [
                            _vm._v(_vm._s(item.input || "")),
                          ]),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
            ]
          )
        : _vm.titleDisponsejson.place == 1 && _vm.titleDisponsejson.direc == 2
        ? _c(
            "div",
            { staticStyle: { width: "1076px", "margin-top": "24px" } },
            [
              _c(
                "div",
                { staticStyle: { width: "1076px" } },
                _vm._l(_vm.otherdata, function (item, index) {
                  return item.select
                    ? _c(
                        "div",
                        {
                          key: "own_o_" + index,
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "16px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                          style:
                            _vm.titleDisponsejson.linenum == 2
                              ? "width:50%"
                              : "width:33%",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(item.value || item.name) + "："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#000" } }, [
                            _vm._v(_vm._s(item.input || "")),
                          ]),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "1076px" } },
                _vm._l(_vm.owndata, function (item, index) {
                  return item.select
                    ? _c(
                        "div",
                        {
                          key: "own_t_" + index,
                          staticStyle: {
                            display: "inline-block",
                            "font-size": "16px",
                            "font-weight": "500",
                            "margin-bottom": "24px",
                          },
                          style:
                            _vm.titleDisponsejson.linenum == 2
                              ? "width:50%"
                              : "width:33%",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(item.value || item.name) + "："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#000" } }, [
                            _vm._v(_vm._s(item.input || "")),
                          ]),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
            ]
          )
        : _vm.titleDisponsejson.place == 2 && _vm.titleDisponsejson.direc == 1
        ? _c(
            "div",
            { staticStyle: { width: "1076px", "margin-top": "24px" } },
            [
              _c("div", { staticStyle: { width: "1076px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      "font-size": "16px",
                      "font-weight": "500",
                      display: "inline-block",
                    },
                  },
                  _vm._l(_vm.owndata, function (item, index) {
                    return item.select
                      ? _c(
                          "div",
                          {
                            key: "own_o_" + index,
                            staticStyle: { "margin-bottom": "24px" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(item.value || item.name) + "："),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(_vm._s(item.input || "")),
                            ]),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "1076px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      "font-size": "16px",
                      "font-weight": "500",
                      display: "inline-block",
                    },
                  },
                  _vm._l(_vm.otherdata, function (item, index) {
                    return item.select
                      ? _c(
                          "div",
                          {
                            key: "own_oh_" + index,
                            staticStyle: { "margin-bottom": "24px" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(item.value || item.name) + "："),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(_vm._s(item.input || "")),
                            ]),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm.titleDisponsejson.place == 2 && _vm.titleDisponsejson.direc == 2
        ? _c(
            "div",
            { staticStyle: { width: "1076px", "margin-top": "24px" } },
            [
              _c("div", { staticStyle: { width: "1076px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      "font-size": "16px",
                      "font-weight": "500",
                      display: "inline-block",
                    },
                  },
                  _vm._l(_vm.otherdata, function (item, index) {
                    return item.select
                      ? _c(
                          "div",
                          {
                            key: "own_oh_" + index,
                            staticStyle: { "margin-bottom": "24px" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(item.value || item.name) + "："),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(_vm._s(item.input || "")),
                            ]),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { width: "1076px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      "font-size": "16px",
                      "font-weight": "500",
                      display: "inline-block",
                    },
                  },
                  _vm._l(_vm.owndata, function (item, index) {
                    return item.select
                      ? _c(
                          "div",
                          {
                            key: "own_o_" + index,
                            staticStyle: { "margin-bottom": "24px" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(item.value || item.name) + "："),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(_vm._s(item.input || "")),
                            ]),
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          width: "1076px",
          background: "#000",
          height: "1px",
          "margin-top": "8px",
        },
      }),
      _vm._v(" "),
      _vm.expiry_date_switch
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-top": "24px",
                "font-size": "16px",
                "font-weight": "500",
              },
            },
            [
              _c("span", [_vm._v("合同有效期：")]),
              _vm._v(" "),
              _vm.timeVal.length
                ? _c("span", { staticStyle: { color: "#000" } }, [
                    _vm._v(
                      _vm._s(_vm.timeVal[0]) + " 至 " + _vm._s(_vm.timeVal[1])
                    ),
                  ])
                : _c("span", { staticStyle: { color: "#000" } }, [
                    _vm._v("- 年 - 月- 日 至 - 年 - 月- 日"),
                  ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "24px", width: "1076px" } }, [
        _vm.content
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.entrust_order_switch
        ? _c(
            "div",
            { staticStyle: { "font-size": "16px", "margin-top": "24px" } },
            [_vm._v("委托标的清单：")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.entrust_order_switch
        ? _c(
            "div",
            { staticStyle: { width: "1076px" } },
            [
              _vm.Catearr.length
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "24px",
                          border: "1px solid #ddd",
                        },
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _vm._l(_vm.Catearr, function (citem, cindex) {
                          return _c(
                            "div",
                            {
                              key: "ct_" + cindex,
                              staticStyle: {
                                "font-weight": "bold",
                                height: "48px",
                                color: "#333",
                                "font-size": "12px",
                                padding: "0 20px",
                              },
                              style:
                                (cindex > 0
                                  ? "border-top:1px solid #ddd;"
                                  : "") +
                                (cindex % 2 == 0 ? "" : "background: #FCFBFC;"),
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "50px",
                                    "text-align": "center",
                                    display: "inline-block",
                                    "line-height": "40px",
                                  },
                                },
                                [_vm._v(_vm._s(cindex + 1))]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticStyle: {
                                  width: "315px",
                                  "text-align": "center",
                                  display: "inline-block",
                                  "line-height": "40px",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "315px",
                                    "text-align": "left",
                                    "margin-left": "20px",
                                    display: "inline-block",
                                    "line-height": "40px",
                                  },
                                },
                                [_vm._v(_vm._s(citem.product_name))]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticStyle: {
                                  width: "315px",
                                  display: "inline-block",
                                  "line-height": "40px",
                                },
                              }),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "20px",
                              color: "#999",
                              "font-size": "12px",
                              "line-height": "48px",
                              height: "48px",
                              "border-top": "1px solid #ddd",
                            },
                          },
                          [
                            _vm._v(
                              "\n          注：保留价如有调整，以调整后的保留价为准\n        "
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.Catearr.length ? [_vm._m(1)] : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.entrust_order_switch
        ? _c(
            "div",
            { staticStyle: { "font-size": "16px", "margin-top": "24px" } },
            [_vm._v("拍卖标的成交收费标准设置：")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.entrust_order_switch
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-top": "24px",
                border: "1px solid #ddd",
                width: "1076px",
              },
            },
            [
              _vm.Catearr.length
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          background: "#F9FCFF",
                          height: "60px",
                          color: "#333",
                          "font-size": "12px",
                          padding: "0 20px",
                        },
                      },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "86px",
                              "text-align": "center",
                              display: "inline-block",
                              "line-height": "60px",
                              "vertical-align": "top",
                            },
                          },
                          [_vm._v("保留价")]
                        ),
                        _vm._v(" "),
                        _vm.inventoryConfig.switch.sold_price_switch
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "230px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  _vm._l(
                                    _vm.inventoryConfig.price.sold_price,
                                    function (item, index) {
                                      return item.name
                                        ? _c(
                                            "div",
                                            {
                                              key: "s_" + index,
                                              staticStyle: {
                                                width: "70px",
                                                "line-height": "60px",
                                                "text-align": "center",
                                                margin: "0 2px",
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.inventoryConfig.switch.unsold_price_switch
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "230px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  _vm._l(
                                    _vm.inventoryConfig.price.unsold_price,
                                    function (item, index) {
                                      return item.name
                                        ? _c(
                                            "div",
                                            {
                                              key: "s_" + index,
                                              staticStyle: {
                                                width: "70px",
                                                "line-height": "60px",
                                                "text-align": "center",
                                                margin: "0 2px",
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.inventoryConfig.switch.other_price_switch
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "230px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  _vm._l(
                                    _vm.inventoryConfig.price.other_price,
                                    function (item, index) {
                                      return item.name
                                        ? _c(
                                            "div",
                                            {
                                              key: "s_" + index,
                                              staticStyle: {
                                                width: "70px",
                                                "line-height": "60px",
                                                "text-align": "center",
                                                margin: "0 2px",
                                                display: "inline-block",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.Catearr, function (citem, cindex) {
                      return [
                        _c(
                          "div",
                          {
                            key: "ct_" + cindex,
                            staticStyle: {
                              color: "#333",
                              "font-size": "12px",
                              padding: "15px 20px",
                            },
                            style:
                              (cindex > 0 ? "border-top:1px solid #ddd;" : "") +
                              (cindex % 2 == 0 ? "" : "background: #FCFBFC;"),
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "220px",
                                  display: "inline-block",
                                  "line-height": "60px",
                                  "vertical-align": "top",
                                },
                              },
                              [_vm._v(_vm._s(citem.product_name))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "86px",
                                  "text-align": "center",
                                  display: "inline-block",
                                  "line-height": "60px",
                                  "vertical-align": "top",
                                },
                              },
                              [_vm._v(_vm._s(citem.retain_price))]
                            ),
                            _vm._v(" "),
                            _vm.inventoryConfig.switch.sold_price_switch
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "230px",
                                      display: "inline-block",
                                    },
                                  },
                                  _vm._l(
                                    _vm.inventoryConfig.price.sold_price,
                                    function (item, index) {
                                      return item.name
                                        ? _c(
                                            "div",
                                            {
                                              key: "s_" + index,
                                              staticStyle: {
                                                width: "70px",
                                                "text-align": "center",
                                                "line-height": "60px",
                                                margin: "0 2px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    citem.success[index].number
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.inventoryConfig.switch.unsold_price_switch
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "240px",
                                      display: "inline-block",
                                    },
                                  },
                                  _vm._l(
                                    _vm.inventoryConfig.price.unsold_price,
                                    function (item, index) {
                                      return item.name
                                        ? _c(
                                            "div",
                                            {
                                              key: "s_" + index,
                                              staticStyle: {
                                                width: "70px",
                                                "text-align": "center",
                                                margin: "0 2px",
                                                "line-height": "60px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    citem.fail[index].number
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.inventoryConfig.switch.other_price_switch
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "240px",
                                      display: "inline-block",
                                    },
                                  },
                                  _vm._l(
                                    _vm.inventoryConfig.price.other_price,
                                    function (item, index) {
                                      return item.name
                                        ? _c(
                                            "div",
                                            {
                                              key: "s_" + index,
                                              staticStyle: {
                                                width: "70px",
                                                "text-align": "center",
                                                margin: "0 2px",
                                                "line-height": "60px",
                                                display: "inline-block",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    citem.other[index].number
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    }),
                  ]
                : [_vm._m(3), _vm._v(" "), _vm._m(4)],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.signDisponsejson.direc == 1
        ? _c(
            "div",
            { staticStyle: { width: "1076px", "margin-top": "19px" } },
            [
              _c(
                "div",
                {
                  staticStyle: { display: "inline-block" },
                  style:
                    _vm.signDisponsejson.place == 1
                      ? "width: 100%;"
                      : "width: 48%",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.ownsigndata[0].value || _vm.ownsigndata[0].name
                          ) +
                          "（签章）："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.ownsigndata[0].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.ownsigndata[1].value || _vm.ownsigndata[1].name
                          ) +
                          "："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.ownsigndata[1].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formatDate(_vm.nowdate, "{y}年{m}月{d}日")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                        "margin-bottom": "24px",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.ownsigndata[2].input || "") +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { display: "inline-block" },
                  style:
                    _vm.signDisponsejson.place == 1
                      ? "width: 100%;"
                      : "width: 48%",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.othersigndata[0].value ||
                              _vm.othersigndata[0].name
                          ) +
                          "（签章）："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.othersigndata[0].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.othersigndata[1].value ||
                              _vm.othersigndata[1].name
                          ) +
                          "："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.othersigndata[1].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formatDate(_vm.nowdate, "{y}年{m}月{d}日")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.othersigndata[2].input || "") +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm.signDisponsejson.direc == 2
        ? _c(
            "div",
            { staticStyle: { width: "1076px", "margin-top": "19px" } },
            [
              _c(
                "div",
                {
                  staticStyle: { display: "inline-block" },
                  style:
                    _vm.signDisponsejson.place == 1
                      ? "width: 100%;"
                      : "width: 48%",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.othersigndata[0].value ||
                              _vm.othersigndata[0].name
                          ) +
                          "（签章）："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.othersigndata[0].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm.othersigndata[1].select
                        ? [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.othersigndata[1].value ||
                                    _vm.othersigndata[1].name
                                ) +
                                "："
                            ),
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(_vm._s(_vm.othersigndata[1].input || "")),
                            ]),
                          ]
                        : [_vm._v("\n              \n          ")],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formatDate(_vm.nowdate, "{y}年{m}月{d}日")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                        "margin-bottom": "24px",
                      },
                    },
                    [
                      _vm.othersigndata[2].select
                        ? [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.othersigndata[2].value || "") +
                                "\n          "
                            ),
                          ]
                        : [_vm._v("\n             \n          ")],
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { display: "inline-block" },
                  style:
                    _vm.signDisponsejson.place == 1
                      ? "width: 100%;"
                      : "width: 48%",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.ownsigndata[0].value || _vm.ownsigndata[0].name
                          ) +
                          "（签章）："
                      ),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v(_vm._s(_vm.ownsigndata[0].input || "")),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm.ownsigndata[1].select
                        ? [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.ownsigndata[1].value ||
                                    _vm.ownsigndata[1].name
                                ) +
                                "："
                            ),
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v(_vm._s(_vm.ownsigndata[1].input || "")),
                            ]),
                          ]
                        : [_vm._v("\n             \n          ")],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formatDate(_vm.nowdate, "{y}年{m}月{d}日")
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "margin-top": "24px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _vm.ownsigndata[2].select
                        ? [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.ownsigndata[2].value ||
                                    _vm.ownsigndata[2].name
                                ) +
                                "\n          "
                            ),
                          ]
                        : [_vm._v("\n             \n          ")],
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "24px", width: "1076px" } }, [
        _vm.attachmentContent
          ? _c("div", {
              domProps: { innerHTML: _vm._s(_vm.attachmentContent) },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-weight": "bold",
          background: "#F9FCFF",
          height: "40px",
          color: "#333",
          "font-size": "12px",
          padding: "0 20px",
        },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "50px",
              "text-align": "center",
              display: "inline-block",
              "line-height": "40px",
            },
          },
          [_vm._v("序号")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              width: "315px",
              "text-align": "center",
              display: "inline-block",
              "line-height": "40px",
            },
          },
          [_vm._v("作者/年代")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              width: "315px",
              "text-align": "left",
              "margin-left": "20px",
              display: "inline-block",
              "line-height": "40px",
            },
          },
          [_vm._v("作品名称")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              width: "315px",
              display: "inline-block",
              "line-height": "40px",
            },
          },
          [_vm._v("备注")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "24px", border: "1px solid #ddd" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              "font-weight": "bold",
              background: "#F9FCFF",
              height: "40px",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  width: "50px",
                  "text-align": "center",
                  display: "inline-block",
                  "line-height": "40px",
                },
              },
              [_vm._v("序号")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  display: "inline-block",
                  "line-height": "40px",
                },
              },
              [_vm._v("图录号")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  display: "inline-block",
                  "line-height": "40px",
                },
              },
              [_vm._v("作者/年代")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "left",
                  "margin-left": "20px",
                  display: "inline-block",
                  "line-height": "40px",
                },
              },
              [_vm._v("作品名称")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  display: "inline-block",
                  "line-height": "40px",
                },
              },
              [_vm._v("质地/形式")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  display: "inline-block",
                  "line-height": "40px",
                },
              },
              [_vm._v("尺寸 (cm)")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  "text-align": "center",
                  display: "inline-block",
                  "line-height": "40px",
                },
              },
              [_vm._v("保留价 (元)")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "132px",
                  display: "inline-block",
                  "line-height": "40px",
                },
              },
              [_vm._v("备注")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "font-weight": "bold",
              height: "48px",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
            },
          },
          [
            _c("div", {
              staticStyle: {
                width: "50px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "left",
                "margin-left": "20px",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "border-top": "1px solid #ddd",
              background: "#FCFBFC",
              "font-weight": "bold",
              height: "48px",
              color: "#333",
              "font-size": "12px",
              padding: "0 20px",
            },
          },
          [
            _c("div", {
              staticStyle: {
                width: "50px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "left",
                "margin-left": "20px",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                "text-align": "center",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: {
                width: "132px",
                display: "inline-block",
                "line-height": "40px",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "padding-left": "20px",
              color: "#999",
              "font-size": "12px",
              display: "flex",
              "align-items": "center",
              height: "48px",
              "border-top": "1px solid #ddd",
            },
          },
          [
            _vm._v(
              "\n          注：保留价如有调整，以调整后的保留价为准\n        "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          width: "220px",
          display: "inline-block",
          "line-height": "60px",
          "vertical-align": "top",
        },
      },
      [
        _c("span", { staticStyle: { "margin-left": "20px" } }, [
          _vm._v("商品名称"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-weight": "bold",
          background: "#F9FCFF",
          height: "60px",
          color: "#333",
          "font-size": "12px",
          padding: "0 20px",
        },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "220px",
              display: "inline-block",
              "line-height": "60px",
              "vertical-align": "top",
            },
          },
          [
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("商品名称"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              width: "86px",
              "text-align": "center",
              display: "inline-block",
              "line-height": "60px",
              "vertical-align": "top",
            },
          },
          [_vm._v("保留价")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "inline-block",
              width: "230px",
              "line-height": "60px",
            },
          },
          [
            _c("div", {}, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("装裱费")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("佣金")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("图录费")]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { display: "inline-block", width: "230px" } },
          [
            _c("div", {}, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("装裱费")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("保留价佣金")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("图录费")]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { display: "inline-block", width: "230px" } },
          [
            _c("div", {}, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("装裱费")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("佣金")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "70px",
                    "text-align": "center",
                    margin: "0 2px",
                    display: "inline-block",
                  },
                },
                [_vm._v("图录费")]
              ),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "space-between",
          color: "#333",
          "font-size": "12px",
          padding: "15px 20px",
        },
      },
      [
        _c("div", { staticStyle: { width: "230px" } }),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "120px", "text-align": "center" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              width: "240px",
              "justify-content": "space-between",
            },
          },
          [
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              width: "240px",
              "justify-content": "space-between",
            },
          },
          [
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              width: "240px",
              "justify-content": "space-between",
            },
          },
          [
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
            _vm._v(" "),
            _c("div", {
              staticStyle: { width: "70px", "text-align": "center" },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }