"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _authenticate = require("@/api/authenticate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var selectType = {
  "1": "Expert",
  "3": "PraisalReport",
  "4": "PraisalCategory",
  "5": "PraisalSetting",
  "2": "Appraisal",
  "6": "AppraisalComplaint"
};
var _default = exports.default = {
  name: "AppraisalComplaint",
  data: function data() {
    return {
      activeMenu: "6",
      showImg: false,
      img: [],
      showVideo: false,
      video: "",
      complaintReasons: "",
      category: 0,
      loading: false,
      total: 0,
      queryParams: {
        limit: 10,
        type: "0",
        page: Number(this.$route.query.page || 1)
      },
      resultData: []
    };
  },
  created: function created() {
    this.load();
  },
  methods: {
    onSelectTit: function onSelectTit(index) {
      var _this = this;
      this.activeMenu = index;
      this.$router.push({
        name: selectType[index]
      });
      setTimeout(function () {
        _this.activeMenu = '6';
      });
    },
    onLookImgs: function onLookImgs(row) {
      this.img = row.content.img;
      this.showImg = true;
    },
    onLookVideo: function onLookVideo(row) {
      this.video = row.content.video;
      this.showVideo = true;
    },
    onChuli: function onChuli(row, types) {
      var _this2 = this;
      var remove = {
        complaint_id: row.id,
        is_blocked: types
      };
      (0, _authenticate._changeComplaint)(remove).then(function (res) {
        if (res.status === 200) {
          _this2.$message.success("操作完成");
        } else {
          _this2.$message.error(res.message);
        }
        _this2.load();
      }).catch(function (err) {
        console.log(err);
        _this2.$message.error(err.message);
      });
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.limit = size;
      this.queryParams.page = 1;
      this.load();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.load();
    },
    load: function load() {
      var _this3 = this;
      this.loading = false;
      var params = JSON.parse(JSON.stringify(this.queryParams));
      if (params.type == 0) params.type = "";
      (0, _authenticate._getComplaintList)(params).then(function (res) {
        if (res.status === 200) {
          res.data.list.forEach(function (m) {
            var newM = m;
            if (m.type === 1) {
              newM.content = {
                id: m.question.id,
                text: m.question.title,
                createTime: _this3.$formatData(m.question.create_time * 1000),
                video: m.question.video_url,
                img: m.question.image_url ? m.question.image_url.split(",") : [],
                audio: ""
              };
            } else {
              newM.content = {
                id: m.answer.id,
                text: m.answer.answer_text,
                createTime: _this3.$formatData(m.answer.create_time * 1000),
                video: m.answer.answer_video,
                img: m.answer.answer_img ? m.answer.answer_img.split(",") : [],
                audio: m.answer.answer_radio
              };
            }
          });
          _this3.total = res.data.count;
          _this3.resultData = res.data.list;
        } else {
          _this3.$message.error(res.message);
        }
      }).catch(function (err) {
        _this3.$message.error(err.message);
      });
    },
    searchAction: function searchAction() {
      this.queryParams.page = 1;
      this.queryParams.complaint_content = this.complaintReasons;
      this.load();
    },
    changeStateEvent: function changeStateEvent(name) {
      this.queryParams.type = name;
      this.queryParams.page = 1;
      this.queryParams.complaint_content = "";
      this.complaintReasons = "";
      this.load();
    }
  }
};