"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var configRouter = {
  path: "".concat(_settings.roterPre, "/config"),
  name: 'system_config',
  meta: {
    icon: 'dashboard',
    title: '系统配置'
  },
  alwaysShow: true,
  // 一直显示根路由
  component: _layout.default,
  children: [{
    path: 'picture',
    name: 'system_config_picture',
    meta: {
      title: '素材管理',
      permissionKey: '/config/picture'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/config/picture'));
      });
    }
  }, {
    path: 'freight',
    name: 'Freight',
    meta: {
      title: '物流设置'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/system/freight/index'));
      });
    },
    children: [{
      path: 'shippingTemplates',
      name: 'ShippingTemplates',
      meta: {
        title: '运费模板',
        noCache: true,
        permissionKey: '/config/freight/shippingTemplates'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/system/freight/shippingTemplates'));
        });
      }
    }]
  }]
};
var _default = exports.default = configRouter;