var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "批量上传",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                "before-close": _vm.isClose,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "form",
                {
                  ref: "uploadForm",
                  attrs: {
                    action: _vm.https + "/excel/import",
                    target: "_blank",
                    enctype: "multipart/form-data",
                    method: "post",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "item-align-center mt20" },
                    [
                      _c("span", { staticClass: "w150" }, [
                        _vm._v("选择素材分类："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.openChangeCategory },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.changeCate.attachment_category_id
                                ? "重新选择"
                                : "去选择"
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.changeCate.attachment_category_id
                        ? _c("span", { staticClass: "ml10" }, [
                            _vm._v(
                              "已选中分类：" +
                                _vm._s(_vm.changeCate.attachment_category_name)
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("span", { staticClass: "w150" }, [_vm._v("表格")]),
                    _vm._v(" "),
                    _c("input", {
                      staticStyle: { cursor: "pointer" },
                      attrs: { type: "file", name: "excel" },
                      on: {
                        change: function ($event) {
                          return _vm.changeFileAction($event)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("span", { staticClass: "w150" }, [_vm._v("表格检查：")]),
                    _vm._v(" "),
                    _vm.errorList.length > 0 && !_vm.loadError
                      ? _c(
                          "div",
                          { staticClass: "item-align-center" },
                          [
                            _c("span", { staticStyle: { color: "#ae0000" } }, [
                              _vm._v(
                                "存在" + _vm._s(_vm.errorList.length) + "个问题"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ml10",
                                on: { click: _vm.showErrorAction },
                              },
                              [_vm._v("查看问题")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ml10",
                                on: { click: _vm.checkElsx },
                              },
                              [_vm._v("重新检查")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errorList.length === 0 && !_vm.loadError
                      ? _c("div", { staticClass: "item-align-center" }, [
                          _c("span", { staticStyle: { color: "#28ab29" } }, [
                            _vm._v("检查完毕"),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.loadError },
                      on: { click: _vm.bulkShipmentAction },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      on: { click: _vm.clearBulkShipmentAction },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-drawer",
            {
              attrs: {
                title: "批量上传图片/视频",
                visible: _vm.showUpload,
                direction: "rtl",
                size: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showUpload = $event
                },
              },
            },
            [
              _c("div", { staticClass: "drawer-view" }, [
                _c(
                  "div",
                  { staticClass: "item-align-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-one" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.uploadImageNow },
                          },
                          [_vm._v("立即上传")]
                        ),
                        _vm._v(" "),
                        _vm.uploadBatch.length + _vm.uploadVideoNum > 0
                          ? _c("span", { staticClass: "ml10" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.uploadBatch.length + _vm.uploadVideoNum
                                ) + "个文件上传成功"
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          "http-request": _vm.modeUpload,
                          multiple: "",
                          action: "/batchUploadNew",
                          "show-file-list": false,
                        },
                      },
                      [
                        _c("el-button", { attrs: { type: "primary" } }, [
                          _vm._v("选择文件"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "upload-btn ml10" },
                      [
                        _c("el-button", { attrs: { type: "primary" } }, [
                          _vm._v("选择文件夹 "),
                          _c("input", {
                            ref: "file",
                            staticStyle: { opacity: "0", cursor: "pointer" },
                            attrs: {
                              id: "file1",
                              type: "file",
                              webkitdirectory: "",
                              directory: "",
                              multiple: "multiple",
                            },
                            on: { change: _vm.handleFileUpload1 },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "h600 mt20" }, [
                  _c(
                    "div",
                    { staticClass: "common-table" },
                    [
                      _c("div", { staticClass: "t-head" }, [
                        _c("div", { staticClass: "tr item-align-center" }, [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "30%" },
                            },
                            [_vm._v("文件")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: {
                                width: "50%",
                                "text-align": "center",
                              },
                            },
                            [_vm._v("状态")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: {
                                width: "20%",
                                "text-align": "center",
                              },
                            },
                            [_vm._v("操作")]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.showUploadImgList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "t-body" },
                          [
                            _c("div", { staticClass: "tr item-align-center" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: { width: "30%" },
                                },
                                [
                                  _c("div", [_vm._v(_vm._s(item.name))]),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(item.showSize))]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: {
                                    width: "50%",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  item.uploadStatus === 0
                                    ? _c("div", [_vm._v("待上传")])
                                    : item.uploadStatus === 1
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-progress", {
                                            attrs: {
                                              percentage: item.progress,
                                              status: "success",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.uploadStatus === 2
                                    ? _c("div", [_vm._v("已完成")])
                                    : item.uploadStatus === 3
                                    ? _c("div", [_vm._v("上传失败")])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "td",
                                  staticStyle: {
                                    width: "20%",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  item.uploadStatus === 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delImgAction(index)
                                            },
                                          },
                                        },
                                        [_vm._v("移除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt20 flex justify-e" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-size": _vm.imgParams.size,
                        "current-page": _vm.imgParams.page,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.uploadImgList.length,
                      },
                      on: { "current-change": _vm.changeImgPage },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "错误报告",
                visible: _vm.showError,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showError = $event
                },
              },
            },
            _vm._l(_vm.errorList, function (m, i) {
              return _c("div", { key: i }, [_vm._v(_vm._s(m))])
            }),
            0
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }