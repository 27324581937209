"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "ProductList",
  data: function data() {
    return {
      props: {
        emitPath: false
      },
      roterPre: _settings.roterPre,
      headeNum: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      seckillStatusList: [{
        label: "正在进行",
        value: 2
      }, {
        label: "活动已结束",
        value: 3
      }, {
        label: "活动未开始",
        value: 1
      }],
      tableFrom: {
        page: 1,
        limit: 20,
        mer_cate_id: "",
        cate_id: "",
        keyword: "",
        type: "1",
        seckill_status: ""
      },
      categoryList: [],
      // 平台
      merCateList: [],
      // 商户分类筛选
      modals: false
    };
  },
  mounted: function mounted() {
    this.getLstFilterApi();
    this.getCategorySelect();
    this.getCategoryList();
    this.getList();
  },
  methods: {
    // 添加淘宝商品成功
    onClose: function onClose() {
      this.modals = false;
    },
    handleRestore: function handleRestore(id) {
      var _this = this;
      this.$modalSure("恢复商品").then(function () {
        (0, _product.spikeRestoreApi)(id).then(function (res) {
          _this.$message.success(res.message);
          _this.getLstFilterApi();
          _this.getList();
        }).catch(function (res) {
          // this.$message.error(res.message);
        });
      });
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this2 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this2.merCateList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 平台分类；
    getCategoryList: function getCategoryList() {
      var _this3 = this;
      (0, _product.categoryListApi)().then(function (res) {
        _this3.categoryList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 列表表头；
    getLstFilterApi: function getLstFilterApi() {
      var _this4 = this;
      (0, _product.spikelstFilterApi)().then(function (res) {
        _this4.headeNum = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList() {
      var _this5 = this;
      this.listLoading = true;
      (0, _product.seckillProductLstApi)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.data.list;
        _this5.tableData.total = res.data.count;
        _this5.listLoading = false;
      }).catch(function (res) {
        _this5.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this6 = this;
      this.$modalSure(this.tableFrom.type !== "5" ? "加入回收站" : "").then(function () {
        _this6.tableFrom.type === "5" ? (0, _product.spikeDestoryApi)(id).then(function (_ref) {
          var message = _ref.message;
          _this6.$message.success(message);
          _this6.getList();
          _this6.getLstFilterApi();
        }).catch(function (_ref2) {
          var message = _ref2.message;
        }) : (0, _product.spikeProductDeleteApi)(id).then(function (_ref3) {
          var message = _ref3.message;
          _this6.$message.success(message);
          _this6.getList();
          _this6.getLstFilterApi();
        }).catch(function (_ref4) {
          var message = _ref4.message;
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this7 = this;
      (0, _product.spikeStatusApi)(row.product_id, row.is_show).then(function (_ref5) {
        var message = _ref5.message;
        _this7.$message.success(message);
        _this7.getList();
        _this7.getLstFilterApi();
      }).catch(function (_ref6) {
        var message = _ref6.message;
      });
    }
  }
};