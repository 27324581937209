var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "logo" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "item-align-center mt20" },
                  [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("logo选择")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group",
                        on: { input: _vm.changeLogoType },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("企业资料"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("自定义"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.type === 2
                ? _vm._l(_vm.content.data, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "logo" + index,
                        staticClass: "upload-img-view item-flex-center",
                        staticStyle: {
                          width: "90px",
                          height: "90px",
                          "margin-top": "20px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.uploadIconAction(1, index)
                          },
                        },
                      },
                      [
                        item.url
                          ? _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delImg(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.url
                          ? _c("img", {
                              staticStyle: { width: "90px", height: "90px" },
                              attrs: { src: item.url + "!120a" },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "最大高度" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 500 },
                    model: {
                      value: _vm.facade.maxHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "maxHeight", $$v)
                      },
                      expression: "facade.maxHeight",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.background_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "background_color", $$v)
                      },
                      expression: "facade.background_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "左右边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 375 },
                    model: {
                      value: _vm.facade.page_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_padding", $$v)
                      },
                      expression: "facade.page_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "radius", $$v)
                      },
                      expression: "facade.radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "对齐方式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "align", $$v)
                        },
                        expression: "facade.align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("中间"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("左"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }