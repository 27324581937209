"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var panels = [{
  background: '#FBF9F7',
  main_text: '#463C37',
  sub_text: '#BCAB97',
  decorative_text: '#EAE0D2',
  highlight_text: '#D09966'
}, {
  background: '#F9F4E7',
  main_text: '#2E1F14',
  sub_text: '#B39068',
  decorative_text: '#D9D0C5',
  highlight_text: '#A64230'
}, {
  background: '#F8FCFB',
  main_text: '#1C3D3A',
  sub_text: '#76A398',
  decorative_text: '#C8DFDC',
  highlight_text: '#467A72'
}, {
  background: '#FAF3E0',
  main_text: '#3E2B18',
  sub_text: '#D5A86E',
  decorative_text: '#F2D9B5',
  highlight_text: '#B85E28'
}, {
  background: '#F8FEFE',
  main_text: '#1B3330',
  sub_text: '#72D6C9',
  decorative_text: '#C9E4E0',
  highlight_text: '#2EC99C'
}, {
  background: '#F8F0F6',
  main_text: '#422939',
  sub_text: '#7F436A',
  decorative_text: '#E7CAD7',
  highlight_text: '#AC3F5B'
}, {
  background: '#FCFFFE',
  main_text: '#000000',
  sub_text: '#328B5B',
  decorative_text: '#C6D7D2',
  highlight_text: '#007752'
}, {
  background: '#FEFCF7',
  main_text: '#364F3B',
  sub_text: '#C2A36D',
  decorative_text: '#F1E0A8',
  highlight_text: '#DD7050'
}, {
  background: '#FFFFFF',
  main_text: '#000000',
  sub_text: '#000000',
  decorative_text: '#D1F4C9',
  highlight_text: '#00B540'
}, {
  background: '#EAF1F0',
  main_text: '#2E3A39',
  sub_text: '#7BA693',
  decorative_text: '#D0E0DC',
  highlight_text: '#3A7564'
}, {
  background: '#FFF7FB',
  main_text: '#4A2E41',
  sub_text: '#EF95B8',
  decorative_text: '#EAD1E1',
  highlight_text: '#FF5B92'
}, {
  background: '#F9FCFF',
  main_text: '#28324A',
  sub_text: '#526983',
  decorative_text: '#C2D2E4',
  highlight_text: '#517CA7'
}, {
  background: '#F2F0E6',
  main_text: '#3E4036',
  sub_text: '#8C8A72',
  decorative_text: '#B4B18A',
  highlight_text: '#87803B'
}, {
  background: '#F7EFE5',
  main_text: '#4A3624',
  sub_text: '#A67F4E',
  decorative_text: '#D9C4A7',
  highlight_text: '#8A6238'
}, {
  background: '#ECEDE9',
  main_text: '#2E3C34',
  sub_text: '#6A7D72',
  decorative_text: '#BCC1BC',
  highlight_text: '#4B6053'
}, {
  background: '#F7F3EF',
  main_text: '#1C1C1B',
  sub_text: '#8B8378',
  decorative_text: '#CDC5B6',
  highlight_text: '#A0522D'
}, {
  background: '#F0F4F2',
  main_text: '#2D2C28',
  sub_text: '#5C6D6C',
  decorative_text: '#9A9F9B',
  highlight_text: '#607D6B'
}, {
  background: '#F6EDE1',
  main_text: '#3B3029',
  sub_text: '#8C7B6A',
  decorative_text: '#D9C8B8',
  highlight_text: '#714B32'
}, {
  background: '#F8F8F8',
  main_text: '#1B1B1B',
  sub_text: '#525252',
  decorative_text: '#D3D3D3',
  highlight_text: '#000000'
}, {
  background: '#F6FFFD',
  main_text: '#001A13',
  sub_text: '#A5E8DE',
  decorative_text: '#C6E8E2',
  highlight_text: '#00CCE1'
}, {
  background: '#FFFDFA',
  main_text: '#4A2E1D',
  sub_text: '#FFAC5A',
  decorative_text: '#FFD8A8',
  highlight_text: '#FF5200'
}, {
  background: '#FFFEFC',
  main_text: '#3F1B1B',
  sub_text: '#5D1F0E',
  decorative_text: '#D7CBC6',
  highlight_text: '#C13D00'
}, {
  background: '#F4EDE1',
  main_text: '#5A3E31',
  sub_text: '#A98261',
  decorative_text: '#D2B89B',
  highlight_text: '#8C503A'
}, {
  background: '#E8EBEB',
  main_text: '#3F3B36',
  sub_text: '#869D9A',
  decorative_text: '#BCC9C7',
  highlight_text: '#5C7A7A'
}, {
  background: '#F5EDEB',
  main_text: '#3A1F1F',
  sub_text: '#A45744',
  decorative_text: '#D1B4A1',
  highlight_text: '#7B2D26'
}, {
  background: '#F1F1F8',
  main_text: '#2B1B33',
  sub_text: '#5A3D5C',
  decorative_text: '#C2AFCB',
  highlight_text: '#7C497B'
}, {
  background: '#F7F0E6',
  main_text: '#4D2F21',
  sub_text: '#CEA977',
  decorative_text: '#D3BFA9',
  highlight_text: '#8C4B2D'
}, {
  background: '#F9F1EC',
  main_text: '#3A2E25',
  sub_text: '#8B4E47',
  decorative_text: '#C0D2E8',
  highlight_text: '#B03A3A'
}, {
  background: '#F1F7EE',
  main_text: '#2C3B27',
  sub_text: '#A28E6E',
  decorative_text: '#C5C8AF',
  highlight_text: '#6A8D55'
}, {
  background: '#EEF1FA',
  main_text: '#2D2744',
  sub_text: '#7F81B6',
  decorative_text: '#C5C7E8',
  highlight_text: '#50589C'
}];
var _default = exports.default = panels;