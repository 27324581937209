"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var panels = {
  basics: {
    title: '通用组件',
    children: [{
      name: 'title_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/title_icon.png',
      title: '标题栏',
      content: {
        title: '标题文字'
      },
      // 外观
      facade: {
        pageMarginTop: 0,
        pageMarginRight: 0,
        pageMarginBottom: 0,
        pageMarginLeft: 0,
        pagePaddingTop: 5,
        pagePaddingRight: 17,
        pagePaddingBottom: 5,
        pagePaddingLeft: 11,
        pageRadiusTopLeft: 0,
        pageRadiusTopRight: 122,
        pageRadiusBottomLeft: 0,
        pageRadiusBottomRight: 122,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: 0,
        borderStyle: 'solid',
        borderColor: '',
        pageBackground: 'rgba(156, 128, 79, 0.72)',
        widthType: '1',
        align: 'flex-start',
        pageColor: '#ffffff',
        fontSize: 15,
        fontWeight: 600,
        temp: ['pageBackground', 'borderColor', 'pageColor']
      }
    }, {
      name: 'text_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/title_icon.png',
      title: '文字区域',
      content: {
        data: ''
      },
      // 外观
      facade: {
        pageMarginTop: 0,
        pageMarginRight: 0,
        pageMarginBottom: 0,
        pageMarginLeft: 0,
        pagePaddingTop: 0,
        pagePaddingRight: 0,
        pagePaddingBottom: 0,
        pagePaddingLeft: 0,
        pageRadiusTopLeft: 0,
        pageRadiusTopRight: 0,
        pageRadiusBottomLeft: 0,
        pageRadiusBottomRight: 0,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: 0,
        borderStyle: 'solid',
        borderColor: '',
        pageBackground: '',
        pageColor: 'rgba(62, 62, 62, 1)',
        fontSize: 15,
        fontWeight: 400,
        lineHeight: 30,
        letterSpacing: 0,
        widthType: '1',
        align: 'flex-start',
        hasFold: '1',
        // 折叠角   1 无  2右上角
        specialColor: '#008000',
        temp: ['pageColor', '']
      }
    },
    // {
    //   name: 'textView',
    //   icon: 'https://saas.cdn.yunzongbu.cn/icon/text_icon.png',
    //   title: '文字',
    //   content: {
    //     title: '标题文字',
    //     subtitle: '内容文字'
    //   },
    //   // 外观
    //   facade: {
    //     theme: 1,
    //     page_margin: 0, // 页面间距
    //     item_margin: 15, // 内容间距
    //     item_background: '#FFFFFF', // 内容背景颜色
    //     item_padding: 15, // 内间距
    //     item_shadow: '#DEDEDE', // 专场阴影颜色
    //     item_shadow_size: 10, // 专场阴影大小
    //     radius: 4, // 内容圆角
    //     title_margin: 10,
    //     title_color: '#000000', // 标题颜色
    //     title_weight: 500,
    //     title_size: 28,
    //     title_text_align: 'left',
    //     desc_margin: 15,
    //     desc_color: '#999999',
    //     desc_weight: 400,
    //     desc_size: 24,
    //     desc_line_height: 20,
    //     desc_text_align: 'left'
    //   }
    // },
    {
      name: 'blank',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/kongge_icon.png',
      title: '分割',
      content: {
        has_line: false
      },
      // 外观
      facade: {
        height: 20,
        background_color: '',
        line_width: 375,
        line_height: 1,
        line_color: '#DDDDDD',
        temp: []
      }
    },
    // {
    //   name: 'rubik_weChat',
    //   icon: 'https://saas.cdn.yunzongbu.cn/icon/image_icon.png',
    //   title: '图片',
    //   content: {
    //     style: 1, // 风格 1  2  3  4  5  6
    //     density: 4, // 魔方密度
    //     data: [
    //       {
    //         url: '',
    //         link: ''
    //       }
    //     ]
    //   },
    //   // 外观
    //   facade: {}
    // },
    {
      name: 'img_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/image_icon.png',
      title: '图文',
      content: {
        type: '1',
        // 1 图文排列 2 推荐阅读 3 底部图片  4 专场封面
        data: [{
          title: '文字',
          url: '',
          link: {}
        }]
      },
      // 外观
      facade: {
        pageMarginTop: 0,
        pageMarginRight: 0,
        pageMarginBottom: 0,
        pageMarginLeft: 0,
        pagePaddingTop: 0,
        pagePaddingRight: 0,
        pagePaddingBottom: 0,
        pagePaddingLeft: 0,
        pageRadiusTopLeft: 0,
        pageRadiusTopRight: 0,
        pageRadiusBottomLeft: 0,
        pageRadiusBottomRight: 0,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: 0,
        borderColor: '',
        pageBackground: '',
        imgRadiusTopLeft: 0,
        imgRadiusTopRight: 0,
        imgRadiusBottomLeft: 0,
        imgRadiusBottomRight: 0,
        textArea: '2',
        textItemArea: 'flex-start',
        textAlign: 'center',
        textWidth: 45,
        textBackground: '',
        textPaddingTop: 0,
        textPaddingRight: 10,
        textPaddingBottom: 0,
        textPaddingLeft: 10,
        color: 'rgba(163, 163, 163, 1)',
        fontSize: 14,
        fontWeight: 400,
        marginTop: 5,
        temp: []
      }
    }, {
      name: 'weChatVideo_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/image_icon.png',
      title: '视频号',
      content: {
        inp: '',
        data: {}
      },
      // 外观
      facade: {}
    }]
  },
  advanced: {
    title: '内容组件',
    children: [{
      name: 'auction_album_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/auction_album_icon.png',
      title: '专场',
      hasContent: true,
      content: {
        data: []
      },
      facade: {
        personalizedDecoration: '',
        pageMarginTop: 0,
        pageMarginRight: 0,
        pageMarginBottom: 0,
        pageMarginLeft: 0,
        pagePaddingTop: 0,
        pagePaddingRight: 0,
        pagePaddingBottom: 0,
        pagePaddingLeft: 0,
        pageRadiusTopLeft: 0,
        pageRadiusTopRight: 0,
        pageRadiusBottomLeft: 0,
        pageRadiusBottomRight: 0,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: 0,
        borderColor: '',
        pageBackground: '',
        imgRadiusTopLeft: 0,
        imgRadiusTopRight: 0,
        imgRadiusBottomLeft: 0,
        imgRadiusBottomRight: 0,
        showVenueName: false,
        showVenueAddress: false,
        noShowImg: false,
        itemPaddingTop: 10,
        textArea: '2',
        textItemArea: 'flex-start',
        textAlign: 'center',
        textWidth: 45,
        textBackground: '',
        textPaddingTop: 0,
        textPaddingRight: 10,
        textPaddingBottom: 0,
        textPaddingLeft: 10,
        venueNameBackground: '',
        venueNameMarginTop: 15,
        venueNameMarginBottom: 15,
        venueName: '',
        venueNameColor: 'rgba(62, 62, 62, 1)',
        venueNameFontSize: 14,
        venueNameFontWeight: 600,
        titleColor: 'rgba(62, 62, 62, 1)',
        titleFontSize: 14,
        titleFontWeight: 600,
        titleMarginTop: 5,
        preTimeText: '预展时间',
        preTimeTextStyle: '1',
        // 1 换行展示  2 同行展示
        preTimeTextColor: 'rgba(62, 62, 62, 1)',
        preTimeTextFontSize: 14,
        preTimeTextFontWeight: 600,
        preTimeTextMarginTop: 5,
        preTimeColor: 'rgba(62, 62, 62, 1)',
        preTimeFontSize: 14,
        preTimeFontWeight: 400,
        preTimeMarginTop: 5,
        timeText: '开拍时间',
        timeTextStyle: '1',
        // 1 换行展示  2 同行展示
        timeTextColor: 'rgba(62, 62, 62, 1)',
        timeTextFontSize: 14,
        timeTextFontWeight: 600,
        timeTextMarginTop: 5,
        timeColor: 'rgba(62, 62, 62, 1)',
        timeFontSize: 14,
        timeFontWeight: 400,
        timeMarginTop: 5,
        liveTimeText: '直播时间',
        liveTimeTextStyle: '1',
        // 1 换行展示  2 同行展示
        liveTimeTextColor: 'rgba(62, 62, 62, 1)',
        liveTimeTextFontSize: 14,
        liveTimeTextFontWeight: 600,
        liveTimeTextMarginTop: 5,
        liveTimeColor: 'rgba(62, 62, 62, 1)',
        liveTimeFontSize: 14,
        liveTimeFontWeight: 400,
        liveTimeMarginTop: 5,
        venueAddressMarginTop: 15,
        venueAddressMarginBottom: 30,
        addressTag: '展拍地点',
        venueAddressBrief: '',
        venueAddressName: '',
        addressTagColor: 'rgba(62, 62, 62, 1)',
        addressTagFontSize: 14,
        addressTagFontWeight: 400,
        addressBriefMarginTop: 10,
        addressBriefColor: 'rgba(62, 62, 62, 1)',
        addressBriefFontSize: 14,
        addressBriefFontWeight: 600,
        addressNameMarginTop: 10,
        addressNameColor: 'rgba(62, 62, 62, 1)',
        addressNameFontSize: 14,
        addressNameFontWeight: 400,
        temp: ['pageBackground', 'borderColor', 'textBackground', 'venueNameBackground', 'venueNameColor', 'titleColor', 'preTimeTextColor', 'preTimeColor', 'timeTextColor', 'timeColor', 'liveTimeTextColor', 'liveTimeColor', 'addressTagColor', 'addressBriefColor', 'addressNameColor']
      }
    }, {
      name: 'auction_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/auction_icon.png',
      title: '拍品',
      hasContent: true,
      content: {
        data: [],
        playmodel: 3
      },
      facade: {
        img_position: 'center',
        swiperWidth: 1080,
        swiperHeight: 1080,
        ai_color: 1,
        theme: 1,
        page_margin: 0,
        // 页面间距
        item_margin: 15,
        // 内容间距
        item_background: '#FFFFFF',
        // 内容背景颜色
        item_padding: 15,
        // 内间距
        radius: 4,
        // 内容圆角
        title_margin: 10,
        title_color: '#000000',
        // 标题颜色
        title_weight: 600,
        title_size: 14,
        title_text_align: 'left',
        desc_margin: 10,
        desc_color: '#999999',
        desc_weight: 400,
        desc_size: 12,
        desc_line_height: 20,
        desc_text_align: 'left',
        content_margin: 10,
        content_color: '#999999',
        content_weight: 400,
        content_size: 12,
        content_line_height: 20,
        content_text_align: 'left',
        style: 1,
        longMode: '1',
        personalizedDecoration: '',
        border_width: 0,
        border_color: '#ffffff',
        border_shawdow_color: '#ffffff',
        border_shawdow_x: 0,
        border_shawdow_y: 0,
        temp: ['item_background', 'animate_color', 'auction_spacing_color', 'content_background', 'border_color', 'border_shawdow_color', 'lot_color', 'title_color', 'attr_color', 'attr_value_color', 'price_color', 'content_color', 'desc_color'],
        price_margin: 10,
        price_color: '#000000',
        price_weight: 600,
        price_size: 14,
        price_line_height: 20,
        price_text_align: 'left',
        lot_margin: 15,
        lot_color: '#999999',
        lot_weight: 400,
        lot_size: 14,
        lot_text_align: 'left'
      }
    }, {
      name: 'banner_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/banner_icon.png',
      title: '轮播图',
      hasContent: true,
      content: {
        view_width: 1080,
        view_height: 1080,
        data: []
      },
      facade: {
        img_position: 'center',
        theme: 1,
        page_margin: 0,
        // 页面间距
        item_margin: 15,
        // 内容间距
        item_background: '#FFFFFF',
        // 内容背景颜色
        item_padding: 15,
        // 内间距
        radius: 4,
        // 内容圆角
        title_margin: 10,
        title_color: '#000000',
        // 标题颜色
        title_weight: 500,
        title_size: 14,
        title_text_align: 'left',
        desc_margin: 15,
        desc_color: '#999999',
        desc_weight: 400,
        desc_size: 12,
        desc_line_height: 20,
        desc_text_align: 'left',
        temp: []
      }
    }]
  },
  enterprise: {
    title: '企业信息',
    children: [{
      name: 'logo_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/banner_icon.png',
      title: 'logo',
      hasContent: true,
      content: {
        view_width: 1080,
        view_height: 1080,
        data: [{
          url: ''
        }]
      },
      facade: {
        background_color: '',
        page_padding: 0,
        // 页面间距
        radius: 0,
        align: 'left',
        maxHeight: 100,
        temp: []
      }
    }, {
      name: 'contact_information',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/banner_icon.png',
      title: '联系方式',
      content: {
        time: '周一到周五 10:00-18:00',
        headOfficeTitle: '总部',
        headOffice: [{
          name: '设置名称',
          address: '设置地址',
          other: '设置联系方式'
        }],
        company: [],
        office: []
      },
      facade: {
        pageMarginTop: 0,
        pageMarginRight: 0,
        pageMarginBottom: 0,
        pageMarginLeft: 0,
        pagePaddingTop: 10,
        pagePaddingRight: 10,
        pagePaddingBottom: 40,
        pagePaddingLeft: 10,
        pageRadiusTopLeft: 0,
        pageRadiusTopRight: 0,
        pageRadiusBottomLeft: 0,
        pageRadiusBottomRight: 0,
        maxHeight: 526,
        pageBackground: 'rgba(37, 35, 31, 1)',
        pageColor: 'rgba(168, 168, 168, 1)',
        titleColor: 'rgba(156, 128, 79, 1)',
        titleFontSize: 14,
        fontSize: 13,
        nameFontSize: 14,
        lineHeight: 26,
        itemPaddingTop: 0,
        itemPaddingLeft: 0,
        itemPaddingBottom: 26,
        itemPaddingRight: 0,
        lineBackgound: 'rgba(76, 133, 212, 0.07)',
        lineSize: 1,
        temp: []
      }
    }, {
      name: 'auction_notice_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/kongge_icon.png',
      title: '参拍须知',
      content: {
        data: ''
      },
      // 外观
      facade: {
        pageMarginTop: -20,
        pageMarginRight: 0,
        pageMarginBottom: 0,
        pageMarginLeft: 0,
        pagePaddingTop: 34,
        pagePaddingRight: 23,
        pagePaddingBottom: 23,
        pagePaddingLeft: 23,
        pageBackground: 'rgba(68, 68, 68, 0.14)',
        pageColor: 'rgba(168, 168, 168, 1)',
        fontSize: 15,
        fontWeight: 400,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 1,
        borderBottom: 0,
        borderColor: 'rgba(156, 128, 79, 0.72)',
        temp: []
      }
    }, {
      name: 'annual_solicitation_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/kongge_icon.png',
      title: '常年征集',
      content: {
        title: '常年征集',
        mer_collection_category: [],
        mer_collection_email_data: [],
        company_address: '',
        contact_phone: '',
        mer_pc_website: '',
        mer_media_make_logo_data: [],
        mer_qrcode_data: []
      },
      // 外观
      facade: {
        pageMarginTop: 0,
        pageMarginRight: 0,
        pageMarginBottom: 0,
        pageMarginLeft: 0,
        pagePaddingTop: 20,
        pagePaddingRight: 0,
        pagePaddingBottom: 20,
        pagePaddingLeft: 0,
        pageBackground: 'rgba(255, 255, 255, 1)',
        pageColor: 'rgba(0, 0, 0, 1)',
        fontSize: 15,
        fontWeight: 400,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderBottom: 0,
        borderColor: 'rgba(156, 128, 79, 0.72)',
        temp: []
      }
    }, {
      name: 'position_weChat',
      icon: 'https://saas.cdn.yunzongbu.cn/icon/kongge_icon.png',
      title: '地理位置',
      content: {
        address_title: '',
        address: '',
        address_name: '',
        address_coord: '',
        address_item: {
          location: {}
        }
      },
      // 外观
      facade: {
        pageMarginTop: 0,
        pageMarginRight: 0,
        pageMarginBottom: 0,
        pageMarginLeft: 0,
        pagePaddingTop: 0,
        pagePaddingRight: 0,
        pagePaddingBottom: 0,
        pagePaddingLeft: 0,
        pageRadiusTopLeft: 0,
        pageRadiusTopRight: 0,
        pageRadiusBottomLeft: 0,
        pageRadiusBottomRight: 0,
        pageBackground: '',
        pageColor: '#7D90A9',
        fontSize: 16,
        align: 'center',
        fontWeight: 600,
        titleColor: 'rgba(62, 62, 62, 1)',
        titleFontSize: 14,
        titleFontWeight: 600,
        addressColor: 'rgba(62, 62, 62, 1)',
        addressFontSize: 14,
        addressFontWeight: 400,
        temp: []
      }
    }]
  }
};
var _default = exports.default = panels;