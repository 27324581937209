var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt20" }, [
    _c("div", [
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.auction_examine,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "auction_examine", $$v)
                },
                expression: "ruleData.auction_examine",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [
          _vm._v("六位以上金额展示格式为"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.ruleData.price_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "price_format", $$v)
                  },
                  expression: "ruleData.price_format",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("15,000")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("1.5万")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              on: { change: _vm.compelAnonymousAction },
              model: {
                value: _vm.ruleData.compel_anonymous,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "compel_anonymous", $$v)
                },
                expression: "ruleData.compel_anonymous",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("买家出价昵称")]),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.ruleData.nickname_anonymous_type,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "nickname_anonymous_type", $$v)
                  },
                  expression: "ruleData.nickname_anonymous_type",
                },
              },
              [
                _c(
                  "el-radio",
                  {
                    attrs: {
                      disabled: _vm.ruleData.compel_anonymous == 1,
                      label: 1,
                    },
                  },
                  [_vm._v("微信昵称（张*亮）")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    attrs: {
                      disabled: _vm.ruleData.compel_anonymous == 1,
                      label: 2,
                    },
                  },
                  [_vm._v("手机号（139****8823）")]
                ),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 3 } }, [_vm._v("买家号牌")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 4 } }, [_vm._v("完全匿名")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("买家出价头像")]),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.ruleData.avatar_anonymous_type,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "avatar_anonymous_type", $$v)
                  },
                  expression: "ruleData.avatar_anonymous_type",
                },
              },
              [
                _c(
                  "el-radio",
                  {
                    attrs: {
                      disabled: _vm.ruleData.compel_anonymous == 1,
                      label: 1,
                    },
                  },
                  [_vm._v("微信头像")]
                ),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("默认头像")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.bid_confirm,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "bid_confirm", $$v)
                },
                expression: "ruleData.bid_confirm",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.negotiate_purchase_switch,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "negotiate_purchase_switch", $$v)
                },
                expression: "ruleData.negotiate_purchase_switch",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(4),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.confirm_network_bidding,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "confirm_network_bidding", $$v)
                },
                expression: "ruleData.confirm_network_bidding",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(5),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.finish_confirm_number,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "finish_confirm_number", $$v)
                },
                expression: "ruleData.finish_confirm_number",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(6),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.finish_show_transaction_price,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "finish_show_transaction_price", $$v)
                },
                expression: "ruleData.finish_show_transaction_price",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(7),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.confirm_start_price,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "confirm_start_price", $$v)
                },
                expression: "ruleData.confirm_start_price",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(8),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.order_signing_agreement,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "order_signing_agreement", $$v)
                },
                expression: "ruleData.order_signing_agreement",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(9),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": "1", "inactive-value": "0" },
              model: {
                value: _vm.ruleData.auction_picture_show,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "auction_picture_show", $$v)
                },
                expression: "ruleData.auction_picture_show",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(10),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": "1", "inactive-value": "0" },
              model: {
                value: _vm.ruleData.check_agreement,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "check_agreement", $$v)
                },
                expression: "ruleData.check_agreement",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(11),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("el-input", {
              staticClass: "w200 ml10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.dialog_title,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "dialog_title", $$v)
                },
                expression: "ruleData.dialog_title",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(12),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.auction_image_show_message,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "auction_image_show_message", $$v)
                },
                expression: "ruleData.auction_image_show_message",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(13),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.auction_refund_entrust_deliver_sign,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.ruleData,
                    "auction_refund_entrust_deliver_sign",
                    $$v
                  )
                },
                expression: "ruleData.auction_refund_entrust_deliver_sign",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _vm._m(14),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.settlementRatio,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "settlementRatio", $$v)
                },
                expression: "ruleData.settlementRatio",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.ruleData.fee_type != "CNY"
        ? _c("div", { staticClass: "item flex align-items-c h50" }, [
            _vm._m(15),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c("el-input", {
                  staticClass: "w200 ml10",
                  attrs: { controls: false, min: 0 },
                  model: {
                    value: _vm.ruleData.fee_rate,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleData, "fee_rate", $$v)
                    },
                    expression: "ruleData.fee_rate",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [
          _vm._v("直播间结拍后不展示专场拍卖数据统计"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.liveEndNotShowAlbumData,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "liveEndNotShowAlbumData", $$v)
                },
                expression: "ruleData.liveEndNotShowAlbumData",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [
          _vm._v(
            "委托方自己成交是否举回（委托方自己成交自己的拍品，订单状态是否自动举回）"
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.auction_entrust_self_transaction_is_juhui,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.ruleData,
                    "auction_entrust_self_transaction_is_juhui",
                    $$v
                  )
                },
                expression:
                  "ruleData.auction_entrust_self_transaction_is_juhui",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [
          _vm._v("打印竞买协议时，不打印证件照片"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("el-switch", {
              attrs: { "active-value": 1, "inactive-value": 0 },
              model: {
                value: _vm.ruleData.printPhotos,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "printPhotos", $$v)
                },
                expression: "ruleData.printPhotos",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(16),
    _vm._v(" "),
    _c("div", { staticStyle: { "padding-bottom": "50px" } }, [
      _c(
        "div",
        {
          staticClass: "item flex align-items-c",
          staticStyle: { padding: "10px 0" },
        },
        [
          _c("div", { staticClass: "flex-one" }, [_vm._v("原跳转ID")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-input", {
                staticStyle: { width: "110px" },
                attrs: { resize: "none", placeholder: "没有可不填" },
                model: {
                  value: _vm.ruleData.transfer_video_costom_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "transfer_video_costom_id", $$v)
                  },
                  expression: "ruleData.transfer_video_costom_id",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item flex align-items-c",
          staticStyle: { padding: "10px 0" },
        },
        [
          _c("div", { staticClass: "flex-one" }, [_vm._v("视频号拍卖大厅")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("div", [_vm._v("专场ID：")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "110px" },
                attrs: { resize: "none", placeholder: "没有可不填" },
                model: {
                  value: _vm.ruleData.transfer_album_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "transfer_album_id", $$v)
                  },
                  expression: "ruleData.transfer_album_id",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ml20" }, [_vm._v("直播ID：")]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "110px" },
                attrs: { resize: "none", placeholder: "没有可不填" },
                model: {
                  value: _vm.ruleData.transfer_live_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "transfer_live_id", $$v)
                  },
                  expression: "ruleData.transfer_live_id",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "formFixedBox" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("上传拍品审核功能"),
      _c("span", [
        _vm._v("（开启后，上传的拍品需要再点一次审核后才能发布成功）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("强制匿名参拍"),
      _c("span", [_vm._v("（买家使用号牌出价）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("同步拍直播间，买家出价时，弹窗提示确认出价"),
      _c("span", [_vm._v("（防止买家误操作）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("拍品流拍后可洽购"),
      _c("span", [
        _vm._v(
          "（开启后，流拍的拍品，显示可洽购，意向买家可点击洽购与客服私信沟通）"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("同步拍网络出价需确认"),
      _c("span", [_vm._v("（开启后，网络出价用户需要拍卖师确认后有效）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("出价控制台落槌时输入号牌"),
      _c("span", [_vm._v("（开启后，输入号牌才能落槌）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("结拍展示成交价"),
      _c("span", [_vm._v("（结拍后价格展示落槌价+佣金）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("出价控制台确认起拍价"),
      _c("span", [
        _vm._v("（开启后，下一件拍品，操作“确认起拍价”后，用户才能出价）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("同步拍订单签署委托发货协议"),
      _c("span", [
        _vm._v("（开启后，网络卖家签署委托发货协议后，才能等待收货）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("横屏直播背景使用拍品图"),
      _c("span", [_vm._v("（横屏直播时，上下空间使用当前拍品图片做背景）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("用户出价时，勾选同意协议"),
      _c("span", [
        _vm._v("（开启后，用户对每一个拍卖会出价时，需勾选一次同意协议）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("拍卖弹窗标题"),
      _c("span", [
        _vm._v("（专场设置弹窗介绍后展示，不填默认为 ‘敬请买家注意’）"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("拍品轮播图在详情重复展示"),
      _c("span", [_vm._v("（开启后，轮播图在详情介绍里重复再展示一次）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("拍品退回，委托发货时签署协议"),
      _c("span"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("拍卖结算单佣金比例展示（默认展示，开启后不展示佣金比例）"),
      _c("span"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-one" }, [
      _vm._v("货币汇率"),
      _c("span"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-items-c mb10 mt40" }, [
      _c("div", { staticClass: "flex-one t ft14 fWeight500" }, [
        _vm._v("迁移设置"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ft14 fWeight500" }, [_vm._v("操作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }