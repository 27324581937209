var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "dialogBody" }, [
            _c("div", { staticClass: "flex dialogCon" }, [
              _c(
                "span",
                [
                  _vm._v("视频类型: \n          "),
                  _vm.moduleForm.make_type == 1
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.moduleForm.make_tag == 1
                              ? "拍卖会导览"
                              : "结拍快讯"
                          )
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.moduleForm.make_type == 2
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.moduleForm.make_tag == 1
                              ? "专场导览"
                              : "结拍快讯"
                          )
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.moduleForm.make_type == 3
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.moduleForm.make_tag == 1
                              ? "拍品导览"
                              : "结拍快讯"
                          )
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v(
                  "计划发布时间: \n          " +
                    _vm._s(_vm.moduleForm.released_plan_date) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.moduleForm.make_type == 3
                ? _c("span", [
                    _vm._v(
                      "拍品信息: \n            " +
                        _vm._s(
                          _vm.moduleForm.makeContentData.product.store_name
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm.moduleForm.make_type == 2
                  ? _c("span", [
                      _vm._v(
                        "所属拍卖会: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3
                  ? _c("span", [
                      _vm._v(
                        "\n            所属专场: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title) +
                          " - " +
                          _vm._s(
                            _vm.moduleForm.makeContentData.album.album_name
                          ) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex mt10" }, [
              _vm.moduleForm.extend_data &&
              _vm.moduleForm.extend_data.video_info &&
              _vm.moduleForm.extend_data.video_info.url
                ? _c(
                    "div",
                    { staticStyle: { width: "40%" } },
                    [
                      _c("div", { staticClass: "mmBox" }, [
                        _vm.moduleVisible
                          ? _c("video", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                controls: "",
                                src: _vm.moduleForm.extend_data.video_info.url,
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            background: "#704af3 !important",
                            border: "1px solid #704af3 !important",
                          },
                          attrs: {
                            type: "primary",
                            size: "small",
                            loading: _vm.loadBut,
                          },
                          on: { click: _vm.onDownload },
                        },
                        [_vm._v("下载视频")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            background: "#704af3 !important",
                            border: "1px solid #704af3 !important",
                          },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.onSame },
                        },
                        [_vm._v("制作同款")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-one waBox" },
                [
                  _c(
                    "div",
                    { staticClass: "mmBox" },
                    [
                      _c("div", { staticClass: "textTit" }, [
                        _vm._v("视频描述文案："),
                      ]),
                      _vm._v(" "),
                      _vm.moduleForm.videoReleasedInfo
                        ? _c("el-input", {
                            staticClass: "sortColOne",
                            attrs: { type: "textarea" },
                            on: { change: _vm.mackTitChange },
                            model: {
                              value:
                                _vm.moduleForm.videoReleasedInfo.videoIntroText,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.moduleForm.videoReleasedInfo,
                                  "videoIntroText",
                                  $$v
                                )
                              },
                              expression:
                                "moduleForm.videoReleasedInfo.videoIntroText",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        background: "#704af3 !important",
                        border: "1px solid #704af3 !important",
                      },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onCopyText },
                    },
                    [_vm._v("复制文案")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("SelectAuctionView", {
        ref: "selectAuctionViewRef",
        attrs: {
          venue_id: _vm.moduleForm.venue_id,
          relation_id: _vm.relation_id,
          currentData: [],
        },
        on: { selectMessage: _vm.onAuctionMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }