var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "flex align-items-c mb20" },
        [
          _c(
            "el-radio-group",
            {
              attrs: { type: "button" },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "" } }, [_vm._v("全部")]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v("待制作"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v("待审核"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "3" } }, [
                _vm._v("待发布"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "4" } }, [
                _vm._v("已完成"),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "5" } }, [
                _vm._v("已删除"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 230px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              attrs: { border: "", height: "100%", data: _vm.resultData },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                key: "1",
                attrs: { type: "selection", width: "36", align: "center" },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发布日期",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.released_plan_date)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "时间",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.released_plan_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "账号名称",
                  "min-width": "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.channelsItem.account_name)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "账号定位",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            _vm._s(scope.row.channelsItem.accountPositionText)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "类型",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(
                            _vm._s(
                              scope.row.channelsItem.accountBindContentTypeText
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "推送内容",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.marketMake &&
                        (scope.row.marketMake.make_status == 3 ||
                          scope.row.marketMake.make_status == 4)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOpenDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看内容")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发布方式",
                  width: "120",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.promotionTypeText)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "制作人",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.marketMake && scope.row.marketMake.makeAdmin
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                _vm._s(scope.row.marketMake.makeAdmin.real_name)
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "制作时间",
                  width: "180",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.marketMake
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(_vm._s(scope.row.marketMake.make_time)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发布人",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.releasedAdmin
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(_vm._s(scope.row.releasedAdmin.real_name)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发布时间",
                  width: "180",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.released_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            scope.row.is_released == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onRelease(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("发布完成")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.is_released == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onUpd(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.marketMake &&
                            scope.row.marketMake.make_status == 3 &&
                            ((scope.row.audit &&
                              scope.row.audit.auditable_admin_id.includes(
                                _vm.merchant_admin_id + ""
                              )) ||
                              _vm.level == 0)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.revieworder(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("审批")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDele(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "4px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.reviewAll },
                },
                [_vm._v("审核")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onReleaseAll },
                },
                [_vm._v("发布完成")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "dialogBody" }, [
            _c("div", { staticClass: "flex dialogCon" }, [
              _c("span", [
                _vm._v("视频类型: \n          "),
                _vm.moduleForm.make_type == 1
                  ? _c("span", [_vm._v("拍卖会介绍视频")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 2
                  ? _c("span", [_vm._v("专场介绍视频")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                  ? _c("span", [_vm._v("拍品介绍视频")])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "计划发布时间: \n          " +
                    _vm._s(_vm.moduleForm.released_plan_date) +
                    " " +
                    _vm._s(_vm.moduleForm.released_plan_time) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                ? _c("span", [
                    _vm._v(
                      "拍品信息: \n            " +
                        _vm._s(
                          _vm.moduleForm.makeContentData.product.store_name
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm.moduleForm.make_type == 2
                  ? _c("span", [
                      _vm._v(
                        "所属拍卖会: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                  ? _c("span", [
                      _vm._v(
                        "\n            所属专场: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title) +
                          " - " +
                          _vm._s(
                            _vm.moduleForm.makeContentData.album.album_name
                          ) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _vm.moduleForm.extend_data &&
              _vm.moduleForm.extend_data.video_info
                ? _c("div", { staticStyle: { width: "50%" } }, [
                    _c("video", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        controls: "",
                        src: _vm.moduleForm.extend_data.video_info.url,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "10px",
                          "text-align": "center",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.onDownload },
                          },
                          [_vm._v("下载视频")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.mask,
              callback: function ($$v) {
                _vm.mask = $$v
              },
              expression: "mask",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showreview = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(-1)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(1)
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑任务",
            visible: _vm.channelVisible,
            "close-on-click-modal": false,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.channelVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号渠道", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      on: { change: _vm.getChannList },
                      model: {
                        value: _vm.channelForm.channels,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channels", $$v)
                        },
                        expression: "channelForm.channels",
                      },
                    },
                    _vm._l(_vm.channelType.channels, function (item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账号", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      model: {
                        value: _vm.channelForm.channels_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channels_id", $$v)
                        },
                        expression: "channelForm.channels_id",
                      },
                    },
                    _vm._l(_vm.channList, function (item) {
                      return _c("el-option", {
                        key: item.account_name,
                        attrs: { label: item.account_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发布时间", required: "" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.channelForm.released_plan_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.channelForm, "released_plan_time", $$v)
                      },
                      expression: "channelForm.released_plan_time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.channelVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("videoDetails", { ref: "videoViewRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }