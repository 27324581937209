var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "section",
      {
        staticStyle: { width: "100%", display: "flex" },
        style: {
          backgroundColor: _vm.facade.background_color,
          paddingLeft: _vm.facade.page_padding + "px",
          paddingRight: _vm.facade.page_padding + "px",
          overflow: "hidden",
          borderRadius: _vm.facade.radius + "px",
          justifyContent: _vm.showType(_vm.facade.align),
        },
      },
      [
        _vm._l(_vm.content.data, function (item, index) {
          return [
            item.url
              ? _c("img", {
                  key: "content" + index,
                  staticStyle: { display: "block" },
                  style: "max-height: " + _vm.facade.maxHeight + "px;",
                  attrs: { src: item.url, alt: "" },
                })
              : _c("img", {
                  key: "c" + index,
                  staticStyle: { display: "block" },
                  style: "max-height: " + _vm.facade.maxHeight + "px;",
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                    alt: "",
                  },
                }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }