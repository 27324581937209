var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container help-detail",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "padding20 bg-white borderRadius6",
          staticStyle: { position: "relative" },
        },
        [
          _c(
            "div",
            { staticClass: "remove-control form-view" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "110px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入活动名称" },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间：", prop: "date_time" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.ruleForm.date_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "date_time", $$v)
                              },
                              expression: "ruleForm.date_time",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "抽奖形式", prop: "type", required: "" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "type", $$v)
                            },
                            expression: "ruleForm.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("大转盘"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("九宫格"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片上传：", required: "" } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", [
                          _vm.ruleForm.config.background_image
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "upload-image flex align-items-c justify-c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeImg(
                                        "",
                                        "background_image",
                                        "config"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.config.background_image +
                                        "!120a",
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "del-upload-img",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delImg(
                                            "background_image",
                                            "config"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "upload-image flex align-items-c justify-c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeImg(
                                        "",
                                        "background_image",
                                        "config"
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-picture" })]
                              ),
                          _vm._v(" "),
                          _c("div", [_vm._v("主背景图")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "20px" } }, [
                          _vm.ruleForm.config.start_image
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "upload-image flex align-items-c justify-c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeImg(
                                        "",
                                        "start_image",
                                        "config"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.config.start_image +
                                        "!120a",
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "del-upload-img",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delImg(
                                            "start_image",
                                            "config"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "upload-image flex align-items-c justify-c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeImg(
                                        "",
                                        "start_image",
                                        "config"
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-picture" })]
                              ),
                          _vm._v(" "),
                          _c("div", [_vm._v("开始抽奖按钮")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#999",
                                "line-height": "13px",
                              },
                            },
                            [_vm._v("1:1正方形")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "margin-left": "20px" } }, [
                          _vm.ruleForm.config.center_image
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "upload-image flex align-items-c justify-c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeImg(
                                        "",
                                        "center_image",
                                        "config"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.ruleForm.config.center_image +
                                        "!120a",
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "del-upload-img",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.delImg(
                                            "center_image",
                                            "config"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "upload-image flex align-items-c justify-c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeImg(
                                        "",
                                        "center_image",
                                        "config"
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-picture" })]
                              ),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.ruleForm.type == 1 ? "转盘图" : "宫格图"
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#999",
                                "line-height": "13px",
                              },
                            },
                            [_vm._v("1:1正方形")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.ruleForm.type == 2
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _vm.ruleForm.config.center_bg_image
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-image flex align-items-c justify-c",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeImg(
                                              "",
                                              "center_bg_image",
                                              "config"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.ruleForm.config
                                                .center_bg_image + "!120a",
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "del-upload-img",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.delImg(
                                                  "center_bg_image",
                                                  "config"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-image flex align-items-c justify-c",
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeImg(
                                              "",
                                              "center_bg_image",
                                              "config"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-picture",
                                        }),
                                      ]
                                    ),
                                _vm._v(" "),
                                _c("div", [_vm._v("宫格背景")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#999",
                                      "line-height": "13px",
                                    },
                                  },
                                  [_vm._v("1:1正方形")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("页面背景色：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "34px" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                            model: {
                              value: _vm.ruleForm.config.background_color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.config,
                                  "background_color",
                                  $$v
                                )
                              },
                              expression: "ruleForm.config.background_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [
                        _vm._v("文字颜色："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                            model: {
                              value: _vm.ruleForm.config.text_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm.config, "text_color", $$v)
                              },
                              expression: "ruleForm.config.text_color",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("奖品名称：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "34px" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                            model: {
                              value: _vm.ruleForm.config.name_color,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm.config, "name_color", $$v)
                              },
                              expression: "ruleForm.config.name_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [
                        _vm._v("选中发光色："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "28px" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                            model: {
                              value: _vm.ruleForm.config.select_color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.config,
                                  "select_color",
                                  $$v
                                )
                              },
                              expression: "ruleForm.config.select_color",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("按钮背景：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "34px" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                            model: {
                              value: _vm.ruleForm.config.button_bg_color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.config,
                                  "button_bg_color",
                                  $$v
                                )
                              },
                              expression: "ruleForm.config.button_bg_color",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "l ml20" }, [
                        _vm._v("按钮文字："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { width: "250px", height: "34px" } },
                        [
                          _c("colour", {
                            attrs: { colour: "#FFFFFF", "no-alpha": "1" },
                            model: {
                              value: _vm.ruleForm.config.button_text_color,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm.config,
                                  "button_text_color",
                                  $$v
                                )
                              },
                              expression: "ruleForm.config.button_text_color",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "奖品设置", required: "" } },
                    [
                      _c(
                        "el-table",
                        { attrs: { data: _vm.prize_data } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              prop: "number",
                              width: "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "奖品类型" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.editTypeAction(
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.type,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "type", $$v)
                                          },
                                          expression: "scope.row.type",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            value: 1,
                                            label: "谢谢参与",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            value: 2,
                                            label: "数字藏品",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            value: 3,
                                            label: "数字盲盒",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 4, label: "积分" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 5, label: "优惠券" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 6, label: "商品" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "奖品" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.name
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "flex align-items-c",
                                            staticStyle: {
                                              height: "36px",
                                              overflow: "hidden",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "line2 flex-one",
                                                staticStyle: {
                                                  "line-height": "18px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(scope.row.name) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.type == 2 ||
                                    scope.row.type == 3 ||
                                    scope.row.type == 5 ||
                                    scope.row.type == 6
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAdditem(
                                                  scope.row.type,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.type == 4
                                      ? _c("el-input-number", {
                                          staticStyle: { width: "100px" },
                                          attrs: { controls: false },
                                          model: {
                                            value: scope.row.inum,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "inum", $$v)
                                            },
                                            expression: "scope.row.inum",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "奖品图片" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.prize_image
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "upload-image flex align-items-c justify-c",
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeImg(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  scope.row.prize_image +
                                                  "!120a",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "upload-image flex align-items-c justify-c",
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeImg(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-picture",
                                            }),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "奖品名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      model: {
                                        value: scope.row.prize_name,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "prize_name", $$v)
                                        },
                                        expression: "scope.row.prize_name",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "奖品数量" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100px" },
                                      attrs: { controls: false },
                                      model: {
                                        value: scope.row.total_num,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "total_num", $$v)
                                        },
                                        expression: "scope.row.total_num",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "人群&次数", required: "" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.selectType,
                            callback: function ($$v) {
                              _vm.selectType = $$v
                            },
                            expression: "selectType",
                          },
                        },
                        [
                          _vm._v("不限人群   最多可抽：\n            "),
                          _c("el-input-number", {
                            staticStyle: { width: "100px" },
                            attrs: { controls: false },
                            model: {
                              value: _vm.ruleForm.max,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "max", $$v)
                              },
                              expression: "ruleForm.max",
                            },
                          }),
                          _vm._v("\n             次"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.selectType,
                            callback: function ($$v) {
                              _vm.selectType = $$v
                            },
                            expression: "selectType",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _vm._v(
                                    "\n                自定义人群次数\n                "
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "post" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("导入")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "export-file",
                                        attrs: { type: "file" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeExselAction($event)
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        "\n                  导入数量：" +
                                          _vm._s(_vm.fileNumber) +
                                          "\n                "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ml10",
                                      attrs: { size: "small", type: "text" },
                                      on: { click: _vm.downLoad },
                                    },
                                    [_vm._v("下载导入模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "免费玩" } },
                    [
                      _vm._v("\n          开局可免费玩：\n            "),
                      _c("el-input-number", {
                        staticStyle: { width: "100px" },
                        attrs: { controls: false },
                        model: {
                          value: _vm.ruleForm.free,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "free", $$v)
                          },
                          expression: "ruleForm.free",
                        },
                      }),
                      _vm._v(
                        "\n             次   （免费玩次数不计算在最多可抽次数中）\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "抽奖规则", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("div", { staticClass: "l" }, [
                            _vm._v("每次抽奖需要消耗："),
                          ]),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticStyle: { width: "100px" },
                            attrs: { controls: false },
                            model: {
                              value: _vm.ruleForm.integral,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "integral", $$v)
                              },
                              expression: "ruleForm.integral",
                            },
                          }),
                          _vm._v("\n             积分\n            "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动规则：", required: "" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.ruleForm.details,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "details", $$v)
                          },
                          expression: "ruleForm.details",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "common-btns",
          class: { left0: _vm.device === "mobile" },
          style: { left: _vm.sidebar.opened ? "230px" : "74px" },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveAction("ruleForm")
                },
              },
            },
            [_vm._v("确认提交")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectProduct", {
        ref: "product",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Productblind", {
        ref: "blind_box",
        attrs: { "current-data": [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Coupon", {
        ref: "coupon",
        attrs: { currentData: [], size: "1" },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }