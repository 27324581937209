"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
var _aiMark = require("@/api/aiMark");
var _user = require("@/api/user.js");
var _review = require("@/api/review");
var _videoDetails = _interopRequireDefault(require("@/views/aiMarketing/module/videoDetails"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    videoDetails: _videoDetails.default
  },
  data: function data() {
    return {
      searchQuery: {},
      listLoading: true,
      resultData: [],
      total: 0,
      tableFrom: {
        page: 1,
        limit: 20
      },
      selectTableRows: [],
      moduleVisible: false,
      moduleForm: {
        marketMake: {}
      },
      level: '',
      merchant_admin_id: '',
      type: 1,
      mask: '',
      showreview: false,
      audit_id: '',
      rowLinkID: '',
      channelVisible: false,
      channelForm: {},
      channelType: {},
      channList: [],
      status: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      _this.level = res.data.merchantAdmin.level;
      _this.merchant_admin_id = res.data.merchantAdmin.merchant_admin_id;
    });
    this.getList();
    (0, _aiMark.getChannelsTypeApi)().then(function (res) {
      var obj = {};
      for (var k in res.data) {
        var arr = [];
        for (var key in res.data[k]) {
          arr.push({
            label: res.data[k][key],
            value: key
          });
        }
        obj[k] = arr;
      }
      // console.log(obj);
      _this.channelType = obj;
    });
  },
  methods: {
    onUpd: function onUpd(row) {
      this.channelForm = {
        id: row.id,
        channels_id: row.channels_id,
        channels: row.channels,
        released_plan_time: row.released_time
      };
      this.getChannList();
      this.channelVisible = true;
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      (0, _aiMark.promoUpdateApi)(this.channelForm.id, this.channelForm).then(function (res) {
        _this2.channelVisible = false;
        _this2.$message.success('操作成功');
        _this2.getList();
      });
    },
    getChannList: function getChannList() {
      var _this3 = this;
      (0, _aiMark.groupListApi)({
        channels: this.channelForm.channels
      }).then(function (res) {
        // console.log(res);
        _this3.channList = res.data.list;
      });
    },
    onDele: function onDele(row) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u4EFB\u52A1?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _aiMark.promoDeleApi)({
          ids: row.id
        }).then(function (res) {
          _this4.$message.success('操作成功');
          _this4.getList();
        });
      }).catch(function () {});
    },
    revieworder: function revieworder(row) {
      this.type = 1;
      this.audit_id = row.marketMake.audit.audit_id;
      this.rowLinkID = row.marketMake.market_make_id;
      this.showreview = true;
      this.mask = '';
    },
    reviewAll: function reviewAll() {
      if (this.selectTableRows.length <= 0) {
        this.$message.error('请选择任务');
        return false;
      }
      var idsArr = this.selectTableRows.map(function (item) {
        return item.marketMake.market_make_id;
      });
      var uniqueArr = Array.from(new Set(idsArr));
      this.type = 2;
      this.rowLinkID = uniqueArr.join(',');
      this.showreview = true;
      this.mask = '';
    },
    handlereview: function handlereview(state) {
      var _this5 = this;
      if (state == -1 && !this.mask) {
        this.$message.error('请填写拒绝备注');
        return;
      }
      var aucUrl = '';
      if (this.type == 2) {
        aucUrl = (0, _review.batchReview)({
          status: state,
          remark: this.mask,
          type: 'market_media_make',
          link_ids: this.rowLinkID
        });
      } else {
        aucUrl = (0, _review.review)(this.audit_id, {
          status: state,
          remark: this.mask,
          type: 'market_media_make',
          link_id: this.rowLinkID
        });
      }
      aucUrl.then(function (res) {
        _this5.$message.success('操作成功');
        _this5.getList();
        _this5.showreview = false;
      }).catch(function (err) {
        _this5.$message.error(err.message);
      });
    },
    onOpenDetails: function onOpenDetails(row) {
      this.$refs.videoViewRef.onOpenInit(row.market_make_id, "".concat(row.released_plan_date, " ").concat(row.released_plan_time));
    },
    getList: function getList() {
      var _this6 = this;
      this.listLoading = true;
      this.tableFrom.venue_id = this.$route.query.venue_id;
      (0, _aiMark.promotionListApi)(this.tableFrom).then(function (res) {
        console.log(res);
        _this6.total = res.data.count;
        _this6.resultData = res.data.list;
        _this6.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onRelease: function onRelease(_ref) {
      var _this7 = this;
      var id = _ref.id;
      (0, _aiMark.releasedApi)({
        ids: id
      }).then(function (res) {
        _this7.$message.success('操作成功');
        _this7.pageChange(1);
      });
    },
    onReleaseAll: function onReleaseAll() {
      var _this8 = this;
      var ids = [];
      if (this.selectTableRows.length <= 0) {
        this.$message.error('请选择任务');
        return false;
      }
      for (var index = 0; index < this.selectTableRows.length; index++) {
        var element = this.selectTableRows[index];
        if (element.releasedAdmin) {
          this.$message.error('请选择未发布状态');
          return false;
        } else if (!element.marketMake) {
          this.$message.error('请选择可以发布的内容');
          return false;
        } else {
          ids.push(element.id);
        }
      }
      (0, _aiMark.releasedApi)({
        ids: ids.join(',')
      }).then(function (res) {
        _this8.$message.success('操作成功');
        _this8.pageChange(1);
      });
    },
    handleSelectionChange: function handleSelectionChange(data) {
      this.selectTableRows = data;
    },
    onDownload: function onDownload() {
      var _this9 = this;
      fetch(this.moduleForm.extend_data.video_info.url).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        var name;
        if (_this9.moduleForm.make_type == 1) name = "".concat(_this9.moduleForm.make_title, "-\u62CD\u5356\u4F1A\u89C6\u9891");
        if (_this9.moduleForm.make_type == 2) name = "".concat(_this9.moduleForm.make_title, "-\u4E13\u573A\u89C6\u9891");
        if (_this9.moduleForm.make_type == 3 || _this9.moduleForm.make_type == 4) name = "".concat(_this9.moduleForm.make_title, "-\u62CD\u54C1\u89C6\u9891");
        var a = document.createElement("a");
        var objectUrl = window.URL.createObjectURL(blob);
        a.download = "".concat(name, ".mp4");
        a.href = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      });
    }
  }
};