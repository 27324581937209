var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-view mt20" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.createdAction } },
            [_vm._v("创建积分排行统计")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "统计时间", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.start_time) +
                              " 至 " +
                              _vm._s(scope.row.end_time)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "链接", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "/pages/marketing/integral/ranking?id=" +
                              _vm._s(scope.row.id)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.add_time))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看排行榜")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                staticClass: "mt20",
                staticStyle: { "text-align": "right" },
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "40%",
            visible: _vm.receiveData.show,
            "append-to-body": "",
            title: "查看排行榜",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.receiveData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "receive-list" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出已生成列表")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.receiveData.listLoading,
                      expression: "receiveData.listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.receiveData.list,
                    size: "small",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "排行", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  (_vm.receiveData.query.page - 1) *
                                    _vm.receiveData.query.limit +
                                    scope.$index +
                                    1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "头像", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.user && scope.row.user.avatar
                              ? _c("div", [
                                  _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                    },
                                    attrs: {
                                      src: scope.row.user.avatar,
                                      alt: "",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "用户信息",
                      "min-width": "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.user && scope.row.user.uid
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.user.real_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(scope.row.user.phone)),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "积分数量",
                      align: "right",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.integral_num))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "block mt20",
                  staticStyle: { "text-align": "right" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.receiveData.query.limit,
                      "current-page": _vm.receiveData.query.page,
                      total: _vm.receiveData.total,
                    },
                    on: { "current-change": _vm.pageChangeNew },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "560px",
            "close-on-click-modal": false,
            visible: _vm.setData.show,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setData, "show", $event)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("创建积分排行统计"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt10" }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("span", { staticStyle: { width: "120px" } }, [
                  _vm._v("名称"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "flex-one",
                  attrs: { placeholder: "单行输入", size: "small" },
                  model: {
                    value: _vm.setData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.setData, "name", $$v)
                    },
                    expression: "setData.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex align-items-c mt10" },
              [
                _c("span", { staticStyle: { width: "120px" } }, [
                  _vm._v("统计排行日期"),
                ]),
                _vm._v(" "),
                _c("el-date-picker", {
                  attrs: {
                    type: "datetimerange",
                    "range-separator": "-",
                    "start-placeholder": "选择开始日期",
                    "end-placeholder": "选择结束日期",
                    format: "yyyy-MM-dd HH:mm:ss",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                  },
                  model: {
                    value: _vm.setData.time,
                    callback: function ($$v) {
                      _vm.$set(_vm.setData, "time", $$v)
                    },
                    expression: "setData.time",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }