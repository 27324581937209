var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "审核状态：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部 "),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("待审核"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("已审核"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "-1" } },
                                [_vm._v("已作废")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          staticStyle: { display: "block" },
                          attrs: { label: "时间选择：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: { type: "button", size: "small" },
                              on: {
                                change: function ($event) {
                                  return _vm.selectChange(_vm.tableFrom.date)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "date", $$v)
                                },
                                expression: "tableFrom.date",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              "value-format": "yyyy/MM/dd",
                              format: "yyyy/MM/dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              placeholder: "自定义时间",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "关键字：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.tableFrom.product_keyword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tableFrom,
                                    "product_keyword",
                                    $$v
                                  )
                                },
                                expression: "tableFrom.product_keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "width100", attrs: { label: "编号：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入编号",
                                size: "small",
                              },
                              model: {
                                value: _vm.tableFrom.order_no,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "order_no", $$v)
                                },
                                expression: "tableFrom.order_no",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path:
                                "" + _vm.roterPre + "/basic/inventorycreate",
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("创建委托标的清单")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.showconfig = true
                            },
                          },
                        },
                        [_vm._v("委托方收费配置")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.exportOrder },
                        },
                        [_vm._v("导出")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: { click: _vm.getExportFileList },
                        },
                        [_vm._v("导出记录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleSelection",
              staticClass: "table",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                "row-key": function (row) {
                  return row.order_id
                },
                data: _vm.tableData.data,
                size: "mini",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "委托方", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.userEntrust
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.userEntrust
                                      .entrust_contact_company
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "单据时间",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "order_no",
                  label: "单据编号",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "creator.real_name",
                  label: "制单人",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == -1
                          ? _c("div", [_vm._v("已作废")])
                          : scope.row.status == 1
                          ? _c("div", [_vm._v("审核通过")])
                          : scope.row.status == 0
                          ? _c("div", [_vm._v("待审核")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.configflag
                ? _c(
                    "el-table-column",
                    {
                      attrs: { "min-width": "1100" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.entrustOrderProduct,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "enp_" + index,
                                      staticClass: "flex-box",
                                      class: {
                                        "flex-box-border-bottom":
                                          index <
                                          scope.row.entrustOrderProduct.length -
                                            1,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-image" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview",
                                            },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  src: item.productItem.image,
                                                  "preview-src-list": [
                                                    item.productItem.image,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-name" },
                                        [
                                          _vm._v(
                                            _vm._s(item.productItem.store_name)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-price" },
                                        [_vm._v(_vm._s(item.retain_price))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-autor" },
                                        [
                                          item.productItem.brand
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      item.productItem.brand
                                                        .brand_name
                                                    ) +
                                                    "\n            "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-box-name" },
                                        _vm._l(
                                          item.productItem.attrInfo,
                                          function (m, i) {
                                            return _c(
                                              "span",
                                              { key: "attr_" + i },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(m.attr_name) +
                                                    ":" +
                                                    _vm._s(m.attr_value_name) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _vm._l(item.price_data, function (m, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: "pp_" + i,
                                            staticClass: "price-flex",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "price-flex-bottom",
                                              },
                                              _vm._l(m, function (im, ii) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "p_" + ii,
                                                    staticClass:
                                                      "flex-box-price",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(im.number) +
                                                        _vm._s(
                                                          im.unit == 1
                                                            ? "元"
                                                            : "%"
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }),
                                              0
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  )
                                }
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        2665098454
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", { staticClass: "flex-box" }, [
                          _c("div", { staticClass: "flex-box-image" }, [
                            _vm._v("图片"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-name" }, [
                            _vm._v("拍品名称"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-price" }, [
                            _vm._v("保留价"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-autor" }, [
                            _vm._v("作者"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-box-name" }, [
                            _vm._v("属性"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "price-flex" }, [
                            _c("div", { staticClass: "price-flex-top" }, [
                              _vm._v("成交收费"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "price-flex-bottom" },
                              _vm._l(
                                _vm.inventoryConfig.price.sold_price,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "sold_" + index,
                                      staticClass: "flex-box-price",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "price-flex" }, [
                            _c("div", { staticClass: "price-flex-top" }, [
                              _vm._v("未成交收费"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "price-flex-bottom" },
                              _vm._l(
                                _vm.inventoryConfig.price.unsold_price,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "unsold_" + index,
                                      staticClass: "flex-box-price",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "price-flex" }, [
                            _c("div", { staticClass: "price-flex-top" }, [
                              _vm._v("其他费用"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "price-flex-bottom" },
                              _vm._l(
                                _vm.inventoryConfig.price.other_price,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "other_" + index,
                                      staticClass: "flex-box-price",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "130",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 1px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            编辑\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "0 1px" },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.switchAction(scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n            作废\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 1px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.getInvoiceInfo(scope.row.order_id)
                              },
                            },
                          },
                          [_vm._v("\n            查看详情\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "委托标的清单",
            visible: _vm.inventoryDetailFlag,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.inventoryDetailFlag = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm.inventoryDetailAll.status == -1
                ? _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { color: "red" },
                          attrs: { span: 12, offset: 0 },
                        },
                        [
                          _vm._v(
                            "审核失败: " + _vm._s(_vm.inventoryDetailAll.remark)
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v("委托方："),
                    _vm.inventoryDetailAll.userEntrust
                      ? _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.inventoryDetailAll.userEntrust
                                  .entrust_contact_company
                              ) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(
                      "单据时间：" + _vm._s(_vm.inventoryDetailAll.create_time)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("入库时间：")]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _vm._v(
                      "单据编号：" + _vm._s(_vm.inventoryDetailAll.order_no)
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(
                _vm.inventoryDetailAll.storeCategory,
                function (pitem, index) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { margin: "20px 0" },
                        attrs: { size: "mini", data: pitem.orderProduct },
                      },
                      [
                        _c("template", { slot: "empty" }, [
                          _c("div", { staticClass: "tableEmpty" }, [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "tableDiv" }, [
                              _vm._v("暂无数据"),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "分类", width: "80" } },
                          [_c("div", [_vm._v(_vm._s(pitem.cate_name))])]
                        ),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "图片", width: "50" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "demo-image__preview" },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "36px",
                                            height: "36px",
                                          },
                                          attrs: {
                                            src: scope.row.product.image,
                                            "preview-src-list": [
                                              scope.row.product.image,
                                            ],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "名称", prop: "product.store_name" },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "保留价", prop: "retain_price" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(scope.row.retain_price) +
                                        "\n          "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "作者", prop: "product.brand" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.product.brand
                                      ? _c("div", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                scope.row.product.brand
                                                  .brand_name
                                              ) +
                                              "\n            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "属性",
                              prop: "order_pay_amount",
                              align: "center",
                            },
                          },
                          _vm._l(pitem.attribute, function (item, index) {
                            return _c("el-table-column", {
                              key: "ps_" + index,
                              attrs: { label: item.attr_name },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return _vm._l(
                                        scope.row.product.attrInfo,
                                        function (m, i) {
                                          return m.attr_id == item.attr_id
                                            ? _c(
                                                "div",
                                                { key: "ps_" + index + i },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        m.attr_value_name
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      )
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "成交收费", align: "center" } },
                          _vm._l(
                            _vm.inventoryConfig.price.sold_price,
                            function (item, index) {
                              return _c("el-table-column", {
                                key: "s_" + index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                scope.row.price_data[0][index]
                                                  .number
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "未成交收费", align: "center" } },
                          _vm._l(
                            _vm.inventoryConfig.price.unsold_price,
                            function (item, index) {
                              return _c("el-table-column", {
                                key: "s_" + index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                scope.row.price_data[1][index]
                                                  .number
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-table-column",
                          { attrs: { label: "其他费用", align: "center" } },
                          _vm._l(
                            _vm.inventoryConfig.price.other_price,
                            function (item, index) {
                              return _c("el-table-column", {
                                key: "s_" + index,
                                attrs: { label: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                scope.row.price_data[2][index]
                                                  .number
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                }
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _vm.inventoryDetailAll.creator
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "制单人：" +
                            _vm._s(_vm.inventoryDetailAll.creator.real_name)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inventoryDetailAll.auditAdmin
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "审批人：" +
                            _vm._s(_vm.inventoryDetailAll.auditAdmin.real_name)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inventoryDetailAll.manageAdmin
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "业务负责人：" +
                            _vm._s(_vm.inventoryDetailAll.manageAdmin.real_name)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.inventoryDetailAll.warehouse
                    ? _c("el-col", { attrs: { span: 6 } }, [
                        _vm._v(
                          "仓库：" +
                            _vm._s(_vm.inventoryDetailAll.warehouse.name)
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            visible: _vm.showconfig,
            direction: "rtl",
            size: "450px",
            "destroy-on-close": true,
            "show-close": true,
            wrapperClosable: true,
            "custom-class": "overfloyy",
            "before-close": _vm.saveconfig,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showconfig = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticStyle: { "font-weight": "bold" },
              attrs: { "label-width": "40px", "label-position": "left" },
            },
            [
              _c("el-form-item", [_c("span", [_vm._v("委托标的清单配置")])]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("售出后收费")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.sold_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "sold_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.sold_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("未售出收费")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.unsold_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "unsold_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.unsold_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                    },
                    [_vm._v("其他收费项目")]
                  ),
                  _vm._v(" "),
                  _c("el-switch", {
                    staticStyle: { width: "40px" },
                    model: {
                      value: _vm.inventoryConfig.switch.other_price_switch,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inventoryConfig.switch,
                          "other_price_switch",
                          $$v
                        )
                      },
                      expression: "inventoryConfig.switch.other_price_switch",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.inventoryConfig.switch.sold_price_switch
                ? [
                    _c("el-form-item", [
                      _c("span", [_vm._v("售出后收费项目")]),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.sold_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "C_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 2, label: "%" } },
                                  [_vm._v("%落槌价")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.inventoryConfig.switch.unsold_price_switch
                ? [
                    _c("el-form-item", [_c("span", [_vm._v("未售出收费")])]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.unsold_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "U_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 2, label: "%" } },
                                  [_vm._v("%保留价")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.inventoryConfig.switch.other_price_switch
                ? [
                    _c("el-form-item", [_c("span", [_vm._v("其他收费项目")])]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.inventoryConfig.price.other_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "o_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }