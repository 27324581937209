"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "furnaceconfig",
  components: {
    colour: _colour.default
  },
  data: function data() {
    return {
      ruleForm: {
        name: "",
        config: {
          background_color: "#143323",
          text_color: "#143323",
          background_image: "",
          button_image: ""
        }
      },
      rules: {}
    };
  },
  created: function created() {
    this.getdetail();
  },
  methods: {
    getdetail: function getdetail() {
      var _this = this;
      (0, _marketing.furnaceGetInfoApi)().then(function (res) {
        if (res.data.name) {
          _this.ruleForm.name = res.data.name;
        }
        if (res.data.config) {
          if (res.data.config.background_image) {
            _this.ruleForm.config.background_image = res.data.config.background_image;
          }
          if (res.data.config.background_color) {
            _this.ruleForm.config.background_color = res.data.config.background_color;
          }
          if (res.data.config.text_color) {
            _this.ruleForm.config.text_color = res.data.config.text_color;
          }
          if (res.data.config.button_image) {
            _this.ruleForm.config.button_image = res.data.config.button_image;
          }
        }
      });
    },
    changeImg: function changeImg(i, key, otherKey) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        if (key) {
          if (otherKey) {
            _this2.ruleForm[otherKey][key] = img[0];
          } else {
            _this2.ruleForm[key] = img[0];
          }
          if (key === "cover_image") {
            _this2.$refs.ruleForm.validateField([key], function (Error) {
              if (!Error) {
                // 验证通过
                console.log("通过了");
              } else {
                console.log("验证不通过");
              }
            });
          }
        } else {
          _this2.prize_data[i].prize_image = img[0];
        }
      }, 1, "1");
    },
    delImg: function delImg(key, otherKey) {
      if (otherKey) {
        this.ruleForm[otherKey][key] = "";
      } else {
        this.ruleForm[key] = "";
      }
    },
    saveAction: function saveAction() {
      var _this3 = this;
      (0, _marketing.furnaceCreateApi)(this.ruleForm).then(function (res) {
        _this3.$message.success('保存成功');
      }).catch(function (res) {});
    }
  }
};