var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("创建盲盒")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "no-height",
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            data: _vm.tableData.data,
            size: "small",
            "row-key": "box_id",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "box_id", label: "ID", "min-width": "60" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "80", label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview" },
                      [
                        _c("el-image", {
                          attrs: {
                            src: scope.row.image + "!120a",
                            "preview-src-list": [scope.row.image],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "盲盒名称",
              prop: "product.store_name",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 1
                      ? _c("div", [_vm._v("发售中")])
                      : scope.row.status == -1
                      ? _c("div", [_vm._v("已下架")])
                      : _c("div", [_vm._v("未发售")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "start_time",
              label: "发售时间",
              "min-width": "150",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "已售出/发售数量", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(scope.row.product.sales) +
                          "/" +
                          _vm._s(scope.row.issuer_num)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "product_count",
              label: "商品数量",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "100", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          width: "100",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyXcxLink(scope.row, $event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  小程序链接\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyH5Link(scope.row, $event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  H5链接\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImgAction(1, scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  小程序码\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImgAction(2, scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  二维码\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ft12 m0 plr2",
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [_vm._v("\n              推广链接\n            ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(scope.row.box_id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    scope.row.status == -1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.changestatus(scope.row.box_id, 1)
                              },
                            },
                          },
                          [_vm._v("上架")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.changestatus(scope.row.box_id, -1)
                              },
                            },
                          },
                          [_vm._v("下架")]
                        ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(
                              scope.row.box_id,
                              scope.$index
                            )
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看图片", visible: _vm.showImg, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }