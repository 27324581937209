"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _llpay = _interopRequireDefault(require("./llpay.js"));
var _freight = require("@/api/freight");
var _lianlianpay = require("@/api/lianlianpay");
var _SendVerifyCode = _interopRequireDefault(require("@/utils/SendVerifyCode"));
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getCityApi} from "@/api/system";
var _default = exports.default = {
  name: "llpay",
  mixins: [_SendVerifyCode.default],
  data: function data() {
    return {
      currentTab: 0,
      opentype: "",
      cityList: [],
      fullscreenLoading: false,
      ruleValidate: {
        "linkedAcctInfo.bank_card_id": [{
          required: true,
          trigger: "blur",
          message: "请输入银行卡号"
        }],
        "linkedAcctInfo.bank_card_phone": [{
          required: true,
          trigger: "blur",
          message: "请输入预留手机号"
        }],
        "linkedAcctInfo.linked_accttype": [{
          required: true,
          trigger: "change",
          message: "请选择类型"
        }],
        "linkedAcctInfo.linked_acctno": [{
          required: true,
          trigger: "blur",
          message: "请输入银行卡号"
        }],
        "linkedAcctInfo.linked_bankcode": [{
          required: true,
          trigger: "change",
          message: "请选择银行编码"
        }],
        "linkedAcctInfo.linked_brbankname": [{
          required: true,
          trigger: "blur",
          message: "请输入对公账户开户行名"
        }],
        "linkedAcctInfo.linked_brbankno": [{
          required: true,
          trigger: "blur",
          message: "请输入对公账户开户行号"
        }],
        "linkedAcctInfo.linked_acctname": [{
          required: true,
          trigger: "blur",
          message: "请输入对公账户名"
        }],
        "linkedAcctInfo.linked_phone": [{
          required: true,
          trigger: "blur",
          message: "请输入预留手机号"
        }]
      },
      formValidate: {
        type: 2,
        cardtype: 1,
        exptype: 1,
        mobile: "",
        code: "",
        basicInfo: {
          phone: "",
          real_name: "",
          idcard: "",
          expiration_date: "",
          business_licene_photo: ""
        },
        linkedAcctInfo: {
          bank_card_id: "",
          bank_card_phone: ""
        },
        legalreptInfo: {
          idcard_face: "",
          idcard_back: "",
          legalrept_id_type: "ID_CARD" // 身份证类型 
        },
        contactsInfo: {},
        basicAcctInfo: {}
      },
      loading: false,
      vcode: "",
      statusdisabled: false,
      lldisabled: false,
      codeList: [],
      bankcodeList: [],
      citycode: '',
      bank_code: '',
      brabank_name: '',
      props: {
        children: 'children',
        label: 'name',
        value: 'area_code',
        multiple: false
      },
      status: 'WAIT_COMMIT'
    };
  },
  created: function created() {
    var _this = this;
    (0, _llpay.default)().then(function (res) {
      console.log("加载完成");
      (0, _lianlianpay.getPasswordRandomFactor)().then(function (res) {
        console.log(res);
        _this.getrandomKey(res.data);
      });
    });
    (0, _lianlianpay.getOpenAccountInfo)().then(function (res) {
      if (res.data && res.data.open_status) {
        _this.formValidate.type = res.data.account_type;
        _this.lldisabled = true;
        _this.formValidate.mobile = res.data.basic_info.phone;
        _this.formValidate.basicInfo = res.data.basic_info;
        _this.formValidate.linkedAcctInfo = res.data.linked_acct_info;
        _this.formValidate.legalreptInfo = res.data.legalrept_info;
        _this.formValidate.legalreptInfo.legalrept_id_type = res.data.legalrept_info.legalrept_id_type || 'ID_CARD';
        if (res.data.legalrept_info && res.data.legalrept_info.expiration_date) {
          if (/9999/.test(res.data.legalrept_info.expiration_date)) {
            _this.formValidate.cardtype = 2;
            _this.formValidate.legalreptInfo.expiration_date = '';
          } else {
            _this.formValidate.cardtype = 1;
          }
        }
        if (res.data.basic_info.org_exp) {
          if (/9999/.test(res.data.basic_info.org_exp)) {
            _this.formValidate.exptype = 2;
            _this.formValidate.basicInfo.org_exp = '';
          } else {
            _this.formValidate.exptype = 1;
          }
        }
        _this.formValidate.contactsInfo = res.data.contacts_info;
        _this.formValidate.basicAcctInfo = res.data.basic_acct_info;
        _this.status = res.data.open_status;
        // this.statusdisabled = (this.status == 'NORMAL') ? true : false;
        _this.checkcodebank();
      }
    });
    this.getBankCode();
    this.getCityList();
    this.getBankCodeList();
  },
  methods: {
    changebind: function changebind() {
      this.$router.push("".concat(_settings.roterPre, "/accounts/llAccount/llchangebind"));
    },
    getBankCode: function getBankCode() {
      var _this2 = this;
      (0, _lianlianpay.getBankCode)().then(function (res) {
        console.log(res);
        _this2.codeList = res.data;
        _this2.checkcodebank();
      });
    },
    checkcodebank: function checkcodebank() {
      var _this3 = this;
      if (this.codeList.length > 0 && this.formValidate.linkedAcctInfo.linked_bankcode) {
        this.codeList.forEach(function (item) {
          if (item.bank_code == _this3.formValidate.linkedAcctInfo.linked_bankcode) {
            _this3.bank_code = item.bank_code;
            _this3.brabank_name = item.bank_name;
            if (_this3.changebankflg) {
              _this3.formValidate.linkedAcctInfo.linked_brbankname = item.bank_name;
              _this3.changebankflg = false;
            }
            _this3.getBankCodeList();
          }
        });
      }
    },
    getBankCodeList: function getBankCodeList() {
      var _this4 = this;
      if (this.bank_code && this.brabank_name && this.citycode) {
        (0, _lianlianpay.getBankCodeList)({
          bank_code: this.bank_code,
          city_code: this.citycode[1],
          brabank_name: this.brabank_name
        }).then(function (res) {
          console.log(res);
          _this4.bankcodeList = res.data.card_list;
        });
      }
    },
    checkbrbankno: function checkbrbankno() {
      if (this.bankcodeList.length <= 0) {
        this.$message.error('请先选择银行编号和地址');
      }
    },
    changebankcode: function changebankcode(val) {
      console.log(val);
      this.changebankflg = true;
      this.checkcodebank();
    },
    changecitycode: function changecitycode(val) {
      console.log(val);
      this.getBankCodeList();
    },
    getrandomKey: function getrandomKey(data) {
      // 初始化密码控件
      function initPassword() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        // eslint-disable-next-line
        var passowrd = new showKeyBord({
          png: "//mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/img",
          // 图片路径，用这个就行了，也可以下载到本地
          click_objId: "#keyboardBtn2",
          // 触发显示键盘的Dom节点Id
          regExp1: "[\\S\\s]",
          // 输入过程限制的正则，不匹配正则的字符不让输入
          regExp2: "^[\\S\\s]{6,12}$",
          // 输入完成后的正则，与getValid()对应，判断输入值是否匹配该正则
          Input_texId: "#LOGPASS2",
          // 接收内容的Dom节点Id
          parent_objId: "#ipt_pwd2",
          // 将键盘插入到何处的Dom节点Id
          placeholderId: "#LOGPASS2_SHOW",
          // span标签的id，该span用于模拟Input_texId的placeholder
          len: "12",
          // 表示最大允许输入多少个字符[可以不传递]
          keyBordLeft: "",
          keyBordTop: "",
          chaosMode: 0,
          // 混乱模式,0:无混乱;1:打开时乱一次;2:每输入一个字符乱一次,默认值0
          clickStatus: 1,
          // 按键状态,0:鼠标划过、悬浮时按键无变化;1:鼠标划过、悬浮时按键的颜色变化,默认值0
          titlefont: "安全支付输入",
          backfont: "退 格",
          capsfont: "切换大/小写",
          surefont: "确定",
          isen: false,
          callBack: function callBack() {},
          // 密码校验成功回调
          errorCallBack: function errorCallBack() {},
          // 密码校验失败回调
          rsaPublicKey: options.rsaPublicKey || "",
          // RSA加密必须参数，国密加密可不要
          sm2KeyHex: options.sm2KeyHex || "",
          // 国密加密必须参数，RSA加密可不要
          license: options.license || "" // 必须参数
        });
        passowrd.initOptions();
        console.log("init");
        return passowrd;
      }
      // 随机因子，必须包含sm2KeyHex、license、randomKey，这里只是示例，正常使用应该由服务端调起连连随机因子申请接口获取
      var options = {
        license: data.license,
        sm2KeyHex: data.sm2_key_hex,
        randomKey: data.random_key,
        rsaPublicKey: data.rsa_public_content
      };
      this.randomKey = data.random_key;
      this.randomValue = data.random_value;
      this.passwordInstance = initPassword(options);
      $("#get-password").click(function () {
        // 获取国密密文
        passwordInstance.setRandKey(options.randomKey); // 先设置随机key
        var password = passwordInstance.getOutputSM(); // 国密加密密码
        var rsaPassword = passwordInstance.getOutput(); // RSA加密密码
        // $('#password').text(rsaPassword);
        console.log("国密密码是", password);
        console.log("RSA密码是", rsaPassword);
        // // 清空密码框
        // passwordInstance.clearPWD()

        // 其他API请参考文档
      });
    },
    getCode: function getCode() {
      if (!this.formValidate.mobile) {
        return this.$message.error("请输入手机号码");
      }
      if (!/^1(3|4|5|7|8|9|6)\d{9}$/i.test(this.formValidate.mobile)) {
        return this.$message.error("请输入正确的手机号码");
      }
      (0, _lianlianpay.getBindMobileCode)({
        phone: this.formValidate.mobile
      }).then(function (res) {});
      this.sendCode();
    },
    checkimg: function checkimg(url) {
      (0, _lianlianpay.checkuploadFile)({
        image: url
      }).then(function (res) {
        console.log(res);
      });
    },
    // 列表
    getCityList: function getCityList() {
      var _this5 = this;
      (0, _freight.cityList)().then(function (res) {
        _this5.cityList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    handleSubmitUp: function handleSubmitUp() {
      if (this.currentTab-- < 0) this.currentTab = 0;
    },
    handleSubmitNest: function handleSubmitNest(name) {
      var _this6 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this6.passwordInstance.setRandKey(_this6.randomValue);
          var json = JSON.parse(JSON.stringify(_this6.formValidate));
          json.random_key = _this6.randomKey;
          json.password = _this6.passwordInstance.getOutputSM();
          if (!json.password) {
            return _this6.$message.error("请输入密码");
          }
          (0, _lianlianpay.getChangeBindApply)(json).then(function (res) {
            _this6.$message.success("提交成功");
            _this6.$router.back();
            // this.currentTab++;
            // console.log(res);
            // this.currentJson = res.data;
          }).catch(function (res) {
            _this6.getrandomKey();
          });
        } else {
          _this6.$message.warning("请填写完整信息！");
        }
      });
    },
    // 提交
    handleSubmit: function handleSubmit(name) {
      var _this7 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this7.fullscreenLoading = true;
          console.log(_this7.currentJson);
          (0, _lianlianpay.getChangeBindVerify)({
            token: _this7.currentJson.token,
            verify_code: _this7.vcode,
            order_no: _this7.currentJson.txn_seqno
          }).then(function (res) {
            _this7.$message.success("提交成功");
            _this7.$router.back();
          });
        } else {}
      });
    },
    changeImg: function changeImg(type) {
      var _this8 = this;
      this.$modalUpload(function (img) {
        (0, _lianlianpay.checkuploadFile)({
          image: img[0]
        }).then(function (res) {
          if (type == 'basicInfo.business_licene_photo') {
            _this8.formValidate.basicInfo.business_licene_photo = img[0];
            _this8.formValidate.basicInfo.business_licene_photo_doc_id = res.data.doc_id;
          } else if (type == 'legalreptInfo.idcard_face') {
            _this8.formValidate.legalreptInfo.idcard_face = img[0];
            _this8.formValidate.legalreptInfo.idcard_face_doc_id = res.data.doc_id;
          } else if (type == 'legalreptInfo.idcard_back') {
            _this8.formValidate.legalreptInfo.idcard_back = img[0];
            _this8.formValidate.legalreptInfo.idcard_back_doc_id = res.data.doc_id;
          }
        });
      }, 1, "1");
    }
  }
};