"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _benefits = _interopRequireDefault(require("./modules/benefits"));
var _member = require("@/api/member");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default,
    Benefits: _benefits.default
  },
  data: function data() {
    var _this = this;
    var timesValidator = function timesValidator(rule, value, callback) {
      if (_this.ruleForm.expire_type === 0) {
        if (!_this.ruleForm.times[0] || !_this.ruleForm.times[1]) {
          callback("请选择会员卡有效期");
        } else {
          callback();
        }
      } else if (_this.ruleForm.expire_type === 1) {
        if (!_this.ruleForm.expire_days) {
          callback("请输入会员卡有效天数");
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var growthValidator = function growthValidator(rule, value, callback) {
      if (!_this.ruleForm.give_growth_value) {
        callback();
      } else {
        if (isNaN(_this.ruleForm.give_growth_value)) {
          callback("成长值必须为数字");
        } else {
          callback();
        }
      }
    };
    return {
      id: "",
      isAccountPayType: false,
      paymentAccountList: [],
      ruleForm: {
        name: "",
        image: "",
        card_number_prefix: "",
        card_type: 0,
        is_can_unbind: 0,
        price: "",
        again_price: "",
        expired_again_price: "",
        stock: "",
        expire_type: 0,
        times: [],
        expire_stime: "",
        expire_etime: "",
        expire_days: undefined,
        is_need_verify: 0,
        use_condition_type: 0,
        use_condition: "",
        give_growth_value: "",
        status: 0,
        is_show: 1,
        use_notice: "",
        content: "",
        pay_account_id: ""
      },
      rules: {
        name: {
          required: true,
          message: "请输入会员卡名称",
          trigger: "blur"
        },
        card_number_prefix: {
          required: true,
          message: "请输入会员卡号开头",
          trigger: "blur"
        },
        image: {
          required: true,
          message: "请选择权益图标",
          trigger: "change"
        },
        price: [{
          required: true,
          message: "请输入会员卡价格",
          trigger: "blur"
        }, {
          type: "number",
          message: "价格必须为数字",
          trigger: "blur"
        }],
        stock: [{
          required: true,
          message: "请输入库存数量",
          trigger: "blur"
        }, {
          type: "number",
          message: "库存数量必须为数字",
          trigger: "blur"
        }],
        expire_type: {
          validator: timesValidator,
          trigger: "blur"
        },
        give_growth_value: {
          validator: growthValidator,
          trigger: "blur"
        }
      },
      benefitsArr: [],
      showImage: false,
      imagesViewKey: "auction_images"
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.getPaymentAccountByBusiness();
    if (this.id) {
      this.loadDetail();
    }
    this.isAccountPayType = localStorage.getItem('mer_id');
  },
  methods: {
    getPaymentAccountByBusiness: function getPaymentAccountByBusiness() {
      var _this2 = this;
      (0, _system._getPaymentAccountByBusiness)({
        business_type: "membership"
      }).then(function (res) {
        _this2.paymentAccountList = res.data;
      }).catch(function (err) {
        console.log(err);
      });
    },
    loadDetail: function loadDetail() {
      var _this3 = this;
      (0, _member.membershipCardDetailApi)(this.id).then(function (res) {
        console.log(res);
        var data = res.data;
        _this3.ruleForm = {
          name: data.name,
          image: data.image,
          card_number_prefix: data.card_number_prefix,
          card_type: data.card_type,
          is_can_unbind: data.is_can_unbind,
          price: data.price,
          again_price: data.again_price,
          expired_again_price: data.expired_again_price,
          stock: data.stock,
          expire_type: data.expire_type,
          times: [],
          expire_stime: "",
          expire_etime: "",
          expire_days: data.expire_days,
          is_need_verify: data.is_need_verify,
          use_condition_type: data.use_condition ? 1 : 0,
          use_condition: data.use_condition,
          give_growth_value: data.give_growth_value,
          status: data.status,
          is_show: data.is_show,
          sort: data.sort,
          use_notice: data.use_notice,
          content: data.content,
          pay_account_id: data.pay_account_id || ""
        };
        _this3.$refs["editor"].setContent(data.use_notice);
        _this3.$refs["editor1"].setContent(data.content);
        if (data.expire_type === 0) {
          _this3.ruleForm.times = [data.expire_stime * 1000, data.expire_etime * 1000];
          _this3.ruleForm.expire_stime = data.expire_stime;
          _this3.ruleForm.expire_etime = data.expire_etime;
        }
        var arr = [];
        data.rightItem.forEach(function (m) {
          if (m.right_type === 2 || m.right_type === 3 || m.right_type === 6) {
            m.goodsCount = 0;
            m.right_give_data.forEach(function (c) {
              m.goodsCount += Number(c.number);
            });
          }
          switch (m.right_type) {
            case 0:
              m.showDesc = m.right_type_text;
              break;
            case 1:
              m.showDesc = "\u8D60\u9001".concat(m.right_type_text);
              break;
            case 2:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u4EF6\u5546\u54C1");
              break;
            case 3:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u5F20\u95E8\u7968");
              break;
            case 4:
              m.showDesc = "\u8D60\u9001".concat(m.right_give_data[0].number, "\u79EF\u5206");
              break;
            case 5:
              m.showDesc = "\u8D60\u9001".concat(m.right_give_data[0].number, "\u4F59\u989D");
              break;
            case 6:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u5F20\u4F18\u60E0\u5238");
              break;
          }
          arr.push({
            name: m.right_name,
            image: m.right_image,
            id: m.right_id,
            showDesc: m.showDesc
          });
        });
        _this3.benefitsArr = arr;
      });
    },
    changeImg: function changeImg(key) {
      var _this4 = this;
      this.$modalUpload(function (img) {
        _this4.ruleForm[key] = img[0];
        _this4.$refs["ruleForm"].validateField(key);
      }, 1, "1");
    },
    showImageAction: function showImageAction(key) {
      this.imagesViewKey = key;
      this.showImage = true;
    },
    delImgAction: function delImgAction(key) {
      this.ruleForm[key] = "";
      this.$refs["ruleForm"].validateField(key);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      this.$refs["benefits"].changeshow();
    },
    selectMessage: function selectMessage(data) {
      this.benefitsArr = data.data;
    },
    delProductAction: function delProductAction(i) {
      this.benefitsArr.splice(i, 1);
    },
    saveAction: function saveAction(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this5.ruleForm));
          console.log(data);
          if (!data.use_condition_type) {
            data.use_condition = "";
          } else {
            if (!data.use_condition) {
              _this5.$message.error("请填写证件名称");
              return false;
            }
          }
          if (data.expire_type === 0) {
            data.expire_stime = data.times[0] / 1000;
            data.expire_etime = data.times[1] / 1000;
          }
          if (_this5.benefitsArr && _this5.benefitsArr.length) {
            data.right_ids = _this5.benefitsArr.map(function (m) {
              return m.id;
            });
          }
          var loading = _this5.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          var action = _member.membershipCardCreateApi;
          if (_this5.id) {
            action = _member.membershipCardUpdateApi;
          }
          action(_this5.id, data).then(function () {
            loading.close();
            _this5.$message.success("操作成功");
            _this5.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          _this5.$message.error("请填写完整信息");
          return false;
        }
      });
    }
  }
};