var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "main",
    },
    [
      _c(
        "el-steps",
        { attrs: { active: _vm.step } },
        [
          _c("el-step", {
            attrs: { title: "选择模板", description: "选择您心仪的展厅模板" },
          }),
          _vm._v(" "),
          _c("el-step", {
            attrs: { title: "设置内容", description: "设置您的展厅基础信息" },
          }),
          _vm._v(" "),
          _c("el-step", {
            attrs: {
              title:
                "设置展品" +
                (_vm.num && _vm.step == 3
                  ? "(" + _vm.works_data_ids.length + "/" + _vm.num + ")"
                  : ""),
              description: "设置您的展厅需要展示什么展品",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _vm.step == 1
        ? [
            _c(
              "div",
              { staticClass: "list-box" },
              _vm._l(_vm.tableData.data, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "list" + index,
                    staticClass: "list",
                    class: {
                      active: _vm.selectedItem.memberNftId == item.memberNftId,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectExhibition(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "image-box" }, [
                      _c("img", {
                        attrs: { src: item.picture + "!m640", alt: "" },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "preview",
                          on: {
                            click: function ($event) {
                              return _vm.previewspace(item)
                            },
                          },
                        },
                        [_vm._v("模板预览")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "list-name" }, [
                      _c("p", { staticClass: "line1" }, [
                        _vm._v(_vm._s(item.nftName)),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("共" + _vm._s(item.boothsCount) + "个展位"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "select-box" }, [
                      _c("div", [
                        _vm.selectedItem.memberNftId == item.memberNftId
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/check.png"),
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/images/nocheck.png"),
                                alt: "",
                              },
                            }),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.tableData.total > _vm.tableFrom.limit
              ? _c(
                  "div",
                  { staticClass: "block flex" },
                  [
                    _c("div", { staticClass: "flex-one" }),
                    _vm._v(" "),
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [20, 40, 60, 80],
                        "page-size": _vm.tableFrom.limit,
                        "current-page": _vm.tableFrom.page,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.tableData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.pageChange,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm.step == 2
        ? _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "空间名称", prop: "space_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入空间名称" },
                        model: {
                          value: _vm.ruleForm.space_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "space_name", $$v)
                          },
                          expression: "ruleForm.space_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "空间简介", prop: "intro" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        attrs: {
                          type: "textarea",
                          placeholder: "最多64个汉字,可回车换行~",
                          rows: 5,
                          maxlength: "64",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.intro,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "intro", $$v)
                          },
                          expression: "ruleForm.intro",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "空间介绍", prop: "content" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { height: 400 },
                        model: {
                          value: _vm.ruleForm.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "content", $$v)
                          },
                          expression: "ruleForm.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.step == 3
        ? _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "medium" },
                  on: { change: _vm.changWorkType },
                  model: {
                    value: _vm.work_type,
                    callback: function ($$v) {
                      _vm.work_type = $$v
                    },
                    expression: "work_type",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "exhibition" } }, [
                    _vm._v("作品"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "product" } }, [
                    _vm._v("商品"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "auction" } }, [
                    _vm._v("拍品"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "live" } }, [
                    _vm._v("直播"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200 ml20",
                  attrs: { clearable: "", placeholder: "关键词搜索" },
                  on: {
                    change: function ($event) {
                      return _vm.loadProductList(1)
                    },
                  },
                  model: {
                    value: _vm.keyword,
                    callback: function ($$v) {
                      _vm.keyword = $$v
                    },
                    expression: "keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list-box ai" },
                _vm._l(_vm.workList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "workList" + index,
                      staticClass: "list",
                      class: {
                        active: _vm.works_data_ids.indexOf(item.id) != -1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectWork(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "image-box" }, [
                        _c("img", { attrs: { src: item.coverImage, alt: "" } }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-name" }, [
                        _c("p", { staticClass: "line1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.work_type == "auction"
                                ? "LOT" + item.extends.lot + " "
                                : ""
                            ) +
                              " " +
                              _vm._s(item.name)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "select-box" }, [
                        _c("div", [
                          _vm.works_data_ids.indexOf(item.id) != -1
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/images/check.png"),
                                  alt: "",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/images/nocheck.png"),
                                  alt: "",
                                },
                              }),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.workTotal > _vm.workLimit
                ? _c(
                    "div",
                    { staticClass: "block flex mt20" },
                    [
                      _c("div", { staticClass: "flex-one" }),
                      _vm._v(" "),
                      _c("el-pagination", {
                        attrs: {
                          "page-sizes": [20, 40, 60, 80],
                          "page-size": _vm.workLimit,
                          "current-page": _vm.workPage,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.workTotal,
                        },
                        on: {
                          "size-change": _vm.productHandleSizeChange,
                          "current-change": _vm.productPageChange,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-btn" },
        [
          _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.step == 1 },
              on: { click: _vm.goPrevAction },
            },
            [_vm._v("上一步")]
          ),
          _vm._v(" "),
          _vm.step == 1
            ? [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: !_vm.selectedItem.memberNftId,
                    },
                    on: { click: _vm.goNextAction },
                  },
                  [_vm._v("下一步")]
                ),
              ]
            : _vm.step == 2
            ? [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: !_vm.ruleForm.space_name || !_vm.ruleForm.intro,
                    },
                    on: { click: _vm.goNextAction },
                  },
                  [_vm._v("下一步")]
                ),
              ]
            : _vm.step == 3
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.goNextAction },
                  },
                  [_vm._v("完成")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }