"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      unitconfig: {
        1: '(元/件)',
        2: '%'
      },
      routerUrl: '',
      showconfig: false,
      configflag: false
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    },
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    }
  },
  mounted: function mounted() {
    this.routerUrl = this.$route.path;
  },
  methods: {
    createContract: function createContract() {
      this.$refs.routerview.createContract();
    },
    changeTab: function changeTab(data) {
      var _this = this;
      if (data !== this.routerUrl) {
        this.$router.push({
          path: data
        });
        this.routerUrl = data;
        setTimeout(function () {
          _this.routerUrl = _this.$route.path;
        });
      }
    },
    goroute: function goroute(url) {
      this.$router.push({
        path: url
      });
    }
  }
};