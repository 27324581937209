"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    iconYtx: {
      type: String,
      default: ''
    },
    icon_url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      icon_url = _context$props.icon_url,
      iconYtx = _context$props.iconYtx;
    var vnodes = [];
    if (icon) {
      // const a = 'icon-' + icon
      var a = icon;
      if (a.indexOf('icon-') === -1) {
        a = 'icon-' + a;
      }
      vnodes.push(h("svg-icon", {
        "attrs": {
          "iconClass": a
        },
        "style": "margin-right: 8px;width:16px;height:16px;position: relative;bottom:2px;"
      }));
    }
    if (iconYtx) {
      // const a = 'icon-' + icon
      var _a = iconYtx;
      if (_a.indexOf('icon-') === -1) {
        _a = 'icon-' + _a;
      }
      vnodes.push(h("ytx-icon", {
        "attrs": {
          "icon": _a
        },
        "style": "margin-right: 8px;width:16px;height:16px;position: relative;bottom:2px;"
      }));
    }
    // if (icon_url){
    //   vnodes.push(<img src={icon_url} style='width:16px;height:16px;margin-right:8px;'/>)
    // }
    // vnodes.push(<img src='https://saas.cdn.yunzongbu.cn/merchant/23/2024527/image/1716782959825489801.png!120a' class='curr-img' style='width:16px;height:16px;margin-right:8px;'/>)
    if (title) {
      vnodes.push(h("span", {
        "slot": 'title'
      }, [title]));
    }
    return vnodes;
  }
};