var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "r-title" }, [_vm._v("参拍记录")]),
    _vm._v(" "),
    _c("div", { staticClass: "echart-box" }, [
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart1", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("浏览类目占比")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart2", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("参拍类目占比")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d" }, [
        _c("div", { ref: "echart3", staticClass: "d1" }),
        _vm._v(" "),
        _c("div", { staticClass: "dsign" }, [_vm._v("成交类目占比")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "count-box" }, [
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(_vm._s(_vm.formatPriceNumber(_vm.countjson.bidCount))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("出价(次)")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "count-list",
          staticStyle: { cursor: "pointer" },
          on: { click: _vm.goOrderAction },
        },
        [
          _c("div", { staticClass: "count-num" }, [
            _vm._v(
              _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionOrderCount))
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count-word" }, [_vm._v("中拍(件)")]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(
              _vm.formatPriceNumber(_vm.countjson.auctionOrderSumTotalPrice)
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("成交额")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionAveragePrice))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("平均单价")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(_vm.formatPriceNumber(_vm.countjson.auctionOrderSumPayPrice))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("实际支付")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(_vm._s(_vm.countjson.auctionOrderCancelCount)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("违约")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "count-list" }, [
        _c("div", { staticClass: "count-num" }, [
          _vm._v(
            _vm._s(
              _vm.formatPriceNumber(
                _vm.countjson.auctionOrderCancelTotalPrice
                  ? _vm.countjson.auctionOrderCancelTotalPrice
                  : 0
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "count-word" }, [_vm._v("违约金额")]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { padding: "20px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticClass: "order-table",
            staticStyle: { width: "100%" },
            attrs: {
              border: "",
              data: _vm.tableData.data,
              size: "mini",
              "highlight-current-row": "",
              "cell-class-name": _vm.addTdClass,
            },
            on: { "sort-change": _vm.changeSortAction },
          },
          [
            _c("el-table-column", {
              attrs: { label: "交易时间", width: "200" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.pay_time) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "专场", width: "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(_vm._s(row.album ? row.album.album_name : "")),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "图片", width: "50", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("img", {
                        staticStyle: {
                          width: "25px",
                          height: "25px",
                          "margin-top": "7px",
                        },
                        attrs: {
                          src: scope.row.orderProductOne
                            ? scope.row.orderProductOne.cart_info.product
                                .image + "!120a"
                            : scope.row.orderProduct[0].cart_info.product
                                .image + "!120a",
                          alt: "",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "LOT", width: "80", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            scope.row.orderProductOne
                              ? scope.row.orderProductOne.cart_info.product.lot
                              : scope.row.orderProduct[0].cart_info.product.lot
                          ) + "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "拍品名称", width: "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(
                          _vm._s(
                            scope.row.orderProductOne
                              ? scope.row.orderProductOne.cart_info.product
                                  .store_name
                              : scope.row.orderProduct[0].cart_info.product
                                  .store_name
                          ) + "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "订单状态", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status == -2
                        ? [_c("div", [_vm._v("已取消")])]
                        : scope.row.status == -1
                        ? [_c("div", [_vm._v("已退款")])]
                        : scope.row.status == -6
                        ? _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: "代拍支付待确认",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "nowrap",
                                      staticStyle: { width: "0", flex: "1" },
                                    },
                                    [_vm._v("代拍支付待确认")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.configPayAction(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("确认收款")]
                              ),
                            ],
                            1
                          )
                        : scope.row.status == -7
                        ? [_c("div", [_vm._v("已举回")])]
                        : scope.row.is_refund !== 0
                        ? [_c("div", [_vm._v("售后中")])]
                        : scope.row.status == -3 || scope.row.status == 0
                        ? [_c("div", [_vm._v("待结算")])]
                        : scope.row.status == -4
                        ? [_c("div", [_vm._v("转账待审核")])]
                        : scope.row.status == 1 &&
                          scope.row.is_out_warehouse_order == 1
                        ? [_c("div", [_vm._v("待出库")])]
                        : scope.row.status == 1 && scope.row.delivery_type == 2
                        ? [_c("div", [_vm._v("待自提")])]
                        : scope.row.status == 1 && scope.row.delivery_type == 3
                        ? [_c("div", [_vm._v("待他人提货")])]
                        : scope.row.status == 1
                        ? [_c("div", [_vm._v("待发货")])]
                        : scope.row.status == 2
                        ? [_c("div", [_vm._v("待收货")])]
                        : scope.row.status == 3
                        ? [_c("div", [_vm._v("待评价")])]
                        : scope.row.status == 4
                        ? [_c("div", [_vm._v("已完成")])]
                        : scope.row.status == -5
                        ? [_c("div", [_vm._v("待确认")])]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "落槌价", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(
                              scope.row.orderProductOne
                                ? scope.row.orderProductOne.product_price
                                : scope.row.orderProduct[0].product_price,
                              2
                            )
                          ) + "\n            "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "佣金", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.formatPriceNumber(scope.row.commission_price, 2)
                          ) +
                            " / " +
                            _vm._s(
                              _vm.formatPriceNumber(
                                scope.row.commission_rate,
                                2
                              )
                            ) +
                            "%"
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "运费", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(row.total_postage) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block mt10" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [6, 20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }