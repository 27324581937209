var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "mobile",
      staticClass: "package",
      style: { backgroundColor: _vm.pageInfo.background },
      attrs: { id: "min-page", draggable: false, "data-index": -2 },
      on: {
        dragover: [
          function ($event) {
            return _vm.allowDrop($event)
          },
          function ($event) {
            $event.preventDefault()
          },
        ],
        drop: _vm.drop,
      },
    },
    [
      _vm._l(_vm.pages, function (item, index) {
        return _c(
          "div",
          { key: index + 1, attrs: { role: "widgetgroup" } },
          [
            _vm.dragActive(index, "top") ? _c("Covermask") : _vm._e(),
            _vm._v(" "),
            item.name === "search"
              ? _c("Search", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "title"
              ? _c("Title", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "video"
              ? _c("Video", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "banner"
              ? _c("Banner", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "rubik"
              ? _c("Rubik", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "nav"
              ? _c("Nav", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "blank"
              ? _c("Blank", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "notice"
              ? _c("Notice", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auctionMeeting"
              ? _c("AuctionMeeting", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auctionAlbum"
              ? _c("AuctionAlbum", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "courseOnline"
              ? _c("CourseOnline", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "auction"
              ? _c("Auction", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "product"
              ? _c("Product", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "tag"
              ? _c("Tag", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "courseOffline"
              ? _c("CourseOffline", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "memberCard"
              ? _c("MemberCard", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "appraisalExpert"
              ? _c("AppraisalExpert", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "appraisalQuestion"
              ? _c("AppraisalQuestion", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "works"
              ? _c("Works", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "artiest"
              ? _c("Artiest", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "bbs"
              ? _c("Bbs", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "brand"
              ? _c("Brand", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "exhibit"
              ? _c("Exhibit", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "live"
              ? _c("Live", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "ticket"
              ? _c("Ticket", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "word"
              ? _c("Word", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "work"
              ? _c("Work", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "coupon"
              ? _c("Coupon", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "category"
              ? _c("Category", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "wechat"
              ? _c("Wechat", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "nftCollection"
              ? _c("NftCollection", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "nftCollectionMarket"
              ? _c("nftCollectionMarket", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "nftCollectionAlbum"
              ? _c("NftCollectionAlbum", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "nftBlindBox"
              ? _c("NftBlindBox", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "blindBox"
              ? _c("BlindBox", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "textView"
              ? _c("textView", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "templateAuctionList"
              ? _c("templateAuctionList", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "templateProductList"
              ? _c("templateProductList", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "templateAlbumList"
              ? _c("templateAlbumList", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "templateMeetingList"
              ? _c("templateMeetingList", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            item.name === "focusDisplay"
              ? _c("focusDisplay", {
                  attrs: {
                    facade: item.facade,
                    content: item.content,
                    index: index,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.dragActive(index, "down") ? _c("Covermask") : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "package-empty", attrs: { "data-index": -2 } },
        [
          _vm.dragActive(-2, "down")
            ? _c("Covermask", { attrs: { "data-index": -2 } })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }