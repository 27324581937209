"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var liveRouter = {
  path: "".concat(_settings.roterPre, "/digital_hall"),
  name: 'digital_hall',
  meta: {
    icon: 'dashboard',
    title: '数字大厅'
  },
  alwaysShow: true,
  component: _layout.default,
  redirect: 'noRedirect',
  children: [{
    path: 'manage',
    name: 'digitalHallManage',
    meta: {
      title: '数字大厅'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalHall/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'digitalHallList',
      meta: {
        title: '数字大厅',
        activeMenu: "".concat(_settings.roterPre, "/digital_hall/manage/list"),
        permissionKey: '/digital_hall/manage/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalHall/list'));
        });
      }
    }, {
      path: 'create',
      name: 'digitalHallCreate',
      meta: {
        title: '创建展厅',
        activeMenu: "".concat(_settings.roterPre, "/digital_hall/manage/list"),
        permissionKey: '/digital_hall/manage/list'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalHall/created'));
        });
      }
    }]
  }]
};
var _default = exports.default = liveRouter;