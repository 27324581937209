var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        backgroundColor: _vm.facade.pageBackground,
        padding:
          _vm.facade.pagePaddingTop +
          "px " +
          _vm.facade.pagePaddingRight +
          "px " +
          _vm.facade.pagePaddingBottom +
          "px " +
          _vm.facade.pagePaddingLeft +
          "px",
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
        borderWidth:
          _vm.facade.borderTop +
          "px " +
          _vm.facade.borderRight +
          "px " +
          _vm.facade.borderBottom +
          "px " +
          _vm.facade.borderLeft +
          "px",
        borderStyle: "solid",
        borderColor: _vm.facade.borderColor,
        color: _vm.facade.pageColor,
        fontSize: _vm.facade.fontSize + "px",
        fontWeight: "" + _vm.facade.fontWeight,
        letterSpacing: "2px",
        maxHeight: _vm.facade.maxHeight + "px",
        overflowY: "auto",
      },
    },
    [_c("section", { domProps: { innerHTML: _vm._s(_vm.content.data) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }