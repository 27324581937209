var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("div", { staticClass: "title" }, [
            _c("span", { staticClass: "tit" }, [
              _vm._v(
                _vm._s(_vm.list[0] ? _vm.list[0].questionnaire.title : "")
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticStyle: { "font-size": "14px" } }, [
              _vm._v(" 回收数量: " + _vm._s(_vm.total)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "default-box " }, [
            _c(
              "div",
              { staticClass: "default-setting " },
              [
                _c(
                  "el-form",
                  { attrs: { inline: "" } },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            "value-format": "yyyy/MM/dd",
                            format: "yyyy/MM/dd",
                            size: "small",
                            type: "daterange",
                            placement: "bottom-end",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          on: { change: _vm.onChangeDate },
                          model: {
                            value: _vm.date,
                            callback: function ($$v) {
                              _vm.date = $$v
                            },
                            expression: "date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "ml10",
                            attrs: { type: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.replay($event)
                              },
                            },
                          },
                          [_vm._v("筛选")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "ml10",
                            attrs: { type: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.replay(1)
                              },
                            },
                          },
                          [_vm._v("清空")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "ml10",
                            attrs: { type: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                _vm.show = true
                              },
                            },
                          },
                          [_vm._v("问题筛选")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "default-box mt-20" }, [
            _c(
              "div",
              [
                _c("el-button", {
                  staticClass: "ml20",
                  attrs: { icon: "el-icon-refresh", size: "small" },
                  on: { click: _vm.replay },
                }),
                _vm._v(" "),
                _c("el-popconfirm", {
                  attrs: { title: "确定删除吗？" },
                  on: { onConfirm: _vm.delAsk },
                  scopedSlots: _vm._u([
                    {
                      key: "reference",
                      fn: function () {
                        return [
                          _c("el-button", {
                            staticClass: "ml20",
                            attrs: { icon: "el-icon-delete", size: "small" },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml20",
                    staticStyle: { "font-size": "14px" },
                    attrs: { type: "default", size: "small" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.exportAction($event)
                      },
                    },
                  },
                  [_vm._v("导出")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: { click: _vm.getExportFileList },
                  },
                  [_vm._v("导出记录\n        ")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { data: _vm.list, border: "" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "查看" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showDetail(scope.row)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-view",
                              staticStyle: { "font-size": "16px" },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "id", prop: "recycle_id", "min-width": "130" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "昵称", prop: "uname", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.user.nickname))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "答题时间", prop: "create_time" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.total > 0
            ? _c("el-pagination", {
                staticClass: "mt20",
                attrs: {
                  total: _vm.total,
                  "show-sizer": "",
                  "show-elevator": "",
                  "show-total": "",
                  "current-page": _vm.queryParams.page,
                  "page-size": _vm.queryParams.size,
                  "page-size-opts": [10, 50, 100],
                },
                on: {
                  "current-change": _vm.changePage,
                  "osize-change": _vm.changePageSize,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.showFlag, title: _vm.title },
              on: {
                "update:visible": function ($event) {
                  _vm.showFlag = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "80px" } },
                _vm._l(_vm.askDetail.recycleAnswerList, function (item, index) {
                  return _c(
                    "el-form-item",
                    { key: index + "_", attrs: { label: index + 1 + "." } },
                    [
                      _c("div", [_vm._v(_vm._s(item.content))]),
                      _vm._v(" "),
                      item.optionData && item.optionData.length
                        ? _c(
                            "div",
                            _vm._l(item.optionData, function (m, i) {
                              return _c(
                                "div",
                                {
                                  key: index + "_" + i,
                                  staticClass: "dislist",
                                },
                                [
                                  _c("span", {
                                    staticClass: "check",
                                    class: m.isSelect ? "active" : "",
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(m.content) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      item.optionData && item.optionData.length && item.addtion
                        ? _c("div", [
                            _vm._v("用户输入：" + _vm._s(item.addtion)),
                          ])
                        : _c("div", [_vm._v(_vm._s(item.addtion))]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.show, title: "添加问题筛选", width: "35%" },
              on: {
                "update:visible": function ($event) {
                  _vm.show = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { ref: "formRef", attrs: { "label-width": "auto" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请选择问题" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择问题" },
                          model: {
                            value: _vm.question_select_id,
                            callback: function ($$v) {
                              _vm.question_select_id = $$v
                            },
                            expression: "question_select_id",
                          },
                        },
                        _vm._l(_vm.questionnaireInfoList, function (m) {
                          return _c("el-option", {
                            key: m.question_id,
                            attrs: { label: m.content, value: m.question_id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.questionnaireSelectList, function (item, index) {
                    return _c(
                      "el-form-item",
                      { key: item.question_id, attrs: { label: item.content } },
                      [
                        item.question_type == "2"
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  multiple: "",
                                  clearable: "",
                                  placeholder: "请选择（多选）",
                                },
                                model: {
                                  value:
                                    _vm.question_select[index].option_keyword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.question_select[index],
                                      "option_keyword",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "question_select[index].option_keyword",
                                },
                              },
                              _vm._l(item.questionOptions, function (m) {
                                return _c("el-option", {
                                  key: m.option_id,
                                  attrs: {
                                    label: m.content,
                                    value: m.option_id,
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.question_type == "1"
                          ? _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择（单选）",
                                  clearable: "",
                                },
                                model: {
                                  value:
                                    _vm.question_select[index].option_keyword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.question_select[index],
                                      "option_keyword",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "question_select[index].option_keyword",
                                },
                              },
                              _vm._l(item.questionOptions, function (m) {
                                return _c("el-option", {
                                  key: m.option_id,
                                  attrs: {
                                    label: m.content,
                                    value: m.option_id,
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.question_type == "3"
                          ? _c("el-input", {
                              staticClass: "w200",
                              attrs: { placeholder: "请输入答案" },
                              model: {
                                value:
                                  _vm.question_select[index].option_keyword,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.question_select[index],
                                    "option_keyword",
                                    $$v
                                  )
                                },
                                expression:
                                  "question_select[index].option_keyword",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-remove",
                          staticStyle: {
                            "font-size": "24px",
                            "margin-left": "10px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addOrDelRow(item, index)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10",
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.replay($event)
                            },
                          },
                        },
                        [_vm._v("确定")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10",
                          attrs: { type: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.show = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }