"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find");
var _contract = require("@/api/contract");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      routerUrl: '',
      venue_id: '',
      venueList: []
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  created: function created() {
    this.venueInit('', 1);
  },
  mounted: function mounted() {
    this.routerUrl = this.$route.path;
  },
  methods: {
    onVenueChange: function onVenueChange() {
      var _this = this;
      var row = this.venueList.find(function (item) {
        return item.venue_id == _this.venue_id;
      });
      localStorage.setItem('venueObj', JSON.stringify({
        venue_id: this.venue_id,
        venueTitle: row.title
      }));
      var model = {
        venue_id: this.venue_id,
        venueTitle: row.title
      };
      if (this.$route.query.status) model.status = this.$route.query.status;
      this.$router.replace({
        query: model
      });
      // this.$nextTick(() => {
      //   this.$refs.videoMakeRef.getList(1);
      // })
    },
    onSearchVenue: function onSearchVenue(val) {
      this.venueInit(val, 2);
    },
    venueInit: function venueInit(keyword, type) {
      var _this2 = this;
      (0, _contract.getLastAuctionApi)({
        title: keyword,
        page: 1,
        limit: 20
      }).then(function (res) {
        // console.log(res);
        _this2.venueList = res.data.list;
        // this.venue_id = res.data.list[0].venue_id;

        if (type == 1) {
          var jsonVenu = localStorage.getItem("venueObj");
          if (jsonVenu) {
            var venueObj = JSON.parse(jsonVenu);
            var index = res.data.list.findIndex(function (item) {
              return item.venue_id == venueObj.venue_id;
            });
            // console.log('1233333',venueObj,index);
            if (index == -1) {
              localStorage.setItem('venueObj', JSON.stringify({
                venue_id: res.data.list[0].venue_id,
                venueTitle: res.data.list[0].title
              }));
              var model = {
                venue_id: res.data.list[0].venue_id,
                venueTitle: res.data.list[0].title
              };
              if (_this2.$route.query.status) model.status = _this2.$route.query.status;
              _this2.$router.replace({
                query: model
              });
              _this2.venue_id = res.data.list[0].venue_id;
            } else {
              var _model = {
                venue_id: venueObj.venue_id,
                venueTitle: venueObj.venueTitle
              };
              if (_this2.$route.query.status) _model.status = _this2.$route.query.status;
              _this2.$router.replace({
                query: _model
              });
              _this2.venue_id = venueObj.venue_id;
            }
          } else {
            localStorage.setItem('venueObj', JSON.stringify({
              venue_id: res.data.list[0].venue_id,
              venueTitle: res.data.list[0].title
            }));
            var _model2 = {
              venue_id: res.data.list[0].venue_id,
              venueTitle: res.data.list[0].title
            };
            if (_this2.$route.query.status) _model2.status = _this2.$route.query.status;
            _this2.$router.replace({
              query: _model2
            });
            _this2.venue_id = res.data.list[0].venue_id;
          }
        }
      });
    },
    changeTab: function changeTab(data) {
      var _this3 = this;
      if (data !== this.routerUrl) {
        this.$router.push({
          path: data
        });
        this.routerUrl = data;
        setTimeout(function () {
          _this3.routerUrl = _this3.$route.path;
        });
      }
    },
    onGOUrl: function onGOUrl(url, type) {
      if (type == 1) {
        this.$router.push({
          path: url
        });
        return false;
      }
      this.$router.push({
        name: url
      });
    }
  }
};