var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: { size: "small", "label-width": "0px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("el-form-item", { attrs: { label: " " } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("span", { staticClass: "fWeight500" }, [_vm._v("状态：")]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "mr20",
                    attrs: { type: "button" },
                    on: {
                      change: function ($event) {
                        return _vm.pageChangeIssue(1)
                      },
                    },
                    model: {
                      value: _vm.tableFromIssue.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "status", $$v)
                      },
                      expression: "tableFromIssue.status",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "" } }, [
                      _vm._v("全部"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "10" } }, [
                      _vm._v("未使用"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "20" } }, [
                      _vm._v("待支付"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "30" } }, [
                      _vm._v("已使用"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: "40" } }, [
                      _vm._v("已过期"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: " " } }, [
            _c(
              "div",
              { staticClass: "flex align-items-c" },
              [
                _c("span", { staticClass: "fWeight500" }, [
                  _vm._v("优惠劵名称："),
                ]),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    staticClass: "w200 mr20",
                    attrs: { placeholder: "请输入优惠劵名称" },
                    model: {
                      value: _vm.tableFromIssue.coupon_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "coupon_name", $$v)
                      },
                      expression: "tableFromIssue.coupon_name",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: {
                        click: function ($event) {
                          return _vm.pageChangeIssue(1)
                        },
                      },
                      slot: "append",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "fWeight500" }, [_vm._v("领取人：")]),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    staticClass: "w200 mr20",
                    attrs: { placeholder: "输入用户名称" },
                    model: {
                      value: _vm.tableFromIssue.real_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "real_name", $$v)
                      },
                      expression: "tableFromIssue.real_name",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: {
                        click: function ($event) {
                          return _vm.pageChangeIssue(1)
                        },
                      },
                      slot: "append",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "fWeight500" }, [
                  _vm._v("用户手机号："),
                ]),
                _vm._v(" "),
                _c(
                  "el-input",
                  {
                    staticClass: "w200",
                    attrs: { placeholder: "输入用户手机号" },
                    model: {
                      value: _vm.tableFromIssue.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFromIssue, "phone", $$v)
                      },
                      expression: "tableFromIssue.phone",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: {
                        click: function ($event) {
                          return _vm.pageChangeIssue(1)
                        },
                      },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt10" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportOrder },
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.getExportFileList } },
            [_vm._v("导出已生成列表")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.Loading,
              expression: "Loading",
            },
          ],
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            data: _vm.issueData.data,
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            key: "10",
            attrs: { prop: "coupon_title", label: "优惠券信息" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(row.coupon_id) + " | " + _vm._s(row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "15",
            attrs: { label: "使用范围" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.use_range, function (m, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        (m[0] || m[0] === 0) && m[0] != -1
                          ? [
                              i == 0
                                ? _c("span", [_vm._v("商品：")])
                                : i == 7
                                ? _c("span", [_vm._v("拍品：")])
                                : i == 8
                                ? _c("span", [_vm._v("数字藏品：")])
                                : i == 9
                                ? _c("span", [_vm._v("数字盲盒：")])
                                : i == 10
                                ? _c("span", [_vm._v("实物盲盒：")])
                                : i == 20
                                ? _c("span", [_vm._v("鉴定：")])
                                : _vm._e(),
                              _vm._v(" "),
                              m[0] == 0
                                ? _c("span", [_vm._v("全部")])
                                : _c("span", [_vm._v(_vm._s(m.length) + "件")]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "20",
            attrs: { label: "领取用户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetails(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.real_name
                                  ? scope.row.real_name
                                  : scope.row.nickname
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(scope.row.phone))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "25",
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 10
                      ? _c("span", [_vm._v("未使用")])
                      : scope.row.status === 20
                      ? _c("span", { staticClass: "color-red" }, [
                          _vm._v("待支付"),
                        ])
                      : scope.row.status === 30
                      ? _c("span", [_vm._v("已使用")])
                      : scope.row.status === 40
                      ? _c("span", [_vm._v("已过期")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "30",
            attrs: { prop: "add_time", label: "领取时间", "min-width": "100" },
          }),
          _vm._v(" "),
          _vm.tableFromIssue.status == 30
            ? [
                _c("el-table-column", {
                  key: "35",
                  attrs: {
                    prop: "use_time",
                    label: "使用时间",
                    "min-width": "100",
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tableFromIssue.status == 40
            ? [
                _c("el-table-column", {
                  key: "40",
                  attrs: {
                    prop: "end_time",
                    label: "过期时间",
                    "min-width": "100",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            staticClass: "mt20",
            staticStyle: { "text-align": "right" },
            attrs: {
              background: "",
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.tableFromIssue.limit,
              "current-page": _vm.tableFromIssue.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.issueData.total,
            },
            on: {
              "size-change": _vm.handleSizeChangeIssue,
              "current-change": _vm.pageChangeIssue,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }