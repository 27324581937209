"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _modules = _interopRequireDefault(require("./modules"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    AlbumDetail: _modules.default.templateAlbumDetail.widget,
    Auction: _modules.default.templateAuctionList.widget,
    Album: _modules.default.templateAlbumList.widget,
    AuctionDetail: _modules.default.templateAuctionDetail.widget,
    Product: _modules.default.templateProductList.widget,
    ProductDetail: _modules.default.templateProductDetail.widget,
    WordDetail: _modules.default.templateWordDetail.widget,
    Login: _modules.default.templateLogin.widget
  },
  data: function data() {
    return {
      is_empty: false,
      id: this.$route.query.id || '',
      type: this.$route.query.type || 'album_component'
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    dragIndex: {
      get: function get() {
        return this.$store.state.finish.dragIndex;
      },
      set: function set(value) {
        this.$store.commit('finish/setDragIndex', value);
      }
    },
    position: {
      get: function get() {
        return this.$store.state.finish.position;
      },
      set: function set(value) {
        this.$store.commit('finish/setDragPosition', value);
      }
    },
    select: function select() {
      return this.$store.state.finish.index;
    },
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set(value) {
        this.$store.commit('finish/setPagesData', value);
      }
    },
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    coms: function coms() {
      return this.$store.state.finish.coms;
    }
  },
  watch: {
    $route: function $route(val) {
      if (val.name === 'templateDiy' && val.query.type) {
        this.type = val.query.type;
        this.pages = [];
        this.$store.commit('finish/setPageInfo', {
          show: false
        });
        this.loadPageInfo();
      }
    }
  },
  mounted: function mounted() {
    this.loadPageInfo();
  },
  methods: {
    pageInfoNew: function pageInfoNew(index) {
      return Object.assign({}, this.pageInfo, this.pageInfo.type_setting[this.pages[index].content.style]);
    },
    /**
     * 加载数据操作
     * @return {[type]} [description]
     */
    loadPageInfo: function loadPageInfo() {
      var _this = this;
      (0, _system.diypageDetail)({
        type: this.type
      }).then(function (res) {
        if (res.data.draft_config_data && res.data.draft_content) {
          res.data.config_data = res.data.draft_config_data;
          res.data.content = res.data.draft_content;
        }
        if (res.data.content) {
          if (res.data.type === 'templateAuctionList' && res.data.content.length == 4) {
            var d = Object.assign({}, res.data.content[0]);
            d.content = {
              style: 6
            };
            res.data.content = [d].concat(res.data.content);
            res.data.config_data.type_setting[6] = Object.assign({
              nameBackground: ''
            }, res.data.config_data.type_setting[5]);
          } else if (res.data.type === 'templateAlbumList' && res.data.content.length == 2) {
            var _d = Object.assign({}, res.data.content[0]);
            _d.content = {
              style: 3
            };
            res.data.content = [_d].concat(res.data.content);
          }
          _this.pages = res.data.content;
        }
        if (res.data.config_data) {
          _this.$store.commit('finish/setPageInfo', res.data.config_data);
        }
      }).catch(function (message) {
        _this.$message.error(message);
      });

      // this.pages = [{"name":"templateWordDetail","title":"标题区","content":{"type":"title"},"facade":{}},{"name":"templateWordDetail","title":"作者区","content":{"type":"author"},"facade":{}},{"name":"templateWordDetail","title":"内容区","content":{"type":"content"},"facade":{}}]
      // this.$store.commit('finish/setPageInfo', {"show":true,"title":"文章详情","background":"rgba(29, 29, 39, 1)","bg_image":"https://dboss.dbweb3.com/merchant/000000/202456/image/1714968421973932174.png","bg_image_mode":4,"page_margin":93,"agreement_bottom":200,"agreement_font_size":24,"agreement_color":"#999999","agreement_link_color":"rgba(78, 99, 228, 1)","id":"1575135151899738113","agreement_before":"https://java-test-cdn.yunzongbu.cn/merchant/000000/2022929/image/1664441597206471452.png","agreement_after":"https://java-test-cdn.yunzongbu.cn/merchant/000000/2022929/image/1664441637854827269.png","background_f":"#1d1d27FF","agreement_color_f":"#999999","agreement_link_color_f":"#4e63e4FF"});
      // this.pages = [];
      //       this.$store.commit("finish/setPageInfo", {
      //         show: true,
      //         title: "盲盒首页",
      //         background: "#1e1e1e",
      //         bg_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022521/image/1653119826546204424.png",
      //         bg_image_mode: 5,

      //         audio_play_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022521/image/165312470761077166.png",
      //         audio_pause_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022521/image/1653124701489100967.png",
      //         contact_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022517/image/1652786408545638390.png",
      //         coupon_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022517/image/1652786408303444165.png",

      //         free_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022521/image/1653131184902269421.png",
      //         open_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022521/image/1653131183805954611.png",
      //         my_box_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022521/image/1653131184584370017.png",

      //         point_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022521/image/1653111771731254210.png",
      //         refause_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022521/image/1653109349705506624.png",
      //         tricks_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022525/image/1653468724774995218.png",
      //         price_bg_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/333425/2022525/image/1653468725307137699.png",
      //         left_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/23/202265/image/1654414132124368310.png",
      //         right_image:
      //           "https://saas.cdn.yunzongbu.cn/merchant/23/202265/image/1654414131488666436.png",
      //         item_width: 190,
      //         item_color: "#E300FF",
      //       });

      //   this.pages = [
      //     {
      //       name: "nftDetail",
      //       title: "轮播图",
      //       content: {
      //         type: 'playimage',
      //       },
      //       facade: {
      //         marginTop: 0
      //       }
      //     },
      //     {
      //       name: "nftDetail",
      //       title: "玩法标签",
      //       content: {
      //         type: 'playtag'
      //       },
      //       facade: {
      //         marginTop: 34,
      //         marginRight: 16,
      //         borderColor: '#444444',
      //         borderRadius: 16,
      //         color: '#999999',
      //         bgcolor: '#333333',
      //         selectColor: '#000000',
      //         selectBgcolor: '#00EEFF',
      //         fontSize: 26,
      //       }
      //     },
      //     {
      //       name: "nftDetail",
      //       title: "玩法说明",
      //       content: { type: "playinfo" },
      //       facade: {
      //         marginTop: 32,
      //         fontSize: 28,
      //         color: "#999999",
      //         lineHeight: 55,
      //         fontWeight: 400,
      //         title: "玩法说明",
      //         title_image: "",
      //       },
      //     },
      //     {
      //       name: "nftDetail",
      //       title: "合成材料",
      //       content: { type: "composemeta" },
      //       facade: {
      //         marginTop: 32,
      //         metaColor: "#FFFFFF",
      //         haveColor: "#999999",
      //         needColor: "#00EEFF",
      //         borderRadius: 16,
      //         addImage: 'https://saas.cdn.yunzongbu.cn/merchant/23/2022611/image/1654906604572789446.png',
      //         resultImage: 'https://saas.cdn.yunzongbu.cn/merchant/23/2022611/image/165490660978131255.png',
      //         resultRadius: 40
      //       }
      //     },
      //     {
      //       name: "nftDetail",
      //       title: "合成须知",
      //       content: { type: "notice" },
      //       facade: {
      //         marginTop: 32,
      //         fontSize: 28,
      //         color: "#999999",
      //         lineHeight: 55,
      //         fontWeight: 400,
      //         title: "合成须知",
      //         title_image: "",
      //       },
      //     },
      //   ];
      //   this.$store.commit("finish/setPageInfo", {
      //     show: true,
      //     title: "合成活动详情",
      //     background: "#1e1e1e",
      //     bg_image: "",
      //     bg_image_mode: 4,
      //     page_margin: 30,
      //     titleColor: "#000000",
      //     titleFontSize: 32,
      //     marginLeft: 22,
      //     borderRadius: 18,
      //     item_background: "#FFFFFF",
      //     border: "#444444",
      //     item_padding: 29,
      //     bottom_background: "#000000",
      //     priceColor: "#E6CB95",
      //     btnBgColor: "",
      //     btnColor: "",
      //     txtColor: "#00CED1",
      //   });

      // this.pages = [
      //   {
      //     name: 'nftCollection',
      //     icon:
      //       'https://saas.cdn.yunzongbu.cn/icon/auction_album_icon.png',
      //     title: 'NFT藏品',
      //     hasContent: true,
      //     content: {
      //       style: 1,
      //       data: []
      //     }
      //   },
      //   {
      //     name: 'nftCollection',
      //     icon:
      //       'https://saas.cdn.yunzongbu.cn/icon/auction_album_icon.png',
      //     title: 'NFT藏品',
      //     hasContent: true,
      //     content: {
      //       style: 2,
      //       data: []
      //     }
      //   }
      // ];
      // this.$store.commit('finish/setPageInfo', {
      //   title: 'NFT藏品组件',
      //   show: true,
      //   background_color: '#1E1E1E',
      //   page_margin: 30,
      //   img_radius: 8,
      //   status_radius: 8,
      //   item_margin: 30,
      //   status_background: '#333333',
      //   status_bg_image: '',
      //   font_weight: 400,
      //   title_color: '#ffffff',
      //   title_font_size: 32,
      //   title_font_weight: 500,
      //   stock_color: '#00eeff',
      //   stock_font_size: 24,
      //   price_font_size: 48,
      //   price_color: '#e6cb95',
      //   img_style: 2,

      //   type_setting: {
      //     1: {
      //       status_btn_bg_color: '#000000',
      //       status_btn_color: '#00eeff',
      //       status_font_size: 24,
      //       btn_font_size: 22,
      //       btn_radius: 2,
      //       main_btn_bg_color: '#00eeff',
      //       main_btn_color: '#000000',
      //       sub_btn_bg_color: '#5f5f5f',
      //       sub_btn_color: '#ffffff',
      //       sub_font_size: 24,
      //       sub_color: '#999999'
      //     },
      //     2: {
      //       month_color: '#FFFFFF',
      //       month_font_size: 28,
      //       month_font_weight: 400,
      //       time_color: '#999999',
      //       time_font_size: 24,
      //       time_font_weight: 400,
      //       dian_color: '#00eeff',
      //       xian_color: '#333'
      //     }
      //   }
      // });

      // this.pages = [
      //   {
      //     name: 'nftDetail',
      //     title: '轮播图',
      //     content: {
      //       type: 'banner'
      //     },
      //     facade: {
      //       marginTop: 180
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '名称区域',
      //     content: {
      //       type: 'name'
      //     },
      //     facade: {
      //       marginTop: 200,
      //       color: '#FFFFFF',
      //       fontSize: 36,
      //       fontWeight: '500'
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '标签',
      //     content: {
      //       type: 'tag'
      //     },
      //     facade: {
      //       marginTop: 6,
      //       btn_font_size: 22,
      //       btn_radius: 2,
      //       main_btn_bg_color: '#00eeff',
      //       main_btn_color: '#000000',
      //       sub_btn_bg_color: '#5f5f5f',
      //       sub_btn_color: '#ffffff',
      //       stock_color: '#00eeff',
      //       stock_font_size: 24
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '作品信息',
      //     content: {
      //       type: 'desc'
      //     },
      //     facade: {
      //       marginTop: 60,
      //       fontSize: 24,
      //       color: '#999999',
      //       otherColor: '#ffffff',
      //       lineHeight: 48,
      //       fontWeight: 400,
      //       title: '作品信息',
      //       title_image: ''
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '作品详情',
      //     content: {
      //       type: 'message'
      //     },
      //     facade: {
      //       marginTop: 32,
      //       fontSize: 24,
      //       color: '#999999',
      //       lineHeight: 48,
      //       fontWeight: 400,
      //       title: '作品故事',
      //       title_image: ''
      //     }
      //   },
      //   {
      //     name: 'nftDetail',
      //     title: '购买须知',
      //     content: {
      //       type: 'notice'
      //     },
      //     facade: {
      //       marginTop: 32,
      //       fontSize: 24,
      //       color: '#999999',
      //       lineHeight: 48,
      //       fontWeight: 400,
      //       title: '购买须知',
      //       title_image: ''
      //     }
      //   }
      // ];
      // this.pages = [
      //   {
      //     name: 'templateAlbumList',
      //     title: '专场',
      //     content: {
      //       style: 1
      //     }
      //   },
      //   {
      //     name: 'templateAlbumList',
      //     title: '专场',
      //     content: {
      //       style: 2
      //     }
      //   }
      //   // {
      //   //   name: 'auction',
      //   //   title: '商品',
      //   //   content: {
      //   //     style: 4
      //   //   }
      //   // },
      //   // {
      //   //   name: 'auction',
      //   //   title: '商品',
      //   //   content: {
      //   //     style: 1
      //   //   }
      //   // },
      //   // {
      //   //   name: 'auction',
      //   //   title: '商品',
      //   //   content: {
      //   //     style: 2
      //   //   }
      //   // }
      // ];
      // this.$store.commit('finish/setPageInfo', {
      //   title: '专场组件',
      //   show: true,
      //   backgroundColor: '#fff',
      //   pageMargin: 32,
      //   imgStyle: 3,
      //   itemBackground: '#FFFFFF',
      //   itemGradientBackground: '',
      //   itemGradientDirection: 'to bottom',
      //   itemMargin: 32,
      //   itemShadow: '#DEDEDE',
      //   itemShadowSize: 0,
      //   itemTopLeftRadius: 8,
      //   itemTopRightRadius: 8,
      //   itemBottomLeftRadius: 8,
      //   itemBottomRightRadius: 8,
      //   imgBackground: '#ccc',
      //   imgTopLeftRadius: 0,
      //   imgTopRightRadius: 0,
      //   imgBottomLeftRadius: 0,
      //   imgBottomRightRadius: 0,
      //   descMarginTop: 24,
      //   descMarginRight: 32,
      //   descMarginBottom: 38,
      //   descMarginLeft: 32,
      //   nameMarginTop: 0,
      //   nameColor: '#000000',
      //   nameFontWeight: 500,
      //   nameFontSize: 36,
      //   albumInfoMarginTop: 16,
      //   albumInfoColor: '#666666',
      //   albumInfoFontWeight: 400,
      //   albumInfoFontSize: 24,
      //   albumTypeMarginTop: 32,
      //   albumTypeColor: '#666666',
      //   albumTypeFontWeight: 400,
      //   albumTypeFontSize: 24,
      //   albumTimeMarginTop: 24,
      //   albumTimeColor: '#000000',
      //   albumTimeFontWeight: 500,
      //   albumTimeFontSize: 24,
      //   nowAuctionMarginTop: 32,
      //   nowAuctionIcon: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024522/image/1716392259005711638.png',
      //   nowAuctionColor: '#000000',
      //   nowAuctionCurrColor: '#FF3D3D',
      //   nowAuctionFontWeight: 500,
      //   nowAuctionFontSize: 28,
      //   statusTopLeftRadius: 8,
      //   statusTopRightRadius: 8,
      //   statusBottomLeftRadius: 8,
      //   statusBottomRightRadius: 8,
      //   statusMarginTop: 20,
      //   statusMarginLeft: 20,
      //   statusFontWeight: 500,
      //   statusFontSize: 24,
      //   waitingBackgoundUrl: '',
      //   waitingBackgoundColor: '#FF3D3D',
      //   waitingColor: '#ffffff',
      //   ingBackgoundUrl: '',
      //   ingBackgoundColor: '#FF3D3D',
      //   ingColor: '#ffffff',
      //   liveingBackgoundUrl: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024522/image/1716392322615609096.png',
      //   liveingBackgoundColor: '#FF3D3D',
      //   liveingColor: '#ffffff',
      //   endBackgoundUrl: '',
      //   endBackgoundColor: '#999999',
      //   endColor: '#ffffff',
      //   horHeight: 80,
      //   horNameBackground: 'rgba(0, 0, 0, 0)',
      //   horNameGradientBackground: 'rgba(0, 0, 0, .5)',
      //   horNameGradientDirection: 'to bottom',
      //   horNameColor: '#ffffff',
      //   horNameFontWeight: 500,
      //   horNameFontSize: 32,
      //   horTimeColor: '#ffffff',
      //   horTimeFontWeight: 400,
      //   horTimeFontSize: 24,
      //   indicatorAlign: 'center',
      //   indicatorColor: '#000000',
      //   indicatorStyle: 2
      // });

      // this.pages = [
      //   {
      //     name: 'productDetail',
      //     title: '商品轮播图',
      //     content: {
      //       type: 'banner'
      //     },
      //     facade: {
      //       marginTop: 0,
      //       background: '#FFFFFF',
      //       view_width: '750',
      //       view_height: '750',
      //       img_width: '750',
      //       img_height: '750',
      //       img_top_radius: 0,
      //       img_bottom_radius: 0,
      //       indicator_style: 3,
      //       indicator_align: 'right',
      //       indicator_color: '#CD0000'
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '商品价格',
      //     content: {
      //       type: 'price'
      //     },
      //     facade: {
      //       height: 120,
      //       background: '#FFFFFF',
      //       fontSize: 24,
      //       marginTop: 17,
      //       color: '#666',
      //       priceFontSize: 48,
      //       oldPriceColor: '#999',
      //       priceColor: '#FF3535'
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '商品名称',
      //     content: {
      //       type: 'name'
      //     },
      //     facade: {
      //       fontSize: 32,
      //       color: '#000000',
      //       fontWeight: 500,
      //       marginTop: 0,
      //       background: '#FFFFFF',
      //       status_type: 1
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '一句话介绍',
      //     content: {
      //       type: 'desc'
      //     },
      //     facade: {
      //       background: '#FFFFFF',
      //       color: '#FF3535',
      //       fold: false,
      //       fontSize: 24,
      //       fontWeight: 400,
      //       marginTop: 20
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '规则介绍',
      //     content: {
      //       type: 'detail'
      //     },
      //     facade: {
      //       background: '#FFFFFF',
      //       color: '#666666',
      //       fontSize: 24,
      //       lineHeight: 71,
      //       marginTop: 26,
      //       otherColor: '#000000',
      //       fontWeight: 400
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '商品详情',
      //     content: {
      //       type: 'message'
      //     },
      //     facade: {
      //       fontSize: 28,
      //       lineHeight: 55,
      //       color: '#666666',
      //       fontWeight: 400,
      //       background: '#FFFFFF',
      //       marginTop: 30,
      //       tabFontSize: 28,
      //       tabLineHeight: 88,
      //       tabCurrColor: '#D5303E',
      //       tabColor: '#000000',
      //       tabFontWeight: 500
      //     }
      //   },
      //   {
      //     name: 'productDetail',
      //     title: '商品列表',
      //     content: {
      //       type: 'list',
      //       style: 2
      //     },
      //     facade: {
      //       background: '',
      //       lineHeight: 110,
      //       marginTop: 22,
      //       page_margin: 12, // 页面间距
      //       top_radius: 8, // 顶部圆角
      //       bottom_radius: 8, // 底部圆角
      //       title_color: '#000000', // 标题颜色
      //       sub_title_color: '#999999', // 副标题颜色
      //       price_color: '#347FF4', // 价格颜色
      //       sales_volumes_color: '#999999', // 销量颜色
      //       img_style: 2, // 显示方式 1 瀑布流  2 对其-完整图片 3 对其-截取正方形
      //       top_margin: 20, // 内容上间距
      //       left_margin: 20, // 内容左间距
      //       text_margin: 20, // 文件撑开距离
      //       item_background: '#FFFFFF', // 拍品底色
      //       item_shadow: '#DEDEDE', // 拍品阴影颜色
      //       item_shadow_size: 10, // 拍品阴影大小
      //       title_size: 28, // 标题颜色
      //       title_weight: 500, // 标题文字类型 300 加细  400正常  500 加粗
      //       price_size: 28, // 标题价格字号
      //       price_weight: 500, // 标题价格文字类型 300 加细  400正常  500 加粗
      //       sales_volumes_size: 24, // 销量次数字号
      //       sales_volumes_weight: 400, // 销量次数 300 加细  400正常  500 加粗
      //       img_width: 120,
      //       img_height: 120,
      //       show_sale: true
      //     }
      //   }
      // ];
      // this.$store.commit('finish/setPageInfo', {
      //   show: true,
      //   title: '商品详情',
      //   background: '#F7F7F7',
      //   bg_image: '',
      //   bg_image_mode: 1,
      //   page_margin: 30,
      //   show_header_view: true,
      //   header_background: '#FFFFFF',
      //   header_color: 'black',
      //   header_text_size: 32,

      //   tLineHeight: 100,
      //   tFontSize: 28,
      //   tColor: '#000000',
      //   tFontWeight: 500
      // });
    },
    /**
     * 拖拽激活
     * @param  {[type]} index    [description]
     * @param  {[type]} position [description]
     * @return {[type]}          [description]
     */
    dragActive: function dragActive(index, position) {
      if (this.dragIndex === index && this.position == position && this.coms) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 拖拽过程事件
     * @return {[type]} [description]
     */
    allowDrop: function allowDrop($event) {
      var index = parseInt($event.target.dataset.index);
      if (index || index === 0) {
        this.dragIndex = index;
        // 判断是否为拖拽元素
        if ($event.type === 'dragover' && $event.target.dataset) {
          // 判断是否是拖拽区域
          if ($event.target.dataset.type === 'widget') {
            // 获取Y轴移动值
            var y = parseFloat($event.offsetY);
            // 由于缩小50%，需要除去双倍
            var h = parseFloat($event.target.clientHeight / 4);
            if (y < h) {
              this.position = 'top';
            } else {
              this.position = 'down';
            }
          }
        }
      }
      if (index == -2) {
        this.dragIndex = index;
        this.position = 'down';
      }
      $event.preventDefault();
    },
    /**
     * 执行放置，只要在范围内
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    drop: function drop() {
      this.$store.dispatch('finish/pushPagesComs');
    },
    draggableStart: function draggableStart() {
      this.$store.commit('finish/setLock', true);
    },
    draggableEnd: function draggableEnd() {
      this.$store.commit('finish/setLock', false);
    },
    selectMessage: function selectMessage(data) {
      console.log(data);
    }
  }
};