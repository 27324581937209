var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      style: {
        backgroundColor: _vm.facade.pageBackground,
        padding:
          _vm.facade.pagePaddingTop +
          "px " +
          _vm.facade.pagePaddingRight +
          "px " +
          _vm.facade.pagePaddingBottom +
          "px " +
          _vm.facade.pagePaddingLeft +
          "px",
        margin:
          _vm.facade.pageMarginTop +
          "px " +
          _vm.facade.pageMarginRight +
          "px " +
          _vm.facade.pageMarginBottom +
          "px " +
          _vm.facade.pageMarginLeft +
          "px",
        borderRadius:
          _vm.facade.pageRadiusTopLeft +
          "px " +
          _vm.facade.pageRadiusTopRight +
          "px " +
          _vm.facade.pageRadiusBottomLeft +
          "px " +
          _vm.facade.pageRadiusBottomRight +
          "px",
        maxHeight: _vm.facade.maxHeight + "px",
        lineHeight: _vm.facade.lineHeight + "px",
        color: _vm.facade.pageColor,
        fontSize: _vm.facade.fontSize + "px",
        overflowY: "auto",
      },
    },
    [
      _vm.content.time
        ? [
            _c(
              "section",
              {
                style: {
                  color: _vm.facade.titleColor,
                  fontSize: _vm.facade.titleFontSize + "px",
                  fontWeight: 500,
                },
              },
              [_vm._v("工作时间")]
            ),
            _vm._v(" "),
            _c(
              "section",
              { style: { fontSize: _vm.facade.nameFontSize + "px" } },
              [_vm._v(_vm._s(_vm.content.time))]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("section", { style: { height: _vm.facade.lineHeight + "px" } }),
      _vm._v(" "),
      _c(
        "section",
        {
          style: {
            color: _vm.facade.titleColor,
            fontSize: _vm.facade.titleFontSize + "px",
            fontWeight: 500,
          },
        },
        [_vm._v("联系方式")]
      ),
      _vm._v(" "),
      _vm.content.headOfficeTitle
        ? _c(
            "section",
            {
              style: {
                fontSize: _vm.facade.nameFontSize + "px",
                fontWeight: 500,
              },
            },
            [_vm._v(_vm._s(_vm.content.headOfficeTitle))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.content.headOffice.length
        ? _c(
            "section",
            {
              style: {
                borderBottom:
                  _vm.facade.lineSize + "px solid " + _vm.facade.lineBackgound,
              },
            },
            [
              _vm._l(_vm.content.headOffice, function (m, i) {
                return [
                  _c(
                    "section",
                    {
                      key: "headOffice" + i,
                      class: { pt0: i === 0 },
                      style:
                        "padding: " +
                        _vm.facade.itemPaddingTop +
                        "px " +
                        _vm.facade.itemPaddingRight +
                        "px " +
                        _vm.facade.itemPaddingBottom +
                        "px " +
                        _vm.facade.itemPaddingLeft +
                        "px",
                    },
                    [
                      m.name
                        ? _c(
                            "section",
                            {
                              style: {
                                fontSize: _vm.facade.nameFontSize + "px",
                                fontWeight: 500,
                              },
                            },
                            [_vm._v(_vm._s(m.name))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      m.address
                        ? _c("section", [_vm._v(_vm._s(m.address))])
                        : _vm._e(),
                      _vm._v(" "),
                      m.other
                        ? _c("section", [_vm._v(_vm._s(m.other))])
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.content.company.length
        ? _c(
            "section",
            {
              style: {
                paddingTop: _vm.facade.itemPaddingBottom + "px",
                borderBottom:
                  (_vm.content.office.length ? _vm.facade.lineSize : 0) +
                  "px solid " +
                  _vm.facade.lineBackgound,
              },
            },
            [
              _vm._l(_vm.content.company, function (m, i) {
                return [
                  _c(
                    "section",
                    {
                      key: "company" + i,
                      style:
                        "padding: " +
                        _vm.facade.itemPaddingTop +
                        "px " +
                        _vm.facade.itemPaddingRight +
                        "px " +
                        _vm.facade.itemPaddingBottom +
                        "px " +
                        _vm.facade.itemPaddingLeft +
                        "px",
                    },
                    [
                      m.name
                        ? _c(
                            "section",
                            {
                              style: {
                                fontSize: _vm.facade.nameFontSize + "px",
                                fontWeight: 500,
                              },
                            },
                            [_vm._v(_vm._s(m.name))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      m.address
                        ? _c("section", [_vm._v(_vm._s(m.address))])
                        : _vm._e(),
                      _vm._v(" "),
                      m.other
                        ? _c("section", [_vm._v(_vm._s(m.other))])
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.content.office.length
        ? _c(
            "section",
            [
              _c(
                "section",
                {
                  style: {
                    paddingTop: _vm.facade.itemPaddingBottom + "px",
                    fontSize: _vm.facade.nameFontSize + "px",
                    fontWeight: 500,
                  },
                },
                [_vm._v("办事处")]
              ),
              _vm._v(" "),
              _vm._l(_vm.content.office, function (m, i) {
                return [
                  _c(
                    "section",
                    {
                      key: "office" + i,
                      style:
                        "padding: " +
                        _vm.facade.itemPaddingTop +
                        "px " +
                        _vm.facade.itemPaddingRight +
                        "px " +
                        _vm.facade.itemPaddingBottom +
                        "px " +
                        _vm.facade.itemPaddingLeft +
                        "px",
                    },
                    [
                      m.name
                        ? _c(
                            "section",
                            {
                              style: {
                                fontSize: _vm.facade.nameFontSize + "px",
                                fontWeight: 500,
                              },
                            },
                            [_vm._v(_vm._s(m.name))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      m.other
                        ? _c("section", [_vm._v(_vm._s(m.other))])
                        : _vm._e(),
                      _vm._v(" "),
                      m.address
                        ? _c("section", [_vm._v(_vm._s(m.address))])
                        : _vm._e(),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }