"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _user = require("@/api/user");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserList',
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      opened: false,
      showAddView: false,
      regiestCodeCount: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      userFrom: {
        page: 1,
        limit: 20
      },
      tableDataNew: {
        data: [],
        total: 0
      },
      listLoadingNew: true,
      userFromNew: {
        page: 1,
        limit: 10,
        is_use: 0
      },
      showCodeView: false
    };
  },
  mounted: function mounted() {
    this.getList('');
  },
  methods: {
    openAction: function openAction() {
      this.opened = !this.opened;
      this.setOpenAction();
    },
    setOpenAction: function setOpenAction() {
      var _this = this;
      (0, _user.setRegisterCodeStatus)({
        status: Number(this.opened)
      }).then(function () {
        _this.$message.success('设置成功');
      });
    },
    showAddAction: function showAddAction() {
      this.regiestCodeCount = '';
      this.showAddView = true;
    },
    addRegiestCodeGroup: function addRegiestCodeGroup() {
      var _this2 = this;
      if (!this.regiestCodeCount || isNaN(this.regiestCodeCount)) {
        this.$message.error('请输入正确的数量');
        return false;
      }
      (0, _user.registercodeCreatedApi)({
        num: this.regiestCodeCount
      }).then(function () {
        _this2.showAddView = false;
        _this2.$message.success('操作成功');
        _this2.getList();
      });
    },
    delAction: function delAction(m) {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u6CE8\u518C\u7801\u7EC4\uFF1F", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.registercodeDelApi)({
          group_id: m.group_id
        }).then(function () {
          _this3.$message.success('操作成功');
          _this3.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showCodeAction: function showCodeAction(m) {
      this.showCodeView = true;
      this.userFromNew.group_id = m.group_id;
      this.loadCode();
    },
    loadCode: function loadCode(num) {
      var _this4 = this;
      this.listLoadingNew = true;
      this.userFromNew.page = num || this.userFromNew.page;
      (0, _user.registercodeListApi)(this.userFromNew).then(function (res) {
        _this4.tableDataNew.data = res.data.list;
        _this4.tableDataNew.total = res.data.count;
        _this4.listLoadingNew = false;
      }).catch(function (res) {
        _this4.listLoadingNew = false;
        // this.$message.error(res.message);
      });
    },
    // 列表
    getList: function getList(num) {
      var _this5 = this;
      this.listLoading = true;
      this.userFrom.page = num || this.userFrom.page;
      (0, _user.registercodeGroupList)(this.userFrom).then(function (res) {
        _this5.opened = !!res.data.registerCodeStatus;
        _this5.tableData.data = res.data.list;
        _this5.tableData.total = res.data.count;
        _this5.listLoading = false;
      }).catch(function (res) {
        _this5.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.userFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.limit = val;
      this.getList('');
    },
    pageChangeNew: function pageChangeNew(page) {
      this.userFromNew.page = page;
      this.loadCode('');
    },
    handleSizeChangeNew: function handleSizeChangeNew(val) {
      this.userFromNew.limit = val;
      this.loadCode('');
    },
    backAction: function backAction() {
      this.$router.back();
    },
    // 导出
    exportOrder: function exportOrder(id) {
      var _this6 = this;
      (0, _system.exportexcel)({
        export_type: 'userRegisterCode',
        group_id: id
      }).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this6.$createElement;
        _this6.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this6.$msgbox.close();
                _this6.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('userRegisterCode');
    }
  }
};