"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      },
      receiveData: {
        show: false,
        listLoading: true,
        list: [],
        total: 0,
        query: {
          page: 1,
          limit: 10,
          id: ''
        }
      },
      changeType: '1',
      setData: {
        show: false,
        name: '',
        time: []
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    changeTypeAction: function changeTypeAction(d) {
      if (Number(d) === 2) {
        this.$router.replace('/merchant/marketing/fanFission/list');
      }
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.integralRankingListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    createdAction: function createdAction() {
      this.setData = {
        show: true,
        name: '',
        time: []
      };
    },
    editAction: function editAction(m) {
      this.setData = {
        show: true,
        id: m.id,
        name: m.name,
        time: [m.start_time, m.end_time]
      };
    },
    delAction: function delAction(m) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u7EDF\u8BA1?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.integralRankingDelApi)({
          id: m.id
        }).then(function () {
          _this2.$message.success('操作成功');
          _this2.getList('');
        }).catch(function (message) {});
      }).catch(function () {});
    },
    showAction: function showAction(m) {
      this.receiveData = {
        show: true,
        listLoading: true,
        list: [],
        total: 0,
        query: {
          page: 1,
          limit: 10,
          id: m.id
        }
      };
      this.pageChangeNew(1);
    },
    pageChangeNew: function pageChangeNew(page) {
      this.receiveData.query.page = page;
      this.loadReceiveAction();
    },
    loadReceiveAction: function loadReceiveAction() {
      var _this3 = this;
      this.receiveData.listLoading = true;
      (0, _marketing.integralRankingDataApi)(this.receiveData.query).then(function (res) {
        _this3.receiveData.total = res.data.count;
        _this3.receiveData.list = res.data.list;
        _this3.receiveData.listLoading = false;
      }).catch(function () {});
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this4 = this;
      var data = {
        export_type: 'exportRankList',
        id: this.receiveData.query.id
      };
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this4.$createElement;
        _this4.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this4.$msgbox.close();
                _this4.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('exportRankList');
    },
    saveAction: function saveAction() {
      var _this5 = this;
      if (!this.setData.name) {
        this.$message.error('请输入名称');
        return false;
      }
      if (!this.setData.time[0] || !this.setData.time[1]) {
        this.$message.error('请设置统计排行时间');
        return false;
      }
      if (this.lock) return false;
      this.lock = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var action = _marketing.integralRankingCreateApi;
      var data = {
        name: this.setData.name,
        start_time: this.setData.time[0],
        end_time: this.setData.time[1],
        link: 'aa'
      };
      if (this.setData.id) {
        action = _marketing.integralRankingEditApi;
        data.id = this.setData.id;
      }
      action(data).then(function () {
        loading.close();
        _this5.$message.success('操作成功');
        _this5.lock = false;
        _this5.setData.show = false;
        _this5.getList(1);
      }).catch(function () {
        loading.close();
        _this5.lock = false;
      });
    }
  }
};