var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.addContract },
              },
              [_vm._v("创建合同模板")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "mini",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "template_id", label: "模板ID", "min-width": "50" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "type", label: "模板类型", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            scope.row.type == 1 ? "自定义模板" : "系统模板"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "name", label: "模板名称", "min-width": "80" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "scene", label: "使用场景", "min-width": "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "creator.real_name",
              label: "创建人",
              "min-width": "80",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "创建时间",
              "min-width": "100",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showeditdetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showdetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("主体模板")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.goappendx(scope.row.template_id)
                              },
                            },
                          },
                          [_vm._v("附件模板")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.getdetail(scope.row.template_id)
                              },
                            },
                          },
                          [_vm._v("预览")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.delcontract(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox" },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建合同模板",
            visible: _vm.createflag,
            width: "700px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createflag = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "divBox" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模板名称：",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { size: "normal", label: "" },
                                model: {
                                  value: _vm.tempName,
                                  callback: function ($$v) {
                                    _vm.tempName = $$v
                                  },
                                  expression: "tempName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "使用场景：",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "normal",
                                  label: "",
                                  type: "textarea",
                                  rows: 5,
                                },
                                model: {
                                  value: _vm.tempDesc,
                                  callback: function ($$v) {
                                    _vm.tempDesc = $$v
                                  },
                                  expression: "tempDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.cursoradd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showpreview, width: "1200px" },
          on: {
            "update:visible": function ($event) {
              _vm.showpreview = $event
            },
          },
        },
        [
          _c("preview", {
            attrs: {
              titleDisponsejson: _vm.titleDisponsejson,
              signDisponsejson: _vm.signDisponsejson,
              expiry_date_switch: _vm.expiry_date_switch,
              entrust_order_switch: _vm.entrust_order_switch,
              owndata: _vm.owndata,
              attachmentContent: _vm.attachmentContent,
              content: _vm.content,
              otherdata: _vm.otherdata,
              ownsigndata: _vm.ownsigndata,
              othersigndata: _vm.othersigndata,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }