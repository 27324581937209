"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _modules = _interopRequireDefault(require("./modules"));
var _covermask = _interopRequireDefault(require("../../components/customPage/covermask.vue"));
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Title: _modules.default.title.widget,
    Video: _modules.default.video.widget,
    Banner: _modules.default.banner.widget,
    Rubik: _modules.default.rubik.widget,
    Blank: _modules.default.blank.widget,
    Notice: _modules.default.notice.widget,
    Wechat: _modules.default.wechat.widget,
    MineNav: _modules.default.mine_nav.widget,
    NftInfo: _modules.default.nftInfo.widget,
    textView: _modules.default.textView.widget,
    memberView: _modules.default.member_view.widget,
    Covermask: _covermask.default
  },
  data: function data() {
    return {
      is_empty: false,
      id: this.$route.query.id || ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    dragIndex: {
      get: function get() {
        return this.$store.state.finish.dragIndex;
      },
      set: function set(value) {
        this.$store.commit('finish/setDragIndex', value);
      }
    },
    position: {
      get: function get() {
        return this.$store.state.finish.position;
      },
      set: function set(value) {
        this.$store.commit('finish/setDragPosition', value);
      }
    },
    select: function select() {
      return this.$store.state.finish.index;
    },
    pages: {
      get: function get() {
        return this.$store.state.finish.pages;
      },
      set: function set(value) {
        this.$store.commit('finish/setPagesData', value);
      }
    },
    coms: function coms() {
      return this.$store.state.finish.coms;
    }
  },
  mounted: function mounted() {
    this.loadPageInfo();
  },
  methods: {
    /**
     * 加载数据操作
     * @return {[type]} [description]
     */
    loadPageInfo: function loadPageInfo() {
      var _this = this;
      var params = {
        type: 'templatePersonalCenter'
      };
      if (this.$route.query.personal_center) {
        params = {
          type: 'personal_center'
        };
      }
      if (this.$route.query.personal_center_tiktok) {
        params = {
          type: 'personal_center_tiktok'
        };
      }
      (0, _system.diypageDetail)(params).then(function (res) {
        if (res.data.draft_config_data && res.data.draft_content) {
          res.data.config_data = res.data.draft_config_data;
          res.data.content = res.data.draft_content;
        }
        if (res.data.id || res.data.content && res.data.config_data) {
          var configData = res.data.config_data;
          _this.pages = res.data.content;
          var data = {
            background: configData.background,
            headerSetting: configData.headerSetting,
            userInfoSetting: configData.userInfoSetting,
            // 用户信息配置

            bg_image: configData.bg_image,
            //  背景图片
            bg_image_mode: configData.bg_image_mode,
            // 背景图片缩略模式

            content: '[]',
            created_time: 1632577555,
            deleted_time: null,
            goods_number: 0,
            is_deleted: 0,
            id: '',
            name: '个人中心',
            status: 0,
            title: '1632577470131',
            updated_time: 1632745632,
            visit_number: 0
          };
          _this.$store.commit('finish/setPageInfo', data);
        } else {
          var _data = {
            background: '#F7F7F7',
            headerSetting: {
              header_color: 'black'
            },
            userInfoSetting: {
              areaAlign: '1',
              avatar_width: 120,
              avatar_height: 120,
              top_padding: 0,
              left_padding: 30,
              uname_size: 32,
              uname_margin_left: 30,
              uname_color: '#FFFFFF',
              uname_style: 400,
              open_level: true,
              progress_color: '#FFFFFF',
              progress_margin_top: 8,
              progress_txt_margin_top: 10,
              progress_txt_color: '#FFFFFF',
              progress_txt_size: 24,
              progress_txt_style: 400,
              settingImg: 'https://saas.cdn.yunzongbu.cn/image/20211209/0995c825a3d1a76d6c43a7683a79c74a.png'
            },
            // 用户信息配置

            bg_image: 'https://saas.cdn.yunzongbu.cn/merchant/23/2021129/image/1639063418952104002.png',
            //  背景图片
            bg_image_mode: 4,
            // 背景图片缩略模式

            content: '[]',
            created_time: 1632577555,
            deleted_time: null,
            goods_number: 0,
            is_deleted: 0,
            id: '',
            name: '个人中心',
            status: 0,
            title: '1632577470131',
            updated_time: 1632745632,
            visit_number: 0
          };
          _this.pages = JSON.parse(_data.content);
          _this.$store.commit('finish/setPageInfo', _data);
        }
      }).catch(function () {});
      // setTimeout(() => {
      // }, 500)
    },
    /**
     * 拖拽激活
     * @param  {[type]} index    [description]
     * @param  {[type]} position [description]
     * @return {[type]}          [description]
     */
    dragActive: function dragActive(index, position) {
      if (this.dragIndex === index && this.position == position && this.coms) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 拖拽过程事件
     * @return {[type]} [description]
     */
    allowDrop: function allowDrop($event) {
      var index = parseInt($event.target.dataset.index);
      if (index || index === 0) {
        this.dragIndex = index;
        // 判断是否为拖拽元素
        if ($event.type === 'dragover' && $event.target.dataset) {
          // 判断是否是拖拽区域
          if ($event.target.dataset.type === 'widget') {
            // 获取Y轴移动值
            var y = parseFloat($event.offsetY);
            // 由于缩小50%，需要除去双倍
            var h = parseFloat($event.target.clientHeight / 4);
            if (y < h) {
              this.position = 'top';
            } else {
              this.position = 'down';
            }
          }
        }
      }
      if (index == -2) {
        this.dragIndex = index;
        this.position = 'down';
      }
      $event.preventDefault();
    },
    /**
     * 执行放置，只要在范围内
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    drop: function drop() {
      this.$store.dispatch('finish/pushPagesComs');
    },
    draggableStart: function draggableStart() {
      this.$store.commit('finish/setLock', true);
    },
    draggableEnd: function draggableEnd() {
      this.$store.commit('finish/setLock', false);
    },
    selectMessage: function selectMessage(data) {
      console.log(data);
    }
  }
};