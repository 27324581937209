"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
var _auction = _interopRequireDefault(require("../auction"));
var _product = _interopRequireDefault(require("../product"));
var _auction_album = _interopRequireDefault(require("../auction_album"));
var _auction_meeting = _interopRequireDefault(require("../auction_meeting"));
var _courseOnline = _interopRequireDefault(require("../courseOnline"));
var _courseOffline = _interopRequireDefault(require("../courseOffline"));
var _memberCard = _interopRequireDefault(require("../memberCard"));
var _appraisalExpert = _interopRequireDefault(require("../appraisalExpert"));
var _works = _interopRequireDefault(require("../works"));
var _appraisalQuestion = _interopRequireDefault(require("../appraisalQuestion"));
var _artiest = _interopRequireDefault(require("../artiest"));
var _bbs = _interopRequireDefault(require("../bbs"));
var _brand = _interopRequireDefault(require("../brand"));
var _exhibit = _interopRequireDefault(require("../exhibit"));
var _live = _interopRequireDefault(require("../live"));
var _ticket = _interopRequireDefault(require("../ticket"));
var _word = _interopRequireDefault(require("../word"));
var _work = _interopRequireDefault(require("../work"));
var _coupon = _interopRequireDefault(require("../coupon"));
var _category = _interopRequireDefault(require("../category"));
var _nftCollection = _interopRequireDefault(require("../nftCollection"));
var _nftBlindBox = _interopRequireDefault(require("../nftBlindBox"));
var _blindBox = _interopRequireDefault(require("../blindBox"));
var _auction2 = _interopRequireDefault(require("../templateComponent/auction"));
var _auctionAlbum = _interopRequireDefault(require("../templateComponent/auctionAlbum"));
var _auctionMeeting = _interopRequireDefault(require("../templateComponent/auctionMeeting"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Auction: _auction.default.element,
    Product: _product.default.element,
    AuctionAlbum: _auction_album.default.element,
    AuctionMeeting: _auction_meeting.default.element,
    CourseOnline: _courseOnline.default.element,
    CourseOffline: _courseOffline.default.element,
    MemberCard: _memberCard.default.element,
    AppraisalExpert: _appraisalExpert.default.element,
    Works: _works.default.element,
    AppraisalQuestion: _appraisalQuestion.default.element,
    Artiest: _artiest.default.element,
    Bbs: _bbs.default.element,
    Brand: _brand.default.element,
    Exhibit: _exhibit.default.element,
    Live: _live.default.element,
    Ticket: _ticket.default.element,
    Word: _word.default.element,
    Work: _work.default.element,
    Coupon: _coupon.default.element,
    Category: _category.default.element,
    NftCollection: _nftCollection.default.element,
    Nftblind: _nftBlindBox.default.element,
    BlindBox: _blindBox.default.element,
    templateAuctionList: _auction2.default.widget,
    templateAlbumList: _auctionAlbum.default.widget,
    templateMeetingList: _auctionMeeting.default.widget
  },
  props: {
    facade: {
      type: [Object, Array]
    },
    content: {
      type: [Object, Array]
    }
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    showContent: function showContent() {
      if (this.content.data[this.content.active] && this.content.data[this.content.active].link) {
        return this.content.data[this.content.active].link;
      } else {
        return {};
      }
    }
  },
  methods: {
    activeColor: function activeColor(index) {
      if (this.content.active === index) {
        return this.facade.text_active_color;
      } else {
        return this.facade.text_color;
      }
    },
    activeFontSize: function activeFontSize(index) {
      if (this.content.active === index) {
        return this.facade.active_text_size;
      } else {
        return this.facade.text_size;
      }
    },
    activeFontWeight: function activeFontWeight(index) {
      if (this.content.active === index) {
        return this.facade.active_text_style;
      } else {
        return this.facade.text_style;
      }
    },
    activeBackground: function activeBackground(index) {
      // text_active_bg_color
      if (this.content.active === index) {
        return this.facade.text_active_bg_color || 'none';
      } else {
        return 'none';
      }
    }
  }
};