var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: "装修" },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: { color: "#3480F4 !important" },
                    attrs: { type: "text", icon: "el-icon-picture-outline" },
                    on: {
                      click: function ($event) {
                        return _vm.onGOUrl("/merchant/systemForm/picture")
                      },
                    },
                  },
                  [_vm._v("素材库")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "relative" },
        [
          _c(
            "div",
            { staticClass: "top-view", staticStyle: { padding: "0 20px" } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs mt10",
                  attrs: {
                    "default-active": _vm.$route.path,
                    mode: "horizontal",
                  },
                  on: { select: _vm.changeTab },
                },
                [
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/pageManagement/decoration/classicTemplate",
                      },
                    },
                    [_vm._v(_vm._s(_vm.templateName))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index: "/merchant/pageManagement/decoration/diy",
                      },
                    },
                    [_vm._v("自定义页面")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-menu-item",
                    {
                      attrs: {
                        index:
                          "/merchant/pageManagement/decoration/templateMall",
                      },
                    },
                    [_vm._v("模版列表")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }