"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accounts = require("@/api/accounts");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Record',
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      chkName: '',
      chkNameRefund: '',
      isIndeterminate: true,
      resource: [],
      visible: false,
      timeVal: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableDataRefund: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10
        // data: '',
        // status: ''
      },
      ids: [],
      idsRefund: []
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getRefundList();
    if (this.$route.params.type === 0) {
      this.setTagsViewTitle();
    }
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  methods: {
    isDisabled: function isDisabled(row) {
      if (row.status !== 3) {
        return false;
      }
      return true;
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.data = this.timeVal ? this.timeVal.join('-') : '';
      this.getList();
      this.getRefundList();
    },
    getTotalRefund: function getTotalRefund(row) {
      var sum = 0;
      for (var i = 0; i < row.length; i++) {
        sum += row[i].product.cart_info.productAttr.price * row[i].refund_num;
      }
      return sum;
    },
    getTotal: function getTotal(row) {
      var sum = 0;
      for (var i = 0; i < row.length; i++) {
        sum += row[i].cart_info.productAttr.price * row[i].product_num;
      }
      return sum;
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _accounts.reconciliationOrderApi)(this.$route.params.id, this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.tableData.data.map(function (item) {
          _this.$set(item, {
            checked: false
          });
        });
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.chkName = '';
      this.getList();
    },
    // 退款列表
    getRefundList: function getRefundList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _accounts.reconciliationRefundApi)(this.$route.params.id, this.tableFrom).then(function (res) {
        _this2.tableDataRefund.data = res.data.list;
        _this2.tableDataRefund.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChangeRefund: function pageChangeRefund(page) {
      this.tableFrom.page = page;
      this.getRefundList();
    },
    handleSizeChangeRefund: function handleSizeChangeRefund(val) {
      this.tableFrom.limit = val;
      this.getRefundList();
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = '查看订单';
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch('tagsView/updateVisitedView', route);
    }
  }
};