"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _vuex = require("vuex");
var _product = require("@/api/product");
var _system = require("@/api/system");
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default,
    draggable: _vuedraggable.default
  },
  data: function data() {
    var _this = this;
    var numberValidator = function numberValidator(rule, value, callback, txt) {
      if (value === '') {
        callback();
      } else if (isNaN(value)) {
        callback("".concat(txt, "\u5FC5\u987B\u4E3A\u6570\u5B57"));
      } else if (value < 0) {
        callback("".concat(txt, "\u5FC5\u987B\u4E3A\u6B63\u6574\u6570"));
      } else {
        callback();
      }
    };
    var yunfeiValidator = function yunfeiValidator(rule, value, callback) {
      if (_this.ruleForm.postage_type === 2) {
        if (value === '') {
          callback();
        } else if (isNaN(value)) {
          callback("\u8FD0\u8D39\u5FC5\u987B\u4E3A\u6570\u5B57");
        } else if (value < 0) {
          callback("\u8FD0\u8D39\u5FC5\u987B\u4E3A\u6B63\u6574\u6570");
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var pluspriceValidator = function pluspriceValidator(rule, value, callback, txt) {
      if (_this.ruleForm.bid_type === 1) {
        if (_this.ruleForm.plus_price === '' || Number(_this.ruleForm.plus_price) === 0) {
          callback('请输入加价幅度');
        } else if (isNaN(_this.ruleForm.plus_price)) {
          callback("\u52A0\u4EF7\u5E45\u5EA6\u5FC5\u987B\u4E3A\u6570\u5B57");
        } else if (_this.ruleForm.plus_price < 0) {
          callback("\u52A0\u4EF7\u5E45\u5EA6\u5FC5\u987B\u4E3A\u6B63\u6574\u6570");
        } else {
          callback();
        }
      } else {
        if (!_this.ruleForm.bid_range_id) {
          callback('请选择竞价阶梯');
        } else {
          callback();
        }
      }
    };
    return {
      zcId: '',
      venue_id: '',
      zcList: [],
      id: '',
      venue_type: '',
      ruleForm: {
        auction_images: [],
        message_images: [],
        auction_video: '',
        lot: '',
        is_display_image_content: 1,
        // 1-显示 0-不显示   前台老数据是undefined
        auction_name: '',
        auction_desc: '',
        album_id: '',
        bond_price: 0,
        bid_type: 1,
        plus_price: '',
        bid_range_id: '',
        start_price: '',
        retain_price: '',
        top_price: '',
        min_price: '',
        max_price: '',
        postage_type: 4,
        postage_price: '',
        service_seven_refund: 1,
        service_fake_indemnity: 1,
        is_mer_recommend: 1,
        is_show: 1,
        order_num: 1,
        browse: undefined,
        content: '',
        special_note: ''
        // brand_id: ''
      },
      attr_info: [],
      has_attr_info: [],
      rules: {
        auction_name: {
          required: true,
          message: '请输入拍品名称',
          trigger: 'blur'
        },
        album_id: {
          required: true,
          message: '请选择专场',
          trigger: 'change'
        },
        bond_price: {
          validator: function validator(rule, value, callback) {
            return numberValidator(rule, value, callback, '拍品保证金');
          },
          trigger: 'blur'
        },
        plus_price: [{
          validator: pluspriceValidator,
          trigger: 'blur'
        }, {
          validator: pluspriceValidator,
          trigger: 'change'
        }],
        start_price: {
          validator: function validator(rule, value, callback) {
            return numberValidator(rule, value, callback, '起拍价');
          },
          trigger: 'blur'
        },
        retain_price: {
          validator: function validator(rule, value, callback) {
            return numberValidator(rule, value, callback, '保留价');
          },
          trigger: 'blur'
        },
        top_price: {
          validator: function validator(rule, value, callback) {
            return numberValidator(rule, value, callback, '一口价');
          },
          trigger: 'blur'
        },
        postage_price: [{
          validator: yunfeiValidator,
          trigger: 'blur'
        }, {
          validator: yunfeiValidator,
          trigger: 'change'
        }]
      },
      startTime: {
        year: undefined,
        month: undefined,
        date: undefined,
        hour: undefined,
        minute: undefined
      },
      endTime: {
        year: undefined,
        month: undefined,
        date: undefined,
        hour: undefined,
        minute: undefined
      },
      changeCategory: [],
      categoryList: [],
      showVideo: false,
      showImage: false,
      imagesViewKey: 'auction_images',
      imagesKey: 0,
      bidRList: [],
      againType: '1',
      brandList: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })),
  watch: {
    changeCategory: function changeCategory(val) {
      this.loadAttr(val[val.length - 1]);
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.zcId = this.$route.query.zcid || '';
    this.againType = this.$route.query.againType || '';
    this.ruleForm.album_id = this.$route.query.zcid ? Number(this.$route.query.zcid) : '';
    this.venue_id = this.$route.query.vid || '';
    this.venue_type = Number(this.$route.query.vtype || '1');
    if (Number(this.venue_type) === 3) {
      this.ruleForm.bid_type = 2;
    }
    this.id = this.$route.query.id || '';
    this.old_id = this.$route.query.old_id || '';
    this.loadRangeList();
    this.loadCategory();
    (0, _product.brandListApi)({
      page: 1,
      limit: 100
    }).then(function (res) {
      _this2.brandList = res.data.list;
    });
    if (this.zcId) {
      this.zcList = [{
        album_id: Number(this.zcId),
        album_name: this.$route.query.zcname || ''
      }];
    }
    // this.loadAlbumList();
    // albumDetail(this.zcId)
    //   .then(res => {
    //     this.zcList = [
    //       {
    //         album_id: res.data.album_id,
    //         album_name: this.$route.query.zcid || ''
    //       }
    //     ];
    //     this.loadAlbumList();
    //   })
    //   .catch(() => {
    //     this.loadAlbumList();
    //   });
    // } else {
    //   this.loadAlbumList();
    // }
    this.loadAlbumList();
    var nowDate = new Date();
    var endDate = new Date(Date.parse(nowDate) + 86400000);
    this.setTime('startTime', nowDate);
    this.setTime('endTime', endDate);
    if (this.id || this.old_id) {
      this.loadAuction();
    }
  },
  methods: {
    loadAttr: function loadAttr(id) {
      var _this3 = this;
      (0, _product.attrListApi)({
        page: 1,
        limit: 100,
        category_id: id
      }).then(function (res) {
        var attr = [];
        var attrIds = _this3.has_attr_info.map(function (m) {
          return m.attr_name;
        });
        res.data.list.forEach(function (m) {
          var index = attrIds.indexOf(m.attr_name);
          if (index === -1) {
            attr.push({
              attr_id: m.attr_id,
              attrValue: m.attrValue,
              attr_name: m.attr_name,
              attr_product_id: '',
              attr_type: m.attr_type,
              is_highlight: m.is_highlight,
              attr_value: m.attr_type === 3 ? [] : '',
              attr_value_name: '',
              product_id: _this3.id
            });
          } else {
            var val = _this3.has_attr_info[index].attr_value;
            if (m.attr_type === 2) {
              val = Number(val);
            } else if (m.attr_type === 3) {
              val = val.split(',').map(function (v) {
                return Number(v);
              });
            }
            attr.push({
              attr_id: m.attr_id,
              attrValue: m.attrValue,
              attr_name: m.attr_name,
              is_highlight: m.is_highlight,
              attr_product_id: _this3.has_attr_info[index].attr_product_id,
              attr_type: m.attr_type,
              attr_value: val,
              attr_value_name: _this3.has_attr_info[index].attr_value_name,
              product_id: _this3.id
            });
          }
        });
        _this3.attr_info = attr;
      });
    },
    loadAuction: function loadAuction() {
      var _this4 = this;
      (0, _auction.auctionDetail)(this.id || this.old_id).then(function (res) {
        var data = res.data;
        _this4.venue_type = data.auction_type;
        _this4.ruleForm = {
          auction_images: data.product.slider_image,
          message_images: data.message_images || [],
          auction_video: data.product.video_link,
          lot: data.lot,
          is_display_image_content: !data.extend_data || data.extend_data.is_display_image_content === undefined ? 1 : data.extend_data.is_display_image_content,
          auction_name: data.product.store_name,
          special_note: data.special_note,
          auction_desc: data.product.store_info,
          bond_price: Number(data.bond_price),
          bid_type: data.bid_type,
          plus_price: Number(data.plus_price),
          bid_range_id: data.bid_range_id ? data.bid_range_id : '',
          start_price: Number(data.start_price),
          retain_price: Number(data.retain_price),
          top_price: data.top_price ? Number(data.top_price) : '',
          // reference_price: data.reference_price,
          min_price: data.reference_price ? data.reference_price.split('-')[0] : 0,
          max_price: data.reference_price ? data.reference_price.split('-')[1] : 0,
          postage_type: data.product.postage_type,
          postage_price: Number(data.product.postage_price),
          service_seven_refund: data.product.service_seven_refund,
          service_fake_indemnity: data.product.service_fake_indemnity,
          is_mer_recommend: data.product.is_mer_recommend,
          is_show: data.product.is_show,
          order_num: data.order_num,
          browse: data.product.browse,
          content: data.content
          // brand_id: data.product.brand_id
        };
        _this4.$refs['editor'].setContent(data.content);
        if (data.attr_info && data.attr_info.length > 0) {
          _this4.has_attr_info = data.attr_info;
        }
        if (!_this4.old_id) {
          _this4.ruleForm.album_id = data.album_id;
        }
        if (!data.album_id) {
          _this4.setTime('startTime', data.start_time * 1000);
          _this4.setTime('endTime', data.end_time * 1000);
        }
        _this4.changeCategory = data.categoryParent.map(function (m) {
          return m.store_category_id;
        });
      }).catch(function () {
        _this4.$message.error('出现错误');
        _this4.$router.back();
      });
    },
    loadCategory: function loadCategory() {
      var _this5 = this;
      (0, _product.storeCategoryListApi)().then(function (res) {
        _this5.categoryList = res.data;
      });
    },
    loadRangeList: function loadRangeList() {
      var _this6 = this;
      (0, _system.bidRangeList)().then(function (res) {
        _this6.bidRList = res.data;
      });
    },
    loadAlbumList: function loadAlbumList() {
      var _this7 = this;
      (0, _auction.albumOptionList)({
        page: 1,
        limit: 50,
        status: 3,
        venue_id: this.venue_id
      }).then(function (res) {
        if (res.data.list && res.data.list.length > 0) {
          if (Number(_this7.$route.query.zcid)) {
            res.data.list.forEach(function (m) {
              if (m.album_id !== Number(_this7.$route.query.zcid)) {
                _this7.zcList.push(m);
              }
            });
          } else {
            _this7.zcList = _this7.zcList.concat(res.data.list);
          }
        }
      });
    },
    changeImg: function changeImg(key) {
      var _this8 = this;
      this.$modalUpload(function (img) {
        _this8.ruleForm[key] = _this8.ruleForm[key].concat(img);
      }, 15 - this.ruleForm[key].length, '1', false, 2);
    },
    uploadVideo: function uploadVideo() {
      var _this9 = this;
      this.$modalUpload(function (img) {
        _this9.ruleForm.auction_video = img[0];
      }, 1, '2', false, 2);
    },
    setData: function setData(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/ordernumable/issues/1012
      dataTransfer.setData('Text', '');
    },
    delImgAction: function delImgAction(i, key) {
      this.ruleForm[key].splice(i, 1);
    },
    delVideoAction: function delVideoAction() {
      this.ruleForm.auction_video = '';
    },
    showVideoAction: function showVideoAction() {
      this.showVideo = true;
    },
    showImageAction: function showImageAction(i, key) {
      this.imagesViewKey = key;
      this.imagesKey = i;
      this.showImage = true;
    },
    changeYunfeiType: function changeYunfeiType() {
      this.$refs['ruleForm'].validateField('postage_price');
    },
    saveAction: function saveAction(formName) {
      var _this10 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this10.ruleForm.auction_images.length <= 0) {
            _this10.$message.error('请上传图片');
            return false;
          }
          if (!_this10.changeCategory.length) {
            _this10.$message.error('请选择分类');
            return false;
          }
          var data = {
            auction_name: _this10.ruleForm.auction_name,
            special_note: _this10.ruleForm.special_note,
            is_display_image_content: _this10.ruleForm.is_display_image_content,
            lot: _this10.ruleForm.lot,
            auction_images: _this10.ruleForm.auction_images.join(','),
            message_images: _this10.ruleForm.message_images.join(','),
            auction_video: _this10.ruleForm.auction_video,
            album_id: _this10.ruleForm.album_id,
            start_price: Number(_this10.ruleForm.start_price),
            bid_type: _this10.ruleForm.bid_type,
            is_show: _this10.ruleForm.is_show,
            postage_type: _this10.ruleForm.postage_type,
            is_mer_recommend: _this10.ruleForm.is_mer_recommend,
            order_num: _this10.ruleForm.order_num || '',
            // reference_price: this.ruleForm.reference_price || '',
            content: _this10.ruleForm.content || '',
            service_seven_refund: _this10.ruleForm.service_seven_refund,
            browse: _this10.ruleForm.browse || '',
            auction_desc: _this10.ruleForm.auction_desc || '',
            service_fake_indemnity: _this10.ruleForm.service_fake_indemnity
            // brand_id: this.ruleForm.brand_id || ''
          };
          if (_this10.ruleForm.min_price && !_this10.ruleForm.max_price) {
            data.reference_price = _this10.ruleForm.min_price;
          }
          if (_this10.ruleForm.max_price && !_this10.ruleForm.min_price) {
            data.reference_price = _this10.ruleForm.max_price;
          }
          if (_this10.ruleForm.min_price && _this10.ruleForm.max_price) {
            data.reference_price = "".concat(_this10.ruleForm.min_price, "-").concat(_this10.ruleForm.max_price);
          }
          if (!_this10.ruleForm.min_price && !_this10.ruleForm.max_price) {
            data.reference_price = '';
          }
          if (_this10.venue_type !== 2 && _this10.venue_type !== 3) {
            data.retain_price = Number(_this10.ruleForm.retain_price);
            data.top_price = Number(_this10.ruleForm.top_price);
            data.bond_price = Number(_this10.ruleForm.bond_price);
            if (data.retain_price && data.top_price && data.top_price < data.retain_price) {
              _this10.$message.error("\u4E00\u53E3\u4EF7\u5FC5\u987B\u5927\u4E8E\u4FDD\u7559\u4EF7");
              return false;
            }
          }
          if (!data.album_id) {
            // 开拍时间处理
            var startTime = _this10.getTime('startTime');
            if (typeof startTime === 'string') {
              _this10.$message.error("".concat(startTime, "\u4E13\u573A\u5F00\u62CD\u65F6\u95F4"));
              return false;
            }
            data.start_time = startTime / 1000;
            // 结拍时间处理
            var endTime = _this10.getTime('endTime');
            if (typeof endTime === 'string') {
              _this10.$message.error("".concat(endTime, "\u7ED3\u62CD\u65F6\u95F4"));
              return false;
            }
            data.end_time = endTime / 1000;
            if (data.start_time + 600 >= data.end_time) {
              _this10.$message.error('拍品必须在开始10分钟后结束');
              return false;
            }
            if (data.end_time - data.start_time > 15552000) {
              _this10.$message.error('拍品必须在开始后半年内结束');
              return false;
            }
          }
          if (_this10.attr_info && _this10.attr_info.length > 0) {
            var attrInfo = JSON.parse(JSON.stringify(_this10.attr_info));
            attrInfo.forEach(function (m) {
              if (m.attr_type === 1) {
                m.attr_value_name = m.attr_value;
              } else if (m.attr_type === 2) {
                var index = m.attrValue.findIndex(function (r) {
                  return r.attr_value_id === Number(m.attr_value);
                });
                if (index !== -1) {
                  m.attr_value_name = m.attrValue[index].attr_value_name;
                }
              } else if (m.attr_type === 3) {
                var attrVName = [];
                m.attr_value.forEach(function (attr) {
                  var index = m.attrValue.findIndex(function (r) {
                    return r.attr_value_id === Number(attr);
                  });
                  if (index !== -1) {
                    attrVName.push(m.attrValue[index].attr_value_name);
                  }
                });
                if (attrVName.length >= 0) {
                  m.attr_value_name = attrVName.join(',');
                }
                m.attr_value = m.attr_value.join(',');
              }
              delete m.attrValue;
            });
            data.attr_info = JSON.stringify(attrInfo);
          }
          if (_this10.changeCategory.length > 0) {
            data.cate_id = _this10.changeCategory[_this10.changeCategory.length - 1];
          }
          if (data.bid_type === 1) {
            data.plus_price = Number(_this10.ruleForm.plus_price);
            data.bid_range_id = '';
          } else if (data.bid_type === 2) {
            data.bid_range_id = _this10.ruleForm.bid_range_id;
          }
          if (Number(data.postage_type) === 2) {
            data.postage_price = Number(_this10.ruleForm.postage_price);
          }
          if (_this10.old_id) {
            data.old_id = _this10.old_id;
          }
          var loading = _this10.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var action = _auction.addAuction;
          if (_this10.id) {
            action = _auction.editAuction;
          }
          action(_this10.id, data).then(function () {
            loading.close();
            _this10.$message.success('操作成功');
            _this10.$router.back();
          }).catch(function (msg) {
            loading.close();
          });
        } else {
          _this10.$message.error('请填写完整信息');
          return false;
        }
      });
    },
    getTime: function getTime(key, noHour) {
      if (!this[key].year || !this[key].month || !this[key].date) {
        return '请完整设置';
      }
      if (!noHour) {
        if (!this[key].hour || !this[key].minute) {
          return '请完整设置';
        }
      }
      var d = new Date("".concat(this[key].year, "-").concat(this[key].month, "-").concat(this[key].date));
      if (!noHour) {
        d.setHours(this[key].hour);
        d.setMinutes(this[key].minute);
        d.setSeconds(0);
      } else {
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
      }
      return Date.parse(d);
    },
    setTime: function setTime(key, date, noHour) {
      var d = new Date(date);
      this[key].year = d.getFullYear();
      this[key].month = d.getMonth() + 1;
      this[key].date = d.getDate();
      if (!noHour) {
        this[key].hour = d.getHours();
        this[key].minute = d.getMinutes();
      }
    },
    goPageAction: function goPageAction(link) {
      window.open(link);
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};