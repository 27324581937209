"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
var _basic = require("@/api/basic");
var _product = require("@/api/product");
var _setting = require("@/api/setting");
var _warehouse = require("@/api/warehouse");
var _user = require("@/api/user");
var _uniqBy = _interopRequireDefault(require("lodash/uniqBy"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import goods from '@/store/modules/goods';
var _default = exports.default = {
  name: "inventorycrate",
  props: {
    adminid: {
      type: String,
      default: ""
    },
    aduitid: {
      type: String,
      default: ""
    },
    serveid: {
      type: String,
      default: ""
    },
    refser: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      loading: false,
      rules: {
        entrust_id: [{
          required: true,
          message: "请选择委托人",
          trigger: "change"
        }],
        manage_admin_id: [{
          required: true,
          message: "请选择业务负责人",
          trigger: "change"
        }],
        audit_admin_id: [{
          required: false,
          message: "请选择审核人",
          trigger: "change"
        }]
      },
      formValidate: {
        transport_type: "",
        warehouse_id: "",
        entrust_id: "",
        manage_admin_id: "",
        audit_admin_id: ""
      },
      timeVal: [],
      goodsData: [],
      selectFlag: false,
      tableFrom: {
        page: 1,
        limit: 20,
        type: "1",
        cate_id: "",
        store_name: "",
        keyword: "",
        product_id: ""
      },
      tableData: {
        data: [],
        total: 0
      },
      merSelect: [],
      editFlag: false,
      num: 0,
      id: this.$route.query.id || "",
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      },
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: "",
      letterArr: [],
      storeArr: [],
      adminarr: [],
      goodsArr: [],
      goodsIdArr: [],
      inventoryConfig: {
        switch: {
          other_price_switch: 0,
          sold_price_switch: 0,
          unsold_price_switch: 0
        },
        price: {
          other_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          sold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          unsold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }]
        }
      },
      cate: "",
      showcategory: false,
      merCateList: [],
      props: {
        emitPath: true
        // multiple: true
      },
      selecrCatearr: [],
      selectCitem: {
        attrList: []
      },
      showcrate: false,
      createJson: {
        store_name: "",
        brand_id: "",
        image: ""
      },
      ruleValidate: {
        store_name: [{
          required: true,
          message: "请输入货品名称",
          trigger: "blur"
        }],
        brand_id: [{
          required: false,
          message: "请选择品牌",
          trigger: "change"
        }],
        image: [{
          required: true,
          message: "请上传货品图",
          trigger: "change"
        }]
      },
      BrandList: [],
      attrList: [],
      OneattrValue: [{
        image: "",
        stock: null,
        bar_code: "",
        weight: null,
        volume: null
      }],
      attr: [],
      cateIdArr: [],
      orderno: "",
      mannagename: "",
      editLoading: false
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    if (this.adminid) {
      this.formValidate.entrust_id = this.adminid;
    }
    if (this.aduitid) {
      this.formValidate.manage_admin_id = this.aduitid;
    }
    if (this.serveid) {
      this.formValidate.audit_admin_id = this.serveid;
    }
    this.getList();
    // this.getletter();
    this.getstore();
    this.getadminList();
    this.getconfig();
    this.getCategorySelect();
    this.getBrandListApi();
    (0, _warehouse.getOrderNo)().then(function (res) {
      _this2.orderno = res.data.order_no;
    });
    (0, _user.getBaseInfo)().then(function (res) {
      _this2.label = res.data;
      _this2.mannagename = res.data.merchantAdmin.real_name;
      // if(res.data.isStaff){
      //   this.formValidate.manage_admin_id = res.data.merchantAdmin.merchant_admin_id;
      // }
    }).catch(function (_ref) {
      var message = _ref.message;
    } //
    );
    if (this.id) {
      this.editLoading = true;
      setTimeout(function () {
        _this2.getDetail();
      }, 1000);
    }
  },
  methods: {
    close: function close() {
      if (this.refser) {
        this.$emit("close");
        return;
      }
      this.$router.back();
    },
    handleselectCate: function handleselectCate(e, noAddGoods) {
      var _this3 = this;
      var newcate = e.map(function (item) {
        return _this3.merCateList.filter(function (i) {
          return i.store_category_id === item;
        })[0];
      });
      // let newids = e.map(item => {
      //   return item.value;
      // });
      var newids = e;
      var ids = this.selecrCatearr.map(function (item) {
        return item.store_category_id;
      });
      // this.cateIdArr = ids;
      if (newids.length) {
        var countarrids = [];
        for (var i = 0; i < newids.length; i++) {
          var flag = false;
          for (var j = 0; j < ids.length; j++) {
            if (newids[i] == ids[j]) {
              flag = true;
            }
          }
          if (!flag) {
            countarrids.push(newcate[i]);
          }
        }
        if (noAddGoods) return false;
        var that = this;
        countarrids.forEach(function (item) {
          var arr = {
            value: item.store_category_id,
            label: item.cate_name
          };
          (0, _basic.goodsCategoryAttr)({
            cate_id: item.store_category_id
          }).then(function (res) {
            if (res.data && res.data.length) {
              res.data.forEach(function (item) {
                if (item.attr_type == 3) {
                  item.store_category_id = [];
                } else {
                  item.store_category_id = "";
                }
              });
              arr.attrList = res.data;
            } else {
              arr.attrList = [];
            }
            var json = {
              product_id: 0,
              image: "",
              store_name: "",
              retain_price: "",
              brand_name: "",
              attrInfo: JSON.parse(JSON.stringify(arr.attrList)),
              cate_id: item.store_category_id
            };
            // let newrow = {};
            json.success = JSON.parse(JSON.stringify(_this3.inventoryConfig.price.sold_price));
            json.fail = JSON.parse(JSON.stringify(_this3.inventoryConfig.price.unsold_price));
            json.other = JSON.parse(JSON.stringify(_this3.inventoryConfig.price.other_price));
            arr.goodsArr = [json];
            that.selecrCatearr.push(arr);
            that.selecrCatearr = (0, _uniqBy.default)(that.selecrCatearr, "value");
            console.log(that.selecrCatearr);
            that.getList(1);
          });
        });
      }
      return;
    },
    // 品牌筛选；
    getBrandListApi: function getBrandListApi() {
      var _this4 = this;
      (0, _product.brandListApi)().then(function (res) {
        _this4.BrandList = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // cursorCreate() {
    //   this.createJson.cate_id = this.selectCitem.value;
    //   console.log(this.createJson);
    //   let attr = [];
    //   if (this.selectCitem.attrList && this.selectCitem.attrList.length > 0) {
    //     this.selectCitem.attrList.forEach(res => {
    //       const d = {
    //         attr_id: res.attr_id,
    //         attr_name: res.attr_name,
    //         attr_type: res.attr_type
    //       };
    //       if (res.attr_type == 1) {
    //         if (res.value) {
    //           d.attr_value = res.value;
    //           d.attr_value_name = res.value;
    //         }
    //       } else if (res.attr_type == 2) {
    //         if (res.value) {
    //           d.attr_value = res.value;
    //           for (let j = 0; j < res.attrValue.length; j++) {
    //             if (res.attrValue[j].attr_value_id == res.value) {
    //               d.attr_value_name = res.attrValue[j].attr_value_name;
    //               break;
    //             }
    //           }
    //         }
    //       } else if (res.attr_type == 3) {
    //         if (res.value) {
    //           d.attr_value = res.value.join(",");
    //           let attrJson3 = {};
    //           res.attrValue.forEach(item => {
    //             attrJson3[item.attr_value_id] = item.attr_value_name;
    //           });
    //           let attrjsonarr = [];
    //           res.value.forEach(item => {
    //             attrjsonarr.push(attrJson3[item]);
    //           });
    //           d.attr_value_name = attrjsonarr.join(",");
    //         }
    //       }
    //       if (d.attr_value) {
    //         attr = attr.concat([d]);
    //       }
    //     });
    //   }
    //   if (attr && attr.length > 0) {
    //     this.createJson.attr_info = JSON.stringify(attr);
    //   }
    //   this.createJson.attrValue = this.OneattrValue;
    //   this.createJson.attr = [];
    //   this.createJson.content = "";
    //   this.$refs.createJson.validate(valid => {
    //     console.log(valid);
    //     if (valid) {
    //       this.fullscreenLoading = true;
    //       this.loading = true;
    //       fastcreateGoods(this.createJson)
    //         .then(async res => {
    //           this.fullscreenLoading = false;
    //           this.$message.success("创建成功");
    //           let newrow = res.data;
    //           newrow.success = JSON.parse(
    //             JSON.stringify(this.inventoryConfig.price.sold_price)
    //           );
    //           newrow.fail = JSON.parse(
    //             JSON.stringify(this.inventoryConfig.price.unsold_price)
    //           );
    //           newrow.other = JSON.parse(
    //             JSON.stringify(this.inventoryConfig.price.other_price)
    //           );
    //           newrow.price_data = [newrow.success, newrow.fail, newrow.other];
    //           newrow.retain_price = "";
    //           this.goodsIdArr.push(newrow.product_id);
    //           this.selecrCatearr[this.selectCindex].goodsArr.push(newrow);
    //           this.showcrate = false;
    //           this.loading = false;
    //           this.$refs.createJson.resetFields();
    //         })
    //         .catch(res => {
    //           this.fullscreenLoading = false;
    //           this.loading = false;
    //           // this.$message.error(res.message);
    //         });
    //     } else {
    //       if (
    //         !this.createJson.store_name ||
    //         !this.createJson.cate_id ||
    //         !this.createJson.image
    //       ) {
    //         this.$message.warning("请填写完整货品信息！");
    //       }
    //     }
    //   });
    //   //  this.selectCitem
    // },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this5 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        if (res.data.length) {
          if (!_this5.id) {
            _this5.cate = [res.data[0].store_category_id];
            _this5.handleselectCate([res.data[0].store_category_id]);
          }
          res.data.push({
            store_category_id: 0,
            cate_name: '无类别'
          });
          _this5.merCateList = res.data;
          //  this.getList(1);
        }
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    handleChange: function handleChange(val) {
      console.log(val);
      // let arr = this.$refs.cascader.getCheckedNodes('leafOnly')[0].data;
      // console.log(arr);
    },
    cursorcate: function cursorcate() {
      var _this6 = this;
      var arr = this.$refs.cascader.getCheckedNodes("leafOnly")[0].data;
      var ids = this.selecrCatearr.map(function (item) {
        return item.value;
      });
      if (ids.indexOf(arr.value) > -1) {
        this.$message.error("分类已选择");
        return;
      }
      this.showcategory = false;
      (0, _basic.goodsCategoryAttr)({
        cate_id: arr.value
      }).then(function (res) {
        if (res.data && res.data.length) {
          res.data.forEach(function (item) {
            if (item.attr_type == 3) {
              item.value = [];
            } else {
              item.value = "";
            }
          });
          arr.attrList = res.data;
        } else {
          arr.attrList = [];
        }
        arr.goodsArr = [];
        _this6.selecrCatearr.push(arr);
      });
    },
    handleClose: function handleClose(tag) {
      this.selecrCatearr.splice(this.selecrCatearr.indexOf(tag.value), 1);
      this.goodsIdArr = this.goodsIdArr.filter(function (item) {
        return item.store_category_id !== tag.value;
      });
      // this.goodsIdArr.splice(this.goodsIdArr.indexOf(tag.value), 1);
      this.cate.splice(this.cate.indexOf(tag.value), 1);
      this.getList(1);
    },
    remotetag: function remotetag(e) {
      console.log(e);
    },
    getconfig: function getconfig() {
      var _this7 = this;
      (0, _basic.contractConfig)().then(function (res) {
        if (res.status == 200) {
          _this7.inventoryConfig = res.data;
        }
      });
    },
    getadminList: function getadminList() {
      var _this8 = this;
      (0, _setting.adminListApi)({
        page: 1,
        limit: 200
      }).then(function (res) {
        _this8.adminarr = res.data.list;
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this9 = this;
      var params = {
        nicknameOrPhone: data,
        page: 1,
        limit: 20
      };
      (0, _basic.letterList)(params).then(function (res) {
        console.log(res);
        _this9.letterArr = res.data.list;
      });
    },
    getstore: function getstore() {
      var _this10 = this;
      (0, _product.getStoreList)().then(function (res) {
        _this10.storeArr = res.data.list;
      });
    },
    changeshowcate: function changeshowcate() {
      this.showcategory = true;
    },
    submitForm: function submitForm(formName) {
      var _this11 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // if(!this.goodsIdArr.length){
          //    return this.$message.error('请选择货品')
          // }
          _this11.loading = true;
          var goodsarr = [];
          var selecrCatearr = JSON.parse(JSON.stringify(_this11.selecrCatearr));
          selecrCatearr.forEach(function (item) {
            goodsarr = goodsarr.concat(item.goodsArr);
          });
          // let goodsarr = JSON.parse(JSON.stringify(this.goodsArr));
          var addflag = false;
          goodsarr.forEach(function (item) {
            item.price_data = JSON.stringify(item.price_data);
            if (item.product_id == 0) {
              var newattinfo = [];
              item.attrInfo.forEach(function (it) {
                newattinfo.push({
                  attr_id: it.attr_id,
                  attr_name: it.attr_name,
                  // attr_type: it.attr_type,
                  attr_type: 1,
                  attr_value: it.attr_value_name,
                  attr_value_name: it.attr_value_name,
                  mer_id: item.mer_id
                });
              });
              item.price_data = JSON.stringify([item.success, item.fail, item.other]);
              item.attr_info = JSON.stringify(newattinfo);
              item.attrValue = _this11.OneattrValue;
              // item.attr_value = this.OneattrValue;
              item.attr = [];
              item.image = item.image[0];
              if (!item.store_name || !item.image) {
                addflag = true;
              }
            }
          });
          if (addflag) {
            _this11.$message.error("存在空的名称或者图片，请完善");
            _this11.loading = false;
            return;
          }
          if (!goodsarr.length) {
            return _this11.$message.error("请选择货品");
          }
          _this11.formValidate.product_data = goodsarr;
          if (_this11.id) {
            (0, _basic.editinventoryOrder)(_this11.id, _this11.formValidate).then(function (res) {
              _this11.loading = false;
              _this11.$message.success("保存成功");
              console.log(_this11.refser);
              if (_this11.refser) {
                _this11.$emit("savesuccess", res.message);
              } else {
                _this11.$router.back();
              }
            }).catch(function (res) {
              _this11.loading = false;
              // this.$message.error(res.message);
            });
            return;
          }
          (0, _basic.createinventoryOrder)(_this11.formValidate).then(function (res) {
            _this11.loading = false;
            if (res.status == 200) {
              _this11.$message.success("保存成功");
              if (_this11.refser) {
                _this11.$emit("savesuccess", res.message);
              } else {
                _this11.$router.back();
              }
            }
            console.log(res);
          }).catch(function (res) {
            _this11.loading = false;
            // this.$message.error(res.message);
          });
        } else {
          return false;
        }
      });
    },
    showImage: function showImage(item) {
      this.dialogVisible = true;
      this.dialogImageUrl = item;
    },
    del: function del(index) {
      this.formValidate.bannerArr[index].img = "";
    },
    getDetail: function getDetail() {
      var _this12 = this;
      (0, _basic.getinventoryOrderDetail)(this.id).then(function (res) {
        _this12.formValidate.entrust_id = res.data.entrust_id;
        _this12.formValidate.manage_admin_id = res.data.manage_admin_id;
        _this12.cate = res.data.storeCategory.map(function (m) {
          return m.store_category_id;
        });
        _this12.letterArr = [res.data.userEntrust];
        res.data.storeCategory.forEach(function (m) {
          m.orderProduct.forEach(function (child) {
            var ids = _this12.tableData.data.map(function (m) {
              return m.product_id;
            });
            var index = ids.indexOf(child.product_id);
            if (index != -1) {
              _this12.tableData.data[index].selected = true;
            }
            var params = {
              product_id: child.product_id
            };
            (0, _basic.goodsList)(params).then(function (res) {
              if (res.data.list && res.data.list[0]) {
                _this12.saleGoods(res.data.list[0], 0, child);
              }
            });
            _this12.editLoading = false;
          });
        });
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      //   this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.formValidate.stime = e[0];
      this.formValidate.etime = e[1];
    },
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        if (tit == "1") {
          _this.selecrCatearr[num].goodsArr[i].image = img;
          _this.$forceUpdate();
          return;
        }
        if (tit === "1" && !num) {
          _this.createJson.image = img[0];
          this.$forceUpdate();
          _this.OneattrValue[0].image = img[0];
        }
        if (tit === "2" && !num) {
          img.map(function (item) {
            attr.push(item.attachment_src);
            _this.createJson.slider_image.push(item);
            if (_this.createJson.slider_image.length > 10) _this.createJson.slider_image.length = 10;
          });
        }
        if (tit === "1" && num === "dan") {
          _this.OneattrValue[0].image = img[0];
        }
        if (tit === "1" && num === "duo") {
          _this.ManyAttrValue[i].image = img[0];
        }
        if (tit === "1" && num === "pi") {
          _this.oneFormBatch[0].image = img[0];
        }
      }, tit);
    },
    getList: function getList() {
      var _this13 = this;
      var ids = this.selecrCatearr.map(function (item) {
        return item.value;
      });
      this.tableFrom.cate_id = ids;
      (0, _basic.goodsList)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item) {
          item.selected = false;
        });
        _this13.tableData.data = res.data.list;
        _this13.tableData.total = res.data.count;
        _this13.listLoading = false;
      }).catch(function (res) {
        _this13.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    selectActiveGoods: function selectActiveGoods(citem, cindex) {
      this.selectFlag = true;
      this.selectCitem = citem;
      this.selectCindex = cindex;
      this.tableFrom.cate_id = citem.value;
      this.getList();
    },
    createActiveGoods: function createActiveGoods(citem, cindex) {
      // this.showcrate = true;
      // this.selectCitem = citem;
      // this.selectCindex = cindex;
      // this.attrList = citem.attrList;
      var json = {
        product_id: 0,
        image: "",
        store_name: "",
        retain_price: "",
        brand_name: "",
        attrInfo: JSON.parse(JSON.stringify(citem.attrList)),
        cate_id: citem.value
      };
      // let newrow = {};
      json.success = JSON.parse(JSON.stringify(this.inventoryConfig.price.sold_price));
      json.fail = JSON.parse(JSON.stringify(this.inventoryConfig.price.unsold_price));
      json.other = JSON.parse(JSON.stringify(this.inventoryConfig.price.other_price));
      // json.price_data = [newrow.success, newrow.fail, newrow.other];
      console.log(json);
      this.selecrCatearr[cindex].goodsArr.push(json);
    },
    saleGoods: function saleGoods(row, index, item) {
      if (this.goodsIdArr.findIndex(function (item) {
        return item.product_id === row.product_id;
      }) > -1) {
        return;
      }
      this.row = row;
      this.sindex = index;
      this.row.selected = true;
      var newrow = JSON.parse(JSON.stringify(row));
      newrow.success = item && item.price_data && item.price_data[0] ? item.price_data[0] : JSON.parse(JSON.stringify(this.inventoryConfig.price.sold_price));
      newrow.fail = item && item.price_data && item.price_data[1] ? item.price_data[1] : JSON.parse(JSON.stringify(this.inventoryConfig.price.unsold_price));
      newrow.other = item && item.price_data && item.price_data[2] ? item.price_data[2] : JSON.parse(JSON.stringify(this.inventoryConfig.price.other_price));
      newrow.price_data = [newrow.success, newrow.fail, newrow.other];
      newrow.retain_price = item ? Number(item.retain_price).toString() : "";
      var ids = this.selecrCatearr.map(function (item) {
        return item.value;
      });
      if (row.cate_id === 0) {
        var cateindex = ids.indexOf(0);
        if (cateindex > -1) {
          this.selecrCatearr[cateindex].goodsArr.push(newrow);
        } else {
          this.selecrCatearr.push({
            cate: null,
            value: 0,
            label: '无类别',
            attrList: newrow.attribute,
            goodsArr: [newrow]
          });
          this.cateIdArr.push(0);
          this.getList(1);
        }
      } else {
        var _cateindex = ids.indexOf(row.cate_parent[0].store_category_id);
        if (_cateindex > -1) {
          this.selecrCatearr[_cateindex].goodsArr.push(newrow);
        } else {
          this.selecrCatearr.push({
            cate: newrow.cate_parent[0],
            value: newrow.cate_parent[0].store_category_id,
            label: newrow.cate_parent[0].cate_name,
            attrList: newrow.attribute,
            goodsArr: [newrow]
          });
          this.cateIdArr.push(row.cate_parent[0].store_category_id);
          this.getList(1);
        }
      }

      // this.selecrCatearr[this.selectCindex].goodsArr.push(newrow);
      // this.goodsArr.push(newrow);
      console.log(row);
      this.goodsIdArr.push({
        product_id: row.product_id,
        store_category_id: row.storeCategory.store_category_id || 0
      });
    },
    closeSelect: function closeSelect() {
      this.selectFlag = false;
    },
    delGoods: function delGoods(index, row, cindex) {
      // let nindex = this.goodsIdArr.findIndex(
      //   item => item.product_id === row.product_id
      // );
      // let nindex = this.goodsIdArr.indexOf(row.product_id);
      // this.goodsIdArr.splice(nindex, 1);
      this.goodsIdArr = this.goodsIdArr.filter(function (item) {
        return item.product_id !== row.product_id;
      });
      this.selecrCatearr[cindex].goodsArr.splice(index, 1);
      // this.goodsArr.splice(nindex, 1);
    },
    // cancelSelect(index, row) {
    //   row.selected = false;
    //   let nindex = this.goodsIdArr.indexOf(row.product_id);
    //   this.goodsIdArr.splice(nindex, 1);
    //   this.goodsArr.splice(nindex, 1);
    //   return;
    //   row.selected = false;
    //   this.tableData.data.splice(index, 1);
    //   this.tableData.data.push(row);
    // },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};