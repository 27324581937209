var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", "label-width": "120px" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { display: "block" },
                          attrs: { label: "状态：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mr20",
                              attrs: {
                                type: "button",
                                size: "small",
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.product_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "product_status", $$v)
                                },
                                expression: "tableFrom.product_status",
                              },
                            },
                            _vm._l(_vm.fromList.fromTxt, function (item, i) {
                              return _c(
                                "el-radio-button",
                                { key: i, attrs: { label: item.val } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "拼团状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.active_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "active_type", $$v)
                                },
                                expression: "tableFrom.active_type",
                              },
                            },
                            _vm._l(_vm.activityStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品搜索：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: { placeholder: "请输入商品名称" },
                              model: {
                                value: _vm.tableFrom.keyword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "keyword", $$v)
                                },
                                expression: "tableFrom.keyword",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: "" + _vm.roterPre + "/marketing/combination/create",
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [
                      _c("i", { staticClass: "add" }, [_vm._v("+")]),
                      _vm._v(" 添加拼团商品\n        "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "row-class-name": _vm.tableRowClassName,
              },
              on: {
                rowclick: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeEdit($event)
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "product_group_id",
                  label: "ID",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拼团商品图片", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.product.image,
                                "preview-src-list": [scope.row.product.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "product.store_name",
                  label: "拼团商品",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "product.price",
                  label: "原价",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "price", label: "拼团价", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "buying_count_num",
                  label: "拼团人数",
                  "min-width": "80",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "success_num",
                  label: "成团数量",
                  "min-width": "90",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sales",
                  label: "已售商品数",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拼团活动状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.action_status === 0
                                ? "未开始"
                                : scope.row.action_status === 1
                                ? "正在进行"
                                : "已结束"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动时间", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "开始日期：" +
                              _vm._s(
                                scope.row.start_time && scope.row.start_time
                                  ? scope.row.start_time
                                  : ""
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "结束日期：" +
                              _vm._s(
                                scope.row.end_time && scope.row.end_time
                                  ? scope.row.end_time
                                  : ""
                              )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "stock",
                  label: "限量",
                  align: "center",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "product.sort",
                  align: "center",
                  label: "排序",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.index === _vm.tabClickIndex
                          ? _c(
                              "span",
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "300",
                                    size: "mini",
                                    autofocus: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.inputBlur(scope)
                                    },
                                  },
                                  model: {
                                    value: scope.row["product"]["sort"],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row["product"],
                                        "sort",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row['product']['sort']",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              {
                                on: {
                                  dblclick: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.tabClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row["product"]["sort"]))]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "显示状态", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onchangeIsShow(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.is_show,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "is_show", $$v)
                            },
                            expression: "scope.row.is_show",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "130" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.product_status === 0
                                ? "待审核"
                                : scope.row.product_status === 1
                                ? "审核通过"
                                : "审核失败"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.product_status === -1 ||
                        scope.row.product_status === -2
                          ? _c(
                              "span",
                              { staticStyle: { "font-size": "12px" } },
                              [
                                _c("br"),
                                _vm._v(
                                  "\n            原因：" +
                                    _vm._s(scope.row.refusal) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                path:
                                  _vm.roterPre +
                                  "/marketing/combination/create/" +
                                  scope.row.product_group_id,
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "commonedit" },
                                }),
                                _vm._v("编辑"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.goDetail(scope.row.product_group_id)
                              },
                            },
                          },
                          [_vm._v("查看详情")]
                        ),
                        _vm._v(" "),
                        scope.row.product.action_status !== 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "拼团商品详情",
                center: "",
                visible: _vm.dialogVisible,
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _c("div", { staticClass: "box-container" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("商品ID："),
                        ]),
                        _vm._v(_vm._s(_vm.formValidate.product_id)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("商品名称："),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formValidate.store_name)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp100 image" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("商品图："),
                        ]),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: { "max-width": "150px", height: "80px" },
                          attrs: { src: _vm.formValidate.image },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp100" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("商品信息"),
                        ]),
                        _vm._v(" "),
                        _vm.formValidate.spec_type === 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticClass: "tabNumWidth",
                                    attrs: {
                                      data: _vm.OneattrValue,
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "拼团价格",
                                        "min-width": "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row["active_price"]
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        279541072
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "已售商品数量",
                                        "min-width": "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row["sales"])
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        703426790
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formValidate.spec_type === 1
                          ? _c(
                              "div",
                              {
                                staticClass: "labeltop",
                                attrs: { label: "规格列表：" },
                              },
                              [
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.ManyAttrValue,
                                      height: "260",
                                      "tooltip-effect": "dark",
                                    },
                                  },
                                  [
                                    _vm.manyTabDate
                                      ? _vm._l(
                                          _vm.manyTabDate,
                                          function (item, iii) {
                                            return _c("el-table-column", {
                                              key: iii,
                                              attrs: {
                                                align: "center",
                                                label:
                                                  _vm.manyTabTit[iii].title,
                                                "min-width": "80",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("span", {
                                                          staticClass:
                                                            "priceBox",
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              scope.row[iii]
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "拼团价格",
                                        "min-width": "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row["active_price"]
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        279541072
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "已售商品数量",
                                        "min-width": "80",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row["sales"])
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        703426790
                                      ),
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        staticStyle: { "margin-top": "20px" },
                      },
                      [_vm._v("拼团商品活动信息")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "acea-row" }, [
                      _c("div", { staticClass: "list sp100" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("拼团活动简介："),
                        ]),
                        _vm._v(_vm._s(_vm.formValidate.store_info)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp100" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("拼团活动日期："),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formValidate.start_time +
                              "-" +
                              _vm.formValidate.end_time
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("拼团人数："),
                        ]),
                        _vm._v(
                          _vm._s(_vm.formValidate.buying_count_num) + "人"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("成团数量："),
                        ]),
                        _vm._v(_vm._s(_vm.formValidate.success_num) + "个"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("活动期间限购件数："),
                        ]),
                        _vm._v(_vm._s(_vm.formValidate.pay_count)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("限量："),
                        ]),
                        _vm._v(_vm._s(_vm.formValidate.stock)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("单次购买限购件数："),
                        ]),
                        _vm._v(_vm._s(_vm.formValidate.once_pay_count)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("拼团活动状态："),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formValidate.action_status === 0
                              ? "未开始"
                              : _vm.formValidate.action_status === 1
                              ? "正在进行"
                              : "已结束"
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("拼团成功人次/参与人次："),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formValidate.count_user +
                              "/" +
                              _vm.formValidate.count_take
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("创建时间："),
                        ]),
                        _vm._v(_vm._s(_vm.formValidate.create_time)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("审核状态："),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formValidate.product_status === 1
                              ? "审核通过"
                              : _vm.formValidate.product_status === 0
                              ? "未审核"
                              : "审核未通过 原因：" + _vm.formValidate.refusal
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "list sp" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("显示状态："),
                        ]),
                        _vm._v(
                          _vm._s(
                            _vm.formValidate.is_show === 1 ? "显示" : "隐藏"
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }