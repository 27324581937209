var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm ",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "优惠券名称：", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: {
                              placeholder: "请输入优惠券名称",
                              disabled: _vm.status > 10,
                            },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发放数量：", prop: "total_num" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c("el-input", {
                            staticClass: "w100",
                            attrs: { placeholder: "数量" },
                            model: {
                              value: _vm.ruleForm.total_num,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "total_num", _vm._n($$v))
                              },
                              expression: "ruleForm.total_num",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "ml10" }, [_vm._v("张")]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型：", prop: "type" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w300",
                              attrs: { disabled: _vm.status > 10 },
                              on: {
                                change: function ($event) {
                                  return _vm.editAction("reduction")
                                },
                              },
                              model: {
                                value: _vm.ruleForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "type", $$v)
                                },
                                expression: "ruleForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: 1, label: "满减券" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 2, label: "立减券" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 3, label: "折扣券" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { value: 4, label: "核销券" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.type == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "满：", prop: "reduction" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                attrs: {
                                  disabled: _vm.status > 10,
                                  placeholder: "金额",
                                },
                                model: {
                                  value: _vm.ruleForm.full,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "full", _vm._n($$v))
                                  },
                                  expression: "ruleForm.full",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("元，减"),
                              ]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "w100 ml10",
                                attrs: {
                                  disabled: _vm.status > 10,
                                  placeholder: "金额",
                                },
                                model: {
                                  value: _vm.ruleForm.reduction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "reduction",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.reduction",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("元"),
                              ]),
                              _vm._v(" "),
                              _vm.ruleForm.type == 1
                                ? _c(
                                    "el-checkbox",
                                    {
                                      staticClass: "ml20",
                                      attrs: { disabled: _vm.status > 10 },
                                      model: {
                                        value: _vm.ruleForm.superposition,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "superposition",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.superposition",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "叠加满减券(例：满" +
                                          _vm._s(_vm.ruleForm.full) +
                                          "减" +
                                          _vm._s(_vm.ruleForm.reduction) +
                                          "，满" +
                                          _vm._s(_vm.ruleForm.full * 2) +
                                          "减" +
                                          _vm._s(_vm.ruleForm.reduction * 2) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm.ruleForm.type == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "立减：", prop: "reduction" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c w300" },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                attrs: {
                                  disabled: _vm.status > 10,
                                  placeholder: "金额",
                                },
                                model: {
                                  value: _vm.ruleForm.reduction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "reduction",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.reduction",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm.ruleForm.type == 3
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "折扣至：", prop: "reduction" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c w500" },
                            [
                              _c("el-input", {
                                staticClass: "w100",
                                attrs: {
                                  disabled: _vm.status > 10,
                                  placeholder: "百分比",
                                },
                                model: {
                                  value: _vm.ruleForm.reduction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "reduction",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.reduction",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("%"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "10px",
                                    color: "#999999",
                                  },
                                },
                                [_vm._v("例：100元，折扣至55%，实付55元")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "有效期：", prop: "failure_type" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.status > 10 },
                          on: {
                            change: function ($event) {
                              return _vm.editAction("failure_type")
                            },
                          },
                          model: {
                            value: _vm.ruleForm.failure_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "failure_type", $$v)
                            },
                            expression: "ruleForm.failure_type",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: 1 } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  disabled:
                                    _vm.ruleForm.failure_type == 2 ||
                                    _vm.status > 10,
                                },
                                model: {
                                  value: _vm.ruleForm.times,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "times", $$v)
                                  },
                                  expression: "ruleForm.times",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { label: 2 } },
                            [
                              _c("span", [_vm._v("领取后")]),
                              _vm._v(" "),
                              _c("el-input", {
                                staticClass: "w100 ml10",
                                attrs: {
                                  placeholder: "天数",
                                  disabled:
                                    _vm.ruleForm.failure_type == 1 ||
                                    _vm.status > 10,
                                },
                                model: {
                                  value: _vm.ruleForm.effective_day,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "effective_day",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.effective_day",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("内有效"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "领券限制：" } }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c("span", [_vm._v("每人限领")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w100 ml10",
                      attrs: { placeholder: "数量", disabled: _vm.status > 10 },
                      model: {
                        value: _vm.ruleForm.get_limit,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "get_limit", _vm._n($$v))
                        },
                        expression: "ruleForm.get_limit",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml10" }, [_vm._v("张")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml10 color-gray" }, [
                      _vm._v("（0或者不填为不限制）"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用限制：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { disabled: _vm.status > 10 },
                      model: {
                        value: _vm.ruleForm.mutex,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "mutex", $$v)
                        },
                        expression: "ruleForm.mutex",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 1 } }, [
                        _vm._v("不与会员卡共同使用"),
                      ]),
                      _vm._v(" "),
                      _c("el-checkbox", { attrs: { label: 2 } }, [
                        _vm._v("不与积分共同使用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "人群限制：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.status > 10 },
                      model: {
                        value: _vm.ruleForm.crowd_limit,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "crowd_limit", $$v)
                        },
                        expression: "ruleForm.crowd_limit",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("全部用户"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { disabled: "", label: 2 } }, [
                        _vm._v("会员可领取"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("关联名单"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("不可领取"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.crowd_limit == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "500px" },
                          attrs: {
                            multiple: "",
                            placeholder: "请选择快照名单",
                          },
                          model: {
                            value: _vm.ruleForm.snapshot_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "snapshot_id", $$v)
                            },
                            expression: "ruleForm.snapshot_id",
                          },
                        },
                        _vm._l(_vm.merCateList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.title,
                              value: item.snapshot_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用须知：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "700px" },
                    attrs: {
                      "show-word-limit": "",
                      maxlength: "1000",
                      rows: "4",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "使用范围：" } }, [
                _c(
                  "div",
                  { staticClass: "tab" },
                  [
                    _c("div", { staticClass: "tr flex align-items-c" }, [
                      _c(
                        "div",
                        { staticClass: "th", staticStyle: { width: "100%" } },
                        [_vm._v("符合如下条件可使用")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.use_range, function (m, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "tr flex align-items-c" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "20%" },
                            },
                            [
                              i == 0
                                ? _c("div", [_vm._v("商品")])
                                : i == 7
                                ? _c("div", [_vm._v("拍卖拍品")])
                                : i == 8
                                ? _c("div", [_vm._v("数字藏品")])
                                : i == 9
                                ? _c("div", [_vm._v("数字盲盒")])
                                : i == 10
                                ? _c("div", [_vm._v("实物盲盒")])
                                : i == 11
                                ? _c("div", [_vm._v("门票")])
                                : i == 20
                                ? _c("div", [_vm._v("鉴定")])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "td",
                              staticStyle: { width: "80%" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.status > 10 },
                                  on: {
                                    change: function (val) {
                                      return _vm.changeTypeAction(val, i)
                                    },
                                  },
                                  model: {
                                    value: m.type,
                                    callback: function ($$v) {
                                      _vm.$set(m, "type", $$v)
                                    },
                                    expression: "m.type",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("不使用"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("全部" + _vm._s(m.keyName)),
                                  ]),
                                  _vm._v(" "),
                                  i != 20
                                    ? _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("指定" + _vm._s(m.keyName)),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              m.type == 2
                                ? [
                                    _c("PopoverTable", {
                                      attrs: { "show-data": m.data },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ml10",
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.status > 10,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeTypeAction(2, i)
                                          },
                                        },
                                      },
                                      [_vm._v("选择" + _vm._s(m.keyName))]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "自动发放：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "font-size": "12px" },
                      model: {
                        value: _vm.ruleForm.auto_issue,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "auto_issue", $$v)
                        },
                        expression: "ruleForm.auto_issue",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "ft12", attrs: { label: 1 } },
                        [_vm._v("开启")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        { staticClass: "ft12", attrs: { label: 2 } },
                        [_vm._v("不开启")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.auto_issue === 1
                ? [
                    _c("el-form-item", { attrs: { label: " " } }, [
                      _c(
                        "div",
                        { staticClass: "flex align-items-c" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { "font-size": "12px" },
                              model: {
                                value: _vm.ruleForm.auto_issue_user_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "auto_issue_user_type",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.auto_issue_user_type",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "ft12", attrs: { label: 1 } },
                                [_vm._v("全部用户")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  staticClass: "ft12",
                                  attrs: { label: 3, disabled: "" },
                                },
                                [_vm._v("会员")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                { staticClass: "ft12", attrs: { label: 2 } },
                                [_vm._v("关联名单")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "ml20",
                              staticStyle: { width: "500px" },
                              attrs: {
                                multiple: "",
                                placeholder: "请选择快照名单",
                                disabled:
                                  _vm.ruleForm.auto_issue_user_type !== 2,
                              },
                              model: {
                                value: _vm.ruleForm.auto_issue_snapshot_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "auto_issue_snapshot_id",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.auto_issue_snapshot_id",
                              },
                            },
                            _vm._l(_vm.merCateList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.title,
                                  value: item.snapshot_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-form-item", { attrs: { label: " " } }, [
                      _c(
                        "div",
                        { staticClass: "tab" },
                        [
                          _c("div", { staticClass: "tr flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "th",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _vm._v(
                                  "\n                购买如下商品后自动发放该优惠券\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.goods_list, function (m, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "tr flex align-items-c" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "td",
                                    staticStyle: { width: "20%" },
                                  },
                                  [
                                    i == 0
                                      ? _c("div", [_vm._v("商品")])
                                      : i == 7
                                      ? _c("div", [_vm._v("拍卖拍品")])
                                      : i == 8
                                      ? _c("div", [_vm._v("数字藏品")])
                                      : i == 9
                                      ? _c("div", [_vm._v("数字盲盒")])
                                      : i == 10
                                      ? _c("div", [_vm._v("实物盲盒")])
                                      : i == 11
                                      ? _c("div", [_vm._v("门票")])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "td",
                                    staticStyle: { width: "80%" },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function (val) {
                                            return _vm.changeSendTypeAction(
                                              val,
                                              i
                                            )
                                          },
                                        },
                                        model: {
                                          value: m.type,
                                          callback: function ($$v) {
                                            _vm.$set(m, "type", $$v)
                                          },
                                          expression: "m.type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("不设置")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [
                                            _vm._v(
                                              "设置全部" + _vm._s(m.keyName)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 2 } },
                                          [
                                            _vm._v(
                                              "设置指定" + _vm._s(m.keyName)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    m.type == 2
                                      ? [
                                          _c("PopoverTable", {
                                            attrs: { "show-data": m.data },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "ml10",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeSendTypeAction(
                                                    2,
                                                    i
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "选择" +
                                                  _vm._s(m.keyName) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveAction("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认提交")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.changeType == 2
        ? [
            _c("Product", {
              ref: "product",
              attrs: { "current-data": _vm.goods_list[0].data },
              on: { selectMessage: _vm.selectMessageNew },
            }),
            _vm._v(" "),
            _c("Auction", {
              ref: "auction",
              attrs: { "current-data": _vm.goods_list[7].data },
              on: { selectMessage: _vm.selectMessageNew },
            }),
            _vm._v(" "),
            _c("Nft", {
              ref: "product_nft",
              attrs: { "current-data": _vm.goods_list[8].data },
              on: { selectMessage: _vm.selectMessageNew },
            }),
            _vm._v(" "),
            _c("Nftblind", {
              ref: "nft_blind",
              attrs: { "current-data": _vm.goods_list[9].data },
              on: { selectMessage: _vm.selectMessageNew },
            }),
            _vm._v(" "),
            _c("BlindBox", {
              ref: "product_blind",
              attrs: { "current-data": _vm.goods_list[10].data },
              on: { selectMessage: _vm.selectMessageNew },
            }),
            _vm._v(" "),
            _c("Ticket", {
              ref: "ticket",
              attrs: { "current-data": _vm.goods_list[11].data },
              on: { selectMessage: _vm.selectMessageNew },
            }),
          ]
        : [
            _c("Product", {
              ref: "product",
              attrs: { "current-data": _vm.use_range[0].data },
              on: { selectMessage: _vm.selectMessage },
            }),
            _vm._v(" "),
            _c("Auction", {
              ref: "auction",
              attrs: { "current-data": _vm.use_range[7].data },
              on: { selectMessage: _vm.selectMessage },
            }),
            _vm._v(" "),
            _c("Nft", {
              ref: "product_nft",
              attrs: { "current-data": _vm.use_range[8].data },
              on: { selectMessage: _vm.selectMessage },
            }),
            _vm._v(" "),
            _c("Nftblind", {
              ref: "nft_blind",
              attrs: { "current-data": _vm.use_range[9].data },
              on: { selectMessage: _vm.selectMessage },
            }),
            _vm._v(" "),
            _c("BlindBox", {
              ref: "product_blind",
              attrs: { "current-data": _vm.use_range[10].data },
              on: { selectMessage: _vm.selectMessage },
            }),
            _vm._v(" "),
            _c("Ticket", {
              ref: "ticket",
              attrs: { "current-data": _vm.use_range[11].data },
              on: { selectMessage: _vm.selectMessage },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }