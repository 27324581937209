var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("创建群发任务")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20", staticStyle: { height: "calc(100vh - 180px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                height: "100%",
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "任务名称", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.title) + " ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发送内容", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.content) + " ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发送人数", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.send_user_number) + " ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.send_status == 0
                          ? _c("span", [_vm._v("未发送")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.send_status == 1
                          ? _c("span", [_vm._v("已发送")])
                          : _vm._e(),
                        _vm._v(" "),
                        row.send_status == 2
                          ? _c("span", [_vm._v("发送失败")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.create_time) + " ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发送时间", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.push_time_format) + " ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.onUpdate(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.delAction(row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: { "current-change": _vm.getList },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创建APP消息推送",
            visible: _vm.newDeliverDetail.show,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.newDeliverDetail, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.newDeliverDetail,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "任务名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入任务名称" },
                    model: {
                      value: _vm.newDeliverDetail.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "title", $$v)
                      },
                      expression: "newDeliverDetail.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送对象" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeTypeAction },
                      model: {
                        value: _vm.newDeliverDetail.send_user_object,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.newDeliverDetail,
                            "send_user_object",
                            $$v
                          )
                        },
                        expression: "newDeliverDetail.send_user_object",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("全部用户"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "4" } }, [
                        _vm._v("选择用户"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.newDeliverDetail.send_user_object == "4"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", multiple: "" },
                              on: { change: _vm.changeUserGroupAction },
                              model: {
                                value: _vm.newDeliverDetail.send_user_group,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newDeliverDetail,
                                    "send_user_group",
                                    $$v
                                  )
                                },
                                expression: "newDeliverDetail.send_user_group",
                              },
                            },
                            _vm._l(_vm.userGroupList, function (m, i) {
                              return _c(
                                "el-option",
                                {
                                  key: i,
                                  attrs: {
                                    value: m.group_id,
                                    label: m.group_name,
                                  },
                                },
                                [_vm._v(_vm._s(m.group_name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [_vm._v("已选" + _vm._s(_vm.userNumber) + "人")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送时间", prop: "push_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      "value-format": "timestamp",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.newDeliverDetail.push_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "push_time", $$v)
                      },
                      expression: "newDeliverDetail.push_time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "链接地址", prop: "jump_link" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.newDeliverDetail.jump_link) +
                      "\n          "
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "mini" },
                      on: { click: _vm.onLink },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.newDeliverDetail.jump_link ? "修改" : "设置")
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送内容", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      placeholder: "输入发送内容",
                      rows: "4",
                    },
                    model: {
                      value: _vm.newDeliverDetail.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.newDeliverDetail, "content", $$v)
                      },
                      expression: "newDeliverDetail.content",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.newDeliverDetail.show = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCategory", {
        ref: "setCategoryView",
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }