"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'List',
  data: function data() {
    return {
      tableData: {
        page: 1,
        limit: 20,
        data: [],
        total: 0
      },
      loading: false
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _system.groupLst)(this.tableData.page, this.tableData.limit).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.loading = false;
      }).catch(function (res) {
        _this.loading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableData.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableData.limit = val;
      this.getList();
    },
    // 添加
    onAdd: function onAdd() {
      var _this2 = this;
      this.$modalForm((0, _system.createGroupTable)()).then(function () {
        return _this2.getLst();
      });
    },
    // 编辑
    onEdit: function onEdit(id) {
      var _this3 = this;
      this.$modalForm((0, _system.updateGroupTable)(id)).then(function () {
        return _this3.getLst();
      });
    },
    // 组合数据列表
    goList: function goList(id) {
      this.$router.push("/group/data/".concat(id));
    }
  }
};