import { render, staticRenderFns } from "./goodsDetail.vue?vue&type=template&id=d2d29588&scoped=true"
import script from "./goodsDetail.vue?vue&type=script&lang=js"
export * from "./goodsDetail.vue?vue&type=script&lang=js"
import style0 from "./goodsDetail.vue?vue&type=style&index=0&id=d2d29588&lang=scss&scoped=true"
import style1 from "./goodsDetail.vue?vue&type=style&index=1&id=d2d29588&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2d29588",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/merchant_UEas/admin_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d2d29588')) {
      api.createRecord('d2d29588', component.options)
    } else {
      api.reload('d2d29588', component.options)
    }
    module.hot.accept("./goodsDetail.vue?vue&type=template&id=d2d29588&scoped=true", function () {
      api.rerender('d2d29588', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/basic/goodsDetail.vue"
export default component.exports