var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            size: "60%",
            visible: _vm.albumVisitData.show,
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.albumVisitData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "drawer-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm.isProduct
                ? _c("span", [
                    _vm._v(
                      "共" +
                        _vm._s(_vm.albumVisitData.uv) +
                        "人观看过本场" +
                        _vm._s(_vm.albumVisitData.num) +
                        "次拍品"
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      "共" +
                        _vm._s(_vm.albumVisitData.uv) +
                        "人观看过" +
                        _vm._s(_vm.albumVisitData.num) +
                        "次"
                    ),
                  ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "record-content" }, [
            _c(
              "div",
              { staticClass: "table-view" },
              [
                _c(
                  "el-table",
                  {
                    staticClass: "color-black",
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      data: _vm.albumVisitData.list,
                      "element-loading-text": "Loading",
                      fit: "",
                      height: "90%",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("template", { slot: "empty" }, [
                      _c("div", { staticClass: "tableEmpty" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "tableDiv" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "用户信息", "min-width": "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "flex" }, [
                                _c("div", { staticClass: "avatar-img" }, [
                                  _c("img", {
                                    attrs: { src: row.user.avatar, alt: "" },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex user-info flex-one ml10",
                                    staticStyle: {
                                      overflow: "hidden",
                                      cursor: "pointer",
                                    },
                                    style: {
                                      color:
                                        row.user.uid == 8888 ? "" : "#46a6ff",
                                      cursor:
                                        row.user.uid == 8888 ? "" : "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(row.user.uid)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          row.user.real_name ||
                                            row.user.nickname
                                        ) + " "
                                      ),
                                      row.user.real_name
                                        ? _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(row.user.nickname) +
                                                ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                      },
                                      [_vm._v("UID:" + _vm._s(row.user.uid))]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "查看次数",
                        "min-width": "80",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", { staticClass: "color-gray" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      Number(row.think_count)
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "拍品信息", width: "200" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.auction && row.product
                                ? _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "avatar-img" }, [
                                        _c("img", {
                                          attrs: {
                                            src: row.product.image + "!120a",
                                            alt: "",
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "user-info flex-one ml10",
                                          staticStyle: { overflow: "hidden" },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "LOT" +
                                                _vm._s(row.auction.lot) +
                                                " " +
                                                _vm._s(row.product.store_name)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "最后浏览时间",
                        "min-width": "120",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("div", [_vm._v(_vm._s(row.max_create_time))]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt20 flex justify-e" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-size": _vm.query.limit,
                        "current-page": _vm.query.page,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.otherTotal,
                      },
                      on: { "current-change": _vm.pageOtherChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }