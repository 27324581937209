var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", [
        _c(
          "div",
          {
            staticClass: "all-height item-flex-center",
            staticStyle: { "text-align": "center" },
          },
          [
            _c("img", {
              staticStyle: { width: "392px", height: "332px" },
              attrs: {
                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024618/image/1718692109484814069.png",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "nolimit-title" }, [
              _vm._v("暂无操作权限，请联系管理员"),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }