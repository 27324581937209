var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "鉴定管理" } }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": _vm.activeMenu,
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("图文")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("专家")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [
                _vm._v("数据报表"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [
                _vm._v("分类设置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [
                _vm._v("基础配置"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "6" } }, [_vm._v("投诉")]),
              _vm._v(" "),
              _c(
                "el-menu-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "color-green create-btn",
                      attrs: { size: "small" },
                      on: { click: _vm.createdAction },
                    },
                    [_vm._v("创建鉴定分类")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "default-box m0",
            },
            [
              _c("div", { staticClass: "category" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("鉴定分类")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "c" }, [_vm._v("分类图片")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "c1" }, [_vm._v("回答标签")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "r" }, [_vm._v("操作")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  _vm._l(_vm.resultData, function (item, index) {
                    return _c("div", { key: item.id, staticClass: "allList" }, [
                      _c("div", { staticClass: "list" }, [
                        _c("div", { staticClass: "l" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(item.id) +
                                " | " +
                                _vm._s(item.category_name)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "c" }, [
                          item.category_img
                            ? _c(
                                "div",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkUrl({
                                        response: { url: item.category_img },
                                      })
                                    },
                                  },
                                },
                                [
                                  item.category_img
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "40px",
                                          height: "40px",
                                        },
                                        attrs: {
                                          src: item.category_img + "!120a",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "c1" }, [
                          item.category_level && item.category_level.length > 0
                            ? _c(
                                "div",
                                _vm._l(item.category_level, function (m, i) {
                                  return _c("span", { key: i }, [
                                    _vm._v(
                                      _vm._s(i > 0 ? "、" : "") +
                                        _vm._s(m.level_name)
                                    ),
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "r" },
                          [
                            index !== 0
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.descAction($event, index)
                                      },
                                    },
                                  },
                                  [_vm._v("上移")]
                                )
                              : _vm._e(),
                            index !== 0 ? _c("span", [_vm._v("|")]) : _vm._e(),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction($event, index)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c("span", [_vm._v("|")]),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editLevelAction($event, index)
                                  },
                                },
                              },
                              [_vm._v("管理标签")]
                            ),
                            _c("span", [_vm._v("|")]),
                            _c("el-popconfirm", {
                              attrs: { title: "确定删除吗？" },
                              on: {
                                onConfirm: function ($event) {
                                  return _vm.removeAction($event, index)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [_c("a", [_vm._v("删除")])]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "auction-modal",
              attrs: {
                visible: _vm.showDetail,
                title: _vm.categoryId ? "编辑分类" : "新增分类",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDetail = $event
                },
                close: _vm.closeDetail,
              },
            },
            [
              _c(
                "div",
                { staticClass: "item-align-center" },
                [
                  _c("span", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("分类名称："),
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "w200",
                    model: {
                      value: _vm.categoryName,
                      callback: function ($$v) {
                        _vm.categoryName = $$v
                      },
                      expression: "categoryName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center mt20 h50 guanggao" },
                [
                  _c("span", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("分类图片："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "ios-cloud-upload-outline" },
                      on: { click: _vm.onUpload },
                    },
                    [_vm._v("上传图片")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.uploadImg,
                          expression: "uploadImg",
                        },
                      ],
                      staticClass: "upload-list",
                    },
                    [
                      _c("img", { attrs: { src: _vm.uploadImg } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "upload-list-cover" }, [
                        _c("i", {
                          staticClass: "el-icon-view",
                          attrs: { size: "20" },
                          on: { click: _vm.handleView },
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          attrs: { size: "20" },
                          on: { click: _vm.handleRemoveNew },
                        }),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c("el-button", { on: { click: _vm.closeDetail } }, [
                    _vm._v("取消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveDetail },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "auction-modal",
              attrs: {
                visible: _vm.showEditLevelDetail,
                title: "编辑标签",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showEditLevelDetail = $event
                },
                "on-cancel": _vm.closeEditLevelDetail,
              },
            },
            [
              _c("div", { staticClass: "item-align-center" }, [
                _c(
                  "span",
                  {
                    staticClass: "mt20",
                    staticStyle: { "margin-right": "20px" },
                  },
                  [_vm._v("分类标签：")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  _vm._l(_vm.categoryLevel, function (m, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "mt20 item-align-center" },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: { placeholder: "输入标签名称" },
                          model: {
                            value: m.level_name,
                            callback: function ($$v) {
                              _vm.$set(m, "level_name", $$v)
                            },
                            expression: "m.level_name",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "ml20" }, [
                          i > 0
                            ? _c(
                                "a",
                                {
                                  staticClass: "mr10 color-b",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUpAction(i)
                                    },
                                  },
                                },
                                [_vm._v("上移")]
                              )
                            : _vm._e(),
                          i == 0
                            ? _c(
                                "a",
                                {
                                  staticClass: "mr10 color-b",
                                  on: { click: _vm.createLevel },
                                },
                                [_vm._v("新增")]
                              )
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass: "color-b",
                              on: {
                                click: function ($event) {
                                  return _vm.delLevel(i)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c("el-button", { on: { click: _vm.closeEditLevelDetail } }, [
                    _vm._v("取消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveLevelDetail },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { title: "查看图片", visible: _vm.visible },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
              },
            },
            [
              _vm.visible
                ? _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.uploadImg },
                  })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }