var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt20" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("服务费")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("div", [_vm._v("每笔交易收取卖家交易额")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "w80 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.service_charge,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "service_charge", $$v)
                },
                expression: "ruleData.service_charge",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("%技术服务费")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("作品信息展示")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "el-checkbox-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.ruleData.work_info_show,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "work_info_show", $$v)
                  },
                  expression: "ruleData.work_info_show",
                },
              },
              [
                _c("el-checkbox", { attrs: { label: "1" } }, [
                  _vm._v("当前归属"),
                ]),
                _vm._v(" "),
                _c("el-checkbox", { attrs: { label: "2" } }, [
                  _vm._v("生成时间"),
                ]),
                _vm._v(" "),
                _c("el-checkbox", { attrs: { label: "3" } }, [
                  _vm._v("哈希值"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("寄售记录")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.ruleData.consign_record,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "consign_record", $$v)
                  },
                  expression: "ruleData.consign_record",
                },
              },
              [
                _c(
                  "el-radio",
                  { attrs: { label: 1 } },
                  [
                    _vm._v("自定义用户名 "),
                    _c("el-input", {
                      staticClass: "w80 ml10 mr10",
                      attrs: { placeholder: "用户名", size: "normal" },
                      model: {
                        value: _vm.ruleData.consign_log_customize_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleData,
                            "consign_log_customize_name",
                            $$v
                          )
                        },
                        expression: "ruleData.consign_log_customize_name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("正常展示")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 3 } }, [_vm._v("不展示")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("列表统计数字格式")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.ruleData.number_display,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "number_display", $$v)
                  },
                  expression: "ruleData.number_display",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("共2件寄售")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 2 } }, [
                  _vm._v("流通/持仓：2/80"),
                ]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 3 } }, [_vm._v("不展示")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("首页标签展示")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "el-checkbox-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.ruleData.home_tab_show,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "home_tab_show", $$v)
                  },
                  expression: "ruleData.home_tab_show",
                },
              },
              [
                _c("el-checkbox", { attrs: { label: "1", disabled: "" } }, [
                  _vm._v("藏品"),
                ]),
                _vm._v(" "),
                _c("el-checkbox", { attrs: { label: "2" } }, [_vm._v("盲盒")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("寄售冷却时间")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c("div", [_vm._v("取消寄售")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "w80 ml10 mr10",
              attrs: { controls: false, min: 0 },
              model: {
                value: _vm.ruleData.consign_ban_time,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "consign_ban_time", $$v)
                },
                expression: "ruleData.consign_ban_time",
              },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("分钟后可再次上架")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item flex align-items-c h50" }, [
        _c("div", { staticClass: "flex-one" }, [_vm._v("我的交易详情")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex align-items-c" },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "small" },
                model: {
                  value: _vm.ruleData.my_trade_info,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "my_trade_info", $$v)
                  },
                  expression: "ruleData.my_trade_info",
                },
              },
              [
                _c("el-radio", { attrs: { label: 2 } }, [
                  _vm._v("不展示购买对象"),
                ]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("正常展示")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt20" },
      [
        _c(
          "el-button",
          {
            staticStyle: { width: "100%" },
            attrs: { type: "primary" },
            on: { click: _vm.saveAction },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-items-c mb10" }, [
      _c("div", { staticClass: "flex-one t ft14 fWeight500" }, [
        _vm._v("交易规则配置"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ft14 fWeight500" }, [_vm._v("操作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }