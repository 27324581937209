var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              attrs: { size: "small", "label-width": "0px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: " " } },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "" + _vm.roterPre + "/product/discountCreate",
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("创建折扣活动")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "活动名称", "min-width": "120" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品范围", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.type == 2
                          ? _c("span", [_vm._v("全部")])
                          : row.type == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "default" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showGoods(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.product.length) + "件")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 10
                          ? _c("span", { staticStyle: { color: "#ee4c4c" } }, [
                              _vm._v("待开始"),
                            ])
                          : scope.row.status == 20
                          ? _c("span", { staticStyle: { color: "#5db741" } }, [
                              _vm._v("进行中"),
                            ])
                          : _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("已结束"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "折扣", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.discount) + "折 ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动时间", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v("开始：" + _vm._s(scope.row.start_time)),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("结束：" + _vm._s(scope.row.end_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status < 30
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.stopAction(
                                        scope.row.id,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("关闭活动")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 10
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAction(scope.row.id)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: { "icon-class": "commonedit" },
                                  }),
                                  _vm._v("编辑"),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status == 10
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(
                                        scope.row.id,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                staticClass: "mt20",
                staticStyle: { "text-align": "right" },
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "商品", visible: _vm.showGoodsFlag, width: "800px" },
          on: {
            "update:visible": function ($event) {
              _vm.showGoodsFlag = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.showGoodsTableData.data } },
            [
              _c("el-table-column", {
                attrs: { label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "store_name" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格", prop: "price" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.stock == 0
                          ? _c("div", [_vm._v("已售罄")])
                          : scope.row.is_show == 1
                          ? _c("div", [_vm._v("销售中")])
                          : _c("div", [_vm._v("下架")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "库存", prop: "stock" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                staticClass: "mt20",
                staticStyle: { "text-align": "right" },
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80],
                  "page-size": _vm.showGoodsTableForm.limit,
                  "current-page": _vm.showGoodsTableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.showGoodsTableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeNewChange,
                  "current-change": _vm.pageNewChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }