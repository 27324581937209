"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      configjson: {
        pay_wechat: '',
        pay_lianlian: ''
      }
    };
  },
  methods: {
    getConfigClassKeys: function getConfigClassKeys() {
      var _this = this;
      (0, _system.getConfigClassKeys)('pay_config').then(function (res) {
        _this.configjson = res.data;
      });
    },
    configClassKeysSave: function configClassKeysSave() {
      var _this2 = this;
      this.configClassKeysSaveLoading = true;
      (0, _system.configClassKeysSave)('pay_config', this.configjson).then(function (res) {
        _this2.configClassKeysSaveLoading = false;
        _this2.$message.success('保存成功');
      }).catch(function (err) {
        _this2.configClassKeysSaveLoading = false;
        _this2.$message.error(err.message);
      });
    }
  },
  mounted: function mounted() {
    this.getConfigClassKeys();
  }
};