var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("div", { staticClass: "top-view" }, [
        _c(
          "div",
          { staticStyle: { "margin-top": "-10px" } },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-demo default-tabs",
                attrs: { "default-active": _vm.changeType, mode: "horizontal" },
                on: { select: _vm.changeTypeAction },
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [
                  _vm._v("助力有奖"),
                ]),
                _vm._v(" "),
                _c("el-menu-item", { attrs: { index: "2" } }, [
                  _vm._v("公众号拉新"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.createdAction } },
              [_vm._v("创建公众号粉丝裂变活动")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "fission_activity_id",
                  label: "ID",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "title", label: "活动名称", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              scope.row.activity_status == 1
                                ? "进行中"
                                : "已关闭"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发起人数", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showReceiveDataAction(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(scope.row.openTaskNum)
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "助力人数", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.formatPriceNumber(scope.row.inviteNum))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "任务完成", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.formatPriceNumber(scope.row.prizeNum || 0)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "成功领奖", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showReceiveDataAction(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(
                                  scope.row.prizeReceivedNum
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "奖品", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "right",
                              width: "500",
                              trigger: "hover",
                            },
                          },
                          [
                            scope.row.prize && scope.row.prize.length
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          color: "#000",
                                        },
                                        attrs: {
                                          data: scope.row.prize,
                                          "highlight-current-row": "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: { label: "奖品类型" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    params.row.prize_type ==
                                                    "product"
                                                      ? _c("div", [
                                                          _vm._v("商品"),
                                                        ])
                                                      : params.row.prize_type ==
                                                        "product_nft"
                                                      ? _c("div", [
                                                          _vm._v("数字藏品"),
                                                        ])
                                                      : params.row.prize_type ==
                                                        "nft_blind_box"
                                                      ? _c("div", [
                                                          _vm._v("数字盲盒"),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "奖品名称",
                                            "min-width": "200",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "num" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            params.row
                                                              .prize_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "任务人数",
                                            "min-width": "80",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "num" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            params.row
                                                              .help_count
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "奖品数量",
                                            "min-width": "80",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (params) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "num" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            params.row.prize_num
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  size: "mini",
                                },
                                slot: "reference",
                              },
                              [_vm._v("查看奖品")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(
                                      scope.row.fission_activity_id
                                    )
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "commonedit" },
                                }),
                                _vm._v("编辑"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.activity_status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "ft12 p5-0",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editStatusAction(
                                          scope.row.fission_activity_id,
                                          0
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("关闭")]
                                )
                              : scope.row.activity_status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "ft12 p5-0",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editStatusAction(
                                          scope.row.fission_activity_id,
                                          1
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("开启")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "70%",
            visible: _vm.receiveData.show,
            "append-to-body": "",
            title: "领奖记录",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.receiveData, "show", $event)
            },
          },
        },
        [
          _vm.receiveData.data
            ? _c("div", { staticClass: "receive-data flex align-items-c" }, [
                _c("div", { staticClass: "flex align-items-c flex-one" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("发起人数：")]),
                    _c("span", { staticStyle: { color: "#28ab29" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.receiveData.data.openTaskNum
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("助力人数：")]),
                    _c("span", { staticStyle: { color: "#28ab29" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(_vm.receiveData.data.inviteNum)
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("完成人数：")]),
                    _c("span", { staticStyle: { color: "#28ab29" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.receiveData.data.prizeNum || 0
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("领奖人数：")]),
                    _c("span", { staticStyle: { color: "#28ab29" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatPriceNumber(
                            _vm.receiveData.data.prizeReceivedNum
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex align-items-c" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.exportOrder },
                      },
                      [_vm._v("导出")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.getExportFileList },
                      },
                      [_vm._v("导出已生成列表")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "receive-list mt20" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.receiveData.listLoading,
                      expression: "receiveData.listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.receiveData.list,
                    size: "small",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "fission_activity_id",
                      label: "头像",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.user_info && scope.row.user_info.avatar
                              ? _c("div", [
                                  _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                    },
                                    attrs: {
                                      src: scope.row.user_info.avatar,
                                      alt: "",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "用户标识",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.open_id) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "昵称", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.user_info && scope.row.user_info.uid
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.user_info.nickname) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "参与时间", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "助力人数", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(scope.row.invite_num)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "任务完成", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    scope.row.prize_count || 0
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "已领取奖品", "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.prize_received &&
                            scope.row.prize_received.length
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      width: "400",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-table",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              color: "#000",
                                            },
                                            attrs: {
                                              data: scope.row.prize_received,
                                              "highlight-current-row": "",
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: { label: "奖品类型" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (params) {
                                                      return [
                                                        params.row.prize
                                                          .prize_type ==
                                                        "product"
                                                          ? _c("div", [
                                                              _vm._v("商品"),
                                                            ])
                                                          : params.row.prize
                                                              .prize_type ==
                                                            "product_nft"
                                                          ? _c("div", [
                                                              _vm._v(
                                                                "数字藏品"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "奖品名称",
                                                "min-width": "200",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (params) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "num",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                params.row.prize
                                                                  .prize_name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("查看已领取奖品")]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v("无")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "待领取奖品", "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.prize_unreceived &&
                            scope.row.prize_unreceived.length
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      width: "400",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-table",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              color: "#000",
                                            },
                                            attrs: {
                                              data: scope.row.prize_unreceived,
                                              "highlight-current-row": "",
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: { label: "奖品类型" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (params) {
                                                      return [
                                                        params.row.prize
                                                          .prize_type ==
                                                        "product"
                                                          ? _c("div", [
                                                              _vm._v("商品"),
                                                            ])
                                                          : params.row.prize
                                                              .prize_type ==
                                                            "product_nft"
                                                          ? _c("div", [
                                                              _vm._v(
                                                                "数字藏品"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "奖品名称",
                                                "min-width": "200",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (params) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "num",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                params.row.prize
                                                                  .prize_name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("查看待领取奖品")]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v("无")]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "block mt20",
                  staticStyle: { "text-align": "right" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.receiveData.query.limit,
                      "current-page": _vm.receiveData.query.page,
                      total: _vm.receiveData.total,
                    },
                    on: { "current-change": _vm.pageChangeNew },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }