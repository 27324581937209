var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "banner"
        ? [
            _c(
              "div",
              {
                staticClass: "banner-img",
                style: {
                  marginTop: _vm.facade.marginTop / 2 + "px",
                  background: _vm.facade.background,
                  width: _vm.facade.view_width / 2 + "px",
                  height: _vm.facade.view_height / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "img",
                    style: {
                      width: _vm.facade.img_width / 2 + "px",
                      height: _vm.facade.img_height / 2 + "px",
                      borderRadius:
                        _vm.facade.img_top_radius / 2 +
                        "px " +
                        _vm.facade.img_top_radius / 2 +
                        "px " +
                        _vm.facade.img_bottom_radius / 2 +
                        "px " +
                        _vm.facade.img_bottom_radius / 2 +
                        "px",
                      background: "none",
                    },
                  },
                  [
                    _c("el-image", {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        display: "block",
                      },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                        fit: _vm.facade.img_style === 2 ? "contain" : "cover",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pointer", {
                  attrs: {
                    color: _vm.facade.indicator_color,
                    type: _vm.facade.indicator_style,
                    align: _vm.facade.indicator_align,
                    "v-align": _vm.facade.indicator_vertical_align,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm.content.type === "price"
        ? [
            _c(
              "div",
              {
                staticClass: "flex align-items-c justify-b",
                style: {
                  height: _vm.facade.height / 2 + "px",
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                  fontSize: _vm.facade.fontSize / 2 + "px",
                  boxSizing: "content-box",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "price-view ft14",
                    style: { color: _vm.facade.color },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "price",
                        style: { color: _vm.facade.priceColor },
                      },
                      [
                        _vm._v("￥"),
                        _c(
                          "span",
                          {
                            style: {
                              fontSize: _vm.facade.priceFontSize / 2 + "px",
                              fontWeight: 500,
                            },
                          },
                          [_vm._v("2,500")]
                        ),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "margin-left": "-4px" } }, [
                          _vm._v(".00"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "old-price",
                            style: {
                              color: _vm.facade.oldPriceColor,
                              fontWeight: _vm.facade.fontWeight,
                            },
                          },
                          [_vm._v("￥2,500.00")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "r-jd-btn flex align-items-c justify-c",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20220512/eff469f91d74e2e451bf62e5ad6eb85f.png",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("分享")]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm._v(
                      "\n        正宗印度迈索尔老山檀香木黑肉沉水手串108颗佛珠手链男女款文玩\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "desc"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c(
                  "div",
                  {
                    class: { line1: _vm.facade.fold },
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      fontSize: _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [_vm._v("\n        仅售三天，越南直供，急速发货\n      ")]
                ),
              ]
            ),
          ]
        : _vm.content.type === "coupon"
        ? [
            _c(
              "div",
              {
                staticClass: "coupon flex align-items-c",
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                  color: _vm.facade.color,
                  fontWeight: _vm.facade.fontWeight,
                  fontSize: _vm.facade.fontSize / 2 + "px",
                },
              },
              [
                _c("div", { staticClass: "flex align-items-c flex-one" }, [
                  _c("img", { attrs: { src: _vm.facade.couponImg, alt: "" } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml10" }, [_vm._v("满500减30")]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "ml20",
                    attrs: { src: _vm.facade.couponImg, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml10" }, [_vm._v("9折券")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex align-items-c" }, [
                  _c("div", [_vm._v("领券")]),
                  _vm._v(" "),
                  _c("img", { attrs: { src: _vm.facade.receiveImg, alt: "" } }),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "detail"
        ? [
            _c(
              "div",
              {
                style: {
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.background,
                },
              },
              [
                _c("div", { staticClass: "desc" }, [
                  _c(
                    "div",
                    {
                      staticClass: "content",
                      style: {
                        fontSize: _vm.facade.fontSize / 2 + "px",
                        color: _vm.facade.color,
                        lineHeight: _vm.facade.lineHeight / 2 + "px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "params flex align-items-c",
                          style: "border-color: " + _vm.facade.borColor,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              style: { fontWeight: _vm.facade.fontWeight },
                            },
                            [_vm._v("运费")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "r flex align-items-c justify-b flex-one",
                              style: { color: _vm.facade.otherColor },
                            },
                            [
                              _c("div", [_vm._v("包邮")]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "span",
                                  { style: { color: _vm.facade.color } },
                                  [_vm._v("销量")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml5",
                                    style: { color: _vm.facade.otherColor },
                                  },
                                  [_vm._v("542")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "params flex align-items-c",
                          style: "border-color: " + _vm.facade.borColor,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              style: { fontWeight: _vm.facade.fontWeight },
                            },
                            [_vm._v("选择")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "c flex-one",
                              style: { color: _vm.facade.otherColor },
                            },
                            [
                              _vm._v(
                                "\n              已选：26寸,林中木\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "r flex align-items-c",
                              style: { color: _vm.facade.otherColor },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20220513/42aec475f61d7622bbd633aa29dd831c.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "params flex align-items-c",
                          style: "border-color: " + _vm.facade.borColor,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "l",
                              style: { fontWeight: _vm.facade.fontWeight },
                            },
                            [_vm._v("服务")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "r",
                              style: { color: _vm.facade.otherColor },
                            },
                            [
                              _c("span", [_vm._v("7天无理由")]),
                              _c("span", { staticClass: "ml10" }, [
                                _vm._v("30天包退"),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm.content.type === "message"
        ? [
            _c(
              "div",
              {
                style: {
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab flex align-items-c",
                    style: {
                      fontSize: _vm.facade.tabFontSize / 2 + "px",
                      color: _vm.facade.tabColor,
                      fontWeight: _vm.facade.tabFontWeight,
                      lineHeight: _vm.facade.tabLineHeight / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        style: { color: _vm.facade.tabCurrColor },
                      },
                      [
                        _vm._v("详情"),
                        _c("span", {
                          staticClass: "bg",
                          style: { background: _vm.facade.tabCurrColor },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "item" }, [_vm._v("推荐")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    style: {
                      padding: "10px " + _vm.pageInfo.page_margin / 2 + "px",
                      fontSize: _vm.facade.attrFontSize / 2 + "px",
                      color: _vm.facade.attrColor,
                      lineHeight: _vm.facade.attrLineHeight / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-c",
                        style: "border-color: " + _vm.facade.attrBorColor + ";",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("材质")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r",
                            style: { color: _vm.facade.attrOtherColor },
                          },
                          [_vm._v("纸质")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "params flex align-items-c",
                        style: "border-color: " + _vm.facade.attrBorColor + ";",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "l",
                            style: { fontWeight: _vm.facade.attrFontWeight },
                          },
                          [_vm._v("尺寸")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "r",
                            style: { color: _vm.facade.attrOtherColor },
                          },
                          [_vm._v("100cm × 200cm")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content",
                    style: {
                      padding: "10px " + _vm.pageInfo.page_margin / 2 + "px",
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      lineHeight: _vm.facade.lineHeight / 2 + "px",
                    },
                  },
                  [_vm._m(0), _vm._v(" "), _vm._m(1)]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "message-bottom flex align-items-c",
                    style: {
                      padding:
                        "0 " +
                        _vm.pageInfo.page_margin / 2 +
                        "px 20px " +
                        _vm.pageInfo.page_margin / 2 +
                        "px",
                    },
                  },
                  [_vm._m(2), _vm._v(" "), _vm._m(3)]
                ),
              ]
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "div",
              {
                style: {
                  background: _vm.facade.background,
                  marginTop: _vm.facade.marginTop / 2 + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "about-goods item-flex-center",
                    style: {
                      padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                      height: _vm.facade.lineHeight / 2 + "px",
                    },
                  },
                  [
                    _c("div", { staticClass: "h" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "d" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "c" }, [_vm._v("相关商品推荐")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "h" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "list" },
                  [
                    _c("productList", {
                      staticStyle: { "margin-top": "-30px" },
                      attrs: { facade: _vm.facade, content: _vm.content },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "l flex-one flex align-items-c" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticStyle: { left: "-10px" },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticStyle: { left: "-20px" },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticStyle: { left: "-30px" },
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png!120a",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "r flex" }, [
      _c("img", {
        attrs: {
          src: "https://saas.cdn.yunzongbu.cn/image/20220513/1c8638536e4b9ced86ff133782c4bf03.png",
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("div", [_vm._v("2524")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }