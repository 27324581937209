"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _accounts = require("@/api/accounts");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: null,
      listLoading: true,
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      rechargeList: [],
      showPay: false,
      orderId: '',
      payImg: '',
      payCount: '',
      payPrice: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _accounts.getSmsPacketList)().then(function (res) {
      res.data.forEach(function (m) {
        m.btnLoading = false;
      });
      _this.rechargeList = res.data;
    }).catch(function () {});
    this.load();
  },
  methods: {
    load: function load() {
      var _this2 = this;
      this.listLoading = true;
      (0, _accounts.getSMSRechargeOrder)(this.queryParams).then(function (response) {
        _this2.total = response.data.count;
        _this2.list = response.data.list;
        _this2.listLoading = false;
      }).catch(function (message) {
        _this2.listLoading = false;
        _this2.list = [];
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    smsRechargeAction: function smsRechargeAction(rechargeId, count, price, i) {
      var _this3 = this;
      this.rechargeList[i].btnLoading = true;
      (0, _accounts.createSMSRechargeOrderApi)({
        id: rechargeId
      }).then(function (res) {
        _this3.rechargeList[i].btnLoading = false;
        _this3.payImg = res.data.image;
        _this3.payCount = count;
        _this3.payPrice = price;
        _this3.showPay = true;
      }).catch(function (message) {
        _this3.rechargeList[i].btnLoading = false;
      });
    },
    closePayAction: function closePayAction() {
      this.$emit('setInfo');
      this.load();
      this.showPay = false;
      this.orderId = '';
      this.payImg = '';
      this.payCount = '';
      this.payPrice = '';
    },
    buyEndAction: function buyEndAction() {
      this.$emit('setInfo');
      this.load();
      this.showPay = false;
      this.orderId = '';
      this.payImg = '';
      this.payCount = '';
      this.payPrice = '';
    }
  }
};