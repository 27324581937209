var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "mt20", staticStyle: { height: "calc(100vh - 230px)" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "table",
            attrs: { border: "", height: "100%", data: _vm.resultData },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("template", { slot: "empty" }, [
              _c("div", { staticClass: "tableEmpty" }, [
                _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-table-column", {
              key: "1",
              attrs: { type: "selection", width: "36", align: "center" },
            }),
            _vm._v(" "),
            _c("ytx-table-column", {
              attrs: {
                label: "内容名称",
                "min-width": "200",
                prop: "",
                "search-query": _vm.searchQuery,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(_vm._s(scope.row.address_name)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("ytx-table-column", {
              attrs: {
                label: "内容类型",
                width: "100",
                prop: "",
                "search-query": _vm.searchQuery,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(_vm._s(scope.row.address_name)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("ytx-table-column", {
              attrs: {
                label: "预展导览",
                width: "100",
                prop: "",
                "search-query": _vm.searchQuery,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(_vm._s(scope.row.address_name)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("ytx-table-column", {
              attrs: {
                label: "拍卖导览",
                width: "100",
                prop: "",
                "search-query": _vm.searchQuery,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(_vm._s(scope.row.address_name)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("ytx-table-column", {
              attrs: {
                label: "结拍战报",
                width: "100",
                prop: "",
                "search-query": _vm.searchQuery,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(_vm._s(scope.row.address_name)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("ytx-table-column", {
              attrs: {
                label: "小程序码",
                width: "100",
                prop: "",
                "search-query": _vm.searchQuery,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(_vm._s(scope.row.address_name)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("ytx-table-column", {
              attrs: {
                label: "小程序链接",
                width: "100",
                prop: "",
                "search-query": _vm.searchQuery,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "nowrap" }, [
                        _vm._v(_vm._s(scope.row.address_name)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex justify-b orderFoot" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            "page-sizes": [20, 40, 60, 80],
            "page-size": _vm.tableFrom.limit,
            "current-page": _vm.tableFrom.page,
            layout: "total, prev, pager, next, sizes",
            total: _vm.total,
            disabled: _vm.listLoading,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }