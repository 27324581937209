var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "关联名单：", prop: "cate" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                placeholder: "请选择快照名单",
                              },
                              on: {
                                change: _vm.handleselectCate,
                                "remove-tag": _vm.remotetag,
                              },
                              model: {
                                value: _vm.formValidate.cate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "cate", $$v)
                                },
                                expression: "formValidate.cate",
                              },
                            },
                            _vm._l(_vm.merCateList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.title,
                                  value: item.snapshot_id,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(
                            "\n            总人数：" +
                              _vm._s(_vm.usercount) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "提前发售时间：",
                            prop: "ahead_time",
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticClass: "selwidth",
                            attrs: { step: 1 },
                            model: {
                              value: _vm.formValidate.ahead_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "ahead_time", $$v)
                              },
                              expression: "formValidate.ahead_time",
                            },
                          }),
                          _vm._v("\n            分钟\n          "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数字藏品：", required: "" } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "multipleSelection",
                              staticClass: "table",
                              staticStyle: {
                                width: "100%",
                                "margin-top": "-10px",
                              },
                              attrs: {
                                "tooltip-effect": "dark",
                                "row-key": function (row) {
                                  return row.order_id
                                },
                                data: _vm.orderArr,
                                size: "mini",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { width: "80", label: "图片" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            scope.row.image
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.image + "!120a",
                                                    "preview-src-list": [
                                                      scope.row.image,
                                                    ],
                                                  },
                                                })
                                              : scope.row.album_image
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.album_image +
                                                      "!120a",
                                                    "preview-src-list": [
                                                      scope.row.album_image,
                                                    ],
                                                  },
                                                })
                                              : _c("el-image", {
                                                  attrs: {
                                                    src:
                                                      scope.row.product.image +
                                                      "!120a",
                                                    "preview-src-list": [
                                                      scope.row.product.image,
                                                    ],
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "名称", "min-width": "100" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.title
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(scope.row.title) +
                                                  "\n                "
                                              ),
                                            ])
                                          : scope.row.album_name
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(scope.row.album_name) +
                                                  "\n                "
                                              ),
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.product.store_name
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "价格", "min-width": "100" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.sale_price
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.sale_price) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : scope.row.price
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.price) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : scope.row.product
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    scope.row.product.price
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "购买数量",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: { min: 0, step: 1 },
                                          model: {
                                            value: scope.row.buy_limit_num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "buy_limit_num",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.buy_limit_num",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "发售时间",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.sale_time
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.sale_time) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : scope.row.newstart_time
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    scope.row.newstart_time
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(scope.row.start_time) +
                                                  "\n                  "
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  "min-width": "80",
                                  fixed: "right",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _vm.orderidArr.indexOf(
                                              scope.row.nft_album_id
                                            ) > -1
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelSelectAlbum(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      删除\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm.orderidArr.indexOf(
                                                  scope.row.product_id
                                                ) > -1
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelSelect(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      删除\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("formValidate")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "30px" } }),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              attrs: { "before-leave": _vm.leavetab },
              on: { "tab-click": _vm.changetab },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "盲盒", name: "box", disabled: !!_vm.locktype },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  label: "数字藏品",
                  name: "goods",
                  disabled: !!_vm.locktype,
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  label: "数字藏品系列",
                  name: "album",
                  disabled: !!_vm.locktype,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tab == "box",
                  expression: "tab == 'box'",
                },
              ],
              staticClass: "mt20",
            },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "250px" },
                  attrs: { placeholder: "盲盒名称" },
                  model: {
                    value: _vm.newtableFrom.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.newtableFrom, "title", $$v)
                    },
                    expression: "newtableFrom.title",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getnewList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.newtableData.data,
                    size: "small",
                    "row-key": "box_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "box_id", label: "ID", "min-width": "60" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "80", label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "demo-image__preview" },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: scope.row.image + "!120a",
                                    "preview-src-list": [scope.row.image],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "盲盒名称",
                      prop: "title",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 1
                              ? _c("div", [
                                  _vm._v(
                                    "\n                  发售中\n              "
                                  ),
                                ])
                              : scope.row.status == -1
                              ? _c("div", [_vm._v("已下架")])
                              : _c("div", [_vm._v("未发售")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sale_time",
                      label: "发售时间",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "已售出/发售数量", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(scope.row.sold_number) +
                                  "/" +
                                  _vm._s(scope.row.total_number) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nft_product_count",
                      label: "数字藏品数量",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "100",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectinver(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.orderidArr.indexOf(
                                            scope.row.product_id
                                          ) > -1
                                            ? "已选择盲盒"
                                            : "选择盲盒"
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderidArr.indexOf(scope.row.product_id) > -1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelSelect(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              取消选择\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.newtableFrom.limit,
                      "current-page": _vm.newtableFrom.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.newtableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tab == "goods",
                  expression: "tab == 'goods'",
                },
              ],
              staticClass: "mt20",
            },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "250px" },
                  attrs: { placeholder: "请输入商品名称，关键字" },
                  model: {
                    value: _vm.queryParams.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "keyword", $$v)
                    },
                    expression: "queryParams.keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "order-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "作品信息", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "flex align-items-c" }, [
                              _c("div", { staticClass: "work-img" }, [
                                _c("img", {
                                  attrs: {
                                    src: row.product.image + "!120a",
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "user-info flex-one ml10",
                                  staticStyle: { overflow: "hidden" },
                                },
                                [
                                  _c("div", { staticClass: "fWeight500" }, [
                                    _vm._v(_vm._s(row.product.store_name)),
                                  ]),
                                  _vm._v(" "),
                                  row.original_hash
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex align-items-c color-gray",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyAction(
                                                row.original_hash
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                "" +
                                                  row.original_hash[0] +
                                                  row.original_hash[1] +
                                                  row.original_hash[2] +
                                                  "****" +
                                                  row.original_hash[
                                                    row.original_hash.length - 3
                                                  ] +
                                                  row.original_hash[
                                                    row.original_hash.length - 2
                                                  ] +
                                                  row.original_hash[
                                                    row.original_hash.length - 1
                                                  ]
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作品价格", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(row.product.price, 2)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发行/库存", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(row.issuer_num) +
                                  "/" +
                                  _vm._s(row.product.stock)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "文件类型", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(row.file_type == 1 ? "图片" : "3D")
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作品状态", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.status == 0
                              ? _c("div", { staticClass: "color-lv" }, [
                                  _vm._v("未发行"),
                                ])
                              : row.status == 1
                              ? _c("div", [_vm._v("已发行")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "创建成员", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [_vm._v(_vm._s(row.admin.real_name))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "时间", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v("创建：" + _vm._s(row.create_time)),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("销售：" + _vm._s(row.start_time)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "140" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.canselect
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectinver(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.orderidArr.indexOf(
                                            scope.row.product_id
                                          ) > -1
                                            ? "已选择藏品"
                                            : "选择藏品"
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderidArr.indexOf(scope.row.product_id) > -1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelSelect(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              取消选择\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 flex justify-e" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.queryParams.limit,
                      "current-page": _vm.queryParams.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: { "current-change": _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tab == "album",
                  expression: "tab == 'album'",
                },
              ],
              staticClass: "mt20",
            },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "250px" },
                  attrs: { placeholder: "请输入系列名称" },
                  model: {
                    value: _vm.albumqueryParams.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.albumqueryParams, "keyword", $$v)
                    },
                    expression: "albumqueryParams.keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.getAlbumList(1)
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "order-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.albumtableData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "系列信息", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "flex align-items-c" }, [
                              _c("div", { staticClass: "work-img" }, [
                                _c("img", {
                                  attrs: {
                                    src: row.album_image + "!120a",
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "user-info flex-one ml10",
                                  staticStyle: { overflow: "hidden" },
                                },
                                [
                                  _c("div", { staticClass: "fWeight500" }, [
                                    _vm._v("ID：" + _vm._s(row.nft_album_id)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "fWeight500" }, [
                                    _vm._v(_vm._s(row.album_name)),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "起售时间", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(_vm._s(_vm.parseTime(row.start_time))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "藏品数量", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.nft_num))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发行/库存", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(row.issuer_num) + "/" + _vm._s(row.stock)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "状态", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.album_status == 1
                              ? _c("div", { staticClass: "color-red" }, [
                                  _vm._v("未起售"),
                                ])
                              : row.album_status == 2
                              ? _c("div", { staticClass: "color-lv" }, [
                                  _vm._v("销售中"),
                                ])
                              : row.album_status == 3
                              ? _c("div", [_vm._v("已售罄")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "创建时间", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.create_time))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "160" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.canselect
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectalbum(
                                          scope.row,
                                          scope.$index,
                                          "album"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.orderidArr.indexOf(
                                            scope.row.nft_album_id
                                          ) > -1
                                            ? "已选择系列"
                                            : "选择系列"
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderidArr.indexOf(scope.row.nft_album_id) > -1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelSelectAlbum(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              取消选择\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 flex justify-e" },
                [
                  _c("div", { staticClass: "flex-one align-items-c flex" }),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                      "page-size": _vm.albumqueryParams.limit,
                      "current-page": _vm.albumqueryParams.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.albumtableData.total,
                    },
                    on: {
                      "size-change": _vm.handleAlbumSizeChange,
                      "current-change": _vm.getAlbumList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }