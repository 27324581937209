var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSelectAuction,
            width: "800px",
            "close-on-click-modal": false,
            top: "5vh",
            "destroy-on-close": "",
            "append-to-body": "",
            title: "拍品",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectAuction = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "h400",
              staticStyle: {
                "border-top": "1px solid #eee",
                "padding-top": "20px",
              },
            },
            [
              _vm.openType == "auctionVideo"
                ? _c(
                    "el-form",
                    { attrs: { "label-width": "80px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "拍品类型", required: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w200",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.auctionForm.make_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.auctionForm, "make_type", $$v)
                                },
                                expression: "auctionForm.make_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "重点拍品", value: 3 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "常规拍品", value: 4 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务场景", required: "" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.auctionForm.make_tag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.auctionForm, "make_tag", $$v)
                                },
                                expression: "auctionForm.make_tag",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "1" } }, [
                                _vm._v("拍卖导览"),
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: "2" } }, [
                                _vm._v("结拍快讯"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "内容形式", required: "" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.auctionForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.auctionForm, "type", $$v)
                                },
                                expression: "auctionForm.type",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "1" } }, [
                                _vm._v("视频"),
                              ]),
                              _vm._v(" "),
                              _c("el-checkbox", { attrs: { label: "3" } }, [
                                _vm._v("图文"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { type: "button", size: "small" },
                      on: { change: _vm.loadAuction },
                      model: {
                        value: _vm.radioVal,
                        callback: function ($$v) {
                          _vm.radioVal = $$v
                        },
                        expression: "radioVal",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("已选择"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.relation_id
                    ? _c(
                        "el-select",
                        {
                          staticClass: "w200 ml10",
                          attrs: {
                            placeholder: "专场名称",
                            filterable: "",
                            remote: "",
                            size: "small",
                            clearable: "",
                            "remote-method": _vm.searchAlbumAction,
                            loading: _vm.venueLoading,
                          },
                          on: { change: _vm.searchAAction },
                          model: {
                            value: _vm.album_id,
                            callback: function ($$v) {
                              _vm.album_id = $$v
                            },
                            expression: "album_id",
                          },
                        },
                        _vm._l(_vm.albumList, function (m, i) {
                          return _c("el-option", {
                            key: "user" + i,
                            attrs: { value: m.album_id, label: m.album_name },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-input",
                    {
                      staticClass: "w200 ml10",
                      attrs: { placeholder: "拍品名称/LOT/ID", size: "small" },
                      on: { change: _vm.searchAAction },
                      model: {
                        value: _vm.auctionName,
                        callback: function ($$v) {
                          _vm.auctionName = $$v
                        },
                        expression: "auctionName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-search",
                          size: "small",
                        },
                        on: { change: _vm.searchAAction },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "spec-table mt20" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticStyle: { height: "30px" },
                          attrs: { width: "25%", align: "center" },
                        },
                        [_vm._v("拍品信息")]
                      ),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "20%", align: "center" } }, [
                        _vm._v("拍卖时间"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "20%", align: "center" } }, [
                        _vm._v("作者"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "20%", align: "center" } }, [
                        _vm._v("价格"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "10%", align: "center" } }, [
                        _vm._v("拍品状态"),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { width: "10%", align: "center" } }, [
                        _vm.size
                          ? _c("div", [_vm._v("操作")])
                          : _c(
                              "a",
                              {
                                staticStyle: { color: "#28ab29" },
                                attrs: { href: "javascript:;" },
                                on: { click: _vm.auctionSelectedAllAction },
                              },
                              [_vm._v(_vm._s(_vm.isAll ? "取消全选" : "全选"))]
                            ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.resultData, function (m, i) {
                    return _c("tbody", { key: i, staticClass: "list ft12" }, [
                      _c("tr", { staticClass: "border-t" }, [
                        _c("td", { staticClass: "bgWhite" }, [
                          _c(
                            "div",
                            { staticClass: "display-flex item-align-center" },
                            [
                              _c(
                                "el-badge",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    value: m.product.slider_image.length,
                                  },
                                },
                                [
                                  m.product.image
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                          display: "block",
                                        },
                                        attrs: {
                                          src: m.product.image + "!120a",
                                          "preview-src-list":
                                            m.product.slider_image,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml10 flex-one ft12" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(m.product_id) +
                                    " | " +
                                    _vm._s(m.product.store_name) +
                                    "\n                "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            m.album_id
                              ? _c("p", [
                                  _vm._v(
                                    "专场：" +
                                      _vm._s(m.album_id) +
                                      " | " +
                                      _vm._s(m.album_name)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !m.album_id
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(m.start_time_format[1]) +
                                      " 至 " +
                                      _vm._s(m.end_time_format[1])
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "selectDom" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-one",
                                    staticStyle: {
                                      padding: "5px 0",
                                      "text-align": "left",
                                    },
                                  },
                                  [
                                    m.artist_info
                                      ? _vm._l(
                                          m.artist_info,
                                          function (ele, ind) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: "" + i + ind,
                                                attrs: {
                                                  type: "info",
                                                  closable: "",
                                                },
                                                on: {
                                                  close: function ($event) {
                                                    return _vm.onAuthorDele(
                                                      m,
                                                      ele.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(ele.name) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ft12",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSeleAuthor(m)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            m.now_price != "0.00"
                              ? _c("p", [
                                  _vm._v("当前价：" + _vm._s(m.now_price)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("起拍价：" + _vm._s(m.start_price)),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("加价幅度：" + _vm._s(m.plus_price)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _c(
                              "p",
                              [
                                m.auction_status == 1
                                  ? [_vm._v("进行中")]
                                  : m.auction_status == -1
                                  ? [_vm._v("违约")]
                                  : m.auction_status == 2
                                  ? [_vm._v("预展中")]
                                  : m.auction_status == 3
                                  ? [_vm._v("保留价待确认")]
                                  : m.auction_status == 4
                                  ? [_vm._v("流拍")]
                                  : m.auction_status == 5
                                  ? [_vm._v("已中拍 未付款")]
                                  : m.auction_status == 6
                                  ? [_vm._v("已完成")]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "bgWhite",
                            attrs: { align: "center" },
                          },
                          [
                            _vm.selectedAuctionIdList.indexOf(m.product_id) ==
                            -1
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.auctionSelectedAction(
                                            $event,
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择")]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#28ab29" },
                                      on: {
                                        click: function ($event) {
                                          _vm.auctionCancelAction(
                                            $event,
                                            _vm.selectedAuctionIdList.indexOf(
                                              m.product_id
                                            )
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("取消选择")]
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-e mt20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: { "current-change": _vm.pageChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.size
            ? _c(
                "div",
                {
                  staticClass: "flex justify-b align-items-c",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("div", [
                    _vm._v(
                      "当前共选择了" +
                        _vm._s(_vm.selectedAuction.length) +
                        "件拍品 "
                    ),
                    _vm.openType == "auctionVideo"
                      ? _c("span", [_vm._v("创建 条制作计划")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.cursorSelect },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showSelectAuction = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("SelectAuthorView", {
        ref: "authoViewRef",
        on: { onMessage: _vm.onAuthorMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }