var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "merchantUserLabelCreate",
                    expression: "'merchantUserLabelCreate'",
                  },
                ],
                staticStyle: { "margin-bottom": "20px" },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$route.path.indexOf("group") !== -1
                      ? "添加用户分组"
                      : "添加用户标签"
                  )
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            border: "",
            size: "small",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$route.path.indexOf("group") !== -1
                            ? row.group_id
                            : row.label_id
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label:
                _vm.$route.path.indexOf("group") !== -1
                  ? "分组名称"
                  : "标签名称",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$route.path.indexOf("group") !== -1
                            ? row.group_name
                            : row.label_name
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "创建时间", width: "200" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "150",
              fixed: "right",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantUserLabelUpdate",
                            expression: "'merchantUserLabelUpdate'",
                          },
                        ],
                        attrs: {
                          type: "text",
                          size: "small",
                          icon: "el-icon-edit-outline",
                        },
                        on: {
                          click: function ($event) {
                            _vm.onEdit(
                              _vm.$route.path.indexOf("group") !== -1
                                ? scope.row.group_id
                                : scope.row.label_id
                            )
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantUserLabelDelete",
                            expression: "'merchantUserLabelDelete'",
                          },
                        ],
                        staticStyle: { color: "red !important" },
                        attrs: {
                          type: "text",
                          icon: "el-icon-delete",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            _vm.handleDelete(
                              _vm.$route.path.indexOf("group") !== -1
                                ? scope.row.group_id
                                : scope.row.label_id,
                              scope.$index
                            )
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }