"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _marketing = require("@/api/marketing");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponList',
  data: function data() {
    return {
      Loading: false,
      roterPre: _settings.roterPre,
      listLoading: true,
      receiveType: 0,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        status: '',
        name: '',
        type: '',
        crowd_limit: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList(1);
  },
  methods: {
    editAction: function editAction(id) {
      this.$router.push({
        path: "".concat(_settings.roterPre) + '/marketing/coupon/creatCoupon',
        query: {
          id: id
        }
      });
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === '2') {
        // this.$router.push('/merchant/user/group');
        return false;
      } else if (tab.name === '3') {
        // this.$router.push('/merchant/user/label');
        return false;
      }
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u4F18\u60E0\u5238?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.couponDeleteApi)({
          id: id
        }).then(function (_ref) {
          var message = _ref.message;
          _this.$message.success(message);
          _this.tableData.data.splice(idx, 1);
        }).catch(function (_ref2) {
          var message = _ref2.message;
          _this.$message.error(message);
        });
      }).catch(function () {});
    },
    // 发行
    issueAction: function issueAction(id, idx) {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u53D1\u884C\u8BE5\u4F18\u60E0\u5238?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.couponIssueApi)({
          id: id
        }).then(function (_ref3) {
          var message = _ref3.message;
          _this2.$message.success(message);
          _this2.tableData.data[idx].status_name = '发行中';
          _this2.tableData.data[idx].status = 20;
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this2.$message.error(message);
        });
      }).catch(function () {});
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _marketing.couponListApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    // 修改状态
    onchangeIsShow: function onchangeIsShow(id, idx, s) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4".concat(s === 20 ? '继续发行' : '停止发行', "\u8BE5\u4F18\u60E0\u5238?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _marketing.couponIssueStatusApi)({
          id: id,
          status: s
        }).then(function (_ref5) {
          var message = _ref5.message;
          _this4.$message.success(message);
          _this4.tableData.data[idx].status_name = s === 20 ? '发行中' : '停止发行';
          _this4.tableData.data[idx].status = s;
        }).catch(function (_ref6) {
          var message = _ref6.message;
          _this4.$message.error(message);
        });
      }).catch(function () {});
    },
    goLinkAction: function goLinkAction(type, m) {
      var link = '/merchant/marketing/coupon/user';
      if (type === 'use') {
        link = '/merchant/marketing/coupon/use';
      }
      link += '?name=' + m.name;
      this.$router.push(link);
    }
  }
};