"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _basic = require("@/api/basic");
var _product = require("@/api/product");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      searchQuery: {},
      listLoading: false,
      tableData: [],
      tableFrom: {
        is_audit: 1,
        page: 1,
        limit: 20
      },
      storeList: []
    };
  },
  created: function created() {
    var _this = this;
    this.getList(1);
    (0, _product.getStoreList)({
      page: 1
    }).then(function (res) {
      _this.storeList = res.data.list.map(function (item) {
        return {
          label: item.name,
          value: item.warehouse_id
        };
      });
      _this.storeList.push({
        label: '无库存',
        value: '0'
      });
    });
  },
  methods: {
    onPrint: function onPrint() {
      (0, _printJs.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page { margin: 5mm 10mm 12mm;size: auto; } .paging{page-break-after: always;}"
      });
    },
    onUpdStatus: function onUpdStatus(row, status) {
      var _this2 = this;
      (0, _basic.auditSwitchStatus)(row.auditItem.id, {
        status: status
      }).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.getList(1);
      });
    },
    onRemove: function onRemove(row) {
      var _this3 = this;
      (0, _basic.auditDelete)(row.auditItem.id).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.getList(1);
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        is_audit: 1,
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _basic.getgoodsListV2Api)(this.tableFrom).then(function (res) {
        console.log(res);
        // res.data.list.forEach((item, index) => {
        //   item.attrjson = item.attribute || {};
        // });
        _this4.tableData = res.data.list;
        _this4.listLoading = false;
      });
    }
  }
};