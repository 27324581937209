var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticStyle: { "padding-top": "10px" } }, [
      _c("div", { staticClass: "default-setting" }, [
        _c("div", { staticClass: "display-flex all-main" }, [
          _c("div", { staticClass: "phone" }, [
            _c("div", { staticClass: "phone-title" }, [
              _c("div", [_vm._v(_vm._s(_vm.title))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "phone-content" }, [
              _vm.upImg
                ? _c("div", { staticClass: "canvas-img" }, [
                    _c("img", { attrs: { src: _vm.upImg } }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.upImg,
                      expression: "!upImg",
                    },
                  ],
                  staticClass: "canvas",
                },
                [
                  _c("el-progress", {
                    staticClass: "quan",
                    attrs: {
                      "show-text": false,
                      type: "circle",
                      percentage: 20,
                      width: 272,
                      color: "#FFCF83",
                    },
                  }),
                  _vm._v(" "),
                  _vm._m(0),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "get-point",
                  style: _vm.upImg ? "margin-top: 0;" : "",
                },
                [
                  _vm.improveInformation ||
                  _vm.bingPhone ||
                  _vm.collectionXcx ||
                  _vm.firstDay
                    ? _c("div", { staticClass: "get-point-item" }, [
                        _c("div", { staticClass: "get-point-item-title" }, [
                          _vm._v("基础任务"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "get-point-item-content" }, [
                          _vm.improveInformation
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.improveInformationImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("实名认证")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "成长值+" +
                                                  _vm._s(
                                                    _vm.improveInformationPoint
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.firstDay
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.firstDayImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("用户注册")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "成长值+" +
                                                  _vm._s(_vm.firstDayPoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("注册")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.bingPhone
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.bingPhoneImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("绑定手机号")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "成长值+" +
                                                  _vm._s(_vm.bingPhonePoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.collectionXcx
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.collectionXcxImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i no-border",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("收藏小程序")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "成长值+" +
                                                  _vm._s(_vm.collectionXcxPoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkIn || _vm.watchLive || _vm.shareLive
                    ? _c("div", { staticClass: "get-point-item" }, [
                        _c("div", { staticClass: "get-point-item-title" }, [
                          _vm._v("日常任务"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "get-point-item-content" }, [
                          _vm.checkIn
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.checkInImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("每日签到")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "成长值+" +
                                                  _vm._s(_vm.checkInPoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("签到")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "get-point-item-content" }, [
                          _vm.watchLive
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.watchLiveImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("观看直播间")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "每日累计观看直播间30分钟以上，成长值+" +
                                                  _vm._s(_vm.watchLivePoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "get-point-item-content" }, [
                          _vm.shareLive
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.shareLiveImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("分享直播间")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "分享拍卖直播间到社交平台，成长值+" +
                                                  _vm._s(_vm.shareLivePoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.buyGoods ||
                  _vm.order ||
                  _vm.vote ||
                  _vm.question ||
                  _vm.newPeople ||
                  _vm.newPeopleCon
                    ? _c("div", { staticClass: "get-point-item" }, [
                        _c("div", { staticClass: "get-point-item-title" }, [
                          _vm._v("其他任务"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "get-point-item-content" }, [
                          _vm.buyGoods
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.buyGoodsImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("购买任务")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "每消费" +
                                                  _vm._s(_vm.buyGoodsPrice) +
                                                  "元，成长值+" +
                                                  _vm._s(_vm.buyGoodsPoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.order
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", { attrs: { src: _vm.orderImg } }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i no-border",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("完成下单")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "每完成" +
                                                  _vm._s(_vm.orderCount) +
                                                  "笔订单，成长值+" +
                                                  _vm._s(_vm.orderPoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.question
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.questionImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i no-border",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("完成鉴定")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "每完成1次线上鉴宝，成长值+" +
                                                  _vm._s(_vm.questionPoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.vote
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", { attrs: { src: _vm.voteImg } }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i no-border",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("投票")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "每投" +
                                                  _vm._s(_vm.voteCount) +
                                                  "票，成长值+" +
                                                  _vm._s(_vm.votePoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("去完成")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.newPeople
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.newPeopleImg },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("邀请新用户")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "成长值+" +
                                                  _vm._s(_vm.newPeoplePoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("邀请")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.newPeopleCon
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item-align-center mic-item-detail",
                                },
                                [
                                  _c("div", { staticClass: "icon-img" }, [
                                    _c("img", {
                                      attrs: { src: _vm.newPeopleImgCon },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-align-center flex-one mic-i",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "get-point-item-c flex-one",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-t",
                                            },
                                            [_vm._v("邀请新用户并消费")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "get-point-item-c-b",
                                            },
                                            [
                                              _vm._v(
                                                "邀请新用户完成首个订单，成长值+" +
                                                  _vm._s(_vm.newPeopleConPoint)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "get-point-item-btn" },
                                        [_vm._v("邀请")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "task-item all-ft14" }, [
            _c("div", { staticClass: "task-container" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "task-c" }, [
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center all-ft14" },
                    [
                      _c("div", { staticClass: "ml20" }, [
                        _vm._v("页面标题："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "w200",
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c("div", { staticClass: "item-align-center all-ft14" }, [
                    _c("div", { staticClass: "ml20" }, [_vm._v("顶部图片：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex flex-wrap" },
                      [
                        _vm.upImg
                          ? [
                              _c("div", { staticClass: "img-list mr10" }, [
                                _c("img", {
                                  attrs: { src: _vm.upImg + "!120a" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImageAction("upImg")
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delImgAction("upImg")
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-close" })]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "upload-image flex align-items-c justify-c mr10",
                            on: {
                              click: function ($event) {
                                return _vm.changeImg("upImg")
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-picture" })]
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "task-c" }, [
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.improveInformation,
                            callback: function ($$v) {
                              _vm.improveInformation = $$v
                            },
                            expression: "improveInformation",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("完善信息"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.improveInformation
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", [_vm._v("完成后赠送：")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.improveInformationPoint,
                              callback: function ($$v) {
                                _vm.improveInformationPoint = $$v
                              },
                              expression: "improveInformationPoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.bingPhone,
                            callback: function ($$v) {
                              _vm.bingPhone = $$v
                            },
                            expression: "bingPhone",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("绑定手机号"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.bingPhone
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", [_vm._v("完成后赠送：")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.bingPhonePoint,
                              callback: function ($$v) {
                                _vm.bingPhonePoint = $$v
                              },
                              expression: "bingPhonePoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "task-c" }, [
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.buyGoods,
                            callback: function ($$v) {
                              _vm.buyGoods = $$v
                            },
                            expression: "buyGoods",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("购买商品"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.buyGoods
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", { staticClass: "mr5" }, [
                            _vm._v("每消费"),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.buyGoodsPrice,
                              callback: function ($$v) {
                                _vm.buyGoodsPrice = $$v
                              },
                              expression: "buyGoodsPrice",
                            },
                          }),
                          _c("span", { staticClass: "ml5 mr5" }, [
                            _vm._v("元，获得"),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.buyGoodsPoint,
                              callback: function ($$v) {
                                _vm.buyGoodsPoint = $$v
                              },
                              expression: "buyGoodsPoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.order,
                            callback: function ($$v) {
                              _vm.order = $$v
                            },
                            expression: "order",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("完成下单"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.order
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", { staticClass: "mr5" }, [
                            _vm._v("每完成"),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.orderCount,
                              callback: function ($$v) {
                                _vm.orderCount = $$v
                              },
                              expression: "orderCount",
                            },
                          }),
                          _c("span", { staticClass: "ml5 mr5" }, [
                            _vm._v("笔订单，获得"),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.orderPoint,
                              callback: function ($$v) {
                                _vm.orderPoint = $$v
                              },
                              expression: "orderPoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt20" }, [
                    _c(
                      "div",
                      { staticClass: "item-align-center" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.question,
                              callback: function ($$v) {
                                _vm.question = $$v
                              },
                              expression: "question",
                            },
                          },
                          [
                            _c("span", { staticClass: "ml5" }, [
                              _vm._v("完成鉴定"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.question
                      ? _c(
                          "div",
                          { staticClass: "item-align-center ml24 mt20" },
                          [
                            _c("span", [_vm._v("完成鉴定后赠送：")]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "remove-control w100",
                              attrs: { type: "number" },
                              model: {
                                value: _vm.questionPoint,
                                callback: function ($$v) {
                                  _vm.questionPoint = $$v
                                },
                                expression: "questionPoint",
                              },
                            }),
                            _c("span", { staticClass: "ml5" }, [
                              _vm._v("成长值"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "task-c" }, [
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.checkIn,
                            callback: function ($$v) {
                              _vm.checkIn = $$v
                            },
                            expression: "checkIn",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("每日签到"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.checkIn
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", [_vm._v("签到后赠送：")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.checkInPoint,
                              callback: function ($$v) {
                                _vm.checkInPoint = $$v
                              },
                              expression: "checkInPoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.firstDay,
                            callback: function ($$v) {
                              _vm.firstDay = $$v
                            },
                            expression: "firstDay",
                          },
                        },
                        [_c("span", { staticClass: "ml5" }, [_vm._v("注册")])]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.firstDay
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", [_vm._v("签到后赠送：")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.firstDayPoint,
                              callback: function ($$v) {
                                _vm.firstDayPoint = $$v
                              },
                              expression: "firstDayPoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.newPeople,
                            callback: function ($$v) {
                              _vm.newPeople = $$v
                            },
                            expression: "newPeople",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("拉取新用户"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.newPeople
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", [_vm._v("拉取后赠送：")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.newPeoplePoint,
                              callback: function ($$v) {
                                _vm.newPeoplePoint = $$v
                              },
                              expression: "newPeoplePoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.newPeopleCon,
                            callback: function ($$v) {
                              _vm.newPeopleCon = $$v
                            },
                            expression: "newPeopleCon",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("拉取新用户"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.newPeopleCon
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", [_vm._v("消费后赠送：")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.newPeopleConPoint,
                              callback: function ($$v) {
                                _vm.newPeopleConPoint = $$v
                              },
                              expression: "newPeopleConPoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.watchLive,
                            callback: function ($$v) {
                              _vm.watchLive = $$v
                            },
                            expression: "watchLive",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("观看直播间"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.watchLive
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", [_vm._v("观看后赠送：")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.watchLivePoint,
                              callback: function ($$v) {
                                _vm.watchLivePoint = $$v
                              },
                              expression: "watchLivePoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c(
                    "div",
                    { staticClass: "item-align-center" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.shareLive,
                            callback: function ($$v) {
                              _vm.shareLive = $$v
                            },
                            expression: "shareLive",
                          },
                        },
                        [
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("分享直播间"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.shareLive
                    ? _c(
                        "div",
                        { staticClass: "item-align-center ml24 mt20" },
                        [
                          _c("span", [_vm._v("分享后赠送：")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "remove-control w100",
                            attrs: { type: "number" },
                            model: {
                              value: _vm.shareLivePoint,
                              callback: function ($$v) {
                                _vm.shareLivePoint = $$v
                              },
                              expression: "shareLivePoint",
                            },
                          }),
                          _c("span", { staticClass: "ml5" }, [
                            _vm._v("成长值"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt40" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.saveAction },
                      },
                      [_vm._v("确认保存")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "abs" }, [
      _c("div", { staticClass: "grouth" }, [
        _c("div", { staticClass: "img item-flex-center" }, [
          _c("img", {
            attrs: {
              src: "https://artimg2.artart.cn/merchant/23/202389/image/1691573583693628549.png",
              alt: "",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "grouth-title" }, [_vm._v("钻石会员")]),
        _vm._v(" "),
        _c("div", { staticClass: "grouth-value" }, [
          _c("span", [_vm._v("成长值")]),
          _vm._v("15215"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "grouth-tips" }, [
          _vm._v("还需150升级为白金会员"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common-title item-align-center" }, [
      _c("span", { staticClass: "tab" }),
      _c("span", { staticClass: "ft14" }, [_vm._v("基础配置")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common-title item-align-center mt20" }, [
      _c("span", { staticClass: "tab" }),
      _c("span", { staticClass: "ft14" }, [_vm._v("基础任务")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common-title item-align-center mt20" }, [
      _c("span", { staticClass: "tab" }),
      _c("span", { staticClass: "ft14" }, [_vm._v("其他任务")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "common-title item-align-center mt20" }, [
      _c("span", { staticClass: "tab" }),
      _c("span", { staticClass: "ft14" }, [_vm._v("日常任务")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }