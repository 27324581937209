var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        {
          attrs: { type: "button" },
          on: { change: _vm.changeStatusAction },
          model: {
            value: _vm.status,
            callback: function ($$v) {
              _vm.status = $$v
            },
            expression: "status",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "1" } }, [
            _vm._v("群发列表"),
          ]),
          _vm._v(" "),
          _c("el-radio-button", { attrs: { label: "2" } }, [
            _vm._v("短信签名"),
          ]),
          _vm._v(" "),
          _c("el-radio-button", { attrs: { label: "3" } }, [
            _vm._v("内容模版"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [_c("router-view", { key: _vm.key, ref: "userChild" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }