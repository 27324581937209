"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
var _vueUeditorWrap = _interopRequireDefault(require("vue-ueditor-wrap"));
var _authenticate = require("@/api/authenticate");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    VueUeditorWrap: _vueUeditorWrap.default
  },
  name: "AddExpert",
  data: function data() {
    return {
      searchLoading: false,
      uid: "",
      loading: true,
      id: "",
      imgName: "",
      visible: false,
      uploadList: [],
      uploadListNew: [],
      uname: "",
      mobile: "",
      money: "",
      myword: "",
      intro: "",
      cateintro: "",
      issave: false,
      categoryList: [],
      // 分类列表
      category: [],
      is_show: 0,
      userList: [],
      withdrawRatio: 0
    };
  },
  activated: function activated() {},
  deactivated: function deactivated() {
    this.initData();
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.getCartgoryList();
    if (this.id) {
      this.getExpertInfo(this.id);
    } else {
      this.loading = false;
    }
    console.log("created");
  },
  methods: {
    onSelectCategory: function onSelectCategory() {
      // if (this.category.length > 2) {
      //   this.category.shift();
      // }
      // console.log(this.category);
    },
    getCartgoryList: function getCartgoryList() {
      var _this = this;
      (0, _authenticate._getCartgorySetList)().then(function (res) {
        _this.categoryList = res.data.list;
      }).catch(function (err) {
        console.log(err);
      });
    },
    getExpertInfo: function getExpertInfo(query) {
      var _this2 = this;
      (0, _authenticate._getExpertInfo)(query).then(function (res) {
        if (res.status === 200) {
          _this2.uploadList = [res.data.expert_avatar];
          if (res.data.cover_image) {
            _this2.uploadListNew = [res.data.cover_image];
          }
          _this2.userList = [{
            real_name: res.data.uname,
            uid: res.data.uid
          }];
          _this2.withdrawRatio = res.data.withdraw_ratio;
          _this2.uname = res.data.nickname;
          _this2.uid = res.data.uid;
          _this2.mobile = res.data.phone;
          _this2.money = res.data.answer_amount / 100;
          _this2.category = res.data.good_at_category.split(",");
          _this2.cateintro = res.data.good_at_description;
          _this2.intro = res.data.profile;
          _this2.is_show = res.data.is_show;
          _this2.loading = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    searchPost: function searchPost(data) {
      var _this3 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 10
      };
      this.searchLoading = true;
      (0, _user.userLstApi)(params).then(function (res) {
        if (res.status === 200) {
          _this3.userList = res.data.list;
        }
        _this3.searchLoading = false;
      });
    },
    onUpload: function onUpload(type) {
      var _this4 = this;
      this.$modalUpload(function (img) {
        if (img.length > 1) return _this4.$message.error("只能选择一张");
        if (type == 1) {
          _this4.uploadList = (0, _toConsumableArray2.default)(img);
        } else {
          _this4.uploadListNew = (0, _toConsumableArray2.default)(img);
        }
      });
    },
    handleView: function handleView(file) {
      this.imgName = file;
      this.visible = true;
    },
    handleRemove: function handleRemove(file) {
      this.uploadList = [];
    },
    handleRemoveNew: function handleRemoveNew(file) {
      this.uploadListNew = [];
    },
    save: function save() {
      var _this5 = this;
      var data = {};
      data.id = this.id;
      data.expert_avatar = this.uploadList[0] || "";
      data.expert_name = this.uname;
      data.expert_uid = this.uid;
      data.expert_phone = this.mobile;
      data.answer_amount = this.money * 100;
      data.good_at_category = this.category.join(",");
      data.good_at_description = this.cateintro;
      data.profile = this.intro;
      data.is_show = this.is_show;
      data.withdraw_ratio = this.withdrawRatio;
      data.cover_image = this.uploadListNew[0] || "";
      console.log(data);
      if (!data.expert_avatar) {
        this.$message.error("请上传头像！");
        return false;
      }
      if (!data.expert_uid) {
        this.$message.error("请绑定用户！");
        return false;
      }
      if (!data.expert_name) {
        this.$message.error("请输入专家名称！");
        return false;
      }
      if (this.money < 0 || isNaN(this.money)) {
        this.$message.error("请输入正确的金额！");
        return false;
      }
      // 允许提现
      if (this.issave) return false;
      this.issave = true;
      if (!data.id) {
        (0, _authenticate._createExpert)(data).then(function (res) {
          _this5.$message.success("操作完成！");
          _this5.$router.back();
          _this5.issave = false;
        });
      } else {
        (0, _authenticate._editExpert)(data).then(function (res) {
          _this5.$message.success("操作完成！");
          _this5.$router.back();
          _this5.issave = false;
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    initData: function initData() {
      {
        this.searchLoading = false;
        this.uid = "";
        this.loading = true;
        this.id = "";
        this.imgName = "";
        this.visible = false;
        this.uploadList = [];
        this.uploadListNew = [];
        this.uname = "";
        this.mobile = "";
        this.money = "";
        this.myword = "";
        this.intro = "";
        this.cateintro = "";
        this.issave = false;
        this.categoryList = []; // 分类列表
        this.category = [];
        this.is_show = 0;
        this.userList = [];
        this.withdrawRatio = 0;
      }
    }
  },
  mounted: function mounted() {}
};