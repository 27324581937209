var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "porBox" }, [
      _c(
        "div",
        { staticClass: "absoBox" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.add },
            },
            [_vm._v("添加货品规格")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { height: "calc(100vh - 175px)" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData.data,
              border: "",
              height: "100%",
              size: "mini",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "attr_template_id",
                label: "ID",
                "min-width": "60",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "template_name",
                label: "规格名称",
                "min-width": "150",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "商品规格", "min-width": "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return _vm._l(
                      scope.row.template_value,
                      function (item, index) {
                        return _c("span", {
                          key: index,
                          staticClass: "mr10",
                          domProps: { textContent: _vm._s(item.value) },
                        })
                      }
                    )
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "商品属性", "min-width": "300" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return _vm._l(
                      scope.row.template_value,
                      function (item, index) {
                        return _c("div", {
                          key: index,
                          domProps: {
                            textContent: _vm._s(item.detail.join(",")),
                          },
                        })
                      }
                    )
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                width: "100",
                align: "center",
                fixed: "right",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(
                                scope.row.attr_template_id,
                                scope.$index
                              )
                            },
                          },
                        },
                        [_vm._v("删除\n          ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "paginaBox" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            "page-sizes": [20, 40, 60, 80],
            "page-size": _vm.tableFrom.limit,
            "current-page": _vm.tableFrom.page,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.tableData.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }