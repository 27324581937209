var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "拍品批量导入",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                "before-close": _vm.isClose,
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "font-size": "16px", "font-weight": "550" } },
                [_vm._v(_vm._s(_vm.albumName))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "folderBox" }, [
                _c("div", [_vm._v("素材图片文件夹：")]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.changeCate.attachment_category_id
                            ? _vm.changeCate.attachment_category_name +
                                " (" +
                                _vm.changeCate.cate_attachment_num +
                                "张)"
                            : "默认分类"
                        ) +
                        "\n        "
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.openChangeCategory },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.changeCate.attachment_category_id
                              ? "修改"
                              : "选择"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex justify-b" }, [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "8px 0",
                          "font-size": "12px",
                          "font-weight": "550",
                        },
                      },
                      [_vm._v("上传拍品图片")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-upload",
                      {
                        ref: "uploadImg",
                        attrs: {
                          action: _vm.fileUrl,
                          headers: _vm.myHeaders,
                          "show-file-list": false,
                          multiple: "",
                          "before-upload": _vm.beforeAvatarUpload,
                          "on-change": _vm.onChange,
                          "http-request": _vm.modeUpload,
                          accept: "image/*,video/*,audio/*",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "primary",
                              icon: "el-icon-picture-outline",
                            },
                          },
                          [_vm._v("上传图片")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.updSussStatus == 1
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              color: "#f1de38",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm._v(
                              "图片上传中" +
                                _vm._s(_vm.updSussNum) +
                                "/" +
                                _vm._s(_vm.imgLength)
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.updSussStatus == 2
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              color: "#54b936",
                              "margin-top": "10px",
                            },
                          },
                          [_vm._v("已上传" + _vm._s(_vm.imgLength) + "张")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "50%" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "font-weight": "550",
                        },
                      },
                      [
                        _vm._v("上传数据表格 "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "margin-left": "20px",
                            },
                            attrs: { icon: "el-icon-download", type: "text" },
                            on: { click: _vm.downloadAction },
                          },
                          [_vm._v("下载拍品导入模板")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          action: _vm.fileUrl,
                          disabled: !_vm.changeCate.attachment_category_id,
                          "show-file-list": false,
                          "http-request": _vm.changeFileAction,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: !_vm.changeCate.attachment_category_id,
                              size: "small",
                              type: "primary",
                              icon: "el-icon-date",
                            },
                          },
                          [_vm._v("上传表格")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.updExclStatus == 1
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              color: "#69d",
                              "margin-top": "10px",
                            },
                          },
                          [_vm._v("表格监测中...")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errorList.length == 0 && _vm.updExclStatus == 2
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              color: "#54b936",
                              "margin-top": "10px",
                            },
                          },
                          [_vm._v("表格通过监测")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errorList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "minHeErrBox" },
                          _vm._l(_vm.errorList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: {
                                  "font-size": "12px",
                                  color: "red",
                                },
                              },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex justify-c mt20",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.loadError },
                      on: { click: _vm.bulkShipmentAction },
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c("el-button", { on: { click: _vm.isClose } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }