var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.is_show,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "is_show", $$v)
                    },
                    expression: "tableFrom.is_show",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "" } }, [
                    _vm._v("\n          全部\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("\n          上架\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("\n          下架\n        "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { placeholder: "请选择门票类型" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.ticket_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "ticket_type", $$v)
                    },
                    expression: "tableFrom.ticket_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: 0 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "门票", value: 1 } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "预约活动", value: 2 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "input-with-select ml20 w200",
                  attrs: { placeholder: "输入门票名称" },
                  on: {
                    change: function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "name", $$v)
                    },
                    expression: "tableFrom.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.createdAction },
                },
                [_vm._v("创建门票")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "mt20 no-height",
          staticStyle: { width: "100%", color: "#000" },
          attrs: {
            data: _vm.tableData.data,
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", color: "#000" },
                        attrs: { data: props.row.spec },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "规格ID",
                            width: "80",
                            prop: "spec_id",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格名称",
                            "min-width": "140",
                            prop: "spec_name",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "价格",
                            "min-width": "80",
                            prop: "spec_name",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            Number(scope.row.price)
                                              ? Number(scope.row.price)
                                              : "免费"
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "库存",
                            "min-width": "130",
                            prop: "stock",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "总库存：" +
                                          _vm._s(scope.row.origin_stock)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "剩余库存：" + _vm._s(scope.row.stock)
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "已预约/可预约", "min-width": "120" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setTimesAction(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.appointments_num) +
                                            "/" +
                                            _vm._s(
                                              scope.row
                                                .available_appointments_num
                                            )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "销售时间", "min-width": "340" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.parseTime(scope.row.upward_time)
                                          ) +
                                          " 至\n                " +
                                          _vm._s(
                                            _vm.parseTime(scope.row.down_time)
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "有效期", "min-width": "340" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.parseTime(scope.row.start_time)
                                          ) +
                                          " 至\n                " +
                                          _vm._s(
                                            _vm.parseTime(scope.row.end_time)
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "PLU", "min-width": "100" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(props.row.ticket_id) +
                                          "_" +
                                          _vm._s(scope.row.spec_id)
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "操作", width: "160" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft12 m0 plr2",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setStockAction(
                                              scope.row.spec_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("操作库存")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft12 m0 plr2",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setTimesAction(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("设置使用时段")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "门票ID", width: "80", prop: "ticket_id" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: "1",
            attrs: { label: "门票信息", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("img", {
                        staticStyle: { width: "37px", height: "37px" },
                        attrs: {
                          src: scope.row.product.image + "!120a",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-one ml10" }, [
                        _c("div", { staticClass: "line2" }, [
                          _vm._v(_vm._s(scope.row.product.store_name)),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "票种数量", "min-width": "80", prop: "spec_count" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "使用地址", "min-width": "150", prop: "address" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "是否实名", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.is_verified ? "需实名" : "不限") +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "已预约/可预约", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.appointments_num) +
                          "/" +
                          _vm._s(scope.row.available_appointments_num) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "排序", "min-width": "100", prop: "sort" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      staticClass: "w100",
                      on: {
                        change: function (val) {
                          return _vm.editSortAction(val, scope.$index)
                        },
                      },
                      model: {
                        value: scope.row.sort,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "sort", $$v)
                        },
                        expression: "scope.row.sort",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        class:
                          scope.row.is_show == 1 ? "color-lv" : "color-red",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.is_show == 1 ? "上架" : "下架") +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", "min-width": "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex" }, [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.add_time))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "left",
                          width: "100",
                          trigger: "hover",
                        },
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyXcxLink(scope.row, $event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                小程序链接\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyH5Link(scope.row, $event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                H5链接\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImgAction(4, scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                小程序码\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImgAction(3, scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                二维码\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImgAction(2, scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                小程序海报\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showImgAction(1, scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                二维码海报\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ft12 m0 plr2",
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [_vm._v("\n            推广链接\n          ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ft12 m0 plr2",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editAction(scope.row.ticket_id)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    scope.row.is_show == 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "ft12 m0 plr2",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.setShowAction(
                                  scope.row.ticket_id,
                                  -1
                                )
                              },
                            },
                          },
                          [_vm._v("下架")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "ft12 m0 plr2",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.setShowAction(scope.row.ticket_id, 1)
                              },
                            },
                          },
                          [_vm._v("上架")]
                        ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ft12 m0 plr2",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.delAction(scope.row.ticket_id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [10, 20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看图片", visible: _vm.showImg, width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作库存",
            visible: _vm.setStockData.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setStockData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c mt10" },
            [
              _c("span", [_vm._v("操作类型：")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.setStockData.data.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.setStockData.data, "type", $$v)
                    },
                    expression: "setStockData.data.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", [_vm._v("操作数量：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                attrs: {
                  placeholder:
                    "请输入要" +
                    (_vm.setStockData.data.type == 1 ? "增加" : "减少") +
                    "的库存数量",
                },
                model: {
                  value: _vm.setStockData.data.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.setStockData.data, "num", $$v)
                  },
                  expression: "setStockData.data.num",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setStockData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setStockFinishAction },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("SpecTimes", { ref: "specTimesEL" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }