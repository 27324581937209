"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _member = require("@/api/member");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        size: 10
      },
      total: 0,
      workName: '',
      searchLoading: false,
      announcer: '',
      searchGoodsId: '',
      parseTime: _index.parseTime
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadArticle();
      }
      this.showSelectAuction = true;
    },
    searchArticleAction: function searchArticleAction() {
      this.queryParams.keyword = this.workName;
      this.queryParams.page = 1;
      this.loadArticle();
    },
    loadArticle: function loadArticle(fun) {
      var _this = this;
      var params = this.queryParams;
      (0, _member.membershipCardListApi)(params).then(function (res) {
        res.data.list.forEach(function (m) {
          m.id = m.membership_card_id;
        });
        _this.resultData = res.data.list;
        _this.total = res.data.count;
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        name: info.name,
        id: info.id,
        image: info.image,
        stock: info.stock,
        status: info.status,
        price: info.price
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.size === '1') {
        this.cursorSelect();
      }
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'nft',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.loadArticle();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.loadArticle();
    }
  }
};