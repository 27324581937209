"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
var _setting = require("@/api/setting");
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tab: '1',
      version_code: '',
      upgrade_type: '1',
      application_market_url: '',
      remark: '',
      startImg: '',
      link_url: '',
      stop_time: '',
      appUploadShow: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20
      },
      apkData: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _system.getConfigClassKeys)('appStartPage').then(function (res) {
      _this.startImg = res.data.appStartPage_pictureUrl;
      _this.link_url = res.data.appStartPage_url;
      _this.stop_time = res.data.appStartPage_time;
    });
  },
  methods: {
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _setting.appPackageListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.data.list;
        _this2.tableData.total = res.data.count;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    changeTab: function changeTab(data) {
      // this.version_code = '';
      // this.upgrade_type = '1';
      // this.application_market_url = '';
      // this.remark = '';
      // this.link_url = '';
      // this.stop_time = '';
      // this.startImg = '';
      // this.apkData = null;
      if (data !== '1') {
        this.tableFrom.type = data === '2' ? 2 : 1;
        this.getList(1);
        this.tab = data;
        return false;
      }
      this.tab = data;
    },
    uploadImgAction: function uploadImgAction() {
      var _this3 = this;
      this.$modalUpload(function (img) {
        _this3.startImg = img[0];
      }, 1, '1');
    },
    delImgAction: function delImgAction() {},
    uploadAPKAction: function uploadAPKAction(file) {
      var _this4 = this;
      if (file.file.name.indexOf('.apk') === -1) {
        this.$message.error('请上传apk文件');
        return false;
      }
      var formData = new FormData();
      formData.append('multipartFile', file.file);
      (0, _setting.uploadAppPackageApi)(formData).then(function (res) {
        _this4.apkData = res.data;
        _this4.version_code = res.data.versionCode;
        _this4.application_market_url = res.data.url;
      });
    },
    showAddAPPAction: function showAddAPPAction() {
      this.version_code = '';
      this.upgrade_type = '1';
      this.application_market_url = '';
      this.remark = '';
      this.link_url = '';
      this.stop_time = '';
      this.startImg = '';
      this.apkData = null;
      this.appUploadShow = true;
    },
    saveAction: function saveAction() {
      var _this5 = this;
      if (Number(this.tab) === 1) {
        if (!this.startImg) {
          this.$message.error("\u8BF7\u4E0A\u4F20\u542F\u52A8\u56FE\u7247");
          return false;
        }
        if (!this.link_url) {
          this.$message.error("\u8BF7\u8F93\u5165\u70B9\u51FB\u56FE\u7247\u8DF3\u8F6C\u5730\u5740");
          return false;
        }
        if (!this.stop_time) {
          this.$message.error("\u8BF7\u8F93\u5165\u505C\u7559\u65F6\u95F4");
          return false;
        }
        (0, _system.configClassKeysSave)('appStartPage', {
          appStartPage_pictureUrl: this.startImg,
          appStartPage_url: this.link_url,
          appStartPage_time: this.stop_time
        }).then(function () {
          _this5.$message.success('保存成功');
        });
        return false;
      }
      if (!this.application_market_url) {
        this.$message.error(Number(this.tab) === 2 ? '请输入应用市场地址' : '请上传APK包文件');
        return false;
      }
      if (!this.version_code) {
        this.$message.error("\u8BF7\u8F93\u5165VersionCode");
        return false;
      }
      if (!this.remark) {
        this.$message.error("\u8BF7\u8F93\u5165\u66F4\u65B0\u8BF4\u660E");
        return false;
      }
      var data = {
        type: Number(this.tab) === 2 ? 2 : 1,
        url: this.application_market_url,
        remark: this.remark,
        renewType: this.upgrade_type,
        versionCode: this.version_code
      };
      if (Number(this.tab) === 3) {
        data.appMd5 = this.apkData.appMd5;
        data.appName = this.apkData.appName;
        data.appPackageName = this.apkData.appPackageName;
        data.version = this.apkData.version;
        data.packageSize = this.apkData.packAgeSize;
      }
      (0, _setting.appRenewCreateApi)(data).then(function (res) {
        _this5.$message.success('设置成功');
        if (Number(_this5.tab) !== 1) {
          _this5.appUploadShow = false;
          _this5.getList();
        }
      }).catch(function () {});
    },
    downloadAPP: function downloadAPP(url) {
      window.open(url);
    },
    changeNow: function changeNow(m) {
      var _this6 = this;
      this.$confirm("\u786E\u8BA4\u4F7F\u7528\u6B64\u7248\u672C?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _setting.appUseVersionApi)({
          id: m.id
        }).then(function () {
          _this6.$message.success('操作成功');
          _this6.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};