var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "",
        visible: _vm.showdetail,
        direction: "rtl",
        size: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showdetail = $event
        },
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "drawer-title" }, [
          _c(
            "div",
            {
              staticClass: "drawer-title-list",
              class: { active: _vm.drawtype == "detail" },
              on: {
                click: function ($event) {
                  return _vm.changedrawtype("detail")
                },
              },
            },
            [_vm._v("\n        货品详情\n      ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "drawer-title-list",
              class: { active: _vm.drawtype == "log" },
              on: {
                click: function ($event) {
                  return _vm.changedrawtype("log")
                },
              },
            },
            [_vm._v("\n        日志记录\n      ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-title-bg" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-body" },
        [
          _vm.drawtype == "detail"
            ? [
                _c("div", { staticClass: "drawer-name" }, [
                  _vm._v(_vm._s(_vm.productdetail.product_name)),
                ]),
                _vm._v(" "),
                _vm.productdetail.imagearr
                  ? _c(
                      "div",
                      { staticClass: "drawer-image-box" },
                      [
                        _vm._l(
                          _vm.productdetail.imagearr,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: "image" + index,
                                staticClass: "drawer-image",
                              },
                              [
                                _c("el-image", {
                                  attrs: {
                                    src: item + "!120a",
                                    "preview-src-list": [item],
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        _vm._v(" "),
                        _vm.productdetail.video_link
                          ? _c("div", { staticClass: "drawer-image" }, [
                              _c("video", {
                                attrs: { src: _vm.productdetail.video_link },
                              }),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "drawer-text-box" }, [
                  _c("div", { staticClass: "drawer-text" }, [
                    _vm._v("\n          货品ID："),
                    _c("span", { staticClass: "color-334" }, [
                      _vm._v(_vm._s(_vm.productdetail.id)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.productdetail.cate_parent
                    ? _c(
                        "div",
                        { staticClass: "drawer-text" },
                        [
                          _vm._v("\n          货品分类："),
                          _vm._l(
                            _vm.productdetail.cate_parent,
                            function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: "cateparent" + index,
                                  staticClass: "color-334",
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.cate_name) + "\n          "
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "drawer-text" }, [
                    _vm._v("\n          作者/品牌："),
                    _vm.productdetail.brand
                      ? _c("span", { staticClass: "color-334" }, [
                          _vm._v(
                            _vm._s(_vm.productdetail.brand.brand_name || "")
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "drawer-text" }, [
                    _vm._v("\n          创建时间："),
                    _c("span", { staticClass: "color-334" }, [
                      _vm._v(_vm._s(_vm.productdetail.create_time)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "drawer-text" }, [
                    _vm._v("\n          录入人员："),
                    _vm.productdetail.createAdmin
                      ? _c("span", { staticClass: "color-334" }, [
                          _vm._v(
                            _vm._s(
                              _vm.productdetail.createAdmin.real_name || ""
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "drawer-text" }, [
                    _vm._v("\n          货品属性："),
                    _vm.productdetail.attribute
                      ? _c(
                          "span",
                          { staticClass: "color-334" },
                          _vm._l(_vm.productdetail.attribute, function (m, i) {
                            return _c("span", { key: "attr_" + i }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(m + "/" || "") +
                                  "\n          "
                              ),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "drawer-text" }, [
                    _vm._v("\n          货品标签："),
                    _vm.productdetail.label && _vm.productdetail.label.length
                      ? _c(
                          "span",
                          { staticClass: "color-334" },
                          _vm._l(
                            _vm.productdetail.label,
                            function (item, index) {
                              return _c("span", { key: "label_" + index }, [
                                _vm._v("#" + _vm._s(item) + "\n            "),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "drawer-list" }, [
                  _c("div", { staticClass: "drawer-list-left" }, [
                    _vm._v("货品介绍"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "drawer-list-right" }, [
                    _vm.productdetail.content
                      ? _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.productdetail.content),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.drawtype != "log"
            ? _c("div", { staticClass: "drawer-body-title" }, [
                _vm._v("时间轴"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.drawtype != "log"
            ? _c(
                "div",
                { staticClass: "timeline-box" },
                _vm._l(_vm.timelog, function (item, index) {
                  return _c(
                    "div",
                    { key: "timelog_" + index, staticClass: "timeline" },
                    [
                      _c("div", { staticClass: "timeline-icon" }, [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20220119/677fae73c3e1c270cae37b6f76d23f74.png",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "timeline-content",
                          class: index % 2 == 0 ? "left" : "right",
                        },
                        [
                          index % 2 == 0
                            ? _c("div", { staticClass: "timeline-oneline" }, [
                                _c("span", [
                                  _vm._v(_vm._s(item.create_time) + " "),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "timeline-title" }, [
                                  _vm._v(_vm._s(item.typeText)),
                                ]),
                                _vm._v(" "),
                                item.category == "pm"
                                  ? _c(
                                      "span",
                                      { staticClass: "timeline-title" },
                                      [
                                        _vm._v(
                                          "：" +
                                            _vm._s(
                                              item.linkItem.album.album_name
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c("div", { staticClass: "timeline-oneline" }, [
                                _c("span", { staticClass: "timeline-title" }, [
                                  _vm._v(_vm._s(item.typeText) + " "),
                                ]),
                                _vm._v(" "),
                                item.category == "pm"
                                  ? _c(
                                      "span",
                                      { staticClass: "timeline-title" },
                                      [
                                        _vm._v(
                                          "：" +
                                            _vm._s(
                                              item.linkItem.album.album_name
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(" " + _vm._s(item.create_time) + " "),
                                ]),
                              ]),
                          _vm._v(" "),
                          index % 2 == 0
                            ? _c("div", { staticClass: "timeline-twoline" }, [
                                item.category == "push_warehouse"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "push_warehouse",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "入库单 " +
                                            _vm._s(
                                              item.linkItem
                                                ? item.linkItem.order_no
                                                : ""
                                            )
                                        ),
                                      ]
                                    )
                                  : item.category == "out_warehouse"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "out_warehouse",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "出库单 " +
                                            _vm._s(
                                              item.linkItem
                                                ? item.linkItem.order_no
                                                : ""
                                            )
                                        ),
                                      ]
                                    )
                                  : item.category == "entrust"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "entrust",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "委托单 " +
                                            _vm._s(
                                              item.linkItem
                                                ? item.linkItem.no
                                                : ""
                                            )
                                        ),
                                      ]
                                    )
                                  : item.category == "contract"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "contract",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "合同 " +
                                            _vm._s(
                                              item.linkItem
                                                ? item.linkItem.no
                                                : ""
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.linkItem && item.linkItem.credentials
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewimg(
                                              item.linkItem.credentials
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("查看凭证")]
                                    )
                                  : _vm._e(),
                                _vm._v("\n            操作人："),
                                item.createAdmin
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(item.createAdmin.real_name)
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("div", { staticClass: "timeline-twoline" }, [
                                _vm._v("\n            操作人："),
                                item.createAdmin
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(item.createAdmin.real_name)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.category == "push_warehouse"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "push_warehouse",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "入库单 " +
                                            _vm._s(
                                              item.linkItem
                                                ? item.linkItem.order_no
                                                : ""
                                            )
                                        ),
                                      ]
                                    )
                                  : item.category == "out_warehouse"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "out_warehouse",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "出库单 " +
                                            _vm._s(
                                              item.linkItem
                                                ? item.linkItem.order_no
                                                : ""
                                            )
                                        ),
                                      ]
                                    )
                                  : item.category == "entrust"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "entrust",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "委托单 " +
                                            _vm._s(
                                              item.linkItem
                                                ? item.linkItem.no
                                                : ""
                                            )
                                        ),
                                      ]
                                    )
                                  : item.category == "contract"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "contract",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "合同 " +
                                            _vm._s(
                                              item.linkItem
                                                ? item.linkItem.no
                                                : ""
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.linkItem && item.linkItem.credentials
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "timeline-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewimg(
                                              item.linkItem.credentials
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("查看凭证")]
                                    )
                                  : _vm._e(),
                              ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.drawtype == "log"
            ? [
                _vm.catelog.pm
                  ? _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("上拍记录"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "drawer-list-right" },
                        _vm._l(_vm.catelog.pm, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "pm_" + index,
                              staticClass: "drawer-l-r-list",
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.create_time))]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(item.linkItem.venue.title)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(item.linkItem.album.album_name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              item.linkItem.auction.auction_status == -1
                                ? _c("span", [_vm._v("已违约")])
                                : item.linkItem.auction.auction_status == 1
                                ? _c("span", [_vm._v("进行中")])
                                : item.linkItem.auction.auction_status == 2
                                ? _c("span", [_vm._v("预展中")])
                                : item.linkItem.auction.auction_status == 3
                                ? _c("span", [_vm._v("拍卖结束")])
                                : item.linkItem.auction.auction_status == 4
                                ? _c("span", [_vm._v("流拍")])
                                : item.linkItem.auction.auction_status == 5
                                ? _c("span", [_vm._v("成交待付款")])
                                : item.linkItem.auction.auction_status == 6
                                ? _c("span", [_vm._v("成交已付款")])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.catelog.product
                  ? _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("货品档案"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "drawer-list-right" },
                        _vm._l(_vm.catelog.product, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "push_" + index,
                              staticClass: "drawer-l-r-list",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.createAdmin.real_name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.create_time))]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.typeText) + " ")]),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.catelog.entrust
                  ? _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("委托单"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "drawer-list-right" },
                        _vm._l(_vm.catelog.entrust, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "push_" + index,
                              staticClass: "drawer-l-r-list",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.creator.real_name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.create_time))]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.typeText) + " ")]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              item.linkItem && item.linkItem.no
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.prieviewtype(
                                            "entrust",
                                            item.link_id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.linkItem.no))]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.catelog.contract
                  ? _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("合同"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "drawer-list-right" },
                        _vm._l(_vm.catelog.contract, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "push_" + index,
                              staticClass: "drawer-l-r-list",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.createAdmin.real_name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.create_time))]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.typeText) + " ")]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              item.linkItem && item.linkItem.no
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.prieviewtype(
                                            "contract",
                                            item.link_id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.linkItem.no))]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.catelog.enter_warehouse
                  ? _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("入库单"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "drawer-list-right" },
                        _vm._l(
                          _vm.catelog.enter_warehouse,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: "push_" + index,
                                staticClass: "drawer-l-r-list",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(item.createAdmin.real_name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "drawer-l-r-split" },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.create_time))]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "drawer-l-r-split" },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(item.typeText) + " "),
                                ]),
                                _vm._v(" "),
                                item.linkItem && item.linkItem.order_no
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "drawer-l-r-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "push_warehouse",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.linkItem.order_no))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "drawer-l-r-split" },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" "),
                                item.linkItem && item.linkItem.pushWarehouse
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(item.linkItem.pushWarehouse.name)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.catelog.push_shelf
                  ? _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("入位"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "drawer-list-right" },
                        _vm._l(_vm.catelog.push_shelf, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "push_" + index,
                              staticClass: "drawer-l-r-list",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.creator.real_name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.create_time))]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.typeText) + " ")]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              item.linkItem.credentials
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "drawer-l-r-handle",
                                      on: {
                                        click: function ($event) {
                                          return _vm.prieviewimg(
                                            item.linkItem.credentials
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("查看凭证")]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.catelog.stock_take
                  ? _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("盘点记录"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "drawer-list-right" },
                        _vm._l(_vm.catelog.stock_take, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "stock_" + index,
                              staticClass: "drawer-l-r-list",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.creator.real_name)),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.create_time))]),
                              _vm._v(" "),
                              _c("span", { staticClass: "drawer-l-r-split" }, [
                                _vm._v("/"),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    item.linkItem.abnormal_status == 1
                                      ? "盘点异常"
                                      : "盘点正常"
                                  ) + "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              item.linkItem.credentials
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "drawer-l-r-handle",
                                      on: {
                                        click: function ($event) {
                                          return _vm.prieviewimg(
                                            item.linkItem.credentials
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("查看存证")]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.catelog.out_warehouse
                  ? _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("出库单"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "drawer-list-right" },
                        _vm._l(
                          _vm.catelog.out_warehouse,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: "out_" + index,
                                staticClass: "drawer-l-r-list",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(item.creator.real_name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "drawer-l-r-split" },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.create_time))]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "drawer-l-r-split" },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(item.typeText) + " "),
                                ]),
                                _vm._v(" "),
                                item.linkItem && item.linkItem.order_no
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "drawer-l-r-handle",
                                        on: {
                                          click: function ($event) {
                                            return _vm.prieviewtype(
                                              "out_warehouse",
                                              item.link_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.linkItem.order_no))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "drawer-l-r-split" },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" "),
                                item.linkItem.outWarehouse
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.linkItem.outWarehouse.name
                                        ) + "\n            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }