var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "酒大盘" } }),
      _vm._v(" "),
      _c("el-card", [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      size: "small",
                      "label-width": "120px",
                      inline: true,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "关键字搜索：" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "selWidth",
                            attrs: { placeholder: "请输入商品名称，关键字" },
                            model: {
                              value: _vm.queryParams.product_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "product_name", $$v)
                              },
                              expression: "queryParams.product_name",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: { slot: "append", icon: "el-icon-search" },
                              on: {
                                click: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.createdAction },
              },
              [_vm._v("创建新产品")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "order-table",
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  data: _vm.tableData.data,
                  size: "mini",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "排序", width: "90", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input-number", {
                            staticStyle: { width: "60px" },
                            attrs: { min: 0, controls: false },
                            on: {
                              change: function (val) {
                                return _vm.editSortAction(val, scope.$index)
                              },
                            },
                            model: {
                              value: scope.row.sort,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "sort", $$v)
                              },
                              expression: "scope.row.sort",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "ID", align: "center", width: "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.id))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "产品图片", width: "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "flex item-flex-center" }, [
                            _c("img", {
                              staticStyle: { width: "37px", height: "37px" },
                              attrs: { src: row.image + "!120a", alt: "" },
                            }),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "产品名称", "min-width": "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.product_name))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "产品规格", "min-width": "160" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.spec))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "产品标签", "min-width": "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [_vm._v(_vm._s(row.labels.join(",")))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "到货时间", "min-width": "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.arrival_date))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "出厂价格", "min-width": "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_c("div", [_vm._v(_vm._s(row.factory_price))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "今日市场参考价", "min-width": "220" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            { staticClass: "flex align-items-c" },
                            [
                              _c("div", { staticClass: "color-red" }, [
                                _vm._v("￥" + _vm._s(row.current_price)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ml10",
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showUpdatePriceCon(row.id)
                                    },
                                  },
                                },
                                [_vm._v("更新价格")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "color-red ft12",
                              staticStyle: {
                                "font-weight": "500",
                                "margin-top": "-5px",
                              },
                            },
                            [_vm._v("更新时间：" + _vm._s(row.update_time))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "状态", "min-width": "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.is_show
                            ? _c("div", { staticClass: "color-lv" }, [
                                _vm._v("显示"),
                              ])
                            : _c("div", { staticClass: "color-red" }, [
                                _vm._v("已隐藏"),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "操作",
                    fixed: "right",
                    align: "right",
                    width: "160",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.editAction(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.showAction(
                                    !scope.row.is_show,
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(scope.row.is_show ? "隐藏" : "展示")
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.delAction(scope.row.id)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "paginaBox flex" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "page-size": _vm.queryParams.limit,
                    "current-page": _vm.queryParams.page,
                    layout: "total, prev, pager, next, jumper",
                    total: _vm.tableData.total,
                  },
                  on: { "current-change": _vm.getList },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.updatePriceCon,
            title: "价格更新",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.updatePriceCon = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.updatePriceLoading },
                  on: { click: _vm.addNewAction },
                },
                [_vm._v("新增记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.priceListLoading,
                  expression: "priceListLoading",
                },
              ],
              staticClass: "order-table mt10",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.priceData,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "日期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.date))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "价格", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isNew
                          ? [
                              _c("el-input-number", {
                                staticClass: "w100",
                                attrs: { min: 0, controls: false },
                                model: {
                                  value:
                                    _vm.priceListNew[scope.$index]
                                      .current_price,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.priceListNew[scope.$index],
                                      "current_price",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "priceListNew[scope.$index].current_price",
                                },
                              }),
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.current_price) +
                                  "\n          "
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "单位", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isNew
                          ? [
                              _c("el-input", {
                                staticClass: "w100",
                                model: {
                                  value: _vm.priceListNew[scope.$index].unit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.priceListNew[scope.$index],
                                      "unit",
                                      $$v
                                    )
                                  },
                                  expression: "priceListNew[scope.$index].unit",
                                },
                              }),
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.unit) +
                                  "\n          "
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isNew
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delPriceAction(scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          : scope.$index ===
                              (_vm.priceListNew.length ? 1 : 0) &&
                            _vm.priceQueryParams.page === 1
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delOldPriceAction(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-size": _vm.priceQueryParams.limit,
                  "current-page": _vm.priceQueryParams.page,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.priceListData.total,
                },
                on: { "current-change": _vm.getPriceList },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.updatePriceLoading },
                  on: { click: _vm.updatePriceAction },
                },
                [_vm._v("更新价格")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }