"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var memberRouter = {
  path: "".concat(_settings.roterPre, "/pageManagement"),
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
  meta: {
    title: '会员管理',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'memberData',
    name: 'memberData',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
    meta: {
      title: '会员管理',
      noCache: true
    },
    children: [{
      path: 'memberList',
      name: 'memberList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/memberList'));
        });
      },
      meta: {
        title: '会员管理',
        noCache: true,
        permissionKey: '/pageManagement/memberData/memberList'
      }
    }, {
      path: 'benefitsList',
      name: 'benefitsList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/benefitsList'));
        });
      },
      meta: {
        title: '权益管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
        permissionKey: '/pageManagement/memberData/benefitsList'
      }
    }, {
      path: 'memberCardList',
      name: 'memberCardList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/memberCardList'));
        });
      },
      meta: {
        title: '会员卡',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
        permissionKey: '/pageManagement/memberData/memberCardList'
      }
    }, {
      path: 'levelList',
      name: 'levelList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/levelList'));
        });
      },
      meta: {
        title: '等级管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
        permissionKey: '/pageManagement/memberData/levelList'
      }
    }, {
      path: 'levelTask',
      name: 'levelTask',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/levelTask'));
        });
      },
      meta: {
        title: '升级任务',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
        permissionKey: '/pageManagement/memberData/levelTask'
      }
    }, {
      path: 'buyRecord',
      name: 'buyRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/buyRecord'));
        });
      },
      meta: {
        title: '用户会员卡',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
        permissionKey: '/pageManagement/memberData/buyRecord'
      }
    }, {
      path: 'venueSign',
      name: 'venueSign',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/venueSign'));
        });
      },
      meta: {
        title: '场馆签到',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
        permissionKey: '/pageManagement/memberData/venueSign'
      }
    }, {
      path: 'memberData',
      name: 'memberData',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/memberData'));
        });
      },
      meta: {
        title: '统计报表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
        permissionKey: '/pageManagement/memberData/memberData'
      }
    }, {
      path: 'buyOrder',
      name: 'buyOrderList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/member/buyOrderList'));
        });
      },
      meta: {
        title: '购买记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList"),
        permissionKey: '/pageManagement/memberData/buyOrder'
      }
    }]
  }, {
    path: 'memberData/benefitsDetail',
    name: 'benefitsDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/benefitsDetail'));
      });
    },
    meta: {
      title: '权益详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList")
    }
  }, {
    path: 'memberData/memberCardDetail',
    name: 'memberCardDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/memberCardDetail'));
      });
    },
    meta: {
      title: '会员卡详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList")
    }
  }, {
    path: 'memberData/levelDetail',
    name: 'levelDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/levelDetail'));
      });
    },
    meta: {
      title: '等级详情',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/memberData/memberList")
    }
  }
  // {
  //   path: 'meetingData/albumDetail',
  //   name: 'albumDetail',
  //   component: () => import('@/views/auction/detailView/albumDetail'),
  //   meta: { title: '专场详情', noCache: true, activeMenu: `${roterPre}/pageManagement/auction/meeting` }
  // },
  // {
  //   path: 'meetingData/auctionDetail',
  //   name: 'auctionDetail',
  //   component: () => import('@/views/auction/detailView/auctionDetail'),
  //   meta: { title: '拍品详情', noCache: true, activeMenu: `${roterPre}/pageManagement/auction/meeting` }
  // },
  // {
  //   path: 'collect',
  //   name: 'auction_collect',
  //   component: () => import('@/views/auction/collect/list'),
  //   meta: { title: '拍品征集', noCache: true }
  // },
  // {
  //   path: 'collect_detail',
  //   name: 'auction_collect_detail',
  //   component: () => import('@/views/auction/collect/detail'),
  //   meta: { title: '征集详情', noCache: true, activeMenu: `${roterPre}/pageManagement/auction/collect` }
  // },
  // {
  //   path: 'collect_auction',
  //   name: 'auction_collect_auction',
  //   component: () => import('@/views/auction/collect/auction'),
  //   meta: { title: '送拍列表', noCache: true, activeMenu: `${roterPre}/pageManagement/auction/collect` }
  // }
  ]
};
var _default = exports.default = memberRouter;