var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: "拍品" } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("风格模板选择"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center flex-wrap" },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "change-type",
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          class: { curr: _vm.is_active(item) },
                          on: {
                            click: function ($event) {
                              return _vm.setStyle(item.value)
                            },
                          },
                        },
                        [_c("label", [_c("img", { attrs: { src: item.src } })])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "name color-gray align-c ft12" },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.content.style !== 1
                ? _c("div", { staticClass: "item-align-center mt20" }, [
                    _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                      _vm._v("图片展示方式"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.content.style !== 1
                ? _c(
                    "div",
                    { staticClass: "item-align-center flex-wrap" },
                    _vm._l(_vm.imgStyleData, function (item, index) {
                      return (_vm.content.style !== 2 && item.value !== 1) ||
                        _vm.content.style === 2
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "change-type",
                              staticStyle: {
                                "margin-right": "20px",
                                "margin-top": "15px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img",
                                  class: {
                                    curr: item.value === _vm.facade.imgStyle,
                                  },
                                },
                                [
                                  _c("label", [
                                    _c("img", { attrs: { src: item.src } }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.facade.imgStyle,
                                          expression: "facade.imgStyle",
                                        },
                                      ],
                                      staticClass: "choose-input",
                                      attrs: { type: "radio", name: "choose" },
                                      domProps: {
                                        value: item.value,
                                        checked: _vm._q(
                                          _vm.facade.imgStyle,
                                          item.value
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.facade,
                                            "imgStyle",
                                            item.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "name color-gray align-c ft12" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.cData.dataType !== "category"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "item-align-center mt20" },
                      [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("数据选择")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "le-radio-group",
                            model: {
                              value: _vm.content.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.content, "type", $$v)
                              },
                              expression: "content.type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("系统自动展示"),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("手动选择展示"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.content.type === 1
                      ? _c(
                          "div",
                          { staticClass: "item-align-center mt20" },
                          [
                            _c(
                              "div",
                              { staticClass: "w80 fontW400 ft14 color-black" },
                              [_vm._v("拍品类型")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-checkbox-group",
                              {
                                staticClass: "le-radio-group",
                                model: {
                                  value: _vm.content.album_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.content, "album_type", $$v)
                                  },
                                  expression: "content.album_type",
                                },
                              },
                              [
                                _c("el-checkbox", { attrs: { label: 1 } }, [
                                  _vm._v("限时拍"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: 2 } }, [
                                  _vm._v("同步拍"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: 3 } }, [
                                  _vm._v("即时拍"),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", { attrs: { label: 4 } }, [
                                  _vm._v("即刻拍"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 1
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("系统自动展示条件")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 1
                      ? _c(
                          "div",
                          { staticClass: "item-align-center mt10" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "le-radio-group",
                                model: {
                                  value: _vm.content.system_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.content, "system_type", $$v)
                                  },
                                  expression: "content.system_type",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("展示预展+进行中+已结束拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("展示预展+进行中的拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("展示预展中的拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 4 } }, [
                                      _vm._v("展示进行中的拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("el-radio", { attrs: { label: 5 } }, [
                                      _vm._v("展示已结束的拍品"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 1 && !_vm.loadMore
                      ? _c(
                          "div",
                          { staticClass: "item-align-center mt20" },
                          [
                            _c(
                              "div",
                              { staticClass: "w80 fontW400 ft14 color-black" },
                              [_vm._v("展示数据")]
                            ),
                            _vm._v(" "),
                            _c("el-input-number", {
                              attrs: { min: 1, max: 50, label: "数量" },
                              model: {
                                value: _vm.content.system_count,
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, "system_count", $$v)
                                },
                                expression: "content.system_count",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 2
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c(
                            "div",
                            { staticClass: "fontW400 ft14 color-black" },
                            [_vm._v("手动选择拍品")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.data &&
                    _vm.content.data.length > 0 &&
                    _vm.content.type === 2
                      ? _c(
                          "div",
                          { staticClass: "pt10" },
                          [
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  staticClass:
                                    "board-column-content flex flex-wrap",
                                  attrs: { list: _vm.content.data },
                                },
                                "draggable",
                                _vm.$attrs,
                                false
                              ),
                              _vm._l(_vm.content.data, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "kuang1 item-align-center mt10",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "item-align-center flex-one",
                                      },
                                      [
                                        _c("div", { staticClass: "ft14" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    index === 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "go-down-img ml10",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goDown(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "go-up-img ml10",
                                            on: {
                                              click: function ($event) {
                                                return _vm.goUp(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "del-img",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.content.type === 2
                      ? _c("div", { staticClass: "item-align-center mt20" }, [
                          _c("img", {
                            staticStyle: {
                              width: "12px",
                              height: "12px",
                              "margin-right": "5px",
                              "margin-top": "2px",
                            },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211022/d286ace899269088993cc544cfb80ab1.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "fontW500 ft16",
                              on: { click: _vm.handleAdditem },
                            },
                            [_vm._v("选择拍品")]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c("div", { staticClass: "item-align-center mt20" }, [
            _c("div", { staticClass: "fontW400 ft14 color-black flex-one" }, [
              _vm._v("单独设置样式"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c("el-switch", {
                  attrs: {
                    "active-color": "#009406",
                    "inactive-color": "#cccccc",
                  },
                  model: {
                    value: _vm.content.set_separately,
                    callback: function ($$v) {
                      _vm.$set(_vm.content, "set_separately", $$v)
                    },
                    expression: "content.set_separately",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          !_vm.content.set_separately
            ? _c("div", { staticClass: "item-align-center mt20" }, [
                _c(
                  "div",
                  { staticClass: "fontW400 ft14 color-black" },
                  [
                    _vm._v("样式设置请前往公用设置页面 "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.goPageAction },
                      },
                      [_vm._v("去设置")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.content.set_separately
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.facade,
                    "label-width": "100px",
                    size: "mini",
                    "label-position": "left",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "列表背景颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#ffffff" },
                        model: {
                          value: _vm.facade.backgroundColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "backgroundColor", $$v)
                          },
                          expression: "facade.backgroundColor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "列表边距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.pageMargin,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "pageMargin", $$v)
                          },
                          expression: "facade.pageMargin",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w100 fontW400 ft14 color-black" },
                      [_vm._v("洽购图标")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "upload-img-view item-flex-center",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap(
                              1,
                              "negotiatePurchaseSwitchIcon"
                            )
                          },
                        },
                      },
                      [
                        _vm.facade.negotiatePurchaseSwitchIcon
                          ? _c(
                              "div",
                              {
                                staticClass: "del-upload-img",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delFacadeImg(
                                      "negotiatePurchaseSwitchIcon"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.facade.negotiatePurchaseSwitchIcon
                          ? _c("img", {
                              attrs: {
                                src:
                                  _vm.facade.negotiatePurchaseSwitchIcon +
                                  "!300",
                              },
                            })
                          : _c("img", {
                              staticClass: "upload-icon",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                alt: "",
                              },
                            }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "拍品底色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.itemBackground,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "itemBackground", $$v)
                          },
                          expression: "facade.itemBackground",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "底色渐变" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.itemGradientBackground,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "itemGradientBackground", $$v)
                          },
                          expression: "facade.itemGradientBackground",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "渐变方向" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.facade.itemGradientDirection,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "itemGradientDirection", $$v)
                            },
                            expression: "facade.itemGradientDirection",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "to bottom" } }, [
                            _vm._v("向下"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "to right" } }, [
                            _vm._v("向右"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { label: "to right bottom" } },
                            [_vm._v("右下")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "拍品间距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.itemMargin,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "itemMargin", $$v)
                          },
                          expression: "facade.itemMargin",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "拍品圆角" } },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          { staticClass: "radius-list tl" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value: _vm.facade.itemTopLeftRadius,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "itemTopLeftRadius", $$v)
                                },
                                expression: "facade.itemTopLeftRadius",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "radius-list tr" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value: _vm.facade.itemTopRightRadius,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "itemTopRightRadius",
                                    $$v
                                  )
                                },
                                expression: "facade.itemTopRightRadius",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "radius-list bl" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value: _vm.facade.itemBottomLeftRadius,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "itemBottomLeftRadius",
                                    $$v
                                  )
                                },
                                expression: "facade.itemBottomLeftRadius",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "radius-list br" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value: _vm.facade.itemBottomRightRadius,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "itemBottomRightRadius",
                                    $$v
                                  )
                                },
                                expression: "facade.itemBottomRightRadius",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "阴影" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.itemShadow,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "itemShadow", $$v)
                          },
                          expression: "facade.itemShadow",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "阴影大小" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.itemShadowSize,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "itemShadowSize", $$v)
                          },
                          expression: "facade.itemShadowSize",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _vm.content.style === 5 ||
                  _vm.content.style === 4 ||
                  _vm.content.style === 6
                    ? _c("div", { staticClass: "item-align-center" }, [
                        _c(
                          "div",
                          { staticClass: "w100 fontW400 ft14 color-black" },
                          [_vm._v("图片尺寸")]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "item-align-center" },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    type: "text",
                                    placeholder: "宽",
                                    disabled: _vm.facade.width_auto,
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: _vm.facade.imgWidth,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.facade, "imgWidth", $$v)
                                    },
                                    expression: "facade.imgWidth",
                                  },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v("px"),
                                  ]),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticStyle: { margin: "0 6px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: { type: "text", placeholder: "宽" },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: _vm.facade.imgHeight,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.facade, "imgHeight", $$v)
                                    },
                                    expression: "facade.imgHeight",
                                  },
                                },
                                [
                                  _c("template", { slot: "suffix" }, [
                                    _vm._v("px"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.content.style == 6
                    ? [
                        _c("div", { staticClass: "item-align-center mt20" }, [
                          _c(
                            "div",
                            { staticClass: "w100 fontW400 ft14 color-black" },
                            [_vm._v("宽度自适应")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "flex-one" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#009406",
                                  "inactive-color": "#cccccc",
                                },
                                model: {
                                  value: _vm.facade.width_auto,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "width_auto", $$v)
                                  },
                                  expression: "facade.width_auto",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "展示行数" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.facade.line,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "line", $$v)
                                  },
                                  expression: "facade.line",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("1行"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("2行"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 3 } }, [
                                  _vm._v("3行"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.facade.line == 2 || _vm.facade.line == 3
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "mt20",
                                attrs: { label: "拍品行间距" },
                              },
                              [
                                _c("slider", {
                                  attrs: { min: 0, max: 100 },
                                  model: {
                                    value: _vm.facade.auctionLineMargin,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.facade,
                                        "auctionLineMargin",
                                        $$v
                                      )
                                    },
                                    expression: "facade.auctionLineMargin",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "图片背景颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.imgBackground,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "imgBackground", $$v)
                          },
                          expression: "facade.imgBackground",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "图片区域圆角" } },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          { staticClass: "radius-list tl" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value: _vm.facade.imgTopLeftRadius,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "imgTopLeftRadius", $$v)
                                },
                                expression: "facade.imgTopLeftRadius",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "radius-list tr" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value: _vm.facade.imgTopRightRadius,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "imgTopRightRadius", $$v)
                                },
                                expression: "facade.imgTopRightRadius",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "radius-list bl" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value: _vm.facade.imgBottomLeftRadius,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "imgBottomLeftRadius",
                                    $$v
                                  )
                                },
                                expression: "facade.imgBottomLeftRadius",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "radius-list br" },
                          [
                            _c("el-input-number", {
                              staticClass: "w50",
                              attrs: { controls: false },
                              model: {
                                value: _vm.facade.imgBottomRightRadius,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.facade,
                                    "imgBottomRightRadius",
                                    $$v
                                  )
                                },
                                expression: "facade.imgBottomRightRadius",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _vm.content.style == 6
                    ? [
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "标题距离" } },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                { staticClass: "radius-list t" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.descMarginTop,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "descMarginTop",
                                          $$v
                                        )
                                      },
                                      expression: "facade.descMarginTop",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radius-list r" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.descMarginRight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "descMarginRight",
                                          $$v
                                        )
                                      },
                                      expression: "facade.descMarginRight",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radius-list b" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.descMarginBottom,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "descMarginBottom",
                                          $$v
                                        )
                                      },
                                      expression: "facade.descMarginBottom",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radius-list l" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.descMarginLeft,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "descMarginLeft",
                                          $$v
                                        )
                                      },
                                      expression: "facade.descMarginLeft",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "mt20",
                            attrs: { label: "标题背景颜色" },
                          },
                          [
                            _c("colour", {
                              attrs: { colour: "#FFFFFF" },
                              model: {
                                value: _vm.facade.nameBackground,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "nameBackground", $$v)
                                },
                                expression: "facade.nameBackground",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "标题颜色" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#FFFFFF" },
                              model: {
                                value: _vm.facade.nameColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "nameColor", $$v)
                                },
                                expression: "facade.nameColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "标题字号" } },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 100 },
                              model: {
                                value: _vm.facade.nameFontSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "nameFontSize", $$v)
                                },
                                expression: "facade.nameFontSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "标题样式" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.facade.nameFontWeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "nameFontWeight", $$v)
                                  },
                                  expression: "facade.nameFontWeight",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 400 } }, [
                                  _vm._v("正常"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 500 } }, [
                                  _vm._v("加粗"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 300 } }, [
                                  _vm._v("加细"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "内容距离" } },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                { staticClass: "radius-list t" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.descMarginTop,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "descMarginTop",
                                          $$v
                                        )
                                      },
                                      expression: "facade.descMarginTop",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radius-list r" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.descMarginRight,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "descMarginRight",
                                          $$v
                                        )
                                      },
                                      expression: "facade.descMarginRight",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radius-list b" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.descMarginBottom,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "descMarginBottom",
                                          $$v
                                        )
                                      },
                                      expression: "facade.descMarginBottom",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radius-list l" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.descMarginLeft,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "descMarginLeft",
                                          $$v
                                        )
                                      },
                                      expression: "facade.descMarginLeft",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "h30" }),
                        _vm._v(" "),
                        _vm.content.style == 4
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "LOT距离" },
                                },
                                [
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "radius-list t" },
                                      [
                                        _c("el-input-number", {
                                          staticClass: "w50",
                                          attrs: { controls: false },
                                          model: {
                                            value: _vm.facade.lotMarginTop,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.facade,
                                                "lotMarginTop",
                                                $$v
                                              )
                                            },
                                            expression: "facade.lotMarginTop",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "LOT颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#FFFFFF" },
                                    model: {
                                      value: _vm.facade.lotColor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.facade, "lotColor", $$v)
                                      },
                                      expression: "facade.lotColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "LOT字号" },
                                },
                                [
                                  _c("slider", {
                                    attrs: { min: 0, max: 100 },
                                    model: {
                                      value: _vm.facade.lotFontSize,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.facade, "lotFontSize", $$v)
                                      },
                                      expression: "facade.lotFontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "LOT样式" },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.facade.lotFontWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.facade,
                                            "lotFontWeight",
                                            $$v
                                          )
                                        },
                                        expression: "facade.lotFontWeight",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: 400 } },
                                        [_vm._v("正常")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: 500 } },
                                        [_vm._v("加粗")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: 300 } },
                                        [_vm._v("加细")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "进行中颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#FFFFFF" },
                                    model: {
                                      value: _vm.facade.ingStatusColor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "ingStatusColor",
                                          $$v
                                        )
                                      },
                                      expression: "facade.ingStatusColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "预展中颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#FFFFFF" },
                                    model: {
                                      value: _vm.facade.endStatusColor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "endStatusColor",
                                          $$v
                                        )
                                      },
                                      expression: "facade.endStatusColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "状态字号" },
                                },
                                [
                                  _c("slider", {
                                    attrs: { min: 0, max: 100 },
                                    model: {
                                      value: _vm.facade.ingStatusFontSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "ingStatusFontSize",
                                          $$v
                                        )
                                      },
                                      expression: "facade.ingStatusFontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "状态样式" },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.facade.ingStatusFontWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.facade,
                                            "ingStatusFontWeight",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "facade.ingStatusFontWeight",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: 400 } },
                                        [_vm._v("正常")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: 500 } },
                                        [_vm._v("加粗")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: 300 } },
                                        [_vm._v("加细")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "h30" }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "状态距离" } },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                { staticClass: "radius-list t" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.appraisalPriceMarginTop,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "appraisalPriceMarginTop",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "facade.appraisalPriceMarginTop",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "h30" }),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "标题距离" } },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                { staticClass: "radius-list t" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.nameMarginTop,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "nameMarginTop",
                                          $$v
                                        )
                                      },
                                      expression: "facade.nameMarginTop",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "标题颜色" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#FFFFFF" },
                              model: {
                                value: _vm.facade.nameColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "nameColor", $$v)
                                },
                                expression: "facade.nameColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "标题字号" } },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 100 },
                              model: {
                                value: _vm.facade.nameFontSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "nameFontSize", $$v)
                                },
                                expression: "facade.nameFontSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "标题样式" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.facade.nameFontWeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "nameFontWeight", $$v)
                                  },
                                  expression: "facade.nameFontWeight",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 400 } }, [
                                  _vm._v("正常"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 500 } }, [
                                  _vm._v("加粗"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 300 } }, [
                                  _vm._v("加细"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "h30" }),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "mt20",
                            attrs: { label: "价格区域距离" },
                          },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                { staticClass: "radius-list t" },
                                [
                                  _c("el-input-number", {
                                    staticClass: "w50",
                                    attrs: { controls: false },
                                    model: {
                                      value: _vm.facade.priceMarginTop,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.facade,
                                          "priceMarginTop",
                                          $$v
                                        )
                                      },
                                      expression: "facade.priceMarginTop",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "文字颜色" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#FFFFFF" },
                              model: {
                                value: _vm.facade.priceTextColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "priceTextColor", $$v)
                                },
                                expression: "facade.priceTextColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "文字字号" } },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 100 },
                              model: {
                                value: _vm.facade.priceTextFontSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "priceTextFontSize", $$v)
                                },
                                expression: "facade.priceTextFontSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "文字样式" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.facade.priceTextFontWeight,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.facade,
                                      "priceTextFontWeight",
                                      $$v
                                    )
                                  },
                                  expression: "facade.priceTextFontWeight",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 400 } }, [
                                  _vm._v("正常"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 500 } }, [
                                  _vm._v("加粗"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 300 } }, [
                                  _vm._v("加细"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "价格颜色" } },
                          [
                            _c("colour", {
                              attrs: { colour: "#FFFFFF" },
                              model: {
                                value: _vm.facade.priceColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "priceColor", $$v)
                                },
                                expression: "facade.priceColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "价格字号" } },
                          [
                            _c("slider", {
                              attrs: { min: 0, max: 100 },
                              model: {
                                value: _vm.facade.priceFontSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "priceFontSize", $$v)
                                },
                                expression: "facade.priceFontSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "mt20", attrs: { label: "价格样式" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.facade.priceFontWeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "priceFontWeight", $$v)
                                  },
                                  expression: "facade.priceFontWeight",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 400 } }, [
                                  _vm._v("正常"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 500 } }, [
                                  _vm._v("加粗"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 700 } }, [
                                  _vm._v("特粗"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 300 } }, [
                                  _vm._v("加细"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "current-data": _vm.content.data },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }