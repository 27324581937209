"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.replace");
var _setting = require("@/api/setting");
var _lodash = require("lodash");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SystemRole',
  data: function data() {
    return {
      grid: {
        xl: 7,
        lg: 7,
        md: 12,
        sm: 24,
        xs: 24
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20,
        role_name: '',
        level: '',
        status: '',
        startTime: '',
        endTime: ''
      },
      isIndeterminate: true,
      oneRuleList: [],
      twoRuleList: [],
      fourRuleList: [],
      threeRuleList: [],
      infoRuleList: [],
      fieldRuleList: [],
      oneall: false,
      twoall: false,
      threeall: false,
      infoall: false,
      fieldall: false,
      rulename: '',
      showruleProp: false,
      editid: '',
      oneitem: {},
      twoitem: {},
      fouritem: {},
      threeitem: {},
      searchLoading: false,
      peopleList: [],
      changePchangePersonInChargeList: [],
      changeStaffList: [],
      personInCharge: [],
      staffList: []
    };
  },
  computed: {
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    },
    pchangePersonInChargeList: function pchangePersonInChargeList() {
      return this.removeDuplicates(this.changePchangePersonInChargeList.concat(this.peopleList));
    },
    changeStaffAllList: function changeStaffAllList() {
      return this.removeDuplicates(this.changeStaffList.concat(this.peopleList));
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _setting.adminListApi)({
      page: 1,
      limit: 100
    }).then(function (res) {
      _this.peopleList = res.data.list;
    }).catch(function (res) {
      // this.$message.error(res.message);
    });
  },
  methods: {
    removeDuplicates: function removeDuplicates(array) {
      // 使用 Set 通过 JSON.stringify 去重
      var uniqueArray = Array.from(new Set(array.map(JSON.stringify))).map(JSON.parse);
      return uniqueArray;
    },
    remoteMethod: function remoteMethod(name) {
      var _this2 = this;
      if (!name) return;
      if (this.searchLoading) return;
      this.searchLoading = true;
      var data = {
        page: 1,
        limit: 10,
        keyword: name
      };
      (0, _setting.adminListApi)(data).then(function (res) {
        var d = [];
        res.data.list.forEach(function (m) {
          d.push({
            merchant_admin_id: m.merchant_admin_id,
            real_name: m.real_name
          });
        });
        _this2.peopleList = d;
        _this2.searchLoading = false;
      }).catch(function () {
        _this2.searchLoading = false;
      });
    },
    changePersonInCharge: function changePersonInCharge(e) {
      var _this3 = this;
      var d = [];
      var ids = this.pchangePersonInChargeList.map(function (m) {
        return m.merchant_admin_id;
      });
      e.forEach(function (m) {
        var index = ids.indexOf(m);
        if (index !== -1) {
          d.push(_this3.pchangePersonInChargeList[index]);
        }
      });
      this.changePchangePersonInChargeList = d;
    },
    changeStaff: function changeStaff(e) {
      var _this4 = this;
      var d = [];
      var ids = this.changeStaffAllList.map(function (m) {
        return m.merchant_admin_id;
      });
      e.forEach(function (m) {
        var index = ids.indexOf(m);
        if (index !== -1) {
          d.push(_this4.changeStaffAllList[index]);
        }
      });
      this.changeStaffList = d;
    },
    saverule: function saverule() {
      var _this5 = this;
      if (!this.rulename) {
        this.$message.error('请输入部门名称');
        return false;
      }
      var data = {
        name: this.rulename,
        staff_ids: this.staffList.join(','),
        leader_ids: this.personInCharge.join(',')
      };
      var action = _setting.departmentCreateApi;
      if (this.editid) {
        action = _setting.departmentUpdateApi;
        data.id = this.editid;
      }
      action(data).then(function (res) {
        _this5.$message.success('操作成功');
        _this5.showruleProp = false;
        _this5.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this6 = this;
      this.listLoading = true;
      (0, _setting.departmentListApi)(this.tableFrom).then(function (res) {
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        _this6.listLoading = false;
        // this.$message.error(res.message)
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this7 = this;
      (0, _setting.roleStatusApi)(row.role_id, row.status).then(function (_ref) {
        var message = _ref.message;
        _this7.$message.success(message);
      }).catch(function (_ref2) {
        var message = _ref2.message;
        _this7.$message.error(message);
      });
    },
    // 添加
    onAdd: function onAdd() {
      // this.$modalForm(roleCreateApi()).then(() => this.getList());
      this.editid = '';
      this.rulename = '';
      this.staffList = [];
      this.personInCharge = [];
      this.showruleProp = true;
    },
    // 编辑
    onEdit: function onEdit(id, row) {
      this.editid = id;
      this.rulename = row.name;
      // const staffl = [];
      // const leaderl = [];
      // if (row.departmentAdmin && row.departmentAdmin.length) {
      //   row.departmentAdmin.forEach(m => {
      //     const lIndex = row.leader_ids.indexOf(m.admin.merchant_admin_id);
      //     if (lIndex !== -1) {
      //       leaderl.push(m.admin);
      //     }
      //     const sIndex = row.staff_ids.indexOf(m.admin.merchant_admin_id);
      //     if (sIndex !== -1) {
      //       staffl.push(m.admin);
      //     }
      //   });
      // }
      // this.peopleList = [];
      // this.changeStaffList = staffl;
      // this.changePchangePersonInChargeList = leaderl;

      this.personInCharge = row.leader_ids;
      this.staffList = row.staff_ids;
      this.showruleProp = true;
      // this.$modalForm(roleUpdateApi(id)).then(() => this.getList());
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this8 = this;
      this.$modalSure().then(function () {
        (0, _setting.departmentDelApi)({
          id: id
        }).then(function (_ref3) {
          var message = _ref3.message;
          _this8.$message.success(message);
          _this8.getList();
        }).catch(function (_ref4) {
          var message = _ref4.message;
          _this8.$message.error(message);
        });
      });
    }
  }
};