var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.content.type === "name"
        ? [
            _c(
              "div",
              {
                style: {
                  marginTop: _vm.facade.paddingTop / 2 + "px",
                  lineHeight: _vm.facade.meeting_lineHeight / 2 + "px",
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.area_background,
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      fontSize: _vm.facade.meeting_fontSize / 2 + "px",
                      fontWeight: _vm.facade.meeting_fontWeight,
                      color: _vm.facade.meeting_color,
                    },
                  },
                  [_vm._v("第三届大众艺术品拍卖会")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                style: {
                  lineHeight: _vm.facade.lineHeight / 2 + "px",
                  padding:
                    " " +
                    _vm.facade.nameMarginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.area_background,
                },
              },
              [
                _c(
                  "span",
                  {
                    style: {
                      fontSize: _vm.facade.fontSize / 2 + "px",
                      fontWeight: _vm.facade.fontWeight,
                      color: _vm.facade.color,
                    },
                  },
                  [_vm._v("书画专场传承经典抽象画艺术大展")]
                ),
              ]
            ),
          ]
        : _vm.content.type === "status"
        ? [
            _c(
              "div",
              {
                staticClass: "flex align-items-c justify-b",
                style: {
                  marginTop: _vm.facade.paddingTop / 2 + "px",
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.area_background,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "time1 flex align-items-c",
                    style: {
                      color: _vm.facade.timeColor,
                      fontSize: _vm.facade.fontSize,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tag-item flex align-items-c",
                        style: {
                          background: "#F4F5F9",
                          "border-radius": "4px",
                          color: "#363636",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "status",
                            style: {
                              background: "#FF4C4C",
                              "border-radius": "4px",
                              color: "#ffffff",
                            },
                          },
                          [_vm._v("预展中")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text" }, [
                          _vm._v("距开拍 20分20秒"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "r-btn flex align-items-c",
                    style: {
                      color: _vm.facade.color,
                      fontWeight: _vm.facade.fontWeight,
                      "font-size": _vm.facade.fontSize / 2 + "px",
                    },
                  },
                  [
                    _vm.facade.remindIcon
                      ? _c("img", {
                          style:
                            "margin-right: " +
                            _vm.facade.remindMargin / 2 +
                            "px;",
                          attrs: { src: _vm.facade.remindIcon },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { position: "relative", bottom: "-1px" } },
                      [_vm._v("开拍提醒")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "detail"
        ? [
            _c(
              "div",
              {
                style: {
                  marginTop: _vm.facade.paddingTop / 2 + "px",
                  padding:
                    _vm.facade.marginTop / 2 +
                    "px " +
                    _vm.pageInfo.page_margin / 2 +
                    "px 0 " +
                    _vm.pageInfo.page_margin / 2 +
                    "px",
                  background: _vm.facade.area_background,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "relative overflow-hidden",
                    style: {
                      "border-radius": _vm.facade.img_radius / 2 + "px",
                    },
                  },
                  [
                    _vm.pageInfo.show_header_view
                      ? _c("img", {
                          style: { width: "100%", display: "block" },
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pageInfo.show_header_view
                      ? _c(
                          "div",
                          {
                            staticClass: "img-pop absolute flex align-items-c",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex align-items-c ml10",
                                style: {
                                  color: _vm.facade.hotColor,
                                  "font-size":
                                    _vm.facade.hotFontSize / 2 + "px",
                                  "font-weight": _vm.facade.hotFontWeight,
                                },
                              },
                              [
                                _vm.facade.hotIcon
                                  ? _c("img", {
                                      style: {
                                        width:
                                          _vm.facade.hotFontSize / 2 + "px",
                                        height:
                                          _vm.facade.hotFontSize / 2 + "px",
                                      },
                                      attrs: { src: _vm.facade.hotIcon },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "txt" }, [
                                  _vm._v("6531"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex align-items-c ml10",
                                style: {
                                  color: _vm.facade.bidCountColor,
                                  "font-size":
                                    _vm.facade.bidCountFontSize / 2 + "px",
                                  "font-weight": _vm.facade.bidCountFontWeight,
                                },
                              },
                              [
                                _vm.facade.bidCountIcon
                                  ? _c("img", {
                                      style: {
                                        width:
                                          _vm.facade.hotFontSize / 2 + "px",
                                        height:
                                          _vm.facade.hotFontSize / 2 + "px",
                                      },
                                      attrs: { src: _vm.facade.bidCountIcon },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "txt" }, [
                                  _vm._v("6531"),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    style: {
                      "padding-top": _vm.facade.infoMarginTop / 2 + "px",
                      "padding-bottom": _vm.facade.infoMarginBottom / 2 + "px",
                    },
                  },
                  [
                    _c("div", { staticClass: "desc flex align-items-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "desc-item flex-one flex align-items-c",
                          style: {
                            fontSize: _vm.facade.fontSize / 2 + "px",
                            color: _vm.facade.color,
                            height: _vm.facade.lineHeight / 2 + "px",
                          },
                        },
                        [
                          _c("div", { staticClass: "item flex-one" }, [
                            _vm._v("拍品：52件（LOT 108-265）"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item flex-one flex align-items-c" },
                            [
                              _c("span", [_vm._v("保证金：5000元 或 1:5")]),
                              _vm._v(" "),
                              _vm.facade.moreIcon
                                ? _c("img", {
                                    style: {
                                      width: _vm.facade.fontSize / 2 + "px",
                                      height: _vm.facade.fontSize / 2 + "px",
                                    },
                                    attrs: { src: _vm.facade.moreIcon },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "desc flex align-items-c" }, [
                      _c(
                        "div",
                        {
                          staticClass: "desc-item flex-one flex align-items-c",
                          style: {
                            fontSize: _vm.facade.fontSize / 2 + "px",
                            color: _vm.facade.color,
                            height: _vm.facade.lineHeight / 2 + "px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "item flex-one flex align-items-c" },
                            [
                              _c("span", [_vm._v("文物局批复 | 参拍须知")]),
                              _vm._v(" "),
                              _vm.facade.moreIcon
                                ? _c("img", {
                                    style: {
                                      width: _vm.facade.fontSize / 2 + "px",
                                      height: _vm.facade.fontSize / 2 + "px",
                                    },
                                    attrs: { src: _vm.facade.moreIcon },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item flex-one flex align-items-c" },
                            [
                              _c("span", [_vm._v("佣金：10% - 15%")]),
                              _vm._v(" "),
                              _vm.facade.moreIcon
                                ? _c("img", {
                                    style: {
                                      width: _vm.facade.fontSize / 2 + "px",
                                      height: _vm.facade.fontSize / 2 + "px",
                                    },
                                    attrs: { src: _vm.facade.moreIcon },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        : _vm.content.type === "list"
        ? [
            _c(
              "div",
              {
                style: {
                  marginTop: _vm.facade.marginTop / 2 + "px",
                  background: _vm.facade.area_background,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab flex align-items-c",
                    style: {
                      height: _vm.facade.tabHeight / 2 + "px",
                      padding: "0 " + _vm.pageInfo.page_margin / 2 + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-one flex align-items-c h-full",
                        style: {
                          "font-size": _vm.facade.tabFontSize / 2 + "px",
                          color: _vm.facade.tabColor,
                          "font-weight": _vm.facade.tabFontWeight,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item curr",
                            style: {
                              "font-size":
                                _vm.facade.currTabFontSize / 2 + "px",
                              color: _vm.facade.currTabColor,
                              "font-weight": _vm.facade.currTabFontWeight,
                            },
                          },
                          [
                            _vm._v("本场拍品\n            "),
                            _c("div", {
                              staticClass: "bg",
                              style: { background: _vm.facade.currTabColor },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(" "),
                        _vm._m(1),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "icon flex align-items-c" }, [
                      _vm.facade.listIcon
                        ? _c("img", { attrs: { src: _vm.facade.listIcon } })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.searchIcon
                        ? _c("img", { attrs: { src: _vm.facade.searchIcon } })
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "list",
                staticStyle: { padding: "20px 0" },
                style: "background: " + _vm.facade.area_background,
              },
              [
                _vm.listFacade
                  ? _c("auctionList", {
                      attrs: {
                        facade: Object.assign(
                          {},
                          _vm.listFacade,
                          _vm.listFacade.type_setting[_vm.facade.listShow],
                          { pageMargin: _vm.pageInfo.page_margin }
                        ),
                        content: { type: "list", style: _vm.facade.listShow },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _vm._v("我出价的\n            "),
      _c("div", { staticClass: "bg" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _vm._v("文物局批复\n            "),
      _c("div", { staticClass: "bg" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }