"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _member = require("@/api/member");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isPlatform: localStorage.getItem('identityid') === '11',
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      searchName: '',
      searchGoodsId: ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  watch: {
    flag: function flag(val) {
      console.log(val);
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
  },
  methods: {
    changeshow: function changeshow() {
      this.selectedAuction = JSON.parse(JSON.stringify(this.currentData));
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.resultData.length === 0) {
        this.loadProduct();
      }
      this.showSelectAuction = true;
    },
    searchGoods: function searchGoods() {
      this.queryParams.keyword = this.searchName;
      this.queryParams.page = 1;
      this.loadProduct();
    },
    loadProduct: function loadProduct(fun) {
      var _this = this;
      (0, _member.membershipRightListApi)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        response.data.list.forEach(function (m) {
          if (m.right_type === 2 || m.right_type === 3 || m.right_type === 6) {
            m.goodsCount = 0;
            m.right_give_data.forEach(function (c) {
              m.goodsCount += Number(c.number);
            });
          }
          switch (m.right_type) {
            case 0:
              m.showDesc = m.right_type_text;
              break;
            case 1:
              m.showDesc = "\u8D60\u9001".concat(m.right_type_text);
              break;
            case 2:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u4EF6\u5546\u54C1");
              break;
            case 3:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u5F20\u95E8\u7968");
              break;
            case 4:
              m.showDesc = "\u8D60\u9001".concat(m.right_give_data[0].number, "\u79EF\u5206");
              break;
            case 5:
              m.showDesc = "\u8D60\u9001".concat(m.right_give_data[0].number, "\u4F59\u989D");
              break;
            case 6:
              m.showDesc = "\u8D60\u9001".concat(m.goodsCount, "\u5F20\u4F18\u60E0\u5238");
              break;
          }
        });
        _this.resultData = response.data.list;
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var m = this.resultData[index];
      var data = {
        image: m.right_image,
        name: m.right_name,
        id: m.right_id,
        showDesc: m.showDesc
      };
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.size === '1') {
        this.cursorSelect();
      }
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      this.$emit('selectMessage', {
        type: 'benefits',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    changePageSize: function changePageSize(size) {
      this.queryParams.size = size;
      this.queryParams.page = 1;
      this.loadProduct();
    },
    changePage: function changePage(page) {
      this.queryParams.page = page;
      this.loadProduct();
    }
  }
};