"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _marketing = require("@/api/marketing");
var _index = require("@/utils/index");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { SourceNode } from "source-map";
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      tdgameflag: false,
      tabeldata: [],
      parseTime: _index.parseTime,
      lastime: '',
      editTableData: [],
      showEditFlag: false,
      formValidate: {
        agreement: '',
        //协议   
        caption: '' //玩法说明
      }
    };
  },
  mounted: function mounted() {
    this.getConfigDetail();
  },
  methods: {
    changegameflag: function changegameflag(e) {
      var _this2 = this;
      (0, _marketing.setGameTowerSwitch)({
        status: this.tdgameflag
      }).then(function () {
        _this2.$message.success('修改成功');
      });
    },
    editSwitch: function editSwitch() {
      this.showEditFlag = true;
    },
    getConfigDetail: function getConfigDetail() {
      var _this3 = this;
      (0, _marketing.getGameTowerConfig)().then(function (res) {
        console.log(res);
        _this3.tdgameflag = res.data.status;
        _this3.tabeldata = res.data.game_conf;
        _this3.editTableData = JSON.parse(JSON.stringify(res.data.game_conf));
        _this3.formValidate.caption = res.data.caption || '';
        _this3.formValidate.agreement = res.data.agreement || '';
        _this3.lastime = res.data.last_time;
      });
    },
    setConfig: function setConfig() {
      var _this4 = this;
      var json = {};
      // json.status = this.tdgameflag;
      json.game_conf = this.editTableData;
      json.caption = this.formValidate.caption;
      json.agreement = this.formValidate.agreement;
      (0, _marketing.setGameTowerConfig)(json).then(function (res) {
        _this4.$message.success('保存成功');
        _this4.showEditFlag = false;
        _this4.tabeldata = JSON.parse(JSON.stringify(_this4.editTableData));
        // this.getConfigDetail();
      });
    },
    // 修改图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit == 1) {
          console.log(img);
          _this.editTableData[num].attack_target_img = img[0];
        }
      }, tit);
    },
    handleRemove: function handleRemove(type) {
      this.formValidate[type] = '';
    },
    changeprobility: function changeprobility() {
      var all = 100;
      this.editTableData.forEach(function (item, index) {
        if (index > 0) {
          all -= item.probability;
        }
      });
      this.editTableData[0].probability = all;
    }
  }
};