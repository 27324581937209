var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "mt20" },
      [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("创建熔炉规则")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData.data,
              size: "small",
              "row-key": "lottery_id",
            },
          },
          [
            _c("el-table-column", {
              attrs: { width: "140", label: "规则名称", prop: "name" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "关联藏品", "min-width": "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.getproduct(scope.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(scope.row.product.length))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "系列", "min-width": "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v(_vm._s(scope.row.album.length)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "规则", "min-width": "135" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.type == 1
                        ? _c("span", [
                            _vm._v(
                              "按发行价1元兑换" +
                                _vm._s(scope.row.number) +
                                "积分"
                            ),
                          ])
                        : scope.row.type == 2
                        ? _c("span", [
                            _vm._v(
                              "固定兑换" + _vm._s(scope.row.number) + "积分"
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "有效期", "min-width": "220" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.parseTime(scope.row.start_time)) +
                            "-" +
                            _vm._s(_vm.parseTime(scope.row.end_time))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status == 10
                        ? _c("span", [_vm._v("待开始")])
                        : scope.row.status == 20
                        ? _c("span", [_vm._v("生效中")])
                        : scope.row.status == 30
                        ? _c("span", [_vm._v("已过期")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "100", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.onEdit(scope.row.rule_id)
                            },
                          },
                        },
                        [_vm._v("\n            编辑\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(
                                scope.row.rule_id,
                                scope.$index
                              )
                            },
                          },
                        },
                        [_vm._v("\n            删除\n          ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "block" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.tableData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("file-list", { ref: "exportList" }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.showImg,
              "close-on-click-modal": false,
              center: "",
              title: "查看二维码",
              width: "500px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showImg = $event
              },
            },
          },
          [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.showImgUrl, alt: "" },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }