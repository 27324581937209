"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'HeaderNotice',
  props: {
    name: String,
    isFixed: {
      type: Boolean,
      default: false
    },
    hasBack: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    backAction: function backAction() {
      this.$router.back();
    }
  }
};