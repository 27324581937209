"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _axios = _interopRequireDefault(require("axios"));
var _store = _interopRequireDefault(require("@/store"));
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _elementUi = require("element-ui");
var instance = _axios.default.create({
  baseURL: _settingMer.default.https,
  timeout: 120000
});
var defaultOpt = {
  login: true
};
function baseRequest(options) {
  var token = _store.default.getters.token || localStorage.getItem('token');
  var headers = options.headers || {};
  if (token) {
    headers['X-Token'] = token;
    options.headers = headers;
  }
  return new Promise(function (resolve, reject) {
    instance(options).then(function (res) {
      var data = res.data || {};
      if (res.status !== 200) {
        return reject({
          message: '请求失败',
          res: res,
          data: data
        });
      }
      if ([401, 402].indexOf(data.status) !== -1) {
        // alert('又要弹出登录了');
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      } else if (data.status === 200 || data.code === 200) {
        return resolve(data, res);
      } else {
        if (options.url !== 'auction/SynAuction/sceneBidPrice') {
          _elementUi.Message.error(data.message);
        }
        return reject({
          message: data.message,
          res: res,
          data: data
        });
      }
    }).catch(function (message) {
      return reject({
        message: message
      });
    });
  });
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
var request = ['post', 'put', 'patch', 'delete'].reduce(function (request, method) {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = function (url) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return baseRequest(Object.assign({
      url: url,
      data: data,
      method: method
    }, defaultOpt, options));
  };
  return request;
}, {});
['get', 'head'].forEach(function (method) {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = function (url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return baseRequest(Object.assign({
      url: url,
      params: params,
      method: method
    }, defaultOpt, options));
  };
});
var _default = exports.default = request;