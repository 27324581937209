var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main",
      style: "height:100vh;width: 100vw;overflow:hidden;",
    },
    [
      _c(
        "div",
        { staticClass: "top", style: "height: " + 149 * _vm.fix + "px;" },
        [
          _c(
            "div",
            {
              staticClass: "view item-align-center",
              style: "transform:scale(" + _vm.fix + ");transform-origin: 0 0;",
            },
            [
              _c("div", { staticClass: "lot" }, [
                _vm._v(_vm._s(_vm.auction.lot || "")),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "shu" }),
              _vm._v(" "),
              _c("div", { staticClass: "name flex-one" }, [
                _vm._v(_vm._s(_vm.auction.product.store_name || "")),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.minutes) + ":" + _vm._s(_vm.second)),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "heng" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "desc-view", style: "height: " + 182 * _vm.fix + "px;" },
        [
          _c("div", {
            staticClass: "desc",
            style:
              (_vm.recordList &&
              _vm.recordList[0] &&
              _vm.recordList[0].uid != 584021 &&
              _vm.recordList[0].is_confirm == 0
                ? "opacity: 0;"
                : "") +
              ("transform:scale(" + _vm.fix + ");transform-origin: 0 0;"),
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "content-view",
              style: "height: " + 408 * _vm.fix + "px;",
            },
            [
              _c(
                "div",
                {
                  staticClass: "content",
                  style:
                    (_vm.auction &&
                      _vm.auction.auction_status == 1 &&
                      _vm.recordList &&
                      _vm.recordList[0] &&
                      _vm.recordList[0].uid != 584021 &&
                      _vm.recordList[0].is_confirm == 0) ||
                    _vm.countDownTime.length > 0
                      ? "opacity: 0;"
                      : "",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "view item-align-center",
                      style:
                        "transform:scale(" +
                        _vm.fix +
                        ");transform-origin: 0 0;",
                    },
                    [
                      _c("div", { staticClass: "img item-flex-center" }, [
                        _vm.auction.product.image
                          ? _c("img", {
                              attrs: {
                                src: _vm.auction.product.image + "!m640",
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "auction-price" }, [
                        _c("div", { staticClass: "t1" }, [_vm._v("当前价格")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "t2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.albumData && _vm.albumData.feeTypeInfo
                                ? _vm.albumData.feeTypeInfo.type == "CNY"
                                  ? "RMB"
                                  : _vm.albumData.feeTypeInfo.type
                                : ""
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "t3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.nowPrice
                                ? _vm.formatPriceNumber(_vm.nowPrice, 2)
                                : "暂无出价"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "t4",
                            style:
                              _vm.nowRecord && _vm.nowRecord.uid == 8888
                                ? "line-height: 100px;"
                                : "",
                          },
                          [
                            _vm.nowRecord && _vm.nowRecord.uid == 8888
                              ? _c("div", { staticClass: "txt" }, [
                                  _vm._v("现场买家"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.nowRecord &&
                            _vm.nowRecord.uid != 8888 &&
                            _vm.nowRecord.user
                              ? _c("div", { staticClass: "txt" }, [
                                  _vm._v(_vm._s(_vm.nowRecord.user.nickname)),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "auction-shu" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "auction-right flex-one" }, [
                        _c("div", { staticClass: "t1" }, [_vm._v("上一口价")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "t2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.albumData && _vm.albumData.feeTypeInfo
                                ? _vm.albumData.feeTypeInfo.type == "CNY"
                                  ? "RMB"
                                  : _vm.albumData.feeTypeInfo.type
                                : ""
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "t3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.lastRecord.bid_price
                                ? _vm.formatPriceNumber(
                                    _vm.lastRecord.bid_price,
                                    2
                                  )
                                : "无"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "t4",
                            style:
                              _vm.lastRecord && _vm.lastRecord.uid == 8888
                                ? "line-height: 100px;"
                                : "",
                          },
                          [
                            _vm.lastRecord && _vm.lastRecord.uid == 8888
                              ? _c("div", { staticClass: "txt" }, [
                                  _vm._v("现场买家"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.lastRecord && _vm.lastRecord.uid != 8888
                              ? _c("div", { staticClass: "txt" }, [
                                  _vm._v("网络买家"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.lastRecord &&
                            _vm.lastRecord.uid != 8888 &&
                            _vm.lastRecord.user
                              ? _c("div", { staticClass: "txt" }, [
                                  _vm._v(_vm._s(_vm.lastRecord.user.nickname)),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.countDownTime.length > 0
        ? _vm._l(_vm.countDownTime, function (m, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "bid-confirm item-align-center",
                style:
                  "transform:scale(" +
                  _vm.fix +
                  ");transform-origin: 0 0;top:" +
                  208 * _vm.fix +
                  "px;background: rgb(228, 84, 73);box-shadow: 0px 0px 20px 0px #E45249;",
              },
              [
                _c("div", { staticClass: "bid-price flex-one" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v("网络买家 " + _vm._s(m.number) + "号 正在充值"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "price",
                      staticStyle: { "margin-top": "20px", color: "#ffffff" },
                    },
                    [_vm._v("00:" + _vm._s(m.time))]
                  ),
                ]),
              ]
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.auction &&
      _vm.auction.auction_status == 1 &&
      _vm.recordList &&
      _vm.recordList[0] &&
      _vm.recordList[0].uid != 584021 &&
      _vm.recordList[0].is_confirm == 0
        ? _c(
            "div",
            {
              staticClass: "bid-confirm item-align-center",
              style:
                "transform:scale(" +
                _vm.fix +
                ");transform-origin: 0 0;top:" +
                208 * _vm.fix +
                "px;",
            },
            [
              _c("div", { staticClass: "bid-price flex-one" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v(
                    _vm._s(
                      _vm.albumData && _vm.albumData.feeTypeInfo
                        ? _vm.albumData.feeTypeInfo.type == "CNY"
                          ? "RMB"
                          : _vm.albumData.feeTypeInfo.type
                        : ""
                    ) +
                      " " +
                      _vm._s(
                        _vm.formatPriceNumber(_vm.recordList[0].bid_price, 2)
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [
                  _vm._v("网络买家 " + _vm._s(_vm.recordList[0].user.nickname)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.recordConfirmAction(_vm.recordList[0].bid_id)
                    },
                  },
                },
                [_vm._v("确认有效")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.end
        ? _c("div", { staticClass: "is-end item-flex-center" }, [
            _c("div", { staticClass: "end-view" }, [
              _c("div", { staticClass: "top-name" }, [
                _vm._v(_vm._s(_vm.albumData.album_name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "top1" }, [
                _vm._v(
                  _vm._s(
                    _vm.parseTime(
                      _vm.albumEndData.end_time,
                      "{y}年{m}月{d}日 {h}:{i}"
                    )
                  ) + " 结拍"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center1" }, [
                _vm._v(
                  "本场" +
                    _vm._s(_vm.albumEndData.total_num) +
                    "件/成交" +
                    _vm._s(_vm.albumEndData.deal_num) +
                    "件/成交率" +
                    _vm._s(_vm.albumEndData.deal_rate) +
                    "%"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center2" }, [
                _vm._v(
                  "本场成交额" +
                    _vm._s(
                      _vm.albumData && _vm.albumData.feeTypeInfo
                        ? _vm.albumData.feeTypeInfo.type == "CNY"
                          ? "RMB"
                          : _vm.albumData.feeTypeInfo.type
                        : "RMB"
                    ) +
                    " " +
                    _vm._s(
                      _vm.formatPriceNumber(
                        Number(_vm.albumEndData.deal_price),
                        2
                      )
                    )
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom-copyright" }, [
                _vm._v("艺天下提供技术支持"),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }