"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var auctionRouter = {
  path: "".concat(_settings.roterPre, "/digitalCollection"),
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
  meta: {
    title: '数字藏品',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'works',
    name: 'digitalCollectionWorks',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/index'));
      });
    },
    redirect: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
    meta: {
      title: '数字藏品',
      noCache: true
    },
    children: [{
      path: 'album',
      name: 'digitalCollection_album_list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/albumList'));
        });
      },
      meta: {
        title: '系列管理',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
        permissionKey: '/digitalCollection/works/album'
      }
    }, {
      path: 'list',
      name: 'digitalCollection_list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/workList'));
        });
      },
      meta: {
        title: '数字藏品',
        noCache: true,
        permissionKey: '/digitalCollection/works/list'
      }
    }, {
      path: 'pass_list',
      name: 'digitalCollection_pass_list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/passList'));
        });
      },
      meta: {
        title: '通证列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
        permissionKey: '/digitalCollection/works/pass_list'
      }
    }, {
      path: 'recovery_record',
      name: 'digitalCollection_recoveryRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/recoveryRecord'));
        });
      },
      meta: {
        title: '回收记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
        permissionKey: '/digitalCollection/works/recovery_record'
      }
    }, {
      path: 'destroy_record',
      name: 'digitalCollection_destroyRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/destroyRecord'));
        });
      },
      meta: {
        title: '销毁记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
        permissionKey: '/digitalCollection/works/destroy_record'
      }
    }, {
      path: 'gift_record',
      name: 'digitalCollection_giftRecord',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/giftRecord'));
        });
      },
      meta: {
        title: '赠与记录',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
        permissionKey: '/digitalCollection/works/gift_record'
      }
    }, {
      path: 'deliver_list',
      name: 'digitalCollection_deliverList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/deliverList'));
        });
      },
      meta: {
        title: '空投列表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
        permissionKey: '/digitalCollection/works/deliver_list'
      }
    }, {
      path: 'transfer_rule',
      name: 'digitalCollection_transferRule',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/transferRule'));
        });
      },
      meta: {
        title: '转赠规则',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
        permissionKey: '/digitalCollection/works/transfer_rule'
      }
    }, {
      path: 'nft_rule',
      name: 'digitalCollection_nft_rule',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/rule'));
        });
      },
      meta: {
        title: '配置',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/digitalCollection/works/list"),
        permissionKey: '/digitalCollection/works/nft_rule'
      }
    }]
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/works/detail"),
    name: 'digitalCollection_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/workDetail'));
      });
    },
    meta: {
      title: '数字藏品详情',
      noCache: true
    }
  }, {
    path: "".concat(_settings.roterPre, "/digitalCollection/works/album_detail"),
    name: 'digitalCollection_album_detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digitalCollection/albumDetail'));
      });
    },
    meta: {
      title: '数字藏品详情',
      noCache: true
    }
  }]
};
var _default = exports.default = auctionRouter;