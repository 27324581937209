var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addAction },
              },
              [_vm._v("添加等级")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              staticStyle: { width: "100%", color: "#000" },
              attrs: {
                border: "",
                data: _vm.tableData.data,
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "等级图标", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: {
                            width: "37px",
                            height: "37px",
                            display: "block",
                          },
                          attrs: {
                            src: scope.row.level_icon + "!120a",
                            alt: "",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "等级名称", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.level_name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "升级成长值", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(scope.row.upgrade_growth_value)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商城折扣", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product_discount_percent != 100
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.product_discount_percent / 10
                                ) + "折"
                              ),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "门票折扣", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ticket_discount_percent != 100
                          ? _c("div", [
                              _vm._v(
                                _vm._s(scope.row.ticket_discount_percent / 10) +
                                  "折"
                              ),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "鉴定折扣", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.identification_discount_percent != 100
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.identification_discount_percent / 10
                                ) + "折"
                              ),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "查看答案折扣比例", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extend_data &&
                        scope.row.extend_data
                          .identification_study_discount_percent != 100
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.extend_data
                                    .identification_study_discount_percent / 10
                                ) + "折"
                              ),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拍卖佣金折扣比例", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extend_data &&
                        scope.row.extend_data
                          .auction_commission_discount_percent &&
                        scope.row.extend_data
                          .auction_commission_discount_percent != 100
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.extend_data
                                    .auction_commission_discount_percent / 10
                                ) + "折"
                              ),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "其他权益", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.rightItem && scope.row.rightItem.length > 0
                          ? _vm._l(scope.row.rightItem, function (m, i) {
                              return _c("div", { key: i }, [
                                _vm._v(_vm._s(m.showDesc)),
                              ])
                            })
                          : [_c("div", [_vm._v("-")])],
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.create_time))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAction(scope.row.level_id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(scope.row.level_id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex" },
            [
              _c("el-pagination", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  background: "",
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }