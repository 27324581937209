"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _ticket = require("@/api/ticket");
var _index = require("@/utils/index");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20
      },
      parseTime: _index.parseTime,
      showAddPeople: {
        show: false,
        uid: '',
        btnLoading: false
      },
      searchLoading: false,
      timeout: null,
      userList: []
    };
  },
  mounted: function mounted() {
    var queryData = JSON.parse(JSON.stringify(this.$route.query));
    if (Number(queryData.page)) {
      this.tableFrom = queryData;
      this.tableFrom.page = Number(this.tableFrom.page);
      this.tableFrom.limit = Number(this.tableFrom.limit);
    }
    this.getList(1);
  },
  methods: {
    createdAction: function createdAction() {
      this.showAddPeople = {
        show: true,
        uid: '',
        btnLoading: false
      };
    },
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      this.$router.replace({
        query: this.tableFrom
      });
      (0, _ticket.ticketCheckerListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.data.list;
        _this.tableData.total = res.data.count;
        _this.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    searchUserAction: function searchUserAction(data) {
      var _this2 = this;
      this.searchLoading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        var params = {
          keyword: data,
          page: 1,
          limit: 10
        };
        (0, _user.userLstApi)(params).then(function (res) {
          _this2.userList = res.data.list;
          _this2.searchLoading = false;
        });
        _this2.timeout = null;
      }, 500);
    },
    saveAddPeopleAction: function saveAddPeopleAction() {
      var _this3 = this;
      (0, _ticket.ticketCheckerCreateApi)({
        uid: this.showAddPeople.uid
      }).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.showAddPeople.show = false;
        _this3.getList();
      });
    },
    editSortAction: function editSortAction(m) {
      console.log(m.sort);
    },
    editAction: function editAction(id) {
      this.$router.push('./detail?id=' + id);
    },
    delAction: function delAction(id) {
      var _this4 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u8BE5\u6838\u9500\u5458?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _ticket.ticketCheckerDelApi)({
          uid: id
        }).then(function () {
          _this4.$message.success('操作成功');
          _this4.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};