"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _yeepay = _interopRequireDefault(require("@/utils/yeepay"));
var _system = require("@/api/system");
var _accounts = require("@/api/accounts");
var _settings = require("@/settings");
var _uploadPDF = _interopRequireDefault(require("./uploadPDF"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { cityList } from "@/api/freight";
var _default = exports.default = {
  name: "llpay",
  components: {
    uploadPDF: _uploadPDF.default
  },
  data: function data() {
    return {
      currentTab: 0,
      opentype: "",
      cityList: [],
      fullscreenLoading: false,
      ruleValidate: {
        "subject_info.signType": [{
          required: true,
          trigger: "change",
          message: "请选择商户签约类型"
        }],
        "subject_info.licenceNo": [{
          required: true,
          trigger: "blur",
          message: "请输入商户证件编号"
        }],
        "subject_info.licenceUrl": [{
          required: true,
          trigger: "change",
          message: "请选择商户证件照片"
        }],
        "subject_info.signName": [{
          required: true,
          trigger: "blur",
          message: "请输入商户签约名称"
        }],
        "subject_info.shortName": [{
          required: true,
          trigger: "blur",
          message: "请输入商户简称"
        }],
        "subject_info.openAccountLicenceNo": [{
          required: true,
          trigger: "blur",
          message: "请输入开户许可证编号"
        }],
        "subject_info.openAccountLicenceUrl": [{
          required: true,
          trigger: "change",
          message: "请选择开户许可证照片"
        }],
        "corporation_info.legalName": [{
          required: true,
          trigger: "blur",
          message: "请输入法人姓名"
        }],
        "corporation_info.legalLicenceType": [{
          required: true,
          trigger: "change",
          message: "请选择法人证件类型"
        }],
        "corporation_info.legalLicenceNo": [{
          required: true,
          trigger: "blur",
          message: "请输入法人证件号码"
        }],
        "corporation_info.legalLicenceFrontUrl": [{
          required: true,
          trigger: "change",
          message: "请选择照片"
        }],
        "corporation_info.legalLicenceBackUrl": [{
          required: true,
          trigger: "change",
          message: "请选择照片"
        }],
        "contact_info.contactName": [{
          required: true,
          trigger: "blur",
          message: "请输入联系人姓名"
        }],
        "contact_info.contactLicenceNo": [{
          required: true,
          trigger: "blur",
          message: "请输入联系人证件号码"
        }],
        "contact_info.contactMobile": [{
          required: true,
          trigger: "blur",
          message: "请输入联系人手机号"
        }],
        "contact_info.contactEmail": [{
          required: true,
          trigger: "blur",
          message: "请输入联系人邮箱"
        }],
        "contact_info.servicePhone": [{
          required: true,
          trigger: "blur",
          message: "请输入客服电话"
        }],
        "business_address_info.address": [{
          required: true,
          trigger: "blur",
          message: "请输入详细地址"
        }],
        "settlement_account_info.settlementDirection": [{
          required: true,
          trigger: "change",
          message: "请选择结算方向"
        }],
        "settlement_account_info.bankAccountType": [{
          required: true,
          trigger: "change",
          message: "请选择银行账户类型"
        }],
        "settlement_account_info.bankCardNo": [{
          required: true,
          trigger: "blur",
          message: "请选择银行账户号码"
        }],
        "settlement_account_info.bankCode": [{
          required: true,
          trigger: "change",
          message: "请选择开户总行编码"
        }],
        "industry_category_info.primaryIndustryCategory": [{
          required: true,
          trigger: "blur",
          message: "请选择行业分类"
        }, {
          required: true,
          trigger: "change",
          message: "请选择行业分类"
        }],
        "industry_category_info.secondaryIndustryCategory": [{
          required: true,
          trigger: "blur",
          message: "请选择行业分类"
        }, {
          required: true,
          trigger: "change",
          message: "请选择行业分类"
        }],
        "function_service_qualification_info.shareCertificate": [{
          required: false,
          trigger: "change",
          message: "请选择图片"
        }],
        "product_qualification_info.terminalName": [{
          required: true,
          trigger: "blur",
          message: "请输入h5地址"
        }],
        "product_qualification_info.terminalTestAccount": [{
          required: true,
          trigger: "blur",
          message: "请输入h5测试账号"
        }]
      },
      formValidate: {
        "subject_info": {
          "signType": "ENTERPRISE",
          "licenceNo": "",
          "licenceUrl": "",
          "signName": "",
          "shortName": "",
          "openAccountLicenceNo": "",
          "openAccountLicenceUrl": "",
          "openAccountLicenceUrl_show": "",
          "handLicenceUrl": "",
          "handLicenceUrl_show": ""
        },
        "corporation_info": {
          "legalName": "",
          "legalLicenceType": "",
          "legalLicenceNo": "",
          "legalLicenceFrontUrl": "",
          "legalLicenceFrontUrl_show": "",
          "legalLicenceBackUrl": "",
          "legalLicenceBackUrl_show": ""
        },
        "contact_info": {
          "contactName": "",
          "contactLicenceNo": "",
          "contactMobile": "",
          "contactEmail": "",
          "servicePhone": ""
        },
        "industry_category_info": {
          "primaryIndustryCategory": "135",
          "secondaryIndustryCategory": "135002",
          "industryQualificationUrl": "",
          "industryQualificationUrl_show": ""
        },
        "business_address_info": {
          "province": "",
          "city": "",
          "district": "",
          "address": ""
        },
        "settlement_account_info": {
          "settlementDirection": "BANKCARD",
          "bankAccountType": "ENTERPRISE_ACCOUNT",
          "bankCardNo": "",
          "bankCode": ""
        },
        "product_info": [{
          "productCode": "ONEKEYPAY_DEBIT",
          "rateType": "SINGLE_PERCENT",
          "percentRate": "0.6",
          "undertaker": "PLATFORM_MERCHANT",
          "paymentMethod": "REAL_TIME"
        }, {
          "productCode": "T1",
          "rateType": "SINGLE_FIXED",
          "fixedRate": "0",
          "paymentMethod": "REAL_TIME"
        }],
        // "function_service": "1",
        // "function_service_qualification_info": {
        //     "shareScene": "FZ_FJ004",
        //     "shareCertificate": "",
        //     "shareCertificate_show": "",
        // },
        "product_qualification_info": {
          "internetType": ["MOBILE"],
          "terminalType": "H5",
          "terminalName": "",
          "terminalTestAccount": "",
          "terminalTestPassword": "使用验证码",
          "phoneCertificationUrl": "",
          "phoneCertificationUrl_show": "",
          "screenshotOfPaymentProcessUrl": "",
          "screenshotOfPaymentProcessUrl_show": ""
        }
      },
      loading: false,
      vcode: "",
      lldisabled: false,
      statusdisabled: false,
      codeList: [],
      bankcodeList: [],
      citycode: '',
      bank_code: '',
      brabank_name: '',
      props: {
        children: 'children',
        label: 'name',
        value: 'area_code',
        multiple: false
      },
      status: 'WAIT_COMMIT',
      yyepayList: _yeepay.default
    };
  },
  created: function created() {
    var _this = this;
    this.getCityList();
    this.getstatus();
    (0, _accounts.getYeepayMerchantInfoApi)().then(function (res) {
      if (res.data) {
        var resjson = JSON.parse(JSON.stringify(res.data));
        if (!resjson.function_service_qualification_info) {
          _this.citycode = [resjson.business_address_info.province, resjson.business_address_info.city, resjson.business_address_info.district];
          resjson.function_service = "SHARE";
          resjson.function_service_qualification_info = {
            "shareScene": "FZ_FJ004",
            "shareCertificate": "",
            "shareCertificate_show": ""
          };
        }
        _this.formValidate = resjson;
      }
    });
  },
  methods: {
    getstatus: function getstatus() {
      var _this2 = this;
      (0, _accounts.getYeepayMerchantStatusApi)().then(function (res) {
        _this2.status = res.data.applicationStatus;
      });
    },
    changecitycode: function changecitycode(val) {
      console.log(val);
      this.formValidate.business_address_info.province = val[0];
      this.formValidate.business_address_info.city = val[1];
      this.formValidate.business_address_info.district = val[2];
    },
    // 列表
    getCityList: function getCityList() {
      var _this3 = this;
      (0, _system.getCityApi)().then(function (res) {
        _this3.cityList = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 提交
    handleSubmit: function handleSubmit(name) {
      var _this4 = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this4.fullscreenLoading = true;
          console.log(_this4.currentJson);
          var json = JSON.parse(JSON.stringify(_this4.formValidate));
          (0, _accounts.createYeepayMerchantApi)(json).then(function (res) {
            _this4.fullscreenLoading = false;
            _this4.$message.success("提交成功");
            _this4.getstatus();
          }).catch(function (res) {
            _this4.fullscreenLoading = false;
          });
        } else {}
      });
    },
    changeImg: function changeImg(type) {
      var _this5 = this;
      this.$modalUpload(function (img) {
        var typearr = type.split('.');
        var msg = _this5.$loading({
          lock: true,
          text: '上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        (0, _accounts.uploadYeepayMerchantFileApi)({
          image: img[0]
        }).then(function (res) {
          msg.close();
          _this5.formValidate[typearr[0]]["".concat(typearr[1], "_show")] = img[0];
          _this5.formValidate[typearr[0]][typearr[1]] = res.data.merQualUrl;
          // if (type == 'basicInfo.business_licene_photo') {
          //   this.formValidate.basicInfo.business_licene_photo = img[0];
          //   this.formValidate.basicInfo.business_licene_photo_doc_id = res.data.doc_id
          // } else if (type == 'legalreptInfo.idcard_face') {
          //   this.formValidate.legalreptInfo.idcard_face = img[0];
          //   this.formValidate.legalreptInfo.idcard_face_doc_id = res.data.doc_id
          // } else if (type == 'legalreptInfo.idcard_back') {
          //   this.formValidate.legalreptInfo.idcard_back = img[0];
          //   this.formValidate.legalreptInfo.idcard_back_doc_id = res.data.doc_id
          // }
        }).catch(function (res) {
          msg.close();
          _this5.$message.error(res.message);
        });
      }, 1, "1");
    },
    uploadPDF: function uploadPDF(type, url) {
      var _this6 = this;
      console.log(type, url);
      var typearr = type.split('.');
      var msg = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _accounts.uploadYeepayMerchantFileApi)({
        image: url
      }).then(function (res) {
        msg.close();
        _this6.formValidate[typearr[0]]["".concat(typearr[1], "_show")] = url;
        _this6.formValidate[typearr[0]][typearr[1]] = res.data.merQualUrl;
      }).catch(function (res) {
        msg.close();
        _this6.$message.error(res.message);
      });
    }
  }
};