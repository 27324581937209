var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageInfo
    ? _c(
        "control",
        { attrs: { title: "页面基础设置", is_tab: false } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "80px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: { label: "封面图", required: "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      staticStyle: { width: "141px", height: "60px" },
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "coverImg")
                        },
                      },
                    },
                    [
                      _vm.pageInfo.coverImg
                        ? _c("img", {
                            staticStyle: {
                              width: "141px",
                              height: "60px",
                              display: "block",
                            },
                            attrs: { src: _vm.pageInfo.coverImg },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入公众号图文标题",
                      maxlength: "64",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.pageInfo.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "title", $$v)
                      },
                      expression: "pageInfo.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "作者" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入作者",
                      maxlength: "8",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.pageInfo.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "author", $$v)
                      },
                      expression: "pageInfo.author",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "摘要" } },
                [
                  _c("el-input", {
                    staticStyle: { "padding-bottom": "40px" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入摘要",
                      maxlength: "120",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.pageInfo.digest,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "digest", $$v)
                      },
                      expression: "pageInfo.digest",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "合集" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入合集" },
                    model: {
                      value: _vm.pageInfo.collection,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "collection", $$v)
                      },
                      expression: "pageInfo.collection",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "原文地址" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        "图文消息的原文地址，即点击“阅读原文”后的URL",
                    },
                    model: {
                      value: _vm.pageInfo.content_source_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "content_source_url", $$v)
                      },
                      expression: "pageInfo.content_source_url",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#999999" },
                    model: {
                      value: _vm.pageInfo.pageBackground,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "pageBackground", $$v)
                      },
                      expression: "pageInfo.pageBackground",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("背景图片"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(1, "bg_image")
                      },
                    },
                  },
                  [
                    _vm.pageInfo.bg_image
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delImg("bg_image")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pageInfo.bg_image
                      ? _c("img", {
                          attrs: { src: _vm.pageInfo.bg_image + "!300" },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "背景图设置" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.pageInfo.bg_image_mode,
                        callback: function ($$v) {
                          _vm.$set(_vm.pageInfo, "bg_image_mode", $$v)
                        },
                        expression: "pageInfo.bg_image_mode",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("宽度固定上下居中"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("满屏上下左右截取"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("宽度固定上下平铺循环展示"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("宽度固定高度自适应顶部展示"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "配色" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _vm.colorConfiguration
                        ? _vm._l(_vm.colorConfiguration, function (m, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "color",
                                on: {
                                  click: function ($event) {
                                    return _vm.setColor(i)
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "color-top",
                                  style:
                                    "height: 100%;background: " + m.sub_text,
                                }),
                              ]
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            height: "24px",
                            margin: "2px 0 3px 5px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.generateColors },
                            },
                            [_vm._v("AI生成")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.configuration && _vm.configuration.length
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "装饰" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap" },
                        [
                          _vm._l(_vm.configuration, function (m, i) {
                            return [
                              i > 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "configuration",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setConfiguration(i)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: m.icon, alt: "" },
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.configuration && _vm.configuration.length
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "英文字体" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              on: { change: _vm.changeFontFamilyAction },
                              model: {
                                value: _vm.fontFamily,
                                callback: function ($$v) {
                                  _vm.fontFamily = $$v
                                },
                                expression: "fontFamily",
                              },
                            },
                            _vm._l(_vm.fontFamilyList, function (m, i) {
                              return _c(
                                "el-option",
                                {
                                  key: i,
                                  attrs: { value: m.value, label: m.name },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "flex-one" }, [
                                        _vm._v(_vm._s(m.name)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ft12",
                                          style:
                                            "font-family: " + m.value + ";",
                                        },
                                        [_vm._v("例：100")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.configuration && _vm.configuration.length
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "中文字体" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              on: { change: _vm.changeFontFamilyCHAction },
                              model: {
                                value: _vm.fontFamily_ch,
                                callback: function ($$v) {
                                  _vm.fontFamily_ch = $$v
                                },
                                expression: "fontFamily_ch",
                              },
                            },
                            _vm._l(_vm.fontFamilyList, function (m, i) {
                              return _c(
                                "el-option",
                                {
                                  key: i,
                                  attrs: { value: m.value, label: m.name },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", { staticClass: "flex-one" }, [
                                        _vm._v(_vm._s(m.name)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ft12",
                                          style:
                                            "font-family: " + m.value + ";",
                                        },
                                        [_vm._v("例：100")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }