var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "快照名称：", prop: "title" } },
                        [
                          _c("el-input", {
                            staticClass: "selwidth",
                            attrs: { placeholder: "请输入快照名称" },
                            model: {
                              value: _vm.formValidate.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "title", $$v)
                              },
                              expression: "formValidate.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.sid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "导入名单：" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "uploadImg",
                                  staticClass: "upload-demo mr10",
                                  staticStyle: { display: "inline-block" },
                                  attrs: {
                                    action: _vm.fileUrl,
                                    "on-success": _vm.handleSuccess,
                                    headers: _vm.myHeaders,
                                    data: {
                                      import_type: "snapshot_user",
                                      snapshot_id: _vm.sid,
                                    },
                                    "show-file-list": true,
                                    name: "excel",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [_vm._v("导入")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "text" },
                                  on: { click: _vm.downLoad },
                                },
                                [_vm._v("下载导入模板")]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { label: "导入名单：" } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex align-items-c" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "post" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("导入")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "export-file",
                                        attrs: { type: "file" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeExselAction($event)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ml10",
                                      attrs: { size: "small", type: "text" },
                                      on: { click: _vm.downLoad },
                                    },
                                    [_vm._v("下载导入模板")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _vm.sid && _vm.success
                        ? _c("el-form-item", [
                            _vm._v(
                              "\n            成功导入的用户数量：" +
                                _vm._s(_vm.success.successCount) +
                                "\n            "
                            ),
                            _c("span", {
                              staticStyle: { "margin-left": "20px" },
                            }),
                            _vm._v(
                              "\n            失败导入的用户数量：" +
                                _vm._s(_vm.success.errorCount) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.sid && _vm.uploadError
                        ? _c("el-form-item", [
                            _c("div", { staticClass: "color-red" }, [
                              _vm._v("导入失败！请检查表格"),
                            ]),
                          ])
                        : !_vm.sid && _vm.fileNumber
                        ? _c("el-form-item", [
                            _vm._v(
                              "\n            导入数量：" +
                                _vm._s(_vm.fileNumber) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c("el-form-item", { attrs: { label: "拍照条件：" } }, [
                        _c(
                          "div",
                          { staticClass: "tab" },
                          [
                            _c(
                              "div",
                              { staticClass: "tr flex align-items-c" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "th",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v("图片")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "th",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v("类型")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "th",
                                    staticStyle: { width: "40%" },
                                  },
                                  [_vm._v("名称")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "th",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v("持有数量大于等于")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "th",
                                    staticStyle: { width: "15%" },
                                  },
                                  [_vm._v("操作")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.orderArr, function (m, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "tr flex align-items-c",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "td item-flex-center",
                                      staticStyle: { width: "15%" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-image flex align-items-c justify-c",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: m.image + "!120a",
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "td",
                                      staticStyle: { width: "15%" },
                                    },
                                    [
                                      m.type == "product_nft"
                                        ? _c("div", [_vm._v("数字藏品")])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      m.type == "product_nft_album"
                                        ? _c("div", [_vm._v("数字系列")])
                                        : m.type == "product_nft_blind"
                                        ? _c("div", [_vm._v("数字盲盒")])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "td",
                                      staticStyle: { width: "40%" },
                                    },
                                    [_c("div", [_vm._v(_vm._s(m.name))])]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "td",
                                      staticStyle: { width: "15%" },
                                    },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100%" },
                                        attrs: { controls: false },
                                        model: {
                                          value: m.have_count,
                                          callback: function ($$v) {
                                            _vm.$set(m, "have_count", $$v)
                                          },
                                          expression: "m.have_count",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "td",
                                      staticStyle: { width: "15%" },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delAction(i)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "tr flex align-items-c" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "td",
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdditem(
                                              "product_nft"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("添加数字藏品")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdditem(
                                              "product_nft_album"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("添加数字系列")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAdditem(
                                              "product_nft_blind"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("添加数字盲盒")]
                                    ),
                                    _vm._v(" "),
                                    _vm.orderArr.length
                                      ? [
                                          _c("span", { staticClass: "ml10" }, [
                                            _vm._v("条件是否需要全部满足："),
                                          ]),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66",
                                              "inactive-color": "#ccc",
                                              "active-value": 1,
                                              "inactive-value": 0,
                                            },
                                            model: {
                                              value: _vm.needAll,
                                              callback: function ($$v) {
                                                _vm.needAll = $$v
                                              },
                                              expression: "needAll",
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _vm.orderArr.length
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "拍照时间：",
                                prop: "perform_time",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "selwidth",
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择拍照时间",
                                  "value-format": "yyyy/MM/dd HH:mm:ss",
                                  format: "yyyy/MM/dd HH:mm:ss",
                                  "picker-options": _vm.pickerOptions,
                                },
                                model: {
                                  value: _vm.formValidate.perform_time,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "perform_time",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.perform_time",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("formValidate")
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "product_nft",
        attrs: { "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("NftAlbum", {
        ref: "product_nft_album",
        attrs: { "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c("Nftblind", {
        ref: "product_nft_blind",
        attrs: { "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }