"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      status: this.$route.query.status,
      searchQuery: {},
      tableFrom: {
        venue_id: this.$route.query.venue_id,
        page: 1,
        limit: 20,
        make_type: '',
        is_del: 1
      },
      resultData: [],
      total: 0,
      listLoading: false,
      titlelist: ['拍卖会推广素材标题', '专场推广素材标题', '拍品推广素材标题']
    };
  },
  created: function created() {
    this.getList(1);
  },
  methods: {
    onRecov: function onRecov(row) {
      var _this = this;
      console.log(row);
      (0, _aiMark.recoverMakeApi)({
        market_make_ids: row.market_make_id
      }).then(function (res) {
        _this.$message.success('操作成功');
        _this.getList(1);
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    onChangeStatus: function onChangeStatus() {
      this.tableFrom.page = 1;
      this.getList();
    },
    getList: function getList(num) {
      var _this2 = this;
      if (num) this.tableFrom.page = num;
      this.tableFrom.make_type = this.status;
      this.listLoading = true;
      (0, _aiMark.venueMediaListApi)(this.tableFrom).then(function (res) {
        // console.log(res);
        _this2.listLoading = false;
        _this2.resultData = res.data.list;
        _this2.total = res.data.count;
      }).catch(function (err) {
        _this2.listLoading = false;
      });
    }
  }
};