"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
var _order = require("@/api/order");
var _system = require("@/api/system");
var _userdetail = _interopRequireDefault(require("../user/list/userdetail"));
var _orderDetail = _interopRequireDefault(require("./orderDetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _SettlementPrintGoods = _interopRequireDefault(require("@/components/order/SettlementPrintGoods.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    detailsFrom: _orderDetail.default,
    cardsData: _index.default,
    fileList: _fileList.default,
    SettlementPrint: _SettlementPrintGoods.default,
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      orderId: 0,
      tableData: {
        data: [],
        total: 0
      },
      changeOrder: [],
      listLoading: true,
      status: "",
      tableFrom: {
        order_sn: this.$route.query.order_sn ? this.$route.query.order_sn : "",
        order_type: "1",
        keywords: "",
        date: "",
        page: 1,
        limit: 20,
        type: "1",
        username: "",
        order_id: this.$route.query.order_id ? this.$route.query.order_id : "",
        activity_type: "",
        order: ""
      },
      activityList: [{
        value: 0,
        label: "普通订单"
      },
      // { value: 1, label: '秒杀订单' },
      // { value: 2, label: '预售订单' },
      // { value: 3, label: '助力订单' },
      // { value: 4, label: '拼团订单' },
      {
        value: "blind_box_product_receive",
        label: "实物盲盒订单"
      }],
      orderChartType: {},
      timeVal: [],
      fromList: {
        title: "选择时间",
        custom: true,
        fromTxt: [{
          text: "全部",
          val: ""
        }, {
          text: "今天",
          val: "today"
        }, {
          text: "昨天",
          val: "yesterday"
        }, {
          text: "最近7天",
          val: "lately7"
        }, {
          text: "最近30天",
          val: "lately30"
        }, {
          text: "本月",
          val: "month"
        }, {
          text: "本年",
          val: "year"
        }]
      },
      selectionList: [],
      ids: "",
      tableFromLog: {
        page: 1,
        limit: 10
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      LogLoading: false,
      dialogVisible: false,
      fileVisible: false,
      editVisible: false,
      cardLists: [],
      orderDatalist: null,
      headeNum: [],
      editId: "",
      formValidate: {
        total_price: "",
        pay_postage: "",
        pay_price: "",
        coupon_price: ""
      },
      uid: "",
      showdetail: false,
      searchQuery: {}
      // statusOptionsList: [
      //   { label: '全部', value: '' },
      //   { label: '待确认', value: 8 },
      //   { label: '待付款', value: 1 },
      // { label: '待发货', value: 2 },
      // { label: '待收货', value: 3 },
      // { label: '待评价', value: 4 },
      // { label: '已完成', value: 5 },
      // { label: '已退款', value: 6 },
      // { label: '已取消', value: 7 }
      // ],
    };
  },
  mounted: function mounted() {
    if (this.$route.query.hasOwnProperty("order_sn")) {
      this.tableFrom.order_sn = this.$route.query.order_sn;
    } else {
      this.tableFrom.order_sn = "";
    }
    this.headerList();
    this.getList(1);
    // this.getHeaderList();
  },
  methods: {
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      // order_type: this.tableFrom.order_type, , order_sn: this.tableFrom.order_sn, order_id: this.tableFrom.order_id,
      this.tableFrom = Object.assign({
        order_type: "1",
        type: this.tableFrom.type,
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    changeStatusAction: function changeStatusAction(e) {
      this.status = e;
      this.getList(1);
    },
    getAllList: function getAllList() {
      this.headerList();
      this.getList(1);
    },
    // 详情
    onDetails: function onDetails(uid) {
      if (uid === 8888) {
        return false;
      }
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    changeSortAction: function changeSortAction(_ref) {
      var column = _ref.column,
        prop = _ref.prop,
        order = _ref.order;
      if (prop === "pay_time" && order === "ascending") {
        // 箭头向上
        this.tableFrom.order = "pay_time_asc";
      } else if (prop === "pay_time" && order === "descending") {
        // 箭头向下
        this.tableFrom.order = "pay_time_desc";
      } else if (prop === "pay_time") {
        this.tableFrom.order = "";
      }
      this.getList();
    },
    // 复制
    copy: function copy(item) {
      console.log(item);
      this.copyText = "".concat(item.real_name, "\uFF0C").concat(item.user_phone, "\uFF0C").concat(item.user_address);
      var input = document.createElement("input"); // 直接构建input
      input.value = this.copyText; // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("操作成功");
    },
    // 头部
    // getHeaderList() {
    //   orderHeadListApi()
    //     .then(res => {
    //       this.headeNum = res.data;
    //     })
    //     .catch(res => {
    //       // this.$message.error(res.message);
    //     });
    // },
    printAllAction: function printAllAction(source) {
      if (!this.changeOrder.length) {
        this.$message.error("请选择订单");
        return false;
      }
      var error = "";
      var uid = this.changeOrder[0].uid;
      var paid = this.changeOrder[0].paid;
      this.changeOrder.forEach(function (m) {
        if (m.uid !== uid) {
          error = "只能选择同一买家的订单";
        }
        if (m.paid !== paid) {
          error = "已支付与未支付订单不能同时打印";
        }
      });
      if (error) {
        this.$message.error(error);
        return false;
      }
      var ids = this.changeOrder.map(function (m) {
        return m.order_id;
      });
      var item = this.changeOrder[0];
      this.$refs["printView"].loadData(ids.join(","), item.uid, source);
    },
    // 订单筛选
    orderFilter: function orderFilter(item) {
      var status = false;
      item.orderProduct.forEach(function (el) {
        if (el.is_refund) {
          status = true;
        }
      });
      return status;
    },
    // 退款详情页
    onRefundDetail: function onRefundDetail(sn) {
      this.$router.push({
        path: "/merchant/order/refund",
        query: {
          sn: sn
        }
      });
    },
    // 表格某一行添加特定的样式
    addTdClass: function addTdClass(val) {
      if (val.row.status > 0 && val.row.paid == 1) {
        for (var i = 0; i < val.row.orderProduct.length; i++) {
          if (val.row.orderProduct[i].is_refund) {
            return "row-bg";
          }
        }
      } else {
        return " ";
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.dialogVisible = true;
      this.$refs.orderDetail.onOrderDetails(id);
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this = this;
      var data = (0, _objectSpread2.default)({
        export_type: "goods_order"
      }, this.tableFrom);
      (0, _system.exportexcel)(data).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this.$createElement;
        _this.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this.$msgbox.close();
                _this.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("goods_order");
    },
    // 订单核销
    orderCancellation: function orderCancellation() {
      var _this2 = this;
      var that = this;
      this.$prompt("", "提示", {
        confirmButtonText: "立即核销",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputPlaceholder: "请输入核销码",
        inputErrorMessage: "请输入核销码"
      }).then(function (_ref2) {
        var value = _ref2.value;
        that.handleCancellation(value);
      }).catch(function () {
        _this2.$message({
          type: "info",
          message: "取消输入"
        });
      });
    },
    // 去核销
    handleCancellation: function handleCancellation(code) {
      var _this3 = this;
      this.$confirm("确定核销此订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _order.orderCancellationApi)(code).then(function (res) {
          _this3.$message.success(res.message);
          _this3.getList("");
        }).catch(function (res) {
          // this.$message.error(res.message);
          _this3.LogLoading = false;
        });
      }).catch(function () {
        _this3.$message({
          type: "info",
          message: "已取消核销"
        });
      });
    },
    refundAction: function refundAction(id) {
      var _this4 = this;
      this.$confirm("确定退款此订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _order.createNftOrderRefundApi)({
          order_id: id
        }).then(function (res) {
          _this4.$message.success(res.message);
          _this4.getList("");
        }).catch(function (res) {
          // this.$message.error(res.message);
          _this4.LogLoading = false;
        });
      }).catch(function () {
        _this4.$message({
          type: "info",
          message: "已取消"
        });
      });
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this5 = this;
      this.dialogVisible = true;
      this.LogLoading = true;
      (0, _order.orderLogApi)(id, this.tableFromLog).then(function (res) {
        _this5.tableDataLog.data = res.data.list;
        _this5.tableDataLog.total = res.data.count;
        _this5.LogLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this5.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.getList("");
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.getList("");
    },
    // 打印订单
    printOrder: function printOrder(id) {
      var _this6 = this;
      (0, _order.orderPrintApi)(id).then(function (res) {
        _this6.$message.success(res.message);
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this7 = this;
      if (row.is_del === 1) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)(row.order_id).then(function (_ref3) {
            var message = _ref3.message;
            _this7.$message.success(message);
            _this7.tableData.data.splice(idx, 1);
          }).catch(function (_ref4) {
            var message = _ref4.message;
          });
        });
      } else {
        this.$confirm("您选择的的订单存在用户未删除的订单，无法删除用户未删除的订单！", "提示", {
          confirmButtonText: "确定",
          type: "error"
        });
      }
    },
    // 备注
    onOrderMark: function onOrderMark(id) {
      var _this8 = this;
      this.$modalForm((0, _order.orderRemarkApi)(id)).then(function () {
        return _this8.getList("");
      });
    },
    // handleSelectionChange(val) {
    //   this.selectionList = val;
    //   const data = [];
    //   this.selectionList.map(item => {
    //     data.push(item.id);
    //   });
    //   this.ids = data.join(',');
    // },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.date = tab;
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.date = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    // 编辑
    edit: function edit(id) {
      var _this9 = this;
      // this.$modalForm(orderUpdateApi(id)).then(() => this.getList(''));
      this.editId = id;
      this.editVisible = true;
      (0, _order.orderDetailApi)(id).then(function (res) {
        _this9.formValidate = {
          pay_postage: res.data.pay_postage,
          total_price: res.data.total_price,
          coupon_price: res.data.coupon_price,
          pay_price: (Number(res.data.total_price) + Number(res.data.pay_postage) - Number(res.data.coupon_price)).toFixed(2)
        };
        _this9.loading = false;
      }).catch(function (_ref5) {
        var message = _ref5.message;
        _this9.loading = false;
      });
    },
    editConfirm: function editConfirm() {
      var _this10 = this;
      (0, _order.orderUpdateApi)(this.editId, this.formValidate).then(function (res) {
        _this10.editVisible = false;
        _this10.$message.success(res.message);
        _this10.getList("");
      }).catch(function (_ref6) {
        var message = _ref6.message;
      });
    },
    changePrice: function changePrice() {
      this.formValidate.pay_price = (this.formValidate.total_price + this.formValidate.pay_postage - this.formValidate.coupon_price).toFixed(2);
    },
    // 发货
    send: function send(id) {
      var _this11 = this;
      this.$modalForm((0, _order.auctionDeliveryForm)({
        order_id: id
      })).then(function () {
        return _this11.getList();
      });
    },
    editDeliveryAction: function editDeliveryAction(id) {
      var _this12 = this;
      this.$modalForm((0, _order.auctionUpdateDeliveryForm)(id)).then(function () {
        return _this12.getList();
      });
    },
    // 列表
    getList: function getList(num) {
      var _this13 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      // if (this.status) 
      // else this.status = '';
      this.tableFrom.status = this.status;
      // this.tableFrom.user_name_phone_keyword = this.tableFrom.username || "";
      (0, _order.orderListApi)(this.tableFrom).then(function (res) {
        _this13.changeOrder = [];
        _this13.tableData.data = [];
        _this13.tableData.data = res.data.list;
        _this13.tableData.total = res.data.count;
        _this13.cardLists = res.data.stat;
        _this13.listLoading = false;
        _this13.$nextTick(function () {
          if (_this13.$refs.scTableRef) _this13.$refs.scTableRef.doLayout();
        });
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this13.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    headerList: function headerList() {
      var _this14 = this;
      (0, _order.getOrderStatusNumber)({
        order_type: this.tableFrom.order_type
      }).then(function (res) {
        _this14.orderChartType = res.data;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    handleSelectionChange: function handleSelectionChange(data) {
      this.changeOrder = data;
    },
    deliveryAllAction: function deliveryAllAction(id) {
      var _this15 = this;
      if (!this.changeOrder.length) {
        this.$message.error("请选择订单");
        return false;
      }
      var error = "";
      var uid = this.changeOrder[0].uid;
      this.changeOrder.forEach(function (m) {
        if (m.uid !== uid) {
          error = "只能选择同一买家的订单";
        }
        if (m.status !== 1) {
          error = "已选择非待发货状态订单，请检查后重试";
        }
        if (m.delivery_type !== 1) {
          error = "已选择自提订单，请检查后重试";
        }
      });
      if (error) {
        this.$message.error(error);
        return false;
      }
      var ids = this.changeOrder.map(function (m) {
        return m.order_id;
      });
      this.$modalForm((0, _order.auctionDeliveryForm)({
        order_id: ids.join(",")
      })).then(function () {
        return _this15.getList();
      });
    }
  }
};