var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSelectAuthor,
            width: "800px",
            "close-on-click-modal": false,
            top: "5vh",
            "destroy-on-close": "",
            "append-to-body": "",
            title: "作者",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSelectAuthor = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex align-items-c mb20",
              staticStyle: {
                "border-top": "1px solid #eee",
                "padding-top": "20px",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { type: "button", size: "small" },
                  model: {
                    value: _vm.radioVal,
                    callback: function ($$v) {
                      _vm.radioVal = $$v
                    },
                    expression: "radioVal",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("已选择"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200 ml10",
                  attrs: { placeholder: "作者名称", size: "small" },
                  on: { change: _vm.searchAuthor },
                  model: {
                    value: _vm.queryParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "name", $$v)
                    },
                    expression: "queryParams.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { change: _vm.searchAuthor },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                data: _vm.radioVal === "1" ? _vm.artistList : _vm.seleList,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "头像",
                  width: "55",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.avatar
                          ? _c("img", {
                              staticStyle: {
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                              },
                              attrs: { src: scope.row.avatar },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "作者名称",
                  width: "100",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "简介",
                  "min-width": "120",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-tooltip", { attrs: { effect: "light" } }, [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "max-width": "400px" } },
                                [_vm._v(_vm._s(scope.row.intro))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "nowrap" }, [
                            _vm._v(_vm._s(scope.row.intro)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "60",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "font-size": "14px" },
                            attrs: { type: "text", size: "small" },
                            on: { click: _vm.selctAll },
                          },
                          [_vm._v(" 全选 ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandSeleAuthor(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.seleFilter(scope.row.id)) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.radioVal === "1"
            ? _c(
                "div",
                { staticClass: "flex justify-e mt20" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.queryParams.limit,
                      "current-page": _vm.queryParams.page,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: { "current-change": _vm.pageChange },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex justify-c align-items-c",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showSelectAuthor = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }