var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-demo default-tabs ",
              attrs: {
                mode: "horizontal",
                "active-text-color": "#409EFF",
                "default-active": "3",
              },
              on: { select: _vm.onSelectTit },
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("模版")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "2" } }, [_vm._v("框类型")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "3" } }, [_vm._v("框卡纸")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "4" } }, [_vm._v("场景")]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "5" } }, [_vm._v("画框")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-20" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { input: _vm.changeType },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "0" } }, [
                    _vm._v("全部"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("尺寸"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "2" } }, [
                    _vm._v("颜色"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "3" } }, [
                    _vm._v("纹理"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default-box" },
            [
              _c(
                "el-table",
                {
                  staticClass: "mt20",
                  staticStyle: { background: "#f7f7f7" },
                  attrs: { data: _vm.lists, border: "" },
                },
                [
                  _c("el-table-column", { attrs: { label: "ID", prop: "id" } }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticStyle: { width: "80px", height: "80px" },
                              attrs: {
                                src: scope.row.img_url + "!m640",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.type == 1
                    ? _c("el-table-column", {
                        attrs: { label: "比例", prop: "ratio" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "排序" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "60px" },
                              on: {
                                blur: function ($event) {
                                  return _vm.changeSort(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.sort,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sort", $$v)
                                },
                                expression: "scope.row.sort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-popconfirm", {
                              attrs: { title: "确定删除吗？" },
                              on: {
                                onConfirm: function ($event) {
                                  return _vm.deltag(scope.row)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "reference",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "m2",
                                            attrs: {
                                              type: "error",
                                              size: "small",
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                staticStyle: { "text-align": "right" },
                attrs: {
                  total: _vm.total,
                  "page-size": _vm.queryParams.limit,
                  "show-sizer": "show-sizer",
                  "show-total": "show-total",
                  "show-elevator": "show-elevator",
                  "current-page": _vm.queryParams.page,
                },
                on: {
                  "size-change": _vm.changePageSize,
                  "current-change": _vm.changePage,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.saleflag, title: "改价" },
              on: {
                "update:visible": function ($event) {
                  _vm.saleflag = $event
                },
                "on-ok": function ($event) {
                  return _vm.handle(1)
                },
              },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.work.title))]),
              _vm._v(" "),
              _c("p", { staticClass: "mt20" }, [
                _c("img", {
                  staticStyle: { width: "80px", height: "80px" },
                  attrs: { src: _vm.work.works_image + "!m640", alt: "" },
                }),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "mt20" },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请输入出售价格" },
                      model: {
                        value: _vm.price,
                        callback: function ($$v) {
                          _vm.price = $$v
                        },
                        expression: "price",
                      },
                    },
                    [
                      _c("el-icon", {
                        attrs: { slot: "prefix", type: "logo-yen", size: "20" },
                        slot: "prefix",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }