var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _vm.collectMoney.show
        ? _c(
            "div",
            { attrs: { id: "printorder" } },
            [
              _c("div", { staticClass: "detail-box" }, [
                _c("div", { staticClass: "flex" }, [
                  _vm.merData.config && _vm.merData.config.mer_print_logo
                    ? _c("img", {
                        staticStyle: { height: "70px", "margin-right": "20px" },
                        attrs: {
                          src: _vm.merData.config.mer_print_logo + "!120a",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-left" }, [
                    _c("div", { staticClass: "detail-left-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.collectMoney.data.merConfig.company_name) +
                          " | " +
                          _vm._s(
                            _vm.source == "send"
                              ? _vm.switchLang("Delivery")
                              : _vm.switchLang("CollectionBill")
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "14px",
                          "font-family": "'printEN'",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.collectMoney.data.merConfig
                                .company_english_name
                            ) +
                            "  " +
                            _vm._s(
                              _vm.source == "send"
                                ? "Bill of Lading"
                                : "Collection settlement bill"
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "qrcodeBox" }, [
                  _vm.qrcodeImg
                    ? _c("img", {
                        staticClass: "qrcimg",
                        attrs: {
                          src: "data:image/png;base64," + _vm.qrcodeImg,
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-abso" }, [
                    _vm.collectMoney.data.recharge_order
                      ? _c("div", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v(
                            _vm._s(_vm.switchLang("CollectionTime")) +
                              "：" +
                              _vm._s(
                                _vm.collectMoney.data.recharge_order.create_time
                              )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "font-size": "14px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.collectMoney.data.recharge_order ||
                            _vm.collectMoney.data.paid == 1
                            ? _vm.switchLang("Settled")
                            : _vm.switchLang("Unsettled")
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "secTit" }, [
                _vm._v(_vm._s(_vm.switchLang("BuyerCompany"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    "Thanks for your support to our company, we beg your understanding and also hope you can support us as usual!"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-title",
                  staticStyle: { "margin-top": "40px" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.collectMoney.data.venue_info.title) + " "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _vm.collectMoney.data.userVerifyInfo
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.switchLang("BuyerName")) + " BUYER'S NAME "
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              "font-size": "16px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.collectMoney.data.userVerifyInfo.real_name
                              )
                            ),
                          ]
                        ),
                      ])
                    : _vm.collectMoney.data.userInfo
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.switchLang("BuyerName")) + " BUYER'S NAME "
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              "font-size": "16px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.collectMoney.data.userInfo.nickname)
                            ),
                          ]
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          _vm._s(_vm.switchLang("BuyerName")) + " BUYER'S NAME"
                        ),
                      ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.switchLang("BuyerNumber")) + " PADDLE NO. "
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.collectMoney.data.numberPlate
                              ? _vm.collectMoney.data.numberPlate.number
                              : ""
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("交易日期 DATE OF SALE "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          "font-size": "16px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.collectMoney.data.order_time))]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.collectMoney.data.delivery_type != 2
                ? _c(
                    "div",
                    {
                      staticClass: "detail-list",
                      staticStyle: { "margin-top": "5px" },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.collectMoney.data.address_info.user_address
                          ) +
                            " " +
                            _vm._s(
                              _vm.collectMoney.data.address_info.user_phone
                            ) +
                            " " +
                            _vm._s(_vm.collectMoney.data.address_info.real_name)
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.collectMoney.data.orderList, function (item, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    staticClass: "ptd-box",
                    style:
                      ind < _vm.collectMoney.data.orderList.length - 1
                        ? "page-break-after: always;"
                        : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ptd",
                        staticStyle: { "font-weight": "bold" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "1" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTabletu"))),
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("LOT NO.")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTablepp"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("DESCRIPTION")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTablelc"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("HAMMER PRICE")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTableyj"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("COMMISSION")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("BuyerTableqt"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("OTHER")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                          ? _c(
                              "div",
                              {
                                staticClass: "ptd-title",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.feeTypeInfo.name) +
                                      _vm._s(_vm.switchLang("Amount"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.feeTypeInfo.type) + " SUBTOTAL"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-title",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.switchLang("Amount"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v("SUBTOTAL")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(item, function (m, i) {
                      return _c(
                        "div",
                        { key: "order" + i, staticClass: "ptd" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            },
                            [_vm._v(_vm._s(m.auction_info.lot))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [_vm._v(_vm._s(m.auction_info.store_name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                    ? _vm.fee_type.replace("CNY", "RMB")
                                    : ""
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatPriceNumber(m.product_price, 2)
                                  )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                    ? _vm.fee_type.replace("CNY", "RMB")
                                    : ""
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatPriceNumber(m.commission_price, 2)
                                  )
                              ),
                              m.commission_rate
                                ? [
                                    _vm._v(
                                      " / " + _vm._s(m.commission_rate) + "%"
                                    ),
                                  ]
                                : _vm._e(),
                              m.extend_data &&
                              m.extend_data.memberCommissionDiscountPercent &&
                              m.extend_data.memberCommissionDiscountPercent !=
                                100
                                ? [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm._f("filterPercent")(
                                            m.extend_data
                                              .memberCommissionDiscountPercent
                                          )
                                        ) +
                                        "折)"
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              m.total_postage && m.delivery_type == 1
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("Freight")) +
                                        "：" +
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.total_postage,
                                            2
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              m.admin_discount_price
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("Discount")) +
                                        "：-" +
                                        _vm._s(
                                          _vm.formatPriceNumber(
                                            m.admin_discount_price,
                                            2
                                          )
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: { flex: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.fee_type !=
                                        _vm.feeTypeInfo.mer_fee_type
                                        ? _vm.fee_type.replace("CNY", "RMB")
                                        : ""
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatPriceNumber(
                                          m.fee_origin_payable_price,
                                          2
                                        )
                                      )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                    ? _vm.feeTypeInfo.mer_fee_type.replace(
                                        "CNY",
                                        "RMB"
                                      )
                                    : ""
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatPriceNumber(m.payable_price, 2)
                                  )
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ptd-box",
                  staticStyle: { "margin-top": "0", "border-top": "none" },
                },
                [
                  _c("div", { staticClass: "ptd" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ptd-body",
                        staticStyle: { flex: "1", "flex-direction": "column" },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.switchLang("Total")))]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "font-family": "'printEN'" } },
                          [_vm._v("TOTAL")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.collectMoney.orderLength) + "件"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                              ? _vm.fee_type.replace("CNY", "RMB")
                              : ""
                          ) +
                            " " +
                            _vm._s(_vm.formatPriceNumber(_vm.total_price, 2))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                              ? _vm.fee_type.replace("CNY", "RMB")
                              : ""
                          ) +
                            " " +
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_commission_price,
                                2
                              )
                            )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm.collectMoney.data.total_postage
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.switchLang("Freight")) +
                                  "：" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data.total_postage,
                                      2
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.collectMoney.data.total_admin_discount_price
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.switchLang("Discount")) +
                                  "：-" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .total_admin_discount_price,
                                      2
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                      ? _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "2" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                  ? _vm.fee_type.replace("CNY", "RMB")
                                  : ""
                              ) +
                                " " +
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    _vm.collectMoney.data
                                      .total_fee_origin_payable_price,
                                    2
                                  )
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ptd-body", staticStyle: { flex: "2" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                              ? _vm.feeTypeInfo.mer_fee_type.replace(
                                  "CNY",
                                  "RMB"
                                )
                              : ""
                          ) +
                            " " +
                            _vm._s(
                              _vm.formatPriceNumber(
                                _vm.collectMoney.data.total_payable_price,
                                2
                              )
                            )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.collectMoney.data.total_admin_discount_price == 0
                    ? _c("div", { staticClass: "ptd" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            style:
                              _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                ? "flex: 5;padding-left: 6px;flex-direction: column;"
                                : "flex: 3;margin-left: -1px;flex-direction: column;",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data.recharge_order ||
                                      _vm.collectMoney.data.paid == 1
                                      ? _vm.switchLang("PaidAmount")
                                      : _vm.switchLang("BuyerPending")
                                  ) +
                                    _vm._s(
                                      _vm.fee_type !=
                                        _vm.feeTypeInfo.mer_fee_type
                                        ? "（" +
                                            _vm.feeTypeInfo.mer_fee_type.replace(
                                              "CNY",
                                              "RMB"
                                            ) +
                                            "）"
                                        : ""
                                    )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-family": "'printEN'",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data.recharge_order ||
                                      _vm.collectMoney.data.paid == 1
                                      ? "ACTUAL PAYMENT AMOUNT"
                                      : "AMOUNT PENDING SETTLEMENT"
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "8", display: "flex" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.collectMoney.data.total_pay_price == 0
                                    ? ""
                                    : _vm.collectMoney.data.total_refund_price >
                                      0
                                    ? _vm.formatPriceNumber(
                                        _vm.collectMoney.data.total_pay_price,
                                        2
                                      ) +
                                      "（付" +
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .total_origin_pay_price,
                                        2
                                      ) +
                                      "、已退" +
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .total_refund_price,
                                        2
                                      ) +
                                      "）"
                                    : _vm.formatPriceNumber(
                                        _vm.collectMoney.data.total_pay_price,
                                        2
                                      )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                      ])
                    : _c("div", { staticClass: "ptd" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            style:
                              _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                ? "flex: 5;padding-left: 6px;flex-direction: column;"
                                : "flex: 3;margin-left: -1px;flex-direction: column;",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  "padding-left": "10px",
                                },
                              },
                              [_vm._v(_vm._s(_vm.switchLang("DiscountAmount")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-family": "'printEN'",
                                  "text-align": "left",
                                  "padding-left": "10px",
                                },
                              },
                              [_vm._v("DISCOUNT AMOUNT")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { flex: "8", display: "flex" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { width: "25%" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data
                                      .total_admin_discount_price
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  width: "calc(50% + 2px)",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.collectMoney.data.recharge_order ||
                                          _vm.collectMoney.data.paid == 1
                                          ? _vm.switchLang("PaidAmount")
                                          : _vm.switchLang("BuyerPending")
                                      ) +
                                        _vm._s(
                                          _vm.fee_type !=
                                            _vm.feeTypeInfo.mer_fee_type
                                            ? "（" +
                                                _vm.feeTypeInfo.mer_fee_type.replace(
                                                  "CNY",
                                                  "RMB"
                                                ) +
                                                "）"
                                            : ""
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "'printEN'",
                                      "text-align": "left",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.collectMoney.data.recharge_order ||
                                          _vm.collectMoney.data.paid == 1
                                          ? "ACTUAL PAYMENT AMOUNT"
                                          : "AMOUNT PENDING SETTLEMENT"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "1",
                                  "font-weight": "bold",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.collectMoney.data.total_pay_price == 0
                                        ? ""
                                        : _vm.collectMoney.data
                                            .total_refund_price > 0
                                        ? _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_pay_price,
                                            2
                                          ) +
                                          "（付" +
                                          _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_origin_pay_price,
                                            2
                                          ) +
                                          "、已退" +
                                          _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_refund_price,
                                            2
                                          ) +
                                          "）"
                                        : _vm.formatPriceNumber(
                                            _vm.collectMoney.data
                                              .total_pay_price,
                                            2
                                          )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                  _vm._v(" "),
                  _vm.feeTypeInfo.mer_fee_type == "CNY"
                    ? _c("div", { staticClass: "ptd" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            style:
                              _vm.fee_type != _vm.feeTypeInfo.mer_fee_type
                                ? "flex: 5;padding-left: 6px;flex-direction: column;"
                                : "flex: 3;margin-left: -1px;flex-direction: column;",
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "text-align": "left" } },
                              [_vm._v("人民币（大写）")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-family": "'printEN'",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("CAPITAL IN RMB")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "8", "font-weight": "bold" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.collectMoney.data.total_pay_price == 0
                                  ? ""
                                  : _vm.collectMoney.data.total_pay_price_rmb
                              )
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "secTit",
                  staticStyle: { "margin-top": "30px" },
                },
                [_vm._v("* " + _vm._s(_vm.switchLang("SettleText")) + "。")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [
                  _vm._v(
                    "I have inspected all the auction items and confirm that they are correct. My signature here signifies my agreement."
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "print-line",
                staticStyle: { "margin-top": "30px" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "detail-sign" }, [
                _vm._v(_vm._s(_vm.switchLang("SettleSign"))),
                _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                  _vm._v("（SIGNATURE）"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "print-line" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "30px" },
                },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.switchLang("AccountName")) +
                        "：" +
                        _vm._s(
                          _vm.collectMoney.data.merConfig
                            ? _vm.collectMoney.data.merConfig.bank_name
                            : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.switchLang("Bank")) +
                        "：" +
                        _vm._s(
                          _vm.collectMoney.data.merConfig
                            ? _vm.collectMoney.data.merConfig.bank
                            : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.switchLang("AccountNum")) +
                        "：" +
                        _vm._s(
                          _vm.collectMoney.data.merConfig
                            ? _vm.collectMoney.data.merConfig.bank_number
                            : ""
                        )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px" },
                },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.switchLang("CompanyAddress")) +
                        "：" +
                        _vm._s(
                          _vm.collectMoney.data.merConfig
                            ? _vm.collectMoney.data.merConfig.company_address
                            : ""
                        )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-list",
                  staticStyle: { "margin-top": "5px", width: "100%" },
                },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.switchLang("DocumentNumber")) +
                        "：" +
                        _vm._s(
                          _vm.collectMoney.data.recharge_order
                            ? _vm.collectMoney.data.recharge_order.out_trade_no
                            : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.switchLang("OrderMak")) +
                        "：" +
                        _vm._s(_vm.collectMoney.data.admin_name)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "20%:" } }, [
                    _vm._v(
                      _vm._s(_vm.switchLang("CollectionPeo")) + "：          "
                    ),
                  ]),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.isOutbound
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.onPrintOut },
                },
                [_vm._v("打印并创建出库单")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }