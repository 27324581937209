var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: _vm.venue_name, hasBack: true, isFixed: true },
        scopedSlots: _vm._u([
          {
            key: "btn",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#3480F4 !important",
                      "margin-right": "20px",
                    },
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onGOUrl(
                          "/merchant/systemForm/systemNumber/numberPlatets?isNo=false",
                          1
                        )
                      },
                    },
                  },
                  [_vm._v("号段管理")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { color: "#3480F4 !important" },
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onGOUrl(
                          "/merchant/systemForm/systemNumber/numberPlatevip?isNo=true",
                          1
                        )
                      },
                    },
                  },
                  [_vm._v("免保用户")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _vm.venue_id && _vm.venue_name
            ? _c("div", { staticClass: "top-view" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "0 20px" } },
                  [
                    _c(
                      "el-menu",
                      {
                        staticClass: "el-menu-demo default-tabs mt10",
                        attrs: {
                          "default-active": _vm.routerUrl,
                          mode: "horizontal",
                        },
                        on: { select: _vm.changeTab },
                      },
                      [
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index:
                                "/merchant/pageManagement/auction/meetingData/album",
                            },
                          },
                          [_vm._v("专场")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index:
                                "/merchant/pageManagement/auction/meetingData/auction",
                            },
                          },
                          [_vm._v("拍品")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index:
                                "/merchant/pageManagement/auction/meetingData/numberPlate",
                            },
                          },
                          [_vm._v("号牌")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index:
                                "/merchant/pageManagement/auction/meetingData/realTime",
                            },
                          },
                          [_vm._v("场控")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index:
                                "/merchant/pageManagement/auction/meetingData/entrust",
                            },
                          },
                          [_vm._v("委托")]
                        ),
                        _vm._v(" "),
                        _vm.liveCount > 0
                          ? _c(
                              "el-menu-item",
                              {
                                attrs: {
                                  index:
                                    "/merchant/pageManagement/auction/meetingData/Live",
                                },
                              },
                              [_vm._v("直播")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.oneNum > 0
                          ? _c(
                              "el-menu-item",
                              {
                                attrs: {
                                  index:
                                    "/merchant/pageManagement/auction/meetingData/LiveOne",
                                },
                              },
                              [_vm._v("投屏")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index:
                                "/merchant/order/list/" +
                                (_vm.label ? _vm.label : "auctionMeeting") +
                                "?vid=" +
                                _vm.venue_id +
                                "&vname=" +
                                _vm.venue_name,
                            },
                          },
                          [_vm._v("订单")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index:
                                "/merchant/pageManagement/auction/meetingData/data",
                            },
                          },
                          [_vm._v("统计")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }