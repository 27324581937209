var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addRuleAction } },
              [_vm._v("新增规则")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex flex-wrap" },
        _vm._l(_vm.resultData, function (m, i) {
          return _c("div", { key: "range" + i, staticClass: "ladder-view" }, [
            _c("div", { staticClass: "l-title" }, [
              _vm._v(_vm._s(m.title) + " | " + _vm._s(m.id)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "l-bottom ft12" },
              [
                _vm._l(m.content, function (interval, index) {
                  return _c(
                    "div",
                    {
                      key: "rr" + m.id + index,
                      staticClass: "l-interval item-flex-center",
                    },
                    [
                      _c("div", { staticClass: "flex-one align-r" }, [
                        _vm._v(
                          _vm._s(interval.min) +
                            "-" +
                            _vm._s(interval.max != "∞" ? interval.max : "∞")
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml20 flex-one" }, [
                        interval.type == 1
                          ? _c("span", [
                              _vm._v("加价" + _vm._s(interval.price)),
                            ])
                          : interval.type == 2
                          ? _c("span", [_vm._v("2位2580")])
                          : _c("span", [_vm._v("3位2580")]),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "l-cz item-flex-center" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.editRuleAction(i)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "commonedit" },
                        }),
                        _vm._v("编辑"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.delRuleAction(m.id)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: (_vm.ladderData.id ? "修改" : "新增") + "规则",
            visible: _vm.ladderData.show,
            "close-on-click-modal": false,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.ladderData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-align-center" },
            [
              _c("div", { staticClass: "w100" }, [_vm._v("区间名称：")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.ladderData.intervalName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ladderData, "intervalName", $$v)
                  },
                  expression: "ladderData.intervalName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-align-center" }, [
            _c("div", { staticClass: "w100 mt20" }, [_vm._v("区间规则：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-one" },
              _vm._l(_vm.intervalRule, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "intervalRule" + i,
                    staticClass: "item-align-center mt20",
                  },
                  [
                    i != 0
                      ? _c("div", { staticClass: "w85 align-c" }, [
                          _vm._v(_vm._s(_vm.intervalRule[i - 1].max)),
                        ])
                      : _c("div", { staticClass: "w85 align-c" }, [
                          _vm._v("0"),
                        ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml5 mr5" }, [_vm._v("至")]),
                    _vm._v(" "),
                    i != _vm.intervalRule.length - 1
                      ? _c(
                          "div",
                          [
                            _c("el-input-number", {
                              staticClass: "w85 align-c ml20",
                              attrs: { controls: false, min: 0 },
                              model: {
                                value: m.max,
                                callback: function ($$v) {
                                  _vm.$set(m, "max", $$v)
                                },
                                expression: "m.max",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "w85 align-c ml20" }, [
                          _vm._v("无限"),
                        ]),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "ml20",
                        model: {
                          value: m.type,
                          callback: function ($$v) {
                            _vm.$set(m, "type", $$v)
                          },
                          expression: "m.type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("3位2-5-8-0加价"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("2位2-5-8-0加价"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("固定加价"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-input-number", {
                      staticClass: "w100 ml10",
                      attrs: {
                        controls: false,
                        min: 0,
                        placeholder: "加价幅度（元）",
                        disabled: m.type == 2 || m.type == 3,
                      },
                      model: {
                        value: m.price,
                        callback: function ($$v) {
                          _vm.$set(m, "price", $$v)
                        },
                        expression: "m.price",
                      },
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-circle-plus ml20",
                      staticStyle: { "font-size": "20px", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.splitAction(i)
                        },
                      },
                    }),
                    _vm._v(" "),
                    i != 0
                      ? _c("i", {
                          staticClass: "el-icon-remove ml10",
                          staticStyle: {
                            "font-size": "20px",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteAction(i)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 item-flex-center" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.ladderData.btnLoading,
                  },
                  on: { click: _vm.saveLadderAction },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.ladderData.show = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }